import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AbwZuordnung } from 'pages/Verwaltung/ABW/components/AbwZuordnung';

const Index = () => {
    return (
        <AbwZuordnung
            title="Treffer zuordnen"
            pathBuilder={PathBuilder.home.aufgaben.abw}
            config={{ addAdresseCoords: true }}
        />
    );
};

export const AbwAufgabenTrefferZuordnenPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN
);
