import React from 'react';
import { Typography } from '@mui/material';

import { PageHeading } from 'components/PageHeading';
import { LinkSupport } from 'elements/LinkSupport';

export const SuccessMessage: React.FC = () => {
    return (
        <>
            <PageHeading title="Account deaktiviert" />

            <Typography paragraph>
                Ihr Account wurde aus Sicherheitsgründen deaktiviert. Bitte wenden Sie sich für eine erneute Aktivierung
                an unseren <LinkSupport />
            </Typography>
        </>
    );
};
