import * as React from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import GetAppIcon from '@mui/icons-material/GetApp';
import { FormControl } from '@mui/material';

import { Link } from 'elements/Link';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { RowControl } from './RowControl';

interface VersorgungsvertragLinkControlProps extends ControlProps {
    data: any;
    label: any;
    showFieldNumberLabels?: boolean;
    gridLayout?: GridLayoutConfig;
}

const styles: Record<string, any> = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
};

const DownloadLinkComponent: React.FC<VersorgungsvertragLinkControlProps> = ({ path, data, label, config, id }) => {
    return (
        <RowControl
            name={path}
            label={label}
            showFieldNumberLabels={config.showFieldNumberLabels}
            gridLayout={config.gridLayout}
            labelForId={id}
        >
            <FormControl fullWidth>
                {data ? (
                    <Link id={id} href={data} target="_blank" style={styles}>
                        herunterladen <GetAppIcon />
                    </Link>
                ) : (
                    '-'
                )}
            </FormControl>
        </RowControl>
    );
};

export const DownloadLinkControl = withJsonFormsControlProps(DownloadLinkComponent);
