import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetEinrichtungenForFem200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useFemEinrichtungenListData = (): GetData<GetEinrichtungenForFem200Response> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getFemEinrichtungCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText,
            existsEnabled: true,
            existsDeleted: false,
            art2: filters?.['einrichtungsart'],
            orderName: sortBy(sortOrder, 'name'),
            orderArt: sortBy(sortOrder, 'einrichtungsart'),
            orderNrwKey: sortBy(sortOrder, 'nrwSchluessel'),
            orderAdresseOrt: sortBy(sortOrder, 'ort'),
            orderFemAntraegeAnzahlGerichtlicherGenehmigung: sortBy(sortOrder, 'gerichtlichGesamtanzahl'),
            orderFemAntraegeAnzahlEinwilligung: sortBy(sortOrder, 'einwilligungGesamtanzahl'),
            orderFemUeberfaellig: sortBy(sortOrder, 'ueberfaelligeMeldungen'),
            orderFemErinnerung: sortBy(sortOrder, 'letzteErinnerung'),
            orderFemAntraegeZuletztEingereichtAt: sortBy(sortOrder, 'letzteMeldung'),
        });
    }, []);
};
