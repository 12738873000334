import { JsonSchema4, JsonSchema7 } from '@jsonforms/core';
import { cloneDeep, isEqual, isObject, pickBy } from 'lodash';

import { BenutzerResponse, EinrichtungGetByIdResponse } from 'api/types';
import { FormState } from 'forms/types/UiSchemaTypes';

export const str2int = (str?: string | number | null, ths = '.'): number => {
    if (!str) return NaN;
    if (typeof str === 'number') return str;

    const preparedString = str.trim().replace(new RegExp(`\\${ths}`, 'g'), '');
    return parseInt(preparedString, 10);
};

export const str2int_udef = (str?: string | null, ths = '.'): number | undefined => {
    const v = str2int(str, ths);
    return isNaN(v) ? undefined : v;
};

export const nl2br = (text: string | undefined | null, replaceMode = true): string => {
    const breakTag = '<br />';
    const replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2';

    return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
};

export const getFieldFromScope = (scope?: string): string => scope?.substr(scope.lastIndexOf('/') + 1) || '-';

export const getStackedFieldFromScope = (scope?: string): string[] => {
    const paths = scope?.split(/\//g) || [''];
    return paths.filter((segment) => !['', '#', 'properties'].includes(segment));
};

export const getStackedFieldValue = (paths: string[], data: FormState) =>
    paths.reduce((obj, field) => (obj && obj.hasOwnProperty(field) ? obj[field] : undefined), data);

export const getObjectTypeAction = (scope?: string, action?: string | null): string => {
    if (action) {
        return `${getFieldFromScope(scope)}${action}`;
    }
    return getFieldFromScope(scope);
};

export const getEinrichtungAnschrift = (einrichtung?: EinrichtungGetByIdResponse) => {
    const hasMissingAddressProps = [
        einrichtung?.adresse?.strasse,
        einrichtung?.adresse?.hausnummer,
        einrichtung?.adresse?.plz,
        einrichtung?.adresse?.ort,
    ].some((part) => part === undefined);
    if (hasMissingAddressProps) return '-';

    return `${einrichtung?.adresse?.strasse} ${einrichtung?.adresse?.hausnummer}, ${einrichtung?.adresse?.plz} ${einrichtung?.adresse?.ort}`;
};

export function arrayDiff<TValue = unknown>(lhs: ReadonlyArray<TValue>, rhs: ReadonlyArray<TValue>) {
    const diff1 = lhs.filter((x: TValue) => !rhs.includes(x));
    const diff2 = rhs.filter((x: TValue) => !lhs.includes(x));

    return [...diff1, ...diff2];
}

export function frmData2Nums(
    schema: JsonSchema4 | JsonSchema7,
    data: Record<string, unknown>
): Record<string, unknown> {
    const fieldsData = cloneDeep(data);

    for (const fieldId in schema.properties) {
        const field = schema.properties[fieldId];

        if ((field.type === 'number' || field.type === 'integer') && !!fieldsData[fieldId]) {
            const newVal =
                field.type === 'number' ? parseFloat(`${fieldsData[fieldId]}`) : parseInt(`${fieldsData[fieldId]}`, 10);
            if (!isNaN(newVal)) {
                fieldsData[fieldId] = newVal;
            }
        }
    }

    return fieldsData;
}

export function displayBenutzerName(
    benutzer: Pick<BenutzerResponse, 'nachname' | 'vorname'> | undefined | null
): string | null {
    if (!benutzer) return null;
    return benutzer ? benutzer.nachname + ', ' + benutzer.vorname : '';
}

export function getBenutzerProfileStatus(benutzer: BenutzerResponse | undefined | null): string | null {
    if (!benutzer) return null;

    switch (true) {
        case !benutzer.isVerified:
            return 'Nicht verifiziert';
        case !!benutzer.deaktiviertAt?.length:
            return 'Deaktiviert';
        default:
            return null;
    }
}

type ValueObject = Record<'@id' | 'uuid', any>;

const isNotUndefinedOrNull = (value: any | ValueObject) => {
    const valueObject = cloneDeep(value);

    if (isObject(valueObject) && (Object.hasOwn(valueObject, '@id') || Object.hasOwn(valueObject, 'uuid'))) {
        delete (valueObject as ValueObject)['@id'];
        delete (valueObject as ValueObject)['uuid'];
    }

    return valueObject !== undefined && valueObject !== null;
};

type DeepEqualOptions = { ignoreUndefinedFields?: boolean };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepEqual = (o1: any, o2: any, { ignoreUndefinedFields }: DeepEqualOptions = {}): boolean => {
    return ignoreUndefinedFields
        ? isEqual(pickBy(o1, isNotUndefinedOrNull), pickBy(o2, isNotUndefinedOrNull))
        : isEqual(o1, o2);
};
