import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { EinrichtungTypes } from 'constants/einrichtungTypes';

export const useStandortDescription = () => {
    return useCallback((einrichtungArt?: string) => {
        switch (einrichtungArt) {
            case EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI:
            case EinrichtungTypes.FULLY_INPATIENT_SHORT:
            case EinrichtungTypes.ASSISTED_LIVING:
            case EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE:
            case EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE:
            case EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION:
            case EinrichtungTypes.HOSPICE:
                return (
                    <Typography paragraph>
                        Bitte geben Sie hier die Standorte Ihrer Einrichtung, die Plätze an den jeweiligen Standorten
                        sowie schließlich die Gesamtplatzzahl der Einrichtung an.
                    </Typography>
                );
            case EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII:
                return (
                    <Typography paragraph>
                        Bitte geben Sie hier die Standorte Ihrer Einrichtung, die Plätze an den jeweiligen Standorten
                        sowie schließlich die Gesamtplatzzahl der Einrichtung an. Außenwohngruppen sind dabei zunächst
                        als eigene Standorte einzugeben.
                    </Typography>
                );
            case EinrichtungTypes.WERKSTATT:
                return (
                    <Typography paragraph>
                        Bitte geben Sie hier die Hauptwerkstatt mit den gegebenenfalls zugeordneten Zweigwerkstätten
                        sowie die jeweiligen Platzzahlen (Stichtag: 31.12.) entsprechend der nachfolgenden Aufstellung
                        an.
                    </Typography>
                );
        }
    }, []);
};
