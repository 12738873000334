import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PartialRecord, PlatzzahlmelderEinrichtungItem } from 'api/types';
import { STATUS } from 'constants/antragStatus';
import { FormButtonGroupOption } from 'forms/components/FormButtonGroup';
import { Filters, usePlatzmelderUrlParams } from 'pages/Platzmelder/hooks/usePlatzmelderUrlParams';

export type HandleFilter = (action: STATUS) => void;

export interface UsePlatzmelderFilterOptionsResponse {
    options: Array<FormButtonGroupOption>;
    handleFilter: HandleFilter;
    filteredEinrichtungen: Array<PlatzzahlmelderEinrichtungItem>;
    setFilteredEinrichtungen: React.Dispatch<React.SetStateAction<Array<PlatzzahlmelderEinrichtungItem>>>;
    filters: Filters;
}

interface UsePlatzmelderFilterOptions {
    token?: string;
    einrichtungen?: Array<PlatzzahlmelderEinrichtungItem>;
    searchText: string;
    updateSearchText: (searchText: string) => void;
}

export const usePlatzmelderFilterOptions = ({
    token,
    einrichtungen,
    searchText,
    updateSearchText,
}: UsePlatzmelderFilterOptions): UsePlatzmelderFilterOptionsResponse => {
    const [params, setParams] = useSearchParams();

    const [filters, setFilters] = useState<Record<string, string[]>>({ filter: ['ALL'] });
    const [filteredEinrichtungen, setFilteredEinrichtungen] = useState<Array<PlatzzahlmelderEinrichtungItem>>([]);

    const isCurrentFilter = useCallback((name: string) => filters.filter.includes(name), [filters]);

    const hasSearchText = (searchText: string, einrichtung: PlatzzahlmelderEinrichtungItem) => {
        const textRegex = new RegExp(searchText);
        return [einrichtung?.name, einrichtung?.nrwKey, einrichtung?.ort].some((value) => textRegex.test(value || ''));
    };

    const filterEinrichtungen = useCallback(
        (status?: STATUS) => {
            if (!einrichtungen) {
                return [];
            }

            if (!status) {
                return setFilteredEinrichtungen(
                    einrichtungen.filter((einrichtung) => {
                        return searchText ? hasSearchText(searchText, einrichtung) : true;
                    })
                );
            }

            const filteredList = einrichtungen.filter((einrichtung) => {
                if (searchText) {
                    return einrichtung.belegbarePlaetzeStatus === status && hasSearchText(searchText, einrichtung);
                }

                return einrichtung.belegbarePlaetzeStatus === status;
            });

            setFilteredEinrichtungen(filteredList);
        },
        [einrichtungen, searchText]
    );

    const handleFilter = (action: STATUS) => {
        setFilters({ filter: [action] });

        const actions: PartialRecord<STATUS, () => void> = {
            ALL: () => filterEinrichtungen(),
            [STATUS.AUSSTEHEND]: () => filterEinrichtungen(STATUS.AUSSTEHEND),
            [STATUS.EINGEREICHT]: () => filterEinrichtungen(STATUS.EINGEREICHT),
        };

        actions[action]?.();
    };

    const updateFilters = useCallback(
        (value: Filters) => {
            setFilters(value);
        },
        [setFilters]
    );

    usePlatzmelderUrlParams(filters, searchText, updateFilters, [params, setParams], updateSearchText, token);

    useEffect(() => {
        (params || []).forEach((value, key) => {
            if (String(key).startsWith('filter')) {
                setFilters({ filter: [value] });

                if (value === 'ALL') return filterEinrichtungen();
                filterEinrichtungen(value as STATUS);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        if (einrichtungen?.length) {
            const currentFilter = filters.filter[0] as STATUS;
            filterEinrichtungen(currentFilter !== STATUS.ALL ? currentFilter : undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [einrichtungen, filters]);

    return {
        options: [
            {
                label: 'Alle Einrichtungen',
                action: 'ALL',
                default: isCurrentFilter('ALL'),
            },
            {
                label: 'Ausstehende Meldungen',
                action: STATUS.AUSSTEHEND,
                default: isCurrentFilter(STATUS.AUSSTEHEND),
            },
            {
                label: 'Eingereichte Meldungen',
                action: STATUS.EINGEREICHT,
                default: isCurrentFilter(STATUS.EINGEREICHT),
            },
        ],
        handleFilter,
        filteredEinrichtungen,
        setFilteredEinrichtungen,
        filters,
    };
};
