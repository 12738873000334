import { styled } from '@mui/material';

import { PageHeading } from 'components/PageHeading';
import { transientOptions } from 'theme/utils';

export const ActionHeading = styled(
    PageHeading,
    transientOptions
)<{ $spaceBetween?: boolean }>(({ theme, $spaceBetween }) => ({
    display: 'flex',
    justifyContent: $spaceBetween ? 'space-between' : 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(3),
    flexWrap: 'wrap',
}));
