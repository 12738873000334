import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { EinrichtungArt, EinrichtungArtType, ProfilHeimfinderListResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';

export const useProfilHeimfinderColumns = (
    handleAction: AntragListAction<any>
): ((data: ProfilHeimfinderListResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                },
                {
                    name: 'name',
                    label: 'Name der Einrichtung',
                },
                {
                    name: 'adresse',
                    label: 'Anschrift',
                },
                {
                    name: 'art',
                    label: 'Art der Einrichtung',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = data['hydra:member']?.[dataIndex];
                            const value = rowData?.art as keyof EinrichtungArt;

                            return value ? EinrichtungArtType[value].label : '-';
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
