import React from 'react';
import { MUIDataTableColumnOptions, MUIDataTableFilterOptions } from 'mui-datatables';

import { BENUTZER_STATUS_FILTER_LABELS } from 'constants/labels';

import { CheckboxesFilter } from './CheckboxesFilter';

export const BenutzerStatusFilterOptions = (isSupport: boolean): MUIDataTableColumnOptions => {
    const benutzerFilterOptions: () => MUIDataTableFilterOptions | undefined = () => {
        if (isSupport) {
            return {
                names: Object.values(BENUTZER_STATUS_FILTER_LABELS),
                fullWidth: true,
            };
        }

        return {
            names: ['Deaktiviert'],
            display: (filterList, onChange, index, column) => {
                return (
                    <CheckboxesFilter
                        index={index}
                        column={column}
                        filterData={['1']}
                        filterValue={filterList[index]}
                        filterLabels={{ '1': 'Deaktiviert' }}
                        onChange={onChange}
                    />
                );
            },
        };
    };

    return {
        customFilterListOptions: {
            render: (value) => {
                if (Array.isArray(value)) {
                    return value.map(() => 'Deaktivierte Benutzer anzeigen');
                }

                return `Status: ${value}`;
            },
        },
        filterOptions: benutzerFilterOptions(),
        filterType: isSupport ? 'dropdown' : 'custom',
    };
};
