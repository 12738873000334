import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { CloseButton } from 'components/Dialog/CloseButton';
import { Dialog } from 'components/Dialog/Dialog';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useDisabled } from 'forms/hooks/useDisabled';
import { useErrors } from 'forms/hooks/useErrors';
import { useFormHandling } from 'forms/hooks/useFormHandling';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useFormValidationMode } from 'forms/hooks/useFormValidationMode';
import { useLabel } from 'forms/hooks/useLabel';
import { useReadonly } from 'forms/hooks/useReadonly';
import { prepareUISchema } from 'forms/hooks/useStepUISchema';
import { renderers } from 'forms/renderers';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useErrorMessages } from 'forms/state/useErrorMessages';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, FormState, LabeledType, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getObjectTypeAction } from 'utilities';

export interface FormDialogData {
    title?: string;
    subTitle?: LabeledType;
    scope: string;
    uuid?: string;
    config?: FormConfig;
    schema?: Schema | undefined;
    uiSchema?: UiSchemaType | undefined;
    readonly?: boolean;
    action?: string | null;
    isNew?: boolean;
}

interface FormDialogProps {
    dialogData: FormDialogData;
    onSave: () => void;
    onClose: () => void;
}

export const FormDialog: React.FC<FormDialogProps> = ({
    dialogData: { title, subTitle, uiSchema, config, scope, uuid, action, readonly: readonlyView, isNew },
    onSave,
    onClose,
}) => {
    const { validationMode, switchValidation } = useFormValidationMode();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const api = useMemo(
        () => ({
            getFormDataAPI: config?.loadFormData
                ? () => config.loadFormData!({ objectType: getObjectTypeAction(scope, action), uuid })
                : undefined,
            submitAPI: config?.submitFormData
                ? ({ body, persist }: { body: FormState; persist?: boolean }) =>
                      config.submitFormData!({
                          body,
                          persist,
                          objectType: getObjectTypeAction(scope, action),
                          uuid,
                      })
                : undefined,
        }),
        [action, config, scope, uuid]
    );

    const {
        data,
        formErrors,
        schema,
        onChange,
        requestPersist,
        requestValidate,
        submit,
        validate,
        isLoading,
        isPersisting,
        clear,
    } = useFormHandling(api);

    const [formTouched, setFormTouched] = useState<boolean>(false);
    const [formUiSchema, setFormUiSchema] = useState<UiSchemaType | undefined>();
    const [isDirty, setDirty] = useState(false);
    const subTitleText = useLabel(data, schema, subTitle);
    const { loadingError } = useFormState();

    const disabled = useDisabled();
    const readonly = useReadonly(schema, config) || readonlyView;

    const dialogConfig: FormConfig = useMemo(
        () => ({
            errors: !isNew ? formErrors : undefined,
            formTouched,
            validateOnBlur: false,
            submitFormData: api.submitAPI,
            loadFormData: api.getFormDataAPI,
            requestValidate,
            requestPersist,
            submit: ({ persist }) => submit({ body: data, persist }),
            validate,
            readonly,
        }),
        [
            isNew,
            formErrors,
            api.submitAPI,
            api.getFormDataAPI,
            requestValidate,
            requestPersist,
            validate,
            readonly,
            submit,
            data,
            formTouched,
        ]
    );

    const { errors, setTouched } = useErrors({
        config: dialogConfig,
        path: 'data',
        immediately: false,
    });

    useErrorMessages(errors, loadingError);

    const { valid } = useFormValidation(schema, data, dialogConfig);

    const onDialogSave = useCallback(() => {
        if (!valid) {
            switchValidation(false);
            setFormTouched(true);
            return;
        }

        dialogConfig
            .submit?.({ body: data, persist: true })
            .then(onSave)
            .finally(() => setDirty(false));
    }, [data, dialogConfig, onSave, switchValidation, valid]);

    const onDialogClose = () => {
        const onCloseBase = () => {
            onClose();
            setDirty(false);
            clear();
        };

        if (isDirty) {
            showConfirmation({
                alertText:
                    'Einige Änderungen sind noch nicht gespeichert und gehen beim Schließen des Dialogs verloren. Sind Sie sicher?',
                confirmAction: () => {
                    closeConfirmation();
                    onCloseBase();
                },
                denyAction: closeConfirmation,
            });
        } else {
            onCloseBase();
        }
    };

    const onDataChange = async (newData: FormState) => {
        if (isEqual(newData, data)) return;

        setDirty(true);
        setTouched(false);
        await onChange(newData, false);
    };

    useEffect(() => {
        if (uiSchema && schema) {
            if (isEqual(formUiSchema, prepareUISchema(uiSchema, schema))) {
                return;
            }
            setFormUiSchema(prepareUISchema(uiSchema, schema));
        }
    }, [uiSchema, schema, formUiSchema, action]);

    return (
        <Dialog
            open={Boolean(scope)}
            onClose={onDialogClose}
            title={title}
            subTitle={subTitleText}
            closeAction={<CloseButton onClick={onDialogClose} disabled={disabled} />}
            maxWidth="md"
            data-cy="FormDialog"
            actions={
                <Grid container direction={'row-reverse'} spacing={1}>
                    {/* reversed order (Tab order) */}
                    {!readonly && (
                        <Grid item>
                            <FormButton
                                variant="contained"
                                color={'primary'}
                                onClick={onDialogSave}
                                disabled={isLoading || isPersisting}
                                data-cy={'FormDialog-saveButton'}
                            >
                                Speichern
                            </FormButton>
                        </Grid>
                    )}
                    <Grid item>
                        <FormButton variant="outlined" color="primary" onClick={onDialogClose} disabled={disabled}>
                            Schließen
                        </FormButton>
                    </Grid>
                </Grid>
            }
        >
            <AppLoaderContainer isLoading={isLoading}>
                {data && formUiSchema ? (
                    <>
                        <Pflichtfeld display={!!schema?.required?.length} />

                        <JsonForms
                            schema={schema}
                            uischema={formUiSchema}
                            data={data}
                            renderers={renderers}
                            onChange={(state) => onDataChange(state.data)}
                            config={dialogConfig}
                            readonly={readonly}
                            validationMode={validationMode}
                        />
                    </>
                ) : null}
            </AppLoaderContainer>
        </Dialog>
    );
};
