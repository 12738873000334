import React, { ReactNode, useMemo } from 'react';
import { Box, Button, styled } from '@mui/material';

import { Link } from 'elements/Link';
import { Tab } from 'navigation/hooks/useTabsConfig';

interface FormNavigationProps {
    isDisabled: boolean;
    isDirty: boolean;
    onNextStep?(nextStepPath: string): void;
    nextTab?: Tab;
    nextTabPath?: string;
    nextTabLabel?: boolean;
    prevTabPath?: string;
    backLink?: {
        buttonText?: string;
        path?: string;
    };
    children?: ReactNode;
}

export const FormNavigation = ({
    isDisabled,
    isDirty,
    onNextStep,
    nextTab,
    nextTabPath,
    nextTabLabel,
    prevTabPath,
    backLink,
    children,
}: FormNavigationProps) => {
    const nextTabButtonLabel = useMemo(
        () => (nextTabLabel && nextTab ? nextTab.label : 'Weiter'),
        [nextTabLabel, nextTab]
    );

    return (
        <ButtonGroupWrapper>
            {nextTabPath ? (
                <Button
                    onClick={() => onNextStep?.(nextTabPath)}
                    disabled={isDisabled || nextTab?.disabled === true}
                    variant="contained"
                    sx={{ flexShrink: 'auto' }}
                >
                    {isDirty ? 'Speichern & Weiter' : nextTabButtonLabel}
                </Button>
            ) : null}

            {children ? children : null}

            {prevTabPath && (
                <Button component={Link} to={prevTabPath} variant="outlined">
                    Zurück
                </Button>
            )}

            {!prevTabPath && backLink?.path ? (
                <Button component={Link} to={backLink.path} variant="outlined" sx={{ flexShrink: 'auto' }}>
                    {backLink?.buttonText || 'Abbrechen'}
                </Button>
            ) : null}
        </ButtonGroupWrapper>
    );
};

const ButtonGroupWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '2%',
    justifyContent: 'space-between',
    [theme.breakpoints.between('sm', 'md')]: {
        width: '50%',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: theme.spacing(2),
        margin: '0 auto',
    },
    '& > .MuiButton-root': {
        flexShrink: 0,
    },
}));
