import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useSpecificRole } from 'api/auth/useHasRole';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useEinrichtungenNachrichtenListActions } from 'components/DataTable/actions/useEinrichtungenNachrichtenListActions';
import { useEinrichtungenNachrichtenColumns } from 'components/DataTable/columns/nachrichten/useEinrichtungenNachrichtenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { EMAIL_ANTRAG_ART } from 'constants/emailAntragArt';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';

import { useEinrichtungenNachrichtenListData } from './useEinrichtungenNachrichtenListData';

interface EinrichtungenNachrichtenProps {
    pathBuilder: PathBuilderProps;
}

export const EinrichtungNachrichten = ({ pathBuilder }: EinrichtungenNachrichtenProps) => {
    const { einrichtungId } = useParams();
    const navigate = useNavigate();

    const { isInProgress, progressStart, progressEnd, setRequestMessage } = useFormState();
    const { isBehoerde } = useSpecificRole();

    const { isLoading, data, error } = useApiEinrichtungGet(einrichtungId);

    const { serverDataChanged, setServerDataChanged, handleAction } = useEinrichtungenNachrichtenListActions({
        pathBuilder,
    });

    const getData = useEinrichtungenNachrichtenListData({ einrichtung: einrichtungId });
    const columns = useEinrichtungenNachrichtenColumns(handleAction, setServerDataChanged);

    const handleEmailAntragCreate = () => {
        progressStart();

        backendApiService
            .createEmailAntrag({
                id: Number(einrichtungId),
                typ: isBehoerde ? EMAIL_ANTRAG_ART.TO_EINRICHTUNG : EMAIL_ANTRAG_ART.TO_BEHOERDE,
            })
            .then(({ id }) =>
                navigate(
                    pathBuilder.create_nachricht?.({ einrichtungId: Number(einrichtungId), antragId: Number(id) }) || ''
                )
            )
            .catch(() => setRequestMessage(errorMessage('Erstellen der neuen Nachricht fehlgeschlagen.')))
            .finally(() => progressEnd());
    };

    return (
        <ContentContainer title="Benachrichtungen zum Leistungsangebot">
            <StyledContainer>
                <BaseGridContainer>
                    <AppLoaderContainer isLoading={isLoading}>
                        {data ? (
                            <>
                                {error ? (
                                    <Alert severity="error">Einrichtungsdaten konnten nicht geladen werden.</Alert>
                                ) : (
                                    <PageHeading title={`Nachrichten: ${data?.name}`} />
                                )}

                                <DataTableHydraServerside
                                    omitReloadTableContext
                                    getColumns={columns}
                                    getData={getData}
                                    serverDataChanged={serverDataChanged}
                                    initialOptions={{
                                        sortOrder: {
                                            name: 'eingereichtAt',
                                            direction: 'desc',
                                        },
                                        custom: {
                                            disableSearch: true,
                                        },
                                    }}
                                />
                            </>
                        ) : null}
                    </AppLoaderContainer>

                    <ButtonGroupGrid backLink={{ path: pathBuilder.path || '' }}>
                        <FormLoadingButton
                            loading={isInProgress}
                            loadingPosition="end"
                            onClick={handleEmailAntragCreate}
                            variant="contained"
                            endIcon={<Add />}
                        >
                            Neue Nachricht
                        </FormLoadingButton>
                    </ButtonGroupGrid>
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};
