import { useNavigate, useParams } from 'react-router-dom';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';
import { AxiosError } from 'axios';

import { ACTIONS, ANTRAG_ACTIONS } from 'constants/antragActions';
import { AntragType } from 'constants/antragTypes';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { PathBuilder } from 'navigation/Paths';
import { AntragPageMessages } from 'pages/AntragPage';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';
import { useRoleText } from 'utilities/hooks/useRoleText';
import { getIriId } from 'utilities/IriUtils';

import { HydraErrorDescription, useServerError } from './useServerError';

interface UseFormActionsProps extends AntragPageMessages {
    antragId?: number;
    antragType: AntragType;
    pathBuilder?: PathBuilderForm;
    workflowFormItem: GetWorkflowFormItem;
    onSave?: (submit: boolean, showSuccessMessage?: boolean) => Promise<void>;
}

interface UseFormActions {
    sortFormActions(actions: Array<ACTIONS>): Array<ACTIONS>;
    getButtonColor(action: ACTIONS): ButtonColors;
    handleFormAction: FormAction;
}

export type ButtonColors = OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
>;

type ActionList = Record<string, ACTIONS>;

export type FormAction = (action: ACTIONS, submitAction?: ACTIONS) => void;

export type PathBuilderForm = {
    path?: string | (({ id }: { id?: string }) => string);
    view?: ({ id }: { id: number }) => string;
    nachrichten?: ({ einrichtungId }: { einrichtungId: number }) => string;
};

export type GetWorkflowFormItem = (id?: number, action?: string) => void | Promise<any>;

export const useFormActions = ({
    antragId,
    antragType,
    pathBuilder,
    workflowFormItem,
    onSave,
    messages,
}: UseFormActionsProps): UseFormActions => {
    const { einrichtungId } = useParams();
    const navigate = useNavigate();

    const { submitStart, submitEnd, progressStart, progressEnd } = useFormState();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const { loadBenutzerAufgaben } = useBenutzerAufgaben();
    const { hint, submitSuccessText, errorPublish } = useRoleText(antragType);
    const { setRequestMessage } = useFormState();

    const serverError = useServerError();

    const sortFormActions = (actions: Array<ACTIONS>): Array<ACTIONS> => {
        const actionList: ActionList = {};
        actions.forEach((action) => {
            actionList[ANTRAG_ACTIONS[action]] = action;
        });

        return Object.values(actionList).filter(Boolean);
    };

    const getPathBuilderPath = (pathBuilder?: PathBuilderForm, antragId?: number): string => {
        if ([AntragType.REGELPRUEFUNG, AntragType.FEM].includes(antragType) && pathBuilder?.view) {
            return pathBuilder.view({ id: Number(antragId) });
        }

        if (AntragType.EMAIL_ANTRAG === antragType && pathBuilder?.nachrichten) {
            return pathBuilder.nachrichten({ einrichtungId: Number(einrichtungId) }) || '';
        }

        if (typeof pathBuilder?.path === 'function') {
            return pathBuilder?.path({ id: String(antragId) });
        }

        return pathBuilder?.path || '';
    };

    const handleFormAction: FormAction = (action: ACTIONS, submitAction) => {
        const confirmLabel = 'Fortfahren';
        const denyLabel = 'Abbrechen';

        const handleSaveAction = () => {
            onSave?.(submitAction === ACTIONS.SPEICHERN)
                .catch((error) => error)
                .finally(submitEnd);
        };

        const handleZurueckweisen = () => {
            progressStart();

            workflowFormItem(antragId, ACTIONS.ZURUECKWEISEN)
                ?.then(async (response) => {
                    await loadBenutzerAufgaben();

                    navigate(
                        PathBuilder.home.aufgaben.freigeben.meldungen.zurueckgeben({
                            einrichtungId: Number(getIriId(response.meldung)),
                            antragId: Number(response.id || antragId),
                        })
                    );
                })
                .catch(() => {
                    setRequestMessage(errorMessage('Meldung konnte nicht zurückgewiesen werden.'));
                })
                .finally(() => progressEnd());
        };

        const submitFormData = (action: ACTIONS) => {
            const redirect = () =>
                navigate(getPathBuilderPath(pathBuilder, einrichtungId ? Number(einrichtungId) : antragId), {
                    replace: true,
                });

            workflowFormItem(antragId, action)
                ?.then(async (response: { responseMessage?: string }) => {
                    await loadBenutzerAufgaben();
                    setRequestMessage(
                        successMessage(
                            response?.responseMessage || messages?.[action]?.submitSuccessText || submitSuccessText
                        )
                    );
                    redirect();
                })
                .catch((error: AxiosError<HydraErrorDescription>) => {
                    progressEnd();

                    if (error.response?.status === 428) {
                        setRequestMessage(errorMessage(serverError(error)));
                        return redirect();
                    }

                    setRequestMessage(errorMessage(messages?.[action]?.errorPublish || errorPublish));
                })
                .finally(() => progressEnd());
        };

        const handleSubmit = (action: ACTIONS) => {
            showConfirmation({
                alertText: hint?.alertText,
                confirmLabel,
                denyLabel,
                severity: 'info',
                confirmAction: async () => {
                    closeConfirmation();
                    progressStart();

                    if (onSave) {
                        return onSave(submitAction === ACTIONS.SPEICHERN, false)
                            .then(() => submitFormData(action))
                            .catch((error) => {
                                progressEnd();
                                return error;
                            });
                    }

                    submitFormData(action);
                    progressEnd();
                },
                denyAction: closeConfirmation,
            });
        };

        const handleEmailSend = () => {
            showConfirmation({
                alertText: messages?.[action]?.hint?.alertText || hint?.alertText,
                confirmLabel,
                denyLabel,
                severity: messages?.[action]?.hint?.severity || 'info',
                confirmAction: async () => {
                    closeConfirmation();
                    progressStart();

                    if (onSave) {
                        return onSave(submitAction === ACTIONS.SPEICHERN)
                            .then(() => submitFormData(ACTIONS.EMAIL_SEND))
                            .catch((error) => {
                                progressEnd();
                                return error;
                            });
                    }
                },
                denyAction: closeConfirmation,
            });
        };

        switch (action) {
            case ACTIONS.SPEICHERN:
                submitStart(true);
                handleSaveAction();
                break;
            case ACTIONS.ZURUECKWEISEN:
                handleZurueckweisen();
                break;
            case ACTIONS.EMAIL_SEND:
                handleEmailSend();
                break;
            case ACTIONS.FREIGEBEN:
            case ACTIONS.EINREICHEN:
            case ACTIONS.BEARBEITUNG_ABSCHLIESSEN:
                handleSubmit(action);
                break;
        }
    };

    const getButtonColor = (action: ACTIONS): ButtonColors => {
        switch (action) {
            case ACTIONS.SPEICHERN:
            case ACTIONS.FREIGEBEN:
                return 'secondary';
            case ACTIONS.ZURUECKWEISEN:
                return 'error';
            case ACTIONS.EINREICHEN:
            case ACTIONS.EMAIL_SEND:
            case ACTIONS.BEARBEITUNG_ABSCHLIESSEN:
                return 'primary';
            default:
                return 'inherit';
        }
    };

    return {
        sortFormActions,
        getButtonColor,
        handleFormAction,
    };
};
