import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { DeletedObjectsTable } from './components/DeletedObjectsTable';

const Index = () => {
    return <DeletedObjectsTable pathBuilder={PathBuilder.home.verwaltung.deletedObjects} />;
};

export const DeletedObjectsOverviewPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_DELETED_OBJECTS
);
