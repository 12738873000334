import React from 'react';

import { backendApiService } from 'api/ApiService';
import { PageHeading } from 'components/PageHeading';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { BenutzerTable } from './component/BenutzerTable';

const Index = () => {
    return (
        <ContentContainer title="Benutzer: Übersicht">
            <StyledContainer>
                <PageHeading title="Benutzer: Übersicht" />

                <BenutzerTable
                    pathBuilder={PathBuilder.home.verwaltung.benutzer}
                    displayCreateButton
                    getDataPromise={backendApiService.getBenutzerCollection.bind(backendApiService)}
                    tableOptions={{
                        filter: true,
                        custom: {
                            pathBuilder: PathBuilder.home.verwaltung.benutzer,
                        },
                        showRolleColumn: true,
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const BenutzerOverviewPage = withAuthorization(withSideBar(Index, menuVerwaltung), [ROLES.USER]);
