import React, { useState } from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    ListItemButton,
    ListItemText,
    useTheme,
} from '@mui/material';

import { IconExpand } from 'elements/IconExpand';

interface CollapsibleCardProps {
    children: React.ReactNode;
    isOpen?: boolean;
    title?: string;
    cardActions?: React.ReactNode;
}

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({ children, isOpen, title, cardActions }) => {
    const theme = useTheme();
    const [cardOpen, setCardOpen] = useState<boolean>(!!isOpen);
    const toggleCardState = () => setCardOpen(!cardOpen);

    return (
        <Card sx={{ marginBottom: theme.spacing(5) }}>
            <CardHeader
                title={
                    <ListItemButton onClick={toggleCardState} sx={{ paddingX: theme.spacing(3) }}>
                        <ListItemText>{title || ''}</ListItemText>

                        <IconExpand open={cardOpen} />
                    </ListItemButton>
                }
                sx={{ paddingY: 0 }}
            />

            <Collapse in={cardOpen} timeout="auto" unmountOnExit>
                <Box sx={{ paddingX: theme.spacing(3) }}>
                    <CardContent>{children}</CardContent>

                    {cardActions ? <CardActions>{cardActions}</CardActions> : null}
                </Box>
            </Collapse>
        </Card>
    );
};
