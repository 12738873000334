import React, { ReactNode } from 'react';
import { Button, Grid, styled } from '@mui/material';

import { Link } from 'elements/Link';
import { transientOptions } from 'theme/utils';

interface ButtonGroupGridProps {
    children?: ReactNode;
    backLink?: {
        path: string;
        title?: string;
    };
    xs?: number;
    lg?: number;
    marginBottom?: string;
}

export const ButtonGroupGrid = ({ children, backLink, marginBottom, xs, lg }: ButtonGroupGridProps) => {
    return (
        <ButtonGroupContainer container $marginBottom={marginBottom}>
            <ButtonGroupContainer item xs={xs || 12} lg={lg || 12}>
                {children ? children : null}

                {backLink ? (
                    <Button component={Link} to={backLink.path} variant="outlined">
                        {backLink?.title ?? 'Zur Übersicht'}
                    </Button>
                ) : null}
            </ButtonGroupContainer>
        </ButtonGroupContainer>
    );
};

const ButtonGroupContainer = styled(
    Grid,
    transientOptions
)<{ $marginBottom?: string }>(({ theme, $marginBottom }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: theme.spacing(3),
    marginBottom: $marginBottom || 0,
}));
