import React, { useCallback, useMemo, useState } from 'react';

import { PlatzzahlmelderEinrichtungItem } from 'api/types';
import { useFormState } from 'forms/state/useFormState';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

type ButtonEvent = React.MouseEvent<HTMLButtonElement>;

interface UsePlatzmelderCheckboxListResult {
    isSelected: (einrichtungId?: number | null) => boolean;
    allEntriesSelected: boolean;
    isIndeterminateState: boolean;
    updateSelectedEinrichtungen(einrichtungen: Array<PlatzzahlmelderEinrichtungItem>): void;
    toggleSelectedList(event: ButtonEvent): void;
    handleSelectedEinrichtungId(einrichtungId: number): void;
    clearSelectedList(): void;
    resetSelectedList(): void;
    setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const usePlatzmelderCheckboxList = (
    filteredEinrichtungen: Array<PlatzzahlmelderEinrichtungItem>
): UsePlatzmelderCheckboxListResult => {
    const { setFormSubmitted } = useFormState();

    const [selectedEinrichtungIdList, setSelectedEinrichtungIdList] = useState<Array<number | null | undefined>>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);

    const isSelected = useCallback(
        (einrichtungId?: number | null) => selectedEinrichtungIdList.includes(einrichtungId),
        [selectedEinrichtungIdList]
    );

    const initialSelectedEinrichtungen = useMemo(
        () => filteredEinrichtungen.map((einrichtung: PlatzzahlmelderEinrichtungItem) => einrichtung.id),
        [filteredEinrichtungen]
    );

    const updateSelectedEinrichtungen = useCallback(
        // ToDo: Fix returned type. Should be: Array<number>
        (einrichtungen: Array<PlatzzahlmelderEinrichtungItem>): void => {
            setSelectedEinrichtungIdList(
                (einrichtungen || [])
                    .filter((einrichtung) => isSelected(einrichtung.id))
                    .map((einrichtung) => einrichtung.id)
            );
        },
        [isSelected]
    );

    const allEntriesSelected = useMemo(() => {
        return (
            selectedEinrichtungIdList.length === filteredEinrichtungen.length &&
            filteredEinrichtungen.every((einrichtungen) => selectedEinrichtungIdList.includes(einrichtungen.id))
        );
    }, [selectedEinrichtungIdList, filteredEinrichtungen]);

    const isIndeterminateState = useMemo(
        () => selectedEinrichtungIdList.length > 0 && selectedEinrichtungIdList.length < filteredEinrichtungen.length,
        [filteredEinrichtungen, selectedEinrichtungIdList]
    );

    const handleSelectedEinrichtungId = useCallback(
        (einrichtungId: number) => {
            const hasEinrichtung = selectedEinrichtungIdList.some((id) => id === einrichtungId);

            if (hasEinrichtung) {
                setSelectedEinrichtungIdList(selectedEinrichtungIdList.filter((id) => id !== einrichtungId));
            } else {
                setSelectedEinrichtungIdList([...selectedEinrichtungIdList, einrichtungId]);
            }

            setSelectAll(false);
            setFormSubmitted(false);
        },
        [selectedEinrichtungIdList, setFormSubmitted]
    );

    const toggleSelectedList = useCallback(
        (event: ButtonEvent) => {
            const target: HTMLInputElement = event.target as HTMLInputElement;

            setSelectAll(target.checked);

            if (!target.checked) {
                return setSelectedEinrichtungIdList([]);
            }

            setSelectedEinrichtungIdList(filteredEinrichtungen.map((einrichtung) => einrichtung.id));
        },
        [filteredEinrichtungen]
    );

    const clearSelectedList = () => {
        setSelectedEinrichtungIdList([]);
    };

    const resetSelectedList = useCallback(() => {
        setSelectedEinrichtungIdList(initialSelectedEinrichtungen);
    }, [initialSelectedEinrichtungen]);

    useDebouncedEffect(
        () => {
            if (selectAll && filteredEinrichtungen.length) {
                resetSelectedList();
            }
        },
        [selectAll, filteredEinrichtungen],
        0
    );

    return {
        isSelected,
        allEntriesSelected,
        isIndeterminateState,
        updateSelectedEinrichtungen,
        toggleSelectedList,
        handleSelectedEinrichtungId,
        clearSelectedList,
        resetSelectedList,
        setSelectAll,
    };
};
