import React, { ReactNode, useMemo } from 'react';
import {
    Divider,
    FormLabel,
    Grid,
    GridProps,
    List,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TypographyProps,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { OverridableStringUnion } from '@mui/types';
import { Property } from 'csstype';
import { isBoolean } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { DokumentJsonld } from 'api/client';
import { GeneratedFile } from 'api/types';
import { LabelsInterface } from 'constants/labels';
import { Link } from 'elements/Link';
import { TableCellValue } from 'forms/controls/SummaryTable/FieldRow';
import { formatDateToString } from 'forms/utilities/formatter';
import { theme } from 'theme/theme';
import { transientOptions } from 'theme/utils';
import { getStackedFieldFromScope, getStackedFieldValue } from 'utilities';

import { DocumentsList } from './DocumentsList';

type StyledTypographyComponent = React.ComponentType<TypographyProps<'span', { component: 'span' }>>;

const StyledContainerContainer = styled(Grid)(({ theme }) => ({
    '& .MuiDivider-root': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

const StyledGroupContainer = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1),

    '& h2': {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(4),
    },

    '& .SimpleTable-item': {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

        'tr:last-child td': {
            border: 0,
        },
    },
}));

const StyledInfoFieldValue: StyledTypographyComponent = styled(Typography)(() => ({
    '& p:first-of-type': {
        marginTop: 0,
    },
}));

export const StyledDownloadLink = styled(Link)(() => ({
    display: 'inline-flex',
    alignItems: 'center',
}));

export const StyledDownloadList = styled(
    List,
    transientOptions
)<{ $numbered: boolean }>(({ theme, $numbered }) => ({
    paddingTop: 0,
    listStyle: $numbered ? 'decimal' : 'none',
    marginLeft: theme.spacing($numbered ? 3 : 0),
    '& .MuiListItem-root': {
        paddingTop: 0,
        paddingLeft: 0,
        display: 'revert',
    },
}));

const Container: React.FC<{ children?: ReactNode; divide?: boolean }> = ({ children, divide = true }) => {
    return (
        <StyledContainerContainer container spacing={2} data-cy="DetailsLayout--Container">
            {children}
            {divide ? (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            ) : null}
        </StyledContainerContainer>
    );
};

interface GroupType {
    title?: string;
    children?: ReactNode;
    divider?: boolean;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    md?: GridProps['md'];
    hide?: boolean;
}

const Group: React.FC<GroupType> = ({ children, divider, title, variant = 'h2', md = 12, hide = false }) => {
    if (hide) return <></>;

    return (
        <Grid item xs={12} md={md}>
            <StyledGroupContainer container spacing={3}>
                <Grid item xs={12}>
                    {title ? (
                        <Typography
                            variant={variant}
                            sx={{
                                marginBottom: theme.spacing(4),
                            }}
                        >
                            {title}
                        </Typography>
                    ) : null}
                </Grid>

                {children}
            </StyledGroupContainer>

            {divider ? <Divider /> : null}
        </Grid>
    );
};

interface InfoFieldProps {
    value: number | string | null | undefined | Array<any> | React.ReactElement;
    label?: string;
    isDate?: boolean;
    dateFormat?: string;
    textAlign?: 'left' | 'right';
    labelMd?: GridProps['md'];
    valueMd?: GridProps['md'];
}

const InfoField: React.FC<InfoFieldProps> = ({
    label,
    value,
    isDate,
    dateFormat,
    textAlign = 'left',
    labelMd = 3,
    valueMd = 9,
}) => {
    const fieldValue = useMemo(() => {
        if (value === undefined || value === null) return '-';

        return isDate && typeof value === 'string' ? formatDateToString(value, dateFormat) ?? '-' : String(value);
    }, [isDate, value, dateFormat]);

    return (
        <Grid container>
            {label ? (
                <Grid item xs={5} md={labelMd} className={'Info-item'}>
                    <FormLabel component={'legend'} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                        {label}
                    </FormLabel>
                </Grid>
            ) : null}

            <Grid item xs={label ? 7 : 12} md={label ? valueMd : 12} className={'Info-item'}>
                <FormLabel style={{ textAlign, marginLeft: theme.spacing(3) }} component={'legend'}>
                    <StyledInfoFieldValue
                        component={'span'}
                        dangerouslySetInnerHTML={{
                            __html: fieldValue,
                        }}
                    />
                </FormLabel>
            </Grid>
        </Grid>
    );
};

interface DownloadLinkProps {
    value: DokumentJsonld[] | null | undefined;
    label?: string;
    unit?: string;
    emptyText?: string;
    textAlign?: 'left' | 'right';
    labelMd?: GridProps['md'];
    valueMd?: GridProps['md'];
    openLinkInWindow?: boolean;
}

const DownloadLink = ({
    label,
    value,
    emptyText,
    textAlign = 'left',
    labelMd = 3,
    valueMd = 9,
    openLinkInWindow,
}: DownloadLinkProps) => {
    return (
        <Grid container>
            {label ? (
                <Grid item xs={5} md={labelMd} className={'DownloadLink-item'}>
                    <FormLabel component={'legend'} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                        {label}
                    </FormLabel>
                </Grid>
            ) : null}

            <Grid item xs={label ? 7 : 12} md={label ? valueMd : 12} className={'DownloadLink-item'}>
                <FormLabel style={{ textAlign, marginLeft: theme.spacing(3) }} component={'legend'}>
                    {Array.isArray(value) && value.length ? (
                        <DocumentsList documents={value as Array<GeneratedFile>} openLinkInWindow={openLinkInWindow} />
                    ) : (
                        emptyText || 'Es wurden keine Dateien hochgeladen'
                    )}
                </FormLabel>
            </Grid>
        </Grid>
    );
};

interface SimpleTableColumn {
    label: string;
    name: string;
    textAlign?: Property.TextAlign;
    width?: string | number;
    dictionary?: LabelsInterface;
    isJaNein?: boolean;
    dotNotation?: boolean;
}

type SimpleTableRow<DataType = any> = DataType & { uuid?: string | null; customName?: string | null };
type SimpleTableColumns = Array<SimpleTableColumn>;
type SimpleTableData<DataType = any> = Array<SimpleTableRow<DataType>> | null;

const SimpleTable = <DataType = any,>({
    columns,
    data,
    vertical,
}: {
    columns: SimpleTableColumns;
    data?: SimpleTableData<DataType>;
    vertical?: boolean;
}) => {
    if (!data) return null;

    const getColumnData = (row: SimpleTableRow<DataType>, column: SimpleTableColumn) => {
        const value = row?.[column.name as keyof DataType];

        if (column.isJaNein && isBoolean(value)) {
            return value ? 'Ja' : 'Nein';
        }

        return value === 'CUSTOM'
            ? row.customName
            : column.dictionary?.[value as keyof LabelsInterface] ||
                  getStackedFieldValue(getStackedFieldFromScope(column.name), row) ||
                  value;
    };

    const isLastEntry = (columnLength: number, index: number) => columnLength === index + 1;

    return (
        <Grid container>
            <Grid item xs={12} className={'SimpleTable-item'}>
                <Table data-cy={'Table'} stickyHeader>
                    {!vertical ? (
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={uuidv4()} sx={{ textAlign: column.textAlign }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    ) : null}

                    <TableBody>
                        {vertical ? (
                            <>
                                {(data || []).map((row) => (
                                    <React.Fragment key={uuidv4()}>
                                        {columns.map((column, index) => (
                                            <TableRow key={uuidv4()}>
                                                <TableCell
                                                    variant="head"
                                                    key={uuidv4()}
                                                    sx={{
                                                        textAlign: column.textAlign,
                                                        borderWidth: isLastEntry(columns.length, index) ? '3px' : '1px',
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>

                                                <TableCell
                                                    key={uuidv4()}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        width: column.width || 'auto',
                                                        borderWidth: isLastEntry(columns.length, index) ? '3px' : '1px',
                                                    }}
                                                >
                                                    <TableCellValue
                                                        key={uuidv4()}
                                                        data={getColumnData(row, column)}
                                                        textAlign={column.textAlign}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <>
                                {(data || []).map((row) => (
                                    <TableRow key={row?.uuid || uuidv4()}>
                                        {columns.map((column) => {
                                            return (
                                                <TableCell
                                                    key={uuidv4()}
                                                    sx={{
                                                        width: column.width || 'auto',
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <TableCellValue
                                                        key={uuidv4()}
                                                        data={getColumnData(row, column)}
                                                        textAlign={column.textAlign}
                                                    />
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export const DetailsLayout = {
    Container,
    Group,
    InfoField,
    DownloadLink,
    SimpleTable,
};
