import { useEffect } from 'react';

const DEFAULT = 'PfAD.wtg2';

export const useDocumentTitle = (title?: string) => {
    useEffect(() => {
        document.title = title ? `${title} | ${DEFAULT}` : DEFAULT;
        return () => {
            document.title = DEFAULT;
        };
    }, [title]);
};
