import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import { PATH_INVEST_REGISTRATION, PATH_INVEST_REGISTRATION_FAILED, PathBuilder } from 'navigation/Paths';
import { Error404Page } from 'pages/Error404';
import { InvestRegistrationOverview } from 'pages/Verwaltung/InvestRegistration/InvestRegistrationOverview';

export const InvestRegistrationRoutes = () => {
    const { SentryRoutes } = useSentry();

    return (
        <SentryRoutes>
            <Route path={PATH_INVEST_REGISTRATION}>
                <Route path={PATH_INVEST_REGISTRATION_FAILED} element={<InvestRegistrationOverview />} />
                <Route index element={<Navigate to={PathBuilder.home.verwaltung.investRegistrations.path} replace />} />

                <Route path="*" element={<Error404Page />} />
            </Route>
        </SentryRoutes>
    );
};
