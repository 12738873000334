import React from 'react';

import { useBehoerdeListActions } from 'components/DataTable/actions/useBehoerdeListActions';
import { useBehoerdenColumns } from 'components/DataTable/columns/behoerden/useBehoerdenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useBehoerdenListData } from './useBehoerdenListData';

const Index = () => {
    const { handleAction } = useBehoerdeListActions();

    const getData = useBehoerdenListData();

    const columns = useBehoerdenColumns(handleAction);

    return (
        <ContentContainer title="Kreise/kreisfreien Städte: Übersicht">
            <StyledContainer>
                <BaseGridContainer>
                    <PageHeading title="Kreise & kreisfreie Städte" />

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        persistSearchText
                        initialOptions={{
                            viewColumns: true,
                            sortOrder: {
                                name: 'name',
                                direction: 'asc',
                            },
                            custom: {
                                pathBuilder: PathBuilder.home.verwaltung.behoerden,
                            },
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const BehoerdenOverview = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_BEHOERDEN);
