import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Typography } from '@mui/material';

import { DokumentPruefungListResponse } from 'api/types';
import { useDokumentPruefungListActions } from 'components/DataTable/actions/useDokumentPruefungListActions';
import { useDokumentColumns } from 'components/DataTable/columns/useDokumentColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ROLE_TYPES } from 'constants/roles';
import { Link } from 'elements/Link';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useDokumentPruefungListData } from './useDokumentPruefungListData';

const Index = () => {
    const pathBuilder = PathBuilder.home.verwaltung.documentVerification;

    const { serverDataChanged, handleAction } = useDokumentPruefungListActions(pathBuilder);

    const columns = useDokumentColumns<DokumentPruefungListResponse>(handleAction);
    const getData = useDokumentPruefungListData();

    return (
        <ContentContainer title="Dokumente nach Prüfung">
            <StyledContainer>
                <Typography variant="h1">Dokumente nach Prüfung</Typography>

                <Alert severity="info">
                    Die hochgeladenen Dokumente können Sie nach der Prüfung/Freigabe einer Meldung als Anhang zu einer
                    E-Mail an den Leistungsanbieter einzeln auswählen und versenden. Die Auswahl der verfügbaren
                    Dokumente/Anhänge kann durch die Angabe "Art des Leistungsangebots" eingeschränkt werden.
                </Alert>

                <Alert severity="warning">
                    Hinweis: Bitte laden Sie hier für jede Art von Leistungsangebot nur wenige Dateien hoch und wenn
                    möglich nicht größer als 1 MB pro Datei, da diese Dateien per E-Mail versendet werden.
                </Alert>

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    serverDataChanged={serverDataChanged}
                    persistSearchText
                    initialOptions={{
                        custom: {
                            pathBuilder,
                        },
                    }}
                />

                <ButtonGroupGrid>
                    <Button
                        component={Link}
                        to={pathBuilder.add}
                        color="primary"
                        variant="contained"
                        endIcon={<AddIcon />}
                    >
                        Dokument(e) hinzufügen
                    </Button>
                </ButtonGroupGrid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const DokumentePruefungOverviewPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_DOKUMENTE_PRUEFUNG
);
