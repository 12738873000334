import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import { BehoerdenDetailPage } from 'pages/Behoerden/BehoerdenDetailPage';
import { BehoerdenEditPage } from 'pages/Behoerden/BehoerdenEditPage';
import { BehoerdenMandantEditPage } from 'pages/Behoerden/BehoerdenMandantEditPage';
import { BehoerdenOverview } from 'pages/Behoerden/BehoerdenOverview';
import { FemAntraegeOverview } from 'pages/FEM/FemAntraegeOverview';
import { FemAntragBearbeitenPage } from 'pages/FEM/FemAntragBearbeitenPage';
import { FemAntragDetailPage } from 'pages/FEM/FemAntragDetailPage';
import { FemAuforderungPage } from 'pages/FEM/FemAufforderungPage';
import { FemEinrichtungenOverview } from 'pages/FEM/FemEinrichtungenOverview';
import { LandschaftsverbandDetailPage } from 'pages/Landschaftsverband/LandschaftsverbandDetailPage';
import { LandschaftsverbandEditPage } from 'pages/Landschaftsverband/LandschaftsverbandEditPage';
import { LandschaftsverbandOverview } from 'pages/Landschaftsverband/LandschaftsverbandOverview';
import { LvMandantEditPage } from 'pages/Landschaftsverband/LvMandantEditPage';
import { MeldungDetailPage } from 'pages/Meldung/Details/MeldungDetailPage';
import { MeldungAufforderungAntrag } from 'pages/Meldung/MeldungAufforderungAntrag';
import { MeldungEditAntrag } from 'pages/Meldung/MeldungEditAntrag';
import { MeldungOverview } from 'pages/Meldung/MeldungOverview';
import { RegelpruefungAntragPage } from 'pages/Regelpruefung/RegelpruefungAntrag';
import { RegelpruefungDetailPage } from 'pages/Regelpruefung/RegelpruefungDetailPage';
import { RegelpruefungEinrichtungOverview } from 'pages/Regelpruefung/RegelpruefungEinrichtungOverview';
import { RegelpruefungOverview } from 'pages/Regelpruefung/RegelpruefungOverview';
import { RegelpruefungenPruefberichteOverview } from 'pages/RegelpruefungPruefberichte/RegelpruefungenPruefberichteOverview';
import { TraegerBearbeitenPage } from 'pages/Traeger/TraegerBearbeitenPage';
import { TraegerCreatePage } from 'pages/Traeger/TraegerCreatePage';
import { TraegerDetailPage } from 'pages/Traeger/TraegerDetailPage';
import { TraegerMandantDetailPage } from 'pages/Traeger/TraegerMandantDetailPage';
import { TraegerUebersicht } from 'pages/Traeger/TraegerUebersicht';
import { TraegerverbandCreatePage } from 'pages/Traegerverband/TraegerverbandCreatePage';
import { TraegerverbandDetailPage } from 'pages/Traegerverband/TraegerverbandDetailPage';
import { TraegerverbandEditPage } from 'pages/Traegerverband/TraegerverbandEditPage';
import { TraegerverbandOverview } from 'pages/Traegerverband/TraegerverbandOverview';
import { AbwAnsehenPage } from 'pages/Verwaltung/ABW/AbwAnsehenPage';
import { AbwCreatePage } from 'pages/Verwaltung/ABW/AbwCreatePage';
import { AbwEditPage } from 'pages/Verwaltung/ABW/AbwEditPage';
import { AbwLeistungsangebotPage } from 'pages/Verwaltung/ABW/AbwLeistungsangebotPage';
import { AbwManuelleZuordnungPage } from 'pages/Verwaltung/ABW/AbwManuelleZuordnungPage';
import { AbwOverviewPage } from 'pages/Verwaltung/ABW/AbwOverviewPage';
import { AbwTrefferZuordnenPage } from 'pages/Verwaltung/ABW/AbwTrefferZuordnenPage';
import { DeletedObjectAnsehenPage } from 'pages/Verwaltung/DeletedObjects/DeletedObjectAnsehenPage';
import { DeletedObjectsOverviewPage } from 'pages/Verwaltung/DeletedObjects/DeletedObjectsOverviewPage';
import { DokumentePruefungOverviewPage } from 'pages/Verwaltung/DokumentePruefung/DokumentePruefungOverviewPage';
import { DokumentPruefungAddPage } from 'pages/Verwaltung/DokumentePruefung/DokumentPruefungAddPage';
import { DokumentPruefungAnsehenPage } from 'pages/Verwaltung/DokumentePruefung/DokumentPruefungAnsehenPage';
import { EmailSignaturEditPage } from 'pages/Verwaltung/EmailSignatur/EmailSignaturEditPage';
import { EmailTextEdit } from 'pages/Verwaltung/EmailText/EmailTextEdit';
import { MassenmailAnsehen } from 'pages/Verwaltung/Massenmail/MassenmailAnsehen';
import { MassenmailCreate } from 'pages/Verwaltung/Massenmail/MassenmailCreate';
import { MassenmailOverview } from 'pages/Verwaltung/Massenmail/MassenmailOverview';
import { MeldungHinweisDokumentAddPage } from 'pages/Verwaltung/MeldungHinweistexte/MeldungHinweisDokumentAddPage';
import { MeldungHinweisDokumentAnsehenPage } from 'pages/Verwaltung/MeldungHinweistexte/MeldungHinweisDokumentAnsehenPage';
import { MeldungHinweiseOverviewPage } from 'pages/Verwaltung/MeldungHinweistexte/MeldungHinweiseOverviewPage';
import { MeldungHinweisTextAnsehenPage } from 'pages/Verwaltung/MeldungHinweistexte/MeldungHinweisTextAnsehenPage';
import { MeldungHinweisTextCreatePage } from 'pages/Verwaltung/MeldungHinweistexte/MeldungHinweisTextCreatePage';
import { MeldungHinweisTextEditPage } from 'pages/Verwaltung/MeldungHinweistexte/MeldungHinweisTextEditPage';
import { ProfilHeimfinderEditPage } from 'pages/Verwaltung/ProfilHeimfinder/ProfilHeimfinderEditPage';
import { ProfilHeimfinderOverviewPage } from 'pages/Verwaltung/ProfilHeimfinder/ProfilHeimfinderOverviewPage';

import {
    PATH_ABW,
    PATH_ADD,
    PATH_ANTRAG,
    PATH_ANTRAG_AUFFORDERUNG,
    PATH_BEHOERDEN,
    PATH_BEHOERDEN_EDIT,
    PATH_CREATE,
    PATH_DELETED_OBJECTS,
    PATH_DOKUMENT_PRUEFUNG,
    PATH_EDIT,
    PATH_EINRICHTUNGEN,
    PATH_EMAIL_SIGNATUR,
    PATH_EMAIL_TEXT,
    PATH_FEM,
    PATH_LANDSCHAFTSVERBAND,
    PATH_LANDSCHAFTSVERBAND_EDIT,
    PATH_LEISTUNGSANGEBOT_ERSTELLEN,
    PATH_MANDANT,
    PATH_MANDANT_BEHOERDE,
    PATH_MANDANT_LV,
    PATH_MASSENMAIL,
    PATH_MELDUNG,
    PATH_MELDUNG_FREIGEGEBENE,
    PATH_MELDUNG_HINWEIS_DOCUMENT,
    PATH_MELDUNG_HINWEIS_TEXT,
    PATH_MELDUNG_HINWEISE,
    PATH_PROFIL_HEIMFINDER,
    PATH_PRUEFBERICHTE,
    PATH_REGELPRUEFUNG,
    PATH_TRAEGER,
    PATH_TRAEGERVERBAND_UEBERSICHT,
    PATH_VERWALTUNG,
    PATH_VIEW,
    PATH_ZUORDNUNG_MANUELL,
    PATH_ZUORDNUNG_TREFFER,
    PathBuilder,
    useRedirectSegment,
} from './Paths';
import { BenutzerRoutes } from './Verwaltung/BenutzerRoutes';
import { InvestRegistrationRoutes } from './Verwaltung/InvestRegistrationRoutes';
import { LeistungsangeboteRoutes } from './Verwaltung/LeistungsangeboteRoutes';
import { PlatzmelderRoutes } from './Verwaltung/PlatzmelderRoutes';

export const RoutingVerwaltung = () => {
    const { SentryRoutes } = useSentry();

    const { regelPruefungAntragFirstStep, abwLeistungsangeboteFirstStep, meldungFirstStep, femAntragFirstStep } =
        useRedirectSegment();

    return (
        <SentryRoutes>
            <Route path={PATH_VERWALTUNG}>
                <Route path={PATH_MELDUNG}>
                    <Route path=":einrichtungId">
                        <Route
                            path={`${PATH_ANTRAG}/:antragId/${PATH_ANTRAG_AUFFORDERUNG}`}
                            element={<MeldungAufforderungAntrag />}
                        />
                    </Route>

                    <Route path=":antragId">
                        <Route path=":step" element={<MeldungEditAntrag />} />
                        <Route index element={<Navigate to={meldungFirstStep} replace />} />
                    </Route>

                    <Route path={PATH_MELDUNG_FREIGEGEBENE}>
                        <Route path=":id" element={<MeldungDetailPage />} />
                        <Route index element={<Navigate to={PathBuilder.home.verwaltung.meldung.path} replace />} />
                    </Route>

                    <Route index element={<MeldungOverview />} />
                </Route>

                <Route path={PATH_LANDSCHAFTSVERBAND}>
                    <Route path=":id">
                        <Route path={PATH_LANDSCHAFTSVERBAND_EDIT} element={<LandschaftsverbandEditPage />} />
                        <Route index element={<LandschaftsverbandDetailPage />} />
                    </Route>

                    <Route index element={<LandschaftsverbandOverview />} />
                </Route>

                <Route path={PATH_MANDANT}>
                    <Route path={PATH_MANDANT_BEHOERDE}>
                        <Route path={PATH_EDIT} element={<BehoerdenMandantEditPage />} />
                    </Route>

                    <Route path={PATH_MANDANT_LV}>
                        <Route path={PATH_EDIT} element={<LvMandantEditPage />} />
                    </Route>
                </Route>

                <Route path={PATH_BEHOERDEN}>
                    <Route path=":id">
                        <Route path={PATH_BEHOERDEN_EDIT} element={<BehoerdenEditPage />} />
                        <Route index element={<BehoerdenDetailPage />} />
                    </Route>

                    <Route index element={<BehoerdenOverview />} />
                </Route>

                <Route path={PATH_TRAEGERVERBAND_UEBERSICHT}>
                    <Route path={PATH_CREATE} element={<TraegerverbandCreatePage />} />

                    <Route path=":id">
                        <Route path={PATH_VIEW} element={<TraegerverbandDetailPage />} />
                        <Route path={PATH_EDIT} element={<TraegerverbandEditPage />} />
                        <Route
                            index
                            element={<Navigate to={PathBuilder.home.verwaltung.traegerverband.path} replace />}
                        />
                    </Route>

                    <Route index element={<TraegerverbandOverview />} />
                </Route>

                <Route path={PATH_TRAEGER}>
                    <Route path={PATH_VIEW} element={<TraegerMandantDetailPage />} />
                    <Route path={PATH_CREATE} element={<TraegerCreatePage />} />

                    <Route path=":id">
                        <Route path={PATH_EDIT} element={<TraegerBearbeitenPage />} />
                        <Route index element={<TraegerDetailPage />} />
                    </Route>

                    <Route path=":antragId">
                        <Route path=":step" element={<TraegerBearbeitenPage />} />
                    </Route>

                    <Route index element={<TraegerUebersicht />} />
                </Route>

                <Route path={PATH_FEM}>
                    <Route path={PATH_EINRICHTUNGEN}>
                        <Route path=":einrichtungId">
                            <Route path={PATH_ANTRAG}>
                                <Route path=":antragId">
                                    <Route path={PATH_VIEW} element={<FemAntragDetailPage />} />
                                    <Route path={PATH_ANTRAG_AUFFORDERUNG} element={<FemAuforderungPage />} />
                                    <Route path=":step" element={<FemAntragBearbeitenPage />} />
                                    <Route index element={<Navigate to={femAntragFirstStep} replace />} />
                                </Route>
                            </Route>

                            <Route index element={<FemAntraegeOverview />} />
                        </Route>

                        <Route index element={<FemEinrichtungenOverview />} />
                    </Route>

                    <Route index element={<Navigate to={PathBuilder.home.verwaltung.femAntrag.path} replace />} />
                </Route>

                <Route path={PATH_DELETED_OBJECTS}>
                    <Route path=":art">
                        <Route path=":id">
                            <Route path={PATH_VIEW} element={<DeletedObjectAnsehenPage />} />
                        </Route>
                        <Route
                            index
                            element={<Navigate to={PathBuilder.home.verwaltung.deletedObjects.path} replace />}
                        />
                    </Route>
                    <Route index element={<DeletedObjectsOverviewPage />} />
                </Route>

                <Route path={PATH_MASSENMAIL}>
                    <Route path={PATH_CREATE} element={<MassenmailCreate />} />
                    <Route path=":massenmailId" element={<MassenmailAnsehen />} />
                    <Route index element={<MassenmailOverview />} />
                </Route>

                <Route path={PATH_EMAIL_TEXT}>
                    <Route index element={<EmailTextEdit />} />
                </Route>

                <Route path={PATH_EMAIL_SIGNATUR}>
                    <Route path={PATH_EDIT} element={<EmailSignaturEditPage />} />
                </Route>

                <Route path={PATH_MELDUNG_HINWEISE}>
                    <Route path={PATH_MELDUNG_HINWEIS_TEXT}>
                        <Route path={PATH_CREATE} element={<MeldungHinweisTextCreatePage />} />
                        <Route path=":id">
                            <Route path={PATH_EDIT} element={<MeldungHinweisTextEditPage />} />
                            <Route path={PATH_VIEW} element={<MeldungHinweisTextAnsehenPage />} />
                        </Route>
                    </Route>

                    <Route path={PATH_MELDUNG_HINWEIS_DOCUMENT}>
                        <Route path={PATH_ADD} element={<MeldungHinweisDokumentAddPage />} />
                        <Route path=":id">
                            <Route path={PATH_VIEW} element={<MeldungHinweisDokumentAnsehenPage />} />
                        </Route>
                    </Route>

                    <Route index element={<MeldungHinweiseOverviewPage />} />
                </Route>

                <Route path={PATH_DOKUMENT_PRUEFUNG}>
                    <Route path={PATH_ADD} element={<DokumentPruefungAddPage />} />
                    <Route path=":id">
                        <Route path={PATH_VIEW} element={<DokumentPruefungAnsehenPage />} />
                    </Route>

                    <Route index element={<DokumentePruefungOverviewPage />} />
                </Route>

                <Route path={PATH_ABW}>
                    <Route path=":id">
                        <Route path={PATH_EDIT} element={<AbwEditPage />} />
                        <Route path={PATH_ZUORDNUNG_TREFFER} element={<AbwTrefferZuordnenPage />} />
                        <Route path={PATH_ZUORDNUNG_MANUELL} element={<AbwManuelleZuordnungPage />} />

                        <Route path={PATH_LEISTUNGSANGEBOT_ERSTELLEN}>
                            <Route path=":antragId">
                                <Route path=":step" element={<AbwLeistungsangebotPage />} />
                                <Route index element={<Navigate to={abwLeistungsangeboteFirstStep} replace />} />
                            </Route>
                            <Route index element={<Navigate to={PathBuilder.home.verwaltung.abw.path} replace />} />
                        </Route>

                        <Route index element={<AbwAnsehenPage />} />
                    </Route>
                    <Route path={PATH_CREATE} element={<AbwCreatePage />} />
                    <Route index element={<AbwOverviewPage />} />
                </Route>

                <Route path={PATH_PRUEFBERICHTE}>
                    <Route index element={<RegelpruefungenPruefberichteOverview />} />
                </Route>

                <Route path={PATH_REGELPRUEFUNG}>
                    <Route path={PATH_EINRICHTUNGEN}>
                        <Route path=":einrichtungId">
                            <Route path={PATH_ANTRAG}>
                                <Route path=":antragId">
                                    <Route path={PATH_VIEW} element={<RegelpruefungDetailPage />} />
                                    <Route path=":step" element={<RegelpruefungAntragPage />} />
                                    <Route index element={<Navigate to={regelPruefungAntragFirstStep} replace />} />
                                </Route>
                            </Route>

                            <Route index element={<RegelpruefungOverview />} />
                        </Route>

                        <Route index element={<RegelpruefungEinrichtungOverview />} />
                    </Route>

                    <Route index element={<Navigate to={PathBuilder.home.verwaltung.regelpruefung.path} replace />} />
                </Route>

                <Route path={PATH_PROFIL_HEIMFINDER}>
                    <Route path=":id">
                        <Route path={PATH_EDIT} element={<ProfilHeimfinderEditPage />} />
                    </Route>
                    <Route index element={<ProfilHeimfinderOverviewPage />} />
                </Route>

                <Route
                    path="*"
                    element={
                        <>
                            <BenutzerRoutes />
                            <PlatzmelderRoutes />
                            <LeistungsangeboteRoutes />
                            <InvestRegistrationRoutes />
                        </>
                    }
                />
            </Route>
        </SentryRoutes>
    );
};
