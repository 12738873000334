import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, styled, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { EmailAntragCollectionResponse } from 'api/types';
import { Link } from 'elements/Link';
import { PathBuilder } from 'navigation/Paths';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

interface DashboardMessagesProps {
    data: EmailAntragCollectionResponse;
}

const MessageLink = styled(Button)(() => ({
    textTransform: 'uppercase',
})) as typeof Button;

export const DashboardMessages: React.FC<DashboardMessagesProps> = ({ data }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const shouldDisplayDivider = (currentIndex: number): boolean => data?.['hydra:member']?.length !== currentIndex + 1;

    const { transform2Date } = useFormatDate();

    const nachrichtPath = PathBuilder.home.nachrichten.view;

    const handleItemClick = (id: number | undefined) => {
        navigate(nachrichtPath({ id: Number(id) }));
    };

    return (
        <>
            <List
                sx={{
                    marginBottom: theme.spacing(4),
                }}
            >
                {data?.['hydra:member']?.length ? (
                    data['hydra:member'].map((item, index) => (
                        <React.Fragment key={uuidv4()}>
                            <StyledListItem
                                onClick={() => handleItemClick(item.id)}
                                secondaryAction={
                                    <MessageLink
                                        focusRipple={false}
                                        component={Link}
                                        href={nachrichtPath({ id: Number(item.id) })}
                                        color="primary"
                                        onClick={(event: MouseEvent) => {
                                            event.preventDefault();
                                            handleItemClick(item.id);
                                        }}
                                    >
                                        Ansehen
                                    </MessageLink>
                                }
                                sx={{
                                    paddingY: theme.spacing(2),
                                }}
                            >
                                {!item?.read ? (
                                    <ListItemAvatar>
                                        <Badge badgeContent={' '} color="primary"></Badge>
                                    </ListItemAvatar>
                                ) : null}

                                <ListItemText
                                    primary={item.betreff}
                                    secondary={
                                        item?.eingereichtAt
                                            ? transform2Date(item?.eingereichtAt, 'dd.MM.yyyy hh:mm')
                                            : '-'
                                    }
                                />
                            </StyledListItem>

                            {shouldDisplayDivider(index) && <Divider />}
                        </React.Fragment>
                    ))
                ) : (
                    <ListItem>Keine neuen Nachrichten</ListItem>
                )}
            </List>
        </>
    );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingRight: theme.spacing(2),
    '&:hover, &:focus': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.selected,
    },
    '& .MuiListItemSecondaryAction-root': {
        position: 'static',
        display: 'flex',
        alignItems: 'center',
        transform: 'initial',
    },
}));
