import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useSpecificRole } from 'api/auth/useHasRole';
import { BenutzerZugriffsrechteListResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { ANREDE_LABELS } from 'constants/labels';

export const useBenutzerZugriffsrechteColumns = (
    handleAction?: AntragListAction<any>
): ((data: BenutzerZugriffsrechteListResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { isBehoerde, isLV } = useSpecificRole();
    const actions = useServerActionsColumn({ handleAction, disableActions: isBehoerde || isLV });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'anrede',
                    label: 'Anrede',
                    options: {
                        customBodyRender: (value) => (value ? ANREDE_LABELS[value] : '-'),
                    },
                },
                {
                    name: 'nachname',
                    label: 'Name',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = data['hydra:member']?.[dataIndex];
                            return `${rowData.nachname} ${rowData?.vorname}`;
                        },
                    },
                },
                {
                    name: 'mandantenName',
                    label: 'Organisation',
                    options: {
                        sort: false,
                    },
                },
                {
                    name: 'telefon',
                    label: 'Telefon',
                },
                {
                    name: 'fax',
                    label: 'Fax',
                },
                {
                    name: 'email',
                    label: 'E-Mail-Adresse',
                },
                {
                    name: 'isAdmin',
                    label: 'Admin',
                    options: {
                        customBodyRender: (value) => (value === true ? 'Ja' : 'Nein'),
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
