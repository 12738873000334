import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Alert, Grid, useTheme } from '@mui/material';

import { useHasRole } from 'api/auth/useHasRole';
import { DokumentJsonld } from 'api/client';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { EinrichtungArt, EinrichtungArtType } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useRegelpruefungListActions } from 'components/DataTable/actions/useRegelpruefungListActions';
import { useRegelpruefungAntragColumns } from 'components/DataTable/columns/regelpruefung/useRegelpruefungAntragColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ACTIONS } from 'constants/antragActions';
import { ROLE_TYPES } from 'constants/roles';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ActionHeading } from 'layout/components/ActionHeading';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { getEinrichtungAnschrift } from 'utilities';

import { useRegelpruefungAntragListData } from './useRegelpruefungAntragListData';

const Index = () => {
    const { einrichtungId } = useParams();
    const theme = useTheme();

    const hasRole = useHasRole();
    const allowCreate = hasRole(ROLE_TYPES.ROLLEN_REGELPRUEFUNG_CREATE);

    const { serverDataChanged, handleAction } = useRegelpruefungListActions();
    const {
        data: einrichtung,
        isLoading: isLoadingEinrichtung,
        error: errorLoadingEinrichtung,
    } = useApiEinrichtungGet(einrichtungId);
    const { isInProgress } = useFormState();

    const getData = useRegelpruefungAntragListData(einrichtungId);
    const columns = useRegelpruefungAntragColumns(handleAction);

    const hasFreigegebeneMeldung = useMemo(() => einrichtung?.letzteFreigegebeneMeldung !== false, [einrichtung]);

    const documentTitle = useMemo(() => {
        if (!isLoadingEinrichtung && einrichtung) {
            return `Detailsseite mit Regelprüfungen: "${einrichtung.name}"`;
        }

        return 'Einrichtungsdaten werden geladen';
    }, [isLoadingEinrichtung, einrichtung]);

    return (
        <ContentContainer title={documentTitle}>
            <StyledContainer>
                <Grid container spacing={3} justifyContent="center">
                    <AppLoaderContainer isLoading={isLoadingEinrichtung}>
                        {einrichtung ? (
                            <>
                                <Grid item xs={12}>
                                    <PageHeading title={`Detailsseite mit Regelprüfungen: "${einrichtung.name}"`} />

                                    <DetailsLayout.Group divider>
                                        <DetailsLayout.InfoField value={einrichtung.nrwKey} label="NRW-Schlüssel" />
                                        <DetailsLayout.InfoField
                                            value={
                                                einrichtung.art
                                                    ? EinrichtungArtType[einrichtung.art as keyof EinrichtungArt].label
                                                    : '-'
                                            }
                                            label="Art des Leistungsangebots"
                                        />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.name}
                                            label="Name des Leistungangebots"
                                        />
                                        <DetailsLayout.InfoField
                                            value={getEinrichtungAnschrift(einrichtung)}
                                            label="Anschrift"
                                        />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.adresse?.name}
                                            label="Ansprechpartner"
                                        />
                                        <DetailsLayout.InfoField value={einrichtung.adresse?.telefon} label="Telefon" />
                                        <DetailsLayout.InfoField value={einrichtung.adresse?.fax} label="Telefax" />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.adresse?.email}
                                            label="E-Mail-Adresse"
                                        />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.adresse?.homepage}
                                            label="Homepage"
                                        />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.erstinbetriebnahme}
                                            label="Datum der Erstinbetriebnahme"
                                            isDate
                                            dateFormat="dd.MM.yyyy"
                                        />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.landschaftsverband?.name}
                                            label="Verband"
                                        />
                                        <DetailsLayout.InfoField
                                            value={einrichtung.traeger?.name}
                                            label="Leistungsanbieter"
                                        />
                                        {einrichtung.mantelbogen ? (
                                            <DetailsLayout.DownloadLink
                                                label="Mantelbogen"
                                                value={
                                                    einrichtung.mantelbogen?.[0]?.generiertesDokument
                                                        ? [
                                                              einrichtung.mantelbogen[0]
                                                                  .generiertesDokument as DokumentJsonld,
                                                          ]
                                                        : null
                                                }
                                            />
                                        ) : null}
                                    </DetailsLayout.Group>
                                </Grid>

                                <Grid item xs={12}>
                                    {!hasFreigegebeneMeldung ? (
                                        <Alert severity="info" sx={{ width: '100%', marginBottom: theme.spacing(3) }}>
                                            Eine Regelprüfung kann erst nach erfolgter Meldung des Leistungsangebotes
                                            erfasst werden. Bitte fordern Sie das Leistungsangebot umgehend auf, eine
                                            Meldung abzugeben
                                        </Alert>
                                    ) : null}

                                    <ActionHeading
                                        variant="h2"
                                        title="Ergebnisse der letzten Regelprüfungen"
                                        margin={0}
                                    />

                                    {allowCreate ? (
                                        <FormLoadingButton
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={!hasFreigegebeneMeldung}
                                            onClick={() =>
                                                handleAction(
                                                    { id: Number(einrichtungId), einrichtung: '' },
                                                    ACTIONS.CREATE_REGELPRUEFUNG
                                                )
                                            }
                                            loading={isInProgress}
                                            endIcon={<Add />}
                                            loadingPosition="end"
                                        >
                                            Neue Regelprüfung erstellen
                                        </FormLoadingButton>
                                    ) : null}
                                </Grid>

                                <Grid item xs={12}>
                                    <DataTableHydraServerside
                                        getColumns={columns}
                                        getData={getData}
                                        serverDataChanged={serverDataChanged}
                                        tableCaption={`Tabelle mit Ergebnissen der letzten Regelprüfungen für die Einrichtung "${einrichtung.name}"`}
                                        initialOptions={{
                                            sortOrder: {
                                                name: 'eingereichtAt',
                                                direction: 'desc',
                                            },
                                            custom: {
                                                disableSearch: true,
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <ButtonGroupGrid
                                        backLink={{
                                            path: PathBuilder.home.verwaltung.regelpruefung.path,
                                            title: 'Zur Übersicht der Einrichtungen',
                                        }}
                                    />
                                </Grid>
                            </>
                        ) : null}

                        {errorLoadingEinrichtung ? (
                            <Alert>
                                Beim Laden der Einrichtung ist ein Fehler aufgetreten. Versuchen Sie diese Seite erneut
                                zu Laden oder wenden Sie sich an den Support, wenn das Problem weiterhin besteht.
                            </Alert>
                        ) : null}
                    </AppLoaderContainer>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const RegelpruefungOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_REGELPRUEFUNG
);
