import React from 'react';
import { styled } from '@mui/material';

interface RequiredFlagProps {
    content?: string;
    color?: string;
}

const Span = styled('span')(({ theme, color }) => ({
    color: color || theme.palette.error.main,
}));

export const RequiredFlag: React.FC<RequiredFlagProps> = ({ content = '\u00A0*', color }) => {
    return <Span color={color} dangerouslySetInnerHTML={{ __html: content }} />;
};
