import { RankedTester, rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

export const isLandschaftsverbandAutocomplete: Tester = (
    uischema: UISchemaElement | any,
    schema: JsonSchema
): boolean => {
    if (uischema?.type !== 'Control') return false;

    const field = getFieldSchemaByScope(uischema.scope, schema as Schema);

    return (field as any)?.custom?.block_prefixes?.includes('landschaftsverband_autocomplete');
};

export const LandschaftsverbandAutocompleteTester: RankedTester = rankWith(6600, isLandschaftsverbandAutocomplete);
