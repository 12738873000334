import React from 'react';
import { Box } from '@mui/material';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { AutocompleteFilter } from 'components/DataTable/filters/AutocompleteFilter';

export interface BenutzerOptions {
    [key: string]: string;
}

export const ZustaendigerBenutzerFilterOptions = (
    zustaendigerBenutzerFilterOptions: BenutzerOptions,
    fullWidth: boolean,
    filterOptions?: BenutzerOptions
): MUIDataTableColumnOptions => ({
    customFilterListOptions: {
        render: (value) =>
            `Zuständig: ${filterOptions ? filterOptions[value] : zustaendigerBenutzerFilterOptions[value]}`,
    },
    filterOptions: {
        fullWidth: fullWidth,
        names: Object.keys(filterOptions ? filterOptions : zustaendigerBenutzerFilterOptions),
        display: (filterList, onChange, index, column, filterData) => (
            <>
                <AutocompleteFilter
                    index={index}
                    column={column}
                    filterData={filterData[index]}
                    filterValue={filterList[index][0]}
                    filterLabels={zustaendigerBenutzerFilterOptions}
                    onChange={onChange}
                />
                <Box width={400} />
            </>
        ),
    },
    filterType: 'custom',
});
