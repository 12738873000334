import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { LeistungsangebotBearbeiten } from './components/LeistungsangebotBearbeiten';

const Index = () => {
    return <LeistungsangebotBearbeiten pathBuilder={PathBuilder.home.aufgaben.freigeben.leistungsangebote} />;
};

export const LeistungsangebotFreigebenPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEBEN
);
