import React, { JSXElementConstructor, useMemo } from 'react';
import { Edit, History, RemoveRedEye } from '@mui/icons-material';
import { Hidden, MenuItem, styled, Theme } from '@mui/material';

import { ActionDropdown } from 'forms/components/ActionDropdown';
import { DeleteActionMenuItem } from 'forms/components/DeleteAction';
import { CollectionItemAction } from 'forms/types/UiSchemaTypes';

interface FormTableActionsType {
    index: number;
    viewable?: boolean;
    editable?: boolean;
    showHistory?: boolean;
    deletable?: boolean;
    disabled?: boolean;
    actions?: CollectionItemAction[];
    onView?: (index: number) => void;
    onEdit?: (index: number) => void;
    onShowHistory?: (index: number) => void;
    onDelete?: (index: number) => void;
    onCustomAction?: (index: number, key: string) => void;
    deleteAlertText?: (index: number) => string;
    deleteConfirmationLabel?: (index: number) => string;
}

const actionIconStyles = (theme: Theme) => ({
    marginRight: theme.spacing(1),
    color: theme.palette.action.active,
});

const StyledEyeIcon = styled(RemoveRedEye)(({ theme }) => actionIconStyles(theme));
const StyledEditIcon = styled(Edit)(({ theme }) => actionIconStyles(theme));
const StyledHistoryIcon = styled(History)(({ theme }) => actionIconStyles(theme));
const CustomActionIcon = ({ icon }: { icon: JSXElementConstructor<any> }) => {
    const StyledComponent = styled(icon)(({ theme }) => actionIconStyles(theme));
    return <StyledComponent />;
};

export const FormTableActions: React.FC<FormTableActionsType> = ({
    viewable,
    editable,
    showHistory,
    deletable,
    disabled,
    actions,
    onView,
    onEdit,
    onShowHistory,
    onDelete,
    onCustomAction,
    index,
    deleteConfirmationLabel,
    deleteAlertText,
}) => {
    const shouldRenderActions = useMemo(
        () => [viewable, editable, actions, deletable].some((action) => action || false),
        [viewable, editable, actions, deletable]
    );

    if (!shouldRenderActions) return null;

    return (
        <ActionDropdown disabled={disabled}>
            {viewable && (
                <MenuItem onClick={() => onView?.(index)} disabled={disabled}>
                    <Hidden mdDown>
                        <StyledEyeIcon />
                    </Hidden>
                    Ansehen
                </MenuItem>
            )}

            {editable && (
                <MenuItem onClick={() => onEdit?.(index)} disabled={disabled}>
                    <Hidden mdDown>
                        <StyledEditIcon />
                    </Hidden>
                    Bearbeiten
                </MenuItem>
            )}

            {showHistory && (
                <MenuItem onClick={() => onShowHistory?.(index)} disabled={disabled}>
                    <Hidden mdDown>
                        <StyledHistoryIcon />
                    </Hidden>
                    Historie
                </MenuItem>
            )}

            {actions
                ? actions.map((action) =>
                      [true, undefined].includes(action.visible) ? (
                          <MenuItem
                              key={action.key}
                              onClick={() => onCustomAction?.(index, action.key)}
                              disabled={disabled}
                          >
                              {action?.icon ? <Hidden mdDown>{<CustomActionIcon icon={action.icon} />}</Hidden> : null}
                              {action.label}
                          </MenuItem>
                      ) : null
                  )
                : null}

            {deletable && (
                <DeleteActionMenuItem
                    denyLabel="Abbrechen"
                    confirmLabel={deleteConfirmationLabel?.(index)}
                    alertText={deleteAlertText?.(index)}
                    onDelete={() => onDelete?.(index)}
                    disabled={disabled}
                />
            )}
        </ActionDropdown>
    );
};
