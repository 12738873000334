import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { femAntragApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { femAntragTabConfig } from 'forms/FemAntrag/femAntragTabConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AntragPage } from 'pages/AntragPage';

const Index = () => {
    const { einrichtungId } = useParams();
    const { data, isLoading } = useApiEinrichtungGet(String(einrichtungId));

    const title = useMemo(() => {
        if (!isLoading && data) {
            return `Freiheitsentziehende Maßnahmen: ${data.name}`;
        }

        return 'Freiheitsentziehende Maßnahmen: Bearbeiten';
    }, [isLoading, data]);

    return (
        <AppLoaderContainer isLoading={isLoading && !data}>
            {data ? (
                <AntragPage
                    antragType={AntragType.FEM}
                    title={title}
                    headline={title}
                    pathBuilder={PathBuilder.home.verwaltung.femAntrag}
                    tabConfig={femAntragTabConfig}
                    workflowFormItem={backendApiService.workflowFemItem.bind(backendApiService)}
                    api={femAntragApi}
                    validationMode="ValidateAndShow"
                />
            ) : null}
        </AppLoaderContainer>
    );
};

export const FemAntragBearbeitenPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_FEM_MELDER_EDIT
);
