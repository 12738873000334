import React, { useMemo } from 'react';
import { LiveMessage } from 'react-aria-live';
import { styled } from '@mui/material';
import MUIDataTable, {
    MUIDataTableColumn,
    MUIDataTableColumnDef,
    MUIDataTableOptions,
    MUIDataTableTextLabels,
} from 'mui-datatables';

export interface DataTableHydraMessages {
    loading: string;
    success: string;
    failure: string;
    noItems: string;
}

export interface DataTableHydraProps {
    data: any;
    columns: MUIDataTableColumnDef[];
    options: MUIDataTableOptions;
    dataTableHydraMessages?: DataTableHydraMessages;
    title?: string;
    isLoading?: boolean;
    tableRef?: React.RefObject<DataTableController | null | undefined>;
}

export interface DataTableController {
    resetFilters: () => void;
}

const StyledDataTable = styled(MUIDataTable)(
    () => `
    & .MuiTableRow-root:nth-of-type(even):hover {
        background: rgba(0, 0, 0, 0.04);
    }
    & .MuiTableFooter-root .MuiTableRow-root {
        background: #fff;
    }
    & .MuiTableBody-root .MuiTableRow-root {
        height: 80px;
    }

    &.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation4 { overflow-x: auto; }
`
);

const dataTableHydraDefaultMessages = {
    loading: 'Tabelle: Daten werden geladen.',
    success: 'Tabelle wurde geladen',
    failure:
        'Tabelle: Beim Laden der Daten ist ein Fehler aufgetreten. Laden Sie diese Seite erneut oder wenden Sie sich an den Support.',
    noItems: 'Tabelle: Es wurden keine Daten gefunden.',
};

export const DataTableHydra = ({
    isLoading,
    data,
    columns,
    title,
    options,
    dataTableHydraMessages,
    tableRef,
}: DataTableHydraProps) => {
    const textLabels: Partial<MUIDataTableTextLabels> = {
        body: {
            noMatch: isLoading ? 'Daten werden geladen...' : 'Keine Daten gefunden.',
            toolTip: 'Sortieren',
            columnHeaderTooltip: (column: MUIDataTableColumn) => `Sortierung nach ${column.label}`,
        },
        pagination: {
            next: 'Nächste Seite',
            previous: 'Vorherige Seite',
            rowsPerPage: 'Zeilen pro Seite:',
            jumpToPage: 'Zur Seite:',
            displayRows: 'von',
        },
        toolbar: {
            search: 'Suche',
            downloadCsv: 'Download CSV',
            print: 'Drucken',
            viewColumns: 'Spalten anzeigen',
            filterTable: 'Filter Tabelle',
        },
        filter: {
            all: 'Alle',
            title: 'FILTER',
            reset: 'ZURÜCKSETZEN',
        },
        viewColumns: {
            title: 'Spalten anzeigen',
            titleAria: 'Spalten anzeigen/verbergen',
        },
        selectedRows: {
            text: 'Zeile(n) selektiert',
            delete: 'Löschen',
            deleteAria: 'Ausgewählte Zeile(n) löschen',
        },
    };

    const tableStateMessage = useMemo(
        () => ({
            ...dataTableHydraDefaultMessages,
            ...dataTableHydraMessages,
        }),
        [dataTableHydraMessages]
    );

    const stateMessage: string = useMemo(() => {
        if (isLoading) {
            return tableStateMessage.loading;
        }

        if (!isLoading && !data['hydra:member'].length) {
            return tableStateMessage.noItems;
        }

        return `${tableStateMessage.success}: ${data['hydra:totalItems']} ${
            data['hydra:totalItems'] === 1 ? 'Eintrag' : 'Einträge'
        }  vorhanden.`;
    }, [tableStateMessage, isLoading, data]);

    return (
        <>
            <LiveMessage aria-live="assertive" message={stateMessage} />

            <StyledDataTable
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Typings of mui-datatable are wrong!
                ref={tableRef}
                columns={columns}
                data={(!isLoading && data && data['hydra:member']) || []}
                title={title}
                options={{
                    jumpToPage: true,
                    selectableRows: 'none',
                    responsive: 'standard',
                    count: (data && data['hydra:totalItems']) || 0,
                    rowsPerPageOptions: [10, 25, 50, 100],
                    sortFilterList: false,
                    textLabels,
                    ...options,
                }}
            />
        </>
    );
};
