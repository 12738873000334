import React, { ReactElement, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { Property } from 'csstype';
import { v4 as uuidv4 } from 'uuid';

interface BaseContentGridProps {
    children: ReactNode;
    justifyContent?: Property.JustifyContent;
    marginBotton?: string;
    xs?: number;
    lg?: number;
}

export const BaseGridContainer = ({ children, xs, lg, justifyContent, marginBotton }: BaseContentGridProps) => {
    const childrenArray = React.Children.toArray(children);

    const childrenList = React.useMemo<Array<ReactElement>>(
        () =>
            childrenArray.map((child) =>
                React.isValidElement(child) ? React.cloneElement(child, { key: child.key || uuidv4() }) : child
            ) as Array<ReactElement>,
        [childrenArray]
    );

    return (
        <Grid container spacing={3} justifyContent={justifyContent || 'flex-start'} marginBottom={marginBotton || 0}>
            {childrenList.map((child) => (
                <Grid item xs={xs || 12} lg={lg || 12} key={child.key}>
                    {child}
                </Grid>
            ))}
        </Grid>
    );
};
