import { ReactElement, ReactNode } from 'react';
import { Container, ContainerProps, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { useDocumentTitle } from 'utilities/hooks/useDocumentTitle';

interface StyledContainerProps extends ContainerProps {
    sx?: { paddingTop?: number | string };
}

type ContentContainerProps = {
    children: ReactNode;
    title?: string;
    sx?: SxProps<Theme>;
} & StyledContainerProps;

export const ContentContainer = ({ title, sx, children }: ContentContainerProps): ReactElement => {
    useDocumentTitle(title);

    return (
        <StyledContainer sx={sx} maxWidth={false}>
            {children}
        </StyledContainer>
    );
};

const StyledContainer = styled(Container)<StyledContainerProps>(({ sx }) => {
    const paddingTop = sx?.paddingTop ?? 36;

    return {
        position: 'relative',
        paddingTop,
        '& h1': {
            marginBottom: '2rem',
        },
        '.ContentContainer--loggedIn &': {
            marginLeft: 0,
            marginRight: 0,
        },
        maxWidth: `calc(100% - 36px)`,
    };
});
