import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { EmailAntrag } from 'api/client';
import { URL_EINRICHTUNG } from 'api/routes';
import { ACTIONS } from 'constants/antragActions';
import { ArtViewSegments } from 'constants/artViewSegments';
import { useServerError } from 'forms/hooks/useServerError';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useFormState } from 'forms/state/useFormState';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { PathBuilder } from 'navigation/Paths';
import { determineAntragStatusAlertText } from 'utilities/antragStatusAenderung';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';
import { getIriId } from 'utilities/IriUtils';

type UseAntragListActionsResult<T> = {
    serverDataChanged: number;
    setServerDataChanged: Dispatch<SetStateAction<number>>;
    handleAction: AntragListAction<T>;
};

type GetWorkflowAntragItem<T> = (id?: number, action?: string) => Promise<T> | void;

// ToDo Refactoring der Typings: Verallgemeinern (ohne "Antrag")
export type AntragListActionItem = {
    id: number;
    status?: string;
    allowedActions?: string[];
    allowedWorkflowActions?: string[];
    einrichtung: string;
    einrichtungId?: number;
    art?: string;
    read?: boolean;
};

export type AntragPathBuilder = {
    path?: string;
    view?: ({ id, segment, step }: { id: number; segment?: string; step?: string }) => string;
    edit?: ({ id, segment, token, step }: { id: number; segment?: string; token?: string; step?: string }) => string;
    antrag?: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) => string;
    antragView?: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) => string;
    aufforderung?: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) => string;
    deletionReason?: ({ id }: { id?: string | number }) => string;
    einrichtungen?: ({ id }: { id: number }) => string;
    nachrichten?: ({ einrichtungId }: { einrichtungId: number }) => string;
    treffer?: ({ id }: { id: number }) => string;
    zugriffsrechte?: ({ einrichtungId }: { einrichtungId: number }) => string;
    zuordnung?: ({ id }: { id: number }) => string;
};

export type AntragListAction<T> = (antrag: T, action: string, segment?: string) => void;

export const determinePathBuilderViewSegment = (antragArt: ArtViewSegments) => {
    const segments: Record<ArtViewSegments, string> = {
        [ArtViewSegments.LEISTUNGSANGEBOT]: 'leistungsangebot',
        [ArtViewSegments.LEISTUNGSANBIETER]: 'leistungsanbieter',
        [ArtViewSegments.WOHNGEMEINSCHAFT]: 'wohngemeinschaft',
    };

    return segments?.[antragArt?.toUpperCase() as ArtViewSegments];
};

export const determineZustaendigkeitMessage = (action: ACTIONS) => {
    return action === ACTIONS.ZUSTAENDIGKEIT ? 'Sie sind nun zuständig.' : 'Sie haben die Zuständigkeit abgegeben.';
};

export const useListActions = <T extends AntragListActionItem, S>(
    pathBuilder: AntragPathBuilder,
    workflowAntragItem?: GetWorkflowAntragItem<S>,
    segment?: string
): UseAntragListActionsResult<T> => {
    const navigate = useNavigate();
    const { einrichtungId } = useParams();

    const { setRequestMessage, progressStart, progressEnd, setAntragId } = useFormState();
    const serverError = useServerError();
    const { showLoading, hideLoading } = useLoadingActions();
    const [serverDataChanged, setServerDataChanged] = useState(0);
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();
    const { loadBenutzerAufgaben } = useBenutzerAufgaben();

    const handleAction: AntragListAction<T> = useCallback(
        (antrag, action) => {
            const eId = Number(
                (antrag.einrichtung?.includes?.(URL_EINRICHTUNG) && getIriId(antrag.einrichtung)) ||
                    antrag.einrichtungId ||
                    einrichtungId
            );

            const handleView = () => {
                if (pathBuilder.antragView && eId) {
                    return navigate(pathBuilder.antragView({ einrichtungId: eId, antragId: antrag.id }));
                }

                navigate(
                    pathBuilder.view?.({
                        id: antrag.id,
                        segment: determinePathBuilderViewSegment(antrag.art as ArtViewSegments),
                    }) || ''
                );
            };
            const handleEdit = () => {
                if (pathBuilder.antrag && eId) {
                    return navigate(
                        pathBuilder.antrag?.({
                            einrichtungId: eId,
                            antragId: antrag.id,
                        }) || ''
                    );
                }

                navigate(
                    pathBuilder.edit?.({
                        id: antrag.id,
                        segment: segment || determinePathBuilderViewSegment(antrag.art as ArtViewSegments),
                    }) || ''
                );
            };

            const handleZugehoerigeEinrichtungen = () => {
                navigate(pathBuilder.einrichtungen?.({ id: antrag.id }) || '');
            };

            const handleManuelleZuordnung = () => {
                navigate(pathBuilder.zuordnung?.({ id: antrag.id }) || '');
            };

            const handleTrefferZuordnung = () => {
                navigate(pathBuilder.treffer?.({ id: antrag.id }) || '');
            };

            const handleLeistungsangebotErstellen = () => {
                backendApiService
                    .workflowWohngemeinschaftItem(antrag.id, action)
                    ?.then(({ id }) => {
                        if (!id) {
                            return;
                        }

                        navigate(pathBuilder.antrag?.({ einrichtungId: antrag.id, antragId: id }) || '');
                    })
                    .catch(() => {
                        setRequestMessage(
                            errorMessage('Es konnte kein Antrag für ein Leistungsangebot erstellt werden.')
                        );
                    });
            };

            const handleAntragView = () => {
                navigate(pathBuilder.antragView?.({ einrichtungId: eId, antragId: antrag.id }) || '');
            };

            const handleNachrichten = () => {
                navigate(pathBuilder.nachrichten?.({ einrichtungId: antrag.id }) || '');
            };

            const handleZugriffsrechteView = () => {
                navigate(pathBuilder.zugriffsrechte?.({ einrichtungId: Number(antrag.id) }) || '');
            };

            const alertText = determineAntragStatusAlertText(antrag.status, action);
            const confirmLabel = 'Fortfahren';
            const denyLabel = 'Abbrechen';

            const handleWorkflowWarning = (alertText: string) => {
                return showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    severity: 'warning',
                    confirmAction: async () => {
                        closeConfirmation();
                        showLoading();
                        await workflowAntragItem?.(antrag.id, action);
                        hideLoading();
                        setServerDataChanged((prevState) => prevState + 1);
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleDeleteZugriffsrechte = () => {
                return showConfirmation({
                    alertText: 'Die Zugriffsrechte des Benutzers werden entzogen? Möchten Sie fortfahren?',
                    confirmLabel,
                    denyLabel,
                    severity: 'warning',
                    confirmAction: () => {
                        closeConfirmation();
                        showLoading();
                        backendApiService
                            .deleteZugriffsrechte({
                                action: 'DELETE',
                                einrichtung: eId,
                                benutzer: Number(antrag.id),
                            })
                            .then(() => {
                                setRequestMessage(successMessage('Der Benutzer wurde entfernt.'));
                                setServerDataChanged((prevState) => prevState + 1);
                            })
                            .catch(() => setRequestMessage(errorMessage('Der Benutzer konnte nicht entfernt werden.')));
                        hideLoading();
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleDeleteEinrichtungWorkflow = (alertText: string) => {
                return showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    severity: 'warning',
                    confirmAction: async () => {
                        closeConfirmation();
                        navigate(pathBuilder.deletionReason?.({ id: antrag.id }) || '');
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleWorkflowChange = () => {
                showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    confirmAction: async () => {
                        closeConfirmation();
                        showLoading();
                        await workflowAntragItem?.(antrag.id, action);
                        hideLoading();
                        navigate(pathBuilder.edit?.({ id: antrag.id }) || '');
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleZurKenntnisNehmen = () => {
                showConfirmation({
                    alertText,
                    confirmLabel,
                    denyLabel,
                    confirmAction: () => {
                        closeConfirmation();
                        showLoading();
                        workflowAntragItem?.(antrag.id, action)
                            ?.then(() => {
                                setServerDataChanged((prevState) => prevState + 1);
                            })
                            ?.catch((error) => setRequestMessage(errorMessage(serverError(error))));
                        hideLoading();
                    },
                    denyAction: closeConfirmation,
                });
            };

            const handleInvestRegistrationRetry = () => {
                backendApiService
                    .postInvestRegistrationRetry(String(antrag.id), antrag)
                    .then(() => {
                        setRequestMessage(successMessage('Das Leistungsangebot wurde zu PfAD.invest übertragen'));
                        setServerDataChanged((prevState) => prevState + 1);
                    })
                    .catch(() =>
                        setRequestMessage(
                            errorMessage(
                                'Das Leistungsangebot konnte nicht zu PfAD.invest übertragen werden. Es ist ein Systemfehler aufgetreten. Bitte wenden Sie sich an den Support.'
                            )
                        )
                    );
            };

            const handleInvestSignup = () => {
                navigate(
                    PathBuilder.home.verwaltung.leistungsangebote.anbieter.freigegebene.investRegistrationSignUp({
                        id: antrag.id,
                    })
                );
            };

            const handleAufforderung = () => {
                showLoading();
                workflowAntragItem?.(antrag.id, action)
                    ?.then((response) => {
                        const emailAntrag = response as EmailAntrag;
                        navigate(
                            pathBuilder.aufforderung?.({
                                einrichtungId: Number(eId || antrag.id),
                                antragId: Number(emailAntrag.id),
                            }) || ''
                        );
                    })
                    .finally(() => hideLoading());
            };

            const handleZustaendigkeit = () => {
                showLoading();
                workflowAntragItem?.(eId || antrag.id, action)
                    ?.then(() => {
                        setRequestMessage(successMessage(determineZustaendigkeitMessage(action as ACTIONS)));
                        setServerDataChanged((prevState) => prevState + 1);
                    })
                    .finally(() => hideLoading());
            };

            const handleMeldungCreate = () => {
                showLoading();

                workflowAntragItem?.(eId || antrag.id, action)
                    // @ts-ignore
                    ?.then(({ id }) => navigate(PathBuilder.home.verwaltung.meldung.edit({ id, step: 'hinweise' })))
                    .finally(() => hideLoading());
            };

            const handleRegelpruefungCreate = (einrichtungId?: string) => {
                progressStart();

                backendApiService
                    .createRegelpruefung(Number(einrichtungId || eId || antrag.id))
                    .then(({ '@id': iriId }) => {
                        const antragId = getIriId(iriId);

                        navigate(
                            PathBuilder.home.verwaltung.regelpruefung.antrag({
                                einrichtungId: Number(einrichtungId || antrag.id),
                                antragId: Number(antragId),
                            })
                        );
                    })
                    .finally(() => progressEnd());
            };

            const handleReadStatus = () => {
                progressStart();
                setAntragId(antrag.id);

                backendApiService
                    .workflowEmailAntrag(Number(antrag.id), !!antrag?.read ? ACTIONS.UNGELESEN : ACTIONS.GELESEN)
                    ?.then(() => {
                        loadBenutzerAufgaben();
                        setServerDataChanged((prevState) => prevState + 1);
                    })
                    ?.catch(() => setRequestMessage(errorMessage('Fehler beim ändern des Status')))
                    ?.finally(() => {
                        progressEnd();
                        setAntragId(undefined);
                    });
            };

            switch (action) {
                case ACTIONS.VIEW:
                    handleView();
                    break;
                case ACTIONS.EDIT:
                    handleEdit();
                    break;
                case ACTIONS.ZUGEHOERIGE_EINRICHTUNGEN:
                    handleZugehoerigeEinrichtungen();
                    break;
                case ACTIONS.MANUELLE_ZUORDNUNG:
                    handleManuelleZuordnung();
                    break;
                case ACTIONS.TREFFER_ZUORDNUNG:
                    handleTrefferZuordnung();
                    break;
                case ACTIONS.LEISTUNGSANGEBOT_ERSTELLEN:
                    handleLeistungsangebotErstellen();
                    break;
                case ACTIONS.ZUGRIFFSRECHTE:
                    handleZugriffsrechteView();
                    break;
                case ACTIONS.ZUGRIFFSRECHTE_ENTFERNEN:
                    handleDeleteZugriffsrechte();
                    break;
                case ACTIONS.ANTRAG_VIEW:
                    handleAntragView();
                    break;
                case ACTIONS.NACHRICHTEN:
                    handleNachrichten();
                    break;
                case ACTIONS.ABBRECHEN:
                    handleWorkflowWarning(
                        'Möchten Sie die Prüfung abbrechen? Diese Aktion kann nicht rückgängig gemacht werden'
                    );
                    break;
                case ACTIONS.ZUR_KENNTNIS_NEHMEN:
                    handleZurKenntnisNehmen();
                    break;
                case ACTIONS.DELETE:
                    handleWorkflowWarning(
                        'Möchten Sie mit dem Löschen fortfahren? Diese Aktion kann nicht rückgängig gemacht werden'
                    );
                    break;
                case ACTIONS.DELETE_EINRICHTUNG:
                    handleDeleteEinrichtungWorkflow('Möchten Sie mit dem Löschen der Einrichtung fortfahren?');
                    break;
                case ACTIONS.BENUTZER_DEACTIVATE:
                case ACTIONS.BENUTZER_ACTIVATE:
                    const actionLabel = action === ACTIONS.BENUTZER_ACTIVATE ? 'aktiviert' : 'deaktiviert';
                    handleWorkflowWarning(`Der Benutzer wird ${actionLabel}. Möchten Sie fortfahren?`);
                    break;
                case ACTIONS.EINREICHEN:
                    handleZurKenntnisNehmen();
                    break;
                case ACTIONS.RETRY:
                    handleInvestRegistrationRetry();
                    break;
                case ACTIONS.INVESTANMELDUNG:
                    handleInvestSignup();
                    break;
                case ACTIONS.AUFFORDERN:
                    handleAufforderung();
                    break;
                case ACTIONS.BEARBEITUNG_STARTEN:
                case ACTIONS.BESCHEID_AENDERN:
                case ACTIONS.PRUEFEN:
                    handleWorkflowChange();
                    break;
                case ACTIONS.ZUSTAENDIGKEIT_LOESCHEN:
                case ACTIONS.ZUSTAENDIGKEIT:
                    handleZustaendigkeit();
                    break;
                case ACTIONS.CREATE_MELDUNG:
                    handleMeldungCreate();
                    break;
                case ACTIONS.CREATE_REGELPRUEFUNG:
                    handleRegelpruefungCreate();
                    break;
                case ACTIONS.GELESEN:
                case ACTIONS.UNGELESEN:
                    handleReadStatus();
                    break;
            }
        },
        [
            einrichtungId,
            pathBuilder,
            navigate,
            segment,
            setRequestMessage,
            showConfirmation,
            closeConfirmation,
            showLoading,
            workflowAntragItem,
            hideLoading,
            serverError,
            progressStart,
            progressEnd,
            setAntragId,
            loadBenutzerAufgaben,
        ]
    );

    return {
        serverDataChanged,
        setServerDataChanged,
        handleAction,
    };
};
