import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilderProps } from 'navigation/Paths';

interface UseAbwListActionsProps {
    pathBuilder: PathBuilderProps;
}

export const useProfilHeimfinderListActions = ({ pathBuilder }: UseAbwListActionsProps) => {
    return useListActions(pathBuilder);
};
