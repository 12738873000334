import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';

export const CreateEditPage = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    const { einrichtungId } = useParams();
    const navigate = useNavigate();

    const { setRequestMessage } = useFormState();

    useEffect(() => {
        backendApiService
            .editLeistungsangebotItem(einrichtungId)
            .then((antrag) => {
                navigate(
                    String(
                        pathBuilder?.antrag?.({
                            einrichtungId: Number(einrichtungId),
                            antragId: Number(antrag.id),
                        })
                    )
                );
            })
            .catch(() => {
                setRequestMessage(errorMessage('Fehler beim erstellen des Bearbeitungsformular'));
                navigate(String(pathBuilder.path));
            });
    }, [einrichtungId, navigate, pathBuilder, setRequestMessage]);

    return (
        <ContentContainer>
            <StyledContainer>
                <AppLoaderContainer isLoading={true} />
            </StyledContainer>
        </ContentContainer>
    );
};
