import React from 'react';
import { useParams } from 'react-router-dom';

import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { EmailAntragForm } from 'pages/EmailAntragForm';

const Index = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    const { einrichtungId } = useParams();

    return (
        <EmailAntragForm
            antragType={AntragType.EMAIL_ANTRAG}
            pathBuilder={pathBuilder}
            cancelPath={String(
                pathBuilder?.nachrichten?.({
                    einrichtungId: Number(einrichtungId),
                })
            )}
        />
    );
};

export const NachrichtCreatePage: React.FC<{ pathBuilder: PathBuilderProps }> = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG
);
