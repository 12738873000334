import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';

import { MeldungProps } from './MeldungDetailPage';

export const Standorte = ({ meldung }: MeldungProps) => {
    return (
        <>
            {meldung.data?.stepStandortStandorte?.length ? (
                <DetailsLayout.Group title="Standorte und Plätze" variant="h2">
                    <DetailsLayout.SimpleTable
                        columns={[
                            {
                                label: 'Hauptstandort',
                                name: 'hauptstandort',
                                isJaNein: true,
                                width: '50%',
                            },
                            { label: 'Adresse', name: 'label' },
                            { label: 'Gesamtplatzanzahl', name: 'gesamtPlatzzahl' },
                            { label: 'Davon Plätze in Einzelzimmer', name: 'einzelzimmerPlaetze' },
                            { label: 'Davon Plätze in Doppelzimmer', name: 'doppelzimmerPlaetze' },
                            {
                                label: 'Davon eingestreute Kurzzeitpflegeplätze',
                                name: 'eingestreuteKurzzeitpflegeplaetze',
                            },
                            { label: 'Davon separate Kurzzeitpflegeplätze', name: 'separateKurzzeitpflegeplaetze' },
                        ]}
                        data={meldung.data.stepStandortStandorte}
                        vertical
                    />
                </DetailsLayout.Group>
            ) : null}

            <DetailsLayout.Group title="Plätze" variant="h2">
                <DetailsLayout.SimpleTable
                    columns={[
                        {
                            label: meldung.labelReplacements?.['[stepStandorteGesamtanzahlLabel]'],
                            name: 'stepStandortGesamtPlatzzahl',
                            width: '50%',
                        },
                        {
                            label: 'davon eingestreute Kurzzeitpflegeplätze',
                            name: 'stepStandortEingestreuteKurzzeitpflegeplaetze',
                        },
                        {
                            label: 'davon separate Kurzzeitpflegeplätze',
                            name: 'stepStandortSeparateKurzzeitpflegeplaetze',
                        },
                    ]}
                    data={meldung?.data ? [meldung.data] : null}
                    vertical
                />
            </DetailsLayout.Group>
        </>
    );
};
