import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { MeldungHinweistextListResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';

export const useMeldungHinweistextColumns = (
    handleAction: AntragListAction<any>
): ((data: MeldungHinweistextListResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'ueberschrift',
                    label: 'Dateiname/Überschrift',
                    options: {
                        sort: false,
                    },
                },
                {
                    name: 'einrichtungsart',
                    label: 'Art des Leistungsangebots',
                    options: {
                        sort: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = data['hydra:member']?.[dataIndex];
                            return rowData?.einrichtungsart?.length
                                ? rowData.einrichtungsart.join(', ')
                                : 'Keine Einschränkung';
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
