import { useCallback } from 'react';
import { Alert, Typography } from '@mui/material';

import { EinrichtungTypes } from 'constants/einrichtungTypes';

export const useDokumenteDescription = () => {
    return useCallback((einrichtungArt?: string) => {
        switch (einrichtungArt) {
            case EinrichtungTypes.WERKSTATT:
                return (
                    <Typography paragraph>
                        Bitte laden Sie hier Bescheide (z.B. Anerkennungsbescheid der Anerkennungsbehörden nach § 225
                        SGB IX) und Verträge (z.B. Muster der mit den Werkstattbeschäftigten abgeschlossenen
                        Werkstattverträge) hoch.
                    </Typography>
                );
            case EinrichtungTypes.ASSISTED_LIVING:
                return (
                    <Typography paragraph>
                        Hier haben Sie die Möglichkeit, weitere Unterlagen, die Sie der WTG-Behörde übermitteln möchten,
                        die Sie aber keiner der vorgenannten Kategorien zuordnen konnten, hochzuladen.
                    </Typography>
                );
            default:
                return (
                    <>
                        <Typography paragraph>
                            Hier haben Sie die Möglichkeit, weitere Unterlagen, die Sie der WTG-Behörde übermitteln
                            möchten, die Sie aber keiner der vorgenannten Kategorien zuordnen konnten, hochzuladen.
                        </Typography>

                        <Alert severity="info" style={{ marginBottom: 36 }}>
                            Bitte laden Sie hier aus datenschutzrechtlichen Gründen keine personenbezogenen Dokumente
                            wie Zeugnisse, Zertifikate oder sonstige Nachweise hoch. Das Hochladen von Dokumenten zum
                            Nachweis der fachlichen Eignung von Beschäftigten ist unter dem Reiter "Beschäftigte"
                            vorzunehmen.
                        </Alert>
                    </>
                );
        }
    }, []);
};
