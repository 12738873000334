import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { BenutzerEinrichtungenListResponse } from 'api/types';
import { getDate } from 'components/DataTable/tableUtils';

export const useBenutzerEinrichtungColumns = (): ((
    data: BenutzerEinrichtungenListResponse | undefined
) => MUIDataTableColumnDef[]) => {
    return useCallback((data) => {
        if (!data) return [];

        return [
            {
                name: 'nrwSchluessel',
                label: 'NRW-Schlüssel',
                options: {
                    sort: false,
                },
            },
            {
                name: 'name',
                label: 'Name des Leistungsangebots',
                options: {
                    sort: false,
                },
            },
            {
                name: 'adresse',
                label: 'Adresse',
                options: {
                    sort: false,
                },
            },
            {
                name: 'erstinbetriebnahme',
                label: 'Datum der Erstinbetriebnahme',
                options: {
                    sort: false,
                    customBodyRender: getDate,
                },
            },
        ];
    }, []);
};
