import { JsonSchema4, JsonSchema7 } from '@jsonforms/core';

import { GroupListDataType, GroupType } from 'forms/controls/GroupList/GroupList';
import { GroupListType, Schema } from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaByUUID } from 'forms/utilities/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export const useGroupListData = (data: any, schema: JsonSchema4 | JsonSchema7, uischema: GroupListType) => {
    const groupKey = getFieldFromScope(uischema.groupByScope);

    const collectionScopeData = uischema.scopes.flatMap((scope: string) => {
        const fieldName = getFieldFromScope(scope);

        return (data[fieldName] || []).map(
            (d: any, index: number) =>
                ({
                    ...d,
                    path: buildPath(uischema.parentPath, `${fieldName}.${index}`),
                    schema: determineSchemaByUUID(`i_${d.uuid}`, schema.properties![fieldName].properties as Schema)[
                        `i_${d.uuid}`
                    ],
                    data: d,
                } as GroupType)
        );
    });

    const groupOptions = collectionScopeData.map((d: any) => d[groupKey]);
    const uniqueGroupOptions = Array.from(new Set(groupOptions));
    const groups = uniqueGroupOptions.map(
        (option) =>
            ({
                title: uischema.groupLabel?.replace('{value}', option) || option,
                key: option,
                controls: [],
            } as GroupListDataType)
    );

    collectionScopeData.forEach((item: GroupType) => {
        return groups.find((g: GroupListDataType) => g.key === item[groupKey])!.controls.push(item);
    });

    return groups;
};
