import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Clear, Search } from '@mui/icons-material';
import { IconButton, styled, TextField, useTheme } from '@mui/material';

type EnsureTargetValue = { target: { value: string } };

export const CustomHydraSearch = ({
    searchText,
    handleSearch,
    options,
}: {
    searchText: string;
    handleSearch: (text: string) => void;
    options: any;
}) => {
    const timer = useRef<NodeJS.Timeout | undefined>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [text, setText] = useState<string | undefined>(searchText);

    const theme = useTheme();

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleInputChange = (event: FormEvent<HTMLInputElement> & EnsureTargetValue) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            handleSearch(event.target.value);
        }, 750);
    };

    const handleClearText = () => {
        setText('');
        handleSearch('');
        inputRef.current?.focus();
    };

    useEffect(() => {
        setText(searchText);
    }, [searchText]);

    return (
        <StyledSearch>
            <StyledTextField
                inputRef={inputRef}
                label="Suche"
                placeholder={options?.searchPlaceholder ?? ''}
                InputProps={{
                    startAdornment: <Search sx={{ fill: theme.palette.text.secondary }} />,
                    endAdornment: (
                        <>
                            {text && text.length > 0 ? (
                                <IconButton aria-label="Suche zurücksetzen" onClick={handleClearText}>
                                    <Clear />
                                </IconButton>
                            ) : null}
                        </>
                    ),
                }}
                value={text || ''}
                onChange={handleTextChange}
                onInput={handleInputChange}
                fullWidth
                variant="outlined"
            />
        </StyledSearch>
    );
};

const StyledSearch = styled('search')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flex: '1 0 auto',
    flexShrink: 0,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(() => ({
    flex: '1 0',
}));
