import * as React from 'react';
import { useMemo } from 'react';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';

import { GanzzahlFormat } from 'forms/components/NumberFormats';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { useStartAdornment } from 'forms/hooks/useStartAdornment';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { useFormState } from 'forms/state/useFormState';
import { formatFieldValue } from 'forms/utilities/SchemaUtils';
import { stringToNumber, THOUSAND_SEPARATOR } from 'utilities/Utils';

import { FlexDiv } from './components/FlexDiv';
import { ReadonlyValueText } from './components/ReadonlyValueText';
import { RowControl } from './RowControl';

const GanzzahlControlComponent: React.FC<CustomControlProps<number>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    unit,
    schema,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    uischema,
    id,
}) => {
    const { formSubmitted } = useFormState();
    const startAdornment = useStartAdornment(uischema, schema);

    const validateOnChange = useMemo(() => uischema.options?.validateOnChange === true, [uischema]);

    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        (e) => stringToNumber(e.target.value.replaceAll(THOUSAND_SEPARATOR, '')),
        onTouched,
        {
            validateOnBlur: false,
            validateOnChange: (formSubmitted && hasErrors) || validateOnChange,
        }
    );

    const loadingStyle = disabled
        ? {
              color: 'rgba(0, 0, 0, 0.38)',
              cursor: 'default',
              outline: 0,
          }
        : {};

    const labelVisuallyHidden = useMemo(() => uischema.labelVisuallyHidden ?? true, [uischema]);
    const isReadonly = useMemo(
        () => uischema.options?.readonly || disabled || readonly,
        [disabled, readonly, uischema]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            labelForId={id}
            labelVisuallyHidden={labelVisuallyHidden}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            hasErrors={hasErrors}
                            errors={errors}
                            text={formatFieldValue(data, schema, undefined, unit)}
                            label={label}
                            textAlign={'left'}
                        />
                    ) : (
                        <FormControl fullWidth>
                            <TextField
                                id={id}
                                value={tempData}
                                onChange={(event) => {
                                    handleOnBlur(event);
                                    handleValueChange(event);
                                }}
                                onBlur={handleOnBlur}
                                required={required}
                                fullWidth
                                aria-readonly={isReadonly}
                                disabled={isReadonly}
                                error={hasErrors}
                                InputProps={{
                                    inputComponent: GanzzahlFormat as any,
                                    startAdornment,
                                    endAdornment: (
                                        <>
                                            {unit ? <InputAdornment position="end">{unit}</InputAdornment> : null}
                                            {uischema?.endAdornment && !unit ? (
                                                <InputAdornment position="end">{uischema?.endAdornment}</InputAdornment>
                                            ) : null}
                                        </>
                                    ),
                                }}
                                inputProps={{
                                    readOnly: isReadonly,
                                    disabled: disabled || isReadonly,
                                    style: {
                                        textAlign: 'right',
                                        cursor: isReadonly ? 'not-allowed' : 'text',
                                        ...loadingStyle,
                                    },
                                    min: schema.custom?.min,
                                    max: schema.custom?.max,
                                    'data-cy': `form_${path}`,
                                }}
                                placeholder={labelVisuallyHidden ? '' : label}
                                label={labelVisuallyHidden ? label : ''}
                            />
                            {!readonly && !uischema?.withInfoIcon && schema.description ? (
                                <FormHelperText>{schema.description}</FormHelperText>
                            ) : null}

                            {!readonly && hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const GanzzahlControl = withCustomControlProps(GanzzahlControlComponent);
