import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { authConfig, authLog } from 'api/auth/AuthTypes';
import { useIsLoggedIn } from 'api/auth/useAuthStateHelpers';
import { useLogout, useLogoutType } from 'api/auth/useLogout';
import { usePageTimeout } from 'api/auth/usePageTimeout';
import { useMessageActions } from 'forms/state/useMessages';
import { successMessage, warningMessage } from 'forms/utilities/MessageUtils';
import { PathBuilder } from 'navigation/Paths';

/**
 * Hook to handle the automatic page expiration.
 */
export const useSessionExpiration = () => {
    const { secondsLeft, reset } = usePageTimeoutHandler();
    useAutomaticSessionExpiration(secondsLeft);
    useSessionExpirationWarning(secondsLeft, reset);
};

type UsePageTimeoutResult = { secondsLeft: number | null; reset: () => void };
/**
 * Hook to calculate and handle the page timeout.
 */
export const usePageTimeoutHandler = (): UsePageTimeoutResult => {
    const { pathname } = useLocation();
    const isLoggedIn = useIsLoggedIn();
    const interval = useRef<number | NodeJS.Timeout | null>(null);
    const { pageTimeout, resetPageTimeout } = usePageTimeout();
    const [secondsLeft, setSecondsLeft] = useState<number | null>(null);

    /**
     * Runs every time a navigation happens to reset the page timeout.
     */
    useEffect(() => {
        authLog('[UsePageTimeout.ResetPageTimeout]');
        if (interval.current) clearInterval(interval.current);
        resetPageTimeout();
    }, [pathname, resetPageTimeout]);

    /**
     * Runs every time the pageTimeout or the users authentication status changes.
     * Starts an interval to count down the seconds left on a page.
     */
    useEffect(() => {
        if (!isLoggedIn || !pageTimeout) {
            setSecondsLeft(null);
            return;
        }
        authLog('[UsePageTimeout.InitializePageTimeoutCountdown]');
        interval.current = setInterval(() => {
            const newSecondsLeft = (pageTimeout - Date.now()) / 1000;
            authLog('[UsePageTimeout.DecrementPageTimoutCountdown]', newSecondsLeft);
            setSecondsLeft(newSecondsLeft);
        }, 1000);
        return () => {
            if (interval.current) clearInterval(interval.current);
        };
    }, [isLoggedIn, pageTimeout]);

    const reset = useCallback(() => {
        authLog('[UsePageTimeout.ResetPageTimeoutCountdownManually]');
        resetPageTimeout();
    }, [resetPageTimeout]);

    return { secondsLeft, reset };
};

/**
 * Hook to handle automatic logout after a page timout occurs.
 * @param secondsLeft Number of seconds left before page timeout.
 */
export const useAutomaticSessionExpiration = (secondsLeft: number | null) => {
    const navigate = useNavigate();
    const isLoggedIn = useIsLoggedIn();
    const logout = useLogout('AUTOMATIC');
    const messageId = useRef<string | null>(null);
    const { logoutType, clearLogoutType } = useLogoutType();
    const { addMessage, closeMessage } = useMessageActions();

    /**
     * Runs every second.
     * If secondsLeft is 0, a logout occurs.
     */
    useEffect(() => {
        if (secondsLeft === null || secondsLeft > 0) return;
        authLog('[UseAutomaticSessionExpiration.ExpireSession]');
        logout();
    }, [secondsLeft, logout, addMessage]);

    /**
     * Runs every time a logout occurs.
     * If the logoutType is automatic, the message is shown.
     */
    useEffect(() => {
        if (logoutType !== 'AUTOMATIC') return;
        authLog('[UseAutomaticSessionExpiration.AutomaticLogoutOccurred]');
        messageId.current = addMessage(
            warningMessage('Sie wurden automatisch abgemeldet.', { persist: true, closable: true })
        );
        navigate(PathBuilder.home.path);
        clearLogoutType();
    }, [logoutType, clearLogoutType, addMessage, closeMessage, navigate]);

    /**
     * Runs every time the user logs in.
     * Closes the "Sitzung abgelaufen" message (if it is still open)
     */
    useEffect(() => {
        if (!isLoggedIn || !messageId.current) return;
        authLog('[UseAutomaticSessionExpiration.ClearExpirationMessage]');
        closeMessage(messageId.current);
        messageId.current = null;
    }, [isLoggedIn, closeMessage]);
};

/**
 * Hook to present a warning message once the page timeout is imminent.
 * @param secondsLeft Number of seconds left before page timeout.
 * @param reset Function to reset the page timeout manually.
 */
export const useSessionExpirationWarning = (secondsLeft: number | null, reset: () => void) => {
    const { addMessage, closeMessage } = useMessageActions();

    /**
     * Runs every second.
     * If secondsLeft is less than the configured threshold, presents a warning message
     * that allows the user to reset the page timeout.
     */
    useEffect(() => {
        if (secondsLeft === null || secondsLeft <= 0 || secondsLeft > authConfig.pageTimeoutWarningThreshold) return;

        authLog('[UseSessionExpirationWarning.ShowExpirationWarning]');
        const messageId = addMessage(
            warningMessage(`Sitzung läuft aus in ${secondsLeft.toFixed()} Sekunden`, {
                action: {
                    actionTitle: 'Weiterarbeiten',
                    actionMethod: async (uuid: string) => {
                        authLog('[UseSessionExpirationWarning.ContinueWorking]');
                        closeMessage(uuid);
                        addMessage(successMessage('Sitzung aktualisiert', { autoCloseSeconds: 8 }));
                        reset();
                    },
                },
            })
        );

        return () => {
            if (messageId) closeMessage(messageId);
        };
    }, [secondsLeft, reset, addMessage, closeMessage]);
};
