import { Grid, Typography } from '@mui/material';

import { PageHeading } from 'components/PageHeading';
import { KONTAKT_BASE_EMAIL, KONTAKT_BASE_TEL } from 'constants/content';
import { Link } from 'elements/Link';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkTel } from 'elements/LinkTel';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const Index = () => {
    return (
        <ContentContainer title="Hilfe">
            <StyledContainer>
                <Grid container spacing={6} justifyContent="center">
                    <Grid item md={8}>
                        <PageHeading title="Hilfe" />

                        <Typography paragraph>
                            Für Fragen rund um die Anwendung PfAD.wtg steht Ihnen unsere Hotline zur Verfügung, die Sie
                            werktags von 9.00 - 17.00 Uhr unter der Rufnummer <LinkTel msisdn={KONTAKT_BASE_TEL} />{' '}
                            erreichen können.
                            <br />
                            Sie können Ihre Anfrage auch per E-Mail an <LinkEmail email={KONTAKT_BASE_EMAIL} /> richten.
                        </Typography>

                        <Typography paragraph>
                            Das Handbuch für das Registrierungs- und Meldeverfahren finden Sie{' '}
                            <Link
                                href="https://www.d-nrw.de/fileadmin/user_upload/Benutzerhandbuch.pdf"
                                target="_blank"
                            >
                                hier
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const HilfePage = withSideBar(Index);
