import React from 'react';
import HtmlParser from 'react-html-parser';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { TableCell, TableRow } from '@mui/material';

type FieldRowInterface = ControlProps;

const FieldRowComponent: React.FC<FieldRowInterface> = ({ config, data, label }) => {
    return (
        <TableRow>
            <TableCell></TableCell>
            <TableCell>{label}</TableCell>
            <TableCell colSpan={config?.compareData ? 3 : 2}>{HtmlParser(data || '-')}</TableCell>
        </TableRow>
    );
};

export const NotesRow = withJsonFormsControlProps(FieldRowComponent);
