import React from 'react';
import { MenuItem, styled } from '@mui/material';

import { PartialRecord } from 'api/types';
import { ActionDropdown as DataTableHydraActionDropdown } from 'components/DataTable/ActionDropdown/ActionDropdown';
import { AntragListAction, AntragListActionItem } from 'components/DataTable/hooks/useListActions';
import { ACTIONS, AntragActions } from 'constants/antragActions';
import { ANTRAG_ACTION_LABELS } from 'constants/labels';

export type LabelOverrides = PartialRecord<ACTIONS, { label?: string }>;

export interface AntragActionsDropdownProps<T> {
    antrag: T;
    actions: AntragActions;
    handleAction: AntragListAction<T>;
    labelOverrides?: LabelOverrides;
    ariaInfoText?: string;
}

export const ActionDropdownAntrag = <T extends AntragListActionItem>({
    antrag,
    actions,
    handleAction,
    labelOverrides,
    ariaInfoText,
}: AntragActionsDropdownProps<T>) => {
    const allowedActions = antrag.allowedWorkflowActions || antrag.allowedActions || [];
    const availableActions = Object.keys(actions).filter((action) => allowedActions.includes(action));

    if (availableActions.length === 0) return <></>;

    return (
        <DataTableHydraActionDropdown ariaInfoText={ariaInfoText}>
            {availableActions.map((action, index) => {
                const nextAction = availableActions[index + 1];
                const isDivider = Boolean(nextAction && actions[action] !== actions[nextAction]);

                return (
                    <AntragMenuItem key={action} onClick={() => handleAction(antrag, action)} divider={isDivider}>
                        {labelOverrides?.[action as ACTIONS]?.label || ANTRAG_ACTION_LABELS[action]}
                    </AntragMenuItem>
                );
            })}
        </DataTableHydraActionDropdown>
    );
};

const AntragMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:focus': { borderColor: theme.palette.divider },
}));
