import { RuleEffect } from '@jsonforms/core';

import { EinrichtungArtType } from 'api/types';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group(
        [
            control('einrichtungNrwKey'),
            control('einrichtungArt'),
            info({
                message:
                    'Bitte wenden Sie sich an die zuständige WTG-Behörde, wenn die Art ihres Leistungsangebotes geändert werden muss',
            }),
            control('einrichtungWohngemeinschaftArt'),
            control('einrichtungWohngemeinschaftLeistungsbereich'),
            control('einrichtungShortCareHospital'),
            control('einrichtungName'),
            inlineRowControl([
                control('einrichtungInstitutionskennzeichen'),
                control('einrichtungPlaetze'),
                control('einrichtungDatumErstinbetriebnahme', {
                    options: {
                        format: 'date',
                    },
                }),
            ]),
        ],
        {},
        'Angaben zum Leistungsangebot'
    ),
    group(
        [
            control('einrichtungAdresse'),
            control('einrichtungTraegerverband'),
            control('einrichtungHomepage'),
            control('unternehmensgruppe'),
            control('neueUnternehmensgruppe', {
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/unternehmensgruppe/properties/id',
                        schema: { const: -2 },
                    },
                },
            }),
            control('traeger'),
        ],
        { noSeparator: true },
        'Details zum Leistungsangebot'
    ),
    group(
        [
            control('einrichtungAnsprechpartner'),
            inlineRowControl([control('einrichtungTelefon'), control('einrichtungFax')]),
            control('einrichtungEmail', { requireEmailConfirm: false }),
        ],
        {},
        'Ansprechpartner'
    ),
    group(
        [
            info({
                message:
                    'Bitte laden Sie hier Ihren aktuellen Versorgungsvertrag nach SGB XI, ihre Vergütungs- oder Leistungsvereinbarung nach SGB XII oder sonstige Vereinbarungen, Mietverträge oder einen Anerkennungsbescheid hoch.',
            }),
            control('dokumente'),
        ],
        {
            rule: {
                effect: RuleEffect.HIDE,
                condition: {
                    scope: '#/properties/einrichtungArt',
                    schema: {
                        enum: [EinrichtungArtType.WERKSTATT.value],
                    },
                },
            },
        },
        'Versorgungsverträge hochladen'
    ),
    control('notizBasisdaten'),
]);
