import * as React from 'react';
import { useState } from 'react';
import { FormControl, styled } from '@mui/material';

import { Link } from 'elements/Link';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { EmailChange } from 'layout/components/EmailChange/EmailChange';

import { RowControl } from './RowControl';

const StyledLink = styled(Link)(
    () => `
    padding: 0 8px;
    line-height: 1.43;
    margin-left: 8px;
`
);

const ProfileEmailComponent: React.FC<CustomControlProps<string>> = ({ path, label, required, data, uischema }) => {
    const { config } = useJsonFormsState();
    const [showDialog, setShowDialog] = useState(false);

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    return (
        <RowControl name={path} label={label} required={required} gridLayout={uischema.gridLayout || config.gridLayout}>
            <FormControl
                fullWidth
                style={{
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    height: '100%',
                }}
            >
                {data}

                {(uischema as any).isEditable && (
                    <>
                        <StyledLink onClick={() => setShowDialog(true)}>(ändern)</StyledLink>
                        <EmailChange open={showDialog} onClose={handleDialogClose} />
                    </>
                )}
            </FormControl>
        </RowControl>
    );
};

export const ProfileEmailControl = withCustomControlProps(ProfileEmailComponent);
