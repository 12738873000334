import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';

export const uiSchema = customVerticalLayout([
    group(
        [control('stepVertraegeDokumente')],
        undefined,
        'Verträge und Vereinbarungen',
        undefined,
        undefined,
        undefined
    ),
    control('notizVertraege'),
]);
