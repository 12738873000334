import React from 'react';
import { LiveHelp } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

import { DashboardCard } from 'components/Dashboard/DashboardCard';
import { KONTAKT_BASE_EMAIL, KONTAKT_BASE_HOTLINE_TIME, KONTAKT_BASE_TEL } from 'constants/content';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkSupport } from 'elements/LinkSupport';
import { LinkTel } from 'elements/LinkTel';

import { dashboardLinkStyles } from './anbieter';

const dashboardIconStyles = {
    width: '40px',
    height: 'auto',
    color: '#9f9f9f',
};

const LiveHelpIcon = styled(LiveHelp)(() => dashboardIconStyles);

export const HilfeSupport: React.FC = () => (
    <DashboardCard headerAvatar={<LiveHelpIcon />} headerTitle="Hilfe & Support">
        <Typography paragraph>
            Sollten Sie Fragen haben, schauen Sie bitte auf der <LinkSupport title="Hilfe-Seite" /> oder wenden sich an
            unsere Hotline, die Sie werktags von {KONTAKT_BASE_HOTLINE_TIME} unter der Rufnummer{' '}
            <LinkTel msisdn={KONTAKT_BASE_TEL} /> erreichen können. Sie können Ihre Anfrage auch per E-Mail an{' '}
            <LinkEmail email={KONTAKT_BASE_EMAIL} /> richten.
        </Typography>

        <LinkEmail sx={dashboardLinkStyles} email={KONTAKT_BASE_EMAIL} underline="hover">
            E-Mail schreiben
        </LinkEmail>
    </DashboardCard>
);
