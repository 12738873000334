import { ROLE_TYPES } from 'constants/roles';
import { MenuInterface } from 'layout/HeaderConfig';
import { PathBuilder } from 'navigation/Paths';

export const menuAuswertung: MenuInterface[] = [
    {
        path: PathBuilder.home.auswertung.overview,
        name: 'Übersicht der Exporte',
        roles: ROLE_TYPES.ROLLEN_AUSWERTUNG,
    },
    {
        path: PathBuilder.home.auswertung.statistik.path,
        name: 'Statistiken',
        roles: ROLE_TYPES.ROLLEN_AUSWERTUNG_STATISTIK,
        divide: true,
    },
    {
        path: PathBuilder.home.auswertung.statistikFem.path,
        name: 'Statistiken FEM',
        roles: ROLE_TYPES.ROLLEN_AUSWERTUNG_STATISTIK_FEM,
    },
    {
        path: PathBuilder.home.auswertung['P44vWTG'].path,
        name: '§44 V WTG',
        roles: ROLE_TYPES.ROLLEN_AUSWERTUNG_P44,
    },
];
