import { CustomVerticalLayoutType, UiSchemaType } from 'forms/types/UiSchemaTypes';

interface CustomVerticalLayoutOptions {
    spacing?: number;
    gridSize?: number;
}

export const customVerticalLayout = (
    elements: UiSchemaType[],
    options: CustomVerticalLayoutOptions = {
        spacing: 2,
        gridSize: 12,
    }
): CustomVerticalLayoutType => ({
    type: 'CustomVerticalLayout',
    elements,
    ...options,
});
