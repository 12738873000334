import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchemaBenutzerdaten = customVerticalLayout([
    group(
        [
            inlineRowControl([control('anrede'), control('title')]),
            inlineRowControl([control('vorname'), control('nachname')]),
            inlineRowControl([control('telefon'), control('fax')]),
        ],
        {},
        'Benutzerprofil'
    ),
    group(
        [
            group([inlineRowControl([control('email', { isEditable: true })])]),
            group([inlineRowControl([control('password')])], { noSeparator: true }, 'Aktuelles Passwort ändern'),
        ],
        {},
        'Login'
    ),
]);
