import { useEffect, useState } from 'react';
import { isEqual, isObject } from 'lodash';

import { backendApiContextsService as ApiContextsService } from 'api/ApiContextsService';
import { useAuthUserRoles } from 'api/auth';
import { Schema } from 'forms/types/UiSchemaTypes';

interface DynamicSchemaInterface {
    schema: Schema;
    required: string[];
    dependentRequired: any[];
}

export const useSchemaFromJsonLdContext = <ContextSchema = Schema>(
    contextName: string,
    data: any
): {
    schema: ContextSchema;
} => {
    const roles = useAuthUserRoles();
    const [schema, setSchema] = useState<ContextSchema | any>();
    const [dynamicSchema, setDynamicSchema] = useState<DynamicSchemaInterface>();

    useEffect(() => {
        if (undefined === schema || undefined === dynamicSchema) {
            ApiContextsService.getContext(contextName).then((contextDocument) => {
                const dynamicSchema: DynamicSchemaInterface = parseSchemaFromJsonLdContext(
                    contextName,
                    contextDocument.data,
                    roles
                );

                setSchema(dynamicSchema.schema);
                setDynamicSchema(dynamicSchema);
            });
        } else {
            if (dynamicSchema && data) {
                setDynamicSchema(updateDependentRequired(dynamicSchema, data));

                if (!isEqual(schema, dynamicSchema.schema)) {
                    setSchema(dynamicSchema.schema);
                }
            }
        }
    }, [schema, data, contextName, dynamicSchema, roles]);

    return {
        schema,
    };
};

export function parseSchemaFromJsonLdContext(
    contextName: string,
    contextDocument: any,
    roles: string[]
): DynamicSchemaInterface {
    const context = contextDocument['@context'];
    const contextKeys = Object.keys(context);
    const required: string[] = [];
    const dependentRequired: any[] = [];
    const Schema: Schema | any = {
        $id: contextName,
        type: 'object',
        required: [],
        properties: {},
    };

    contextKeys.forEach((field: string) => {
        let schema;

        if (isObject(context[field]) && isObject(context[field]['schema'])) {
            schema = parseContextProperty(context[field]['@id'], context[field]['schema'], roles);

            Schema.properties[schema['$id']] = createOptions(schema);
        }

        if (Object.prototype.hasOwnProperty.call(context[field], 'required') && context[field]['required']) {
            required.push(schema['$id']);
        }

        if (
            Object.prototype.hasOwnProperty.call(context[field], 'dependentRequired') &&
            context[field]['dependentRequired']
        ) {
            dependentRequired.push({
                field: schema['$id'],
                dependency: context[field]['dependentRequired'],
            });
        }
    });

    Schema.required = required;

    return {
        schema: Schema,
        required: required,
        dependentRequired: dependentRequired,
    };
}

export function updateDependentRequired(dynamicSchema: DynamicSchemaInterface, data: any): DynamicSchemaInterface {
    const dependentRequired: any[] = [];

    dynamicSchema.dependentRequired.forEach((item: any) => {
        if (data && item.dependency.values.includes(data[item.dependency.field])) {
            dependentRequired.push(item.field);
        }
    });

    // eslint-disable-next-line
    dynamicSchema.schema.required = [...dynamicSchema.required, ...dependentRequired];

    return dynamicSchema;
}

function parseContextProperty(id: string, schema: any, roles: string[]): Schema | any {
    const property: Schema | any = {};
    const contextKeys = Object.keys(schema);

    property['$id'] = id.split('/').pop();

    contextKeys.forEach((key: string) => {
        if (key === 'WITH_ROLE') {
            Object.assign(property, getContextForRole(schema[key], roles));
        } else {
            property[key] = schema[key];
        }
    });

    return property;
}

function getContextForRole(context: any, roles: string[]): any {
    let match: any;

    roles.forEach((role) => {
        if (!match && Object.prototype.hasOwnProperty.call(context, role)) {
            match = context[role];
        }
    });

    return match;
}

function createOptions(schema: any): any {
    const contextKeys = Object.keys(schema);

    contextKeys.forEach((key: string) => {
        if (['anyOf', 'oneOf', 'enum'].includes(key)) {
            const items = schema[key];
            const options: any[] = [];

            for (const option in items) {
                options.push({
                    const: option,
                    title: items[option],
                });
            }

            // eslint-disable-next-line
            schema[key] = options;
        }
    });

    return schema;
}

export function getOneOfTitel(schema: any, field: string, value: any): any {
    return schema.properties[field]?.oneOf?.find((item: any) => item.const === value)?.title;
}

export function getAnyOfTitel(schema: any, field: string, value: any): any {
    return value
        ?.map((v: any) => schema.properties[field]?.anyOf?.find((item: any) => item.const === v)?.title)
        .join(', ');
}
