import React from 'react';
import HtmlParser from 'react-html-parser';
import { FormControl, FormHelperText, FormLabel, styled, Typography } from '@mui/material';

import { ComponentError } from 'forms/hooks/useErrors';

import { ErrorList } from './ErrorList';

interface ReadonlyValueTextProps {
    text: string | number;
    hasErrors: boolean;
    errors: ComponentError[];
    label?: string;
    variant?: 'h2' | 'h3';
    textAlign?: 'left' | 'right';
}

export const ReadonlyValueText: React.FC<ReadonlyValueTextProps> = ({
    label,
    text,
    errors,
    hasErrors,
    variant,
    textAlign = 'left',
}) => {
    return (
        <FormControl fullWidth>
            {label && !variant && (
                <FormLabel component={'legend'} style={{ textAlign, paddingRight: 12 }}>
                    {HtmlParser(label)}
                </FormLabel>
            )}

            {variant && label ? <Typography variant={variant}>{label}</Typography> : null}

            <StyledReadonlyText className="readonly-text">{text}</StyledReadonlyText>

            {hasErrors && (
                <FormHelperText component={'div'} error>
                    {hasErrors ? <ErrorList errors={errors} /> : ' '}
                </FormHelperText>
            )}
        </FormControl>
    );
};

const StyledReadonlyText = styled(Typography)(({ theme }) => ({
    marginBottom: `${theme.spacing(2)} !important`,
}));
