import { useCallback, useEffect, useRef, useState } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { STATUS } from 'constants/antragStatus';
import { TABLE_ACTIONS } from 'constants/tableActions';
import { useFormState } from 'forms/state/useFormState';
import {
    FormResponse,
    FormState,
    FormStateConfigDelete,
    FormStateConfigReload,
    FormStateConfigRequestPersist,
    FormStateConfigRequestValidate,
    FormStateConfigSubmitFormData,
    FormStateConfigValidate,
    Schema,
    ServerErrorsType,
} from 'forms/types/UiSchemaTypes';
import { cleanUpData } from 'forms/utilities/SchemaUtils';
import { Step } from 'layout/HeaderConfig';

import { useFormLeaveWarning } from './useFormLeaveWarning';
import { HydraErrorDescription, useServerError } from './useServerError';

export type FormHandlingGet<FormData> = ({
    objectType,
    uuid,
    options,
}: {
    objectType?: string;
    uuid?: string;
    options?: AxiosRequestConfig;
}) => Promise<FormData | void>;
export type FormHandlingDelete = (id: string) => Promise<void>;
export type FormHandlingSubmit<FormData> = ({
    body,
    persist,
    modelPath,
    objectType,
    recalculateFields,
    uuid,
}: {
    body: FormState;
    persist?: boolean;
    modelPath?: string;
    objectType?: string;
    recalculateFields?: boolean;
    uuid?: string;
}) => Promise<FormData | false | void>;

export type UseFormHandlingProps = {
    getFormDataAPI?: FormHandlingGet<FormResponse>;
    submitAPI?: FormHandlingSubmit<FormResponse>;
    deleteObjectTypeItemAPI?: FormHandlingDelete;
    initialLoading?: boolean;
};

export type FormHandlingOnChange = (newData: FormState, shouldSubmit?: boolean) => Promise<void>;
export type FormHandlingSetResponseData = (
    response: FormResponse | false | void,
    fields?: string[],
    persist?: boolean
) => FormResponse | undefined;

interface FormHandlingData extends FormHandlingDataState {
    clear: () => void;
    isDirty: boolean;
    isPersisting: boolean;
    isLoading: boolean;
    isSubmitted: boolean;
    loadingError: string | undefined;
    onChange: FormHandlingOnChange;
    // TODO: remove false from result promise type
    requestValidate: () => void;
    requestPersist: () => void;
    setLoadingError: (error: string) => void;
    submit: FormStateConfigSubmitFormData;
    validate: FormStateConfigValidate;
    reloadData: FormStateConfigReload;
    deleteObjectTypeItem: FormStateConfigDelete;
    setEinrichtungId: (einrichtungId: number | undefined) => void;
    setAntragId: (antragId: number | undefined) => void;
    setAntragToken: (antragToken: string | undefined) => void;
    setAntragStatus: (antragStatus: STATUS | undefined) => void;
}

interface FormHandlingDataState<DataFormType = any> {
    schema: Schema;
    data: DataFormType;
    formErrors: ServerErrorsType;
    sidebarSteps: string[];
    steps: Step<string>[];
    allowedWorkflowActions: TABLE_ACTIONS[];
}

export const useFormHandling = <DataFormType>({
    getFormDataAPI,
    submitAPI,
    deleteObjectTypeItemAPI,
    initialLoading = true,
}: UseFormHandlingProps): FormHandlingData => {
    const {
        isPersisting,
        isLoading,
        loadingError,
        submitStart,
        submitEnd,
        loadingStart,
        loadingEnd,
        setLoadingError,
        setUuidMap,
        setEinrichtungId,
        setAntragId,
        setAntragToken,
        setAntragStatus,
    } = useFormState();

    const validateRef = useRef<boolean>(false);
    const submitRef = useRef<boolean>(false);
    const modelPathRef = useRef<string | undefined>();
    const abortController = useRef<AbortController | null>(null);
    const [initialData, setInitialData] = useState<FormState>();
    const [data, setData] = useState<FormHandlingDataState<DataFormType>>({} as FormHandlingDataState<DataFormType>);
    const serverError = useServerError();

    const { isDirty } = useFormLeaveWarning(initialData, data.data, isPersisting);

    const requestValidate: FormStateConfigRequestValidate = useCallback((path) => {
        validateRef.current = true;
        modelPathRef.current = path;
    }, []);

    const requestPersist: FormStateConfigRequestPersist = useCallback((path) => {
        submitRef.current = true;
        modelPathRef.current = path;
    }, []);

    const resetRequestRefs = useCallback(() => {
        validateRef.current = false;
        submitRef.current = false;
        modelPathRef.current = undefined;
    }, []);

    const clear = useCallback(() => {
        setData((prevState) => ({ steps: prevState.steps } as FormHandlingDataState<DataFormType>));
        setInitialData(undefined);
    }, []);

    const updatePartialFields = useCallback(
        (old: FormState, newData: FormState, fields?: string[]) => {
            if (!fields || (Array.isArray(fields) && fields.length === 0)) {
                return newData;
            }
            if (!newData) {
                return {};
            }
            try {
                return fields.reduce((preparedData, field) => {
                    return {
                        ...preparedData,
                        [field]: newData[field],
                    };
                }, old);
            } catch (e) {
                console.warn(e);
                setLoadingError('[update] Laden fehlgeschlagen');
                return old;
            }
        },
        [setLoadingError]
    );

    const setResponseData: FormHandlingSetResponseData = useCallback(
        (response, fields, persist) => {
            if (!response) {
                clear();
                return;
            }

            setData(
                (prev) =>
                    ({
                        // Empty {} results to empty [] in php
                        data: Array.isArray(response.data)
                            ? {}
                            : updatePartialFields(prev.data, response.data!, fields),
                        schema: response.schema,
                        uuidMap: response.uuidMap,
                        sidebarSteps: persist ? response.steps : prev.sidebarSteps,
                        steps: response.steps,
                        allowedWorkflowActions: response.allowedWorkflowActions,
                        formErrors: response.errors, // Clean up response.errors to avoid error messages for empty fields
                    } as FormHandlingDataState<DataFormType>)
            );

            setUuidMap(response.uuidMap);
            return response;
        },
        [setUuidMap, clear, updatePartialFields]
    );

    const reloadData: FormStateConfigReload = useCallback(
        (fields) => {
            if (getFormDataAPI) {
                abortController.current = new AbortController();

                loadingStart();

                resetRequestRefs();

                return getFormDataAPI({ options: { signal: abortController.current?.signal } })
                    .then((response) => {
                        loadingEnd();
                        return setResponseData(response, fields);
                    })
                    .then((res) => setInitialData(res?.data))
                    .catch((error) => {
                        if (error.code !== 'ERR_CANCELED') {
                            if (error?.response?.status === 421) {
                                setLoadingError(serverError(error as AxiosError<HydraErrorDescription>));
                            } else {
                                setLoadingError('Laden fehlgeschlagen');
                            }
                        }

                        return error;
                    })
                    .finally(loadingEnd);
            } else {
                return Promise.resolve();
            }
        },
        // eslint-disable-next-line
        [getFormDataAPI, loadingStart, resetRequestRefs, loadingEnd, setResponseData, setLoadingError]
    );

    const submit: FormStateConfigSubmitFormData = useCallback(
        ({ body, persist = false }) => {
            if (!submitAPI) {
                return new Promise(() => false);
            }

            resetRequestRefs();

            submitStart(persist);

            return submitAPI({ body: markAsEdited(body, data.schema, persist), persist })
                .then((res) => setResponseData(res, undefined, persist))
                .then((res) => {
                    if (persist) {
                        setInitialData(res?.data);
                    }
                    return res;
                })
                .catch((error) => Promise.reject(error))
                .finally(submitEnd);
        },
        [submitAPI, resetRequestRefs, submitStart, data.schema, submitEnd, setResponseData]
    );

    const onChange: FormHandlingOnChange = useCallback(
        async (newData) => {
            setData((prevState) => {
                return { ...prevState, data: newData };
            });

            if (validateRef.current || submitRef.current) {
                await submit({ body: cleanUpData(newData, data.schema), persist: submitRef.current });
            }
        },
        [data.schema, submit]
    );

    const validate: FormStateConfigValidate = useCallback(
        (modelPath?: string) => {
            return submit({ body: data.data, persist: false, objectType: modelPath });
        },
        [submit, data.data]
    );

    const deleteObjectTypeItem: FormStateConfigDelete = useCallback(
        (uuid) => {
            if (deleteObjectTypeItemAPI) {
                return deleteObjectTypeItemAPI(uuid);
            } else {
                return Promise.resolve();
            }
        },
        [deleteObjectTypeItemAPI]
    );

    useEffect(() => {
        if (!initialLoading) return;
        reloadData();

        return () => {
            abortController.current?.abort();
        };
    }, [reloadData, initialLoading]);

    return {
        ...data,
        isDirty,
        isPersisting,
        isLoading,
        isSubmitted: false,
        loadingError,
        onChange,
        requestValidate,
        requestPersist,
        submit,
        setLoadingError,
        setEinrichtungId,
        setAntragId,
        setAntragToken,
        setAntragStatus,
        validate,
        clear,
        reloadData,
        deleteObjectTypeItem,
    };
};

const markAsEdited = (formData: FormState, schema: Schema, persist = false) => {
    if (!persist) {
        return formData;
    }

    if (!schema?.properties) {
        return formData;
    }

    const editFieldName = Object.keys(schema.properties)
        .filter((k) => k !== 'prototype')
        .find((k) => (schema.properties![k] as Schema).custom?.block_prefixes?.includes('bearbeitet'));

    if (!editFieldName) {
        return formData;
    }
    return { ...formData, [editFieldName]: true };
};
