import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { ExportRequestCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useAuswertungExportListData = (): GetData<ExportRequestCollectionResponse> => {
    return useCallback(({ page, rowsPerPage, sortOrder }) => {
        return backendApiService.getExportsCollection({
            page,
            itemsPerPage: rowsPerPage,
            orderResource: sortBy(sortOrder, 'resource'),
            orderStatus: sortBy(sortOrder, 'status'),
            orderCreatedDate: sortBy(sortOrder, 'createdDate'),
        });
    }, []);
};
