import React from 'react';
import { isVisible, LayoutProps, UISchemaElement } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid, GridSize, styled, Typography } from '@mui/material';

import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { prepareUISchema } from 'forms/hooks/useStepUISchema';
import { renderers } from 'forms/renderers';
import { InlineRowControlType, Schema, SchemaInterface } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope, getParentSchemaOfField } from 'forms/utilities/SchemaUtils';
import { theme } from 'theme/theme';
import { getFieldFromScope } from 'utilities';

import { RowControl } from './RowControl';

interface InlineRowControlProps extends Omit<LayoutProps, 'uischema' | 'schema'> {
    uischema: InlineRowControlType & SchemaInterface;
    schema: Schema;
}

const InlineRowControlComponent: React.FC<InlineRowControlProps> = ({
    schema,
    uischema,
    path,
}: InlineRowControlProps) => {
    const { ajv, rootData, config } = useJsonFormsState();

    const gridLayout = uischema.gridLayout || config.gridLayout;
    const gridLayoutInput = (gridLayout?.input || 12) as number;

    const field = getFieldFromScope(uischema.scope)!;
    const fieldSchema = getFieldSchemaByScope(uischema.scope, schema);
    const parentSchema = getParentSchemaOfField(uischema.scope, schema);
    const preparedUiSchema = prepareUISchema(uischema, schema);
    const visibleElements =
        preparedUiSchema.elements?.filter((element, key) =>
            isVisible(uischema.elements?.[key] as UISchemaElement, rootData, '', ajv)
        ) || [];

    const childSize = (gridLayoutInput / (visibleElements.length || 1)) as GridSize;

    return (
        <RowControl
            name={field}
            label={fieldSchema?.title}
            required={parentSchema?.required?.includes(field)}
            showFieldNumberLabels={config.showFieldNumberLabels}
            skipLabel={true}
            gridLayout={gridLayout}
        >
            {visibleElements.length && uischema?.title ? (
                <Grid item xs={12} data-cy="InlineRowControl--title">
                    <Typography variant="subtitle1" marginBottom={theme.spacing(3)}>
                        {uischema?.title}
                    </Typography>
                </Grid>
            ) : null}

            {false !== uischema.grid ? (
                <Grid container spacing={3} data-cy="InlineRowControl--grid" paddingTop={1}>
                    {visibleElements.map((child, index) => (
                        <Grid item xs={12} md={child.gridSize || childSize} key={index} container>
                            <JsonFormsDispatch schema={schema} uischema={child} path={path} renderers={renderers} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <div>
                    {visibleElements.map((child, index) => (
                        <NoGridItem data-cy="InlineRowControl--no-grid">
                            <JsonFormsDispatch
                                key={index}
                                schema={schema}
                                uischema={child}
                                path={path}
                                renderers={renderers}
                            />
                        </NoGridItem>
                    ))}
                </div>
            )}
        </RowControl>
    );
};

// @ts-ignore
export const InlineRowControl = withJsonFormsLayoutProps(InlineRowControlComponent);

const NoGridItem = styled('span')(() => ({
    display: 'inline-block',
    marginRight: 5,
    marginBottom: 5,
}));
