import React, { useMemo } from 'react';

import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { MeldungProps } from 'pages/Meldung/Details/MeldungDetailPage';

export const Beschaeftigte = ({ meldung }: MeldungProps) => {
    const labelPalliativversorgung = useMemo(() => {
        if (meldung.data?.stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck) {
            return 'Durch eigenes Personal sichergestellt';
        } else {
            return 'Durch Kooperation sichergestellt';
        }
    }, [meldung.data]);

    return (
        <DetailsLayout.Group title="Beschäftigte" variant="h2" divider>
            {meldung.data?.stepBeschaeftigteEinrichtungsleitung ? (
                <>
                    {meldung.data.stepBeschaeftigteEinrichtungsleitung.map((item, index) => (
                        <DetailsLayout.Group
                            key={item.uuid}
                            title={`Angaben zur Einrichtungsleitung ${index + 1}`}
                            variant="h3"
                            divider={index + 1 !== meldung.data?.stepBeschaeftigteEinrichtungsleitung?.length}
                        >
                            <DetailsLayout.InfoField value={item.personalName} label="Name" />
                            <DetailsLayout.InfoField value={item.personalTelefon} label="Telefon" />
                            <DetailsLayout.InfoField value={item.personalFax} label="Telefax" />
                            <DetailsLayout.InfoField value={item.personalEmail} label="E-Mail-Adresse" />
                            <DetailsLayout.InfoField value={item.personalTaetigSeit} label="Tätig seit" isDate />
                            <DetailsLayout.InfoField value={item.personalStellenAnteil} label="Stellenanteil (%)" />
                        </DetailsLayout.Group>
                    ))}
                </>
            ) : null}

            {meldung.data?.stepBeschaeftigteEinrichtungsleitungDokumente ? (
                <DetailsLayout.Group title="Dokumente zur Einrichtungsleitung" variant="h3" divider>
                    <DetailsLayout.DownloadLink value={meldung.data.stepBeschaeftigteEinrichtungsleitungDokumente} />
                </DetailsLayout.Group>
            ) : null}

            {meldung.data?.stepBeschaeftigtePflegedienstleitung ? (
                <>
                    {meldung.data.stepBeschaeftigtePflegedienstleitung.map((item, index) => (
                        <DetailsLayout.Group
                            key={item.uuid}
                            title={`Angaben zur Pflegedienstleitung ${index + 1}`}
                            variant="h3"
                            divider={index + 1 !== meldung.data?.stepBeschaeftigtePflegedienstleitung?.length}
                        >
                            <DetailsLayout.InfoField value={item.fachkraftName} label="Name" />
                            <DetailsLayout.InfoField value={item.fachkraftTelefon} label="Telefon" />
                            <DetailsLayout.InfoField value={item.fachkraftFax} label="Telefax" />
                            <DetailsLayout.InfoField value={item.fachkraftEmail} label="E-Mail-Adresse" />
                            <DetailsLayout.InfoField value={item.fachkraftTaetigSeit} label="Tätig seit" isDate />
                            <DetailsLayout.InfoField value={item.fachkraftStellenAnteil} label="Stellenanteil (%)" />
                        </DetailsLayout.Group>
                    ))}
                </>
            ) : null}

            {meldung.data?.stepBeschaeftigtePflegedienstleitungDokumente ? (
                <DetailsLayout.Group title="Dokumente zur Plegedienstleitung" variant="h3" divider>
                    <DetailsLayout.DownloadLink value={meldung.data.stepBeschaeftigtePflegedienstleitungDokumente} />
                </DetailsLayout.Group>
            ) : null}

            <DetailsLayout.Group
                title={
                    meldung.labelReplacements?.['[stepBeschaeftigtePflegePersonalLabel]'] || '[Missing field401Label]'
                }
                variant="h3"
            >
                {meldung.data?.field401 ? (
                    <DetailsLayout.Group
                        title={meldung.labelReplacements?.['[pflegePersonalFachkraft]']}
                        variant="h4"
                        divider
                    >
                        <DetailsLayout.InfoField value={meldung.data.field401.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field401.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}

                {meldung.data?.field401b ? (
                    <DetailsLayout.Group title="Davon Fachkräfte mit pflegerischer Ausbildung" variant="h4" divider>
                        <DetailsLayout.InfoField value={meldung.data.field401b.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field401b.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}

                {meldung.data?.field401c ? (
                    <DetailsLayout.Group
                        title="Davon Fachkräfte in der sozialen Betreuung (z.B. Heilerziehungspfleger)"
                        variant="h4"
                        divider
                    >
                        <DetailsLayout.InfoField value={meldung.data.field401c.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field401c.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}

                {meldung.data?.field401d ? (
                    <DetailsLayout.Group title="Davon Palliativfachkräfte" variant="h4" divider>
                        <DetailsLayout.InfoField value={meldung.data.field401d.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field401d.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}

                {meldung.data?.field401a ? (
                    <DetailsLayout.Group title="Hilfskräfte" variant="h4" divider>
                        <DetailsLayout.InfoField value={meldung.data.field401a.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field401a.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}
            </DetailsLayout.Group>

            <DetailsLayout.Group
                title={meldung.labelReplacements?.['[stepBeschaeftigtePflegePersonalSozialeBetreuungLabel]']}
                variant="h3"
            >
                {meldung.data?.field402 ? (
                    <DetailsLayout.Group
                        title={meldung.labelReplacements?.['[pflegePersonalSozialeBetreuungLabel]']}
                        variant="h4"
                        divider
                    >
                        <DetailsLayout.InfoField value={meldung.data.field402.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field402.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}

                {meldung.data?.field402a ? (
                    <DetailsLayout.Group title="Hilfskräfte" variant="h4" divider>
                        <DetailsLayout.InfoField value={meldung.data.field402a.vza} label="VZÄ" />
                        <DetailsLayout.InfoField value={meldung.data.field402a.anzahl} label="Anzahl" />
                    </DetailsLayout.Group>
                ) : (
                    '-'
                )}
            </DetailsLayout.Group>

            {meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung ? (
                <DetailsLayout.Group
                    title="Personal in der sozialen Betreuung (ohne 43b-Kräfte/Alltagsbegleiter)"
                    variant="h3"
                >
                    {meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung.pflegePersonalFachkraft ? (
                        <DetailsLayout.Group
                            title="Fachkräfte (ohne Pflegedienst- und Einrichtungsleitung)"
                            variant="h4"
                            divider
                        >
                            <DetailsLayout.InfoField
                                value={
                                    meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung
                                        .pflegePersonalFachkraft?.vza
                                }
                                label="VZÄ"
                            />
                            <DetailsLayout.InfoField
                                value={
                                    meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung
                                        .pflegePersonalFachkraft?.anzahl
                                }
                                label="Anzahl"
                            />
                        </DetailsLayout.Group>
                    ) : (
                        '-'
                    )}

                    {meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung.pflegePersonalHilfskraft ? (
                        <DetailsLayout.Group title="Hilfskräfte" variant="h4" divider>
                            <DetailsLayout.InfoField
                                value={
                                    meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung
                                        .pflegePersonalHilfskraft?.vza
                                }
                                label="VZÄ"
                            />
                            <DetailsLayout.InfoField
                                value={
                                    meldung.data?.stepBeschaeftigtePflegePersonalSozialeBetreuung
                                        .pflegePersonalHilfskraft?.anzahl
                                }
                                label="Anzahl"
                            />
                        </DetailsLayout.Group>
                    ) : (
                        '-'
                    )}
                </DetailsLayout.Group>
            ) : null}

            <DetailsLayout.Group title="Intensivpflege" variant="h3">
                <DetailsLayout.InfoField
                    value={meldung.data?.stepBeschaeftigteIntensivpflegeCheck ? 'Ja' : 'Nein'}
                    label="Intensivpflege / Pflege beatmungspflichtiger Personen"
                />

                {meldung.data?.stepBeschaeftigteIntensivpflegeCheck ? (
                    <DetailsLayout.Group title="Intensivpflegefachkräfte" variant="h4">
                        <DetailsLayout.SimpleTable
                            columns={[
                                {
                                    label: 'VZÄ',
                                    name: 'stepBeschaeftigteIntensivpflegeDatenVZA',
                                    textAlign: 'right',
                                },
                                {
                                    label: 'Anzahl',
                                    name: 'stepBeschaeftigteIntensivpflegeDatenAnzahl',
                                    textAlign: 'right',
                                },
                            ]}
                            data={[meldung.data]}
                        />
                    </DetailsLayout.Group>
                ) : null}
            </DetailsLayout.Group>

            <DetailsLayout.Group title="Hauswirtschaft" variant="h3">
                <DetailsLayout.InfoField
                    value={meldung.data?.stepBeschaeftigtepflegePersonalHauswirtschaftCheck ? 'Ja' : 'Nein'}
                    label="Hauswirtschaftsfachkraft"
                />

                {meldung.data?.stepBeschaeftigtepflegePersonalHauswirtschaftCheck === false ? (
                    <DetailsLayout.Group title="Hauswirtschaftliche Versorgung wird extern sichergestellt" variant="h4">
                        <DetailsLayout.InfoField
                            value={meldung.data?.stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung}
                            label="Hauswirtschaftliche Versorgung wird extern sichergestellt"
                        />
                    </DetailsLayout.Group>
                ) : null}
            </DetailsLayout.Group>

            <DetailsLayout.Group title="Spezialisierte ambulante Palliativversorgung" variant="h3">
                <DetailsLayout.InfoField
                    value={meldung.data?.stepBeschaeftigtepflegePersonalPalliativversorgungCheck ? 'Ja' : 'Nein'}
                    label="Werden in Ihrem Leistungsangebot auch Nutzerinnen und Nutzer mit nicht heilbaren, fortschreitenden und weit fortgeschrittenen Erkrankungen bei einer zugleich eng begrenzten Lebenserwartung betreut:"
                    labelMd={8}
                    valueMd={4}
                />
                <DetailsLayout.InfoField
                    value={labelPalliativversorgung}
                    label="Betreuung von Nutzerinnen und Nutzern mit nicht heilbaren, fortschreitenden und weit fortgeschrittenen Erkrankungen bei einer zugleich eng begrenzten Lebenserwartung"
                    labelMd={8}
                    valueMd={4}
                />
            </DetailsLayout.Group>
        </DetailsLayout.Group>
    );
};
