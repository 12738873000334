import { backendApiService } from 'api/ApiService';
import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useRegelpruefungListActions = () => {
    return useListActions(
        PathBuilder.home.verwaltung.regelpruefung,
        backendApiService.workflowRegelpruefungItem.bind(backendApiService)
    );
};
