import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { WohngemeinschaftCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';

export const useAbwColumns = (
    handleAction: AntragListAction<any>
): ((data: WohngemeinschaftCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'name',
                    label: 'Name der Wohngemeinschaft',
                    options: {
                        customBodyRender: (value) => (value ? value : '-'),
                    },
                },
                {
                    name: 'anschrift',
                    label: 'Hausanschrift',
                },
                {
                    name: 'einrichtung',
                    label: 'Name des Leistungsangebots',
                    options: {
                        customBodyRender: (value) => (value ? value : '-'),
                    },
                },
                {
                    name: 'ambulanterDienst',
                    label: 'Ambulanter Dienst',
                },
                {
                    name: 'anzahlDerBetreutenNutzer',
                    label: 'Nutzer',
                },
                actions(data),
            ];
        },
        [actions]
    );
};
