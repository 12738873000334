import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { MeldungProps } from 'pages/Meldung/Details/MeldungDetailPage';

export const Ansprechpartner = ({ meldung }: MeldungProps) => (
    <DetailsLayout.Group title="Ansprechpartner" variant="h3" divider>
        <DetailsLayout.InfoField label="Name" value={meldung.data?.einrichtungAnsprechpartner} />
        <DetailsLayout.InfoField label="Telefon" value={meldung.data?.einrichtungTelefon} />
        <DetailsLayout.InfoField label="Telefax" value={meldung.data?.einrichtungFax} />
        <DetailsLayout.InfoField label="E-Mail-Adresse" value={meldung.data?.einrichtungEmail} />
    </DetailsLayout.Group>
);
