import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';

import { useMassenmailListActions } from 'components/DataTable/actions/useMassenmailListActions';
import { useMassenmailColumns } from 'components/DataTable/columns/useMassenmailColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ROLE_TYPES } from 'constants/roles';
import { FormLoadingButton } from 'forms/components/FormButton';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { theme } from 'theme/theme';

import { useMassenmailListData } from './useMassenmailListData';

const Index = () => {
    const navigate = useNavigate();

    const { handleAction } = useMassenmailListActions();

    const getData = useMassenmailListData();

    const columns = useMassenmailColumns(handleAction);

    return (
        <ContentContainer title="Übersicht der Massenmails">
            <StyledContainer>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} lg={10}>
                        <Typography variant="h1">Massenmails</Typography>

                        <DataTableHydraServerside
                            getColumns={columns}
                            getData={getData}
                            persistSearchText
                            initialOptions={{
                                viewColumns: true,
                                custom: {
                                    pathBuilder: PathBuilder.home.verwaltung.massenmail,
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        lg={10}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'flex-end',
                            gap: theme.spacing(3),
                        }}
                    >
                        <FormLoadingButton
                            loading={false}
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-cy="LANSubmitButton"
                            onClick={() => navigate(PathBuilder.home.verwaltung.massenmail.create)}
                            endIcon={<AddIcon />}
                        >
                            Neue Massenmail
                        </FormLoadingButton>
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MassenmailOverview = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_MASSENMAIL);
