import React from 'react';
import { Grid, useTheme } from '@mui/material';

import { DashboardCardBadgeTitle } from 'components/Dashboard/DashboardBadgeTitle';
import { DashboardCard } from 'components/Dashboard/DashboardCard';
import { PageHeading } from 'components/PageHeading';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';
import { HilfeSupport } from 'pages/Dashboard/HilfeSupport';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

export const DashboardBehoerde: React.FC = () => {
    const theme = useTheme();
    const { getBenutzerAufgabenTotal } = useBenutzerAufgaben();

    return (
        <ContentContainer title="Startseite">
            <StyledContainer>
                <PageHeading title="Willkommen bei PfAD.wtg" />

                <Grid container spacing={5} marginBottom={theme.spacing(5)}>
                    <Grid item md={4}>
                        <DashboardCard
                            headerTitle={
                                <DashboardCardBadgeTitle
                                    title="Leistungsangebot freigeben"
                                    badgeProps={{
                                        badgeContent: getBenutzerAufgabenTotal(['leistungsangebotFreigeben']),
                                    }}
                                />
                            }
                            cardActions={[
                                {
                                    href: PathBuilder.home.aufgaben.freigeben.leistungsangebote.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DashboardCard
                            headerTitle={
                                <DashboardCardBadgeTitle
                                    title="Angezeigte Betreuung in Wohngemeinschaften zuordnen"
                                    badgeProps={{
                                        badgeContent: getBenutzerAufgabenTotal(['wohngemeinschaftZuordnen']),
                                    }}
                                />
                            }
                            cardActions={[
                                {
                                    href: PathBuilder.home.aufgaben.abw.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DashboardCard
                            headerTitle={
                                <DashboardCardBadgeTitle
                                    title="Leistungsanbieter freigeben"
                                    badgeProps={{
                                        badgeContent: getBenutzerAufgabenTotal(['leistungsanbieterFreigeben']),
                                    }}
                                />
                            }
                            cardActions={[
                                {
                                    href: PathBuilder.home.aufgaben.freigeben.leistungsanbieter.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DashboardCard
                            headerTitle={
                                <DashboardCardBadgeTitle
                                    title="Meldung ohne Zuständigkeit"
                                    badgeProps={{
                                        badgeContent: getBenutzerAufgabenTotal(['meldungOhneZustaendigkeit']),
                                    }}
                                />
                            }
                            cardActions={[
                                {
                                    href: PathBuilder.home.aufgaben.meldungen.ohneZustaendigkeit.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DashboardCard
                            headerTitle={
                                <DashboardCardBadgeTitle
                                    title="Meldungen freigeben"
                                    badgeProps={{
                                        badgeContent: getBenutzerAufgabenTotal(['meldungFreigeben']),
                                    }}
                                />
                            }
                            cardActions={[
                                {
                                    href: PathBuilder.home.aufgaben.freigeben.meldungen.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DashboardCard
                            headerTitle={
                                <DashboardCardBadgeTitle
                                    title="Zur Meldung aufgefordert"
                                    badgeProps={{
                                        badgeContent: getBenutzerAufgabenTotal(['zurMeldungAufgefordert']),
                                    }}
                                />
                            }
                            cardActions={[
                                {
                                    href: PathBuilder.home.aufgaben.meldungen.aufgefordert.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>
                </Grid>

                <HilfeSupport />
            </StyledContainer>
        </ContentContainer>
    );
};
