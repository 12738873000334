import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { ApiAuswertung44VWtgGetCollection200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useAuswertung44vWtgListData = (): GetData<ApiAuswertung44VWtgGetCollection200Response> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) =>
            backendApiService.getAuswertungP44EinrichtungCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderArt: sortBy(sortOrder, 'art'),
                orderAdressePlz: sortBy(sortOrder, 'plz'),
                orderAdresseOrt: sortBy(sortOrder, 'ort'),
            }),
        []
    );
};
