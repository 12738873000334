import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { LvMandantEdit } from './LvMandantEdit';

const Index = () => {
    return <LvMandantEdit disableBackLink />;
};

export const LvMandantEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MANDANT_BEARBEITEN_LV
);
