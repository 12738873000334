import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { ApiLeistungsanbieterGetCollection200Response } from 'api/client';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { ACTIONS } from 'constants/antragActions';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

export const useLeistungsanbieterColumns = (
    handleAction: AntragListAction<any>
): ((data: ApiLeistungsanbieterGetCollection200Response | undefined) => MUIDataTableColumnDef[]) => {
    const { transform2Date } = useFormatDate();
    const actions = useServerActionsColumn({
        handleAction,
        labelOverrides: { [ACTIONS.EDIT]: { label: 'Freigeben' } },
    });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                {
                    name: 'id',
                    options: {
                        display: false,
                    },
                },
                {
                    name: 'uuid',
                    options: {
                        display: false,
                    },
                },
                {
                    name: 'name',
                    label: 'Name',
                },
                {
                    name: 'eingereichtAt',
                    label: 'Eingereicht am',
                    options: {
                        sort: false,
                        customBodyRender: (value) => transform2Date(value),
                    },
                },
                actions(data),
            ];
        },
        [actions, transform2Date]
    );
};
