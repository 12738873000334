import { control } from 'forms/ui-schemas/elements/control';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    spacing: 6,
    elements: [
        info({
            message:
                'Sie können hier die Meldung des Leistungsanbieters zur Korrektur oder Vervollständigung mit einer Nachricht an den Leistungsanbieter zurückgeben. Die Meldung wird dann beim Leistungsanbieter in den Entwurfstatus zurückversetzt und kann von diesem erneut bearbeitet und an die WTG-Behörde abgesendet werden. Sobald die Meldung erneut abgesendet wurde, erscheint Sie bei Ihnen als neue Aufgabe unter „Meldungen“, sofern Sie zuständig sind.',
        }),
        control('zurueckweisenBetreff'),
        control('zurueckweisenNotiz'),
        group(
            [control('zurueckweisenDokumente')],
            {},
            undefined,
            undefined,
            undefined,
            '#/properties/zurueckweisenDokumente'
        ),
    ],
};
