import { RuleEffect } from '@jsonforms/core';

import { AnschriftType } from 'api/types';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    control('traegerBezeichnung'),
    control('traegerAnschriftTyp'),
    group(
        [
            control('traegerPostfachBezeichnung'),
            inlineRowControl([control('traegerPostfachPlz'), control('traegerPostfachOrt')]),
        ],
        {
            unstyled: true,
            noSeparator: true,
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/traegerAnschriftTyp',
                    schema: { const: AnschriftType.POSTFACH },
                },
            },
        }
    ),
    control('traegerAdresse', {
        rule: {
            effect: RuleEffect.SHOW,
            condition: {
                scope: '#/properties/traegerAnschriftTyp',
                schema: { const: AnschriftType.HAUSANSCHRIFT },
            },
        },
    }),
    inlineRowControl([control('traegerTelefon'), control('traegerEmail', { requireEmailConfirm: false })]),
    inlineRowControl([control('traegerTyp'), control('traegerTraegerverband')]),
]);
