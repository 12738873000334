import { MUIDataTableColumnOptions } from 'mui-datatables';

import { WOHNGEMEINSCHAFT_ART_FILTER_LABELS } from 'constants/labels';

export const WohngemeinschaftArtFilterOptions: MUIDataTableColumnOptions = {
    customFilterListOptions: {
        render: (value) => {
            return `Art der Wohngemeinschaft: ${WOHNGEMEINSCHAFT_ART_FILTER_LABELS[value]}`;
        },
    },
    filterOptions: {
        names: Object.keys(WOHNGEMEINSCHAFT_ART_FILTER_LABELS),
        renderValue: (value) => WOHNGEMEINSCHAFT_ART_FILTER_LABELS[value],
    },
    filterType: 'dropdown',
};
