export enum AntragType {
    LEISTUNGSANBIETER = 'LEISTUNGSANBIETER',
    LEISTUNGSANGEBOT = 'LEISTUNGSANGEBOT',
    LEISTUNGSANGEBOT_BEARBEITEN = 'LEISTUNGSANGEBOT_BEARBEITEN',
    MELDUNG = 'MELDUNG',
    PLATZZAHLMELDER = 'PLATZZAHLMELDER',
    FEM = 'FEM',
    REGELPRUEFUNG = 'REGELPRUEFUNG',
    ZURUECKGEBEN = 'ZURUECKGEBEN',
    EMAIL_ANTRAG = 'EMAIL_ANTRAG',
}

export enum MeldungArtType {
    ERSTMELDUNG = 'Erstmeldung',
    AENDERUNGSMELDUNG = 'Änderungsmeldung',
}
