import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangebotBearbeiten } from 'pages/Leistungsangebote/components/LeistungsangebotBearbeiten';

const Index = () => {
    return (
        <LeistungsangebotBearbeiten
            headlineOverride="Leistungsangebot erstellen und freigeben"
            buttonOverrides={{ [ACTIONS.FREIGEBEN]: { label: 'Erstellen und freigeben' } }}
            pathBuilder={PathBuilder.home.verwaltung.abw}
        />
    );
};

export const AbwLeistungsangebotPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN
);
