import { styled } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { Link } from 'elements/Link';

interface BenutzerColumnPathBuilder {
    view: ({ id }: { id: string | number }) => string;
}

export const BenutzerColumn = (data: any, pathBuilder: BenutzerColumnPathBuilder): MUIDataTableColumnDef => ({
    name: 'benutzer',
    label: 'Benutzer',
    options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
            const rowData = data['hydra:member']?.[dataIndex];
            const benutzerCount = rowData?.benutzer || 0;

            return benutzerCount > 0 ? (
                <StyledColumnLink
                    to={pathBuilder.view({
                        id: rowData?.id,
                    })}
                >
                    {benutzerCount}
                </StyledColumnLink>
            ) : (
                <>{benutzerCount}</>
            );
        },
    },
});

const StyledColumnLink = styled(Link)(() => ({
    cursor: 'pointer',
}));
