import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useSpecificRole } from 'api/auth/useHasRole';
import { BenutzerListResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeColumn } from 'components/DataTable/components/BadgeColumn';
import { BenutzerStatusFilterOptions } from 'components/DataTable/filters/BenutzerStatusFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getRowData } from 'components/DataTable/tableUtils';
import { ROLE_TYPE_LABELS } from 'constants/labels';

export const useBenutzerColumns = (
    handleAction: AntragListAction<any>,
    showRolle?: boolean,
    disableActions?: boolean
): ((data: BenutzerListResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { isSupport } = useSpecificRole();
    const actions = useServerActionsColumn({ handleAction, disableActions });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nachname',
                    label: 'Name',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'vorname',
                    label: 'Vorname',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'email',
                    label: 'E-Mail-Adresse',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'telefon',
                    label: 'Telefon',
                    options: {
                        sort: false,
                        filter: false,
                    },
                },
                {
                    name: 'fax',
                    label: 'Fax',
                    options: {
                        sort: false,
                        filter: false,
                    },
                },
                {
                    name: 'isAdmin',
                    label: 'Admin',
                    options: {
                        sort: false,
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                            return getRowData(dataIndex, data)?.isAdmin ? 'Ja' : 'Nein';
                        },
                    },
                },
                {
                    name: 'rolle',
                    label: 'Rolle',
                    options: {
                        display: showRolle ?? 'excluded',
                        viewColumns: !!showRolle,
                        sort: false,
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);

                            if (!rowData?.rolle) {
                                return '-';
                            }

                            return ROLE_TYPE_LABELS[rowData.rolle];
                        },
                    },
                },
                {
                    name: 'deaktiviertAt',
                    label: 'Status',
                    options: {
                        ...BenutzerStatusFilterOptions(isSupport),
                        sort: false,
                        customBodyRender: (value) => {
                            return (
                                <BadgeColumn
                                    content={value ? 'Deaktiviert' : 'Aktiviert'}
                                    color={value ? 'warning' : 'success'}
                                />
                            );
                        },
                    },
                },
                actions(data),
            ];
        },
        [showRolle, isSupport, actions]
    );
};
