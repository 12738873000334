import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilderProps } from 'navigation/Paths';

interface UseListActionsProps {
    pathBuilder: PathBuilderProps;
}

export const useDeletedObjectsListActions = ({ pathBuilder }: UseListActionsProps) => {
    return useListActions(pathBuilder);
};
