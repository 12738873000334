import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetCollectionMeldung200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';
import { STATUS } from 'constants/antragStatus';

interface UseMeldungListData {
    status?: STATUS;
    statusList?: Array<STATUS>;
}

export const useMeldungListData = ({
    status,
    statusList,
}: UseMeldungListData): GetData<GetCollectionMeldung200Response> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) => {
            return backendApiService.getMeldungCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                orderModifiedAt: sortBy(sortOrder, 'modifiedAt'),
                orderZuletztEingereichtAt: sortBy(sortOrder, 'letzteMeldung'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtungName'),
                orderEinrichtungAdresseOrt: sortBy(sortOrder, 'ort'),
                orderEinrichtungArt: sortBy(sortOrder, 'art'),
                orderStatus: sortBy(sortOrder, 'status'),
                orderEinrichtungNrwKey: sortBy(sortOrder, 'nrwSchluessel'),
                existsEinrichtungDeleted: false,
                status,
                status2: filters?.['status']?.[0] ? [filters?.['status'][0]] : statusList,
            });
        },
        [status, statusList]
    );
};
