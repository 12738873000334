import * as React from 'react';
import { useMemo } from 'react';
import Info from '@mui/icons-material/Info';
import { FormControl, FormHelperText, styled, Tooltip, Typography } from '@mui/material';

import { HtmlEditor } from 'forms/components/HtmlEditor';
import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';

import { FlexDiv, FlexDivInner } from './components/FlexDiv';
import { RowControl } from './RowControl';

const MehrzeiligerTextControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    uischema,
    readonly,
    disabled,
    hasErrors,
    errors,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
}) => {
    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        (e) => e.target.value,
        onTouched,
        {
            validateOnBlur: false,
            validateOnChange: false,
        }
    );

    const { scrollRef } = useScrollInView<HTMLDivElement>({});

    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: 8,
    };

    const grid = { ...defaultGrid, ...gridLayout };

    const fieldLabel = useMemo(() => label || schema.title || schema.label, [label, schema]);

    return (
        <RowControl
            name={path}
            label={uischema?.options?.noLabel === true ? undefined : fieldLabel}
            required={required}
            multiline={uischema?.options?.multiline}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={grid}
            labelForId={id}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            label={fieldLabel}
                            hasErrors={hasErrors}
                            errors={errors}
                            text={data || '-'}
                        />
                    ) : (
                        <FlexDivInner>
                            <FormControl fullWidth ref={scrollRef} error={hasErrors}>
                                <StyledHeadline variant="h3">
                                    {fieldLabel}
                                    {required && <RequiredFlag />}
                                    {uischema.withInfoIcon && schema?.description ? (
                                        <StyledTooltip title={schema.description}>
                                            <Info />
                                        </StyledTooltip>
                                    ) : null}
                                </StyledHeadline>

                                <HtmlEditor
                                    id={id}
                                    value={tempData || ''}
                                    disabled={disabled}
                                    onBlur={(value) => handleOnBlur(value)}
                                    onChange={(value) => handleValueChange(value)}
                                />
                                {!uischema.withInfoIcon && schema?.description ? (
                                    <FormHelperText>{schema.description}</FormHelperText>
                                ) : null}

                                {hasErrors && (
                                    <FormHelperText component="div">
                                        <ErrorList errors={errors} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </FlexDivInner>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

const StyledHeadline = styled(Typography)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
    alignItems: 'center',
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

export const MehrzeiligerTextControl = withCustomControlProps(MehrzeiligerTextControlComponent);
