import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetCollectionMeldung200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useEinrichtungListData = ({
    einrichtungId,
}: {
    einrichtungId?: string;
}): GetData<GetCollectionMeldung200Response> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getMeldungCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                einrichtung: einrichtungId,
                orderEinrichtungNrwKey: sortBy(sortOrder, 'nrwSchluessel'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtungName'),
                orderEinrichtungArt: sortBy(sortOrder, 'art'),
                orderStatus: sortBy(sortOrder, 'status'),
                orderZuletztEingereichtAt: sortBy(sortOrder, 'letzteMeldung'),
            });
        },
        [einrichtungId]
    );
};
