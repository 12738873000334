import { useEffect, useState } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { CustomSortableColumns } from 'components/DataTable/components/CustomSortableColumns';
import { GetColumns, TableViewColumns } from 'components/DataTable/types';

export const useTableColumns = <Data = any,>(
    data: Data | undefined,
    filters: Record<string, any>,
    viewColumns: TableViewColumns,
    getColumns: GetColumns<Data>
): MUIDataTableColumnDef[] => {
    const [columns, setColumns] = useState(() =>
        populateViewColumns(populateFilters(getColumns(data), filters), viewColumns)
    );

    useEffect(() => {
        setColumns(populateViewColumns(populateFilters(getColumns(data), filters), viewColumns));
    }, [getColumns, data, filters, viewColumns]);

    return columns;
};

export const populateFilters = (
    columns: MUIDataTableColumnDef[],
    filters: Record<string, any>
): MUIDataTableColumnDef[] => {
    return columns.map((column) => {
        if (typeof column === 'string') {
            return column;
        } else {
            return {
                ...column,
                options: {
                    ...column.options,
                    filterList: filters[column.name] ? filters[column.name] : column.options?.filterList,
                },
            };
        }
    });
};

export const populateViewColumns = (
    columns: MUIDataTableColumnDef[],
    viewColumns: TableViewColumns
): MUIDataTableColumnDef[] => {
    return columns.map((column) => {
        if (typeof column === 'string') {
            return column;
        } else {
            return {
                ...column,
                options: {
                    ...CustomSortableColumns(),
                    ...column.options,
                    display: viewColumns[column.name],
                },
            };
        }
    });
};
