import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useSpecificRole } from 'api/auth/useHasRole';
import { GetEinrichtungenForPlatzzahlmelder200Response } from 'api/client';
import { EinrichtungenFuerPlatzzahlCollection } from 'api/types';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeStatus } from 'components/DataTable/components/BadgeStatus';
import { EinrichtungArtFilterOptions } from 'components/DataTable/filters/EinrichtungArtFilterOptions';
import { EinrichtungMeldungenStatusFilterOptions } from 'components/DataTable/filters/EinrichtungMeldungenStatusFilterOptions';
import { STATUS } from 'constants/antragStatus';
import {
    BELEGBARE_PLAETZE_STATUS_FILTER_LABELS,
    EINRICHTUNG_ART_BELEGBARE_PLAETZE_FILTER_LABELS,
} from 'constants/labels';
import { FormTableActions } from 'forms/controls/components/FormTableActions';
import { CollectionItemAction } from 'forms/types/UiSchemaTypes';
import { PathBuilder } from 'navigation/Paths';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

interface UsePlatzzahlmelderColumnsProps {
    token: string;
    getColumnActions: (
        id: string | number | null | undefined,
        antragStatus: STATUS
    ) => Array<CollectionItemAction> | undefined;
    handleCustomAction: (index: number, tableData: EinrichtungenFuerPlatzzahlCollection, action: string) => void;
    onAfterCustomAction?: () => void;
}

export const usePlatzzahlmelderColumns = ({
    token,
    getColumnActions,
    handleCustomAction,
    onAfterCustomAction,
}: UsePlatzzahlmelderColumnsProps): ((
    data: GetEinrichtungenForPlatzzahlmelder200Response | undefined
) => MUIDataTableColumnDef[]) => {
    const navigate = useNavigate();

    const { isBehoerde } = useSpecificRole();
    const { transform2Date } = useFormatDate();

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'name',
                    label: 'Name der Einrichtung',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'art',
                    label: 'Art der Einrichtung',
                    options: {
                        ...EinrichtungArtFilterOptions(EINRICHTUNG_ART_BELEGBARE_PLAETZE_FILTER_LABELS),
                        display: false,
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'belegbarePlaetze.dauerpflegeplaetze',
                    label: 'Belegbare vollstationäre Dauerpflegeplätze',
                    options: {
                        filter: false,
                        customBodyRenderLite: (index) => {
                            const belegbarePlaetze = data['hydra:member'][index]?.belegbarePlaetze;
                            return belegbarePlaetze && belegbarePlaetze.dauerpflegeplaetze
                                ? belegbarePlaetze.dauerpflegeplaetze
                                : 0;
                        },
                    },
                },
                {
                    name: 'belegbarePlaetze.kurzpflegeplaetze',
                    label: 'Belegbare vollstationäre Kurzzeitpflegeplätze',
                    options: {
                        filter: false,
                        customBodyRenderLite: (index) => {
                            const belegbarePlaetze = data['hydra:member'][index]?.belegbarePlaetze;
                            return belegbarePlaetze && belegbarePlaetze.kurzpflegeplaetze
                                ? belegbarePlaetze.kurzpflegeplaetze
                                : 0;
                        },
                    },
                },
                {
                    name: 'belegbarePlaetze.modifiedAt',
                    label: 'Letzte Meldung',
                    options: {
                        filter: false,
                        customBodyRenderLite: (index) => {
                            const belegbarePlaetze = data['hydra:member'][index]?.belegbarePlaetze;
                            return belegbarePlaetze && belegbarePlaetze.modifiedAt
                                ? transform2Date(belegbarePlaetze.modifiedAt, 'dd.MM.yyyy - HH:mm')
                                : '-';
                        },
                    },
                },
                {
                    name: 'belegbarePlaetze.aufgefordert',
                    label: 'Letzte Erinnerung',
                    options: {
                        filter: false,
                        customBodyRenderLite: (index) => {
                            const belegbarePlaetze = data['hydra:member'][index]?.belegbarePlaetze;
                            return belegbarePlaetze && belegbarePlaetze.aufgefordert
                                ? transform2Date(belegbarePlaetze.aufgefordert, 'dd.MM.yyyy - HH:mm')
                                : '-';
                        },
                    },
                },
                {
                    name: 'belegbarePlaetzeStatus',
                    label: 'Status der Meldung',
                    options: {
                        sort: false,
                        filter: true,
                        ...EinrichtungMeldungenStatusFilterOptions(BELEGBARE_PLAETZE_STATUS_FILTER_LABELS),
                        customBodyRender: (value) => <BadgeStatus status={value ?? STATUS.AUSSTEHEND} />,
                    },
                },
                {
                    name: '',
                    label: 'Aktion',
                    options: {
                        viewColumns: false,
                        sort: false,
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = data['hydra:member'][dataIndex];

                            return (
                                <FormTableActions
                                    viewable={isBehoerde && rowData?.belegbarePlaetze?.id !== undefined}
                                    onView={() =>
                                        navigate(
                                            PathBuilder.home.verwaltung.platzmelder.view({
                                                id: rowData?.belegbarePlaetze?.id,
                                                token,
                                            })
                                        )
                                    }
                                    showHistory={isBehoerde && !!rowData.eingereichtAt}
                                    onShowHistory={() =>
                                        navigate(PathBuilder.home.verwaltung.platzmelder.history(token, rowData.id!))
                                    }
                                    actions={getColumnActions(
                                        rowData?.belegbarePlaetze?.id,
                                        rowData?.belegbarePlaetzeStatus as STATUS
                                    )}
                                    onCustomAction={(index, action) => {
                                        handleCustomAction(index, rowData, action);
                                        onAfterCustomAction?.();
                                    }}
                                    index={dataIndex}
                                />
                            );
                        },
                    },
                },
            ];
        },
        [getColumnActions, handleCustomAction, isBehoerde, navigate, onAfterCustomAction, token, transform2Date]
    );
};
