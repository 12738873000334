import { useEffect } from 'react';

import { useAuthRefresh, useExpiresAt } from 'api/auth';
import { authConfig, authLog } from 'api/auth/AuthTypes';

/**
 * Hook to automatically refresh the token before it expires.
 */
export const useSessionRefresh = () => {
    const expiresAt = useExpiresAt();
    const refreshAuth = useAuthRefresh();

    /**
     * Runs every time the token's expiry changes.
     * Creates a timeout to automatically execute a token refresh shortly before token expiry.
     * Includes jitter to prevent multiple tabs from fetching a new token at the same time.
     */
    useEffect(() => {
        if (!expiresAt) return;
        const timeoutMillis = calculateTimeoutMillis(expiresAt);
        authLog('[UseSessionRefresh.InitializeSessionRefresh]', timeoutMillis);
        const timeout = setTimeout(() => {
            authLog('[UseSessionRefresh.RefreshToken]');
            return refreshAuth();
        }, timeoutMillis);
        return () => {
            clearTimeout(timeout);
        };
    }, [expiresAt, refreshAuth]);
};

const calculateTimeoutMillis = (expiresAt: number): number => {
    const now = Date.now();
    const expiration = new Date(expiresAt * 1000);
    const jitterMillis = randomInt(authConfig.refreshJitterMin, authConfig.refreshJitterMax);
    return expiration.getTime() - now - jitterMillis;
};

const randomInt = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
