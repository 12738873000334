import { useEffect, useState } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';

import { TableFilters } from 'components/DataTable/hooks/useTableFilters';
import { getExistingParams } from 'components/DataTable/hooks/useTableUrlParams';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';
import { parseFiltersFromUrl } from 'utilities/Utils';

export type Filters = Record<string, string[]>;

export const usePlatzmelderUrlParams = (
    filters: Filters,
    searchText: string,
    updateFilters: (filters: Filters) => void,
    [params, setParams]: [URLSearchParams, SetURLSearchParams],
    updateSearchText?: (searchText: string) => void,
    token?: string
) => {
    const { initialFilters, initialSearchText } = useInitialParamsFromUrl(params);

    useDebouncedEffect(
        () => {
            const nextSearchParams = convertPlatzmelderParams(filters, searchText, { filters: ['ALL'] });
            const existingParams = getExistingParams(params);

            setParams({ ...nextSearchParams, ...existingParams }, { replace: true });
        },
        [searchText, filters, token],
        500
    );

    useEffect(() => {
        if (initialFilters !== undefined) updateFilters(initialFilters);
    }, [updateFilters, initialFilters]);

    useEffect(() => {
        if (initialSearchText !== undefined) updateSearchText?.(initialSearchText);
    }, [updateSearchText, initialSearchText]);
};

type UseInitialParamsFromUrlResult = {
    initialFilters: TableFilters | undefined;
    initialSearchText: string | undefined;
};

const useInitialParamsFromUrl = (params: URLSearchParams): UseInitialParamsFromUrlResult => {
    const [result] = useState(() => {
        let initialFilters: TableFilters | undefined = undefined;
        let initialSearchText: string | undefined = undefined;

        params.forEach((value, key) => {
            initialFilters = parseFiltersFromUrl({ key, value }, initialFilters);

            if (key === 'searchText' && value.length > 0) {
                initialSearchText = value;
            }
        });

        return { initialFilters, initialSearchText };
    });

    return result;
};

const convertPlatzmelderParams = (
    filters: TableFilters,
    searchText: string,
    defaultFilters: TableFilters
): Record<string, string | string[]> => {
    const filterParams = Object.keys(filters).reduce((agg, curr) => {
        if (isEqual(defaultFilters[curr], filters[curr])) return agg;
        return { ...agg, [`filter[${curr}]`]: filters[curr] };
    }, {});

    const searchTextParams = searchText ? { searchText } : undefined;

    return { ...filterParams, ...searchTextParams };
};
