import React, { SyntheticEvent, useCallback, useState } from 'react';
import { FiberManualRecord } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { LoaderApp } from 'components/AppLoaderContainer';
import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_ACTION_LABELS } from 'constants/labels';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

interface ReadStatusData {
    id?: number;
    read?: boolean;
    allowedWorkflowActions?: Array<string>;
}

interface ReadStatusProps {
    data: ReadStatusData | undefined;
    rowIndex: number;
    onStatusChanged: () => void;
}

export const ReadStatus = ({ rowIndex, data, onStatusChanged }: ReadStatusProps) => {
    const [currentRowIndex, setCurrentRowIndex] = useState<number | undefined>(undefined);
    const { setRequestMessage, progressStart, progressEnd, isInProgress, antragId } = useFormState();
    const { loadBenutzerAufgaben } = useBenutzerAufgaben();

    const isRead = !!data?.read;
    const hintText = ANTRAG_ACTION_LABELS[isRead ? ACTIONS.UNGELESEN : ACTIONS.GELESEN];

    const handleReadStatus = useCallback(
        (event: SyntheticEvent, emailId?: number, rowIndex?: number, isRead: boolean = false) => {
            event.stopPropagation();

            progressStart();
            setCurrentRowIndex(rowIndex);

            backendApiService
                .workflowEmailAntrag(Number(emailId), isRead ? ACTIONS.UNGELESEN : ACTIONS.GELESEN)
                ?.then(() => {
                    loadBenutzerAufgaben();
                    onStatusChanged();
                })
                ?.catch(() => setRequestMessage(errorMessage('Fehler beim ändern des Status')))
                ?.finally(() => {
                    progressEnd();
                    setCurrentRowIndex(undefined);
                });
        },
        [loadBenutzerAufgaben, onStatusChanged, progressEnd, progressStart, setRequestMessage]
    );

    if (![ACTIONS.UNGELESEN, ACTIONS.GELESEN].some((action) => data?.allowedWorkflowActions?.includes(action))) {
        return <></>;
    }

    return (
        <Tooltip title={hintText}>
            <IconButton
                aria-label={hintText}
                color={isRead ? 'default' : 'primary'}
                size="large"
                onClick={(event) => handleReadStatus(event, data?.id, rowIndex, isRead)}
            >
                {isInProgress && (antragId === data?.id || currentRowIndex === rowIndex) ? (
                    <LoaderApp size={28} />
                ) : (
                    <FiberManualRecord fontSize="inherit" />
                )}
            </IconButton>
        </Tooltip>
    );
};
