import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { useLeistungsangebotAntragListAction } from 'components/DataTable/actions/useLeistungsangebotAntragListAction';
import { useLeistungsangebotFreigegebeneListAction } from 'components/DataTable/actions/useLeistungsangebotFreigegebeneListAction';
import { useAnbieterFreigegebeneColumns } from 'components/DataTable/columns/leistungsangebot/useAnbieterFreigegebeneColumns';
import { useAnbieterLeistungsangeboteColumns } from 'components/DataTable/columns/leistungsangebot/useAnbieterLeistungsangeboteColumns';
import { GetColumns } from 'components/DataTable/types';
import { useEinrichtungListData } from 'components/DataTable/useEinrichtungListData';
import { STATUS } from 'constants/antragStatus';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder, useLeistungsangebotSegment } from 'navigation/Paths';
import { LeistungsangeboteOverviewTable } from 'pages/Leistungsangebote/LeistungsangeboteOverviewTable';
import { useLeistungsangebotListData } from 'pages/Leistungsangebote/useLeistungsangebotListData';

export const LeistungsangeboteAnbieterOverview = () => {
    const location = useLocation();
    const theme = useTheme();

    const pathBuilderFreigegebene = PathBuilder.home.verwaltung.leistungsangebote.anbieter.freigegebene;

    const segment = useLeistungsangebotSegment();

    const { serverDataChanged, setServerDataChanged } = useLeistungsangebotAntragListAction(segment);

    const { handleAction } = useLeistungsangebotFreigegebeneListAction(pathBuilderFreigegebene);

    const columnsEinrichtung = useAnbieterFreigegebeneColumns(handleAction);

    const columns = useAnbieterLeistungsangeboteColumns(setServerDataChanged, segment);

    const getData = useLeistungsangebotListData({});

    const getEinrichtungData = useEinrichtungListData({ status: STATUS.FREIGEGEBEN });

    return (
        <ContentContainer title="Leistungsangebote Übersicht">
            <StyledContainer>
                <LeistungsangeboteOverviewTable
                    getData={getData}
                    columns={columns}
                    serverDataChanged={serverDataChanged}
                    pathBuilder={PathBuilder.home.verwaltung.leistungsangebote.anbieter.entwuerfe}
                    segment={segment}
                    marginBottom={theme.spacing(6)}
                    headline="Leistungsangebote Übersicht"
                    nameOverride={`${location.pathname}-entwuerfe`}
                />

                <LeistungsangeboteOverviewTable
                    getData={getEinrichtungData}
                    columns={columnsEinrichtung as GetColumns}
                    serverDataChanged={serverDataChanged}
                    pathBuilder={pathBuilderFreigegebene}
                    segment={segment}
                    card={{
                        overview: {
                            subHeadline: 'Freigegebene Leistungsangebote',
                            subDescription: ' ',
                        },
                    }}
                    nameOverride={`${location.pathname}-freigegebene`}
                    hideHeader
                />
            </StyledContainer>
        </ContentContainer>
    );
};
