import * as React from 'react';
import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { InlineResponseTraegerverbandResponse } from 'api/responses/InlineResponse';
import { TraegerverbandData } from 'api/types';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { ReadonlyValueText } from './components/ReadonlyValueText';
import { RowControl } from './RowControl';

const TraegerverbandAutocompleteComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    handleChange,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
    readonly,
    hasErrors,
    errors,
}) => {
    const handleValueChange = useCallback(
        (id: SelectOption | null) => {
            handleChange(path, id, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, options: AxiosRequestConfig): Promise<InlineResponseTraegerverbandResponse> => {
        return backendApiService.getTraegerverbandCollection({
            page: 1,
            itemsPerPage: 10,
            qSearch: value,
            options: {
                signal: options.signal,
            },
        });
    };

    const getOption = (item: TraegerverbandData): SelectOption => {
        return {
            id: item.id as number,
            name: item.name,
        };
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            {readonly ? (
                <ReadonlyValueText hasErrors={hasErrors} errors={errors} label={label} text={data?.name ?? '-'} />
            ) : (
                <Typeahead
                    id={id}
                    data={data}
                    schema={schema}
                    uischema={uischema}
                    path={path}
                    multiple={false}
                    getData={getData}
                    getOption={getOption}
                    onChange={handleValueChange}
                    required={required}
                />
            )}
        </RowControl>
    );
};

export const TraegerverbandAutocompleteControl = withCustomControlProps(TraegerverbandAutocompleteComponent);
