import React, { ReactElement } from 'react';
import { LayoutProps, RankedTester, rankWith } from '@jsonforms/core';
import { JsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Button, Grid, GridSize, styled } from '@mui/material';
import { isEqual } from 'lodash';

import { RowControl } from 'forms/controls/RowControl';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import {
    FormConfig,
    FormStateChange,
    FormStateValue,
    InlineGroupRowActionOptions,
    InlineGroupRowControlType,
    Schema,
    UiSchemaType,
} from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaByUUID } from 'forms/utilities/SchemaUtils';

import { useInlineGroupRowControl } from './hooks/useInlineGroupRowControl';

interface InlineGroupRowControlProps extends Omit<LayoutProps, 'uischema'> {
    uischema: InlineGroupRowControlType;
}

const InlineGroupRowControlComponent = ({ schema, uischema, data }: InlineGroupRowControlProps) => {
    const { config, handleChange } = useJsonFormsState();

    const { collectionField, collectionData, collectionSchema, fieldName, fieldLabel, fieldFormula, isRequiredField } =
        useInlineGroupRowControl(data, uischema, schema);

    if (collectionData.length === 0) {
        return <></>;
    }

    const calculatedGridSize = uischema.gridSize ?? 12 / collectionData.length;
    return (
        <RowControl
            name={fieldName}
            label={fieldLabel}
            required={isRequiredField}
            showFieldNumberLabels={config.showFieldNumberLabels}
            gridLayout={uischema.gridLayout || config.gridLayout}
            formula={fieldFormula}
            rowAction={
                uischema.action ? (
                    <InlineGroupRowControlAction
                        action={uischema.action}
                        data={collectionData}
                        fieldName={fieldName}
                        collectionField={collectionField}
                        onChange={handleChange}
                    />
                ) : undefined
            }
        >
            <Grid container spacing={2}>
                {collectionData.map((d: FormStateValue, i: number) => {
                    return (
                        <InlineGroupFormControl
                            key={i}
                            index={i}
                            data={d}
                            schema={determineSchemaByUUID(d.uuid, collectionSchema)}
                            gridSize={calculatedGridSize}
                            scope={uischema.rowScope}
                            highlighted={i % 2 !== 0}
                            collectionField={collectionField}
                            config={config}
                            onChange={handleChange}
                        />
                    );
                })}
            </Grid>
        </RowControl>
    );
};

const InlineGroupFormControl: React.FC<{
    index: number;
    data: FormStateValue;
    collectionField: string;
    scope: string;
    gridSize: GridSize;
    schema: Schema;
    highlighted: boolean;
    config: FormConfig;
    onChange: (path: string, data: FormStateValue) => void;
}> = ({ index, data, collectionField, scope, gridSize, schema, highlighted, config, onChange }) => {
    const onStateChange = (state: FormStateChange) => {
        if (isEqual(state.data, data)) return;
        onChange(buildPath(collectionField, index), state.data);
    };

    const GridItem = highlighted ? HighlightedGrid : Grid;
    return (
        <GridItem item xs={12} md={gridSize}>
            <JsonForms
                schema={schema}
                uischema={{ type: 'Control', scope, controlOnly: true } as UiSchemaType}
                data={data}
                renderers={renderers}
                onChange={onStateChange}
                config={{ ...config, path: buildPath(config.path, collectionField, index) }}
            />
        </GridItem>
    );
};

type InlineGroupRowControlActionProps = {
    action: InlineGroupRowActionOptions;
    data: FormStateValue[];
    fieldName: string;
    collectionField: string;
    onChange: (path: string, data: FormStateValue) => void;
};

const InlineGroupRowControlAction = ({
    action: { label, value },
    data,
    fieldName,
    collectionField,
    onChange,
}: InlineGroupRowControlActionProps): ReactElement => {
    const onAction = () => {
        onChange(
            collectionField,
            data.map((d) => ({ ...d, [fieldName]: value }))
        );
    };

    return <Button onClick={onAction}>{label}</Button>;
};

export const HighlightedGrid = styled(Grid)(
    ({ theme }) => `
    background: ${theme.palette.background.surfaceHighlighted};`
);

// @ts-ignore
export const InlineGroupRowControl = withJsonFormsLayoutProps(InlineGroupRowControlComponent);

export const InlineGroupRowControlTester: RankedTester = rankWith(
    1,
    (uischema) => uischema.type === 'InlineGroupRowControl'
);
