import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { EinrichtungCollectionResponse, MeldungenStatus } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { STATUS } from 'constants/antragStatus';

import { sortBy } from './tableUtils';

interface UseEinrichtungListData {
    status?: STATUS;
    meldungenStatus?: MeldungenStatus;
    statusList?: Array<STATUS>;
}

export const useEinrichtungListData = ({
    status,
    statusList,
    meldungenStatus,
}: UseEinrichtungListData): GetData<EinrichtungCollectionResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) => {
            const zustaendigerBenutzer =
                filters?.['zustaendig'] && filters?.['zustaendig'].length
                    ? [Number(filters?.['zustaendig'])]
                    : undefined;

            return backendApiService.getEinrichtungCollection({
                page,
                itemsPerPage: rowsPerPage,
                status: !filters?.['letzterStatus']?.length ? status : undefined,
                status2: filters?.['letzterStatus'] || statusList,
                art2: filters?.['einrichtungsArt'],
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderArt: sortBy(sortOrder, 'einrichtungsArt'),
                orderNrwKey: sortBy(sortOrder, 'nrwKey'),
                orderErstinbetriebnahme: sortBy(sortOrder, 'datumInbetriebnahme'),
                orderStatus: sortBy(sortOrder, 'status'),
                orderLetzteMeldung: sortBy(sortOrder, 'letzteMeldung'),
                orderRegelpruefungenPruefDatum: sortBy(sortOrder, 'letzteRegelpruefung'),
                orderRegelpruefungenLetzterUploadAt: sortBy(sortOrder, 'letzterPruefbericht'),
                orderZustaendig: sortBy(sortOrder, 'zustaendig'),
                orderAdressePlz: sortBy(sortOrder, 'plz'),
                orderAdresseOrt: sortBy(sortOrder, 'ort'),
                existsDeleted: false,
                zustaendigerBenutzer,
                wohngemeinschaftArt: filters?.['wohngemeinschaftArt']?.[0],
                wohngemeinschaftLeistungsbereich: filters?.['wohngemeinschaftLeistungsbereich']?.[0],
                meldungenStatus: (filters?.['meldungenStatus']?.[0] as MeldungenStatus) || meldungenStatus,
            });
        },
        [status, statusList, meldungenStatus]
    );
};
