import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { EinrichtungCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { EinrichtungArtFilterOptions } from 'components/DataTable/filters/EinrichtungArtFilterOptions';
import {
    BenutzerOptions,
    ZustaendigerBenutzerFilterOptions,
} from 'components/DataTable/filters/ZustaendigerBenutzerFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { centerAlignedColumn } from 'components/DataTable/tableUtils';
import { EINRICHTUNG_ART_FILTER_LABELS } from 'constants/labels';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

export const useAnbieterFreigegebeneColumns = (
    handleAction: AntragListAction<any>,
    zustaendigerBenutzerOptions?: BenutzerOptions
): ((data: EinrichtungCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { transform2Date } = useFormatDate();
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'name',
                    label: 'Name',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    options: {
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                {
                    name: 'datumInbetriebnahme',
                    label: 'Datum der Inbetriebnahme',
                    options: {
                        ...centerAlignedColumn,
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? transform2Date(value) : '-';
                        },
                    },
                },
                {
                    name: 'einrichtungsArt',
                    label: 'Art des Leistungsangebots',
                    options: {
                        ...EinrichtungArtFilterOptions(EINRICHTUNG_ART_FILTER_LABELS),
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                {
                    name: 'letzteMeldung',
                    label: 'Letzte Meldung',
                    options: {
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? transform2Date(value, 'dd.MM.yyyy HH:ii') : '-';
                        },
                    },
                },
                {
                    name: 'zustaendig',
                    label: 'Zuständig',
                    options: {
                        ...(zustaendigerBenutzerOptions
                            ? ZustaendigerBenutzerFilterOptions(zustaendigerBenutzerOptions, false)
                            : {}),
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                actions(data),
            ];
        },
        [zustaendigerBenutzerOptions, actions, transform2Date]
    );
};
