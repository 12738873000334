import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { GetEinrichtungenForFem200Response } from 'api/client';
import { AntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeColumn } from 'components/DataTable/components/BadgeColumn';
import { EinrichtungArtFilterOptions } from 'components/DataTable/filters/EinrichtungArtFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDateTime } from 'components/DataTable/tableUtils';

export const useFemEinrichtungColumns = (
    handleAction: AntragListAction<any>,
    filterOptions: AntragTableFilterOptions | undefined
): ((data: GetEinrichtungenForFem200Response | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwSchluessel',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'einrichtungsart',
                    label: 'Leistungsart',
                    options: {
                        ...EinrichtungArtFilterOptions(filterOptions?.leistungsart || {}),
                    },
                },
                {
                    name: 'name',
                    label: 'Name der Einrichtung',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'gerichtlichGesamtanzahl',
                    label: 'Anzahl gerichtliche Genehmigung (Gesamtanzahl)',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'einwilligungGesamtanzahl',
                    label: 'Anzahl der Einwilligungen (Gesamtanzahl)',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'letzteMeldung',
                    label: 'Letzte Meldung',
                    options: {
                        filter: false,
                        customBodyRender: getDateTime,
                    },
                },
                {
                    name: 'letzteErinnerung',
                    label: 'Letzte Erinnerung',
                    options: {
                        filter: false,
                        customBodyRender: getDateTime,
                    },
                },
                {
                    name: 'ueberfaelligeMeldungen',
                    label: 'Anzahl Quartale im Verzug',
                    options: {
                        filter: false,
                        customBodyRender: (value) => {
                            return <BadgeColumn content={value} color={value > 0 ? 'warning' : 'success'} />;
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions, filterOptions?.leistungsart]
    );
};
