import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { BelegbarePlaetzeHistorie200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const usePlatzmelderHistoryListData = (einrichtungId?: string): GetData<BelegbarePlaetzeHistorie200Response> => {
    return useCallback(
        ({ page, rowsPerPage, sortOrder }) => {
            return backendApiService.getPlatzmelderHistorie({
                page,
                itemsPerPage: rowsPerPage,
                einrichtung: einrichtungId,
                orderCreatedAt: sortBy(sortOrder, 'createdAt'),
            });
        },
        [einrichtungId]
    );
};
