import { captureException, withScope } from '@sentry/react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    AdresseApi,
    AdresseJsonldAdresseRead,
    AngezeigteBetreuungFormApi,
    ApiAuswertung44VWtgGetCollection200Response,
    ApiBenutzerListeGetCollection200Response,
    ApiBezirksregierungGetCollection200Response,
    ApiLeistungsanbieterGetCollection200Response,
    ApiRegelpruefungAntragGetCollection200Response,
    ApiRegionGetCollection200Response,
    ApiUnternehmensgruppeGetCollection200Response,
    BehoerdeApi,
    BehoerdeDtoApi,
    BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung,
    BelegbarePlaetzeApi,
    BelegbarePlaetzeGeneriertesDokumentJsonld,
    BelegbarePlaetzeHistorie200Response,
    BelegbarePlaetzeHistorieApi,
    BelegbarePlaetzeJsonld,
    BenutzerApi,
    BenutzerAufgabenOutputJsonldBenutzerAufgaben,
    BenutzerCredentialChangeConfirmInput,
    BenutzerCredentialChangeRequestInput,
    BenutzerDtoApi,
    BenutzerDtoJsonld,
    BenutzerForm,
    BenutzerFormApi,
    BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm,
    BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm,
    BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles,
    BenutzerProfileEdit,
    BenutzerTablePreference,
    BenutzerTablePreferenceApi,
    BezirksregierungApi,
    EinrichtungApi,
    EmailAntrag,
    EmailAntragApi,
    EmailAntragJsonld,
    EmailAntragJsonldEmailAntragWrite,
    EmailtextApi,
    EmailtextJsonldEmailtextWrite,
    ExportRequestApi,
    FemAntrag,
    FemAntragApi,
    FemAntragJsonld,
    FemExportApi,
    GeloeschtesObjektApi,
    GetCollectionMeldung200Response,
    GetEinrichtungenForFem200Response,
    GetEinrichtungenForPlatzzahlmelder200Response,
    GetEinrichtungenForRegelpruefung200Response,
    HeimfinderprofilApi,
    InvestRegistrationApi,
    LandschaftsverbandApi,
    LandschaftsverbandDtoApi,
    LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
    Leistungsanbieter,
    LeistungsanbieterApi,
    LeistungsanbieterJsonld,
    LeistungsangebotRegistrieren,
    LeistungsangebotRegistrierenApi,
    LeistungsangebotRegistrierenJsonld,
    MassenmailApi,
    MassenmailJsonldMassenmailWrite,
    Meldung,
    MeldungApi,
    MeldungFreigabeDokumentApi,
    MeldungHinweisdokumentApi,
    MeldungHinweistextApi,
    MeldungHinweistextJsonldMeldungHinweistextWrite,
    MeldungHinweistextMeldungHinweistextWrite,
    MeldungJsonld,
    MeldungJsonldMeldungRead,
    PlatzzahlmelderTokenApi,
    PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead,
    RegelpruefungAntrag,
    RegelpruefungAntragApi,
    RegelpruefungAntragJsonld,
    RegelpruefungAntragJsonldRegelpruefungRead,
    RegionApi,
    RegistrierungApi,
    RegistrierungJsonld,
    SignaturApi,
    SignaturSignaturWrite,
    StandardauswertungApi,
    TableFilterApi,
    TraegerApi,
    TraegerDtoApi,
    TraegerDtoJsonld,
    TraegerDtoTraegerWriteRolleEinrichtung,
    TraegerverbandApi,
    TraegerverbandDtoApi,
    TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
    UnternehmensgruppeApi,
    VerifizierungsMailApi,
    VerifizierungsMailJsonldVerifizierungsmailWrite,
    WohngemeinschaftApi,
    WohngemeinschaftJsonld,
    ZugehoerigeEinrichtungenDTOApi,
    ZugriffsrechteApi,
} from 'api/client';
import { FileUploadResult } from 'api/responses/FileUploadResponse';
import { URL_BEHOERDE, URL_BENUTZER, URL_EINRICHTUNG, URL_HEIMFINDER_UPLOAD, URL_UPLOAD } from 'api/routes';
import {
    AdresseLookupParams,
    AngezeigteBetreuungFormData,
    BehoerdenListOptionParams,
    BehoerdenListResponse,
    BelegbarePlaetzeHistorieParams,
    BenutzerEinrichtungenListResponse,
    BenutzerListCollectionParams,
    BenutzerListOptionParams,
    BenutzerListResponse,
    BenutzerProfileResponse,
    BenutzerResponse,
    BenutzerTablePreferenceResponse,
    BenutzerZugehoerigeEinrichtungen,
    BenutzerZugriffsrechteListCollection,
    BenutzerZugriffsrechteListResponse,
    BezirksregierungListOptionParams,
    DeletecObjectCollectionResponse,
    DeletedObjectCollectionParams,
    DokumentPruefungCollectionParams,
    DokumentPruefungFormData,
    DokumentPruefungListResponse,
    EinrichtungApiFormGetParams,
    EinrichtungApiFormSubmitParams,
    EinrichtungCollectionResponse,
    EinrichtungExportParams,
    EinrichtungExportResponse,
    EinrichtungFormResponse,
    EinrichtungGetByIdResponse,
    EinrichtungListOptionParams,
    EmailAntragCollectionParams,
    EmailAntragCollectionResponse,
    EmailAntragData,
    EmailAntragItem,
    ExportRequestCollectionParams,
    ExportRequestCollectionResponse,
    ExportRequestParams,
    ExportRequestResponse,
    FemAntragCollectionResponse,
    FemAntragDetailResponse,
    FemAntragResponse,
    FemCollectionParams,
    FemExportCollectionParams,
    FemExportRequestParams,
    InvestRegistrationCollectionParams,
    InvestRegistrationCollectionResponse,
    InvestRegistrationFormData,
    InvestRegistrationItem,
    LandschaftsverbandCollectionResponse,
    LandschaftsverbandItem,
    LandschaftsverbandListOptionParams,
    LeistungsanbieterCollectionParams,
    LeistungsanbieterFormResponse,
    LeistungsangebotData,
    LeistungsangebotEntwurfCollectionResponse,
    LeistungsangebotEntwurfListOptions,
    LeistungsangebotFormResponse,
    LeistungsangebotJsonParams,
    MassenmailCollectionParams,
    MassenmailCollectionResponse,
    MassenmailFormData,
    MassenmailFormGet,
    MassenmailFormResponse,
    MeldungCollectionParams,
    MeldungEinrichtungPatchResponse,
    MeldungEinrichtungResponse,
    MeldungHinweisDokumentCollectionParams,
    MeldungHinweisDokumentFormData,
    MeldungHinweisDokumentListResponse,
    MeldungHinweistextCollectionParams,
    MeldungHinweistextListResponse,
    ProfilHeimfinderFormData,
    ProfilHeimfinderFormGet,
    RegelpruefungAntragCollectionParams,
    RegelpruefungAntragFormData,
    RegelpruefungFileExportParams,
    RegelpruefungPruefberichteCollectionParams,
    RegelpruefungPruefberichtResponse,
    RegelpruefungPruefberichtStatusItem,
    RegionListOptionParams,
    StandardauswertungCsvExportParams,
    StandardauswertungRequestParams,
    TableFilterListResponse,
    TraegerCreateFormData,
    TraegerDtoResponse,
    TraegerExportParams,
    TraegerExportResponse,
    TraegerListOptionParams,
    TraegerListResponse,
    TraegerResponse,
    TraegerverbandData,
    TraegerverbandListOptionParams,
    TraegerverbandListResponse,
    UnternehmensgruppeParams,
    WohngemeinschaftCollectionParams,
    WohngemeinschaftCollectionResponse,
    WohngemeinschaftExportParams,
    WohngemeinschaftExportResponse,
    WohngemeinschaftFormData,
    WohngemeinschaftFormGet,
    WohngemeinschaftSubmitData,
    WohngemeinschaftWorkflowResponse,
} from 'api/types';
import { defaultUserData, getErrorLevel } from 'components/Sentry/useSentry';
import { EMAIL_ANTRAG_ART } from 'constants/emailAntragArt';
import { FormResponse } from 'forms/types/UiSchemaTypes';
import { toIriId } from 'utilities/IriUtils';

import { ApiBaseService } from './ApiBaseService';

export const BACKEND_API_BASE_URL = process.env.REACT_APP_API_URL as string;

export class ApiService extends ApiBaseService {
    private setAdditionalRequestHeaders(options?: AxiosRequestConfig) {
        return options?.headers;
    }

    public setResponseInterceptor(id: number | null): number {
        if (id) {
            this.client.interceptors.response.eject(id);
        }

        return this.client.interceptors.response.use(
            (res) => res,
            async (error: AxiosError) => {
                if (error.status !== 404) {
                    withScope((scope) => {
                        scope.setLevel(getErrorLevel(Number(error.status)));
                        captureException(error, { user: defaultUserData });
                    });
                }

                return Promise.reject(error);
            }
        );
    }

    public removeResponseInterceptor(id: number) {
        this.client.interceptors.response.eject(id);
    }

    public setImpersonateUser(email?: string): void {
        if (email) {
            this.client.defaults.headers['x-switch-user'] = email;
        } else {
            delete this.client.defaults.headers['x-switch-user'];
        }
    }

    public postRegistrierung(data: RegistrierungJsonld): Promise<RegistrierungJsonld> {
        return new RegistrierungApi(undefined, '', this.client)
            .apiRegistrierungPost(data)
            .then((response) => response.data);
    }

    public getBenutzerAufgaben(options?: AxiosRequestConfig): Promise<BenutzerAufgabenOutputJsonldBenutzerAufgaben> {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfilelvAufgabenGet(options)
            .then((res) => res.data as BenutzerAufgabenOutputJsonldBenutzerAufgaben)
            .catch((error) => error);
    }

    public getAddress({
        query,
        nrwOnly,
        regionalKey,
        page,
        itemsPerPage,
        options,
    }: AdresseLookupParams): Promise<AdresseJsonldAdresseRead> {
        return new AdresseApi(undefined, '', this.client)
            .apiAdresselookupGetCollection(query, nrwOnly, regionalKey, page, itemsPerPage, options)
            .then((res) => {
                return res.data as AdresseJsonldAdresseRead;
            });
    }

    public getUnternehmensgruppeCollection({
        page,
        itemsPerPage,
        name,
        approved,
        approved2,
        options,
    }: UnternehmensgruppeParams): Promise<ApiUnternehmensgruppeGetCollection200Response> {
        return new UnternehmensgruppeApi(undefined, '', this.client)
            .apiUnternehmensgruppeGetCollection(page, itemsPerPage, name, approved, approved2, options)
            .then((res) => res.data as ApiUnternehmensgruppeGetCollection200Response);
    }

    public getBenutzerItem(id: string): Promise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
        return new BenutzerApi(undefined, '', this.client).apiBenutzerIdGet(id).then((res) => res.data);
    }

    public getBenutzerDtoItem(id: string): Promise<BenutzerDtoJsonld> {
        return new BenutzerDtoApi(undefined, '', this.client)
            .apiBenutzerDtoIdGet(id)
            .then((res) => res.data as BenutzerDtoJsonld);
    }

    public getBenutzerCollection({
        page,
        itemsPerPage,
        existsDeaktiviertAt,
        existsDeletedAt,
        id,
        id2,
        rolle,
        rolle2,
        landschaftsverband,
        landschaftsverband2,
        behoerde,
        behoerde2,
        bezirksregierung,
        bezirksregierung2,
        traeger,
        traeger2,
        einrichtungBenutzerEinrichtung,
        einrichtungBenutzerEinrichtung2,
        traegerverband,
        traegerverband2,
        email,
        email2,
        qSearch,
        qImpersonateSearch,
        orderNachname,
        orderVorname,
        orderEmail,
        orderDeaktiviertAt,
        options,
    }: BenutzerListOptionParams): Promise<BenutzerListResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .apiBenutzerGetCollection(
                page,
                itemsPerPage || 9999,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            )
            .then((res) => res.data as BenutzerListResponse);
    }

    public getBenutzerListCollection = ({
        page,
        itemsPerPage,
        existsDeaktiviertAt,
        existsDeletedAt = false,
        id,
        id2,
        rolle,
        rolle2,
        landschaftsverband,
        landschaftsverband2,
        behoerde,
        behoerde2,
        bezirksregierung,
        bezirksregierung2,
        traeger,
        traeger2,
        einrichtungBenutzerEinrichtung,
        einrichtungBenutzerEinrichtung2,
        traegerverband,
        traegerverband2,
        email,
        email2,
        qSearch,
        qImpersonateSearch,
        orderNachname,
        orderVorname,
        orderEmail,
        orderDeaktiviertAt,
    }: BenutzerListCollectionParams): Promise<ApiBenutzerListeGetCollection200Response> => {
        return new BenutzerApi(undefined, '', this.client)
            .apiBenutzerListeGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt
            )
            .then((res) => res.data as ApiBenutzerListeGetCollection200Response);
    };

    public workflowBenutzerItem(id?: number, action?: string) {
        return new BenutzerApi(undefined, '', this.client)
            .apiBenutzerIdworkflowPatch(String(id), { action: String(action) })
            .then((res) => res.data);
    }

    public async getBenutzerTablePreference(name: string): Promise<BenutzerTablePreferenceResponse> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .apiBenutzerTablePreferenceGetCollection(undefined, undefined, name)
            .then((res) => res.data?.['hydra:member']?.[0] as BenutzerTablePreferenceResponse);
    }

    public async postBenutzerTablePreference(data: BenutzerTablePreference): Promise<BenutzerTablePreference> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .apiBenutzerTablePreferencePost(data)
            .then((res) => res.data as BenutzerTablePreference);
    }

    public async putBenutzerTablePreference(
        id: string,
        data: BenutzerTablePreference
    ): Promise<BenutzerTablePreference> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .apiBenutzerTablePreferenceIdPatch(id, data)
            .then((res) => res.data as BenutzerTablePreference);
    }

    public async deleteBenutzerTablePreference(id: string): Promise<void> {
        return new BenutzerTablePreferenceApi(undefined, '', this.client)
            .apiBenutzerTablePreferenceIdDelete(id)
            .then((res) => res.data);
    }

    public getBenutzerEinrichtungen({
        benutzer,
        page,
        itemsPerPage,
        options,
    }: BenutzerZugehoerigeEinrichtungen): Promise<BenutzerEinrichtungenListResponse> {
        return new ZugehoerigeEinrichtungenDTOApi(undefined, '', this.client)
            .apiZugehoerigeEinrichtungenDTOGetCollection(benutzer, page, itemsPerPage, options)
            .then((res) => res.data as BenutzerEinrichtungenListResponse);
    }

    public getBehoerdenCollection({
        page,
        itemsPerPage,
        qSearch,
        orderName,
        orderVerantwortlicheBehoerde,
        orderAdresseEmail,
        orderAdresseName,
    }: BehoerdenListOptionParams): Promise<BehoerdenListResponse> {
        return new BehoerdeApi(undefined, '', this.client)
            .apiBehoerdeGetCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                orderName,
                orderVerantwortlicheBehoerde,
                orderAdresseEmail,
                orderAdresseName
            )
            .then((res) => res.data as BehoerdenListResponse);
    }

    public getBehoerdeDtoItem({ id }: { id: string }): Promise<BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung> {
        return new BehoerdeDtoApi(undefined, '', this.client)
            .apiBehoerdeDtoIdGet(id)
            .then((res) => res.data as BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung);
    }

    public patchBehoerdenItem(id: string, behoerdeBehoerdeWrite: BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung) {
        return new BehoerdeDtoApi(undefined, '', this.client)
            .apiBehoerdeDtoIdPatch(id, behoerdeBehoerdeWrite)
            .then((res) => res.data);
    }

    public getTraegerverbandCollection({
        page,
        itemsPerPage,
        id,
        id2,
        qSearch,
        orderName,
        orderAdresseEmail,
        orderAdresseName,
        options,
    }: TraegerverbandListOptionParams): Promise<TraegerverbandListResponse> {
        return new TraegerverbandApi(undefined, '', this.client)
            .apiTraegerverbandGetCollection(
                page,
                itemsPerPage || 9999,
                id,
                id2,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            )
            .then((res) => res.data as TraegerverbandListResponse);
    }

    public getTraegerverbandDtoItem(id: string): Promise<TraegerverbandData> {
        return new TraegerverbandDtoApi(undefined, '', this.client)
            .apiTraegerverbandDtoIdGet(id)
            .then((res) => res.data as TraegerverbandData);
    }

    public patchTraegerverbandItem(
        id: string,
        formData: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
    ) {
        return new TraegerverbandDtoApi(undefined, '', this.client)
            .apiTraegerverbandDtoIdPatch(id, formData)
            .then((res) => res.data);
    }

    public postTraegerverbandItem(formData: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung) {
        return new TraegerverbandApi(undefined, '', this.client)
            .apiTraegerverbandPost(formData)
            .then((res) => res.data);
    }

    public getBezirksregierungCollection({
        page,
        itemsPerPage,
        name,
        orderName,
        options,
    }: BezirksregierungListOptionParams): Promise<ApiBezirksregierungGetCollection200Response> {
        return new BezirksregierungApi(undefined, '', this.client)
            .apiBezirksregierungGetCollection(page, itemsPerPage, name, orderName, options)
            .then((res) => res.data as ApiBezirksregierungGetCollection200Response);
    }

    public getTraegerCollection({
        page,
        itemsPerPage,
        qSearch,
        landschaftsverband,
        landschaftsverband2,
        behoerde,
        behoerde2,
        status,
        status2,
        type,
        type2,
        existsDeleted,
        orderName,
        orderNrwKey,
        orderType,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderModifiedAt,
        orderDeleted,
        options,
    }: TraegerListOptionParams): Promise<TraegerListResponse> {
        return new TraegerApi(undefined, '', this.client)
            .apiTraegerGetCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                status,
                status2,
                type,
                type2,
                existsDeleted,
                orderName,
                orderNrwKey,
                orderType,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderModifiedAt,
                orderDeleted,
                options
            )
            .then((res) => res.data as TraegerListResponse);
    }

    public getTraegerCollectionExport({
        qSearch,
        landschaftsverband,
        landschaftsverband2,
        behoerde,
        behoerde2,
        status,
        status2,
        type,
        type2,
        existsDeleted,
        orderName,
        orderNrwKey,
        orderType,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderModifiedAt,
        orderDeleted,
    }: TraegerExportParams): Promise<TraegerExportResponse> {
        return new TraegerApi(undefined, '', this.client)
            .apiTraegerCollectionexportCsvGetCollection(
                qSearch,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                status,
                status2,
                type,
                type2,
                existsDeleted,
                orderName,
                orderNrwKey,
                orderType,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderModifiedAt,
                orderDeleted
            )
            .then((res) => res.data as TraegerExportResponse);
    }

    public getTraegerItem(id: string): Promise<TraegerResponse> {
        return new TraegerApi(undefined, '', this.client)
            .apiTraegerIdGet(id)
            .then((res) => res.data as TraegerResponse);
    }

    public getTraegerDtoItem(id?: string): Promise<TraegerDtoResponse> {
        return new TraegerDtoApi(undefined, '', this.client)
            .apiTraegerDtoIdGet(String(id))
            .then((res) => res.data as TraegerDtoResponse);
    }

    public createTraegerDtoItem(formData: TraegerCreateFormData): Promise<TraegerDtoJsonld> {
        return new TraegerDtoApi(undefined, '', this.client)
            .apiTraegerDtoPost(formData)
            .then((res) => res.data as TraegerDtoJsonld);
    }

    public patchTraegerDto(id: string, formData: TraegerDtoTraegerWriteRolleEinrichtung) {
        return new TraegerDtoApi(undefined, '', this.client).apiTraegerDtoIdPatch(id, formData).then((res) => res.data);
    }

    public workflowTraeger(id?: number, action?: string) {
        return new TraegerApi(undefined, '', this.client)
            .apiTraegerIdworkflowPatch(String(id), { action: String(action) })
            .then((res) => res.data);
    }

    public getLandschaftsverbandCollection({
        page,
        itemsPerPage,
        qSearch,
        orderName,
        orderAdresseEmail,
        orderAdresseName,
        options,
    }: LandschaftsverbandListOptionParams): Promise<LandschaftsverbandCollectionResponse> {
        return new LandschaftsverbandApi(undefined, '', this.client)
            .apiLandschaftsverbandGetCollection(
                page,
                itemsPerPage,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            )
            .then((res) => res.data as LandschaftsverbandCollectionResponse);
    }

    public getLandschaftsverbandDtoItem(id: string) {
        return new LandschaftsverbandDtoApi(undefined, '', this.client)
            .apiLandschaftsverbandDtoIdGet(id)
            .then((res) => res.data as LandschaftsverbandItem);
    }

    public patchLandschaftsverbandItem(id: string, body: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung) {
        return new LandschaftsverbandDtoApi(undefined, '', this.client)
            .apiLandschaftsverbandDtoIdPatch(id, body)
            .then((res) => res.data);
    }

    public getLeistungsangebotEntwurfCollection({
        page,
        itemsPerPage,
        eingereichtBy,
        eingereichtBy2,
        einrichtungName,
        modifiedAt,
        status,
        status2,
        qSearch,
        orderEinrichtungName,
        orderModifiedAt,
        orderZuletztEingereichtAt,
        orderErstinbetriebnahme,
        orderOrt,
        orderLeistungsanbieterName,
        orderLeistungsanbieterStatus,
        orderEinrichtungArt,
        options,
    }: LeistungsangebotEntwurfListOptions): Promise<LeistungsangebotEntwurfCollectionResponse> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenGetCollection(
                page,
                itemsPerPage,
                eingereichtBy,
                eingereichtBy2,
                einrichtungName,
                modifiedAt,
                status,
                status2,
                qSearch,
                orderEinrichtungName,
                orderModifiedAt,
                orderZuletztEingereichtAt,
                orderErstinbetriebnahme,
                orderOrt,
                orderLeistungsanbieterName,
                orderLeistungsanbieterStatus,
                orderEinrichtungArt,
                options
            )
            .then((res) => res.data as LeistungsangebotEntwurfCollectionResponse);
    }

    public createLeistungsangebotEntwurf(options?: AxiosRequestConfig): Promise<LeistungsangebotRegistrierenJsonld> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenPost({}, options)
            .then((res) => res.data as LeistungsangebotRegistrierenJsonld);
    }

    public getLeistungsangebotEntwurfJson({
        id,
        step = 'basisdaten',
        objectType,
        uuid,
        options,
    }: LeistungsangebotJsonParams): Promise<LeistungsangebotFormResponse> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenIdformGet(id, step, objectType, uuid, options)
            .then((res) => res.data as LeistungsangebotFormResponse);
    }

    public deleteLeistungsangebotEntwurfItem(id: number): Promise<void> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenIdDelete(String(id))
            .then((res) => res.data);
    }

    public updateLeistungsangebotEntwurfItem({
        id,
        step = 'basisdaten',
        body,
        objectType,
        uuid,
        persist,
        fields,
        options,
    }: EinrichtungApiFormSubmitParams<LeistungsangebotRegistrieren>): Promise<LeistungsangebotData> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((res) => res.data as LeistungsangebotData);
    }

    public editLeistungsangebotItem(einrichtungId?: string): Promise<LeistungsangebotRegistrierenJsonld> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenPost({ einrichtung: toIriId(URL_EINRICHTUNG, einrichtungId) })
            .then((res) => res.data as LeistungsangebotRegistrierenJsonld);
    }

    public deleteLeistungsangebotObjectTypeItem({ id, uuid }: { id: number; uuid: string }): Promise<void> {
        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenIdformDelete(id, uuid)
            .then((res) => res.data);
    }

    public workflowLeistungsangebotItem(
        id?: number,
        action?: string
    ): Promise<LeistungsangebotRegistrierenJsonld> | void {
        if (!id || !action) return console.warn('id and action must be set for workflowLeistungsangebotItem');

        return new LeistungsangebotRegistrierenApi(undefined, '', this.client)
            .apiLeistungsangebotRegistrierenIdworkflowPatch(String(id), { action })
            .then((res) => res.data as LeistungsangebotRegistrierenJsonld);
    }

    public getEinrichtungById(id: string, options?: AxiosRequestConfig): Promise<EinrichtungGetByIdResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .apiEinrichtungIdGet(id, options)
            .then((res) => res.data as EinrichtungGetByIdResponse);
    }

    public getZugriffsrechte(id: string, options?: AxiosRequestConfig) {
        return new ZugriffsrechteApi(undefined, '', this.client)
            .apiZugriffsrechteIdGet(id, options)
            .then((res) => res.data);
    }

    public postZugriffsrechte({ einrichtung, benutzer }: { einrichtung: string; benutzer?: Array<string> | null }) {
        return new ZugriffsrechteApi(undefined, '', this.client)
            .apiZugriffsrechtePost({ einrichtung: toIriId(URL_EINRICHTUNG, einrichtung), benutzer })
            .then((res) => res.data);
    }

    public deleteZugriffsrechte({
        action,
        einrichtung,
        benutzer,
    }: {
        action: string;
        einrichtung?: number;
        benutzer?: number;
    }) {
        return new ZugriffsrechteApi(undefined, '', this.client).apiZugriffsrechteWorkflowPost({
            action,
            einrichtung,
            benutzer,
        });
    }

    public getZugriffsrechteBenutzer({
        page,
        itemsPerPage,
        existsDeaktiviertAt,
        existsDeletedAt,
        id,
        id2,
        rolle,
        rolle2,
        landschaftsverband,
        landschaftsverband2,
        behoerde,
        behoerde2,
        bezirksregierung,
        bezirksregierung2,
        traeger,
        traeger2,
        einrichtungBenutzerEinrichtung,
        einrichtungBenutzerEinrichtung2,
        traegerverband,
        traegerverband2,
        email,
        email2,
        qSearch,
        qImpersonateSearch,
        orderNachname,
        orderVorname,
        orderEmail,
        orderDeaktiviertAt,
    }: BenutzerZugriffsrechteListCollection): Promise<BenutzerZugriffsrechteListResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .apiBenutzerListeZugriffsrechteGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt
            )
            .then((res) => res.data as BenutzerZugriffsrechteListResponse);
    }

    public getLeistungsanbieterCollection({
        page,
        itemsPerPage,
        eingereichtBy,
        eingereichtBy2,
        name,
        modifiedAt,
        status,
        status2,
        qSearch,
        orderName,
        orderEingereichtAt,
        options,
    }: LeistungsanbieterCollectionParams): Promise<ApiLeistungsanbieterGetCollection200Response> {
        return new LeistungsanbieterApi(undefined, '', this.client)
            .apiLeistungsanbieterGetCollection(
                page,
                itemsPerPage,
                eingereichtBy,
                eingereichtBy2,
                name,
                modifiedAt,
                status,
                status2,
                qSearch,
                orderName,
                orderEingereichtAt,
                options
            )
            .then((res) => res.data as ApiLeistungsanbieterGetCollection200Response);
    }

    public workflowLeistungsanbieterItem(id?: number, action?: string): Promise<LeistungsanbieterJsonld> | void {
        if (!id || !action) return console.warn('id and action must be set for workflowLeistungsanbieterItem');

        return new LeistungsanbieterApi(undefined, '', this.client)
            .apiLeistunganbieterIdworkflowPatch(String(id), { action: action })
            .then((res) => res.data as LeistungsanbieterJsonld);
    }

    public getLeistungsanbieterJson({
        id,
        step = 'basisdaten',
        objectType,
        uuid,
        options,
    }: EinrichtungApiFormGetParams): Promise<EinrichtungFormResponse> {
        return new LeistungsanbieterApi(undefined, '', this.client)
            .apiLeistunganbieterIdformGet(id, step, objectType, uuid, options)
            .then((res) => res.data as EinrichtungFormResponse);
    }

    public patchLeistungsanbieterJson({
        id,
        step = 'basisdaten',
        body,
        objectType,
        uuid,
        persist,
        fields,
        options,
    }: EinrichtungApiFormSubmitParams<Leistungsanbieter>): Promise<LeistungsanbieterFormResponse> {
        return new LeistungsanbieterApi(undefined, '', this.client)
            .apiLeistunganbieterIdformPatch(Number(id), step, body, objectType, uuid, persist, fields, options)
            .then((res) => res.data as LeistungsanbieterFormResponse);
    }

    public deleteLeistungsanbieterJson({ id, uuid }: { id: number; uuid: string }): Promise<void> {
        return new LeistungsanbieterApi(undefined, '', this.client)
            .apiLeistunganbieterIdformDelete(id, uuid)
            .then((res) => res.data);
    }

    public getEinrichtungCollection({
        page,
        itemsPerPage,
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeModifiedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
        options,
    }: EinrichtungListOptionParams): Promise<EinrichtungCollectionResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .apiEinrichtungGetCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((res) => res.data as EinrichtungCollectionResponse);
    }

    public getEinrichtungenForMeldung({
        page,
        itemsPerPage,
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeModifiedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
        options,
    }: EinrichtungListOptionParams): Promise<EinrichtungCollectionResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .getEinrichtungenForMeldung(
                page,
                itemsPerPage || 9999,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((res) => res.data as EinrichtungCollectionResponse);
    }

    public createMeldungWithEinrichtung(einrichtungId: string): Promise<MeldungEinrichtungResponse> {
        return new MeldungApi(undefined, '', this.client)
            .apiMeldungPost({
                einrichtung: toIriId(URL_EINRICHTUNG, einrichtungId),
            })
            .then((res) => res.data as MeldungEinrichtungResponse);
    }

    public workflowMeldungItem(id?: number, action?: string): Promise<MeldungJsonld> | void {
        if (!id || !action) return console.warn('id and action must be set for workflowMeldungItem');

        return new MeldungApi(undefined, '', this.client)
            .apiMeldungIdworkflowPatch(String(id), { action: action })
            .then((res) => res.data as MeldungJsonld);
    }

    public getMeldungItem<T = MeldungJsonldMeldungRead>(id: string): Promise<T> {
        return new MeldungApi(undefined, '', this.client).apiMeldungIdGet(id).then((res) => res.data as T);
    }

    public getMeldungFormByStepJson({
        id,
        step = '',
        objectType,
        uuid,
        options,
    }: EinrichtungApiFormGetParams): Promise<EinrichtungFormResponse> {
        return new MeldungApi(undefined, '', this.client)
            .apiMeldungIdformGet(id, step, objectType, uuid, options)
            .then((res) => res.data as EinrichtungFormResponse);
    }

    public patchMeldungByStepJson({
        id,
        step = '',
        body,
        objectType,
        uuid,
        persist,
        fields,
        options,
    }: EinrichtungApiFormSubmitParams<Meldung>): Promise<MeldungEinrichtungPatchResponse> {
        return new MeldungApi(undefined, '', this.client)
            .apiMeldungIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((res) => res.data as MeldungEinrichtungPatchResponse);
    }

    public deleteMeldungByStepJson({ id, uuid }: { id: number; uuid: string }): Promise<void> {
        return new MeldungApi(undefined, '', this.client).jsonFormDeleteMeldung(id, uuid).then((res) => res.data);
    }

    public getMeldungCollection({
        page,
        itemsPerPage,
        qSearch,
        eingereichtBy,
        eingereichtBy2,
        einrichtung,
        einrichtung2,
        modifiedAt,
        status,
        status2,
        einrichtungNeedsUpdate,
        keinZustaendigerBenutzer,
        existsEinrichtungDeleted,
        orderZuletztEingereichtAt,
        orderModifiedAt,
        orderEinrichtungName,
        orderEinrichtungArt,
        orderEinrichtungAdresseOrt,
        orderEinrichtungNrwKey,
        orderStatus,
        options,
    }: MeldungCollectionParams): Promise<GetCollectionMeldung200Response> {
        return new MeldungApi(undefined, '', this.client)
            .getCollectionMeldung(
                page,
                itemsPerPage || 9999,
                qSearch,
                eingereichtBy,
                eingereichtBy2,
                einrichtung,
                einrichtung2,
                modifiedAt,
                status,
                status2,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                existsEinrichtungDeleted,
                orderZuletztEingereichtAt,
                orderModifiedAt,
                orderEinrichtungName,
                orderEinrichtungArt,
                orderEinrichtungAdresseOrt,
                orderEinrichtungNrwKey,
                orderStatus,
                options
            )
            .then((res) => res.data as GetCollectionMeldung200Response);
    }

    public getMeldungAufgabeCollection({
        page,
        itemsPerPage,
        qSearch,
        eingereichtBy,
        eingereichtBy2,
        einrichtung,
        einrichtung2,
        modifiedAt,
        status,
        status2,
        einrichtungNeedsUpdate,
        keinZustaendigerBenutzer,
        existsEinrichtungDeleted,
        orderZuletztEingereichtAt,
        orderModifiedAt,
        orderEinrichtungName,
        orderEinrichtungArt,
        orderEinrichtungAdresseOrt,
        orderEinrichtungNrwKey,
        orderStatus,
        options,
    }: MeldungCollectionParams): Promise<GetCollectionMeldung200Response> {
        return new MeldungApi(undefined, '', this.client)
            .getMeldungAufgabe(
                page,
                itemsPerPage || 9999,
                qSearch,
                eingereichtBy,
                eingereichtBy2,
                einrichtung,
                einrichtung2,
                modifiedAt,
                status,
                status2,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                existsEinrichtungDeleted,
                orderZuletztEingereichtAt,
                orderModifiedAt,
                orderEinrichtungName,
                orderEinrichtungArt,
                orderEinrichtungAdresseOrt,
                orderEinrichtungNrwKey,
                orderStatus,
                options
            )
            .then((res) => res.data as GetCollectionMeldung200Response);
    }

    public getRegionCollection({
        page,
        itemsPerPage,
        qSearch,
        options,
    }: RegionListOptionParams): Promise<ApiRegionGetCollection200Response> {
        return new RegionApi(undefined, '', this.client)
            .apiRegionGetCollection(page, itemsPerPage, qSearch, options)
            .then((res) => res.data as ApiRegionGetCollection200Response);
    }

    public postFile(file: File, targetUrl: string, config?: AxiosRequestConfig): Promise<FileUploadResult> {
        const formData = new FormData();
        formData.append('file', file);

        return this.client
            .post(targetUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                ...(config || {}),
            })
            .then((response) => response.data)
            .catch((e) => ({ fileName: file.name, error: e }));
    }

    public getFile(fileId: number, config?: AxiosRequestConfig): Promise<FileUploadResult> {
        return this.client
            .get(`${URL_UPLOAD}/${fileId}`, config)
            .then((response) => response.data)
            .catch((e) => ({ fileId, error: e }));
    }

    public getProfile(options?: AxiosRequestConfig): Promise<BenutzerProfileResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfileGet({
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
                withCredentials: true,
            })
            .then((res) => res.data);
    }

    public profileAccountDeaktivieren(
        hash: string,
        options?: AxiosRequestConfig
    ): Promise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfileaccountDeaktivierenHashGet(hash, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res.data);
    }

    public patchProfileEmail(data: BenutzerCredentialChangeRequestInput, options?: AxiosRequestConfig) {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfileeditEmailPatch(data, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res.data);
    }

    public patchProfile(
        id: string,
        data: BenutzerProfileEdit,
        options?: AxiosRequestConfig
    ): Promise<BenutzerProfileEdit> {
        return new BenutzerApi(undefined, '', this.client)
            .apiBenutzerIdPatch(id, data, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res.data);
    }

    public resetPasswordRequest(
        data: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ): Promise<AxiosResponse<BenutzerResponse>> {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfilepasswordResetRequestPatch(data, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res as AxiosResponse<BenutzerResponse>);
    }

    public patchPasswordRequest(
        token: string,
        data: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ): Promise<AxiosResponse<BenutzerResponse>> {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfilepasswordResetTokenPatch(token, data, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res as AxiosResponse<BenutzerResponse>);
    }

    public checkChangeToken(
        data: BenutzerCredentialChangeConfirmInput,
        options?: AxiosRequestConfig
    ): Promise<BenutzerResponse> {
        return new BenutzerApi(undefined, '', this.client)
            .apiProfileconfirmChangePatch(data, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res.data as BenutzerResponse);
    }

    public verifyEmail(
        hash: string,
        options?: AxiosRequestConfig
    ): Promise<VerifizierungsMailJsonldVerifizierungsmailWrite> {
        return new VerifizierungsMailApi(undefined, '', this.client)
            .apiEmailVerifizierenHashGet(hash, {
                ...options,
                headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
            })
            .then((res) => res.data as VerifizierungsMailJsonldVerifizierungsmailWrite);
    }

    public resendVerificationEmail(
        email: string,
        options?: AxiosRequestConfig
    ): Promise<VerifizierungsMailJsonldVerifizierungsmailWrite> {
        return new VerifizierungsMailApi(undefined, '', this.client)
            .apiResendVerificationEmailPost(
                { email },
                {
                    ...options,
                    headers: { Accept: 'application/json', ...this.setAdditionalRequestHeaders(options) },
                }
            )
            .then((res) => res.data as VerifizierungsMailJsonldVerifizierungsmailWrite);
    }

    public workflowEinrichtung(id?: number, action?: string, grund?: string) {
        return new EinrichtungApi(undefined, '', this.client)
            .apiEinrichtungIdworkflowPatch(String(id), { action: action || null, grund })
            .then((res) => res.data);
    }

    public getProfilHeimfinderCollection({
        page,
        itemsPerPage,
        options,
    }: {
        page?: number;
        itemsPerPage?: number;
        options?: AxiosRequestConfig;
    }) {
        return new HeimfinderprofilApi(undefined, '', this.client)
            .apiHeimfinderprofilGetCollection(page, itemsPerPage || 9999, options)
            .then((res) => res.data);
    }

    public getProfilHeimfinderById(id?: string): Promise<ProfilHeimfinderFormGet> {
        return new HeimfinderprofilApi(undefined, '', this.client)
            .apiHeimfinderprofilIdGet(String(id))
            .then((res) => res.data as ProfilHeimfinderFormGet);
    }

    public patchProfilHeimfinderItem(id: string, formData: ProfilHeimfinderFormData) {
        const uploadIri: string = formData.upload?.['@id'] || toIriId(URL_HEIMFINDER_UPLOAD, formData.upload?.uploadId);
        const postData = Object.assign({}, formData, { upload: uploadIri || null });

        return new HeimfinderprofilApi(undefined, '', this.client)
            .apiHeimfinderprofilIdPatch(id, postData)
            .then((res) => res.data);
    }

    public createPlatzzahlmelderToken(
        benutzerId: string | number
    ): Promise<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead> {
        return new PlatzzahlmelderTokenApi(undefined, '', this.client)
            .apiPlatzzahlmelderTokenPost({ benutzer: toIriId(URL_BENUTZER, benutzerId) })
            .then((res) => res.data);
    }

    public getPlatzzahlmelderTokenByBenutzerId(
        id: string
    ): Promise<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead> {
        return new PlatzzahlmelderTokenApi(undefined, '', this.client)
            .apiPlatzzahlmelderTokengetByBenutzerIdGet(id)
            .then((res) => res.data);
    }

    public getPlatzzahlmelderLinkFile(): Promise<BelegbarePlaetzeGeneriertesDokumentJsonld> {
        return new BelegbarePlaetzeApi(undefined, '', this.client)
            .apiPlatzmelderDownloadGet()
            .then((res) => res.data as BelegbarePlaetzeGeneriertesDokumentJsonld);
    }

    public getListForPlatzzahlmelder({
        page,
        itemsPerPage,
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeModifiedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
        options,
    }: EinrichtungListOptionParams): Promise<GetEinrichtungenForPlatzzahlmelder200Response> {
        return new EinrichtungApi(undefined, '', this.client)
            .getEinrichtungenForPlatzzahlmelder(
                page,
                itemsPerPage || 9999,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((res) => res.data as GetEinrichtungenForPlatzzahlmelder200Response);
    }

    public getPlatzzahlmelderItem(id: number) {
        return new BelegbarePlaetzeApi(undefined, '', this.client).getBelegbarePlaetze(id).then((res) => res.data);
    }

    public savePlatzzahlmelderItem(token?: string, item: Record<string, any> = {}): Promise<BelegbarePlaetzeJsonld> {
        return new BelegbarePlaetzeApi(undefined, '', this.client).saveBelegbarePlaetze(item).then((res) => res.data);
    }

    public getPlatzmelderHistorie({
        page,
        itemsPerPage,
        einrichtung,
        einrichtung2,
        orderCreatedAt,
        options,
    }: BelegbarePlaetzeHistorieParams): Promise<BelegbarePlaetzeHistorie200Response> {
        return new BelegbarePlaetzeHistorieApi(undefined, '', this.client)
            .belegbarePlaetzeHistorie(page, itemsPerPage, einrichtung, einrichtung2, orderCreatedAt, options)
            .then((res) => res.data);
    }

    public getPlatzzahlmelderTokenCollection(token?: string) {
        return new BelegbarePlaetzeApi(undefined, '', this.client)
            .apiPlatzmelderTokenGetCollection(String(token))
            .then((res) => res.data);
    }

    public postPlatzzahlmelderTokenData(token?: string, body = {}) {
        return new BelegbarePlaetzeApi(undefined, '', this.client)
            .apiPlatzmelderTokenPost(String(token), body)
            .then((res) => res.data);
    }

    public getEmailAntragCollection({
        page,
        itemsPerPage,
        einrichtung,
        einrichtung2,
        eingereichtBy,
        eingereichtBy2,
        modifiedAt,
        status,
        status2,
        typ,
        typ2,
        qSearch,
        orderName,
        orderBetreff,
        orderEinrichtungName,
        orderEinrichtungNrwKey,
        orderEingereichtAt,
        orderEingereichtByNachname,
        rolle,
        orderTyp,
        isRead,
    }: EmailAntragCollectionParams): Promise<EmailAntragCollectionResponse> {
        return new EmailAntragApi(undefined, '', this.client)
            .apiEmailAntragGetCollection(
                page,
                itemsPerPage || 9999,
                einrichtung ? toIriId(URL_EINRICHTUNG, einrichtung) : undefined,
                einrichtung2,
                eingereichtBy,
                eingereichtBy2,
                modifiedAt,
                status,
                status2,
                typ,
                typ2,
                qSearch,
                orderName,
                orderBetreff,
                orderEinrichtungName,
                orderEinrichtungNrwKey,
                orderEingereichtAt,
                orderEingereichtByNachname,
                rolle,
                orderTyp,
                isRead
            )
            .then((res) => res.data as EmailAntragCollectionResponse);
    }

    public getEmailAntragItem(id?: string): Promise<EmailAntragItem> {
        return new EmailAntragApi(undefined, '', this.client)
            .apiEmailAntragIdGet(String(id))
            .then((res) => res.data as EmailAntragItem);
    }

    public createEmailAntrag({
        id,
        typ,
    }: {
        id: number | undefined | null;
        typ: EMAIL_ANTRAG_ART;
    }): Promise<EmailAntragJsonld> {
        if (!id) {
            return Promise.reject('Id must be set for createEmailAntrag');
        }

        const requestBody: EmailAntragJsonldEmailAntragWrite = {
            typ,
            einrichtung: toIriId(URL_EINRICHTUNG, id),
        };

        return new EmailAntragApi(undefined, '', this.client)
            .apiEmailAntragPost(requestBody)
            .then((res) => res.data as EmailAntragJsonld);
    }

    public getEmailAntragJson({
        id,
        step = 'aufforderung',
        objectType,
        uuid,
    }: EinrichtungApiFormGetParams): Promise<EinrichtungFormResponse> {
        return new EmailAntragApi(undefined, '', this.client)
            .apiEmailAntragIdformGet(id, step, objectType, uuid)
            .then((res) => res.data as EinrichtungFormResponse);
    }

    public patchEmailAntragJson({
        id,
        step = 'aufforderung',
        body,
        objectType,
        uuid,
        persist,
        fields,
    }: EinrichtungApiFormSubmitParams<EmailAntrag>): Promise<EmailAntragData> {
        return new EmailAntragApi(undefined, '', this.client)
            .apiEmailAntragIdformPatch(id, step, body, objectType, uuid, persist, fields)
            .then((res) => res.data as EmailAntragData);
    }

    public workflowEmailAntrag(id?: number, action?: string): Promise<EmailAntragJsonld> | void {
        if (!id || !action) return console.warn('id and action must be set for workflowEmailAntrag');
        return new EmailAntragApi(undefined, '', this.client)
            .apiEmailAntragIdworkflowPatch(String(id), { action: action })
            .then((res) => res.data);
    }

    public getEmailText(emailtyp: string, id: string) {
        return new EmailtextApi(undefined, '', this.client).apiEmailtextIdGet(emailtyp, id).then((res) => res.data);
    }

    public postEmailText(formData: EmailtextJsonldEmailtextWrite) {
        const postData = Object.assign({}, formData, { behoerde: toIriId(URL_BEHOERDE, formData?.behoerde) });
        return new EmailtextApi(undefined, '', this.client).apiEmailtextPost(postData).then((res) => res.data);
    }

    public getEmailSignatur() {
        return new SignaturApi(undefined, '', this.client).apiSignaturGet().then((res) => res.data);
    }

    public patchEmailSignatur(formData: SignaturSignaturWrite) {
        return new SignaturApi(undefined, '', this.client)
            .apiSignaturIdPatch(String(formData.id), formData)
            .then((res) => res.data);
    }

    public getMeldungHinweistextCollection({
        page,
        itemsPerPage,
        behoerde,
        behoerde2,
        options,
    }: MeldungHinweistextCollectionParams): Promise<MeldungHinweistextListResponse> {
        return new MeldungHinweistextApi(undefined, '', this.client)
            .apiMeldungHinweistextGetCollection(page, itemsPerPage || 9999, behoerde, behoerde2, options)
            .then((res) => res.data as MeldungHinweistextListResponse);
    }

    public createMeldungHinweistext(formData: MeldungHinweistextJsonldMeldungHinweistextWrite) {
        return new MeldungHinweistextApi(undefined, '', this.client)
            .apiMeldungHinweistextPost(formData)
            .then((res) => res.data);
    }

    public getMeldungHinweistextItem(id: string) {
        return new MeldungHinweistextApi(undefined, '', this.client)
            .apiMeldungHinweistextIdGet(id)
            .then((res) => res.data);
    }

    public patchMeldungHinweistextItem(id: string, formData: MeldungHinweistextMeldungHinweistextWrite) {
        return new MeldungHinweistextApi(undefined, '', this.client)
            .apiMeldungHinweistextIdPatch(id, formData)
            .then((res) => res.data);
    }

    public workflowMeldungHinweistext(id?: number, action?: string) {
        return new MeldungHinweistextApi(undefined, '', this.client)
            .apiMeldungHinweistextIdworkflowPatch(String(id), { action: String(action) })
            .then((res) => res.data);
    }

    public getMeldungHinweisDokumentCollection({
        page,
        itemsPerPage,
        behoerde,
        behoerde2,
        options,
    }: MeldungHinweisDokumentCollectionParams): Promise<MeldungHinweisDokumentListResponse> {
        return new MeldungHinweisdokumentApi(undefined, '', this.client)
            .apiMeldungHinweisdokumentGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((res) => res.data as MeldungHinweisDokumentListResponse);
    }

    public createMeldungHinweisDokument(formData: MeldungHinweisDokumentFormData) {
        const uploadIris: string[] = (formData.uploads || []).map((upload) => toIriId(URL_UPLOAD, upload.uploadId));
        const postData = Object.assign({}, formData, { uploads: uploadIris });

        return new MeldungHinweisdokumentApi(undefined, '', this.client)
            .apiMeldungHinweisdokumentPost(postData)
            .then((res) => res.data);
    }

    public getMeldungHinweisDokumentItem(id?: string) {
        return new MeldungHinweisdokumentApi(undefined, '', this.client)
            .apiMeldungHinweisdokumentIdGet(String(id))
            .then((res) => res.data);
    }

    public workflowMeldungHinweisDokument(id?: number, action?: string) {
        return new MeldungHinweisdokumentApi(undefined, '', this.client)
            .apiMeldungHinweisdokumentIdworkflowPatch(String(id), { action: String(action) })
            .then((res) => res.data);
    }

    public getDokumentPruefungCollection({
        page,
        itemsPerPage,
        behoerde,
        behoerde2,
        options,
    }: DokumentPruefungCollectionParams): Promise<DokumentPruefungListResponse> {
        return new MeldungFreigabeDokumentApi(undefined, '', this.client)
            .apiMeldungFreigabeDokumentGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((res) => res.data as DokumentPruefungListResponse);
    }

    public createDokumentPruefung(formData: DokumentPruefungFormData) {
        const uploadIris: string[] = (formData.uploads || []).map((upload) => toIriId(URL_UPLOAD, upload.uploadId));
        const postData = Object.assign({}, formData, { uploads: uploadIris });

        return new MeldungFreigabeDokumentApi(undefined, '', this.client)
            .apiMeldungFreigabeDokumentPost(postData)
            .then((res) => res.data);
    }

    public getDokumentPruefungItem(id?: string) {
        return new MeldungFreigabeDokumentApi(undefined, '', this.client)
            .apiMeldungFreigabeDokumentIdGet(String(id))
            .then((res) => res.data);
    }

    public workflowDokumentPruefung(id?: number, action?: string) {
        return new MeldungFreigabeDokumentApi(undefined, '', this.client)
            .apiMeldungFreigabeDokumentIdworkflowPatch(String(id), { action: String(action) })
            .then((res) => res.data);
    }

    public getFemEinrichtungCollection({
        page,
        itemsPerPage,
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeModifiedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
        options,
    }: EinrichtungListOptionParams): Promise<GetEinrichtungenForFem200Response> {
        return new EinrichtungApi(undefined, '', this.client)
            .getEinrichtungenForFem(
                page,
                itemsPerPage || 9999,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((res) => res.data);
    }

    public getFemAntragCollection({
        page,
        itemsPerPage,
        einrichtung,
        einrichtung2,
        eingereichtBy,
        eingereichtBy2,
        name,
        modifiedAt,
        status,
        status2,
        jahr,
        jahr2,
        qSearch,
        orderName,
        orderEingereichtAt,
    }: FemCollectionParams): Promise<FemAntragCollectionResponse> {
        return new FemAntragApi(undefined, '', this.client)
            .apiFemAntragGetCollection(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                eingereichtBy,
                eingereichtBy2,
                name,
                modifiedAt,
                status,
                status2,
                jahr,
                jahr2,
                qSearch,
                orderName,
                orderEingereichtAt
            )
            .then((res) => res.data as FemAntragCollectionResponse);
    }

    public workflowFemItem(id?: number, action?: string): Promise<FemAntragJsonld> | void {
        if (!id || !action) {
            return console.warn('Id and action must be set for workflowFemItem');
        }

        return new FemAntragApi(undefined, '', this.client)
            .apiFemAntragIdworkflowPatch(String(id), { action: action })
            .then((res) => res.data);
    }

    public getFemAntragJson({
        id,
        step = 'gerichtlich',
        objectType,
        uuid,
    }: EinrichtungApiFormGetParams): Promise<EinrichtungFormResponse> {
        return new FemAntragApi(undefined, '', this.client)
            .apiFemAntragIdformGet(id, step, objectType, uuid)
            .then((res) => res.data as EinrichtungFormResponse);
    }

    public patchFemAntragJson({
        id,
        step = 'gerichtlich',
        body,
        objectType,
        uuid,
        persist,
        fields,
    }: EinrichtungApiFormSubmitParams<FemAntrag>): Promise<FemAntragResponse> {
        return new FemAntragApi(undefined, '', this.client)
            .apiFemAntragIdformPatch(id, step, body, objectType, uuid, persist, fields)
            .then((res) => res.data as FemAntragResponse);
    }

    public getFemAntragItem(id: string): Promise<FemAntragDetailResponse> {
        return new FemAntragApi(undefined, '', this.client)
            .apiFemAntragIdGet(id)
            .then((res) => res.data as FemAntragDetailResponse);
    }

    public deleteFemAntragItemJson({ id, uuid }: { id: number; uuid: string }): Promise<void> {
        return new FemAntragApi(undefined, '', this.client).apiFemAntragIdformDelete(id, uuid).then((res) => res.data);
    }

    public getTableFilter({
        antragType,
        page,
        itemsPerPage,
        options,
    }: {
        antragType: string;
        page?: number;
        itemsPerPage?: number;
        options?: AxiosRequestConfig;
    }): Promise<TableFilterListResponse> {
        return new TableFilterApi(undefined, '', this.client)
            .apiFiltersEntityTypeGetCollection(antragType, page, itemsPerPage, options)
            .then((res) => res.data as TableFilterListResponse);
    }

    public getDeletedObjectCollection({
        page,
        itemsPerPage,
        qSearch,
        confirmed,
        orderByNrwKey,
        orderByArt,
        orderByName,
        orderByErstinbetriebnahme,
        orderByDeletedAt,
        orderByDeletedBy,
        options,
    }: DeletedObjectCollectionParams): Promise<DeletecObjectCollectionResponse> {
        return new GeloeschtesObjektApi(undefined, '', this.client)
            .apiGeloeschtesObjektGetCollection(
                page,
                itemsPerPage,
                qSearch,
                confirmed,
                orderByNrwKey,
                orderByArt,
                orderByName,
                orderByErstinbetriebnahme,
                orderByDeletedAt,
                orderByDeletedBy,
                options
            )
            .then((res) => res.data as DeletecObjectCollectionResponse);
    }

    public getInvestRegistrationCollection({
        page,
        itemsPerPage,
        existsTransfered,
        failed,
        options,
    }: InvestRegistrationCollectionParams): Promise<InvestRegistrationCollectionResponse> {
        return new InvestRegistrationApi(undefined, '', this.client)
            .apiInvestRegistrationGetCollection(page, itemsPerPage, existsTransfered, failed, options)
            .then((res) => res.data as InvestRegistrationCollectionResponse);
    }

    public postInvestRegistrationRetry(id: string, body: InvestRegistrationItem): Promise<InvestRegistrationItem> {
        return new InvestRegistrationApi(undefined, '', this.client)
            .apiInvestRegistrationIdretryPost(id, body)
            .then((res) => res.data as InvestRegistrationItem);
    }

    public postInvestSignUp(id: string, formData: InvestRegistrationFormData): Promise<InvestRegistrationFormData> {
        const postData: InvestRegistrationFormData = Object.assign({}, formData, {
            einrichtung: toIriId(URL_EINRICHTUNG, id),
        });

        return new InvestRegistrationApi(undefined, '', this.client)
            .apiInvestRegistrationIdregisterPost(id, postData)
            .then((res) => res.data as InvestRegistrationFormData);
    }

    public getMassenmailCollection({
        page,
        itemsPerPage,
        behoerde,
        behoerde2,
        options,
    }: MassenmailCollectionParams): Promise<MassenmailCollectionResponse> {
        return new MassenmailApi(undefined, '', this.client)
            .apiMassenmailGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((res) => res.data as MassenmailCollectionResponse);
    }

    public getMassenmailItem(id: string, options?: AxiosRequestConfig): Promise<MassenmailFormGet> {
        return new MassenmailApi(undefined, '', this.client)
            .apiMassenmailIdGet(id, options)
            .then((res) => res.data as MassenmailFormGet);
    }

    public postMassenmailCreate(
        formData: MassenmailFormData,
        options?: AxiosRequestConfig
    ): Promise<MassenmailFormResponse> {
        const uploadIris: string[] = (formData.uploads || []).map((upload) => toIriId(URL_UPLOAD, upload.uploadId));

        const postData: MassenmailJsonldMassenmailWrite = Object.assign({}, formData, {
            betreff: formData.betreff || '',
            inhalt: formData.inhalt || '',
            uploads: uploadIris,
        });

        return new MassenmailApi(undefined, '', this.client)
            .apiMassenmailPost(postData, options)
            .then((res) => res.data as MassenmailFormResponse);
    }

    public getWohngemeinschaftCollection({
        page,
        itemsPerPage,
        qSearch,
        existsEinrichtung,
        existsDeleted = false,
        orderName,
        orderEinrichtungName,
        orderAmbulanterDienstName,
        orderAdresseStrasse,
        orderPlaetze,
        orderBeginn,
        orderCreatedAt,
        orderDeleted,
        options,
    }: WohngemeinschaftCollectionParams): Promise<WohngemeinschaftCollectionResponse> {
        return new WohngemeinschaftApi(undefined, '', this.client)
            .apiWohngemeinschaftGetCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                existsEinrichtung,
                existsDeleted,
                orderName,
                orderEinrichtungName,
                orderAmbulanterDienstName,
                orderAdresseStrasse,
                orderPlaetze,
                orderBeginn,
                orderCreatedAt,
                orderDeleted,
                options
            )
            .then((res) => res.data as WohngemeinschaftCollectionResponse);
    }

    public getWohngemeinschaftItem(id: string, options?: AxiosRequestConfig): Promise<WohngemeinschaftFormGet> {
        return new WohngemeinschaftApi(undefined, '', this.client)
            .apiWohngemeinschaftIdGet(id, options)
            .then((res) => res.data as WohngemeinschaftFormGet);
    }

    public patchWohngemeinschaftItem(formData: WohngemeinschaftFormData, id?: number): Promise<WohngemeinschaftJsonld> {
        const postData: WohngemeinschaftSubmitData = Object.assign({}, formData, {
            einrichtung: toIriId(URL_EINRICHTUNG, formData?.einrichtung?.id),
        });

        return new WohngemeinschaftApi(undefined, '', this.client)
            .apiWohngemeinschaftIdPatch(String(id), postData)
            .then((res) => res.data as WohngemeinschaftJsonld);
    }

    public workflowWohngemeinschaftItem(
        id?: number,
        action?: string
    ): Promise<WohngemeinschaftWorkflowResponse> | void {
        if (!id || !action) {
            return console.warn('Id and action must be set for workflowWohngemeinschaftItem');
        }

        return new WohngemeinschaftApi(undefined, '', this.client)
            .apiWohngemeinschaftIdworkflowPatch(String(id), { action })
            .then((res) => res.data as WohngemeinschaftWorkflowResponse);
    }

    public createAngezeigteBetreuungPost(formData: AngezeigteBetreuungFormData) {
        return new AngezeigteBetreuungFormApi(undefined, '', this.client)
            .apiAngezeigteBetreuungFormPost(formData)
            .then((res) => res);
    }

    public getWohngemeinschaftExport({
        qSearch,
        existsEinrichtung,
        existsDeleted = false,
        orderName,
        orderEinrichtungName,
        orderAmbulanterDienstName,
        orderAdresseStrasse,
        orderPlaetze,
        orderBeginn,
        orderCreatedAt,
        orderDeleted,
        options,
    }: WohngemeinschaftExportParams): Promise<WohngemeinschaftExportResponse> {
        return new WohngemeinschaftApi(undefined, '', this.client)
            .apiWohngemeinschaftCsvGetCollection(
                qSearch,
                existsEinrichtung,
                existsDeleted,
                orderName,
                orderEinrichtungName,
                orderAmbulanterDienstName,
                orderAdresseStrasse,
                orderPlaetze,
                orderBeginn,
                orderCreatedAt,
                orderDeleted,
                options
            )
            .then((res) => res.data as WohngemeinschaftExportResponse);
    }

    public getEinrichtungenExport({
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeCreatedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
    }: EinrichtungExportParams): Promise<EinrichtungExportResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .apiEinrichtungCollectionexportCsvGetCollection(
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeCreatedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt
            )
            .then((res) => res.data as EinrichtungExportResponse);
    }

    public getRegelpruefungEinrichtungCollection({
        page,
        itemsPerPage,
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeModifiedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
        options,
    }: EinrichtungListOptionParams): Promise<GetEinrichtungenForRegelpruefung200Response> {
        return new EinrichtungApi(undefined, '', this.client)
            .getEinrichtungenForRegelpruefung(
                page,
                itemsPerPage || 9999,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((res) => res.data);
    }

    public getRegelpruefungenPruefberichteCollection({
        page,
        itemsPerPage,
        qSearch,
        eingereichtAt,
        eingereichtAt2,
        einrichtung,
        einrichtung2,
        einrichtungBehoerde,
        einrichtungBehoerde2,
        einrichtungStandort,
        einrichtungStandort2,
        status,
        status2,
        orderEinrichtungName,
        orderEinrichtungBehoerdeName,
        orderPruefDatum,
        orderLetzteNachpruefung,
        orderLetzterUploadAt,
        hasPruefbericht,
        jahr,
    }: RegelpruefungPruefberichteCollectionParams): Promise<RegelpruefungPruefberichtResponse> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiPruefberichteGetCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                eingereichtAt,
                eingereichtAt2,
                einrichtung,
                einrichtung2,
                einrichtungBehoerde,
                einrichtungBehoerde2,
                einrichtungStandort,
                einrichtungStandort2,
                status,
                status2,
                orderEinrichtungName,
                orderEinrichtungBehoerdeName,
                orderPruefDatum,
                orderLetzteNachpruefung,
                orderLetzterUploadAt,
                hasPruefbericht,
                jahr
            )
            .then((res) => res.data as RegelpruefungPruefberichtResponse);
    }

    public patchPruefberichtGeprueftStatus(id: string): Promise<RegelpruefungPruefberichtStatusItem> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiPruefberichteIdpruefenPatch(id, {})
            .then((res) => res.data as RegelpruefungPruefberichtStatusItem);
    }

    public getRegelpruefungAntragCollection({
        page,
        itemsPerPage,
        qSearch,
        eingereichtAt,
        eingereichtAt2,
        einrichtung,
        einrichtung2,
        einrichtungBehoerde,
        einrichtungBehoerde2,
        einrichtungStandort,
        einrichtungStandort2,
        status,
        status2,
        orderEinrichtungName,
        orderEinrichtungBehoerdeName,
        orderPruefDatum,
        orderLetzteNachpruefung,
        orderLetzterUploadAt,
        hasPruefbericht,
        jahr,
    }: RegelpruefungAntragCollectionParams): Promise<ApiRegelpruefungAntragGetCollection200Response> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiRegelpruefungAntragGetCollection(
                page,
                itemsPerPage || 9999,
                qSearch,
                eingereichtAt,
                eingereichtAt2,
                einrichtung,
                einrichtung2,
                einrichtungBehoerde,
                einrichtungBehoerde2,
                einrichtungStandort,
                einrichtungStandort2,
                status,
                status2,
                orderEinrichtungName,
                orderEinrichtungBehoerdeName,
                orderPruefDatum,
                orderLetzteNachpruefung,
                orderLetzterUploadAt,
                hasPruefbericht,
                jahr
            )
            .then((res) => res.data);
    }

    public createRegelpruefung(id: number): Promise<RegelpruefungAntragJsonld> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiRegelpruefungAntragPost(
                {
                    einrichtung: toIriId(URL_EINRICHTUNG, id),
                },
                {}
            )
            .then((res) => res.data);
    }

    public getRegelpruefungAntragJson({
        id,
        step = 'einreichen',
        objectType,
        uuid,
    }: EinrichtungApiFormGetParams): Promise<FormResponse> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiRegelpruefungAntragIdformGet(id, step, objectType, uuid)
            .then((res) => res.data as FormResponse);
    }

    public patchRegelpruefungAntragJson({
        id,
        step = 'einreichen',
        body,
        objectType,
        uuid,
        persist,
        fields,
    }: EinrichtungApiFormSubmitParams<RegelpruefungAntrag>): Promise<RegelpruefungAntragFormData> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiRegelpruefungAntragIdformPatch(id, step, body, objectType, uuid, persist, fields)
            .then((res) => res.data as RegelpruefungAntragFormData);
    }

    public workflowRegelpruefungItem(id?: number, action?: string): Promise<RegelpruefungAntragJsonld> | void {
        if (!id || !action) {
            return console.warn('Id and action must be set for workflowRegelpruefungItem');
        }

        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiRegelpruefungAntragIdworkflowPatch(String(id), { action: action })
            .then((res) => res.data);
    }

    public getRegelpruefungItem(id?: string): Promise<RegelpruefungAntragJsonldRegelpruefungRead> {
        return new RegelpruefungAntragApi(undefined, '', this.client)
            .apiRegelpruefungAntragIdGet(String(id))
            .then((res) => res.data as RegelpruefungAntragJsonldRegelpruefungRead);
    }

    public getAuswertungP44EinrichtungCollection({
        page,
        itemsPerPage,
        qSearch,
        status,
        status2,
        art,
        art2,
        wohngemeinschaftLeistungsbereich,
        wohngemeinschaftLeistungsbereich2,
        wohngemeinschaftArt,
        wohngemeinschaftArt2,
        adresseCx,
        adresseCx2,
        adresseCy,
        adresseCy2,
        existsEnabled,
        existsActivated,
        existsDeleted,
        meldungenStatus,
        zustaendigerBenutzer,
        keinZustaendigerBenutzer,
        belegbarePlaetzeStatus,
        orderName,
        orderNrwKey,
        orderErstinbetriebnahme,
        orderStatus,
        orderAdressePlz,
        orderAdresseOrt,
        orderBelegbarePlaetzeModifiedAt,
        orderBelegbarePlaetzeAufgefordert,
        orderBelegbarePlaetzeDauerpflege,
        orderBelegbarePlaetzeKurzzeitpflege,
        orderFemAntraegeAnzahlGerichtlicherGenehmigung,
        orderFemAntraegeAnzahlEinwilligung,
        orderFemAntraegeZuletztEingereichtAt,
        orderDeleted,
        orderUpdateDeadline,
        orderArt,
        orderFemUeberfaellig,
        orderFemErinnerung,
        orderLetzteMeldung,
        orderZustaendig,
        orderRegelpruefungenPruefDatum,
        orderRegelpruefungenLetzterUploadAt,
        options,
    }: EinrichtungListOptionParams): Promise<ApiAuswertung44VWtgGetCollection200Response> {
        return new EinrichtungApi(undefined, '', this.client)
            .apiAuswertung44VWtgGetCollection(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((res) => res.data as ApiAuswertung44VWtgGetCollection200Response);
    }

    public getAuswertungP44vWtgExport({ qSearch, options }: ExportRequestParams): Promise<ExportRequestResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .auswertung44VWtgCsv(qSearch, options)
            .then((res) => res.data as ExportRequestResponse);
    }

    public getFemAuswertungExport({
        jahr,
        quartal,
        bezirksregierung,
        behoerde,
        gemeinde,
        arten,
        options,
    }: FemExportCollectionParams) {
        return new FemExportApi(undefined, '', this.client)
            .apiFemexportCsvGet(jahr, quartal, bezirksregierung, behoerde, gemeinde, arten, options)
            .then((res) => res.data);
    }

    public getFemExportFormData({ formData, options }: FemExportRequestParams) {
        return new FemExportApi(undefined, '', this.client)
            .apiFemExportPost(formData || {}, options)
            .then((res) => res.data);
    }

    public getStandardauswertung({ formData, options }: StandardauswertungRequestParams) {
        return new StandardauswertungApi(undefined, '', this.client)
            .apiStandardauswertungPost(formData || {}, options)
            .then((res) => res.data);
    }

    public getStandardauswertungExport({
        bezirksregierung,
        behoerde,
        gemeinde,
        options,
    }: StandardauswertungCsvExportParams) {
        return new StandardauswertungApi(undefined, '', this.client)
            .apiStandardauswertungCsvGet(bezirksregierung, behoerde, gemeinde, options)
            .then((res) => res.data);
    }

    public getRegelpruefungExport({ format = 'xlsx' }: RegelpruefungFileExportParams): Promise<ExportRequestResponse> {
        return new EinrichtungApi(undefined, '', this.client)
            .apiRegelpruefungCsvGet(format)
            .then((res) => res.data as ExportRequestResponse);
    }

    public createBenutzerAntragItem(): Promise<BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm> {
        return new BenutzerFormApi(undefined, '', this.client)
            .benutzerFormGet()
            .then((res) => res.data as BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm);
    }

    public getBenutzerFormItemById(
        id: string
    ): Promise<BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm> {
        return new BenutzerFormApi(undefined, '', this.client)
            .benutzerFormGetUser(id)
            .then((res) => res.data as BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm);
    }

    public putBenutzerAntrag(body: BenutzerForm, persist?: boolean) {
        return new BenutzerFormApi(undefined, '', this.client)
            .apiBenutzerFormPut(body, persist)
            .then((res) => res.data);
    }

    public getExportsCollection({
        page,
        itemsPerPage,
        benutzer,
        benutzer2,
        reference,
        reference2,
        status,
        status2,
        resource,
        resource2,
        orderResource,
        orderStatus,
        orderCreatedDate,
        orderUpdatedDate,
        options,
    }: ExportRequestCollectionParams): Promise<ExportRequestCollectionResponse> {
        return new ExportRequestApi(undefined, '', this.client)
            .apiExportRequestGetCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                reference,
                reference2,
                status,
                status2,
                resource,
                resource2,
                orderResource,
                orderStatus,
                orderCreatedDate,
                orderUpdatedDate,
                options
            )
            .then((res) => res.data as ExportRequestCollectionResponse);
    }
}

export const backendApiService = new ApiService();
