import { Schema } from 'forms/types/UiSchemaTypes';

export const landschaftsverbandSchema: Schema = {
    type: 'object',
    required: ['name', 'adressDaten', 'ansprechpartner', 'telefon', 'email'],
    properties: {
        name: {
            type: 'string',
            title: 'Name des Landschaftsverbands',
        },
        adressDaten: {
            type: 'object',
            title: 'Hausanschrift',
            // @ts-ignore
            custom: {
                resetField: 'label',
                block_prefixes: ['adresse_lookup_autocomplete'],
            },
        },
        ansprechpartner: {
            type: 'string',
            title: 'Ansprechpartner',
        },
        telefon: {
            type: 'string',
            title: 'Telefon',
        },
        fax: {
            type: 'string',
            title: 'Telefax',
        },
        email: {
            type: 'string',
            title: 'E-Mail-Adresse',
        },
    },
};
