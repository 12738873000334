import { Container, styled } from '@mui/material';

export const TextContainer = styled(Container)(
    ({ theme }) => `
    max-width: 70%;
    margin: 0;

    h1 {
        margin: 2.8rem 0 1.6rem;
    }

    h2 {
        margin: 1.6rem 0 1.0rem;
    }

    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
`
);
