import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { authLog } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';

/**
 * Provides an async function that refreshes the token.
 */
export const useAuthRefresh = (): (() => Promise<void>) => {
    const login = useAuthState(({ login }) => login);
    return useCallback(async () => {
        authLog('[UseAuthRefresh.Refresh]');
        const token = await backendApiService.getRefreshToken();
        login(token);
    }, [login]);
};
