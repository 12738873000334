import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountCircleOutlined, ArrowBack, HelpOutlineOutlined, Menu } from '@mui/icons-material';
import {
    Badge,
    Box,
    BoxProps,
    Button,
    Divider,
    Hidden,
    MenuItem,
    styled,
    Toolbar as ToolbarBase,
    ToolbarProps,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useAuthUsername, useHasRole, useIsImpersonated, useIsLoggedIn, useLogout } from 'api/auth';
import { VersionTag } from 'components/VersionTag';
import { ROLES } from 'constants/roles';
import { Link } from 'elements/Link';
import { LoginLink } from 'layout/components/LoginLink/LoginLink';
import { Logo, LogoLink } from 'layout/components/Logo';
import { useNavigationCallback } from 'navigation/hooks/useNavigationCallback';
import { PathBuilder } from 'navigation/Paths';
import { BenutzerAufgaben, useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

import { Impersonate } from './components/Impersonate/Impersonate';
import { LogoWrapper } from './container/LogoWrapper';
import { HeaderConfig, MenuInterface } from './HeaderConfig';
import { MainNavigationDrawer } from './MainNavigationDrawer';
import { Dropdown, DropdownButton } from './menu/Dropdown';
import { MenuLoader } from './menu/MenuLoader';
import { NavigationMenuItem } from './menu/NavigationMenuItem';

const HeaderWrapper = styled(Box)<BoxProps>(
    ({ theme }) => `
    height: ${theme.mixins.header.height}px;
    background: ${theme.palette.primary.lighter};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
`
);

const Toolbar = styled(ToolbarBase)<ToolbarProps>(
    ({ theme }) => `
    padding-left: 0;
    padding-right:0;
    & .MuiSvgIcon-root {
        margin-right: 7px
    }
    background-color:${theme.palette.primary.lighter};
    border-bottom: 2px solid ${theme.palette.divider};
    `
);

const MainNavigation = styled('nav')<BoxProps>(
    ({ theme }) => `
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    gap: 3%;
    height: 64px;

    & > .MuiButtonBase-root {
        height: 64px;
        padding: 0 ${theme.spacing(1)};
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
`
);

const MetaNavigation = styled('nav')<BoxProps>(
    ({ theme }) => `
    align-items: center;
    display: flex;
    height: 64px;
    padding-left: ${theme.spacing(1)};
    margin-left: auto;

    & > .MuiButtonBase-root {
        margin: 0 ${theme.spacing(2)};
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: none;
            background-color: ${theme.palette.primary.light}
        }
    }
`
);

const StyledHeaderBadge = styled(Badge)(() => ({
    marginLeft: 20,
    marginRight: 15,
}));

interface HeaderWrapperProps {
    id?: string;
    mobileMenus: MenuInterface[];
}

const DropDownButtonLabel = ({
    label,
    aufgabenBadge,
    ariaLabel,
}: {
    label: string;
    aufgabenBadge?: Array<keyof BenutzerAufgaben>;
    ariaLabel?: string;
}): ReactElement => {
    const { isLoadingAufgaben, getBenutzerAufgabenTotal } = useBenutzerAufgaben();
    const aufgabenCount = getBenutzerAufgabenTotal(aufgabenBadge);

    return (
        <>
            {label}

            {aufgabenBadge ? (
                <>
                    {isLoadingAufgaben ? (
                        <MenuLoader withSpacing />
                    ) : (
                        <StyledHeaderBadge
                            aria-label={ariaLabel ? `${ariaLabel} ${aufgabenCount}` : undefined}
                            badgeContent={aufgabenCount}
                            color="primary"
                            showZero
                        />
                    )}
                </>
            ) : null}
        </>
    );
};

export const Header = ({ id }: HeaderWrapperProps) => {
    const navigate = useNavigate();

    const logout = useLogout();
    const hasRole = useHasRole();
    const isImpersonated = useIsImpersonated();
    const isLoggedIn = useIsLoggedIn();
    const username = useAuthUsername();

    const handleLogout = (): Promise<void> => {
        return logout().then(() => navigate(PathBuilder.home.path));
    };

    const [drawer, setDrawer] = useState(false);
    const closeDrawer = useCallback(() => setDrawer(false), []);
    useNavigationCallback(closeDrawer);

    const hasPasswordReset = hasRole(ROLES.PASSWORD_RESET);

    const logoLink = useMemo(
        () => (isLoggedIn ? PathBuilder.home.dashboard.path : PathBuilder.home.path),
        [isLoggedIn]
    );

    if (hasPasswordReset) {
        return (
            <HeaderWrapper id={id} component="header" role="banner">
                <Toolbar disableGutters>
                    <LogoWrapper id="LogoWrapper">
                        <LogoLink to={logoLink} underline="none">
                            <Logo />
                        </LogoLink>
                    </LogoWrapper>
                </Toolbar>
            </HeaderWrapper>
        );
    }

    return (
        <HeaderWrapper id={id} component="header" role="banner">
            <Toolbar disableGutters>
                <LogoWrapper id="LogoWrapper">
                    <LogoLink to={logoLink} underline="none">
                        <Logo />
                    </LogoLink>

                    {isLoggedIn && (
                        <Hidden lgUp>
                            <Button
                                onClick={() => setDrawer(!drawer)}
                                aria-label="Menü öffnen"
                                aria-expanded={drawer}
                                aria-haspopup="menu"
                            >
                                <Menu />
                            </Button>
                        </Hidden>
                    )}
                </LogoWrapper>

                <MainNavigationDrawer
                    open={drawer}
                    header={
                        <Toolbar disableGutters>
                            <LogoWrapper id="LogoWrapper">
                                <Link to={logoLink} underline="none">
                                    <Logo />
                                </Link>

                                <Hidden lgUp>
                                    <Button onClick={closeDrawer}>
                                        <ArrowBack />
                                    </Button>
                                </Hidden>
                            </LogoWrapper>
                        </Toolbar>
                    }
                    onClose={closeDrawer}
                />

                <Hidden lgDown>
                    {isLoggedIn ? (
                        <MainNavigation id="Mainmenu" aria-label="Hauptnavigation" role="navigation">
                            {HeaderConfig.areas
                                .filter((area) => hasRole(area.roles))
                                .map((area, i) => {
                                    const ariaLabel = () => {
                                        if (area.aria?.label) {
                                            return area.aria.label;
                                        }

                                        return undefined;
                                    };

                                    if (!area.menu?.length) {
                                        return (
                                            <DropdownButton focusRipple={false} component={Link} to={area.path} key={i}>
                                                <DropDownButtonLabel
                                                    label={area.name}
                                                    aufgabenBadge={area.aufgabenBadge}
                                                    ariaLabel={ariaLabel()}
                                                />
                                            </DropdownButton>
                                        );
                                    }

                                    const availableMenuItems = area.menu.filter((menu) => hasRole(menu.roles));

                                    return (
                                        <Dropdown
                                            label={
                                                <DropDownButtonLabel
                                                    label={area.name}
                                                    aufgabenBadge={area.aufgabenBadge}
                                                    ariaLabel={ariaLabel()}
                                                />
                                            }
                                            name={area.name}
                                            key={i}
                                            buttonProps={{
                                                focusRipple: false,
                                            }}
                                        >
                                            {availableMenuItems.map((menu, i) => {
                                                const { divide, ...menuProps } = menu;
                                                const hasPreviousItem = !!availableMenuItems[i - 1];

                                                return (
                                                    <NavigationMenuItem
                                                        key={uuidv4()}
                                                        divide={divide && hasPreviousItem}
                                                        ariaLabel={ariaLabel()}
                                                        {...menuProps}
                                                    />
                                                );
                                            })}
                                        </Dropdown>
                                    );
                                })}
                        </MainNavigation>
                    ) : null}
                </Hidden>

                <MetaNavigation id="Metamenu" aria-label="Metanavigation">
                    <VersionTag />

                    <DropdownButton
                        startIcon={<HelpOutlineOutlined />}
                        component={Link}
                        to={PathBuilder.home.hilfe.path}
                    >
                        <Hidden mdDown>Hilfe</Hidden>
                    </DropdownButton>

                    {isLoggedIn && (hasRole(ROLES.FACHADMIN) || isImpersonated) && <Impersonate />}

                    {isLoggedIn && (
                        <Dropdown
                            label={<Hidden mdDown>Profil</Hidden>}
                            name="Profil"
                            startIcon={<AccountCircleOutlined />}
                        >
                            <MenuItem>
                                <strong>{username}</strong>
                            </MenuItem>

                            <Divider aria-hidden />

                            <MenuItem component={Link} to={PathBuilder.home.profile.benutzerdaten.path}>
                                Eigene Benutzerdaten
                            </MenuItem>

                            <Divider aria-hidden />

                            <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
                        </Dropdown>
                    )}

                    {!isLoggedIn && <LoginLink />}
                </MetaNavigation>
            </Toolbar>
        </HeaderWrapper>
    );
};
