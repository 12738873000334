import * as React from 'react';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup, styled } from '@mui/material';

import { RadioWithFocus } from 'elements/RadioWithFocus';
import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useFocus } from 'forms/hooks/useFocus';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const RadioAuswahlControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    showFieldNumberLabels,
    gridLayout,
    uischema,
}) => {
    const { ref, requestFocusAfterValidate } = useFocus<HTMLDivElement>();
    const { scrollRef } = useScrollInView({ ref });
    const keyDownRef = useRef<boolean>();

    const handleValueChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleChange(path, e.target.value);
            if (keyDownRef.current) {
                requestFocusAfterValidate();
                keyDownRef.current = false;
            }
        },
        [handleChange, path, requestFocusAfterValidate]
    );

    const Choices = useMemo(
        () =>
            (schema.oneOf || []).map((entry, index: number) => (
                <FormControlLabel
                    key={`${index}#${entry.const}`}
                    value={entry.const}
                    control={
                        <RadioWithFocus
                            inputProps={
                                {
                                    'data-cy': `form_${path}_${entry.const}`,
                                } as any
                            }
                        />
                    }
                    label={entry.title}
                />
            )),
        [schema.oneOf, path]
    );

    const readonlyValueText = useMemo(() => {
        if (!readonly) return '';
        const selectedValue = data || schema.default || '';
        if (!selectedValue?.length) return '-';
        return (schema.oneOf || []).find((entry) => entry.const === selectedValue)?.title || selectedValue;
    }, [readonly, data, schema.default, schema.oneOf]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            fullValueWidth
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            label={label}
                            hasErrors={hasErrors}
                            errors={errors}
                            text={readonlyValueText}
                        />
                    ) : (
                        <FormControl fullWidth disabled={disabled} ref={scrollRef}>
                            <ChoicesFormLabel data-cy={label}>
                                {label}
                                {required && <RequiredFlag />}
                            </ChoicesFormLabel>

                            <ChoicesGroup
                                value={data || schema.default || ''}
                                onChange={handleValueChange}
                                onKeyDown={() => (keyDownRef.current = true)}
                            >
                                {Choices}
                            </ChoicesGroup>

                            {hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

const ChoicesFormLabel = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const ChoicesGroup = styled(RadioGroup)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));

export const RadioAuswahlControl = withCustomControlProps(RadioAuswahlControlComponent);
