import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { TraegerForm } from './TraegerForm';

const Index = () => {
    return (
        <TraegerForm
            title="Leistungsanbieter: Bearbeiten"
            messages={{
                success: 'Leistungsanbieter wurde aktualisiert.',
                error: 'Leistungsanbieter konnte nicht aktualisiert werden.',
            }}
            submitLabel="Speichern"
        />
    );
};

export const TraegerBearbeitenPage = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_TRAEGER);
