export const basisdaten_table = {
    type: 'SummaryTable',
    columns: 3,
    scope: '#/properties/basisdaten',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/traegerBezeichnung',
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/traegerAdresse/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Adresse der Einrichtung',
                    scope: '#/properties/label',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/traegerAnschriftTyp',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/traegerTelefon',
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/traegerTraegerverband/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Adresse des Trägerverbands',
                    scope: '#/properties/name',
                },
            ],
        },
    ],
};
