import * as React from 'react';
import { ChangeEvent, useMemo, useRef } from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup, styled } from '@mui/material';

import { RadioWithFocus } from 'elements/RadioWithFocus';
import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useFocus } from 'forms/hooks/useFocus';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const JaNeinControlComponent: React.FC<CustomControlProps<boolean>> = ({
    config,
    path,
    label,
    required,
    data,
    handleChange,
    disabled,
    readonly,
    formula,
    hasErrors,
    errors,
    showFieldNumberLabels,
    gridLayout,
    schema,
    uischema,
    id,
}) => {
    const { ref, requestFocusAfterValidate } = useFocus<HTMLInputElement>();
    const keyDownRef = useRef<boolean>(false);
    const elementRef = useScrollInView({ ref });

    const submitOnChange = useMemo(() => uischema.options?.submitOnChange === true, [uischema]);
    const validateOnChange = useMemo(() => uischema.options?.validateOnChange === true, [uischema]);

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (submitOnChange) {
            config.requestPersist(path);
        }

        handleChange(path, e.target.value === 'true', validateOnChange);

        if (keyDownRef.current) {
            requestFocusAfterValidate();
            keyDownRef.current = false;
        }
    };

    const controlLabels: Record<'positive' | 'negative', string> = useMemo(
        () => ({
            positive: schema.custom?.labels?.positive ?? 'Ja',
            negative: schema.custom?.labels?.negative ?? 'Nein',
        }),
        [schema]
    );

    return (
        <RowControl
            name={path}
            required={required}
            formula={formula}
            fullValueWidth
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            label={label}
                            hasErrors={hasErrors}
                            errors={errors}
                            text={data === undefined ? '-' : data ? controlLabels.positive : controlLabels.negative}
                        />
                    ) : (
                        <FormControl fullWidth disabled={disabled}>
                            {label?.length > 0 && (
                                <ChoicesFormLabel data-cy={label}>
                                    {label}
                                    {required && <RequiredFlag />}
                                </ChoicesFormLabel>
                            )}

                            <ChoicesGroup
                                id={id}
                                value={data === undefined ? null : data}
                                onKeyDown={() => {
                                    keyDownRef.current = true;
                                }}
                                onChange={handleValueChange}
                                ref={elementRef.scrollRef}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={
                                        <RadioWithFocus
                                            inputProps={
                                                {
                                                    'data-cy': `form_${path}_ja`,
                                                } as any
                                            }
                                        />
                                    }
                                    label={controlLabels.positive}
                                />

                                <FormControlLabel
                                    value={false}
                                    control={
                                        <RadioWithFocus
                                            inputProps={
                                                {
                                                    'data-cy': `form_${path}_nein`,
                                                } as any
                                            }
                                        />
                                    }
                                    label={controlLabels.negative}
                                />
                            </ChoicesGroup>

                            {!readonly && hasErrors && (
                                <FormHelperText component="div">{<ErrorList errors={errors} />}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const JaNeinControl = withCustomControlProps(JaNeinControlComponent);

const ChoicesFormLabel = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const ChoicesGroup = styled(RadioGroup)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));
