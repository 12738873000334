import React from 'react';

import { PageHeading } from 'components/PageHeading';
import { EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES } from 'constants/labels';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangeboteTable } from 'pages/Leistungsangebote/components/LeistungsangeboteTable';

const Index = () => {
    return (
        <ContentContainer title="Zur Meldung aufgefordert">
            <StyledContainer>
                <PageHeading title="Zur Meldung aufgefordert: Übersicht" />

                <LeistungsangeboteTable
                    enableMeldungenStatusFilter={false}
                    meldungenStatus={EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES.AUFGEFORDERT}
                    pathBuilder={PathBuilder.home.aufgaben.meldungen.aufgefordert}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const ZurMeldungAufgefordertOverview = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_AUFGEFORDERT
);
