import React from 'react';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { STATUS } from 'constants/antragStatus';

import { CheckboxesFilter } from './CheckboxesFilter';

export const MeldungenStatusFilter = (): MUIDataTableColumnOptions => {
    return {
        customFilterListOptions: {
            render: (value) => {
                return value.map(() => 'Gelöschte Meldungen anzeigen');
            },
        },
        filterOptions: {
            names: ['Gelöscht'],
            display: (filterList, onChange, index, column) => {
                return (
                    <CheckboxesFilter
                        index={index}
                        column={column}
                        filterData={[STATUS.DELETED]}
                        filterValue={filterList[index]}
                        filterLabels={{ [STATUS.DELETED]: 'Gelöscht' }}
                        onChange={onChange}
                    />
                );
            },
        },
        filterType: 'custom',
    };
};
