import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import { useSpecificRole } from 'api/auth';
import { useBenutzerListActions } from 'components/DataTable/actions/useBenutzerListActions';
import { useBenutzerColumns } from 'components/DataTable/columns/useBenutzerColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { AntragPathBuilder } from 'components/DataTable/hooks/useListActions';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { TableFilters } from 'components/DataTable/hooks/useTableFilters';
import { TableOptions } from 'components/DataTable/hooks/useTableOptions';
import { sortBy } from 'components/DataTable/tableUtils';
import { BENUTZER_STATUS_FILTER_LABELS } from 'constants/labels';
import { Link } from 'elements/Link';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { PathBuilder } from 'navigation/Paths';

interface BenutzerTableOptions {
    showRolleColumn?: boolean;
    disableActions?: boolean;
}

interface BenutzerOverviewProps<Data = any> {
    getDataPromise: (...args: any) => Promise<Data>;
    pathBuilder: AntragPathBuilder;
    title?: string;
    displayCreateButton?: boolean;
    titleVariant?: Variant;
    dataOptions?: Record<string, any>;
    tableOptions?: TableOptions & BenutzerTableOptions;
    showBenutzerTable?: boolean;
}

export const BenutzerTable = <Data = any,>({
    getDataPromise,
    pathBuilder,
    title,
    titleVariant,
    displayCreateButton,
    dataOptions = {},
    tableOptions = {},
    showBenutzerTable = true,
}: BenutzerOverviewProps<Data>) => {
    const { isSupport } = useSpecificRole();
    const theme = useTheme();

    const [isTableReady, setTableReady] = useState<boolean>(false);
    const { serverDataChanged, handleAction } = useBenutzerListActions(pathBuilder);

    const { showRolleColumn, disableActions, ...options } = tableOptions;

    const initialTableOptions: TableOptions = useMemo(
        () => ({
            sortOrder: {
                name: 'nachname',
                direction: 'asc',
            },
            viewColumns: true,
            ...options,
        }),
        [options]
    );

    const filterDeaktiviertAt = useCallback(
        (filters: TableFilters) => {
            if (!isSupport) {
                return true === tableOptions.filter && !!filters?.['deaktiviertAt']?.length;
            }

            switch (filters?.['deaktiviertAt']?.[0]) {
                case BENUTZER_STATUS_FILTER_LABELS.ACTIVATED:
                    return false;
                case BENUTZER_STATUS_FILTER_LABELS.DEACTIVATED:
                    return true;
                default:
                    return undefined;
            }
        },
        [isSupport, tableOptions.filter]
    );

    const getData: GetData<Data> = useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return getDataPromise({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText,
            orderNachname: sortBy(sortOrder, 'nachname'),
            orderVorname: sortBy(sortOrder, 'vorname'),
            orderEmail: sortBy(sortOrder, 'email'),
            orderDeaktiviertAt: sortBy(sortOrder, 'deaktiviertAt'),
            existsDeaktiviertAt: filterDeaktiviertAt(filters),
            existsDeletedAt: false,
            ...dataOptions,
        });
        // eslint-disable-next-line
    }, []);

    const columns = useBenutzerColumns(handleAction, showRolleColumn, disableActions);

    useEffect(() => {
        if (!showBenutzerTable) {
            setTableReady(true);
        }
    }, [showBenutzerTable]);

    return (
        <>
            {showBenutzerTable ? (
                <>
                    {title ? <Typography variant={titleVariant || 'h1'}>{title}</Typography> : null}

                    {isTableReady && displayCreateButton ? (
                        <ButtonGroupGrid marginBottom={theme.spacing(4)}>
                            {displayCreateButton ? (
                                <Button
                                    component={Link}
                                    to={PathBuilder.home.verwaltung.benutzer.create}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<AddIcon />}
                                >
                                    Neuen Benutzer erstellen
                                </Button>
                            ) : null}
                        </ButtonGroupGrid>
                    ) : null}

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData as GetData<any>}
                        initialOptions={initialTableOptions}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                        onReady={(data) => {
                            if (data) {
                                setTableReady(true);
                            }
                        }}
                    />
                </>
            ) : null}
        </>
    );
};
