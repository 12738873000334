import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { EinrichtungTypes } from 'constants/einrichtungTypes';

export const useLeistungserbringerDescription = () => {
    return useCallback((einrichtungArt?: string) => {
        switch (einrichtungArt) {
            case EinrichtungTypes.ASSISTED_LIVING:
                return (
                    <Typography paragraph>
                        Bitte geben Sie hier die Leistungsangebote an, die in Ihrer Einrichtung Pflege- oder
                        Betreuungsleistungen erbringen (z.B. Ambulante Dienste).
                    </Typography>
                );
            case EinrichtungTypes.SHARED_HOME:
                return (
                    <Typography paragraph>
                        Bitte geben Sie hier die Leistungsangebote an, die in dieser Wohngemeinschaft Pflege- oder
                        Betreuungsleistungen erbringen (insb. Ambulante Dienste).
                    </Typography>
                );
        }
    }, []);
};
