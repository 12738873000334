import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JsonForms } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';
import { isEqual } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { BenutzerCredentialChangeRequestInput } from 'api/client';
import { FormButton } from 'forms/components/FormButton';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { PathBuilder } from 'navigation/Paths';
import { useFormLayoutConfig } from 'utilities/hooks/useFormLayoutConfig';

type PasswortResetFormProps = {
    stateHandler: (type: string) => void;
    token: string;
};

export const PasswortResetForm = ({ stateHandler, token }: PasswortResetFormProps) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);

    const handleChange = (data: FormStateChange) => {
        if (!isEqual(formData, data.data)) {
            setFormData(data.data);
        }
    };

    const handleSave = () => {
        if (isSubmitting) {
            return;
        }

        setSubmitting(true);

        backendApiService
            .patchPasswordRequest(token, formData as BenutzerCredentialChangeRequestInput)
            .then((res) => {
                if (res.status === 204) {
                    stateHandler('success');
                }
            })
            .catch((error) => {
                if (error.response.status) {
                    stateHandler('exception');
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const configLayout: FormLayoutConfig = useFormLayoutConfig();

    const schema: Schema = {
        required: ['password'],
        properties: {
            password: {
                $id: 'password',
                type: 'string',
                title: 'Passwort',
                // @ts-ignore
                custom: {
                    block_prefixes: ['password_widget'],
                },
            },
        },
    };

    const uiSchema = customVerticalLayout([group([control('password')], { noSeparator: true })]);

    const { valid } = useFormValidation(schema, formData);

    return (
        <>
            <Typography variant="h1">Neues Passwort setzen</Typography>

            <Typography paragraph>Bitte vergeben Sie ein neues Passwort für Ihren Zugang.</Typography>

            <JsonForms
                data={formData}
                schema={schema}
                uischema={uiSchema}
                onChange={handleChange}
                renderers={renderers}
                config={configLayout}
            />

            <Grid container spacing={1} direction={'row-reverse'}>
                <Grid item>
                    <FormButton variant="contained" color="primary" onClick={handleSave} disabled={!valid}>
                        Passwort ändern
                    </FormButton>
                </Grid>

                <Grid item>
                    <FormButton variant="outlined" color="primary" onClick={() => navigate(PathBuilder.home.path)}>
                        Abbrechen
                    </FormButton>
                </Grid>
            </Grid>
        </>
    );
};
