import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { JsonForms } from '@jsonforms/react';
import { Button } from '@mui/material';

import { emailAntragApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { useEinrichtungApi } from 'api/hooks/useEinrichtungApi';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { AntragType } from 'constants/antragTypes';
import { Link } from 'elements/Link';
import { FormActionButtons } from 'forms/components/FormActionButtons';
import { FormNavigation } from 'forms/components/FormNavigation';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useFormActions } from 'forms/hooks/useFormActions';
import { useFormHandling } from 'forms/hooks/useFormHandling';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useFormValidationMode } from 'forms/hooks/useFormValidationMode';
import { useStepUISchema } from 'forms/hooks/useStepUISchema';
import { renderers } from 'forms/renderers';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';

interface AufforderungPageProps {
    antragType: AntragType;
    pathBuilder: PathBuilderProps;
    cancelPath: string;
}

export const EmailAntragForm = ({ antragType, pathBuilder, cancelPath }: AufforderungPageProps) => {
    const { id, step: currentStep } = useParams();

    const { formSubmitted, setRequestMessage, setFormSubmitted } = useFormState();

    const api = useEinrichtungApi(emailAntragApi);

    const {
        data,
        schema,
        validate,
        reloadData,
        requestValidate,
        requestPersist,
        onChange,
        isLoading,
        isDirty,
        isPersisting,
        steps,
        submit,
    } = useFormHandling(api);

    const { validationMode, switchValidation } = useFormValidationMode();

    const config: any = useMemo(
        () => ({
            validate,
            reloadData,
            requestValidate,
            requestPersist,
            onChange,
            loadFormData: api.getFormDataAPI,
            submitFormData: api.submitAPI,
        }),
        [validate, reloadData, requestValidate, requestPersist, onChange, api.getFormDataAPI, api.submitAPI]
    );

    const { uiSchema } = useStepUISchema(schema, antragType, currentStep);

    const { valid } = useFormValidation(schema, data, config, uiSchema);

    const handleSave = useCallback(
        (validate = true) => {
            if (!valid) {
                setRequestMessage(errorMessage('Bitte prüfen Sie Ihre Eingaben'));
                setFormSubmitted(true);
                return Promise.reject();
            }

            return submit({ body: data, persist: true }).then(() => {
                if (validate) {
                    switchValidation(true);
                }

                return Promise.resolve();
            });
        },
        [valid, submit, data, setRequestMessage, setFormSubmitted, switchValidation]
    );

    const { getButtonColor, handleFormAction } = useFormActions({
        antragId: Number(id),
        antragType: antragType,
        pathBuilder,
        workflowFormItem: backendApiService.workflowEmailAntrag.bind(backendApiService),
        onSave: handleSave,
    });

    const formActions = useMemo(
        () => (steps || []).find((step) => step?.state === 'active' && step.step === currentStep)?.actions || [],
        [steps, currentStep]
    );

    useEffect(() => {
        if (formSubmitted) {
            switchValidation(false);
        }
        // eslint-disable-next-line
    }, [formSubmitted, validationMode]);

    return (
        <ContentContainer title={schema?.title}>
            <StyledContainer>
                <PageHeading title={`${schema?.title}`} />

                <BaseGridContainer lg={10}>
                    <Pflichtfeld display={!!schema?.required?.length} />

                    <AppLoaderContainer isLoading={isLoading}>
                        {data && uiSchema ? (
                            <JsonForms
                                data={data}
                                schema={schema}
                                uischema={uiSchema}
                                renderers={renderers}
                                onChange={(state) => onChange(state.data)}
                                config={config}
                                validationMode={validationMode}
                            />
                        ) : null}
                    </AppLoaderContainer>
                </BaseGridContainer>

                <ButtonGroupGrid>
                    <FormNavigation isDirty={isDirty} isDisabled={isPersisting}>
                        <FormActionButtons
                            isDirty={isDirty}
                            actions={formActions}
                            handleFormAction={handleFormAction}
                            getButtonColor={getButtonColor}
                        />
                    </FormNavigation>

                    <Button component={Link} to={cancelPath} variant="outlined">
                        Abbrechen
                    </Button>
                </ButtonGroupGrid>
            </StyledContainer>
        </ContentContainer>
    );
};
