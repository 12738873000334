import React, { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Chip, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { useStartAdornment } from 'forms/hooks/useStartAdornment';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

export interface AddressSelectOption {
    label?: string | null;
    strasse?: string | null;
    hausnummer?: string | null;
    plz?: string | null;
    ort?: string | null;
    cy?: string | null;
    cx?: string | null;
}

export interface SelectOption extends AddressSelectOption {
    '@id'?: string | null;
    id?: number;
    name?: string | null;
    status?: string | null;
    ort?: string | null;
    plz?: string | null;
}

interface TypeaheadPropsBase {
    schema: Schema;
    uischema?: UiSchemaType;
    path: string;
    error?: boolean;
    noOptionsText?: string;
    noSearchText?: string;
    loadingText?: string;
    defaultOptions?: Array<Record<string, number | string>>;
    id?: string;
    displayBenutzerStatus?: boolean;
    required?: boolean;
    placeholder?: string;
    loadInitialOptions?: boolean;
    enabled?: boolean;

    getData: (value: string, options: AxiosRequestConfig) => Promise<any>;

    getOption(item: any): SelectOption;
}

type TypeaheadMultipleProps = TypeaheadPropsBase & {
    multiple: true;
    data: SelectOption[] | null;
    onChange: (value: SelectOption[] | null) => void;
};

type TypeaheadSingleProps = TypeaheadPropsBase & {
    multiple: false;
    data: SelectOption | null;
    onChange: (value: SelectOption | null) => void;
};

type TypeaheadProps = TypeaheadSingleProps | TypeaheadMultipleProps;

export const StyledStatusChip = styled(Chip)(
    () => `
  color: white;
  font-weight: bold;
  background: darkred;
`
);

export const Typeahead = ({
    id,
    data = null,
    schema,
    uischema,
    path,
    multiple,
    error,
    getData,
    getOption,
    onChange,
    noOptionsText = 'Keine Elemente gefunden.',
    noSearchText = 'Bitte geben Sie einen Suchbegriff ein',
    loadingText = 'Es wird nach Einträgen gesucht...',
    displayBenutzerStatus = false,
    loadInitialOptions,
    required,
    placeholder,
    enabled,
    defaultOptions = [],
}: TypeaheadProps): ReactElement => {
    const timer = useRef<NodeJS.Timeout>();
    const abortController = useRef<AbortController | null>(null);
    const dataNameRef = useRef<string | null | undefined>((data as SelectOption)?.name);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSelected, setCurrentSelected] = useState<string>();
    const isEnabled = useMemo(() => enabled === undefined || enabled, [enabled]);
    const startAdornment = useStartAdornment(uischema as UiSchemaType, schema);

    const loadOptions = (value: string): SelectOption[] | void => {
        abortController.current = new AbortController();

        getData(value, { signal: abortController.current.signal })
            .then((response) => {
                const results = response['hydra:member'] || [];

                setOptions(() => [
                    ...defaultOptions,
                    ...(results.map((item: any) => {
                        return getOption(item);
                    }) as SelectOption[]),
                ]);
            })
            .catch((error) => error)
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onInputChange = (event: SyntheticEvent<Element, Event>, value: string) => {
        if (timer.current) {
            clearTimeout(timer.current);
            abortController.current?.abort();
        }

        setCurrentSelected(value);

        if (!value && !loadInitialOptions) {
            setOptions([]);
            return;
        }

        if (event?.type !== 'change') {
            return;
        }

        timer.current = setTimeout(() => {
            setIsLoading(true);

            loadOptions(value);
        }, 500);
    };

    const handleOnChange = (event: ChangeEvent<object>, newValue: any) => {
        onChange(newValue);
    };

    useDebouncedEffect(
        () => {
            if (loadInitialOptions) {
                loadOptions('');
            }

            if (dataNameRef.current && !loadInitialOptions) {
                loadOptions(dataNameRef.current);
            }
        },
        [loadInitialOptions, dataNameRef],
        300
    );

    useEffect(() => {
        if (!isLoading && abortController.current) {
            abortController.current.abort();
        }
    }, [isLoading]);

    return (
        <Autocomplete
            id={id}
            value={data}
            multiple={multiple}
            disabled={!isEnabled}
            onChange={handleOnChange}
            onInputChange={onInputChange}
            noOptionsText={currentSelected?.length ? noOptionsText : noSearchText}
            loadingText={loadingText}
            getOptionLabel={(option) => option?.label || option?.name || ''}
            isOptionEqualToValue={(option, currentValue) =>
                option?.label === currentValue?.label ||
                option?.name === currentValue?.name ||
                option?.id === currentValue?.id
            }
            options={options}
            loading={isLoading}
            filterOptions={(options: SelectOption[]) => options}
            renderOption={(props, option) => {
                const status = displayBenutzerStatus ? option.status : null;

                return (
                    <li
                        {...props}
                        key={option.id || uuidv4()}
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        {option?.label || option?.name} {status && <StyledStatusChip size="small" label={status} />}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    label={schema.title}
                    placeholder={placeholder ?? ''}
                    disabled={!isEnabled}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        required,
                        inputProps: {
                            ...params.inputProps,
                            'data-cy': `form_${path}`,
                            required,
                        },
                        startAdornment,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
