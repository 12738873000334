import React from 'react';

import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { MeldungProps } from 'pages/Meldung/Details/MeldungDetailPage';

export const Dokumente = ({ meldung }: MeldungProps) => {
    return (
        <DetailsLayout.Group title="Hochgeladene Dokumente" variant="h3">
            <DetailsLayout.DownloadLink value={meldung.data?.dokumente} />
        </DetailsLayout.Group>
    );
};
