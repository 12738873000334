import React from 'react';

import { useAntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { usePruefberichtColumns } from 'components/DataTable/columns/regelpruefung/usePruefberichtColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { usePruefberichtData } from './usePruefberichtData';

const Index = () => {
    const getData = usePruefberichtData();

    const filterOptions = useAntragTableFilterOptions(AntragType.REGELPRUEFUNG);
    const columns = usePruefberichtColumns(filterOptions);

    return (
        <ContentContainer title="Übersicht: Regeprüfungen der Prüfberichte">
            <StyledContainer>
                <PageHeading title="Übersicht: Prüfberichte der Regelprüfungen" />

                <DataTableHydraServerside
                    tableCaption="Tabelle mit allen Prüfberichten der Regelprüfungen von Einrichtungen"
                    getColumns={columns}
                    getData={getData}
                    initialOptions={{
                        searchPlaceholder: 'Suche nach Name, Ort und Plz',
                        filter: true,
                        sortOrder: {
                            name: 'pruefDatum',
                            direction: 'desc',
                        },
                        rowsPerPage: 100,
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const RegelpruefungenPruefberichteOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PRUEFBERICHTE
);
