import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { emailAntragApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { useApiMeldungItem } from 'api/hooks/useApiClient';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { meldungAufforderungConfig } from 'forms/Meldung/meldungAufforderungConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AntragPage } from 'pages/AntragPage';

const Index = () => {
    const { einrichtungId } = useParams();

    const { isLoading, data } = useApiMeldungItem(einrichtungId);

    const headline = useMemo(() => {
        if (!isLoading && data) {
            return `Aufforderung zur Meldung: ${data?.einrichtungName}`;
        }

        return 'Aufforderung zur Meldung';
    }, [isLoading, data]);

    return (
        <AntragPage
            headline={headline}
            customStep="aufforderung"
            antragType={AntragType.EMAIL_ANTRAG}
            pathBuilder={PathBuilder.home.verwaltung.meldung}
            tabConfig={meldungAufforderungConfig}
            workflowFormItem={backendApiService.workflowEmailAntrag.bind(backendApiService)}
            api={emailAntragApi}
            stepUiSchemaDir="meldung"
        />
    );
};

export const MeldungAufforderungAntrag = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNGEN_AUFFORDERUNG
);
