import React, { useState } from 'react';
import { HighlightOffOutlined, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { Box, Button, Popover, styled, Tooltip, Typography } from '@mui/material';

import { useImpersonation } from 'api/auth';
import { FormButton } from 'forms/components/FormButton';
import { ImpersonateForm } from 'layout/components/Impersonate/ImpersonateForm';

export const Impersonate = () => {
    const { impersonator, impersonate } = useImpersonation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clearImpersonation = (): void => {
        impersonate(null);
    };

    const open = !!anchorEl?.isConnected;

    if (impersonator) {
        return (
            <>
                <Tooltip title={`ID: ${impersonator.benutzerId}, E-Mail: ${impersonator.username}`}>
                    <ImpersonateTypography>
                        <ImpersonateIcon /> {`${impersonator.nachname}, ${impersonator.vorname}`}
                    </ImpersonateTypography>
                </Tooltip>

                <ImpersonateFormButton type="button" onClick={clearImpersonation} title="Clear Impersonation">
                    <HighlightOffOutlined />
                </ImpersonateFormButton>
            </>
        );
    }

    return (
        <>
            <ImpersonateButton startIcon={<ImpersonateIcon />} data-cy="impersonate_button" onClick={handleClick}>
                Impersonate
            </ImpersonateButton>

            <Popover
                data-cy="impersonate_container"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box margin={2} style={{ width: '500px' }}>
                    <ImpersonateForm />
                </Box>
            </Popover>
        </>
    );
};

const ImpersonateTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.dark,
    display: 'inline-flex',
    verticalAlign: 'middle',
}));

const ImpersonateIcon = styled(SupervisedUserCircleOutlined)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 0,
}));

const ImpersonateFormButton = styled(FormButton)(({ theme }) => ({
    color: theme.palette.error.dark,
    minWidth: 'unset',
    paddingLeft: '0px',
}));

const ImpersonateButton = styled(Button)(() => ({
    color: '#000',
}));
