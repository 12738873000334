export enum EinrichtungTypes {
    FULLY_INPATIENT_PERMANENT_XI = 'FULLY_INPATIENT_PERMANENT_XI',
    FULLY_INPATIENT_INTEGRATION_XII = 'FULLY_INPATIENT_INTEGRATION_XII',
    FULLY_INPATIENT_SHORT = 'FULLY_INPATIENT_SHORT',
    SEMI_RESIDENTIAL_NIGHT_CARE = 'SEMI_RESIDENTIAL_NIGHT_CARE',
    SEMI_RESIDENTIAL_DAYCARE = 'SEMI_RESIDENTIAL_DAYCARE',
    SEMI_RESIDENTIAL_INTEGRATION = 'SEMI_RESIDENTIAL_INTEGRATION',
    AMBULANT_XI_CARE_CONTRACT = 'AMBULANT_XI_CARE_CONTRACT',
    AMBULANT_XII_CARE_CONTRACT = 'AMBULANT_XII_CARE_CONTRACT',
    HOSPICE = 'HOSPICE',
    ASSISTED_LIVING = 'ASSISTED_LIVING',
    SHARED_HOME = 'SHARED_HOME',
    WERKSTATT = 'WERKSTATT',
}
