import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { AngezeigteBetreuungFormData } from 'api/types';
import { ROLES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { adSchemaEdit, adUiSchemaEdit } from './formConfig';

const initialFormData = {
    name: undefined,
    adresse: undefined,
    beginn: undefined,
    plaetze: undefined,
    ambulanterDienst: undefined,
};

const Index = () => {
    const navigate = useNavigate();

    const handleSubmit = (formData: AngezeigteBetreuungFormData) => {
        return backendApiService.createAngezeigteBetreuungPost(formData);
    };

    return (
        <ContentContainer>
            <StyledContainer>
                <Typography variant="h1">Angezeigte Betreuung in Wohngemeinschaften: Registrieren</Typography>

                <BaseGridContainer>
                    <SimpleForm
                        hideTitle
                        data={initialFormData}
                        schema={adSchemaEdit}
                        uiSchema={adUiSchemaEdit}
                        messages={{
                            success: 'Wohngemeinschaft wurde erstellt.',
                            error: 'Erstellen der Wohngemeinschaft ist fehlgeschlagen.',
                        }}
                        onSubmit={handleSubmit}
                        onAfterSubmit={async () => {
                            navigate(PathBuilder.home.verwaltung.abw.path || '');
                        }}
                        submitLabel="Speichern"
                        backLink={{
                            path: PathBuilder.home.verwaltung.abw.path || '',
                            title: 'Zur Übersicht',
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const AbwCreatePage = withAuthorization(withSideBar(Index, menuVerwaltung), [ROLES.ROLE_AMBULANTER_DIENST]);
