import { MUIDataTableColumnOptions } from 'mui-datatables';

import { LEISTUNGSBEREICH_ART_FILTER_LABELS } from 'constants/labels';

export const LeistungsbereichFilterOptions: MUIDataTableColumnOptions = {
    customFilterListOptions: {
        render: (value) => {
            return `Art der Wohngemeinschaft: ${LEISTUNGSBEREICH_ART_FILTER_LABELS[value]}`;
        },
    },
    filterOptions: {
        names: Object.keys(LEISTUNGSBEREICH_ART_FILTER_LABELS),
        renderValue: (value) => LEISTUNGSBEREICH_ART_FILTER_LABELS[value],
    },
    filterType: 'dropdown',
};
