import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';

export const landschaftsverbandUiSchema = customVerticalLayout([
    control('name'),
    control('adressDaten'),
    control('ansprechpartner'),
    control('telefon'),
    control('fax'),
    control('email'),
]);
