import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { RegistrierungJsonld } from 'api/client';
import { useFormState } from 'forms/state/useFormState';
import { PathBuilder } from 'navigation/Paths';

type RegistrierungFormDataType = {
    anrede?: string;
    title?: string;
    vorname?: string;
    nachname?: string;
    telefon?: string;
    fax?: string;
    email?: string;
    plainPassword?: string;
    password?: string;
};

const initialFormData: RegistrierungFormDataType = {
    anrede: undefined,
    title: undefined,
    vorname: undefined,
    nachname: undefined,
    telefon: undefined,
    fax: undefined,
    email: undefined,
    plainPassword: undefined,
    password: undefined,
};

interface UseRegistrationFormApiReturn {
    formData: RegistrierungFormDataType;
    handleSubmit(updatedData: RegistrierungJsonld): void;
    handleChange(formData: RegistrierungFormDataType): void;
}

export const useRegistrationFormApi = (): UseRegistrationFormApiReturn => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState<RegistrierungFormDataType>(initialFormData);

    const { submitStart, submitEnd } = useFormState();

    const handleSubmit = (updatedData: RegistrierungJsonld) => {
        submitStart();

        const changedData: RegistrierungFormDataType = {
            ...formData,
            ...updatedData,
            ...{
                email: formData.email?.trim(),
            },
        };

        backendApiService
            .postRegistrierung(changedData as RegistrierungJsonld)
            .then(() => {
                setFormData(changedData);

                navigate({
                    pathname: PathBuilder.home.registrierung.success,
                    search: `?email=${encodeURIComponent(formData.email || '')}`,
                });

                return Promise.resolve();
            })
            .catch(() => Promise.reject())
            .finally(() => submitEnd());
    };

    const handleChange = useCallback((data: RegistrierungFormDataType) => setFormData(data), []);

    return {
        formData,
        handleSubmit,
        handleChange,
    };
};
