import { AxiosError } from 'axios';

export interface HydraErrorDescription {
    'hydra:description': string;
}

export const useServerError = () => {
    return (
        serverError: AxiosError<HydraErrorDescription>,
        defaultMessage = 'Bei der Anfrage ist ein Fehler aufgetreten'
    ) => {
        if (Object.hasOwn(serverError.response?.data || {}, 'hydra:description')) {
            return serverError.response?.data['hydra:description'] || '';
        }

        return defaultMessage;
    };
};
