import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungAntrag } from './Components/MeldungAntrag';

const Index = () => {
    return (
        <MeldungAntrag
            headline="Meldung: Prüfen"
            pathBuilder={PathBuilder.home.aufgaben.freigeben.meldungen}
            nextTabLabel
            showInfoHeader
        />
    );
};

export const MeldungFreigebenAntrag = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_FREIGEBEN
);
