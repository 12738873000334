import { control } from 'forms/ui-schemas/elements/control';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';

import { basisdaten_table } from './zusammenfassung/basisdaten_table';
import { beschaeftigte_table } from './zusammenfassung/beschaeftigte_table';
import { konzeptinformation_dokumente_table } from './zusammenfassung/konzeptinformation_dokumente_table';
import { konzeptinformationen_table } from './zusammenfassung/konzeptinformation_table';
import { leistungserbringer_table } from './zusammenfassung/leistungserbringer_table';
import { standort_list_table } from './zusammenfassung/standort_list_table';
import { standorte_table } from './zusammenfassung/standorte_table';
import { unterlagen_table } from './zusammenfassung/unterlagen_table';
import { vertraege_table } from './zusammenfassung/vertraege_table';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    spacing: 6,
    elements: [
        basisdaten_table,
        konzeptinformationen_table,
        konzeptinformation_dokumente_table,
        standorte_table,
        standort_list_table,
        beschaeftigte_table,
        leistungserbringer_table,
        unterlagen_table,
        vertraege_table,
        control('notizZusammenfassung'),
        group([
            info({
                message:
                    'Bei Abschluss der Prüfung wird eine Bestätigungs-E-Mail mit folgenden Dateianhängen an den Antragsteller versendet:',
            }),
            control('freigabeDokumente', { parseValueToInt: true, noLabel: true }),
        ]),
    ],
};
