import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { ResendVerificationEmail } from 'components/ResendVerificationEmail';
import { FormButton } from 'forms/components/FormButton';
import { PathBuilder } from 'navigation/Paths';

export const FailureMessage: React.FC<{ data: { email: string } }> = ({ data }) => {
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h1">Fehler</Typography>

            <Grid container>
                <Grid item xs={8}>
                    <Typography paragraph>Der von Ihnen genutzte Link ist fehlerhaft oder abgelaufen.</Typography>
                    <Typography paragraph>
                        Um Ihre E-Mail-Adresse zu bestätigen fordern Sie einen neuen Link an.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction={'row-reverse'}>
                <Grid item>
                    <FormButton
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(PathBuilder.home.path)}
                    >
                        Zum Login
                    </FormButton>
                </Grid>

                <Grid item>
                    <ResendVerificationEmail email={data.email} />
                </Grid>
            </Grid>
        </>
    );
};
