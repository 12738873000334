import React from 'react';
import { TableCell } from '@mui/material';

import { TableCellValue } from 'forms/controls/SummaryTable/FieldRow';
import { useDataFromScopes } from 'forms/hooks/useDataFromScopes';
import { Schema, TableColumn } from 'forms/types/UiSchemaTypes';
import { isNumeric } from 'forms/utilities/SchemaUtils';

interface FormTableCellProps {
    column: TableColumn;
    schema: Schema;
    rowData: any;
}

export const FormTableCell: React.FC<FormTableCellProps> = ({ column, schema, rowData }) => {
    const { value, colSchema } = useDataFromScopes(schema, rowData, column.scopes ? column.scopes : [column.scope!]);

    return (
        <TableCell style={column.styles}>
            <TableCellValue
                data={value}
                schema={colSchema! as Schema}
                column={column}
                textAlign={column.styles?.textAlign || (isNumeric(colSchema! as Schema) ? 'right' : 'left')}
            />
        </TableCell>
    );
};
