import { PartialRecord } from 'api/types';
import { AntragType } from 'constants/antragTypes';
import { ROLES } from 'constants/roles';

import { defaultTextValues, DictionaryAntragTypes, DictionaryRoleTypes, UseRoleText } from './textTypes';

export type TextRoleType = Record<DictionaryAntragTypes, PartialRecord<DictionaryRoleTypes, UseRoleText>>;

export const textDictionary: TextRoleType = {
    [AntragType.LEISTUNGSANGEBOT]: {
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
            documentTitle: 'Leistungsangebot registrieren',
            headline: 'Registrierung: Ihr Leistungsangebot',
            submitText: 'Jetzt registrieren',
            submitSuccessText: 'Das Leistungsangebot wurde erfolgreich registriert',
            saveSuccessText: 'Gespeichert',
            errorPublish: 'Das Leistungsangebot konnte nicht registriert werden.',
            errorSubmit: 'Für dieses Leistungsangebot wurden nicht alle erforderlichen Daten eingetragen.',
            backButton: 'Zurück',
            overview: {
                description:
                    'Sie haben hier die Möglichkeit, unmittelbar aus der Übersicht heraus bereits erstellte Meldungen einzusehen, eine angebotsbezogene Meldung zu erstellen oder Änderungen zu einem Leistungsangebot im Rahmen einer Änderungsmeldung anzuzeigen. Darüber hinaus können Sie den angebotsbezogenen Nachrichtenaustausch mit Ihrer WTG-Behörde nutzen. Möchten Sie die Basisdaten des Leistungsanbieters ändern, wenden Sie sich bitte an Ihre zuständige WTG-Behörde. Möchten Sie ein weiteres Leistungsangebot erstellen, gehen Sie hierfür bitte auf „Neues Leistungsangebot“.',
                subHeadline: 'Entwürfe',
                subDescription: 'Hier sehen Sie alle Leistungsangebote, die noch nicht registriert wurden.',
            },
            hint: {
                alertText: 'Leistungsangebot jetzt einreichen?',
            },
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            documentTitle: 'Leistungsangebot freigeben',
            headline: 'Leistungsangebot: Freigeben',
            submitText: 'Angebot freigeben',
            submitSuccessText: 'Das Leistungsangebot wurde erfolgreich freigegeben',
            saveSuccessText: 'Gespeichert',
            errorSubmit:
                'Die Angaben zum Leistungsangebot sind nicht vollständig. Bitte vervollständigen Sie die Angaben oder löschen Sie das Leistungsangebot und benachrichtigen Sie den Leistungsanbieter, sodass dieser das Leistungsangebot erneut registrieren kann',
            errorPublish: 'Das Leistungsangebot konnte nicht freigegeben werden.',
            backButton: 'Bearbeiten',
            overview: {
                subHeadline: 'Eingereichte Leistungsangebote',
                subDescription: 'Hier sehen Sie alle Leistungsangebote, die noch nicht freigegeben wurden.',
            },
            hint: {
                alertText: 'Mit der Freigabe fortfahren?',
            },
        },
        [ROLES.SUPPORT]: {
            ...defaultTextValues,
            documentTitle: 'Leistungsangebot freigeben',
            headline: 'Leistungsangebot: Freigeben',
            submitText: 'Angebot freigeben',
            submitSuccessText: 'Das Leistungsangebot wurde erfolgreich freigegeben',
            saveSuccessText: 'Gespeichert',
            errorPublish: 'Das Leistungsangebot konnte nicht freigegeben werden.',
            backButton: 'Bearbeiten',
            overview: {
                subHeadline: 'Eingereichte Leistungsangebote',
                subDescription: 'Hier sehen Sie alle Leistungsangebote, die noch nicht freigegeben wurden.',
            },
            hint: {
                alertText: 'Mit der Freigabe fortfahren?',
            },
        },
    },
    [AntragType.LEISTUNGSANGEBOT_BEARBEITEN]: {
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            documentTitle: 'Leistungsangebot bearbeiten',
            headline: 'Leistungsangebot: Bearbeiten',
            submitSuccessText: 'Das Leistungsangebot wurde aktualisiert.',
            saveSuccessText: 'Gespeichert',
            errorPublish: 'Das Leistungsangebot konnte nicht gespeichert werden.',
            backButton: 'Bearbeiten',
            hint: {
                alertText: 'Die Bearbeitung abschließen?',
            },
        },
        [ROLES.LV]: {
            ...defaultTextValues,
            documentTitle: 'Leistungsangebot bearbeiten',
            headline: 'Leistungsangebot: Bearbeiten',
            submitSuccessText: 'Das Leistungsangebot wurde aktualisiert.',
            saveSuccessText: 'Gespeichert',
            errorPublish: 'Das Leistungsangebot konnte nicht gespeichert werden.',
            backButton: 'Bearbeiten',
            hint: {
                alertText: 'Die Bearbeitung abschließen?',
            },
        },
        [ROLES.SUPPORT]: {
            ...defaultTextValues,
            documentTitle: 'Leistungsangebot bearbeiten',
            headline: 'Leistungsangebot: Bearbeiten',
            submitSuccessText: 'Das Leistungsangebot wurde aktualisiert.',
            saveSuccessText: 'Gespeichert',
            errorPublish: 'Das Leistungsangebot konnte nicht gespeichert werden.',
            backButton: 'Bearbeiten',
            hint: {
                alertText: 'Die Bearbeitung abschließen?',
            },
        },
    },
    [AntragType.MELDUNG]: {
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
            documentTitle: 'Meldungen einreichen',
            submitText: 'Einreichen',
            submitSuccessText: 'Meldung wurde eingereicht.',
            errorPublish: 'Meldung konnte nicht eingereicht werden.',
            hint: {
                alertText: 'Möchten Sie diese Meldung wirklich einreichen?',
            },
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            documentTitle: 'Meldungen freigeben',
            submitText: 'Freigeben',
            submitSuccessText: 'Meldung wurde freigegeben.',
            errorPublish: 'Meldung konnte nicht freigegeben werden.',
            errorSubmit:
                'Die Angaben zur Meldung sind nicht vollständig. Bitte geben Sie die Meldung zurück, damit die Angaben vervollständigt oder korrigiert werden können.',
            hint: {
                zurueckweisen: 'Möchten Sie diese Meldung wirklich zurückgeben?',
                alertText: 'Möchten Sie diese Meldung wirklich freigeben?',
            },
        },
    },
    [AntragType.PLATZZAHLMELDER]: {
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
            submitText: 'Einreichen',
            submitSuccessText: 'Die verfügbare Platzzahl wurde gemeldet',
            errorPublish: 'Beim Senden der Platzzahl ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Sollen die erfassten freien Plätze jetzt gemeldet werden?',
            },
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            submitText: 'Aufforderung senden',
            submitSuccessText: 'Die Aufforderung zur Meldung wurde verschickt.',
            errorPublish: 'Beim Senden der Aufforderung ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Aufforderung versenden?',
            },
        },
    },
    [AntragType.FEM]: {
        [ROLES.SUPPORT]: {
            ...defaultTextValues,
            submitSuccessText: 'Die Meldung wurde erfolgreich eingereicht.',
            errorPublish: 'Beim Senden der Meldung ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Meldung zu freiheitsentziehenden Maßnahmen einreichen?',
            },
        },
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
            submitSuccessText: 'Die Meldung wurde erfolgreich eingereicht.',
            errorPublish: 'Beim Senden der Meldung ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Meldung zu freiheitsentziehenden Maßnahmen einreichen?',
            },
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            submitText: 'Aufforderung senden',
            submitSuccessText: 'Die Aufforderung zur Meldung wurde verschickt.',
            errorPublish: 'Beim Senden der Aufforderung ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Aufforderung versenden?',
            },
        },
    },
    [AntragType.REGELPRUEFUNG]: {
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            submitText: 'Regelprüfung speichern',
            submitSuccessText: 'Die Regelprüfung wurde gespeichert.',
            errorPublish: 'Beim Einreichen der Regelprüfung ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Regelprüfung speichern?',
            },
        },
        [ROLES.SUPPORT]: {
            ...defaultTextValues,
            submitText: 'Regelprüfung speichern',
            submitSuccessText: 'Die Regelprüfung wurde gespeichert.',
            errorPublish: 'Beim Einreichen der Regelprüfung ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Regelprüfung speichern?',
            },
        },
    },
    [AntragType.LEISTUNGSANBIETER]: {
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            submitText: 'Jetzt freigeben',
            submitSuccessText: 'Der Leistungsanbieter wurde freigegeben',
            errorPublish: 'Beim Freigeben des Leistungsanbieters ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Leistungsanbieter jetzt freigeben?',
            },
        },
        [ROLES.SUPPORT]: {
            ...defaultTextValues,
            submitText: 'Jetzt freigeben',
            submitSuccessText: 'Der Leistungsanbieter wurde freigegeben',
            errorPublish: 'Beim Freigeben des Leistungsanbieters ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Leistungsanbieter jetzt freigeben?',
            },
        },
        [ROLES.LV]: {
            ...defaultTextValues,
            submitText: 'Jetzt freigeben',
            submitSuccessText: 'Der Leistungsanbieter wurde freigegeben',
            errorPublish: 'Beim Freigeben des Leistungsanbieters ist ein Fehler aufgetreten.',
            hint: {
                alertText: 'Leistungsanbieter jetzt freigeben?',
            },
        },
    },
    [AntragType.EMAIL_ANTRAG]: {
        [ROLES.ANBIETER]: {
            ...defaultTextValues,
            submitSuccessText: 'Nachricht erfolgreich versendet',
            errorPublish: 'Beim Versenden ist ein Fehler aufgetreten',
            hint: {
                alertText: 'Nachricht jetzt senden?',
            },
        },
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
            submitSuccessText: 'Nachricht erfolgreich versendet',
            errorPublish: 'Beim Versenden ist ein Fehler aufgetreten',
            hint: {
                alertText: 'Nachricht jetzt senden?',
            },
        },
    },
    [AntragType.ZURUECKGEBEN]: {
        [ROLES.BEHOERDE]: {
            ...defaultTextValues,
        },
    },
};
