import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { ApiRegelpruefungAntragGetCollection200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useRegelpruefungAntragListData = (
    einrichtungId?: string
): GetData<ApiRegelpruefungAntragGetCollection200Response> => {
    return useCallback(
        ({ page, rowsPerPage, sortOrder }) => {
            return backendApiService.getRegelpruefungAntragCollection({
                page,
                itemsPerPage: rowsPerPage,
                einrichtung: einrichtungId,
                orderPruefDatum: sortBy(sortOrder, 'eingereichtAt'),
                orderLetzterUploadAt: sortBy(sortOrder, 'letzterBericht'),
            });
        },
        [einrichtungId]
    );
};
