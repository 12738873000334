import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { MassenmailCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useMassenmailListData = (): GetData<MassenmailCollectionResponse> => {
    return useCallback(({ page, rowsPerPage }) => {
        return backendApiService.getMassenmailCollection({ page, itemsPerPage: rowsPerPage || 9999 });
    }, []);
};
