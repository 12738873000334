import { useLocation, useNavigate } from 'react-router-dom';

import { ComponentError } from 'forms/hooks/useErrors';

export const useErrorNavigate = (path: string, errors: Array<ComponentError> = []): (() => void) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return () => {
        const base = pathname.substring(0, pathname.lastIndexOf('/'));
        const target = path.substring(0, path.indexOf('.'));
        navigate(`${base}/${target}`, { state: { errors } });
    };
};
