import { AnredeType } from 'api/types';
import { PHONE_NUMBER_REGEX } from 'forms/hooks/useFormValidation';
import { Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const schema: Schema = {
    required: ['anrede', 'vorname', 'nachname', 'telefon', 'email', 'plainPassword'],
    properties: {
        anrede: {
            type: 'string',
            title: 'Anrede',
            oneOf: [
                {
                    const: AnredeType.DIVERS,
                    title: 'Divers',
                },
                {
                    const: AnredeType.MALE,
                    title: 'Herr',
                },
                {
                    const: AnredeType.FEMALE,
                    title: 'Frau',
                },
            ],
        },
        title: {
            type: 'string',
            title: 'Titel',
        },
        vorname: {
            type: 'string',
            title: 'Vorname',
        },
        nachname: {
            type: 'string',
            title: 'Nachname',
        },
        telefon: {
            type: 'string',
            title: 'Telefon',
            pattern: PHONE_NUMBER_REGEX,
        },
        fax: {
            type: 'string',
            title: 'Fax',
            pattern: PHONE_NUMBER_REGEX,
        },
        email: {
            type: 'string',
            title: 'E-Mail-Adresse',
            // @ts-ignore
            custom: {
                block_prefixes: ['email_change_widget'],
            },
        },
        plainPassword: {
            type: 'string',
            title: 'Passwort',
            // @ts-ignore
            custom: {
                block_prefixes: ['password_widget'],
            },
        },
    },
};

export const uiSchema = customVerticalLayout([
    group(
        [
            inlineRowControl([control('anrede'), control('title')]),
            inlineRowControl([control('vorname'), control('nachname')]),
            inlineRowControl([control('telefon'), control('fax')]),
        ],
        {},
        'Allgemeine Daten'
    ),
    group(
        [
            info({ message: 'Ihre E-Mail-Adresse ist gleichzeitig ihr Benutzername' }),
            group([control('email')], { noSeparator: true }),
            group([control('plainPassword')], { noSeparator: true }),
        ],
        {},
        'Anmeldedaten'
    ),
]);
