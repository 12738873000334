import { RankedTester, rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

export const isRadioAuswahl: Tester = (uischema: UISchemaElement | any, schema: JsonSchema | any): boolean => {
    if (uischema?.type !== 'Control') return false;
    const field = getFieldSchemaByScope(uischema.scope, schema);

    return (
        (field as any)?.custom?.block_prefixes?.includes('choice') &&
        (field as any)?.custom?.block_prefixes?.includes('radios')
    );
};

export const RadioAuswahlTester: RankedTester = rankWith(5500, isRadioAuswahl);
