import { AuthState } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';

type UsePageTimeoutResult = Pick<AuthState, 'pageTimeout' | 'resetPageTimeout'>;

/**
 * Hook to isolate page timeout logic.
 */
export const usePageTimeout = (): UsePageTimeoutResult => {
    return useAuthState(({ pageTimeout, resetPageTimeout }) => ({
        pageTimeout,
        resetPageTimeout,
    }));
};
