import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RuleEffect } from '@jsonforms/core';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { InvestRegistrationFormData } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { useServerError } from 'forms/hooks/useServerError';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const formData: InvestRegistrationFormData = {
    pflegewohngeld: undefined,
    meldung_p29: undefined,
    email: undefined,
    firstname: undefined,
    lastname: undefined,
    mandant: undefined,
    einrichtung: undefined,
};

const uiSchema = customVerticalLayout([
    group([
        control('pflegewohngeld', { options: { submitOnChange: false } }),
        control('meldung_p29', {
            gridLayout: { label: 12 },
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/pflegewohngeld',
                    schema: { const: false },
                },
            },
            options: { submitOnChange: false },
        }),
    ]),
    group(
        [
            info({
                message:
                    'Bitte geben Sie hier die E-Mail-Adresse derjenigen Person in Ihrer Einrichtung ein, die für die Investitionskostenförderung oder -berechnung zuständig ist. Sollte diese Person bereits einen Zugang zu PfAD.invest besitzen, so können Sie die E-Mail-Adresse hier trotzdem angeben, sodass die Einrichtung diesem Benutzer zugewiesen wird. Nach Registrierung in PfAD.invest besteht die Möglichkeit weitere Benutzer für das Leistungsangebot anzulegen.',
            }),
            inlineRowControl([control('firstname'), control('lastname')], {
                title: 'Angaben zum Benutzerkonto',
            }),
            control('email'),
            control('mandant'),
        ],
        {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    type: 'OR',
                    conditions: [
                        {
                            scope: '#/properties/pflegewohngeld',
                            schema: { const: true },
                        },
                        {
                            scope: '#/properties/meldung_p29',
                            schema: { const: true },
                        },
                    ],
                },
            },
        }
    ),
]);

const Index = () => {
    const { einrichtungId } = useParams();
    const navigate = useNavigate();

    const { setRequestMessage, submitEnd } = useFormState();
    const [currentFormData, setCurrentFormData] = useState<InvestRegistrationFormData>(formData);

    const serverError = useServerError();

    const { data, isLoading: isLoadingEinrichtung, error } = useApiEinrichtungGet(einrichtungId);

    const { schema } = useSchemaFromJsonLdContext('InvestRegistration', currentFormData);

    const submitDisabled = useMemo(
        () => currentFormData.pflegewohngeld !== true && currentFormData.meldung_p29 !== true,
        [currentFormData]
    );

    const handleSubmit = (updatedFormData: InvestRegistrationFormData) => {
        backendApiService
            .postInvestSignUp(String(einrichtungId), updatedFormData)
            .then(() => {
                setRequestMessage(
                    successMessage('Das Leistungsangebot wurde erfolgreich zur Teilnahme an PfAD.invest angemeldet')
                );
                navigate(PathBuilder.home.verwaltung.leistungsangebote.path, { replace: true });
            })
            .catch((error) => {
                if (error?.response?.status === 498) {
                    setRequestMessage(errorMessage(serverError(error, error.message)));
                    return;
                }

                setRequestMessage(
                    errorMessage(
                        serverError(
                            error,
                            error.message || 'Bei der Anmeldung zur Teilnahme an PfAD.invest ist ein Fehler aufgetreten'
                        )
                    )
                );
            })
            .finally(submitEnd);
    };

    const handleChange = (formData: InvestRegistrationFormData) => {
        setCurrentFormData(formData);
    };

    return (
        <ContentContainer>
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoadingEinrichtung}>
                    {!error ? (
                        <>
                            {data ? <PageHeading title={`${data.name}: Teilnahme an PfAD.invest`} /> : null}

                            <SimpleForm
                                data={currentFormData}
                                schema={schema}
                                uiSchema={uiSchema}
                                submitLabel="Daten an PfAD.invest übermitteln"
                                submitDisabled={submitDisabled}
                                onSubmit={handleSubmit}
                                onChange={handleChange}
                                backLink={{ path: PathBuilder.home.verwaltung.leistungsangebote.path, title: 'Zurück' }}
                            />
                        </>
                    ) : (
                        <Alert severity="error">
                            Beim Laden der Einrichtung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem
                            späteren Zeitpunkt erneut.
                        </Alert>
                    )}
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const FreigegebeneInvestSignUp = withAuthorization(withSideBar(Index, menuVerwaltung), []);
