import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Button, Drawer, Hidden, List, styled } from '@mui/material';

import { useHasRole } from 'api/auth';
import { ROLES } from 'constants/roles';
import { Logo, LogoLink } from 'layout/components/Logo';
import { PathBuilder } from 'navigation/Paths';

import { LogoWrapper } from './container/LogoWrapper';
import { Toolbar } from './container/Toolbar';
import { HeaderConfig } from './HeaderConfig';
import { NavigationMenuItem } from './menu/NavigationMenuItem';

const StyledDrawer = styled(Drawer)(
    ({ theme }) => `
        width: ${theme.mixins.sidebar.width + 50}px;
        max-width: ${theme.mixins.sidebar.width + 50}px;
        padding-right: 0px;
        z-index: 1013;
        & #LogoWrapper {
            width: ${theme.mixins.sidebar.width + 50}px;
        }

        & .MuiButtonBase-root {
            max-width: ${theme.mixins.sidebar.width + 50}px;
            text-decoration: none;

            &:hover {
                background-color: ${theme.palette.primary.light};
            }

            &:focus {
                background: ${theme.palette.primary.light};
            }
        }
`
);

export const StyledNavList = styled(List)(
    ({ theme }) => `
    color: ${theme.palette.common.black};
    height: 100%;
    background: ${theme.palette.primary.lighter};
    overflow: auto;
    padding-top: 0px;

`
) as typeof List;

interface MainNavigationDrawerType {
    open: boolean;
    header: React.ReactElement;
    onClose: () => void;
}

export const MainNavigationDrawer: React.FC<MainNavigationDrawerType> = ({ open, onClose }) => {
    const hasRole = useHasRole();

    return (
        <StyledDrawer open={open} onClose={onClose}>
            <Toolbar disableGutters>
                <LogoWrapper id="LogoWrapper">
                    <LogoLink to={PathBuilder.home.path} underline="none">
                        <Logo />
                    </LogoLink>
                    <Hidden lgUp>
                        <Button onClick={onClose} aria-label="Zurück">
                            <ArrowBack />
                        </Button>
                    </Hidden>
                </LogoWrapper>
            </Toolbar>
            <StyledNavList component="nav" role="navigation" aria-label="Hauptnavigation">
                {(hasRole(ROLES.PASSWORD_RESET) ? [] : HeaderConfig.areas)
                    .filter((area) => hasRole(area.roles))
                    .map((area, i) => {
                        return <NavigationMenuItem key={i} {...area} menus={area.menu} divide />;
                    })}
            </StyledNavList>
        </StyledDrawer>
    );
};
