import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { withAuthorization } from 'navigation/withAuthorization';

import { LeistungsanbieterDetail } from './LeistungsanbieterDetail';

const Index = () => {
    return <LeistungsanbieterDetail />;
};

export const LeistungsanbieterDetailPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_LEISTUNGSANBIETER_FREIGEBEN
);
