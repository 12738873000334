import { useMemo } from 'react';

import { useHasRole } from 'api/auth';
import { AntragType } from 'constants/antragTypes';

import { textDictionary, TextRoleType } from './textConfigs/texts';
import { defaultTextValues, DictionaryRoleTypes, TextDictionaryOutput, UseRoleText } from './textConfigs/textTypes';

export const useRoleText = (type: AntragType): TextDictionaryOutput => {
    const hasRole = useHasRole();

    return useMemo((): UseRoleText => {
        const dictionary = textDictionary[type as keyof TextRoleType];
        const requiredRole: Partial<DictionaryRoleTypes> | undefined = Object.keys(dictionary).find((role) =>
            hasRole(role)
        ) as DictionaryRoleTypes;

        if (requiredRole) {
            return dictionary[requiredRole] as UseRoleText;
        }

        return defaultTextValues;
    }, [hasRole, type]);
};
