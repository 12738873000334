import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useLogout } from 'api/auth';
import { Dialog } from 'components/Dialog/Dialog';
import { SupportInfo } from 'elements/SupportInfo';
import { FormButton } from 'forms/components/FormButton';
import { PathBuilder } from 'navigation/Paths';

interface PasswordChangedDialogProps {
    open: boolean;
}

export const PasswordChangedDialog: React.FC<PasswordChangedDialogProps> = ({ open }) => {
    const logout = useLogout();
    const navigate = useNavigate();

    const handleClick = async (): Promise<void> => {
        await logout();
        navigate(PathBuilder.home.path);
    };

    return (
        <Dialog
            open={open}
            title={'Passwort wurde geändert'}
            wrapActions
            actions={
                <>
                    <Grid container spacing={1} direction={'row-reverse'}>
                        <Grid item>
                            <FormButton
                                variant="outlined"
                                color="primary"
                                data-cy={'FormDialog-closeeButton'}
                                onClick={handleClick}
                            >
                                Zum Login
                            </FormButton>
                        </Grid>
                    </Grid>
                    <SupportInfo />
                </>
            }
        >
            Ihr Passwort wurde erfolgreich aktualisiert. Bitte melden Sie sich mit Ihrem neuen Passwort an.
        </Dialog>
    );
};
