import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { MassenmailFormData } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { uiSchema } from './config';

const formData: MassenmailFormData = {
    empfaengerGruppe: undefined,
    betreff: undefined,
    inhalt: undefined,
    einrichtungsart: [],
    uploads: [],
};

const Index = () => {
    const navigate = useNavigate();

    const overviewPath = PathBuilder.home.verwaltung.massenmail.path;

    const { submitStart, submitEnd } = useFormState();
    const { schema } = useSchemaFromJsonLdContext('Massenmail', formData);
    const handleSubmit = (formData: MassenmailFormData) => {
        submitStart(true);

        return backendApiService.postMassenmailCreate(formData).catch(() => {
            submitEnd();
            return Promise.reject();
        });
    };

    const handleOnAfterSubmit = () => {
        navigate(overviewPath);
    };

    return (
        <ContentContainer title="Neue Massenmail erstellen">
            <StyledContainer>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} lg={10}>
                        <Typography variant="h1">Neue Massenmail</Typography>

                        <SimpleForm
                            isLoading={!formData || !schema}
                            data={formData}
                            schema={schema}
                            uiSchema={uiSchema}
                            messages={{
                                success: 'Massenmails wurden erstellt.',
                                error: 'Erstellen der Massenmails fehlgeschlagen.',
                            }}
                            onSubmit={handleSubmit}
                            onAfterSubmit={handleOnAfterSubmit}
                            submitLabel="Senden"
                            backLink={{
                                path: overviewPath,
                                title: 'Zur Übersicht',
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MassenmailCreate = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_MASSENMAIL);
