import { ColumnScoped, TableColumn } from 'forms/types/UiSchemaTypes';

interface ColumnOptions {
    nullValue?: string;
    unit?: string;
    format?: string;
    valueLabel?: string;
    highlighted?: boolean;
}

export const tableColumn = (label: string, scoped: ColumnScoped, options?: ColumnOptions): TableColumn => {
    return {
        label,
        ...scoped,
        styles: { textAlign: 'left' },
        ...options,
    };
};

export const tableColumnDate = (label: string, scoped: ColumnScoped, options?: ColumnOptions): TableColumn => {
    return {
        ...tableColumn(label, scoped, options),
        styles: { textAlign: 'right' },
    };
};

export const tableColumnDecimal = (label: string, scoped: ColumnScoped, options?: ColumnOptions): TableColumn => {
    return {
        ...tableColumn(label, scoped, options),
        styles: { textAlign: 'right' },
    };
};
