import * as React from 'react';
import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { EinrichtungCollectionJsonldItem } from 'api/types';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const MeldungEinrichtungAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    handleChange,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
}) => {
    const handleValueChange = useCallback(
        (value: SelectOption | null) => {
            handleChange(path, value, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, options: AxiosRequestConfig): Promise<any> => {
        return backendApiService.getEinrichtungenForMeldung({
            page: 1,
            itemsPerPage: 10,
            qSearch: value,
            options: {
                signal: options.signal,
            },
        });
    };

    const getOption = (item: EinrichtungCollectionJsonldItem): SelectOption => {
        return {
            id: item.id as number,
            name: item.name,
        };
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <Typeahead
                id={id}
                data={data}
                schema={schema}
                uischema={uischema}
                path={path}
                getData={getData}
                getOption={getOption}
                onChange={handleValueChange}
                multiple={false}
                loadInitialOptions
                noOptionsText="Keine Einrichtungen gefunden. Geben Sie mindestens 3 Zeichen ein."
            />
        </RowControl>
    );
};

export const MeldungEinrichtungAutocompleteControl = withCustomControlProps(
    MeldungEinrichtungAutocompleteControlComponent
);
