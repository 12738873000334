import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group(
        [
            inlineRowControl([
                inlineRowControl(
                    [
                        control('dauerpflegeplaetze', {
                            endAdornment: 'Plätze',
                        }),
                    ],
                    { title: 'Belegbare Dauerpflegeplätze' }
                ),
                inlineRowControl(
                    [
                        control('kurzpflegeplaetze', {
                            endAdornment: 'Plätze',
                        }),
                    ],
                    { title: 'Belegbare Kurzzeitpflegeplätze' }
                ),
            ]),
            control('beschreibung'),
        ],
        { noSeparator: true }
    ),
]);

export const uiSchemaTagespflege = customVerticalLayout([
    group(
        [
            inlineRowControl([
                inlineRowControl([control('montag', { endAdornment: 'Plätze' })], { title: 'Montag' }),
                inlineRowControl([control('dienstag', { endAdornment: 'Plätze' })], { title: 'Dienstag' }),
                inlineRowControl([control('mittwoch', { endAdornment: 'Plätze' })], { title: 'Mittwoch' }),
                inlineRowControl([control('donnerstag', { endAdornment: 'Plätze' })], { title: 'Donnerstag' }),
                inlineRowControl([control('freitag', { endAdornment: 'Plätze' })], { title: 'Freitag' }),
            ]),
            control('beschreibung'),
        ],
        { noSeparator: true }
    ),
]);
