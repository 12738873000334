import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Done } from '@mui/icons-material';
import { Alert, styled, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthUser } from 'api/auth';
import { useHasRole } from 'api/auth/useHasRole';
import { useApiTraegerItem } from 'api/hooks/useApiClient';
import { AnschriftType, TraegerResponse } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ACTIONS } from 'constants/antragActions';
import { TRAEGER_ART_LABELS } from 'constants/labels';
import { ROLE_TYPES } from 'constants/roles';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useServerError } from 'forms/hooks/useServerError';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';
import { BenutzerTable } from 'pages/Benutzer/component/BenutzerTable';

interface TraegerDetailProps {
    pathBuilder?: PathBuilderProps;
    showBenutzerTable?: boolean;
    title?: string;
}

export const TraegerDetail = ({ pathBuilder, showBenutzerTable = true, title }: TraegerDetailProps) => {
    const { id } = useParams();

    const hasRole = useHasRole();
    const allowAccess = hasRole(ROLE_TYPES.ROLLEN_TRAEGER);

    const benutzer = useAuthUser();
    const { setRequestMessage, submitStart, submitEnd, isPersisting } = useFormState();

    const collectionError = useServerError();

    const currentId = useMemo(() => {
        if (benutzer?.traegerId) {
            return benutzer.traegerId;
        }

        return id;
    }, [id, benutzer]);

    const { isLoading, data, error } = useApiTraegerItem(String(currentId));

    const [traegerData, setTraegerData] = useState<TraegerResponse | undefined>(undefined);

    const handleDeleteConfirm = () => {
        submitStart(true);

        backendApiService
            .workflowTraeger(Number(id), ACTIONS.CONFIRM_DELETE)
            .then((response) => {
                setTraegerData(response as TraegerResponse);
                setRequestMessage(successMessage('Löschung erfolgreich zur Kenntnis genommen.'));
            })
            .catch((error) => setRequestMessage(errorMessage(collectionError(error, 'Fehler bei der Kenntnisnahme.'))))
            .finally(() => submitEnd);
    };

    useEffect(() => {
        if (data && !traegerData) {
            setTraegerData(data);
        }
    }, [data, traegerData, setTraegerData]);

    return (
        <ContentContainer title={`Träger: ${traegerData?.name || ''}`}>
            <StyledContainer>
                <Typography variant="h1">{title || 'Leistungsanbieter: Ansehen'}</Typography>

                <BaseGridContainer lg={10} justifyContent="center">
                    <AppLoaderContainer isLoading={isLoading || (!traegerData && !error)}>
                        {!error ? (
                            <>
                                {traegerData?.deleted && traegerData?.deleteConfirmed ? (
                                    <Alert severity="info">Löschung wurde zur Kenntnis genommen</Alert>
                                ) : null}

                                <DetailsLayout.Container divide={false}>
                                    <DetailsLayout.InfoField value={traegerData?.nrwKey} label="NRW-Schlüssel" />
                                    <DetailsLayout.InfoField
                                        value={traegerData?.name}
                                        label="Name des Leistungsanbieters"
                                    />

                                    <DetailsLayout.InfoField
                                        value={
                                            traegerData?.adresse?.anschriftTyp === AnschriftType.HAUSANSCHRIFT
                                                ? traegerData?.adresse?.label
                                                : `${traegerData?.adresse?.postfachBezeichnung}, ${traegerData?.adresse?.plz} ${traegerData?.adresse?.ort}`
                                        }
                                        label={
                                            traegerData?.adresse?.anschriftTyp === AnschriftType.HAUSANSCHRIFT
                                                ? 'Hausanschrift'
                                                : 'Postfach'
                                        }
                                    />
                                    <DetailsLayout.InfoField
                                        value={traegerData?.adresse?.name}
                                        label="Ansprechpartner"
                                    />
                                    <DetailsLayout.InfoField value={traegerData?.adresse?.telefon} label="Telefon" />
                                    <DetailsLayout.InfoField value={traegerData?.adresse?.fax} label="Fax" />
                                    <DetailsLayout.InfoField
                                        value={traegerData?.adresse?.email}
                                        label="E-Mail-Adresse"
                                    />
                                    <DetailsLayout.InfoField
                                        value={TRAEGER_ART_LABELS[traegerData?.type || ''] || '-'}
                                        label="Art"
                                    />
                                    <DetailsLayout.InfoField
                                        value={traegerData?.traegerverband?.name || '-'}
                                        label="Verband"
                                    />
                                </DetailsLayout.Container>
                            </>
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </AppLoaderContainer>
                </BaseGridContainer>

                {traegerData?.deleted ? (
                    <>
                        <StyledDivider />
                        <BaseGridContainer lg={10} justifyContent="center">
                            <StyledSubHeadline variant="h2">Löschung</StyledSubHeadline>

                            <DetailsLayout.Container divide={false}>
                                <DetailsLayout.InfoField
                                    value={traegerData.deleted}
                                    label="Zeitpunkt der Löschung"
                                    isDate
                                />
                                <DetailsLayout.InfoField
                                    value={traegerData.deleteConfirmed}
                                    label="Zeitpunkt der Kenntnisnahme"
                                    isDate
                                />
                                {traegerData?.deletedByBenutzer ? (
                                    <DetailsLayout.InfoField
                                        value={`${traegerData?.deletedByBenutzer?.nachname}, ${traegerData?.deletedByBenutzer?.vorname} (${traegerData?.deletedByBenutzer?.email})`}
                                        label="Gelöscht von"
                                    />
                                ) : null}
                            </DetailsLayout.Container>
                        </BaseGridContainer>
                    </>
                ) : null}

                <StyledDivider />

                {allowAccess ? (
                    <BaseGridContainer>
                        <BenutzerTable
                            title="Zugeordnete Benutzer"
                            titleVariant="h2"
                            getDataPromise={backendApiService.getBenutzerListCollection.bind(backendApiService)}
                            pathBuilder={PathBuilder.home.verwaltung.benutzer}
                            tableOptions={{
                                filter: true,
                                custom: {
                                    pathBuilder: PathBuilder.home.verwaltung.benutzer,
                                },
                                disableActions: true,
                            }}
                            dataOptions={{
                                traeger: currentId,
                            }}
                            showBenutzerTable={showBenutzerTable}
                        />
                    </BaseGridContainer>
                ) : null}

                {pathBuilder ? (
                    <ButtonGroupGrid backLink={{ path: pathBuilder.path || '' }}>
                        {traegerData?.deleted && !traegerData?.deleteConfirmed ? (
                            <FormLoadingButton
                                variant="contained"
                                color="success"
                                loading={isPersisting}
                                startIcon={!isPersisting ? <Done /> : null}
                                onClick={handleDeleteConfirm}
                            >
                                Löschung zur Kenntnis nehmen
                            </FormLoadingButton>
                        ) : null}
                    </ButtonGroupGrid>
                ) : null}
            </StyledContainer>
        </ContentContainer>
    );
};

const StyledSubHeadline = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));
