import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useBehoerdeDtoItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { BenutzerTable } from 'pages/Benutzer/component/BenutzerTable';

const Index = () => {
    const { id } = useParams();

    const { isLoading, data, error } = useBehoerdeDtoItem(String(id));

    return (
        <ContentContainer title={`Kreis / kreisfreie Stadt: ${data?.name}`}>
            <StyledContainer>
                <PageHeading title={`Kreis / kreisfreie Stadt: ${data?.name}`} />

                <BaseGridContainer lg={10} justifyContent="center">
                    <AppLoaderContainer isLoading={isLoading}>
                        {!error ? (
                            <DetailsLayout.Container divide={false}>
                                <DetailsLayout.InfoField
                                    value={data?.verantwortlicheBehoerde}
                                    label="Zuständige Organisationseinheit"
                                />
                                <DetailsLayout.InfoField value={data?.adressDaten?.label} label="Hausanschrift" />
                                <DetailsLayout.InfoField value={data?.ansprechpartner} label="Ansprechpartner" />
                                <DetailsLayout.InfoField value={data?.telefon} label="Telefon" />
                                <DetailsLayout.InfoField value={data?.fax} label="Telefax" />
                                <DetailsLayout.InfoField value={data?.email} label="E-Mail-Adresse" />
                            </DetailsLayout.Container>
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </AppLoaderContainer>
                </BaseGridContainer>

                <StyledDivider />

                <BaseGridContainer>
                    <BenutzerTable
                        title="Zugeordnete Benutzer"
                        titleVariant="h2"
                        getDataPromise={backendApiService.getBenutzerListCollection.bind(backendApiService)}
                        pathBuilder={PathBuilder.home.verwaltung.benutzer}
                        dataOptions={{ behoerde: id }}
                        tableOptions={{
                            filter: true,
                            custom: {
                                pathBuilder: PathBuilder.home.verwaltung.benutzer,
                            },
                            disableActions: true,
                        }}
                    />
                </BaseGridContainer>

                <ButtonGroupGrid backLink={{ path: PathBuilder.home.verwaltung.behoerden.path }} />
            </StyledContainer>
        </ContentContainer>
    );
};

export const BehoerdenDetailPage = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_BEHOERDEN);
