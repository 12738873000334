import React from 'react';

import { getDate } from 'components/DataTable/tableUtils';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { MeldungProps } from 'pages/Meldung/Details/MeldungDetailPage';

interface Username {
    nachname: string;
    vorname: string;
}

export const AngabenMeldung = ({ meldung }: MeldungProps) => {
    const getUsername = (value?: Username) => {
        if (!value) {
            return undefined;
        }

        return `${value.nachname} ${value.vorname}`;
    };

    return (
        <DetailsLayout.Group divider>
            <DetailsLayout.InfoField label="Art der Meldung" value="Änderungsmeldung" />
            <DetailsLayout.InfoField
                label="Antragsstellung"
                value={`${getDate(meldung.eingereichtAt)} durch ${
                    getUsername(meldung.eingereichtBy as Username) || '-'
                }`}
            />
            <DetailsLayout.InfoField
                label="Änderungsdatum"
                value={`${getDate(meldung.modifiedAt)} durch ${getUsername(meldung.modifiedBy as Username) || '-'}`}
            />
        </DetailsLayout.Group>
    );
};
