import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useApiPlatzzahlmelderItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const Index = () => {
    const { token, id } = useParams();
    const { isLoading, data: antrag } = useApiPlatzzahlmelderItem(Number(id));

    const einrichtungAdresse: string = useMemo(
        () =>
            `${antrag?.einrichtung?.einrichtungStrasse} ${antrag?.einrichtung?.einrichtungHausnummer} <br /> ${antrag?.einrichtung?.einrichtungPlz} ${antrag?.einrichtung?.einrichtungOrt}`,
        [antrag]
    );

    return (
        <ContentContainer title={`Belegbare Plätze des Leistungsangebots: ${antrag?.einrichtung?.name}`}>
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading}>
                    {antrag ? (
                        <>
                            <PageHeading
                                title={`Belegbare Plätze des Leistungsangebots: "${antrag?.einrichtung?.name}"`}
                            />

                            <BaseGridContainer lg={10} justifyContent="center">
                                <DetailsLayout.Container divide={false}>
                                    <DetailsLayout.Group>
                                        <DetailsLayout.InfoField
                                            label="NRW-Schlüssel"
                                            value={antrag?.einrichtung?.nrwKey}
                                        />
                                        <DetailsLayout.InfoField
                                            label="Art der Einrichtung"
                                            value={antrag?.einrichtung?.art}
                                        />
                                        <DetailsLayout.InfoField
                                            label="Adresse der Einrichtung"
                                            value={einrichtungAdresse}
                                        />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group>
                                        <DetailsLayout.InfoField
                                            label="Zuletzt gemeldet am"
                                            value={antrag?.createdAt}
                                            isDate
                                            dateFormat="dd.MM.yyyy HH:mm"
                                        />
                                        <DetailsLayout.InfoField
                                            label="Dauerpflege"
                                            value={antrag?.dauerpflegeplaetze}
                                        />

                                        <DetailsLayout.InfoField
                                            label="Kurzzeitpflege"
                                            value={antrag?.kurzpflegeplaetze}
                                        />

                                        <DetailsLayout.InfoField label="Beschreibung" value={antrag?.beschreibung} />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group
                                        title="Tagespflegeplätze"
                                        variant="h3"
                                        hide={antrag?.montag === undefined}
                                    >
                                        <DetailsLayout.InfoField label="Montag" value={antrag?.montag} />
                                        <DetailsLayout.InfoField label="Dienstag" value={antrag?.dienstag} />
                                        <DetailsLayout.InfoField label="Mittwoch" value={antrag?.mittwoch} />
                                        <DetailsLayout.InfoField label="Donnerstag" value={antrag?.donnerstag} />
                                        <DetailsLayout.InfoField label="Freitag" value={antrag?.freitag} />
                                    </DetailsLayout.Group>
                                </DetailsLayout.Container>
                            </BaseGridContainer>

                            <StyledDivider />

                            <ButtonGroupGrid
                                backLink={{
                                    path: PathBuilder.home.verwaltung.platzmelder.token(token!),
                                    title: 'Zurück',
                                }}
                            />
                        </>
                    ) : null}
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const PlatzmelderDetailPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PLATZMELDER_DETAIL
);
