import { useCallback } from 'react';
import { CallToAction } from '@mui/icons-material';

import { backendApiService } from 'api/ApiService';
import { EinrichtungenFuerPlatzzahlCollection } from 'api/types';
import { STATUS } from 'constants/antragStatus';
import { EMAIL_ANTRAG_ART } from 'constants/emailAntragArt';
import { ROLES } from 'constants/roles';
import { TABLE_ACTIONS } from 'constants/tableActions';
import { useFormState } from 'forms/state/useFormState';
import { CollectionItemAction } from 'forms/types/UiSchemaTypes';
import { errorMessage } from 'forms/utilities/MessageUtils';

interface UsePlatzzahlmelderColumnActionsProps {
    role?: ROLES;
    onCreateAufforderungAntrag?: (id: number | undefined) => void;
}

export const usePlatzzahlmelderColumnActions = ({
    role,
    onCreateAufforderungAntrag,
}: UsePlatzzahlmelderColumnActionsProps) => {
    const { setRequestMessage } = useFormState();

    const getColumnActions = useCallback(
        (id: string | number | null | undefined, antragStatus: STATUS) => {
            const actions: Record<string, Array<CollectionItemAction> | undefined> = {
                [ROLES.BEHOERDE]: [
                    {
                        key: TABLE_ACTIONS.PLATZZAHLMELDER_AUFFORDERUNG,
                        label: 'Zur Meldung auffordern',
                        visible: ![STATUS.EINGEREICHT].includes(antragStatus),
                        icon: CallToAction,
                    },
                ],
            };

            return actions?.[role ?? ''] || undefined;
        },
        [role]
    );

    const handleCustomAction = (index: number, rowData: EinrichtungenFuerPlatzzahlCollection, action: string) => {
        const actions: Record<string, Function> = {
            [TABLE_ACTIONS.PLATZZAHLMELDER_AUFFORDERUNG]: () => {
                backendApiService
                    .createEmailAntrag({
                        id: rowData.id,
                        typ: EMAIL_ANTRAG_ART.PLATZZAHLMELDER_AUFFORDERUNG,
                    })
                    .then(({ id: antragId }) => onCreateAufforderungAntrag?.(antragId))
                    .catch(() => {
                        setRequestMessage(errorMessage('Erstellen der Aufforderung fehlgeschlagen'));
                    });
            },
        };

        return actions[action]?.();
    };

    return {
        getColumnActions,
        handleCustomAction,
    };
};
