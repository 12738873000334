import { JsonFormsState } from '@jsonforms/core';
import { create } from 'zustand';

interface UseRequiredByRuleProps {
    missingProperty: string;
    setMissingProperty: (formState: JsonFormsState, path: string) => void;
}

export const useRequiredByRule = create<UseRequiredByRuleProps>((set, get) => ({
    missingProperty: '',
    setMissingProperty: (formState, path) => {
        if (formState.jsonforms.core?.errors) {
            const fieldWithDependencies = formState.jsonforms.core?.errors.find(
                (error) => error.schemaPath.includes('dependencies') && error.schemaPath.includes(path)
            );

            const hasErrors: boolean = formState.jsonforms.core?.errors.some((error) =>
                error.schemaPath.includes('dependencies')
            );

            const fieldName: string = fieldWithDependencies?.params.missingProperty;

            if (fieldName && fieldName !== get().missingProperty) {
                set({
                    missingProperty: fieldName,
                });
            }

            if (!hasErrors && get().missingProperty) {
                set({ missingProperty: '' });
            }
        }
    },
}));
