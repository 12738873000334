import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { useHasRole } from 'api/auth';
import { useApiMeldungItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ROLE_TYPES } from 'constants/roles';
import { useFormState } from 'forms/state/useFormState';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungAntrag } from './Components/MeldungAntrag';

const Index = () => {
    const { setEinrichtungArt } = useFormState();
    const { antragId } = useParams();
    const hasRole = useHasRole();
    const allowedCheck = hasRole(ROLE_TYPES.ROLLEN_MELDUNGEN_PRUEFEN);

    const { isLoading, data, error } = useApiMeldungItem(antragId);

    const headline = useMemo(() => {
        if (!data?.zuletztEingereichtAt) {
            return 'Meldung: Erstellen';
        }

        return allowedCheck ? 'Meldung: Prüfen' : 'Meldung: Bearbeiten';
    }, [allowedCheck, data]);

    useEffect(() => {
        if (!data) {
            return;
        }

        setEinrichtungArt(data?.einrichtungArt || undefined);
    }, [data, setEinrichtungArt]);

    return (
        <>
            <AppLoaderContainer isLoading={isLoading}>
                {!error ? (
                    <>
                        {data ? (
                            <MeldungAntrag
                                headline={headline}
                                pathBuilder={PathBuilder.home.verwaltung.meldung}
                                layout={{ xs: 10 }}
                                showInfoHeader={allowedCheck}
                            />
                        ) : null}
                    </>
                ) : (
                    <Alert severity="error">Fehler beim Laden der Meldung.</Alert>
                )}
            </AppLoaderContainer>
        </>
    );
};

export const MeldungEditAntrag = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_MELDUNGEN);
