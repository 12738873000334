import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';
import { modalFemMassnahmeEinwilligung } from 'forms/ui-schemas/modalElements';

export const uiSchema = customVerticalLayout([
    control('einwilligungGesamtanzahl'),
    control('einwilligungGesamtanzahlBetroffene'),
    group(
        [
            inlineRowControl([
                control('einwilligungSiebenPunktFixierung/properties/anzahlPersonen'),
                control('einwilligungSiebenPunktFixierung/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungSiebenPunktFixierung'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungFuenfPunktFixierung/properties/anzahlPersonen'),
                control('einwilligungFuenfPunktFixierung/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungFuenfPunktFixierung'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungFixierungArme/properties/anzahlPersonen'),
                control('einwilligungFixierungArme/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungFixierungArme'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungFixierungBeine/properties/anzahlPersonen'),
                control('einwilligungFixierungBeine/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungFixierungBeine'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungFixierungBauchgurt/properties/anzahlPersonen'),
                control('einwilligungFixierungBauchgurt/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungFixierungBauchgurt'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungZwangsjacke/properties/anzahlPersonen'),
                control('einwilligungZwangsjacke/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungZwangsjacke'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungBettgitter/properties/anzahlPersonen'),
                control('einwilligungBettgitter/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungBettgitter'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungPflegedecken/properties/anzahlPersonen'),
                control('einwilligungPflegedecken/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungPflegedecken'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungTherapietisch/properties/anzahlPersonen'),
                control('einwilligungTherapietisch/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungTherapietisch'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungGeschlossene/properties/anzahlPersonen'),
                control('einwilligungGeschlossene/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungGeschlossene'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungEinsperren/properties/anzahlPersonen'),
                control('einwilligungEinsperren/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungEinsperren'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungSchliessmechanismen/properties/anzahlPersonen'),
                control('einwilligungSchliessmechanismen/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungSchliessmechanismen'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungArretieren/properties/anzahlPersonen'),
                control('einwilligungArretieren/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungArretieren'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungFesthalten/properties/anzahlPersonen'),
                control('einwilligungFesthalten/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungFesthalten'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungVerbot/properties/anzahlPersonen'),
                control('einwilligungVerbot/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungVerbot'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungGesicherteAufzuege/properties/anzahlPersonen'),
                control('einwilligungGesicherteAufzuege/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungGesicherteAufzuege'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungSicherungTransport/properties/anzahlPersonen'),
                control('einwilligungSicherungTransport/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungSicherungTransport'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungSedierendeMedikamente/properties/anzahlPersonen'),
                control('einwilligungSedierendeMedikamente/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungSedierendeMedikamente'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungSitzhosen/properties/anzahlPersonen'),
                control('einwilligungSitzhosen/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungSitzhosen'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungWegnehmenDerKleidung/properties/anzahlPersonen'),
                control('einwilligungWegnehmenDerKleidung/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungWegnehmenDerKleidung'
    ),
    group(
        [
            inlineRowControl([
                control('einwilligungSchutzhandschuhe/properties/anzahlPersonen'),
                control('einwilligungSchutzhandschuhe/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/einwilligungSchutzhandschuhe'
    ),
    listControlWithModalForm(
        ['#/properties/einwilligungCustomMassnahmen'],
        [
            tableColumn('Maßnahme', { scope: '#/properties/name' }),
            tableColumn('Anzahl der Personen', { scope: '#/properties/anzahlPersonen' }),
            tableColumn('Anzahl im Quartal', { scope: '#/properties/anzahlQuartal' }),
        ],
        ['Maßnahme hinzufügen'],
        ['Maßnahme löschen'],
        ['Wollen Sie diese Maßnahme mit allen Angaben wirklich löschen?'],
        [modalFemMassnahmeEinwilligung],
        { types: ['Maßnahmen'], labelScope: '#/properties/einwilligungCustomMassnahmen' }
    ),
]);
