import { MUIDataTableColumnOptions } from 'mui-datatables';

import { BENACHRICHTIGUNG_STATUS_FILTER_LABELS } from 'constants/labels';

export const BenachrichtigungStatusFilterOptions = (): MUIDataTableColumnOptions => {
    return {
        customFilterListOptions: {
            render: (value) => {
                return `Status der Benachrichtigung: ${value}`;
            },
        },
        filterOptions: {
            names: [BENACHRICHTIGUNG_STATUS_FILTER_LABELS.GELESEN, BENACHRICHTIGUNG_STATUS_FILTER_LABELS.UNGELESEN],
            fullWidth: true,
        },
        filterType: 'dropdown',
    };
};
