import React from 'react';
import { ControlProps } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, styled, Table, TableBody, TableContainer, Typography, useTheme } from '@mui/material';

import { renderers } from 'forms/renderers';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { theme } from 'theme/theme';

import { TableHeadCompare } from './TableHeadCompare';

interface SummaryTableUiSchema {
    columns: number;
    scope: string;
    label: string;
    schemaScope: string;
    entries: UiSchemaType[];
    hideTableHead?: boolean;
}

interface SummaryTableInterface extends Omit<ControlProps, 'uischema' | 'schema'> {
    uischema: SummaryTableUiSchema;
    schema: Schema;
}

export const SummaryTableComponent: React.FC<SummaryTableInterface> = ({ config, uischema, path, schema, data }) => {
    const theme = useTheme();

    return (
        <Grid
            container
            spacing={1}
            data-testid={'SummaryTable'}
            justifyContent="center"
            sx={{ marginBottom: theme.spacing(4) }}
        >
            {uischema.label ? (
                <Grid item xs={10}>
                    <SummaryTableHeadline variant={'h3'}>{uischema.label}</SummaryTableHeadline>
                </Grid>
            ) : null}

            <Grid item xs={10}>
                <TableContainer>
                    <Table role="presentation">
                        {config?.compareData && true !== uischema?.hideTableHead ? <TableHeadCompare /> : null}

                        <TableBody>
                            {schema &&
                                uischema.entries
                                    ?.filter((row) => getFieldSchemaByScope(row.scope, schema))
                                    .map((row, index) => (
                                        <JsonFormsDispatch
                                            key={index}
                                            renderers={renderers}
                                            schema={schema}
                                            uischema={row}
                                            path={path}
                                            enabled
                                            visible
                                        />
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const SummaryTableHeadline = styled(Typography)(() => ({
    fontWeight: 800,
    margin: `${theme.spacing(4)} 0`,
}));

// @ts-ignore
export const SummaryTable = withJsonFormsControlProps(SummaryTableComponent);
