import { AlertColor } from '@mui/material/Alert/Alert';

import { AntragType } from 'constants/antragTypes';
import { ROLES } from 'constants/roles';

export interface UseRoleText {
    overview?: {
        description?: string;
        subHeadline?: string;
        subDescription?: string;
    };
    documentTitle: string;
    headline: string;
    submitText: string;
    submitSuccessText: string;
    saveSuccessText: string;
    errorPublish: string;
    errorSubmit?: string;
    backButton?: string;
    hint?: {
        severity?: AlertColor;
        zurueckweisen?: string;
        alertText?: string;
    };
}

export type DictionaryAntragTypes = AntragType;

export type DictionaryRoleTypes = ROLES;

export type TextDictionaryOutput = UseRoleText;

export const defaultTextValues: UseRoleText = {
    overview: undefined,
    documentTitle: 'DocumentTitle',
    headline: 'Headline',
    submitText: 'SubmitText',
    submitSuccessText: 'SubmitSuccessText',
    saveSuccessText: 'SaveSuccessText',
    errorPublish: 'ErrorPublish',
    backButton: 'BackButton',
    hint: {
        zurueckweisen: 'HintZurückweisen',
        alertText: 'HintAlertText',
    },
};
