import React from 'react';

import { useAntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { useFemEinrichtungListActions } from 'components/DataTable/actions/useFemEinrichtungListActions';
import { useFemEinrichtungColumns } from 'components/DataTable/columns/fem/useFemEinrichtungColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useFemEinrichtungenListData } from './useFemEinrichtungenListData';

const Index = () => {
    const { handleAction } = useFemEinrichtungListActions();

    const getData = useFemEinrichtungenListData();

    const filterOptions = useAntragTableFilterOptions(AntragType.FEM);

    const columns = useFemEinrichtungColumns(handleAction, filterOptions);

    return (
        <ContentContainer title="Freiheitsentziehende Maßnahmen: Leistungsangebote">
            <StyledContainer>
                <PageHeading title="Freiheitsentziehende Maßnahmen: Leistungsangebote" />

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    initialOptions={{
                        viewColumns: true,
                        filter: true,
                        sortOrder: {
                            name: 'einrichtungsart',
                            direction: 'asc',
                        },
                        custom: {
                            pathBuilder: PathBuilder.home.verwaltung.femAntrag,
                        },
                    }}
                    persistSearchText
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const FemEinrichtungenOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_FEM_MELDER
);
