import React from 'react';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Grid } from '@mui/material';

import { CustomLayoutProps, withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { CustomVerticalLayoutType, UiSchemaType } from 'forms/types/UiSchemaTypes';

const CustomVerticalLayoutComponent: React.FC<CustomLayoutProps<CustomVerticalLayoutType>> = ({
    uischema,
    ...others
}) => {
    return (
        <Grid container spacing={uischema.spacing} data-cy="CustomVerticalLayout">
            <Grid item xs={12} md={uischema.gridSize}>
                {uischema.elements?.map((element: UiSchemaType, i: number) => {
                    if (element?.elements?.length === 0) {
                        return <React.Fragment key={i}></React.Fragment>;
                    }

                    return (
                        <Grid key={i} item xs={12} data-cy="CustomVerticalLayout--grid-item">
                            <JsonFormsDispatch {...others} uischema={element} />
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export const CustomVerticalLayout = withLayoutProps(CustomVerticalLayoutComponent);
