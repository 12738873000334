import * as React from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TextField } from '@mui/material';

import { useFocus } from 'forms/hooks/useFocus';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const LoginPasswordControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    disabled,
    onTouched,
    showFieldNumberLabels,
    gridLayout,
    id,
}) => {
    const [show, setShow] = useState<boolean>(false);
    const { ref, setFocus } = useFocus<HTMLDivElement>();

    const handleValueChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            handleChange(path, value === '' ? undefined : value);
        },
        [handleChange, path]
    );

    const handleToggleShow = () => {
        setShow(!show);
        setFocus();
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <TextField
                id={id}
                ref={ref}
                variant="outlined"
                label="Passwort"
                autoComplete="current-password"
                placeholder="Passwort eingeben ..."
                size="medium"
                type={!show ? 'password' : 'text'}
                required={required}
                value={data ?? ''}
                error={hasErrors}
                disabled={disabled}
                onBlur={onTouched}
                onChange={handleValueChange}
                InputProps={{
                    startAdornment: <LockIcon />,
                    endAdornment: !show ? (
                        <VisibilityIcon onClick={handleToggleShow} sx={{ cursor: 'pointer' }} />
                    ) : (
                        <VisibilityOffIcon onClick={handleToggleShow} sx={{ cursor: 'pointer' }} />
                    ),
                }}
                inputProps={{ 'data-cy': `form_${path}` }}
                fullWidth
            />
        </RowControl>
    );
};

export const LoginPasswordControl = withCustomControlProps(LoginPasswordControlComponent);
