import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { CreateEditPage } from './CreateEditPage';

const Index = () => {
    return <CreateEditPage pathBuilder={PathBuilder.home.verwaltung.leistungsangebote.freigegeben} />;
};

export const FreigegebeneCreateEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN_EDIT
);
