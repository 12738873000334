import { Typography } from '@mui/material';

import { Adresse } from 'components/Adresse/Adresse';
import { PageHeading } from 'components/PageHeading';
import {
    KONTAKT_BASE_URL,
    KONTAKT_BRMS_EMAIL,
    KONTAKT_DNRW,
    KONTAKT_DNRW_ANSPRECHPARTNER,
    KONTAKT_DNRW_HNR,
    KONTAKT_DNRW_PLZ,
    KONTAKT_DNRW_STADT,
    KONTAKT_DNRW_STR,
    KONTAKT_ITNRW,
    KONTAKT_ITNRW_HNR,
    KONTAKT_ITNRW_PLZ,
    KONTAKT_ITNRW_STADT,
    KONTAKT_ITNRW_STR,
    KONTAKT_LDI,
    KONTAKT_LDI_EMAIL,
    KONTAKT_LDI_FAX,
    KONTAKT_LDI_HNR,
    KONTAKT_LDI_PLZ,
    KONTAKT_LDI_STADT,
    KONTAKT_LDI_STR,
    KONTAKT_LDI_TEL,
    KONTAKT_LDI_URL,
    KONTAKT_MAGS,
    KONTAKT_MAGS_DATENSCHUTZ,
    KONTAKT_MAGS_EMAIL_DATENSCHUTZ,
    KONTAKT_MAGS_EMAIL_POSTSTELLE,
    KONTAKT_MAGS_FAX_DATENSCHUTZ,
    KONTAKT_MAGS_HNR,
    KONTAKT_MAGS_PLZ,
    KONTAKT_MAGS_STADT,
    KONTAKT_MAGS_STR,
    KONTAKT_MAGS_TEL,
    KONTAKT_PUBLIC_PLAN,
    KONTAKT_PUBLIC_PLAN_HNR,
    KONTAKT_PUBLIC_PLAN_PLZ,
    KONTAKT_PUBLIC_PLAN_STADT,
    KONTAKT_PUBLIC_PLAN_STR,
} from 'constants/content';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkExtern } from 'elements/LinkExtern';
import { LinkTel } from 'elements/LinkTel';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { TextContainer } from 'layout/container/TextContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const Datenschutz = () => {
    return (
        <ContentContainer title="Datenschutzhinweis">
            <StyledContainer>
                <TextContainer>
                    <PageHeading title="Datenschutzhinweise" />

                    <Typography variant="h2">
                        Verantwortlicher im Sinne der Datenschutz-Grundverordnung („DSGVO“)
                    </Typography>

                    <Typography paragraph>
                        Den Verantwortlichen für die Verarbeitung Ihrer Daten erreichen Sie per E-Mail unter{' '}
                        <LinkEmail email={KONTAKT_MAGS_EMAIL_POSTSTELLE} /> oder über die Adresse:
                    </Typography>
                    <Adresse
                        name={KONTAKT_MAGS}
                        strasse={KONTAKT_MAGS_STR}
                        hausnummer={KONTAKT_MAGS_HNR}
                        plz={KONTAKT_MAGS_PLZ}
                        ort={KONTAKT_MAGS_STADT}
                    />
                    <Typography paragraph>
                        Tel. <LinkTel msisdn={KONTAKT_MAGS_TEL} />
                        <br />
                        Fax <LinkTel msisdn={KONTAKT_MAGS_FAX_DATENSCHUTZ} />
                    </Typography>

                    <Typography variant="h2">Datenschutzbeauftragter</Typography>
                    <Typography paragraph>
                        Die Datenschutzbeauftragte des Ministeriums erreichen Sie über folgende Adresse:
                    </Typography>
                    <Adresse
                        name={KONTAKT_MAGS}
                        ansprechpartner={KONTAKT_MAGS_DATENSCHUTZ}
                        strasse={KONTAKT_MAGS_STR}
                        hausnummer={KONTAKT_MAGS_HNR}
                        plz={KONTAKT_MAGS_PLZ}
                        ort={KONTAKT_MAGS_STADT}
                    />
                    <Typography paragraph>
                        Tel. <LinkTel msisdn={KONTAKT_MAGS_TEL} />
                        <br />
                        Fax <LinkTel msisdn={KONTAKT_MAGS_FAX_DATENSCHUTZ} />
                        <br />
                        E-Mail <LinkEmail email={KONTAKT_MAGS_EMAIL_DATENSCHUTZ} />
                    </Typography>

                    <Typography variant="h2">Hinweise zum Datenschutz</Typography>
                    <Typography paragraph>
                        Bei der Nutzung des Onlineportals PfAD.wtg werden personenbezogene Daten verarbeitet. Dies ist
                        schon aus technischen Gründen erforderlich, um Ihnen alle Funktionalitäten von PfAD.wtg und die
                        Sicherheit unseres Angebotes zu gewährleisten (Rechtsgrundlage ist Art.&nbsp;6 Abs.&nbsp;1
                        S.&nbsp;1 lit.&nbsp;e DSGVO).
                        <br />
                        Personenbezogene Daten sind solche, die sich auf eine identifizierte oder identifizierbare
                        natürliche Person beziehen. Die Verarbeitung von personenbezogenen Daten umfasst u.a. das
                        Erheben, Speichern, Übermitteln oder Löschen dieser Daten.
                        <br />
                        Bei der Benutzung von PfAD.wtg werden vorübergehend Daten über sogenannte Protokoll- bzw.
                        Log-Dateien gespeichert. Die folgenden Daten werden bei jedem Aufruf der Internetseite erhoben:
                    </Typography>
                    <ul>
                        <li>Datum und Uhrzeit des Abrufs</li>
                        <li>
                            Name des aufgerufenen Internetdienstes, der aufgerufenen Ressource und der verwendeten
                            Aktion
                        </li>
                        <li>Abfrage, die der Client gestellt hat</li>
                        <li>übertragene Datenmenge</li>
                        <li>Meldung, ob der Abruf erfolgreich war</li>
                        <li>IP-Adresse des aufrufenden Rechners</li>
                        <li>Clientinformationen (u.a. Browser, Betriebssystem)</li>
                    </ul>
                    <Typography paragraph>
                        Diese Daten aus den Protokoll- bzw. Log-Dateien dienen zur Abwehr und Analyse von Angriffen auf
                        das Portal und werden bis zu 48&nbsp;Stunden direkt und ausschließlich für Administratoren
                        zugänglich aufbewahrt. Danach sind sie nur noch indirekt über die Rekonstruktion von
                        Sicherungsbändern verfügbar und werden nach sechs Wochen endgültig gelöscht.
                    </Typography>

                    <Typography variant="h2">E-Mail</Typography>
                    <Typography paragraph>
                        Für Ihre Kontaktaufnahme mit den zuständigen Behörden oder dem Support bieten wir Ihnen
                        verschiedene Zugangsmöglichkeiten über E-Mail-Adressen. Sofern Sie diese Kontakte nutzen,
                        erfolgt die Eingabe personenbezogener Daten auf ausdrücklich freiwilliger Basis (Rechtsgrundlage
                        ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO). Dies betrifft zum Beispiel Anfragen
                        über E-Mail.
                    </Typography>
                    <Typography paragraph>In diesem Zusammenhang können gespeichert werden:</Typography>
                    <ul>
                        <li>Anrede</li>
                        <li>Name</li>
                        <li>Vorname</li>
                        <li>E-Mail-Adresse</li>
                        <li>Straße</li>
                        <li>Hausnummer</li>
                        <li>Postleitzahl</li>
                        <li>Ort</li>
                        <li>Telefonnummer</li>
                        <li>Betreff</li>
                        <li>Ihre Nachricht</li>
                        <li>Zeitstempel</li>
                        <li>Benutzer</li>
                        <li>IP-Adresse</li>
                    </ul>
                    <Typography paragraph>
                        Bei Ihrer Kontaktaufnahme mit uns per E-Mail müssen die von Ihnen freiwillig mitgeteilten Daten
                        gespeichert werden, damit wir Ihr Anliegen bearbeiten können.
                    </Typography>
                    <Typography paragraph>
                        Personen unter 16&nbsp;Jahren sollten keine personenbezogenen Daten an uns übermitteln, es sei
                        denn die Zustimmung der Eltern oder Sorgeberechtigten (Träger der elterlichen Verantwortung)
                        wurde erteilt (Art.&nbsp;8 Abs.&nbsp;1 DSGVO). Die Zustimmung muss dann ausdrücklich in der
                        Nachricht vermerkt sein (Art.&nbsp;8 Abs.&nbsp;2 DSGVO). Wir fordern keine personenbezogenen
                        Daten von Kindern und Jugendlichen an. Wissentlich sammeln wir solche Daten nicht.
                    </Typography>
                    <Typography paragraph>
                        Die von Ihnen eingegebenen persönlichen Daten verwenden wir nur zu dem von Ihnen gewünschten
                        Zweck und nur innerhalb der Landesverwaltung bzw. der mit dem jeweiligen Service beauftragten
                        Behörden, Dienststellen und Institutionen.
                    </Typography>
                    <Typography paragraph>
                        Die anfallenden personenbezogenen Daten, die wir per E-Mail erhalten, löschen wir, sobald die
                        Speicherung nicht mehr für den von Ihnen verfolgten Zweck oder im Zusammenhang mit damit
                        ausgelösten Verwaltungsvorgängen und den hierfür geltenden Aufbewahrungspflichten erforderlich
                        ist. Die Bezirksregierung unterliegt verschiedenen Aufbewahrungs- und Dokumentationspflichten,
                        die sich aus gesetzlichen Vorgaben ergeben. Die dort vorgegebenen Fristen zur Aufbewahrung bzw.
                        Dokumentation können unterschiedliche Dauer betragen.
                    </Typography>
                    <Typography paragraph>
                        Alle von dieser Website versandten E-Mails werden nur an die von Ihnen angegebene Adresse
                        geschickt. Die Adresse wird nicht veröffentlicht und wird nur zu dem/der von Ihnen gewünschten
                        Zweck, Dienst oder Funktion verwendet.
                    </Typography>

                    <Typography variant="h2">Nutzung</Typography>
                    <Typography paragraph>
                        Das Angebot verwendet sogenannte Cookies. Bei Cookies handelt es sich um kleine Textdateien, die
                        auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Diese dienen dazu, unser
                        Angebot für Sie nutzerfreundlicher und effektiver zu machen.
                        <br />
                        Sie können die Verwendung von Cookies unterbinden, indem Sie Ihren Internetbrowser so
                        einstellen, dass dieser keine Cookies akzeptiert. Sie können in Ihrem Browser aber auch
                        festlegen, ob Sie über das Setzen von Cookies informiert werden möchten und Cookies nur im
                        Einzelfall erlauben, ob Sie die Annahme von Cookies für bestimmte Fälle oder eben auch generell
                        ausschließen. Außerdem können Sie das automatische Löschen der Cookies beim Schließen des
                        Browsers aktivieren. Wie man das macht, hängt von Ihrem Browser ab. Beim erstmaligen Besuch des
                        Internetangebotes bzw. einer Seite werden Sie automatisch auf die Cookie-Nutzung hingewiesen.
                    </Typography>

                    <Typography variant="h2">Webanalyse</Typography>
                    <Typography paragraph>Derzeit wird kein Instrument zur Webanalyse eingesetzt.</Typography>

                    <Typography variant="h2">Auftragsdatenverarbeitung</Typography>
                    <Typography paragraph>
                        Für die Entwicklung des Datenverarbeitungssystems PfAD.wtg hat das Ministerium für Arbeit,
                        Gesundheit und Soziales des Landes Nordrhein-Westfalen die d-NRW AöR beauftragt. Im Rahmen der
                        Auftragsverarbeitung kann diese Anstalt öffentlichen Rechts Daten verarbeiten, soweit dies für
                        die Aufgabenerfüllung erforderlich ist.
                    </Typography>
                    <Adresse
                        name={KONTAKT_DNRW}
                        ansprechpartner={KONTAKT_DNRW_ANSPRECHPARTNER}
                        strasse={KONTAKT_DNRW_STR}
                        hausnummer={KONTAKT_DNRW_HNR}
                        plz={KONTAKT_DNRW_PLZ}
                        ort={KONTAKT_DNRW_STADT}
                    />
                    <Typography paragraph>
                        Für die Entwicklungsarbeiten hat d-NRW die publicplan GmbH beauftragt:
                    </Typography>
                    <Adresse
                        name={KONTAKT_PUBLIC_PLAN}
                        strasse={KONTAKT_PUBLIC_PLAN_STR}
                        hausnummer={KONTAKT_PUBLIC_PLAN_HNR}
                        plz={KONTAKT_PUBLIC_PLAN_PLZ}
                        ort={KONTAKT_PUBLIC_PLAN_STADT}
                    />
                    <Typography paragraph>
                        Die Internetseite <LinkExtern href={KONTAKT_BASE_URL} /> wird bei IT.NRW gehostet.
                    </Typography>
                    <Adresse
                        name={KONTAKT_ITNRW}
                        strasse={KONTAKT_ITNRW_STR}
                        hausnummer={KONTAKT_ITNRW_HNR}
                        plz={KONTAKT_ITNRW_PLZ}
                        ort={KONTAKT_ITNRW_STADT}
                    />

                    <Typography variant="h2">Ihre Rechte als Nutzer</Typography>
                    <Typography paragraph>
                        Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen
                        Daten:
                    </Typography>
                    <ul>
                        <li>Recht auf Auskunft (Art.&nbsp;15 DSGVO),</li>
                        <li>Recht auf Berichtigung oder Löschung (Art.&nbsp;16, 17 DSGVO),</li>
                        <li>Recht auf Einschränkung der Verarbeitung (Art.&nbsp;18 DSGVO),</li>
                        <li>Recht auf Widerspruch gegen die Verarbeitung (Art.&nbsp;21 DSGVO),</li>
                        <li>Recht auf Datenübertragbarkeit (Art.&nbsp;20 DSGVO).</li>
                    </ul>
                    <Typography paragraph>
                        Wenden Sie sich bitte dazu per Mail über das Postfach <LinkEmail email={KONTAKT_BRMS_EMAIL} />{' '}
                        oder schriftlich über die im Impressum angegebene Adresse an die Online-Redaktion.
                        <br />
                        Personenbezogene Daten, die über PfAD.wtg erfasst werden, werden gelöscht, sobald die
                        Speicherung nicht mehr zur Erfüllung der gesetzlichen Aufgaben, für den von Ihnen verfolgten
                        Zweck oder im Zusammenhang mit damit ausgelösten Verwaltungsvorgängen und den hierfür geltenden
                        Aufbewahrungspflichten erforderlich ist. Daten, die nicht zur Antragstellung eingereicht werden,
                        werden innerhalb von zwölf Monaten gelöscht, sofern keine weitere Bearbeitung erfolgt. Nach
                        Antragstellung und nach Widerruf eines Angebotes werden die Daten gelöscht, wenn sie nicht mehr
                        benötigt werden. Dies sind in der Regel fünf Jahre nach Ablauf des Jahres des letzten
                        Verwaltungshandelns. Die Frist im Einzelfall setzen die für die Aufgabenerfüllung zuständigen
                        Behörden fest.
                    </Typography>
                    <Typography paragraph>
                        Bitte beachten Sie die datenschutzrechtlichen Anforderungen, wenn Sie Daten Dritter, z.B. Ihrer
                        Beschäftigten oder Kooperationspartner angeben. Auch diese Personen haben ein Recht auf Auskunft
                        und Berichtigung ihrer Daten und können dies gegenüber der zuständigen Stelle (Ministerium für
                        Arbeit, Gesundheit und Soziales) geltend machen. Darüber hinaus haben sie ein Recht auf
                        Einschränkung der Verarbeitung und ein Widerspruchsrecht. Daher sollten Sie darauf achten, dass
                        die Personen hierüber informiert sind. Am besten, Sie lassen sich dies bestätigen.
                    </Typography>

                    <Typography variant="h2">Aufsichtsbehörde</Typography>
                    <Typography paragraph>
                        Wenn Sie der Auffassung sind, dass wir Ihrem Anliegen nicht oder nicht in vollem Umfang
                        nachgekommen sind, können Sie bei der zuständigen Datenschutzaufsichtsbehörde Beschwerde
                        einlegen:
                    </Typography>
                    <Adresse
                        name={KONTAKT_LDI}
                        strasse={KONTAKT_LDI_STR}
                        hausnummer={KONTAKT_LDI_HNR}
                        plz={KONTAKT_LDI_PLZ}
                        ort={KONTAKT_LDI_STADT}
                    />
                    <Typography paragraph>
                        Tel. <LinkTel msisdn={KONTAKT_LDI_TEL} />
                        <br />
                        Fax <LinkTel msisdn={KONTAKT_LDI_FAX} />
                        <br />
                        E-Mail <LinkEmail email={KONTAKT_LDI_EMAIL} />
                    </Typography>

                    <Typography variant="h2">Allgemeines zum Thema Datenschutz</Typography>
                    <Typography paragraph>
                        Da sich im Laufe der Zeit Änderungen ergeben können, empfehlen wir Ihnen, sich die
                        Datenschutzhinweise von Zeit zu Zeit erneut durchzulesen.
                        <br />
                        Allgemeines zum Datenschutz finden Sie auf der Internetseite der Landesbeauftragten für
                        Datenschutz und Informationsfreiheit <LinkExtern href={KONTAKT_LDI_URL} />.
                    </Typography>
                </TextContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const DatenschutzPage = withSideBar(Datenschutz);
