import { RankedTester, rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

export const isJahr: Tester = (uischema: UISchemaElement & { scope?: string }, schema: JsonSchema): boolean =>
    uischema?.type === 'Control' &&
    Boolean(getFieldSchemaByScope(uischema.scope, schema as Schema)?.custom?.block_prefixes?.includes('jahr'));

export const JahrTester: RankedTester = rankWith(6500, isJahr);
