import React from 'react';
import { useParams } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, styled, Typography } from '@mui/material';
import formatDate from 'date-fns/format';

import { useApiMassenmailItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { getDateTime } from 'components/DataTable/tableUtils';
import { ROLE_TYPES } from 'constants/roles';
import { Link } from 'elements/Link';
import { getAnyOfTitel, getOneOfTitel, useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { displayBenutzerName, nl2br } from 'utilities';

const MassenmailAnsehenContent = styled('div')(
    () => `
    & dd {
        margin-bottom: 20px;
    }
    & ul {
        padding-left: 0px;
    }
    & li {
        list-style-type: none;
    }

    & dl:last-of-type {
         margin-top: 36px;
    }
`
);

const StyledAccordion = styled(Accordion)(
    () => `
    &::before {
        opacity: 0;
    }
    & {
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 16px;
    }
    &.Mui-expanded:last-of-type {
        margin-bottom: 16px;
    }
`
);

const Index = () => {
    const { massenmailId } = useParams();

    const { isLoading, data } = useApiMassenmailItem(massenmailId);
    const { schema } = useSchemaFromJsonLdContext('Massenmail', data);

    const versendete =
        undefined !== data ? data.empfaenger?.filter((empfaenger) => undefined !== empfaenger.gesendetAt) : undefined;

    const nichtVersendete =
        undefined !== data ? data.empfaenger?.filter((empfaenger) => undefined === empfaenger.gesendetAt) : undefined;

    const getFormattedDate = (date?: string) => (date ? formatDate(new Date(date), 'dd.MM.yyyy') : '-');

    return (
        <ContentContainer title={`Massenmail vom ${getFormattedDate(data?.createdAt)}`}>
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading || !data || !schema}>
                    {data && schema ? (
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <Typography variant="h1">{`Massenmail vom ${getFormattedDate(
                                    data?.createdAt
                                )}`}</Typography>
                                <MassenmailAnsehenContent>
                                    <dl>
                                        <dt>Erstellt</dt>
                                        <dd>{getDateTime(data.createdAt)}</dd>
                                        <dt>Absender</dt>
                                        <dd>{displayBenutzerName(data.createdBy)}</dd>
                                        <dt>{schema.properties?.empfaengerGruppe.title}</dt>
                                        <dd>{getOneOfTitel(schema, 'empfaengerGruppe', data.empfaengerGruppe)}</dd>
                                        {data.empfaengerGruppe === 'CUSTOM' && (
                                            <>
                                                <dt>{schema.properties?.einrichtungsart.title}</dt>
                                                <dd>
                                                    {getAnyOfTitel(schema, 'einrichtungsart', data.einrichtungsart)}
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                    <StyledAccordion elevation={0}>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <strong>{`Versendet an ${versendete?.length ?? 0} Empfänger`}</strong>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {versendete && versendete.length
                                                ? versendete.map((empfaenger) => empfaenger.email).join('; ')
                                                : 'Es wurden noch keine Mails versendet.'}
                                        </AccordionDetails>
                                    </StyledAccordion>
                                    <StyledAccordion elevation={0}>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <strong>{`Noch nicht versendet an ${
                                                nichtVersendete?.length ?? 0
                                            } Empfänger`}</strong>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {nichtVersendete && nichtVersendete.length
                                                ? nichtVersendete.map((empfaenger) => empfaenger.email).join('; ')
                                                : 'Es wurden alle Mails versendet.'}
                                        </AccordionDetails>
                                    </StyledAccordion>
                                    <dl>
                                        <dt>{schema.properties?.betreff.title}</dt>
                                        <dd>{data.betreff}</dd>
                                        <dt>{schema.properties?.inhalt.title}</dt>
                                        <dd>
                                            <div dangerouslySetInnerHTML={{ __html: nl2br(data.inhalt) }} />
                                            {data.uploads && data.uploads.length > 0 && (
                                                <>
                                                    <br />
                                                    <strong>Hochgeladene Dokumente</strong>
                                                    <br />
                                                    <ul>
                                                        {data.uploads.map((upload, index) => (
                                                            <React.Fragment key={`${upload.id}.${index}`}>
                                                                {upload.contentUrl && (
                                                                    <li key={upload.id}>
                                                                        <Link href={upload.contentUrl} target="_blank">
                                                                            {upload.originalName}
                                                                        </Link>
                                                                    </li>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                        </dd>
                                    </dl>
                                </MassenmailAnsehenContent>
                            </Grid>

                            <Grid
                                item
                                xs={8}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    component={Link}
                                    to={PathBuilder.home.verwaltung.massenmail.path}
                                    variant="outlined"
                                >
                                    Zur Übersicht
                                </Button>
                            </Grid>
                        </Grid>
                    ) : null}
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MassenmailAnsehen = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_MASSENMAIL);
