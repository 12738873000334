import React, { useState } from 'react';
import { Download } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import { backendApiService } from 'api/ApiService';
import { useSpecificRole } from 'api/auth';
import { GeneratedFile, MeldungenStatus } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useLeistungsangebotFreigegebeneListAction } from 'components/DataTable/actions/useLeistungsangebotFreigegebeneListAction';
import { useFreigegebeneColumns } from 'components/DataTable/columns/leistungsangebot/useFreigegebeneColumns';
import { DataTableHydraServerside, TableChangeProps } from 'components/DataTable/DataTableHydraServerside';
import { useBenutzerOptions } from 'components/DataTable/hooks/useBenutzerOptions';
import { OpenViewTab } from 'components/DataTable/hooks/useTableOptions';
import { sortBy } from 'components/DataTable/tableUtils';
import { useEinrichtungListData } from 'components/DataTable/useEinrichtungListData';
import { DocumentDownload } from 'components/DocumentDownload';
import { STATUS } from 'constants/antragStatus';
import { ROLLEN } from 'constants/roles';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';

interface LeistungsangeboteTableProps {
    title?: string;
    status?: STATUS;
    meldungenStatus?: MeldungenStatus;
    enableMeldungenStatusFilter?: boolean;
    titleVariant?: Variant;
    openViewTab?: OpenViewTab;
    pathBuilder?: PathBuilderProps;
}

export const LeistungsangeboteTable = ({
    title,
    status,
    meldungenStatus,
    enableMeldungenStatusFilter,
    titleVariant = 'h1',
    openViewTab,
    pathBuilder,
}: LeistungsangeboteTableProps) => {
    const { isLoading, options: zustaendigerBenutzerOptions } = useBenutzerOptions(ROLLEN.BEHOERDE);
    const { handleAction, serverDataChanged } = useLeistungsangebotFreigegebeneListAction(pathBuilder);

    const getData = useEinrichtungListData({ status, meldungenStatus });

    const { isLoading: isLoadingGeneratedFile, loadingStart, loadingEnd } = useFormState();

    const [exportParams, setExportParams] = useState<TableChangeProps>({});
    const [generatedFile, setGeneratedFile] = useState<GeneratedFile | null>(null);

    const { isBehoerde } = useSpecificRole();

    const columns = useFreigegebeneColumns(
        handleAction,
        zustaendigerBenutzerOptions,
        openViewTab,
        enableMeldungenStatusFilter
    );

    const handleTableChange = ({ filters, sortOrder, searchText }: TableChangeProps) => {
        setExportParams({ filters, sortOrder, searchText });
    };

    const handleFileExport = () => {
        loadingStart();

        backendApiService
            .getEinrichtungenExport({
                qSearch: exportParams.searchText,
                orderName: sortBy(exportParams.sortOrder, 'name'),
                orderAdresseOrt: sortBy(exportParams.sortOrder, 'ort'),
                orderErstinbetriebnahme: sortBy(exportParams.sortOrder, 'datumInbetriebnahme'),
                orderArt: sortBy(exportParams.sortOrder, 'einrichtungsArt'),
                orderLetzteMeldung: sortBy(exportParams.sortOrder, 'letzteMeldung'),
                orderStatus: sortBy(exportParams.sortOrder, 'letzterStatus'),
                orderRegelpruefungenPruefDatum: sortBy(exportParams.sortOrder, 'letzteRegelpruefung'),
                orderRegelpruefungenLetzterUploadAt: sortBy(exportParams.sortOrder, 'letzterPruefbericht'),
                orderZustaendig: sortBy(exportParams.sortOrder, 'zustaendig'),
                status: !exportParams.filters?.['letzterStatus']?.length ? status : undefined,
                status2: exportParams.filters?.['letzterStatus'],
                art2: exportParams.filters?.['einrichtungsArt'],
                existsDeleted: false,
                meldungenStatus: exportParams.filters?.['meldungenStatus']?.[0] as MeldungenStatus,
            })
            .then((response) => {
                setGeneratedFile(response);
            })
            .finally(() => loadingEnd());
    };

    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
                {title ? <Typography variant={titleVariant}>{title}</Typography> : null}

                <AppLoaderContainer isLoading={Boolean(isLoading)}>
                    <DataTableHydraServerside
                        onTableChange={(...tableParams) => {
                            if (isBehoerde) {
                                handleTableChange(...tableParams);
                            }
                        }}
                        getColumns={columns}
                        getData={getData}
                        initialOptions={{
                            searchPlaceholder: 'Suche nach NRW-Schlüssel, Name des Leistungsangebots, Ort und PLZ',
                            viewColumns: true,
                            filter: true,
                            sortOrder: {
                                name: 'nrwKey',
                                direction: 'asc',
                            },
                            custom: {
                                pathBuilder: pathBuilder || PathBuilder.home.verwaltung.leistungsangebote.freigegeben,
                            },
                        }}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                    />

                    {isBehoerde ? (
                        <ButtonGroupGrid>
                            <DocumentDownload generatedFile={generatedFile} />

                            <FormLoadingButton
                                loading={isLoadingGeneratedFile}
                                loadingPosition="end"
                                endIcon={<Download />}
                                type="submit"
                                color="primary"
                                variant="contained"
                                data-cy="BehoerdeExportButton"
                                onClick={handleFileExport}
                            >
                                Exportieren
                            </FormLoadingButton>
                        </ButtonGroupGrid>
                    ) : null}
                </AppLoaderContainer>
            </Grid>
        </Grid>
    );
};
