import * as React from 'react';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { FormControl } from '@mui/material';

import { FileUploadResponse } from 'api/responses/FileUploadResponse';
import { URL_HEIMFINDER_UPLOAD, URL_UPLOAD } from 'api/routes';
import { FileUploadButton } from 'forms/controls/components/FileUploadButton';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useFileUpload } from 'forms/hooks/useFileUpload';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { UploadValue } from 'forms/types/UploadValueType';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const UploadComponent: React.FC<CustomControlProps<UploadValue>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    handleBlur,
    hasErrors,
    errors,
    disabled,
    readonly,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    schema,
}) => {
    const autoFocus = useRef(false);
    const onUpload = useCallback(
        (responses: FileUploadResponse[]) => {
            if (!responses.length) {
                return;
            }
            handleChange(
                path,
                {
                    uploadId: responses[0].id,
                    originalName: responses[0].originalName,
                    contentUrl: responses[0].contentUrl,
                    accessRights: responses[0].accessRights,
                },
                true
            );
        },
        [handleChange, path]
    );

    const onRemove = useCallback(() => {
        handleChange(path, null, true);
        autoFocus.current = true;
    }, [handleChange, path]);

    const targetUrl = useMemo(() => {
        if (schema?.custom?.block_prefixes.includes('heimfinder_upload')) {
            return URL_HEIMFINDER_UPLOAD;
        }

        return URL_UPLOAD;
    }, [schema]);

    const uploadedFile = useMemo(() => (data?.originalName ? [data] : []), [data]);

    const { uploadErrors, removeFile, isUploading, uploadFiles } = useFileUpload(
        onUpload,
        onRemove,
        targetUrl,
        uploadedFile,
        uischema.withUploadLimit
    );

    const handleValueChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files?.length) return;
            uploadFiles(e.target.files!);
            autoFocus.current = true;
        },
        [uploadFiles]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={
                (uischema as any).showFieldNumberLabels !== undefined
                    ? (uischema as any).showFieldNumberLabels
                    : showFieldNumberLabels
            }
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
        >
            <FlexDiv>
                <FormControl fullWidth>
                    {data ? (
                        <UploadedDocument
                            fileData={data}
                            onRemove={() => removeFile(data.uploadId)}
                            readonly={readonly}
                            disabled={disabled}
                            autoFocus={autoFocus}
                        />
                    ) : readonly ? (
                        <ReadonlyValueText hasErrors={hasErrors} errors={errors} text={'-'} />
                    ) : (
                        <FileUploadButton
                            isUploadingFile={isUploading}
                            hasErrors={hasErrors}
                            uploadErrors={uploadErrors}
                            errors={errors}
                            disabled={disabled}
                            required={required}
                            onBlur={handleBlur}
                            onChange={handleValueChange}
                            autoFocus={autoFocus.current}
                        />
                    )}
                </FormControl>
            </FlexDiv>
        </RowControl>
    );
};

export const UploadControl = withCustomControlProps(UploadComponent);
