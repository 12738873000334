import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { DeletedObjectsTable } from 'pages/Verwaltung/DeletedObjects/components/DeletedObjectsTable';

const Index = () => {
    return <DeletedObjectsTable pathBuilder={PathBuilder.home.aufgaben.deletedObjects} filterConfirmed={false} />;
};

export const DeletedObjectsOverviewPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_DELETED_OBJECTS
);
