import * as React from 'react';
import { ChangeEvent, useCallback } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { TextField } from '@mui/material';

import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const LoginEmailComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    disabled,
    onTouched,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    id,
}) => {
    const handleValueChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            handleChange(path, value === '' ? undefined : value);
        },
        [handleChange, path]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <TextField
                id={id}
                type="email"
                label="E-Mail"
                variant="outlined"
                placeholder="E-Mail eingeben ..."
                size="medium"
                autoComplete="email"
                required={required}
                value={data ?? ''}
                error={hasErrors}
                disabled={disabled}
                onBlur={onTouched}
                onChange={handleValueChange}
                InputProps={{ startAdornment: <EmailOutlinedIcon /> }}
                inputProps={{ 'data-cy': `form_${path}` }}
                autoFocus={uischema.options?.focus}
                fullWidth
            />
        </RowControl>
    );
};

export const LoginEmailControl = withCustomControlProps(LoginEmailComponent);
