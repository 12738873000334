import React from 'react';

import { PathBuilder } from 'navigation/Paths';

import { Link } from './Link';

interface LinkSupportProps {
    title?: string;
}

export const LinkSupport: React.FC<LinkSupportProps> = ({ title }) => {
    return (
        <Link to={PathBuilder.home.hilfe.path} target={'_blank'}>
            {title || 'Support'}
        </Link>
    );
};
