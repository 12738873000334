import formatDate from 'date-fns/format';

const string2Date = (data?: string | null): Date | null => {
    if (!data?.length) return null;
    const date = new Date(data);
    if (isNaN(date.getTime())) return null;

    return date;
};

export const transform2Date = (date: string | undefined, format = 'dd.MM.yyyy') => {
    const transformedDate = string2Date(date);

    if (!transformedDate || (transformedDate && !isNaN(transformedDate.getTime()))) {
        return (transformedDate && formatDate(transformedDate, format)) || null;
    }

    return null;
};

// ToDo Refactoring: Hook entfernen und Funktion direkt verwenden - Eventuell in Formatter auslagern
export const useFormatDate = () => {
    return {
        transform2Date,
    };
};
