import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Done } from '@mui/icons-material';
import { Alert, Button, styled, Typography, useTheme } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { DokumentJsonld } from 'api/client';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { AnschriftType, EinrichtungArt, EinrichtungArtType } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useMeldungenAntragListActions } from 'components/DataTable/actions/useMeldungenAntragListActions';
import { useMeldungenColumns } from 'components/DataTable/columns/meldungen/useMeldungenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ACTIONS } from 'constants/antragActions';
import { Link } from 'elements/Link';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useServerError } from 'forms/hooks/useServerError';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';

import { useEinrichtungListData } from './useMeldungListData';

interface DetailPageProps {
    pathBuilder: PathBuilderProps;
    tablePathBuilder: PathBuilderProps;
    title?: string;
    meldungEinrichtungId?: string;
}

export const DetailPage = ({ pathBuilder, tablePathBuilder, title, meldungEinrichtungId }: DetailPageProps) => {
    const { id, einrichtungId } = useParams();
    const theme = useTheme();

    const currentId = useMemo(() => id || einrichtungId, [id, einrichtungId]);

    const { setRequestMessage, submitStart, submitEnd, isPersisting } = useFormState();
    const collectionError = useServerError();

    const { data, isLoading, loadData } = useApiEinrichtungGet(currentId);
    const { handleAction } = useMeldungenAntragListActions({ pathBuilder: PathBuilder.home.verwaltung.meldung });

    const getData = useEinrichtungListData({ einrichtungId: meldungEinrichtungId || einrichtungId });

    const isHausanschrift = useMemo(() => data?.adresse?.anschriftTyp === AnschriftType.HAUSANSCHRIFT, [data]);
    const isPostfach = useMemo(() => data?.adresse?.anschriftTyp === AnschriftType.POSTFACH, [data]);

    const columns = useMeldungenColumns(handleAction);

    const einrichtungName = useMemo(() => data && data.name, [data]);

    const handleDeleteConfirm = () => {
        submitStart(true);

        backendApiService
            .workflowEinrichtung(Number(id), ACTIONS.CONFIRM_DELETE)
            .then(() => {
                loadData(currentId);
                setRequestMessage(successMessage('Löschung erfolgreich zur Kenntnis genommen.'));
            })
            .catch((error) => setRequestMessage(errorMessage(collectionError(error, 'Fehler bei der Kenntnisnahme.'))))
            .finally(() => submitEnd());
    };

    return (
        <ContentContainer title="Leistungsangebot: Ansehen">
            <StyledContainer>
                <PageHeading title={title || `Leistungsangebot ${einrichtungName}`} />

                <BaseGridContainer lg={8} justifyContent="center" marginBotton={theme.spacing(3)}>
                    {data?.deleted && data?.delete_confirmed ? (
                        <Alert severity="info">Löschung wurde zur Kenntnis genommen</Alert>
                    ) : null}

                    <AppLoaderContainer isLoading={isLoading && !data}>
                        {data ? (
                            <>
                                <DetailsLayout.Container divide={!!data?.deleted}>
                                    <DetailsLayout.Group>
                                        <DetailsLayout.InfoField value={data.nrwKey} label="NRW-Schlüssel" />
                                        <DetailsLayout.InfoField
                                            value={
                                                data.art
                                                    ? EinrichtungArtType[data.art as keyof EinrichtungArt].label
                                                    : '-'
                                            }
                                            label="Art des Leistungsangebots"
                                        />
                                        <DetailsLayout.InfoField value={data.name} label="Name des Leistungsangebots" />
                                        {isPostfach || isHausanschrift ? (
                                            <>
                                                {isPostfach ? (
                                                    <DetailsLayout.InfoField
                                                        label="Postfach"
                                                        value={data.adresse?.postfachBezeichnung}
                                                    />
                                                ) : null}

                                                {isHausanschrift ? (
                                                    <DetailsLayout.InfoField
                                                        label="Straße, Nr."
                                                        value={`${data.adresse?.strasse} ${data.adresse?.hausnummer}`}
                                                    />
                                                ) : null}

                                                <DetailsLayout.InfoField
                                                    label="PLZ und Ort"
                                                    value={`${data?.adresse?.plz} ${data?.adresse?.ort}`}
                                                />
                                            </>
                                        ) : null}
                                        <DetailsLayout.InfoField label="Ansprechpartner" value={data.adresse?.name} />
                                        <DetailsLayout.InfoField label="Telefon" value={data.adresse?.telefon} />
                                        <DetailsLayout.InfoField label="Telefax" value={data.adresse?.fax} />
                                        <DetailsLayout.InfoField label="E-Mail-Adresse" value={data.adresse?.email} />
                                        <DetailsLayout.InfoField label="Homepage" value={data.adresse?.homepage} />
                                        <DetailsLayout.InfoField
                                            label="Datum der Erstinbetriebnahme"
                                            value={data.erstinbetriebnahme}
                                            isDate
                                        />
                                        <DetailsLayout.InfoField
                                            label="Verband" // Trägerverband
                                            value={data.traegerverband?.name}
                                        />
                                        {data?.traeger?.name ? (
                                            <DetailsLayout.InfoField
                                                label="Leistungsanbieter"
                                                value={data.traeger?.name}
                                            />
                                        ) : null}

                                        {data?.traeger?.adresse?.plz ? (
                                            <>
                                                {data.traeger?.adresse?.anschriftTyp === AnschriftType.HAUSANSCHRIFT ? (
                                                    <DetailsLayout.InfoField
                                                        label="Adresse des Leistungsanbieters"
                                                        value={`${data?.traeger?.adresse?.strasse} ${data?.traeger?.adresse?.hausnummer}, ${data?.traeger?.adresse?.plz} ${data?.traeger?.adresse?.ort}`}
                                                    />
                                                ) : (
                                                    <DetailsLayout.InfoField
                                                        label="Postfach des Leistungsanbieters"
                                                        value={`${data?.traeger?.adresse?.postfachBezeichnung}, ${data?.traeger?.adresse?.plz} ${data?.traeger?.adresse?.ort}`}
                                                    />
                                                )}
                                            </>
                                        ) : null}

                                        <DetailsLayout.DownloadLink
                                            label="Versorgungsvertraege"
                                            value={data.uploads as DokumentJsonld[]}
                                        />

                                        {data.mantelbogen && data.mantelbogen.length > 0 ? (
                                            <DetailsLayout.DownloadLink
                                                label="Mantelbogen"
                                                value={
                                                    data.mantelbogen[0].generiertesDokument
                                                        ? [data.mantelbogen[0].generiertesDokument as DokumentJsonld]
                                                        : null
                                                }
                                            />
                                        ) : null}
                                    </DetailsLayout.Group>
                                </DetailsLayout.Container>
                            </>
                        ) : null}
                    </AppLoaderContainer>

                    {data?.deleted ? (
                        <>
                            <StyledSubHeadline variant="h2">Löschung</StyledSubHeadline>

                            <DetailsLayout.Container divide={false}>
                                <DetailsLayout.InfoField value={data.deleted} label="Zeitpunkt der Löschung" isDate />
                                <DetailsLayout.InfoField
                                    isDate
                                    value={data.delete_confirmed}
                                    label="Zeitpunkt der Kenntnisnahme"
                                />
                                {data?.deletedByBenutzer ? (
                                    <DetailsLayout.InfoField
                                        value={`${data?.deletedByBenutzer?.nachname}, ${data?.deletedByBenutzer?.vorname} (${data?.deletedByBenutzer?.email})`}
                                        label="Gelöscht von"
                                    />
                                ) : null}
                                {data?.deleted_notice ? (
                                    <DetailsLayout.InfoField value={data.deleted_notice} label="Grund der Löschung" />
                                ) : null}
                            </DetailsLayout.Container>
                        </>
                    ) : null}
                </BaseGridContainer>

                <StyledDivider />

                <>
                    <Typography variant="h2">Meldungen</Typography>

                    <DataTableHydraServerside
                        loadingContext={isLoading}
                        getColumns={columns}
                        getData={getData}
                        persistSearchText
                        initialOptions={{
                            sortOrder: {
                                name: 'letzteMeldung',
                                direction: 'desc',
                            },
                            custom: {
                                pathBuilder: tablePathBuilder,
                            },
                        }}
                    />
                </>

                <ButtonGroupGrid>
                    {data?.deleted && !data?.delete_confirmed ? (
                        <FormLoadingButton
                            variant="contained"
                            color="success"
                            loading={isPersisting}
                            startIcon={!isPersisting ? <Done /> : null}
                            onClick={handleDeleteConfirm}
                        >
                            Löschung zur Kenntnis nehmen
                        </FormLoadingButton>
                    ) : null}

                    <Button component={Link} to={pathBuilder.path} variant="outlined">
                        Zur Übersicht
                    </Button>
                </ButtonGroupGrid>
            </StyledContainer>
        </ContentContainer>
    );
};

const StyledSubHeadline = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));
