export const vertraege_table = {
    type: 'SummaryTable',
    columns: 3,
    label: 'Verträge und Vereinbarungen',
    scope: '#/properties/vertraege',
    entries: [
        {
            type: 'FieldRow',
            label: 'Dokumente',
            scope: '#/properties/stepVertraegeDokumente',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizVertraege',
        },
    ],
};
