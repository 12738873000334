import React, { useEffect, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { FormButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { useMessageActions } from 'forms/state/useMessages';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';

export const ResendVerificationEmail: React.FC<{ email: string | null; renderLink?: boolean }> = ({
    email,
    renderLink,
}) => {
    const { closeMessage } = useMessageActions();
    const { setRequestMessage } = useFormState();

    const [messageId, setMessageId] = useState<string | null | undefined>(null);
    const [linkRequested, setLinkRequested] = useState<boolean>(false);

    const handleResendVerificationEmail = async () => {
        let errorText = 'Beim Senden des Verifizierungslink ist ein Fehler aufgetreten.';

        if (!email) {
            setMessageId(setRequestMessage(errorMessage(errorText)));
            return;
        }

        try {
            await backendApiService.resendVerificationEmail(email);
            setMessageId(
                setRequestMessage(
                    successMessage(`Es wurde erneut eine E-Mail mit einem Verifizierungslink an "${email}" gesendet.`)
                )
            );
            setLinkRequested(true);
        } catch (error: any) {
            if (error) {
                errorText = error.response.data?.detail;
            }

            setMessageId(setRequestMessage(errorMessage(errorText)));
        }
    };

    useEffect(() => {
        if (!messageId) return;

        return () => {
            closeMessage(messageId);
        };
    }, [messageId, closeMessage]);

    return (
        <FormButton
            type="button"
            variant={renderLink ? 'text' : 'contained'}
            color="primary"
            onClick={() => handleResendVerificationEmail()}
            disabled={linkRequested}
        >
            Link erneut anfordern
        </FormButton>
    );
};
