import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { MeldungAntrag } from 'pages/Meldung/Components/MeldungAntrag';

const Index = () => {
    return (
        <MeldungAntrag
            headline="Meldung: Freigeben"
            pathBuilder={PathBuilder.home.aufgaben.meldungen.ohneZustaendigkeit}
        />
    );
};

export const MeldungEditPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_OHNE_ZUSTAENDIGKEIT
);
