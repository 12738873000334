import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, BoxProps, Hidden, styled } from '@mui/material';

import { useIsLoggedIn } from 'api/auth';
import { SkipLinks } from 'components/SkipLinks';
import { useFormState } from 'forms/state/useFormState';
import { useMessageActions } from 'forms/state/useMessages';

import { Footer } from './Footer';
import { Header } from './Header';
import { useSideBar } from './hooks/useSideBar';
import { MessageStack } from './messages/MessageStack';
import { Sidebar } from './Sidebar';

interface StyledBoxProps extends BoxProps {
    id?: string;
}

const PageContainer = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding-top: ${theme.mixins.header.height}px;

    & .sr-only {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width:  1px !important;
        white-space: nowrap !important;
    },
`
);

const Content = styled(Box)<StyledBoxProps>(({ theme }) => ({
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${theme.mixins.header.height + theme.mixins.footer.height}px)`,
    [theme.breakpoints.up(`md`)]: {
        display: 'flex',
    },
}));

const MainContentWrapper = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    flex: 1 1 auto;
    padding: 6px 0 30px;
    position: relative;
    overflow: hidden;

    & > .MuiContainer-root {
        min-width: calc(100vw - (${theme.mixins.sidebar.width}px));
    }
`
);

const SidebarWrapper = styled(Box)<StyledBoxProps>(
    ({ theme }) => `
    background: ${theme.palette.common.white};
    border-right: 2px solid ${theme.palette.divider};
    min-width: ${theme.mixins.sidebar.width}px;
    width: ${theme.mixins.sidebar.width}px;
    `
);

const ContentWrapper = styled(Box)(
    () => `
    display: flex;
    flex-wrap: no-wrap;
`
);

export const Page: React.FC = () => {
    const { pathname } = useLocation();
    const { addMessage, clearMessages } = useMessageActions();
    const { requestMessage, setRequestMessage } = useFormState();

    const isLoggedIn = useIsLoggedIn();
    const { menus } = useSideBar();

    const pageContainerRef = useRef<HTMLElement | null>(null);
    const mainContentRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        pageContainerRef?.current?.focus();
        mainContentRef?.current?.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!requestMessage) return;
        addMessage(requestMessage);
    }, [addMessage, clearMessages, requestMessage, setRequestMessage]);

    return (
        <PageContainer id="PageContainer" ref={pageContainerRef}>
            <SkipLinks isLoggedIn={isLoggedIn} />

            <Header id="PageHeader" mobileMenus={menus} />

            <ContentWrapper>
                <Hidden lgDown>
                    {isLoggedIn && (
                        <SidebarWrapper id="SidebarWrapper" component={'aside'}>
                            <Sidebar menus={menus} />
                        </SidebarWrapper>
                    )}
                </Hidden>

                <Content id="Content" component="main" role="main">
                    <MainContentWrapper
                        id="Inhalt"
                        className={isLoggedIn ? 'ContentContainer--aside' : 'ContentContainer--default'}
                        ref={mainContentRef}
                    >
                        <MessageStack />
                        <Outlet />
                    </MainContentWrapper>
                </Content>
            </ContentWrapper>
            <Footer />
        </PageContainer>
    );
};
