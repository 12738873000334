import { useEffect, useState } from 'react';

import { useApiCheckChangeToken } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { SupportInfo } from 'elements/SupportInfo';
import { ContentContainer } from 'layout/container/ContentContainer';
import { ExceptionMessage } from 'pages/Profil/PasswortReset/components/ExceptionMessage';
import { FailureMessage } from 'pages/Profil/PasswortReset/components/FailureMessage';
import { PasswortResetForm } from 'pages/Profil/PasswortReset/components/PasswortResetForm';
import { SuccessMessage } from 'pages/Profil/PasswortReset/components/SuccessMessage';
import useQuery from 'utilities/hooks/useQuery';

export const PasswortResetPage = () => {
    const query = useQuery();
    const queryToken = query.get('t'); // token
    const securityToken = queryToken || '';
    const [status, setStatus] = useState<string>('initial');
    const { isLoading, error, data } = useApiCheckChangeToken(securityToken);

    useEffect(() => {
        switch (true) {
            case error?.response?.status === 500:
                setStatus('exception');
                break;

            case !!error:
                setStatus('error');
                break;
        }
    }, [error, data]);

    return (
        <ContentContainer title="Passwort zurücksetzen">
            <AppLoaderContainer isLoading={isLoading}>
                {status === 'initial' && <PasswortResetForm token={securityToken} stateHandler={setStatus} />}
                {status === 'error' && <FailureMessage />}
                {status === 'exception' && <ExceptionMessage />}
                {status === 'success' && <SuccessMessage />}

                <SupportInfo />
            </AppLoaderContainer>
        </ContentContainer>
    );
};
