import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AbwAnsehen } from 'pages/Verwaltung/ABW/components/AbwAnsehen';

const Index = () => {
    return <AbwAnsehen pathBuilder={PathBuilder.home.aufgaben.abw} />;
};

export const AbwAufgabenAnsehenPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN
);
