import { useMemo } from 'react';

import { FormState, InlineGroupRowControlType } from 'forms/types/UiSchemaTypes';
import { determineSchemaByUUID, getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export const useInlineGroupRowControl = (data: FormState, uischema: InlineGroupRowControlType, schema: any): any => {
    const { collectionScope, rowScope } = uischema;
    const collectionField = getFieldFromScope(collectionScope);
    const collectionData: { uuid: string; [key: string]: any }[] = data[collectionField];

    return useMemo(() => {
        if (!collectionData) {
            return {
                collectionData: [],
            };
        }

        const collectionSchema = getFieldSchemaByScope(collectionScope, schema);

        const firstSchema = determineSchemaByUUID(collectionData[0].uuid, collectionSchema);

        const fieldName = getFieldFromScope(rowScope);
        const fieldSchema = getFieldSchemaByScope(rowScope, firstSchema);
        const fieldLabel = fieldSchema?.title;
        const fieldFormula = fieldSchema?.custom?.formula;
        const isRequiredField = firstSchema?.required?.includes(fieldName);
        return {
            collectionField,
            collectionData,
            collectionSchema,
            fieldName,
            fieldLabel,
            fieldFormula,
            isRequiredField,
        };
    }, [collectionData, collectionField, collectionScope, rowScope, schema]);
};
