export const konzeptinformationen_table = {
    type: 'SummaryTable',
    label: 'Konzeptinformationen',
    columns: 3,
    scope: '#/properties/konzeptinformation',
    hideTableHead: true,
    entries: [
        {
            type: 'SubTable',
            level: 0,
            label: 'Liste der Schwerpunkte',
            scope: '#/properties/konzeptinformationen',
            showLabel: true,
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/customName',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/plaetze',
                },
            ],
        },
    ],
};
