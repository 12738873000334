import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Collapse, Grid } from '@mui/material';

import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { FormStateInfo } from './FormStateInfo';
import { usePasswordValidation } from './hooks/usePasswordValidation';
import { PasswordInput } from './PasswordInput';
import { PasswordStrengthList } from './PasswordStrengthList';

const PasswordValidationControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    handleChange,
    data,
    gridLayout,
    required,
    id,
    schema,
    uischema,
    hasErrors,
    errors,
}) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const requiredLength = 8;

    const [validLength, hasNumber, upperCase, lowerCase, specialChar, match, valid] = usePasswordValidation(
        password,
        confirmPassword,
        requiredLength
    );

    const inputLabel = useMemo(() => schema.title ?? 'Neues Passwort', [schema]);

    useEffect(() => {
        if (valid && data !== password) {
            handleChange(path, password);
        }
    }, [data, password, valid, path, handleChange]);

    return (
        <>
            <PasswordInput
                path={'password'}
                label={inputLabel}
                value={password}
                handleChange={setPassword}
                required={required}
                gridLayout={gridLayout}
                disabled={false}
                uischema={uischema}
                hasErrors={hasErrors}
                errors={errors}
            />

            <Collapse in={Boolean(password) || Boolean(confirmPassword)}>
                <PasswordStrengthList
                    label={'Ihr Passwort muss die folgenden Kriterien erfüllen:'}
                    requiredLength={requiredLength}
                    validLength={validLength}
                    hasNumber={hasNumber}
                    upperCase={upperCase}
                    lowerCase={lowerCase}
                    specialChar={specialChar}
                    gridLayout={gridLayout}
                />
            </Collapse>

            <PasswordInput
                id={id}
                path={'password-confirm'}
                label={`${inputLabel} wiederholen`}
                value={confirmPassword}
                handleChange={setConfirmPassword}
                required={required}
                gridLayout={gridLayout}
                disabled={!password}
                uischema={uischema}
            />

            {!match && confirmPassword && (
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={gridLayout?.label || 2} />
                    <Grid item xs={gridLayout?.input || 4}>
                        <FormStateInfo label={'Die Passwörter stimmen nicht überein.'} state={false} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export const PasswordValidationControl = withCustomControlProps(PasswordValidationControlComponent);
