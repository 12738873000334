import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import { AbwAufgabenAnsehenPage } from 'pages/Aufgaben/ABW/AbwAufgabenAnsehenPage';
import { AbwAufgabenBearbeitenPage } from 'pages/Aufgaben/ABW/AbwAufgabenBearbeitenPage';
import { AbwAufgabenLeistungsangebotPage } from 'pages/Aufgaben/ABW/AbwAufgabenLeistungsangebotPage';
import { AbwAufgabenManuelleZuordnungPage } from 'pages/Aufgaben/ABW/AbwAufgabenManuelleZuordnungPage';
import { AbwAufgabenOverview } from 'pages/Aufgaben/ABW/AbwAufgabenOverview';
import { AbwAufgabenTrefferZuordnenPage } from 'pages/Aufgaben/ABW/AbwAufgabenTrefferZuordnenPage';
import { DeletedObjectAnsehenPage } from 'pages/Aufgaben/DeletedObjects/DeletedObjectAnsehenPage';
import { DeletedObjectsOverviewPage } from 'pages/Aufgaben/DeletedObjects/DeletedObjectsOverviewPage';
import { LeistungsanbieterOverview } from 'pages/Leistungsanbieter';
import { LeistungsanbieterBearbeitenPage } from 'pages/Leistungsanbieter/LeistungsanbieterBearbeitenPage';
import { LeistungsanbieterDetailPage } from 'pages/Leistungsanbieter/LeistungsanbieterDetailPage';
import { LeistungsangeboteBehoerdeOverview } from 'pages/Leistungsangebote/behoerde/LeistungsangeboteBehoerdeOverview';
import { LeistungsangebotFreigebenPage } from 'pages/Leistungsangebote/LeistungsangebotFreigebenPage';
import { ZurMeldungAufgefordertOverview } from 'pages/Meldung/Aufgefordert/ZurMeldungAufgefordertOverview';
import { OhneMeldungOverview } from 'pages/Meldung/Leistungsangebote/OhneMeldungOverview';
import { MeldungFreigebenAntrag } from 'pages/Meldung/MeldungFreigebenAntrag';
import { MeldungFreigebenOverview } from 'pages/Meldung/MeldungFriegebenOverview';
import { MeldungZurueckgeben } from 'pages/Meldung/MeldungZurueckgeben';
import { MeldungDetail } from 'pages/Meldung/OhneZustaendigkeit/MeldungDetail';
import { MeldungEditPage } from 'pages/Meldung/OhneZustaendigkeit/MeldungEditPage';
import { MeldungOhneZustaendigkeit } from 'pages/Meldung/OhneZustaendigkeit/MeldungOhneZustaendigkeit';

import { LabMeldungenActionRoutes } from './Aufgaben/LabMeldungenActionRoutes';
import {
    PATH_ABW,
    PATH_ANTRAG,
    PATH_AUFGABEN,
    PATH_DELETED_OBJECTS,
    PATH_EDIT,
    PATH_LEISTUNGSANBIETER,
    PATH_LEISTUNGSANBIETER_FREIGEBEN,
    PATH_LEISTUNGSANBIETER_VIEW,
    PATH_LEISTUNGSANGEBOT_ERSTELLEN,
    PATH_LEISTUNGSANGEBOTE,
    PATH_LEISTUNGSANGEBOTE_FREIGEBEN,
    PATH_MELDUNG,
    PATH_MELDUNG_AUFGEFORDERT,
    PATH_MELDUNG_FREIGEBEN,
    PATH_MELDUNG_LABS_OHNE_MELDUNG,
    PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT,
    PATH_MELDUNG_ZURUECKGEBEN,
    PATH_VIEW,
    PATH_ZUORDNUNG_MANUELL,
    PATH_ZUORDNUNG_TREFFER,
    PathBuilder,
    useRedirectSegment,
} from './Paths';

export const RoutingAufgaben = () => {
    const { SentryRoutes } = useSentry();

    const {
        leistungsanbieterFirstStep,
        meldungFirstStep,
        meldungOhneZustaendigkeitFirstStep,
        leistungsangeboteFirstStep,
        abwLeistungsangeboteFirstStep,
    } = useRedirectSegment();

    return (
        <SentryRoutes>
            <Route path={PATH_AUFGABEN}>
                <Route path={PATH_LEISTUNGSANGEBOTE}>
                    <Route path={PATH_LEISTUNGSANGEBOTE_FREIGEBEN}>
                        <Route path=":antragId">
                            <Route path=":step" element={<LeistungsangebotFreigebenPage />} />
                            <Route index element={<Navigate to={leistungsangeboteFirstStep} replace />} />
                        </Route>

                        <Route index element={<LeistungsangeboteBehoerdeOverview />} />
                    </Route>
                </Route>

                <Route path={PATH_ABW}>
                    <Route path=":id">
                        <Route path={PATH_EDIT} element={<AbwAufgabenBearbeitenPage />} />
                        <Route path={PATH_ZUORDNUNG_MANUELL} element={<AbwAufgabenManuelleZuordnungPage />} />
                        <Route path={PATH_ZUORDNUNG_TREFFER} element={<AbwAufgabenTrefferZuordnenPage />} />

                        <Route path={PATH_LEISTUNGSANGEBOT_ERSTELLEN}>
                            <Route path=":antragId">
                                <Route path=":step" element={<AbwAufgabenLeistungsangebotPage />} />
                                <Route index element={<Navigate to={abwLeistungsangeboteFirstStep} replace />} />
                            </Route>
                            <Route index element={<Navigate to={PathBuilder.home.aufgaben.abw.path} replace />} />
                        </Route>

                        <Route index element={<AbwAufgabenAnsehenPage />} />
                    </Route>
                    <Route index element={<AbwAufgabenOverview />} />
                </Route>

                <Route path={PATH_LEISTUNGSANBIETER}>
                    <Route path={PATH_LEISTUNGSANBIETER_FREIGEBEN}>
                        <Route path=":antragId">
                            <Route path={PATH_LEISTUNGSANBIETER_VIEW} element={<LeistungsanbieterDetailPage />} />
                            <Route path=":step" element={<LeistungsanbieterBearbeitenPage />} />
                            <Route index element={<Navigate to={leistungsanbieterFirstStep} replace />} />
                        </Route>

                        <Route index element={<LeistungsanbieterOverview />} />
                    </Route>
                </Route>

                <Route path={PATH_MELDUNG}>
                    <Route path={PATH_MELDUNG_FREIGEBEN}>
                        <Route path=":antragId">
                            <Route path=":step" element={<MeldungFreigebenAntrag />} />
                            <Route index element={<Navigate to={meldungFirstStep} replace />} />
                        </Route>

                        <Route path=":einrichtungId">
                            <Route path={PATH_ANTRAG}>
                                <Route path=":antragId">
                                    <Route path=":step" element={<MeldungZurueckgeben />} />
                                    <Route index element={<Navigate to={PATH_MELDUNG_ZURUECKGEBEN} replace />} />
                                </Route>
                            </Route>
                        </Route>

                        <Route index element={<MeldungFreigebenOverview />} />
                    </Route>

                    <Route path={PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT}>
                        <Route path=":antragId">
                            <Route path={PATH_VIEW} element={<MeldungDetail />} />
                            <Route path=":step" element={<MeldungEditPage />} />
                            <Route index element={<Navigate to={meldungOhneZustaendigkeitFirstStep} replace />} />
                        </Route>

                        <Route index element={<MeldungOhneZustaendigkeit />} />
                    </Route>

                    <Route path={PATH_MELDUNG_AUFGEFORDERT}>
                        <Route
                            path="*"
                            element={
                                <LabMeldungenActionRoutes
                                    pathBuilder={PathBuilder.home.aufgaben.meldungen.aufgefordert}
                                />
                            }
                        />
                        <Route index element={<ZurMeldungAufgefordertOverview />} />
                    </Route>

                    <Route path={PATH_MELDUNG_LABS_OHNE_MELDUNG}>
                        <Route
                            path="*"
                            element={
                                <LabMeldungenActionRoutes
                                    pathBuilder={PathBuilder.home.aufgaben.meldungen.labsOhneMeldung}
                                />
                            }
                        />
                        <Route index element={<OhneMeldungOverview />} />
                    </Route>
                </Route>

                <Route path={PATH_DELETED_OBJECTS}>
                    <Route path=":art">
                        <Route path=":id">
                            <Route path={PATH_VIEW} element={<DeletedObjectAnsehenPage />} />
                        </Route>
                        <Route
                            index
                            element={<Navigate to={PathBuilder.home.aufgaben.deletedObjects.path} replace />}
                        />
                    </Route>
                    <Route index element={<DeletedObjectsOverviewPage />} />
                </Route>
            </Route>
        </SentryRoutes>
    );
};
