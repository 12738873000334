import { useEffect, useRef } from 'react';

import { backendApiService } from 'api/ApiService';
import { useIsLoggedIn } from 'api/auth';

export const useApiInterceptors = () => {
    const isLoggedIn = useIsLoggedIn();
    const interceptorId = useRef<number | null>(null);

    useEffect(() => {
        interceptorId.current = backendApiService.setResponseInterceptor(interceptorId.current);
    }, []);

    useEffect(() => {
        if (interceptorId !== null && !isLoggedIn) {
            backendApiService.removeResponseInterceptor(Number(interceptorId.current));
        }
    }, [isLoggedIn]);
};
