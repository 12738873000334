import React from 'react';
import { useParams } from 'react-router-dom';

import { meldungApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { AntragType } from 'constants/antragTypes';
import { useMeldungTabConfig } from 'forms/Meldung/configs/useMeldungTabConfig';
import { useFormState } from 'forms/state/useFormState';
import { PathBuilderProps } from 'navigation/Paths';
import { AntragPage } from 'pages/AntragPage';

interface MeldungAntragProps {
    headline: string;
    pathBuilder: PathBuilderProps;
    nextTabLabel?: boolean;
    layout?: {
        xs?: number;
        lg?: number;
    };
    showInfoHeader?: boolean;
}

export const MeldungAntrag = ({
    headline,
    pathBuilder,
    nextTabLabel,
    layout,
    showInfoHeader = false,
}: MeldungAntragProps) => {
    const { step } = useParams();
    const { einrichtungArt } = useFormState();

    const tabConfig = useMeldungTabConfig(step, einrichtungArt);

    return (
        <AntragPage
            headline={headline}
            api={meldungApi}
            pathBuilder={pathBuilder}
            tabConfig={tabConfig}
            title="Antrag zur Meldung"
            antragType={AntragType.MELDUNG}
            workflowFormItem={backendApiService.workflowMeldungItem.bind(backendApiService)}
            disableValidationOnSave
            layout={layout}
            nextTabLabel={nextTabLabel}
            showMeldungInfoHeader={showInfoHeader}
        />
    );
};
