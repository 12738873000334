import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { GetCollectionMeldung200Response } from 'api/client';
import { LabelOverrides } from 'components/DataTable/ActionDropdown/ActionDropdownAntrag';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeStatus } from 'components/DataTable/components/BadgeStatus';
import { MeldungenStatusFilter } from 'components/DataTable/filters/MeldungenStatusFilter';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDateTime } from 'components/DataTable/tableUtils';

export const useMeldungenColumns = (
    handleAction: AntragListAction<any>,
    labelOverrides?: LabelOverrides
): ((data: GetCollectionMeldung200Response | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction, labelOverrides });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwSchluessel',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'letzteMeldung',
                    label: 'Datum der Einreichung',
                    options: {
                        customBodyRender: (value) => getDateTime(value),
                        filter: false,
                    },
                },
                {
                    name: 'einrichtungName',
                    label: 'Name',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    options: {
                        filter: false,
                        sort: false,
                    },
                },
                {
                    name: 'art',
                    label: 'Art des Leistungsangebots',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'status',
                    label: 'Status',
                    options: {
                        customBodyRender: (value) => <BadgeStatus status={value} />,
                        ...MeldungenStatusFilter(),
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
