import React from 'react';
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useAuthUserRoles, useSpecificRole } from 'api/auth';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { DashboardAnbieter } from './anbieter';
import { DashboardBehoerde } from './behoerde';
import { DashboardBezReg } from './DashboardBezReg';
import { DashboardLV } from './DashboardLV';
import { DashboardMinisterium } from './DashboardMinisterium';

const Index: React.FC = () => {
    const { isAnbieter, isBehoerde, isMags, isLV, isBezirksregierung, isSupport, isMonitoring } = useSpecificRole();
    const roles = useAuthUserRoles();

    switch (true) {
        case isAnbieter:
            return <DashboardAnbieter />;
        case isBehoerde:
            return <DashboardBehoerde />;
        case isMags:
            return <DashboardMinisterium />;
        case isLV:
            return <DashboardLV />;
        case isBezirksregierung:
            return <DashboardBezReg />;
        case isSupport:
        case isMonitoring:
            return <Navigate to={PathBuilder.home.verwaltung.benutzer.path} replace />;
        default:
            return (
                <ContentContainer title="Startseite">
                    <Typography component={'h1'}>No Role matched: {roles.join(', ')}</Typography>
                </ContentContainer>
            );
    }
};

export const DashboardPage = withAuthorization(withSideBar(Index), [ROLES.USER]);
