import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { ApiLeistungsanbieterGetCollection200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';
import { STATUS } from 'constants/antragStatus';

export const useLeistungsanbieterListData = (
    status?: STATUS
): GetData<ApiLeistungsanbieterGetCollection200Response> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getLeistungsanbieterCollection({
                page,
                itemsPerPage: rowsPerPage,
                status,
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderEingereichtAt: sortBy(sortOrder, 'eingereichtAt'),
            });
        },
        [status]
    );
};
