import { CustomRule, GroupControlType, UiSchemaOptions, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const group = (
    elements: UiSchemaType[],
    options: {
        unstyled?: boolean;
        noSeparator?: boolean;
        headline?: {
            variant?: 'h3' | 'h4';
            inline?: boolean;
        };
        options?: UiSchemaOptions;
        rule?: CustomRule;
    } = {},
    label?: string,
    scope?: string,
    labelValue?: string,
    labelScope?: string
): GroupControlType => ({
    type: 'Group',
    ...(label && { label: `${label}` }),
    ...(options.rule && { rule: options.rule }),
    labelValue,
    labelScope,
    scope,
    elements,
    options,
});
