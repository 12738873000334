import { modalLeistungserbringer } from 'forms/Meldung/modalElements';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';

export const uiSchema = customVerticalLayout([
    group(
        [
            listControlWithModalForm(
                ['#/properties/stepLeistungserbringerLeistungserbringer'],
                [
                    tableColumn('Name', { scopes: ['#/properties/name'] }),
                    tableColumn('Adresse', { scopes: ['#/properties/adresse/properties/label'] }),
                    tableColumn('Ansprechpartner', { scope: '#/properties/ansprechpartner' }),
                    tableColumn('Telefon', { scope: '#/properties/telefon' }),
                    tableColumn('Fax', { scope: '#/properties/fax' }),
                    tableColumn('Mail', { scope: '#/properties/mail' }),
                ],
                ['Leistungserbringer hinzufügen'],
                ['Leistungserbringer löschen'],
                ['Wollen Sie diesen Leistungserbringer mit allen Angaben wirklich löschen?'],
                [modalLeistungserbringer],
                { types: ['Leistungserbringer'] }
            ),
        ],
        {
            noSeparator: true,
        }
    ),
    control('notizLeistungserbringer'),
]);
