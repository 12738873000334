import React from 'react';
import { Badge, BadgeProps, Grid, useTheme } from '@mui/material';

interface DashboardCardBadgeTitleProps {
    title: string;
    badgeProps?: BadgeProps;
}

export const DashboardCardBadgeTitle: React.FC<DashboardCardBadgeTitleProps> = ({ title, badgeProps }) => {
    const theme = useTheme();
    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={11}>
                {title}
            </Grid>

            {badgeProps ? (
                <Grid item xs={1} textAlign="right" paddingRight={theme.spacing(1)}>
                    <Badge color="primary" {...badgeProps} />
                </Grid>
            ) : null}
        </Grid>
    );
};
