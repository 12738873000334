import axios, { AxiosInstance } from 'axios';

import { Credentials, TokenApi, TokenRefreshApi } from './client';
import { Token } from './types';

export const BACKEND_API_BASE_URL = process.env.REACT_APP_API_URL as string;

export class ApiBaseService {
    public readonly client: AxiosInstance;
    protected jwtToken?: string;
    protected jwtRefreshToken?: string;

    constructor() {
        this.client = axios.create({
            baseURL: BACKEND_API_BASE_URL,
        });
    }

    public setJwtToken(jwtToken?: string, jwtRefreshToken?: string): void {
        this.jwtToken = jwtToken;
        this.jwtRefreshToken = jwtRefreshToken;

        if (jwtToken) {
            this.client.defaults.headers.common['X-Authorization'] = `Bearer ${jwtToken}`;
        } else {
            delete this.client.defaults.headers.common['X-Authorization'];
        }
    }

    public getToken(credentials: Credentials): Promise<Token> {
        return new TokenApi(undefined, '', this.client)
            .postCredentialsItem(credentials)
            .then((res) => res.data as Token);
    }

    public getRefreshToken(): Promise<Token> {
        return new TokenRefreshApi(undefined, '', this.client)
            .postRefreshTokenItem({ refresh_token: this.jwtRefreshToken })
            .then((res) => res.data as Token);
    }
}
