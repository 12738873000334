import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import {
    PATH_ANTRAG,
    PATH_DELETE,
    PATH_EDIT,
    PATH_NACHRICHTEN,
    PATH_VIEW,
    PATH_ZUGRIFFSRECHTE,
    PathBuilderProps,
} from 'navigation/Paths';
import { LabCreateEditPage } from 'pages/Meldung/Leistungsangebote/Edit/LabCreateEditPage';
import { LabEditPage } from 'pages/Meldung/Leistungsangebote/Edit/LabEditPage';
import { LabMeldungAufforderung } from 'pages/Meldung/Leistungsangebote/LabMeldungAufforderung';
import { LabMeldungDeletionReason } from 'pages/Meldung/Leistungsangebote/LabMeldungDeletionReasonPage';
import { LabZugriffsrechtePage } from 'pages/Meldung/Leistungsangebote/LabZugriffsrechtePage';
import { LeistungsangebotDetailPage } from 'pages/Meldung/Leistungsangebote/LeistungsangebotDetailPage';
import { NachrichtCreatePage } from 'pages/Meldung/Leistungsangebote/Nachrichten/NachrichtCreatePage';
import { NachrichtDetailsPage } from 'pages/Meldung/Leistungsangebote/Nachrichten/NachrichtDetailsPage';
import { NachrichtenOverview } from 'pages/Meldung/Leistungsangebote/Nachrichten/NachrichtenOverview';

export const LabMeldungenActionRoutes = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    const { SentryRoutes } = useSentry();
    return (
        <SentryRoutes>
            <Route path=":einrichtungId">
                <Route path={PATH_VIEW} element={<LeistungsangebotDetailPage pathBuilder={pathBuilder} />} />

                <Route path={PATH_EDIT}>
                    <Route path={PATH_ANTRAG}>
                        <Route path=":antragId">
                            <Route path=":step" element={<LabEditPage pathBuilder={pathBuilder} />} />
                            <Route index element={<Navigate to="basisdaten" replace />} />
                        </Route>
                        <Route index element={<Navigate to={String(pathBuilder.path)} replace />} />
                    </Route>

                    <Route index element={<LabCreateEditPage pathBuilder={pathBuilder} />} />
                </Route>

                <Route path={PATH_NACHRICHTEN}>
                    <Route path=":id">
                        <Route path=":step" element={<NachrichtCreatePage pathBuilder={pathBuilder} />} />
                        <Route index element={<NachrichtDetailsPage pathBuilder={pathBuilder} />} />
                    </Route>

                    <Route index element={<NachrichtenOverview pathBuilder={pathBuilder} />} />
                </Route>

                <Route path={PATH_ZUGRIFFSRECHTE} element={<LabZugriffsrechtePage pathBuilder={pathBuilder} />} />

                <Route path={PATH_ANTRAG}>
                    <Route path=":antragId">
                        <Route path=":step" element={<LabMeldungAufforderung pathBuilder={pathBuilder} />} />
                    </Route>
                </Route>

                <Route path={PATH_DELETE} element={<LabMeldungDeletionReason pathBuilder={pathBuilder} />} />
            </Route>
        </SentryRoutes>
    );
};
