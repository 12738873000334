import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { TraegerDetail } from './TraegerDetail';

const Index = () => {
    return <TraegerDetail pathBuilder={PathBuilder.home.verwaltung.traeger} />;
};

export const TraegerDetailPage = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_TRAEGER);
