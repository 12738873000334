import React, { ReactNode, useState } from 'react';
import { LiveMessage } from 'react-aria-live';
import { Box, CircularProgress, CircularProgressProps, Grid, styled } from '@mui/material';

import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

interface AppLoaderBaseProps {
    isLoading: boolean | undefined;
    minHeight?: number;
    children?: ReactNode;
}

export const LoaderApp = (props: CircularProgressProps) => <CircularProgress {...props} aria-label="Wird geladen..." />;

export const AppLoaderContainer = ({ isLoading, minHeight, children }: AppLoaderBaseProps) => {
    const [loadingMessage, setLoadingMessage] = useState<string>('');

    useDebouncedEffect(
        () => {
            setLoadingMessage(!isLoading ? 'Allgemeine Daten wurden geladen.' : 'Allgemeine Daten werden geladen.');
        },
        [isLoading],
        500
    );

    return (
        <>
            <LiveMessage message={loadingMessage} aria-live="polite" clearOnUnmount="true" />

            {!isLoading ? (
                <>{children}</>
            ) : (
                <Grid
                    container
                    justifyContent={'center'}
                    data-cy={'AppLoaderContainer'}
                    style={{
                        marginTop: '24px',
                        minHeight,
                    }}
                >
                    <Grid item>
                        <LoaderApp />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export const AppLoaderOverlay = ({ isLoading, children }: AppLoaderBaseProps) => {
    if (!isLoading) {
        return <>{children}</>;
    }

    return (
        <OverlayWrapper>
            <OverlayContainer data-cy="OverlayContainer">
                <LoaderApp />
            </OverlayContainer>

            {children}
        </OverlayWrapper>
    );
};

const OverlayWrapper = styled(Box)(() => ({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
}));

const OverlayContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: `rgba(255, 255, 255, 0.75)`,
    paddingTop: '30%',
    zIndex: theme.zIndex.modal,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
}));
