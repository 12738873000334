import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { GetEinrichtungenForFem200Response } from 'api/client';
import { AntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { EinrichtungArtFilterOptions } from 'components/DataTable/filters/EinrichtungArtFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDate, getDateTime } from 'components/DataTable/tableUtils';

export const useRegelpruefungEinrichtungColumns = (
    handleAction: AntragListAction<any>,
    filterOptions: AntragTableFilterOptions | undefined
): ((data: GetEinrichtungenForFem200Response | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwSchluessel',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'einrichtungsart',
                    label: 'Leistungsart',
                    options: {
                        ...EinrichtungArtFilterOptions(filterOptions?.leistungsart || {}),
                    },
                },
                {
                    name: 'name',
                    label: 'Name der Einrichtung',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'letzteRegelpruefung',
                    label: 'Letzte Regelprüfung',
                    options: {
                        filter: false,
                        customBodyRender: (value) => getDate(value),
                    },
                },
                {
                    name: 'letzterBericht',
                    label: 'Letzter Prüfbericht',
                    options: {
                        filter: false,
                        customBodyRender: (value) => getDateTime(value),
                    },
                },
                actions(data, 'name'),
            ];
        },
        [actions, filterOptions?.leistungsart]
    );
};
