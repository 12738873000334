import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ROLE_TYPES, ROLES } from 'constants/roles';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { menuAuswertung } from 'navigation/menuConfigs/menuAuswertung';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { BenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

export interface MenuInterface {
    path?: string;
    name: string;
    divide?: boolean;
    collapse?: boolean;
    roles?: string[];
    menus?: MenuInterface[];
    disabled?: boolean;
    expanded?: boolean;
    aufgabenBadge?: Array<keyof BenutzerAufgaben>;
    tabs?: MenuInterface[];
    padded?: boolean;
    fullWidth?: boolean;
}

export interface Step<T> {
    title: string;
    headline?: string;
    state: 'active' | 'disabled';
    step: T;
    actions?: ACTIONS[];
    children?: Step<T>[];
    component?: React.ReactElement;
}

export interface Area {
    name: string;
    expanded?: boolean;
    aufgabenBadge?: Array<keyof BenutzerAufgaben>;
    menu?: MenuInterface[];
    path?: string;
    roles: string[];
    aria?: {
        label: string;
    };
}

export const menuNachrichten: Area[] = [
    {
        path: PathBuilder.home.nachrichten.path,
        name: 'Benachrichtigungen',
        aufgabenBadge: ['ungeleseneNachrichten'],
        roles: ROLE_TYPES.ROLLEN_BENACHRICHTIGUNGEN,
        aria: {
            label: 'Neue Benachrichtigungen:',
        },
    },
];

/**
 * HeaderConfig
 * Konfiguration von Menüelementen in der MainNavigation
 *
 * @example
 *
 * areas: [
 *         {
 *             name: 'Einrichtungen',
 *             roles: [ROLES.USER],
 *         },
 *     ],
 */
export const HeaderConfig: { areas: Area[] } = {
    areas: [
        {
            name: 'Startseite',
            path: PathBuilder.home.dashboard.path,
            roles: [ROLES.USER],
        },
        ...menuNachrichten,
        {
            name: 'Aufgaben',
            aufgabenBadge: [
                'geloeschteObjekte',
                'leistungsanbieterFreigeben',
                'leistungsangebotFreigeben',
                'leistungsangebotOhneMeldung',
                'meldungFreigeben',
                'meldungOhneZustaendigkeit',
                'wohngemeinschaftZuordnen',
                'zurMeldungAufgefordert',
            ],
            menu: menuAufgaben,
            roles: ROLE_TYPES.ROLLEN_LEISTUNGSANBIETER_FREIGEBEN,
        },
        {
            name: 'Verwaltung',
            menu: menuVerwaltung,
            roles: [ROLES.USER],
        },
        {
            name: 'Auswertung',
            menu: menuAuswertung,
            roles: ROLE_TYPES.ROLLEN_AUSWERTUNG,
        },
    ],
};
