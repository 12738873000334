import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import {
    PATH_PLATZZAHLMELDER,
    PATH_PLATZZAHLMELDER_ANTRAG,
    PATH_PLATZZAHLMELDER_HISTORY,
    PATH_PLATZZAHLMELDER_MELDUNG,
    PATH_PLATZZAHLMELDER_VIEW,
    PathBuilder,
    useRedirectSegment,
} from 'navigation/Paths';
import { Error404Page } from 'pages/Error404';
import { PlatzmelderAufforderungPage } from 'pages/Platzmelder/PlatzmelderAufforderungPage';
import { PlatzmelderDetailPage } from 'pages/Platzmelder/PlatzmelderDetailPage';
import { PlatzmelderHistoryPage } from 'pages/Platzmelder/PlatzmelderHistoryPage';
import { PlatzmelderPage } from 'pages/Platzmelder/PlatzmlederPage';

export const PlatzmelderRoutes = () => {
    const { SentryRoutes } = useSentry();
    const { platzmelderFirstStep, platzmelderAufforderungFirstStep } = useRedirectSegment();

    return (
        <SentryRoutes>
            <Route path={PATH_PLATZZAHLMELDER}>
                <Route path=":token">
                    <Route path={PATH_PLATZZAHLMELDER_MELDUNG}>
                        <Route path=":id">
                            <Route path={PATH_PLATZZAHLMELDER_VIEW} element={<PlatzmelderDetailPage />} />
                            <Route index element={<Navigate to={platzmelderFirstStep} replace />} />
                        </Route>

                        <Route index element={<Navigate to={PathBuilder.home.verwaltung.platzmelder.path} replace />} />
                    </Route>

                    <Route path={PATH_PLATZZAHLMELDER_ANTRAG}>
                        <Route path=":id">
                            <Route path=":step" element={<PlatzmelderAufforderungPage />} />
                            <Route index element={<Navigate to={platzmelderAufforderungFirstStep} replace />} />
                        </Route>
                    </Route>

                    <Route path={PATH_PLATZZAHLMELDER_HISTORY}>
                        <Route path=":id" element={<PlatzmelderHistoryPage />} />
                    </Route>

                    <Route index element={<PlatzmelderPage />} />
                </Route>

                <Route index element={<PlatzmelderPage />} />

                <Route path="*" element={<Error404Page />} />
            </Route>
        </SentryRoutes>
    );
};
