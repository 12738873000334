import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useLandschaftsverbandItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { BenutzerTable } from 'pages/Benutzer/component/BenutzerTable';

const Index = () => {
    const { id } = useParams();
    const { data, isLoading, error } = useLandschaftsverbandItem(String(id));

    return (
        <ContentContainer title={`Kreis / kreisfreie Stadt: ${data?.name}`}>
            <StyledContainer>
                <PageHeading title={`${data?.name}`} />

                <AppLoaderContainer isLoading={isLoading}>
                    <BaseGridContainer lg={10}>
                        {!error ? (
                            <DetailsLayout.Container>
                                <DetailsLayout.InfoField value={data?.adressDaten?.label} label="Hausanschrift" />
                                <DetailsLayout.InfoField value={data?.ansprechpartner} label="Ansprechpartner" />
                                <DetailsLayout.InfoField value={data?.email} label="E-Mail-Adresse" />
                                <DetailsLayout.InfoField value={data?.telefon} label="Telefon" />
                                <DetailsLayout.InfoField value={data?.fax} label="Fax" />
                            </DetailsLayout.Container>
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </BaseGridContainer>

                    <BaseGridContainer>
                        <Typography variant="h2">Zugeordnete Benutzer</Typography>

                        <BenutzerTable
                            getDataPromise={backendApiService.getBenutzerListCollection.bind(backendApiService)}
                            pathBuilder={PathBuilder.home.verwaltung.benutzer}
                            dataOptions={{ landschaftsverband: id }}
                            tableOptions={{
                                filter: true,
                                custom: {
                                    pathBuilder: PathBuilder.home.verwaltung.benutzer,
                                },
                            }}
                        />
                    </BaseGridContainer>

                    <ButtonGroupGrid backLink={{ path: PathBuilder.home.verwaltung.landschaftsverband.path }} />
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const LandschaftsverbandDetailPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LANDSCHAFTSVERBAND
);
