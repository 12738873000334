import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { RegelpruefungPruefberichtResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const usePruefberichtData = (): GetData<RegelpruefungPruefberichtResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getRegelpruefungenPruefberichteCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText,
            orderPruefDatum: sortBy(sortOrder, 'pruefDatum'),
            orderLetzteNachpruefung: sortBy(sortOrder, 'letzteNachpruefung'),
            orderEinrichtungBehoerdeName: sortBy(sortOrder, 'behoerde'),
            orderEinrichtungName: sortBy(sortOrder, 'leistungsangebot'),
            hasPruefbericht: String(true),
            jahr: filters?.['jahr']?.[0],
            einrichtungBehoerde: filters?.['einrichtungBehoerde']?.[0],
        });
    }, []);
};
