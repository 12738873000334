import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { TraegerverbandData } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { schema, uiSchema } from 'pages/Traegerverband/formConfig';

const initialFormData = {
    adressDaten: undefined,
    anschriftTyp: undefined,
    ansprechpartner: undefined,
    email: undefined,
    fax: '',
    name: undefined,
    postfachBezeichnung: undefined,
    postfachOrt: undefined,
    postfachPlz: undefined,
    telefon: undefined,
};

const Index = () => {
    const navigate = useNavigate();

    const pathBuilder = PathBuilder.home.verwaltung.traegerverband.path;

    const handleSubmit = (formData: TraegerverbandData) => {
        return backendApiService.postTraegerverbandItem(formData);
    };

    return (
        <ContentContainer title="Trägerverband erstellen">
            <StyledContainer>
                <Typography variant="h1">Trägerverband erstellen</Typography>

                <SimpleForm
                    data={initialFormData}
                    schema={schema}
                    uiSchema={uiSchema}
                    submitLabel="Trägerverband erstellen"
                    onSubmit={handleSubmit}
                    onAfterSubmit={() => navigate(pathBuilder)}
                    messages={{
                        success: 'Trägerverband wurde erstellt.',
                        error: 'Trägerverband konnte nicht erstellt werden.',
                    }}
                    backLink={{
                        title: 'Abbrechen',
                        path: pathBuilder,
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const TraegerverbandCreatePage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_TRAEGERVERBAENDE_FORM
);
