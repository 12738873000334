import { rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

export const isMehrfachUpload: Tester = (uischema: UISchemaElement | any, schema: JsonSchema | any): boolean => {
    if (!(uischema.type === 'Control' || uischema.type === 'object')) return false;

    const field = getFieldSchemaByScope(uischema.scope, schema);

    const data = ((field as any)?.custom?.block_prefixes || []) as Array<string>;

    return data.includes('uploads');
};

export const MehrfachUploadTester = rankWith(15000, isMehrfachUpload);
