import { CollectionGroupsType, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const collectionGroups = (
    field: string,
    entries: UiSchemaType[],
    label?: string,
    labelScope?: string,
    labelValue?: string
): CollectionGroupsType => {
    return {
        type: 'CollectionGroups',
        scope: `#/properties/${field}`,
        entries,
        label,
        labelScope,
        labelValue,
    };
};
