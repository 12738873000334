import React from 'react';
import { JsonForms } from '@jsonforms/react';
import { Delete } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, useTheme } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useDisabled } from 'forms/hooks/useDisabled';
import { useErrors } from 'forms/hooks/useErrors';
import { renderers } from 'forms/renderers';
import { FormStateChange, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { ErrorAlert } from './ErrorAlert';

interface SubFormCardProps {
    onClose?: () => void;
    schema: Schema;
    uiSchema: UiSchemaType;
    config: any;
    data: any;
    onChange: (state: FormStateChange) => void;
}

export const SubFormCard: React.FC<SubFormCardProps> = ({ onClose, schema, uiSchema, data, onChange, config }) => {
    const theme = useTheme();
    const { hasErrors, errors } = useErrors({ config });
    const disabled = useDisabled();

    return (
        <Grid container spacing={1}>
            {hasErrors && (
                <Grid item xs={12}>
                    <ErrorAlert errors={errors} />
                </Grid>
            )}
            <Grid item xs={12}>
                <AppLoaderContainer isLoading={!schema?.properties}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: theme.spacing(2),
                        }}
                    >
                        <JsonForms
                            schema={schema}
                            uischema={uiSchema}
                            data={data}
                            renderers={renderers}
                            config={config}
                            onChange={onChange}
                        />

                        {onClose ? (
                            <>
                                <Tooltip title="Entfernen">
                                    <IconButton
                                        onClick={onClose}
                                        size={'small'}
                                        disabled={disabled}
                                        data-cy={'SubForm-close'}
                                        color="primary"
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : null}
                    </Box>
                </AppLoaderContainer>
            </Grid>
        </Grid>
    );
};
