import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetCollectionMeldung200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export interface UseMeldungListDataProps {
    einrichtungNeedsUpdate?: boolean;
    keinZustaendigerBenutzer?: boolean;
}

export const useMeldungAufgabenListData = ({
    einrichtungNeedsUpdate,
    keinZustaendigerBenutzer,
}: UseMeldungListDataProps): GetData<GetCollectionMeldung200Response> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getMeldungAufgabeCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                orderEinrichtungNrwKey: sortBy(sortOrder, 'nrwSchluessel'),
                orderZuletztEingereichtAt: sortBy(sortOrder, 'letzteMeldung'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtungName'),
                orderEinrichtungAdresseOrt: sortBy(sortOrder, 'ort'),
                orderEinrichtungArt: sortBy(sortOrder, 'art'),
                orderStatus: sortBy(sortOrder, 'status'),
                orderModifiedAt: sortBy(sortOrder, 'modifiedAt'),
            });
        },
        [einrichtungNeedsUpdate, keinZustaendigerBenutzer]
    );
};
