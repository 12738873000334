import * as React from 'react';
import { useMemo } from 'react';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { useReadonly } from 'forms/hooks/useReadonly';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { EventToDataFunction, useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { useFormState } from 'forms/state/useFormState';

import { FlexDiv } from './components/FlexDiv';
import { ReadonlyValueText } from './components/ReadonlyValueText';
import { RowControl } from './RowControl';

const eventToData =
    (noBlank?: boolean): EventToDataFunction<string | undefined> =>
    (e) => {
        const newValue = noBlank ? e.target.value?.trimStart() : e.target.value;
        return newValue === '' ? undefined : newValue;
    };

const TextControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    readonly,
    disabled,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    onTouched,
    schema,
    id,
    enabled,
}) => {
    const { formSubmitted } = useFormState();

    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        eventToData(required),
        onTouched,
        {
            validateOnBlur: false,
            validateOnChange: formSubmitted && hasErrors,
        }
    );

    const { scrollRef } = useScrollInView<HTMLDivElement>({});
    const labelVisuallyHidden = useMemo(() => uischema.labelVisuallyHidden ?? true, [uischema]);

    const isEditable = useReadonly(schema, config);
    const isReadonly = useMemo(
        () => uischema.options?.readonly || isEditable || readonly,
        [isEditable, readonly, uischema]
    );

    const isFieldEnabled = useMemo(() => enabled !== undefined && enabled, [enabled]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            hasChangedData={false}
            labelForId={id}
            labelVisuallyHidden={labelVisuallyHidden}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly && !schema?.custom?.disabled ? (
                        <ReadonlyValueText hasErrors={hasErrors} errors={errors} label={label} text={tempData ?? '-'} />
                    ) : (
                        <FormControl fullWidth>
                            <TextField
                                id={id}
                                fullWidth
                                required={required}
                                aria-readonly={!isFieldEnabled || isReadonly}
                                disabled={!isFieldEnabled || isReadonly}
                                value={tempData ?? ''}
                                error={hasErrors}
                                onBlur={(event) => {
                                    handleOnBlur(event);
                                }}
                                onChange={handleValueChange}
                                ref={scrollRef}
                                sx={{
                                    cursor: isReadonly ? 'not-allowed' : 'default',
                                }}
                                autoFocus={
                                    uischema.options?.focus ||
                                    (uischema.options?.firstControl && config.autoFocusFirstControl)
                                }
                                InputProps={{
                                    endAdornment: uischema?.endAdornment ? (
                                        <InputAdornment position="end">{uischema.endAdornment}</InputAdornment>
                                    ) : null,
                                }}
                                inputProps={{
                                    disabled: disabled || !isFieldEnabled || isReadonly,
                                    readOnly: isReadonly,
                                    'data-cy': `form_${path}`,
                                }}
                                placeholder={labelVisuallyHidden ? '' : label}
                                label={labelVisuallyHidden ? label : ''}
                            />

                            {schema.description && <FormHelperText>{schema.description}</FormHelperText>}

                            {hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const TextControl = withCustomControlProps(TextControlComponent);
