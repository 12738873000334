import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { useApiMeldungHinweistextItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ROLE_TYPES } from 'constants/roles';
import { translateEinrichtungArtList } from 'forms/utilities/SchemaUtils';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const Index = () => {
    const { id } = useParams();
    const { data, isLoading, error } = useApiMeldungHinweistextItem(id);

    return (
        <ContentContainer title="Anforderungstext ansehen">
            <StyledContainer>
                <Typography variant="h1">Anforderungstext ansehen</Typography>

                <BaseGridContainer lg={10} justifyContent="center">
                    <AppLoaderContainer isLoading={isLoading}>
                        {!error ? (
                            <DetailsLayout.Container divide={false}>
                                <DetailsLayout.InfoField value={data?.ueberschrift} label="Überschrift" />
                                <DetailsLayout.Group>
                                    <DetailsLayout.InfoField value={data?.inhalt} label="Text" />
                                </DetailsLayout.Group>
                                <DetailsLayout.InfoField
                                    value={translateEinrichtungArtList(data?.einrichtungsart) || 'Keine Einschränkung'}
                                    label="Art des Leistungsangebots"
                                />
                            </DetailsLayout.Container>
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </AppLoaderContainer>
                </BaseGridContainer>

                <StyledDivider />

                <ButtonGroupGrid backLink={{ path: PathBuilder.home.verwaltung.meldungHinweise.path }} />
            </StyledContainer>
        </ContentContainer>
    );
};

export const MeldungHinweisTextAnsehenPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT
);
