import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group([
        control('mandant'),
        control('einrichtung'),
        control('traegerverband', { options: { valueType: 'number' } }),
        control('traeger'),
        control('bezirksregierung'),
        control('landschaftsverband'),
        control('behoerde', { options: { valueType: 'number' } }),
    ]),
    group([
        control('anrede'),
        control('titel'),
        inlineRowControl([control('nachname'), control('vorname')]),
        inlineRowControl([control('telefon'), control('fax')]),
        control('email'),
    ]),
    group([control('rechte'), control('aktivierung')]),
    control('zugehoerigeEinrichtungen'),
]);
