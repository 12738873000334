import { useEffect, useState } from 'react';

import { authLog } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';
import { sessionService } from 'api/SessionService';

type UseSessionRecoveryResult = {
    isRecovering: boolean;
};

/**
 * Hook to retrieve the token in case of a fresh browser window or tab.
 */
export const useSessionRecovery = (): UseSessionRecoveryResult => {
    const [isRecovering, setRecovering] = useState(true);
    const login = useAuthState(({ login }) => login);

    /**
     * Runs every first render of the application.
     * Tries to retrieve a token from the backend and logs the user in, if it exists.
     */
    useEffect(() => {
        if (!isRecovering) return;
        authLog('[UseSessionRecovery.RecoverSession]');
        sessionService
            .getToken()
            .then(({ token, refresh_token }) => {
                if (token && refresh_token) login({ token, refresh_token });
            })
            .catch((e) => authLog('[UseSessionRecovery.RecoveryFailed]', e))
            .finally(() => setRecovering(false));
    }, [isRecovering, login]);

    return { isRecovering };
};
