import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useSpecificRole } from 'api/auth/useHasRole';
import { useApiTraegerverbandItem } from 'api/hooks/useApiClient';
import { AnschriftType } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { BenutzerTable } from 'pages/Benutzer/component/BenutzerTable';

const Index = () => {
    const { id } = useParams();

    const { isLV } = useSpecificRole();

    const { isLoading, data, error } = useApiTraegerverbandItem(String(id));

    const isHausanschrift = useMemo(() => data?.anschriftTyp === AnschriftType.HAUSANSCHRIFT, [data]);
    const isPostfach = useMemo(() => data?.anschriftTyp === AnschriftType.POSTFACH, [data]);

    return (
        <ContentContainer title={'Trägerverband ansehen'}>
            <StyledContainer>
                <Typography variant="h1">Trägerverband ansehen</Typography>

                <AppLoaderContainer isLoading={isLoading}>
                    <BaseGridContainer lg={10} justifyContent="center">
                        {!error ? (
                            <DetailsLayout.Container divide={false}>
                                <DetailsLayout.InfoField value={data?.name} label="Name des Verbands" />
                                {isPostfach || isHausanschrift ? (
                                    <>
                                        {isPostfach ? (
                                            <>
                                                <DetailsLayout.InfoField
                                                    label="Postfach"
                                                    value={data?.postfachBezeichnung}
                                                />
                                                <DetailsLayout.InfoField
                                                    label="PLZ und Ort"
                                                    value={`${data?.postfachPlz} ${data?.postfachOrt}`}
                                                />
                                            </>
                                        ) : (
                                            <DetailsLayout.InfoField
                                                label="Hausanschrift"
                                                value={data?.adressDaten?.label}
                                            />
                                        )}
                                    </>
                                ) : null}
                                <DetailsLayout.InfoField value={data?.ansprechpartner} label="Ansprechpartner" />
                                <DetailsLayout.InfoField value={data?.email} label="E-Mail-Adresse" />
                                <DetailsLayout.InfoField value={data?.telefon} label="Telefon" />
                                <DetailsLayout.InfoField value={data?.fax} label="Fax" />
                            </DetailsLayout.Container>
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </BaseGridContainer>
                </AppLoaderContainer>

                <StyledDivider />

                {!isLV ? (
                    <BaseGridContainer>
                        <BenutzerTable
                            title="Zugeordnete Benutzer"
                            titleVariant="h2"
                            getDataPromise={backendApiService.getBenutzerListCollection.bind(backendApiService)}
                            pathBuilder={PathBuilder.home.verwaltung.benutzer}
                            tableOptions={{
                                filter: true,
                            }}
                            dataOptions={{
                                traegerverband: id,
                            }}
                        />
                    </BaseGridContainer>
                ) : null}

                <ButtonGroupGrid
                    backLink={{ path: PathBuilder.home.verwaltung.traegerverband.path, title: 'Zu den Verbänden' }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const TraegerverbandDetailPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_TRAEGERVERBAENDE
);
