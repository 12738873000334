import React from 'react';

import { useSpecificRole } from 'api/auth/useHasRole';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { AbwBearbeiten } from './components/AbwBearbeiten';
import { adSchemaEdit, adUiSchemaEdit, defaultSchemaEdit, defaultUiSchemaEdit } from './formConfig';

const Index = () => {
    const { isAmbulanterDienst } = useSpecificRole();

    switch (true) {
        case isAmbulanterDienst:
            return (
                <AbwBearbeiten
                    schema={adSchemaEdit}
                    uiSchema={adUiSchemaEdit}
                    pathBuilder={PathBuilder.home.verwaltung.abw}
                />
            );
        default:
            return (
                <AbwBearbeiten
                    schema={defaultSchemaEdit}
                    uiSchema={defaultUiSchemaEdit}
                    pathBuilder={PathBuilder.home.verwaltung.abw}
                />
            );
    }
};

export const AbwEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN
);
