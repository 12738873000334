import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';

export const behoerdeUiSchema = customVerticalLayout([
    control('name', { options: { readonly: true } }),
    control('verantwortlicheBehoerde'),
    control('adressDaten'),
    control('ansprechpartner'),
    control('telefon'),
    control('fax'),
    control('email'),
]);
