import { MUIDataTableColumnOptions } from 'mui-datatables';

import { EINRICHTUNG_ART_FILTER_LABELS } from 'constants/labels';

import { useCheckboxFilterList } from './useCheckboxFilterList';

export const EinrichtungArtFilterOptions: (filterList: Record<string, string>) => MUIDataTableColumnOptions = (
    filterList: Record<string, string>
) => {
    return useCheckboxFilterList(filterList ?? EINRICHTUNG_ART_FILTER_LABELS);
};
