import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEqual, isFinite } from 'lodash';
import { MUIDataTableOptions, MUISortOptions } from 'mui-datatables';

import { TableFilters } from 'components/DataTable/hooks/useTableFilters';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';
import { parseFiltersFromUrl } from 'utilities/Utils';

export const useTableUrlParams = (
    filters: TableFilters,
    sortOrder: MUISortOptions | undefined,
    searchText: string,
    page: number,
    rowsPerPage: number,
    updateFilters: (filters: TableFilters) => void,
    updateSortOrder: (sortOrder: MUISortOptions | undefined) => void,
    updateSearchText: (searchText: string) => void,
    updatePage: (page: number) => void,
    updateRowsPerPage: (rowsPerPage: number) => void,
    initialTableOptions: MUIDataTableOptions | undefined,
    initialTableFilters: TableFilters
) => {
    const [params, setParams] = useSearchParams();
    const [defaultFilters] = useState(initialTableFilters);
    const [defaultOptions] = useState(initialTableOptions ?? {});
    const { initialFilters, initialSortOrder, initialSearchText, initialPage, initialRowsPerPage } =
        useInitialParamsFromUrl(params);

    useDebouncedEffect(
        () => {
            const nextSearchParams = convertParams(
                filters,
                sortOrder,
                searchText,
                page,
                rowsPerPage,
                defaultOptions,
                defaultFilters
            );
            const existingParams = getExistingParams(params);
            setParams({ ...nextSearchParams, ...existingParams }, { replace: true });
        },
        [filters, sortOrder, searchText, page, rowsPerPage, defaultOptions, defaultFilters, params, setParams],
        500
    );

    useEffect(() => {
        if (initialFilters !== undefined) updateFilters(initialFilters);
    }, [updateFilters, initialFilters]);

    useEffect(() => {
        if (initialSortOrder !== undefined) updateSortOrder(initialSortOrder);
    }, [updateSortOrder, initialSortOrder]);

    useEffect(() => {
        if (initialSearchText !== undefined) updateSearchText(initialSearchText);
    }, [updateSearchText, initialSearchText]);

    useEffect(() => {
        if (initialPage !== undefined) updatePage(initialPage);
    }, [updatePage, initialPage]);

    useEffect(() => {
        if (initialRowsPerPage !== undefined) updateRowsPerPage(initialRowsPerPage);
    }, [updateRowsPerPage, initialRowsPerPage]);
};

type UseInitialParamsFromUrlResult = {
    initialFilters: TableFilters | undefined;
    initialSortOrder: MUISortOptions | undefined;
    initialSearchText: string | undefined;
    initialPage: number | undefined;
    initialRowsPerPage: number | undefined;
};

const useInitialParamsFromUrl = (params: URLSearchParams): UseInitialParamsFromUrlResult => {
    const [result] = useState(() => {
        let initialFilters: TableFilters | undefined = undefined;
        let initialSortOrder: MUISortOptions | undefined = undefined;
        let initialSearchText: string | undefined = undefined;
        let initialPage: number | undefined = undefined;
        let initialRowsPerPage: number | undefined = undefined;

        params.forEach((value, key) => {
            initialFilters = parseFiltersFromUrl({ key, value }, initialFilters);

            if (key.startsWith('order')) {
                const orderKey = key.substring(key.indexOf('[') + 1, key.length - 1);
                if (value === 'asc' || value === 'desc') {
                    initialSortOrder = { name: orderKey, direction: value };
                }
            }
            if (key === 'searchText' && value.length > 0) {
                initialSearchText = value;
            }
            if (key === 'page' && isFinite(Number(value))) {
                initialPage = Number(value);
            }
            if (key === 'rowsPerPage' && isFinite(Number(value))) {
                initialRowsPerPage = Number(value);
            }
        });

        return { initialFilters, initialSortOrder, initialSearchText, initialPage, initialRowsPerPage };
    });

    return result;
};

const convertParams = (
    filters: TableFilters,
    sortOrder: MUISortOptions | undefined,
    searchText: string,
    page: number,
    rowsPerPage: number,
    defaultOptions: MUIDataTableOptions | undefined,
    defaultFilters: TableFilters
): Record<string, string | string[]> => {
    const filterParams = Object.keys(filters).reduce((agg, curr) => {
        if (isEqual(defaultFilters[curr], filters[curr])) return agg;
        return { ...agg, [`filter[${curr}]`]: filters[curr] };
    }, {});
    const sortOrderParams =
        sortOrder && !isEqual(sortOrder, defaultOptions?.sortOrder)
            ? { [`order[${sortOrder.name}]`]: sortOrder.direction }
            : undefined;
    const searchTextParams = searchText ? { searchText } : undefined;
    const pageParams = page !== (defaultOptions?.page ?? 0) ? { page: String(page) } : undefined;
    const rowsPerPageParams =
        rowsPerPage !== (defaultOptions?.rowsPerPage ?? 10) ? { rowsPerPage: String(rowsPerPage) } : undefined;
    return { ...filterParams, ...sortOrderParams, ...searchTextParams, ...pageParams, ...rowsPerPageParams };
};

export const getExistingParams = (params: URLSearchParams): Record<string, string | string[]> => {
    const existing: Record<string, string | string[]> = {};
    params.forEach((value, key) => {
        if (!/^filter|order|searchText|page|rowsPerPage/.test(key)) {
            if (existing[key]) {
                const exists = existing[key];
                if (typeof exists === 'string') {
                    existing[key] = [exists, value];
                } else {
                    existing[key] = [...exists, value];
                }
            } else {
                existing[key] = value;
            }
        }
    });
    return existing;
};
