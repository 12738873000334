import { backendApiService } from './ApiService';
import {
    EmailAntragApi,
    FemAntragApi,
    LeistungsanbieterAntragApi,
    LeistungsangebotRegistrierenApi,
    MeldungAntragApi,
    RegelpruefungAntragApi,
} from './einrichtungApiTypes';

export const leistungsangebotEntwurfApi: LeistungsangebotRegistrierenApi = {
    getFormDataAPI: backendApiService.getLeistungsangebotEntwurfJson.bind(backendApiService),
    submitAPI: backendApiService.updateLeistungsangebotEntwurfItem.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteLeistungsangebotObjectTypeItem.bind(backendApiService),
};

export const meldungApi: MeldungAntragApi = {
    getFormDataAPI: backendApiService.getMeldungFormByStepJson.bind(backendApiService),
    submitAPI: backendApiService.patchMeldungByStepJson.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteMeldungByStepJson.bind(backendApiService),
};

export const leistungsanbieterApi: LeistungsanbieterAntragApi = {
    getFormDataAPI: backendApiService.getLeistungsanbieterJson.bind(backendApiService),
    submitAPI: backendApiService.patchLeistungsanbieterJson.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteLeistungsanbieterJson.bind(backendApiService),
};

export const emailAntragApi: EmailAntragApi = {
    getFormDataAPI: backendApiService.getEmailAntragJson.bind(backendApiService),
    submitAPI: backendApiService.patchEmailAntragJson.bind(backendApiService),
};

export const femAntragApi: FemAntragApi = {
    getFormDataAPI: backendApiService.getFemAntragJson.bind(backendApiService),
    submitAPI: backendApiService.patchFemAntragJson.bind(backendApiService),
    deleteObjectTypeItem: backendApiService.deleteFemAntragItemJson.bind(backendApiService),
};

export const regelpruefungAntragApi: RegelpruefungAntragApi = {
    getFormDataAPI: backendApiService.getRegelpruefungAntragJson.bind(backendApiService),
    submitAPI: backendApiService.patchRegelpruefungAntragJson.bind(backendApiService),
};
