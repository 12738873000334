import { NestedGroupControlType, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const nestedGroup = (
    nestedScope: string,
    scopes: string[],
    uischemas: UiSchemaType[],
    options?: NestedGroupControlType['options']
): NestedGroupControlType => ({
    type: 'NestedGroupControl',
    scopes,
    uischemas,
    nestedScope,
    options,
});
