import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiMeldungHinweistextItem } from 'api/hooks/useApiClient';
import { MeldungHinweisTextFormData } from 'api/types';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { SimpleForm } from 'forms/SimpleForm';
import { PathBuilder } from 'navigation/Paths';
import { uiSchema } from 'pages/Verwaltung/MeldungHinweistexte/config';

interface MeldungHinweisTextFormProps {
    submitRedirect?: boolean;
    submitLabel?: string;
    messages?: {
        success: string;
        error: string;
    };
}

const initialFormData: MeldungHinweisTextFormData = {
    inhalt: undefined,
    einrichtungsart: [],
    ueberschrift: undefined,
};

export const MeldungHinweisTextForm = ({ submitRedirect, submitLabel, messages }: MeldungHinweisTextFormProps) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<MeldungHinweisTextFormData | null>(null);

    const overviewPath = PathBuilder.home.verwaltung.meldungHinweise.path;

    const { data, isLoading, error } = useApiMeldungHinweistextItem(id);

    const { schema } = useSchemaFromJsonLdContext('MeldungHinweistext', formData);

    const handleSubmit = (formData: MeldungHinweisTextFormData) => {
        if (id) {
            return backendApiService.patchMeldungHinweistextItem(String(id), formData).catch(() => Promise.reject());
        }

        return backendApiService.createMeldungHinweistext(formData).catch(() => Promise.reject());
    };

    const handleAfterSubmit = () => {
        if (submitRedirect) {
            navigate(overviewPath);
        }
    };

    useEffect(() => {
        if (!isLoading && schema) {
            setFormData(data || initialFormData);
        }
    }, [data, isLoading, schema]);

    return (
        <>
            {!error ? (
                <SimpleForm
                    isLoading={isLoading || !schema}
                    data={formData}
                    schema={schema}
                    uiSchema={uiSchema}
                    messages={messages}
                    onSubmit={handleSubmit}
                    onAfterSubmit={handleAfterSubmit}
                    submitLabel={submitLabel || 'Speichern'}
                    backLink={{
                        path: overviewPath,
                        title: 'Zur Übersicht',
                    }}
                />
            ) : (
                <Alert severity="error">Beim Laden des Hinweistext ist ein Fehler aufgetreten.</Alert>
            )}
        </>
    );
};
