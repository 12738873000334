import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { authLog, AuthState, LogoutType } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';
import { PathBuilder } from 'navigation/Paths';

import { useIsLoggedIn } from './useAuthStateHelpers';

/**
 * Provides a logout function to sign out the current user.
 */
export const useLogout = (type: LogoutType = 'MANUAL'): (() => Promise<void>) => {
    const navigate = useNavigate();
    const logout = useAuthState(({ logout }) => logout);
    return useCallback(async () => {
        authLog('[UseLogout.Logout]', type);
        logout(type);
        if (type === 'MANUAL') navigate(PathBuilder.home.path);
    }, [logout, type, navigate]);
};

/**
 * Log out logged in users and redirect them to the requested location.
 */
export const useLogoutAndRedirect = () => {
    const logout = useLogout();
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = useIsLoggedIn();

    useEffect(() => {
        const logoutAndRedirect = async () => {
            if (isLoggedIn) {
                await logout();
                navigate(location);
            }
        };

        logoutAndRedirect();
    }, [isLoggedIn, logout, navigate, location]);
};

type UseLogoutTypeResult = Pick<AuthState, 'logoutType' | 'clearLogoutType'>;
export const useLogoutType = (): UseLogoutTypeResult => {
    return useAuthState(({ logoutType, clearLogoutType }) => ({
        logoutType,
        clearLogoutType,
    }));
};
