import React, { useMemo } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';

import { THOUSAND_SEPARATOR } from 'utilities/Utils';

export interface NummerFormatProps extends Omit<NumericFormatProps, 'getInputRef' | 'onValueChange'> {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const DezimalFormat: React.FC<NummerFormatProps> = React.forwardRef(({ onChange, min, max, ...props }, ref) => {
    const minValue = useMemo(() => (min ? parseInt(String(min), 10) : 0), [min]);
    const allowNegative = useMemo(() => minValue < 0, [minValue]);

    return (
        <NumericFormat
            getInputRef={ref}
            onValueChange={(values: NumberFormatValues) => {
                onChange({
                    target: {
                        name: props.name!,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={allowNegative}
            valueIsNumericString
            {...props}
        />
    );
});

export const GanzzahlFormat: React.FC<NummerFormatProps> = React.forwardRef(
    ({ onChange, min, max, name, ...props }, ref) => {
        const minValue = useMemo(() => (min ? parseInt(String(min), 10) : 0), [min]);
        const allowNegative = useMemo(() => minValue < 0, [minValue]);

        return (
            <NumericFormat
                getInputRef={ref}
                onValueChange={(values: NumberFormatValues) => {
                    onChange({
                        target: {
                            name: name!,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator=","
                decimalScale={0}
                fixedDecimalScale={true}
                allowNegative={allowNegative}
                {...props}
            />
        );
    }
);
