import { Grid, Typography } from '@mui/material';

import { RegistrierungForm } from 'forms/RegistrierungForm';
import { ContentContainer } from 'layout/container/ContentContainer';

export const RegistrierungPage = () => {
    return (
        <ContentContainer title="Registrierung">
            <Grid container direction="row" justifyContent="center" spacing={1}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h1">Registrierung</Typography>

                    <RegistrierungForm />
                </Grid>
            </Grid>
        </ContentContainer>
    );
};
