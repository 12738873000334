import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiWohngemeinschaftItem } from 'api/hooks/useApiClient';
import { WohngemeinschaftFormData } from 'api/types';
import { SimpleForm } from 'forms/SimpleForm';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

interface AwbBearbeitenProps {
    pathBuilder: PathBuilderProps;
    schema?: Schema;
    uiSchema?: UiSchemaType;
}

export const AbwBearbeiten = ({ pathBuilder, schema, uiSchema }: AwbBearbeitenProps) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { loadBenutzerAufgaben } = useBenutzerAufgaben();
    const { isLoading, data } = useApiWohngemeinschaftItem(id);

    const handleSubmit = (formData: WohngemeinschaftFormData) => {
        return backendApiService.patchWohngemeinschaftItem(formData, Number(id));
    };

    return (
        <ContentContainer title="Angezeigte Betreuung in Wohngemeinschaften: Bearbeiten">
            <StyledContainer>
                <Typography variant="h1">Angezeigte Betreuung in Wohngemeinschaften: Bearbeiten</Typography>

                <SimpleForm
                    hideTitle
                    isLoading={isLoading}
                    data={data}
                    schema={schema}
                    uiSchema={uiSchema}
                    messages={{
                        success: 'Wohngemeinschaft wurde aktualisiert.',
                        error: 'Aktualisierung der Wohngemeinschaft ist fehlgeschlagen.',
                    }}
                    onSubmit={handleSubmit}
                    onAfterSubmit={async () => {
                        await loadBenutzerAufgaben();

                        navigate(pathBuilder?.path || '');
                    }}
                    submitLabel="Speichern"
                    backLink={{
                        path: pathBuilder?.path || '',
                        title: 'Zur Übersicht',
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};
