import { ExpandLess, ExpandMore, UnfoldMore } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { MUIDataTableColumnOptions } from 'mui-datatables';

export const CustomSortableColumns = (): MUIDataTableColumnOptions => {
    return {
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
            return (
                <CustomColumnHead
                    key={`${columnMeta.index}-${columnMeta.label}-${columnMeta.name}`}
                    id={`${columnMeta.name}`}
                    scope="col"
                >
                    {columnMeta.sort ? (
                        <Button color="inherit" variant="text" onClick={() => handleToggleColumn(columnMeta.index)}>
                            <CustomSortActionContainer>
                                <strong>{columnMeta.label}</strong>

                                <CustomSortActionContainer>
                                    {sortOrder.name === columnMeta.name ? (
                                        <>{sortOrder.direction === 'asc' ? <ExpandLess /> : <ExpandMore />}</>
                                    ) : (
                                        <UnfoldMore className="icon-lighter" />
                                    )}
                                </CustomSortActionContainer>
                            </CustomSortActionContainer>
                        </Button>
                    ) : (
                        <strong>{columnMeta.label}</strong>
                    )}
                </CustomColumnHead>
            );
        },
    };
};

const CustomColumnHead = styled('th')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'left',
    padding: theme.spacing(2),
    '> button': {
        paddingLeft: 0,
    },
}));

const CustomSortActionContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    '> svg': {
        color: theme.palette.grey.A700,

        '&.icon-lighter': {
            color: theme.palette.grey.A400,
        },
    },
}));
