import { emailAntragApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { femAufforderungConfig } from 'forms/FemAntrag/femAufforderungConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AntragPage } from 'pages/AntragPage';

const Index = () => {
    return (
        <AntragPage
            customStep="aufforderung"
            antragType={AntragType.FEM}
            pathBuilder={PathBuilder.home.verwaltung.femAntrag}
            tabConfig={femAufforderungConfig}
            workflowFormItem={backendApiService.workflowEmailAntrag.bind(backendApiService)}
            api={emailAntragApi}
        />
    );
};

export const FemAuforderungPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_FEM_MELDER_AUFFORDERUNG
);
