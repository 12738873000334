import { LoadingButton } from '@mui/lab';
import { Button, styled } from '@mui/material';

import { theme } from 'theme/theme';

const formButtonStyles = () => `
    border-radius: 4px;
    line-height: 1.4;

    .MuiSvgIcon-root {
        margin-right: ${theme.spacing(1)}
    }
`;

export const FormLoadingButton = styled(LoadingButton)(formButtonStyles);
export const FormButton = styled(Button)(formButtonStyles);
