import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { useDataFromScopes } from 'forms/hooks/useDataFromScopes';
import { withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { Schema, TableColumn, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { determineSchemaByUUID } from 'forms/utilities/SchemaUtils';
import { getFieldFromScope } from 'utilities';

import { FormTableHeaderCell } from './FormTableHeaderCell';
import { FormTableRow } from './FormTableRow';

interface FormTableProps {
    data: any;
    schema: Schema;
    uischema: UiSchemaType & {
        title?: string;
        columns: TableColumn[];
        scopes?: string[];
        viewOnly?: boolean;
        renderActionsColumn?: boolean;
    };

    onView?: (index: number) => void;
    onEdit?: (index: number) => void;
    onDelete?: (index: number) => void;
    onCustomAction?: (index: number, key: string) => void;
    disabled?: boolean;
    readonly?: boolean;
    renderActionsColumn?: boolean;
    deleteAlertText?: (index: number) => string;
    deleteConfirmationLabel?: (index: number) => string;
}

export const FormTable: React.FC<FormTableProps> = ({
    uischema,
    disabled = false,
    readonly = false,
    data,
    schema,
    ...rowProps
}) => {
    return (
        <Table data-cy={'FormTable'} stickyHeader title={uischema.title}>
            <TableHead>
                <TableRow>
                    <TableCell />
                    {uischema.columns.map((colSchema, index) => (
                        <FormTableHeaderCell
                            key={index}
                            column={colSchema}
                            label={schema.custom?.columnLabels?.[getFieldFromScope(colSchema?.scope) as string]}
                        />
                    ))}
                    {uischema.renderActionsColumn !== false ? (
                        <TableCell style={{ verticalAlign: 'bottom' }}>Aktion</TableCell>
                    ) : null}
                </TableRow>
            </TableHead>

            <TableBody>
                {data?.map((elem: any, index: number) => (
                    <FormTableRow
                        key={index}
                        index={index}
                        schema={determineSchemaByUUID(elem.uuid, schema)}
                        rowData={elem}
                        disabled={disabled}
                        columns={uischema.columns}
                        viewOnly={uischema.viewOnly || readonly}
                        renderActionsColumn={uischema.renderActionsColumn}
                        {...rowProps}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

const FormTableControlComponent: React.FC<Pick<FormTableProps, 'uischema' | 'schema' | 'data'>> = ({
    uischema,
    schema,
    data,
}) => {
    const { value } = useDataFromScopes(schema, data, uischema.scopes);

    return <FormTable data={value} schema={schema} uischema={uischema} />;
};

export const FormTableControl = withCustomControlProps(FormTableControlComponent);
