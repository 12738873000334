import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormHelperText, List, ListItem } from '@mui/material';
import { isEqual } from 'lodash';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const CheckboxMultipleControlComponent: React.FC<CustomControlProps<(string | number)[]>> = ({
    path,
    label,
    data,
    handleChange,
    schema,
    uischema,
    onTouched,
    hasErrors,
    errors,
    required,
    showFieldNumberLabels,
    gridLayout,
}) => {
    const [values, setValues] = useState<(string | number)[]>(data || []);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedValue = uischema.parseValueToInt ? parseInt(event.target.value, 10) : event.target.value;

        if (event.target.checked) {
            setValues([...values, selectedValue]);
        } else {
            setValues(values.filter((value) => value !== selectedValue));
        }
    };

    const determineOptionValue = (value: string) => {
        return uischema.parseValueToInt ? parseInt(value, 10) : value;
    };

    useEffect(() => {
        if (isEqual(values, data)) {
            return;
        }

        handleChange(path, values);
    }, [handleChange, path, values, data]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            labelHeadline={uischema?.noLabel === true ? undefined : { variant: 'h3' }}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelVisuallyHidden={uischema?.noLabel === true}
        >
            {schema.anyOf?.length ? (
                <List>
                    {schema.anyOf.map((option, index: number) => {
                        const elementKey = `${option.const}-${index}`;
                        return (
                            <ListItem key={`${elementKey}-item`}>
                                <FormControlLabel
                                    key={`${elementKey}-label`}
                                    control={
                                        <Checkbox
                                            checked={values.includes(determineOptionValue(option.const))}
                                            value={option.const}
                                            onBlur={onTouched}
                                            onChange={(event) => onChange(event)}
                                        />
                                    }
                                    label={option.title}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}

            {hasErrors && (
                <FormHelperText component="div">
                    <ErrorList errors={errors} />
                </FormHelperText>
            )}
        </RowControl>
    );
};

export const CheckboxMultipleControl = withCustomControlProps(CheckboxMultipleControlComponent);
