import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { CreateEditPage } from 'pages/Leistungsangebote/Freigegebene/CreateEditPage';

const Index = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    return <CreateEditPage pathBuilder={pathBuilder} />;
};

export const LabCreateEditPage: React.FC<{ pathBuilder: PathBuilderProps }> = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG
);
