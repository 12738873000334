import { KONZEPTINFORMATIONEN_ART } from 'constants/labels';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';

import { MeldungProps } from './MeldungDetailPage';

export const Konzeptinformationen = ({ meldung }: MeldungProps) => {
    return (
        <>
            {meldung.data?.konzeptinformationen?.length ? (
                <DetailsLayout.Group title="Konzeptionelle Schwerpunkte" variant="h2">
                    <DetailsLayout.SimpleTable
                        columns={[
                            {
                                label: 'Schwerpunkt',
                                name: 'name',
                                dictionary: KONZEPTINFORMATIONEN_ART,
                                width: '50%',
                            },
                            { label: 'Plätze', name: 'plaetze' },
                        ]}
                        data={meldung.data?.konzeptinformationen}
                    />
                </DetailsLayout.Group>
            ) : null}

            {Object.keys(meldung.data || []).some((key) => key.startsWith('stepKonzeptinformationen')) ? (
                <DetailsLayout.Group
                    title={meldung.labelReplacements?.['[konzeptinformationenUploadLabel]']}
                    variant="h2"
                >
                    {meldung.data?.stepKonzeptinformationenLeistungsbeschreibungen ? (
                        <DetailsLayout.DownloadLink
                            value={meldung.data.stepKonzeptinformationenLeistungsbeschreibungen}
                            label="Allgemeine Leistungsbeschreibungen"
                        />
                    ) : null}
                    {meldung.data?.stepKonzeptinformationenHausordnungen ? (
                        <DetailsLayout.DownloadLink
                            value={meldung.data.stepKonzeptinformationenHausordnungen}
                            label="Hausordnung"
                        />
                    ) : null}
                    {meldung.data?.stepKonzeptinformationenKonzeptinformationen ? (
                        <DetailsLayout.DownloadLink
                            value={meldung.data.stepKonzeptinformationenKonzeptinformationen}
                            label="Konzeptinformationen"
                        />
                    ) : null}
                    {meldung.data?.stepKonzeptinformationenFreiheitentziehende ? (
                        <DetailsLayout.DownloadLink
                            value={meldung.data.stepKonzeptinformationenFreiheitentziehende}
                            label="Konzept zur Vermeidung von freiheitsentziehenden / freiheitsbeschränkenden Maßnahmen"
                        />
                    ) : null}
                    {meldung.data?.stepKonzeptinformationenQualitaetBeschwerden ? (
                        <DetailsLayout.DownloadLink
                            value={meldung.data.stepKonzeptinformationenQualitaetBeschwerden}
                            label="Qualitäts- und Beschwerdeverfahren"
                        />
                    ) : null}
                    {meldung.data?.stepKonzeptinformationenGewaltpraeventionen ? (
                        <DetailsLayout.DownloadLink
                            value={meldung.data.stepKonzeptinformationenGewaltpraeventionen}
                            label="Konzept Gewaltprävention"
                        />
                    ) : null}
                </DetailsLayout.Group>
            ) : null}
        </>
    );
};
