import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useLandschaftsverbandListData = (): GetData<any> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) =>
            backendApiService.getLandschaftsverbandCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderAdresseEmail: sortBy(sortOrder, 'email'),
                orderAdresseName: sortBy(sortOrder, 'ansprechpartner'),
            }),
        []
    );
};
