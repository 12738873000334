import { Rule } from '@jsonforms/core/lib/models/uischema';

import { InfoType } from 'forms/types/UiSchemaTypes';

interface InfoFieldOptionsBase {
    rule?: Rule;
    type?: 'Info' | 'Warning' | 'Error' | 'Success';
}

interface InfoFieldOptionsDefault extends InfoFieldOptionsBase {
    withMessageKey?: boolean;
}

interface InfoFieldOptionsMessageKey extends InfoFieldOptionsBase {
    withMessageKey: boolean;
}

interface InfoFieldMessageDefault {
    message: string;
    options?: InfoFieldOptionsDefault;
}

interface InfoFieldMessageKey {
    message?: string;
    options: InfoFieldOptionsMessageKey;
}

type InfoFieldMessageProps = InfoFieldMessageDefault | InfoFieldMessageKey;

export const info = ({ message, options = {} }: InfoFieldMessageProps): InfoType => ({
    message,
    withMessageKey: !!options?.withMessageKey,
    type: options?.type || 'Info',
    rule: options.rule,
});
