import { Grid, styled } from '@mui/material';

import { Link } from 'elements/Link';
import { PathBuilder } from 'navigation/Paths';

export const Footer = () => {
    return (
        <StyledFooter role="contentinfo">
            <Root container>
                <PfadWtg item xs={12} md={6}>
                    PfAD.wtg | © 2010-2022 d-NRW AöR / publicplan GmbH
                </PfadWtg>

                <Links item xs={12} md={6}>
                    <nav aria-label="footer">
                        <ALink to={PathBuilder.home.datenschutz.path}>Datenschutzhinweise</ALink>{' '}
                        <ALink to={PathBuilder.home.impressum.path}>Impressum</ALink>{' '}
                        <ALink to={PathBuilder.home.barrierefreiheit.path}>Barrierefreiheit</ALink>
                    </nav>
                </Links>
            </Root>
        </StyledFooter>
    );
};

const StyledFooter = styled('footer')(() => ({
    position: 'relative',
}));

const Root = styled(Grid)(({ theme }) => ({
    paddingLeft: 15,
    backgroundColor: '#4d4d4d',
    color: theme.palette.common.white,
    lineHeight: '45px',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down(`md`)]: {
        paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down(`sm`)]: {
        paddingLeft: 15,
    },
}));

const PfadWtg = styled(Grid)(({ theme }) => ({
    order: 1,
    [theme.breakpoints.down(`md`)]: {
        order: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [theme.breakpoints.down(`sm`)]: {
        justifyContent: 'flex-start',
    },
}));

const ALink = styled(Link)(({ theme }) => ({
    paddingRight: 15,
    '&, &:visited': {
        color: theme.palette.common.white,
    },
}));

const Links = styled(Grid)(({ theme }) => ({
    order: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    [theme.breakpoints.down(`md`)]: {
        order: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [theme.breakpoints.down(`sm`)]: {
        justifyContent: 'flex-start',
    },
}));
