import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { MeldungHinweisDokumentListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useMeldungHinweisDokumentListData = (): GetData<MeldungHinweisDokumentListResponse> => {
    return useCallback(({ page, rowsPerPage }) => {
        return backendApiService.getMeldungHinweisDokumentCollection({
            page,
            itemsPerPage: rowsPerPage,
        });
    }, []);
};
