import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { RegionJsonld } from 'api/client';
import { InlineResponseRegionJsonld } from 'api/responses/InlineResponse';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

const RegionAutocompleteComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
}) => {
    const handleValueChange = useCallback(
        (value: SelectOption | null) => {
            handleChange(path, value, true);
        },
        [handleChange, path]
    );

    const labelVisuallyHidden = useMemo(() => uischema.labelVisuallyHidden ?? true, [uischema]);

    const getData = (value: string, options: AxiosRequestConfig): Promise<InlineResponseRegionJsonld> => {
        return backendApiService.getRegionCollection({
            page: 1,
            itemsPerPage: 10,
            qSearch: value,
            options: {
                signal: options.signal,
            },
        });
    };

    const getOption = (item: RegionJsonld): SelectOption => {
        return {
            id: item.id as number,
            name: `${item.plz}, ${item.ort}`,
        };
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            labelVisuallyHidden={labelVisuallyHidden}
        >
            <Typeahead
                id={id}
                data={data}
                schema={schema}
                uischema={uischema}
                path={path}
                multiple={false}
                getData={getData}
                getOption={getOption}
                onChange={handleValueChange}
                required={required}
                placeholder="Suchen"
            />
        </RowControl>
    );
};

export const RegionAutocompleteControl = withCustomControlProps(RegionAutocompleteComponent);
