import { Navigate, useParams } from 'react-router-dom';

import { ArtViewSegments } from 'constants/artViewSegments';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';
import { DetailPage } from 'pages/Leistungsangebote/Freigegebene/DetailPage';
import { TraegerDetail } from 'pages/Traeger/TraegerDetail';
import { AbwAnsehen } from 'pages/Verwaltung/ABW/components/AbwAnsehen';

interface DeletedObjectDetailsProps {
    pathBuilder: PathBuilderProps;
}

export const DeletedObjectDetails = ({ pathBuilder }: DeletedObjectDetailsProps) => {
    const { art, id } = useParams();

    switch (art?.toUpperCase()) {
        case ArtViewSegments.LEISTUNGSANGEBOT:
            return (
                <DetailPage
                    title="Leistungsangebot: Gelöscht"
                    pathBuilder={pathBuilder}
                    tablePathBuilder={PathBuilder.home.verwaltung.leistungsangebote.freigegeben.meldung}
                    meldungEinrichtungId={id}
                />
            );
        case ArtViewSegments.LEISTUNGSANBIETER:
            return (
                <TraegerDetail
                    title="Leistungsanbieter: Gelöscht"
                    pathBuilder={pathBuilder}
                    showBenutzerTable={false}
                />
            );
        case ArtViewSegments.WOHNGEMEINSCHAFT:
            return (
                <AbwAnsehen title="Angezeigte Betreuung in Wohngemeinschaften: Gelöscht" pathBuilder={pathBuilder} />
            );
        default:
            return <Navigate to={String(pathBuilder.path)} replace />;
    }
};
