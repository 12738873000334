import { AxiosRequestConfig } from 'axios';

import { ACTIONS } from 'constants/antragActions';
import { STATUS } from 'constants/antragStatus';
import { ANTRAG_VERSIONEN_TYP } from 'constants/antragVersionenTyp';
import { EinrichtungTypes } from 'constants/einrichtungTypes';
import { EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES } from 'constants/labels';
import { FormUuidMap, Schema } from 'forms/types/UiSchemaTypes';

import {
    AdressDaten,
    AngezeigteBetreuungFormJsonld,
    ApiBehoerdeGetCollection200Response,
    ApiGeloeschtesObjektGetCollection200Response,
    ApiTraegerGetCollection200Response,
    BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld,
    BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
    BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension,
    BenutzerJsonldBenutzerCreate,
    BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles,
    BenutzerJsonldProfileRead,
    BenutzerTablePreferenceJsonld,
    EinrichtungBenutzerJsonld,
    EinrichtungBenutzerJsonldEinrichtungBenutzerRead,
    EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet,
    EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet,
    EinrichtungExportRequestJsonldExportRequest,
    EinrichtungGeneriertesDokumentJsonld,
    EinrichtungJsonldEinrichtungRead,
    EinrichtungJsonldEinrichtungReadAdresseRead,
    EmailAntrag,
    EmailAntragJsonld,
    EmailAntragJsonldEmailAntragRead,
    EmailJsonldEmailListEmailReadEmail,
    EmailtextJsonldEmailtextRead,
    ExportRequestJsonldExportRequest,
    FemAntragFemCollectionOutputFemantragList,
    FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead,
    FemAntragJsonld,
    FemExportFemExportJsonForm,
    FemExportFemFilterJsonldFemexportWrite,
    HeimfinderprofilCollectionOutputJsonldHeimfinderList,
    HeimfinderprofilHeimfinderWrite,
    HeimfinderprofilJsonldHeimfinderRead,
    InvestRegistrationJsonld,
    JsonFormStepAntragJsonForm,
    LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung,
    LeistungsanbieterJsonld,
    LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot,
    LeistungsangebotRegistrierenJsonld,
    LeistungsangebotRegistrierenJsonldData,
    MassenmailJsonldMassenmailList,
    MassenmailJsonldMassenmailRead,
    MassenmailJsonldMassenmailWrite,
    Meldung,
    MeldungDataJsonld,
    MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList,
    MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead,
    MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
    MeldungHinweisdokumentJsonldMeldungHinweistextList,
    MeldungHinweisdokumentJsonldMeldungHinweistextRead,
    MeldungHinweisdokumentJsonldMeldungHinweistextWrite,
    MeldungHinweistextJsonldMeldungHinweistextList,
    MeldungHinweistextJsonldMeldungHinweistextRead,
    MeldungHinweistextJsonldMeldungHinweistextWrite,
    RegelpruefungAntragAntragJsonForm,
    RegelpruefungAntragJsonld,
    RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort,
    RegelpruefungAntragPruefberichtDTOJsonld,
    StandardauswertungStandardauswertungFilterStandardauswertungWrite,
    StandardauswertungStandardauswertungJsonForm,
    TableFilterJsonld,
    Token as ApiToken,
    TraegerDtoJsonldTraegerCreate,
    TraegerDtoJsonldTraegerReadRolleEinrichtung,
    TraegerDtoTraegerWriteRolleEinrichtung,
    TraegerGeneriertesDokumentJsonld,
    TraegerJsonldTraegerRead,
    TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung,
    WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead,
    WohngemeinschaftGeneriertesDokumentJsonld,
    WohngemeinschaftJsonld,
    WohngemeinschaftJsonldWohngemeinschaftRead,
    WohngemeinschaftWohngemeinschaftWriteAdresseWrite,
    ZugehoerigeEinrichtungenDTO,
    ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead,
} from './client';
import { InlineResponse } from './responses/InlineResponse';

export type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

export type Step = (Omit<JsonFormStepAntragJsonForm, 'actions'> & { actions?: Array<ACTIONS> }) | undefined;
export type Steps = Array<Step>;

export enum StepsType {
    HINWEISE = 'hinweise',
    BASISDATEN = 'basisdaten',
    KONZEPTINFORMATION = 'konzeptinformation',
    STANDORTE = 'standorte',
    BESCHAEFTIGTE = 'beschaeftigte',
    LEISTUNGSERBRINGER = 'leistungserbringer',
    VERTRAEGE = 'vertraege',
    DOKUMENTE = 'dokumente',
    ZUSAMMENFASSUNG = 'zusammenfassung',
    ZURUECKWEISEN = 'zurueckweisen',
    GERICHTLICH = 'gerichtlich',
    EINWILLIGUNG = 'einwilligung',
    EINREICHEN = 'einreichen',
    AUFFORDERUNG = 'aufforderung',
}

export enum AnredeType {
    DIVERS = 'DIVERS',
    FEMALE = 'FRAU',
    MALE = 'HERR',
}

export enum AnschriftType {
    POSTFACH = 'POSTFACH',
    HAUSANSCHRIFT = 'HAUSANSCHRIFT',
}

export type EinrichtungArt = Record<EinrichtungTypes, { value: string; label: string }>;

export const EinrichtungArtType: EinrichtungArt = {
    [EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI]: {
        value: EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI,
        label: 'Vollstationäre Dauerpflegeeinrichtung nach SGB XI',
    },
    [EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII]: {
        value: EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII,
        label: 'Vollstationäre Einrichtung der Eingliederungshilfe nach SGB IX',
    },
    [EinrichtungTypes.FULLY_INPATIENT_SHORT]: {
        value: EinrichtungTypes.FULLY_INPATIENT_SHORT,
        label: 'Vollstationäre solitäre Kurzzeitpflege',
    },
    [EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE]: {
        value: EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE,
        label: 'Teilstationäre Nachtpflegeeinrichtung',
    },
    [EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE]: {
        value: EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE,
        label: 'Teilstationäre Tagespflegeeinrichtung',
    },
    [EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION]: {
        value: EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION,
        label: 'Tagesstätten der Eingliederungshilfe',
    },
    [EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT]: {
        value: EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT,
        label: 'Ambulanter Dienst mit Versorgungsvertrag nach § 72 SGB XI',
    },
    [EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT]: {
        value: EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT,
        label: 'Ambulanter Dienst mit Leistungsvereinbarung nach §§ 123 ff. SGB IX',
    },
    [EinrichtungTypes.HOSPICE]: {
        value: EinrichtungTypes.HOSPICE,
        label: 'Hospiz',
    },
    [EinrichtungTypes.ASSISTED_LIVING]: {
        value: EinrichtungTypes.ASSISTED_LIVING,
        label: 'Servicewohnen',
    },
    [EinrichtungTypes.SHARED_HOME]: {
        value: EinrichtungTypes.SHARED_HOME,
        label: 'Wohngemeinschaft',
    },
    [EinrichtungTypes.WERKSTATT]: {
        value: EinrichtungTypes.WERKSTATT,
        label: 'Werkstatt für behinderte Menschen',
    },
};

export type DefaultListOptions = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
};

export interface BenutzerListOptionParams extends DefaultListOptions {
    existsDeaktiviertAt?: boolean;
    existsDeletedAt?: boolean;
    id?: number;
    id2?: Array<number>;
    rolle?: string;
    rolle2?: Array<string>;
    landschaftsverband?: string;
    landschaftsverband2?: Array<string>;
    behoerde?: string;
    behoerde2?: Array<string>;
    bezirksregierung?: string;
    bezirksregierung2?: Array<string>;
    traeger?: string;
    traeger2?: Array<string>;
    einrichtungBenutzerEinrichtung?: string;
    einrichtungBenutzerEinrichtung2?: Array<string>;
    traegerverband?: string;
    traegerverband2?: Array<string>;
    email?: string;
    email2?: Array<string>;
    qImpersonateSearch?: string;
    orderNachname?: 'asc' | 'desc';
    orderVorname?: 'asc' | 'desc';
    orderEmail?: 'asc' | 'desc';
    orderDeaktiviertAt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface BenutzerListCollectionParams extends DefaultListOptions {
    existsDeaktiviertAt?: boolean;
    existsDeletedAt?: boolean;
    id?: number;
    id2?: Array<number>;
    rolle?: string;
    rolle2?: Array<string>;
    landschaftsverband?: string;
    landschaftsverband2?: Array<string>;
    behoerde?: string;
    behoerde2?: Array<string>;
    bezirksregierung?: string;
    bezirksregierung2?: Array<string>;
    traeger?: string;
    traeger2?: Array<string>;
    einrichtungBenutzerEinrichtung?: string;
    einrichtungBenutzerEinrichtung2?: Array<string>;
    traegerverband?: string;
    traegerverband2?: Array<string>;
    email?: string;
    email2?: Array<string>;
    qImpersonateSearch?: string;
    orderNachname?: 'asc' | 'desc';
    orderVorname?: 'asc' | 'desc';
    orderEmail?: 'asc' | 'desc';
    orderDeaktiviertAt?: 'asc' | 'desc';
}

export interface BenutzerZugehoerigeEinrichtungen {
    benutzer: number;
    page?: number;
    itemsPerPage?: number;
    options?: AxiosRequestConfig;
}

export interface BenutzerZugriffsrechteListCollection {
    page?: number;
    itemsPerPage?: number;
    existsDeaktiviertAt?: boolean;
    existsDeletedAt?: boolean;
    id?: number;
    id2?: Array<number>;
    rolle?: string;
    rolle2?: Array<string>;
    landschaftsverband?: string;
    landschaftsverband2?: Array<string>;
    behoerde?: string;
    behoerde2?: Array<string>;
    bezirksregierung?: string;
    bezirksregierung2?: Array<string>;
    traeger?: string;
    traeger2?: Array<string>;
    einrichtungBenutzerEinrichtung?: string;
    einrichtungBenutzerEinrichtung2?: Array<string>;
    traegerverband?: string;
    traegerverband2?: Array<string>;
    email?: string;
    email2?: Array<string>;
    qSearch?: string;
    qImpersonateSearch?: string;
    orderNachname?: 'asc' | 'desc';
    orderVorname?: 'asc' | 'desc';
    orderEmail?: 'asc' | 'desc';
    orderDeaktiviertAt?: 'asc' | 'desc';
}

export interface BehoerdenListOptionParams extends DefaultListOptions {
    orderName?: 'asc' | 'desc';
    orderVerantwortlicheBehoerde?: 'asc' | 'desc';
    orderAdresseEmail?: 'asc' | 'desc';
    orderAdresseName?: 'asc' | 'desc';
}

export interface LandschaftsverbandListOptionParams extends DefaultListOptions {
    orderName?: 'asc' | 'desc';
    orderAdresseEmail?: 'asc' | 'desc';
    orderAdresseName?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface TraegerverbandListOptionParams extends DefaultListOptions {
    id?: number;
    id2?: number[];
    orderName?: 'asc' | 'desc';
    orderAdresseEmail?: 'asc' | 'desc';
    orderAdresseName?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface BezirksregierungListOptionParams {
    page?: number;
    itemsPerPage?: number;
    name?: string;
    orderName?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface TraegerListOptionParams extends DefaultListOptions {
    landschaftsverband?: string;
    landschaftsverband2?: Array<string>;
    behoerde?: string;
    behoerde2?: Array<string>;
    status?: string;
    status2?: Array<string>;
    type?: string;
    type2?: Array<string>;
    existsDeleted?: boolean;
    orderName?: 'asc' | 'desc';
    orderNrwKey?: 'asc' | 'desc';
    orderType?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderAdressePlz?: 'asc' | 'desc';
    orderAdresseOrt?: 'asc' | 'desc';
    orderModifiedAt?: 'asc' | 'desc';
    orderDeleted?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export type MeldungenStatus =
    | EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES.KEINE
    | EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES.AUFGEFORDERT;

export interface EinrichtungListOptionParams extends DefaultListOptions {
    qSearch?: string;
    status?: string;
    status2?: Array<string>;
    art?: string;
    art2?: Array<string>;
    wohngemeinschaftLeistungsbereich?: string;
    wohngemeinschaftLeistungsbereich2?: Array<string>;
    wohngemeinschaftArt?: string;
    wohngemeinschaftArt2?: Array<string>;
    adresseCx?: string;
    adresseCx2?: Array<string>;
    adresseCy?: string;
    adresseCy2?: Array<string>;
    existsEnabled?: boolean;
    existsActivated?: boolean;
    existsDeleted?: boolean;
    meldungenStatus?: MeldungenStatus;
    zustaendigerBenutzer?: Array<number>;
    keinZustaendigerBenutzer?: boolean;
    belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND';
    orderName?: 'asc' | 'desc';
    orderNrwKey?: 'asc' | 'desc';
    orderErstinbetriebnahme?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderAdressePlz?: 'asc' | 'desc';
    orderAdresseOrt?: 'asc' | 'desc';
    orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc';
    orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc';
    orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc';
    orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc';
    orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc';
    orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc';
    orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc';
    orderDeleted?: 'asc' | 'desc';
    orderUpdateDeadline?: 'asc' | 'desc';
    orderArt?: 'asc' | 'desc';
    orderFemUeberfaellig?: 'asc' | 'desc';
    orderFemErinnerung?: 'asc' | 'desc';
    orderLetzteMeldung?: 'asc' | 'desc';
    orderZustaendig?: 'asc' | 'desc';
    orderRegelpruefungenPruefDatum?: 'asc' | 'desc';
    orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export type MeldungCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
    eingereichtBy?: string;
    eingereichtBy2?: Array<string>;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    modifiedAt?: string;
    status?: string;
    status2?: Array<string>;
    einrichtungNeedsUpdate?: boolean;
    keinZustaendigerBenutzer?: boolean;
    existsEinrichtungDeleted?: boolean;
    orderZuletztEingereichtAt?: 'asc' | 'desc';
    orderModifiedAt?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderEinrichtungArt?: 'asc' | 'desc';
    orderEinrichtungAdresseOrt?: 'asc' | 'desc';
    orderEinrichtungNrwKey?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type EmailAntragCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    eingereichtBy?: string;
    eingereichtBy2?: Array<string>;
    modifiedAt?: string;
    status?: string;
    status2?: Array<string>;
    typ?: string;
    typ2?: Array<string>;
    qSearch?: string;
    orderName?: 'asc' | 'desc';
    orderBetreff?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderEinrichtungNrwKey?: 'asc' | 'desc';
    orderEingereichtAt?: 'asc' | 'desc';
    orderEingereichtByNachname?: 'asc' | 'desc';
    rolle?: 'BEHOERDE' | 'ANBIETER';
    orderTyp?: 'asc' | 'desc';
    isRead?: boolean;
};

export type EmailCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    empfaenger?: string;
    empfaenger2?: Array<string>;
    createdBy?: string;
    createdBy2?: Array<string>;
    orderCreatedAt?: 'asc' | 'desc';
};

export type EmailItem = EmailJsonldEmailListEmailReadEmail;

export type EmailCollectionResponse = Array<EmailItem>;

export type FemCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    eingereichtBy?: string;
    eingereichtBy2?: Array<string>;
    name?: string;
    modifiedAt?: string;
    status?: string;
    status2?: Array<string>;
    jahr?: number;
    jahr2?: Array<number>;
    qSearch?: string;
    orderName?: 'asc' | 'desc';
    orderEingereichtAt?: 'asc' | 'desc';
};

export type InvestRegistrationCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    existsTransfered?: boolean;
    failed?: boolean;
    options?: AxiosRequestConfig;
};

export type MassenmailCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    behoerde?: string;
    behoerde2?: Array<string>;
    options?: AxiosRequestConfig;
};

export type DeletedObjectCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
    confirmed?: boolean;
    orderByNrwKey?: 'asc' | 'desc';
    orderByArt?: 'asc' | 'desc';
    orderByName?: 'asc' | 'desc';
    orderByErstinbetriebnahme?: 'asc' | 'desc';
    orderByDeletedAt?: 'asc' | 'desc';
    orderByDeletedBy?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type WohngemeinschaftCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
    existsEinrichtung?: boolean;
    existsDeleted?: boolean;
    orderName?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderAmbulanterDienstName?: 'asc' | 'desc';
    orderAdresseStrasse?: 'asc' | 'desc';
    orderPlaetze?: 'asc' | 'desc';
    orderBeginn?: 'asc' | 'desc';
    orderCreatedAt?: 'asc' | 'desc';
    orderDeleted?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
};

export type RegelpruefungPruefberichteCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
    eingereichtAt?: string;
    eingereichtAt2?: string;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    einrichtungBehoerde?: string;
    einrichtungBehoerde2?: Array<string>;
    einrichtungStandort?: string;
    einrichtungStandort2?: Array<string>;
    status?: string;
    status2?: Array<string>;
    orderEinrichtungName?: 'asc' | 'desc';
    orderEinrichtungBehoerdeName?: 'asc' | 'desc';
    orderPruefDatum?: 'asc' | 'desc';
    orderLetzteNachpruefung?: 'asc' | 'desc';
    orderLetzterUploadAt?: 'asc' | 'desc';
    hasPruefbericht?: string;
    jahr?: string;
};

export type RegelpruefungAntragCollectionParams = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
    eingereichtAt?: string;
    eingereichtAt2?: string;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    einrichtungBehoerde?: string;
    einrichtungBehoerde2?: Array<string>;
    einrichtungStandort?: string;
    einrichtungStandort2?: Array<string>;
    status?: string;
    status2?: Array<string>;
    orderEinrichtungName?: 'asc' | 'desc';
    orderEinrichtungBehoerdeName?: 'asc' | 'desc';
    orderPruefDatum?: 'asc' | 'desc';
    orderLetzteNachpruefung?: 'asc' | 'desc';
    orderLetzterUploadAt?: 'asc' | 'desc';
    hasPruefbericht?: string;
    jahr?: string;
};

export interface MeldungHinweistextCollectionParams {
    page?: number;
    itemsPerPage?: number;
    behoerde?: string;
    behoerde2?: Array<string>;
    options?: AxiosRequestConfig;
}

export interface MeldungHinweisDokumentCollectionParams {
    page?: number;
    itemsPerPage?: number;
    behoerde?: string;
    behoerde2?: Array<string>;
    options?: AxiosRequestConfig;
}

export interface DokumentPruefungCollectionParams {
    page?: number;
    itemsPerPage?: number;
    behoerde?: string;
    behoerde2?: Array<string>;
    options?: AxiosRequestConfig;
}

export interface Indexable {
    [key: string]: any;
}

export interface LeistungsangebotEntwurfListOptions extends Indexable {
    page?: number;
    itemsPerPage?: number;
    eingereichtBy?: string;
    eingereichtBy2?: Array<string>;
    einrichtungName?: string;
    modifiedAt?: string;
    status?: string;
    status2?: Array<string>;
    qSearch?: string;
    orderEinrichtungName?: 'asc' | 'desc';
    orderModifiedAt?: 'asc' | 'desc';
    orderZuletztEingereichtAt?: 'asc' | 'desc';
    orderErstinbetriebnahme?: 'asc' | 'desc';
    orderOrt?: 'asc' | 'desc';
    orderLeistungsanbieterName?: 'asc' | 'desc';
    orderLeistungsanbieterStatus?: 'asc' | 'desc';
    orderEinrichtungArt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface LeistungsangebotJsonParams {
    id: number;
    step?: string;
    objectType?: string;
    uuid?: string;
    options?: AxiosRequestConfig;
}

export type LeistungsangebotData = Omit<LeistungsangebotRegistrierenJsonldData, 'schema'> &
    EnsureCustomSchema &
    EnsureArt &
    EnsureUuid &
    EnsureUuidMap &
    EnsureId;

export interface LeistungsanbieterCollectionParams {
    page?: number;
    itemsPerPage?: number;
    eingereichtBy?: string;
    eingereichtBy2?: Array<string>;
    name?: string;
    modifiedAt?: string;
    status?: string;
    status2?: Array<string>;
    qSearch?: string;
    orderName?: 'asc' | 'desc';
    orderEingereichtAt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface BelegbarePlaetzeHistorieParams {
    page?: number;
    itemsPerPage?: number;
    einrichtung?: string;
    einrichtung2?: Array<string>;
    orderCreatedAt?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface LeistungsanbieterJson {
    id: number;
    step?: string;
    objectType?: string;
    uuid?: string;
    options?: AxiosRequestConfig;
}

export type StandardauswertungRequestData = StandardauswertungStandardauswertungFilterStandardauswertungWrite & {
    '@id'?: string;
};

export interface FemExportCollectionParams {
    jahr: number;
    quartal: number;
    bezirksregierung?: Array<number>;
    behoerde?: Array<number>;
    gemeinde?: Array<number>;
    arten?: Array<string>;
    options?: AxiosRequestConfig;
}

export interface FemExportRequestParams {
    formData?: FemExportFemFilterJsonldFemexportWrite;
    options?: AxiosRequestConfig;
}

export type FemExportRequestResponse = FemExportFemExportJsonForm;

export interface StandardauswertungRequestParams {
    formData?: StandardauswertungRequestData;
    options?: AxiosRequestConfig;
}

export interface StandardauswertungCsvExportParams {
    bezirksregierung: Array<number>;
    behoerde: Array<number>;
    gemeinde: Array<number>;
    options?: AxiosRequestConfig;
}

export type StandardauswertungResponse = StandardauswertungStandardauswertungJsonForm;

export interface RegelpruefungFileExportParams {
    format?: 'csv' | 'xlsx';
}

export interface GeneratedFile {
    id?: number;
    contentUrl?: string | null;
    fileName?: string;
    originalName?: string;
    fileSize?: number;
    mimeType?: string;
    accessRights?: boolean;
    uploadId?: number;
}

export interface ExportRequestParams {
    qSearch?: string;
    options?: AxiosRequestConfig;
}

export type ExportRequestResponse = EinrichtungExportRequestJsonldExportRequest;

export interface ExportRequestCollectionParams {
    page?: number;
    itemsPerPage?: number;
    benutzer?: string;
    benutzer2?: Array<string>;
    reference?: string;
    reference2?: Array<string>;
    status?: string;
    status2?: Array<string>;
    resource?: string;
    resource2?: Array<string>;
    orderResource?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderCreatedDate?: 'asc' | 'desc';
    orderUpdatedDate?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export type ExportRequestCollectionResponse = InlineResponse<ExportRequestJsonldExportRequest>;

export type AntragFormDataType<T> = Omit<T, 'schema'> &
    EnsureCustomSchema &
    EnsureArt &
    EnsureUuid &
    EnsureId &
    EnsureUuidMap;

export type EmailAntragData = AntragFormDataType<EmailAntragJsonld>;
export type FemAntragData = AntragFormDataType<FemAntragJsonld>;
export type RegelpruefungAntragFormData = AntragFormDataType<RegelpruefungAntragAntragJsonForm>;

export type RegelpruefungPruefberichtItem = RegelpruefungAntragPruefberichtDTOJsonld;
export type RegelpruefungPruefberichtResponse = InlineResponse<RegelpruefungPruefberichtItem>;
export type RegelpruefungPruefberichtStatusItem = RegelpruefungAntragJsonld;

export type EinrichtungApiFormSubmitParams<DataType> = {
    id: number;
    step?: string;
    body: DataType;
    objectType?: string;
    uuid?: string;
    persist?: boolean;
    fields?: Array<string>;
    recalculateFields?: boolean;
    options?: AxiosRequestConfig;
};

export type EinrichtungApiFormGetParams = {
    id: number;
    step?: string;
    objectType?: string;
    uuid?: string;
    options?: AxiosRequestConfig;
};

export type RegionListOptionParams = {
    page?: number;
    itemsPerPage?: number;
    qSearch?: string;
    options?: AxiosRequestConfig;
};

export type AdresseLookupParams = {
    query?: string;
    nrwOnly?: boolean;
    regionalKey?: string;
    page?: number;
    itemsPerPage?: number;
    options?: AxiosRequestConfig;
};

export type UnternehmensgruppeParams = {
    page?: number;
    itemsPerPage?: number;
    name?: string;
    approved?: boolean;
    approved2?: Array<boolean>;
    options?: AxiosRequestConfig;
};

export type EnsureCustomSchema = {
    schema: Schema;
};

export type EnsureArt = {
    art: string;
};

export type EnsureUuid = {
    uuid: string | undefined;
};

export type EnsureUuidMap = {
    uuidMap: FormUuidMap | undefined;
};

export type EnsureId = {
    id: number;
};

export type UploadType = {
    uploadId: string;
};

export type EnsureIRI = {
    '@id': string;
};

export type EnsureAdressDaten = {
    adresse?: AdressDaten;
};

export type EnsureCreatedAt = {
    createdAt: string;
};

export type EnsureModifiedAt = {
    modifiedAt: string;
};

export interface WithEinrichtung {
    einrichtung: EinrichtungResponse;
}

export interface WithEinrichtungBenutzer {
    einrichtungBenutzer: EinrichtungBenutzerResponse[];
}

export interface WithStatus {
    status: STATUS;
}

export interface WithVersionTyp {
    typ: ANTRAG_VERSIONEN_TYP;
}

export interface WithCreated {
    createdBy: { vorname: string; nachname: string };
    createdAt: string;
}

type WithCollectionFilterValues = {
    field: string;
    values: string[] | { [key: string]: string };
};

export interface WithFilters {
    filters: Array<WithCollectionFilterValues>;
}

// Responses
export type Token = Required<ApiToken>;

export type BenutzerProfileResponse = BenutzerJsonldProfileRead;

export type BenutzerResponse = BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles & EnsureId;
export type BenutzerListResponse = InlineResponse<BenutzerResponse>;

export type BenutzerZugriffsrechteListResponse =
    InlineResponse<BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension>;
export type BenutzerTablePreferenceResponse = BenutzerTablePreferenceJsonld & EnsureId;

export type ApiFormResponse<FormType> = Omit<FormType, 'antrag' | 'schema' | 'art'> &
    EnsureId &
    EnsureCustomSchema &
    EnsureArt &
    EnsureUuid &
    EnsureUuidMap;

export type LeistungsangebotFormResponse = ApiFormResponse<LeistungsangebotRegistrierenJsonld>;
export type LeistungsangebotItem =
    LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot & EnsureId;
export type LeistungsangebotEntwurfCollectionResponse = InlineResponse<LeistungsangebotItem>;

export type InvestRegistrationItem = InvestRegistrationJsonld;
export type InvestRegistrationCollectionResponse = InlineResponse<InvestRegistrationItem>;

export type MassenmailCollectionResponse = InlineResponse<MassenmailJsonldMassenmailList>;
export type DeletecObjectCollectionResponse = InlineResponse<ApiGeloeschtesObjektGetCollection200Response>;
export type WohngemeinschaftCollectionResponse =
    InlineResponse<WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead>;
export type FemAntragCollectionResponse = InlineResponse<FemAntragFemCollectionOutputFemantragList>;

export type LandschaftsverbandItem = LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung;
export type LandschaftsverbandCollectionResponse = InlineResponse<LandschaftsverbandItem>;

export type EinrichtungResponse = EinrichtungJsonldEinrichtungRead & WithEinrichtungBenutzer & EnsureId;
export type EinrichtungBenutzerResponse = EinrichtungBenutzerJsonldEinrichtungBenutzerRead & EnsureId;
export type EinrichtungBenutzerListResponse = InlineResponse<EinrichtungBenutzerResponse>;

export type EinrichtungenFuerPlatzzahlCollection =
    EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet;

export type EinrichtungCollectionJsonldItem = EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet;

export type EinrichtungCollectionResponse = InlineResponse<EinrichtungCollectionJsonldItem> & EnsureId;

export type EinrichtungGetByIdResponse = EinrichtungJsonldEinrichtungReadAdresseRead;

export type EinrichtungZugriffsrechteResponse =
    ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead;

export type EmailAntragItem = EmailAntragJsonldEmailAntragRead;
export type EmailAntragCollectionResponse = InlineResponse<EmailAntragItem>;

export type MeldungEinrichtungResponse = EnsureId;
export type MeldungFormResponse = ApiFormResponse<Meldung>;
export type MeldungEinrichtungPatchResponse = ApiFormResponse<MeldungDataJsonld>;

export type LeistungsanbieterFormResponse = ApiFormResponse<LeistungsanbieterJsonld>;

export type PlatzzahlmelderAntragResponse = BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld;
export type PlatzzahlmelderEinrichtungItem =
    EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet;

export type FemAntragResponse = ApiFormResponse<FemAntragJsonld>;
export type FemAntragDetailResponse = FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead;

export type MassenmailFormData = Omit<MassenmailJsonldMassenmailWrite, 'betreff' | 'inhalt' | 'uploads'> & {
    betreff?: string;
    inhalt?: string;
    uploads?: UploadType[];
};

export type InvestRegistrationFormData = InvestRegistrationJsonld;

export type EmailtextFormData = Omit<EmailtextJsonldEmailtextRead, 'emailtyp' | 'inhalt'> & {
    emailtyp?: string;
    inhalt?: string;
};

export type MeldungHinweisTextFormData = MeldungHinweistextJsonldMeldungHinweistextWrite;
export type MeldungHinweisDokumentFormData = Omit<MeldungHinweisdokumentJsonldMeldungHinweistextWrite, 'uploads'> & {
    uploads?: UploadType[];
};
export type DokumentPruefungFormData = Omit<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite, 'uploads'> & {
    uploads?: UploadType[];
};

export type ProfilHeimfinderFormData = Omit<HeimfinderprofilHeimfinderWrite, 'upload'> & {
    upload: UploadType & EnsureIRI;
};
export type ProfilHeimfinderFormGet = HeimfinderprofilJsonldHeimfinderRead;

export type MassenmailFormGet = MassenmailJsonldMassenmailRead;
export type MassenmailFormResponse = ApiFormResponse<MassenmailJsonldMassenmailRead>;

export type MeldungHinweistextGet = MeldungHinweistextJsonldMeldungHinweistextRead;
export type MeldungHinweisDokumentGet = MeldungHinweisdokumentJsonldMeldungHinweistextRead;
export type DokumentPruefungGet = MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead;

export type WohngemeinschaftSubmitData = WohngemeinschaftWohngemeinschaftWriteAdresseWrite;
export type WohngemeinschaftFormData = Omit<WohngemeinschaftSubmitData, 'einrichtung'> & {
    einrichtung: EnsureId;
};
export type WohngemeinschaftFormGet = WohngemeinschaftJsonldWohngemeinschaftRead;
export type WohngemeinschaftWorkflowResponse = WohngemeinschaftJsonld & LeistungsangebotRegistrierenJsonld;

export type AngezeigteBetreuungFormData = AngezeigteBetreuungFormJsonld;

export type TraegerFormData = TraegerDtoTraegerWriteRolleEinrichtung;
export type TraegerCreateFormData = TraegerDtoJsonldTraegerCreate;

export interface WohngemeinschaftExportParams {
    qSearch?: string;
    existsEinrichtung?: boolean;
    existsDeleted?: boolean;
    orderName?: 'asc' | 'desc';
    orderEinrichtungName?: 'asc' | 'desc';
    orderAmbulanterDienstName?: 'asc' | 'desc';
    orderAdresseStrasse?: 'asc' | 'desc';
    orderPlaetze?: 'asc' | 'desc';
    orderBeginn?: 'asc' | 'desc';
    orderCreatedAt?: 'asc' | 'desc';
    orderDeleted?: 'asc' | 'desc';
    options?: AxiosRequestConfig;
}

export interface EinrichtungExportParams {
    qSearch?: string;
    status?: string;
    status2?: Array<string>;
    art?: string;
    art2?: Array<string>;
    wohngemeinschaftLeistungsbereich?: string;
    wohngemeinschaftLeistungsbereich2?: Array<string>;
    wohngemeinschaftArt?: string;
    wohngemeinschaftArt2?: Array<string>;
    adresseCx?: string;
    adresseCx2?: Array<string>;
    adresseCy?: string;
    adresseCy2?: Array<string>;
    existsEnabled?: boolean;
    existsActivated?: boolean;
    existsDeleted?: boolean;
    meldungenStatus?: 'KEINE' | 'AUFGEFORDERT';
    zustaendigerBenutzer?: Array<number>;
    keinZustaendigerBenutzer?: boolean;
    belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND';
    orderName?: 'asc' | 'desc';
    orderNrwKey?: 'asc' | 'desc';
    orderErstinbetriebnahme?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderAdressePlz?: 'asc' | 'desc';
    orderAdresseOrt?: 'asc' | 'desc';
    orderBelegbarePlaetzeCreatedAt?: 'asc' | 'desc';
    orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc';
    orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc';
    orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc';
    orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc';
    orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc';
    orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc';
    orderDeleted?: 'asc' | 'desc';
    orderUpdateDeadline?: 'asc' | 'desc';
    orderArt?: 'asc' | 'desc';
    orderFemUeberfaellig?: 'asc' | 'desc';
    orderFemErinnerung?: 'asc' | 'desc';
    orderLetzteMeldung?: 'asc' | 'desc';
    orderZustaendig?: 'asc' | 'desc';
    orderRegelpruefungenPruefDatum?: 'asc' | 'desc';
    orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc';
}

export interface TraegerExportParams {
    qSearch?: string;
    landschaftsverband?: string;
    landschaftsverband2?: Array<string>;
    behoerde?: string;
    behoerde2?: Array<string>;
    status?: string;
    status2?: Array<string>;
    type?: string;
    type2?: Array<string>;
    existsDeleted?: boolean;
    orderName?: 'asc' | 'desc';
    orderNrwKey?: 'asc' | 'desc';
    orderType?: 'asc' | 'desc';
    orderStatus?: 'asc' | 'desc';
    orderAdressePlz?: 'asc' | 'desc';
    orderAdresseOrt?: 'asc' | 'desc';
    orderModifiedAt?: 'asc' | 'desc';
    orderDeleted?: 'asc' | 'desc';
}

export type WohngemeinschaftExportResponse = WohngemeinschaftGeneriertesDokumentJsonld;
export type EinrichtungExportResponse = EinrichtungGeneriertesDokumentJsonld;
export type TraegerExportResponse = TraegerGeneriertesDokumentJsonld;

export type RegelpruefungAntragResponse = ApiFormResponse<RegelpruefungAntragAntragJsonForm>;
export type RegelpruefungAntragStandort = RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort;

export type EmailFormResponse = ApiFormResponse<EmailAntrag>;

export type EinrichtungFormResponse =
    | MeldungFormResponse
    | LeistungsangebotFormResponse
    | LeistungsanbieterFormResponse
    | FemAntragResponse
    | EmailFormResponse
    | RegelpruefungAntragResponse;

export type TraegerverbandData = TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung;
export type TraegerverbandListResponse = InlineResponse<TraegerverbandData>;

export type TraegerResponse = TraegerJsonldTraegerRead & EnsureId;
export type TraegerDtoResponse = TraegerDtoJsonldTraegerReadRolleEinrichtung & EnsureId;
export type TraegerListResponse = InlineResponse<ApiTraegerGetCollection200Response>;

export type BehoerdenListResponse = InlineResponse<ApiBehoerdeGetCollection200Response>;
export type TableFilterListResponse = InlineResponse<TableFilterJsonld>;

export type ProfilHeimfinderListResponse = InlineResponse<HeimfinderprofilCollectionOutputJsonldHeimfinderList>;
export type MeldungHinweistextListResponse = InlineResponse<MeldungHinweistextJsonldMeldungHinweistextList>;
export type MeldungHinweisDokumentListResponse = InlineResponse<MeldungHinweisdokumentJsonldMeldungHinweistextList>;
export type DokumentPruefungListResponse = InlineResponse<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList>;

export type BenutzerEinrichtungenListResponse = InlineResponse<ZugehoerigeEinrichtungenDTO>;

// Requests
export type BenutzerRequest = BenutzerJsonldBenutzerCreate;
export type EinrichtungBenutzerRequest = EinrichtungBenutzerJsonld;
export type PutBenutzer =
    BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger;
