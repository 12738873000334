import { useCallback, useContext } from 'react';

import { STATUS } from 'constants/antragStatus';
import { UuidMappingFunction } from 'forms/hooks/useUuidValue';

import { FormStateActions, FormStateContext, FormStateData } from './FormStateProvider';
import { Message } from './useMessages';

export const useFormState = (): FormStateData & FormStateActions => {
    const { formStateData, setFormStateData } = useContext(FormStateContext);

    const setUuidMap = useCallback(
        (uuidMap: { [key: string]: string } | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                uuidMap: uuidMap,
            })),
        [setFormStateData]
    );

    const mapUuid: UuidMappingFunction = useCallback(
        (uuid?: string) => {
            if (uuid && checkIfValidUUID(uuid)) {
                return formStateData.uuidMap?.[uuid] || 'Virtuelles Konto';
            }
            return uuid;
        },
        [formStateData.uuidMap]
    );

    const setRequestMessage = useCallback(
        (message: Message | undefined) => {
            setFormStateData((prevState) => ({
                ...prevState,
                requestMessage: message,
            }));

            return message?.uuid;
        },
        [setFormStateData]
    );

    const setFormSubmitted = useCallback(
        (formSubmitted = false) =>
            setFormStateData((prevState) => ({
                ...prevState,
                formSubmitted,
            })),
        [setFormStateData]
    );

    const setAntragId = useCallback(
        (antragId: number | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                antragId,
            })),
        [setFormStateData]
    );

    const setAntragToken = useCallback(
        (antragToken: string | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                antragToken,
            })),
        [setFormStateData]
    );

    const setAntragStatus = useCallback(
        (antragStatus: STATUS | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                antragStatus,
            })),
        [setFormStateData]
    );

    const setEinrichtungId = useCallback(
        (einrichtungId: number | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                einrichtungId,
            })),
        [setFormStateData]
    );

    const setEinrichtungArt = useCallback(
        (einrichtungArt: string | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                einrichtungArt,
            })),
        [setFormStateData]
    );

    const progressStart = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isInProgress: true,
                loadingError: undefined,
            })),
        [setFormStateData]
    );

    const progressEnd = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isInProgress: false,
            })),
        [setFormStateData]
    );

    const submitStart = useCallback(
        (persist?: boolean) =>
            setFormStateData((prevState) => ({
                ...prevState,
                isSubmitting: true,
                isPersisting: Boolean(persist),
                loadingError: undefined,
            })),
        [setFormStateData]
    );

    const submitEnd = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isSubmitting: false,
                isPersisting: false,
            })),
        [setFormStateData]
    );

    const loadingStart = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isLoading: true,
                loadingError: undefined,
            })),
        [setFormStateData]
    );

    const loadingEnd = useCallback(
        () =>
            setFormStateData((prevState) => ({
                ...prevState,
                isLoading: false,
            })),
        [setFormStateData]
    );

    const setLoadingError = useCallback(
        (loadingError: string | undefined) =>
            setFormStateData((prevState) => ({
                ...prevState,
                loadingError,
            })),
        [setFormStateData]
    );

    return {
        ...formStateData,
        progressStart,
        progressEnd,
        submitStart,
        submitEnd,
        loadingStart,
        loadingEnd,
        setRequestMessage,
        setLoadingError,
        setUuidMap,
        setAntragId,
        setAntragToken,
        setEinrichtungArt,
        setEinrichtungId,
        setAntragStatus,
        setFormSubmitted,
        mapUuid,
    };
};

const checkIfValidUUID = (uuid?: string): boolean => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return Boolean(uuid && regexExp.test(uuid));
};
