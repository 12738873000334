import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { DeletedObjectDetails } from 'pages/Verwaltung/DeletedObjects/components/DeletedObjectDetails';

const Index = () => {
    return <DeletedObjectDetails pathBuilder={PathBuilder.home.verwaltung.deletedObjects} />;
};

export const DeletedObjectAnsehenPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_DELETED_OBJECTS
);
