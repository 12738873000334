import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { AdresseJsonldAdresseRead } from 'api/client';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { ComponentError } from 'forms/hooks/useErrors';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { ErrorList } from './components/ErrorList';
import { ReadonlyValueText } from './components/ReadonlyValueText';
import { RowControl } from './RowControl';

const AdresseLookupAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    handleChange,
    hasErrors,
    readonly,
    errors,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
    config,
    enabled,
}) => {
    const [errorList, setErrorList] = useState<ComponentError[]>(errors);
    const handleValueChange = useCallback(
        (value: SelectOption | null) => {
            handleChange(path, value, true);
        },
        [handleChange, path]
    );

    const labelVisuallyHidden = useMemo(() => uischema.labelVisuallyHidden ?? true, [uischema]);

    const getData = (query: string, options: AxiosRequestConfig): Promise<any> => {
        return backendApiService.getAddress({
            query,
            nrwOnly: schema?.custom?.addressLookupNrwOnly ?? true,
            regionalKey: schema?.custom?.adressLookupRegionalKey ?? undefined,
            options: { signal: options.signal },
        });
    };

    const getOption = (item: AdresseJsonldAdresseRead): SelectOption => {
        return {
            label: item.label,
            strasse: item.strasse,
            hausnummer: item.hausnummer,
            plz: item.plz,
            ort: item.ort,
            cy: item.cy,
            cx: item.cx,
        };
    };

    const hasErrorList = useMemo(() => {
        if (!required) return false;

        return (!data || !Object.keys(data || {}).includes('label')) && config.formTouched;
    }, [required, config, data]);

    useEffect(() => {
        if (required && hasErrorList) {
            setErrorList([{ message: 'Eingabe erforderlich', path }]);
        }
    }, [required, hasErrorList, path, data]);

    const isFieldEnabled = useMemo(() => enabled !== undefined && enabled, [enabled]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
            labelVisuallyHidden={labelVisuallyHidden}
        >
            {readonly ? (
                <ReadonlyValueText hasErrors={hasErrors} errors={errors} label={label} text={data?.label ?? '-'} />
            ) : (
                <>
                    <Typeahead
                        id={id}
                        data={data}
                        error={hasErrorList}
                        schema={schema}
                        uischema={uischema}
                        path={path}
                        getData={getData}
                        getOption={getOption}
                        onChange={handleValueChange}
                        multiple={false}
                        noOptionsText="Keine Adresse gefunden."
                        required={required}
                        enabled={isFieldEnabled}
                        placeholder="Adresse suchen"
                    />

                    {(hasErrors || hasErrorList) && (
                        <FormControl>
                            <FormHelperText component="div">
                                <ErrorList errors={errorList} />
                            </FormHelperText>
                        </FormControl>
                    )}
                </>
            )}
        </RowControl>
    );
};

export const AdresseLookupAutocompleteControl = withCustomControlProps(AdresseLookupAutocompleteControlComponent);
