import React, { ReactElement } from 'react';
import { Alert, List, ListItem, Typography } from '@mui/material';

import { DokumentJsonld, MeldungData } from 'api/client';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { theme } from 'theme/theme';

type StepData = Omit<MeldungData, 'stepHinweiseHinweistexte'> & {
    stepHinweiseHinweistexte: Array<{
        '@id': string;
        ueberschrift: string;
        text: string;
    }>;
};

interface HinweiseProps {
    data?: StepData;
}

export const Hinweise = ({ data }: HinweiseProps): ReactElement => {
    return (
        <>
            <Typography paragraph>
                Für alle Leistungsangebote, die in den Geltungsbereich des Wohn- und Teilhabegesetzes (WTG NRW) fallen,
                ist nach der (Erst-) Registrierung eine sog. Meldung anzufertigen (Ausnahme: Selbstverantwortete
                Wohngemeinschaften).
            </Typography>

            <Typography variant="h3" sx={{ marginBottom: theme.spacing(3) }}>
                So funktioniert es
            </Typography>

            <List className="numbered-list">
                <ListItem>
                    <Typography paragraph>
                        <strong>Daten eingeben und Dokumente hochladen</strong>
                        <br />
                        Für die Meldung sind die folgenden angebotsbezogenen Eingabemasken einmalig auszufüllen und
                        durch Upload von entsprechenden Dokumenten zu belegen.
                    </Typography>

                    <Alert severity="info">
                        Die erfassten Meldedaten werden bei jedem Betätigen des Buttons „Speichern und weiter“
                        abgespeichert. Sie können also einmal abgespeicherte Daten jederzeit weiter bearbeiten.
                    </Alert>
                </ListItem>

                <ListItem>
                    <Typography paragraph>
                        <strong>Daten auf Richtigkeit prüfen und Meldung abschicken</strong>
                        <br />
                        Wenn Sie die Erfassung der Meldedaten abgeschlossen haben und die Daten an die WTG-Behörde
                        absenden möchten, müssen Sie die Richtigkeit auf der letzten Seite “Zusammenfassung” bestätigen.
                        Es erfolgt eine automatische Weiterleitung an die zuständige Sachbearbeitung Ihrer WTG-Behörde.
                    </Typography>
                </ListItem>

                <ListItem>
                    <Typography paragraph>
                        <strong>Prüfung durch die WTG-Behörde</strong>
                        <br />
                        Die WTG-Behörde prüft Ihre Daten auf Plausibilität und Vollständigkeit. Sollten aus Sicht der
                        WTG-Behörde Angaben oder Dokumente ergänzt oder korrigiert werden, wird sie die Meldung zur
                        erneuten Bearbeitung an Sie zurückgeben. Das Leistungsangebot, für das die Meldung erneut
                        bearbeitet werden muss, wird Ihnen dann auf der Startseite angezeigt.
                    </Typography>

                    <Typography paragraph>
                        Besteht aus Sicht der WTG-Behörde kein Änderungsbedarf, wird sie die Meldung lediglich
                        bestätigen.
                    </Typography>
                </ListItem>

                <ListItem>
                    <Typography paragraph>
                        <strong>Bestätigung Ihrer Meldung</strong>
                        <br />
                        Hat die WTG-Behörde eine Meldung für ein Leistungsangebot bestätigt, sind danach nur noch
                        Änderungen, zu deren Anzeige Sie nach dem WTG bzw. nach der WTG-DVO verpflichtet sind
                        anzuzeigen.
                    </Typography>
                </ListItem>
            </List>

            {data?.stepHinweiseHinweistexte?.length || data?.stepHinweiseHinweisdokumente?.length ? (
                <DetailsLayout.Container divide={false}>
                    {data?.stepHinweiseHinweistexte?.length ? (
                        <>
                            {data.stepHinweiseHinweistexte.map((hinweis) => (
                                <DetailsLayout.Group
                                    title="Zusätzliche Hinweise Ihrer WTG-Behörde:"
                                    key={hinweis['@id']}
                                    variant="h3"
                                    divider
                                >
                                    <DetailsLayout.InfoField value={hinweis.ueberschrift} />
                                    <DetailsLayout.InfoField value={hinweis.text} />
                                </DetailsLayout.Group>
                            ))}
                        </>
                    ) : null}

                    {data.stepHinweiseHinweisdokumente?.length ? (
                        <DetailsLayout.Group title="Hinterlegte Hinweisdokumente Ihrer WTG-Behörde:" variant="h3">
                            <DetailsLayout.DownloadLink
                                value={data.stepHinweiseHinweisdokumente as Array<DokumentJsonld>}
                            />
                        </DetailsLayout.Group>
                    ) : null}
                </DetailsLayout.Container>
            ) : null}
        </>
    );
};
