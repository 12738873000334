import React from 'react';
import { LayoutProps, RankedTester, rankWith } from '@jsonforms/core';
import { JsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';

import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { NestedGroupType } from 'forms/types/UiSchemaTypes';
import { applyGridLayout } from 'forms/ui-schemas/elements/gridLayoutConfig';
import { buildPath, determineSchemaByUUID } from 'forms/utilities/SchemaUtils';

import { useNestedGroup } from './hooks/useNestedGroup';

interface NestedGroupProps extends Omit<LayoutProps, 'uischema'> {
    uischema: NestedGroupType;
}

const NestedGroupComponent: React.FC<NestedGroupProps> = ({ schema, uischema, data }: NestedGroupProps) => {
    const { config, handleChange } = useJsonFormsState();

    const { collectionField, collectionData, collectionSchema } = useNestedGroup(data, uischema, schema);

    if (undefined === collectionData || collectionData.length === 0) {
        return <></>;
    }

    return (
        <>
            {collectionData.map((d: any, i: number) => (
                <JsonForms
                    key={i}
                    schema={determineSchemaByUUID(d.uuid, collectionSchema)}
                    uischema={applyGridLayout(uischema.uischema, uischema.gridLayout)}
                    data={d}
                    onChange={(state) => {
                        handleChange(buildPath(collectionField, i), state.data);
                    }}
                    renderers={renderers}
                    config={{
                        ...config,
                        path: buildPath(config.path, collectionField, i),
                    }}
                />
            ))}
        </>
    );
};
// @ts-ignore
export const NestedGroup = withJsonFormsLayoutProps(NestedGroupComponent);

export const NestedGroupTester: RankedTester = rankWith(1, (uischema) => uischema.type === 'NestedGroup');
