import { useCallback, useEffect, useMemo, useState } from 'react';

import { Step, Steps } from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { UiSchemaType } from 'forms/types/UiSchemaTypes';
import { TabConfig } from 'layout/menu/TabNavigation';
import { buildRelativePath } from 'navigation/Paths';
import { capitalize } from 'utilities/Utils';

export interface TabComponentProps {
    description?: string;
    uischema?: UiSchemaType;
}

export type Tab = TabConfig<TabComponentProps>;

interface UseTabsConfig {
    tabs: Tab[];
    nextTab: Tab;
    currentIndex: number;
    prevTabPath?: string;
    nextTabPath?: string;
    tabHeadline: string | undefined;
    tabActions: ACTIONS[];
    hasErrors?: boolean;
    disabled?: boolean;
}

interface UseTabsConfigProps {
    tabsDictionary: TabConfig<TabComponentProps>[];
    basePath: string;
    step?: string;
    steps?: Steps;
}

export const useTabsConfig = ({ tabsDictionary = [], basePath, step, steps }: UseTabsConfigProps): UseTabsConfig => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const getActiveStep = useCallback(
        (tab: TabConfig<TabComponentProps>): Step =>
            (steps || []).find((step) => step?.state === 'active' && step.step === tab.value),
        [steps]
    );

    const tabs = useMemo(() => {
        if (!tabsDictionary.length) return [];

        return tabsDictionary
            .filter((tab) => {
                if (!steps?.length && tab?.component) {
                    return tab;
                }

                return getActiveStep(tab);
            })
            .map((tab) => {
                const activeStep = getActiveStep(tab);

                return {
                    ...tab,
                    headline: activeStep?.headline,
                    label: activeStep?.title || tab.label || capitalize(tab.value),
                    actions: activeStep?.actions || [],
                    hasErrors: !!activeStep?.hasErrors,
                    disabled: !!activeStep?.disabled,
                };
            });
    }, [getActiveStep, steps?.length, tabsDictionary]);

    const tabSlug = useCallback(
        (prev: boolean = false): string | undefined => tabs[prev ? currentIndex - 1 : currentIndex + 1]?.value,
        [tabs, currentIndex]
    );

    const currentTab = useMemo(() => tabs?.[currentIndex], [tabs, currentIndex]);
    const nextTab = useMemo(() => tabs?.[currentIndex + 1], [tabs, currentIndex]);

    const prevTabPath = useMemo(() => {
        const tab = tabs.find((tab) => tab.value === tabSlug(true));

        return tab ? buildRelativePath(basePath, tab.value) : undefined;
    }, [tabs, basePath, tabSlug]);

    const nextTabPath = useMemo(() => {
        const tab = tabs.find((tab) => tab.value === tabSlug());

        return tab ? buildRelativePath(basePath, tab.value) : undefined;
    }, [tabs, basePath, tabSlug]);

    const tabHeadline: string | undefined = useMemo(() => {
        return currentTab?.headline;
    }, [currentTab]);

    const tabActions: ACTIONS[] = useMemo(() => {
        return currentTab?.actions || [];
    }, [currentTab]);

    const hasErrors: boolean = useMemo(() => !!currentTab?.hasErrors, [currentTab]);

    const isDisabled: boolean = useMemo(() => !!currentTab?.disabled, [currentTab]);

    useEffect(() => {
        if (!tabs.length) return;

        setCurrentIndex(tabs.findIndex((tab) => tab.value === step) || 0);
    }, [step, tabs]);

    return {
        tabs,
        nextTab,
        currentIndex,
        prevTabPath,
        nextTabPath,
        tabHeadline,
        tabActions,
        disabled: isDisabled,
        hasErrors,
    };
};
