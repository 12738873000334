import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { ProfilHeimfinderListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useProfilHeimfinderListData = (): GetData<ProfilHeimfinderListResponse> => {
    return useCallback(({ page, rowsPerPage }) => {
        return backendApiService.getProfilHeimfinderCollection({
            page,
            itemsPerPage: rowsPerPage,
        });
    }, []);
};
