import { backendApiService } from 'api/ApiService';
import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useLeistungsanbieterAntragListAction = () => {
    return useListActions(
        PathBuilder.home.aufgaben.freigeben.leistungsanbieter,
        backendApiService.workflowLeistungsanbieterItem.bind(backendApiService)
    );
};
