import { useEffect } from 'react';

import { useIsLoggedIn } from 'api/auth';

import { useBenutzerAufgaben } from './hooks/useBenutzerAufgabenState';

export const BenutzerAufgaben = () => {
    const isLoggedIn = useIsLoggedIn();
    const { loadBenutzerAufgaben, hasBenutzerAufgaben } = useBenutzerAufgaben();

    useEffect(() => {
        if (!hasBenutzerAufgaben && isLoggedIn) {
            loadBenutzerAufgaben();
        }
    }, [hasBenutzerAufgaben, loadBenutzerAufgaben, isLoggedIn]);

    return <></>;
};
