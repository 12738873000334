import { Typography } from '@mui/material';

import { Adresse } from 'components/Adresse/Adresse';
import { PageHeading } from 'components/PageHeading';
import {
    KONTAKT_BASE_URL,
    KONTAKT_DNRW,
    KONTAKT_DNRW_URL,
    KONTAKT_MAGS,
    KONTAKT_MAGS_EMAIL_POSTSTELLE,
    KONTAKT_MAGS_FAX_IMPRESSUM,
    KONTAKT_MAGS_HNR,
    KONTAKT_MAGS_PLZ,
    KONTAKT_MAGS_STADT,
    KONTAKT_MAGS_STR,
    KONTAKT_MAGS_TEL,
    KONTAKT_PUBLIC_PLAN,
    KONTAKT_PUBLIC_PLAN_URL,
} from 'constants/content';
import { Link } from 'elements/Link';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkExtern } from 'elements/LinkExtern';
import { LinkTel } from 'elements/LinkTel';
import { ContentContainer } from 'layout/container/ContentContainer';
import { TextContainer } from 'layout/container/TextContainer';
import { withSideBar } from 'layout/hooks/useSideBar';

const Impressum = () => {
    return (
        <ContentContainer title="Impressum">
            <TextContainer>
                <PageHeading title="Impressum" />

                <Typography variant="h2">Herausgeber</Typography>
                <Adresse
                    name={KONTAKT_MAGS}
                    strasse={KONTAKT_MAGS_STR}
                    hausnummer={KONTAKT_MAGS_HNR}
                    plz={KONTAKT_MAGS_PLZ}
                    ort={KONTAKT_MAGS_STADT}
                />
                <Typography paragraph>
                    Tel. <LinkTel msisdn={KONTAKT_MAGS_TEL} />
                    <br />
                    Fax <LinkTel msisdn={KONTAKT_MAGS_FAX_IMPRESSUM} />
                    <br />
                    E-Mail <LinkEmail email={KONTAKT_MAGS_EMAIL_POSTSTELLE} />
                </Typography>

                <Typography variant="h2">Technische Umsetzung und Programmierung</Typography>
                <Typography paragraph>
                    {KONTAKT_DNRW}, <LinkExtern href={KONTAKT_DNRW_URL} />
                    <br />
                    in Zusammenarbeit mit
                    <br />
                    {KONTAKT_PUBLIC_PLAN}, <LinkExtern href={KONTAKT_PUBLIC_PLAN_URL} />
                    <br />
                </Typography>

                <Typography variant="h2">Technischer Betrieb</Typography>
                <Typography paragraph>Landesbetrieb Information und Technik Nordrhein-Westfalen (IT.NRW)</Typography>

                <Typography variant="h2">Urheberrecht</Typography>
                <Typography paragraph>
                    Die Inhalte dieser Website, insbesondere alle Texte, Bilder, Grafiken, Logos, Ton-, Video- und
                    Animationsdateien sowie auch ihre Arrangements, unterliegen dem Urheberrecht und anderen Gesetzen
                    zum Schutze des geistigen Eigentums.
                </Typography>

                <Typography variant="h2">Haftung, Haftungsausschluss und Disclaimer</Typography>
                <Typography paragraph>Verantwortlich im Sinne §&nbsp;7 TMG</Typography>
                <Typography paragraph>
                    Gerhard Herrmann, Leiter der Abteilung VI, „Pflege, Alter, demographische Entwicklung“
                </Typography>

                <Typography variant="h2">Rechtliche Hinweise im Sinne §§&nbsp;7-10 TMG</Typography>
                <ol>
                    <li>
                        "
                        <Link href={KONTAKT_BASE_URL} />" ist nach §&nbsp;7 Abs.&nbsp;1 TMG für die eigenen Inhalte, die
                        es zur Nutzung bereithält, nach den allgemeinen Vorschriften verantwortlich. Die Haftung für
                        Schäden materieller oder ideeller Art, die durch die Nutzung der Inhalte verursacht wurden, ist
                        ausgeschlossen, sofern nicht Vorsatz oder grobe Fahrlässigkeit vorliegt.
                    </li>
                    <li>
                        Soweit ein Text von dritter Seite erstellt ist, wird die jeweilige Verfasserin oder der
                        jeweilige Verfasser oder die verantwortliche Einrichtung namentlich benannt. In diesen Fällen
                        ist die Verfasserin oder der Verfasser bzw. die genannte Einrichtung des jeweiligen Dokuments
                        für den Inhalt verantwortlich.
                        <br />
                        <br />"
                        <Link href={KONTAKT_BASE_URL} />
                        " bewertet den Inhalt der verzeichneten Web-Sites zum Zeitpunkt ihrer Aufnahme in das
                        Verzeichnis sorgfältig, und es werden nur solche Verweise aufgenommen, deren Inhalt nach Prüfung
                        zum Zeitpunkt der Aufnahme nicht gegen geltendes Recht verstößt.
                        <br />
                        <br />"
                        <Link href={KONTAKT_BASE_URL} />
                        " bewertet den Inhalt der verzeichneten Web-Sites zum Zeitpunkt ihrer Aufnahme in das
                        Verzeichnis sorgfältig, und es werden nur solche Verweise aufgenommen, deren Inhalt nach Prüfung
                        zum Zeitpunkt der Aufnahme nicht gegen geltendes Recht verstößt.
                        <br />
                        <br />"
                        <Link href={KONTAKT_BASE_URL} />
                        " macht sich den Inhalt der zugänglich gemachten fremden Websites jedoch ausdrücklich nicht zu
                        Eigen. Die Inhalte der Sites, auf die verwiesen wird, können sich ständig ändern - das macht
                        gerade das Wesen eines www-Angebots aus. Aus diesem Grund übernimmt "
                        <Link href={KONTAKT_BASE_URL} />
                        " trotz Prüfung keine Gewähr für die Korrektheit, Vollständigkeit und Verfügbarkeit der
                        jeweiligen fremden Website."
                        <Link href={KONTAKT_BASE_URL} />
                        " hat keinen Einfluss auf die aktuelle und zukünftige Gestaltung und Inhalte der Seiten. "
                        <Link href={KONTAKT_BASE_URL} />" übernimmt keine Haftung für Schäden, die aus der Benutzung der
                        Links entstehen könnten.
                    </li>
                </ol>
            </TextContainer>
        </ContentContainer>
    );
};

export const ImpressumPage = withSideBar(Impressum, []);
