import React from 'react';
import { Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import { PATH_BENUTZER, PATH_BENUTZER_EINRICHTUNGEN, PATH_CREATE, PATH_EDIT, PATH_VIEW } from 'navigation/Paths';
import { BenutzerCreatePage } from 'pages/Benutzer/BenutzerCreatePage';
import { BenutzerDetailPage } from 'pages/Benutzer/BenutzerDetailPage';
import { BenutzerEditPage } from 'pages/Benutzer/BenutzerEditPage';
import { BenutzerEinrichtungenPage } from 'pages/Benutzer/BenutzerEinrichtungenPage';
import { BenutzerOverviewPage } from 'pages/Benutzer/BenutzerOverviewPage';
import { Error404Page } from 'pages/Error404';

export const BenutzerRoutes = () => {
    const { SentryRoutes } = useSentry();

    return (
        <SentryRoutes>
            <Route path={PATH_BENUTZER}>
                <Route path={PATH_CREATE} element={<BenutzerCreatePage />} />

                <Route path={':benutzerId'}>
                    <Route path={PATH_VIEW} element={<BenutzerDetailPage />} />
                    <Route path={PATH_EDIT} element={<BenutzerEditPage />} />
                    <Route path={PATH_BENUTZER_EINRICHTUNGEN} element={<BenutzerEinrichtungenPage />} />
                </Route>

                <Route index element={<BenutzerOverviewPage />} />

                <Route path="*" element={<Error404Page />} />
            </Route>
        </SentryRoutes>
    );
};
