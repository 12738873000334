import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { useLogout } from 'api/auth';
import { FormButton } from 'forms/components/FormButton';
import { PathBuilder } from 'navigation/Paths';

interface SuccessProps {
    email: string;
}

export const SuccessMessage: React.FC<SuccessProps> = ({ email }) => {
    const logout = useLogout();
    const navigate = useNavigate();
    return (
        <>
            <Typography variant="h1">E-Mail-Adresse erfolgreich geändert</Typography>

            <Typography paragraph>Ihre E-Mail-Adresse wurde erfolgreich auf</Typography>

            <Typography style={{ fontWeight: 700 }} paragraph>
                {email}
            </Typography>

            <Typography paragraph>geändert.</Typography>

            <Typography style={{ fontWeight: 700 }} paragraph>
                Bitte melden Sie sich mit Ihren neuen Zugangsdaten bei PfAD.wtg an.
            </Typography>

            <Grid container spacing={1} direction={'row-reverse'}>
                <Grid item>
                    <FormButton
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            logout();
                            navigate(PathBuilder.home.path);
                        }}
                    >
                        Zum Login
                    </FormButton>
                </Grid>
            </Grid>
        </>
    );
};
