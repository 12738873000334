import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { ComponentError } from './useErrors';

type HTMLAnyInput = HTMLInputElement | HTMLTextAreaElement;
type TempControlDataOptions = {
    validateOnChange?: boolean;
    validateOnBlur?: boolean;
};

export type EventToDataFunction<T> = (e: ChangeEvent<HTMLAnyInput>) => T;

export const useTempControlData = <T>(
    data: T,
    path: string,
    errors: ComponentError[],
    handleChange: (path: string, data: T, validate?: boolean) => void,
    eventToData: EventToDataFunction<T>,
    onTouched: () => void,
    { validateOnChange, validateOnBlur }: TempControlDataOptions = { validateOnChange: true, validateOnBlur: true }
) => {
    const timer = useRef<NodeJS.Timeout | undefined>();
    const [tempData, setTempData] = useState(data);

    const toValue = (e: ChangeEvent<HTMLAnyInput> | FocusEvent<HTMLAnyInput> | string): T => {
        return typeof e === 'string' ? (e as unknown as T) : eventToData(e);
    };

    const handleValueChange = (e: ChangeEvent<HTMLInputElement | any> | string) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        const value = toValue(e);

        setTempData(value);
        onTouched();

        timer.current = setTimeout(() => {
            handleChange(path, value, validateOnChange);
        }, 500);
    };

    const handleOnInput = (e?: any) => {
        const currValue = e ? toValue(e) ?? tempData : tempData;
        if (isEqual(tempData, data) && !e) return;

        handleChange(path, currValue, validateOnBlur);
    };

    const handleOnBlur = (e?: any) => {
        onTouched();

        const currValue = e ? toValue(e) ?? tempData : tempData;

        if (isEqual(tempData, data) && !e) return;

        if (validateOnBlur) {
            handleChange(path, currValue, true);
        }
    };

    useEffect(() => {
        setTempData((prev) => (isEqual(data, prev) ? prev : data));
    }, [data]);

    return {
        tempData,
        handleValueChange,
        handleOnBlur,
        handleOnInput,
    };
};
