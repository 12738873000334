import { useRef } from 'react';
import { LiveMessage } from 'react-aria-live';
import { Grid, Popper, styled } from '@mui/material';

import { Message, useMessages } from 'forms/state/useMessages';
import { MessageAlert } from 'layout/messages/components/MessageAlert';
import { MultiErrorMessageAlert } from 'layout/messages/components/MultiErrorMessageAlert';

const MESSAGE_WIDTH: string | number = 600;

export const MessageStack = () => {
    const ref = useRef<HTMLDivElement>(null);
    const { messages, closeMessage } = useMessages();
    const messageStack = useMessageStack(messages);

    return (
        <>
            <Anchor ref={ref} />
            <StyledPopper
                open={Boolean(ref.current && messages.length > 0)}
                anchorEl={ref.current}
                placement="bottom"
                disablePortal
            >
                <Grid container style={{ width: MESSAGE_WIDTH }} justifyContent="center">
                    {messageStack.map((messageStackItem, i) => (
                        <Grid item xs={12} key={i}>
                            {Array.isArray(messageStackItem) ? (
                                <MultiErrorMessageAlert messages={messageStackItem} />
                            ) : (
                                <>
                                    <LiveMessage aria-live="assertive" message={messageStackItem.text} />
                                    <MessageAlert message={messageStackItem} closeMessage={closeMessage} />
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </StyledPopper>
        </>
    );
};

const useMessageStack = (messages: Message[]): (Message | Message[])[] => {
    const summableErrorMessages = (message: Message) =>
        message.type === 'error' && !message.closable && message.autoCloseSeconds === undefined;

    const messagesToSummarize = messages.filter((m) => summableErrorMessages(m));

    if (messagesToSummarize.length <= 1) {
        return messages;
    }

    const stackedMessages: (Message | Message[])[] = messages.filter((m) => !summableErrorMessages(m));
    const index = messages.findIndex(summableErrorMessages);
    stackedMessages.splice(index, 0, messagesToSummarize);
    return stackedMessages;
};

const Anchor = styled('div')(({ theme }) => ({
    height: 0,
    width: 0,
    position: 'fixed',
    top: 100,
    left: '50%',
}));

const StyledPopper = styled(Popper)(() => ({
    zIndex: 1010,
}));
