import React from 'react';
import { Grid } from '@mui/material';

import { DashboardCard } from 'components/Dashboard/DashboardCard';
import { PageHeading } from 'components/PageHeading';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';
import { HilfeSupport } from 'pages/Dashboard/HilfeSupport';

export const DashboardBezReg = () => {
    return (
        <ContentContainer title="Startseite">
            <StyledContainer>
                <PageHeading title="Startseite" />

                <Grid container spacing={5} justifyContent="flex-start">
                    <Grid item md={6}>
                        <DashboardCard
                            headerTitle="Benutzer"
                            cardActions={[
                                {
                                    href: PathBuilder.home.verwaltung.benutzer.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <DashboardCard
                            headerTitle="Leistungsangebote"
                            cardActions={[
                                {
                                    href: PathBuilder.home.verwaltung.landschaftsverband.path,
                                    title: 'Zur Übersicht',
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item md={12}>
                        <HilfeSupport />
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};
