import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiTraegerverbandItem } from 'api/hooks/useApiClient';
import { TraegerverbandData } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { schema, uiSchema } from 'pages/Traegerverband/formConfig';

const Index = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const pathBuilder = PathBuilder.home.verwaltung.traegerverband.path;

    const { isLoading, data, error } = useApiTraegerverbandItem(String(id));

    const handleSubmit = (formData: TraegerverbandData) => {
        return backendApiService.patchTraegerverbandItem(String(id), formData);
    };

    return (
        <ContentContainer title="Trägerverband bearbeiten">
            <StyledContainer>
                {!error ? (
                    <SimpleForm
                        isLoading={isLoading}
                        data={data}
                        schema={schema}
                        uiSchema={uiSchema}
                        submitLabel="Trägerverband aktualisieren"
                        onSubmit={handleSubmit}
                        onAfterSubmit={() => navigate(pathBuilder)}
                        messages={{
                            success: 'Trägerverband wurde aktualisiert.',
                            error: 'Trägerverband konnte nicht aktualisiert werden.',
                        }}
                        backLink={{
                            title: 'Abbrechen',
                            path: pathBuilder,
                        }}
                    />
                ) : (
                    <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};

export const TraegerverbandEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_TRAEGERVERBAENDE_FORM
);
