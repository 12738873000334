import { useMemo } from 'react';

import { Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

interface FormAddButton {
    label: string;
    scope: string;
}

export const useFormAddButtons = (scopes: string[], addButtonLabels: string[], schema: Schema): FormAddButton[] =>
    useMemo(() => {
        if (!scopes) {
            return [];
        }

        const isAddable = (scope: string, schema: Schema) => {
            const fieldSchema = getFieldSchemaByScope(scope, schema);
            return Boolean(scope && fieldSchema?.custom?.addable);
        };

        const toFormAddButton = (scope: string) => ({
            scope,
            label: addButtonLabels[scopes.indexOf(scope)] || addButtonLabels[0],
        });

        return scopes.filter((scope) => isAddable(scope, schema)).map(toFormAddButton);
    }, [scopes, addButtonLabels, schema]);
