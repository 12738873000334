import { useCallback, useContext, useEffect, useMemo } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { Blocker, History, Transition } from 'history';

import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { FormState } from 'forms/types/UiSchemaTypes';
import { deepEqual } from 'utilities';

export const useFormLeaveWarning = (
    initialData: FormState | undefined,
    currentData: FormState,
    isPersisting: boolean
): { isDirty: boolean } => {
    const isDirty = useMemo(
        () => !deepEqual(initialData, currentData, { ignoreUndefinedFields: true }),
        [currentData, initialData]
    );

    usePrompt(
        useMemo(
            () => ({
                alertText: 'Einige Änderungen sind noch nicht gespeichert und gehen beim Verlassen der Seite verloren.',
                confirmLabel: 'Seite verlassen',
                denyLabel: 'Auf Seite bleiben',
            }),
            []
        ),
        !isPersisting && isDirty
    );

    return {
        isDirty,
    };
};

function useBlocker(blocker: Blocker, when = true) {
    const navigation = useContext(NavigationContext).navigator as History;

    useEffect(() => {
        if (!when) return;

        const unblock = navigation.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigation, blocker, when]);
}

function usePrompt(config: { alertText: string; confirmLabel?: string; denyLabel?: string }, when = true) {
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const blocker = useCallback(
        (tx: Transition) => {
            showConfirmation({
                ...config,
                denyAction: closeConfirmation,
                confirmAction: () => {
                    tx.retry();
                    closeConfirmation();
                },
            });
        },
        [config, closeConfirmation, showConfirmation]
    );

    useBlocker(blocker, when);
}
