export const beschaeftigte_table = {
    type: 'SummaryTable',
    label: 'Beschäftigte',
    columns: 3,
    scope: '#/properties/beschaeftigte',
    hideTableHead: true,
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigteIntensivpflegeCheck',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigteIntensivpflegeDatenAnzahl',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigteIntensivpflegeDatenVZA',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigtepflegePersonalHauswirtschaftCheck',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigtepflegePersonalPalliativversorgungCheck',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck',
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/stepBeschaeftigteEinrichtungsleitung',
            showLabel: true,
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Name',
                    scope: '#/properties/personalName',
                },
                {
                    type: 'FieldRow',
                    label: 'Stellenanteil',
                    scope: '#/properties/personalStellenAnteil',
                },
                {
                    type: 'FieldRow',
                    label: 'Tätig seit',
                    scope: '#/properties/personalTaetigSeit',
                },
                {
                    type: 'FieldRow',
                    label: 'E-Mail',
                    scope: '#/properties/personalEmail',
                },
                {
                    type: 'FieldRow',
                    label: 'Telefon',
                    scope: '#/properties/personalTelefon',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigteEinrichtungsleitungDokumente',
            label: 'Dokumente der Einrichtungsleitung',
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/stepBeschaeftigtePflegedienstleitung',
            showLabel: true,
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Name',
                    scope: '#/properties/fachkraftName',
                },
                {
                    type: 'FieldRow',
                    label: 'Stellenanteil',
                    scope: '#/properties/fachkraftStellenAnteil',
                },
                {
                    type: 'FieldRow',
                    label: 'Tätig seit',
                    scope: '#/properties/fachkraftTaetigSeit',
                },
                {
                    type: 'FieldRow',
                    label: 'E-Mail',
                    scope: '#/properties/fachkraftEmail',
                },
                {
                    type: 'FieldRow',
                    label: 'Telefon',
                    scope: '#/properties/fachkraftTelefon',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepBeschaeftigtePflegedienstleitungDokumente',
            label: 'Dokumente der Pflegedienstleitung',
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/field401Label',
            showLabel: true,
            entries: [],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field401',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field401b',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field401c',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field401d',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field401a',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/field402Label',
            showLabel: true,
            entries: [],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field402',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'SubTable',
            level: 1,
            scope: '#/properties/field402a',
            showLabel: true,
            entries: [
                {
                    type: 'SubTable',
                    level: 0,
                    scope: '#/properties',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/vza',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/anzahl',
                        },
                    ],
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizBeschaeftigte',
        },
    ],
};
