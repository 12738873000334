import { rankWith, Tester } from '@jsonforms/core';

import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

export const isVersorgungsvertragLink: Tester = (uischema: any, schema: any): boolean => {
    if (uischema.type !== 'Control') return false;

    const field = getFieldSchemaByScope(uischema.scope, schema);

    return ((field?.custom?.block_prefixes || []) as Array<string>).includes('download_link');
};

export const DownloadLinkTester = rankWith(10000, isVersorgungsvertragLink);
