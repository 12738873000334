import { useState } from 'react';
import { Checkbox } from '@mui/material';

import { backendApiService } from 'api/ApiService';

interface CheckboxPruefberichtProps {
    id: string;
    isChecked: boolean;
    leistungsangebotName: string;
}

export const CheckboxPruefbericht = ({ id, isChecked, leistungsangebotName }: CheckboxPruefberichtProps) => {
    const [checked, setChecked] = useState<boolean>(isChecked);
    const label = isChecked
        ? `Status für ${leistungsangebotName} auf ungeprüft setzen`
        : `Status für ${leistungsangebotName} auf geprüft setzen`;

    const handleCheckboxChange = (checked: boolean) => {
        setChecked(checked);
        backendApiService.patchPruefberichtGeprueftStatus(String(id));
    };

    return (
        <Checkbox
            checked={checked}
            onChange={(event, checked) => handleCheckboxChange(checked)}
            inputProps={{ 'aria-checked': checked, 'aria-label': label }}
        />
    );
};
