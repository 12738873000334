import { backendApiService } from 'api/ApiService';
import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useFemAntragListActions = () => {
    return useListActions(
        PathBuilder.home.verwaltung.femAntrag,
        backendApiService.workflowFemItem.bind(backendApiService)
    );
};
