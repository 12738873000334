import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { DetailPage } from 'pages/Leistungsangebote/Freigegebene/DetailPage';

const Index = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    return <DetailPage pathBuilder={pathBuilder} tablePathBuilder={pathBuilder} />;
};

export const LeistungsangebotDetailPage: React.FC<{ pathBuilder: PathBuilderProps }> = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG
);
