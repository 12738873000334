import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import { ACTIONS } from 'constants/antragActions';
import { Link } from 'elements/Link';
import { ErrorIcon } from 'forms/components/ErrorIcon';
import { FormState } from 'forms/types/UiSchemaTypes';
import { buildRelativePath } from 'navigation/Paths';

export type BaseComponentProps = Record<string, any>;

export interface TabConfig<ComponentProps> {
    value: string;
    label?: string;
    headline?: string;
    description?: ReactNode;
    actions?: Array<ACTIONS>;
    component?: React.FC<ComponentProps>;
    hasErrors?: boolean;
    disabled?: boolean;
    initialLoading?: boolean;
}

interface TabNavigationProps {
    tabs: TabConfig<BaseComponentProps>[];
    currentIndex: number;
    data?: FormState;
    basePath?: string;
    onTabChange?: (tab: TabConfig<BaseComponentProps>) => void;
    fullContentWidth?: boolean;
    defaultComponent?: React.ReactElement;
}

export const TabNavigation: React.FC<TabNavigationProps> = ({
    tabs,
    basePath,
    currentIndex,
    data,
    onTabChange,
    fullContentWidth,
    defaultComponent,
}) => {
    const style = fullContentWidth
        ? {
              background: 'grey',
              paddingLeft: '0',
              paddingRight: '0',
          }
        : {};

    const [activeTab, setActiveTab] = useState<string>('');

    const handleCurrentTabChange = useCallback(
        (currentTab: TabConfig<BaseComponentProps>) => onTabChange?.(currentTab),
        [onTabChange]
    );

    useEffect(() => {
        if (tabs[currentIndex]?.value) {
            setActiveTab(tabs[currentIndex].value);
        }
    }, [currentIndex, tabs]);

    return (
        <>
            {activeTab && (
                <TabContext value={activeTab}>
                    {tabs.length > 1 ? (
                        <TabList
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={(_: SyntheticEvent, newTab: string) => {
                                handleCurrentTabChange(tabs.find((tab) => tab.value === newTab) || tabs[currentIndex]);
                            }}
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    icon={tab.hasErrors ? <ErrorIcon /> : <></>}
                                    iconPosition="start"
                                    disabled={tab.disabled}
                                    component={Link}
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                    to={buildRelativePath(basePath, tab.value)}
                                />
                            ))}
                        </TabList>
                    ) : null}

                    {tabs.map((tab) => (
                        <TabPanel
                            key={tab.value}
                            value={tab.value}
                            style={style}
                            sx={tabs.length > 1 ? { paddingBottom: 0 } : { paddingBottom: 0, paddingTop: 0 }}
                        >
                            {tab?.description || null}

                            {tab?.component ? <tab.component data={data} /> : defaultComponent || null}
                        </TabPanel>
                    ))}
                </TabContext>
            )}
        </>
    );
};
