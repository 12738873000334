import React from 'react';
import { JsonForms } from '@jsonforms/react';
import { Alert, Divider, Grid, Typography, useTheme } from '@mui/material';

import { FormLoadingButton } from 'forms/components/FormButton';
import { schema, uiSchema } from 'forms/Meldung/configs/meldungCreateConfig';
import { renderers } from 'forms/renderers';
import { PathBuilder } from 'navigation/Paths';
import { theme } from 'theme/theme';
import { useFormLayoutConfig } from 'utilities/hooks/useFormLayoutConfig';

import { useFormMeldungCreate } from './useFormMeldungCreate';

interface MeldungCreateFormProps {
    onCreate(): void;
}

export const MeldungCreateForm = ({ onCreate }: MeldungCreateFormProps) => {
    const theme = useTheme();
    const config = useFormLayoutConfig();

    const { isLoading, selectedEinrichtung, handleMeldungCreate, handleMeldungFormChange } = useFormMeldungCreate({
        onCreate,
        pathBuilder: PathBuilder.home.verwaltung.meldung,
    });

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2">Meldung erstellen</Typography>
            </Grid>

            <Grid item xs={9}>
                <Alert severity="info">
                    In der Auswahl werden nur Leistungsangebote angezeigt für die keine offene Meldung ("Eingereicht",
                    "Erneut eingereicht", "Entwurf" oder "Zurückgewiesen") existiert.
                </Alert>
            </Grid>

            <Grid item xs={6}>
                <JsonForms
                    data={null}
                    renderers={renderers}
                    schema={schema}
                    uischema={uiSchema}
                    config={config}
                    onChange={handleMeldungFormChange}
                />
            </Grid>

            <Grid item xs={4}>
                <FormLoadingButton
                    disabled={!selectedEinrichtung || !Object.keys(selectedEinrichtung).length}
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleMeldungCreate}
                    loading={isLoading}
                    style={{ marginBottom: theme.spacing(3) }}
                >
                    Meldung erstellen
                </FormLoadingButton>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 0, marginTop: theme.spacing(2) }}>
                <Divider style={{ marginBottom: theme.spacing(4) }} />
            </Grid>
        </Grid>
    );
};
