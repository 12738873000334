import * as React from 'react';
import { useMemo } from 'react';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';

import { DezimalFormat } from 'forms/components/NumberFormats';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { useFormState } from 'forms/state/useFormState';
import { formatDezimal } from 'forms/utilities/formatter';
import { getControlLabel } from 'forms/utilities/SchemaUtils';
import { stringToNumber } from 'utilities/Utils';

import { RowControl } from './RowControl';

const DezimalzahlControlComponent: React.FC<CustomControlProps<number | undefined>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    unit,
    disabled,
    readonly,
    formula,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
}) => {
    const { formSubmitted } = useFormState();

    const { tempData, handleValueChange } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        (e) => stringToNumber(e.target.value),
        onTouched,
        {
            validateOnBlur: false,
            validateOnChange: formSubmitted && hasErrors,
        }
    );

    const controlLabel = getControlLabel(uischema, label, schema);
    const isReadonly = useMemo(
        () => uischema.options?.readonly || readonly || uischema.options?.disabled || disabled,
        [uischema, readonly, disabled]
    );

    const { scrollRef } = useScrollInView<HTMLDivElement>({});

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            controlOnly={uischema.controlOnly}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            {readonly ? (
                <ReadonlyValueText
                    hasErrors={hasErrors}
                    errors={errors}
                    text={formatDezimal(data, unit) || '-'}
                    textAlign={'right'}
                    label={label}
                />
            ) : (
                <>
                    <TextField
                        id={id}
                        name={id}
                        fullWidth
                        required={required}
                        disabled={isReadonly}
                        value={tempData ?? ''}
                        error={hasErrors}
                        onChange={handleValueChange}
                        ref={scrollRef}
                        InputProps={{
                            inputComponent: DezimalFormat as any,
                            endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
                        }}
                        inputProps={{
                            style: { textAlign: 'right' },
                            min: schema.custom?.min,
                            max: schema.custom?.max,
                            readOnly: uischema.options?.readonly,
                            disabled: isReadonly,
                            'data-cy': `form_${path}`,
                        }}
                        placeholder=""
                        label={controlLabel}
                    />

                    {schema.description && <FormHelperText component="div">{schema.description}</FormHelperText>}

                    {hasErrors && (
                        <FormHelperText component="div">
                            <ErrorList errors={errors} />
                        </FormHelperText>
                    )}
                </>
            )}
        </RowControl>
    );
};

export const DezimalzahlControl = withCustomControlProps(DezimalzahlControlComponent);
