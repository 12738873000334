import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useBenutzerDtoItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPE_LABELS } from 'constants/labels';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const Index = () => {
    const { benutzerId } = useParams();

    const { isLoading, data: benutzer } = useBenutzerDtoItem(String(benutzerId));

    const benutzerPageTitle = useMemo(() => `${benutzer?.anrede} ${benutzer?.vorname} ${benutzer?.name}`, [benutzer]);

    return (
        <ContentContainer title="Benutzer: Ansehen">
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading}>
                    <BaseGridContainer>
                        <PageHeading title={`Benutzer: ${benutzerPageTitle}`} />

                        <DetailsLayout.Container>
                            <DetailsLayout.InfoField value={ROLE_TYPE_LABELS[benutzer?.rolle || '']} label="Mandant" />
                            <DetailsLayout.InfoField value={benutzer?.mandantName} label="Mandantenname" />
                            <DetailsLayout.InfoField value={benutzer?.anrede} label="Anrede" />
                            <DetailsLayout.InfoField value={benutzer?.name} label="Name" />
                            <DetailsLayout.InfoField value={benutzer?.vorname} label="Vorname" />
                            <DetailsLayout.InfoField value={benutzer?.telefon} label="Telefon" />
                            <DetailsLayout.InfoField value={benutzer?.fax} label="Telefax" />
                            <DetailsLayout.InfoField value={benutzer?.email} label="E-Mail-Adresse" />
                            <DetailsLayout.InfoField
                                value={benutzer?.isAdmin ? 'Ja' : 'Nein'}
                                label="Benutzer ist Admin"
                            />
                            <DetailsLayout.InfoField value={benutzer?.isActive ? 'Ja' : 'Nein'} label="Aktiviert" />
                        </DetailsLayout.Container>

                        <ButtonGroupGrid backLink={{ path: PathBuilder.home.verwaltung.benutzer.path }} />
                    </BaseGridContainer>
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const BenutzerDetailPage = withAuthorization(withSideBar(Index, menuVerwaltung));
