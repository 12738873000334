import React from 'react';
import { Grid, useTheme } from '@mui/material';

import { useHasRole } from 'api/auth/useHasRole';
import { useAntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { useRegelpruefungListActions } from 'components/DataTable/actions/useRegelpruefungListActions';
import { useRegelpruefungEinrichtungColumns } from 'components/DataTable/columns/regelpruefung/useRegelpruefungEinrichtungColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { RegelpruefungenExportButton } from 'components/RegelpruefungenExportButton';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useRegelpruefungEinrichtungListData } from './useRegelpruefungEinrichtungListData';

const Index = () => {
    const { spacing } = useTheme();

    const hasRole = useHasRole();
    const allowExport = hasRole(ROLE_TYPES.ROLLEN_REGELPRUEFUNG_EXPORT);

    const { serverDataChanged, handleAction } = useRegelpruefungListActions();

    const getData = useRegelpruefungEinrichtungListData();

    const filterOptions = useAntragTableFilterOptions(AntragType.REGELPRUEFUNG);

    const columns = useRegelpruefungEinrichtungColumns(handleAction, filterOptions);

    return (
        <ContentContainer title="Regeprüfungen für Einrichtungen">
            <StyledContainer>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                        <PageHeading title="Regelprüfungen für Einrichtungen" />

                        {allowExport ? <RegelpruefungenExportButton marginBottom={spacing(3)} /> : null}

                        <DataTableHydraServerside
                            tableCaption="Übersichtsstabelle der Regelprüfungen für Einrichtungen"
                            getColumns={columns}
                            getData={getData}
                            serverDataChanged={serverDataChanged}
                            persistSearchText
                            initialOptions={{
                                filter: true,
                                sortOrder: {
                                    name: 'letzteRegelpruefung',
                                    direction: 'asc',
                                },
                                searchPlaceholder: 'Suche nach NRW-Schlüssel oder Name des Einrichtung',
                                custom: {
                                    pathBuilder: PathBuilder.home.verwaltung.regelpruefung,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const RegelpruefungEinrichtungOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_REGELPRUEFUNG
);
