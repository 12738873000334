import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { DashboardMeldungen } from 'components/Dashboard/DashboardMeldungen';
import { DashboardMessageCard } from 'components/Dashboard/DashboardMessageCard';
import { PageHeading } from 'components/PageHeading';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { HilfeSupport } from 'pages/Dashboard/HilfeSupport';
import { theme } from 'theme/theme';

export const dashboardLinkStyles = {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 600,
};

export const DashboardAnbieter: React.FC = () => {
    return (
        <ContentContainer title="Startseite">
            <StyledContainer>
                <Box marginBottom={theme.spacing(3)}>
                    <PageHeading title="Willkommen bei PfAD.wtg" />

                    <Typography paragraph>
                        Über dieses System wird die Anzeigepflicht für alle Angebote nach dem Wohn- und Teilhabegesetz
                        gegenüber den WTG-Behörden erfüllt.
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <DashboardMeldungen />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <DashboardMessageCard />
                    </Grid>

                    <Grid item xs={12}>
                        <HilfeSupport />
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};
