import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, TableCell, TableRow, Typography } from '@mui/material';

import { Schema } from 'forms/types/UiSchemaTypes';

interface SubHeadlineUiSchema {
    text: string;
    scoped?: string;
}

interface SubHeadlineProps extends Omit<ControlProps, 'uischema' | 'schema'> {
    uischema: SubHeadlineUiSchema;
    schema: Schema;
}

const SubHeadlineComponent = ({ config, uischema }: SubHeadlineProps) => {
    return (
        <TableRow>
            <TableCell colSpan={config?.compareData ? 4 : 3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4">{uischema.text}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

// @ts-ignore
export const SubHeadline = withJsonFormsControlProps(SubHeadlineComponent);
