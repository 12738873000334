import React, { useState } from 'react';
import { useTheme } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuswertung44vWtgColumns } from 'components/DataTable/columns/useAuswertung44vWtgColumns';
import { DataTableHydraServerside, TableChangeProps } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAuswertung } from 'navigation/menuConfigs/menuAuswertung';
import { withAuthorization } from 'navigation/withAuthorization';
import { useExportMessage } from 'pages/Auswertung/useExportMessage';

import { useAuswertung44vWtgListData } from './useAuswertung44vWtgListData';

const Index = () => {
    const theme = useTheme();

    const [exportParams, setExportParams] = useState<TableChangeProps>({});

    const getData = useAuswertung44vWtgListData();

    const { isLoading, loadingStart, loadingEnd } = useFormState();

    const columns = useAuswertung44vWtgColumns();

    const { createSuccessMessage, createErrorMessage } = useExportMessage();

    const handleTableChange = ({ filters, sortOrder, searchText }: TableChangeProps) => {
        setExportParams({ filters, sortOrder, searchText });
    };

    const handleExport = () => {
        loadingStart();

        backendApiService
            .getAuswertungP44vWtgExport({
                qSearch: exportParams.searchText,
            })
            .then(() => createSuccessMessage('Auswertung §44 V WTG'))
            .catch(() => createErrorMessage())
            .finally(() => loadingEnd());
    };

    return (
        <ContentContainer title="Auswertung §44 V WTG">
            <StyledContainer>
                <PageHeading title="Auswertung §44 V WTG" />

                <ButtonGroupGrid marginBottom={theme.spacing(3)}>
                    <FormLoadingButton loading={isLoading} onClick={handleExport} variant="contained">
                        XSLX-Export
                    </FormLoadingButton>
                </ButtonGroupGrid>

                <BaseGridContainer lg={8}>
                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        onTableChange={handleTableChange}
                        persistSearchText
                        initialOptions={{
                            sortOrder: {
                                name: 'name',
                                direction: 'asc',
                            },
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const P44vWtgPage = withAuthorization(withSideBar(Index, menuAuswertung), ROLE_TYPES.ROLLEN_AUSWERTUNG_P44);
