import React from 'react';
import { Alert, List, ListItem, Typography } from '@mui/material';

import { useSpecificRole } from 'api/auth/useHasRole';

export const AbwInfo = () => {
    const { isAmbulanterDienst, isBehoerde } = useSpecificRole();

    return (
        <>
            {isAmbulanterDienst || isBehoerde ? (
                <Alert severity="info">
                    {isBehoerde ? (
                        <>
                            <Typography paragraph>
                                Ambulante Dienste müssen in folgenden Fällen eine „Angezeigte Betreuung in
                                Wohngemeinschaften“ registrieren:
                            </Typography>

                            <List className="numbered-list">
                                <ListItem>
                                    Ein ambulanter Dienst ist in einer anbieterverantworteten Wohngemeinschaft tätig,
                                    die <strong>nicht</strong> von dem ambulanten Dienst verantwortet wird.
                                </ListItem>
                                <ListItem>
                                    Ein ambulanter Dienst ist in einer selbstverantworteten Wohngemeinschaft tätig, die{' '}
                                    <strong>nicht</strong> von dem ambulanten Dienst verantwortet wird.
                                </ListItem>
                            </List>

                            <Typography paragraph>
                                Sofern ein ambulanter Dienst in einer Wohngemeinschaft tätig ist, die er selbst
                                verantwortet, ist dies seitens des ambulanten Dienstes <strong>ausschließlich</strong>{' '}
                                als Leistungsangebot im Sinne des § 2 Abs. 2 WTG zu registrieren.
                            </Typography>
                        </>
                    ) : null}

                    {isAmbulanterDienst ? (
                        <Typography paragraph>
                            Hier sehen Sie alle Wohngemeinschaften, in denen Sie tätig sind, die von Ihnen aber nicht
                            verantwortet werden. Wohngemeinschaften, die Sie als eigenes Leistungsangebot betreiben,
                            müssen als Leistungsangebot im Sinne von § 2 Abs. 2 WTG registriert werden und erscheinen in
                            der Übersicht der Leistungsangebote.
                        </Typography>
                    ) : null}
                </Alert>
            ) : null}
        </>
    );
};
