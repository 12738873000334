import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty, isEqual, pickBy } from 'lodash';
import { MUIDataTableOptions, ToolbarButton } from 'mui-datatables';

import { backendApiService } from 'api/ApiService';
import { BenutzerTablePreference } from 'api/client';
import { useApiBenutzerTablePreference } from 'api/hooks/useApiClient';
import { GetColumns } from 'components/DataTable/types';
import { useFormState } from 'forms/state/useFormState';
import { useLoadingActions } from 'forms/state/useLoadingState';
import { errorMessage } from 'forms/utilities/MessageUtils';

export type TableFilterValue = string[];
export type TableFilters = Record<string, TableFilterValue>;

export type OnFiltersChanged = NonNullable<MUIDataTableOptions['onFilterChange']>;

export type FilterButtonProps = Omit<
    UseTableFiltersResult,
    'filters' | 'initialFilters' | 'onFiltersChanged' | 'updateFilters'
>;

export interface UseTableFiltersResult {
    filters: TableFilters;
    initialFilters: TableFilters;
    onFiltersChanged: OnFiltersChanged;
    saveFilters: () => Promise<void>;
    deleteFilters: () => Promise<void>;
    updateFilters: (filters: TableFilters) => void;
    isFilterDirty: boolean;
    isFilterSaved: boolean;
}

export const useTableFilters = (
    name: string,
    getColumns: GetColumns,
    withFilters: ToolbarButton = false
): UseTableFiltersResult => {
    const { setRequestMessage } = useFormState();
    const { showLoading, hideLoading } = useLoadingActions();
    const initialFilters = useMemo(() => determineInitialFilters(getColumns), [getColumns]);
    const [filters, setFilters] = useState<TableFilters>(initialFilters);
    const [originalFilters, setOriginalFilters] = useOriginalFilters(name, initialFilters, setFilters, withFilters);

    const saveFilters = async () => {
        const id = originalFilters?.id;
        showLoading('Tabellenfilter werden gespeichert...');
        if (id) {
            backendApiService
                .putBenutzerTablePreference(id.toString(), {
                    name,
                    value: filters,
                })
                .then((res) => setOriginalFilters(res))
                .catch((e) => {
                    console.error(e);
                    setRequestMessage(errorMessage('Filter konnten nicht gespeichert werden.'));
                })
                .finally(() => {
                    hideLoading();
                });
        } else {
            backendApiService
                .postBenutzerTablePreference({
                    name,
                    value: filters,
                })
                .then((res) => setOriginalFilters(res))
                .catch((e) => {
                    console.error(e);
                    setRequestMessage(errorMessage('Filter konnten nicht gespeichert werden.'));
                })
                .finally(() => {
                    hideLoading();
                });
        }
    };

    const deleteFilters = async () => {
        try {
            const id = originalFilters?.id;
            if (id) {
                showLoading('Tabellenfilter werden gelöscht...');
                setFilters({});
                setOriginalFilters(null);
                await backendApiService.deleteBenutzerTablePreference(String(id)).catch((e) => {
                    console.error(e);
                    setRequestMessage(errorMessage('Filter konnten nicht gelöscht werden.'));
                });
            }
        } finally {
            hideLoading();
        }
    };

    const resetFilters = () => {
        setFilters({});
    };

    const updateFilter = (key: string | undefined, value: TableFilterValue) => {
        if (!key) return;
        setFilters((filters) => ({ ...filters, [key]: value }));
    };

    const onFiltersChanged: OnFiltersChanged = (changedColumn, filterList, type, changedColumnIndex) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            const name = typeof changedColumn === 'string' ? changedColumn : changedColumn?.name;
            updateFilter(name, filterList[changedColumnIndex]);
        }
    };

    return {
        filters,
        initialFilters,
        onFiltersChanged,
        saveFilters,
        deleteFilters,
        updateFilters: setFilters,
        isFilterSaved: Boolean(originalFilters?.id),
        isFilterDirty: determineFiltersDirty(filters, originalFilters?.value ?? initialFilters),
    };
};

const useOriginalFilters = (
    name: string,
    initialFilters: TableFilters,
    setFilters: Dispatch<SetStateAction<TableFilters>>,
    withFilters: ToolbarButton = false
): [BenutzerTablePreference | null, Dispatch<SetStateAction<BenutzerTablePreference | null>>] => {
    const { search } = useLocation();
    const { setRequestMessage } = useFormState();
    const isFirstRun = useRef(true);
    const [originalFilters, setOriginalFilters] = useState<BenutzerTablePreference | null>(null);

    const { data, error } = useApiBenutzerTablePreference(name, withFilters !== false);

    useEffect(() => {
        if (error && !isEmpty(initialFilters)) setRequestMessage(createErrorMessage());
    }, [error, initialFilters, setRequestMessage]);

    useEffect(() => {
        if (data) setOriginalFilters(data);
    }, [data]);

    useEffect(() => {
        if (originalFilters && search.length === 0 && isFirstRun.current) {
            setFilters(originalFilters.value as TableFilters);
        }
        if (originalFilters) {
            isFirstRun.current = false;
        }
    }, [originalFilters, search, setFilters]);

    return [originalFilters, setOriginalFilters];
};

const createErrorMessage = () => errorMessage('Filter konnten nicht geladen werden.');

const determineInitialFilters = (getColumns: GetColumns): TableFilters => {
    const columns = getColumns({});
    return columns.reduce((acc, column) => {
        if (typeof column === 'string') return acc;
        const filterList = column.options?.filterList ?? [];
        if (!filterList || filterList.length <= 0) return acc;
        return { ...acc, [column.name]: filterList };
    }, {});
};

const determineFiltersDirty = (filters: TableFilters, original: object): boolean => {
    const cleanedFilters = pickBy(filters, (value) => value && value.length > 0);
    const cleanedOriginal = pickBy(original, (value: string[]) => value && value.length > 0);
    return !isEqual(cleanedFilters, cleanedOriginal);
};
