import React, { useState } from 'react';
import { Typography } from '@mui/material';

import { useSpecificRole } from 'api/auth';
import { LabelOverrides } from 'components/DataTable/ActionDropdown/ActionDropdownAntrag';
import { useMeldungenAntragListActions } from 'components/DataTable/actions/useMeldungenAntragListActions';
import { useMeldungenColumns } from 'components/DataTable/columns/meldungen/useMeldungenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { AntragPathBuilder } from 'components/DataTable/hooks/useListActions';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { TableOptions } from 'components/DataTable/hooks/useTableOptions';
import { PageHeading } from 'components/PageHeading';
import { MeldungCreateForm } from 'forms/Meldung/MeldungCreateForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';

interface MeldungUebersichtProps<Data = any> {
    title: string;
    headline: string;
    getData: GetData<Data>;
    initialTableOptions?: TableOptions;
    pathBuilder?: AntragPathBuilder;
    labelOverrides?: LabelOverrides;
}

export const MeldungUebersicht = ({
    title,
    headline,
    getData,
    initialTableOptions,
    pathBuilder,
    labelOverrides,
}: MeldungUebersichtProps) => {
    const { isAnbieter } = useSpecificRole();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const { serverDataChanged, handleAction } = useMeldungenAntragListActions({ pathBuilder });

    const columns = useMeldungenColumns(handleAction, labelOverrides);

    return (
        <ContentContainer title={title}>
            <StyledContainer>
                <PageHeading title={headline} />

                {isAnbieter ? <MeldungCreateForm onCreate={() => setIsProcessing((prevState) => !prevState)} /> : null}

                {isAnbieter ? <Typography variant="h2">Übersicht der Meldungen</Typography> : null}

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    initialOptions={initialTableOptions}
                    serverDataChanged={serverDataChanged}
                    persistSearchText
                    loadingContext={isProcessing}
                />
            </StyledContainer>
        </ContentContainer>
    );
};
