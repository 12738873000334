import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { EmailAntragCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { ReadStatus } from 'components/DataTable/components/ReadStatus';
import { EmptyColumnHeader } from 'components/DataTable/EmptyColmnHeader';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDateTime, getRowData } from 'components/DataTable/tableUtils';

import { BenutzerStatusBadge } from './StyledColumnComponents';

export const useEinrichtungenNachrichtenColumns = (
    handleAction: AntragListAction<any>,
    setServerDataChanged: Dispatch<SetStateAction<number>>
): ((data: EmailAntragCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                {
                    name: 'read',
                    options: {
                        customHeadRender: EmptyColumnHeader,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            const rowData = getRowData(dataIndex, data);

                            return (
                                <ReadStatus
                                    data={rowData}
                                    rowIndex={rowIndex}
                                    onStatusChanged={() => setServerDataChanged((prevState) => prevState + 1)}
                                />
                            );
                        },
                    },
                },
                {
                    name: 'eingereichtAt',
                    label: 'Datum',
                    options: {
                        customBodyRenderLite: (dataIndex) => getDateTime(getRowData(dataIndex, data)?.eingereichtAt),
                    },
                },
                {
                    name: 'empfaenger',
                    label: 'Empfänger',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            return <>{getRowData(dataIndex, data)?.empfaenger}</>;
                        },
                    },
                },
                {
                    name: 'sender',
                    label: 'Sender',
                    options: {
                        customBodyRenderLite: (dataIndex) => (
                            <BenutzerStatusBadge sender={getRowData(dataIndex, data)?.sender} />
                        ),
                    },
                },
                {
                    name: 'betreff',
                    label: 'Betreff',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            return getRowData(dataIndex, data)?.data?.betreff || '-';
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions, setServerDataChanged]
    );
};
