import { ReactElement, useEffect, useRef, useState } from 'react';
import debounceRender from 'react-debounce-render';
import { useLocation } from 'react-router-dom';
import { Alert, Grid } from '@mui/material';
import { MUISortOptions } from 'mui-datatables';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { LinkSupport } from 'elements/LinkSupport';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

import { DataTableController, DataTableHydra, DataTableHydraMessages } from './DataTableHydra';
import { DataTableHydraFilterButtons } from './DataTableHydraFilterButtons';
import { GetData } from './hooks/useTableData';
import { TableFilters } from './hooks/useTableFilters';
import { TableOptions } from './hooks/useTableOptions';
import { useTableParameters } from './hooks/useTableParameters';
import { GetColumns } from './types';

export interface TableChangeProps<Data = any> {
    data?: Data;
    filters?: TableFilters;
    sortOrder?: MUISortOptions | undefined;
    searchText?: string;
}

interface ServersideDataTableHydraProps<Data> {
    getColumns: GetColumns<Data>;
    getData: GetData<Data>;
    tableCaption?: string;
    tableStateMessages?: DataTableHydraMessages;
    onTableChange?: ({ data, filters, sortOrder, searchText }: TableChangeProps<Data>) => void;
    onReady?: (data?: Data) => void;
    initialOptions?: TableOptions;
    loadingContext?: boolean;
    omitReloadTableContext?: boolean;
    serverDataChanged?: number;
    nameOverride?: string;
    persistSearchText?: boolean;
}

export const DataTableHydraServerside = <Data = any,>({
    getColumns,
    getData,
    tableCaption,
    tableStateMessages,
    onTableChange,
    onReady,
    initialOptions,
    loadingContext,
    omitReloadTableContext,
    serverDataChanged,
    nameOverride,
    persistSearchText,
}: ServersideDataTableHydraProps<Data>): ReactElement => {
    const location = useLocation();
    const tableRef = useRef<DataTableController>();
    const [renderTable, setRenderTable] = useState<boolean>(false);

    const { isLoading, columns, data, hasErrors, filterButtonProps, filters, options, searchText, sortOrder } =
        useTableParameters<Data>(
            nameOverride || location.pathname,
            initialOptions,
            getColumns,
            getData,
            persistSearchText,
            [serverDataChanged]
        );

    useEffect(() => {
        onTableChange?.({ data, filters, sortOrder, searchText });
        // eslint-disable-next-line
    }, [filters, sortOrder, searchText]);

    useDebouncedEffect(
        () => {
            if (!isLoading && !!data) {
                setRenderTable(true);
                onReady?.(data);
            }
        },
        [isLoading, data, renderTable],
        100
    );

    return (
        <AppLoaderContainer isLoading={(isLoading && !data) || !!loadingContext}>
            {renderTable ? (
                <Grid container spacing={3}>
                    {filterButtonProps ? (
                        <Grid item xs={12} container justifyContent="flex-end">
                            <DataTableHydraFilterButtons {...filterButtonProps} tableRef={tableRef} />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        {!hasErrors ? (
                            <DataTable
                                dataTableHydraMessages={tableStateMessages}
                                title={tableCaption}
                                isLoading={omitReloadTableContext ? isLoading && !data : isLoading}
                                data={data}
                                columns={columns}
                                options={options}
                                tableRef={tableRef}
                            />
                        ) : (
                            <Alert severity="error">
                                Es gab ein Problem bei der Anfrage der Daten. Bitte versuchen Sie es zu einem späteren
                                Zeitpunkt erneut oder wenden Sie sich an den <LinkSupport />
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            ) : null}
        </AppLoaderContainer>
    );
};

const DataTable = debounceRender(DataTableHydra, 100);
