import React from 'react';
import { Alert, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { SignaturSignaturWrite } from 'api/client';
import { useApiEmailSignatur } from 'api/hooks/useApiClient';
import { ROLE_TYPES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { schema, uiSchema } from './config';

const Index = () => {
    const { data, error, isLoading } = useApiEmailSignatur();

    const handleSubmit = (formData: SignaturSignaturWrite) => {
        if (formData?.id) {
            return backendApiService.patchEmailSignatur(formData).catch(() => Promise.reject());
        }

        return backendApiService.patchEmailSignatur(formData).catch(() => Promise.reject());
    };

    return (
        <ContentContainer title="E-Mail-Signatur editieren">
            <StyledContainer>
                <Typography variant="h1">E-Mail-Signatur editieren</Typography>

                {!error ? (
                    <SimpleForm
                        isLoading={isLoading}
                        data={data}
                        schema={schema}
                        uiSchema={uiSchema}
                        messages={{
                            success: `E-Mail-Signatur wurde aktualisiert`,
                            error: 'Aktualisierung der E-Mail-Signatur ist fehlgeschlagen',
                        }}
                        onSubmit={handleSubmit}
                        submitLabel="Aktualisieren"
                    />
                ) : (
                    <Alert severity="error">Beim Laden der Signatur ist ein Fehler aufgetreten</Alert>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};

export const EmailSignaturEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_EMAIL_SIGNATUR
);
