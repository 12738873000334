import React, { useCallback, useMemo } from 'react';
import { ControlProps } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ErrorIcon } from 'forms/components/ErrorIcon';
import { useErrors } from 'forms/hooks/useErrors';
import { useUuidValue } from 'forms/hooks/useUuidValue';
import { renderers } from 'forms/renderers';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { determineSchemaByUUID, getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { getFieldFromScope } from 'utilities';
import { replaceScopeTemplates } from 'utilities/ScopeUtils';

import { TableHeadCompare } from './TableHeadCompare';
import { useErrorNavigate } from './useErrorNavigate';

interface SubTableUiSchema {
    scope: string;
    entries: UiSchemaType[];
    columns?: number;
    label?: string;
    level?: number;
    showLabel?: boolean;
}

interface SubTableInterface extends Omit<ControlProps, 'uischema' | 'schema'> {
    schema: Schema;
    uischema: SubTableUiSchema;
}

const SubTableComponent: React.FC<SubTableInterface> = ({ config, uischema, path, schema, data, errors }) => {
    const theme = useTheme();

    const { hasErrors } = useErrors({ config, path, jsonFormError: errors, immediately: true });
    const errorNavigate = useErrorNavigate(path);

    const headerStyle = {
        fontWeight: 600,
        marginBottom: 0,
        marginTop: theme.spacing(uischema.level === 0 ? 2 : 1),
        paddingLeft: theme.spacing((uischema.level ?? 0) * 6),
    };

    const showLabel = useMemo(() => uischema.showLabel !== undefined && uischema.showLabel, [uischema]);

    const getEntryTitleByUuid = useCallback(
        (uuid: string) => {
            const entrySchema = determineSchemaByUUID(uuid, schema as Schema);
            return entrySchema?.title || null;
        },
        [schema]
    );

    if (hasErrors) {
        return (
            <TableRow sx={{ backgroundColor: theme.palette.error.lighter }}>
                <TableCell
                    sx={{
                        width: 110,
                        textAlign: 'left',
                    }}
                >
                    <ErrorIcon label={uischema?.label || schema?.title} onClick={errorNavigate} />
                </TableCell>

                <TableCell>
                    <SubTableHeader
                        data={data}
                        schema={schema}
                        uischema={uischema}
                        style={{ margin: 0, fontWeight: 300 }}
                    />
                </TableCell>

                <TableCell>-</TableCell>

                {config.compareData ? <TableCell>-</TableCell> : null}
            </TableRow>
        );
    }

    if (Array.isArray(data) && data.length > 0) {
        return (
            <>
                {data.map((d, dataIndex) => (
                    <React.Fragment key={dataIndex}>
                        {showLabel && dataIndex === 0 ? (
                            <TableRow>
                                <TableCell colSpan={uischema?.columns ?? 3} sx={{ borderBottom: 0 }}>
                                    <SubTableHeader
                                        key={`${dataIndex}-header`}
                                        schema={schema}
                                        uischema={uischema}
                                        data={d}
                                        style={headerStyle}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : null}

                        {data.length > 1 ? (
                            <TableRow>
                                <TableCell
                                    colSpan={uischema?.columns ?? 3}
                                    sx={{
                                        padding: `${theme.spacing(4)} ${theme.spacing(2)} 0 ${theme.spacing(4)}`,
                                        border: 0,
                                        fontWeight: 600,
                                    }}
                                >
                                    {`${dataIndex + 1}. ${getEntryTitleByUuid(d.uuid)}`}
                                </TableCell>
                            </TableRow>
                        ) : null}

                        {config?.compareData ? <TableHeadCompare inline /> : null}

                        {uischema.entries
                            .filter((row) =>
                                getFieldSchemaByScope(row.scope, determineSchemaByUUID(d.uuid, schema as Schema))
                            )
                            .map((row, index) => {
                                return (
                                    <JsonFormsDispatch
                                        key={`${dataIndex}-${index}`}
                                        renderers={renderers}
                                        schema={determineSchemaByUUID(d.uuid, schema as Schema)}
                                        uischema={row}
                                        path={`${path}.${dataIndex}`}
                                        enabled
                                        visible
                                    />
                                );
                            })}
                    </React.Fragment>
                ))}
            </>
        );
    }

    return (
        <>
            {showLabel ? (
                <TableRow>
                    <TableCell colSpan={uischema?.columns ?? 3} sx={{ borderBottom: 0 }}>
                        <SubTableHeader
                            key={'label'}
                            data={data}
                            schema={schema as Schema}
                            uischema={uischema}
                            style={headerStyle}
                        />
                    </TableCell>
                </TableRow>
            ) : null}

            {uischema.entries
                .filter((row) => {
                    return schema[getFieldFromScope(row.scope)];
                })
                .map((row, index) => {
                    return (
                        <JsonFormsDispatch
                            key={uuidv4()}
                            renderers={renderers}
                            schema={{ properties: getFieldSchemaByScope(row.scope, schema) || schema }}
                            uischema={row}
                            path={path}
                            enabled
                            visible
                        />
                    );
                })}
        </>
    );
};

interface SubTableHeaderType {
    schema: Schema;
    uischema: SubTableUiSchema;
    data: FormData;
    style: any;
}

const SubTableHeader: React.FC<SubTableHeaderType> = ({ schema, uischema, data, style }) => {
    const title = uischema?.label || schema?.title || undefined;
    const headerText = useUuidValue(replaceScopeTemplates(title, data)) || replaceScopeTemplates(title, data);

    if (!headerText) {
        return null;
    }

    return (
        <Typography variant={'h4'} style={style}>
            {headerText}
        </Typography>
    );
};

// @ts-ignore
export const SubTable = withJsonFormsControlProps(SubTableComponent);
