import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { regelpruefungAntragApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { ACTIONS } from 'constants/antragActions';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { regelpruefungTabConfig } from 'forms/RegelpruefungAntragForm/regelpruefungTabConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AntragPage } from 'pages/AntragPage';

const RegelpruefungAntrag = () => {
    const { einrichtungId } = useParams();
    const { data, isLoading } = useApiEinrichtungGet(String(einrichtungId));

    const title = useMemo(() => {
        if (!isLoading && data) {
            return `Regelprüfung erstellen: "${data.name}"`;
        }

        return 'Regelprüfung erstellen';
    }, [isLoading, data]);

    return (
        <AntragPage
            api={regelpruefungAntragApi}
            pathBuilder={PathBuilder.home.verwaltung.regelpruefung}
            tabConfig={regelpruefungTabConfig}
            title={title}
            headline={title}
            antragType={AntragType.REGELPRUEFUNG}
            workflowFormItem={backendApiService.workflowRegelpruefungItem.bind(backendApiService)}
            buttonOverrides={{
                [ACTIONS.EINREICHEN]: {
                    label: 'Speichern',
                },
            }}
            layout={{ lg: 8 }}
        />
    );
};

export const RegelpruefungAntragPage = withAuthorization(
    withSideBar(RegelpruefungAntrag, menuVerwaltung),
    ROLE_TYPES.ROLLEN_REGELPRUEFUNG
);
