import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { BenutzerEinrichtungenListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useBenutzerEinrichtungenListData = (benutzerId: number): GetData<BenutzerEinrichtungenListResponse> => {
    return useCallback(
        ({ page, rowsPerPage }) =>
            backendApiService.getBenutzerEinrichtungen({
                page,
                itemsPerPage: rowsPerPage,
                benutzer: benutzerId,
            }),
        [benutzerId]
    );
};
