import React, { useEffect, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Alert, Grid, Typography } from '@mui/material';
import _, { identity } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { useAuthUser } from 'api/auth';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Dialog } from 'components/Dialog/Dialog';
import { SupportInfo } from 'elements/SupportInfo';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { renderers } from 'forms/renderers';
import { FormLayoutConfig, FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { useFormLayoutConfig } from 'utilities/hooks/useFormLayoutConfig';

type FormDataType = {
    currentEmail: string | undefined;
    email: string | undefined;
    password?: string | undefined;
};

const formData: FormDataType = {
    currentEmail: '',
    email: '',
    password: '',
};

const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        {
            type: 'Group',
            options: {
                noSeparator: true,
            },
            gridLayout: {
                label: 3,
                input: 5,
            },
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/currentEmail',
                },
                {
                    type: 'Control',
                    scope: '#/properties/email',
                    options: {
                        focus: true,
                    },
                },
            ],
        },
        {
            type: 'Group',
            options: {
                noSeparator: true,
            },
            elements: [
                {
                    type: 'LabelRowControl',
                    text: 'Bitte bestätigen Sie die Änderung Ihrer E-Mail-Adresse durch Eingabe Ihres PfAD.wtg Passworts (Ihr Login-Passwort)',
                },
            ],
        },
        {
            type: 'Group',
            gridLayout: {
                label: 3,
                input: 5,
            },
            options: {
                noSeparator: true,
            },
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/password',
                },
            ],
        },
    ],
};

const schema: Schema = {
    type: 'object',
    properties: {
        currentEmail: {
            type: 'string',
            title: 'Aktuelle E-Mail-Adresse',
            // @ts-ignore
            custom: {
                disabled: true,
            },
        },
        email: {
            type: 'string',
            title: 'Neue E-Mail-Adresse',
            // @ts-ignore
            custom: {
                block_prefixes: ['email_change_widget'],
                required: true,
            },
        },
        password: {
            type: 'string',
            title: 'Passwort',
            // @ts-ignore
            custom: {
                block_prefixes: ['password', 'small'],
                required: true,
            },
        },
    },
    required: ['email', 'password'],
};

interface EmailChangeProps {
    open: boolean;
    onClose: () => void;
}

export const EmailChange: React.FC<EmailChangeProps> = ({ open, onClose }) => {
    const user = useAuthUser();
    const [data, setData] = useState(formData);
    const configLayout: FormLayoutConfig = useFormLayoutConfig();
    const config = useLayoutWithJsonFormsState(configLayout);
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { valid } = useFormValidation(schema, data);
    const [isLoading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (user && !data.currentEmail) {
            setData((prevState) => {
                const newData = {
                    ...prevState,
                    currentEmail: user?.username,
                };

                return _.pickBy(newData, identity) as FormDataType;
            });
        }
    }, [data, user]);

    useEffect(() => {
        if (data) {
            setLoading(false);
        }
    }, [data]);

    const closeModal = () => {
        onClose();
        setTimeout(() => {
            setSuccess(false);
        });
    };

    const handleChange = (changeData: FormStateChange) => {
        setData(changeData.data);
    };

    const handleSave = () => {
        if (isSubmitting) {
            return;
        }

        setSubmitting(true);

        setErrorMessage(undefined);

        backendApiService
            .patchProfileEmail(data)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                setErrorMessage(
                    'Beim Ändern Ihrer E-Mail-Adresse ist ein Fehler aufgetreten. ' +
                        'Prüfen Sie Ihre Eingaben und versuchen Sie es erneut.'
                );
            })
            .finally(() => {
                setSubmitting(false);
                setData({ ...data, password: '' });
            });
    };

    return (
        <Dialog
            open={open}
            title={'E-Mail-Adresse ändern'}
            onClose={closeModal}
            wrapActions
            actions={
                <>
                    <Grid container spacing={1} direction={'row-reverse'}>
                        {!success && (
                            <Grid item>
                                <FormButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={!valid || isSubmitting}
                                    data-cy={'FormDialog-saveButton'}
                                >
                                    E-Mail-Adresse ändern
                                </FormButton>
                            </Grid>
                        )}

                        <Grid item>
                            <FormButton
                                variant="outlined"
                                color="primary"
                                onClick={closeModal}
                                data-cy={'FormDialog-closeeButton'}
                            >
                                Schließen
                            </FormButton>
                        </Grid>
                    </Grid>
                    <SupportInfo />
                </>
            }
        >
            <>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

                <AppLoaderContainer isLoading={isLoading}>
                    {success && (
                        <>
                            <Typography paragraph>
                                Um Ihre E-Mail-Adresse zu ändern, klicken Sie bitte innerhalb von 24 Stunden auf den
                                Bestätigungslink, den wir an Ihre E-Mail-Adresse gesendet haben.
                            </Typography>
                        </>
                    )}

                    {!success && (
                        <>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography paragraph>
                                        Ihre E-Mail-Adresse ist zeitgleich Ihr Benutzername in PfAD.wtg. Um eine andere
                                        E-Mail-Adresse für Ihren Account zu nutzen, müssen Sie Ihre neue E-Mail-Adresse
                                        zunächst bestätigen. Um die neue E-Mail-Adresse zu bestätigen, klicken Sie auf
                                        den Bestätigungslink, den wir an Ihre neue E-Mail-Adresse schicken.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Alert severity={'warning'}>
                                WICHTIG: Erst nach erfolgreicher Bestätigung Ihrer neuen E-Mail-Adresse wird diese als
                                Benutzername in PfAD.wtg akzeptiert. Ihre aktuelle E-Mail-Adresse ist bis zur
                                Bestätigung aktiv.
                            </Alert>

                            <Pflichtfeld display={!!schema?.required?.length} />

                            <JsonForms
                                data={data}
                                schema={schema}
                                uischema={uiSchema}
                                onChange={handleChange}
                                renderers={renderers}
                                config={config}
                            />
                        </>
                    )}
                </AppLoaderContainer>
            </>
        </Dialog>
    );
};
