import React, { useState } from 'react';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { Box, Button, Grid, Link, Popover, styled } from '@mui/material';

import { Link as InternalLink } from 'elements/Link';
import { LoginForm } from 'layout/components/LoginLink/LoginForm';
import { PasswordResetRequest } from 'layout/components/PasswordReset/PasswordResetRequest';
import { PathBuilder } from 'navigation/Paths';

const LoginButton = styled(Button)(() => ({
    color: '#000',
}));

interface LoginLinkProps {
    buttonTitle?: string;
    idPopover?: string;
    idLink?: string;
}

export const LoginLink = ({ buttonTitle, idPopover, idLink }: LoginLinkProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openPasswordReset, setOpenPasswordReset] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenPasswordReset(false);
    };

    const handlePasswordReset = () => {
        setAnchorEl(null);
        setOpenPasswordReset(true);
    };

    const open = Boolean(anchorEl);
    const idLoginPopover = open ? idPopover ?? 'HeaderLoginPopover' : '';
    const idLoginLink = idLink ?? 'HeaderLoginLink';

    return (
        <>
            <LoginButton aria-describedby={idLoginPopover} id={idLoginLink} onClick={handleClick}>
                {buttonTitle ? (
                    buttonTitle
                ) : (
                    <>
                        <AccountIcon /> Anmelden
                    </>
                )}
            </LoginButton>

            <Popover
                id={idLoginPopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box margin={2} width="400px">
                    <LoginForm />
                    <br />
                    <Grid container justifyContent={'space-between'}>
                        <InternalLink to={PathBuilder.home.registrierung.path} onClick={handleClose}>
                            Registrieren
                        </InternalLink>

                        <Link onClick={handlePasswordReset} underline="hover">
                            Passwort vergessen
                        </Link>
                    </Grid>
                </Box>
            </Popover>

            <PasswordResetRequest open={openPasswordReset} onClose={handleClose} />
        </>
    );
};
