import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { leistungsangebotEntwurfApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { useSpecificRole } from 'api/auth';
import { PartialRecord } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ACTIONS } from 'constants/antragActions';
import { AntragType } from 'constants/antragTypes';
import { leistungsangebotTabConfig } from 'forms/LeistungsangebotForm/leistungsangebotTabConfig';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PATH_LEISTUNGSANGEBOT_REGISTRIEREN, PathBuilderProps } from 'navigation/Paths';
import { AntragPage, FormActionButtonOverrides } from 'pages/AntragPage';
import { useRoleText } from 'utilities/hooks/useRoleText';

interface LeistungsangebotBearbeitenProps {
    headlineOverride?: string;
    buttonOverrides?: PartialRecord<ACTIONS, FormActionButtonOverrides>;
    pathBuilder: PathBuilderProps;
}

export const LeistungsangebotBearbeiten = ({
    headlineOverride,
    buttonOverrides,
    pathBuilder,
}: LeistungsangebotBearbeitenProps) => {
    const { isAnbieter } = useSpecificRole();
    const { antragId, step } = useParams();
    const navigate = useNavigate();

    const [draftId, setDraftId] = useState<string | null>(null);
    const [isDraftCreated, setIsDraftCreated] = useState(false);

    useEffect(() => {
        if (antragId) {
            setDraftId(antragId);
            return;
        }

        if (draftId) {
            navigate(pathBuilder?.edit?.({ id: draftId, segment: PATH_LEISTUNGSANGEBOT_REGISTRIEREN }) || '');
            return;
        }

        backendApiService
            .createLeistungsangebotEntwurf()
            .then(async ({ id }) => {
                if (!id) return;

                setIsDraftCreated(true);

                await setDraftId(String(id));
            })
            .finally(() => setIsDraftCreated(false));
    }, [antragId, draftId, setDraftId, navigate, step, pathBuilder]);

    const { documentTitle, headline } = useRoleText(AntragType.LEISTUNGSANGEBOT);

    if (!draftId) {
        return <></>;
    }

    return (
        <ContentContainer title={`${headlineOverride || documentTitle} ${'| ' + draftId ?? ''}`}>
            <StyledContainer>
                <PageHeading title={headlineOverride || headline} />

                <BaseGridContainer lg={8}>
                    {isAnbieter ? (
                        <Alert severity="info">
                            Bitte entnehmen Sie die Daten für Ihr Leistungsangebot aus Ihrem aktuellen
                            Versorgungsvertrag und Ihrer Vergütungsvereinbarung.
                        </Alert>
                    ) : null}

                    <AppLoaderContainer isLoading={isDraftCreated}>
                        <AntragPage
                            headline={false}
                            antragType={AntragType.LEISTUNGSANGEBOT}
                            pathBuilder={pathBuilder}
                            tabConfig={leistungsangebotTabConfig}
                            workflowFormItem={backendApiService.workflowLeistungsangebotItem.bind(backendApiService)}
                            api={leistungsangebotEntwurfApi}
                            disableValidationOnSave={true}
                            buttonOverrides={buttonOverrides}
                            layout={{
                                xs: 12,
                                paddingTop: 0,
                            }}
                        />
                    </AppLoaderContainer>
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};
