import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { EinrichtungNachrichtDetails } from 'pages/Nachrichten/Components/EinrichtungNachrichtDetails';

const Index = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    return <EinrichtungNachrichtDetails pathBuilder={pathBuilder} />;
};

export const NachrichtDetailsPage: React.FC<{ pathBuilder: PathBuilderProps }> = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_NACHRICHTEN
);
