export const unterlagen_table = {
    type: 'SummaryTable',
    columns: 1,
    label: 'Weitere Unterlagen',
    scope: '#/properties/dokumente',
    entries: [
        {
            type: 'FieldRow',
            label: 'Dokumente',
            scope: '#/properties/stepUnterlagenDokumente',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizUnterlagen',
        },
    ],
};
