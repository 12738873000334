export enum ROLES {
    USER = 'user', // special role - user is logged in
    PASSWORD_RESET = 'ROLE_PASSWORD_RESET',
    BEZIERKSREGIERUNG = 'ROLE_BEZREG',
    FACHADMIN = 'ROLE_FACHADMIN',
    SUPPORT = 'ROLE_SUPPORT',
    MINISTERIUM = 'ROLE_MINISTERIUM',
    LV = 'ROLE_LV',
    ANBIETER = 'ROLE_ANBIETER',
    BEHOERDE = 'ROLE_BEHOERDE',
    MONITORING = 'ROLE_MONITORING',
    TRAEGERVERBAND = 'ROLE_TRAEGERVERBAND',
    TRAEGER = 'ROLE_TRAEGER',
    EINRICHTUNG_BENUTZER = 'ROLE_EINRICHTUNG_BENUTZER',
    ROLE_PLATZMELDER = 'ROLE_PLATZMELDER',
    ROLE_HEIMFINDER = 'ROLE_HEIMFINDER',
    ROLE_FEMMELDER = 'ROLE_FEMMELDER',
    ROLE_AMBULANTER_DIENST = 'ROLE_AMBULANTER_DIENST',
}

export enum ROLLEN {
    SUPPORT = 'SUPPORT',
    MINISTERIUM = 'MINISTERIUM',
    MONITORING = 'MONITORING',
    TRAEGERVERBAND = 'TRAEGERVERBAND',
    TRAEGER = 'TRAEGER',
    BEHOERDE = 'BEHOERDE',
    LV = 'LV',
    EINRICHTUNG_BENUTZER = 'EINRICHTUNG_BENUTZER',
    BEZIRKSREGIERUNG = 'BEZIRKSREGIERUNG',
}

export const ROLE_TYPES = {
    ROLLEN_AUFGABEN_REQUEST: [ROLES.ANBIETER, ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.LV],
    ROLLEN_AUSWERTUNG: [ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.BEZIERKSREGIERUNG, ROLES.MINISTERIUM, ROLES.MONITORING],
    ROLLEN_AUSWERTUNG_P44: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_AUSWERTUNG_STATISTIK: [ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.BEZIERKSREGIERUNG, ROLES.MINISTERIUM],
    ROLLEN_AUSWERTUNG_STATISTIK_FEM: [ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.MINISTERIUM, ROLES.MONITORING],
    ROLLEN_BEHOERDEN: [ROLES.MINISTERIUM, ROLES.SUPPORT, ROLES.LV],
    ROLLEN_BEHOERDEN_EDIT: [ROLES.SUPPORT],
    ROLLEN_BENACHRICHTIGUNGEN: [ROLES.BEHOERDE, ROLES.ANBIETER],
    ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN: [ROLES.BEHOERDE, ROLES.ROLE_AMBULANTER_DIENST, ROLES.MINISTERIUM, ROLES.LV],
    ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN: [ROLES.BEHOERDE, ROLES.MINISTERIUM],
    ROLLEN_DELETED_OBJECTS: [ROLES.BEHOERDE],
    ROLLEN_DELETION_REASON: [ROLES.BEHOERDE, ROLES.TRAEGER, ROLES.LV, ROLES.SUPPORT],
    ROLLEN_DOKUMENTE_PRUEFUNG: [ROLES.BEHOERDE],
    ROLLEN_EMAIL_SIGNATUR: [ROLES.BEHOERDE],
    ROLLEN_EMAIL_TEXT: [ROLES.BEHOERDE],
    ROLLEN_FEM_MELDER: [ROLES.ROLE_FEMMELDER, ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.MONITORING],
    ROLLEN_FEM_MELDER_AUFFORDERUNG: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_FEM_MELDER_EDIT: [ROLES.ROLE_FEMMELDER, ROLES.SUPPORT],
    ROLLEN_GLOESCHTE_OBJEKTE: [ROLES.BEHOERDE],
    ROLLEN_INVEST_REGISTRATION_FAILED: [ROLES.LV, ROLES.SUPPORT],
    ROLLEN_LANDSCHAFTSVERBAND: [ROLES.MINISTERIUM, ROLES.SUPPORT],
    ROLLEN_LANDSCHAFTSVERBAND_EDIT: [ROLES.SUPPORT],
    ROLLEN_LEISTUNGSANBIETER_FREIGEBEN: [ROLES.BEHOERDE, ROLES.LV, ROLES.SUPPORT],
    ROLLEN_LEISTUNGSANGEBOTE: [ROLES.ANBIETER],
    ROLLEN_LEISTUNGSANGEBOTE_FREIGEBEN: [ROLES.BEHOERDE],
    ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN: [
        ROLES.BEHOERDE,
        ROLES.BEZIERKSREGIERUNG,
        ROLES.MINISTERIUM,
        ROLES.SUPPORT,
        ROLES.LV,
    ],
    ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN_EDIT: [ROLES.BEHOERDE, ROLES.LV, ROLES.SUPPORT],
    ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN_DETAILS: [
        ROLES.ANBIETER,
        ROLES.BEHOERDE,
        ROLES.BEZIERKSREGIERUNG,
        ROLES.MINISTERIUM,
        ROLES.SUPPORT,
        ROLES.LV,
    ],
    ROLLEN_LEISTUNGSANGEBOTE_NACHRICHTEN: [ROLES.ANBIETER, ROLES.BEHOERDE, ROLES.MINISTERIUM, ROLES.SUPPORT],
    ROLLEN_MANDANT_BEARBEITEN_BEHOERDE: [ROLES.BEHOERDE],
    ROLLEN_MANDANT_BEARBEITEN_LV: [ROLES.LV],
    ROLLEN_MASSENMAIL: [ROLES.BEHOERDE],
    ROLLEN_MELDUNG_HINWEISTEXT: [ROLES.BEHOERDE],
    ROLLEN_MELDUNGEN: [ROLES.BEHOERDE, ROLES.ANBIETER, ROLES.SUPPORT],
    ROLLEN_MELDUNGEN_AUFFORDERUNG: [ROLES.BEHOERDE],
    ROLLEN_MELDUNGEN_AUFGEFORDERT: [ROLES.BEHOERDE],
    ROLLEN_MELDUNGEN_DETAIL: [ROLES.ANBIETER, ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_MELDUNGEN_PRUEFEN: [ROLES.BEHOERDE],
    ROLLEN_MELDUNGEN_FREIGEBEN: [ROLES.BEHOERDE],
    ROLLEN_MELDUNGEN_FREIGEBEN_OVERVIEW: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_MELDUNGEN_OHNE_ZUSTAENDIGKEIT: [ROLES.BEHOERDE],
    ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG: [ROLES.BEHOERDE],
    ROLLEN_PLATZMELDER: [ROLES.ROLE_PLATZMELDER],
    ROLLEN_PLATZMELDER_AUFFORDERUNG: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_PLATZMELDER_BEHOERDE: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_PLATZMELDER_BEHOERDE_MENU: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_PLATZMELDER_DETAIL: [ROLES.BEHOERDE],
    ROLLEN_PLATZMELDER_HISTORY: [ROLES.BEHOERDE],
    ROLLEN_PROFIL_HEIMFINDER: [ROLES.ROLE_HEIMFINDER],
    ROLLEN_REGELPRUEFUNG: [ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.MINISTERIUM, ROLES.BEZIERKSREGIERUNG],
    ROLLEN_REGELPRUEFUNG_CREATE: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_REGELPRUEFUNG_EXPORT: [ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.MINISTERIUM, ROLES.BEZIERKSREGIERUNG],
    ROLLEN_PRUEFBERICHTE: [ROLES.BEHOERDE, ROLES.BEZIERKSREGIERUNG, ROLES.SUPPORT],
    ROLLEN_TRAEGER: [ROLES.BEHOERDE, ROLES.MINISTERIUM, ROLES.SUPPORT, ROLES.LV],
    ROLLEN_TRAEGER_CREATE: [ROLES.BEHOERDE, ROLES.SUPPORT],
    ROLLEN_TRAEGER_MANDANT_ANSEHEN: [ROLES.TRAEGER],
    ROLLEN_TRAEGERVERBAENDE: [ROLES.BEHOERDE, ROLES.MINISTERIUM, ROLES.LV, ROLES.SUPPORT],
    ROLLEN_TRAEGERVERBAENDE_FORM: [ROLES.LV, ROLES.SUPPORT],
    ROLLEN_ZUGRIFFSRECHTE: [ROLES.BEHOERDE, ROLES.MINISTERIUM, ROLES.ANBIETER, ROLES.SUPPORT, ROLES.LV],
    ROLLEN_ZUGRIFFSRECHTE_ADD: [ROLES.ANBIETER, ROLES.SUPPORT],
};
