import {
    buildPath,
    PATH_ANTRAG,
    PATH_ANTRAG_AUFFORDERUNG,
    PATH_DELETE,
    PATH_EDIT,
    PATH_NACHRICHTEN,
    PATH_VIEW,
    PATH_ZUGRIFFSRECHTE,
} from 'navigation/Paths';

export const behoerdeLeistungsangeboteActions = (...paths: (number | string | undefined | null)[]) => ({
    path: buildPath(...paths),
    view: ({ id }: { id?: string | number }) => buildPath(...paths, id, PATH_VIEW),
    edit: ({ id }: { id: string | number }) => buildPath(...paths, id, PATH_EDIT),
    nachrichten: ({ einrichtungId }: { einrichtungId: number }) => buildPath(...paths, einrichtungId, PATH_NACHRICHTEN),
    create_nachricht: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
        buildPath(...paths, einrichtungId, PATH_NACHRICHTEN, antragId, PATH_ANTRAG_AUFFORDERUNG),
    antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
        buildPath(...paths, einrichtungId, PATH_NACHRICHTEN, antragId),
    antrag: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
        buildPath(...paths, einrichtungId, PATH_EDIT, PATH_ANTRAG, antragId),
    overview: () => buildPath(...paths),
    zugriffsrechte: ({ einrichtungId }: { einrichtungId: number }) =>
        buildPath(...paths, einrichtungId, PATH_ZUGRIFFSRECHTE),
    aufforderung: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
        buildPath(...paths, einrichtungId, PATH_ANTRAG, antragId, PATH_ANTRAG_AUFFORDERUNG),
    deletionReason: ({ id }: { id?: string | number }) => buildPath(...paths, id, PATH_DELETE),
});
