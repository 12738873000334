import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { EmailAntragCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';
import { BENACHRICHTIGUNG_STATUS_FILTER_LABELS } from 'constants/labels';

export const useNachrichtenListData = (): GetData<EmailAntragCollectionResponse> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        const isRead = (read?: string) => {
            switch (read) {
                case BENACHRICHTIGUNG_STATUS_FILTER_LABELS.GELESEN:
                    return true;
                case BENACHRICHTIGUNG_STATUS_FILTER_LABELS.UNGELESEN:
                    return false;
                default:
                    return undefined;
            }
        };

        return backendApiService.getEmailAntragCollection({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText,
            orderEinrichtungName: sortBy(sortOrder, 'einrichtung') || sortBy(sortOrder, 'einrichtungName'),
            orderEinrichtungNrwKey: sortBy(sortOrder, 'nrwKey'),
            orderBetreff: sortBy(sortOrder, 'betreff'),
            orderEingereichtAt: sortBy(sortOrder, 'eingereichtAt'),
            orderEingereichtByNachname: sortBy(sortOrder, 'sender'),
            orderTyp: sortBy(sortOrder, 'typ'),
            status: 'VERSENDET',
            isRead: isRead(filters?.['read']?.[0]),
        });
    }, []);
};
