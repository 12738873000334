import React from 'react';

import { useApiVerifyEmailToken } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import useQuery from 'utilities/hooks/useQuery';

import { ExceptionMessage } from './components/ExceptionMessage';
import { FailureMessage } from './components/FailureMessage';
import { SuccessMessage } from './components/SuccessMessage';

export const VerifyEmail = () => {
    const query = useQuery();
    const token = query.get('h');

    const { isLoading, error } = useApiVerifyEmailToken(token || '');

    return (
        <ContentContainer title="E-Mail-Adresse verifizieren">
            <AppLoaderContainer isLoading={isLoading}>
                {error ? (
                    error.response?.status === 500 ? (
                        <ExceptionMessage />
                    ) : (
                        <FailureMessage data={error.response?.data as { email: string }} />
                    )
                ) : (
                    <SuccessMessage />
                )}
            </AppLoaderContainer>
        </ContentContainer>
    );
};
