import React, { ReactNode, useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { EinrichtungArtType } from 'api/types';
import { EinrichtungTypes } from 'constants/einrichtungTypes';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

const defaultPlegePlaetzeColumns: MUIDataTableColumnDef[] = [
    {
        name: 'dauerpflegeplaetze',
        label: 'Dauerpflegeplätze',
    },
    {
        name: 'kurzpflegeplaetze',
        label: 'Kurzzeitpflegeplätze',
    },
];

const tagsepflegeplaetzeColumns: MUIDataTableColumnDef[] = [
    {
        name: 'montag',
        label: 'Montag',
    },
    {
        name: 'dienstag',
        label: 'Dienstag',
    },
    {
        name: 'mittwoch',
        label: 'Mittwoch',
    },
    {
        name: 'donnerstag',
        label: 'Donnerstag',
    },
    {
        name: 'freitag',
        label: 'Freitag',
    },
];

export const usePlatzzahlmelderHistorieColumns = (
    einrichtungArt?: EinrichtungTypes
): (() => MUIDataTableColumnDef[]) => {
    const { transform2Date } = useFormatDate();

    const renderHTMLContent = (value: string): ReactNode => (
        <span dangerouslySetInnerHTML={{ __html: String(value ?? '-') }} />
    );

    return useCallback(
        (): Array<MUIDataTableColumnDef> => [
            ...(EinrichtungArtType[EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE].label !== einrichtungArt
                ? defaultPlegePlaetzeColumns
                : tagsepflegeplaetzeColumns),
            {
                name: 'beschreibung',
                label: 'Beschreibung',
                options: {
                    display: false,
                    customBodyRender: renderHTMLContent,
                },
            },
            {
                name: 'createdAt',
                label: 'Gemeldet am',
                options: {
                    customBodyRender: (value) => {
                        return value ? transform2Date(value, 'dd.MM.yyyy - HH:mm') : '-';
                    },
                },
            },
        ],
        [einrichtungArt, transform2Date]
    );
};
