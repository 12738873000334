import format from 'date-fns/format';
import { padEnd } from 'lodash';

import { Token } from 'api/types';

export type JWTToken = {
    benutzerId?: number;
    username: string;
    vorname: string;
    nachname: string;
    rolle: string;
    roles?: string[];
    exp: number;
    iat: number;
    behoerdeId?: number | null;
    landschaftsverbandId?: number | null;
    traegerId?: number | null;
};

export type AuthUser = Omit<JWTToken, 'exp' | 'iat'>;

export type LogoutType = 'AUTOMATIC' | 'MANUAL';

export type AuthState = {
    user: AuthUser | null;
    token: Token | null;
    expiresAt: number | null;

    login: (token: Token) => void;
    logout: (type: LogoutType) => void;

    logoutType: LogoutType | null;
    clearLogoutType: () => void;

    impersonator: AuthUser | null;
    impersonate: (impersonator: AuthUser | null) => void;

    pageTimeout: number | null;
    resetPageTimeout: () => void;
};

export type AuthConfig = {
    /**
     * If true, auth logging will be active during dev mode
     */
    logging: boolean;
    /**
     * The number of seconds a user can stay on a given page.
     * 2700 (45 Minutes).
     */
    pageTimeout: number;
    /**
     * The number of seconds before the page timeout warning.
     * 300 (5 Minutes - the warning will appear after 40 minutes).
     */
    pageTimeoutWarningThreshold: number;
    /**
     * Minimum jitter milliseconds for the automatic token refresh.
     * 300.000 (5 Minutes).
     */
    refreshJitterMin: number;
    /**
     * Maximum jitter milliseconds for the automatic token refresh.
     * 420.000 (7 Minutes).
     */
    refreshJitterMax: number;
};

export const authConfig: AuthConfig = {
    logging: process.env.REACT_APP_ENABLE_AUTH_LOGGING === 'true',
    pageTimeout: 2700,
    pageTimeoutWarningThreshold: 300,
    refreshJitterMin: 300000,
    refreshJitterMax: 450000,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authLog = (message: string, ...args: any[]) => {
    if (!authConfig.logging) return;
    console.debug(`[Auth/${format(Date.now(), 'HH:mm:ss')}]`, padEnd(message, 60), ...args);
};
