import { DependencyList, useEffect, useReducer } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { useIsLoggedIn } from 'api/auth';
import {
    ApiPlatzmelderTokenGetCollection200Response,
    BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung,
    BenutzerDtoJsonld,
    BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm,
    BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles,
    BenutzerTablePreference,
    GetEinrichtungenForPlatzzahlmelder200Response,
    MeldungJsonldMeldungRead,
    RegelpruefungAntragJsonldRegelpruefungRead,
    SignaturJsonldSignaturRead,
    VerifizierungsMailJsonldVerifizierungsmailWrite,
} from 'api/client';
import {
    BenutzerListOptionParams,
    BenutzerListResponse,
    BenutzerProfileResponse,
    BenutzerResponse,
    BenutzerTablePreferenceResponse,
    DokumentPruefungGet,
    EinrichtungGetByIdResponse,
    EinrichtungZugriffsrechteResponse,
    EmailAntragCollectionParams,
    EmailAntragCollectionResponse,
    EmailAntragItem,
    FemAntragDetailResponse,
    FemExportRequestParams,
    FemExportRequestResponse,
    LandschaftsverbandItem,
    MassenmailFormGet,
    MeldungHinweisDokumentGet,
    MeldungHinweistextGet,
    PlatzzahlmelderAntragResponse,
    ProfilHeimfinderFormGet,
    StandardauswertungRequestParams,
    StandardauswertungResponse,
    TraegerDtoResponse,
    TraegerResponse,
    TraegerverbandData,
    WohngemeinschaftFormGet,
} from 'api/types';
import { STATUS } from 'constants/antragStatus';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

const API_ACTION_LOAD = 'LOAD';
const API_ACTION_SUCCESS = 'LOADED';
const API_ACTION_ERROR = 'ERROR';

type ApiAction<T> = { type: 'LOADED'; payload: T } | { type: 'ERROR'; payload: AxiosError } | { type: 'LOAD' };
type ApiReducer<T> = (state: ApiState<T>, action: ApiAction<T>) => ApiState<T>;
type ApiState<T> = {
    isLoading: boolean | undefined;
    error: AxiosError | undefined;
    data: T | undefined;
};

export const apiStateInitial: ApiState<never> = {
    isLoading: undefined,
    error: undefined,
    data: undefined,
};

export const getApiReducer = <T>(): ApiReducer<T> => {
    return (state, action) => {
        switch (action.type) {
            case API_ACTION_LOAD:
                return {
                    ...state,
                    isLoading: true,
                };
            case API_ACTION_SUCCESS:
                return {
                    isLoading: false,
                    data: action.payload,
                    error: undefined,
                };
            case API_ACTION_ERROR:
                return {
                    data: undefined,
                    isLoading: false,
                    error: action.payload,
                };
        }
    };
};

export const reduce = <T>(promise: Promise<T>, dispatch: (p: ApiAction<T>) => void) => {
    dispatch({ type: API_ACTION_LOAD });
    promise
        .then((data) => {
            return dispatch({ type: API_ACTION_SUCCESS, payload: data });
        })
        .catch((err) => dispatch({ type: API_ACTION_ERROR, payload: err }));
};

export const useApiBenutzerTablePreference = (
    name: string,
    withFilters: boolean
): ApiState<BenutzerTablePreference> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerTablePreferenceResponse>(), apiStateInitial);

    useEffect(() => {
        if (!withFilters) return;
        reduce(backendApiService.getBenutzerTablePreference(name), dispatch);
    }, [name, withFilters]);

    return state;
};

export const useApiProfile = (): ApiState<BenutzerProfileResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerProfileResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getProfile(), dispatch);
    }, []);

    return state;
};

export const useApiBenutzerCollection = ({
    page,
    itemsPerPage,
    existsDeaktiviertAt,
    id,
    id2,
    rolle,
    rolle2,
    landschaftsverband,
    landschaftsverband2,
    einrichtungBenutzerEinrichtung,
    einrichtungBenutzerEinrichtung2,
    traegerverband,
    traegerverband2,
    qSearch,
    qImpersonateSearch,
    orderNachname,
    orderVorname,
    orderEmail,
    orderDeaktiviertAt,
}: BenutzerListOptionParams) => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerListResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(
            backendApiService.getBenutzerCollection({
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
            }),
            dispatch
        );
    }, [
        page,
        itemsPerPage,
        existsDeaktiviertAt,
        id,
        id2,
        rolle,
        rolle2,
        landschaftsverband,
        landschaftsverband2,
        einrichtungBenutzerEinrichtung,
        einrichtungBenutzerEinrichtung2,
        traegerverband,
        traegerverband2,
        qSearch,
        qImpersonateSearch,
        orderNachname,
        orderVorname,
        orderEmail,
        orderDeaktiviertAt,
    ]);

    return state;
};

export const useBenutzerItem = (id: string): ApiState<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> => {
    const [state, dispatch] = useReducer(
        getApiReducer<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>(),
        apiStateInitial
    );

    useEffect(() => {
        reduce(backendApiService.getBenutzerItem(id), dispatch);
    }, [id]);

    return state;
};

export const useBenutzerDtoItem = (id: string): ApiState<BenutzerDtoJsonld> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerDtoJsonld>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getBenutzerDtoItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiEmailAntragCollection = ({
    page,
    itemsPerPage,
    orderName,
    orderEingereichtAt,
}: EmailAntragCollectionParams) => {
    const [state, dispatch] = useReducer(getApiReducer<EmailAntragCollectionResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(
            backendApiService.getEmailAntragCollection({
                page,
                itemsPerPage,
                orderName,
                orderEingereichtAt,
            }),
            dispatch
        );
    }, [page, itemsPerPage, orderName, orderEingereichtAt]);

    return state;
};

export const useBehoerdeDtoItem = (id: string): ApiState<BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung> => {
    const [state, dispatch] = useReducer(
        getApiReducer<BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung>(),
        apiStateInitial
    );

    useEffect(() => {
        reduce(backendApiService.getBehoerdeDtoItem({ id }), dispatch);
    }, [id]);

    return state;
};

export const useApiEmailSignatur = (): ApiState<SignaturJsonldSignaturRead> => {
    const [state, dispatch] = useReducer(getApiReducer<SignaturJsonldSignaturRead>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getEmailSignatur(), dispatch);
    }, []);

    return state;
};

export const useLandschaftsverbandItem = (id: string): ApiState<LandschaftsverbandItem> => {
    const [state, dispatch] = useReducer(getApiReducer<LandschaftsverbandItem>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getLandschaftsverbandDtoItem(id), dispatch);
    }, [id]);

    return state;
};

export const useBenutzerFormItemById = (
    id: string
): ApiState<BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm> => {
    const [state, dispatch] = useReducer(
        getApiReducer<BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm>(),
        apiStateInitial
    );

    useEffect(() => {
        reduce(backendApiService.getBenutzerFormItemById(id), dispatch);
    }, [id]);

    return state;
};

export const useApiCheckChangeToken = (token?: string, options?: AxiosRequestConfig): ApiState<BenutzerResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<BenutzerResponse>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.checkChangeToken({ token }, options), dispatch);
    }, [token, options]);

    return state;
};

export const useApiVerifyEmailToken = (
    hash: string,
    options?: AxiosRequestConfig
): ApiState<VerifizierungsMailJsonldVerifizierungsmailWrite> => {
    const [state, dispatch] = useReducer(
        getApiReducer<VerifizierungsMailJsonldVerifizierungsmailWrite>(),
        apiStateInitial
    );

    useEffect(() => {
        reduce(backendApiService.verifyEmail(hash, options), dispatch);
    }, [hash, options]);

    return state;
};

export const useApiAccountDeaktivieren = (
    hash: string,
    options?: AxiosRequestConfig
): ApiState<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> => {
    const [state, dispatch] = useReducer(
        getApiReducer<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>(),
        apiStateInitial
    );

    useEffect(() => {
        reduce(backendApiService.profileAccountDeaktivieren(hash, options), dispatch);
    }, [hash, options]);

    return state;
};

export const useApiFemExportGet = ({ formData, options }: FemExportRequestParams) => {
    const [state, dispatch] = useReducer(getApiReducer<FemExportRequestResponse>(), apiStateInitial);

    const loadData = ({ formData, options }: FemExportRequestParams) => {
        reduce(
            backendApiService.getFemExportFormData({
                formData,
                options,
            }),
            dispatch
        );
    };

    useEffect(() => {
        loadData({ formData, options });
    }, [formData, options]);

    return { ...state, loadData };
};

export const useApiStandardauswertungGet = ({ formData, options }: StandardauswertungRequestParams) => {
    const [state, dispatch] = useReducer(getApiReducer<StandardauswertungResponse>(), apiStateInitial);

    const loadData = ({ formData, options }: StandardauswertungRequestParams) => {
        reduce(
            backendApiService.getStandardauswertung({
                formData,
                options,
            }),
            dispatch
        );
    };

    useEffect(() => {
        loadData({ formData, options });
    }, [formData, options]);

    return { ...state, loadData };
};

export const useApiEinrichtungGet = (id?: string, options?: AxiosRequestConfig) => {
    const [state, dispatch] = useReducer(getApiReducer<EinrichtungGetByIdResponse>(), apiStateInitial);

    const loadData = (id?: string) => {
        reduce(backendApiService.getEinrichtungById(String(id), options), dispatch);
    };

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiEinrichtungGet(id)');

        loadData(id);
        // eslint-disable-next-line
    }, [id]);

    return { ...state, loadData };
};

export const useApiZugriffsrechteGet = (
    id?: string,
    options?: AxiosRequestConfig
): ApiState<EinrichtungZugriffsrechteResponse> & { loadData: (id?: string) => void } => {
    const [state, dispatch] = useReducer(getApiReducer<EinrichtungZugriffsrechteResponse>(), apiStateInitial);

    const loadData = (id?: string) => {
        reduce(backendApiService.getZugriffsrechte(String(id), options), dispatch);
    };

    return { ...state, loadData };
};

export const useApiMeldungItem = <T = MeldungJsonldMeldungRead>(id?: string): ApiState<T> => {
    const [state, dispatch] = useReducer(getApiReducer<T>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiMeldungItem(id)');

        reduce(backendApiService.getMeldungItem<T>(id), dispatch);
    }, [id]);

    return state;
};

export const useApiTraegerverbandItem = (id: string): ApiState<TraegerverbandData> => {
    const [state, dispatch] = useReducer(getApiReducer<TraegerverbandData>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiTraegerverbandItem(id)');

        reduce(backendApiService.getTraegerverbandDtoItem(String(id)), dispatch);
    }, [id]);

    return state;
};

export const useApiTraegerItem = (id: string): ApiState<TraegerResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<TraegerResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiTraegerItem(id)');

        reduce(backendApiService.getTraegerItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiTraegerDtoItem = (id?: string): ApiState<TraegerDtoResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<TraegerDtoResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) {
            return undefined;
        }

        reduce(backendApiService.getTraegerDtoItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiProfilHeimfinderItem = (id?: string | null): ApiState<ProfilHeimfinderFormGet> => {
    const [state, dispatch] = useReducer(getApiReducer<ProfilHeimfinderFormGet>(), apiStateInitial);

    useEffect(() => {
        reduce(backendApiService.getProfilHeimfinderById(String(id)), dispatch);
    }, [id]);

    return state;
};

type ApiPlatzzahlmelderEinrichtungenCollection = {
    page?: number;
    rowsPerPage?: number;
    searchText?: string;
    status?: STATUS;
};
export const useApiPlatzzahlmelderEinrichtungenCollection = (
    { page, rowsPerPage, searchText, status }: ApiPlatzzahlmelderEinrichtungenCollection,
    deps: DependencyList = []
): ApiState<GetEinrichtungenForPlatzzahlmelder200Response> & {
    loadData: ({ page, rowsPerPage, searchText, status }: ApiPlatzzahlmelderEinrichtungenCollection) => void;
} => {
    const isLoggedIn = useIsLoggedIn();

    const [state, dispatch] = useReducer(
        getApiReducer<GetEinrichtungenForPlatzzahlmelder200Response>(),
        apiStateInitial
    );

    const loadData = ({ page, rowsPerPage, searchText, status }: ApiPlatzzahlmelderEinrichtungenCollection) => {
        reduce(
            backendApiService.getListForPlatzzahlmelder({
                page,
                itemsPerPage: rowsPerPage,
                status: status,
                qSearch: searchText,
            }),
            dispatch
        );
    };

    useDebouncedEffect(
        () => {
            if (!isLoggedIn) {
                return;
            }

            loadData({ page, rowsPerPage, searchText, status });
        },
        [page, rowsPerPage, searchText, status, ...deps],
        100
    );

    return { ...state, loadData };
};

export const useApiPlatzzahlmelderItem = (id: number): ApiState<PlatzzahlmelderAntragResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<PlatzzahlmelderAntragResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiPlatzzahlmelderItem(id)');

        reduce(backendApiService.getPlatzzahlmelderItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiPlatzzahlmelderTokenCollection = (
    token?: string,
    deps: DependencyList = []
): ApiState<ApiPlatzmelderTokenGetCollection200Response> & {
    loadTokenCollection: (token?: string) => void;
} => {
    const isLoggedIn = useIsLoggedIn();
    const [state, dispatch] = useReducer(getApiReducer<ApiPlatzmelderTokenGetCollection200Response>(), apiStateInitial);

    const loadTokenCollection = (token?: string) => {
        reduce(backendApiService.getPlatzzahlmelderTokenCollection(token), dispatch);
    };

    useDebouncedEffect(
        () => {
            if (isLoggedIn) {
                return;
            }

            loadTokenCollection(token);
        },
        [isLoggedIn, token, ...deps],
        100
    );

    return { ...state, loadTokenCollection };
};

export const useApiEmailAntragItem = (id?: string): ApiState<EmailAntragItem> => {
    const [state, dispatch] = useReducer(getApiReducer<EmailAntragItem>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiEmailAntragItem(id)');

        reduce(backendApiService.getEmailAntragItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiFemAntragItem = (id?: string): ApiState<FemAntragDetailResponse> => {
    const [state, dispatch] = useReducer(getApiReducer<FemAntragDetailResponse>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiFemAntragItem(id)');

        reduce(backendApiService.getFemAntragItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiMassenmailItem = (id?: string): ApiState<MassenmailFormGet> => {
    const [state, dispatch] = useReducer(getApiReducer<MassenmailFormGet>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiMassenmailItem(id)');

        reduce(backendApiService.getMassenmailItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiMeldungHinweistextItem = (id?: string): ApiState<MeldungHinweistextGet> => {
    const [state, dispatch] = useReducer(getApiReducer<MeldungHinweistextGet>(), apiStateInitial);

    useEffect(() => {
        if (!id) return undefined;

        reduce(backendApiService.getMeldungHinweistextItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiMeldungHinweisDokumentItem = (id?: string): ApiState<MeldungHinweisDokumentGet> => {
    const [state, dispatch] = useReducer(getApiReducer<MeldungHinweisDokumentGet>(), apiStateInitial);

    useEffect(() => {
        if (!id) return undefined;

        reduce(backendApiService.getMeldungHinweisDokumentItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiDokumentPruefungItem = (id?: string): ApiState<DokumentPruefungGet> => {
    const [state, dispatch] = useReducer(getApiReducer<DokumentPruefungGet>(), apiStateInitial);

    useEffect(() => {
        if (!id) return undefined;

        reduce(backendApiService.getDokumentPruefungItem(id), dispatch);
    }, [id]);

    return state;
};

export const useApiWohngemeinschaftItem = (
    id?: string
): ApiState<WohngemeinschaftFormGet> & { loadData: (id?: string) => void } => {
    const [state, dispatch] = useReducer(getApiReducer<WohngemeinschaftFormGet>(), apiStateInitial);

    const loadData = (id?: string) => {
        reduce(backendApiService.getWohngemeinschaftItem(String(id)), dispatch);
    };

    useEffect(() => {
        loadData(id);
    }, [id]);

    return { ...state, loadData };
};

export const useApiRegelpruefungItem = (id?: string): ApiState<RegelpruefungAntragJsonldRegelpruefungRead> => {
    const [state, dispatch] = useReducer(getApiReducer<RegelpruefungAntragJsonldRegelpruefungRead>(), apiStateInitial);

    useEffect(() => {
        if (!id) return console.warn('Id must be set for useApiRegelpruefungItem(id)');

        reduce(backendApiService.getRegelpruefungItem(id), dispatch);
    }, [id]);

    return state;
};
