import React, { useCallback } from 'react';
import { Warning } from '@mui/icons-material';
import { styled, Tooltip } from '@mui/material';
import { isFuture } from 'date-fns';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { AntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { FemAntragCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeStatus } from 'components/DataTable/components/BadgeStatus';
import { JahrFilterOptions } from 'components/DataTable/filters/JahrFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDate, getDateTime, getRowData } from 'components/DataTable/tableUtils';

export const useFemAntragColumns = (
    handleAction: AntragListAction<any>,
    filterOptions: AntragTableFilterOptions | undefined
): ((data: FemAntragCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            const submissionInFuture = (dataIndex: number) => {
                const rowData = getRowData(dataIndex, data);
                const submissionDate = new Date(rowData?.abgabeAb || '');

                return isFuture(submissionDate) ? (
                    <Tooltip title={`Abgabe erst am ${getDate(rowData?.abgabeAb)} möglich`}>
                        <WarningIcon fontSize="medium" />
                    </Tooltip>
                ) : null;
            };

            return [
                IdColumn(),
                {
                    name: '',
                    options: {
                        filter: false,
                        sort: false,
                        viewColumns: false,
                        customBodyRenderLite: submissionInFuture,
                    },
                },
                {
                    name: 'jahr',
                    label: 'Jahr',
                    options: {
                        ...JahrFilterOptions('Jahr', filterOptions?.antragJahr),
                    },
                },
                {
                    name: 'quartal',
                    label: 'Quartal',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'startDesQuartals',
                    label: 'Start des Quartals',
                    options: {
                        filter: false,
                        customBodyRender: getDate,
                    },
                },
                {
                    name: 'abgabeAb',
                    label: 'Abgabe ab',
                    options: {
                        filter: false,
                        customBodyRender: getDate,
                    },
                },
                {
                    name: 'status',
                    label: 'Status',
                    options: {
                        filter: false,
                        customBodyRender: (value) => <BadgeStatus status={value} />,
                    },
                },
                {
                    name: 'zuletztAufgefordertAt',
                    label: 'Zuletzt aufgefordert am',
                    options: {
                        filter: false,
                        customBodyRender: getDateTime,
                    },
                },
                actions(data),
            ];
        },
        [actions, filterOptions?.antragJahr]
    );
};

const WarningIcon = styled(Warning)(({ theme }) => ({
    color: theme.palette.error.main,
    textAlign: 'center',
}));
