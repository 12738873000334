import React from 'react';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiStandardauswertungGet } from 'api/hooks/useApiClient';
import { StandardauswertungCsvExportParams, StandardauswertungRequestData } from 'api/types';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { Schema } from 'forms/types/UiSchemaTypes';
import { uiSchema } from 'forms/ui-schemas/statistik/standardauswertung';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAuswertung } from 'navigation/menuConfigs/menuAuswertung';
import { withAuthorization } from 'navigation/withAuthorization';
import { useExportMessage } from 'pages/Auswertung/useExportMessage';

const Index = () => {
    const { createSuccessMessage, createErrorMessage } = useExportMessage();

    const { submitEnd } = useFormState();

    const { isLoading, data, error, loadData } = useApiStandardauswertungGet({});

    const handleSubmitData = (formData: StandardauswertungCsvExportParams) => {
        backendApiService
            .getStandardauswertungExport(formData)
            .then(() => createSuccessMessage())
            .catch(() => createErrorMessage())
            .finally(() => submitEnd());
    };

    const handleOnChange = (formData: StandardauswertungRequestData) => {
        loadData({ formData });
    };

    return (
        <ContentContainer title="Auswertung generieren">
            <StyledContainer>
                <PageHeading title="Auswertung generieren" />

                <Alert severity="info">
                    <strong>Regionale Eingrenzung:</strong>
                    <br />
                    Bitte wählen Sie zunächst die regionalen Parameter aus, auf die sich Ihre Auswertung beziehen soll.
                    Wünschen Sie einen Vergleich mehrerer Regionen, so wählen Sie bitte mehrere Regionen aus.
                </Alert>

                {!error ? (
                    <BaseGridContainer lg={8}>
                        <SimpleForm
                            onChange={handleOnChange}
                            isLoading={isLoading && !data}
                            data={data?.data}
                            schema={data?.schema as Schema}
                            uiSchema={uiSchema}
                            submitLabel="Export Standardauswertung"
                            onSubmit={handleSubmitData}
                            ignoreLeaveWarning
                            buttonStyles={{
                                justifyContent: 'flex-end',
                            }}
                        />
                    </BaseGridContainer>
                ) : (
                    <Alert severity="error">Laden des Formulars ist fehlgeschlagen.</Alert>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};

export const StatistikPage = withAuthorization(withSideBar(Index, menuAuswertung), ROLE_TYPES.ROLLEN_AUSWERTUNG);
