import React from 'react';

import { SimpleForm } from 'forms/SimpleForm';
import { useFormLayoutConfig } from 'utilities/hooks/useFormLayoutConfig';

import { schema, uiSchema } from './config';
import { useRegistrationFormApi } from './useRegistrationFormApi';

const Index = () => {
    const config = useFormLayoutConfig();

    const { formData, handleSubmit, handleChange } = useRegistrationFormApi();

    return (
        <SimpleForm
            formConfig={config}
            data={formData}
            schema={schema}
            uiSchema={uiSchema}
            submitLabel="Jetzt registrieren"
            onSubmit={handleSubmit}
            onChange={handleChange}
            ignoreLeaveWarning
        />
    );
};

export const RegistrierungForm = Index;
