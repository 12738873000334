import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangebotBearbeiten } from 'pages/Leistungsangebote/components/LeistungsangebotBearbeiten';

const Index = () => {
    return <LeistungsangebotBearbeiten pathBuilder={PathBuilder.home.aufgaben.abw} />;
};

export const AbwAufgabenLeistungsangebotPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN
);
