import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useStartAdornment } from 'forms/hooks/useStartAdornment';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const AuswahlControlComponent: React.FC<CustomControlProps<string | number>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    onTouched,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    id,
}) => {
    const { getFormData } = useJsonFormsState();
    const startAdornment = useStartAdornment(uischema, schema);

    const handleValueChange = useCallback(
        (e: any) => {
            const currentFormData = getFormData();
            const selectedValue =
                schema.type === 'number' || uischema.options?.valueType === 'number'
                    ? parseInt(e.target.value, 10)
                    : e.target.value;

            config.requestValidate?.(path);

            if (uischema.options?.submitOnChange) {
                config.requestPersist?.(path);
            }

            config.onChange?.({ ...currentFormData, [path]: selectedValue });

            if (!config.onChange) {
                handleChange(path, selectedValue, true);
            }
        },
        [getFormData, config, path, uischema, handleChange, schema]
    );

    const labelId = useMemo(() => `${path}-${uuidv4()}`, [path]);
    const labelVisuallyHidden = useMemo(() => uischema.options?.labelVisuallyHidden ?? true, [uischema]);

    const MenuItems = useMemo(
        () =>
            (schema.oneOf || []).map((entry, index: number) => (
                <MenuItem key={`${index}#${entry.const}`} value={entry.const}>
                    {entry.title}
                </MenuItem>
            )),
        [schema.oneOf]
    );

    return (
        <RowControl
            id={path}
            name={path}
            label={label}
            required={required}
            formula={formula}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            labelForId={id}
            labelVisuallyHidden={labelVisuallyHidden}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            label={label}
                            hasErrors={hasErrors}
                            errors={errors}
                            text={(data && (schema.oneOf?.find((e) => e.const === String(data))?.title || data)) || '-'}
                        />
                    ) : (
                        <FormControl fullWidth size="small">
                            <InputLabel id={labelId} error={hasErrors}>
                                {label}
                                {required && <RequiredFlag />}
                            </InputLabel>

                            <Select
                                labelId={labelId}
                                id={id}
                                fullWidth
                                label={label}
                                error={hasErrors}
                                displayEmpty={!labelVisuallyHidden}
                                onBlur={onTouched}
                                disabled={disabled}
                                required={required}
                                value={data}
                                onChange={handleValueChange}
                                inputProps={{ 'data-cy': `form_${path}` }}
                                multiple={isArray(data)}
                                tabIndex={uischema?.firstControl ? 0 : -1}
                                autoFocus={uischema?.firstControl}
                                startAdornment={startAdornment}
                            >
                                {(!required || !data) && (
                                    <MenuItem>
                                        <em>- Bitte wählen -</em>
                                    </MenuItem>
                                )}
                                {MenuItems}
                            </Select>

                            {!readonly && schema?.description && <FormHelperText>{schema.description}</FormHelperText>}
                            {!uischema?.withInfoIcon && !readonly && schema?.description ? (
                                <FormHelperText>{schema.description}</FormHelperText>
                            ) : null}

                            {!readonly && hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const AuswahlControl = withCustomControlProps(AuswahlControlComponent);
