import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { FormDialogData } from 'forms/controls/components/FormDialog';
import { FormConfig, FormDialogUiSchemaType, MultiScopeUiSchemaType, Schema } from 'forms/types/UiSchemaTypes';

export const useModalFormControlData = (config: FormConfig, uischema: MultiScopeUiSchemaType, schema: Schema) => {
    const [dialogFormData, setDialogFormData] = useState<FormDialogData>({} as FormDialogData);

    const scopeIndex = useCallback((scope: string): number => uischema?.scopes?.indexOf(scope), [uischema.scopes]);

    const uiSchemaForScopeAndAction = useCallback(
        (scope: string, actionKey?: string | null): FormDialogUiSchemaType =>
            uischema.uiSchemas.find((schema) => schema.scope === scope && schema.actionKey === actionKey) ||
            uischema.uiSchemas[scopeIndex(scope)],
        [scopeIndex, uischema.uiSchemas]
    );

    useEffect(() => {
        const uiSchema = uiSchemaForScopeAndAction(dialogFormData.scope, dialogFormData.action);
        const formDialogConfig = {
            ...config,
            readonly: dialogFormData.readonly,
        };

        if (!dialogFormData.scope || isEqual(dialogFormData.config, formDialogConfig)) return;

        setDialogFormData({
            ...dialogFormData,
            title: dialogFormData.isNew ? uiSchema.title : uiSchema.editTitle,
            subTitle: uiSchema.subTitle,
            uiSchema: uiSchema.uiSchema,
            config: formDialogConfig,
            schema,
        });
    }, [uiSchemaForScopeAndAction, schema, uischema, config, dialogFormData]);

    const prepareModal = useCallback(
        (dialogProps: {
            scope: string;
            isNew?: boolean;
            readonly?: boolean;
            uuid?: string;
            action?: string | null;
        }) => {
            setDialogFormData(dialogProps);
        },
        []
    );

    const clearModal = useCallback(() => {
        setDialogFormData({} as FormDialogData);
    }, []);

    return { dialogFormData, prepareModal, clearModal };
};
