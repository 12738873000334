import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { BehoerdenListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useBehoerdenListData = (): GetData<BehoerdenListResponse> => {
    return useCallback(
        ({ page, searchText, rowsPerPage, sortOrder }) =>
            backendApiService.getBehoerdenCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderAdresseEmail: sortBy(sortOrder, 'email'),
                orderAdresseName: sortBy(sortOrder, 'ansprechpartner'),
            }),
        []
    );
};
