import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';

export const uiSchema = customVerticalLayout([
    group([
        control('empfaengerGruppe'),
        control('einrichtungsart'),
        control('betreff'),
        control('inhalt', { options: { rows: 20 } }),
        control('uploads', { withUploadLimit: true }),
    ]),
]);
