import React from 'react';
import { FileDownload } from '@mui/icons-material';
import { styled } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { GeneratedFile } from 'api/types';
import { buildPath } from 'forms/utilities/SchemaUtils';
import { getFileExtension, getReadableFileSize } from 'utilities/Utils';

interface DocumentDownloadProps {
    generatedFile?: GeneratedFile | null;
    withIcon?: boolean;
    openInWindow?: boolean;
}

export const DocumentDownload = React.forwardRef(
    (
        { generatedFile, withIcon, openInWindow }: DocumentDownloadProps,
        ref: React.ForwardedRef<HTMLAnchorElement | null>
    ) => {
        const { client } = backendApiService;

        const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            if (!openInWindow || !generatedFile) {
                return;
            }

            event.preventDefault();

            client.get(buildPath(generatedFile.contentUrl), { responseType: 'blob' }).then((response) => {
                const fileBlob = response.data;
                const blobUrl = window.URL.createObjectURL(fileBlob);
                const title = document.createElement('title');
                const newWindow = window.open(blobUrl, '_blank');

                title.innerText = String(generatedFile.originalName);
                newWindow?.document.head.appendChild(title);
                newWindow?.focus();
            });
        };

        return (
            <>
                {generatedFile ? (
                    <LinkText
                        ref={ref}
                        href={buildPath(generatedFile.contentUrl)}
                        target="_blank"
                        rel="noreferrer"
                        download={!openInWindow ? generatedFile.originalName : false}
                        onClick={(event) => handleClick(event)}
                        aria-label={`${generatedFile.originalName} (${getReadableFileSize(
                            Number(generatedFile.fileSize)
                        )}, ${getFileExtension(generatedFile.mimeType)})`}
                    >
                        {generatedFile.originalName} {withIcon ? <FileDownloadIcon /> : null}
                    </LinkText>
                ) : null}
            </>
        );
    }
);

const LinkText = styled('a')(() => ({
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
}));

const FileDownloadIcon = styled(FileDownload)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));
