import { useCallback } from 'react';
import { styled, Typography } from '@mui/material';

import { EinrichtungTypes } from 'constants/einrichtungTypes';

export const useBeschaeftigteDescription = () => {
    return useCallback((einrichtungArt?: string) => {
        switch (einrichtungArt) {
            case EinrichtungTypes.SHARED_HOME:
            case EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI:
            case EinrichtungTypes.FULLY_INPATIENT_SHORT:
            case EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII:
            case EinrichtungTypes.HOSPICE:
                return (
                    <>
                        <Typography paragraph>
                            Gemäß § 23 WTG DVO müssen Sie gegenüber der WTG-Behörde folgende Angaben zu den
                            Beschäftigten machen:
                        </Typography>

                        <StyledUnorderedList>
                            <li>die Zahl der Beschäftigten sowie deren Stellenumfang und Qualifikation,</li>

                            {einrichtungArt === EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII ? (
                                <li>
                                    den Namen, die berufliche Ausbildung und die Angaben, die für die Beurteilung der
                                    fachlichen Eignung der Einrichtungsleitung und der Fach bzw. Bereichsleitung
                                    wesentlich sind.
                                </li>
                            ) : (
                                <li>
                                    den Namen, die berufliche Ausbildung und die Angaben, die für die Beurteilung der
                                    fachlichen Eignung der Einrichtungsleitung und der Pflegedienstleitung wesentlich
                                    sind.
                                </li>
                            )}
                        </StyledUnorderedList>

                        {einrichtungArt === EinrichtungTypes.SHARED_HOME ? (
                            <Typography paragraph>
                                Bitte machen Sie daher folgende Angaben zu den Beschäftigten in der Wohngemeinschaft:
                            </Typography>
                        ) : (
                            <Typography paragraph>
                                Bitte machen Sie daher folgende Angaben zu den Beschäftigten in Ihrer Einrichtung:
                            </Typography>
                        )}
                    </>
                );
            case EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT:
                return (
                    <Typography paragraph>
                        Gemäß § 36 WTG DVO müssen Sie gegenüber der WTG-Behörde den Namen, die berufliche Ausbildung und
                        die Angaben, die für die Beurteilung der fachlichen Eignung der Unternehmensleitung sowie der
                        Pflegedienstleitung wesentlich sind, anzeigen. Bitte machen Sie daher folgende Angaben zu den
                        Beschäftigten:
                    </Typography>
                );
            case EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT:
                return (
                    <Typography paragraph>
                        Gemäß § 36 WTG DVO müssen Sie gegenüber der WTG-Behörde den Namen, die berufliche Ausbildung und
                        die Angaben, die für die Beurteilung der fachlichen Eignung der Unternehmensleitung sowie der
                        verantwortlichen Fachkraft wesentlich sind, anzeigen. Bitte machen Sie daher folgende Angaben zu
                        den Beschäftigten:
                    </Typography>
                );
            case EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE:
            case EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE:
            case EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION:
                return (
                    <Typography paragraph>
                        Gemäß § 43 Absatz 1 WTG DVO sind Gasteinrichtungen nur verpflichtet Angaben zu Beschäftigten der
                        Einrichtung (insbesondere zur Einrichtungsleitung) zu machen, wenn die zuständige WTG-Behörde
                        diese Angaben verlangt.
                    </Typography>
                );
        }
    }, []);
};

const StyledUnorderedList = styled('ul')(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(5),
}));
