export const basisdaten_table = {
    type: 'SummaryTable',
    label: '',
    columns: 3,
    scope: '#/properties/basisdaten',
    entries: [
        {
            type: 'FieldRow',
            label: 'Art der Einrichtung',
            scope: '#/properties/einrichtungArt',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungWohngemeinschaftArt',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungWohngemeinschaftLeistungsbereich',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungName',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungInstitutionskennzeichen',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungDatumErstinbetriebnahme',
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Adresse der Einrichtung',
            scope: '#/properties/einrichtungAdresse/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Adresse der Einrichtung',
                    scope: '#/properties/label',
                },
            ],
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Trägerverband der Einrichtung',
            scope: '#/properties/einrichtungTraegerverband/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Trägerverband der Einrichtung',
                    scope: '#/properties/name',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungHomepage',
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Träger-/Unternehmensgruppe',
            scope: '#/properties/unternehmensgruppe/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Träger-/Unternehmensgruppe',
                    scope: '#/properties/name',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/neueUnternehmensgruppe',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/tragerExistiertBereits',
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Träger',
            scope: '#/properties/traeger/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Träger',
                    scope: '#/properties/name',
                },
            ],
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Adresse des Trägers',
            scope: '#/properties/traegerAdresse/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Adresse des Trägers',
                    scope: '#/properties/label',
                },
            ],
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Trägerverband des Trägers',
            scope: '#/properties/traegerTraegerverband/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Trägerverband des Trägers',
                    scope: '#/properties/name',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungAnsprechpartner',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungTelefon',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungFax',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungEmail',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungPlaetze',
        },
        {
            type: 'FieldRow',
            label: 'Dokumente',
            scope: '#/properties/dokumente',
        },
        {
            type: 'FieldRow',
            title: 'Teilnahme an PfAD.invest',
            label: 'Pflegewohngeld beantragen',
            scope: '#/properties/pflegeWohngeld',
        },
        {
            type: 'FieldRow',
            label: 'Angaben nach § 29 APG DVO über die Anwendung PfAD.invest abgeben?',
            scope: '#/properties/angabenUeberInvest',
        },
        {
            type: 'FieldRow',
            label: 'Vorname',
            title: 'Angaben zum PfAD.invest-Benutzerkonto',
            scope: '#/properties/investVorname',
        },
        {
            type: 'FieldRow',
            label: 'Nachname',
            scope: '#/properties/investName',
        },
        {
            type: 'FieldRow',
            label: 'E-Mail-Adresse',
            scope: '#/properties/investEmail',
        },
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/wohngemeinschaften',
            label: 'Anzuzeigende Betreuung in Wohngemeinschaften',
            showLabel: true,
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Name der anzuzeigenden Betreuung',
                    scope: '#/properties/name',
                },
                {
                    type: 'SubTable',
                    label: 'Adresse',
                    scope: '#/properties/adresse',
                    entries: [
                        {
                            type: 'FieldRow',
                            label: 'Adresse',
                            scope: '#/properties/label',
                        },
                    ],
                },
                {
                    type: 'FieldRow',
                    label: 'Anzahl der betreuten Nutzer',
                    scope: '#/properties/plaetze',
                },
                {
                    type: 'FieldRow',
                    label: 'Datum Beginn der betreuten Tätigkeit',
                    scope: '#/properties/startDate',
                },
            ],
        },
    ],
};
