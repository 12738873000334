import { RuleEffect } from '@jsonforms/core';

import { AnschriftType, EinrichtungArtType } from 'api/types';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group(
        [
            control('einrichtungNrwkey'),
            control('einrichtungArt', { withInfoIcon: true }),
            control('einrichtungWohngemeinschaftArt'),
            control('einrichtungWohngemeinschaftLeistungsbereich'),
            control('einrichtungName'),
            inlineRowControl([
                control('einrichtungInstitutionskennzeichen'),
                control('einrichtungPlaetze'),
                control('einrichtungDatumErstinbetriebnahme', {
                    options: {
                        format: 'date',
                    },
                }),
            ]),
            control('einrichtungKurzzeitpflegeImKrankenhaus'),
            control('einrichtungPlatzzahlmeldepflicht'),
        ],
        {},
        'Angaben zum Leistungsangebot'
    ),
    group(
        [
            control('einrichtungAdresse', { withInfoIcon: true }),
            control('einrichtungTraegerverband'),
            control('einrichtungHomepage'),
            control('unternehmensgruppe'),
            control('neueUnternehmensgruppe', {
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/unternehmensgruppe/properties/id',
                        schema: { const: -2 },
                    },
                },
            }),
        ],
        { noSeparator: true },
        'Details zum Leistungsangebot'
    ),
    group([
        control('tragerExistiertBereits'),
        group(
            [
                control('traegerBezeichnung'),
                control('traegerAnschriftTyp'),
                group(
                    [
                        control('traegerPostfachBezeichnung'),
                        inlineRowControl([control('traegerPostfachPlz'), control('traegerPostfachOrt')]),
                    ],
                    {
                        unstyled: true,
                        noSeparator: true,
                        rule: {
                            effect: RuleEffect.SHOW,
                            condition: {
                                scope: '#/properties/traegerAnschriftTyp',
                                schema: { const: AnschriftType.POSTFACH },
                            },
                        },
                    }
                ),
                control('traegerAdresse', {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/traegerAnschriftTyp',
                            schema: { const: AnschriftType.HAUSANSCHRIFT },
                        },
                    },
                }),
                inlineRowControl([control('traegerTelefon'), control('traegerEmail')]),
                inlineRowControl([control('traegerTyp'), control('traegerFax')]),
            ],
            {
                noSeparator: true,
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#properties/tragerExistiertBereits',
                        schema: { const: false },
                    },
                },
            }
        ),
        control('traeger', {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#properties/tragerExistiertBereits',
                    schema: { const: true },
                },
            },
            withInfoIcon: true,
        }),
    ]),
    group(
        [
            control('einrichtungAnsprechpartner'),
            inlineRowControl([control('einrichtungTelefon'), control('einrichtungFax')]),
            control('einrichtungEmail'),
        ],
        {},
        'Ansprechpartner'
    ),
    group(
        [
            info({
                message:
                    'Versorgungsverträge, Vergütungs- oder Leistungsvereinbarungen nach SGB XII/IX oder sonstige Vereinbarungen.',
            }),
            control('dokumente'),
        ],
        {
            rule: {
                effect: RuleEffect.HIDE,
                condition: {
                    scope: '#/properties/einrichtungArt',
                    schema: {
                        enum: [EinrichtungArtType.WERKSTATT.value],
                    },
                },
            },
        },
        'Dokumente hochladen'
    ),
    group([control('mailText'), control('notiz')]),
]);
