import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';

export const schema = {
    required: ['text'],
    properties: {
        text: {
            type: 'string',
            title: 'Signatur',
            // @tslint-ignore
            custom: {
                block_prefixes: ['plain_textarea'],
            },
        },
    },
};

export const uiSchema = customVerticalLayout([group([control('text')])]);
