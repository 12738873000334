import React, { useMemo } from 'react';

import { useSpecificRole } from 'api/auth/useHasRole';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangebotDeletionReason } from 'pages/Leistungsangebote/components/LeistungsangebotDeletionReason';

const Index = () => {
    const { isTraeger } = useSpecificRole();

    const overviewPath = useMemo(() => {
        return isTraeger
            ? PathBuilder.home.verwaltung.leistungsangebote.path
            : PathBuilder.home.verwaltung.leistungsangebote.freigegeben.path;
    }, [isTraeger]);

    return <LeistungsangebotDeletionReason overviewPath={overviewPath} />;
};

export const FreigegebeneDeletionReasonPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_DELETION_REASON
);
