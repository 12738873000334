import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, Typography } from '@mui/material';

import { useAuthUser } from 'api/auth';
import { FormButton } from 'forms/components/FormButton';
import { PathBuilder } from 'navigation/Paths';

export const FailureMessage: React.FC = () => {
    const benutzer = useAuthUser();
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h1">Fehler</Typography>

            <Grid container>
                <Grid item xs={8}>
                    <Typography paragraph>Der von Ihnen genutzte Link ist fehlerhaft oder abgelaufen.</Typography>
                    <Typography paragraph>Um Ihre E-Mail-Adresse zu ändern fordern Sie einen neuen Link an.</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction={'row-reverse'}>
                <Grid item>
                    <FormControl margin="normal">
                        {benutzer ? (
                            <FormButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() => navigate(PathBuilder.home.profile.benutzerdaten.path)}
                            >
                                Zum Profil
                            </FormButton>
                        ) : (
                            <FormButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() => navigate(PathBuilder.home.path)}
                            >
                                Zur Startseite
                            </FormButton>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
