import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ValidationMode } from '@jsonforms/core';
import { isBoolean } from 'lodash';

import {
    EmailAntragApi,
    FemAntragApi,
    LeistungsanbieterAntragApi,
    MeldungAntragApi,
    RegelpruefungAntragApi,
} from 'api/einrichtungApiTypes';
import { useEinrichtungApi } from 'api/hooks/useEinrichtungApi';
import { PartialRecord } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ACTIONS } from 'constants/antragActions';
import { AntragType, MeldungArtType } from 'constants/antragTypes';
import { AntragForm } from 'forms/AntragForm';
import { useErrors } from 'forms/hooks/useErrors';
import { ButtonColors, GetWorkflowFormItem } from 'forms/hooks/useFormActions';
import { useFormHandling } from 'forms/hooks/useFormHandling';
import { useStepUISchema } from 'forms/hooks/useStepUISchema';
import { useErrorMessages } from 'forms/state/useErrorMessages';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, FormResponse } from 'forms/types/UiSchemaTypes';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { TabConfig } from 'layout/menu/TabNavigation';
import { TabComponentProps } from 'navigation/hooks/useTabsConfig';
import { PathBuilderProps } from 'navigation/Paths';
import { UseRoleText } from 'utilities/hooks/textConfigs/textTypes';

import { MeldungInfoHeader } from './Meldung/MeldungInfoHeader';

export interface AntragPageMessages {
    messages?: PartialRecord<ACTIONS, UseRoleText>;
}

export interface FormActionButtonOverrides {
    label?: string;
    color?: ButtonColors;
}

interface AntragPagePropsBase extends AntragPageMessages {
    antragType: AntragType;
    pathBuilder: PathBuilderProps;
    tabConfig: TabConfig<TabComponentProps>[];
    workflowFormItem: GetWorkflowFormItem;
    buttonOverrides?: PartialRecord<ACTIONS, FormActionButtonOverrides>;
    nextTabLabel?: boolean;
    stepUiSchemaDir?: string;
    customStep?: string;
    headline?: string | boolean;
    title?: string;
    disableValidationOnSave?: boolean;
    validationMode?: ValidationMode;
    layout?: {
        xs?: number;
        lg?: number;
        paddingTop?: number;
    };
    showMeldungInfoHeader?: boolean;
    withServerErrors?: boolean;
}

export type AntragBase = FormResponse;

type RegelpruefungAntragAntragProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    api: RegelpruefungAntragApi<AntragResponse>;
};

type LeistungsanbieterAntragProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    api: LeistungsanbieterAntragApi<AntragResponse>;
};

type MeldungAntragAntragProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    api: MeldungAntragApi<AntragResponse>;
};

type EmailAntragProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    api: EmailAntragApi<AntragResponse>;
};

type FemAntragProps<AntragResponse extends AntragBase> = AntragPagePropsBase & {
    api: FemAntragApi<AntragResponse>;
};

type AntragPageProps<AntragResponse extends AntragBase> =
    | RegelpruefungAntragAntragProps<AntragResponse>
    | LeistungsanbieterAntragProps<AntragResponse>
    | EmailAntragProps<AntragResponse>
    | MeldungAntragAntragProps<AntragResponse>
    | FemAntragProps<AntragResponse>;

export const AntragPage = <Antrag extends AntragBase>({
    antragType,
    customStep,
    stepUiSchemaDir,
    title,
    headline,
    api,
    pathBuilder,
    tabConfig,
    workflowFormItem,
    buttonOverrides,
    nextTabLabel,
    disableValidationOnSave,
    validationMode,
    layout = {
        paddingTop: undefined,
        xs: 12,
        lg: 10,
    },
    messages,
    showMeldungInfoHeader = false,
    withServerErrors = true,
}: AntragPageProps<Antrag>) => {
    const { einrichtungId, id, antragId, token, step } = useParams();

    const { formSubmitted } = useFormState();

    const basePath = useMemo(() => {
        if (pathBuilder.antrag) {
            return (
                pathBuilder.antrag?.({ einrichtungId: Number(einrichtungId || id), antragId: Number(antragId) }) || ''
            );
        }

        return pathBuilder.edit?.({ id: Number(antragId ?? (einrichtungId || id)) }) || '';
    }, [pathBuilder, einrichtungId, id, antragId]);

    const currentStep = useMemo(() => customStep ?? step, [customStep, step]);

    const antragAPI = useEinrichtungApi(api);
    const {
        formErrors,
        data,
        schema,
        steps,
        requestValidate,
        requestPersist,
        loadingError,
        isLoading,
        isDirty,
        submit,
        onChange,
        validate,
        reloadData,
        deleteObjectTypeItem,
        setAntragId,
        setAntragToken,
        setEinrichtungId,
    } = useFormHandling(antragAPI);

    const { uiSchema, isLoadingSchema } = useStepUISchema(schema, antragType, currentStep, stepUiSchemaDir);

    const compareData = useMemo(
        () => antragType === AntragType.MELDUNG && data?.meldungInfo?.meldungArt === MeldungArtType.AENDERUNGSMELDUNG,
        [antragType, data]
    );

    const config: FormConfig = useMemo(() => {
        return {
            validate,
            reloadData,
            requestPersist,
            requestValidate,
            deleteObjectTypeItem,
            submit,
            errors: formSubmitted && ![undefined, false].includes(withServerErrors) ? formErrors : undefined,
            loadFormData: antragAPI.getFormDataAPI,
            submitFormData: antragAPI.submitAPI,
            compareData,
        };
    }, [
        validate,
        reloadData,
        requestPersist,
        requestValidate,
        deleteObjectTypeItem,
        submit,
        formSubmitted,
        withServerErrors,
        formErrors,
        antragAPI.getFormDataAPI,
        antragAPI.submitAPI,
        compareData,
    ]);

    const { errors } = useErrors({
        config,
        path: 'data',
    });

    const pageTitle = useMemo(() => {
        if (false === headline) {
            return null;
        }

        return !isBoolean(headline) ? headline || schema?.title : undefined;
    }, [schema, headline]);

    useErrorMessages(errors, loadingError);

    useEffect(() => {
        setEinrichtungId(Number(einrichtungId || id));
        setAntragId(Number(antragId));
        setAntragToken(token);
    }, [einrichtungId, id, antragId, token, setEinrichtungId, setAntragId, setAntragToken]);

    const backLinkPath = useMemo(() => {
        if (pathBuilder.overview) {
            return pathBuilder.overview({ einrichtungId: Number(einrichtungId) });
        }

        return pathBuilder.view?.({ id: Number(antragId || einrichtungId) });
    }, [antragId, einrichtungId, pathBuilder]);

    return (
        <ContentContainer title={title || pageTitle || ''} sx={{ paddingTop: layout?.paddingTop }}>
            <StyledContainer>
                {pageTitle ? <PageHeading title={pageTitle} /> : null}

                <MeldungInfoHeader
                    isMeldungAntrag={showMeldungInfoHeader && antragType === AntragType.MELDUNG}
                    data={data}
                />

                {loadingError ? (
                    <ButtonGroupGrid backLink={{ path: String(backLinkPath) }} />
                ) : (
                    <AppLoaderContainer isLoading={(isLoading && !uiSchema) || (!data && !schema)}>
                        <AntragForm
                            basePath={basePath}
                            backLinkPath={String(backLinkPath)}
                            config={config}
                            isDirty={isDirty}
                            buttonOverrides={buttonOverrides}
                            nextTabLabel={nextTabLabel}
                            isLoadingSchema={isLoadingSchema}
                            pathBuilder={pathBuilder}
                            tabConfig={tabConfig}
                            workflowFormItem={workflowFormItem}
                            schema={schema}
                            onChange={onChange}
                            antragType={antragType}
                            uiSchema={uiSchema}
                            data={data}
                            errors={formErrors}
                            step={currentStep}
                            steps={steps}
                            initialValidationMode={validationMode}
                            layout={layout}
                            messages={messages}
                            disableValidationOnSave={disableValidationOnSave}
                            withServerErrors={withServerErrors}
                        />
                    </AppLoaderContainer>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};
