import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Box, FormControl, FormHelperText, styled, TextField } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { RowControl } from 'forms/controls/RowControl';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { transientOptions } from 'theme/utils';

import { useEmailValidation } from './hooks/useEmailValidation';

const EmailChangeComponent: React.FC<CustomControlProps<string>> = ({
    path,
    handleChange,
    data,
    required,
    label,
    uischema,
    schema,
    hasErrors,
    errors,
}) => {
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const { config } = useJsonFormsState();

    const requireConfirm = useMemo(
        () => (schema.custom?.requireConfirm || uischema?.requireEmailConfirm) ?? true,
        [schema, uischema]
    );

    const [valid, match, validationError, matchingError] = useEmailValidation(email, confirmEmail, requireConfirm);

    useEffect(() => {
        if (valid && match && data !== email) {
            handleChange(path, email, hasErrors);
        }
    }, [valid, match, email, handleChange, data, path, hasErrors]);

    useEffect(() => {
        if (data?.length) {
            setEmail(data);
        }
    }, [data]);

    const labelVisuallyHidden = useMemo(() => uischema.options?.labelVisuallyHidden ?? true, [uischema]);

    const errorList = useMemo(() => {
        if (validationError) {
            return [
                {
                    path: '',
                    message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                },
            ];
        }

        if (hasErrors && !email) {
            return errors;
        }

        return [];
    }, [validationError, hasErrors, errors, email]);

    return (
        <>
            <RowControl
                name={path}
                label={label}
                required={required}
                gridLayout={uischema.gridLayout || config.gridLayout}
                labelVisuallyHidden={labelVisuallyHidden}
            >
                <FormControl fullWidth>
                    <TextFieldWrapper $withBottomSpacing={requireConfirm}>
                        <TextField
                            fullWidth
                            required={required}
                            value={email}
                            error={validationError || hasErrors}
                            onChange={(event) => setEmail(event.target.value)}
                            data-cy={'EmailChangeControl-email'}
                            autoFocus={uischema.options?.focus}
                            placeholder={labelVisuallyHidden ? '' : label}
                            label={labelVisuallyHidden ? label : ''}
                        />
                        {validationError || hasErrors ? (
                            <FormHelperText component="div" style={{ marginBottom: 0 }}>
                                <ErrorList errors={errorList} />
                            </FormHelperText>
                        ) : null}
                    </TextFieldWrapper>
                </FormControl>
            </RowControl>

            {requireConfirm && (
                <RowControl
                    name={path}
                    label={`${label} wiederholen`}
                    required={required}
                    gridLayout={uischema.gridLayout || config.gridLayout}
                    labelVisuallyHidden={labelVisuallyHidden}
                >
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            required={required}
                            value={confirmEmail}
                            error={matchingError}
                            disabled={!email}
                            data-cy={'EmailChangeControl-confirmEmail'}
                            onChange={(event) => setConfirmEmail(event.target.value)}
                            helperText={
                                matchingError ? (
                                    <ErrorList
                                        errors={[
                                            {
                                                path: '',
                                                message: 'Die eingegeben E-Mail-Adressen stimmen nicht überein.',
                                            },
                                        ]}
                                    />
                                ) : null
                            }
                            placeholder={labelVisuallyHidden ? '' : label}
                            label={labelVisuallyHidden ? `${label} wiederholen` : ''}
                        />
                    </FormControl>
                </RowControl>
            )}
        </>
    );
};

const TextFieldWrapper = styled(
    Box,
    transientOptions
)<{ $withBottomSpacing?: boolean }>(({ theme, $withBottomSpacing }) => ({
    '& .MuiFormHelperText-root': {
        marginBottom: $withBottomSpacing ? theme.spacing(4) : 0,
    },
}));

export const EmailChangeControl = withCustomControlProps(EmailChangeComponent);
