import { useCallback, useEffect, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { TableFilterListResponse } from 'api/types';
import { BenutzerOptions } from 'components/DataTable/filters/ZustaendigerBenutzerFilterOptions';
import { AntragType } from 'constants/antragTypes';

type TableFilterOption = Record<string, string>;

export interface AntragTableFilterOptions {
    behoerden: TableFilterOption;
    leistungsart: TableFilterOption;
    antragJahr: TableFilterOption;
    antragTypeLabels: TableFilterOption;
    zustaendigerBenutzer: BenutzerOptions;
}

export const useAntragTableFilterOptions = (antragType: AntragType): AntragTableFilterOptions | undefined => {
    const [filters, setFilters] = useState<AntragTableFilterOptions>();

    const loadFilters = useCallback(async () => {
        try {
            const response: TableFilterListResponse = await backendApiService.getTableFilter({ antragType });

            const filterList = response['hydra:member'].reduce(
                (aggregate, current) => ({ ...aggregate, [current.field as string]: current.values }),
                { behoerden: {}, leistungsart: {}, antragJahr: {}, antragTypeLabels: {}, zustaendigerBenutzer: {} }
            );

            setFilters(filterList);
        } catch (error) {
            console.error(error);
        }
    }, [antragType]);

    useEffect(() => {
        loadFilters();
    }, [loadFilters]);

    return filters;
};
