import React from 'react';
import { CardActions, CardContent, CardHeader, styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { Link } from 'elements/Link';
import { StyledCard } from 'layout/components/StyledCard';

export interface DashboardCardAction {
    href: string;
    title: string;
}

export interface DashboardCardProps {
    children?: React.ReactNode | JSX.Element;
    isLoading?: boolean;
    headerAvatar?: React.ReactNode;
    headerTitle?: string | React.ReactNode;
    cardActions?: Array<DashboardCardAction>;
    sx?: SxProps;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
    children,
    isLoading = false,
    headerAvatar,
    headerTitle,
    cardActions,
    sx,
}) => {
    return (
        <StyledCard elevation={3} sx={sx}>
            {headerAvatar || headerTitle ? (
                <CardHeader avatar={headerAvatar} title={headerTitle} data-cy-id="DashboardCardHeader" />
            ) : null}

            <CardContent>
                <AppLoaderContainer isLoading={isLoading}>{children}</AppLoaderContainer>
            </CardContent>

            {cardActions?.length ? (
                <StyledCardActions data-cy-id="DashboardCardActions">
                    {cardActions.map((action, index) => (
                        <StyledLink key={index} to={action.href} underline="hover">
                            {action.title}
                        </StyledLink>
                    ))}
                </StyledCardActions>
            ) : null}
        </StyledCard>
    );
};

export const StyledLink = styled(Link)(({ theme }) => ({
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:visited': {
        color: theme.palette.primary.main,
    },
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
}));
