import { modalKonzeptinformationen } from 'forms/Meldung/modalElements';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';

export const uiSchema = customVerticalLayout([
    group(
        [
            listControlWithModalForm(
                ['#/properties/konzeptinformationen'],
                [
                    tableColumn('Name', { scopes: ['#/properties/customName', '#/properties/name'] }),
                    tableColumn('Plätze', { scope: '#/properties/plaetze' }),
                ],
                ['Schwerpunkt hinzufügen'],
                ['Schwerpunkt löschen'],
                ['Wollen Sie diesen Schwerpunkt mit allen Angaben wirklich löschen?'],
                [modalKonzeptinformationen],
                {
                    types: ['Schwerpunkt'],
                    field: 'konzeptinformationen',
                    labelScope: '#/properties/konzeptinformationen',
                }
            ),
        ],
        {
            noSeparator: true,
        }
    ),
    group(
        [control('stepKonzeptinformationenLeistungsbeschreibungen')],
        {},
        undefined,
        undefined,
        undefined,
        '#/properties/stepKonzeptinformationenLeistungsbeschreibungen'
    ),
    group(
        [control('stepKonzeptinformationenHausordnungen')],
        {},
        undefined,
        undefined,
        undefined,
        '#/properties/stepKonzeptinformationenHausordnungen'
    ),
    group(
        [control('stepKonzeptinformationenKonzeptinformationen')],
        {},
        undefined,
        undefined,
        undefined,
        '#/properties/stepKonzeptinformationenKonzeptinformationen'
    ),
    group(
        [control('stepKonzeptinformationenFreiheitentziehende')],
        {},
        undefined,
        undefined,
        undefined,
        '#/properties/stepKonzeptinformationenFreiheitentziehende'
    ),
    group(
        [control('stepKonzeptinformationenQualitaetBeschwerden')],
        {},
        undefined,
        undefined,
        undefined,
        '#/properties/stepKonzeptinformationenQualitaetBeschwerden'
    ),
    group(
        [control('stepKonzeptinformationenGewaltpraeventionen')],
        {},
        undefined,
        undefined,
        undefined,
        '#/properties/stepKonzeptinformationenGewaltpraeventionen'
    ),
    control('notizKonzeptinformation'),
]);
