import React from 'react';

import { useAbwListActions } from 'components/DataTable/actions/useAbwListActions';
import { useAbwAufgabenColumns } from 'components/DataTable/columns/abw/useAbwAufgabenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AbwInfo } from 'pages/Verwaltung/ABW/AbwInfo';
import { useAbwListData } from 'pages/Verwaltung/ABW/useAbwListData';

const Index = () => {
    const pathBuilder = PathBuilder.home.aufgaben.abw;
    const { serverDataChanged, handleAction } = useAbwListActions({ pathBuilder });

    const columns = useAbwAufgabenColumns(handleAction);

    const getData = useAbwListData(false);

    return (
        <ContentContainer title="Angezeigte Betreuung in Wohngemeinschaften zuordnen">
            <StyledContainer>
                <PageHeading title="Angezeigte Betreuung in Wohngemeinschaften zuordnen" />

                <AbwInfo />

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    serverDataChanged={serverDataChanged}
                    persistSearchText
                    initialOptions={{
                        sortOrder: {
                            name: 'name',
                            direction: 'asc',
                        },
                        custom: {
                            pathBuilder,
                        },
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const AbwAufgabenOverview = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN
);
