import React from 'react';
import { Alert, Typography } from '@mui/material';

import { ROLE_TYPES } from 'constants/roles';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungHinweisDokumentTable } from './components/MeldungHinweisDokumentTable';
import { MeldungHinweisTextTable } from './components/MeldungHinweisTextTable';

const Index = () => {
    return (
        <ContentContainer title="Anforderungstexte und Dokumente für die Meldung">
            <StyledContainer>
                <BaseGridContainer>
                    <Typography variant="h1">Anforderungstexte und Dokumente für die Meldung</Typography>

                    <Alert severity="info">
                        Die hinzugefügten Anforderungstexte und Dokumente werden dem Leistungsanbieter im Reiter
                        "Hinweise" einer Meldung angezeigt, wenn die Art des Leistungsangebots mit der Meldung
                        übereinstimmt. Gibt es keine Beschränkungen werden die Informationen in allen Meldungen
                        dargestellt.
                    </Alert>

                    <MeldungHinweisTextTable />

                    <MeldungHinweisDokumentTable />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MeldungHinweiseOverviewPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT
);
