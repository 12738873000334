import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { MeldungHinweistextListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useMeldungHinweistextListData = (): GetData<MeldungHinweistextListResponse> => {
    return useCallback(({ page, rowsPerPage }) => {
        return backendApiService.getMeldungHinweistextCollection({
            page,
            itemsPerPage: rowsPerPage,
        });
    }, []);
};
