import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { TraegerListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useTraegerListData = (): GetData<TraegerListResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) =>
            backendApiService.getTraegerCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderNrwKey: sortBy(sortOrder, 'nrwKey'),
                orderModifiedAt: sortBy(sortOrder, 'lastModifiedAt'),
                existsDeleted: false,
            }),
        []
    );
};
