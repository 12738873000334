import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import { Button, FormControl, FormHelperText, LinearProgress, styled, TextField } from '@mui/material';
import { v4 } from 'uuid';

import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ComponentError } from 'forms/hooks/useErrors';
import { useFocus } from 'forms/hooks/useFocus';
import { KEY, useKeyDown } from 'forms/hooks/useKeyDown';

import { ErrorList } from './ErrorList';

interface FileUploadButtonProps {
    uploadErrors: string[];
    errors: ComponentError[];
    disabled?: boolean;
    required?: boolean;
    hasErrors?: boolean;
    onBlur?: () => void;
    multiple?: boolean;
    isLoading?: boolean;
    isUploadingFile?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
    uploadErrors,
    errors,
    disabled = false,
    required,
    hasErrors = false,
    multiple = false,
    onBlur,
    onChange,
    isLoading,
    isUploadingFile,
    autoFocus,
}) => {
    const fieldId = useMemo(() => `file-multi-upload-${v4()}`, []);
    const requestFocus = useRef<boolean>();

    const { ref, requestFocusAfterValidate } = useFocus<any>();
    const onKeyDown = useKeyDown(
        useCallback(() => document.getElementById(fieldId)?.click(), [fieldId]),
        [KEY.SPACE, KEY.ENTER]
    );

    useEffect(() => {
        if (isUploadingFile) {
            requestFocus.current = true;
        }
        if (!isUploadingFile && requestFocus.current) {
            requestFocusAfterValidate();
            requestFocus.current = false;
        }
        if (autoFocus) {
            ref.current?.focus();
        }
    }, [isUploadingFile, requestFocusAfterValidate, autoFocus, ref]);

    return isLoading || isUploadingFile ? (
        <LinearProgress />
    ) : (
        <FormControl fullWidth error={hasErrors || uploadErrors.length > 0}>
            <FileButton
                disabled={disabled}
                variant="outlined"
                ref={ref}
                component="label"
                htmlFor={fieldId}
                onKeyDown={onKeyDown}
                endIcon={<PublishIcon />}
            >
                <i>Datei(en) hochladen {required && <RequiredFlag />}</i>
            </FileButton>
            <FileTextField
                error={hasErrors || uploadErrors.length > 0}
                disabled={disabled}
                id={fieldId}
                fullWidth
                type="file"
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{ multiple, tabIndex: -1, required }}
            />

            {uploadErrors.length > 0 && (
                <FormHelperText component="div">
                    <ErrorList
                        errors={uploadErrors.map((e) => ({
                            path: '',
                            message: e,
                        }))}
                    />
                </FormHelperText>
            )}

            {hasErrors && (
                <FormHelperText component="div">
                    <ErrorList errors={errors} />
                </FormHelperText>
            )}
        </FormControl>
    );
};

const FileButton = styled(Button)<{
    component?: React.ElementType;
    htmlFor?: string;
}>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    minHeight: 40,
    backgroundColor: theme.palette.background.inputControls,
    paddingRight: theme.spacing(3),
    '&:focus': { background: theme.palette.primary.lighter },
}));

const FileTextField = styled(TextField)(() => ({
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
}));
