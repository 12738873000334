import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';

import { MeldungProps } from './MeldungDetailPage';

export const Leistungserbringer = ({ meldung }: MeldungProps) => {
    return (
        <>
            {meldung.data?.stepLeistungserbringerLeistungserbringer ? (
                <DetailsLayout.Group title="Weitere Leistungserbringer" variant="h2">
                    <DetailsLayout.SimpleTable
                        columns={[
                            {
                                label: 'Name',
                                name: 'name',
                            },
                            {
                                label: 'Adresse',
                                name: 'adresse/properties/label',
                                dotNotation: true,
                            },
                            {
                                label: 'Ansprechpartner',
                                name: 'ansprechpartner',
                            },
                            {
                                label: 'Telefon',
                                name: 'telefon',
                            },
                            {
                                label: 'Fax',
                                name: 'fax',
                            },
                            {
                                label: 'E-Mail-Adresse',
                                name: 'mail',
                            },
                        ]}
                        data={meldung.data.stepLeistungserbringerLeistungserbringer}
                        vertical
                    />
                </DetailsLayout.Group>
            ) : null}
        </>
    );
};
