import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useApiEinrichtungGet, useApiRegelpruefungItem } from 'api/hooks/useApiClient';
import { RegelpruefungAntragStandort } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { MAENGEL_ART_LABELS } from 'constants/labels';
import { MAENGEL_TYPES } from 'constants/MAENGEL_TYPES';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

export const getStandort = (einrichtungStandort?: RegelpruefungAntragStandort) => {
    if (einrichtungStandort && 'hauptstandort' in einrichtungStandort && einrichtungStandort?.adresse) {
        return `${einrichtungStandort.hauptstandort ? 'Hauptstandort' : 'Nebenstandort'} - ${
            einrichtungStandort.adresse.label
        }`;
    }

    if (einrichtungStandort?.hauptstandort) {
        return einrichtungStandort.hauptstandort ? 'Hauptstandort' : 'Nebenstandort';
    }

    return 'Hauptstandort';
};

const Index = () => {
    const { einrichtungId, antragId } = useParams();

    const { data: einrichtung, isLoading: isLoadingEinrichtung } = useApiEinrichtungGet(einrichtungId);
    const { data: antrag, isLoading } = useApiRegelpruefungItem(antragId);

    return (
        <ContentContainer title={`Regelprüfung: ${einrichtung?.name}`}>
            <StyledContainer>
                <Typography variant="h1">Regelprüfung: {einrichtung?.name}</Typography>

                <AppLoaderContainer isLoading={isLoading && isLoadingEinrichtung}>
                    <BaseGridContainer lg={8} justifyContent="center">
                        <DetailsLayout.Container divide={false}>
                            <DetailsLayout.InfoField
                                value={MAENGEL_ART_LABELS[antrag?.data?.ergebnis as MAENGEL_TYPES]}
                                label="Ergebnis der letzten Prüfung"
                            />

                            <DetailsLayout.InfoField
                                value={antrag?.data?.maengel}
                                label="Art der Mängel, Gegenstand der Anordnung (Stichworte)"
                            />
                            <DetailsLayout.DownloadLink
                                value={antrag?.data?.dokumente}
                                label="Prüfbericht(e)"
                                openLinkInWindow
                            />
                            <DetailsLayout.InfoField
                                value={antrag?.data?.nachpruefungen ? 'Ja' : 'Nein'}
                                label="Waren eine oder mehrere Nachprüfungen notwendig"
                            />

                            <DetailsLayout.InfoField
                                value={antrag?.data?.pruefDatum}
                                label="Datum der letzten Prüfung"
                                isDate
                            />
                            {(antrag?.data?.nachpruefungenList || []).map((item, index) => (
                                <DetailsLayout.InfoField
                                    key={item.uuid}
                                    value={item.datum}
                                    isDate
                                    label={`Datum der ${index + 1}. Nachprüfungen`}
                                />
                            ))}
                            <DetailsLayout.InfoField
                                label="Standort"
                                value={getStandort(antrag?.einrichtungStandort ?? undefined)}
                            />
                            <DetailsLayout.InfoField value={antrag?.data?.bemerkung} label="Sonstige Bemerkungen" />
                        </DetailsLayout.Container>
                    </BaseGridContainer>

                    <StyledDivider />

                    <ButtonGroupGrid
                        backLink={{
                            path: PathBuilder.home.verwaltung.regelpruefung.view({ id: Number(einrichtungId) }),
                        }}
                    />
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const RegelpruefungDetailPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_REGELPRUEFUNG
);
