import React from 'react';

import { useLeistungsangebotAntragListAction } from 'components/DataTable/actions/useLeistungsangebotAntragListAction';
import { useBehoerdeLeistungsangeboteColumns } from 'components/DataTable/columns/leistungsangebot/useBehoerdeLeistungsangeboteColumns';
import { STATUS } from 'constants/antragStatus';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder, useLeistungsangebotSegment } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangeboteOverviewTable } from 'pages/Leistungsangebote/LeistungsangeboteOverviewTable';
import { useLeistungsangebotListData } from 'pages/Leistungsangebote/useLeistungsangebotListData';

const Index = () => {
    const segment = useLeistungsangebotSegment();

    const { serverDataChanged, handleAction } = useLeistungsangebotAntragListAction(segment);

    const columns = useBehoerdeLeistungsangeboteColumns(handleAction);

    const getData = useLeistungsangebotListData({ status: STATUS.EINGEREICHT });

    return (
        <ContentContainer title="Übersicht - Leistungsangebote freigeben">
            <StyledContainer>
                <LeistungsangeboteOverviewTable
                    getData={getData}
                    columns={columns}
                    serverDataChanged={serverDataChanged}
                    pathBuilder={PathBuilder.home.aufgaben.freigeben.leistungsangebote}
                    segment={segment}
                    headline="Leistungsangebote freigeben"
                    initialOptions={{
                        sortOrder: {
                            name: 'zuletztEingereichtAt',
                            direction: 'desc',
                        },
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const LeistungsangeboteBehoerdeOverview = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEBEN
);
