import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, CardActions, CardContent, CardHeader } from '@mui/material';

import { useMeldungHinweisTextListActions } from 'components/DataTable/actions/useMeldungHinweisTextListActions';
import { useMeldungHinweistextColumns } from 'components/DataTable/columns/useMeldungHinweistextColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { Link } from 'elements/Link';
import { StyledCard } from 'layout/components/StyledCard';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';
import { useMeldungHinweistextListData } from 'pages/Verwaltung/MeldungHinweistexte/useMeldungHinweistextListData';

export const MeldungHinweisTextTable = () => {
    const pathBuilder = PathBuilder.home.verwaltung.meldungHinweise;

    const { serverDataChanged, handleAction } = useMeldungHinweisTextListActions(pathBuilder);

    const columns = useMeldungHinweistextColumns(handleAction);
    const getData = useMeldungHinweistextListData();

    return (
        <StyledContainer>
            <StyledCard elevation={2} padding={3}>
                <CardHeader title="Anforderungstexte" />

                <CardContent>
                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                        initialOptions={{
                            custom: {
                                pathBuilder,
                            },
                        }}
                    />
                </CardContent>

                <CardActions sx={{ paddingLeft: 0 }}>
                    <Button
                        component={Link}
                        to={pathBuilder.create}
                        color="primary"
                        variant="contained"
                        endIcon={<AddIcon />}
                    >
                        Anforderungstext hinzufügen
                    </Button>
                </CardActions>
            </StyledCard>
        </StyledContainer>
    );
};
