import { RuleEffect } from '@jsonforms/core';

import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';

export const uiSchema = customVerticalLayout([
    group([
        control('emailtyp'),
        control('inhalt', {
            options: { rows: 20 },
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/emailtyp',
                    schema: { type: 'string' },
                },
            },
        }),
    ]),
]);
