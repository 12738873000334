import { RuleEffect } from '@jsonforms/core';

import { control } from 'forms/ui-schemas/elements/control';
import { customHorizontalLayout } from 'forms/ui-schemas/elements/customHorizontalLayout';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { formExtendButton } from 'forms/ui-schemas/elements/formExtendButton';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group([control('standort')]),
    group([inlineRowControl([control('ergebnis'), control('pruefDatum')]), control('maengel')]),
    group([
        info({
            message:
                'Hier können Sie Ihren Prüfbericht hochladen, um Ihre Pflicht zur Übersendung der Prüfberichte nach § 23 Absatz 3 WTG zu erfüllen.',
        }),
        control('dokumente'),
    ]),
    group([
        control('nachpruefungen', { options: { validateOnChange: true } }),
        customHorizontalLayout(
            [
                group(
                    [
                        formExtendButton(
                            customHorizontalLayout([control('datum')], { gridSize: 12 }),
                            '#/properties/nachpruefungenList',
                            'Weitere Nachprüfung hinzufügen'
                        ),
                    ],
                    {
                        noSeparator: true,
                        rule: {
                            effect: RuleEffect.SHOW,
                            condition: {
                                scope: '#properties/nachpruefungen',
                                schema: { const: true },
                            },
                        },
                    }
                ),
            ],
            { gridSize: 6 }
        ),
    ]),
    group([control('bemerkung')]),
]);
