import React from 'react';
import { LayoutProps, RankedTester, rankWith, UISchemaElement } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Box, styled, Typography } from '@mui/material';

import { renderers } from 'forms/renderers';
import { NestedGroupControlType, Schema } from 'forms/types/UiSchemaTypes';

import { NestedField, useNestedFields } from './hooks/useNestedFields';

interface NestedGroupProps extends Omit<LayoutProps, 'uischema'> {
    uischema: NestedGroupControlType;
}

const NestedGroupControlComponent: React.FC<NestedGroupProps> = ({ schema, uischema, data }: NestedGroupProps) => {
    const { nestedTitle, nestedFields } = useNestedFields(data, uischema, schema as Schema);

    return (
        <>
            {nestedFields.length ? (
                <StyledNestedGroup>
                    {nestedTitle && uischema?.options?.hideTitle !== true ? (
                        <Typography variant="h2">{nestedTitle}</Typography>
                    ) : null}

                    {nestedFields.map((field: NestedField, index: number) => (
                        <React.Fragment key={index}>
                            {field.title && uischema?.options?.hideParentLabel !== true ? (
                                <Typography variant="h3">{field.title}</Typography>
                            ) : null}

                            <JsonFormsDispatch
                                path={field.path}
                                uischema={uischema.uischemas[index]}
                                schema={field.schema}
                                renderers={renderers}
                            />
                        </React.Fragment>
                    ))}
                </StyledNestedGroup>
            ) : null}
        </>
    );
};

export const StyledNestedGroup = styled(Box)(
    ({ theme }) => `
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: ${theme.spacing(1.5)};
  margin-bottom: ${theme.spacing(4)};

  & h2 {
      margin: ${theme.spacing(2)} 0 ${theme.spacing(4)}  0;
  }

  & h3 {
      margin: 0 0 ${theme.spacing(2)}  0;
      font-weight: bold;
  }
`
);

// @ts-ignore
export const NestedGroupControl = withJsonFormsLayoutProps(NestedGroupControlComponent);

const isNestedGroupControl = (uischema: UISchemaElement | any) => uischema.type === 'NestedGroupControl';

export const NestedGroupControlTester: RankedTester = rankWith(1, isNestedGroupControl);
