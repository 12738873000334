import * as React from 'react';
import { useCallback } from 'react';
import { FormHelperText } from '@mui/material';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { TraegerListResponse, TraegerResponse } from 'api/types';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { theme } from 'theme/theme';

import { ErrorList } from './components/ErrorList';
import { ReadonlyValueText } from './components/ReadonlyValueText';
import { RowControl } from './RowControl';

const TraegerAutocompleteComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
    readonly,
    hasErrors,
    errors,
}) => {
    const handleValueChange = useCallback(
        (id: SelectOption | null) => {
            handleChange(path, id, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, options: AxiosRequestConfig): Promise<TraegerListResponse> => {
        return backendApiService.getTraegerCollection({
            page: 1,
            itemsPerPage: 10,
            options: {
                params: {
                    qSearch: value,
                },
                signal: options.signal,
            },
        });
    };

    const getOption = (item: TraegerResponse): SelectOption => {
        return {
            '@id': item?.['@id'],
            id: item?.id as number,
            name: item?.name,
        };
    };

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            {readonly ? (
                <ReadonlyValueText hasErrors={hasErrors} errors={errors} label={label} text={data?.name ?? '-'} />
            ) : (
                <Typeahead
                    id={id}
                    data={data}
                    schema={schema}
                    uischema={uischema}
                    path={path}
                    multiple={false}
                    getData={getData}
                    getOption={getOption}
                    onChange={handleValueChange}
                    required={required}
                    placeholder="Suchen"
                />
            )}

            {!uischema?.withInfoIcon && schema.description ? (
                <FormHelperText sx={{ paddingX: theme.spacing(2) }}>{schema.description}</FormHelperText>
            ) : null}

            {hasErrors && (
                <FormHelperText component="div">
                    <ErrorList errors={errors} />
                </FormHelperText>
            )}
        </RowControl>
    );
};

export const TraegerAutocompleteControl = withCustomControlProps(TraegerAutocompleteComponent);
