import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Button, Grid, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { isEmpty } from 'lodash';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { Link } from 'elements/Link';
import { FormLoadingButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { useFormValidation } from 'forms/hooks/useFormValidation';
import { renderers } from 'forms/renderers';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, FormStateChange, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { deepEqual } from 'utilities';

import { useFormLeaveWarning } from './hooks/useFormLeaveWarning';
import { useFormValidationMode } from './hooks/useFormValidationMode';
import { useServerError } from './hooks/useServerError';

export type SimpleFormConfig = Omit<FormConfig, 'validate' | 'requestValidate' | 'requestPersist'>;

interface SimpleFormProps {
    data: any;
    schema: Schema | undefined;
    submitLabel: string;
    submitDisabled?: boolean;
    onSubmit: (...args: any) => Promise<any> | void;
    formConfig?: SimpleFormConfig;
    onAfterSubmit?: () => void;
    onChange?: (...args: any) => void;
    itemId?: string | number;
    uiSchema?: UiSchemaType;
    isLoading?: boolean;
    messages?: {
        success: string;
        error: string;
    };
    additionalActions?: ReactNode;
    buttonStyles?: SxProps<Theme>;
    hideTitle?: boolean;
    backLink?: {
        path: string;
        title?: string;
    };
    ignoreLeaveWarning?: boolean;
    setResponseFormData?: boolean;
}

export const isDataEqual = (initialData: any, updatedFormData: any, ignoreUndefinedFields = true) => {
    return deepEqual(initialData, updatedFormData, { ignoreUndefinedFields });
};

export const SimpleForm = ({
    messages = {
        success: 'Daten wurden gespeichert.',
        error: 'Es ist ein Fehler aufgetreten.',
    },
    isLoading,
    data,
    formConfig = {},
    onChange,
    schema,
    uiSchema,
    submitLabel,
    submitDisabled = false,
    itemId,
    onSubmit,
    onAfterSubmit,
    additionalActions,
    buttonStyles,
    hideTitle,
    backLink,
    ignoreLeaveWarning = false,
    setResponseFormData = false,
}: SimpleFormProps) => {
    const theme = useTheme();

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [initialFormData, setInitialFormData] = useState<any>(undefined);
    const [formData, setFormData] = useState<any>(undefined);

    const { submitStart, submitEnd, isSubmitting, isPersisting, setRequestMessage, setFormSubmitted, formSubmitted } =
        useFormState();
    const collectionError = useServerError();

    const { validationMode, switchValidation } = useFormValidationMode();

    const { isDirty } = useFormLeaveWarning(initialFormData, formData, isPersisting);

    const config: SimpleFormConfig = useMemo(
        () => ({ ...formConfig, formTouched: formSubmitted }),
        [formConfig, formSubmitted]
    );

    const { valid } = useFormValidation(schema, formData, config, uiSchema);

    const determineDataByState = (state: FormStateChange) => (!isEmpty(state.data) ? state.data : initialFormData);

    const handleSubmit = () => {
        setIsSubmitted(true);

        if (!valid) {
            setRequestMessage(errorMessage('Bitte prüfen Sie Ihre Eingaben.'));
            switchValidation(false);
            setFormSubmitted(true);
            return;
        }

        if (!Object.keys(formData).length) {
            return setRequestMessage(errorMessage('Bitte prüfen Sie Ihre Eingaben.'));
        }

        submitStart(true);

        onSubmit(formData, itemId)
            ?.then(() => {
                setInitialFormData((prevState: Record<string, any>) => ({ ...prevState, ...formData }));
                setRequestMessage(successMessage(messages.success));
            })
            .catch((error) => {
                setRequestMessage(errorMessage(collectionError(error, messages.error)));
            })
            .finally(() => submitEnd());
    };

    const handleOnChange = (state: FormStateChange) => {
        if (isLoading) {
            return;
        }

        if (isDataEqual(formData, state.data)) {
            return setIsSubmitted(false);
        }

        setFormData(determineDataByState(state));

        if (ignoreLeaveWarning) {
            setInitialFormData(determineDataByState(state));
        }

        if (onChange && formData && !isSubmitted) {
            onChange?.(state.data);
        }
    };

    useEffect(() => {
        if (isSubmitted && !isDirty && valid) {
            onAfterSubmit?.();
        }
    }, [isDirty, isSubmitted, onAfterSubmit, valid]);

    // Set initial data
    useEffect(() => {
        // ToDo: Refactor and debug setting of initial and response data

        if (isDataEqual(data, formData, false)) {
            return;
        }

        if (!isDataEqual(data, formData, false) && setResponseFormData) {
            setFormData(data);
            setInitialFormData(data);
        }

        if (!formData) {
            setFormData(data);
        }

        if (!initialFormData) {
            setInitialFormData(data);
        }

        return () => {
            setFormSubmitted(false);
        };
    }, [data, formData, initialFormData, setFormSubmitted, setResponseFormData]);

    return (
        <AppLoaderContainer isLoading={isLoading}>
            {formData?.name && (hideTitle === undefined || !hideTitle) ? <PageHeading title={formData?.name} /> : null}

            <Grid container spacing={3} justifyContent="flex-start">
                {formData && schema ? (
                    <Grid item xs={12} lg={10}>
                        <Pflichtfeld display={!!schema?.required?.length} />

                        <JsonForms
                            config={config}
                            data={formData}
                            schema={schema}
                            uischema={uiSchema}
                            onChange={handleOnChange}
                            renderers={renderers}
                            validationMode={validationMode}
                        />
                    </Grid>
                ) : null}

                <Grid
                    item
                    xs={12}
                    lg={10}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        gap: theme.spacing(3),
                        ...buttonStyles,
                    }}
                >
                    {additionalActions || null}

                    <FormLoadingButton
                        loading={isSubmitting}
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-cy="SimpleFormSubmitButton"
                        onClick={handleSubmit}
                        disabled={submitDisabled}
                    >
                        {submitLabel}
                    </FormLoadingButton>

                    {backLink ? (
                        <Button component={Link} to={backLink.path} variant="outlined">
                            {backLink?.title || 'Zur Übersicht'}
                        </Button>
                    ) : null}
                </Grid>
            </Grid>
        </AppLoaderContainer>
    );
};
