import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Grid } from '@mui/material';

import { useApiFemAntragItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { getDate } from 'components/DataTable/tableUtils';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { Link } from 'elements/Link';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const Index = () => {
    const { einrichtungId, antragId } = useParams();

    const { isLoading, data, error } = useApiFemAntragItem(antragId);

    const femAntragTitle = useMemo(() => `${data?.jahr}/${data?.quartal}`, [data]);

    return (
        <ContentContainer title="Freiheitsentziehende Maßnahmen - Antrag">
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading}>
                    {error ? (
                        <Grid container spacing={5} justifyContent="center">
                            <Grid item md={8}>
                                <Alert severity="error">Beim Laden des Antrag ist ein Fehler aufgetreten</Alert>
                            </Grid>
                        </Grid>
                    ) : null}

                    <PageHeading title={`FEM Meldung ${femAntragTitle}`} />

                    <Grid container spacing={5} justifyContent="center">
                        <Grid item md={8}>
                            {data ? (
                                <DetailsLayout.Container>
                                    <DetailsLayout.Group title="Details des Leistungsangebots" divider>
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.nrwKey}
                                            label="NRW-Schlüssel:"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.art}
                                            label="Art des Leistungsangebots:"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.name}
                                            label="Name des Leitstungsangebots"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.adresse?.label}
                                            label="Adresse:"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.adresse?.name}
                                            label="Ansprechpartner"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.adresse?.telefon}
                                            label="Telefon"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.adresse?.fax}
                                            label="Telefax"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.adresse?.email}
                                            label="E-Mail-Adresse"
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einrichtungDaten?.adresse?.homepage}
                                            label="Homepage"
                                        />
                                        <DetailsLayout.InfoField
                                            value={getDate(data?.einrichtungDaten?.datumInbetriebnahme ?? undefined)}
                                            label="Datum der Erstinbetriebnahme"
                                        />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group title="Angaben zur Meldung" divider>
                                        <DetailsLayout.InfoField
                                            value={getDate(data?.eingereichtAt ?? undefined)}
                                            label="Eingereicht am:"
                                        />
                                        <DetailsLayout.InfoField
                                            value={getDate(data?.zuletztEingereichtAt ?? undefined)}
                                            label="Zuletzt bearbeitet am:"
                                        />
                                        <DetailsLayout.InfoField value={data?.jahr} label="Jahr" />
                                        <DetailsLayout.InfoField value={data?.quartal} label="Quartal" />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group title="Gerichtliche Genehmigung nach § 8a Abs. 7 Nr. 1 WTG">
                                        <DetailsLayout.InfoField
                                            value={data?.gerichtlichGesamtanzahl}
                                            label="Gesamtanzahl gerichtliche Genehmigungen"
                                        />

                                        <DetailsLayout.InfoField
                                            value={data?.gerichtlichGesamtanzahlBetroffene}
                                            label="Gesamtanzahl betroffene Personen"
                                        />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group
                                        title="Maßnahmen"
                                        variant="h3"
                                        divider={(data.gerichtlichCustomMassnahmen || []).length > 0}
                                    >
                                        {data.gerichtlichMassnahmen?.length ? (
                                            <DetailsLayout.SimpleTable
                                                columns={[
                                                    { label: 'Maßnahme', name: 'displayName' },
                                                    {
                                                        label: 'Anzahl',
                                                        name: 'anzahlPersonen',
                                                        textAlign: 'right',
                                                    },
                                                    {
                                                        label: 'Anzahl Betroffene',
                                                        name: 'anzahlQuartal',
                                                        textAlign: 'right',
                                                    },
                                                ]}
                                                data={data.gerichtlichMassnahmen}
                                            />
                                        ) : (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Alert severity="info">Aktuell keine Maßnahmen erfasst.</Alert>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {data.gerichtlichCustomMassnahmen?.length ? (
                                            <DetailsLayout.SimpleTable
                                                columns={[
                                                    { label: 'Maßnahme', name: 'name' },
                                                    {
                                                        label: 'Anzahl',
                                                        name: 'anzahlPersonen',
                                                        textAlign: 'right',
                                                    },
                                                    {
                                                        label: 'Anzahl Betroffene',
                                                        name: 'anzahlQuartal',
                                                        textAlign: 'right',
                                                    },
                                                ]}
                                                data={data.gerichtlichCustomMassnahmen}
                                            />
                                        ) : null}
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group title="Einwilligung zu freiheitsentziehenden Maßnahmen nach § 8a Abs. 7 Nr. 2 WTG ">
                                        <DetailsLayout.InfoField
                                            value={data?.einwilligungGesamtanzahl}
                                            label="Gesamtanzahl Einwilligungen "
                                            labelMd={3}
                                            valueMd={9}
                                        />
                                        <DetailsLayout.InfoField
                                            value={data?.einwilligungGesamtanzahlBetroffene}
                                            label="Gesamtanzahl betroffene Personen"
                                            labelMd={3}
                                            valueMd={9}
                                        />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group
                                        title="Maßnahmen"
                                        variant="h3"
                                        divider={(data.einwilligungCustomMassnahmen || []).length > 0}
                                    >
                                        {data.einwilligungMassnahmen?.length ? (
                                            <DetailsLayout.SimpleTable
                                                columns={[
                                                    { label: 'Maßnahme', name: 'displayName' },
                                                    {
                                                        label: 'Anzahl',
                                                        name: 'anzahlPersonen',
                                                        textAlign: 'right',
                                                    },
                                                    {
                                                        label: 'Anzahl Betroffene',
                                                        name: 'anzahlQuartal',
                                                        textAlign: 'right',
                                                    },
                                                ]}
                                                data={data.einwilligungMassnahmen}
                                            />
                                        ) : (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Alert severity="info">Aktuell keine Maßnahmen erfasst.</Alert>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {data.einwilligungCustomMassnahmen?.length ? (
                                            <DetailsLayout.SimpleTable
                                                columns={[
                                                    { label: 'Maßnahme', name: 'name' },
                                                    {
                                                        label: 'Anzahl',
                                                        name: 'anzahlPersonen',
                                                        textAlign: 'right',
                                                    },
                                                    {
                                                        label: 'Anzahl Betroffene',
                                                        name: 'anzahlQuartal',
                                                        textAlign: 'right',
                                                    },
                                                ]}
                                                data={data.einwilligungCustomMassnahmen}
                                            />
                                        ) : null}
                                    </DetailsLayout.Group>
                                </DetailsLayout.Container>
                            ) : null}
                        </Grid>

                        <Grid
                            item
                            xs={10}
                            md={8}
                            sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}
                        >
                            <Button
                                component={Link}
                                to={PathBuilder.home.verwaltung.femAntrag.view({
                                    id: Number(einrichtungId),
                                })}
                                variant="outlined"
                            >
                                Zur Übersicht
                            </Button>
                        </Grid>
                    </Grid>
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const FemAntragDetailPage = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_FEM_MELDER);
