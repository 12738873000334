import React, { ReactElement } from 'react';
import { Warning } from '@mui/icons-material';
import { styled, SvgIconProps, Tooltip } from '@mui/material';

type MissingRequiredFieldsIconCustomProps = {
    label?: string;
};

type MissingRequiredFieldsIconProps = MissingRequiredFieldsIconCustomProps & SvgIconProps;

export const MissingRequiredFieldsIcon = (props: MissingRequiredFieldsIconProps): ReactElement => {
    const defaultMessage = 'Bitte überprüfen Sie Ihre Eingaben.';
    const title = props.label
        ? `Für das Feld "${props.label}" sind nicht alle Pflichtangaben gesetzt.`
        : defaultMessage;
    const subtitle = props.onClick ? ' Klicken Sie hier, um zum Formularschritt zu gelangen.' : '';

    return (
        <Tooltip title={`${title}${subtitle}`}>
            <ErrorIcon {...props} />
        </Tooltip>
    );
};

const ErrorIcon = styled(Warning)(({ theme }) => ({
    color: theme.palette.error.main,
    verticalAlign: 'text-bottom',
}));
