import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { useAuthUser } from 'api/auth';
import { PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead } from 'api/client';
import { PathBuilder } from 'navigation/Paths';

interface UsePlatzzahlmelderApi {
    isLoading: boolean;
    benutzerData: PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead | null;
    currentToken: string | undefined;
    createToken: () => void;
}

export const usePlatzzahlmelderTokenApi = (token?: string): UsePlatzzahlmelderApi => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [benutzerData, setBenutzerData] = useState<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead | null>(null);
    const [currentToken, setCurrentToken] = useState<string | undefined>(token);

    const benutzer = useAuthUser();

    const navigate = useNavigate();

    const createToken = useCallback(() => {
        if (!benutzer?.benutzerId) return;

        setIsLoading(true);
        backendApiService
            .createPlatzzahlmelderToken(benutzer.benutzerId)
            .then((res) => {
                setCurrentToken(res.token);
            })
            .finally(() => setIsLoading(false));
    }, [benutzer, setCurrentToken]);

    useEffect(() => {
        if (!benutzer?.benutzerId && !currentToken) {
            return navigate(PathBuilder.home.path);
        }

        if (currentToken) {
            return navigate(PathBuilder.home.verwaltung.platzmelder.token(currentToken));
        }

        if (!benutzer?.benutzerId) {
            return;
        }

        setIsLoading(true);
        backendApiService
            .getPlatzzahlmelderTokenByBenutzerId(String(benutzer.benutzerId))
            .then((res) => {
                setCurrentToken(res?.token);
                setBenutzerData(res);
            })
            .catch(({ response }) => {
                if (404 === response.status) {
                    createToken();
                }
            })
            .finally(() => setIsLoading(false));
    }, [benutzer, createToken, currentToken, navigate]);

    return {
        isLoading,
        benutzerData,
        currentToken,
        createToken,
    };
};
