import React from 'react';
import { Grid, Typography } from '@mui/material';

import { PageHeading } from 'components/PageHeading';
import { LinkSupport } from 'elements/LinkSupport';

export const ExceptionMessage: React.FC = () => {
    return (
        <>
            <PageHeading title="Fehler" />

            <Grid container>
                <Grid item xs={8}>
                    <Typography paragraph>Der Account konnte nicht deaktiviert werden.</Typography>
                    <Typography paragraph>
                        Es liegt ein technisches Problem vor. Bitte wenden Sie sich an unseren <LinkSupport />.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};
