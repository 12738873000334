import { RankedTester, rankWith } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Box, BoxProps, Grid, styled } from '@mui/material';

const HighlightedWrapper = styled(Box)<BoxProps>(
    ({ theme }) => `
    background: ${theme.palette.background.surfaceHighlighted};
    margin-left: -${theme.spacing(2)};
    padding-left: ${theme.spacing(2)};
    padding-top: ${theme.spacing(3)};
    border: 1px solid rgba(0, 0, 0, 0.12);
`
);

export const HighlightedArea = withJsonFormsLayoutProps(({ uischema, ...others }) => {
    return (
        <HighlightedWrapper>
            <Grid container spacing={1}>
                {(uischema as any).elements?.map((element: any, i: number) => (
                    <Grid item xs={12} key={i}>
                        <JsonFormsDispatch {...others} uischema={element} />
                    </Grid>
                ))}
            </Grid>
        </HighlightedWrapper>
    );
});

export const HighlightedAreaTester: RankedTester = rankWith(1, (uischema) => uischema.type === 'HighlightedArea');
