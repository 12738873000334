import { RuleEffect } from '@jsonforms/core';

import { AnschriftType } from 'api/types';
import { Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { isPostfach } from 'forms/utilities/UiSchemaUtils';

export const schema: Schema = {
    type: 'object',
    required: [
        'name',
        'adressDaten',
        'anschriftTyp',
        'postfachBezeichnung',
        'postfachPlz',
        'postfachOrt',
        'ansprechpartner',
        'telefon',
        'email',
    ],
    properties: {
        name: {
            type: 'string',
            title: 'Name des Trägerverbands',
        },
        adressDaten: {
            type: 'object',
            title: 'Hausanschrift',
            // @ts-ignore
            custom: {
                resetField: 'label',
                block_prefixes: ['adresse_lookup_autocomplete'],
            },
        },
        ansprechpartner: {
            type: 'string',
            title: 'Ansprechpartner',
        },
        anschriftTyp: {
            type: 'string',
            title: 'Anschrift',
            oneOf: [
                {
                    const: AnschriftType.POSTFACH,
                    title: 'Postfach',
                },
                {
                    const: AnschriftType.HAUSANSCHRIFT,
                    title: 'Hausanschrift',
                },
            ],
            // @ts-ignore
            custom: {
                block_prefixes: ['radios', 'choice'],
            },
        },
        postfachBezeichnung: {
            type: 'string',
            title: 'Postfach',
        },
        postfachPlz: {
            type: 'string',
            title: 'PLZ',
        },
        postfachOrt: {
            type: 'string',
            title: 'Ort',
        },
        telefon: {
            type: 'string',
            title: 'Telefon',
        },
        fax: {
            type: 'string',
            title: 'Telefax',
        },
        email: {
            type: 'string',
            title: 'E-Mail-Adresse',
        },
    },
};

export const uiSchema = customVerticalLayout([
    control('name'),
    control('anschriftTyp'),
    group([
        control('postfachBezeichnung', isPostfach(RuleEffect.SHOW)),
        inlineRowControl([
            control('postfachPlz', isPostfach(RuleEffect.SHOW)),
            control('postfachOrt', isPostfach(RuleEffect.SHOW)),
        ]),
        control('adressDaten', {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/anschriftTyp',
                    schema: { const: AnschriftType.HAUSANSCHRIFT },
                },
            },
        }),
    ]),
    control('ansprechpartner'),
    inlineRowControl([control('telefon'), control('fax')]),
    control('email'),
]);
