import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { EinrichtungCollectionJsonldItem } from 'api/types';
import { EinrichtungTypes } from 'constants/einrichtungTypes';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { ComponentError } from 'forms/hooks/useErrors';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { ErrorList } from './components/ErrorList';
import { RowControl } from './RowControl';

const WohngemeinschaftAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    handleChange,
    hasErrors,
    errors,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
    enabled,
    config,
}) => {
    const [errorList, setErrorList] = useState<ComponentError[]>(errors);
    const handleValueChange = useCallback(
        (value: SelectOption | null) => {
            handleChange(path, value, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, options: AxiosRequestConfig): Promise<any> => {
        return backendApiService.getEinrichtungCollection({
            page: 1,
            itemsPerPage: 10,
            qSearch: value,
            adresseCx: config.adresseCx,
            adresseCy: config.adresseCy,
            ...(uischema.options?.requestFilter || {
                art: [EinrichtungTypes.SHARED_HOME],
            }),
            options: {
                signal: options.signal,
            },
        });
    };

    const getOption = (item: EinrichtungCollectionJsonldItem): SelectOption => {
        return {
            id: item.id as number,
            name: item.name,
        };
    };

    const hasErrorList = useMemo(() => {
        if (!required) return false;

        return (!data || !Object.keys(data || {}).includes('label')) && config.formTouched;
    }, [required, data, config.formTouched]);

    useEffect(() => {
        if (required && hasErrorList) {
            setErrorList([{ message: 'Eingabe erforderlich', path }]);
        }
    }, [required, hasErrorList, path, data]);

    const isFieldEnabled = useMemo(
        () => !schema.custom?.disabled || (enabled !== undefined && enabled),
        [schema, enabled]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <Typeahead
                id={id}
                data={data}
                error={hasErrorList}
                schema={schema}
                uischema={uischema}
                path={path}
                getData={getData}
                getOption={getOption}
                onChange={handleValueChange}
                multiple={false}
                required={required}
                enabled={isFieldEnabled}
                loadInitialOptions={!schema.custom?.disabled ?? enabled}
                noOptionsText="Keine Einrichtungen gefunden. Geben Sie mindestens 3 Zeichen ein."
            />

            {(hasErrors || hasErrorList) && (
                <FormControl>
                    <FormHelperText component="div">
                        <ErrorList errors={errorList} />
                    </FormHelperText>
                </FormControl>
            )}
        </RowControl>
    );
};

export const WohngemeinschaftAutocompleteControl = withCustomControlProps(WohngemeinschaftAutocompleteControlComponent);
