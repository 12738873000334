import { Alert } from '@mui/material';

import { theme } from 'theme/theme';

import { LinkSupport } from './LinkSupport';

export const SupportInfo = () => {
    return (
        <Alert severity={'info'} style={{ marginTop: theme.spacing(2) }}>
            Sollten Sie Hilfe benötigen, wenden Sie sich bitte an unseren <LinkSupport />.
        </Alert>
    );
};
