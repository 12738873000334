import { RuleEffect } from '@jsonforms/core';

import { AnschriftType, EinrichtungArtType } from 'api/types';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';
import { modalWohngemeinschaft } from 'forms/ui-schemas/modalElements';

export const uiSchema = customVerticalLayout([
    group(
        [
            control('einrichtungArt'),
            control('einrichtungWohngemeinschaftArt'),
            control('einrichtungWohngemeinschaftLeistungsbereich'),
            control('einrichtungName'),
            inlineRowControl([
                control('einrichtungInstitutionskennzeichen'),
                control('einrichtungPlaetze'),
                control('einrichtungDatumErstinbetriebnahme', {
                    options: {
                        format: 'date',
                    },
                }),
            ]),
        ],
        {},
        'Angaben zum Leistungsangebot'
    ),
    group(
        [
            control('einrichtungAdresse'),
            control('einrichtungTraegerverband'),
            control('einrichtungHomepage'),
            control('unternehmensgruppe'),
            control('neueUnternehmensgruppe', {
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/unternehmensgruppe/properties/id',
                        schema: { const: -2 },
                    },
                },
            }),
        ],
        { noSeparator: true },
        'Details zum Leistungsangebot'
    ),
    group([
        control('tragerExistiertBereits'),
        group(
            [
                control('traegerBezeichnung'),
                control('traegerAnschriftTyp'),
                group(
                    [
                        control('traegerPostfachBezeichnung'),
                        inlineRowControl([control('traegerPostfachPlz'), control('traegerPostfachOrt')]),
                    ],
                    {
                        unstyled: true,
                        noSeparator: true,
                        rule: {
                            effect: RuleEffect.SHOW,
                            condition: {
                                scope: '#/properties/traegerAnschriftTyp',
                                schema: { const: AnschriftType.POSTFACH },
                            },
                        },
                    }
                ),
                control('traegerAdresse', {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/traegerAnschriftTyp',
                            schema: { const: AnschriftType.HAUSANSCHRIFT },
                        },
                    },
                }),
                inlineRowControl([control('traegerTelefon'), control('traegerEmail', { requireEmailConfirm: false })]),
                inlineRowControl([control('traegerTyp'), control('traegerFax')]),
            ],
            {
                noSeparator: true,
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#properties/tragerExistiertBereits',
                        schema: { const: false },
                    },
                },
            }
        ),
        control('traeger', {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#properties/tragerExistiertBereits',
                    schema: { const: true },
                },
            },
        }),
    ]),
    group(
        [
            control('einrichtungAnsprechpartner'),
            inlineRowControl([control('einrichtungTelefon'), control('einrichtungFax')]),
            control('einrichtungEmail', { requireEmailConfirm: false }),
        ],
        {},
        'Ansprechpartner'
    ),
    group(
        [
            info({
                message:
                    'Versorgungsverträge, Vergütungs- oder Leistungsvereinbarungen nach SGB XII/IX oder sonstige Vereinbarungen.',
            }),
            control('dokumente'),
        ],
        {
            rule: {
                effect: RuleEffect.HIDE,
                condition: {
                    scope: '#/properties/einrichtungArt',
                    schema: {
                        enum: [EinrichtungArtType.WERKSTATT.value],
                    },
                },
            },
        },
        'Dokumente hochladen'
    ),
    group(
        [
            info({
                message:
                    'Falls Sie 2 oder mehr Personen betreuen, welche gemeinsam in einer Wohnung leben und nicht miteinander verwandt sind bzw. nicht in einer Partnerschaft leben, können Sie die entsprechenden Wohngemeinschaften hier angeben.',
            }),
            listControlWithModalForm(
                ['#/properties/wohngemeinschaften'],
                [
                    tableColumn('Name', { scope: '#/properties/name' }),
                    tableColumn('Anzahl der betreuten Nutzer', { scope: '#/properties/plaetze' }),
                    tableColumn('Beginn der Betreuung', { scope: '#/properties/startDate' }),
                ],
                ['Wohngemeinschaft hinzufügen'],
                ['Wohngemeinschaft löschen'],
                ['Wollen Sie diese Wohngemeinschaft mit allen Angaben wirklich löschen?'],
                [modalWohngemeinschaft],
                { types: ['Wohngemeinschaft'] }
            ),
        ],
        {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/einrichtungArt',
                    schema: {
                        enum: [
                            EinrichtungArtType.AMBULANT_XI_CARE_CONTRACT.value,
                            EinrichtungArtType.AMBULANT_XII_CARE_CONTRACT.value,
                        ],
                    },
                },
            },
        },
        'Wohngemeinschaften'
    ),
    group(
        [
            info({ message: 'Eine Teilnahme nach der Registrierung Ihres Leistungsangebots ist jederzeit möglich.' }),
            control('pflegeWohngeld'),
            control('angabenUeberInvest', {
                gridLayout: { label: 12 },
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/pflegeWohngeld',
                        schema: { const: false },
                    },
                },
            }),
            group(
                [
                    info({
                        message:
                            'Bitte geben Sie hier die E-Mail-Adresse ein, für die ein Benutzer in PfAD.invest angelegt werden soll. Sollten Sie bereits einen Zugang zu PfAD.invest haben, so können Sie diese E-Mail-Adresse hier angeben, damit die Einrichtung diesem Benutzer zugewiesen wird.',
                    }),
                    inlineRowControl([control('investVorname'), control('investName'), control('investMandant')], {
                        title: 'Angaben zum Benutzerkonto',
                    }),
                    control('investEmail', { requireEmailConfirm: false }),
                ],
                {
                    noSeparator: true,
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            type: 'OR',
                            conditions: [
                                {
                                    scope: '#/properties/pflegeWohngeld',
                                    schema: { const: true },
                                },
                                {
                                    scope: '#/properties/angabenUeberInvest',
                                    schema: { const: true },
                                },
                            ],
                        },
                    },
                }
            ),
        ],
        {
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/einrichtungArt',
                    schema: {
                        enum: [
                            EinrichtungArtType.FULLY_INPATIENT_PERMANENT_XI.value,
                            EinrichtungArtType.FULLY_INPATIENT_SHORT.value,
                            EinrichtungArtType.SEMI_RESIDENTIAL_NIGHT_CARE.value,
                            EinrichtungArtType.SEMI_RESIDENTIAL_DAYCARE.value,
                        ],
                    },
                },
            },
        },
        'Teilnahme an PfAD.invest'
    ),
    group([control('mailText'), control('notiz')]),
]);
