import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { EinrichtungNachrichtDetails } from 'pages/Nachrichten/Components/EinrichtungNachrichtDetails';

const Index = () => {
    return (
        <EinrichtungNachrichtDetails
            pathBuilder={PathBuilder.home.verwaltung.leistungsangebote.anbieter.freigegebene}
        />
    );
};

export const LeistungsangeboteNachrichtDetailsPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_NACHRICHTEN
);
