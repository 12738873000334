import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, styled, Typography, useTheme } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useHasRole, useSpecificRole } from 'api/auth';
import {
    ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead,
    ZugriffsrechteJsonldZugriffsrechtePost,
} from 'api/client';
import { useApiZugriffsrechteGet } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useBenutzerZugriffsrechteListActions } from 'components/DataTable/actions/useBenutzerZugriffsrechteListActions';
import { useBenutzerZugriffsrechteColumns } from 'components/DataTable/columns/useBenutzerZugriffsrechteColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ROLE_TYPES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { Schema } from 'forms/types/UiSchemaTypes';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

import { useBenutzerZugriffsrechteListData } from './useBenutzerZugriffsrechteListData';

const initialFormData = {
    benutzer: [],
};

const Index = () => {
    const theme = useTheme();
    const { einrichtungId } = useParams();

    const hasRole = useHasRole();
    const allowedAddUser = hasRole(ROLE_TYPES.ROLLEN_ZUGRIFFSRECHTE_ADD);

    const { isAnbieter } = useSpecificRole();

    const pathBuilder = useMemo(
        () =>
            isAnbieter
                ? PathBuilder.home.verwaltung.leistungsangebote.path
                : PathBuilder.home.verwaltung.leistungsangebote.freigegeben.path,
        [isAnbieter]
    );

    const { submitStart, submitEnd, isSubmitting } = useFormState();
    const { serverDataChanged, setServerDataChanged, handleAction } = useBenutzerZugriffsrechteListActions(
        PathBuilder.home.verwaltung.leistungsangebote
    );
    const { isLoading, data, error, loadData } = useApiZugriffsrechteGet(einrichtungId);

    const [formData, setFormData] = useState<ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead>(initialFormData);

    const getData = useBenutzerZugriffsrechteListData(String(einrichtungId));
    const columns = useBenutzerZugriffsrechteColumns(handleAction);

    const handleSubmit = (formData: ZugriffsrechteJsonldZugriffsrechtePost) => {
        submitStart();

        return backendApiService
            .postZugriffsrechte({
                einrichtung: String(einrichtungId),
                benutzer: formData.benutzer,
            })
            .then(() => {
                setFormData(initialFormData);
                setServerDataChanged((prevState) => prevState + 1);
            })
            .catch(() => Promise.reject())
            .finally(submitEnd);
    };

    useEffect(() => {
        if (!isLoading && data?.data && data?.data?.leistungsanbieterBenutzer?.length) {
            setFormData(data.data);
        }
    }, [isLoading, data?.data, formData]);

    useDebouncedEffect(
        () => {
            if (isSubmitting) {
                return;
            }

            loadData(einrichtungId);
        },
        [serverDataChanged, isSubmitting],
        50
    );

    return (
        <ContentContainer title="Zugriffsrechte verwalten">
            <StyledContainer>
                <BaseGridContainer>
                    {!error ? (
                        <AppLoaderContainer isLoading={isLoading && !data?.data}>
                            {allowedAddUser ? (
                                <>
                                    <Typography variant="h1">
                                        {data?.data?.einrichtung?.name}: Zugriffsrechte verwalten
                                    </Typography>

                                    <Alert severity="info">
                                        An dieser Stelle können Sie verwalten, wer auf Ihr Leistungsangebot zugreifen
                                        kann. Geben Sie einem Benutzer Zugriffsrechte auf eines Ihrer Leistungsangebote,
                                        so hat dieser ebenfalls die Möglichkeit weiteren Benutzern Zugriff auf dieses
                                        Leistungsangebot zu gewähren.
                                    </Alert>

                                    <FormWrapper>
                                        <SimpleForm
                                            data={formData}
                                            schema={data?.schema as Schema}
                                            submitLabel="Zuweisen"
                                            onSubmit={handleSubmit}
                                            ignoreLeaveWarning
                                            backLink={{
                                                path: pathBuilder,
                                                title: 'Abbrechen',
                                            }}
                                            buttonStyles={{
                                                justifyContent: 'flex-end',
                                            }}
                                            messages={{
                                                success: 'Benutzer wurde hinzugefügt.',
                                                error: 'Benutzer konnte nicht hinzugefügt werden.',
                                            }}
                                        />
                                    </FormWrapper>

                                    <StyledDivider $marginTop={0} $marginBottom={theme.spacing(1)} />
                                </>
                            ) : (
                                <Typography variant="h1">{data?.data?.einrichtung?.name}: Zugriffsrechte</Typography>
                            )}

                            <DataTableHydraServerside
                                getColumns={columns}
                                getData={getData}
                                serverDataChanged={serverDataChanged}
                                initialOptions={{
                                    sortOrder: {
                                        name: 'nachname',
                                        direction: 'asc',
                                    },
                                    custom: {
                                        disableSearch: true,
                                    },
                                }}
                            />
                        </AppLoaderContainer>
                    ) : (
                        <Alert severity="error">Beim Laden der Zugriffsrechte ist ein Fehler aufgetreten.</Alert>
                    )}

                    {!allowedAddUser ? (
                        <ButtonGroupGrid
                            backLink={{ path: PathBuilder.home.verwaltung.leistungsangebote.freigegeben.path }}
                        />
                    ) : null}
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const FormWrapper = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

export const ZugriffsrechtePage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_ZUGRIFFSRECHTE
);
