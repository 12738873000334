import React from 'react';
import { Box } from '@mui/material';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { AutocompleteFilter } from './AutocompleteFilter';

export const BehoerdenFilterOptions = (
    columnLabel: string,
    filterOptions?: Record<number, string>
): MUIDataTableColumnOptions => {
    return {
        customFilterListOptions: {
            render: (value) => {
                return `${columnLabel}: ${filterOptions?.[value]}`;
            },
        },
        filterOptions: {
            names: Object.keys(filterOptions || {}),
            fullWidth: true,
            display: (filterList, onChange, index, column, filterData) => {
                return (
                    <>
                        <AutocompleteFilter
                            index={index}
                            column={column}
                            filterData={filterData[index]}
                            filterValue={filterList[index][0]}
                            filterLabels={filterOptions}
                            onChange={onChange}
                        />
                        <Box width={400} />
                    </>
                );
            },
        },
        filterType: 'custom',
    };
};
