import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

type UseLocalStorageOptions<T> = {
    defaultValue?: T;
    shallow?: boolean;
    name?: string;
};

export const useLocalStorage = <S = undefined>(
    key?: string,
    { defaultValue, shallow, name }: UseLocalStorageOptions<S> = {}
): [S, Dispatch<SetStateAction<S>>, () => void, () => void] => {
    const storageName = useMemo(() => name?.split('/').join('-') || '', [name]);
    const storageKey = useMemo(() => `${key}${storageName}`, [storageName, key]);

    const [value, setValue] = useState<S>(() => {
        try {
            if (!shallow) return defaultValue;
            const item = localStorage.getItem(storageKey);
            if (!item) return defaultValue;
            else return JSON.parse(item);
        } catch (e) {
            return defaultValue;
        }
    });

    const removeValue = () => {
        localStorage.removeItem(storageKey);
    };

    const clearStorage = () => {
        localStorage.clear();
    };

    useEffect(() => {
        if (!shallow || !value) return;
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [shallow, storageKey, value]);

    return [value, setValue, removeValue, clearStorage];
};
