import React, { ReactElement } from 'react';
import { Add } from '@mui/icons-material';
import { Alert, Box, Button, styled, Typography } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useSpecificRole } from 'api/auth/useHasRole';
import { LeistungsangebotEntwurfCollectionResponse } from 'api/types';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { TableOptions } from 'components/DataTable/hooks/useTableOptions';
import { GetColumns } from 'components/DataTable/types';
import { AntragType } from 'constants/antragTypes';
import { Link } from 'elements/Link';
import { ActionHeading } from 'layout/components/ActionHeading';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';
import { transientOptions } from 'theme/utils';
import { UseRoleText } from 'utilities/hooks/textConfigs/textTypes';
import { useRoleText } from 'utilities/hooks/useRoleText';

interface LeistungsangeboteOverviewProps<T> {
    getData: GetData<T>;
    columns: (data: LeistungsangebotEntwurfCollectionResponse | undefined) => MUIDataTableColumnDef[];
    serverDataChanged: number;
    pathBuilder: PathBuilderProps;
    nameOverride?: string;
    initialOptions?: TableOptions;
    segment?: string;
    card?: Pick<UseRoleText, 'overview'> | { overview: { subHeadline?: string; subDescription?: ReactElement } };
    headline?: string;
    hideHeader?: boolean;
    marginBottom?: string;
}

export const LeistungsangeboteOverviewTable = <T = any,>({
    getData,
    columns,
    serverDataChanged,
    pathBuilder,
    nameOverride,
    initialOptions = {},
    segment,
    card,
    headline,
    hideHeader = false,
    marginBottom,
}: LeistungsangeboteOverviewProps<T>) => {
    const { isAnbieter, isEinrichtungBenutzer, isBehoerde } = useSpecificRole();

    const { overview, headline: roleHeadline } = useRoleText(AntragType.LEISTUNGSANGEBOT);

    return (
        <BaseGridContainer>
            {!hideHeader ? (
                <>
                    <ActionHeading variant="h1" title={headline || roleHeadline || 'Leistungsangebote'} $spaceBetween>
                        {isAnbieter && !isEinrichtungBenutzer ? (
                            <StyledButton
                                variant="contained"
                                endIcon={<Add />}
                                component={Link}
                                to={PathBuilder.home.verwaltung.leistungsangebote.registrieren.path}
                            >
                                Neues Leistungsangebot
                            </StyledButton>
                        ) : null}
                    </ActionHeading>

                    {overview?.description ? <Typography paragraph>{overview?.description}</Typography> : null}
                </>
            ) : null}

            {isBehoerde ? (
                <Alert severity="info">
                    Bitte prüfen Sie vor Freigabe des Leistungsangebotes in der Spalte „Status Leistungsanbieter“, ob
                    der dazugehörige Leistungsanbieter bereits freigegeben ist und ein NRW-Schlüssel vergeben wurde.
                    Sollte das nicht der Fall sein, müssen Sie die Freigabe des Leistungsanbieters zunächst über die
                    Aufgabe „Leistungsanbieter freigeben“ vornehmen. In der Spalte „Leistungsanbieter“ finden Sie den
                    Namen des freizugebenden Leistungsanbieters.
                </Alert>
            ) : null}

            <ContentLayoutContainer $marginBottom={marginBottom}>
                <Typography variant="h2">{card?.overview?.subHeadline || overview?.subHeadline}</Typography>
                <Typography paragraph>{card?.overview?.subDescription || overview?.subDescription}</Typography>

                <DataTableHydraServerside
                    getColumns={columns as GetColumns}
                    getData={getData}
                    initialOptions={{
                        sortOrder: {
                            name: 'einrichtungName',
                            direction: 'asc',
                        },
                        searchPlaceholder: 'Suche nach Einrichtungsname',
                        custom: {
                            pathBuilder,
                            segment,
                        },
                        ...initialOptions,
                    }}
                    serverDataChanged={serverDataChanged}
                    persistSearchText
                    nameOverride={nameOverride}
                />
            </ContentLayoutContainer>
        </BaseGridContainer>
    );
};

const StyledButton = styled(Button)(() => ({
    '&:visited': {
        color: '#fff',
    },
})) as typeof Button;

const ContentLayoutContainer = styled(
    Box,
    transientOptions
)<{ $marginBottom?: string }>(({ $marginBottom }) => ({
    marginBottom: $marginBottom ?? 0,
}));
