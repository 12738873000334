import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { DeletecObjectCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDate, getDateTime } from 'components/DataTable/tableUtils';

export const useDeletedObjectsColumns = (
    handleAction: AntragListAction<any>
): ((data: DeletecObjectCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                },
                {
                    name: 'art',
                    label: 'Art des gelöschten Objektes',
                },
                {
                    name: 'name',
                    label: 'Name des gelöschten Objektes',
                },
                {
                    name: 'erstinbetriebnahme',
                    label: 'Datum der Inbetriebnahme',
                    options: {
                        customBodyRender: getDate,
                    },
                },
                {
                    name: 'deletedAt',
                    label: 'Datum der Löschung',
                    options: {
                        customBodyRender: getDateTime,
                    },
                },
                {
                    name: 'deletedBy',
                    label: 'Gelöscht von',
                },
                actions(data),
            ];
        },
        [actions]
    );
};
