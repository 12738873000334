import { useCallback, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { FileUploadResponse, FileUploadResult } from 'api/responses/FileUploadResponse';
import { UploadValue } from 'forms/types/UploadValueType';
import { getReadableFileSize } from 'utilities/Utils';

const UPLOAD_LIMIT_BYTES = 20971520;

export const useFileUpload = (
    onUpload: (responses: FileUploadResponse[]) => void,
    onRemove: (id: number) => void,
    targetUrl: string,
    uploadedFileList?: Array<UploadValue>,
    withUploadLimit = false
) => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    const getServerError = (result: FileUploadResult) => {
        if ('error' in result && result?.error?.response?.status === 413) {
            return 'Die ausgewählten Dateien überschreiten das zulässige Limit.';
        }

        return 'fileId' in result
            ? `Dokument konnte nicht geladen werden. (Id: ${result.fileId})`
            : `Dokument konnte nicht hochgeladen werden. (${result.fileName})`;
    };

    const handleUploadPromises = useCallback((promises: Promise<FileUploadResult>[]) => {
        setErrors([]);
        return Promise.all(promises).then(
            (results) =>
                results.filter((result) => {
                    if (!('error' in result)) {
                        return true;
                    }

                    setErrors((prevState) => [...prevState, getServerError(result)]);
                    return false;
                }) as FileUploadResponse[]
        );
    }, []);

    const hasUploadLimitError = useCallback(
        (files: FileList): boolean => {
            const currentTotalFileSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
            const totalFileSize = (uploadedFileList || []).reduce(
                (acc, file) => acc + (file.fileSize || 0),
                currentTotalFileSize
            );

            if (withUploadLimit && totalFileSize > UPLOAD_LIMIT_BYTES) {
                setErrors((prevState) => [
                    ...prevState,
                    `Die ausgewählten Dateien überschreiten das Limit von ${getReadableFileSize(UPLOAD_LIMIT_BYTES)}.`,
                ]);

                return true;
            }

            return false;
        },
        [withUploadLimit, uploadedFileList]
    );

    const uploadFiles = useCallback(
        (files: FileList) => {
            setErrors([]);
            setIsUploading(true);

            if (hasUploadLimitError(files)) {
                setIsUploading(false);
                return;
            }

            handleUploadPromises(Array.from(files).map((file) => backendApiService.postFile(file, targetUrl)))
                .then((responses) => onUpload(responses))
                .finally(() => setIsUploading(false));
        },
        [handleUploadPromises, hasUploadLimitError, onUpload, targetUrl]
    );

    const removeFile = useCallback(
        (id: number) => {
            setErrors([]);
            onRemove(id);
        },
        [onRemove]
    );

    return {
        isUploading,
        uploadFiles,
        uploadErrors: errors,
        removeFile,
    };
};
