import { ROLE_TYPES, ROLES } from 'constants/roles';
import { MenuInterface } from 'layout/HeaderConfig';
import { PathBuilder } from 'navigation/Paths';

export const menuVerwaltung: MenuInterface[] = [
    {
        path: PathBuilder.home.verwaltung.mandant.behoerde.edit,
        name: 'Mandant bearbeiten',
        roles: ROLE_TYPES.ROLLEN_MANDANT_BEARBEITEN_BEHOERDE,
    },
    {
        path: PathBuilder.home.verwaltung.mandant.lv.edit,
        name: 'Mandant bearbeiten',
        roles: ROLE_TYPES.ROLLEN_MANDANT_BEARBEITEN_LV,
    },
    {
        path: PathBuilder.home.verwaltung.traeger.mandantView,
        name: 'Mandant ansehen',
        roles: ROLE_TYPES.ROLLEN_TRAEGER_MANDANT_ANSEHEN,
    },
    {
        path: PathBuilder.home.verwaltung.benutzer.path,
        name: 'Benutzer',
        divide: true,
        roles: [ROLES.USER],
    },
    {
        path: PathBuilder.home.verwaltung.meldung.path,
        name: 'Meldungen',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_MELDUNGEN,
    },
    {
        path: PathBuilder.home.verwaltung.platzmelder.path,
        name: 'Belegbare Plätze',
        roles: ROLE_TYPES.ROLLEN_PLATZMELDER_BEHOERDE_MENU,
    },
    {
        path: PathBuilder.home.verwaltung.regelpruefung.path,
        name: 'Regelprüfungen',
        roles: ROLE_TYPES.ROLLEN_REGELPRUEFUNG,
    },
    {
        path: PathBuilder.home.verwaltung.pruefberichte.path,
        name: 'Prüfberichte der Regelprüfungen',
        roles: ROLE_TYPES.ROLLEN_PRUEFBERICHTE,
    },
    {
        path: PathBuilder.home.verwaltung.landschaftsverband.path,
        name: 'Landschaftsverbände',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_LANDSCHAFTSVERBAND,
    },
    {
        path: PathBuilder.home.verwaltung.behoerden.path,
        name: 'Kreise & kreisfreie Städte',
        roles: ROLE_TYPES.ROLLEN_BEHOERDEN,
    },
    {
        path: PathBuilder.home.verwaltung.traegerverband.path,
        name: 'Verbände',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_TRAEGERVERBAENDE,
    },
    {
        path: PathBuilder.home.verwaltung.traeger.path,
        name: 'Leistungsanbieter',
        roles: ROLE_TYPES.ROLLEN_TRAEGER,
    },
    {
        path: PathBuilder.home.verwaltung.leistungsangebote.path,
        name: 'Leistungsangebote',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE,
    },
    {
        path: PathBuilder.home.verwaltung.leistungsangebote.freigegeben.path,
        name: 'Leistungsangebote',
        roles: ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN,
    },
    {
        path: PathBuilder.home.verwaltung.profilHeimfinder.path,
        name: 'Profil Heimfinder',
        roles: ROLE_TYPES.ROLLEN_PROFIL_HEIMFINDER,
    },
    {
        path: PathBuilder.home.verwaltung.platzmelder.path,
        name: 'Belegbare Plätze',
        roles: ROLE_TYPES.ROLLEN_PLATZMELDER,
    },
    {
        path: PathBuilder.home.verwaltung.abw.path,
        name: 'Angezeigte Betreuung in Wohngemeinschaften',
        roles: ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN,
    },
    {
        path: PathBuilder.home.verwaltung.femAntrag.path,
        name: 'Freiheitsentziehende Maßnahmen',
        roles: ROLE_TYPES.ROLLEN_FEM_MELDER,
    },
    {
        path: PathBuilder.home.verwaltung.deletedObjects.path,
        name: 'Gelöschte Objekte',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_DELETED_OBJECTS,
    },
    {
        path: PathBuilder.home.verwaltung.massenmail.path,
        name: 'Massenmail',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_MASSENMAIL,
    },
    {
        path: PathBuilder.home.verwaltung.emailText.path,
        name: 'E-Mail-Texte editieren',
        roles: ROLE_TYPES.ROLLEN_EMAIL_TEXT,
    },
    {
        path: PathBuilder.home.verwaltung.emailSignatur.path,
        name: 'E-Mail-Signatur',
        roles: ROLE_TYPES.ROLLEN_EMAIL_SIGNATUR,
    },
    {
        path: PathBuilder.home.verwaltung.meldungHinweise.path,
        name: 'Hinweistexte und Dokumente für die Meldung',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT,
    },
    {
        path: PathBuilder.home.verwaltung.documentVerification.path,
        name: 'Dokumente nach Prüfung',
        roles: ROLE_TYPES.ROLLEN_DOKUMENTE_PRUEFUNG,
    },
    {
        path: PathBuilder.home.verwaltung.investRegistrations.path,
        name: 'Fehlgeschlagene Invest-Übertragungen',
        divide: true,
        roles: ROLE_TYPES.ROLLEN_INVEST_REGISTRATION_FAILED,
    },
];
