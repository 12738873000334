import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { MeldungHinweisDokumentFormData } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { SimpleForm } from 'forms/SimpleForm';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { uiSchemaHinweisDocuments } from './config';

const initialFormData: MeldungHinweisDokumentFormData = {
    einrichtungsArt: [],
    uploads: undefined,
};

const Index = () => {
    const navigate = useNavigate();

    const { schema } = useSchemaFromJsonLdContext('MeldungHinweisdokument', initialFormData);

    const handleAfterSubmit = () => navigate(PathBuilder.home.verwaltung.meldungHinweise.path);

    const handleSubmit = backendApiService.createMeldungHinweisDokument.bind(backendApiService);

    return (
        <ContentContainer title="Anforderungsdokumente hinzufügen">
            <StyledContainer>
                <Typography variant="h1">Anforderungsdokumente hinzufügen</Typography>

                <BaseGridContainer lg={8}>
                    <SimpleForm
                        isLoading={!schema}
                        data={initialFormData}
                        schema={schema}
                        uiSchema={uiSchemaHinweisDocuments}
                        submitLabel="Speichern"
                        onSubmit={handleSubmit}
                        onAfterSubmit={handleAfterSubmit}
                        backLink={{
                            path: PathBuilder.home.verwaltung.meldungHinweise.path,
                            title: 'Zur Übersicht',
                        }}
                        messages={{
                            success: 'Die Hinweisdokumente wurden erfolgreich hinzugefügt.',
                            error: 'Die Hinweisdokumente konnten nicht hinzugefügt werden.',
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MeldungHinweisDokumentAddPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT
);
