import { GridSize } from '@mui/material';

import { CustomHorizontalLayoutType, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const customHorizontalLayout = (
    elements: UiSchemaType[],
    options: {
        gridSize?: GridSize;
        spacing?: number;
        style?: any;
    } = {
        gridSize: 6,
        spacing: 2,
        style: {},
    }
): CustomHorizontalLayoutType => ({
    type: 'CustomHorizontalLayout',
    elements,
    ...options,
});
