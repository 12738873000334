import { createAjv, getAjv, getData, JsonFormsCore, JsonFormsState } from '@jsonforms/core';
import { ctxDispatchToControlProps, useJsonForms } from '@jsonforms/react';

import { FormConfig, FormLayoutConfig } from 'forms/types/UiSchemaTypes';

interface UseJsonFormsState {
    formState: JsonFormsState;
    ajv: any; // ToDo Type verwenden
    rootData: any;
    config: FormConfig;
    getFormData: () => JsonFormsCore['data'];
    handleChange(path: string, value: any): void;
    isReadonly?: boolean;
}

export const useJsonFormsState = (): UseJsonFormsState => {
    const ctx = useJsonForms();

    const formState = { jsonforms: { ...ctx } };
    const ajv = getAjv(formState) || createAjv();
    const rootData = getData(formState);

    return {
        formState,
        ajv,
        rootData,
        config: ctx.config as FormConfig,
        getFormData: () => getData(formState),
        handleChange: ctxDispatchToControlProps(ctx.dispatch as any).handleChange,
        isReadonly: ctx.readonly,
    };
};

export const useLayoutWithJsonFormsState = (config: FormLayoutConfig) => {
    const ctx = useJsonForms();

    return {
        ...ctx.config,
        ...config,
    };
};
