import { useEffect, useMemo, useState } from 'react';

import { EMAIL_REGEX } from 'forms/hooks/useFormValidation';

export const useEmailValidation = (
    email = '',
    confirmEmail = '',
    requireConfirm = true
): [valid: boolean, match: boolean, hasValidationError: boolean, hasMatchingError: boolean] => {
    const [valid, setValid] = useState(false);
    const [match, setMatch] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [matchingError, setVMatchingError] = useState(false);

    const cleanEmail = useMemo(() => email.trim(), [email]);
    const cleanConfirmEmail = useMemo(() => confirmEmail.trim(), [confirmEmail]);

    useEffect(() => {
        if (cleanEmail) {
            setValid(RegExp(EMAIL_REGEX).test(cleanEmail));
        }

        if ((cleanEmail && cleanConfirmEmail) || !requireConfirm) {
            setMatch(Boolean(cleanEmail && cleanEmail === cleanConfirmEmail) || !requireConfirm);
        }
    }, [cleanEmail, cleanConfirmEmail, requireConfirm]);

    useEffect(() => {
        if (cleanEmail && !valid) {
            setValidationError(true);
        } else {
            setValidationError(false);
        }

        if (cleanConfirmEmail && !match) {
            setVMatchingError(true);
        } else {
            setVMatchingError(false);
        }
    }, [valid, match, cleanEmail, cleanConfirmEmail]);

    return [valid, match, validationError, matchingError];
};
