import React from 'react';
import { Typography } from '@mui/material';

import { PageHeading } from 'components/PageHeading';
import { LinkSupport } from 'elements/LinkSupport';

export const FailureMessage: React.FC = () => {
    return (
        <>
            <PageHeading title="Fehler" />

            <Typography paragraph>Der von Ihnen genutzte Link ist fehlerhaft oder abgelaufen.</Typography>

            <Typography paragraph>
                Bitte wenden Sie sich an unseren <LinkSupport />
            </Typography>
        </>
    );
};
