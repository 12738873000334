import React from 'react';
import { styled, TableCell, TableHead, TableRow } from '@mui/material';

interface TableHeadCompareProps {
    inline?: boolean;
}

export const TableHeadCompare = ({ inline = false }: TableHeadCompareProps) => {
    const TableHeadRow = () => (
        <TableRow>
            <StyledTableHeadCell></StyledTableHeadCell>
            <StyledTableHeadCell>Bezeichnung</StyledTableHeadCell>
            <StyledTableHeadCell>Wert (Alt)</StyledTableHeadCell>
            <StyledTableHeadCell>Wert (Neu)</StyledTableHeadCell>
        </TableRow>
    );

    return (
        <>
            {inline ? (
                <TableHeadRow />
            ) : (
                <TableHead>
                    <TableHeadRow />
                </TableHead>
            )}
        </>
    );
};

const StyledTableHeadCell = styled(TableCell)(() => ({
    fontWeight: 600,
}));
