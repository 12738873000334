import React, { useMemo } from 'react';
import HtmlParser from 'react-html-parser';
import { Checkbox, Grid, styled, TableCell, TableRow, Typography } from '@mui/material';
import { Property } from 'csstype';
import { v4 as uuidv4 } from 'uuid';

import { ErrorIcon } from 'forms/components/ErrorIcon';
import { FormulaPopover } from 'forms/components/FormulaPopover';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useErrorNavigate } from 'forms/controls/SummaryTable/useErrorNavigate';
import { ComponentError } from 'forms/hooks/useErrors';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { FormConfig, FormStateValue, Schema, TableColumn, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { UploadValue } from 'forms/types/UploadValueType';
import { formatFieldValue } from 'forms/utilities/SchemaUtils';
import { transientOptions } from 'theme/utils';
import { deepEqual } from 'utilities';

type FieldRowUiSchema = {
    uischema: UiSchemaType & { showValueLabel?: boolean; verticalAlign?: 'top' | 'middle' | 'bottom'; title?: string };
};
type FieldRowInterface = Omit<CustomControlProps, 'uischema'> & FieldRowUiSchema;

const FieldRowControl: React.FC<FieldRowInterface> = ({
    data,
    path,
    schema,
    uischema,
    label,
    config,
    hasErrors,
    errors,
}) => {
    return (
        <FieldRowComponent
            label={label}
            title={uischema?.title}
            path={path}
            showValueLabel={uischema.showValueLabel}
            verticalAlign={uischema.verticalAlign}
            value={config.mapUuid ? config.mapUuid(data) || data : data}
            valueSchema={schema as Schema}
            hasErrors={Boolean(hasErrors)}
            errors={errors}
            config={config}
        />
    );
};

interface FieldRowComponentInterface {
    path: string;
    value: FormStateValue | any[];
    config: FormConfig;
    verticalAlign?: FieldRowUiSchema['uischema']['verticalAlign'];
    label?: string;
    title?: FieldRowUiSchema['uischema']['title'];
    valueSchema?: Schema;
    showValueLabel?: boolean;
    hasErrors?: boolean;
    errors?: Array<ComponentError>;
}

const getPreparedValue = (value: any, schema?: Schema) => {
    if (schema?.custom?.block_prefixes.includes('upload')) {
        const uploadValue = value as UploadValue;
        return {
            uploadId: uploadValue.uploadId,
            contentUrl: uploadValue.contentUrl,
            originalName: uploadValue.originalName,
            fileSize: uploadValue.fileSize,
            accessRights: uploadValue.accessRights,
        };
    }

    if (schema?.custom?.block_prefixes.includes('uploads') && Array.isArray(value)) {
        if (!value.length) {
            return undefined;
        }

        return value?.map((upload: UploadValue) => ({
            uploadId: upload.uploadId,
            contentUrl: upload.contentUrl,
            originalName: upload.originalName,
            fileSize: upload.fileSize,
            accessRights: upload.accessRights,
        }));
    }

    return value;
};

export const FieldRowComponent: React.FC<FieldRowComponentInterface> = ({
    path,
    value,
    config,
    verticalAlign = 'top',
    label,
    title,
    valueSchema,
    showValueLabel = false,
    hasErrors = false,
    errors,
}) => {
    const errorNavigate = useErrorNavigate(path, errors);

    const previousValue = useMemo(
        () => getPreparedValue(valueSchema?.custom?.previous_value, valueSchema),
        [valueSchema]
    );

    const preparedValue = useMemo(() => getPreparedValue(value, valueSchema), [valueSchema, value]);

    if (Array.isArray(preparedValue) && showValueLabel) {
        return (
            <ZusammenfassungTableRow $error={hasErrors} data-cy={`FieldRow-${path}`}>
                <FieldCell>{hasErrors ? <ErrorIcon label={label} onClick={errorNavigate} /> : null}</FieldCell>

                <LabelCell $verticalAlign={verticalAlign}>{label}</LabelCell>

                {config?.compareData ? (
                    <ValueCell style={{ verticalAlign: 'top' }}>
                        <TableCellValue data={previousValue} schema={valueSchema} showLabel={showValueLabel} />
                    </ValueCell>
                ) : null}

                {preparedValue.length > 0 ? (
                    preparedValue.map((field: any) => (
                        <ValueCell key={uuidv4()}>
                            <TableCellValue data={field.value} schema={field.colSchema} showLabel={showValueLabel} />
                        </ValueCell>
                    ))
                ) : (
                    <ValueCell>
                        <TableCellValue data={null} schema={valueSchema} showLabel={showValueLabel} />
                    </ValueCell>
                )}
            </ZusammenfassungTableRow>
        );
    }

    return (
        <>
            {title ? (
                <TableRow>
                    <TableCell colSpan={3} sx={{ borderBottom: 0 }}>
                        <Typography variant={'h4'} style={{ margin: 0, fontWeight: 600 }}>
                            {title}
                        </Typography>
                    </TableCell>
                </TableRow>
            ) : null}

            <ZusammenfassungTableRow
                $error={hasErrors}
                $highlighted={
                    config?.compareData &&
                    valueSchema?.custom?.compare_previous_value &&
                    !deepEqual(previousValue, preparedValue)
                }
                data-cy={`FieldRow-${path}`}
            >
                <FieldCell>{hasErrors ? <ErrorIcon label={label} onClick={errorNavigate} /> : null}</FieldCell>

                <LabelCell $verticalAlign={verticalAlign}>{label}</LabelCell>

                {config?.compareData ? (
                    <ValueCell style={{ verticalAlign: 'top' }}>
                        <TableCellValue data={previousValue} schema={valueSchema} showLabel={showValueLabel} />
                    </ValueCell>
                ) : null}

                <ValueCell style={{ verticalAlign: 'top' }}>
                    <TableCellValue data={preparedValue} schema={valueSchema} showLabel={showValueLabel} />
                </ValueCell>
            </ZusammenfassungTableRow>
        </>
    );
};

export const TableCellValue = ({
    data,
    schema,
    showLabel = false,
    column,
    textAlign = 'left',
}: {
    data: FormStateValue;
    schema?: Schema;
    showLabel?: boolean;
    column?: TableColumn;
    textAlign?: Property.TextAlign;
}) => {
    if (data === undefined || data === null) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={11} container justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}>
                    <Typography component={column?.nullValue ? 'p' : 'span'} style={{ textAlign }}>
                        {column?.nullValue || '-'}
                    </Typography>
                </Grid>
                {schema?.custom?.formula && (
                    <Grid item xs={1} container justifyContent={'center'}>
                        <FormulaPopover formula={schema.custom.formula} />
                    </Grid>
                )}
            </Grid>
        );
    }

    if (schema?.custom?.block_prefixes.includes('upload')) {
        return <UploadedDocument fileData={data} readonly />;
    }

    if (schema?.custom?.block_prefixes.includes('uploads')) {
        return (
            data?.map((d: FormStateValue) => <UploadedDocument key={d.uuid || uuidv4()} fileData={d} readonly />) || (
                <>-</>
            )
        );
    }

    if (
        schema?.custom?.block_prefixes.includes('checkbox') &&
        !schema?.custom?.block_prefixes.includes('ja_nein') &&
        !column?.format
    ) {
        return (
            <div style={{ display: 'inline-block' }}>
                <Checkbox style={{ padding: 0, marginRight: 8, float: 'left' }} checked={data} disabled />
                {showLabel && <Typography component={'span'}>{schema.title}</Typography>}
            </div>
        );
    }

    const value = formatFieldValue(data, schema, column?.format, column?.unit);

    const getValueOutput = (value: string) => {
        return value === undefined
            ? column?.nullValue || '-'
            : column?.valueLabel
            ? column?.valueLabel.replace('{value}', value)
            : `${value}`;
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={11} container justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}>
                    <Typography style={{ textAlign }} component="div">
                        <div style={{ float: 'left' }}>{HtmlParser(getValueOutput(value))}</div>
                    </Typography>
                </Grid>

                {schema?.custom?.formula && (
                    <Grid item xs={1} container justifyContent={'center'}>
                        <FormulaPopover formula={schema.custom.formula} />{' '}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const LabelCell = styled(
    TableCell,
    transientOptions
)<{ $verticalAlign?: FieldRowUiSchema['uischema']['verticalAlign'] }>(({ $verticalAlign }) => ({
    verticalAlign: $verticalAlign,
}));

const FieldCell = styled(TableCell)(() => ({
    width: 110,
}));

const ValueCell = styled(TableCell)(() => ({
    width: 'auto',
}));

const ZusammenfassungTableRow = styled(
    TableRow,
    transientOptions
)<{ $error: boolean; $highlighted?: boolean }>(({ theme, $error, $highlighted }) => {
    let backgroundColor = undefined;

    if ($highlighted) {
        backgroundColor = '#fcf8e3';
    }

    if ($error) {
        backgroundColor = theme.palette.error.lighter;
    }

    return {
        backgroundColor,
    };
});

export const FieldRow = withCustomControlProps(FieldRowControl, { showErrorsImmediately: true });
