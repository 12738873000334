import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { TraegerForm } from './TraegerForm';

const Index = () => {
    return (
        <TraegerForm
            title="Leistungsanbieter: Erstellen"
            messages={{
                success: 'Leistungsanbieter wurde erstellt.',
                error: 'Leistungsanbieter konnte nicht erstellt werden.',
            }}
            submitLabel="Erstellen"
        />
    );
};

export const TraegerCreatePage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_TRAEGER_CREATE
);
