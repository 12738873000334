import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
    EmailAntragApi,
    FemAntragApi,
    LeistungsangebotRegistrierenApi,
    MeldungAntragApi,
    RegelpruefungAntragApi,
} from 'api/einrichtungApiTypes';
import { EinrichtungApiFormGetParams } from 'api/types';
import { FormResponse, FormState } from 'forms/types/UiSchemaTypes';

interface UseEinrichtungApiResponse {
    getFormDataAPI: ({ objectType, uuid }: { objectType?: string; uuid?: string }) => Promise<FormResponse>;
    submitAPI: ({
        body,
        persist,
        objectType,
        uuid,
        recalculateFields,
    }: {
        body: FormState;
        persist?: boolean;
        objectType?: string;
        uuid?: string;
        recalculateFields?: boolean;
    }) => Promise<FormResponse>;
    deleteObjectTypeItemAPI: (uuid: string) => Promise<void>;
}

type EinrichtungApiTypes =
    | LeistungsangebotRegistrierenApi
    | MeldungAntragApi
    | EmailAntragApi
    | FemAntragApi
    | RegelpruefungAntragApi;

export const useEinrichtungApi = (api: EinrichtungApiTypes): UseEinrichtungApiResponse => {
    const { id, antragId, step } = useParams();
    const currentId = useMemo<number | undefined>(() => {
        const typeId = antragId || id;
        return typeId ? parseInt(typeId, 10) : undefined;
    }, [id, antragId]);

    const getFormDataAPI = useCallback(
        ({ objectType, uuid, options }: EinrichtungApiFormGetParams) => {
            if (!currentId) {
                return Promise.resolve();
            }

            return api.getFormDataAPI({ id: currentId, step, objectType, uuid, options });
        },
        [currentId, step, api]
    );

    const submitAPI = useCallback(
        ({
            body,
            persist,
            objectType,
            uuid,
            recalculateFields,
        }: {
            body: FormState;
            persist?: boolean;
            objectType?: string;
            uuid?: string;
            recalculateFields?: boolean;
        }) => {
            if (!currentId) return Promise.reject(Error(`Id[${currentId}] needs to be set.`));
            return api.submitAPI({
                id: currentId,
                step,
                body,
                objectType,
                uuid,
                persist,
                recalculateFields,
            });
        },
        [currentId, step, api]
    );

    const deleteObjectTypeItemAPI = useCallback(
        (uuid: string) => {
            if (!currentId) return Promise.reject(Error(`Id[${currentId}] needs to be set.`));
            return api.deleteObjectTypeItem?.({ id: currentId, uuid });
        },
        [currentId, api]
    );

    return {
        getFormDataAPI,
        submitAPI,
        deleteObjectTypeItemAPI,
    } as UseEinrichtungApiResponse;
};
