import React, { useCallback } from 'react';
import { ListItem } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { ApiRegelpruefungAntragGetCollection200Response } from 'api/client';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeColorTypes, BadgeColumn } from 'components/DataTable/components/BadgeColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDate } from 'components/DataTable/tableUtils';
import { MAENGEL_ART_LABELS } from 'constants/labels';
import { MAENGEL_TYPES } from 'constants/MAENGEL_TYPES';
import { getStandort } from 'pages/Regelpruefung/RegelpruefungDetailPage';

export const useRegelpruefungAntragColumns = (
    handleAction: AntragListAction<any>
): ((data: ApiRegelpruefungAntragGetCollection200Response | undefined) => MUIDataTableColumnDef[]) => {
    const getMaengelBadgeColor = (value: string) => {
        const colors: Record<string, BadgeColorTypes> = {
            [MAENGEL_TYPES.MAENGELFREI]: 'success',
            [MAENGEL_TYPES.WESENTLICHE_MAENGEL]: 'error',
            [MAENGEL_TYPES.MAENGEL_OHNE_ANORDNUNG]: 'warning',
        };

        return colors[value];
    };

    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'eingereichtAt',
                    label: 'Datum der letzten Regelprüfung',
                    options: {
                        customBodyRender: (value) => getDate(value),
                    },
                },
                {
                    name: 'einrichtungStandort',
                    label: 'Standort',
                    options: {
                        sort: false,
                        customBodyRender: (value) => getStandort(value),
                    },
                },
                {
                    name: 'ergebnis',
                    label: 'Ergebnis der letzten Regelprüfung',
                    options: {
                        sort: false,
                        customBodyRender: (value) =>
                            value ? (
                                <BadgeColumn content={MAENGEL_ART_LABELS[value]} color={getMaengelBadgeColor(value)} />
                            ) : (
                                '-'
                            ),
                    },
                },
                {
                    name: 'maengel',
                    label: 'Art der Mängel, Gegenstand der Anordnung (Stichworte)',
                    options: {
                        sort: false,
                    },
                },
                {
                    name: 'nachpruefungen',
                    label: 'Daten der Nachprüfung',
                    options: {
                        sort: false,
                        customBodyRender: (value?: Array<string>) => {
                            if (!value?.length) return '-';

                            return (value || []).map((date: string, index: number) => (
                                <ListItem key={index}>
                                    <span>{getDate(date)}</span>
                                </ListItem>
                            ));
                        },
                    },
                },
                {
                    name: 'bemerkung',
                    label: 'Bemerkung',
                    options: {
                        sort: false,
                        customBodyRender: (value) => {
                            return <span dangerouslySetInnerHTML={{ __html: value }} />;
                        },
                    },
                },
                actions(data, 'Regelprüfung'),
            ];
        },
        [actions]
    );
};
