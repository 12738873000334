import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { useApiWohngemeinschaftItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';
import { transform2Date } from 'utilities/hooks/useFormatDate';

interface AbwAnsehenProps {
    pathBuilder: PathBuilderProps;
    title?: string;
}

export const AbwAnsehen = ({ pathBuilder, title }: AbwAnsehenProps) => {
    const { id } = useParams();

    const { isLoading, data, error } = useApiWohngemeinschaftItem(id);

    return (
        <ContentContainer title={`${title || 'Angezeigte Betreuung in Wohngemeinschaften: Ansehen'} ${data?.name}`}>
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading || !data}>
                    <Typography variant="h1">
                        {title || 'Angezeigte Betreuung in Wohngemeinschaften: Ansehen'}
                    </Typography>

                    {!error ? (
                        <BaseGridContainer justifyContent="center">
                            <DetailsLayout.Container>
                                <DetailsLayout.InfoField value={data?.name} label="Name der Wohngemeinschaft" />
                                <DetailsLayout.InfoField value={data?.adresse?.label} label="Hausanschrift" />
                                <DetailsLayout.InfoField
                                    value={data?.beginn ? transform2Date(data?.beginn) : '-'}
                                    label="Datum Beginn betreuende Tätigkeit"
                                />
                                <DetailsLayout.InfoField value={data?.plaetze} label="Anzahl der betreuten Nutzer" />
                                <DetailsLayout.InfoField
                                    value={
                                        data?.ambulanterDienst?.name
                                            ? `${data.ambulanterDienst.name} (${data.ambulanterDienst.nrwKey})`
                                            : undefined
                                    }
                                    label="Ambulanter Dienst"
                                />
                                <DetailsLayout.InfoField
                                    value={
                                        data?.einrichtung?.name
                                            ? `${data?.einrichtung?.name} (${data?.einrichtung?.nrwKey})`
                                            : '<i>Noch nicht zugeordnet</i>'
                                    }
                                    label="Name des Leistungsangebots"
                                />
                            </DetailsLayout.Container>

                            {data?.deleted ? (
                                <>
                                    <Typography variant="h2">Löschung</Typography>

                                    <DetailsLayout.Container>
                                        <DetailsLayout.InfoField
                                            value={data.deleted}
                                            label="Zeitpunkt der Löschung"
                                            isDate
                                        />
                                        {data?.deletedByBenutzer ? (
                                            <DetailsLayout.InfoField
                                                value={`${data?.deletedByBenutzer?.nachname}, ${data?.deletedByBenutzer?.vorname} (${data?.deletedByBenutzer?.email})`}
                                                label="Gelöscht von"
                                            />
                                        ) : null}
                                    </DetailsLayout.Container>
                                </>
                            ) : null}
                        </BaseGridContainer>
                    ) : (
                        <Alert severity="error">Beim Laden der Wohngemeinschaft ist ein Fehler aufgetreten.</Alert>
                    )}

                    <ButtonGroupGrid backLink={{ path: pathBuilder.path || '' }} />
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};
