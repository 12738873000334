export enum STATUS {
    ALL = 'ALL',
    ENTWURF = 'ENTWURF',
    EINGEREICHT = 'EINGEREICHT',
    ERNEUT_EINGEREICHT = 'ERNEUT_EINGEREICHT',
    ZURUECKZIEHEN_BEANTRAGT = 'ZURUECKZIEHEN_BEANTRAGT',
    ZURUECKGEZOGEN = 'ZURUECKGEZOGEN',
    ZURUECKGEWIESEN = 'ZURUECKGEWIESEN',
    ABGEBROCHEN = 'ABGEBROCHEN',
    IN_BEARBEITUNG = 'IN_BEARBEITUNG',
    ZUR_PRUEFUNG_FREIGEGEBEN = 'ZUR_PRUEFUNG_FREIGEGEBEN',
    IN_PRUEFUNG = 'IN_PRUEFUNG',
    FREIGEGEBEN = 'FREIGEGEBEN',
    BESCHIEDEN = 'BESCHIEDEN',
    AUFGEHOBEN = 'AUFGEHOBEN',
    ZUR_KENNTNIS_GENOMMEN = 'ZUR_KENNTNIS_GENOMMEN',
    REGISTERED = 'REGISTERED',
    AUSSTEHEND = 'AUSSTEHEND',
    DELETED = 'DELETED',
}

export const STATUS_LIST: string[] = [
    STATUS.ENTWURF,
    STATUS.EINGEREICHT,
    STATUS.ERNEUT_EINGEREICHT,
    STATUS.ZURUECKGEZOGEN,
    STATUS.ZURUECKZIEHEN_BEANTRAGT,
    STATUS.ABGEBROCHEN,
    STATUS.IN_BEARBEITUNG,
    STATUS.ZUR_PRUEFUNG_FREIGEGEBEN,
    STATUS.IN_PRUEFUNG,
    STATUS.FREIGEGEBEN,
    STATUS.BESCHIEDEN,
    STATUS.AUFGEHOBEN,
    STATUS.ZUR_KENNTNIS_GENOMMEN,
    STATUS.REGISTERED,
    STATUS.DELETED,
];
