import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import { useSpecificRole } from 'api/auth';
import { MissingRequiredFieldsIcon } from 'forms/controls/components/MissingRequiredFieldsIcon';
import { useCollectionItemActions } from 'forms/hooks/useCollectionItemActions';
import { Schema, TableColumn } from 'forms/types/UiSchemaTypes';

import { FormTableActions } from './FormTableActions';
import { FormTableCell } from './FormTableCell';

interface FormTableRowProps {
    index: number;
    rowData: any;
    schema: Schema;
    columns: TableColumn[];
    onView?: (index: number) => void;
    onEdit?: (index: number) => void;
    onDelete?: (index: number) => void;
    onCustomAction?: (index: number, key: string) => void;
    disabled?: boolean;
    deleteAlertText?: (index: number) => string;
    deleteConfirmationLabel?: (index: number) => string;
    viewOnly?: boolean;
    renderActionsColumn?: boolean;
}

export const FormTableRow: React.FC<FormTableRowProps> = ({
    columns,
    rowData,
    schema,
    viewOnly,
    renderActionsColumn,
    ...actionProps
}) => {
    const actions = useCollectionItemActions(schema?.custom?.actions);
    const { isLV } = useSpecificRole();

    return (
        <TableRow>
            <TableCell>{rowData.hasErrors && <MissingRequiredFieldsIcon />}</TableCell>

            {columns.map((col, i) => (
                <FormTableCell key={i} column={col} schema={schema} rowData={rowData} />
            ))}

            {renderActionsColumn !== false ? (
                <TableCell>
                    <FormTableActions
                        viewable={rowData.beschieden || viewOnly}
                        editable={(!rowData.beschieden || isLV) && !viewOnly}
                        deletable={(!rowData.beschieden || isLV) && !viewOnly && schema?.custom?.deleteable !== false}
                        actions={actions}
                        {...actionProps}
                    />
                </TableCell>
            ) : null}
        </TableRow>
    );
};
