import React, { useCallback } from 'react';
import { WarningAmber } from '@mui/icons-material';
import { styled, Tooltip } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { LeistungsangebotEntwurfCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getRowData } from 'components/DataTable/tableUtils';
import { ACTIONS } from 'constants/antragActions';
import { STATUS } from 'constants/antragStatus';
import { ANTRAG_STATUS_LABELS } from 'constants/labels';
import { Link } from 'elements/Link';
import { PathBuilder } from 'navigation/Paths';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

export const useBehoerdeLeistungsangeboteColumns = (
    handleAction: AntragListAction<any>
): ((data: LeistungsangebotEntwurfCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { transform2Date } = useFormatDate();
    const actions = useServerActionsColumn({
        handleAction,
        labelOverrides: { [ACTIONS.EDIT]: { label: 'Freigeben' } },
    });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                {
                    name: 'id',
                    options: {
                        display: false,
                    },
                },
                {
                    name: 'zuletztEingereichtAt',
                    label: 'Registrierungsdatum',
                    options: {
                        customBodyRender: (value) => transform2Date(value),
                    },
                },
                {
                    name: 'einrichtungName',
                    label: 'Name des Leistungsangebots',
                },
                {
                    name: 'erstinbetriebnahme',
                    label: 'Erstinb.',
                    options: {
                        customBodyRender: (value) => transform2Date(value),
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort.',
                },
                {
                    name: 'leistungsanbieter',
                    label: 'Status Leistungsanbieter',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);

                            if (!rowData?.leistungsanbieter) {
                                return '';
                            }

                            if (
                                rowData?.leistungsanbieter?.status &&
                                rowData?.leistungsanbieter?.status !== STATUS.EINGEREICHT
                            ) {
                                return ANTRAG_STATUS_LABELS[rowData.leistungsanbieter.status];
                            }

                            return (
                                <Tooltip title="Der Leistungsanbieter für dieses Angebot wurde noch nicht freigegeben. Klicken Sie hier, um zum Leistungsanbieter zu gelangen.">
                                    <Link
                                        to={PathBuilder.home.aufgaben.freigeben.leistungsanbieter.edit({
                                            id: Number(rowData?.leistungsanbieter?.id),
                                        })}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <LeistungsanbieterWarningIcon /> Eingereicht
                                    </Link>
                                </Tooltip>
                            );
                        },
                    },
                },
                {
                    name: 'leistungsanbieterName',
                    label: 'Leistungsanbieter',
                },
                actions(data),
            ];
        },
        [actions, transform2Date]
    );
};

const LeistungsanbieterWarningIcon = styled(WarningAmber)(({ theme }) => ({
    color: theme.palette.warning.main,
    marginRight: theme.spacing(2),
}));
