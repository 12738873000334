import {control} from 'forms/ui-schemas/elements/control';
import {customVerticalLayout} from 'forms/ui-schemas/elements/customVerticalLayout';
import {formDialog} from 'forms/ui-schemas/elements/formDialog';
import {inlineRowControl} from 'forms/ui-schemas/elements/inlineRowControl';
import {labeled} from 'forms/ui-schemas/elements/labeled';

export const modalWohngemeinschaft = formDialog(
    'Neue Wohngemeinschaft',
    'Wohngemeinschaft bearbeiten',
    labeled({ labelScope: '#/properties/name' }),
    customVerticalLayout(
        [
            inlineRowControl([
                control('name'),
                control('plaetze'),
                control('startDate', {
                    options: {
                        format: 'date',
                    },
                }),
            ]),
            control('adresse'),
        ],
        { spacing: 1 }
    ),
    { scope: '#/properties/wohngemeinschaften', actionKey: null }
);

export const modalFemMassnahme = formDialog(
    'Neue Maßnahme',
    'Maßnahme bearbeiten',
    labeled({ labelScope: '#/properties/gerichtlichCustomMassnahmen' }),
    customVerticalLayout(
        [control('name'), inlineRowControl([control('anzahlPersonen'), control('anzahlQuartal')])],
        { spacing: 1 }
    ),
    { scope: '#/properties/gerichtlichCustomMassnahmen', actionKey: null }
);

export const modalFemMassnahmeEinwilligung = formDialog(
    'Neue Maßnahme',
    'Maßnahme bearbeiten',
    labeled({ labelScope: '#/properties/einwilligungCustomMassnahmen' }),
    customVerticalLayout(
        [control('name'), inlineRowControl([control('anzahlPersonen'), control('anzahlQuartal')])],
        { spacing: 1 }
    ),
    { scope: '#/properties/einwilligungCustomMassnahmen', actionKey: null }
);
