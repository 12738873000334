import React, { useEffect, useState } from 'react';

import { useApiAccountDeaktivieren } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import useQuery from 'utilities/hooks/useQuery';

import { ExceptionMessage } from './components/ExceptionMessage';
import { FailureMessage } from './components/FailureMessage';
import { SuccessMessage } from './components/SuccessMessage';

type StatusTypes = 'success' | 'exception' | 'error';

export const AccountDeactivate: React.FC = () => {
    const query = useQuery();
    const token = query.get('h');

    const { isLoading, error } = useApiAccountDeaktivieren(token || '');
    const [status, setStatus] = useState<StatusTypes>('success');

    useEffect(() => {
        switch (true) {
            case error?.response?.status === 500:
                setStatus('exception');
                break;
            case !!error:
                setStatus('error');
                break;
        }
    }, [error]);

    return (
        <ContentContainer title="E-Mail-Adresse verifizieren">
            <AppLoaderContainer isLoading={isLoading}>
                {status === 'error' && <FailureMessage />}
                {status === 'exception' && <ExceptionMessage />}
                {status === 'success' && <SuccessMessage />}
            </AppLoaderContainer>
        </ContentContainer>
    );
};
