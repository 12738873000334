import React, { useMemo, useState } from 'react';

import { backendApiService } from 'api/ApiService';
import { GeneratedFile } from 'api/types';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { buildRelativePath, PATH_PLATZZAHLMELDER, PATH_VERWALTUNG } from 'navigation/Paths';
import { useClipboard } from 'utilities/hooks/useClipboard';

interface UsePlatzmelderTokenLinkResult {
    tokenUrl: string;
    generatedFile: GeneratedFile | null;
    isSubmitting: boolean;
    handleFileExport(): void;
    handleCopyLink(): void;
    setGeneratedFile: React.Dispatch<React.SetStateAction<GeneratedFile | null>>;
}

interface UsePlatzmelderTokenLink {
    currentToken?: string;
}

export const usePlatzmelderTokenLink = ({ currentToken }: UsePlatzmelderTokenLink): UsePlatzmelderTokenLinkResult => {
    const { setRequestMessage, submitStart, submitEnd, isSubmitting } = useFormState();
    const { copyTextToClipBoard } = useClipboard();

    const [generatedFile, setGeneratedFile] = useState<GeneratedFile | null>(null);

    const handleCopyLink = () => {
        copyTextToClipBoard(tokenUrl)
            .then(() => {
                setRequestMessage(successMessage('URL wurde kopiert!'));
            })
            .catch(() => {
                setRequestMessage(errorMessage('URL konnte nicht kopiert werden'));
            });
    };

    const handleFileExport = () => {
        submitStart();

        backendApiService
            .getPlatzzahlmelderLinkFile()
            .then((res) => setGeneratedFile(res))
            .catch(() => setRequestMessage(errorMessage('Beim Generieren der Link-Datei ist ein Fehler aufgetreten.')))
            .finally(submitEnd);
    };

    const baseUrl = useMemo(() => {
        if (!window) {
            return '';
        }

        const {
            location: { protocol, hostname },
        } = window;

        return `${protocol}//${hostname}`;
    }, []);

    const tokenUrl = useMemo(
        () => buildRelativePath(baseUrl, PATH_VERWALTUNG, PATH_PLATZZAHLMELDER, currentToken),
        [baseUrl, currentToken]
    );

    return {
        tokenUrl,
        generatedFile,
        isSubmitting,
        setGeneratedFile,
        handleFileExport,
        handleCopyLink,
    };
};
