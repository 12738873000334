import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import {
    PATH_ANTRAG,
    PATH_DELETE,
    PATH_EDIT,
    PATH_INVEST_REGISTRATION_SIGNUP,
    PATH_LEISTUNGSANGEBOT_REGISTRIEREN,
    PATH_LEISTUNGSANGEBOTE,
    PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
    PATH_MELDUNG,
    PATH_NACHRICHTEN,
    PATH_ZUGRIFFSRECHTE,
    PathBuilder,
    useRedirectSegment,
} from 'navigation/Paths';
import { Error404Page } from 'pages/Error404';
import { LeistungsangeboteAnbieterNachrichtCreatePage } from 'pages/Leistungsangebote/anbieter/LeistungsangeboteAnbieterNachrichtCreatePage';
import { LeistungsangeboteMeldungDetails } from 'pages/Leistungsangebote/anbieter/LeistungsangeboteMeldungDetails';
import { LeistungsangeboteNachrichtDetailsPage } from 'pages/Leistungsangebote/anbieter/LeistungsangeboteNachrichtDetailsPage';
import { LeistungsangeboteNachrichtenOverview } from 'pages/Leistungsangebote/anbieter/LeistungsangeboteNachrichtenOverview';
import { LeistungsangebotRegistrierenPage } from 'pages/Leistungsangebote/anbieter/LeistungsangebotRegistrierenPage';
import { LeistungsangeboteBehoerdeNachrichtCreatePage } from 'pages/Leistungsangebote/behoerde/LeistungsangeboteBehoerdeNachrichtCreatePage';
import { LeistungsangeboteBehoerdeNachrichtDetailsPage } from 'pages/Leistungsangebote/behoerde/LeistungsangeboteBehoerdeNachrichtDetailsPage';
import { LeistungsangeboteBehoerdeNachrichtenOverview } from 'pages/Leistungsangebote/behoerde/LeistungsangeboteBehoerdeNachrichtenOverview';
import { LeistungsangeboteBehoerdeMeldungDetails } from 'pages/Leistungsangebote/behoerde/LeistungsangeboteMeldungDetails';
import { FreigegebeneAnbieterDetailPage } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneAnbieterDetailPage';
import { FreigegebeneAufforderung } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneAufforderung';
import { FreigegebeneBehoerdeDetailPage } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneBehoerdeDetailPage';
import { FreigegebeneCreateEditPage } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneCreateEditPage';
import { FreigegebeneDeletionReasonPage } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneDeletionReasonPage';
import { FreigegebeneEditPage } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneEditPage';
import { FreigegebeneInvestSignUp } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneInvestSignUp';
import { FreigegebeneOverview } from 'pages/Leistungsangebote/Freigegebene/FreigegebeneOverview';
import { LeistungsangeboteOverview } from 'pages/Leistungsangebote/LeistungsangeboteOverview';
import { ZugriffsrechtePage } from 'pages/Verwaltung/Zugriffsrechte/ZugriffsrechtePage';

export const LeistungsangeboteRoutes = () => {
    const { SentryRoutes } = useSentry();
    const { leistungsangeboteFirstStep } = useRedirectSegment();

    return (
        <SentryRoutes>
            <Route path={PATH_LEISTUNGSANGEBOTE}>
                <Route path={PATH_LEISTUNGSANGEBOT_REGISTRIEREN}>
                    <Route path=":antragId">
                        <Route path=":step" element={<LeistungsangebotRegistrierenPage />} />
                        <Route index element={<Navigate to={leistungsangeboteFirstStep} replace />} />
                    </Route>

                    <Route index element={<LeistungsangebotRegistrierenPage />} />
                </Route>

                <Route path={PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE}>
                    <Route path=":einrichtungId">
                        <Route path={PATH_EDIT}>
                            <Route path={PATH_ANTRAG}>
                                <Route path=":antragId">
                                    <Route path=":step" element={<FreigegebeneEditPage />} />
                                    <Route index element={<Navigate to="basisdaten" replace />} />
                                </Route>
                                <Route
                                    index
                                    element={
                                        <Navigate
                                            to={PathBuilder.home.verwaltung.leistungsangebote.freigegeben.path}
                                            replace
                                        />
                                    }
                                />
                            </Route>

                            <Route index element={<FreigegebeneCreateEditPage />} />
                        </Route>

                        <Route path={PATH_MELDUNG}>
                            <Route path=":id" element={<LeistungsangeboteBehoerdeMeldungDetails />} />
                        </Route>

                        <Route path={PATH_DELETE} element={<FreigegebeneDeletionReasonPage />} />
                        <Route path={PATH_INVEST_REGISTRATION_SIGNUP} element={<FreigegebeneInvestSignUp />} />

                        <Route path={PATH_NACHRICHTEN}>
                            <Route path=":id">
                                <Route path=":step" element={<LeistungsangeboteBehoerdeNachrichtCreatePage />} />
                                <Route index element={<LeistungsangeboteBehoerdeNachrichtDetailsPage />} />
                            </Route>

                            <Route index element={<LeistungsangeboteBehoerdeNachrichtenOverview />} />
                        </Route>

                        <Route path={PATH_ZUGRIFFSRECHTE}>
                            <Route index element={<ZugriffsrechtePage />} />
                        </Route>

                        <Route path={PATH_ANTRAG}>
                            <Route path=":antragId">
                                <Route path=":step" element={<FreigegebeneAufforderung />} />
                            </Route>
                        </Route>

                        <Route index element={<FreigegebeneBehoerdeDetailPage />} />
                    </Route>

                    <Route index element={<FreigegebeneOverview />} />
                </Route>

                <Route path=":einrichtungId">
                    <Route path={PATH_MELDUNG}>
                        <Route path=":id" element={<LeistungsangeboteMeldungDetails />} />
                    </Route>

                    <Route path={PATH_ZUGRIFFSRECHTE}>
                        <Route index element={<ZugriffsrechtePage />} />
                    </Route>

                    <Route path={PATH_NACHRICHTEN}>
                        <Route path=":id">
                            <Route path=":step" element={<LeistungsangeboteAnbieterNachrichtCreatePage />} />
                            <Route index element={<LeistungsangeboteNachrichtDetailsPage />} />
                        </Route>

                        <Route index element={<LeistungsangeboteNachrichtenOverview />} />
                    </Route>

                    <Route index element={<FreigegebeneAnbieterDetailPage />} />
                </Route>

                <Route index element={<LeistungsangeboteOverview />} />

                <Route path="*" element={<Error404Page />} />
            </Route>
        </SentryRoutes>
    );
};
