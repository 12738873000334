import { useSpecificRole } from 'api/auth/useHasRole';

import { behoerdeLeistungsangeboteActions } from './subPaths/behoerdeLeistungsangeboteActions';

export const PATH_HOME = '/';
export const PATH_ANTRAG_AUFFORDERUNG = 'aufforderung';
export const PATH_REGISTRIERUNG = 'registrierung';
export const PATH_REGISTRIERUNG_SUCCESS = 'erfolgreich';
export const PATH_REGISTRIERUNG_EMAIL_VERIFY = 'email-verifizieren';
export const PATH_HILFE = 'hilfe';
export const PATH_DATENSCHUTZ = 'datenschutz';
export const PATH_IMPRESSUM = 'impressum';
export const PATH_BARRIEREFREIHEIT = 'barrierefreiheit';
export const PATH_DASHBOARD = 'dashboard';
export const PATH_STATISTIK = 'statistik';
export const PATH_VERWALTUNG = 'verwaltung';
export const PATH_AUFGABEN = 'aufgaben';
export const PATH_AUSWERTUNG = 'auswertung';

export const PATH_EINRICHTUNGEN = 'einrichtungen';
export const PATH_CREATE = 'erstellen';
export const PATH_ADD = 'hinzufügen';
export const PATH_EDIT = 'bearbeiten';
export const PATH_DELETE = 'loeschen';
export const PATH_VIEW = 'ansehen';

export const PATH_STEP_BASISDATEN = 'basisdaten';
export const PATH_STEP_ZUSAMMENFASSUNG = 'zusammenfassung';

export const PATH_ZUORDNUNG_MANUELL = 'manuelle-zuordnung';
export const PATH_ZUORDNUNG_TREFFER = 'treffer-zuordnen';
export const PATH_LEISTUNGSANGEBOT_ERSTELLEN = 'leistungsangebot-erstellen';
export const PATH_ANTRAG = 'formular';
export const PATH_MELDUNG_AUFGEFORDERT = 'aufgefordert';
export const PATH_MELDUNG_LABS_OHNE_MELDUNG = 'leistungsangebote-ohne-meldung';
export const PATH_MELDUNG_ZURUECKGEBEN = 'zurueckweisen';

export const PATH_BENUTZER_EINRICHTUNGEN = 'zugehoerige-einrichtungen';

export const PATH_LEISTUNGSANGEBOTE = 'leistungsangebote';
export const PATH_LEISTUNGSANGEBOT_REGISTRIEREN = 'registrieren';
export const PATH_LEISTUNGSANGEBOTE_FREIGEBEN = 'freigeben';
export const PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE = 'freigegebene';

export const PATH_ZUGRIFFSRECHTE = 'zugriffsrechte';

export const PATH_LEISTUNGSANBIETER = 'leistungsanbieter';
export const PATH_LEISTUNGSANBIETER_VIEW = 'ansehen';
export const PATH_LEISTUNGSANBIETER_FREIGEBEN = 'freigeben';
export const PATH_LEISTUNGSANBIETER_ZUSAMMENFASSUNG = 'zusammenfassung';

export const PATH_MELDUNG = 'meldungen';
export const PATH_MELDUNG_FREIGEBEN = 'freigeben';
export const PATH_MELDUNG_FREIGEGEBENE = 'freigegebene';
export const PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT = 'ohne-zustaendigkeit';

export const PATH_TRAEGER = 'traeger';
export const PATH_TRAEGERVERBAND_UEBERSICHT = 'verbaende';

export const PATH_MANDANT = 'mandant';
export const PATH_MANDANT_BEHOERDE = 'behoerde';
export const PATH_MANDANT_LV = 'landschaftsverband';

export const PATH_BEHOERDEN = 'behoerden';

export const PATH_BEHOERDEN_EDIT = 'bearbeiten';
export const PATH_LANDSCHAFTSVERBAND = 'landschaftsverbaende';
export const PATH_LANDSCHAFTSVERBAND_EDIT = 'bearbeiten';

export const PATH_PLATZZAHLMELDER = 'platzzahlmelder';
export const PATH_PLATZZAHLMELDER_MELDUNG = 'meldung';
export const PATH_PLATZZAHLMELDER_ANTRAG = 'antrag';
export const PATH_PLATZZAHLMELDER_HISTORY = 'historie';
export const PATH_PLATZZAHLMELDER_VIEW = 'ansehen';

export const PATH_PROFIL = 'profile';
export const PATH_PROFIL_BENUTZERDATEN = 'benutzerdaten';
export const PATH_PROFIL_PASSWORT_RESET = 'passwort-zuruecksetzen';
export const PATH_PROFIL_ACCOUNT_DEACTIVATE = 'account-deaktivieren';
export const PATH_PROFIL_EMAIL_CONFIRM = 'email-bestaetigen';

export const PATH_NACHRICHTEN = 'nachrichten';
export const PATH_NACHRICHTEN_ANSEHEN = 'ansehen';

export const PATH_FEM = 'freiheitsentziehende-massnahmen';

export const PATH_DELETED_OBJECTS = 'geloeschte-objekte';
export const PATH_INVEST_REGISTRATION = 'invest';
export const PATH_INVEST_REGISTRATION_SIGNUP = 'invest-anmeldung';
export const PATH_INVEST_REGISTRATION_FAILED = 'fehlgeschlagene';

export const PATH_MASSENMAIL = 'massenmails';

export const PATH_EMAIL_TEXT = 'email-text';
export const PATH_EMAIL_SIGNATUR = 'email-signatur';

export const PATH_MELDUNG_HINWEISE = 'meldungen-hinweise';
export const PATH_MELDUNG_HINWEIS_TEXT = 'text';
export const PATH_MELDUNG_HINWEIS_DOCUMENT = 'dokument';
export const PATH_DOKUMENT_PRUEFUNG = 'dokumente-nach-pruefung';

export const PATH_ABW = 'angezeigte-betreuung-wohngemeinschaft';

export const PATH_REGELPRUEFUNG = 'regelpruefung';
export const PATH_PRUEFBERICHTE = 'pruefberichte-regelpruefungen';
export const PATH_BENUTZER = 'benutzer';

export const PATH_AUSWERTUNG_OVERVIEW = 'exporte';
export const PATH_AUSWERTUNG_P44 = '44-v-wtg';
export const PATH_AUSWERTUNG_STATISTIK = 'statistik';
export const PATH_AUSWERTUNG_STATISTIK_FEM = 'statistik-fem';

export const PATH_PROFIL_HEIMFINDER = 'profil-heimfinder';

export const buildRelativePath = (...paths: (number | string | undefined | null)[]): string =>
    paths.filter((p) => p).join('/');

export const buildPath = (...paths: (number | string | undefined | null)[]): string => {
    return PATH_HOME + buildRelativePath(...paths);
};

// ToDo Refactoring dieses Hooks zur Verwendung in der Path Function
export const useLeistungsangebotSegment = () => {
    const { isAnbieter, isBehoerde, isSupport } = useSpecificRole();

    switch (true) {
        case isAnbieter:
            return PATH_LEISTUNGSANGEBOT_REGISTRIEREN;
        case isBehoerde || isSupport:
            return PATH_LEISTUNGSANGEBOTE_FREIGEBEN;
        default:
            return undefined;
    }
};

export const useRedirectSegment = () => {
    const { isBehoerde } = useSpecificRole();
    return {
        meldungFirstStep: isBehoerde ? PATH_STEP_ZUSAMMENFASSUNG : 'hinweise',
        meldungOhneZustaendigkeitFirstStep: PATH_STEP_ZUSAMMENFASSUNG,
        leistungsangeboteFirstStep: isBehoerde ? PATH_STEP_ZUSAMMENFASSUNG : PATH_STEP_BASISDATEN,
        abwLeistungsangeboteFirstStep: isBehoerde ? PATH_STEP_BASISDATEN : PATH_STEP_ZUSAMMENFASSUNG,
        leistungsanbieterFirstStep: isBehoerde ? PATH_STEP_BASISDATEN : PATH_STEP_ZUSAMMENFASSUNG,
        platzmelderFirstStep: isBehoerde ? 'ansehen' : 'bearbeiten',
        platzmelderAufforderungFirstStep: 'aufforderung',
        femAntragFirstStep: isBehoerde ? 'aufforderung' : 'gerichtlich',
        regelPruefungAntragFirstStep: 'einreichen',
    };
};

type PathBuilderEditOptions = {
    id: string | number;
    segment?: string;
    step?: string;
    token?: string;
};

type PathBuilderAntragOptions = { einrichtungId: number; antragId: number };

export type PathBuilderProps = {
    path?: string;
    overview?: ({ einrichtungId }: { einrichtungId: number }) => string;
    antragView?: ({ einrichtungId, antragId }: PathBuilderAntragOptions) => string;
    create_nachricht?: ({ einrichtungId, antragId }: PathBuilderAntragOptions) => string;
    nachrichten?: ({ einrichtungId }: Pick<PathBuilderAntragOptions, 'einrichtungId'>) => string;
    antrag?: ({ einrichtungId, antragId }: PathBuilderAntragOptions) => string;
    view?: ({ id, segment, token }: PathBuilderEditOptions) => string;
    edit?: ({ id, segment, token, step }: PathBuilderEditOptions) => string;
};

export const PathBuilder = {
    home: {
        path: PATH_HOME,
        registrierung: {
            path: buildPath(PATH_REGISTRIERUNG),
            success: buildPath(PATH_REGISTRIERUNG, PATH_REGISTRIERUNG_SUCCESS),
            verifyEmail: buildPath(PATH_REGISTRIERUNG, PATH_REGISTRIERUNG_EMAIL_VERIFY),
        },
        hilfe: {
            path: buildPath(PATH_HILFE),
        },
        datenschutz: {
            path: buildPath(PATH_DATENSCHUTZ),
        },
        impressum: {
            path: buildPath(PATH_IMPRESSUM),
        },
        barrierefreiheit: {
            path: buildPath(PATH_BARRIEREFREIHEIT),
        },
        dashboard: {
            path: buildPath(PATH_DASHBOARD),
        },
        statistik: {
            path: buildPath(PATH_STATISTIK),
        },
        nachrichten: {
            path: buildPath(PATH_NACHRICHTEN),
            view: ({ id }: { id: string | number }) => buildPath(PATH_NACHRICHTEN, id, PATH_NACHRICHTEN_ANSEHEN),
        },
        aufgaben: {
            freigeben: {
                leistungsangebote: {
                    path: buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOTE_FREIGEBEN),
                    view: ({ id }: { id: string | number }) =>
                        buildPath(
                            PATH_AUFGABEN,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEBEN,
                            id,
                            PATH_STEP_BASISDATEN
                        ),
                    edit: ({ id, step }: { id: string | number; step?: string }) =>
                        buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOTE_FREIGEBEN, id, step),
                    overview: () => buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOTE_FREIGEBEN),
                    summary: (id: string, segment: string | undefined) =>
                        buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANGEBOTE, segment, id, PATH_STEP_ZUSAMMENFASSUNG),
                },
                leistungsanbieter: {
                    path: buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANBIETER, PATH_LEISTUNGSANBIETER_FREIGEBEN),
                    overview: () => buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANBIETER, PATH_LEISTUNGSANBIETER_FREIGEBEN),
                    edit: ({ id, step }: { id: number | string; step?: string }) =>
                        buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANBIETER, PATH_LEISTUNGSANBIETER_FREIGEBEN, id, step),
                    view: ({ id }: { id: string | number }) =>
                        buildPath(PATH_AUFGABEN, PATH_LEISTUNGSANBIETER, PATH_LEISTUNGSANBIETER_FREIGEBEN, id),
                    summary: (id?: string) =>
                        buildPath(
                            PATH_AUFGABEN,
                            PATH_LEISTUNGSANBIETER,
                            PATH_LEISTUNGSANBIETER_FREIGEBEN,
                            id,
                            PATH_LEISTUNGSANBIETER_ZUSAMMENFASSUNG
                        ),
                },
                meldungen: {
                    path: buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_FREIGEBEN),
                    antrag: ({ einrichtungId, antragId }: { einrichtungId?: number; antragId: number }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_FREIGEBEN, einrichtungId, antragId),
                    antragView: ({ einrichtungId, antragId }: { einrichtungId?: number; antragId: number }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_FREIGEBEN, einrichtungId, antragId),
                    edit: ({ id, step }: { id: string | number; step?: string }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_FREIGEBEN, id, step),
                    view: ({ id }: { id: string | number }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_FREIGEBEN, id),
                    overview: ({ einrichtungId }: { einrichtungId: number }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_FREIGEBEN, einrichtungId),
                    zurueckgeben: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                        buildPath(
                            PATH_AUFGABEN,
                            PATH_MELDUNG,
                            PATH_MELDUNG_FREIGEBEN,
                            einrichtungId,
                            PATH_ANTRAG,
                            antragId,
                            PATH_MELDUNG_ZURUECKGEBEN
                        ),
                },
            },
            abw: {
                path: buildPath(PATH_AUFGABEN, PATH_ABW),
                view: ({ id }: { id: string | number }) => buildPath(PATH_AUFGABEN, PATH_ABW, id),
                edit: ({ id }: { id: string | number }) => {
                    return buildPath(PATH_AUFGABEN, PATH_ABW, id, PATH_EDIT);
                },
                antrag: ({
                    einrichtungId,
                    antragId,
                    step,
                }: {
                    einrichtungId?: number;
                    antragId?: number;
                    step?: string;
                }) =>
                    buildPath(PATH_AUFGABEN, PATH_ABW, einrichtungId, PATH_LEISTUNGSANGEBOT_ERSTELLEN, antragId, step),
                overview: () => buildPath(PATH_AUFGABEN, PATH_ABW),
                zuordnung: ({ id }: { id: string | number }) =>
                    buildPath(PATH_AUFGABEN, PATH_ABW, id, PATH_ZUORDNUNG_MANUELL),
                treffer: ({ id }: { id: string | number }) =>
                    buildPath(PATH_AUFGABEN, PATH_ABW, id, PATH_ZUORDNUNG_TREFFER),
            },
            meldungen: {
                labsOhneMeldung: behoerdeLeistungsangeboteActions(
                    PATH_AUFGABEN,
                    PATH_MELDUNG,
                    PATH_MELDUNG_LABS_OHNE_MELDUNG
                ),
                aufgefordert: behoerdeLeistungsangeboteActions(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_AUFGEFORDERT),
                ohneZustaendigkeit: {
                    path: buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT),
                    antragView: ({ antragId }: { antragId: number }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT, antragId, PATH_VIEW),
                    overview: () => buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT),
                    edit: ({ id, step }: { id: string | number; step?: string }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT, id, step),
                    view: ({ id }: { id: string | number; step?: string }) =>
                        buildPath(PATH_AUFGABEN, PATH_MELDUNG, PATH_MELDUNG_OHNE_ZUSTAENDIGKEIT, id),
                },
            },
            deletedObjects: {
                path: buildPath(PATH_AUFGABEN, PATH_DELETED_OBJECTS),
                view: ({ id, segment }: { id: string | number; segment?: string }) =>
                    buildPath(PATH_AUFGABEN, PATH_DELETED_OBJECTS, segment, id, PATH_VIEW),
            },
        },
        verwaltung: {
            benutzer: {
                path: buildPath(PATH_VERWALTUNG, PATH_BENUTZER),
                create: buildPath(PATH_VERWALTUNG, PATH_BENUTZER, PATH_CREATE),
                view: ({ id }: { id?: string | number }) => buildPath(PATH_VERWALTUNG, PATH_BENUTZER, id, PATH_VIEW),
                edit: ({ id }: { id?: string | number }) => buildPath(PATH_VERWALTUNG, PATH_BENUTZER, id, PATH_EDIT),
                einrichtungen: ({ id }: { id?: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_BENUTZER, id, PATH_BENUTZER_EINRICHTUNGEN),
            },
            platzmelder: {
                path: buildPath(PATH_VERWALTUNG, PATH_PLATZZAHLMELDER),
                view: ({ id, token, segment }: { id?: number | string | null; token?: string; segment?: string }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_PLATZZAHLMELDER,
                        token,
                        segment,
                        PATH_PLATZZAHLMELDER_MELDUNG,
                        id,
                        PATH_PLATZZAHLMELDER_VIEW
                    ),
                edit: ({ id, step, token }: { id: string | number; step?: string; token?: string }) =>
                    buildPath(PATH_VERWALTUNG, PATH_PLATZZAHLMELDER, token, PATH_PLATZZAHLMELDER_MELDUNG, id, step),
                aufforderung: (token: string, id: string | number) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_PLATZZAHLMELDER,
                        token,
                        PATH_PLATZZAHLMELDER_ANTRAG,
                        id,
                        PATH_ANTRAG_AUFFORDERUNG
                    ),
                history: (token: string, id: string | number) =>
                    buildPath(PATH_VERWALTUNG, PATH_PLATZZAHLMELDER, token, PATH_PLATZZAHLMELDER_HISTORY, id),
                token: (token: string) => buildPath(PATH_VERWALTUNG, PATH_PLATZZAHLMELDER, token),
            },
            meldung: {
                path: buildPath(PATH_VERWALTUNG, PATH_MELDUNG),
                antrag: ({ antragId }: { antragId: number }) => buildPath(PATH_VERWALTUNG, PATH_MELDUNG, antragId),
                edit: ({ id, step }: { id: string | number; step?: string }) =>
                    buildPath(PATH_VERWALTUNG, PATH_MELDUNG, id, step),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_MELDUNG, id),
                overview: () => buildPath(PATH_VERWALTUNG, PATH_MELDUNG),
                antragView: ({ antragId }: { antragId: number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_MELDUNG, PATH_MELDUNG_FREIGEGEBENE, antragId),
                aufforderung: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_MELDUNG,
                        einrichtungId,
                        PATH_ANTRAG,
                        antragId,
                        PATH_ANTRAG_AUFFORDERUNG
                    ),
            },
            landschaftsverband: {
                path: buildPath(PATH_VERWALTUNG, PATH_LANDSCHAFTSVERBAND),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_LANDSCHAFTSVERBAND, id),
                edit: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_LANDSCHAFTSVERBAND, id, PATH_LANDSCHAFTSVERBAND_EDIT),
            },
            mandant: {
                behoerde: {
                    edit: buildPath(PATH_VERWALTUNG, PATH_MANDANT, PATH_MANDANT_BEHOERDE, PATH_EDIT),
                },
                lv: {
                    edit: buildPath(PATH_VERWALTUNG, PATH_MANDANT, PATH_MANDANT_LV, PATH_EDIT),
                },
            },
            behoerden: {
                path: buildPath(PATH_VERWALTUNG, PATH_BEHOERDEN),
                edit: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_BEHOERDEN, id, PATH_BEHOERDEN_EDIT),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_BEHOERDEN, id),
            },
            traeger: {
                path: buildPath(PATH_VERWALTUNG, PATH_TRAEGER),
                create: buildPath(PATH_VERWALTUNG, PATH_TRAEGER, PATH_CREATE),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_TRAEGER, id),
                edit: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_TRAEGER, id, PATH_EDIT),
                mandantView: buildPath(PATH_VERWALTUNG, PATH_TRAEGER, PATH_VIEW),
            },
            traegerverband: {
                path: buildPath(PATH_VERWALTUNG, PATH_TRAEGERVERBAND_UEBERSICHT),
                view: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_TRAEGERVERBAND_UEBERSICHT, id, PATH_VIEW),
                edit: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_TRAEGERVERBAND_UEBERSICHT, id, PATH_EDIT),
                create: buildPath(PATH_VERWALTUNG, PATH_TRAEGERVERBAND_UEBERSICHT, PATH_CREATE),
            },
            leistungsangebote: {
                path: buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE),
                edit: ({ id, segment = undefined, step }: { id: string | number; segment?: string; step?: string }) =>
                    buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, segment, id, step),
                target: (id: number, segment?: string) =>
                    buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, segment, id),
                view: () => buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE),
                antrag: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_LEISTUNGSANGEBOTE,
                        PATH_LEISTUNGSANGEBOT_REGISTRIEREN,
                        einrichtungId,
                        antragId
                    ),
                meldung: {
                    antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                        buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, einrichtungId, PATH_MELDUNG, antragId),
                    overview: ({ einrichtungId }: { einrichtungId: number }) =>
                        buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, einrichtungId),
                    view: ({ id }: { id: string | number; step?: string }) =>
                        buildPath(PATH_VERWALTUNG, PATH_MELDUNG, id),
                },
                anbieter: {
                    entwuerfe: {
                        path: buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE),
                        edit: ({
                            id,
                            segment = undefined,
                            step,
                        }: {
                            id: string | number;
                            segment?: string;
                            step?: string;
                        }) => buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, segment, id, step),
                        view: () => buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE),
                    },
                    freigegebene: {
                        path: buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE),
                        edit: ({
                            id,
                            segment = undefined,
                            step,
                        }: {
                            id: string | number;
                            segment?: string;
                            step?: string;
                        }) => buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, segment, id, step),
                        view: ({ id }: { id: string | number }) =>
                            buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, id),
                        antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                            buildPath(
                                PATH_VERWALTUNG,
                                PATH_LEISTUNGSANGEBOTE,
                                einrichtungId,
                                PATH_NACHRICHTEN,
                                antragId
                            ),
                        create_nachricht: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                            buildPath(
                                PATH_VERWALTUNG,
                                PATH_LEISTUNGSANGEBOTE,
                                einrichtungId,
                                PATH_NACHRICHTEN,
                                antragId,
                                PATH_ANTRAG_AUFFORDERUNG
                            ),
                        deletionReason: ({ id }: { id?: string | number }) =>
                            buildPath(
                                PATH_VERWALTUNG,
                                PATH_LEISTUNGSANGEBOTE,
                                PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                                id,
                                PATH_DELETE
                            ),
                        investRegistrationSignUp: ({ id }: { id?: string | number }) =>
                            buildPath(
                                PATH_VERWALTUNG,
                                PATH_LEISTUNGSANGEBOTE,
                                PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                                id,
                                PATH_INVEST_REGISTRATION_SIGNUP
                            ),
                        nachrichten: ({ einrichtungId }: { einrichtungId: number }) =>
                            buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, einrichtungId, PATH_NACHRICHTEN),
                        zugriffsrechte: ({ einrichtungId }: { einrichtungId: number }) =>
                            buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, einrichtungId, PATH_ZUGRIFFSRECHTE),
                    },
                },
                registrieren: {
                    path: buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOT_REGISTRIEREN),
                },
                freigegeben: {
                    antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            einrichtungId,
                            PATH_NACHRICHTEN,
                            antragId
                        ),
                    view: ({ id }: { id: string | number }) =>
                        buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE, id),
                    edit: ({ id }: { id: string | number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            id,
                            PATH_EDIT
                        ),
                    antrag: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            einrichtungId,
                            PATH_EDIT,
                            PATH_ANTRAG,
                            antragId
                        ),
                    path: buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE),
                    deletionReason: ({ id }: { id?: string | number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            id,
                            PATH_DELETE
                        ),
                    meldung: {
                        antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                            buildPath(
                                PATH_VERWALTUNG,
                                PATH_LEISTUNGSANGEBOTE,
                                PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                                einrichtungId,
                                PATH_MELDUNG,
                                antragId
                            ),
                        overview: ({ einrichtungId }: { einrichtungId: number }) =>
                            buildPath(
                                PATH_VERWALTUNG,
                                PATH_LEISTUNGSANGEBOTE,
                                PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                                einrichtungId
                            ),
                    },
                    overview: () =>
                        buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE),
                    zugriffsrechte: ({ einrichtungId }: { einrichtungId: number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            einrichtungId,
                            PATH_ZUGRIFFSRECHTE
                        ),
                    create_nachricht: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            einrichtungId,
                            PATH_NACHRICHTEN,
                            antragId,
                            PATH_ANTRAG_AUFFORDERUNG
                        ),
                    nachrichten: ({ einrichtungId }: { einrichtungId: number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            einrichtungId,
                            PATH_NACHRICHTEN
                        ),
                    aufforderung: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                        buildPath(
                            PATH_VERWALTUNG,
                            PATH_LEISTUNGSANGEBOTE,
                            PATH_LEISTUNGSANGEBOTE_FREIGEGEBENE,
                            einrichtungId,
                            PATH_ANTRAG,
                            antragId,
                            PATH_ANTRAG_AUFFORDERUNG
                        ),
                },
                summary: (id: string, segment: string | undefined) =>
                    buildPath(PATH_VERWALTUNG, PATH_LEISTUNGSANGEBOTE, segment, id, PATH_STEP_ZUSAMMENFASSUNG),
            },
            femAntrag: {
                path: buildPath(PATH_VERWALTUNG, PATH_FEM, PATH_EINRICHTUNGEN),
                edit: ({ id, step }: { id: string | number; step?: string }) =>
                    buildPath(PATH_VERWALTUNG, PATH_FEM, id, step),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_FEM, PATH_EINRICHTUNGEN, id),
                overview: ({ einrichtungId }: { einrichtungId: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_FEM, PATH_EINRICHTUNGEN, einrichtungId),
                antrag: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_FEM, PATH_EINRICHTUNGEN, einrichtungId, PATH_ANTRAG, antragId),
                aufforderung: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_FEM,
                        PATH_EINRICHTUNGEN,
                        einrichtungId,
                        PATH_ANTRAG,
                        antragId,
                        PATH_ANTRAG_AUFFORDERUNG
                    ),
                antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_FEM,
                        PATH_EINRICHTUNGEN,
                        einrichtungId,
                        PATH_ANTRAG,
                        antragId,
                        PATH_VIEW
                    ),
            },
            investRegistrations: {
                path: buildPath(PATH_VERWALTUNG, PATH_INVEST_REGISTRATION, PATH_INVEST_REGISTRATION_FAILED),
                view: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_INVEST_REGISTRATION, PATH_INVEST_REGISTRATION_FAILED, id),
            },
            deletedObjects: {
                path: buildPath(PATH_VERWALTUNG, PATH_DELETED_OBJECTS),
                view: ({ id, segment }: { id: string | number; segment?: string }) =>
                    buildPath(PATH_VERWALTUNG, PATH_DELETED_OBJECTS, segment, id, PATH_VIEW),
            },
            massenmail: {
                path: buildPath(PATH_VERWALTUNG, PATH_MASSENMAIL),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_MASSENMAIL, id),
                create: buildPath(PATH_VERWALTUNG, PATH_MASSENMAIL, PATH_CREATE),
            },
            emailText: {
                path: buildPath(PATH_VERWALTUNG, PATH_EMAIL_TEXT),
            },
            emailSignatur: {
                path: buildPath(PATH_VERWALTUNG, PATH_EMAIL_SIGNATUR, PATH_EDIT),
            },
            meldungHinweise: {
                path: buildPath(PATH_VERWALTUNG, PATH_MELDUNG_HINWEISE),
                create: buildPath(PATH_VERWALTUNG, PATH_MELDUNG_HINWEISE, PATH_MELDUNG_HINWEIS_TEXT, PATH_CREATE),
                view: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_MELDUNG_HINWEISE, PATH_MELDUNG_HINWEIS_TEXT, id, PATH_VIEW),
                edit: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_MELDUNG_HINWEISE, PATH_MELDUNG_HINWEIS_TEXT, id, PATH_EDIT),
                document: {
                    add: buildPath(PATH_VERWALTUNG, PATH_MELDUNG_HINWEISE, PATH_MELDUNG_HINWEIS_DOCUMENT, PATH_ADD),
                    view: ({ id }: { id: string | number }) =>
                        buildPath(PATH_VERWALTUNG, PATH_MELDUNG_HINWEISE, PATH_MELDUNG_HINWEIS_DOCUMENT, id, PATH_VIEW),
                },
            },
            documentVerification: {
                path: buildPath(PATH_VERWALTUNG, PATH_DOKUMENT_PRUEFUNG),
                add: buildPath(PATH_VERWALTUNG, PATH_DOKUMENT_PRUEFUNG, PATH_ADD),
                view: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_DOKUMENT_PRUEFUNG, id, PATH_VIEW),
            },
            abw: {
                path: buildPath(PATH_VERWALTUNG, PATH_ABW),
                view: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_ABW, id),
                edit: ({ id }: { id: string | number }) => buildPath(PATH_VERWALTUNG, PATH_ABW, id, PATH_EDIT),
                create: buildPath(PATH_VERWALTUNG, PATH_ABW, PATH_CREATE),
                antrag: ({
                    einrichtungId,
                    antragId,
                    step,
                }: {
                    einrichtungId?: number;
                    antragId?: number;
                    step?: string;
                }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_ABW,
                        einrichtungId,
                        PATH_LEISTUNGSANGEBOT_ERSTELLEN,
                        antragId,
                        step
                    ),
                overview: () => buildPath(PATH_VERWALTUNG, PATH_ABW),
                zuordnung: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_ABW, id, PATH_ZUORDNUNG_MANUELL),
                treffer: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_ABW, id, PATH_ZUORDNUNG_TREFFER),
            },
            pruefberichte: {
                path: buildPath(PATH_VERWALTUNG, PATH_PRUEFBERICHTE),
            },
            regelpruefung: {
                path: buildPath(PATH_VERWALTUNG, PATH_REGELPRUEFUNG, PATH_EINRICHTUNGEN),
                edit: ({ id, step }: { id: string | number; step?: string }) =>
                    buildPath(PATH_VERWALTUNG, PATH_REGELPRUEFUNG, id, step),
                view: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_REGELPRUEFUNG, PATH_EINRICHTUNGEN, id),
                overview: ({ einrichtungId }: { einrichtungId: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_REGELPRUEFUNG, PATH_EINRICHTUNGEN, einrichtungId),
                antrag: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_REGELPRUEFUNG,
                        PATH_EINRICHTUNGEN,
                        einrichtungId,
                        PATH_ANTRAG,
                        antragId
                    ),
                antragView: ({ einrichtungId, antragId }: { einrichtungId: number; antragId: number }) =>
                    buildPath(
                        PATH_VERWALTUNG,
                        PATH_REGELPRUEFUNG,
                        PATH_EINRICHTUNGEN,
                        einrichtungId,
                        PATH_ANTRAG,
                        antragId,
                        PATH_VIEW
                    ),
            },
            profilHeimfinder: {
                path: buildPath(PATH_VERWALTUNG, PATH_PROFIL_HEIMFINDER),
                edit: ({ id }: { id: string | number }) =>
                    buildPath(PATH_VERWALTUNG, PATH_PROFIL_HEIMFINDER, id, PATH_EDIT),
                create: buildPath(PATH_VERWALTUNG, PATH_PROFIL_HEIMFINDER, PATH_CREATE),
            },
        },
        auswertung: {
            path: buildPath(PATH_AUSWERTUNG),
            overview: buildPath(PATH_AUSWERTUNG, PATH_AUSWERTUNG_OVERVIEW),
            P44vWTG: {
                path: buildPath(PATH_AUSWERTUNG, PATH_AUSWERTUNG_P44),
            },
            statistik: {
                path: buildPath(PATH_AUSWERTUNG, PATH_AUSWERTUNG_STATISTIK),
            },
            statistikFem: {
                path: buildPath(PATH_AUSWERTUNG, PATH_AUSWERTUNG_STATISTIK_FEM),
            },
        },
        profile: {
            path: buildPath(PATH_PROFIL),
            benutzerdaten: {
                path: buildPath(PATH_PROFIL, PATH_PROFIL_BENUTZERDATEN),
            },
        },
        externeQuellen: {},
    },
};
