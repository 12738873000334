import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthUser, useSpecificRole } from 'api/auth';
import { LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung } from 'api/client';
import { useLandschaftsverbandItem } from 'api/hooks/useApiClient';
import { SimpleForm } from 'forms/SimpleForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';

import { landschaftsverbandSchema } from './schema';
import { landschaftsverbandUiSchema } from './ui-schema';

interface LvMandantEditProps {
    disableBackLink?: boolean;
}

export const LvMandantEdit = ({ disableBackLink }: LvMandantEditProps) => {
    const { id } = useParams();

    const { isSupport } = useSpecificRole();
    const currentBenutzer = useAuthUser();

    const benutzerId = isSupport ? id : currentBenutzer?.landschaftsverbandId;
    const { data, isLoading, error } = useLandschaftsverbandItem(String(benutzerId));

    const handleSubmit = (changedData: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung) => {
        return backendApiService.patchLandschaftsverbandItem(String(benutzerId), changedData);
    };

    const backLink = useMemo(() => {
        if (disableBackLink) {
            return undefined;
        }

        return {
            title: 'Zur Übersicht',
            path: PathBuilder.home.verwaltung.landschaftsverband.path,
        };
    }, [disableBackLink]);

    return (
        <ContentContainer title="Landschaftsverband bearbeiten">
            <StyledContainer>
                {!error ? (
                    <SimpleForm
                        isLoading={isLoading}
                        data={data}
                        schema={landschaftsverbandSchema}
                        uiSchema={landschaftsverbandUiSchema}
                        submitLabel="Landschaftsverband aktualisieren"
                        onSubmit={handleSubmit}
                        messages={{
                            success: 'Landschaftsverband wurde aktualisiert.',
                            error: 'Landschaftsverband konnte nicht aktualisiert werden.',
                        }}
                        backLink={backLink}
                    />
                ) : (
                    <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};
