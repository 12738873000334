import React, { useMemo } from 'react';

import { useSpecificRole } from 'api/auth/useHasRole';
import { useNachrichtenListActions } from 'components/DataTable/actions/useNachrichtenListActions';
import { useNachrichtenAnbieterColumns } from 'components/DataTable/columns/nachrichten/useNachrichtenAnbieterColumns';
import { useNachrichtenColumns } from 'components/DataTable/columns/nachrichten/useNachrichtenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { menuNachrichten } from 'layout/HeaderConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useNachrichtenListData } from './useNachrichtenListData';

const Index = () => {
    const { isBehoerde } = useSpecificRole();

    const { handleAction, serverDataChanged, setServerDataChanged } = useNachrichtenListActions({
        pathBuilder: PathBuilder.home.nachrichten,
    });

    const getData = useNachrichtenListData();

    const anbieterColumns = useNachrichtenAnbieterColumns(handleAction, setServerDataChanged);
    const behoerdeColumns = useNachrichtenColumns(handleAction, setServerDataChanged);

    const columns = useMemo(
        () => (isBehoerde ? behoerdeColumns : anbieterColumns),
        [anbieterColumns, behoerdeColumns, isBehoerde]
    );

    return (
        <ContentContainer title="Übersicht der Benachrichtigungen">
            <StyledContainer>
                <PageHeading title="Benachrichtigungen" />

                <DataTableHydraServerside
                    omitReloadTableContext
                    getColumns={columns}
                    getData={getData}
                    initialOptions={{
                        filter: true,
                        sortOrder: {
                            name: 'eingereichtAt',
                            direction: 'desc',
                        },
                        custom: {
                            pathBuilder: PathBuilder.home.nachrichten,
                        },
                    }}
                    serverDataChanged={serverDataChanged}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const NachrichtenOverview = withAuthorization(withSideBar(Index, menuNachrichten), [ROLES.USER]);
