import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { useAntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useFemAntragListActions } from 'components/DataTable/actions/useFemAntragListActions';
import { useFemAntragColumns } from 'components/DataTable/columns/fem/useFemAntragColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useFemAntragListData } from './useFemAntragListData';

export const Index = () => {
    const { einrichtungId } = useParams();

    const { serverDataChanged, handleAction } = useFemAntragListActions();

    const getData = useFemAntragListData(einrichtungId);
    const { data: einrichtung, isLoading: isLoadingEinrichtung } = useApiEinrichtungGet(einrichtungId);

    const filterOptions = useAntragTableFilterOptions(AntragType.FEM);

    const columns = useFemAntragColumns(handleAction, filterOptions);

    return (
        <ContentContainer title="Freiheitsentziehende Maßnahmen: Übersicht">
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoadingEinrichtung}>
                    {einrichtung ? (
                        <>
                            <PageHeading
                                title={`Freiheitsentziehende Maßnahmen: ${einrichtung?.name || 'Übersicht'}`}
                            />

                            <DataTableHydraServerside
                                getColumns={columns}
                                getData={getData}
                                initialOptions={{
                                    sort: false,
                                    filter: true,
                                    sortOrder: {
                                        name: 'zuletztEingereichtAt',
                                        direction: 'asc',
                                    },
                                    custom: {
                                        disableSearch: true,
                                        pathBuilder: PathBuilder.home.verwaltung.femAntrag,
                                    },
                                }}
                                serverDataChanged={serverDataChanged}
                            />

                            <ButtonGroupGrid backLink={{ path: PathBuilder.home.verwaltung.femAntrag.path }} />
                        </>
                    ) : (
                        <Alert severity="error">
                            Es wurde keine Einrichtung mit der ID ({einrichtungId}) gefunden.
                        </Alert>
                    )}
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const FemAntraegeOverview = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_FEM_MELDER);
