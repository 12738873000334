import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { useApiMeldungItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';
import { AngabenMeldung } from 'pages/Meldung/Details/Basisdaten/AngabenMeldung';
import { Ansprechpartner } from 'pages/Meldung/Details/Basisdaten/Ansprechpartner';
import { DetailsLeistungsangebot } from 'pages/Meldung/Details/Basisdaten/DetailsLeistungsangebot';
import { Dokumente } from 'pages/Meldung/Details/Basisdaten/Dokumente';
import { Leistungsanbieter } from 'pages/Meldung/Details/Basisdaten/Leistungsanbieter';
import { Beschaeftigte } from 'pages/Meldung/Details/Beschaeftigte';
import { Konzeptinformationen } from 'pages/Meldung/Details/Konzeptinformationen';
import { Leistungserbringer } from 'pages/Meldung/Details/Leistungserbringer';
import { MeldungType } from 'pages/Meldung/Details/MeldungDetailPage';
import { Standorte } from 'pages/Meldung/Details/Standorte.';
import { Vereinbarungen } from 'pages/Meldung/Details/Vereinbarungen';
import { WeitereUnterlagen } from 'pages/Meldung/Details/WeitereUnterlagen';

interface MeldungDetailPageProps {
    title: string;
    headline: string;
    pathBuilder: PathBuilderProps;
}

export const MeldungAnsehen = ({ title, headline, pathBuilder }: MeldungDetailPageProps) => {
    const theme = useTheme();

    const { einrichtungId, id, antragId } = useParams();

    const { isLoading, data: meldung } = useApiMeldungItem<MeldungType>(antragId || id);

    return (
        <ContentContainer title={`${title}: Zusammenfassung`}>
            <StyledContainer>
                <PageHeading title={`${headline}: Zusammenfassung`} />

                <AppLoaderContainer isLoading={isLoading}>
                    <BaseGridContainer marginBotton={theme.spacing(4)} lg={8} justifyContent="center">
                        {meldung ? (
                            <DetailsLayout.Container divide={false}>
                                <DetailsLayout.Group title="Basisdaten" variant="h2">
                                    <AngabenMeldung meldung={meldung} />
                                    <DetailsLeistungsangebot meldung={meldung} />
                                    <Ansprechpartner meldung={meldung} />
                                    <Leistungsanbieter meldung={meldung} />
                                    <Dokumente meldung={meldung} />
                                </DetailsLayout.Group>

                                <Konzeptinformationen meldung={meldung} />

                                <Standorte meldung={meldung} />

                                <Beschaeftigte meldung={meldung} />

                                <Leistungserbringer meldung={meldung} />

                                <Vereinbarungen meldung={meldung} />

                                <WeitereUnterlagen meldung={meldung} />
                            </DetailsLayout.Container>
                        ) : null}
                    </BaseGridContainer>
                </AppLoaderContainer>

                {pathBuilder ? (
                    <ButtonGroupGrid
                        backLink={{ path: String(pathBuilder.overview?.({ einrichtungId: Number(einrichtungId) })) }}
                    />
                ) : null}
            </StyledContainer>
        </ContentContainer>
    );
};
