import { AuthState } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';

type UseImpersonationResult = Pick<AuthState, 'impersonator' | 'impersonate'>;

/**
 * Helper hook to isolate impersonation logic.
 */
export const useImpersonation = (): UseImpersonationResult => {
    return useAuthState(({ impersonator, impersonate }) => ({
        impersonator,
        impersonate,
    }));
};
