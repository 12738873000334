import { useEffect } from 'react';

import { ComponentError } from 'forms/hooks/useErrors';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { useDeepEqualMemo } from 'utilities/hooks/useDeepEqualMemo';

import { useFormState } from './useFormState';
import { useMessageActions } from './useMessages';

export const useErrorMessages = (errors: ComponentError[], loadingError?: string) => {
    const deepErrors = useDeepEqualMemo(errors);
    const { closeMessage } = useMessageActions();
    const { setRequestMessage } = useFormState();

    useEffect(() => {
        if (!deepErrors || deepErrors.length === 0) {
            return;
        }
        const uuids = deepErrors.map((e) => setRequestMessage(errorMessage(e.message)));
        return () => (uuids || []).forEach((uuid) => uuid && closeMessage(uuid));
    }, [deepErrors, setRequestMessage, closeMessage]);

    useEffect(() => {
        if (loadingError) {
            const uuid = setRequestMessage(errorMessage(loadingError));
            if (!uuid) return;

            return () => closeMessage(uuid);
        }
    }, [loadingError, setRequestMessage, closeMessage]);
};
