import React from 'react';
import { Chip, useTheme } from '@mui/material';

import { STATUS } from 'constants/antragStatus';
import { ANTRAG_STATUS_COLORS, ANTRAG_STATUS_LABELS } from 'constants/labels';

interface BadgeColumnProps {
    status: STATUS;
}

export const BadgeStatus = ({ status }: BadgeColumnProps) => {
    const theme = useTheme();
    const antragLabel = ANTRAG_STATUS_LABELS?.[status];
    const antragStatusColors = ANTRAG_STATUS_COLORS?.[status];

    if (!antragLabel) return <></>;

    return (
        <Chip
            label={antragLabel}
            sx={{
                fontSize: theme.spacing(2),
                backgroundColor: `${antragStatusColors?.backgroundColor}`,
                color: `${antragStatusColors?.color}`,
            }}
        />
    );
};
