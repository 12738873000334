import { useMemo, useState } from 'react';
import { ValidationMode } from '@jsonforms/core';

interface UseFormValidationModeResponse {
    defaultValidationMode: ValidationMode;
    validationMode: ValidationMode;
    displayErrors: boolean;
    switchValidation: (hide?: boolean) => void;
}

export const useFormValidationMode = (mode?: ValidationMode): UseFormValidationModeResponse => {
    const defaultValidationMode: ValidationMode = 'NoValidation';
    const [validationMode, setValidationMode] = useState<ValidationMode>(mode ?? defaultValidationMode);

    const displayErrors = useMemo(() => validationMode !== defaultValidationMode, [validationMode]);

    const switchValidation = (disableValidation = true) => {
        setValidationMode(disableValidation ? defaultValidationMode : 'ValidateAndShow');
    };

    return {
        defaultValidationMode,
        validationMode,
        displayErrors,
        switchValidation,
    };
};
