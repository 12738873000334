import { MUIDataTableColumnOptions } from 'mui-datatables';

import { LabelsInterface } from 'constants/labels';

export const EinrichtungMeldungenStatusFilterOptions: (statusLabels: LabelsInterface) => MUIDataTableColumnOptions = (
    statusLabels
) => ({
    customFilterListOptions: {
        render: (value) => {
            return `Status der Meldungen: ${statusLabels[value]}`;
        },
    },
    filterOptions: {
        names: Object.keys(statusLabels),
        renderValue: (value) => statusLabels[value],
    },
    filterType: 'dropdown',
});
