import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetEinrichtungenForRegelpruefung200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useRegelpruefungEinrichtungListData = (): GetData<GetEinrichtungenForRegelpruefung200Response> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder, filters }) =>
            backendApiService.getRegelpruefungEinrichtungCollection({
                page,
                itemsPerPage: rowsPerPage,
                qSearch: searchText,
                orderName: sortBy(sortOrder, 'name'),
                orderNrwKey: sortBy(sortOrder, 'nrwSchluessel'),
                orderArt: sortBy(sortOrder, 'einrichtungsart'),
                orderAdresseOrt: sortBy(sortOrder, 'ort'),
                orderRegelpruefungenPruefDatum: sortBy(sortOrder, 'letzteRegelpruefung'),
                orderRegelpruefungenLetzterUploadAt: sortBy(sortOrder, 'letzterBericht'),
                art2: filters?.['einrichtungsart'],
            }),
        []
    );
};
