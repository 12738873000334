import { AlertColor } from '@mui/material';
import { create } from 'zustand';

import { useFormState } from './useFormState';

interface MessageState {
    messages: Message[];
}

export interface MessageOptions {
    persist?: boolean;
    closable?: boolean;
    autoCloseSeconds?: number;
    action?: {
        actionTitle: string;
        actionMethod: (uuid: string) => void;
    };
}

export interface Message extends MessageOptions {
    uuid: string;
    type: AlertColor;
    text: string;
}

interface MessageActions {
    addMessage: (message: Message) => string;
    hasMessage: (uuid: string) => boolean;
    closeMessage: (uuid: string) => void;
    clearMessages: () => void;
}

const useMessageState = create<MessageState & MessageActions>((set, get) => ({
    messages: [],
    addMessage: (message) => {
        set((state) => {
            if (message.autoCloseSeconds) {
                setTimeout(() => state.closeMessage(message.uuid), message.autoCloseSeconds * 1000);
            }

            if (state.hasMessage(message.uuid)) {
                return { messages: [...state.messages] };
            }

            return { messages: [message, ...state.messages] };
        });

        return message.uuid;
    },
    hasMessage: (uuid: string) => {
        return get().messages.some((message) => message.uuid === uuid);
    },
    closeMessage: (uuid) =>
        set((state) => ({
            messages: state.messages.filter((message) => message.uuid !== uuid),
        })),
    clearMessages: () => {
        set((state) => ({ messages: state.messages.filter((message) => message.persist) }));
    },
}));

export const useMessageActions = () => {
    return useMessageState((s) => ({
        addMessage: s.addMessage,
        closeMessage: s.closeMessage,
        clearMessages: s.clearMessages,
    }));
};

export const useMessages = () => {
    const { requestMessage, setRequestMessage } = useFormState();

    return useMessageState((s) => ({
        messages: s.messages,
        closeMessage: (uuid: string) => {
            if (requestMessage?.uuid === uuid) {
                setRequestMessage(undefined);
            }

            s.closeMessage(uuid);
        },
        clearMessages: s.clearMessages,
    }));
};
