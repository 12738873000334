import React, { useEffect, useRef } from 'react';
import { ButtonBaseActions, Radio, RadioProps } from '@mui/material';

import { useDisabled } from 'forms/hooks/useDisabled';

export const RadioWithFocus: React.FC<RadioProps> = ({ ...props }) => {
    const actionRef = useRef<ButtonBaseActions>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const disabled = useDisabled();
    useEffect(() => {
        if (!disabled && document.activeElement === inputRef.current) {
            actionRef.current?.focusVisible();
        }
    }, [disabled]);
    return <Radio {...props} action={actionRef} inputRef={inputRef} />;
};
