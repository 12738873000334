import { backendApiService } from 'api/ApiService';
import { useSpecificRole } from 'api/auth';
import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useLeistungsangebotAntragListAction = (segment?: string) => {
    const { isBehoerde, isSupport } = useSpecificRole();

    return useListActions(
        isBehoerde || isSupport
            ? PathBuilder.home.aufgaben.freigeben.leistungsangebote
            : PathBuilder.home.verwaltung.leistungsangebote,
        backendApiService.workflowLeistungsangebotItem.bind(backendApiService),
        segment
    );
};
