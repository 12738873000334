import { useMemo } from 'react';

import { NestedGroupControlType, Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { getFieldFromScope } from 'utilities';

export interface NestedField {
    path?: string;
    scope?: string;
    data?: any;
    schema?: Schema;
    title?: string;
}

export interface UseNestedFields {
    nestedField: string;
    nestedData: any;
    nestedSchema: Schema;
    nestedTitle: string | undefined;
    nestedFields: NestedField[];
}

export const useNestedFields = (data: any, uischema: NestedGroupControlType, schema: Schema): UseNestedFields => {
    const propertyPath = '#/properties/';
    const { nestedScope } = uischema;
    const nestedField = getFieldFromScope(nestedScope);
    const nestedData: { uuid: string; [key: string]: any }[] = data?.[nestedField];
    const nestedSchema = getFieldSchemaByScope(propertyPath + nestedScope, schema);
    const nestedTitle = nestedSchema?.title;

    const nestedFields: NestedField[] = useMemo(() => {
        return (uischema.scopes ?? [])
            .filter((scope: string) => (nestedSchema ? scope : false))
            .map((scope: string) => {
                const field: string = getFieldFromScope(propertyPath + scope);
                const fieldSchema = getFieldSchemaByScope(propertyPath + scope, nestedSchema);
                const data = nestedData ? nestedData[field as any] : null;

                return {
                    path: fieldSchema?.custom?.model_path || '',
                    title: fieldSchema?.title ?? '',
                    scope: propertyPath + scope,
                    data,
                    schema: fieldSchema,
                };
            });
    }, [nestedData, nestedSchema, uischema.scopes]);

    return {
        nestedField,
        nestedData,
        nestedSchema,
        nestedTitle,
        nestedFields,
    };
};
