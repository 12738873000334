import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { useApiEmailAntragItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { EMAIL_ANTRAG_ART, EMAIL_ANTRAG_ART_LABELS } from 'constants/emailAntragArt';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';

interface EinrichtungNachrichtDetailsProps {
    pathBuilder: PathBuilderProps;
}

export const EinrichtungNachrichtDetails = ({ pathBuilder }: EinrichtungNachrichtDetailsProps) => {
    const { einrichtungId, id } = useParams();

    const { isLoading, data: message, error } = useApiEmailAntragItem(id);

    return (
        <ContentContainer title="Nachricht zum Leistungsangebot">
            <StyledContainer>
                <BaseGridContainer>
                    <PageHeading title="Nachricht: ansehen" />

                    <AppLoaderContainer isLoading={isLoading}>
                        {!error ? (
                            <>
                                <DetailsLayout.Container>
                                    <DetailsLayout.Group>
                                        <DetailsLayout.InfoField
                                            value={message?.eingereichtAt}
                                            label="Gesendet"
                                            dateFormat="dd.MM.yyyy HH:mm"
                                            isDate
                                        />

                                        {message?.eingereichtBy ? (
                                            <DetailsLayout.InfoField
                                                value={`${message?.eingereichtBy?.nachname}, ${message?.eingereichtBy?.vorname}`}
                                                label="Absender"
                                            />
                                        ) : null}

                                        <DetailsLayout.InfoField
                                            value={EMAIL_ANTRAG_ART_LABELS[message?.typ as EMAIL_ANTRAG_ART]}
                                            label="Art der Nachricht"
                                        />
                                        <DetailsLayout.InfoField value={message?.empfaenger} label="Empfänger" />
                                        <DetailsLayout.InfoField value={message?.data?.betreff} label="Betreff" />
                                    </DetailsLayout.Group>

                                    <DetailsLayout.Group>
                                        <DetailsLayout.InfoField
                                            value={message?.data?.nachricht}
                                            label="Aufforderung / Erinnerung"
                                        />
                                        <DetailsLayout.DownloadLink
                                            value={message?.data?.dokumente}
                                            label="Dokumente"
                                        />
                                    </DetailsLayout.Group>
                                </DetailsLayout.Container>
                            </>
                        ) : (
                            <Alert severity="error">Beim Laden der Nachricht ist ein Fehler aufgetreten.</Alert>
                        )}

                        <ButtonGroupGrid
                            backLink={{
                                path: pathBuilder.nachrichten?.({ einrichtungId: Number(einrichtungId) }) || '',
                            }}
                        />
                    </AppLoaderContainer>
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};
