import { backendApiService } from 'api/ApiService';
import { AntragPathBuilder, useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useLeistungsangebotFreigegebeneListAction = (pathBuilder?: AntragPathBuilder) => {
    return useListActions(
        pathBuilder || PathBuilder.home.verwaltung.leistungsangebote.freigegeben,
        backendApiService.workflowEinrichtung.bind(backendApiService)
    );
};
