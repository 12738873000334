import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, CardActions, CardContent, CardHeader } from '@mui/material';

import { MeldungHinweisDokumentListResponse } from 'api/types';
import { useMeldungHinweisDokumentListActions } from 'components/DataTable/actions/useMeldungHinweisDokumentListActions';
import { useDokumentColumns } from 'components/DataTable/columns/useDokumentColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { Link } from 'elements/Link';
import { StyledCard } from 'layout/components/StyledCard';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';
import { useMeldungHinweisDokumentListData } from 'pages/Verwaltung/MeldungHinweistexte/useMeldungHinweisDokumentListData';

export const MeldungHinweisDokumentTable = () => {
    const pathBuilder = PathBuilder.home.verwaltung.meldungHinweise.document;

    const { serverDataChanged, handleAction } = useMeldungHinweisDokumentListActions(pathBuilder);

    const columns = useDokumentColumns<MeldungHinweisDokumentListResponse>(handleAction);
    const getData = useMeldungHinweisDokumentListData();

    return (
        <StyledContainer>
            <StyledCard elevation={2} padding={3}>
                <CardHeader title="Dokumente" />

                <CardContent>
                    <Alert severity="info">
                        Bitte laden Sie hier für jede Art von Leistungsangebot nur wenige Dateien hoch und wenn möglich
                        nicht größer als 1 MB pro Datei, da diese Dateien per E-Mail versendet werden.
                    </Alert>

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                        initialOptions={{
                            custom: {
                                pathBuilder,
                            },
                        }}
                    />
                </CardContent>

                <CardActions sx={{ paddingLeft: 0 }}>
                    <Button
                        component={Link}
                        to={pathBuilder.add}
                        color="primary"
                        variant="contained"
                        endIcon={<AddIcon />}
                    >
                        Dokument hinzufügen
                    </Button>
                </CardActions>
            </StyledCard>
        </StyledContainer>
    );
};
