import { DependencyList } from 'react';
import { MUIDataTableColumnDef, MUIDataTableOptions, MUISortOptions } from 'mui-datatables';

import { GetColumns } from 'components/DataTable/types';

import { useTableColumns } from './useTableColumns';
import { GetData, useTableData } from './useTableData';
import { FilterButtonProps, TableFilters, useTableFilters } from './useTableFilters';
import { TableOptions, useTableOptions } from './useTableOptions';
import { useTableSearchText } from './useTableSearchText';
import { useTableUrlParams } from './useTableUrlParams';
import { useTableViewColumns } from './useTableViewColumns';

interface UseTableParametersResult<Data = any> {
    isLoading: boolean;
    data: Data | undefined;
    hasErrors: boolean;
    options: MUIDataTableOptions;
    columns: MUIDataTableColumnDef[];
    filterButtonProps: FilterButtonProps;
    filters?: TableFilters;
    sortOrder?: MUISortOptions | undefined;
    searchText?: string;
}

export const useTableParameters = <Data = any>(
    name: string,
    initialOptions: TableOptions,
    getColumns: GetColumns<Data>,
    getData: GetData<Data>,
    persistSearchText: boolean | undefined,
    deps: DependencyList = []
): UseTableParametersResult<Data> => {
    const { viewColumns, onViewColumnsChanged } = useTableViewColumns(getColumns);

    // ToDo Refactor loading of benutzer table preferences based on filter settings
    const { filters, initialFilters, onFiltersChanged, updateFilters, ...filterButtonProps } = useTableFilters(
        name,
        getColumns,
        initialOptions?.filter
    );

    const { searchText, onSearchChanged, updateSearchText } = useTableSearchText(
        initialOptions,
        persistSearchText,
        name
    );

    const { tableOptions, sortOrder, page, rowsPerPage, updateSortOrder, updatePage, updateRowsPerPage } =
        useTableOptions(initialOptions, searchText, onFiltersChanged, onSearchChanged, onViewColumnsChanged, name);

    const { isLoading, data, hasErrors } = useTableData<Data>(tableOptions, filters, searchText, getData, deps);

    const columns = useTableColumns(data, filters, viewColumns, getColumns);

    useTableUrlParams(
        filters,
        sortOrder,
        searchText,
        page,
        rowsPerPage,
        updateFilters,
        updateSortOrder,
        updateSearchText,
        updatePage,
        updateRowsPerPage,
        initialOptions,
        initialFilters
    );

    return {
        isLoading,
        data,
        hasErrors,
        options: tableOptions,
        columns,
        filterButtonProps,
        filters,
        sortOrder,
        searchText,
    };
};
