import React, { ChangeEvent, createRef, useEffect, useMemo, useRef } from 'react';
import { ButtonBaseActions, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { formatBoolean } from 'forms/utilities/formatter';
import { getControlLabel } from 'forms/utilities/SchemaUtils';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const CheckboxControlComponent: React.FC<CustomControlProps<boolean>> = ({
    path,
    label,
    uischema,
    handleChange,
    disabled,
    readonly,
    formula,
    data,
    onTouched,
    hasErrors,
    errors,
    required,
    showFieldNumberLabels,
    gridLayout,
    schema,
}) => {
    const requestFocus = useRef<boolean>();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(path, event.target.checked);
        setTimeout(() => {
            requestFocus.current = true;
            buttonRef.current?.blur();
        }, 50);
    };

    const actionRef = createRef<ButtonBaseActions>();
    const buttonRef = createRef<HTMLButtonElement>();
    const isChecked = useMemo<boolean>(() => !!data, [data]);

    const checkboxLabel: string = getControlLabel(uischema, label, schema);

    useEffect(() => {
        if (!disabled && requestFocus.current) {
            actionRef.current?.focusVisible();
            requestFocus.current = false;
        }
    }, [requestFocus, disabled, actionRef]);

    return (
        <RowControl
            name={path}
            label={label}
            controlOnly={uischema.controlOnly}
            formula={formula}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            text={data ? formatBoolean(data) : '-'}
                            hasErrors={false}
                            textAlign={'left'}
                            errors={[]}
                            label={label}
                        />
                    ) : (
                        <FormControl fullWidth disabled={disabled} required={required}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={disabled}
                                        checked={isChecked}
                                        ref={buttonRef}
                                        action={actionRef}
                                        focusRipple={true}
                                        onBlur={onTouched}
                                        onChange={onChange}
                                        required={required}
                                        inputProps={{ 'data-cy': `form_${path}` } as any}
                                    />
                                }
                                label={checkboxLabel}
                            />

                            {hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const CheckboxControl = withCustomControlProps(CheckboxControlComponent);
