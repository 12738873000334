import { Typography } from '@mui/material';

import { PageHeading } from 'components/PageHeading';
import {
    KONTAKT_BARR_OMBUD_EMAIL,
    KONTAKT_BARR_OMBUD_TEL,
    KONTAKT_BARR_OMBUD_URL,
    KONTAKT_BARR_UEBERWACHUNG_EMAIL,
    KONTAKT_BARR_UEBERWACHUNG_URL,
    KONTAKT_BASE_URL,
} from 'constants/content';
import { Link } from 'elements/Link';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkExtern } from 'elements/LinkExtern';
import { LinkTel } from 'elements/LinkTel';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { TextContainer } from 'layout/container/TextContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { PathBuilder } from 'navigation/Paths';

const Barrierefreiheit = () => {
    return (
        <ContentContainer title="Barrierefreiheit">
            <StyledContainer>
                <TextContainer>
                    <PageHeading title="Erklärung zur Barrierefreiheit" />
                    <Typography variant="h2">
                        Stand der Vereinbarkeit mit den Anforderungen zur barrierefreien Informationstechnik
                    </Typography>
                    <Typography paragraph>
                        Die Internetseite <Link href={KONTAKT_BASE_URL} /> ist mit den technischen Anforderungen gemäß
                        der Barrierefreie-Informationstechnik-Verordnung Nordrhein-Westfalen derzeit noch nicht komplett
                        vereinbar.
                    </Typography>
                    <Typography paragraph>Sie wird momentan nach dem WCAG-Verfahren getestet.</Typography>

                    <Typography variant="h2">Nicht barrierefreie Inhalte</Typography>
                    <Typography paragraph>
                        Sobald das WCAG-Verfahren abgeschlossen ist, informieren wir Sie an dieser Stelle über etwaige
                        nicht barrierefreie Inhalte und bemühen uns umgehend um die Verbesserung der Zugänglichkeit.
                        Dafür bitten wir Sie noch um etwas Geduld.
                    </Typography>

                    <Typography variant="h2">Erstellung dieser Erklärung zur Barrierefreiheit </Typography>
                    <Typography paragraph>Diese Erklärung wurde am 10. September 2020 erstellt. </Typography>

                    <Typography variant="h2">Feedback und Kontaktangaben </Typography>
                    <Typography paragraph>
                        Wenn Sie Anregungen oder Fragen zur Barrierefreiheit haben, finden Sie die entsprechenden
                        Ansprechpartner im <Link to={PathBuilder.home.impressum.path}>Impressum</Link>.
                    </Typography>
                    <Typography paragraph>
                        Darüber hinaus bitten wir Sie, unsere{' '}
                        <Link to={PathBuilder.home.datenschutz.path}>datenschutzrechtlichen Hinweise</Link> zu beachten.
                    </Typography>

                    <Typography variant="h2">
                        Überwachungsstelle für barrierefreie Informationstechnik des Landes Nordrhein-Westfalen
                    </Typography>
                    <Typography paragraph>
                        Die Überwachungsstelle für barrierefreie Informationstechnik des Landes Nordrhein-Westfalen
                        prüft regelmäßig, ob und inwiefern Internetseiten und mobile Anwendungen öffentlicher Stellen
                        des Landes den Anforderungen an die Barrierefreiheit genügen.
                    </Typography>
                    <Typography paragraph>
                        Ziel der Arbeit der Überwachungsstelle ist es, die Einhaltung der Anforderungen an die
                        barrierefreie Informationstechnik sicherzustellen und für eine flächendeckende Umsetzung der
                        gesetzlichen Regelungen zu sorgen.
                    </Typography>

                    <Typography paragraph>
                        <LinkEmail email={KONTAKT_BARR_UEBERWACHUNG_EMAIL}>
                            E-Mail an die Überwachungsstelle senden
                        </LinkEmail>
                    </Typography>

                    <Typography paragraph>
                        <LinkExtern href={KONTAKT_BARR_UEBERWACHUNG_URL}>
                            Weitere Informationen zur Überwachungsstelle
                        </LinkExtern>
                    </Typography>

                    <Typography variant="h2">
                        Ombudsstelle für barrierefreie Informationstechnik des Landes Nordrhein-Westfalen
                    </Typography>
                    <Typography paragraph>
                        Sollten Sie auf Mitteilungen oder Anfragen zur barrierefreien Informationstechnik der
                        Internetseite <Link href={KONTAKT_BASE_URL} /> von der Online-Redaktion keine
                        zufriedenstellenden Antworten erhalten haben, können Sie die Ombudsstelle für barrierefreie
                        Informationstechnik einschalten. Unter Einbeziehung aller Beteiligten versucht die Ombudsstelle,
                        die Umstände der fehlenden Barrierefreiheit zu ermitteln, damit der Träger diese beheben kann.
                    </Typography>
                    <Typography paragraph>
                        Sie ist der oder dem Beauftragten für die Belange der Menschen mit Behinderung nach § 11 des
                        Behindertengleichstellungsgesetzes Nordrhein-Westfalen zugeordnet und über folgenden Kontakt zu
                        erreichen:
                    </Typography>
                    <Typography paragraph>
                        <LinkEmail email={KONTAKT_BARR_OMBUD_EMAIL}>
                            E-Mail an die Ombudsstelle für barrierefreie Informationstechnik NRW senden
                        </LinkEmail>
                    </Typography>
                    <Typography paragraph>
                        Telefonisch ist die Ombudsstelle für barrierefreie Informationstechnik NRW unter folgender
                        Rufnummer zu erreichen: <LinkTel msisdn={KONTAKT_BARR_OMBUD_TEL} />.
                    </Typography>
                    <Typography paragraph>
                        <LinkExtern href={KONTAKT_BARR_OMBUD_URL}>
                            Weitere Informationen zur Ombudsstelle für barrierefreie Informationstechnik NRW
                        </LinkExtern>
                    </Typography>
                </TextContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const BarrierefreiheitPage = withSideBar(Barrierefreiheit);
