import React, { ReactNode } from 'react';
import { AssignmentReturn, Publish, Save } from '@mui/icons-material';
import { Box, styled } from '@mui/material';

import { PartialRecord } from 'api/types';
import { ACTIONS } from 'constants/antragActions';
import { ANTRAG_ACTION_LABELS } from 'constants/labels';
import { ButtonColors, FormAction } from 'forms/hooks/useFormActions';
import { useFormState } from 'forms/state/useFormState';
import { FormActionButtonOverrides } from 'pages/AntragPage';
import { transientOptions } from 'theme/utils';

import { FormLoadingButton } from './FormButton';

interface FormActionsProps {
    isDirty: boolean;
    getButtonColor: (action: ACTIONS) => ButtonColors;
    handleFormAction: FormAction;
    actions?: Array<ACTIONS>;
    buttonOverrides?: PartialRecord<ACTIONS, FormActionButtonOverrides>;
    onSave?(): void;
}

export const FormActionButtons = ({
    actions = [],
    isDirty,
    getButtonColor,
    handleFormAction,
    buttonOverrides,
}: FormActionsProps) => {
    const { isSubmitting, isPersisting, isInProgress } = useFormState();

    const getWorkflowActionIcon = (action: ACTIONS): ReactNode => {
        switch (action) {
            case ACTIONS.FREIGEBEN:
                return <Publish />;
            case ACTIONS.ZURUECKWEISEN:
                return <AssignmentReturn />;
            case ACTIONS.EINREICHEN:
                return <Publish />;
            case ACTIONS.BEARBEITUNG_ABSCHLIESSEN:
                return <Save />;
            default:
                return <></>;
        }
    };

    return (
        <FormActionsWrapper $wrapped={actions?.length > 2}>
            {actions.map((action) => {
                switch (action) {
                    case ACTIONS.SPEICHERN:
                        return (
                            <FormLoadingButton
                                key={action}
                                variant="contained"
                                color={getButtonColor(action)}
                                disabled={!isDirty}
                                loading={isSubmitting || isPersisting}
                                loadingPosition="end"
                                endIcon={<Save />}
                                onClick={() => handleFormAction(action, ACTIONS.SPEICHERN)}
                            >
                                Zwischenspeichern
                            </FormLoadingButton>
                        );
                    case ACTIONS.ZURUECKWEISEN:
                    case ACTIONS.EINREICHEN:
                    case ACTIONS.FREIGEBEN:
                    case ACTIONS.BEARBEITUNG_ABSCHLIESSEN:
                        return (
                            <FormLoadingButton
                                key={action}
                                type="button"
                                color={buttonOverrides?.[action]?.color || getButtonColor(action)}
                                loading={isInProgress}
                                loadingPosition="end"
                                endIcon={getWorkflowActionIcon(action)}
                                variant="contained"
                                onClick={() => handleFormAction(action)}
                            >
                                {buttonOverrides?.[action]?.label || ANTRAG_ACTION_LABELS[action]}
                            </FormLoadingButton>
                        );
                    default:
                        return (
                            <FormLoadingButton
                                key={action}
                                color={buttonOverrides?.[action]?.color || getButtonColor(action)}
                                onClick={() => handleFormAction(action)}
                                loading={isInProgress}
                                variant="contained"
                            >
                                {buttonOverrides?.[action]?.label || ANTRAG_ACTION_LABELS[action]}
                            </FormLoadingButton>
                        );
                }
            })}
        </FormActionsWrapper>
    );
};

const FormActionsWrapper = styled(
    Box,
    transientOptions
)<{ $wrapped: boolean }>(({ theme, $wrapped }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        flexDirection: $wrapped ? 'column' : 'row',
    },
}));
