import { Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';

export const schema: Schema = {
    required: ['einrichtung'],
    properties: {
        einrichtung: {
            type: 'string',
            title: 'Name des Leistungsangebots',
            // @ts-ignore
            custom: {
                block_prefixes: ['form', 'wohngemeinschaft_autocomplete'],
            },
        },
    },
};

export const uiSchema = customVerticalLayout([control('einrichtung')]);
