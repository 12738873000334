import React from 'react';
import { Chip, styled } from '@mui/material';
import { ChipPropsSizeOverrides } from '@mui/material/Chip/Chip';
import { OverridableStringUnion } from '@mui/types';

import { transientOptions } from 'theme/utils';

export type BadgeColorTypes = 'info' | 'primary' | 'success' | 'warning' | 'error';

interface BadgeColumnProps {
    content: React.ReactNode | string;
    color: BadgeColorTypes;
    fontSize?: number;
    textBold?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', ChipPropsSizeOverrides>;
}

export const BadgeColumn = ({ content, color, fontSize, textBold, size }: BadgeColumnProps) => {
    return <StyledBadge label={content} $fontSize={fontSize} $textBold={textBold} size={size} $color={color} />;
};

const StyledBadge = styled(
    Chip,
    transientOptions
)<{ $fontSize?: number; $textBold?: boolean; $color: BadgeColorTypes }>(({ theme, $fontSize, $textBold, $color }) => ({
    fontSize: $fontSize ?? theme.spacing(2),
    fontWeight: $textBold ? 600 : 300,
    backgroundColor: theme.palette[$color]?.main,
    color: theme.palette[$color]?.contrastText,
}));
