import { MUIDataTableColumnOptions, MUISortOptions } from 'mui-datatables';

import { InlineResponse } from 'api/responses/InlineResponse';
import { formatDate } from 'forms/utilities/formatter';
import { transform2Date } from 'utilities/hooks/useFormatDate';

export const sortBy = (
    sortOrder: MUISortOptions | undefined,
    name: string
): MUISortOptions['direction'] | undefined => {
    return sortOrder?.name === name ? sortOrder?.direction : undefined;
};

export const getRowData = <T = any>(dataIndex: number, data: InlineResponse<T>): T | undefined =>
    data['hydra:member']?.[dataIndex];

export const getDateTime = (value?: string | null) =>
    value ? `${transform2Date(value, 'dd.MM.yyyy - HH:mm')} Uhr` : '-';

export const getDate = (value?: string | null) => (value ? formatDate(value) : '-');

export const rightAlignedColumn: MUIDataTableColumnOptions = {
    setCellProps: () => ({
        align: 'right',
    }),
    setCellHeaderProps: () => ({
        align: 'right',
    }),
};

export const centerAlignedColumn: MUIDataTableColumnOptions = {
    setCellProps: () => ({
        align: 'center',
    }),
    setCellHeaderProps: () => ({
        align: 'center',
    }),
};
