import React, { ReactNode } from 'react';
import { TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';

type DatePickerProps = Omit<DesktopDatePickerProps<Date>, 'renderInput' | 'inputFormat'> & {
    error?: boolean;
    onBlur?: () => void;
    helperText?: string | ReactNode;
    fullWidth?: boolean;
    inputProps?: TextFieldProps['inputProps'];
};

const fieldYearPlaceholder = () => 'jjjj';

export const DatePickerYear: React.FC<DatePickerProps> = ({
    error = false,
    fullWidth,
    onBlur,
    helperText,
    inputProps,
    ...props
}) => {
    return (
        <DesktopDatePicker
            format="yyyy"
            views={['year']}
            localeText={{
                fieldYearPlaceholder,
            }}
            slotProps={{
                actionBar: { actions: inputProps?.required ? [] : ['clear'] },
                textField: {
                    error,
                    helperText,
                    onBlur,
                    fullWidth,
                    inputProps: {
                        ...inputProps,
                        placeholder: 'jjjj',
                        required: inputProps?.required,
                    },
                },
            }}
            {...props}
        />
    );
};
