import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ValidationMode } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { Typography } from '@mui/material';
import { isArray } from 'lodash';

import { PartialRecord, Steps } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ACTIONS } from 'constants/antragActions';
import { AntragType } from 'constants/antragTypes';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { TabConfig, TabNavigation } from 'layout/menu/TabNavigation';
import { TabComponentProps, useTabsConfig } from 'navigation/hooks/useTabsConfig';
import { PathBuilderProps } from 'navigation/Paths';
import { AntragPageMessages, FormActionButtonOverrides } from 'pages/AntragPage';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';
import { useRoleText } from 'utilities/hooks/useRoleText';

import { FormActionButtons } from './components/FormActionButtons';
import { FormNavigation } from './components/FormNavigation';
import { Pflichtfeld } from './components/Pflichtfeld';
import { GetWorkflowFormItem, useFormActions } from './hooks/useFormActions';
import { FormHandlingOnChange } from './hooks/useFormHandling';
import { useFormValidation } from './hooks/useFormValidation';
import { useFormValidationMode } from './hooks/useFormValidationMode';
import { renderers } from './renderers';
import { useFormState } from './state/useFormState';
import { FormConfig, FormState, Schema, ServerErrorsType, UiSchemaType } from './types/UiSchemaTypes';

interface AntragFormProps extends AntragPageMessages {
    basePath: string;
    backLinkPath: string;
    config: FormConfig;
    initialValidationMode?: ValidationMode;
    isDirty: boolean;
    isLoadingSchema: boolean;
    pathBuilder: PathBuilderProps;
    tabConfig: TabConfig<TabComponentProps>[];
    workflowFormItem: GetWorkflowFormItem;
    schema: Schema | undefined;
    onChange: FormHandlingOnChange;
    antragType: AntragType;
    uiSchema?: UiSchemaType;
    data?: FormState;
    errors?: ServerErrorsType;
    step?: string;
    steps?: Steps;
    layout?: {
        xs?: number;
        lg?: number;
    };
    disableValidationOnSave?: boolean;
    buttonOverrides?: PartialRecord<ACTIONS, FormActionButtonOverrides>;
    nextTabLabel?: boolean;
    withServerErrors?: boolean;
}

export const AntragForm = ({
    basePath,
    backLinkPath,
    config,
    isDirty,
    isLoadingSchema,
    pathBuilder,
    tabConfig,
    workflowFormItem,
    schema,
    onChange,
    antragType,
    uiSchema,
    data,
    errors,
    step,
    steps,
    layout = {
        xs: 12,
        lg: 10,
    },
    messages,
    disableValidationOnSave,
    initialValidationMode,
    buttonOverrides,
    nextTabLabel,
    withServerErrors = true,
}: AntragFormProps) => {
    const navigate = useNavigate();

    const { validationMode, switchValidation } = useFormValidationMode(initialValidationMode);
    const { antragId, isPersisting, isSubmitting, isInProgress, formSubmitted, setRequestMessage, setFormSubmitted } =
        useFormState();

    const { errorSubmit } = useRoleText(antragType);

    const { tabs, nextTab, currentIndex, tabHeadline, tabActions, prevTabPath, nextTabPath } = useTabsConfig({
        tabsDictionary: tabConfig,
        basePath,
        step,
        steps: steps as Steps,
    });

    const formConfig = useMemo(() => {
        if (!config.errors && errors && withServerErrors) {
            return {
                ...config,
                errors,
            };
        }

        return config;
    }, [config, errors, withServerErrors]);

    const { valid } = useFormValidation(schema, data, formConfig, uiSchema);

    const handleSubmit = useCallback(
        (persist: boolean, showSuccessMessage: boolean = true) =>
            new Promise<void>((resolve, reject) => {
                if (!config.submit) return resolve();

                setFormSubmitted(!valid && !persist);

                if (!valid && !persist) {
                    setRequestMessage(errorMessage(errorSubmit || 'Bitte prüfen Sie Ihre Eingaben'));
                    switchValidation(false);
                    return reject();
                }

                return config
                    .submit({ body: data, persist: true })
                    .then(async (response) => {
                        if (
                            response &&
                            response?.errors &&
                            'data' in (response?.errors || {}) &&
                            !disableValidationOnSave
                        ) {
                            // ToDo Refactor Error Response handling
                            if (isArray(response.errors.data) && response.errors.data.length) {
                                response.errors.data.forEach((error) => {
                                    setRequestMessage(errorMessage(error));
                                });
                                return reject();
                            }

                            setRequestMessage(errorMessage('Es ist ein Fehler aufgetreten'));
                            return reject();
                        }

                        if (showSuccessMessage) {
                            setRequestMessage(successMessage('Änderungen wurden gespeichert.'));
                        }
                        return resolve();
                    })
                    .catch(() => {
                        setRequestMessage(errorMessage('Antrag konnte nicht eingereicht werden.'));

                        return reject();
                    });
            }),
        [
            config,
            data,
            disableValidationOnSave,
            errorSubmit,
            setFormSubmitted,
            setRequestMessage,
            switchValidation,
            valid,
        ]
    );

    const { getButtonColor, handleFormAction } = useFormActions({
        antragId: Number(antragId),
        antragType,
        pathBuilder,
        workflowFormItem,
        onSave: handleSubmit,
        messages,
    });

    const handleNextStep = (nextPath: string) => {
        if (isDirty) {
            return config.submit?.({ body: data, persist: true }).then(() => {
                navigate(nextPath);
            });
        }

        navigate(nextPath);
    };

    const FormValidationTrigger = () => {
        useDebouncedEffect(
            () => {
                switchValidation(!formSubmitted);
            },
            [],
            100
        );

        return <></>;
    };

    useEffect(() => {
        if (initialValidationMode) {
            setFormSubmitted(initialValidationMode === 'ValidateAndShow');
        }

        return () => {
            setFormSubmitted(false);
        };
    }, [initialValidationMode, setFormSubmitted]);

    return (
        <BaseGridContainer justifyContent="flex-start" xs={layout.xs} lg={layout.lg}>
            {tabHeadline ? <Typography variant="h2">{tabHeadline || ''}</Typography> : null}

            <TabNavigation
                tabs={tabs}
                data={data}
                currentIndex={currentIndex}
                basePath={basePath}
                defaultComponent={
                    <AppLoaderContainer isLoading={!isInProgress && isLoadingSchema}>
                        {data && uiSchema ? (
                            <>
                                <FormValidationTrigger />

                                <Pflichtfeld display={!!schema?.required?.length} />

                                <JsonForms
                                    data={data}
                                    schema={schema}
                                    uischema={uiSchema}
                                    renderers={renderers}
                                    onChange={(state) => onChange(state.data)}
                                    config={config}
                                    validationMode={validationMode}
                                />
                            </>
                        ) : null}
                    </AppLoaderContainer>
                }
            />

            <FormNavigation
                isDirty={isDirty}
                isDisabled={isSubmitting || isPersisting}
                nextTab={nextTab}
                nextTabPath={nextTabPath}
                nextTabLabel={nextTabLabel}
                prevTabPath={prevTabPath}
                onNextStep={handleNextStep}
                backLink={{ path: backLinkPath }}
            >
                <FormActionButtons
                    isDirty={isDirty}
                    actions={tabActions}
                    buttonOverrides={buttonOverrides}
                    handleFormAction={(action, submitAction) => handleFormAction(action, submitAction)}
                    getButtonColor={getButtonColor}
                />
            </FormNavigation>
        </BaseGridContainer>
    );
};
