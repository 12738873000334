export const basisdaten_table = {
    type: 'SummaryTable',
    label: 'Basisdaten',
    columns: 4,
    scope: '#/properties/basisdaten',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungNrwKey',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungArt',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungShortCareHospital',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungWohngemeinschaftArt',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungWohngemeinschaftLeistungsbereich',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungName',
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Adresse der Einrichtung',
            scope: '#/properties/einrichtungAdresse/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Adresse der Einrichtung',
                    scope: '#/properties/label',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungInstitutionskennzeichen',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungAnsprechpartner',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungTelefon',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungEmail',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungHomepage',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungPlaetze',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/einrichtungDatumErstinbetriebnahme',
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Träger-/Unternehmensgruppe',
            scope: '#/properties/unternehmensgruppe/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Träger-/Unternehmensgruppe',
                    scope: '#/properties/name',
                },
            ],
        },
        {
            type: 'FieldRow',
            label: 'Neue Unternehmensgruppe',
            scope: '#/properties/neueUnternehmensgruppe',
        },
        {
            type: 'SubTable',
            level: 0,
            label: 'Trägerverband der Einrichtung',
            scope: '#/properties/einrichtungTraegerverband/properties',
            entries: [
                {
                    type: 'FieldRow',
                    label: 'Trägerverband der Einrichtung',
                    scope: '#/properties/name',
                },
            ],
        },
        {
            type: 'FieldRow',
            scope: '#/properties/tragerExistiertBereits',
        },
        {
            type: 'FieldRow',
            label: 'Dokumente',
            scope: '#/properties/dokumente',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizBasisdaten',
        },
    ],
};
