import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';
import { modalFemMassnahme } from 'forms/ui-schemas/modalElements';

export const uiSchema = customVerticalLayout([
    control('gerichtlichGesamtanzahl', { withInfoIcon: true }),
    control('gerichtlichGesamtanzahlBetroffene', { withInfoIcon: true }),
    info({
        message:
            'Bitte geben Sie hier an, wie oft eine gerichtlich genehmigte Maßnahme innerhalb des Quartals und bei wie vielen Personen tatsächlich angewendet wurde. Beispiel: Zweimal täglich wird das Bettgitter bei Person X hochgeklappt. Ansonsten wurde bei keiner anderen Person der Einrichtung das Bettgitter genutzt. In diesem Fall sind die Zahlen 180 und 1 in die entsprechenden Felder einzutragen.',
    }),
    group(
        [
            inlineRowControl([
                control('gerichtlichSiebenPunktFixierung/properties/anzahlPersonen'),
                control('gerichtlichSiebenPunktFixierung/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichSiebenPunktFixierung'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichFuenfPunktFixierung/properties/anzahlPersonen'),
                control('gerichtlichFuenfPunktFixierung/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichFuenfPunktFixierung'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichFixierungArme/properties/anzahlPersonen'),
                control('gerichtlichFixierungArme/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichFixierungArme'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichFixierungBeine/properties/anzahlPersonen'),
                control('gerichtlichFixierungBeine/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichFixierungBeine'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichFixierungBauchgurt/properties/anzahlPersonen'),
                control('gerichtlichFixierungBauchgurt/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichFixierungBauchgurt'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichZwangsjacke/properties/anzahlPersonen'),
                control('gerichtlichZwangsjacke/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichZwangsjacke'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichBettgitter/properties/anzahlPersonen'),
                control('gerichtlichBettgitter/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichBettgitter'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichPflegedecken/properties/anzahlPersonen'),
                control('gerichtlichPflegedecken/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichPflegedecken'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichTherapietisch/properties/anzahlPersonen'),
                control('gerichtlichTherapietisch/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichTherapietisch'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichGeschlossene/properties/anzahlPersonen'),
                control('gerichtlichGeschlossene/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichGeschlossene'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichEinsperren/properties/anzahlPersonen'),
                control('gerichtlichEinsperren/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichEinsperren'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichSchliessmechanismen/properties/anzahlPersonen'),
                control('gerichtlichSchliessmechanismen/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichSchliessmechanismen'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichArretieren/properties/anzahlPersonen'),
                control('gerichtlichArretieren/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichArretieren'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichFesthalten/properties/anzahlPersonen'),
                control('gerichtlichFesthalten/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichFesthalten'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichVerbot/properties/anzahlPersonen'),
                control('gerichtlichVerbot/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichVerbot'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichGesicherteAufzuege/properties/anzahlPersonen'),
                control('gerichtlichGesicherteAufzuege/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichGesicherteAufzuege'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichSicherungTransport/properties/anzahlPersonen'),
                control('gerichtlichSicherungTransport/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichSicherungTransport'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichSedierendeMedikamente/properties/anzahlPersonen'),
                control('gerichtlichSedierendeMedikamente/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichSedierendeMedikamente'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichSitzhosen/properties/anzahlPersonen'),
                control('gerichtlichSitzhosen/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichSitzhosen'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichWegnehmenDerKleidung/properties/anzahlPersonen'),
                control('gerichtlichWegnehmenDerKleidung/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichWegnehmenDerKleidung'
    ),
    group(
        [
            inlineRowControl([
                control('gerichtlichSchutzhandschuhe/properties/anzahlPersonen'),
                control('gerichtlichSchutzhandschuhe/properties/anzahlQuartal'),
            ]),
        ],
        {},
        '',
        '',
        '',
        '#/properties/gerichtlichSchutzhandschuhe'
    ),
    listControlWithModalForm(
        ['#/properties/gerichtlichCustomMassnahmen'],
        [
            tableColumn('Maßnahme', { scope: '#/properties/name' }),
            tableColumn('Anzahl der Personen', { scope: '#/properties/anzahlPersonen' }),
            tableColumn('Anzahl im Quartal', { scope: '#/properties/anzahlQuartal' }),
        ],
        ['Maßnahme hinzufügen'],
        ['Maßnahme löschen'],
        ['Wollen Sie diese Maßnahme mit allen Angaben wirklich löschen?'],
        [modalFemMassnahme],
        { types: ['Maßnahmen'], labelScope: '#/properties/gerichtlichCustomMassnahmen' }
    ),
]);
