import React from 'react';
import { Typography } from '@mui/material';

import { useLeistungsanbieterAntragListAction } from 'components/DataTable/actions/useLeistungsanbieterAntragListAction';
import { useLeistungsanbieterColumns } from 'components/DataTable/columns/leistungsanbieter/useLeistungsanbieterColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { STATUS } from 'constants/antragStatus';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useLeistungsanbieterListData } from './useLeistungsanbieterListData';

export const Index: React.FC = () => {
    const { serverDataChanged, handleAction } = useLeistungsanbieterAntragListAction();

    const getData = useLeistungsanbieterListData(STATUS.EINGEREICHT);

    const columns = useLeistungsanbieterColumns(handleAction);

    return (
        <ContentContainer title="Leistungsanbieter freigeben">
            <StyledContainer>
                <PageHeading title="Leistungsanbieter freigeben" />

                <Typography paragraph>
                    Hier sehen Sie alle Leistungsanbieter, die noch nicht freigegeben wurden.
                </Typography>

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    persistSearchText
                    initialOptions={{
                        sortOrder: {
                            name: 'name',
                            direction: 'asc',
                        },
                        custom: {
                            pathBuilder: PathBuilder.home.aufgaben.freigeben.leistungsanbieter,
                            segment: 'freigeben',
                        },
                    }}
                    serverDataChanged={serverDataChanged}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const LeistungsanbieterOverview = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_LEISTUNGSANBIETER_FREIGEBEN
);
