import * as React from 'react';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { FormControl } from '@mui/material';

import { FileUploadResponse } from 'api/responses/FileUploadResponse';
import { URL_UPLOAD } from 'api/routes';
import { FileUploadButton } from 'forms/controls/components/FileUploadButton';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { useFileUpload } from 'forms/hooks/useFileUpload';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { Schema } from 'forms/types/UiSchemaTypes';
import { UploadValue } from 'forms/types/UploadValueType';
import { stringToNumber } from 'utilities/Utils';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const MehrfachUploadControlComponent: React.FC<CustomControlProps<UploadValue[]>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    readonly,
    disabled,
    showFieldNumberLabels,
    gridLayout,
    uischema,
}) => {
    const onUpload = useCallback(
        (responses: FileUploadResponse[]) => {
            const newFiles: UploadValue[] = responses.map((r) => ({
                uploadId: r.id,
                originalName: r.originalName,
                contentUrl: r.contentUrl,
                fileSize: stringToNumber(r.fileSize), // Bytes to Mb
                accessRights: r.accessRights,
            }));
            handleChange(path, [...(data || []), ...newFiles], true);
        },
        [handleChange, path, data]
    );

    const onRemove = useCallback(
        (id: number) => {
            const newFiles = [...(data || []).filter((r) => r.uploadId !== id)];
            handleChange(path, newFiles, true);
        },
        [handleChange, path, data]
    );

    const uploadedFileList = useMemo(() => data?.filter((file) => file.originalName), [data]);

    const { uploadErrors, uploadFiles, isUploading, removeFile } = useFileUpload(
        onUpload,
        onRemove,
        URL_UPLOAD,
        uploadedFileList,
        uischema.withUploadLimit
    );

    const handleValueChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files?.length) return;
            uploadFiles(e.target.files);
        },
        [uploadFiles]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={(uischema as Schema).showFieldNumberLabels ?? showFieldNumberLabels}
            gridLayout={gridLayout}
            labelVisuallyHidden={uischema?.labelVisuallyHidden}
        >
            <FlexDiv>
                <FormControl fullWidth>
                    {!readonly && (
                        <FileUploadButton
                            isUploadingFile={isUploading}
                            hasErrors={hasErrors}
                            uploadErrors={uploadErrors}
                            errors={errors}
                            disabled={disabled}
                            onChange={handleValueChange}
                            required={required}
                            multiple
                        />
                    )}

                    {data
                        ?.filter((file) => file.originalName)
                        .map((file, i) => (
                            <UploadedDocument
                                key={i}
                                fileData={file}
                                onRemove={() => removeFile(file.uploadId)}
                                disabled={disabled}
                                readonly={readonly}
                            />
                        ))}

                    {readonly && (!data || data.length === 0) && (
                        <ReadonlyValueText label={label} hasErrors={hasErrors} errors={errors} text={'-'} />
                    )}
                </FormControl>
            </FlexDiv>
        </RowControl>
    );
};

export const MehrfachUploadControl = withCustomControlProps(MehrfachUploadControlComponent);
