import React from 'react';
import { Alert, Typography } from '@mui/material';

import { useProfilHeimfinderListActions } from 'components/DataTable/actions/useProfilHeimfinderListActions';
import { useProfilHeimfinderColumns } from 'components/DataTable/columns/useProfilHeimfinderColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ROLE_TYPES } from 'constants/roles';
import { Link } from 'elements/Link';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useProfilHeimfinderListData } from './useProfilHeimfinderListData';

const Index = () => {
    const pathBuilder = PathBuilder.home.verwaltung.profilHeimfinder;
    const { handleAction } = useProfilHeimfinderListActions({ pathBuilder });

    const columns = useProfilHeimfinderColumns(handleAction);
    const getData = useProfilHeimfinderListData();

    return (
        <ContentContainer title="Profil Heimfinder">
            <StyledContainer>
                <Typography variant="h1">Profil Heimfinder</Typography>

                <Alert severity="info">
                    Der Heimfinder NRW ist eine App des Landes über die Bürgerinnen und Bürger freie und belegbare
                    Pflegeplätze in ihrer Umgebung und in ganz Nordrhein-Westfalen finden können. Damit die Bürgerinnen
                    und Bürger auch Ihre Einrichtung im Heimfinder finden, machen Sie bitte hier Angaben zu Ihrer
                    Einrichtung, die im Heimfinder NRW zu Ihrer Einrichtung angezeigt werden sollen. Dafür klicken Sie
                    bitte unter "Aktion" auf die Bearbeitungsfunktion. Bitte machen Sie sich nach Bearbeitung aller
                    Profile mit den verschiedenen Möglichkeiten für die tägliche{' '}
                    <Link to={PathBuilder.home.verwaltung.platzmelder.path}>
                        Meldung der freien und belegbaren Plätze
                    </Link>{' '}
                    vertraut.
                </Alert>

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    persistSearchText
                    initialOptions={{
                        sort: false,
                        custom: {
                            pathBuilder,
                        },
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const ProfilHeimfinderOverviewPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PROFIL_HEIMFINDER
);
