import { useNavigate } from 'react-router-dom';

import { useFormState } from 'forms/state/useFormState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { PathBuilder } from 'navigation/Paths';

interface UseExportMessage {
    createSuccessMessage: (type?: string) => void;
    createErrorMessage: () => void;
}

export const useExportMessage = (): UseExportMessage => {
    const navigate = useNavigate();
    const { setRequestMessage } = useFormState();

    const createSuccessMessage = (type?: string) => {
        const message = type ? `Export für "${type}" gestartet.` : 'Export wurde gestartet.';

        setRequestMessage(
            successMessage(message, {
                closable: true,
            })
        );

        navigate(PathBuilder.home.auswertung.overview);
    };

    const createErrorMessage = () => {
        setRequestMessage(errorMessage('Export konnte nicht gestartet werden.'));
    };

    return {
        createSuccessMessage,
        createErrorMessage,
    };
};
