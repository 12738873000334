import React from 'react';
import { useParams } from 'react-router-dom';

import { useApiZugriffsrechteGet } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useBenutzerZugriffsrechteColumns } from 'components/DataTable/columns/useBenutzerZugriffsrechteColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { useBenutzerZugriffsrechteListData } from 'pages/Verwaltung/Zugriffsrechte/useBenutzerZugriffsrechteListData';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

const Index = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    const { einrichtungId } = useParams();

    const { isLoading, data, error, loadData } = useApiZugriffsrechteGet(einrichtungId);
    const getData = useBenutzerZugriffsrechteListData(String(einrichtungId));
    const columns = useBenutzerZugriffsrechteColumns();

    useDebouncedEffect(
        () => {
            loadData(einrichtungId);
        },
        [einrichtungId],
        100
    );

    return (
        <ContentContainer title="Zugriffsrechte verwalten">
            <StyledContainer>
                <BaseGridContainer>
                    {!error ? (
                        <AppLoaderContainer isLoading={isLoading && !data?.data}>
                            <PageHeading title={`${data?.data?.einrichtung?.name}: Zugriffsrechte`} />

                            <DataTableHydraServerside
                                getColumns={columns}
                                getData={getData}
                                initialOptions={{
                                    sortOrder: {
                                        name: 'nachname',
                                        direction: 'asc',
                                    },
                                    custom: {
                                        disableSearch: true,
                                    },
                                }}
                            />
                        </AppLoaderContainer>
                    ) : null}

                    <ButtonGroupGrid backLink={{ path: String(pathBuilder.path) }} />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const LabZugriffsrechtePage: React.FC<{ pathBuilder: PathBuilderProps }> = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG
);
