import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { DokumentPruefungListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useDokumentPruefungListData = (): GetData<DokumentPruefungListResponse> => {
    return useCallback(({ page, rowsPerPage }) => {
        return backendApiService.getDokumentPruefungCollection({
            page,
            itemsPerPage: rowsPerPage,
        });
    }, []);
};
