import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { AnschriftType } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ACTIONS } from 'constants/antragActions';
import { SimpleForm } from 'forms/SimpleForm';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { schema, uiSchema } from 'pages/Leistungsangebote/Freigegebene/deletionReasonFormConfig';

const initialFormData = {
    grund: undefined,
};

interface LeistungsangebotDeletionReasonProps {
    overviewPath: string;
}

export const LeistungsangebotDeletionReason = ({ overviewPath }: LeistungsangebotDeletionReasonProps) => {
    const { einrichtungId } = useParams();
    const navigate = useNavigate();

    const { isLoading, data, error } = useApiEinrichtungGet(einrichtungId);

    const handleSubmit = ({ grund }: { grund: string }) => {
        return backendApiService
            .workflowEinrichtung(Number(einrichtungId), ACTIONS.DELETE_EINRICHTUNG, grund)
            .catch(() => Promise.reject());
    };

    const handleAfterSubmit = () => {
        navigate(overviewPath);
    };

    return (
        <ContentContainer title="Leistungsangebot: Grund der Löschung">
            <StyledContainer>
                <PageHeading title="Leistungsangebot: Grund der Löschung" />

                {!error ? (
                    <AppLoaderContainer isLoading={isLoading && !data}>
                        <BaseGridContainer lg={10}>
                            <DetailsLayout.Container divide>
                                <DetailsLayout.InfoField value={data?.name} label="Name des Leistungsangebots" />
                                <DetailsLayout.InfoField value={data?.nrwKey} label="NRW-Schlüssel" />
                                {data?.adresse?.anschriftTyp === AnschriftType.HAUSANSCHRIFT ? (
                                    <DetailsLayout.InfoField
                                        value={`${data?.adresse?.strasse} ${data?.adresse?.hausnummer}, ${data?.adresse?.plz} ${data?.adresse?.ort}`}
                                        label="Hausanschrift"
                                    />
                                ) : null}

                                {data?.adresse?.anschriftTyp === AnschriftType.POSTFACH ? (
                                    <DetailsLayout.InfoField
                                        value={`${data?.adresse?.postfachBezeichnung}, ${data?.adresse?.plz} ${data?.adresse?.ort}`}
                                        label="Postfach"
                                    />
                                ) : null}
                            </DetailsLayout.Container>
                        </BaseGridContainer>

                        {data ? (
                            <SimpleForm
                                data={initialFormData}
                                schema={schema}
                                uiSchema={uiSchema}
                                submitLabel="Löschung fortsetzen"
                                onSubmit={handleSubmit}
                                onAfterSubmit={handleAfterSubmit}
                                backLink={{
                                    path: overviewPath,
                                    title: 'Abbrechen',
                                }}
                                messages={{
                                    success: 'Die Löschung des Leistungsangebots war erfolgreich.',
                                    error: 'Die Löschung des Leistungsangebots ist fehlgeschlagen.',
                                }}
                            />
                        ) : null}
                    </AppLoaderContainer>
                ) : (
                    <Alert severity="error">Beim Laden des Leistungsangebots ist ein Fehler aufgetreten.</Alert>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};
