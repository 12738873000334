import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';

import { useHasRole } from 'api/auth';
import { useTraegerverbandAntragListActions } from 'components/DataTable/actions/useTraegerverbandAntragListActions';
import { useTraegerverbandColumns } from 'components/DataTable/columns/useTraegerverbandColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ROLE_TYPES } from 'constants/roles';
import { Link } from 'elements/Link';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useTraegerverbandListData } from './useTraegerverbandListData';

const Index = () => {
    const hasRoles = useHasRole();
    const canCreate = hasRoles(ROLE_TYPES.ROLLEN_TRAEGERVERBAENDE_FORM);

    const { serverDataChanged, handleAction } = useTraegerverbandAntragListActions();

    const getData = useTraegerverbandListData();

    const columns = useTraegerverbandColumns(handleAction);

    return (
        <ContentContainer title="Übersicht der Trägerverbände">
            <StyledContainer>
                <Typography variant="h1">Trägerverbände</Typography>

                <BaseGridContainer>
                    {canCreate ? (
                        <ButtonGroupGrid marginBottom="24px">
                            <Button
                                component={Link}
                                to={PathBuilder.home.verwaltung.traegerverband.create}
                                variant="contained"
                                color="primary"
                                endIcon={<AddIcon />}
                            >
                                Verband hinzufügen
                            </Button>
                        </ButtonGroupGrid>
                    ) : null}

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                        initialOptions={{
                            viewColumns: true,
                            sortOrder: {
                                name: 'name',
                                direction: 'asc',
                            },
                            custom: {
                                pathBuilder: PathBuilder.home.verwaltung.traegerverband,
                            },
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const TraegerverbandOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_TRAEGERVERBAENDE
);
