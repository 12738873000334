import { Card, styled } from '@mui/material';

interface StyledCardProps {
    minWidth?: string;
    borderRadius?: string | number;
    padding?: number;
}

export const StyledCard = styled(Card)<StyledCardProps>(({ theme, minWidth, borderRadius, padding }) => {
    return `
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-width: ${minWidth || '275px'};
            border-radius: ${borderRadius || '4px'};
            padding-right: ${theme.spacing(padding ?? 2)};
            padding-bottom: ${padding ? theme.spacing(padding) : 'initial'};
            padding-left: ${theme.spacing(padding ?? 2)};
            margin-bottom: ${theme.spacing(3)};
            height: 100% !important;
        `;
});
