import React from 'react';
import { Navigate } from 'react-router-dom';

import { useHasRole, useIsLoggedIn } from 'api/auth';
import { ROLES } from 'constants/roles';
import { Error404Page } from 'pages/Error404';

import { PathBuilder } from './Paths';

export const withAuthorization = (Component: React.FC<any>, roles: string[] = []): React.FC => {
    return (props) => {
        const hasRole = useHasRole();
        const isLoggedIn = useIsLoggedIn();
        const accessDenied = roles.length && !hasRole(roles);

        if (!isLoggedIn) {
            return <Navigate to={PathBuilder.home.path} replace />;
        }

        if (accessDenied && !hasRole([ROLES.PASSWORD_RESET])) {
            return <Error404Page />;
        }

        return <Component {...props} />;
    };
};
