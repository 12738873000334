export enum ACTIONS {
    ABBRECHEN = 'ABBRECHEN',
    ABLEHNEN = 'ABLEHNEN',
    ANTRAG_VIEW = 'ANTRAG_VIEW',
    AUFFORDERN = 'AUFFORDERN',
    BEARBEITUNG_STARTEN = 'BEARBEITUNG_STARTEN',
    BENUTZER_ACTIVATE = 'BENUTZER_ACTIVATE',
    BENUTZER_DEACTIVATE = 'BENUTZER_DEACTIVATE',
    BESCHEID_AENDERN = 'BESCHEID_AENDERN',
    BESCHEIDEN = 'BESCHEIDEN',
    CONFIRM_DELETE = 'CONFIRM_DELETE',
    DELETE = 'DELETE',
    DELETE_EINRICHTUNG = 'DELETE_EINRICHTUNG',
    EDIT = 'EDIT',
    EINREICHEN = 'EINREICHEN',
    EMAIL_SEND = 'EMAIL_SEND',
    ERNEUT_EINREICHEN = 'ERNEUT_EINREICHEN',
    FREIGEBEN = 'FREIGEBEN',
    LEISTUNGSANGEBOT_ERSTELLEN = 'LEISTUNGSANGEBOT_ERSTELLEN',
    MANUELLE_ZUORDNUNG = 'MANUELLE_ZUORDNUNG',
    NACHRICHTEN = 'NACHRICHTEN',
    PRUEFEN = 'PRUEFEN',
    SPEICHERN = 'SPEICHERN',
    TREFFER_ZUORDNUNG = 'TREFFER_ZUORDNUNG',
    VIEW = 'VIEW',
    ZUGRIFFSRECHTE = 'ZUGRIFFSRECHTE',
    ZUGRIFFSRECHTE_ANSEHEN = 'ZUGRIFFSRECHTE_ANSEHEN',
    ZUGRIFFSRECHTE_ENTFERNEN = 'ZUGRIFFSRECHTE_ENTFERNEN',
    ZUR_KENNTNIS_NEHMEN = 'ZUR_KENNTNIS_NEHMEN',
    ZUR_PRUEFUNG_FREIGEBEN = 'ZUR_PRUEFUNG_FREIGEBEN',
    ZUGEHOERIGE_EINRICHTUNGEN = 'ZUGEHOERIGE_EINRICHTUNGEN',
    ZURUECKWEISEN = 'ZURUECKWEISEN',
    ZUSTAENDIGKEIT = 'ZUSTAENDIGKEIT',
    ZUSTAENDIGKEIT_LOESCHEN = 'ZUSTAENDIGKEIT_LOESCHEN',
    CREATE_MELDUNG = 'CREATE_MELDUNG',
    CREATE_REGELPRUEFUNG = 'CREATE_REGELPRUEFUNG',
    RETRY = 'RETRY',
    INVESTANMELDUNG = 'INVESTANMELDUNG',
    BEARBEITUNG_ABSCHLIESSEN = 'BEARBEITUNG_ABSCHLIESSEN',
    GELESEN = 'GELESEN',
    UNGELESEN = 'UNGELESEN',
}

type Action = string;
type ActionGroup = number;
export type AntragActions = Record<Action, ActionGroup>;
export const TABLE_ACTIONS: AntragActions = {
    [ACTIONS.VIEW]: 0,
    [ACTIONS.CREATE_MELDUNG]: 0,
    [ACTIONS.CREATE_REGELPRUEFUNG]: 0,
    [ACTIONS.ANTRAG_VIEW]: 0,
    [ACTIONS.NACHRICHTEN]: 0,
    [ACTIONS.EDIT]: 0,
    [ACTIONS.EINREICHEN]: 0,
    [ACTIONS.ERNEUT_EINREICHEN]: 0,
    [ACTIONS.ZUGEHOERIGE_EINRICHTUNGEN]: 0,
    [ACTIONS.ZUSTAENDIGKEIT_LOESCHEN]: 1,
    [ACTIONS.ZUSTAENDIGKEIT]: 1,
    [ACTIONS.ZUGRIFFSRECHTE]: 1,
    [ACTIONS.ZUGRIFFSRECHTE_ENTFERNEN]: 1,
    [ACTIONS.MANUELLE_ZUORDNUNG]: 1,
    [ACTIONS.TREFFER_ZUORDNUNG]: 1,
    [ACTIONS.LEISTUNGSANGEBOT_ERSTELLEN]: 1,
    [ACTIONS.AUFFORDERN]: 1,
    [ACTIONS.ABBRECHEN]: 1,
    [ACTIONS.BEARBEITUNG_STARTEN]: 1,
    [ACTIONS.BESCHEID_AENDERN]: 1,
    [ACTIONS.ZUR_KENNTNIS_NEHMEN]: 2,
    [ACTIONS.PRUEFEN]: 2,
    [ACTIONS.DELETE]: 3,
    [ACTIONS.DELETE_EINRICHTUNG]: 3,
    [ACTIONS.BENUTZER_ACTIVATE]: 3,
    [ACTIONS.BENUTZER_DEACTIVATE]: 3,
    [ACTIONS.RETRY]: 4,
    [ACTIONS.INVESTANMELDUNG]: 4,
    [ACTIONS.GELESEN]: 4,
    [ACTIONS.UNGELESEN]: 4,
};

export const ANTRAG_ACTIONS: AntragActions = {
    [ACTIONS.SPEICHERN]: 0,
    [ACTIONS.EINREICHEN]: 1,
    [ACTIONS.ZURUECKWEISEN]: 2,
    [ACTIONS.FREIGEBEN]: 3,
};
