import { isObject } from 'lodash';

export const getIriId = (iri: string | undefined | null): string | undefined => {
    if (!iri || isObject(iri)) return undefined;
    return iri.substring(iri.lastIndexOf('/') + 1, iri.length);
};

export function toIriId(api: string, id: null | undefined): undefined;
export function toIriId(api: string, id: string | number): string;
export function toIriId(api: string, id: string | number | null | undefined): string | undefined;
export function toIriId(api: string, id: string | number | null | undefined): string | undefined {
    if (!id) return undefined;
    return `${api}/${id}`;
}
