import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { GetEinrichtungenForPlatzzahlmelder200Response } from 'api/client';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';
import { BELEGBARE_PLAETZE_STATUS_FILTER_TYPES } from 'constants/labels';

export const usePlatzmelderListData = (): GetData<GetEinrichtungenForPlatzzahlmelder200Response> => {
    return useCallback(({ page, rowsPerPage, searchText, sortOrder, filters }) => {
        return backendApiService.getListForPlatzzahlmelder({
            page,
            itemsPerPage: rowsPerPage,
            qSearch: searchText,
            art2: filters?.['art'],
            orderName: sortBy(sortOrder, 'name'),
            orderArt: sortBy(sortOrder, 'art'),
            orderNrwKey: sortBy(sortOrder, 'nrwKey'),
            orderErstinbetriebnahme: sortBy(sortOrder, 'datumErstinbetriebnahme'),
            orderAdressePlz: sortBy(sortOrder, 'adressePlz'),
            orderAdresseOrt: sortBy(sortOrder, 'adresseOrt'),
            orderBelegbarePlaetzeModifiedAt: sortBy(sortOrder, 'belegbarePlaetze.modifiedAt'),
            orderBelegbarePlaetzeAufgefordert: sortBy(sortOrder, 'belegbarePlaetze.aufgefordert'),
            orderBelegbarePlaetzeDauerpflege: sortBy(sortOrder, 'belegbarePlaetze.dauerpflegeplaetze'),
            orderBelegbarePlaetzeKurzzeitpflege: sortBy(sortOrder, 'belegbarePlaetze.kurzpflegeplaetze'),
            belegbarePlaetzeStatus: filters?.['belegbarePlaetzeStatus']?.[0] as BELEGBARE_PLAETZE_STATUS_FILTER_TYPES,
        });
    }, []);
};
