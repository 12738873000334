import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useNavigationCallback = (onNavigation: () => void) => {
    const { pathname } = useLocation();
    const pathRef = useRef<string>();

    useEffect(() => {
        if (pathname === pathRef.current) {
            return;
        }

        pathRef.current = pathname;
        onNavigation();
    }, [onNavigation, pathname]);
};
