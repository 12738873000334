import { useMemo } from 'react';

import { AuthUser } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';

/**
 * @returns {true} If the user is authenticated.
 * @returns {false} If the user is not authenticated.
 */
export const useIsLoggedIn = (): boolean => {
    return useAuthState(({ token }) => Boolean(token));
};

/**
 * @returns {true} If impersonation is active.
 * @returns {false} If impersonation is not active.
 */
export const useIsImpersonated = (): boolean => {
    return useAuthState(({ impersonator }) => Boolean(impersonator));
};

/**
 * @returns {AuthUser} The authenticated user.
 * @throws {Error} If the hook is used in a public component.
 */
export const useAuthUser = (): AuthUser => {
    const isLoggedIn = useIsLoggedIn();
    const { user, impersonator } = useAuthState(({ user, impersonator }) => ({ user, impersonator }));

    if (!isLoggedIn) return {} as AuthUser;
    if (!user) throw Error('User cannot be null.');

    return impersonator ? impersonator : user;
};

/**
 * @returns {number} The token expiration timestamp.
 * @returns {null} If the user is not authenticated.
 */
export const useExpiresAt = (): number | null => {
    return useAuthState(({ expiresAt }) => expiresAt);
};

/**
 * @returns {number} The authenticated users database id.
 */
export const useAuthUserId = (): number | undefined => {
    const { benutzerId } = useAuthUser();
    return benutzerId;
};

/**
 * @returns {string} The authenticated users name.
 */
export const useAuthUsername = (): string => {
    const user = useAuthUser();

    return `${user?.vorname} ${user?.nachname}`;
};

/**
 * @returns {number} The authenticated users database id.
 */
export const useAuthUserIdOptional = (): number | undefined => {
    const { user, impersonator } = useAuthState(({ user, impersonator }) => ({ user, impersonator }));
    if (impersonator) return impersonator.benutzerId;
    if (user) return user.benutzerId;
    return undefined;
};

/**
 * Hook to wrap the id inside an array.
 * @returns {number[]} The authenticated users database id inside an array.
 */
export const useAuthUserIds = (): (number | undefined)[] => {
    const id = useAuthUserId();
    return useMemo(() => [id], [id]);
};

/**
 * @returns {string[]} The authenticated users roles.
 */
export const useAuthUserRoles = (): string[] => {
    const { roles } = useAuthUser();
    return roles || [];
};
