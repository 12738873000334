import { useLayoutWithJsonFormsState } from 'forms/hooks/useJsonFormsState';

interface GridConfig {
    label: number;
    input: number;
}

interface FormLayoutConfig {
    gridLayout: GridConfig;
}

export const useFormLayoutConfig = (): FormLayoutConfig => {
    const configLayout: FormLayoutConfig = {
        gridLayout: {
            label: 0,
            input: 12,
        },
    };

    return useLayoutWithJsonFormsState(configLayout);
};
