import React from 'react';

import { leistungsanbieterApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { leistungsanbieterTabConfig } from 'forms/LeistungsanbieterForm/leistungsanbieterTabConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AntragPage } from 'pages/AntragPage';

const Index = () => {
    return (
        <AntragPage
            headline="Leistungsanbieter freigeben"
            antragType={AntragType.LEISTUNGSANBIETER}
            title={'Leistungsanbieter freigeben'}
            pathBuilder={PathBuilder.home.aufgaben.freigeben.leistungsanbieter}
            tabConfig={leistungsanbieterTabConfig}
            workflowFormItem={backendApiService.workflowLeistungsanbieterItem.bind(backendApiService)}
            api={leistungsanbieterApi}
        />
    );
};

export const LeistungsanbieterBearbeitenPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_LEISTUNGSANBIETER_FREIGEBEN
);
