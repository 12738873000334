import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { EmailAntragCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { ReadStatus } from 'components/DataTable/components/ReadStatus';
import { EmptyColumnHeader } from 'components/DataTable/EmptyColmnHeader';
import { BenachrichtigungStatusFilterOptions } from 'components/DataTable/filters/BenachrichtigungStatusFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getRowData } from 'components/DataTable/tableUtils';
import { EMAIL_ANTRAG_ART_LABELS } from 'constants/emailAntragArt';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

import { BenutzerStatusBadge } from './StyledColumnComponents';

export const useNachrichtenAnbieterColumns = (
    handleAction: AntragListAction<any>,
    setServerDataChanged: Dispatch<SetStateAction<number>>
): ((data: EmailAntragCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { transform2Date } = useFormatDate();
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) {
                return [];
            }

            return [
                IdColumn(),
                {
                    name: 'read',
                    label: 'Status der Benachrichtigung',
                    options: {
                        customHeadRender: EmptyColumnHeader,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            const rowData = getRowData(dataIndex, data);

                            return (
                                <ReadStatus
                                    data={rowData}
                                    rowIndex={rowIndex}
                                    onStatusChanged={() => setServerDataChanged((prevState) => prevState + 1)}
                                />
                            );
                        },
                        ...BenachrichtigungStatusFilterOptions(),
                    },
                },
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                            return getRowData(dataIndex, data)?.einrichtung?.nrwKey;
                        },
                    },
                },
                {
                    name: 'einrichtungName',
                    label: 'Leistungsangebot',
                    options: {
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                            return getRowData(dataIndex, data)?.einrichtung?.name;
                        },
                    },
                },
                {
                    name: 'betreff',
                    label: 'Betreff',
                    options: {
                        filter: false,
                        sort: false,
                    },
                },
                {
                    name: 'typ',
                    label: 'Art',
                    options: {
                        filter: false,
                        customBodyRender: (value) => EMAIL_ANTRAG_ART_LABELS?.[value] || '-',
                    },
                },
                {
                    name: 'sender',
                    label: 'Absender',
                    options: {
                        filter: false,
                        customBodyRenderLite: (dataIndex) => (
                            <BenutzerStatusBadge sender={getRowData(dataIndex, data)?.sender} />
                        ),
                    },
                },
                {
                    name: 'eingereichtAt',
                    label: 'Empfangen am',
                    options: {
                        filter: false,
                        customBodyRender: (value) => (value ? transform2Date(value, 'dd.MM.yyyy - HH:mm') : '-'),
                    },
                },
                actions(data),
            ];
        },
        [actions, setServerDataChanged, transform2Date]
    );
};
