import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { MeldungAnsehen } from 'pages/Meldung/Components/MeldungAnsehen';

const Index = () => {
    return (
        <MeldungAnsehen
            title="Zusmamenfassung der Meldung"
            headline="Zusammenfassung der Meldung"
            pathBuilder={PathBuilder.home.aufgaben.meldungen.ohneZustaendigkeit}
        />
    );
};

export const MeldungDetail = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_OHNE_ZUSTAENDIGKEIT
);
