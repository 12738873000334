import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    info({
        message:
            'Der Heimfinder NRW ist eine App des Landes, über die Bürgerinnen und Bürger freie und belegbare Pflegeplätze in ihrer Umgebung und in ganz Nordrhein-Westfalen finden können. Bitte machen Sie hier Angaben zu Ihrer Einrichtung, die im Heimfinder NRW angezeigt werden sollen.',
    }),
    group(
        [
            control('name'),
            inlineRowControl([control('telefon'), control('fax')]),
            control('email'),
            control('homepage'),
        ],
        undefined,
        'Allgemeine Informationen'
    ),
    group(
        [
            control('upload'),
            info({
                message:
                    'Für die Inhalte der hochgeladenen Dateien tragen Sie selbst im vollen Umfang die Verantwortung. Bitte stellen Sie daher sicher, dass Ihnen zu den auf Ihren Bildern zu erkennenden Personen eine Zustimmungserklärung vorliegt.',
                options: { type: 'Warning' },
            }),
        ],
        { unstyled: true },
        'Bild des Leistungsangebots'
    ),
]);
