import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { EinrichtungCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BenutzerOptions } from 'components/DataTable/filters/ZustaendigerBenutzerFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { OpenViewTab } from 'components/DataTable/hooks/useTableOptions';

export const useMeldungenEinrichtungenColumns = (
    handleAction: AntragListAction<any>,
    zustaendigerBenutzerOptions?: BenutzerOptions,
    openViewTab?: OpenViewTab
): ((data: EinrichtungCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({
        handleAction,
        openViewTab,
    });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'name',
                    label: 'Name',
                    options: {
                        filter: false,
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
