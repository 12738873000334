import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { TraegerDetail } from './TraegerDetail';

const Index = () => {
    return <TraegerDetail />;
};

export const TraegerMandantDetailPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_TRAEGER_MANDANT_ANSEHEN
);
