import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { WohngemeinschaftCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useAbwListData = (existsEinrichtung?: boolean): GetData<WohngemeinschaftCollectionResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getWohngemeinschaftCollection({
                page,
                qSearch: searchText,
                existsEinrichtung,
                itemsPerPage: rowsPerPage || 9999,
                orderName: sortBy(sortOrder, 'name'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtung'),
                orderAmbulanterDienstName: sortBy(sortOrder, 'ambulanterDienst'),
                orderAdresseStrasse: sortBy(sortOrder, 'anschrift'),
                orderPlaetze: sortBy(sortOrder, 'plaetze'),
                orderBeginn: sortBy(sortOrder, 'beginn'),
                orderCreatedAt: sortBy(sortOrder, 'registrierungsDatum'),
            });
        },
        [existsEinrichtung]
    );
};
