import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiWohngemeinschaftItem } from 'api/hooks/useApiClient';
import { SimpleForm, SimpleFormConfig } from 'forms/SimpleForm';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';
import { schema, uiSchema } from 'pages/Verwaltung/ABW/components/zuordnungConfig';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

interface AbwZuordnungProps {
    title: string;
    pathBuilder?: PathBuilderProps;
    config?: SimpleFormConfig;
}

export const AbwZuordnung = ({ title, pathBuilder, config }: AbwZuordnungProps) => {
    const { id } = useParams();
    const { loadBenutzerAufgaben } = useBenutzerAufgaben();
    const navigate = useNavigate();

    const { isLoading, data } = useApiWohngemeinschaftItem(id);

    const formConfig: SimpleFormConfig = useMemo(() => {
        if (config?.addAdresseCoords && data) {
            return {
                adresseCx: String(data.adresse?.cx),
                adresseCy: String(data.adresse?.cy),
            };
        }

        return {};
    }, [config, data]);

    return (
        <ContentContainer title={`Angezeigte Betreuung in Wohngemeinschaften: ${title}`}>
            <StyledContainer>
                <Typography variant="h1">Angezeigte Betreuung in Wohngemeinschaften: {title}</Typography>

                <BaseGridContainer lg={8} justifyContent="center">
                    <DetailsLayout.Container divide={false}>
                        <DetailsLayout.InfoField value={data?.name} label="Name der Wohngemeinschaft" />
                        <DetailsLayout.InfoField value={data?.adresse?.label} label="Hausanschrift" />
                        <DetailsLayout.InfoField value={data?.beginn} label="Datum Beginn betreuende Tätigkeit" />
                        <DetailsLayout.InfoField value={data?.plaetze} label="Anzahl der betreuten Nutzer" />
                        <DetailsLayout.InfoField
                            value={
                                data?.ambulanterDienst?.name
                                    ? `${data.ambulanterDienst.name} (${data.ambulanterDienst.nrwKey})`
                                    : undefined
                            }
                            label="Ambulanter Dienst"
                        />
                    </DetailsLayout.Container>
                </BaseGridContainer>

                <StyledDivider />

                <SimpleForm
                    itemId={id}
                    hideTitle
                    isLoading={isLoading}
                    formConfig={formConfig}
                    data={data}
                    schema={schema}
                    uiSchema={uiSchema}
                    messages={{
                        success: 'Wohngemeinschaft wurde zugeordnet.',
                        error: 'Zuordnung der Wohngemeinschaft ist fehlgeschlagen.',
                    }}
                    onSubmit={backendApiService.patchWohngemeinschaftItem.bind(backendApiService)}
                    onAfterSubmit={async () => {
                        loadBenutzerAufgaben();
                        navigate(pathBuilder?.path || '');
                    }}
                    submitLabel="Zuordnung Speichern"
                    backLink={{
                        path: pathBuilder?.path || '',
                        title: 'Zur Übersicht',
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};
