// @See: https://mui.com/material-ui/about-the-lab/#typescript
import type { LabComponents } from '@mui/lab/themeAugmentation';
import { Components, createTheme, ThemeOptions } from '@mui/material';

import '@fontsource/open-sans';
import 'mui-datatables';
import './global.css';
import { mixins } from './mixins';
import { palette } from './palette';
import { typography } from './typography';

type ThemeOverrides = Components & LabComponents;

const overrides: ThemeOverrides = {
    MuiCssBaseline: {
        styleOverrides: {
            address: {
                fontStyle: 'normal',
            },
            ul: {
                paddingLeft: 20,
            },
            ol: {
                paddingLeft: 20,
            },
            dl: {
                display: 'grid',
                gridTemplateColumns: '25% auto',
            },
            dt: {
                fontWeight: 'bold',
                gridColumn: 1,
            },
            dd: {
                gridColumn: 2,
            },
            a: {
                color: '#233755',
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:visited': {
                    color: '#2b1c44',
                },
                '&.MuiListItemButton-root:visited': {
                    color: 'inherit',
                },
            },
            'a.arrowLink': {
                '&::before': {
                    verticalAlign: 'text-bottom',
                    content: '"» "',
                },
            },
        },
    },
    MuiGrid: {
        styleOverrides: {
            item: {
                '&:empty': {
                    display: 'none',
                },
            },
        },
    },
    MuiBadge: {
        styleOverrides: {
            badge: {
                fontWeight: 600,
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: '14px',
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paperScrollPaper: {
                maxHeight: 'calc(100% - 152px)',
            },
        },
    },
    MuiList: {
        styleOverrides: {
            root: {
                textWrap: 'balance',
                '&.numbered-list': {
                    listStyle: 'decimal',
                    marginLeft: '18px',

                    '& .MuiListItem-root': {
                        display: 'list-item',
                        paddingLeft: '2px',

                        '&::marker': {
                            fontWeight: 700,
                        },
                        '& .MuiAlert-root:last-child': {
                            marginBottom: '36px',
                        },
                    },
                    '& .MuiAlert-root': {
                        marginLeft: '-18px',
                        marginRight: '-16px',
                        marginBottom: 0,
                    },
                },
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderWidth: '1px',
                borderColor: palette.primary.main,
                borderRadius: 4,

                '&:hover, &:focus': {
                    borderColor: palette.primary.dark,
                },
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            subtitle1: {
                fontWeight: 'bold',
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                },
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            root: {
                color: 'inherit',
                '.MuiFormControl-root &': {
                    color: '#006ECB',
                },
                '.Mui-disabled &': {
                    color: '#999',
                },
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                margin: 10,
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                borderRadius: 4,

                legend: {
                    marginBottom: 8,
                },
                '.readonly-text': {
                    padding: 16,
                    backgroundColor: palette.primary.lighter,
                },
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                backgroundColor: '#fff',
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                color: '#000',
                '&:focus': {
                    borderColor: '#006ECB',
                },
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                'legend&': {
                    marginTop: 9,
                    color: '#333',
                    lineHeight: 1.4,
                },
            },
            asterisk: {
                color: palette.error.main,
                '&$error': {
                    color: palette.error.main,
                },
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            root: {
                marginBottom: 15,
                borderRadius: '4px',
                '& .MuiSvgIcon-root': {
                    color: 'inherit',
                },
            },
            standardInfo: {
                backgroundColor: '#EAEFF6',
            },
            message: {
                width: '100%',
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                fontWeight: 400,
                color: '#006ECB',
                '& .Mui-selected': {
                    color: '#006ECB',
                },
                '& .Mui-disabled': {
                    color: '#AAA',
                },
                '& button': {
                    fontSize: '14px',
                    fontWeight: 400,
                    textTransform: 'uppercase',
                },
                '& .MuiTabs-scrollButtons.Mui-disabled': {
                    opacity: 0.3,
                },
            },
            indicator: {
                height: '2px',
                backgroundColor: '#006ECB',
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontWeight: 'bold',
                '&selected': {
                    color: '#006ECB',
                },
            },
        },
    },
    MuiTabPanel: {
        styleOverrides: {
            root: {
                padding: 0,
                paddingTop: '36px',
                paddingBottom: '76px',
                '& p': {
                    marginBottom: '36px',
                },
            },
        },
    },
    MuiTableSortLabel: {
        styleOverrides: {
            root: {
                width: '18px',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&$hover:hover': {
                    backgroundColor: '#EAEFF6',
                },
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            footer: {
                border: 0,
            },
            head: {
                fontWeight: 'bolder',
            },
        },
    },
    //@ts-ignore
    MUIDataTableHeadCell: {
        styleOverrides: {
            fixedHeader: {
                backgroundColor: 'transparent',
            },
            data: {
                fontWeight: 'bolder',
            },
            sortAction: {
                lineHeight: '1.25rem',
            },
            root: {
                '& .MuiButtonBase-root': {
                    textAlign: 'left',
                },
            },
        },
    },
    MUIDataTableFilter: {
        styleOverrides: {
            header: {},
            root: {
                '& .MuiGrid-root': {
                    alignItems: 'normal',
                },
                '& .MuiAutocomplete-root': {
                    marginTop: '16px',
                },
            },
        },
    },
    MUIDataTable: {
        styleOverrides: {
            paper: {
                boxShadow: 'none',
            },
            root: {
                overflow: 'hidden',
            },
            responsiveScroll: {
                overflow: 'visible',
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: '#233755',

                '&.MuiButton-outlinedPrimary:visited': {
                    color: '#1976D2',
                },

                '&.MuiButton-containedPrimary:visited': {
                    color: '#ffffff',
                },
            },
        },
        defaultProps: {
            underline: 'always',
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: 4,
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: 'outlined',
            size: 'small',
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: 'outlined',
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            text: {
                fontWeight: 600,
            },
        },
    },
    MUIDataTableToolbarSelect: {
        styleOverrides: {
            root: {
                minHeight: 64,
            },
        },
    },
    MUIDataTableToolbar: {
        styleOverrides: {
            root: {
                minHeight: 'initial',
                paddingLeft: 0,
                paddingRight: 0,
            },
            actions: {
                paddingTop: '16px',
            },
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
            },
            title: {
                fontSize: typography.h2.fontSize,
                fontWeight: typography.h2.fontWeight,
            },
        },
    },
};

export const themeOptions: ThemeOptions = {
    components: {
        ...overrides,
    },
    typography: {
        ...typography,
        button: {
            textTransform: 'none', // keine Ahnung warum das nicht innerhalb der importierten typography funktioniert
            fontSize: 16,
        },
    },
    palette: {
        ...palette,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1450,
        },
    },
    mixins: {
        ...mixins,
    },
    shape: {
        borderRadius: 0,
    },
    zIndex: {
        drawer: 1000,
        modal: 1001,
        snackbar: 1002,
        tooltip: 1003,
    },
};

export const theme = createTheme({ ...themeOptions });
