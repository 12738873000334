import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { LeistungsangebotEntwurfCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';
import { STATUS } from 'constants/antragStatus';

interface UseLeistungsangebotListDataProps {
    status?: STATUS;
}

export const useLeistungsangebotListData = ({
    status,
}: UseLeistungsangebotListDataProps): GetData<LeistungsangebotEntwurfCollectionResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getLeistungsangebotEntwurfCollection({
                page,
                itemsPerPage: rowsPerPage,
                status,
                qSearch: searchText,
                orderLeistungsanbieterName: sortBy(sortOrder, 'leistungsanbieterName'),
                orderLeistungsanbieterStatus: sortBy(sortOrder, 'leistungsanbieter'),
                orderOrt: sortBy(sortOrder, 'ort'),
                orderErstinbetriebnahme: sortBy(sortOrder, 'erstinbetriebnahme'),
                orderEinrichtungName: sortBy(sortOrder, 'einrichtungName'),
                orderModifiedAt: sortBy(sortOrder, 'modifiedAt'),
                orderZuletztEingereichtAt: sortBy(sortOrder, 'zuletztEingereichtAt'),
            });
        },
        [status]
    );
};
