import React from 'react';
import { Typography } from '@mui/material';

import { ResendVerificationEmail } from 'components/ResendVerificationEmail';
import { ContentContainer } from 'layout/container/ContentContainer';
import useQuery from 'utilities/hooks/useQuery';

export const Success = () => {
    const query = useQuery();
    const userEmail = query.get('email');
    const userInfoText = userEmail ? `an ${userEmail}` : '';

    return (
        <ContentContainer title="Registrierung abschließen">
            <Typography variant="h1">Registrierung erfolgreich</Typography>

            <Typography paragraph>
                Ihre E-Mail-Adresse ist zeitgleich Ihr Benutzername in PfAD.wtg.
                <br />
                Erst nach erfolgreicher Bestätigung Ihrer E-Mail-Adresse wird diese als Benutzername in PfAD.wtg
                akzeptiert.
            </Typography>

            <Typography paragraph>Eine Bestätigungs-Mail wurde {userInfoText} versendet.</Typography>

            <ResendVerificationEmail email={userEmail} />
        </ContentContainer>
    );
};
