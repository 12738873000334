import { ROLE_TYPES } from 'constants/roles';
import { MenuInterface } from 'layout/HeaderConfig';
import { PathBuilder } from 'navigation/Paths';

export const menuAufgaben: MenuInterface[] = [
    {
        path: PathBuilder.home.aufgaben.freigeben.leistungsangebote.path,
        name: 'Leistungsangebote freigeben',
        aufgabenBadge: ['leistungsangebotFreigeben'],
        roles: ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEBEN,
    },
    {
        path: PathBuilder.home.aufgaben.abw.path,
        name: 'Angezeigte Betreuung in Wohngemeinschaften zuordnen ',
        aufgabenBadge: ['wohngemeinschaftZuordnen'],
        roles: ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN,
    },
    {
        name: 'Leistungsanbieter freigeben',
        path: PathBuilder.home.aufgaben.freigeben.leistungsanbieter.path,
        aufgabenBadge: ['leistungsanbieterFreigeben'],
        divide: true,
        roles: ROLE_TYPES.ROLLEN_LEISTUNGSANBIETER_FREIGEBEN,
    },
    {
        path: PathBuilder.home.aufgaben.meldungen.labsOhneMeldung.path,
        name: 'Leistungsangebote ohne Meldung',
        aufgabenBadge: ['leistungsangebotOhneMeldung'],
        divide: true,
        roles: ROLE_TYPES.ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG,
    },
    {
        path: PathBuilder.home.aufgaben.meldungen.ohneZustaendigkeit.path,
        name: 'Meldungen ohne Zuständigkeit',
        aufgabenBadge: ['meldungOhneZustaendigkeit'],
        roles: ROLE_TYPES.ROLLEN_MELDUNGEN_OHNE_ZUSTAENDIGKEIT,
    },
    {
        path: PathBuilder.home.aufgaben.freigeben.meldungen.path,
        name: 'Meldungen freigeben',
        aufgabenBadge: ['meldungFreigeben'],
        roles: ROLE_TYPES.ROLLEN_MELDUNGEN_FREIGEBEN,
    },
    {
        path: PathBuilder.home.aufgaben.meldungen.aufgefordert.path,
        name: 'Zur Meldung aufgefordert',
        aufgabenBadge: ['zurMeldungAufgefordert'],
        roles: ROLE_TYPES.ROLLEN_MELDUNGEN_AUFGEFORDERT,
    },
    {
        path: PathBuilder.home.aufgaben.deletedObjects.path,
        name: 'Gelöschte Objekte',
        divide: true,
        aufgabenBadge: ['geloeschteObjekte'],
        roles: ROLE_TYPES.ROLLEN_DELETED_OBJECTS,
    },
];
