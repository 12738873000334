import { useCallback } from 'react';

import { authLog } from 'api/auth/AuthTypes';
import { ROLES } from 'constants/roles';

import { useAuthUser } from './useAuthStateHelpers';

/**
 * Provides a function to determine if the authenticated user has the given role or roles.
 */
export const useHasRole = (): ((roles: string[] | string | undefined) => boolean) => {
    const user = useAuthUser();

    return useCallback(
        (roles) => {
            authLog('[UseHasRole.HasRole]', roles);
            if (!user || !user.roles) return false;
            if (!roles || roles.includes(ROLES.USER)) return true;
            if (typeof roles === 'string') return user.roles.includes(roles);
            return user.roles.some((role) => roles.includes(role));
        },
        [user]
    );
};

type UseSpecificRoleKeys =
    | 'isAnbieter'
    | 'isBehoerde'
    | 'isTraeger'
    | 'isAmbulanterDienst'
    | 'isEinrichtungBenutzer'
    | 'isLV'
    | 'isMags'
    | 'isBezirksregierung'
    | 'isMonitoring'
    | 'isSupport';

export const useSpecificRole = (): Record<UseSpecificRoleKeys, boolean> => {
    const user = useAuthUser();
    const hasRole = (role: string) => (user?.roles || []).includes(role);

    return {
        isAnbieter: hasRole(ROLES.ANBIETER),
        isTraeger: hasRole(ROLES.TRAEGER),
        isBehoerde: hasRole(ROLES.BEHOERDE),
        isAmbulanterDienst: hasRole(ROLES.ROLE_AMBULANTER_DIENST),
        isEinrichtungBenutzer: hasRole(ROLES.EINRICHTUNG_BENUTZER),
        isLV: hasRole(ROLES.LV),
        isMags: hasRole(ROLES.MINISTERIUM),
        isMonitoring: hasRole(ROLES.MONITORING),
        isBezirksregierung: hasRole(ROLES.BEZIERKSREGIERUNG),
        isSupport: hasRole(ROLES.SUPPORT),
    };
};
