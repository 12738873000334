import React, { useMemo } from 'react';
import { Mail } from '@mui/icons-material';
import { Badge, styled } from '@mui/material';

import { useApiEmailAntragCollection } from 'api/hooks/useApiClient';
import { PathBuilder } from 'navigation/Paths';

import { DashboardCard } from './DashboardCard';
import { DashboardMessages } from './DashboardMessages';

const MailIcon = styled(Mail)(() => ({
    width: '40px',
    height: 'auto',
    color: '#9f9f9f',
}));

export const DashboardMessageCard = () => {
    const { data, isLoading } = useApiEmailAntragCollection({
        itemsPerPage: 5,
        orderEingereichtAt: 'desc',
    });

    const emails = useMemo(() => data?.['hydra:member'] || [], [data]);

    const unreadEmailCount = useMemo(() => emails.filter((email) => !email?.read).length, [emails]);

    return (
        <DashboardCard
            isLoading={isLoading}
            headerAvatar={
                <Badge badgeContent={unreadEmailCount} color="primary">
                    <MailIcon />
                </Badge>
            }
            headerTitle="Aktuelles"
            cardActions={[{ href: PathBuilder.home.nachrichten.path, title: 'Alles anzeigen' }]}
        >
            {data ? <DashboardMessages data={data} /> : null}
        </DashboardCard>
    );
};
