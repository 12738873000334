import React, { ReactElement } from 'react';
import Info from '@mui/icons-material/Info';
import { InputAdornment, Tooltip } from '@mui/material';

import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const useStartAdornment = (uiSchema: UiSchemaType, schema: Schema, type?: ReactElement) => {
    if (!(uiSchema?.withInfoIcon && schema?.description)) {
        return null;
    }

    return (
        <InputAdornment position="start">
            <Tooltip title={schema.description}>{type ?? <Info />}</Tooltip>
        </InputAdornment>
    );
};
