import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useSpecificRole } from 'api/auth';
import { AntragTableFilterOptions } from 'api/hooks/useAntragTableFilterOptions';
import { GeneratedFile, RegelpruefungPruefberichtResponse } from 'api/types';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { CheckboxPruefbericht } from 'components/DataTable/components/CheckboxPruefbericht';
import { BehoerdenFilterOptions } from 'components/DataTable/filters/BehoerdenFilterOptions';
import { JahrFilterSelectOptions } from 'components/DataTable/filters/JahrFilterSelectOptions';
import { getDate, getRowData } from 'components/DataTable/tableUtils';
import { DocumentsList } from 'layout/components/DocumentsList';

export const usePruefberichtColumns = (
    filterOptions: AntragTableFilterOptions | undefined
): ((data: RegelpruefungPruefberichtResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { isBezirksregierung } = useSpecificRole();

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'leistungsangebot',
                    label: 'Name der Einrichtung',
                    options: {
                        filter: false,
                        customBodyRender: (value) => (value ? value : '-'),
                    },
                },
                {
                    name: 'behoerde',
                    label: 'Name des Kreises / der kreisfreien Stadt',
                    options: {
                        filter: false,
                        display: isBezirksregierung,
                        customBodyRender: (value) => (value ? value : '-'),
                    },
                },
                {
                    name: 'pruefDatum',
                    label: 'Datum der Prüfung',
                    options: {
                        filter: false,
                        customBodyRender: (value) => (value ? getDate(value) : '-'),
                    },
                },
                {
                    name: 'letzteNachpruefung',
                    label: 'Datum der letzten Nachprüfung',
                    options: {
                        filter: false,
                        customBodyRender: (value) => (value ? getDate(value) : 'Keine Nachprüfung vorhanden'),
                    },
                },
                {
                    name: 'link',
                    label: 'Link zum Download',
                    options: {
                        sort: false,
                        filter: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);
                            return (
                                <DocumentsList
                                    documents={rowData?.dokumente as Array<GeneratedFile>}
                                    openLinkInWindow
                                />
                            );
                        },
                    },
                },
                {
                    name: 'zuletztGeprueft',
                    label: 'Geprüft',
                    options: {
                        sort: false,
                        filter: false,
                        display: isBezirksregierung,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);
                            const isChecked = rowData?.zuletztGeprueft ? rowData.zuletztGeprueft?.length > 0 : false;

                            return (
                                <CheckboxPruefbericht
                                    isChecked={isChecked}
                                    id={String(rowData?.id)}
                                    leistungsangebotName={String(rowData?.leistungsangebot)}
                                />
                            );
                        },
                    },
                },
                {
                    name: 'jahr',
                    label: 'Jahr',
                    options: {
                        sort: false,
                        display: false,
                        ...JahrFilterSelectOptions('Jahr', filterOptions?.antragJahr),
                    },
                },
                {
                    name: 'einrichtungBehoerde',
                    label: 'Behörden',
                    options: {
                        sort: false,
                        display: false,
                        filter: !!Object.keys(filterOptions?.behoerden || {}).length,
                        ...BehoerdenFilterOptions('Behörde', filterOptions?.behoerden),
                    },
                },
            ];
        },
        [filterOptions, isBezirksregierung]
    );
};
