import { useMemo } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

import { useIsLoggedIn } from 'api/auth';

export const LogoWrapper = styled(Box)<BoxProps>(({ theme }) => {
    const isLoggedIn = useIsLoggedIn();
    const isLgUp = useMemo(() => theme.breakpoints.up('lg'), [theme]);

    return `
            align-items: center;
            align-self: stretch;
            border-right: ${isLoggedIn && !isLgUp ? `2px solid ${theme.palette.divider}` : 0};
            display: flex;
            justify-content: space-between;
            padding-left: 15px;
            width: ${theme.mixins.sidebar.width}px;
            height: 64px;

            a {
                margin-top: 10px;
                margin-right: 8px;
            }

            & > .MuiButtonBase-root {
                height: 64px;
                text-decoration: none;
                padding: 10px 30px;
                &:hover {
                    text-decoration: none;
                    background-color: ${theme.palette.primary.light};
                }

                &:focus {
                    background: ${theme.palette.primary.light};
                }
            }
        `;
});
