import React, { useMemo } from 'react';
import { isVisible, LayoutProps, UISchemaElement } from '@jsonforms/core';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system/createStyled';

import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { useLabel } from 'forms/hooks/useLabel';
import { withLayoutProps } from 'forms/hooks/withJsonFormProps';
import { GroupControlType, Schema } from 'forms/types/UiSchemaTypes';
import { applyGridLayout } from 'forms/ui-schemas/elements/gridLayoutConfig';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { useControlVisibility } from 'forms/utilities/UiSchemaUtils';
import { theme } from 'theme/theme';
import { transientOptions } from 'theme/utils';

export const StyledGroup = styled(Box)(
    ({ theme }) => `
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: ${theme.spacing(1.5)};
  margin-bottom: ${theme.spacing(4)};

  & h2 {
      margin: ${theme.spacing(2)} 0 ${theme.spacing(4)}  0;
  }

  & h3 {
      margin: 0 0 ${theme.spacing(2)}  0;
      font-weight: bold;
  }
`
);

export const NoSeparatorGroup = styled(StyledGroup)(
    ({ theme }) => `border-bottom: 0px; padding-bottom: ${theme.spacing(1)};`
);

interface GroupControlComponentType extends Omit<LayoutProps, 'uischema'> {
    uischema: GroupControlType;
}

const GroupControlComponent: React.FC<GroupControlComponentType> = ({
    uischema,
    schema,
    path,
    visible,
    renderers,
    data,
}) => {
    const controlVisibility = useControlVisibility(uischema);
    const label = useLabel(data, schema as Schema, uischema);

    const labelBySchema = useMemo(() => {
        if (!uischema?.labelScope) return;
        const fieldSchema = getFieldSchemaByScope(uischema?.labelScope, schema as Schema);
        return fieldSchema?.title;
    }, [schema, uischema?.labelScope]);

    const { ajv, rootData } = useJsonFormsState();
    const visibleElements = uischema.elements?.filter((element) =>
        isVisible(element as UISchemaElement, rootData, '', ajv)
    );

    const styled = !uischema?.options?.unstyled;
    const noSeparator = Boolean(uischema?.options?.noSeparator);
    const noSeparatorStyles: MUIStyledCommonProps<any>['sx'] = noSeparator
        ? {
              borderBottom: '0px',
              paddingBottom: theme.spacing(1),
          }
        : {};

    const content = visible && controlVisibility && (
        <GroupWrapper $inline={uischema.options?.headline?.inline}>
            <GroupHeader header={labelBySchema || label} variant={uischema?.options?.headline?.variant} />
            <Grid container spacing={1} className={!label ? 'no-label-group' : undefined} data-cy="GroupControl">
                {visibleElements.map((elem, i) => {
                    const fieldSchema = getFieldSchemaByScope(elem.scope, schema as Schema);

                    return (
                        fieldSchema && (
                            <Grid item xs={12} key={i} data-cy="GroupControlItem">
                                <JsonFormsDispatch
                                    uischema={applyGridLayout(elem, uischema.gridLayout)}
                                    schema={schema}
                                    path={path}
                                    renderers={renderers}
                                />
                            </Grid>
                        )
                    );
                })}
            </Grid>
        </GroupWrapper>
    );

    if (!visible || !controlVisibility) {
        return <></>;
    }

    return <>{(styled && <StyledGroup sx={noSeparatorStyles}>{content}</StyledGroup>) || <Box>{content}</Box>}</>;
};

const GroupWrapper = styled(
    Box,
    transientOptions
)<{ $inline?: boolean }>(({ theme, $inline }) => ({
    display: $inline ? 'flex' : 'initial',

    '& .MuiTypography-root': {
        width: $inline ? 400 : 'auto',
        marginRight: $inline ? `${theme.spacing(2)} !important` : undefined,
    },
}));

export const GroupHeader: React.FC<{
    header: string | undefined;
    className?: string;
    variant?: 'h3' | 'h4';
}> = ({ header, className, variant }) => {
    if (!header) {
        return <></>;
    }
    return (
        <Typography className={className} variant={variant || 'h2'} gutterBottom>
            {header}
        </Typography>
    );
};

export const GroupControl = withLayoutProps(GroupControlComponent);
