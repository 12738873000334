import { AnredeType, AnschriftType, EinrichtungArtType } from 'api/types';
import { BadgeColorTypes } from 'components/DataTable/components/BadgeColumn';
import { theme } from 'theme/theme';

import { ACTIONS } from './antragActions';
import { STATUS } from './antragStatus';
import { BENUTZER_STATUS } from './BENUTZER_STATUS';
import { EinrichtungTypes } from './einrichtungTypes';
import { EXPORT_STATUS } from './exportStatus';
import { MAENGEL_TYPES } from './MAENGEL_TYPES';
import { ROLLEN } from './roles';

export interface LabelsInterface<T = string> {
    [key: string]: T;
}

export interface ColorsInterface {
    [key: string]: { backgroundColor?: string; color?: string };
}

export const ANTRAG_STATUS_LABELS: LabelsInterface = {
    [STATUS.ENTWURF]: 'Entwurf',
    [STATUS.EINGEREICHT]: 'Eingereicht',
    [STATUS.ERNEUT_EINGEREICHT]: 'Erneut Eingereicht',
    [STATUS.ZURUECKGEZOGEN]: 'Zurückgezogen',
    [STATUS.ZURUECKZIEHEN_BEANTRAGT]: 'Zurückziehen beantragt',
    [STATUS.ZURUECKGEWIESEN]: 'Zurückgewiesen',
    [STATUS.ABGEBROCHEN]: 'Abgebrochen',
    [STATUS.IN_BEARBEITUNG]: 'In Bearbeitung',
    [STATUS.ZUR_PRUEFUNG_FREIGEGEBEN]: 'Zur Prüfung freigegeben',
    [STATUS.IN_PRUEFUNG]: 'In Prüfung',
    [STATUS.FREIGEGEBEN]: 'Freigegeben',
    [STATUS.BESCHIEDEN]: 'Beschieden',
    [STATUS.AUFGEHOBEN]: 'Aufgehoben',
    [STATUS.ZUR_KENNTNIS_GENOMMEN]: 'Zur Kenntnis genommen',
    [STATUS.AUSSTEHEND]: 'Ausstehend',
    [STATUS.DELETED]: 'Gelöscht',
};

export const ANTRAG_STATUS_COLORS: ColorsInterface = {
    [STATUS.ENTWURF]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    [STATUS.EINGEREICHT]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    [STATUS.IN_PRUEFUNG]: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    [STATUS.FREIGEGEBEN]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    [STATUS.AUSSTEHEND]: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    [STATUS.DELETED]: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
};

export const ANTRAG_ACTION_STATUS_LABELS: LabelsInterface = {
    [ACTIONS.EINREICHEN]: 'Eingereicht',
    [ACTIONS.BEARBEITUNG_STARTEN]: 'In Bearbeitung',
    [ACTIONS.ZUR_PRUEFUNG_FREIGEBEN]: 'Zur Prüfung freigegeben',
    [ACTIONS.PRUEFEN]: 'In Prüfung',
    [ACTIONS.FREIGEBEN]: 'Freigegeben',
    [ACTIONS.BESCHEIDEN]: 'Beschieden',
    [ACTIONS.BESCHEID_AENDERN]: 'In Bearbeitung',
    [ACTIONS.ABLEHNEN]: 'Abgelehnt',
    [ACTIONS.ZURUECKWEISEN]: 'Abgelehnt',
    [ACTIONS.ZUR_KENNTNIS_NEHMEN]: 'Zur Kenntnis genommen',
};

export const ANTRAG_ACTION_LABELS: LabelsInterface = {
    [ACTIONS.ABBRECHEN]: 'Abbrechen',
    [ACTIONS.ABLEHNEN]: 'Ablehnen',
    [ACTIONS.ANTRAG_VIEW]: 'Ansehen',
    [ACTIONS.CREATE_MELDUNG]: 'Neue Meldung erstellen',
    [ACTIONS.CREATE_REGELPRUEFUNG]: 'Neue Regelprüfung erstellen',
    [ACTIONS.AUFFORDERN]: 'Zur Meldung auffordern',
    [ACTIONS.BEARBEITUNG_STARTEN]: 'Bearbeitung starten',
    [ACTIONS.BENUTZER_ACTIVATE]: 'Aktivieren',
    [ACTIONS.BENUTZER_DEACTIVATE]: 'Deaktivieren',
    [ACTIONS.BESCHEID_AENDERN]: 'Bescheid ändern',
    [ACTIONS.BESCHEIDEN]: 'Bescheiden',
    [ACTIONS.DELETE]: 'Löschen',
    [ACTIONS.DELETE_EINRICHTUNG]: 'Löschen',
    [ACTIONS.EDIT]: 'Bearbeiten',
    [ACTIONS.EINREICHEN]: 'Einreichen',
    [ACTIONS.EMAIL_SEND]: 'Senden',
    [ACTIONS.FREIGEBEN]: 'Freigeben',
    [ACTIONS.LEISTUNGSANGEBOT_ERSTELLEN]: 'Leistungsangebot erstellen',
    [ACTIONS.MANUELLE_ZUORDNUNG]: 'Manuell zuordnen',
    [ACTIONS.NACHRICHTEN]: 'Nachrichten',
    [ACTIONS.PRUEFEN]: 'Prüfen',
    [ACTIONS.RETRY]: 'Erneut nach PfAD.invest übertragen',
    [ACTIONS.INVESTANMELDUNG]: 'Teilnahme an PfAD.invest',
    [ACTIONS.SPEICHERN]: 'Änderungen speichern',
    [ACTIONS.BEARBEITUNG_ABSCHLIESSEN]: 'Speichern',
    [ACTIONS.TREFFER_ZUORDNUNG]: 'Treffer zuordnen',
    [ACTIONS.VIEW]: 'Ansehen',
    [ACTIONS.ZUGRIFFSRECHTE]: 'Zugriffsrechte verwalten',
    [ACTIONS.ZUGRIFFSRECHTE_ANSEHEN]: 'Zugriffsrechte ansehen',
    [ACTIONS.ZUGRIFFSRECHTE_ENTFERNEN]: 'Zugriffsrechte entziehen',
    [ACTIONS.ZUR_KENNTNIS_NEHMEN]: 'Zur Kenntnis nehmen',
    [ACTIONS.ZUR_PRUEFUNG_FREIGEBEN]: 'Zur Prüfung freigeben',
    [ACTIONS.ZUGEHOERIGE_EINRICHTUNGEN]: 'Zugehörige Leistungsangebote',
    [ACTIONS.ZURUECKWEISEN]: 'Meldung zurückgeben',
    [ACTIONS.ZUSTAENDIGKEIT]: 'Zuständig werden',
    [ACTIONS.ZUSTAENDIGKEIT_LOESCHEN]: 'Zuständigkeit abgeben',
    [ACTIONS.GELESEN]: 'Als gelesen markieren',
    [ACTIONS.UNGELESEN]: 'Als ungelesen markieren',
};

export const EINRICHTUNG_ART_FILTER_LABELS: LabelsInterface = {
    [EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI]:
        EinrichtungArtType[EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI].label,
    [EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII]:
        EinrichtungArtType[EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII].label,
    [EinrichtungTypes.FULLY_INPATIENT_SHORT]: EinrichtungArtType[EinrichtungTypes.FULLY_INPATIENT_SHORT].label,
    [EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE]:
        EinrichtungArtType[EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE].label,
    [EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE]: EinrichtungArtType[EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE].label,
    [EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION]:
        EinrichtungArtType[EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION].label,
    [EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT]: EinrichtungArtType[EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT].label,
    [EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT]:
        EinrichtungArtType[EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT].label,
    [EinrichtungTypes.HOSPICE]: EinrichtungArtType[EinrichtungTypes.HOSPICE].label,
    [EinrichtungTypes.ASSISTED_LIVING]: EinrichtungArtType[EinrichtungTypes.ASSISTED_LIVING].label,
    [EinrichtungTypes.SHARED_HOME]: EinrichtungArtType[EinrichtungTypes.SHARED_HOME].label,
    [EinrichtungTypes.WERKSTATT]: EinrichtungArtType[EinrichtungTypes.WERKSTATT].label,
};

export const EINRICHTUNG_ART_BELEGBARE_PLAETZE_FILTER_LABELS: LabelsInterface = {
    [EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI]:
        EinrichtungArtType[EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI].label,
    [EinrichtungTypes.FULLY_INPATIENT_SHORT]: EinrichtungArtType[EinrichtungTypes.FULLY_INPATIENT_SHORT].label,
    [EinrichtungTypes.HOSPICE]: EinrichtungArtType[EinrichtungTypes.HOSPICE].label,
};

export const WOHNGEMEINSCHAFT_ARTEN: LabelsInterface = {
    EXTERNAL_CONTROLLED: 'Anbieterverantwortet',
    SELF_CONTROLLED: 'Selbstverantwortet',
};

export const KONZEPTINFORMATIONEN_ART: LabelsInterface = {
    ALLGEMEINE_PFLEGE: 'Allgemeine Pflege (ohne Schwerpunkt)',
    JUNGE_PFLEGE: 'Junge Pflege (für Pflegebedürftige im Alter von 18-60 Jahren)',
    INTENSIV_BEATMUNG: 'Intensiv- und Beatmungspflege',
    WACHKOMA: 'Menschen im Wachkoma',
    GEISTIG: 'Menschen mit geistiger Behinderung',
    KOERPERLICH: 'Menschen mit körperlicher Behinderung',
    PSYCHISCH: 'Menschen mit psychischen Behinderungen',
    SUCHT: 'Menschen mit Suchterkrankungen',
    MEHRFACH_BEHINDERUNGEN: 'Menschen mit Mehrfachbehinderungen',
    DEMENZ: 'Menschen mit dementiellen Erkrankungen',
    UNTERBRINGUNGSBESCHLUSS: 'Menschen mit Unterbringungbeschluss',
    BEGLEITUNG: 'Begleitung von Menschen in ihrer letzten Lebensphase',
    NEUROMUSKULAER: 'Menschen mit neuromuskulären Erkrankungen',
    SEELISCH: 'Menschen mit seelischer Behinderung',
    SINNES: 'Menschen mit Sinnesbehinderung',
    CUSTOM: 'Anderer Schwerpunkt',
};

export const WOHNGEMEINSCHAFT_LEISTUNGSBEREICH: LabelsInterface = {
    XI: 'SGB XI (Pflege)',
    IX: 'SGB IX (Eingliederungshilfe)',
};

export const EINRICHTUNG_STATUS_FILTER_LABELS: LabelsInterface = {
    [STATUS.FREIGEGEBEN]: 'Freigegeben',
    [STATUS.IN_PRUEFUNG]: 'In Prüfung',
};

export const ADRESSE_ANSCHRIFTTYP: LabelsInterface = {
    [AnschriftType.HAUSANSCHRIFT]: 'Hausanschrift',
    [AnschriftType.POSTFACH]: 'Postfach',
};

export const TRAEGER_ART_LABELS: LabelsInterface = {
    FREI: 'Freie Wohlfahrtspflege',
    PRIVAT: 'Privat',
    KOMMUNAL: 'Kommunal',
};

export enum EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES {
    KEINE = 'KEINE',
    AUFGEFORDERT = 'AUFGEFORDERT',
}

export enum BELEGBARE_PLAETZE_STATUS_FILTER_TYPES {
    EINGEREICHT = 'EINGEREICHT',
    AUSSTEHEND = 'AUSSTEHEND',
}

export enum WOHNGEMEINSCHAFT_ART_FILTER_TYPES {
    EXTERNAL_CONTROLLED = 'EXTERNAL_CONTROLLED',
    SELF_CONTROLLED = 'SELF_CONTROLLED',
}

export enum LEISTUNGSBEREICH_FILTER_TYPES {
    COMMUNITY_SERVICE_AREA_XI = 'XI',
    COMMUNITY_SERVICE_AREA_IX = 'XII',
}

export const LEISTUNGSBEREICH_ART_FILTER_LABELS: LabelsInterface = {
    [LEISTUNGSBEREICH_FILTER_TYPES.COMMUNITY_SERVICE_AREA_XI]: 'SGB XI (Pflege)',
    [LEISTUNGSBEREICH_FILTER_TYPES.COMMUNITY_SERVICE_AREA_IX]: 'SGB IX (Eingliederungshilfe)',
};

export const WOHNGEMEINSCHAFT_ART_FILTER_LABELS: LabelsInterface = {
    [WOHNGEMEINSCHAFT_ART_FILTER_TYPES.EXTERNAL_CONTROLLED]: 'Anbieterverantwortet',
    [WOHNGEMEINSCHAFT_ART_FILTER_TYPES.SELF_CONTROLLED]: 'Selbstverantwortet',
};

export const EINRICHTUNG_MELDUNG_STATUS_FILTER_LABELS: LabelsInterface = {
    [EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES.KEINE]: 'Keine',
    [EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES.AUFGEFORDERT]: 'Aufgefordert',
};

export const BELEGBARE_PLAETZE_STATUS_FILTER_LABELS: LabelsInterface = {
    [BELEGBARE_PLAETZE_STATUS_FILTER_TYPES.EINGEREICHT]: 'Eingereicht',
    [BELEGBARE_PLAETZE_STATUS_FILTER_TYPES.AUSSTEHEND]: 'Ausstehend',
};

export enum BENACHRICHTIGUNG_STATUS_FILTER_LABELS {
    GELESEN = 'Gelesen',
    UNGELESEN = 'Ungelesen',
}

export enum BENUTZER_STATUS_FILTER_TYPES {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED',
}

export const BENUTZER_STATUS_FILTER_LABELS: LabelsInterface = {
    [BENUTZER_STATUS_FILTER_TYPES.ACTIVATED]: 'Aktiviert',
    [BENUTZER_STATUS_FILTER_TYPES.DEACTIVATED]: 'Deaktiviert',
};

export const MAENGEL_ART_LABELS: LabelsInterface = {
    [MAENGEL_TYPES.MAENGELFREI]: 'Mängelfrei',
    [MAENGEL_TYPES.MAENGEL_OHNE_ANORDNUNG]: 'Mängel ohne Anordnung',
    [MAENGEL_TYPES.WESENTLICHE_MAENGEL]: 'Wesentliche Mängel',
};

export const ROLE_TYPE_LABELS: LabelsInterface = {
    [ROLLEN.BEHOERDE]: 'Behörde',
    [ROLLEN.TRAEGER]: 'Leistungsanbieter',
    [ROLLEN.EINRICHTUNG_BENUTZER]: 'Leistungsangebot Nutzer',
    [ROLLEN.LV]: 'Landschaftsverband',
    [ROLLEN.TRAEGERVERBAND]: 'Trägerverband',
    [ROLLEN.MONITORING]: 'Monitoring',
    [ROLLEN.MINISTERIUM]: 'Ministerium',
    [ROLLEN.BEZIRKSREGIERUNG]: 'Bezirksregierung',
};

export const BENUTZER_STATUS_LABELS: LabelsInterface = {
    [BENUTZER_STATUS.DELETED]: 'gelöscht',
    [BENUTZER_STATUS.INACTIVE]: 'inaktiv',
    [BENUTZER_STATUS.INVITED]: 'eingeladen',
    [BENUTZER_STATUS.ACTIVE]: 'aktiv',
};

export const BENUTZER_STATUS_COLORS: LabelsInterface<BadgeColorTypes> = {
    [BENUTZER_STATUS.DELETED]: 'error',
    [BENUTZER_STATUS.INACTIVE]: 'warning',
    [BENUTZER_STATUS.INVITED]: 'warning',
    [BENUTZER_STATUS.ACTIVE]: 'success',
};

export const EXPORT_STATUS_LABELS: LabelsInterface = {
    [EXPORT_STATUS.NEW]: 'Ausstehend',
    [EXPORT_STATUS.WORKING]: 'In Verarbeitung',
    [EXPORT_STATUS.FAILED]: 'Export fehlgeschlagen',
    [EXPORT_STATUS.COMPLETED]: 'Export abgeschlossen',
};

export const EXPORT_STATUS_COLORS: LabelsInterface<BadgeColorTypes> = {
    [EXPORT_STATUS.NEW]: 'info',
    [EXPORT_STATUS.WORKING]: 'warning',
    [EXPORT_STATUS.FAILED]: 'error',
    [EXPORT_STATUS.COMPLETED]: 'success',
};

export const ANREDE_LABELS: LabelsInterface = {
    [AnredeType.FEMALE]: 'Frau',
    [AnredeType.MALE]: 'Herr',
    [AnredeType.DIVERS]: 'Divers',
};
