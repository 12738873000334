import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { GetCollectionMeldung200Response } from 'api/client';
import { EinrichtungCollectionResponse } from 'api/types';
import { useLeistungsangebotFreigegebeneListAction } from 'components/DataTable/actions/useLeistungsangebotFreigegebeneListAction';
import { useMeldungenAntragListActions } from 'components/DataTable/actions/useMeldungenAntragListActions';
import { useMeldungenDashboardColumns } from 'components/DataTable/columns/meldungen/useMeldungenDashboardColumns';
import { useMeldungenEinrichtungenColumns } from 'components/DataTable/columns/meldungen/useMeldungenEinrichtungenColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { useEinrichtungListData } from 'components/DataTable/useEinrichtungListData';
import { STATUS } from 'constants/antragStatus';
import { EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES } from 'constants/labels';
import { PathBuilder } from 'navigation/Paths';
import { useMeldungListData } from 'pages/Meldung/useMeldungListData';

import { DashboardCard } from './DashboardCard';

export const DashboardMeldungen = () => {
    const location = useLocation();

    const getData = useMeldungListData({ status: STATUS.ENTWURF });
    const getEinrichtungData = useEinrichtungListData({
        meldungenStatus: EINRICHTUNG_MELDUNG_STATUS_FILTER_TYPES.KEINE,
    });

    const [showMeldungListData, setShowMeldungListData] = useState<boolean>(true);
    const [showEinrichtungListData, setShowEinrichtungListData] = useState<boolean>(true);

    const { serverDataChanged, handleAction } = useMeldungenAntragListActions({
        pathBuilder: PathBuilder.home.verwaltung.meldung,
    });
    const { serverDataChanged: einrichtungenDataChanged, handleAction: handleEinrichtungenAction } =
        useLeistungsangebotFreigegebeneListAction(PathBuilder.home.verwaltung.leistungsangebote.anbieter.freigegebene);

    const columns = useMeldungenDashboardColumns(handleAction, { EDIT: { label: 'Bearbeiten und einreichen' } });

    const columnsEinrichtungen = useMeldungenEinrichtungenColumns(handleEinrichtungenAction);

    const handleTableReadyMeldungen = (data?: GetCollectionMeldung200Response) =>
        setShowMeldungListData(!!(data && data?.['hydra:member']?.length > 0));

    const handleTableReadyEinrichtungen = (data?: EinrichtungCollectionResponse) =>
        setShowEinrichtungListData(!!(data && data['hydra:member']?.length > 0));

    return (
        <DashboardCard
            headerTitle="Meldungen"
            sx={{ justifyContent: 'flex-start' }}
            cardActions={[{ title: 'Zur Meldungsübersicht', href: PathBuilder.home.verwaltung.meldung.path }]}
        >
            {showMeldungListData ? (
                <>
                    <Typography variant="h3">Meldungen im Entwurf</Typography>
                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        onReady={handleTableReadyMeldungen}
                        initialOptions={{
                            sortOrder: {
                                name: 'letzteMeldung',
                                direction: 'asc',
                            },
                            rowsPerPage: 10,
                            custom: {
                                disableSearch: true,
                            },
                        }}
                        serverDataChanged={serverDataChanged}
                        nameOverride={`${location.pathname}--meldungen-entwurf`}
                        persistSearchText
                    />
                </>
            ) : null}

            {showEinrichtungListData ? (
                <>
                    <Typography variant="h3">Leistungsangebote ohne Meldung</Typography>
                    <DataTableHydraServerside
                        getColumns={columnsEinrichtungen}
                        getData={getEinrichtungData}
                        onReady={handleTableReadyEinrichtungen}
                        initialOptions={{
                            sortOrder: {
                                name: 'letzteMeldung',
                                direction: 'asc',
                            },
                            custom: {
                                disableSearch: true,
                            },
                        }}
                        serverDataChanged={einrichtungenDataChanged}
                        nameOverride={`${location.pathname}--lab-ohne-meldung`}
                        persistSearchText
                    />
                </>
            ) : null}

            {!showMeldungListData && !showEinrichtungListData ? (
                <Alert severity="success">Sie haben keine offenen Meldungen</Alert>
            ) : null}
        </DashboardCard>
    );
};
