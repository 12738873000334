import { useMemo } from 'react';
import { create } from 'zustand';

import { backendApiService } from 'api/ApiService';
import { useHasRole, useIsLoggedIn } from 'api/auth';
import { BenutzerAufgabenOutputJsonldBenutzerAufgaben } from 'api/client';
import { ROLE_TYPES } from 'constants/roles';

export type BenutzerAufgaben = Omit<BenutzerAufgabenOutputJsonldBenutzerAufgaben, '@context' | '@id' | '@type'>;

interface UseBenutzerAufgabenState {
    isLoadingAufgaben: boolean;
    loadingAufgabenStart: () => void;
    loadingAufgabenEnd: () => void;
    benutzerAufgaben: BenutzerAufgaben;
    updateBenutzerAufgabenState: (aufgaben: BenutzerAufgaben) => BenutzerAufgaben;
    clearBenutzerAufgaben: () => void;
}

interface UseBenutzerAufgabenReturn
    extends Omit<
        UseBenutzerAufgabenState,
        'loadingAufgabenStart' | 'loadingAufgabenEnd' | 'updateBenutzerAufgabenState'
    > {
    loadBenutzerAufgaben: () => Promise<BenutzerAufgaben>;
    getBenutzerAufgabenTotal: (keys?: Array<keyof BenutzerAufgaben>) => number;
    hasBenutzerAufgaben: boolean;
}

export const useBenutzerAufgabenState = create<UseBenutzerAufgabenState>((set) => ({
    isLoadingAufgaben: false,
    benutzerAufgaben: {},
    loadingAufgabenStart: () => {
        set(() => ({
            isLoadingAufgaben: true,
        }));
    },
    loadingAufgabenEnd: () => {
        set(() => ({
            isLoadingAufgaben: false,
        }));
    },
    updateBenutzerAufgabenState: (aufgaben) => {
        set((state) => {
            return { benutzerAufgaben: { ...state.benutzerAufgaben, ...aufgaben } };
        });

        return aufgaben;
    },
    clearBenutzerAufgaben: () => {
        set(() => ({
            benutzerAufgaben: {},
        }));
    },
}));

export const useBenutzerAufgaben = (): UseBenutzerAufgabenReturn => {
    const hasRole = useHasRole();
    const isLoggedIn = useIsLoggedIn();

    const {
        isLoadingAufgaben,
        loadingAufgabenStart,
        loadingAufgabenEnd,
        updateBenutzerAufgabenState,
        clearBenutzerAufgaben,
        benutzerAufgaben,
    } = useBenutzerAufgabenState((state) => state);

    const loadBenutzerAufgaben = () => {
        if (isLoggedIn && hasRole(ROLE_TYPES.ROLLEN_AUFGABEN_REQUEST) && !isLoadingAufgaben) {
            loadingAufgabenStart();

            return backendApiService
                .getBenutzerAufgaben()
                .then((aufgaben) => updateBenutzerAufgabenState(aufgaben))
                .finally(loadingAufgabenEnd);
        }

        return Promise.resolve(benutzerAufgaben);
    };

    const getBenutzerAufgabeByKey = (key: string): number => benutzerAufgaben[key as keyof BenutzerAufgaben] ?? 0;

    const getBenutzerAufgabenTotal = (keys?: Array<keyof BenutzerAufgaben>) =>
        (keys || []).reduce((sum, key: string) => getBenutzerAufgabeByKey(key) + sum, 0);

    const hasBenutzerAufgaben = useMemo(() => Object.keys(benutzerAufgaben).length > 0, [benutzerAufgaben]);

    return {
        loadBenutzerAufgaben,
        getBenutzerAufgabenTotal,
        hasBenutzerAufgaben,
        isLoadingAufgaben,
        clearBenutzerAufgaben,
        benutzerAufgaben,
    };
};
