import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { JsonForms } from '@jsonforms/react';
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    styled,
    TextField,
    Typography,
} from '@mui/material';

import { useHasRole, useIsLoggedIn } from 'api/auth';
import { usePlatzzahlmelderTokenApi } from 'api/hooks/usePlatzzahlmelderTokenApi';
import { URL_EINRICHTUNG } from 'api/routes';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { BadgeStatus } from 'components/DataTable/components/BadgeStatus';
import { CustomHydraSearch } from 'components/DataTable/CustomHydraSearch';
import { useTableSearchText } from 'components/DataTable/hooks/useTableSearchText';
import { getDateTime } from 'components/DataTable/tableUtils';
import { DocumentDownload } from 'components/DocumentDownload';
import { PageHeading } from 'components/PageHeading';
import { STATUS } from 'constants/antragStatus';
import { EinrichtungTypes } from 'constants/einrichtungTypes';
import { ROLES } from 'constants/roles';
import { FormLoadingButton } from 'forms/components/FormButton';
import { FormButtonGroup } from 'forms/components/FormButtonGroup';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { renderers } from 'forms/renderers';
import { useFormState } from 'forms/state/useFormState';
import { Schema } from 'forms/types/UiSchemaTypes';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';
import { uiSchema, uiSchemaTagespflege } from 'pages/Platzmelder/formConfig';
import { toIriId } from 'utilities/IriUtils';

import { CollapsibleCard } from './components/CollapsibleCard';
import { usePlatzmelderCheckboxList } from './hooks/usePlatzmelderCheckboxList';
import { usePlatzmelderCollectionData } from './hooks/usePlatzmelderCollectionData';
import { usePlatzmelderTokenLink } from './hooks/usePlatzmelderTokenLink';
import { AuthorizedPlatzmelderBehoerdePage } from './PlatzmlederBehoerdePage';

const GeneratedLinkField = styled(TextField)(
    ({ theme }) => `
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(3)};

    .MuiInputBase-multiline {
        padding-top: ${theme.spacing(2)};
        padding-bottom: ${theme.spacing(2)};
    }
`
);

const Index = () => {
    const { token } = useParams();

    const { setRequestMessage, setFormSubmitted, progressStart, progressEnd, isInProgress } = useFormState();
    const isLoggedIn = useIsLoggedIn();

    const { isLoading: isLoadingToken, currentToken, createToken } = usePlatzzahlmelderTokenApi(token);

    const { generatedFile, tokenUrl, isSubmitting, handleFileExport, handleCopyLink, setGeneratedFile } =
        usePlatzmelderTokenLink({ currentToken });

    const { searchText, onSearchChanged, updateSearchText } = useTableSearchText(undefined, false);

    const {
        options,
        handleFilter,
        isLoadingCollection,
        filteredEinrichtungen,
        collectionData,
        handleDataChanged,
        getLetzteErinnerung,
        submitPromise,
        setServerDataChanged,
        errors,
    } = usePlatzmelderCollectionData({ currentToken, isLoggedIn, searchText, updateSearchText });

    const {
        isSelected,
        allEntriesSelected,
        isIndeterminateState,
        toggleSelectedList,
        handleSelectedEinrichtungId,
        clearSelectedList,
        setSelectAll,
    } = usePlatzmelderCheckboxList(filteredEinrichtungen);

    const handleSubmit = useCallback(() => {
        const selectedEinrichtungen = collectionData
            .filter((einrichtung) => isSelected(einrichtung.id))
            .map((einrichtung) => {
                return {
                    einrichtung: toIriId(URL_EINRICHTUNG, einrichtung.id),
                    ...einrichtung.belegbarePlaetze,
                };
            });

        const hasErrors = errors.some((errorItem) =>
            selectedEinrichtungen.some((selected) => selected.einrichtung === errorItem.data.einrichtung)
        );

        if (!selectedEinrichtungen.length) {
            return setRequestMessage(errorMessage('Bitte wählen Sie mindestens eine Einrichtung aus.'));
        }

        if (hasErrors) {
            setFormSubmitted(true);
            return setRequestMessage(errorMessage('Bitte überprüfen Sie Ihre Eingaben.'));
        }

        progressStart();

        submitPromise(token, selectedEinrichtungen)
            .then(() => {
                setRequestMessage(successMessage('Ausgewählte Einrichtungen wurden gemeldet.'));
                setServerDataChanged((prevState) => prevState + 1);
                clearSelectedList();
            })
            .catch(() =>
                setRequestMessage(errorMessage('Die Meldung zu belegbaren Plätzen konnte nicht gespeichert werden.'))
            )
            .finally(() => {
                progressEnd();
                setFormSubmitted(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        collectionData,
        errors,
        isSelected,
        progressEnd,
        progressStart,
        setFormSubmitted,
        setRequestMessage,
        setServerDataChanged,
        submitPromise,
        token,
    ]);

    return (
        <ContentContainer title="Freie/belegte Plätze">
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoadingToken && !currentToken}>
                    {currentToken ? (
                        <>
                            <PageHeading title="Belegbare Plätze" />

                            <Grid container spacing={3} justifyContent="flex-start">
                                <Grid item xs={12} md={10}>
                                    <Typography variant="h3">Anleitung für einen schnellen Zugriff</Typography>
                                    <Typography paragraph>
                                        Nachdem Sie Ihr Profil für den Heimfinder NRW eingerichtet haben, bieten wir
                                        Ihnen hier verschiedene Varianten an, damit Sie Ihre freien und belegbaren
                                        Plätze täglich auf möglichst komfortable Weise und ohne ständiges Einloggen bei
                                        PfAD.wtg melden können.
                                    </Typography>
                                </Grid>

                                {isLoggedIn ? (
                                    <>
                                        <Grid item xs={12} md={5}>
                                            <CollapsibleCard
                                                title="Link-Datei herunterladen"
                                                isOpen={true}
                                                cardActions={
                                                    <>
                                                        {!generatedFile ? (
                                                            <FormLoadingButton
                                                                variant="text"
                                                                color="primary"
                                                                onClick={handleFileExport}
                                                                aria-label="Link-Datei generieren"
                                                                loading={isSubmitting}
                                                                loadingPosition="center"
                                                            >
                                                                Link-Datei generieren
                                                            </FormLoadingButton>
                                                        ) : (
                                                            <DocumentDownload generatedFile={generatedFile} />
                                                        )}
                                                    </>
                                                }
                                            >
                                                <Typography paragraph>
                                                    Sie haben die Möglichkeit sich eine Link-Datei auf Ihrem Rechner zu
                                                    speichern. Durch das Speichern und tägliche Aufrufen der Link-Datei
                                                    kann die Platzzahlmeldung in PfAD.wtg ohne Eingabe der Login-Daten
                                                    erfolgen.
                                                </Typography>
                                                <Alert severity="info">
                                                    Es wird empfohlen, die Link-Datei auf dem Desktop zu speichern. Wenn
                                                    Sie nun Ihre Platzmeldungen durchführen möchten, klicken Sie bitte
                                                    doppelt auf die Link-Datei. Anschließend gelangen Sie direkt zur
                                                    Eingabeseite. Hierbei ist es unerheblich, ob Sie die Plätze für ein
                                                    oder mehrere Leistungsangebote melden möchten.
                                                </Alert>
                                            </CollapsibleCard>
                                        </Grid>

                                        <Grid item xs={12} md={5}>
                                            <CollapsibleCard
                                                title="Link als Lesezeichen im Browser speichern"
                                                isOpen={true}
                                                cardActions={
                                                    <>
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            onClick={() => handleCopyLink()}
                                                        >
                                                            URL kopieren
                                                        </Button>

                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            onClick={async () => {
                                                                await createToken();
                                                                setGeneratedFile(null);
                                                            }}
                                                        >
                                                            Neue URL generieren
                                                        </Button>
                                                    </>
                                                }
                                            >
                                                <Typography paragraph>
                                                    Sie haben die Möglichkeit den Link der URL zu kopieren und diesen so
                                                    als Lesezeichen im Browser zu speichern.
                                                </Typography>

                                                <AppLoaderContainer isLoading={isLoadingToken}>
                                                    <GeneratedLinkField
                                                        id="generated-link"
                                                        label="Link"
                                                        multiline
                                                        // ToDo: Check workaround for resize bug (https://github.com/mui/material-ui/issues/39105)
                                                        rows={2}
                                                        defaultValue={tokenUrl}
                                                        disabled
                                                        fullWidth
                                                    />
                                                </AppLoaderContainer>

                                                <Alert severity="info">
                                                    Um eine neue URL für den Link zum Platzmelder zu generieren, klicken
                                                    Sie auf den Button "Neue URL generieren". Wenn Sie eine neue URL
                                                    generieren, wird die bisherige URL und damit auch die Link-Datei zum
                                                    Platzmelder ungültig.
                                                </Alert>
                                            </CollapsibleCard>
                                        </Grid>
                                    </>
                                ) : null}

                                <Grid item xs={12} md={10}>
                                    <Typography variant="h3">Meldung</Typography>

                                    <CollapsibleCard title="Informationen zur Meldung" isOpen={false}>
                                        <Alert severity="info">
                                            <Typography paragraph>
                                                Bitte melden Sie tagesaktuell Ihre freien und belegbaren Plätze für alle
                                                Einrichtungen. An Wochenenden und Feiertagen kann die Meldung der freien
                                                Plätze auf freiwilliger Basis erfolgen. Schließen Sie die Meldung bitte
                                                durch einen Klick auf den Button "Melden" ab.
                                            </Typography>

                                            <strong>
                                                Bitte beachten Sie, dass ein Platz nur dann frei und belegbar ist, wenn
                                            </strong>
                                            <List className="numbered-list">
                                                <ListItem>
                                                    keine Warteliste geführt wird, die dazu führt, dass freiwerdende
                                                    Plätze ausschließlich Personen angeboten werden, die auf der
                                                    Warteliste geführt werden.
                                                </ListItem>
                                                <ListItem>
                                                    der Platz keiner Belegungssperre unterliegt. Dies gilt sowohl für
                                                    Belegungsverbote, die Ihnen von der WTG-Behörde auferlegt worden
                                                    sind als auch für einen freiwilligen Belegungsstopp, z.B. wegen
                                                    Personalknappheit.
                                                </ListItem>
                                                <ListItem>
                                                    der belegbare Platz nicht durch anderweitige Zusagen bereits an eine
                                                    Person vergeben wurde. Temporär freie Zimmer, die aufgrund von
                                                    Auszug, Renovierung o.ä. frei sind, deren Belegung mit einer
                                                    bestimmten Person jedoch schon konkret geplant ist, gelten als nicht
                                                    belegbar.
                                                </ListItem>
                                            </List>

                                            <Typography paragraph>
                                                Freie und belegbare Plätze, die{' '}
                                                <strong>
                                                    sowohl für die Kurzzeit-, als auch für die Dauerpflege genutzt
                                                </strong>{' '}
                                                werden können, sind doppelt sowohl als freie Kurzzeit-, als auch freie
                                                Dauerpflegeplätze zu melden.
                                            </Typography>

                                            <Typography paragraph>
                                                Sind <strong>keine belegbaren Plätze verfügbar</strong>, ist gleichwohl
                                                eine tägliche Meldung erforderlich (Eingabe: 0 Plätze).
                                            </Typography>

                                            <Typography paragraph>
                                                Die gemeldeten Platzzahlen können{' '}
                                                <strong>beliebig oft aktualisiert werden</strong>, auch während des
                                                Tages, z.B. wenn ein freier Platz belegt wurde und Sie diesen Platz
                                                nicht weiter anbieten können. Es erfolgt eine automatische
                                                Synchronisierung der Heimfinder-App.
                                            </Typography>

                                            <Typography paragraph>
                                                <strong>Bitte beachten Sie:</strong>
                                                <br />
                                                Die hierüber gemeldeten Platzzahlen werden ebenso wie die Profildaten
                                                der Einrichtungen im Heimfinder NRW veröffentlicht. Denken Sie bitte
                                                auch daran die Profildaten der Einrichtungen zu pflegen.
                                            </Typography>
                                        </Alert>
                                    </CollapsibleCard>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="flex-start" marginBottom={4}>
                                <Grid item xs={12} md={10} alignSelf="flex-start">
                                    <CustomHydraSearch
                                        searchText={searchText || ''}
                                        handleSearch={(text) => {
                                            onSearchChanged(text);
                                        }}
                                        options={{
                                            searchPlaceholder: 'Suche nach Einrichtung, NRW-Schlüssel oder Ort',
                                            textLabels: {
                                                toolbar: {
                                                    search:
                                                        searchText || 'Suche nach Einrichtung, NRW-Schlüssel oder Ort',
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} justifyContent="flex-start">
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={allEntriesSelected}
                                                indeterminate={isIndeterminateState}
                                                onClick={(event) => {
                                                    toggleSelectedList(event);
                                                }}
                                            />
                                        }
                                        label="Einrichtungen aus-/abwählen"
                                    />
                                </Grid>

                                <Grid item xs={7} alignSelf="flex-start">
                                    <FormButtonGroup
                                        options={options}
                                        handleCallback={(action) => {
                                            handleFilter(action as STATUS);
                                            setSelectAll(true);
                                        }}
                                    />
                                </Grid>

                                <AppLoaderContainer isLoading={isLoadingCollection}>
                                    <Grid item xs={12} md={10}>
                                        <Pflichtfeld
                                            display={(filteredEinrichtungen || []).some(
                                                (einrichtung) => einrichtung?.schema?.required?.length
                                            )}
                                        />
                                    </Grid>

                                    {filteredEinrichtungen.length ? (
                                        filteredEinrichtungen.map((einrichtung) => (
                                            <Grid item xs={12} md={10} key={einrichtung.id}>
                                                <StyledMeldungCard elevation={2}>
                                                    <StyledMeldungCardHeader
                                                        title={
                                                            <StyledFormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={isSelected(einrichtung.id)}
                                                                        onClick={() =>
                                                                            handleSelectedEinrichtungId(
                                                                                Number(einrichtung.id)
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={`${einrichtung.name}, ${einrichtung.ort}, ${einrichtung.nrwKey}`}
                                                            />
                                                        }
                                                        action={
                                                            <BadgeStatus
                                                                status={einrichtung.belegbarePlaetzeStatus as STATUS}
                                                            />
                                                        }
                                                    />
                                                    <CardContent>
                                                        <MeldungStatusInfos paragraph>
                                                            <span>
                                                                Letzte Aktualisierung:{' '}
                                                                {getDateTime(einrichtung?.belegbarePlaetze?.modifiedAt)}
                                                            </span>
                                                            <br />
                                                            <span>{`Letzte Erinnerung: ${getLetzteErinnerung(
                                                                einrichtung
                                                            )}`}</span>
                                                        </MeldungStatusInfos>

                                                        <JsonForms
                                                            data={einrichtung.belegbarePlaetze}
                                                            renderers={renderers}
                                                            schema={einrichtung.schema as Schema}
                                                            onChange={({ data, errors }) => {
                                                                handleDataChanged(einrichtung.id, data, errors);
                                                            }}
                                                            uischema={
                                                                EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE ===
                                                                einrichtung.art
                                                                    ? uiSchemaTagespflege
                                                                    : uiSchema
                                                            }
                                                            readonly={!isSelected(einrichtung.id)}
                                                            validationMode={
                                                                isSelected(einrichtung.id)
                                                                    ? 'ValidateAndShow'
                                                                    : 'NoValidation'
                                                            }
                                                        />
                                                    </CardContent>
                                                </StyledMeldungCard>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={12} md={10}>
                                            <Alert severity="warning">Es wurden keine Einrichtungen gefunden</Alert>
                                        </Grid>
                                    )}
                                </AppLoaderContainer>

                                <Grid item xs={12} md={10}>
                                    <FormLoadingButton
                                        variant="contained"
                                        onClick={handleSubmit}
                                        loading={isInProgress}
                                    >
                                        Melden
                                    </FormLoadingButton>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

const UnauthorizedPlatzmelderPage = Index;
const AuthorizedPlatzmelderPage = withAuthorization(withSideBar(Index, menuVerwaltung), [ROLES.ROLE_PLATZMELDER]);

const StyledMeldungCardHeader = styled(CardHeader)(
    ({ theme }) => `
    .MuiCardHeader-title {
        font-size: 16px;
    }

    .MuiCardHeader-action {
        align-self: center;
        margin-right: ${theme.spacing(1)}
    }

    border-bottom: 1px solid ${theme.palette.divider};
`
);

const StyledMeldungCard = styled(Card)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '.MuiTypography-h2': {
        fontSize: 16,
    },
    '.MuiTypography-subtitle1': {
        fontSize: 14,
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '.MuiFormControlLabel-label': {
        fontWeight: '600',
    },
}));

const MeldungStatusInfos = styled(Typography)<{ $color?: string }>(({ theme }) => ({
    fontSize: 14,
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
}));

export const PlatzmelderPage = () => {
    const isLoggedIn = useIsLoggedIn();
    const hasRole = useHasRole();

    if (isLoggedIn) {
        return (
            <>
                {hasRole([ROLES.BEHOERDE, ROLES.SUPPORT]) ? <AuthorizedPlatzmelderBehoerdePage /> : null}
                {hasRole(ROLES.ANBIETER) ? <AuthorizedPlatzmelderPage /> : null}
            </>
        );
    }

    return <UnauthorizedPlatzmelderPage />;
};
