import React from 'react';
import { Chip, styled } from '@mui/material';

export const StyledChip = styled(Chip)(
    ({ theme }) => `
  color: white;
  font-weight: bold;
  background: darkred;
  border: 1px solid darkred;

  &:focus {
    border: 1px solid ${theme.palette.text.primary};
  }
`
);

export const VersionTag = () => {
    const release = process.env.REACT_APP_VERSION;

    return (
        <>
            {release ? (
                <StyledChip
                    size="small"
                    label={release}
                    tabIndex={0}
                    aria-label={`Aktuelle Anwendungsversion: ${release}`}
                />
            ) : null}
        </>
    );
};
