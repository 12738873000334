import React from 'react';
import { LiveAnnouncer } from 'react-aria-live';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import { createBrowserHistory } from 'history';

import { AuthProvider } from './api/auth';
import { ConfirmationDialog } from './components/Dialog/ConfirmationDialog';
import { useSentry } from './components/Sentry/useSentry';
import { FormStateProvider } from './forms/state/FormStateProvider';
import { Routing } from './navigation/Routing';
import { theme } from './theme/theme';
import { BenutzerAufgaben } from './utilities/BenutzerAufgaben';

export const App = () => {
    const history = createBrowserHistory();
    const { initSentry, ErrorBoundary } = useSentry();

    initSentry();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    {/* @ts-ignore */}
                    <Router history={history}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                            <LiveAnnouncer>
                                <CssBaseline />

                                <AuthProvider>
                                    <FormStateProvider>
                                        <BenutzerAufgaben />
                                        <Routing />
                                    </FormStateProvider>

                                    <ConfirmationDialog />
                                </AuthProvider>
                            </LiveAnnouncer>
                        </LocalizationProvider>
                    </Router>
                </ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
