import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { create } from 'zustand';

import { useHasRole } from 'api/auth';
import { MenuInterface } from 'layout/HeaderConfig';

interface SideBarState {
    menus: MenuInterface[];
    setMenus: (menus: MenuInterface[]) => void;
}

export const useSideBar = create<SideBarState>((set, get) => ({
    menus: [],
    setMenus: (menus: MenuInterface[]) => {
        if (isEqual(get().menus, menus)) {
            return;
        }

        set({ menus: menus });
    },
}));

export const useSideBarMenuConfig = (menus: MenuInterface[] = []) => {
    const setMenus = useSideBar((s) => s.setMenus);
    const hasRole = useHasRole();

    const allowedMenus = menus.filter((menu) => hasRole(menu.roles));

    useEffect(() => {
        if (!allowedMenus.length) return setMenus(allowedMenus);
        setMenus(allowedMenus);
    }, [allowedMenus, setMenus]);
};

export const withSideBar = (Component: React.FC<any>, menus: MenuInterface[] = []): React.FC => {
    return (props) => {
        useSideBarMenuConfig(menus);
        return <Component {...props} />;
    };
};
