import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Button,
    ButtonProps,
    ClickAwayListener,
    Grow,
    MenuList,
    MenuProps,
    Paper,
    Popper,
    styled,
} from '@mui/material';

import { IconExpand } from 'elements/IconExpand';
import { KEY, useKeyDown } from 'forms/hooks/useKeyDown';
import { transientOptions } from 'theme/utils';

interface DropdownProps {
    label: React.ReactElement | string;
    name?: string;
    startIcon?: React.ReactNode;
    buttonProps?: ButtonProps;
    menuProps?: MenuProps;
    children?: React.ReactNode;
}

export const Dropdown = ({ name, label, startIcon, buttonProps, menuProps, children }: DropdownProps) => {
    const [isOpen, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const location = useLocation();

    const openMenu = (currentTarget: HTMLElement) => {
        setAnchorEl(currentTarget);
        setOpen(true);
    };

    const handleDropdownButton = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        openMenu(event.currentTarget);
    };

    const onKeyDown = useKeyDown<HTMLButtonElement>(
        useCallback((key, e) => {
            e.stopPropagation();
            e.preventDefault();
            openMenu(e.currentTarget);
        }, []),
        [KEY.SPACE, KEY.ENTER, KEY.ARROW_DOWN]
    );

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
            return;
        }

        setAnchorEl(null);
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(isOpen);

    useEffect(() => {
        if (prevOpen.current && !isOpen) {
            anchorEl?.focus();
        }

        prevOpen.current = isOpen;
    }, [anchorEl, isOpen]);

    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    return (
        <>
            <DropdownButton
                startIcon={startIcon}
                onClick={handleDropdownButton}
                onKeyDown={onKeyDown}
                role="link"
                id={`dropdown-button-${name}`}
                aria-controls={isOpen ? `dropdown-button-${name}` : undefined}
                aria-expanded={isOpen}
                aria-haspopup="true"
                {...buttonProps}
            >
                {label}

                <IconExpand open={isOpen} />
            </DropdownButton>

            <Popper
                open={isOpen}
                anchorEl={anchorEl}
                placement="bottom-start"
                role="presentation"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    component="nav"
                                    autoFocusItem={isOpen}
                                    onClose={handleClose}
                                    aria-labelledby={`dropdown-button-${name}`}
                                    onKeyDown={handleListKeyDown}
                                    {...menuProps}
                                >
                                    {children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export const DropdownButton = styled(
    Button,
    transientOptions
)<{ $isActive?: boolean }>(({ theme, $isActive }) => ({
    color: '#000',
    backgroundColor: $isActive ? 'rgba(25, 118, 210, 0.04)' : 'inherit',
    border: `inset 1px transparent`,
    '& .MuiSvgIcon-root': {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: 0,
    },
    '&:focus': {
        border: 'solid 1px',
    },
})) as typeof Button;
