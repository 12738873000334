import { modalStandort } from 'forms/Meldung/modalElements';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';

export const uiSchema = customVerticalLayout([
    group(
        [
            listControlWithModalForm(
                ['#/properties/stepStandortStandorte'],
                [
                    tableColumn('Hauptstandort', { scopes: ['#/properties/hauptstandort'] }, { format: 'boolean' }),
                    tableColumn('Gesamtplatzzahl', { scopes: ['#/properties/gesamtPlatzzahl'] }),
                    tableColumn('Adresse', { scopes: ['#/properties/label'] }),
                ],
                ['Standort hinzufügen'],
                ['Standort löschen'],
                ['Wollen Sie diesen Standort mit allen Angaben wirklich löschen?'],
                [modalStandort],
                {
                    types: ['Standort'],
                    field: 'stepStandortStandorte',
                    labelScope: '#/properties/stepStandortStandorte',
                }
            ),
        ],
        {
            noSeparator: true,
        }
    ),
    group(
        [
            info({
                message:
                    'Die angezeigten Werte basieren auf den Angaben der einzelnen Standorte und werden automatisch berechnet.',
            }),
            inlineRowControl([
                control('stepStandortGesamtPlatzzahl'),
                control('stepStandortEingestreuteKurzzeitpflegeplaetze'),
                control('stepStandortSeparateKurzzeitpflegeplaetze'),
            ]),
            inlineRowControl([
                control('stepStandortAnnerkanntePlaetze'),
                control('stepStandortTeilzeitbeschaeftigte'),
                control('stepStandortBetriebsintegriertePlatzzahl'),
            ]),
        ],
        { noSeparator: true },
        'Zusammenfassung der Standorte'
    ),
    control('notizStandort'),
]);
