import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { DokumentJsonld } from 'api/client';
import { InlineResponse } from 'api/responses/InlineResponse';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';

interface DokumentDataBase {
    upload?: DokumentJsonld | null;
    einrichtungsArt?: Array<string>;
}

type DokumentDataResponse = InlineResponse<DokumentDataBase>;

export const useDokumentColumns = <DataResponseType extends DokumentDataResponse>(
    handleAction: AntragListAction<any>
): ((data: DataResponseType | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'upload',
                    label: 'Dateiname',
                    options: {
                        sort: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = data['hydra:member']?.[dataIndex];
                            return rowData?.upload?.originalName || 'Keine Datei';
                        },
                    },
                },
                {
                    name: 'einrichtungsart',
                    label: 'Art des Leistungsangebots',
                    options: {
                        sort: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = data['hydra:member']?.[dataIndex];
                            return rowData?.einrichtungsArt?.length
                                ? rowData.einrichtungsArt.join(', ')
                                : 'Keine Einschränkung';
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
