import React, { useState } from 'react';
import { LiveMessage } from 'react-aria-live';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyOwnProps } from '@mui/material/Typography/Typography';

import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

interface PageHeadingProps extends TypographyOwnProps {
    title: string;
    variant?: Variant;
    children?: React.ReactNode;
    className?: string;
}

export const PageHeading = ({ title, variant, children, ...props }: PageHeadingProps) => {
    const [message, setMessage] = useState<string>('');

    useDebouncedEffect(() => setMessage(title || ''), [title], 250);

    return (
        <>
            <LiveMessage aria-live="assertive" message={message} clearOnUnmount="true" />
            <Typography variant={variant || 'h1'} {...props}>
                <>
                    <span>{title}</span>
                    {children || null}
                </>
            </Typography>
        </>
    );
};
