import * as React from 'react';
import { Box, FormControl, FormHelperText, styled, TextField, Typography } from '@mui/material';

import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { useTempControlData } from 'forms/hooks/useTempControlData';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';
import { theme } from 'theme/theme';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const PlaintextMehrzeiligerTextControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    uischema,
    readonly,
    hasErrors,
    errors,
    showFieldNumberLabels,
    gridLayout,
    onTouched,
    id,
}) => {
    const { tempData, handleValueChange, handleOnBlur } = useTempControlData(
        data,
        path,
        errors,
        handleChange,
        (e) => e.target.value,
        onTouched,
        {
            validateOnBlur: false,
            validateOnChange: false,
        }
    );
    const { scrollRef } = useScrollInView<HTMLDivElement>({});

    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: 8,
    };
    const grid = { ...defaultGrid, ...gridLayout };

    return (
        <RowControl
            name={path}
            label={uischema?.options?.noLabel === true ? undefined : label}
            required={required}
            multiline={uischema?.options?.multiline}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={grid}
            labelForId={id}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyContainer>
                            <ReadonlyValueText label={label} hasErrors={hasErrors} errors={errors} text={data || '-'} />
                        </ReadonlyContainer>
                    ) : (
                        <FormControl fullWidth ref={scrollRef} error={hasErrors}>
                            <Typography variant="h3" sx={{ marginBottom: theme.spacing(3) }}>
                                {label}
                                {required && <RequiredFlag />}
                            </Typography>

                            <TextField
                                id={id}
                                fullWidth
                                required={required}
                                error={hasErrors}
                                value={tempData}
                                onBlur={handleOnBlur}
                                onChange={handleValueChange}
                                multiline={true}
                                rows={5}
                            />

                            {!readonly && schema?.description && <FormHelperText>{schema.description}</FormHelperText>}

                            {!readonly && hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

const ReadonlyContainer = styled(Box)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: theme.palette.divider,
    margin: -8,
    padding: 6,
}));

export const PlaintextMehrzeiligerTextControl = withCustomControlProps(PlaintextMehrzeiligerTextControlComponent);
