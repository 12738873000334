import { RuleEffect } from '@jsonforms/core';

import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { formDialog } from 'forms/ui-schemas/elements/formDialog';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { labeled } from 'forms/ui-schemas/elements/labeled';

export const modalKonzeptinformationen = formDialog(
    'Neuer Schwerpunkt',
    'Schwerpunkt bearbeiten',
    labeled({ labelScope: '#/properties/name' }),
    customVerticalLayout(
        [
            inlineRowControl([
                control('name'),
                control('customName', {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/name',
                            schema: {
                                enum: ['CUSTOM'],
                                required: ['customName'],
                            },
                        },
                    },
                }),
                control('plaetze', { options: { validateOnChange: true } }),
            ]),
        ],
        {
            spacing: 1,
        }
    ),
    { scope: '#/properties/konzeptinformationen', actionKey: null }
);

export const modalStandort = formDialog(
    'Neuer Standort',
    'Standort bearbeiten',
    labeled({ labelScope: undefined }),
    customVerticalLayout(
        [
            group([control('hauptstandort')], { noSeparator: true }),
            inlineRowControl([control('standortAdresse'), control('gesamtPlatzzahl')]),
            inlineRowControl([control('einzelzimmerPlaetze'), control('doppelzimmerPlaetze')]),
            inlineRowControl([control('eingestreuteKurzzeitpflegeplaetze'), control('separateKurzzeitpflegeplaetze')]),
            inlineRowControl([control('annerkanntePlaetze'), control('teilzeitbeschaeftigte')]),
            inlineRowControl([control('betriebsintegriertePlatzzahl')]),
        ],
        { spacing: 1 }
    ),
    { scope: '#/properties/stepStandortStandorte', actionKey: null }
);

export const modalEinrichtungsleitung = formDialog(
    'Neuer Ansprechpartner',
    'Ansprechpartner bearbeiten',
    labeled({ labelScope: '#/properties/personalName' }),
    customVerticalLayout(
        [
            inlineRowControl([control('personalName')]),
            inlineRowControl([control('personalTaetigSeit'), control('personalStellenAnteil')]),
            inlineRowControl([
                control('personalTelefon'),
                control('personalFax'),
                control('personalEmail', { requireEmailConfirm: false }),
            ]),
        ],
        { spacing: 1 }
    ),
    { scope: '#/properties/stepBeschaeftigteEinrichtungsleitung', actionKey: null }
);

export const modalVerantwortlicheFachkraft = formDialog(
    'Neuer Ansprechpartner',
    'Ansprechpartner bearbeiten',
    labeled({ labelScope: '#/properties/fachkraftName' }),
    customVerticalLayout(
        [
            inlineRowControl([control('fachkraftName')]),
            inlineRowControl([control('fachkraftTaetigSeit'), control('fachkraftStellenAnteil')]),
            inlineRowControl([
                control('fachkraftTelefon'),
                control('fachkraftFax'),
                control('fachkraftEmail', { requireEmailConfirm: false }),
            ]),
        ],
        { spacing: 1 }
    ),
    { scope: '#/properties/stepBeschaeftigtePflegedienstleitung', actionKey: null }
);

export const modalLeistungserbringer = formDialog(
    'Neuer Leistungserbringer',
    'Leistungserbringer bearbeiten',
    labeled({ labelScope: '#/properties/name' }),
    customVerticalLayout(
        [
            control('name'),
            control('adresse'),
            control('ansprechpartner'),
            inlineRowControl([control('telefon'), control('fax')]),
            control('mail'),
        ],
        {
            spacing: 1,
        }
    ),
    { scope: '#/properties/stepLeistungserbringerLeistungserbringer', actionKey: null }
);
