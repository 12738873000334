import { ReactNode } from 'react';
import { Typography } from '@mui/material';

import { useSpecificRole } from 'api/auth';
import { StepsType } from 'api/types';

import { useBeschaeftigteDescription } from './useBeschaeftigteDescription';
import { useDokumenteDescription } from './useDokumenteDescription';
import { useKonzeptinformationDescription } from './useKonzeptinformationDescription';
import { useLeistungserbringerDescription } from './useLeistungserbringerDescription';
import { useStandortDescription } from './useStandortDescription';

export const useStepDescription = (currentStep?: string, einrichtungArt?: string): ReactNode => {
    const { isAnbieter } = useSpecificRole();

    const getKonzeptinformationDescription = useKonzeptinformationDescription();
    const getStandortDescription = useStandortDescription();
    const getBeschaeftigteDescription = useBeschaeftigteDescription();
    const getLeistungserbringerDescription = useLeistungserbringerDescription();
    const getDokumenteDescription = useDokumenteDescription();

    if (!isAnbieter) {
        return <></>;
    }

    switch (currentStep) {
        case StepsType.BASISDATEN:
            return (
                <Typography paragraph>
                    Hier können Sie Änderungen an Ihren Basisdaten vornehmen. Die Basisdaten der Leistungsanbieter
                    können derzeit nur von der WTG-Behörde geändert werden. Bitte wenden Sie sich hierzu bitte an Ihre
                    zuständige WTG-Behörde.
                </Typography>
            );
        case StepsType.KONZEPTINFORMATION:
            return getKonzeptinformationDescription(einrichtungArt);
        case StepsType.STANDORTE:
            return getStandortDescription(einrichtungArt);
        case StepsType.BESCHAEFTIGTE:
            return getBeschaeftigteDescription(einrichtungArt);
        case StepsType.LEISTUNGSERBRINGER:
            return getLeistungserbringerDescription(einrichtungArt);
        case StepsType.VERTRAEGE:
            return (
                <Typography paragraph>
                    Bitte laden sie hier Verträge und Vereinbarungen (z. B. Kooperationsvereinbarungen,
                    Anerkennungsbescheide, Versorgungsverträge / Vergütungsvereinbarungen / Leistungs- und
                    Prüfungsvereinbarungen in der jeweils aktuellen Fassung) bzw. Muster der mit den Nutzern
                    abgeschlossenen Verträge hoch.
                </Typography>
            );
        case StepsType.DOKUMENTE:
            return getDokumenteDescription(einrichtungArt);
        default:
            return <></>;
    }
};
