import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { backendApiService } from 'api/ApiService';
import { LeistungsangebotEntwurfCollectionResponse } from 'api/types';
import { EmptyColumnHeader } from 'components/DataTable/EmptyColmnHeader';
import { FormTableActions } from 'forms/controls/components/FormTableActions';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage, successMessage } from 'forms/utilities/MessageUtils';
import { PathBuilder } from 'navigation/Paths';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

export const useAnbieterLeistungsangeboteColumns = (
    setServerDataChanged: React.Dispatch<React.SetStateAction<number>>,
    segment?: string
): ((data: LeistungsangebotEntwurfCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const navigate = useNavigate();

    const { transform2Date } = useFormatDate();
    const { setRequestMessage } = useFormState();
    // ToDo Delete als Workflowaction oder in actions list hook auslagern
    const handleDelete = useCallback(
        (index: number, data: LeistungsangebotEntwurfCollectionResponse) => {
            const currentItem = data['hydra:member']?.[index];

            if (!currentItem) return;

            if (!currentItem?.id) {
                return setRequestMessage(errorMessage('Der Entwurf existiert nicht mehr'));
            }

            backendApiService
                .deleteLeistungsangebotEntwurfItem(currentItem.id)
                .then(() => {
                    setRequestMessage(successMessage('Entwurf wurde erfolgreich gelöscht'));
                    setServerDataChanged((prevState) => prevState + 1);
                })
                .catch(() => {
                    setRequestMessage(errorMessage('Beim Löschen ist ein Fehler aufgetreten'));
                });
        },
        [setRequestMessage, setServerDataChanged]
    );

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                {
                    name: 'id',
                    options: {
                        display: false,
                    },
                },
                {
                    name: 'uuid',
                    options: {
                        display: false,
                    },
                },
                {
                    name: 'einrichtungName',
                    label: 'Name',
                },
                {
                    name: 'modifiedAt',
                    label: 'Bearbeitet am',
                    options: {
                        customBodyRender: (value) => transform2Date(value),
                    },
                },
                {
                    name: '',
                    options: {
                        sort: false,
                        customHeadRender: EmptyColumnHeader,
                        customBodyRender: (value, tableMeta) => {
                            return (
                                <FormTableActions
                                    editable
                                    deletable
                                    onEdit={(rowIndex) => {
                                        const dataList = data['hydra:member']?.[rowIndex];
                                        const id = dataList?.id;

                                        if (!id) return;

                                        navigate(
                                            PathBuilder.home.verwaltung.leistungsangebote.edit({
                                                id,
                                                segment,
                                            })
                                        );
                                    }}
                                    onDelete={(index) => handleDelete(index, data)}
                                    deleteAlertText={() =>
                                        'Das Löschen eines Entwurfs kann <strong>nicht rückgängig</strong> gemacht werden. Möchten Sie mit dem Löschen fortfahren?'
                                    }
                                    actions={[]}
                                    index={tableMeta.rowIndex}
                                />
                            );
                        },
                    },
                },
            ];
        },
        [handleDelete, segment, navigate, transform2Date]
    );
};
