import { AlertColor } from '@mui/material/Alert/Alert';
import { noop } from 'lodash';
import { create } from 'zustand';

export interface ConfirmDialogContent {
    severity?: AlertColor;
    alertText?: string;
    denyLabel?: string;
    confirmLabel?: string;
    denyAction?: () => void;
    confirmAction: () => void;
}

interface ConfirmationDialogProps extends ConfirmDialogContent {
    open: boolean;
    showConfirmation: (props: ConfirmDialogContent) => void;
    closeConfirmation: () => void;
}

export const useConfirmationDialogState = create<ConfirmationDialogProps>((set) => {
    const defaultValues = {
        severity: 'info' as AlertColor,
        alertText: 'Sind Sie sicher?',
        denyLabel: 'Nein',
        confirmLabel: 'Ja',
        open: false,
        confirmAction: noop,
        denyAction: noop,
    };

    return {
        ...defaultValues,
        showConfirmation: (props) => set({ ...props, open: true }),
        closeConfirmation: () => {
            set({ open: false });
            setTimeout(() => set({ ...defaultValues }), 500);
        },
    };
});

export const useConfirmationDialogActions = () =>
    useConfirmationDialogState((s) => ({
        showConfirmation: s.showConfirmation,
        closeConfirmation: s.closeConfirmation,
    }));
