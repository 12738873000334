import { backendApiService } from 'api/ApiService';
import { AntragPathBuilder, useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useMeldungenAntragListActions = ({ pathBuilder }: { pathBuilder?: AntragPathBuilder }) => {
    return useListActions(
        pathBuilder || PathBuilder.home.verwaltung.meldung,
        backendApiService.workflowMeldungItem.bind(backendApiService)
    );
};
