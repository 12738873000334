import { useParams } from 'react-router-dom';

import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { EmailAntragForm } from 'pages/EmailAntragForm';

const Index = () => {
    const { einrichtungId } = useParams();

    return (
        <EmailAntragForm
            antragType={AntragType.EMAIL_ANTRAG}
            pathBuilder={PathBuilder.home.verwaltung.leistungsangebote.freigegeben}
            cancelPath={PathBuilder.home.verwaltung.leistungsangebote.freigegeben.nachrichten({
                einrichtungId: Number(einrichtungId),
            })}
        />
    );
};

export const LeistungsangeboteBehoerdeNachrichtCreatePage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_NACHRICHTEN
);
