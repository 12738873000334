import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { AbwZuordnung } from './components/AbwZuordnung';

const Index = () => {
    return (
        <AbwZuordnung
            title="Treffer zuordnen"
            pathBuilder={PathBuilder.home.verwaltung.abw}
            config={{ addAdresseCoords: true }}
        />
    );
};

export const AbwTrefferZuordnenPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN
);
