import React from 'react';
import { Box, styled } from '@mui/material';

import { EmailAntragJsonldEmailAntragReadEingereichtBy } from 'api/client';
import { BadgeColumn } from 'components/DataTable/components/BadgeColumn';
import { BENUTZER_STATUS } from 'constants/BENUTZER_STATUS';
import { BENUTZER_STATUS_COLORS, BENUTZER_STATUS_LABELS } from 'constants/labels';

export const StyledBadgeColumn = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

export const BenutzerStatusBadge = ({ sender }: { sender?: EmailAntragJsonldEmailAntragReadEingereichtBy | null }) => {
    return (
        <>
            {sender?.nachname && sender?.vorname ? (
                <StyledBadgeColumn>
                    {`${sender?.nachname}, ${sender?.vorname}`}
                    {sender?.status && sender.status !== BENUTZER_STATUS.ACTIVE ? (
                        <BadgeColumn
                            content={BENUTZER_STATUS_LABELS[sender.status]}
                            size="small"
                            textBold
                            fontSize={14}
                            color={BENUTZER_STATUS_COLORS[sender.status]}
                        />
                    ) : null}
                </StyledBadgeColumn>
            ) : (
                'System'
            )}
        </>
    );
};
