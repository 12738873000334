import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField/useField.types';
import { isEqual } from 'date-fns';
import formatDate from 'date-fns/format';

import { DatePicker } from 'forms/components/DatePicker';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { ReadonlyValueText } from 'forms/controls/components/ReadonlyValueText';
import { useFocus } from 'forms/hooks/useFocus';
import { useScrollInView } from 'forms/hooks/useScrollInView';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { formatDateToString } from 'forms/utilities/formatter';

import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const data2value = (data?: string | null): Date | null => {
    if (!data?.length) return null;
    const date = new Date(data);
    if (isNaN(date.getTime())) return null;

    return date;
};

const DateControlComponent: React.FC<CustomControlProps<string>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    hasErrors,
    errors,
    disabled,
    readonly,
    formula,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    config,
    schema,
    id,
    enabled,
}) => {
    const [value, setValue] = useState<Date | null>(data2value(data));
    const { ref } = useFocus<HTMLInputElement>();
    useScrollInView({ ref });

    useEffect(
        () => {
            const date = data2value(data);

            if (!date || !value || !isEqual(date, value)) {
                setValue(date);
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [data]
    );

    const handleDateChange = useCallback(
        (value: string | Date | null, context: FieldChangeHandlerContext<any>) => {
            if (context.validationError !== null) return;

            const date = typeof value === 'string' ? new Date(value) : value;

            if (!date || (date && !isNaN(date.getTime()))) {
                handleChange(path, (date && formatDate(date, 'yyyy-MM-dd')) || null, true);
            }
        },
        [handleChange, path]
    );

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            formula={formula}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            labelForId={id}
        >
            <FormControl fullWidth data-cy="Date-FormControl">
                <FlexDiv>
                    {readonly ? (
                        <ReadonlyValueText
                            label={label}
                            text={data ? formatDateToString(new Date(data)) : '-'}
                            hasErrors={false}
                            errors={[]}
                        />
                    ) : (
                        <FormControl fullWidth data-cy="Date-FormControl">
                            <DatePicker
                                error={hasErrors}
                                value={value}
                                onChange={handleDateChange}
                                disabled={!enabled || disabled}
                                inputProps={{
                                    required,
                                    label,
                                    id,
                                    'data-cy': `form_${path}`,
                                    style: {
                                        textAlign: 'right',
                                    },
                                    tabIndex: uischema?.firstControl ? 0 : -1,
                                    autoFocus: uischema?.firstControl && config.autoFocusFirstControl,
                                }}
                                inputRef={ref}
                            />

                            {!readonly && schema?.description && <FormHelperText>{schema.description}</FormHelperText>}

                            {!readonly && hasErrors && (
                                <FormHelperText component="div">
                                    <ErrorList errors={errors} />
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const DateControl = withCustomControlProps(DateControlComponent);
