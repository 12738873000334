import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { info } from 'forms/ui-schemas/elements/info';

export const messageVariablesText =
    'Der Nachricht wird automatisch Ihre Signatur angehängt.<br />' +
    'Sie können in der Nachricht folgende Platzhalter verwenden:<br />' +
    '<ul style="margin-left: 8px"><li><b>@meldefrist</b>: Das Datum, das Sie im Feld "Meldefrist" eingeben</li>' +
    '<li><b>@leistungsangebot</b>: NRW-Schlüssel und Name des Leistungsangebots</li></ul>';

export const uiSchema = customVerticalLayout([
    info({ options: { withMessageKey: true } }),
    control('einrichtung'),
    control('empfaenger'),
    control('updateDeadline'),
    control('betreff'),
    control('nachricht', { withInfoIcon: true }),
    control('dokumente'),
    info({ message: messageVariablesText }),
]);
