import React, { useCallback } from 'react';
import { MUIDataTableColumn } from 'mui-datatables';

import { ActionDropdownAntrag, LabelOverrides } from 'components/DataTable/ActionDropdown/ActionDropdownAntrag';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { OpenViewTab } from 'components/DataTable/hooks/useTableOptions';
import { getRowData } from 'components/DataTable/tableUtils';
import { ACTIONS, TABLE_ACTIONS } from 'constants/antragActions';

interface UseServerActionsColumn {
    handleAction?: AntragListAction<any>;
    openViewTab?: OpenViewTab;
    disableActions?: boolean;
    labelOverrides?: LabelOverrides;
}

interface ActionData {
    [key: string]: any;
}

export const useServerActionsColumn = ({
    handleAction,
    openViewTab,
    disableActions,
    labelOverrides,
}: UseServerActionsColumn): ((data: ActionData, ariaInfoProp?: string) => MUIDataTableColumn) => {
    return useCallback(
        (data: any, ariaInfoProp?: string) => {
            if (true === disableActions) {
                return {
                    name: '',
                    options: {
                        filter: false,
                        display: false,
                        viewColumns: false,
                    },
                };
            }

            return {
                name: '',
                label: 'Aktion',
                options: {
                    sort: false,
                    viewColumns: false,
                    filter: false,
                    customBodyRenderLite: (dataIndex: number) => {
                        const rowData = getRowData(dataIndex, data);

                        return Boolean(rowData.allowedWorkflowActions?.length || rowData.allowedActions?.length) ? (
                            <ActionDropdownAntrag
                                antrag={rowData}
                                actions={TABLE_ACTIONS}
                                handleAction={(antrag, action) => {
                                    if (action === ACTIONS.VIEW && openViewTab) {
                                        const path = openViewTab.view?.({
                                            id: antrag.id,
                                        });
                                        return window.open(path, '_blank', 'noreferrer');
                                    }

                                    handleAction?.(antrag, action);
                                }}
                                labelOverrides={labelOverrides}
                                ariaInfoText={rowData[String(ariaInfoProp)] || ariaInfoProp}
                            />
                        ) : null;
                    },
                },
            };
        },
        [disableActions, handleAction, labelOverrides, openViewTab]
    );
};
