import React from 'react';
import { Typography } from '@mui/material';

import { ROLE_TYPES } from 'constants/roles';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungHinweisTextForm } from './components/MeldungHinweisTextForm';

const Index = () => {
    return (
        <ContentContainer title="Neuen Anforderungstext erstellen">
            <StyledContainer>
                <Typography variant="h1">Neuen Anforderungstext erstellen</Typography>

                <BaseGridContainer lg={8}>
                    <MeldungHinweisTextForm
                        submitRedirect
                        submitLabel="Erstellen"
                        messages={{
                            success: 'Anforderungstext wurden erstellt.',
                            error: 'Erstellen des Anforderungstext fehlgeschlagen.',
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MeldungHinweisTextCreatePage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT
);
