import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { URL_EINRICHTUNG } from 'api/routes';
import { BenutzerZugriffsrechteListResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';
import { toIriId } from 'utilities/IriUtils';

export const useBenutzerZugriffsrechteListData = (
    einrichtungId: string
): GetData<BenutzerZugriffsrechteListResponse> => {
    return useCallback(
        ({ page, rowsPerPage, sortOrder }) => {
            return backendApiService.getZugriffsrechteBenutzer({
                page,
                itemsPerPage: rowsPerPage || 9999,
                einrichtungBenutzerEinrichtung: toIriId(URL_EINRICHTUNG, einrichtungId),
                existsDeletedAt: false,
                existsDeaktiviertAt: false,
                orderVorname: sortBy(sortOrder, 'vorname'),
                orderNachname: sortBy(sortOrder, 'nachname'),
                orderEmail: sortBy(sortOrder, 'email'),
            });
        },
        [einrichtungId]
    );
};
