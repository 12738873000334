import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { ComponentError } from 'forms/hooks/useErrors';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { ErrorList } from './components/ErrorList';
import { FlexDiv } from './components/FlexDiv';
import { RowControl } from './RowControl';

const UnternehmensgruppeAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    config,
    handleChange,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    hasErrors,
    errors,
    id,
}) => {
    const [errorList, setErrorList] = useState<ComponentError[]>(errors);
    const handleValueChange = useCallback(
        (value: SelectOption | null) => {
            handleChange(path, value, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, options: AxiosRequestConfig): Promise<any> => {
        return backendApiService.getUnternehmensgruppeCollection({ name: value, options: { signal: options.signal } });
    };

    // ToDo: Korrekten Type verwenden
    const getOption = (item: any): SelectOption => {
        return {
            id: item.id as number,
            name: item.name,
        };
    };

    const hasErrorList = useMemo(() => {
        if (!required) return false;

        return (!data || !Object.keys(data || {}).includes('label')) && config.formTouched;
    }, [required, config, data]);

    useEffect(() => {
        if (required && hasErrorList) {
            setErrorList([{ message: 'Eingabe erforderlich', path }]);
        }
    }, [required, hasErrorList, path, data]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <FormControl fullWidth>
                <FlexDiv>
                    <FormControl fullWidth>
                        <Typeahead
                            id={id}
                            data={data}
                            error={hasErrors || hasErrorList}
                            schema={schema}
                            uischema={uischema}
                            path={path}
                            required={required}
                            getData={getData}
                            getOption={getOption}
                            onChange={handleValueChange}
                            multiple={false}
                            loadInitialOptions
                            defaultOptions={[
                                { id: -1, name: 'Keine' },
                                { id: -2, name: 'Andere' },
                            ]}
                            noOptionsText="Keine Unternehmensgruppe gefunden. Geben Sie mindestens 3 Zeichen ein."
                        />

                        {(hasErrors || hasErrorList) && (
                            <FormControl>
                                <FormHelperText component="div">
                                    <ErrorList errors={errorList} />
                                </FormHelperText>
                            </FormControl>
                        )}
                    </FormControl>
                </FlexDiv>
            </FormControl>
        </RowControl>
    );
};

export const UnternehmensgruppeAutocompleteControl = withCustomControlProps(
    UnternehmensgruppeAutocompleteControlComponent
);
