import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useApiEinrichtungGet } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { usePlatzzahlmelderHistorieColumns } from 'components/DataTable/columns/platzzahlmelder/usePlatzzahlmelderHistorieColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { EinrichtungTypes } from 'constants/einrichtungTypes';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { usePlatzmelderHistoryListData } from './hooks/usePlatzmelderHistoryListData';

const Index = () => {
    const { token, id: einrichtungId } = useParams();

    const { isLoading: isLoadingEinrichtung, data: einrichtungData } = useApiEinrichtungGet(einrichtungId);

    const pageTitle = useMemo(() => {
        if (einrichtungData) {
            return `Meldehistorie: ${einrichtungData.name}`;
        }

        return null;
    }, [einrichtungData]);

    const getData = usePlatzmelderHistoryListData(einrichtungId);
    const columns = usePlatzzahlmelderHistorieColumns(einrichtungData?.art as EinrichtungTypes);

    return (
        <ContentContainer title={pageTitle ?? 'Historie'}>
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoadingEinrichtung}>
                    {pageTitle ? <PageHeading title={pageTitle} /> : null}

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        initialOptions={{
                            viewColumns: true,
                            sortOrder: { name: 'createdAt', direction: 'desc' },
                            custom: { disableSearch: true },
                        }}
                    />

                    <ButtonGroupGrid
                        backLink={{ path: PathBuilder.home.verwaltung.platzmelder.token(token!), title: 'Zurück' }}
                    />
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const PlatzmelderHistoryPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PLATZMELDER_HISTORY
);
