import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthUserId, useSpecificRole } from 'api/auth';
import { BehoerdeBehoerdeWrite } from 'api/client';
import { useBehoerdeDtoItem } from 'api/hooks/useApiClient';
import { SimpleForm } from 'forms/SimpleForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';

import { behoerdeSchema } from './schema';
import { behoerdeUiSchema } from './ui-schema';

interface BehoerdenEditProps {
    disableBackLink?: boolean;
}

export const BehoerdenEdit = ({ disableBackLink = false }: BehoerdenEditProps) => {
    const { id } = useParams();

    const { isSupport } = useSpecificRole();
    const userId = useAuthUserId();

    const benutzerId = isSupport ? id : userId;
    const { data, isLoading, error } = useBehoerdeDtoItem(String(benutzerId));

    const handleSubmit = (changedData: BehoerdeBehoerdeWrite) => {
        return backendApiService.patchBehoerdenItem(String(benutzerId), changedData);
    };

    const backLink = useMemo(() => {
        if (disableBackLink) {
            return undefined;
        }

        return {
            title: 'Zur Übersicht',
            path: PathBuilder.home.verwaltung.behoerden.path,
        };
    }, [disableBackLink]);

    return (
        <ContentContainer title="Kreis / kreisfreie Stadt bearbeiten">
            <StyledContainer>
                {!error ? (
                    <SimpleForm
                        isLoading={isLoading}
                        data={data}
                        schema={behoerdeSchema}
                        uiSchema={behoerdeUiSchema}
                        submitLabel="Eintrag aktualisieren"
                        onSubmit={handleSubmit}
                        messages={{
                            success: 'Daten wurden aktualisiert.',
                            error: 'Daten konnten nicht aktualisiert werden.',
                        }}
                        backLink={backLink}
                    />
                ) : (
                    <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                )}
            </StyledContainer>
        </ContentContainer>
    );
};
