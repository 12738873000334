import React from 'react';
import { Alert, Grid, styled } from '@mui/material';

import { FormButton } from 'forms/components/FormButton';
import { useConfirmationDialogState } from 'forms/state/useConfirmationDialogState';

import { Dialog } from './Dialog';

const TopLevelConfirmationDialog = styled(Dialog)(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 10,
}));

export const ConfirmationDialog: React.FC = () => {
    const { open, severity, alertText, denyAction, confirmAction, denyLabel, confirmLabel, closeConfirmation } =
        useConfirmationDialogState();

    return (
        <TopLevelConfirmationDialog
            open={open}
            closeAction={<></>}
            onClose={denyAction ? denyAction : closeConfirmation}
            data-testid="ConfirmationDialog"
            actions={
                <Grid container direction="row-reverse" spacing={1}>
                    {/* reversed order (Tab order) */}
                    <Grid item>
                        <FormButton variant="contained" color="primary" onClick={confirmAction}>
                            {confirmLabel}
                        </FormButton>
                    </Grid>
                    {denyAction && (
                        <Grid item>
                            <FormButton variant="outlined" onClick={denyAction}>
                                {denyLabel}
                            </FormButton>
                        </Grid>
                    )}
                </Grid>
            }
            maxWidth="sm"
        >
            <Alert severity={severity}>
                <span dangerouslySetInnerHTML={{ __html: String(alertText) }} />
            </Alert>
        </TopLevelConfirmationDialog>
    );
};
