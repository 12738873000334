import { ControlElement } from '@jsonforms/core';
import { GridSize } from '@mui/material';

import { CustomRule, GridLayoutConfig, UiSchemaOptions } from 'forms/types/UiSchemaTypes';

interface ControlOptions extends UiSchemaOptions {
    controlOnly?: boolean;
    gridLayout?: GridLayoutConfig;
    labelVisuallyHidden?: boolean;
    gridSize?: GridSize;
    endAdornment?: string;
    showFieldNumberLabels?: boolean;
    options?: UiSchemaOptions;
    rule?: CustomRule;
    parseValueToInt?: boolean;
    withInfoIcon?: boolean;
    withUploadLimit?: boolean;
    requireEmailConfirm?: boolean;
}

export const control = (field: string, options: ControlOptions = {}): ControlElement => ({
    type: 'Control',
    scope: `#/properties/${field}`,
    ...{
        gridLayout: options?.gridLayout || { input: 12 },
        parseValueToInt: options?.parseValueToInt || false,
        withInfoIcon: options?.withInfoIcon,
        withUploadLimit: options?.withUploadLimit,
        requireEmailConfirm: options?.requireEmailConfirm,
    },
    ...options,
});
