export enum InfoMessages {
    'EMAIL_ANTRAG_HINWEIS_KEINE_MELDUNG' = 'EMAIL_ANTRAG_HINWEIS_KEINE_MELDUNG',
    'EMAIL_ANTRAG_HINWEIS_NEUE_VERSION' = 'EMAIL_ANTRAG_HINWEIS_NEUE_VERSION',
    'EMAIL_ANTRAG_HINWEIS_KEINE_AENDERUNG' = 'EMAIL_ANTRAG_HINWEIS_KEINE_AENDERUNG',
}

export const INFO_MESSAGE_LABELS: Record<InfoMessages, string> = {
    [InfoMessages.EMAIL_ANTRAG_HINWEIS_KEINE_MELDUNG]:
        'Das Leistungsangebot hat bislang noch keine Meldung abgesendet.',
    [InfoMessages.EMAIL_ANTRAG_HINWEIS_NEUE_VERSION]:
        'Die Stammdaten haben sich seit der letzten Meldung des Leistungsangebots geändert.',
    [InfoMessages.EMAIL_ANTRAG_HINWEIS_KEINE_AENDERUNG]:
        'Die Stammdaten haben sich seit der letzten Meldung des Leistungsangebots nicht geändert.',
};
