import React, { useMemo } from 'react';

import { useHasRole } from 'api/auth/useHasRole';
import { LabelOverrides } from 'components/DataTable/ActionDropdown/ActionDropdownAntrag';
import { STATUS } from 'constants/antragStatus';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungUebersicht } from './Components/MeldungUebersicht';
import { useMeldungListData } from './useMeldungListData';

const Index = () => {
    const pathBuilder = PathBuilder.home.verwaltung.meldung;
    const hasRole = useHasRole();
    const allowedCheck = hasRole(ROLE_TYPES.ROLLEN_MELDUNGEN_PRUEFEN);

    const getData = useMeldungListData({ statusList: [STATUS.ENTWURF, STATUS.FREIGEGEBEN, STATUS.EINGEREICHT] });

    const determineActionLabelOverrides: LabelOverrides | undefined = useMemo(() => {
        if (allowedCheck) {
            return { EDIT: { label: 'Meldung prüfen' } };
        }

        return { EDIT: { label: 'Bearbeiten und einreichen' } };
    }, [allowedCheck]);

    return (
        <MeldungUebersicht
            title="Meldungen"
            headline="Meldungen"
            pathBuilder={pathBuilder}
            getData={getData}
            initialTableOptions={{
                filter: true,
                sortOrder: {
                    name: 'letzteMeldung',
                    direction: 'asc',
                },
                custom: {
                    pathBuilder: pathBuilder as PathBuilderProps,
                },
                searchPlaceholder: 'Suche nach NRW-Schlüssel, Name & Art des Leistungsangebots, Status...',
            }}
            labelOverrides={determineActionLabelOverrides}
        />
    );
};

export const MeldungOverview = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_MELDUNGEN);
