import { CustomRule, GridLayoutConfig, InlineRowControlType, UiSchemaType } from 'forms/types/UiSchemaTypes';

export const inlineRowControl = (
    elements: UiSchemaType[],
    options: {
        title?: string;
        gridLayout?: GridLayoutConfig;
        grid?: boolean;
        skipLabel?: boolean;
        rule?: CustomRule;
    } = {},
    scope?: string
): InlineRowControlType => ({
    type: 'InlineRowControl',
    scope,
    elements,
    ...options,
});
