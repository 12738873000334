import { ADRESSE_ANSCHRIFTTYP, TRAEGER_ART_LABELS } from 'constants/labels';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { MeldungProps } from 'pages/Meldung/Details/MeldungDetailPage';

export const Leistungsanbieter = ({ meldung }: MeldungProps) => {
    return (
        <DetailsLayout.Group title="Leistungsanbieter" variant="h3" divider>
            <DetailsLayout.InfoField value={meldung.data?.traegerBezeichnung} label="Bezeichnung inkl. Rechtsform" />
            <DetailsLayout.InfoField value={meldung.data?.traegerNrwKey} label="NRW-Schlüssel" />
            <DetailsLayout.InfoField
                value={ADRESSE_ANSCHRIFTTYP[meldung.data?.traegerAnschriftTyp || '']}
                label="Anschrifttyp"
            />
            <DetailsLayout.InfoField value={meldung.data?.traegerAdresse?.label} label="Anschrift" />
            <DetailsLayout.InfoField value={meldung.data?.traegerTelefon} label="Telefon" />
            <DetailsLayout.InfoField value={TRAEGER_ART_LABELS[meldung.data?.traegerTyp || '']} label="Typ" />
            <DetailsLayout.InfoField value={meldung.data?.traegerEmail} label="E-Mail-Adresse" />
            <DetailsLayout.InfoField
                label="Verbandszugehörigkeit"
                value={meldung.data?.einrichtungTraegerverband?.name || 'Keinem Verband angeschlossen'}
            />
        </DetailsLayout.Group>
    );
};
