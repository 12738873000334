import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const uiSchema = customVerticalLayout([
    group(
        [control('dauerpflegeplaetze', { endAdornment: 'Plätze' }), control('dauerpflegeplaetzeBeschreibung')],
        { noSeparator: true },
        'Dauerpflegeplätze'
    ),
    group(
        [control('kurzpflegeplaetze', { endAdornment: 'Plätze' }), control('kurzpflegeplaetzeBeschreibung')],
        { noSeparator: true },
        'Kurzzeitpflegeplätze'
    ),
    group(
        [
            inlineRowControl([
                inlineRowControl([control('montag', { endAdornment: 'Plätze' })], { title: 'Montag' }),
                inlineRowControl([control('dienstag', { endAdornment: 'Plätze' })], { title: 'Dienstag' }),
                inlineRowControl([control('mittwoch', { endAdornment: 'Plätze' })], { title: 'Mittwoch' }),
                inlineRowControl([control('donnerstag', { endAdornment: 'Plätze' })], { title: 'Donnerstag' }),
                inlineRowControl([control('freitag', { endAdornment: 'Plätze' })], { title: 'Freitag' }),
            ]),
            inlineRowControl([
                inlineRowControl([control('samstag', { endAdornment: 'Plätze' })], { title: 'Samstag' }),
                inlineRowControl([control('sonntag', { endAdornment: 'Plätze' })], { title: 'Sonntag' }),
            ]),
        ],
        { noSeparator: true },
        'Tagespflegeplätze'
    ),
]);
