import { BACKEND_API_BASE_URL } from 'api/ApiService';
import { TableFilters } from 'components/DataTable/hooks/useTableFilters';

export const THOUSAND_SEPARATOR = '.';

export const capitalize = (word: string) => {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const stringToNumber = (value: string): number | undefined =>
    value === undefined || value === '' ? undefined : Number(value);

export const getAbsoluteBackendPath = (relativePath: string) =>
    BACKEND_API_BASE_URL + (BACKEND_API_BASE_URL?.endsWith('/') ? '' : '/') + relativePath;

export const formatBytes = (bytes: number | undefined, decimals = 2) => {
    if (!bytes) {
        return {
            i: 0,
            value: 0,
        };
    }

    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const value = parseFloat((bytes / Math.pow(1024, i)).toFixed(dm));

    return {
        i,
        value,
    };
};

export const getReadableFileSize = (bytes: number | undefined, separator = ''): string => {
    const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const formatted = formatBytes(bytes);

    return Math.ceil(formatted.value) + separator + sufixes[formatted.i];
};

export const getFileExtension = (mimeType: string | null | undefined): string =>
    mimeType ? mimeType.split('/')[1] : '';

export const parseFiltersFromUrl = ({ key, value }: { key: string; value: string }, initialFilters?: TableFilters) => {
    if (key.startsWith('filter')) {
        const filterKey = key.substring(key.indexOf('[') + 1, key.length - 1);
        if (initialFilters?.[filterKey]) {
            return { ...initialFilters, [filterKey]: [...initialFilters[filterKey], value] };
        }

        return { ...initialFilters, [filterKey]: [value] };
    }
};
