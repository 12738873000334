import { control } from 'forms/ui-schemas/elements/control';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    spacing: 6,
    elements: [
        group([
            group(
                [control('stepKonzeptinformationenLeistungsbeschreibungen')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepKonzeptinformationenLeistungsbeschreibungen'
            ),
            group(
                [control('stepKonzeptinformationenHausordnungen')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepKonzeptinformationenHausordnungen'
            ),
            group(
                [control('stepKonzeptinformationenKonzeptinformationen')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepKonzeptinformationenKonzeptinformationen'
            ),
            group(
                [control('stepKonzeptinformationenFreiheitentziehende')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepKonzeptinformationenFreiheitentziehende'
            ),
            group(
                [control('stepKonzeptinformationenQualitaetBeschwerden')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepKonzeptinformationenQualitaetBeschwerden'
            ),
            group(
                [control('stepKonzeptinformationenGewaltpraeventionen')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepKonzeptinformationenGewaltpraeventionen'
            ),
            group(
                [control('stepBeschaeftigteEinrichtungsleitungDokumente')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepBeschaeftigteEinrichtungsleitungDokumente'
            ),
            group(
                [control('stepBeschaeftigtePflegedienstleitungDokumente')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepBeschaeftigtePflegedienstleitungDokumente'
            ),
            group(
                [control('stepVertraegeDokumente')],
                {},
                undefined,
                undefined,
                undefined,
                '#/properties/stepVertraegeDokumente'
            ),
            group(
                [control('stepUnterlagenDokumente')],
                { noSeparator: true },
                undefined,
                undefined,
                undefined,
                '#/properties/stepUnterlagenDokumente'
            ),
        ]),
        group([
            info({
                message:
                    'Bei Abschluss der Prüfung wird eine Bestätigungs-E-Mail mit folgenden Dateianhängen an den Antragsteller versendet:',
            }),
            control('freigabeDokumente', { parseValueToInt: true, noLabel: true }),
        ]),
    ],
};
