import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import {
    Button,
    ButtonBaseActions,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';

import { STATUS } from 'constants/antragStatus';

export interface FormButtonGroupOption {
    label: string;
    action: Partial<STATUS> | string;
    default?: boolean;
    disabled?: boolean;
}

interface FormButtonGroupProps {
    options: Array<FormButtonGroupOption>;
    handleCallback: (action: string) => void;
    onAction?: (action: string) => void;
}

export const FormButtonGroup = ({ options, handleCallback, onAction }: FormButtonGroupProps) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const actionRef = createRef<ButtonBaseActions>();

    const defaultOption = useMemo(() => {
        const defaultIndex = options.findIndex((option) => option.default);
        if (defaultIndex === -1) return 0;

        return defaultIndex;
    }, [options]);

    const [open, setOpen] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const handleAction = async (action: string) => {
        await handleCallback(action);
        onAction?.(action);
    };

    const handleClick = (action: string) => handleAction(action);

    const handleMenuItemClick = async (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
        action: string
    ) => {
        setSelectedIndex(index);
        setOpen(false);

        await handleAction(action);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        setSelectedIndex(defaultOption);
    }, [defaultOption]);

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="Auswahlmenü um Aktionen auszuführen.">
                <Button
                    aria-label={`Aktion ${options[selectedIndex].label} ausführen.`}
                    onClick={() => handleClick(options[selectedIndex].action)}
                >
                    {options[selectedIndex].label}
                </Button>

                <Button
                    action={actionRef}
                    size="small"
                    disableTouchRipple
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="Bitte wählen Sie eine Aktion aus."
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            disabled={!!option.disabled}
                                            selected={index === selectedIndex || option.default}
                                            onClick={(event) => handleMenuItemClick(event, index, option.action)}
                                            onKeyDown={(event) => {
                                                if (event.key.toLowerCase() === 'escape') {
                                                    setOpen(false);
                                                }
                                            }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
