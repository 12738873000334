import React from 'react';

import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangebotDeletionReason } from 'pages/Leistungsangebote/components/LeistungsangebotDeletionReason';

const Index = ({ pathBuilder }: { pathBuilder: PathBuilderProps }) => {
    return <LeistungsangebotDeletionReason overviewPath={String(pathBuilder.path)} />;
};

export const LabMeldungDeletionReason: React.FC<{ pathBuilder: PathBuilderProps }> = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNGEN_LABS_OHNE_MELDUNG
);
