import React from 'react';

import { MeldungJsonldMeldungRead } from 'api/client';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { MeldungAnsehen } from 'pages/Meldung/Components/MeldungAnsehen';

export type MeldungType = Omit<MeldungJsonldMeldungRead, 'labelReplacements'> & {
    labelReplacements: Record<string, string>;
};

export interface MeldungProps {
    meldung: MeldungType;
}

const Index = () => (
    <MeldungAnsehen title="Meldung" headline="Meldung" pathBuilder={PathBuilder.home.verwaltung.meldung} />
);

export const MeldungDetailPage = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_MELDUNGEN);
