import { RuleEffect } from '@jsonforms/core';

import { AnschriftType } from 'api/types';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { isPostfach } from 'forms/utilities/UiSchemaUtils';

export const uiSchema = customVerticalLayout([
    group([
        control('nrwKey'),
        control('name'),
        control('anschriftTyp'),
        group([
            control('postfachBezeichnung', isPostfach(RuleEffect.SHOW)),
            inlineRowControl([
                control('postfachPlz', isPostfach(RuleEffect.SHOW)),
                control('postfachOrt', isPostfach(RuleEffect.SHOW)),
            ]),
            control('adressDaten', {
                rule: {
                    effect: RuleEffect.SHOW,
                    condition: {
                        scope: '#/properties/anschriftTyp',
                        schema: { const: AnschriftType.HAUSANSCHRIFT },
                    },
                },
            }),
        ]),
        control('ansprechpartner'),
        inlineRowControl([control('telefon'), control('fax')]),
        control('email'),
        control('typ'),
        control('traegerverband'),
    ]),
]);
