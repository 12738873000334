import { v4 as uuidv4 } from 'uuid';

import { Message, MessageOptions } from 'forms/state/useMessages';

const defaultOptions: MessageOptions = {
    closable: true,
};

export const errorMessage = (text: string, options: MessageOptions = defaultOptions): Message => ({
    uuid: uuidv4(),
    type: 'error',
    text,
    ...defaultOptions,
    ...options,
});
export const infoMessage = (text: string, options: MessageOptions = defaultOptions): Message => ({
    uuid: uuidv4(),
    type: 'info',
    text,
    ...defaultOptions,
    ...options,
});
export const successMessage = (text: string, options: MessageOptions = defaultOptions): Message => ({
    uuid: uuidv4(),
    type: 'success',
    text,
    ...defaultOptions,
    ...options,
});
export const warningMessage = (text: string, options: MessageOptions = defaultOptions): Message => ({
    uuid: uuidv4(),
    type: 'warning',
    text,
    ...defaultOptions,
    ...options,
});
