import { useCallback, useEffect, useRef } from 'react';
import { ButtonBaseActions } from '@mui/material';

import { useDisabled } from './useDisabled';

export const useFocus = <T extends HTMLElement | ButtonBaseActions>() => {
    const ref = useRef<T>(null);
    const requestFocus = useRef<boolean>(false);
    const disabled = useDisabled();

    const setFocus = useCallback(() => {
        if (!ref.current) return;

        requestFocus.current = false;

        if ((ref.current as ButtonBaseActions).focusVisible) {
            (ref.current as ButtonBaseActions).focusVisible();
            return;
        }

        determineElementToFocus(ref.current as HTMLElement)?.focus();
    }, []);

    const requestFocusAfterValidate = useCallback(() => {
        setTimeout(() => (requestFocus.current = true), 20);
    }, []);

    useEffect(() => {
        if (!disabled && requestFocus.current) {
            setFocus();
        }
    }, [disabled, setFocus]);

    return { ref, setFocus, requestFocusAfterValidate };
};

const determineElementToFocus = (htmlElement: HTMLElement) => {
    if (htmlElement.tagName.toLowerCase() === 'input' || htmlElement.tagName.toLowerCase() === 'button') {
        return htmlElement;
    }

    const inputs = htmlElement.getElementsByTagName('input');

    if (inputs.length === 1) {
        return inputs[0];
    }

    const checkedInput = Array.from(inputs).find((i) => i.checked);

    if (checkedInput) return checkedInput;
    return htmlElement;
};
