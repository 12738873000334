import { useCallback } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';

import { useLocalStorage } from 'utilities/hooks/useLocalStorage';

import { TableOptions } from './useTableOptions';

export type OnSearchChanged = NonNullable<MUIDataTableOptions['onSearchChange']>;

type UseTableSearchTextResult = {
    searchText: string;
    onSearchChanged: OnSearchChanged;
    updateSearchText: (value: string) => void;
};

export const useTableSearchText = (
    initialOptions: TableOptions,
    persistSearchText: boolean | undefined,
    name?: string
): UseTableSearchTextResult => {
    const [searchText, setSearchText, removeSearchText] = useLocalStorage('datatable_searchText', {
        defaultValue: initialOptions?.searchText ?? '',
        shallow: persistSearchText,
        name,
    });

    const onSearchChanged: OnSearchChanged = useCallback(
        (value) => {
            if (!value?.length) {
                setSearchText('');
                removeSearchText();
                return;
            }

            setSearchText(value ?? '');
        },
        [setSearchText, removeSearchText]
    );

    const updateSearchText = useCallback(
        (value: string) => {
            setSearchText(value);
        },
        [setSearchText]
    );

    return {
        searchText,
        onSearchChanged,
        updateSearchText,
    };
};
