import { PropsWithChildren } from 'react';

import { AuthSessionExpiration } from 'api/auth/AuthSessionExpiration';
import { useSessionHandler } from 'api/auth/useSessionHandler';
import { useSessionRecovery } from 'api/auth/useSessionRecovery';
import { useSessionRefresh } from 'api/auth/useSessionRefresh';
import { useApiInterceptors } from 'api/hooks/useApiInterceptors';
import { useSentry } from 'components/Sentry/useSentry';

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const { useSentrySetUser } = useSentry();

    useSentrySetUser();
    useApiInterceptors();

    const { isRecovering } = useSessionRecovery();

    useSessionHandler(isRecovering);
    useSessionRefresh();

    if (isRecovering) return <></>;

    return (
        <>
            {children}
            <AuthSessionExpiration />
        </>
    );
};
