import { useApiCheckChangeToken } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { ExceptionMessage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen/components/ExceptionMessage';
import { FailureMessage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen/components/FailureMessage';
import { SuccessMessage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen/components/SuccessMessage';
import useQuery from 'utilities/hooks/useQuery';

export const EmailBestaetigenPage = () => {
    const query = useQuery();
    const securityToken = query.get('t'); // token
    const email = window.atob(query.get('d') || ''); // displayed email

    const { isLoading, error } = useApiCheckChangeToken(securityToken || undefined);

    return (
        <ContentContainer title="E-Mail-Adresse bestätigen">
            <AppLoaderContainer isLoading={isLoading}>
                {error ? (
                    error.response?.status === 500 ? (
                        <ExceptionMessage />
                    ) : (
                        <FailureMessage />
                    )
                ) : (
                    <SuccessMessage email={email} />
                )}
            </AppLoaderContainer>
        </ContentContainer>
    );
};
