export const standort_list_table = {
    type: 'SummaryTable',
    columns: 3,
    scope: '#/properties/standorte',
    entries: [
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/stepStandortStandorte',
            label: 'Liste der Standorte',
            showLabel: true,
            entries: [
                {
                    type: 'FieldRow',
                    scope: '#/properties/hauptstandort',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/label',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/gesamtPlatzzahl',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/einzelzimmerPlaetze',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/doppelzimmerPlaetze',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/eingestreuteKurzzeitpflegeplaetze',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/separateKurzzeitpflegeplaetze',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/annerkanntePlaetze',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/betriebsintegriertePlatzzahl',
                },
                {
                    type: 'FieldRow',
                    scope: '#/properties/teilzeitbeschaeftigte',
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizStandort',
        },
    ],
};
