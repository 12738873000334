import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AbwBearbeiten } from 'pages/Verwaltung/ABW/components/AbwBearbeiten';
import { defaultSchemaEdit, defaultUiSchemaEdit } from 'pages/Verwaltung/ABW/formConfig';

const Index = () => {
    return (
        <AbwBearbeiten
            schema={defaultSchemaEdit}
            uiSchema={defaultUiSchemaEdit}
            pathBuilder={PathBuilder.home.aufgaben.abw}
        />
    );
};

export const AbwAufgabenBearbeitenPage = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN_AUFGABEN
);
