import { useCallback, useEffect, useRef, useState } from 'react';
import { Generate } from '@jsonforms/core';
import { isEqual } from 'lodash';

import { useHasRole } from 'api/auth';
import { AntragType } from 'constants/antragTypes';
import { ROLES } from 'constants/roles';
import { useFormState } from 'forms/state/useFormState';
import { Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

interface UseStepUISchema {
    uiSchema?: UiSchemaType;
    isLoadingSchema: boolean;
}

export const useStepUISchema = (
    schema: Schema | undefined,
    antragType: AntragType,
    step = 'basisdaten',
    fileDir?: string
): UseStepUISchema => {
    const [uiSchema, setUiSchema] = useState<UiSchemaType | undefined>();
    const prevSchemaRef = useRef<Schema>();

    const { loadingStart, loadingEnd, isLoading } = useFormState();

    const hasRole = useHasRole();

    const loadUISchema = useCallback(
        (step: string) => {
            const roleDir = hasRole([ROLES.BEHOERDE, ROLES.SUPPORT, ROLES.LV]) ? 'behoerde' : 'anbieter';

            try {
                loadingStart();
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                return require(`../ui-schemas/${antragType.toLowerCase()}/${fileDir || roleDir}/${step}.ts`)?.uiSchema;
            } catch (e) {
                console.warn(e);
            } finally {
                loadingEnd();
            }
            return undefined;
        },
        [antragType, fileDir, hasRole, loadingEnd, loadingStart]
    );

    useEffect(() => {
        if (!schema || typeof schema !== 'object' || !step) {
            setUiSchema(undefined);
            return;
        }
        if (isEqual(schema, prevSchemaRef.current)) {
            return;
        }
        prevSchemaRef.current = schema;
        const loadedUiSchema = loadUISchema(step);
        setUiSchema(
            loadedUiSchema ? prepareUISchema(loadedUiSchema, schema) : (Generate.uiSchema(schema) as UiSchemaType)
        );
    }, [step, schema, loadUISchema]);

    return {
        uiSchema,
        isLoadingSchema: isLoading,
    };
};

export const prepareUISchema = (uiSchema: UiSchemaType, schema: Schema): UiSchemaType => {
    if (uiSchema.elements && Array.isArray(uiSchema.elements)) {
        return {
            collectionScope: '',
            // @ts-ignore
            columns: undefined,
            // @ts-ignore
            entries: undefined,
            // @ts-ignore
            label: '',
            // @ts-ignore
            scopes: [],
            text: '',
            ...uiSchema,
            elements: deleteMissingFields(uiSchema.elements, schema),
        };
    }
    return uiSchema;
};

const deleteMissingFields = (elements: UiSchemaType[], schema: Schema): any[] => {
    const ems = [];

    for (const element of elements) {
        const fieldSchema = getFieldSchemaByScope(element.scope, schema);

        switch (element.type) {
            case 'FormExtendButton':
            case 'ListControlWithModalForm':
                if (fieldSchema) {
                    if (Array.isArray(element.elements)) {
                        ems.push(prepareUISchema(element, fieldSchema));
                    } else {
                        ems.push(element);
                    }
                }
                break;
            default:
                if (fieldSchema) {
                    if (Array.isArray(element.elements)) {
                        ems.push(prepareUISchema(element, schema));
                    } else {
                        ems.push(element);
                    }
                }
        }
    }

    return ems;
};
