import React from 'react';

import { backendApiService } from 'api/ApiService';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { useExportMessage } from 'pages/Auswertung/useExportMessage';

interface ExportButtonProps {
    marginBottom?: number | string;
}

export const RegelpruefungenExportButton = ({ marginBottom }: ExportButtonProps) => {
    const { createSuccessMessage, createErrorMessage } = useExportMessage();
    const { submitStart, submitEnd, isSubmitting } = useFormState();

    const handleFileExport = () => {
        submitStart();

        backendApiService
            .getRegelpruefungExport({})
            .then((res) => {
                createSuccessMessage();
            })
            .catch(() => {
                createErrorMessage();
            })
            .finally(() => submitEnd());
    };

    return (
        <FormLoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleFileExport}
            sx={{
                marginBottom,
            }}
        >
            Export der Regelprüfungen (Excel)
        </FormLoadingButton>
    );
};
