import { LabelsInterface } from './labels';

export enum EMAIL_ANTRAG_ART {
    FEM_AUFFORDERUNG = 'FEM_AUFFORDERUNG',
    PLATZZAHLMELDER_AUFFORDERUNG = 'PLATZZAHLMELDER_AUFFORDERUNG',
    TO_EINRICHTUNG = 'TO_EINRICHTUNG',
    TO_BEHOERDE = 'TO_BEHOERDE',
    MELDUNG_ZURUECKGEGEBEN = 'MELDUNG_ZURUECKGEGEBEN',
    SYSTEM = 'SYSTEM',
    DEFAULT = 'DEFAULT',
    MELDUNG_AUFFORDERUNG = 'MELDUNG_AUFFORDERUNG',
}

export const EMAIL_ANTRAG_ART_LABELS: LabelsInterface = {
    [EMAIL_ANTRAG_ART.TO_EINRICHTUNG]: 'Nachricht an Leistungsangebot',
    [EMAIL_ANTRAG_ART.MELDUNG_ZURUECKGEGEBEN]: 'Meldung zurückgeben',
    [EMAIL_ANTRAG_ART.PLATZZAHLMELDER_AUFFORDERUNG]: 'Platzzahlmelder Aufforderung',
    [EMAIL_ANTRAG_ART.TO_BEHOERDE]: 'Nachricht an Behörde',
    [EMAIL_ANTRAG_ART.FEM_AUFFORDERUNG]: 'Freiheitsentziehende Maßnahmen Aufforderung',
    [EMAIL_ANTRAG_ART.MELDUNG_AUFFORDERUNG]: 'Aufforderung zur Meldung',
    [EMAIL_ANTRAG_ART.SYSTEM]: 'Systemmeldung',
    [EMAIL_ANTRAG_ART.DEFAULT]: 'Systemmeldung',
};
