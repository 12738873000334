import { styled } from '@mui/material';

import { LoaderApp } from 'components/AppLoaderContainer';
import { transientOptions } from 'theme/utils';

export const MenuLoader = ({ withSpacing }: { withSpacing?: boolean }) => {
    return <StyledLoaderApp size={20} $withSpacing={withSpacing !== undefined && withSpacing} />;
};

const StyledLoaderApp = styled(
    LoaderApp,
    transientOptions
)<{ $withSpacing: boolean }>(({ $withSpacing }) => ({
    marginLeft: $withSpacing ? '10px' : 'initial',
    marginRight: $withSpacing ? '5px' : 'initial',
}));
