import React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { NewLineText } from 'elements/NewLineText';
import { UploadedDocument } from 'forms/controls/components/UploadedDocument';
import { DocumentTableType } from 'forms/types/UiSchemaTypes';

import { useDocumentTableData } from './hooks/useDocumentTableData';

export interface ScopedDocumentUploadUiSchema {
    scope: string;
    description?: string;
    uploads: (DocumentUploadUiSchema | ScopedDocumentUploadUiSchema)[];
}

export interface DocumentUploadUiSchema {
    step: string;
    description?: string;
    file: string;
    fileDescription: string;
}

interface TableControlProps extends Omit<LayoutProps, 'uischema'> {
    uischema: DocumentTableType;
}

const StyledTableContainer = styled(TableContainer)(
    ({ theme }) => `
    max-height: 402px;
`
);

export const DocumentTableControlComponent: React.FC<TableControlProps> = ({ data, uischema }) => {
    const tableData = useDocumentTableData(data, uischema);

    return (
        <Grid container style={{ ...uischema.styles }}>
            <Grid item xs={12} md={uischema.gridWidth}>
                <StyledTableContainer>
                    <Table data-cy="Table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {uischema.columns?.map((column, i) => (
                                    <TableCell key={i} style={column.styles}>
                                        {column.header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.length &&
                                tableData.map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            {uischema.columns.map((column) => (
                                                <TableCell key={column.key} style={column?.styles}>
                                                    {column.key === 'fileData' ? (
                                                        row.fileData.map((file, i) => (
                                                            <UploadedDocument
                                                                key={i}
                                                                fileData={file}
                                                                readonly
                                                                style={{
                                                                    ...column?.styles,
                                                                    display: 'block',
                                                                }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <NewLineText text={row[column.key]} />
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Grid>
        </Grid>
    );
};

// @ts-ignore
export const DocumentTableControl = withJsonFormsLayoutProps(DocumentTableControlComponent);
