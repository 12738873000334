import * as React from 'react';
import { useMemo } from 'react';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Alert, AlertColor } from '@mui/material';

import { INFO_MESSAGE_LABELS, InfoMessages } from 'constants/infoMessages';
import { UiSchemaType } from 'forms/types/UiSchemaTypes';
import { theme } from 'theme/theme';

interface InfoMessage {
    withMessageKey?: string;
    message?: string;
}

export type InfoControlMessageProps = InfoMessage;

interface InfoControlProps extends Omit<ControlProps, 'uischema' | 'schema'> {
    schema: Pick<ControlProps, 'schema'> & { custom?: { hinweise?: Array<string> } };
    uischema: UiSchemaType & InfoControlMessageProps;
}

const InfoControlComponent: React.FC<InfoControlProps> = ({ schema, uischema, visible }) => {
    const message = useMemo(() => {
        if (uischema?.withMessageKey) {
            return INFO_MESSAGE_LABELS?.[schema?.custom?.hinweise?.[0] as InfoMessages];
        }

        return uischema?.message;
    }, [schema, uischema]);

    return visible ? (
        <Alert
            severity={uischema.type.toLowerCase() as AlertColor}
            style={{ ...uischema.styles }}
            sx={{ width: '100%', marginBottom: theme.spacing(3) }}
        >
            <span dangerouslySetInnerHTML={{ __html: message || 'Keine Information hinterlegt' }} />
        </Alert>
    ) : (
        <></>
    );
};

// @ts-ignore
export const InfoControl = withJsonFormsLayoutProps(InfoControlComponent);
