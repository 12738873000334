/* tslint:disable */
/* eslint-disable */
/**
 * Pfad.Wtg
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AdressDaten
 */
export interface AdressDaten {
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenBehoerdeReadRolleEinrichtung
 */
export interface AdressDatenBehoerdeReadRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenBehoerdeWriteRolleEinrichtung
 */
export interface AdressDatenBehoerdeWriteRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenBehoerdeWriteRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonld
 */
export interface AdressDatenJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface AdressDatenJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldBehoerdeReadRolleEinrichtung
 */
export interface AdressDatenJsonldBehoerdeReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldBehoerdeReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 * @type AdressDatenJsonldContext
 * @export
 */
export type AdressDatenJsonldContext = AdressDatenJsonldContextOneOf | string;

/**
 *
 * @export
 * @interface AdressDatenJsonldContextOneOf
 */
export interface AdressDatenJsonldContextOneOf {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldContextOneOf
     */
    '@vocab': string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldContextOneOf
     */
    hydra: AdressDatenJsonldContextOneOfHydraEnum;
}

export const AdressDatenJsonldContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#',
} as const;

export type AdressDatenJsonldContextOneOfHydraEnum =
    (typeof AdressDatenJsonldContextOneOfHydraEnum)[keyof typeof AdressDatenJsonldContextOneOfHydraEnum];

/**
 *
 * @export
 * @interface AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
 */
export interface AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLandschaftsverbandReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldLeistungsanbieterList
 */
export interface AdressDatenJsonldLeistungsanbieterList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldLeistungsanbieterList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLeistungsanbieterList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLeistungsanbieterList
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldLeistungsangebotRegistrierenList
 */
export interface AdressDatenJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface AdressDatenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldMeldungRead
 */
export interface AdressDatenJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldMeldungRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldTraegerCreate
 */
export interface AdressDatenJsonldTraegerCreate {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerCreate
     */
    cx?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldTraegerReadRolleEinrichtung
 */
export interface AdressDatenJsonldTraegerReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldTraegerverbandReadRolleEinrichtung
 */
export interface AdressDatenJsonldTraegerverbandReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
 */
export interface AdressDatenJsonldTraegerverbandWriteRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenJsonldTraegerverbandWriteRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenLandschaftsverbandReadRolleEinrichtung
 */
export interface AdressDatenLandschaftsverbandReadRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenLandschaftsverbandWriteRolleEinrichtung
 */
export interface AdressDatenLandschaftsverbandWriteRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenLandschaftsverbandWriteRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenMeldungRead
 */
export interface AdressDatenMeldungRead {
    /**
     *
     * @type {string}
     * @memberof AdressDatenMeldungRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenTraegerCreate
 */
export interface AdressDatenTraegerCreate {
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerCreate
     */
    cx?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenTraegerReadRolleEinrichtung
 */
export interface AdressDatenTraegerReadRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenTraegerWriteRolleEinrichtung
 */
export interface AdressDatenTraegerWriteRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerWriteRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenTraegerverbandReadRolleEinrichtung
 */
export interface AdressDatenTraegerverbandReadRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdressDatenTraegerverbandWriteRolleEinrichtung
 */
export interface AdressDatenTraegerverbandWriteRolleEinrichtung {
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdressDatenTraegerverbandWriteRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AdresseAdresseRead
 */
export interface AdresseAdresseRead {
    /**
     *
     * @type {number}
     * @memberof AdresseAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseAdresseRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseBezirksregierungWrite
 */
export interface AdresseBezirksregierungWrite {
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseBezirksregierungWrite
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface AdresseEinrichtungRead
 */
export interface AdresseEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof AdresseEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseEinrichtungReadAdresseRead
 */
export interface AdresseEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof AdresseEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseEinrichtungReadAdresseReadModifiedBy
 */
export interface AdresseEinrichtungReadAdresseReadModifiedBy {
    /**
     *
     * @type {number}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof AdresseEinrichtungReadAdresseReadModifiedBy
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseEinrichtungReadModifiedBy
 */
export interface AdresseEinrichtungReadModifiedBy {
    /**
     *
     * @type {number}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof AdresseEinrichtungReadModifiedBy
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseEinrichtungWrite
 */
export interface AdresseEinrichtungWrite {
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseEinrichtungWrite
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface AdresseFemViewEinrichtungGetAdresseRead
 */
export interface AdresseFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {number}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseFemViewEinrichtungGetAdresseRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseItemGet
 */
export interface AdresseItemGet {
    /**
     *
     * @type {string}
     * @memberof AdresseItemGet
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseItemGet
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseItemGet
     */
    strasse?: string;
}
/**
 *
 * @export
 * @interface AdresseJsonldAdresseRead
 */
export interface AdresseJsonldAdresseRead {
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldAdresseRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldBezirksregierungWrite
 */
export interface AdresseJsonldBezirksregierungWrite {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldBezirksregierungWrite
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldEinrichtungRead
 */
export interface AdresseJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof AdresseJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldEinrichtungReadAdresseRead
 */
export interface AdresseJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof AdresseJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldEinrichtungReadAdresseReadModifiedBy
 */
export interface AdresseJsonldEinrichtungReadAdresseReadModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof AdresseJsonldEinrichtungReadAdresseReadModifiedBy
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldEinrichtungReadModifiedBy
 */
export interface AdresseJsonldEinrichtungReadModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof AdresseJsonldEinrichtungReadModifiedBy
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldEinrichtungWrite
 */
export interface AdresseJsonldEinrichtungWrite {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldEinrichtungWrite
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldFemViewEinrichtungGetAdresseRead
 */
export interface AdresseJsonldFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldFemViewEinrichtungGetAdresseRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldRegelpruefungList
 */
export interface AdresseJsonldRegelpruefungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldRegelpruefungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldRegelpruefungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldRegelpruefungList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldRegelpruefungList
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldRegelpruefungRead
 */
export interface AdresseJsonldRegelpruefungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldRegelpruefungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldRegelpruefungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldRegelpruefungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldRegelpruefungRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldSimpleformJsonFormEinrichtungRead
 */
export interface AdresseJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
 */
export interface AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldTraegerRead
 */
export interface AdresseJsonldTraegerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldTraegerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AdresseJsonldTraegerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldTraegerRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseJsonldWohngemeinschaftRead
 */
export interface AdresseJsonldWohngemeinschaftRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AdresseJsonldWohngemeinschaftRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldWohngemeinschaftRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldWohngemeinschaftRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldWohngemeinschaftRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldWohngemeinschaftRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseJsonldWohngemeinschaftRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseRegelpruefungList
 */
export interface AdresseRegelpruefungList {
    /**
     *
     * @type {string}
     * @memberof AdresseRegelpruefungList
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseRegelpruefungRead
 */
export interface AdresseRegelpruefungRead {
    /**
     *
     * @type {string}
     * @memberof AdresseRegelpruefungRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseSimpleformJsonFormEinrichtungRead
 */
export interface AdresseSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof AdresseSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseSimpleformJsonFormEinrichtungReadModifiedBy
 */
export interface AdresseSimpleformJsonFormEinrichtungReadModifiedBy {
    /**
     *
     * @type {number}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof AdresseSimpleformJsonFormEinrichtungReadModifiedBy
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface AdresseTraegerRead
 */
export interface AdresseTraegerRead {
    /**
     *
     * @type {number}
     * @memberof AdresseTraegerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseTraegerRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseWohngemeinschaftRead
 */
export interface AdresseWohngemeinschaftRead {
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftRead
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftRead
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface AdresseWohngemeinschaftWriteAdresseWrite
 */
export interface AdresseWohngemeinschaftWriteAdresseWrite {
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AdresseWohngemeinschaftWriteAdresseWrite
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungForm
 */
export interface AngezeigteBetreuungForm {
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungForm
     */
    name?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof AngezeigteBetreuungForm
     */
    adresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungForm
     */
    beginn?: string | null;
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungForm
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungForm
     */
    einrichtung?: string;
    /**
     *
     * @type {RelationClassData}
     * @memberof AngezeigteBetreuungForm
     */
    ambulanterDienst?: RelationClassData;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormAdresse
 */
export interface AngezeigteBetreuungFormAdresse {
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAdresse
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    name?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonFormAdresse}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    adresse?: AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    beginn?: string | null;
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    einrichtung?: string;
    /**
     *
     * @type {RelationClassDataAngezeigteBetreuungFormGetSimpleformJsonForm}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    ambulanterDienst?: RelationClassDataAngezeigteBetreuungFormGetSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonFormAdresse
 */
export interface AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonFormAdresse {}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormAngezeigteBetreuungJsonFormAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface AngezeigteBetreuungFormAngezeigteBetreuungJsonFormAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    data?: AngezeigteBetreuungFormAngezeigteBetreuungFormGetSimpleformJsonForm;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    schema?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    data?: AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm}
     * @memberof AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    schema?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormJsonld
 */
export interface AngezeigteBetreuungFormJsonld {
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    name?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    adresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    beginn?: string | null;
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    einrichtung?: string;
    /**
     *
     * @type {RelationClassDataJsonld}
     * @memberof AngezeigteBetreuungFormJsonld
     */
    ambulanterDienst?: RelationClassDataJsonld;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormJsonldAdresse
 */
export interface AngezeigteBetreuungFormJsonldAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAdresse
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    name?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    adresse?: AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    beginn?: string | null;
    /**
     *
     * @type {number}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    einrichtung?: string;
    /**
     *
     * @type {RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    ambulanterDienst?: RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse
 */
export interface AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AngezeigteBetreuungFormJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdresse
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface AntragInterface
 */
export interface AntragInterface {
    /**
     *
     * @type {number}
     * @memberof AntragInterface
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    status?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterface
     */
    dataRaw?: Array<string> | null;
    /**
     *
     * @type {DataInterface}
     * @memberof AntragInterface
     */
    data?: DataInterface;
    /**
     *
     * @type {AntragInterfaceEmails}
     * @memberof AntragInterface
     */
    emails?: AntragInterfaceEmails | null;
    /**
     *
     * @type {Collection}
     * @memberof AntragInterface
     */
    uploads?: Collection;
    /**
     *
     * @type {AntragInterfaceEmails}
     * @memberof AntragInterface
     */
    versionen?: AntragInterfaceEmails | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterface
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterface
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    art?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterface
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterface
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterface
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AntragInterfaceEmails
 */
export interface AntragInterfaceEmails {
    /**
     * Checks whether the collection is empty (contains no elements).
     * @type {boolean}
     * @memberof AntragInterfaceEmails
     */
    empty?: boolean;
    /**
     * Gets all keys/indices of the collection.
     * @type {Array<number>}
     * @memberof AntragInterfaceEmails
     */
    keys?: Array<number>;
    /**
     * Gets all values of the collection.
     * @type {Array<string>}
     * @memberof AntragInterfaceEmails
     */
    values?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof AntragInterfaceEmails
     */
    iterator?: any;
}
/**
 *
 * @export
 * @interface AntragInterfaceJsonld
 */
export interface AntragInterfaceJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AntragInterfaceJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AntragInterfaceJsonld
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    status?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterfaceJsonld
     */
    dataRaw?: Array<string> | null;
    /**
     *
     * @type {DataInterfaceJsonld}
     * @memberof AntragInterfaceJsonld
     */
    data?: DataInterfaceJsonld;
    /**
     *
     * @type {AntragInterfaceJsonldEmails}
     * @memberof AntragInterfaceJsonld
     */
    emails?: AntragInterfaceJsonldEmails | null;
    /**
     *
     * @type {CollectionJsonld}
     * @memberof AntragInterfaceJsonld
     */
    uploads?: CollectionJsonld;
    /**
     *
     * @type {AntragInterfaceJsonldEmails}
     * @memberof AntragInterfaceJsonld
     */
    versionen?: AntragInterfaceJsonldEmails | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterfaceJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterfaceJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    art?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterfaceJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragInterfaceJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonld
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface AntragInterfaceJsonldEmails
 */
export interface AntragInterfaceJsonldEmails {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AntragInterfaceJsonldEmails
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonldEmails
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AntragInterfaceJsonldEmails
     */
    '@type'?: string;
    /**
     * Checks whether the collection is empty (contains no elements).
     * @type {boolean}
     * @memberof AntragInterfaceJsonldEmails
     */
    empty?: boolean;
    /**
     * Gets all keys/indices of the collection.
     * @type {Array<number>}
     * @memberof AntragInterfaceJsonldEmails
     */
    keys?: Array<number>;
    /**
     * Gets all values of the collection.
     * @type {Array<string>}
     * @memberof AntragInterfaceJsonldEmails
     */
    values?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof AntragInterfaceJsonldEmails
     */
    iterator?: any;
}
/**
 *
 * @export
 * @interface AntragVersionInterface
 */
export interface AntragVersionInterface {
    /**
     *
     * @type {number}
     * @memberof AntragVersionInterface
     */
    id?: number | null;
    /**
     *
     * @type {AntragInterface}
     * @memberof AntragVersionInterface
     */
    antrag?: AntragInterface;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    antragModifiedBy?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterface
     */
    dataRaw?: Array<string> | null;
    /**
     *
     * @type {DataInterface}
     * @memberof AntragVersionInterface
     */
    data?: DataInterface;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    antragModifiedAt?: string;
    /**
     *
     * @type {Collection}
     * @memberof AntragVersionInterface
     */
    uploads?: Collection;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterface
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    modifiedAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterface
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterface
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterface
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface AntragVersionInterfaceJsonld
 */
export interface AntragVersionInterfaceJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof AntragVersionInterfaceJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof AntragVersionInterfaceJsonld
     */
    id?: number | null;
    /**
     *
     * @type {AntragInterfaceJsonld}
     * @memberof AntragVersionInterfaceJsonld
     */
    antrag?: AntragInterfaceJsonld;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    antragModifiedBy?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterfaceJsonld
     */
    dataRaw?: Array<string> | null;
    /**
     *
     * @type {DataInterfaceJsonld}
     * @memberof AntragVersionInterfaceJsonld
     */
    data?: DataInterfaceJsonld;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    antragModifiedAt?: string;
    /**
     *
     * @type {CollectionJsonld}
     * @memberof AntragVersionInterfaceJsonld
     */
    uploads?: CollectionJsonld;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterfaceJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    modifiedAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterfaceJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof AntragVersionInterfaceJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof AntragVersionInterfaceJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface ApiAdresselookupGetCollection200Response
 */
export interface ApiAdresselookupGetCollection200Response {
    /**
     *
     * @type {Array<AdresseJsonldAdresseRead>}
     * @memberof ApiAdresselookupGetCollection200Response
     */
    'hydra:member': Array<AdresseJsonldAdresseRead>;
    /**
     *
     * @type {number}
     * @memberof ApiAdresselookupGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiAdresselookupGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiAdresselookupGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiAdresselookupGetCollection200ResponseHydraSearch
 */
export interface ApiAdresselookupGetCollection200ResponseHydraSearch {
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     *
     * @type {Array<ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 *
 * @export
 * @interface ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner
     */
    variable?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner
     */
    property?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraSearchHydraMappingInner
     */
    required?: boolean;
}
/**
 *
 * @export
 * @interface ApiAdresselookupGetCollection200ResponseHydraView
 */
export interface ApiAdresselookupGetCollection200ResponseHydraView {
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     *
     * @type {string}
     * @memberof ApiAdresselookupGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 *
 * @export
 * @interface ApiAuswertung44VWtgGetCollection200Response
 */
export interface ApiAuswertung44VWtgGetCollection200Response {
    /**
     *
     * @type {Array<EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead>}
     * @memberof ApiAuswertung44VWtgGetCollection200Response
     */
    'hydra:member': Array<EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead>;
    /**
     *
     * @type {number}
     * @memberof ApiAuswertung44VWtgGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiAuswertung44VWtgGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiAuswertung44VWtgGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiAuswertungStandardCsvGetCollection200Response
 */
export interface ApiAuswertungStandardCsvGetCollection200Response {
    /**
     *
     * @type {Array<EinrichtungJsonldStandardauswertungRead>}
     * @memberof ApiAuswertungStandardCsvGetCollection200Response
     */
    'hydra:member': Array<EinrichtungJsonldStandardauswertungRead>;
    /**
     *
     * @type {number}
     * @memberof ApiAuswertungStandardCsvGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiAuswertungStandardCsvGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiAuswertungStandardCsvGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBehoerdeGetCollection200Response
 */
export interface ApiBehoerdeGetCollection200Response {
    /**
     *
     * @type {Array<BehoerdeCollectionOutputJsonld>}
     * @memberof ApiBehoerdeGetCollection200Response
     */
    'hydra:member': Array<BehoerdeCollectionOutputJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiBehoerdeGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBehoerdeGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBehoerdeGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response
 */
export interface ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response {
    /**
     *
     * @type {Array<TraegerverbandJsonld>}
     * @memberof ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response
     */
    'hydra:member': Array<TraegerverbandJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBenutzerGetCollection200Response
 */
export interface ApiBenutzerGetCollection200Response {
    /**
     *
     * @type {Array<BenutzerJsonldBenutzerCollectionGet>}
     * @memberof ApiBenutzerGetCollection200Response
     */
    'hydra:member': Array<BenutzerJsonldBenutzerCollectionGet>;
    /**
     *
     * @type {number}
     * @memberof ApiBenutzerGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBenutzerGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBenutzerGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBenutzerListeGetCollection200Response
 */
export interface ApiBenutzerListeGetCollection200Response {
    /**
     *
     * @type {Array<BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension>}
     * @memberof ApiBenutzerListeGetCollection200Response
     */
    'hydra:member': Array<BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension>;
    /**
     *
     * @type {number}
     * @memberof ApiBenutzerListeGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBenutzerListeGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBenutzerListeGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBenutzerListeZugriffsrechteGetCollection200Response
 */
export interface ApiBenutzerListeZugriffsrechteGetCollection200Response {
    /**
     *
     * @type {Array<BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension>}
     * @memberof ApiBenutzerListeZugriffsrechteGetCollection200Response
     */
    'hydra:member': Array<BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension>;
    /**
     *
     * @type {number}
     * @memberof ApiBenutzerListeZugriffsrechteGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBenutzerListeZugriffsrechteGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBenutzerListeZugriffsrechteGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBenutzerTablePreferenceGetCollection200Response
 */
export interface ApiBenutzerTablePreferenceGetCollection200Response {
    /**
     *
     * @type {Array<BenutzerTablePreferenceJsonld>}
     * @memberof ApiBenutzerTablePreferenceGetCollection200Response
     */
    'hydra:member': Array<BenutzerTablePreferenceJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiBenutzerTablePreferenceGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBenutzerTablePreferenceGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBenutzerTablePreferenceGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiBezirksregierungGetCollection200Response
 */
export interface ApiBezirksregierungGetCollection200Response {
    /**
     *
     * @type {Array<BezirksregierungJsonldBezirksregierungRead>}
     * @memberof ApiBezirksregierungGetCollection200Response
     */
    'hydra:member': Array<BezirksregierungJsonldBezirksregierungRead>;
    /**
     *
     * @type {number}
     * @memberof ApiBezirksregierungGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiBezirksregierungGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiBezirksregierungGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiEinrichtungBenutzerGetCollection200Response
 */
export interface ApiEinrichtungBenutzerGetCollection200Response {
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>}
     * @memberof ApiEinrichtungBenutzerGetCollection200Response
     */
    'hydra:member': Array<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>;
    /**
     *
     * @type {number}
     * @memberof ApiEinrichtungBenutzerGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiEinrichtungBenutzerGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiEinrichtungBenutzerGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiEinrichtungCollectionexportCsvGetCollection200Response
 */
export interface ApiEinrichtungCollectionexportCsvGetCollection200Response {
    /**
     *
     * @type {Array<EinrichtungGeneriertesDokumentJsonld>}
     * @memberof ApiEinrichtungCollectionexportCsvGetCollection200Response
     */
    'hydra:member': Array<EinrichtungGeneriertesDokumentJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiEinrichtungCollectionexportCsvGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiEinrichtungCollectionexportCsvGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiEinrichtungCollectionexportCsvGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiEinrichtungGetCollection200Response
 */
export interface ApiEinrichtungGetCollection200Response {
    /**
     *
     * @type {Array<EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet>}
     * @memberof ApiEinrichtungGetCollection200Response
     */
    'hydra:member': Array<EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet>;
    /**
     *
     * @type {number}
     * @memberof ApiEinrichtungGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiEinrichtungGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiEinrichtungGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiEmailAntragGetCollection200Response
 */
export interface ApiEmailAntragGetCollection200Response {
    /**
     *
     * @type {Array<EmailAntragJsonldEmailAntragList>}
     * @memberof ApiEmailAntragGetCollection200Response
     */
    'hydra:member': Array<EmailAntragJsonldEmailAntragList>;
    /**
     *
     * @type {number}
     * @memberof ApiEmailAntragGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiEmailAntragGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiEmailAntragGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiExportRequestGetCollection200Response
 */
export interface ApiExportRequestGetCollection200Response {
    /**
     *
     * @type {Array<ExportRequestJsonldExportRequest>}
     * @memberof ApiExportRequestGetCollection200Response
     */
    'hydra:member': Array<ExportRequestJsonldExportRequest>;
    /**
     *
     * @type {number}
     * @memberof ApiExportRequestGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiExportRequestGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiExportRequestGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiFemAntragGetCollection200Response
 */
export interface ApiFemAntragGetCollection200Response {
    /**
     *
     * @type {Array<FemAntragFemCollectionOutputJsonldFemantragList>}
     * @memberof ApiFemAntragGetCollection200Response
     */
    'hydra:member': Array<FemAntragFemCollectionOutputJsonldFemantragList>;
    /**
     *
     * @type {number}
     * @memberof ApiFemAntragGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiFemAntragGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiFemAntragGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiFiltersEntityTypeGetCollection200Response
 */
export interface ApiFiltersEntityTypeGetCollection200Response {
    /**
     *
     * @type {Array<TableFilterJsonld>}
     * @memberof ApiFiltersEntityTypeGetCollection200Response
     */
    'hydra:member': Array<TableFilterJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiFiltersEntityTypeGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiFiltersEntityTypeGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiFiltersEntityTypeGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiGeloeschtesObjektGetCollection200Response
 */
export interface ApiGeloeschtesObjektGetCollection200Response {
    /**
     *
     * @type {Array<GeloeschtesObjektJsonld>}
     * @memberof ApiGeloeschtesObjektGetCollection200Response
     */
    'hydra:member': Array<GeloeschtesObjektJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiGeloeschtesObjektGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiGeloeschtesObjektGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiGeloeschtesObjektGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiHeimfinderprofilGetCollection200Response
 */
export interface ApiHeimfinderprofilGetCollection200Response {
    /**
     *
     * @type {Array<HeimfinderprofilCollectionOutputJsonldHeimfinderList>}
     * @memberof ApiHeimfinderprofilGetCollection200Response
     */
    'hydra:member': Array<HeimfinderprofilCollectionOutputJsonldHeimfinderList>;
    /**
     *
     * @type {number}
     * @memberof ApiHeimfinderprofilGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiHeimfinderprofilGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiHeimfinderprofilGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiInvestRegistrationGetCollection200Response
 */
export interface ApiInvestRegistrationGetCollection200Response {
    /**
     *
     * @type {Array<InvestRegistrationJsonld>}
     * @memberof ApiInvestRegistrationGetCollection200Response
     */
    'hydra:member': Array<InvestRegistrationJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiInvestRegistrationGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiInvestRegistrationGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiInvestRegistrationGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiLandschaftsverbandGetCollection200Response
 */
export interface ApiLandschaftsverbandGetCollection200Response {
    /**
     *
     * @type {Array<LandschaftsverbandCollectionOutputJsonld>}
     * @memberof ApiLandschaftsverbandGetCollection200Response
     */
    'hydra:member': Array<LandschaftsverbandCollectionOutputJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiLandschaftsverbandGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiLandschaftsverbandGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiLandschaftsverbandGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiLeistungsanbieterGetCollection200Response
 */
export interface ApiLeistungsanbieterGetCollection200Response {
    /**
     *
     * @type {Array<LeistungsanbieterJsonldLeistungsanbieterList>}
     * @memberof ApiLeistungsanbieterGetCollection200Response
     */
    'hydra:member': Array<LeistungsanbieterJsonldLeistungsanbieterList>;
    /**
     *
     * @type {number}
     * @memberof ApiLeistungsanbieterGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiLeistungsanbieterGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiLeistungsanbieterGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiLeistungsangebotRegistrierenGetCollection200Response
 */
export interface ApiLeistungsangebotRegistrierenGetCollection200Response {
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList>}
     * @memberof ApiLeistungsangebotRegistrierenGetCollection200Response
     */
    'hydra:member': Array<LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList>;
    /**
     *
     * @type {number}
     * @memberof ApiLeistungsangebotRegistrierenGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiLeistungsangebotRegistrierenGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiLeistungsangebotRegistrierenGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiMassenmailGetCollection200Response
 */
export interface ApiMassenmailGetCollection200Response {
    /**
     *
     * @type {Array<MassenmailJsonldMassenmailList>}
     * @memberof ApiMassenmailGetCollection200Response
     */
    'hydra:member': Array<MassenmailJsonldMassenmailList>;
    /**
     *
     * @type {number}
     * @memberof ApiMassenmailGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiMassenmailGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiMassenmailGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiMeldungFreigabeDokumentGetCollection200Response
 */
export interface ApiMeldungFreigabeDokumentGetCollection200Response {
    /**
     *
     * @type {Array<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList>}
     * @memberof ApiMeldungFreigabeDokumentGetCollection200Response
     */
    'hydra:member': Array<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList>;
    /**
     *
     * @type {number}
     * @memberof ApiMeldungFreigabeDokumentGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiMeldungFreigabeDokumentGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiMeldungFreigabeDokumentGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiMeldungHinweisdokumentGetCollection200Response
 */
export interface ApiMeldungHinweisdokumentGetCollection200Response {
    /**
     *
     * @type {Array<MeldungHinweisdokumentJsonldMeldungHinweistextList>}
     * @memberof ApiMeldungHinweisdokumentGetCollection200Response
     */
    'hydra:member': Array<MeldungHinweisdokumentJsonldMeldungHinweistextList>;
    /**
     *
     * @type {number}
     * @memberof ApiMeldungHinweisdokumentGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiMeldungHinweisdokumentGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiMeldungHinweisdokumentGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiMeldungHinweistextGetCollection200Response
 */
export interface ApiMeldungHinweistextGetCollection200Response {
    /**
     *
     * @type {Array<MeldungHinweistextJsonldMeldungHinweistextList>}
     * @memberof ApiMeldungHinweistextGetCollection200Response
     */
    'hydra:member': Array<MeldungHinweistextJsonldMeldungHinweistextList>;
    /**
     *
     * @type {number}
     * @memberof ApiMeldungHinweistextGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiMeldungHinweistextGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiMeldungHinweistextGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiMeldungVersionGetCollection200Response
 */
export interface ApiMeldungVersionGetCollection200Response {
    /**
     *
     * @type {Array<MeldungVersionJsonld>}
     * @memberof ApiMeldungVersionGetCollection200Response
     */
    'hydra:member': Array<MeldungVersionJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiMeldungVersionGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiMeldungVersionGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiMeldungVersionGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiPlatzmelderTokenGetCollection200Response
 */
export interface ApiPlatzmelderTokenGetCollection200Response {
    /**
     *
     * @type {Array<BelegbarePlaetzeJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof ApiPlatzmelderTokenGetCollection200Response
     */
    'hydra:member': Array<BelegbarePlaetzeJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {number}
     * @memberof ApiPlatzmelderTokenGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiPlatzmelderTokenGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiPlatzmelderTokenGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiPruefberichteGetCollection200Response
 */
export interface ApiPruefberichteGetCollection200Response {
    /**
     *
     * @type {Array<RegelpruefungAntragPruefberichtDTOJsonld>}
     * @memberof ApiPruefberichteGetCollection200Response
     */
    'hydra:member': Array<RegelpruefungAntragPruefberichtDTOJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiPruefberichteGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiPruefberichteGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiPruefberichteGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiRegelpruefungAntragGetCollection200Response
 */
export interface ApiRegelpruefungAntragGetCollection200Response {
    /**
     *
     * @type {Array<RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList>}
     * @memberof ApiRegelpruefungAntragGetCollection200Response
     */
    'hydra:member': Array<RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList>;
    /**
     *
     * @type {number}
     * @memberof ApiRegelpruefungAntragGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiRegelpruefungAntragGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiRegelpruefungAntragGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiRegionGetCollection200Response
 */
export interface ApiRegionGetCollection200Response {
    /**
     *
     * @type {Array<RegionJsonld>}
     * @memberof ApiRegionGetCollection200Response
     */
    'hydra:member': Array<RegionJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiRegionGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiRegionGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiRegionGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiTraegerCollectionexportCsvGetCollection200Response
 */
export interface ApiTraegerCollectionexportCsvGetCollection200Response {
    /**
     *
     * @type {Array<TraegerGeneriertesDokumentJsonld>}
     * @memberof ApiTraegerCollectionexportCsvGetCollection200Response
     */
    'hydra:member': Array<TraegerGeneriertesDokumentJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiTraegerCollectionexportCsvGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiTraegerCollectionexportCsvGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiTraegerCollectionexportCsvGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiTraegerGetCollection200Response
 */
export interface ApiTraegerGetCollection200Response {
    /**
     *
     * @type {Array<TraegerCollectionOutputJsonld>}
     * @memberof ApiTraegerGetCollection200Response
     */
    'hydra:member': Array<TraegerCollectionOutputJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiTraegerGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiTraegerGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiTraegerGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiTraegerverbandGetCollection200Response
 */
export interface ApiTraegerverbandGetCollection200Response {
    /**
     *
     * @type {Array<TraegerverbandCollectionOutputJsonld>}
     * @memberof ApiTraegerverbandGetCollection200Response
     */
    'hydra:member': Array<TraegerverbandCollectionOutputJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiTraegerverbandGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiTraegerverbandGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiTraegerverbandGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiUnternehmensgruppeGetCollection200Response
 */
export interface ApiUnternehmensgruppeGetCollection200Response {
    /**
     *
     * @type {Array<UnternehmensgruppeJsonld>}
     * @memberof ApiUnternehmensgruppeGetCollection200Response
     */
    'hydra:member': Array<UnternehmensgruppeJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiUnternehmensgruppeGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiUnternehmensgruppeGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiUnternehmensgruppeGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiWohngemeinschaftCsvGetCollection200Response
 */
export interface ApiWohngemeinschaftCsvGetCollection200Response {
    /**
     *
     * @type {Array<WohngemeinschaftGeneriertesDokumentJsonld>}
     * @memberof ApiWohngemeinschaftCsvGetCollection200Response
     */
    'hydra:member': Array<WohngemeinschaftGeneriertesDokumentJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiWohngemeinschaftCsvGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiWohngemeinschaftCsvGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiWohngemeinschaftCsvGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiWohngemeinschaftGetCollection200Response
 */
export interface ApiWohngemeinschaftGetCollection200Response {
    /**
     *
     * @type {Array<WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead>}
     * @memberof ApiWohngemeinschaftGetCollection200Response
     */
    'hydra:member': Array<WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead>;
    /**
     *
     * @type {number}
     * @memberof ApiWohngemeinschaftGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiWohngemeinschaftGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiWohngemeinschaftGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface ApiZugehoerigeEinrichtungenDTOGetCollection200Response
 */
export interface ApiZugehoerigeEinrichtungenDTOGetCollection200Response {
    /**
     *
     * @type {Array<ZugehoerigeEinrichtungenDTOJsonld>}
     * @memberof ApiZugehoerigeEinrichtungenDTOGetCollection200Response
     */
    'hydra:member': Array<ZugehoerigeEinrichtungenDTOJsonld>;
    /**
     *
     * @type {number}
     * @memberof ApiZugehoerigeEinrichtungenDTOGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof ApiZugehoerigeEinrichtungenDTOGetCollection200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof ApiZugehoerigeEinrichtungenDTOGetCollection200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface Behoerde
 */
export interface Behoerde {
    /**
     *
     * @type {number}
     * @memberof Behoerde
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    traegerVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    einrichtungVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    meldungHinweisdokumente?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    meldungHinweistexte?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    emailtexts?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Behoerde
     */
    meldungFreigabeDokumente?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Behoerde
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface BehoerdeBehoerdeReadRolleEinrichtung
 */
export interface BehoerdeBehoerdeReadRolleEinrichtung {
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeBehoerdeReadRolleEinrichtung
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeReadRolleEinrichtung
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeReadRolleEinrichtung
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeReadRolleEinrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeReadRolleEinrichtung
     */
    verantwortlicheBehoerde?: string;
}
/**
 *
 * @export
 * @interface BehoerdeBehoerdeWrite
 */
export interface BehoerdeBehoerdeWrite {
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeWrite
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeWrite
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeWrite
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeWrite
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBehoerdeWrite
     */
    verantwortlicheBehoerde?: string;
}
/**
 *
 * @export
 * @interface BehoerdeBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BehoerdeBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {number}
     * @memberof BehoerdeBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BehoerdeCollectionOutput
 */
export interface BehoerdeCollectionOutput {
    /**
     *
     * @type {number}
     * @memberof BehoerdeCollectionOutput
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutput
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeCollectionOutput
     */
    benutzer?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutput
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutput
     */
    email?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeCollectionOutput
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeCollectionOutput
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BehoerdeCollectionOutputJsonld
 */
export interface BehoerdeCollectionOutputJsonld {
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    benutzer?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    email?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeCollectionOutputJsonld
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BehoerdeDto
 */
export interface BehoerdeDto {
    /**
     *
     * @type {number}
     * @memberof BehoerdeDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDto
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDto
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDto
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDto
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDto
     */
    fax?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof BehoerdeDto
     */
    adressDaten?: AngezeigteBetreuungFormAdresse | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoBehoerdeReadRolleEinrichtung
 */
export interface BehoerdeDtoBehoerdeReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    fax?: string | null;
    /**
     *
     * @type {BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtung
     */
    adressDaten?: BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
 */
export interface BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoBehoerdeWriteRolleEinrichtung
 */
export interface BehoerdeDtoBehoerdeWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    fax?: string | null;
    /**
     *
     * @type {BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtung
     */
    adressDaten?: BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
 */
export interface BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoBehoerdeWriteRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoJsonld
 */
export interface BehoerdeDtoJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeDtoJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeDtoJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonld
     */
    fax?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof BehoerdeDtoJsonld
     */
    adressDaten?: AngezeigteBetreuungFormJsonldAdresse | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
 */
export interface BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    fax?: string | null;
    /**
     *
     * @type {BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung
     */
    adressDaten?: BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
 */
export interface BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeDtoJsonldBehoerdeReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface BehoerdeEinrichtungRead
 */
export interface BehoerdeEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof BehoerdeEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof BehoerdeEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeEinrichtungReadAdresseRead
 */
export interface BehoerdeEinrichtungReadAdresseRead {
    /**
     *
     * @type {string}
     * @memberof BehoerdeEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof BehoerdeEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeJsonld
 */
export interface BehoerdeJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    verantwortlicheBehoerde?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    traegerVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    einrichtungVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    meldungHinweisdokumente?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    meldungHinweistexte?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    emailtexts?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonld
     */
    meldungFreigabeDokumente?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldBehoerdeReadRolleEinrichtung
 */
export interface BehoerdeJsonldBehoerdeReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeReadRolleEinrichtung
     */
    verantwortlicheBehoerde?: string;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldBehoerdeWrite
 */
export interface BehoerdeJsonldBehoerdeWrite {
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeWrite
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeWrite
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeWrite
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeWrite
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBehoerdeWrite
     */
    verantwortlicheBehoerde?: string;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldEinrichtungRead
 */
export interface BehoerdeJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof BehoerdeJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldEinrichtungReadAdresseRead
 */
export interface BehoerdeJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof BehoerdeJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
 */
export interface BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldMassenmailList
 */
export interface BehoerdeJsonldMassenmailList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldMassenmailList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldMassenmailList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldMassenmailList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldMassenmailRead
 */
export interface BehoerdeJsonldMassenmailRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldMassenmailRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BehoerdeJsonldSimpleformJsonFormEinrichtungRead
 */
export interface BehoerdeJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BehoerdeJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BehoerdeJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
 */
export interface BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy {
    /**
     *
     * @type {string}
     * @memberof BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BehoerdeMassenmailList
 */
export interface BehoerdeMassenmailList {
    /**
     *
     * @type {string}
     * @memberof BehoerdeMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BehoerdeMassenmailRead
 */
export interface BehoerdeMassenmailRead {
    /**
     *
     * @type {string}
     * @memberof BehoerdeMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BehoerdeSimpleformJsonFormEinrichtungRead
 */
export interface BehoerdeSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof BehoerdeSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BehoerdeSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BehoerdeSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetze
 */
export interface BelegbarePlaetze {
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetze
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetze
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
 */
export interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutput {
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    id?: number | null;
    /**
     *
     * @type {BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    einrichtung?: BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    dauerpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    kurzpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutput
     */
    createdAt?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
 */
export interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung {
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    verantwortlicheBehoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerName?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    traegerVerbandName?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    deletedNotice?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    needsUpdate?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    letzteFreigegebeneMeldung?: string | null;
    /**
     *
     * @type {EinrichtungDetailsOutputMantelbogen}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    mantelbogen?: EinrichtungDetailsOutputMantelbogen | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputEinrichtung
     */
    versorgungsvertraege?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
 */
export interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    id?: number | null;
    /**
     *
     * @type {BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    einrichtung?: BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    dauerpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    kurzpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld
     */
    createdAt?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
 */
export interface BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    verantwortlicheBehoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerName?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    traegerVerbandName?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    deletedNotice?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    needsUpdate?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    letzteFreigegebeneMeldung?: string | null;
    /**
     *
     * @type {EinrichtungDetailsOutputJsonldMantelbogen}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    mantelbogen?: EinrichtungDetailsOutputJsonldMantelbogen | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonldEinrichtung
     */
    versorgungsvertraege?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeEinrichtungRead
 */
export interface BelegbarePlaetzeEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof BelegbarePlaetzeEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeEinrichtungReadAdresseRead
 */
export interface BelegbarePlaetzeEinrichtungReadAdresseRead {
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof BelegbarePlaetzeEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeGeneriertesDokument
 */
export interface BelegbarePlaetzeGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequest>}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    exportRequests?: Array<ExportRequest>;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokument
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeGeneriertesDokumentJsonld
 */
export interface BelegbarePlaetzeGeneriertesDokumentJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequestJsonld>}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    exportRequests?: Array<ExportRequestJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeGeneriertesDokumentJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeHistorie
 */
export interface BelegbarePlaetzeHistorie {
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorie
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorie
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorie
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorie
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorie
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorie
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {any}
     * @memberof BelegbarePlaetzeHistorie
     */
    aufgefordert?: any;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeHistorie200Response
 */
export interface BelegbarePlaetzeHistorie200Response {
    /**
     *
     * @type {Array<BelegbarePlaetzeHistorieJsonld>}
     * @memberof BelegbarePlaetzeHistorie200Response
     */
    'hydra:member': Array<BelegbarePlaetzeHistorieJsonld>;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorie200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof BelegbarePlaetzeHistorie200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof BelegbarePlaetzeHistorie200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeHistorieJsonld
 */
export interface BelegbarePlaetzeHistorieJsonld {
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {any}
     * @memberof BelegbarePlaetzeHistorieJsonld
     */
    aufgefordert?: any;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeJsonld
 */
export interface BelegbarePlaetzeJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeJsonld
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface BelegbarePlaetzeJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeJsonldEinrichtungRead
 */
export interface BelegbarePlaetzeJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof BelegbarePlaetzeJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
 */
export interface BelegbarePlaetzeJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof BelegbarePlaetzeJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
 */
export interface BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BelegbarePlaetzeJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dauerpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    kurzpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    modifiedAt?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dauerpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    kurzpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    modifiedAt?: string | null;
}
/**
 *
 * @export
 * @interface BelegbarePlaetzeSimpleformJsonFormEinrichtungRead
 */
export interface BelegbarePlaetzeSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BelegbarePlaetzeSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BelegbarePlaetzeSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerAntragWorkflowActionAntragWorkflow
 */
export interface BenutzerAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof BenutzerAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface BenutzerAufgabenOutputBenutzerAufgaben
 */
export interface BenutzerAufgabenOutputBenutzerAufgaben {
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    leistungsangebotFreigeben?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    leistungsanbieterFreigeben?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    wohngemeinschaftZuordnen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    meldungOhneZustaendigkeit?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    meldungFreigeben?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    zurMeldungAufgefordert?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    leistungsangebotOhneMeldung?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    geloeschteObjekte?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputBenutzerAufgaben
     */
    ungeleseneNachrichten?: number | null;
}
/**
 *
 * @export
 * @interface BenutzerAufgabenOutputJsonldBenutzerAufgaben
 */
export interface BenutzerAufgabenOutputJsonldBenutzerAufgaben {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    leistungsangebotFreigeben?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    leistungsanbieterFreigeben?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    wohngemeinschaftZuordnen?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    meldungOhneZustaendigkeit?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    meldungFreigeben?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    zurMeldungAufgefordert?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    leistungsangebotOhneMeldung?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    geloeschteObjekte?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerAufgabenOutputJsonldBenutzerAufgaben
     */
    ungeleseneNachrichten?: number | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerCollectionGet
 */
export interface BenutzerBenutzerCollectionGet {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCollectionGet
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerCollectionGet
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
 */
export interface BenutzerBenutzerCollectionGetBenutzerCollectionGetWithoutExtension {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerCreate
 */
export interface BenutzerBenutzerCreate {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerCreate
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerCreate
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerCreate
     */
    einrichtungBenutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerCreate
     */
    empfangeneEmails?: Array<string>;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
 */
export interface BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    password?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    plainPassword?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    fax: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    isVerified?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    einrichtungBenutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    empfangeneEmails?: Array<string>;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
 */
export interface BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    rolle?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputBenutzerGetByEmail
     */
    einrichtungIds?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
 */
export interface BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    rolle?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail
     */
    einrichtungIds?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    roles?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    fax?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    isVerified?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    isAdmin?: boolean;
    /**
     *
     * @type {BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    bezirksregierung?: BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung | null;
    /**
     *
     * @type {BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    landschaftsverband?: BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband | null;
    /**
     *
     * @type {BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traegerverband?: BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband | null;
    /**
     *
     * @type {BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traeger?: BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger | null;
    /**
     *
     * @type {BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    behoerde?: BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles>}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles>;
    /**
     *
     * @type {Array<EmailBenutzerReadBenutzerReadEmailBenutzerReadRoles>}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    empfangeneEmails?: Array<EmailBenutzerReadBenutzerReadEmailBenutzerReadRoles>;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    status?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
 */
export interface BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
 */
export interface BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension {
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    anrede?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    vorname?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    nachname?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    rolle?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    mandantenName?: string;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    isAdmin?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deletedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deletedBy?: BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
 */
export interface BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
 */
export interface BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension {
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    anrede?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    vorname?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    nachname?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    rolle?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    mandantenName?: string;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    isAdmin?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deletedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deletedBy?: BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
 */
export interface BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerBenutzerZugriffsrechteCollectionOutputJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtensionDeletedBy
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerCredentialChangeConfirmInput
 */
export interface BenutzerCredentialChangeConfirmInput {
    /**
     *
     * @type {string}
     * @memberof BenutzerCredentialChangeConfirmInput
     */
    token?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerCredentialChangeRequestInput
 */
export interface BenutzerCredentialChangeRequestInput {
    /**
     *
     * @type {string}
     * @memberof BenutzerCredentialChangeRequestInput
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerCredentialChangeRequestInput
     */
    password?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerDto
 */
export interface BenutzerDto {
    /**
     *
     * @type {number}
     * @memberof BenutzerDto
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    mandant?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    mandantName?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    anrede?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    vorname?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    email?: string;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerDto
     */
    isAdmin?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerDto
     */
    isVerified?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    isDeleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    deletedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerDto
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    rolle?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDto
     */
    bezirksregierung?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerDtoJsonld
 */
export interface BenutzerDtoJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerDtoJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerDtoJsonld
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    mandant?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    mandantName?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    anrede?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    vorname?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    email?: string;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerDtoJsonld
     */
    isAdmin?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerDtoJsonld
     */
    isVerified?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    isDeleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    deletedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerDtoJsonld
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    rolle?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerDtoJsonld
     */
    bezirksregierung?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerEinrichtungBenutzerRead
 */
export interface BenutzerEinrichtungBenutzerRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungBenutzerRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungBenutzerRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerEinrichtungRead
 */
export interface BenutzerEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof BenutzerEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerEinrichtungReadAdresseRead
 */
export interface BenutzerEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof BenutzerEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerEmailAntragList
 */
export interface BenutzerEmailAntragList {
    /**
     *
     * @type {number}
     * @memberof BenutzerEmailAntragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragList
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragList
     */
    status?: string;
}
/**
 *
 * @export
 * @interface BenutzerEmailAntragRead
 */
export interface BenutzerEmailAntragRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerEmailAntragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragRead
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailAntragRead
     */
    status?: string;
}
/**
 *
 * @export
 * @interface BenutzerEmailListEmailReadEmail
 */
export interface BenutzerEmailListEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerEmailReadEmailReadEmail
 */
export interface BenutzerEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerFemViewEinrichtungGetAdresseRead
 */
export interface BenutzerFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerFemViewEinrichtungGetAdresseRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFemViewEinrichtungGetAdresseRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFemViewEinrichtungGetAdresseRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BenutzerForm
 */
export interface BenutzerForm {
    /**
     *
     * @type {number}
     * @memberof BenutzerForm
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    titel?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    email?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerForm
     */
    rechte?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerForm
     */
    aktivierung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerForm
     */
    mandantenname?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof BenutzerForm
     */
    einrichtung?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof BenutzerForm
     */
    traeger?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof BenutzerForm
     */
    bezirksregierung?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof BenutzerForm
     */
    landschaftsverband?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerForm
     */
    behoerde?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerForm
     */
    traegerverband?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerForm
     */
    zugehoerigeEinrichtungen?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerFormBenutzerFormGetSimpleformJsonForm
 */
export interface BenutzerFormBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {number}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    titel?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    email?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    rechte?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    aktivierung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    mandantenname?: string | null;
    /**
     *
     * @type {BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    einrichtung?: BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    traeger?: BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    bezirksregierung?: BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    landschaftsverband?: BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    behoerde?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    traegerverband?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonForm
     */
    zugehoerigeEinrichtungen?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung
 */
export interface BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung {
    /**
     *
     * @type {number}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm
 */
export interface BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {BenutzerFormBenutzerFormGetSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm
     */
    data?: BenutzerFormBenutzerFormGetSimpleformJsonForm;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormBenutzerFormGetSimpleformJsonForm
     */
    schema?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm
 */
export interface BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    data?: BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    schema?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm
 */
export interface BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerFormJsonldSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm
     */
    data?: BenutzerFormJsonldSimpleformJsonForm;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm
     */
    schema?: SimpleFormJsonSchemaJsonldSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface BenutzerFormBenutzerJsonFormSimpleformJsonForm
 */
export interface BenutzerFormBenutzerJsonFormSimpleformJsonForm {
    /**
     *
     * @type {BenutzerFormSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormSimpleformJsonForm
     */
    data?: BenutzerFormSimpleformJsonForm;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonForm}
     * @memberof BenutzerFormBenutzerJsonFormSimpleformJsonForm
     */
    schema?: SimpleFormJsonSchemaSimpleformJsonForm;
}
/**
 *
 * @export
 * @interface BenutzerFormEinrichtung
 */
export interface BenutzerFormEinrichtung {
    /**
     *
     * @type {number}
     * @memberof BenutzerFormEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerFormJsonld
 */
export interface BenutzerFormJsonld {
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonld
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    titel?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    email?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormJsonld
     */
    rechte?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormJsonld
     */
    aktivierung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonld
     */
    mandantenname?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof BenutzerFormJsonld
     */
    einrichtung?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof BenutzerFormJsonld
     */
    traeger?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof BenutzerFormJsonld
     */
    bezirksregierung?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof BenutzerFormJsonld
     */
    landschaftsverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonld
     */
    behoerde?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonld
     */
    traegerverband?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerFormJsonld
     */
    zugehoerigeEinrichtungen?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
 */
export interface BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    titel?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    email?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    rechte?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    aktivierung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    mandantenname?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    einrichtung?: BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    traeger?: BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    bezirksregierung?: BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    landschaftsverband?: BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    behoerde?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    traegerverband?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonForm
     */
    zugehoerigeEinrichtungen?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung
 */
export interface BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldBenutzerFormGetSimpleformJsonFormEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerFormJsonldEinrichtung
 */
export interface BenutzerFormJsonldEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerFormJsonldSimpleformJsonForm
 */
export interface BenutzerFormJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    titel?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    email?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    rechte?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    aktivierung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    mandantenname?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    einrichtung?: BenutzerFormJsonldSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    traeger?: BenutzerFormJsonldSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    bezirksregierung?: BenutzerFormJsonldSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    landschaftsverband?: BenutzerFormJsonldSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    behoerde?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    traegerverband?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerFormJsonldSimpleformJsonForm
     */
    zugehoerigeEinrichtungen?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerFormJsonldSimpleformJsonFormEinrichtung
 */
export interface BenutzerFormJsonldSimpleformJsonFormEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerFormJsonldSimpleformJsonFormEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonFormEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonFormEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormJsonldSimpleformJsonFormEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormJsonldSimpleformJsonFormEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerFormSimpleformJsonForm
 */
export interface BenutzerFormSimpleformJsonForm {
    /**
     *
     * @type {number}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    titel?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    vorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    nachname?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    email?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    rechte?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    aktivierung?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    mandantenname?: string | null;
    /**
     *
     * @type {BenutzerFormSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    einrichtung?: BenutzerFormSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    traeger?: BenutzerFormSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    bezirksregierung?: BenutzerFormSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormSimpleformJsonFormEinrichtung}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    landschaftsverband?: BenutzerFormSimpleformJsonFormEinrichtung | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    behoerde?: number | null;
    /**
     *
     * @type {number}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    traegerverband?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerFormSimpleformJsonForm
     */
    zugehoerigeEinrichtungen?: Array<string> | null;
}
/**
 *
 * @export
 * @interface BenutzerFormSimpleformJsonFormEinrichtung
 */
export interface BenutzerFormSimpleformJsonFormEinrichtung {
    /**
     *
     * @type {number}
     * @memberof BenutzerFormSimpleformJsonFormEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerFormSimpleformJsonFormEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerCollectionGet
 */
export interface BenutzerJsonldBenutzerCollectionGet {
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCollectionGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCollectionGet
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCollectionGet
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerJsonldBenutzerCollectionGet
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
 */
export interface BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerJsonldBenutzerCollectionGetBenutzerCollectionGetWithoutExtension
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerCreate
 */
export interface BenutzerJsonldBenutzerCreate {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    einrichtungBenutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerJsonldBenutzerCreate
     */
    empfangeneEmails?: Array<string>;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
 */
export interface BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    password?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    plainPassword?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    telefon: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    fax: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    isVerified?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    einrichtungBenutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
     */
    empfangeneEmails?: Array<string>;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    roles?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    fax?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    isVerified?: boolean;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    isAdmin?: boolean;
    /**
     *
     * @type {BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    bezirksregierung?: BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung | null;
    /**
     *
     * @type {BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    landschaftsverband?: BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband | null;
    /**
     *
     * @type {BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traegerverband?: BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband | null;
    /**
     *
     * @type {BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    traeger?: BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger | null;
    /**
     *
     * @type {BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    behoerde?: BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>;
    /**
     *
     * @type {Array<EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    empfangeneEmails?: Array<EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    status?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBehoerde
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesBezirksregierung
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesLandschaftsverband
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraeger
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
 */
export interface BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRolesTraegerverband
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEinrichtungBenutzerRead
 */
export interface BenutzerJsonldEinrichtungBenutzerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungBenutzerRead
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEinrichtungRead
 */
export interface BenutzerJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof BenutzerJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEinrichtungReadAdresseRead
 */
export interface BenutzerJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof BenutzerJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEmailAntragList
 */
export interface BenutzerJsonldEmailAntragList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEmailAntragList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEmailAntragList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragList
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragList
     */
    status?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEmailAntragRead
 */
export interface BenutzerJsonldEmailAntragRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailAntragRead
     */
    status?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEmailListEmailReadEmail
 */
export interface BenutzerJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldEmailReadEmailReadEmail
 */
export interface BenutzerJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldFemViewEinrichtungGetAdresseRead
 */
export interface BenutzerJsonldFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldFemViewEinrichtungGetAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFemViewEinrichtungGetAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFemViewEinrichtungGetAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFemViewEinrichtungGetAdresseRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFemViewEinrichtungGetAdresseRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldFemViewEinrichtungGetAdresseRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldLeistungsangebotRegistrierenList
 */
export interface BenutzerJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy
 */
export interface BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof BenutzerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMassenmailList
 */
export interface BenutzerJsonldMassenmailList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldMassenmailList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMassenmailRead
 */
export interface BenutzerJsonldMassenmailRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldMassenmailRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMeldungList
 */
export interface BenutzerJsonldMeldungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldMeldungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof BenutzerJsonldMeldungList
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMeldungListModifiedBy
 */
export interface BenutzerJsonldMeldungListModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof BenutzerJsonldMeldungListModifiedBy
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMeldungRead
 */
export interface BenutzerJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof BenutzerJsonldMeldungRead
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldMeldungReadModifiedBy
 */
export interface BenutzerJsonldMeldungReadModifiedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof BenutzerJsonldMeldungReadModifiedBy
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldProfileEdit
 */
export interface BenutzerJsonldProfileEdit {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldProfileEdit
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    plainPassword?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileEdit
     */
    fax?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerJsonldProfileEdit
     */
    isVerified?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerJsonldProfileRead
 */
export interface BenutzerJsonldProfileRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldProfileRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldProfileRead
     */
    fax?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldSimpleformJsonFormEinrichtungRead
 */
export interface BenutzerJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerJsonldTraegerRead
 */
export interface BenutzerJsonldTraegerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldTraegerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldTraegerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldTraegerRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldTraegerRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldTraegerRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldTraegerRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BenutzerJsonldWohngemeinschaftRead
 */
export interface BenutzerJsonldWohngemeinschaftRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerJsonldWohngemeinschaftRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldWohngemeinschaftRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldWohngemeinschaftRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldWohngemeinschaftRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldWohngemeinschaftRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerJsonldWohngemeinschaftRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BenutzerLeistungsangebotRegistrierenList
 */
export interface BenutzerLeistungsangebotRegistrierenList {
    /**
     *
     * @type {string}
     * @memberof BenutzerLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof BenutzerLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerLeistungsangebotRegistrierenListModifiedBy
 */
export interface BenutzerLeistungsangebotRegistrierenListModifiedBy {
    /**
     *
     * @type {string}
     * @memberof BenutzerLeistungsangebotRegistrierenListModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof BenutzerLeistungsangebotRegistrierenListModifiedBy
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface BenutzerLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof BenutzerLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerMassenmailList
 */
export interface BenutzerMassenmailList {
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailList
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerMassenmailRead
 */
export interface BenutzerMassenmailRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BenutzerMeldungList
 */
export interface BenutzerMeldungList {
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungList
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungList
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof BenutzerMeldungList
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerMeldungListModifiedBy
 */
export interface BenutzerMeldungListModifiedBy {
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungListModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungListModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungListModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungListModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof BenutzerMeldungListModifiedBy
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerMeldungRead
 */
export interface BenutzerMeldungRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof BenutzerMeldungRead
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerMeldungReadModifiedBy
 */
export interface BenutzerMeldungReadModifiedBy {
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungReadModifiedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungReadModifiedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungReadModifiedBy
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerMeldungReadModifiedBy
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof BenutzerMeldungReadModifiedBy
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerProfileEdit
 */
export interface BenutzerProfileEdit {
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    plainPassword?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileEdit
     */
    fax?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof BenutzerProfileEdit
     */
    isVerified?: boolean;
}
/**
 *
 * @export
 * @interface BenutzerProfileRead
 */
export interface BenutzerProfileRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    anrede?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerProfileRead
     */
    fax?: string | null;
}
/**
 *
 * @export
 * @interface BenutzerSimpleformJsonFormEinrichtungRead
 */
export interface BenutzerSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BenutzerSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BenutzerTablePreference
 */
export interface BenutzerTablePreference {
    /**
     *
     * @type {number}
     * @memberof BenutzerTablePreference
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreference
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreference
     */
    benutzer?: string | null;
    /**
     *
     * @type {object}
     * @memberof BenutzerTablePreference
     */
    value?: object;
}
/**
 *
 * @export
 * @interface BenutzerTablePreferenceJsonld
 */
export interface BenutzerTablePreferenceJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BenutzerTablePreferenceJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BenutzerTablePreferenceJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTablePreferenceJsonld
     */
    benutzer?: string | null;
    /**
     *
     * @type {object}
     * @memberof BenutzerTablePreferenceJsonld
     */
    value?: object;
}
/**
 *
 * @export
 * @interface BenutzerTraegerRead
 */
export interface BenutzerTraegerRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerTraegerRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTraegerRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerTraegerRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BenutzerWohngemeinschaftRead
 */
export interface BenutzerWohngemeinschaftRead {
    /**
     *
     * @type {string}
     * @memberof BenutzerWohngemeinschaftRead
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerWohngemeinschaftRead
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof BenutzerWohngemeinschaftRead
     */
    email: string;
}
/**
 *
 * @export
 * @interface BezirksregierungBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BezirksregierungBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BezirksregierungBezirksregierungRead
 */
export interface BezirksregierungBezirksregierungRead {
    /**
     *
     * @type {number}
     * @memberof BezirksregierungBezirksregierungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungRead
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungRead
     */
    adresse?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BezirksregierungBezirksregierungRead
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface BezirksregierungBezirksregierungWrite
 */
export interface BezirksregierungBezirksregierungWrite {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWrite
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWrite
     */
    kuerzel: string;
    /**
     *
     * @type {BezirksregierungBezirksregierungWriteAdresse}
     * @memberof BezirksregierungBezirksregierungWrite
     */
    adresse?: BezirksregierungBezirksregierungWriteAdresse | null;
}
/**
 *
 * @export
 * @interface BezirksregierungBezirksregierungWriteAdresse
 */
export interface BezirksregierungBezirksregierungWriteAdresse {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungBezirksregierungWriteAdresse
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface BezirksregierungEinrichtungRead
 */
export interface BezirksregierungEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof BezirksregierungEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BezirksregierungEinrichtungReadAdresseRead
 */
export interface BezirksregierungEinrichtungReadAdresseRead {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungEinrichtungReadAdresseRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof BezirksregierungEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldBezirksregierungRead
 */
export interface BezirksregierungJsonldBezirksregierungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    adresse?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof BezirksregierungJsonldBezirksregierungRead
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldBezirksregierungWrite
 */
export interface BezirksregierungJsonldBezirksregierungWrite {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWrite
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWrite
     */
    kuerzel: string;
    /**
     *
     * @type {BezirksregierungJsonldBezirksregierungWriteAdresse}
     * @memberof BezirksregierungJsonldBezirksregierungWrite
     */
    adresse?: BezirksregierungJsonldBezirksregierungWriteAdresse | null;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldBezirksregierungWriteAdresse
 */
export interface BezirksregierungJsonldBezirksregierungWriteAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldBezirksregierungWriteAdresse
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldEinrichtungRead
 */
export interface BezirksregierungJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof BezirksregierungJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldEinrichtungReadAdresseRead
 */
export interface BezirksregierungJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof BezirksregierungJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
 */
export interface BezirksregierungJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BezirksregierungJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface BezirksregierungSimpleformJsonFormEinrichtungRead
 */
export interface BezirksregierungSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof BezirksregierungSimpleformJsonFormEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof BezirksregierungSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof BezirksregierungSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * Checks whether the collection is empty (contains no elements).
     * @type {boolean}
     * @memberof Collection
     */
    empty?: boolean;
    /**
     * Gets all keys/indices of the collection.
     * @type {Array<number>}
     * @memberof Collection
     */
    keys?: Array<number>;
    /**
     * Gets all values of the collection.
     * @type {Array<string>}
     * @memberof Collection
     */
    values?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof Collection
     */
    iterator?: any;
}
/**
 *
 * @export
 * @interface CollectionJsonld
 */
export interface CollectionJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof CollectionJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof CollectionJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof CollectionJsonld
     */
    '@type'?: string;
    /**
     * Checks whether the collection is empty (contains no elements).
     * @type {boolean}
     * @memberof CollectionJsonld
     */
    empty?: boolean;
    /**
     * Gets all keys/indices of the collection.
     * @type {Array<number>}
     * @memberof CollectionJsonld
     */
    keys?: Array<number>;
    /**
     * Gets all values of the collection.
     * @type {Array<string>}
     * @memberof CollectionJsonld
     */
    values?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof CollectionJsonld
     */
    iterator?: any;
}
/**
 *
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     *
     * @type {string}
     * @memberof Credentials
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof Credentials
     */
    password?: string;
}
/**
 *
 * @export
 * @interface CustomMassnahme
 */
export interface CustomMassnahme {
    /**
     *
     * @type {string}
     * @memberof CustomMassnahme
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahme
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahme
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahme
     */
    anzahlPersonen?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahme
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface CustomMassnahmeFemViewEinrichtungGetAdresseRead
 */
export interface CustomMassnahmeFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeFemViewEinrichtungGetAdresseRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahmeFemViewEinrichtungGetAdresseRead
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahmeFemViewEinrichtungGetAdresseRead
     */
    anzahlPersonen?: number | null;
}
/**
 *
 * @export
 * @interface CustomMassnahmeJsonld
 */
export interface CustomMassnahmeJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof CustomMassnahmeJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonld
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahmeJsonld
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahmeJsonld
     */
    anzahlPersonen?: number | null;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
 */
export interface CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    anzahlPersonen?: number | null;
}
/**
 *
 * @export
 * @interface DataInterface
 */
export interface DataInterface {
    /**
     *
     * @type {Array<string>}
     * @memberof DataInterface
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof DataInterface
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DataInterface
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof DataInterface
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof DataInterface
     */
    objectVars?: Array<string>;
}
/**
 *
 * @export
 * @interface DataInterfaceJsonld
 */
export interface DataInterfaceJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof DataInterfaceJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof DataInterfaceJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DataInterfaceJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof DataInterfaceJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof DataInterfaceJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DataInterfaceJsonld
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof DataInterfaceJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof DataInterfaceJsonld
     */
    objectVars?: Array<string>;
}
/**
 *
 * @export
 * @interface Dokument
 */
export interface Dokument {
    /**
     *
     * @type {number}
     * @memberof Dokument
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof Dokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof Dokument
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof Dokument
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof Dokument
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Dokument
     */
    accessRights?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof Dokument
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Dokument
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface DokumentEmailAntragRead
 */
export interface DokumentEmailAntragRead {
    /**
     *
     * @type {number}
     * @memberof DokumentEmailAntragRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentEmailAntragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentEmailAntragRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentEmailAntragRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentEmailAntragRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentEmailAntragRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentJsonld
 */
export interface DokumentJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof DokumentJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonld
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonld
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentJsonld
     */
    accessRights?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface DokumentJsonldEmailAntragRead
 */
export interface DokumentJsonldEmailAntragRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof DokumentJsonldEmailAntragRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldEmailAntragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldEmailAntragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldEmailAntragRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldEmailAntragRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldEmailAntragRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldEmailAntragRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldEmailAntragRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentJsonldEmailAntragRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentJsonldMeldungRead
 */
export interface DokumentJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof DokumentJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldMeldungRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldMeldungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldMeldungRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldMeldungRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldMeldungRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentJsonldMeldungRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentJsonldRegelpruefungRead
 */
export interface DokumentJsonldRegelpruefungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentJsonldRegelpruefungRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {number}
     * @memberof DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentMeldungRead
 */
export interface DokumentMeldungRead {
    /**
     *
     * @type {number}
     * @memberof DokumentMeldungRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentMeldungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentMeldungRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentMeldungRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentMeldungRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentMeldungRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface DokumentRegelpruefungRead
 */
export interface DokumentRegelpruefungRead {
    /**
     *
     * @type {number}
     * @memberof DokumentRegelpruefungRead
     */
    uploadId?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentRegelpruefungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof DokumentRegelpruefungRead
     */
    originalName?: string | null;
    /**
     *
     * @type {number}
     * @memberof DokumentRegelpruefungRead
     */
    fileSize?: number | null;
    /**
     *
     * @type {string}
     * @memberof DokumentRegelpruefungRead
     */
    mimeType?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DokumentRegelpruefungRead
     */
    accessRights?: boolean | null;
}
/**
 *
 * @export
 * @interface Einrichtung
 */
export interface Einrichtung {
    /**
     *
     * @type {number}
     * @memberof Einrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof Einrichtung
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof Einrichtung
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Einrichtung
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Einrichtung
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    traeger?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    uploads?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    einrichtungBenutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    entwuerfe?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    emailAntraege?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    meldungen?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    belegbarePlaetze?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    regelpruefungen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    femAntraege?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    einrichtungVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    standorte?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    wohngemeinschaften?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    ambulanteDienste?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    offeneMeldungen?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    latestApprovedVersion?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    investRegistrations?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Einrichtung
     */
    zustaendigkeiten?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    heimfinder?: string | null;
    /**
     *
     * @type {Array<Mantelbogen>}
     * @memberof Einrichtung
     */
    mantelbogen?: Array<Mantelbogen>;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Einrichtung
     */
    needsUpdate?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    deletedNotice?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtung
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Einrichtung
     */
    shortCareHospital?: boolean;
    /**
     *
     * @type {any}
     * @memberof Einrichtung
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungAuswertung44VWtgAuswertung44VWtgRead
 */
export interface EinrichtungAuswertung44VWtgAuswertung44VWtgRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgAuswertung44VWtgRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgAuswertung44VWtgRead
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgAuswertung44VWtgRead
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgAuswertung44VWtgRead
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgAuswertung44VWtgRead
     */
    ort?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungAuswertung44VWtgAuswertung44VWtgRead
     */
    platzzahl?: number;
}
/**
 *
 * @export
 * @interface EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
 */
export interface EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    ort?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungAuswertung44VWtgJsonldAuswertung44VWtgRead
     */
    platzzahl?: number;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzer
 */
export interface EinrichtungBenutzer {
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzer
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzer
     */
    einrichtung: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzer
     */
    benutzer: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzer
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzer
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzer
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzer
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface EinrichtungBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungBenutzerRead
 */
export interface EinrichtungBenutzerEinrichtungBenutzerRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    einrichtung: EinrichtungEinrichtungBenutzerRead;
    /**
     *
     * @type {BenutzerEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    benutzer: BenutzerEinrichtungBenutzerRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerEinrichtungBenutzerRead
     */
    isAntragsteller: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungRead
 */
export interface EinrichtungBenutzerEinrichtungRead {
    /**
     *
     * @type {BenutzerEinrichtungRead}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    benutzer: BenutzerEinrichtungRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungBenutzerEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerEinrichtungReadAdresseRead
 */
export interface EinrichtungBenutzerEinrichtungReadAdresseRead {
    /**
     *
     * @type {BenutzerEinrichtungReadAdresseRead}
     * @memberof EinrichtungBenutzerEinrichtungReadAdresseRead
     */
    benutzer: BenutzerEinrichtungReadAdresseRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerEinrichtungReadAdresseRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungBenutzerEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonld
 */
export interface EinrichtungBenutzerJsonld {
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonld
     */
    einrichtung: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonld
     */
    benutzer: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonld
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungBenutzerRead
 */
export interface EinrichtungBenutzerJsonldEinrichtungBenutzerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    einrichtung: EinrichtungJsonldEinrichtungBenutzerRead;
    /**
     *
     * @type {BenutzerJsonldEinrichtungBenutzerRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    benutzer: BenutzerJsonldEinrichtungBenutzerRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldEinrichtungBenutzerRead
     */
    isAntragsteller: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungRead
 */
export interface EinrichtungBenutzerJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerJsonldEinrichtungRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    benutzer: BenutzerJsonldEinrichtungRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungBenutzerJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
 */
export interface EinrichtungBenutzerJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerJsonldEinrichtungReadAdresseRead}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    benutzer: BenutzerJsonldEinrichtungReadAdresseRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungBenutzerJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
 */
export interface EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {BenutzerJsonldSimpleformJsonFormEinrichtungRead}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    benutzer: BenutzerJsonldSimpleformJsonFormEinrichtungRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungBenutzerSimpleformJsonFormEinrichtungRead
 */
export interface EinrichtungBenutzerSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {BenutzerSimpleformJsonFormEinrichtungRead}
     * @memberof EinrichtungBenutzerSimpleformJsonFormEinrichtungRead
     */
    benutzer: BenutzerSimpleformJsonFormEinrichtungRead;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungBenutzerSimpleformJsonFormEinrichtungRead
     */
    isAntragsteller: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungBenutzerSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungBenutzerSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
 */
export interface EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    traeger?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    adresse?: EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    deletedBy?: EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    modifiedBy?: EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseRead
     */
    needsUpdate?: boolean | null;
}
/**
 *
 * @export
 * @interface EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
 */
export interface EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse {
    /**
     *
     * @type {number}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy
 */
export interface EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy {
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy
     */
    email: string;
}
/**
 *
 * @export
 * @interface EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
 */
export interface EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    traeger?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    adresse?: EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    deletedBy?: EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    modifiedBy?: EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    needsUpdate?: boolean | null;
}
/**
 *
 * @export
 * @interface EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
 */
export interface EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
 */
export interface EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy
     */
    email: string;
}
/**
 *
 * @export
 * @interface EinrichtungDetailsOutput
 */
export interface EinrichtungDetailsOutput {
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutput
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    verantwortlicheBehoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    traegerVerbandName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutput
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutput
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutput
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    deletedNotice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutput
     */
    needsUpdate?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutput
     */
    letzteFreigegebeneMeldung?: string | null;
    /**
     *
     * @type {EinrichtungDetailsOutputMantelbogen}
     * @memberof EinrichtungDetailsOutput
     */
    mantelbogen?: EinrichtungDetailsOutputMantelbogen | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungDetailsOutput
     */
    versorgungsvertraege?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungDetailsOutputJsonld
 */
export interface EinrichtungDetailsOutputJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    verantwortlicheBehoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    traegerVerbandName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungStrasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungHausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    deletedNotice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    needsUpdate?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    letzteFreigegebeneMeldung?: string | null;
    /**
     *
     * @type {EinrichtungDetailsOutputJsonldMantelbogen}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    mantelbogen?: EinrichtungDetailsOutputJsonldMantelbogen | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungDetailsOutputJsonld
     */
    versorgungsvertraege?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungDetailsOutputJsonldMantelbogen
 */
export interface EinrichtungDetailsOutputJsonldMantelbogen {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequestJsonld>}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    exportRequests?: Array<ExportRequestJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputJsonldMantelbogen
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungDetailsOutputMantelbogen
 */
export interface EinrichtungDetailsOutputMantelbogen {
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequest>}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    exportRequests?: Array<ExportRequest>;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungDetailsOutputMantelbogen
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungBenutzerRead
 */
export interface EinrichtungEinrichtungBenutzerRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungEinrichtungBenutzerReadDeletedByBenutzer}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    deletedByBenutzer?: EinrichtungEinrichtungBenutzerReadDeletedByBenutzer | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerRead
     */
    delete_confirmed?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungBenutzerReadDeletedByBenutzer
 */
export interface EinrichtungEinrichtungBenutzerReadDeletedByBenutzer {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungBenutzerReadDeletedByBenutzer
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerReadDeletedByBenutzer
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungBenutzerReadDeletedByBenutzer
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
 */
export interface EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    einrichtungsArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    letzteVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    letzterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    meldefrist?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    platzzahlmeldung?: EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    letzteRegelpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    letzterPruefbericht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    zustaendig?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGet
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
 */
export interface EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    letzteAntragId?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    letzterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    belegbarePlaetzeStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    dauerpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    kurzzeitpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    freitag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    samstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    sonntag?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputEinrichtungCollectionGetPlatzzahlmeldung
     */
    eingereichtAt?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
 */
export interface EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    einrichtungsArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    meldefrist?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    platzzahlmeldung?: EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteRegelpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzterPruefbericht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    zustaendig?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGet
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
 */
export interface EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    letzteAntragId?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    letzterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    belegbarePlaetzeStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    dauerpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    kurzzeitpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    freitag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    samstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    sonntag?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungCollectionOutputJsonldEinrichtungCollectionGetPlatzzahlmeldung
     */
    eingereichtAt?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungRead
 */
export interface EinrichtungEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungRead
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresse}
     * @memberof EinrichtungEinrichtungRead
     */
    adresse?: EinrichtungEinrichtungReadAdresse | null;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungRead
     */
    deletedByBenutzer?: AdresseEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadLandschaftsverband}
     * @memberof EinrichtungEinrichtungRead
     */
    landschaftsverband?: EinrichtungEinrichtungReadLandschaftsverband | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadBehoerde}
     * @memberof EinrichtungEinrichtungRead
     */
    behoerde?: EinrichtungEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadTraegerverband}
     * @memberof EinrichtungEinrichtungRead
     */
    traegerverband?: EinrichtungEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadBezirksregierung}
     * @memberof EinrichtungEinrichtungRead
     */
    bezirksregierung?: EinrichtungEinrichtungReadBezirksregierung | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadTraeger}
     * @memberof EinrichtungEinrichtungRead
     */
    traeger?: EinrichtungEinrichtungReadTraeger | null;
    /**
     *
     * @type {Array<UploadEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    uploads?: Array<UploadEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerEinrichtungRead>;
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    entwuerfe?: Array<LeistungsangebotRegistrierenEinrichtungRead>;
    /**
     *
     * @type {Array<EmailAntragEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    emailAntraege?: Array<EmailAntragEinrichtungRead>;
    /**
     *
     * @type {Array<MeldungEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    meldungen?: Array<MeldungEinrichtungRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadBelegbarePlaetze}
     * @memberof EinrichtungEinrichtungRead
     */
    belegbarePlaetze?: EinrichtungEinrichtungReadBelegbarePlaetze | null;
    /**
     *
     * @type {Array<RegelpruefungAntragEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    regelpruefungen?: Array<RegelpruefungAntragEinrichtungRead>;
    /**
     *
     * @type {Array<FemAntragEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    femAntraege?: Array<FemAntragEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    ambulanteDienste?: Array<WohngemeinschaftEinrichtungRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadOffeneMeldungen}
     * @memberof EinrichtungEinrichtungRead
     */
    offeneMeldungen?: EinrichtungEinrichtungReadOffeneMeldungen | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadLatestApprovedVersion}
     * @memberof EinrichtungEinrichtungRead
     */
    latestApprovedVersion?: EinrichtungEinrichtungReadLatestApprovedVersion | null;
    /**
     *
     * @type {Array<MantelbogenEinrichtungRead>}
     * @memberof EinrichtungEinrichtungRead
     */
    mantelbogen?: Array<MantelbogenEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungEinrichtungRead
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresse
 */
export interface EinrichtungEinrichtungReadAdresse {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresse
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresse
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresse
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseRead
 */
export interface EinrichtungEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    adresse?: EinrichtungEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    deletedByBenutzer?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadLandschaftsverband}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    landschaftsverband?: EinrichtungEinrichtungReadAdresseReadLandschaftsverband | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadBehoerde}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    behoerde?: EinrichtungEinrichtungReadAdresseReadBehoerde | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadTraegerverband}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    traegerverband?: EinrichtungEinrichtungReadAdresseReadTraegerverband | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadBezirksregierung}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    bezirksregierung?: EinrichtungEinrichtungReadAdresseReadBezirksregierung | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadTraeger}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    traeger?: EinrichtungEinrichtungReadAdresseReadTraeger | null;
    /**
     *
     * @type {Array<UploadEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    uploads?: Array<UploadEinrichtungReadAdresseRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    entwuerfe?: Array<LeistungsangebotRegistrierenEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<EmailAntragEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    emailAntraege?: Array<EmailAntragEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<MeldungEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    meldungen?: Array<MeldungEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    belegbarePlaetze?: EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze | null;
    /**
     *
     * @type {Array<RegelpruefungAntragEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    regelpruefungen?: Array<RegelpruefungAntragEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<FemAntragEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    femAntraege?: Array<FemAntragEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<WohngemeinschaftEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<WohngemeinschaftEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    ambulanteDienste?: Array<WohngemeinschaftEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadOffeneMeldungen}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    offeneMeldungen?: EinrichtungEinrichtungReadAdresseReadOffeneMeldungen | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    latestApprovedVersion?: EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion | null;
    /**
     *
     * @type {Array<MantelbogenEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    mantelbogen?: Array<MantelbogenEinrichtungReadAdresseRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungEinrichtungReadAdresseRead
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadAdresse
 */
export interface EinrichtungEinrichtungReadAdresseReadAdresse {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadAdresse
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadBehoerde
 */
export interface EinrichtungEinrichtungReadAdresseReadBehoerde {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBehoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadBehoerde
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze
 */
export interface EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadBelegbarePlaetze
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadBezirksregierung
 */
export interface EinrichtungEinrichtungReadAdresseReadBezirksregierung {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBezirksregierung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBezirksregierung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadBezirksregierung
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadBezirksregierung
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadLandschaftsverband
 */
export interface EinrichtungEinrichtungReadAdresseReadLandschaftsverband {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLandschaftsverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLandschaftsverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLandschaftsverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadLandschaftsverband
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
 */
export interface EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion {
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadBehoerde}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    behoerde?: EinrichtungEinrichtungReadAdresseReadBehoerde | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadTraegerverband}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    traegerverband?: EinrichtungEinrichtungReadAdresseReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungEinrichtungReadAdresseRead>}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    meldungen?: Array<MeldungEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadTraeger}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    traeger?: EinrichtungEinrichtungReadAdresseReadTraeger | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    adresse?: EinrichtungEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadLatestApprovedVersion
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
 */
export interface EinrichtungEinrichtungReadAdresseReadOffeneMeldungen {
    /**
     *
     * @type {MeldungEinrichtungReadAdresseReadData}
     * @memberof EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
     */
    data?: MeldungEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadOffeneMeldungen
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadTraeger
 */
export interface EinrichtungEinrichtungReadAdresseReadTraeger {
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraeger
     */
    adresse?: EinrichtungEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraeger
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraeger
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadAdresseReadTraegerverband
 */
export interface EinrichtungEinrichtungReadAdresseReadTraegerverband {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadAdresseReadTraegerverband
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadBehoerde
 */
export interface EinrichtungEinrichtungReadBehoerde {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBehoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadBehoerde
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadBelegbarePlaetze
 */
export interface EinrichtungEinrichtungReadBelegbarePlaetze {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadBelegbarePlaetze
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadBezirksregierung
 */
export interface EinrichtungEinrichtungReadBezirksregierung {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBezirksregierung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBezirksregierung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadBezirksregierung
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadBezirksregierung
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadLandschaftsverband
 */
export interface EinrichtungEinrichtungReadLandschaftsverband {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLandschaftsverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLandschaftsverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLandschaftsverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadLandschaftsverband
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadLatestApprovedVersion
 */
export interface EinrichtungEinrichtungReadLatestApprovedVersion {
    /**
     *
     * @type {EinrichtungEinrichtungReadBehoerde}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    behoerde?: EinrichtungEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadTraegerverband}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    traegerverband?: EinrichtungEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungEinrichtungRead>}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    meldungen?: Array<MeldungEinrichtungRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadTraeger}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    traeger?: EinrichtungEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresse}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    adresse?: EinrichtungEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadLatestApprovedVersion
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadOffeneMeldungen
 */
export interface EinrichtungEinrichtungReadOffeneMeldungen {
    /**
     *
     * @type {MeldungEinrichtungReadData}
     * @memberof EinrichtungEinrichtungReadOffeneMeldungen
     */
    data?: MeldungEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadOffeneMeldungen
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadOffeneMeldungen
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadOffeneMeldungen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadOffeneMeldungen
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadOffeneMeldungen
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadTraeger
 */
export interface EinrichtungEinrichtungReadTraeger {
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresse}
     * @memberof EinrichtungEinrichtungReadTraeger
     */
    adresse?: EinrichtungEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadTraeger
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadTraeger
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungReadTraegerverband
 */
export interface EinrichtungEinrichtungReadTraegerverband {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadTraegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungReadTraegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungEinrichtungReadTraegerverband
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungWrite
 */
export interface EinrichtungEinrichtungWrite {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungWrite
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungWrite
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungWrite
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungWriteAdresse}
     * @memberof EinrichtungEinrichtungWrite
     */
    adresse?: EinrichtungEinrichtungWriteAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungWrite
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWrite
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungWriteAdresse
 */
export interface EinrichtungEinrichtungWriteAdresse {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungWriteAdresse
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
 */
export interface EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    nrwSchluessel?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    einrichtungsart?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    ort?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    gerichtlichGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    einwilligungGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    ueberfaelligeMeldungen?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    letzteErinnerung?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputEinrichtungCollectionGet
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
 */
export interface EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    nrwSchluessel?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    einrichtungsart?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    ort?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    gerichtlichGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    einwilligungGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    ueberfaelligeMeldungen?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteErinnerung?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet
 */
export interface EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet
     */
    letzteVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputEinrichtungCollectionGet
     */
    letzterStatus?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
 */
export interface EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzterStatus?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    ort?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    belegbarePlaetze?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    belegbarePlaetzeStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    zustaendigeBehoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    behoerdeName?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    schema?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
 */
export interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    dauerpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    kurzpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    modifiedAt?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
 */
export interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    pattern?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    additionalItems?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    items?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    required?: Array<string>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    additionalProperties?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    definitions?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    properties?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    patternProperties?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    dependencies?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    allOf?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    anyOf?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    oneOf?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    not?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    contains?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    propertyNames?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    if?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    then?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    else?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    custom?: JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    ort?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    belegbarePlaetze?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    belegbarePlaetzeStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    zustaendigeBehoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    behoerdeName?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    schema?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
 */
export interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    beschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    dauerpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    kurzpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    freitag?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    aufgefordert?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetBelegbarePlaetze
     */
    modifiedAt?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
 */
export interface EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    pattern?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    additionalItems?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    items?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    required?: Array<string>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    additionalProperties?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    definitions?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    properties?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    patternProperties?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    dependencies?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    allOf?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    anyOf?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    oneOf?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    not?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    contains?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    propertyNames?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    if?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    then?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    else?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom}
     * @memberof EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema
     */
    custom?: JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom | null;
}
/**
 *
 * @export
 * @interface EinrichtungEmailAntragList
 */
export interface EinrichtungEmailAntragList {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailAntragList
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailAntragList
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEmailAntragRead
 */
export interface EinrichtungEmailAntragRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailAntragRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailAntragRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungEmailListEmailReadEmail
 */
export interface EinrichtungEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungEmailReadEmailReadEmail
 */
export interface EinrichtungEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungExportRequestExportRequest
 */
export interface EinrichtungExportRequestExportRequest {
    /**
     *
     * @type {number}
     * @memberof EinrichtungExportRequestExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestExportRequestGeneriertesDokument}
     * @memberof EinrichtungExportRequestExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    status?: EinrichtungExportRequestExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequest
     */
    resource?: string;
}

export const EinrichtungExportRequestExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type EinrichtungExportRequestExportRequestStatusEnum =
    (typeof EinrichtungExportRequestExportRequestStatusEnum)[keyof typeof EinrichtungExportRequestExportRequestStatusEnum];

/**
 *
 * @export
 * @interface EinrichtungExportRequestExportRequestGeneriertesDokument
 */
export interface EinrichtungExportRequestExportRequestGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungExportRequestExportRequestGeneriertesDokument
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungExportRequestJsonldExportRequest
 */
export interface EinrichtungExportRequestJsonldExportRequest {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestJsonldExportRequestGeneriertesDokument}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestJsonldExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    status?: EinrichtungExportRequestJsonldExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequest
     */
    resource?: string;
}

export const EinrichtungExportRequestJsonldExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type EinrichtungExportRequestJsonldExportRequestStatusEnum =
    (typeof EinrichtungExportRequestJsonldExportRequestStatusEnum)[keyof typeof EinrichtungExportRequestJsonldExportRequestStatusEnum];

/**
 *
 * @export
 * @interface EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
 */
export interface EinrichtungExportRequestJsonldExportRequestGeneriertesDokument {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungExportRequestJsonldExportRequestGeneriertesDokument
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungGeneriertesDokument
 */
export interface EinrichtungGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof EinrichtungGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungGeneriertesDokument
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequest>}
     * @memberof EinrichtungGeneriertesDokument
     */
    exportRequests?: Array<ExportRequest>;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokument
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokument
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungGeneriertesDokumentJsonld
 */
export interface EinrichtungGeneriertesDokumentJsonld {
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequestJsonld>}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    exportRequests?: Array<ExportRequestJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungGeneriertesDokumentJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungItemGet
 */
export interface EinrichtungItemGet {
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    nrw_key?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    web?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungItemGet
     */
    bild?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonld
 */
export interface EinrichtungJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonld
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonld
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonld
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonld
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    traegerverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    traeger?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    einrichtungBenutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    entwuerfe?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    emailAntraege?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    meldungen?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    belegbarePlaetze?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    regelpruefungen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    femAntraege?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    einrichtungVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    standorte?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    wohngemeinschaften?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    ambulanteDienste?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    offeneMeldungen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    latestApprovedVersion?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    investRegistrations?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonld
     */
    zustaendigkeiten?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    heimfinder?: string | null;
    /**
     *
     * @type {Array<MantelbogenJsonld>}
     * @memberof EinrichtungJsonld
     */
    mantelbogen?: Array<MantelbogenJsonld>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonld
     */
    needsUpdate?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    deletedNotice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonld
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonld
     */
    shortCareHospital?: boolean;
    /**
     *
     * @type {any}
     * @memberof EinrichtungJsonld
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungBenutzerRead
 */
export interface EinrichtungJsonldEinrichtungBenutzerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    deletedByBenutzer?: EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerRead
     */
    delete_confirmed?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
 */
export interface EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungBenutzerReadDeletedByBenutzer
     */
    nachname: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungRead
 */
export interface EinrichtungJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresse}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresse | null;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    deletedByBenutzer?: AdresseJsonldEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadLandschaftsverband}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    landschaftsverband?: EinrichtungJsonldEinrichtungReadLandschaftsverband | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadBehoerde}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    behoerde?: EinrichtungJsonldEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadTraegerverband}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    traegerverband?: EinrichtungJsonldEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadBezirksregierung}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    bezirksregierung?: EinrichtungJsonldEinrichtungReadBezirksregierung | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadTraeger}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    traeger?: EinrichtungJsonldEinrichtungReadTraeger | null;
    /**
     *
     * @type {Array<UploadJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    uploads?: Array<UploadJsonldEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerJsonldEinrichtungRead>;
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    entwuerfe?: Array<LeistungsangebotRegistrierenJsonldEinrichtungRead>;
    /**
     *
     * @type {Array<EmailAntragJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    emailAntraege?: Array<EmailAntragJsonldEinrichtungRead>;
    /**
     *
     * @type {Array<MeldungJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    meldungen?: Array<MeldungJsonldEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadBelegbarePlaetze}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    belegbarePlaetze?: EinrichtungJsonldEinrichtungReadBelegbarePlaetze | null;
    /**
     *
     * @type {Array<RegelpruefungAntragJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    regelpruefungen?: Array<RegelpruefungAntragJsonldEinrichtungRead>;
    /**
     *
     * @type {Array<FemAntragJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    femAntraege?: Array<FemAntragJsonldEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    ambulanteDienste?: Array<WohngemeinschaftJsonldEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadOffeneMeldungen}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    offeneMeldungen?: EinrichtungJsonldEinrichtungReadOffeneMeldungen | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadLatestApprovedVersion}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    latestApprovedVersion?: EinrichtungJsonldEinrichtungReadLatestApprovedVersion | null;
    /**
     *
     * @type {Array<MantelbogenJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    mantelbogen?: Array<MantelbogenJsonldEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungJsonldEinrichtungRead
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresse
 */
export interface EinrichtungJsonldEinrichtungReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresse
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresse
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresse
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseRead
 */
export interface EinrichtungJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    deletedByBenutzer?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    landschaftsverband?: EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadBehoerde}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    behoerde?: EinrichtungJsonldEinrichtungReadAdresseReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    traegerverband?: EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    bezirksregierung?: EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadTraeger}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    traeger?: EinrichtungJsonldEinrichtungReadAdresseReadTraeger | null;
    /**
     *
     * @type {Array<UploadJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    uploads?: Array<UploadJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    entwuerfe?: Array<LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<EmailAntragJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    emailAntraege?: Array<EmailAntragJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<MeldungJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    meldungen?: Array<MeldungJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    belegbarePlaetze?: EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze | null;
    /**
     *
     * @type {Array<RegelpruefungAntragJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    regelpruefungen?: Array<RegelpruefungAntragJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<FemAntragJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    femAntraege?: Array<FemAntragJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    ambulanteDienste?: Array<WohngemeinschaftJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    offeneMeldungen?: EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    latestApprovedVersion?: EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion | null;
    /**
     *
     * @type {Array<MantelbogenJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    mantelbogen?: Array<MantelbogenJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseRead
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadAdresse
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadAdresse
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBehoerde
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBelegbarePlaetze
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadBezirksregierung
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLandschaftsverband
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadBehoerde}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    behoerde?: EinrichtungJsonldEinrichtungReadAdresseReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    traegerverband?: EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    meldungen?: Array<MeldungJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadTraeger}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    traeger?: EinrichtungJsonldEinrichtungReadAdresseReadTraeger | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadLatestApprovedVersion
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldEinrichtungReadAdresseReadData}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    data?: MeldungJsonldEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadOffeneMeldungen
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadTraeger
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraeger
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
 */
export interface EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadBehoerde
 */
export interface EinrichtungJsonldEinrichtungReadBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBehoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadBehoerde
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadBelegbarePlaetze
 */
export interface EinrichtungJsonldEinrichtungReadBelegbarePlaetze {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadBelegbarePlaetze
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBelegbarePlaetze
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBelegbarePlaetze
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadBelegbarePlaetze
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadBezirksregierung
 */
export interface EinrichtungJsonldEinrichtungReadBezirksregierung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadBezirksregierung
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadLandschaftsverband
 */
export interface EinrichtungJsonldEinrichtungReadLandschaftsverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadLandschaftsverband
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadLatestApprovedVersion
 */
export interface EinrichtungJsonldEinrichtungReadLatestApprovedVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadBehoerde}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    behoerde?: EinrichtungJsonldEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadTraegerverband}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    traegerverband?: EinrichtungJsonldEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungJsonldEinrichtungRead>}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    meldungen?: Array<MeldungJsonldEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadTraeger}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    traeger?: EinrichtungJsonldEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresse}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadLatestApprovedVersion
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadOffeneMeldungen
 */
export interface EinrichtungJsonldEinrichtungReadOffeneMeldungen {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldEinrichtungReadData}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    data?: MeldungJsonldEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadOffeneMeldungen
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadTraeger
 */
export interface EinrichtungJsonldEinrichtungReadTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresse}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadTraeger
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungReadTraegerverband
 */
export interface EinrichtungJsonldEinrichtungReadTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldEinrichtungReadTraegerverband
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungWrite
 */
export interface EinrichtungJsonldEinrichtungWrite {
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungWriteAdresse}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    adresse?: EinrichtungJsonldEinrichtungWriteAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungJsonldEinrichtungWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEinrichtungWriteAdresse
 */
export interface EinrichtungJsonldEinrichtungWriteAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEinrichtungWriteAdresse
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEmailAntragList
 */
export interface EinrichtungJsonldEmailAntragList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEmailAntragList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragList
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragList
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEmailAntragRead
 */
export interface EinrichtungJsonldEmailAntragRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEmailAntragRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailAntragRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEmailListEmailReadEmail
 */
export interface EinrichtungJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldEmailReadEmailReadEmail
 */
export interface EinrichtungJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldLeistungsangebotRegistrierenList
 */
export interface EinrichtungJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof EinrichtungJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldMeldungList
 */
export interface EinrichtungJsonldMeldungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldMeldungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof EinrichtungJsonldMeldungList
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldMeldungRead
 */
export interface EinrichtungJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof EinrichtungJsonldMeldungRead
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungRead
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    adresse?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    deletedByBenutzer?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    landschaftsverband?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    behoerde?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    traegerverband?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    bezirksregierung?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    traeger?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger | null;
    /**
     *
     * @type {Array<UploadJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    uploads?: Array<UploadJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    entwuerfe?: Array<LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<EmailAntragJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    emailAntraege?: Array<EmailAntragJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<MeldungJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    meldungen?: Array<MeldungJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    belegbarePlaetze?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze | null;
    /**
     *
     * @type {Array<RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    regelpruefungen?: Array<RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<FemAntragJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    femAntraege?: Array<FemAntragJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    ambulanteDienste?: Array<WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    offeneMeldungen?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    latestApprovedVersion?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion | null;
    /**
     *
     * @type {Array<MantelbogenJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    mantelbogen?: Array<MantelbogenJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungRead
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    behoerde?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    traegerverband?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    meldungen?: Array<MeldungJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    traeger?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    adresse?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldSimpleformJsonFormEinrichtungReadData}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    data?: MeldungJsonldSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    adresse?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
 */
export interface EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldStandardauswertungRead
 */
export interface EinrichtungJsonldStandardauswertungRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldStandardauswertungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldStandardauswertungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EinrichtungJsonldWohngemeinschaftRead
 */
export interface EinrichtungJsonldWohngemeinschaftRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungJsonldWohngemeinschaftRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldWohngemeinschaftRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldWohngemeinschaftRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungJsonldWohngemeinschaftRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldWohngemeinschaftRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungJsonldWohngemeinschaftRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungLeistungsangebotRegistrierenList
 */
export interface EinrichtungLeistungsangebotRegistrierenList {
    /**
     *
     * @type {string}
     * @memberof EinrichtungLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof EinrichtungLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface EinrichtungLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof EinrichtungLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungMeldungList
 */
export interface EinrichtungMeldungList {
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof EinrichtungMeldungList
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungMeldungRead
 */
export interface EinrichtungMeldungRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof EinrichtungMeldungRead
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungPfadInvestEinrichtungOutputInvestRead
 */
export interface EinrichtungPfadInvestEinrichtungOutputInvestRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    pflegewohngeld?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    traegerUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    traegerverbandUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    landschaftsverbandUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    landkreisUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputInvestRead
     */
    versorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
 */
export interface EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    pflegewohngeld?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    traegerUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    traegerverbandUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    landschaftsverbandUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    landkreisUuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead
     */
    versorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
 */
export interface EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet {
    /**
     *
     * @type {number}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    nrwSchluessel?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    einrichtungsart?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    letzteRegelpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    letzterBericht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    letzterBerichtUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungRegelpruefungCollectionOutputEinrichtungCollectionGet
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
 */
export interface EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet {
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    nrwSchluessel?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    einrichtungsart?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteRegelpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzterBericht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzterBerichtUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungRead
 */
export interface EinrichtungSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    wohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    wohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    short_care_hospital?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    adresse?: EinrichtungSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    deletedByBenutzer?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    landschaftsverband?: EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadBehoerde}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    behoerde?: EinrichtungSimpleformJsonFormEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    traegerverband?: EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    bezirksregierung?: EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadTraeger}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    traeger?: EinrichtungSimpleformJsonFormEinrichtungReadTraeger | null;
    /**
     *
     * @type {Array<UploadSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    uploads?: Array<UploadSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {Array<EinrichtungBenutzerSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    einrichtungBenutzer?: Array<EinrichtungBenutzerSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    entwuerfe?: Array<LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<EmailAntragSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    emailAntraege?: Array<EmailAntragSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<MeldungSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    meldungen?: Array<MeldungSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    belegbarePlaetze?: EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze | null;
    /**
     *
     * @type {Array<RegelpruefungAntragSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    regelpruefungen?: Array<RegelpruefungAntragSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<FemAntragSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    femAntraege?: Array<FemAntragSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<WohngemeinschaftSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    ambulanteDienste?: Array<WohngemeinschaftSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    offeneMeldungen?: EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    latestApprovedVersion?: EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion | null;
    /**
     *
     * @type {Array<MantelbogenSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    mantelbogen?: Array<MantelbogenSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
    /**
     *
     * @type {any}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungRead
     */
    letzteFreigegebeneMeldung?: any;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadAdresse
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadAdresse {
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadAdresse
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadAdresse
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadAdresse
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadBehoerde
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadBehoerde {
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBehoerde
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBehoerde
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze {
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBelegbarePlaetze
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung {
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadBezirksregierung
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband {
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLandschaftsverband
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion {
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadBehoerde}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    behoerde?: EinrichtungSimpleformJsonFormEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    traegerverband?: EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    meldungen?: Array<MeldungSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadTraeger}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    traeger?: EinrichtungSimpleformJsonFormEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    adresse?: EinrichtungSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadLatestApprovedVersion
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen {
    /**
     *
     * @type {MeldungSimpleformJsonFormEinrichtungReadData}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    data?: MeldungSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadOffeneMeldungen
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadTraeger
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadTraeger {
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraeger
     */
    adresse?: EinrichtungSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraeger
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraeger
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband
 */
export interface EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband {
    /**
     *
     * @type {number}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungStandortJsonldRegelpruefungList
 */
export interface EinrichtungStandortJsonldRegelpruefungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungStandortJsonldRegelpruefungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungList
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungStandortJsonldRegelpruefungListAdresse}
     * @memberof EinrichtungStandortJsonldRegelpruefungList
     */
    adresse?: EinrichtungStandortJsonldRegelpruefungListAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungStandortJsonldRegelpruefungList
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungStandortJsonldRegelpruefungListAdresse
 */
export interface EinrichtungStandortJsonldRegelpruefungListAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungStandortJsonldRegelpruefungListAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungListAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungListAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungListAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungStandortJsonldRegelpruefungRead
 */
export interface EinrichtungStandortJsonldRegelpruefungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungStandortJsonldRegelpruefungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EinrichtungStandortJsonldRegelpruefungRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungStandortJsonldRegelpruefungReadAdresse}
     * @memberof EinrichtungStandortJsonldRegelpruefungRead
     */
    adresse?: EinrichtungStandortJsonldRegelpruefungReadAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungStandortJsonldRegelpruefungRead
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungStandortJsonldRegelpruefungReadAdresse
 */
export interface EinrichtungStandortJsonldRegelpruefungReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungStandortJsonldRegelpruefungReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortJsonldRegelpruefungReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungStandortRegelpruefungList
 */
export interface EinrichtungStandortRegelpruefungList {
    /**
     *
     * @type {EinrichtungStandortRegelpruefungListAdresse}
     * @memberof EinrichtungStandortRegelpruefungList
     */
    adresse?: EinrichtungStandortRegelpruefungListAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungStandortRegelpruefungList
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungStandortRegelpruefungListAdresse
 */
export interface EinrichtungStandortRegelpruefungListAdresse {
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortRegelpruefungListAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungStandortRegelpruefungRead
 */
export interface EinrichtungStandortRegelpruefungRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungStandortRegelpruefungRead
     */
    id?: number;
    /**
     *
     * @type {EinrichtungStandortRegelpruefungReadAdresse}
     * @memberof EinrichtungStandortRegelpruefungRead
     */
    adresse?: EinrichtungStandortRegelpruefungReadAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungStandortRegelpruefungRead
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface EinrichtungStandortRegelpruefungReadAdresse
 */
export interface EinrichtungStandortRegelpruefungReadAdresse {
    /**
     *
     * @type {string}
     * @memberof EinrichtungStandortRegelpruefungReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionEinrichtungRead
 */
export interface EinrichtungVersionEinrichtungRead {
    /**
     *
     * @type {EinrichtungEinrichtungReadBehoerde}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    behoerde?: EinrichtungEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadTraegerverband}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    traegerverband?: EinrichtungEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungEinrichtungRead>}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    meldungen?: Array<MeldungEinrichtungRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadTraeger}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    traeger?: EinrichtungEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresse}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    adresse?: EinrichtungEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EinrichtungVersionEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionEinrichtungReadAdresseRead
 */
export interface EinrichtungVersionEinrichtungReadAdresseRead {
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadBehoerde}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    behoerde?: EinrichtungEinrichtungReadAdresseReadBehoerde | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadTraegerverband}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    traegerverband?: EinrichtungEinrichtungReadAdresseReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungEinrichtungReadAdresseRead>}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    meldungen?: Array<MeldungEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadTraeger}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    traeger?: EinrichtungEinrichtungReadAdresseReadTraeger | null;
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    adresse?: EinrichtungEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungVersionEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionJsonldEinrichtungRead
 */
export interface EinrichtungVersionJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadBehoerde}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    behoerde?: EinrichtungJsonldEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadTraegerverband}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    traegerverband?: EinrichtungJsonldEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungJsonldEinrichtungRead>}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    meldungen?: Array<MeldungJsonldEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadTraeger}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    traeger?: EinrichtungJsonldEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresse}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EinrichtungVersionJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionJsonldEinrichtungReadAdresseRead
 */
export interface EinrichtungVersionJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadBehoerde}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    behoerde?: EinrichtungJsonldEinrichtungReadAdresseReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    traegerverband?: EinrichtungJsonldEinrichtungReadAdresseReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungJsonldEinrichtungReadAdresseRead>}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    meldungen?: Array<MeldungJsonldEinrichtungReadAdresseRead>;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadTraeger}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    traeger?: EinrichtungJsonldEinrichtungReadAdresseReadTraeger | null;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadAdresse}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EinrichtungVersionJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionJsonldMeldungList
 */
export interface EinrichtungVersionJsonldMeldungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungVersionJsonldMeldungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof EinrichtungVersionJsonldMeldungList
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionJsonldMeldungRead
 */
export interface EinrichtungVersionJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungVersionJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof EinrichtungVersionJsonldMeldungRead
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
 */
export interface EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    behoerde?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    traegerverband?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    meldungen?: Array<MeldungJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    traeger?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    adresse?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungVersionJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionMeldungList
 */
export interface EinrichtungVersionMeldungList {
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof EinrichtungVersionMeldungList
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionMeldungRead
 */
export interface EinrichtungVersionMeldungRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof EinrichtungVersionMeldungRead
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungVersionSimpleformJsonFormEinrichtungRead
 */
export interface EinrichtungVersionSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadBehoerde}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    behoerde?: EinrichtungSimpleformJsonFormEinrichtungReadBehoerde | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    traegerverband?: EinrichtungSimpleformJsonFormEinrichtungReadTraegerverband | null;
    /**
     *
     * @type {Array<MeldungSimpleformJsonFormEinrichtungRead>}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    meldungen?: Array<MeldungSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadTraeger}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    traeger?: EinrichtungSimpleformJsonFormEinrichtungReadTraeger | null;
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    adresse?: EinrichtungSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    unternehmensgruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    schliessungDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    sector?: string;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    platzzahlmeldepflicht?: boolean;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    community_service_area?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    short_care_hospital?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    aktenzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    zad?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    institutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    weiteresInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EinrichtungVersionSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EinrichtungWohngemeinschaftRead
 */
export interface EinrichtungWohngemeinschaftRead {
    /**
     *
     * @type {number}
     * @memberof EinrichtungWohngemeinschaftRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EinrichtungWohngemeinschaftRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungWohngemeinschaftRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungWorkflowActionAntragWorkflow
 */
export interface EinrichtungWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof EinrichtungWorkflowActionAntragWorkflow
     */
    action: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungWorkflowActionAntragWorkflow
     */
    grund?: string | null;
}
/**
 *
 * @export
 * @interface Einrichtungsleitung
 */
export interface Einrichtungsleitung {
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    personalName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    personalTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof Einrichtungsleitung
     */
    personalStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    personalTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    personalFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    personalEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof Einrichtungsleitung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungsleitungJsonld
 */
export interface EinrichtungsleitungJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungsleitungJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    personalName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    personalTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungsleitungJsonld
     */
    personalStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    personalTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    personalFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    personalEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungsleitungJsonldMeldungRead
 */
export interface EinrichtungsleitungJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    personalName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    personalTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    personalStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    personalTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    personalFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungJsonldMeldungRead
     */
    personalEmail?: string | null;
}
/**
 *
 * @export
 * @interface EinrichtungsleitungMeldungRead
 */
export interface EinrichtungsleitungMeldungRead {
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungMeldungRead
     */
    personalName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungMeldungRead
     */
    personalTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof EinrichtungsleitungMeldungRead
     */
    personalStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungMeldungRead
     */
    personalTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungMeldungRead
     */
    personalFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof EinrichtungsleitungMeldungRead
     */
    personalEmail?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntrag
 */
export interface EmailAntrag {
    /**
     *
     * @type {number}
     * @memberof EmailAntrag
     */
    id?: number;
    /**
     *
     * @type {EmailAntragData}
     * @memberof EmailAntrag
     */
    data?: EmailAntragData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    typ?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    meldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    uuid?: string;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntrag
     */
    isRead?: boolean;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    sender?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    empfaenger?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterface>}
     * @memberof EmailAntrag
     */
    versionen?: Array<AntragVersionInterface>;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntrag
     */
    read?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntrag
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    shortClassName?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntrag
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragAntragJsonForm
 */
export interface EmailAntragAntragJsonForm {
    /**
     *
     * @type {EmailAntragAntragJsonFormData}
     * @memberof EmailAntragAntragJsonForm
     */
    data?: EmailAntragAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface EmailAntragAntragJsonFormData
 */
export interface EmailAntragAntragJsonFormData {}
/**
 *
 * @export
 * @interface EmailAntragAntragWorkflowActionAntragWorkflow
 */
export interface EmailAntragAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof EmailAntragAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragData
 */
export interface EmailAntragData {
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragData
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragData
     */
    behoerde?: number | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof EmailAntragData
     */
    dokumente?: Array<Dokument> | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface EmailAntragDataEmailAntragList
 */
export interface EmailAntragDataEmailAntragList {
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragList
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataEmailAntragList
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataEmailAntragList
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragEmailAntragListSender}
     * @memberof EmailAntragDataEmailAntragList
     */
    benutzer?: EmailAntragEmailAntragListSender | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragList
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragList
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragList
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragList
     */
    updateDeadline?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragDataEmailAntragRead
 */
export interface EmailAntragDataEmailAntragRead {
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragRead
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataEmailAntragRead
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataEmailAntragRead
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragEmailAntragReadEingereichtBy}
     * @memberof EmailAntragDataEmailAntragRead
     */
    benutzer?: EmailAntragEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragRead
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragRead
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragRead
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataEmailAntragRead
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<DokumentEmailAntragRead>}
     * @memberof EmailAntragDataEmailAntragRead
     */
    dokumente?: Array<DokumentEmailAntragRead> | null;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonld
 */
export interface EmailAntragDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataJsonld
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataJsonld
     */
    behoerde?: number | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof EmailAntragDataJsonld
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragDataJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragDataJsonld
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonldAntragJsonForm
 */
export interface EmailAntragDataJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldAntragJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonldEinrichtungRead
 */
export interface EmailAntragDataJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonldEinrichtungReadAdresseRead
 */
export interface EmailAntragDataJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonldEmailAntragList
 */
export interface EmailAntragDataJsonldEmailAntragList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragListSender}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    benutzer?: EmailAntragJsonldEmailAntragListSender | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragList
     */
    updateDeadline?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonldEmailAntragRead
 */
export interface EmailAntragDataJsonldEmailAntragRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragReadEingereichtBy}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    benutzer?: EmailAntragJsonldEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldEmailAntragRead>}
     * @memberof EmailAntragDataJsonldEmailAntragRead
     */
    dokumente?: Array<DokumentJsonldEmailAntragRead> | null;
}
/**
 *
 * @export
 * @interface EmailAntragDataJsonldSimpleformJsonFormEinrichtungRead
 */
export interface EmailAntragDataJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragEinrichtungRead
 */
export interface EmailAntragEinrichtungRead {
    /**
     *
     * @type {EmailAntragEinrichtungReadData}
     * @memberof EmailAntragEinrichtungRead
     */
    data?: EmailAntragEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof EmailAntragEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EmailAntragEinrichtungReadAdresseRead
 */
export interface EmailAntragEinrichtungReadAdresseRead {
    /**
     *
     * @type {EmailAntragEinrichtungReadAdresseReadData}
     * @memberof EmailAntragEinrichtungReadAdresseRead
     */
    data?: EmailAntragEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof EmailAntragEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EmailAntragEinrichtungReadAdresseReadData
 */
export interface EmailAntragEinrichtungReadAdresseReadData {}
/**
 *
 * @export
 * @interface EmailAntragEinrichtungReadData
 */
export interface EmailAntragEinrichtungReadData {}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragJsonAntragJsonForm
 */
export interface EmailAntragEmailAntragJsonAntragJsonForm {
    /**
     *
     * @type {EmailAntragAntragJsonForm}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    antrag?: EmailAntragAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragEmailAntragJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragJsonJsonldAntragJsonForm
 */
export interface EmailAntragEmailAntragJsonJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {EmailAntragJsonldAntragJsonForm}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    antrag?: EmailAntragJsonldAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragEmailAntragJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragList
 */
export interface EmailAntragEmailAntragList {
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragList
     */
    id?: number;
    /**
     *
     * @type {EmailAntragEmailAntragListData}
     * @memberof EmailAntragEmailAntragList
     */
    data?: EmailAntragEmailAntragListData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    status?: string;
    /**
     *
     * @type {EmailAntragEmailAntragListEinrichtung}
     * @memberof EmailAntragEmailAntragList
     */
    einrichtung?: EmailAntragEmailAntragListEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragEmailAntragList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    typ?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    meldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    betreff?: string;
    /**
     *
     * @type {EmailAntragEmailAntragListSender}
     * @memberof EmailAntragEmailAntragList
     */
    sender?: EmailAntragEmailAntragListSender | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragList
     */
    empfaenger?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragEmailAntragList
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntragEmailAntragList
     */
    read?: boolean;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragListData
 */
export interface EmailAntragEmailAntragListData {
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragListData
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragListData
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragEmailAntragListSender}
     * @memberof EmailAntragEmailAntragListData
     */
    benutzer?: EmailAntragEmailAntragListSender | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListData
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListData
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListData
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListData
     */
    updateDeadline?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragListEinrichtung
 */
export interface EmailAntragEmailAntragListEinrichtung {
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragListSender
 */
export interface EmailAntragEmailAntragListSender {
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragListSender
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListSender
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListSender
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListSender
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragListSender
     */
    status?: string;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragRead
 */
export interface EmailAntragEmailAntragRead {
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragRead
     */
    id?: number;
    /**
     *
     * @type {EmailAntragEmailAntragReadData}
     * @memberof EmailAntragEmailAntragRead
     */
    data?: EmailAntragEmailAntragReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    status?: string;
    /**
     *
     * @type {EmailAntragEmailAntragReadEinrichtung}
     * @memberof EmailAntragEmailAntragRead
     */
    einrichtung?: EmailAntragEmailAntragReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {EmailAntragEmailAntragReadEingereichtBy}
     * @memberof EmailAntragEmailAntragRead
     */
    eingereichtBy?: EmailAntragEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragEmailAntragRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    typ?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    meldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    betreff?: string;
    /**
     *
     * @type {EmailAntragEmailAntragReadEingereichtBy}
     * @memberof EmailAntragEmailAntragRead
     */
    sender?: EmailAntragEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragRead
     */
    empfaenger?: string;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntragEmailAntragRead
     */
    read?: boolean;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragReadData
 */
export interface EmailAntragEmailAntragReadData {
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragReadData
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragReadData
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragEmailAntragReadEingereichtBy}
     * @memberof EmailAntragEmailAntragReadData
     */
    benutzer?: EmailAntragEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadData
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadData
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadData
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadData
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<DokumentEmailAntragRead>}
     * @memberof EmailAntragEmailAntragReadData
     */
    dokumente?: Array<DokumentEmailAntragRead> | null;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragReadEingereichtBy
 */
export interface EmailAntragEmailAntragReadEingereichtBy {
    /**
     *
     * @type {number}
     * @memberof EmailAntragEmailAntragReadEingereichtBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadEingereichtBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadEingereichtBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadEingereichtBy
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadEingereichtBy
     */
    status?: string;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragReadEinrichtung
 */
export interface EmailAntragEmailAntragReadEinrichtung {
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragReadEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragEmailAntragWrite
 */
export interface EmailAntragEmailAntragWrite {
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragWrite
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragEmailAntragWrite
     */
    typ?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonld
 */
export interface EmailAntragJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonld
     */
    id?: number;
    /**
     *
     * @type {EmailAntragJsonldData}
     * @memberof EmailAntragJsonld
     */
    data?: EmailAntragJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    typ?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    meldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    uuid?: string;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntragJsonld
     */
    isRead?: boolean;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    sender?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    empfaenger?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterfaceJsonld>}
     * @memberof EmailAntragJsonld
     */
    versionen?: Array<AntragVersionInterfaceJsonld>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntragJsonld
     */
    read?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonld
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldAntragJsonForm
 */
export interface EmailAntragJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {EmailAntragJsonldAntragJsonFormData}
     * @memberof EmailAntragJsonldAntragJsonForm
     */
    data?: EmailAntragJsonldAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldAntragJsonFormData
 */
export interface EmailAntragJsonldAntragJsonFormData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldAntragJsonFormData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldAntragJsonFormData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldAntragJsonFormData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldData
 */
export interface EmailAntragJsonldData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldData
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldData
     */
    behoerde?: number | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof EmailAntragJsonldData
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonldData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonldData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEinrichtungRead
 */
export interface EmailAntragJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EmailAntragJsonldEinrichtungReadData}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    data?: EmailAntragJsonldEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof EmailAntragJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEinrichtungReadAdresseRead
 */
export interface EmailAntragJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {EmailAntragJsonldEinrichtungReadAdresseReadData}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    data?: EmailAntragJsonldEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEinrichtungReadAdresseReadData
 */
export interface EmailAntragJsonldEinrichtungReadAdresseReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadAdresseReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEinrichtungReadData
 */
export interface EmailAntragJsonldEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragList
 */
export interface EmailAntragJsonldEmailAntragList {
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    id?: number;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragListData}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    data?: EmailAntragJsonldEmailAntragListData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    status?: string;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragListEinrichtung}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    einrichtung?: EmailAntragJsonldEmailAntragListEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    typ?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    meldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    betreff?: string;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragListSender}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    sender?: EmailAntragJsonldEmailAntragListSender | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    empfaenger?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntragJsonldEmailAntragList
     */
    read?: boolean;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragListData
 */
export interface EmailAntragJsonldEmailAntragListData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragListSender}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    benutzer?: EmailAntragJsonldEmailAntragListSender | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListData
     */
    updateDeadline?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragListEinrichtung
 */
export interface EmailAntragJsonldEmailAntragListEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragListEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragListSender
 */
export interface EmailAntragJsonldEmailAntragListSender {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragListSender
     */
    status?: string;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragRead
 */
export interface EmailAntragJsonldEmailAntragRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    id?: number;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragReadData}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    data?: EmailAntragJsonldEmailAntragReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    status?: string;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragReadEinrichtung}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    einrichtung?: EmailAntragJsonldEmailAntragReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragReadEingereichtBy}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    eingereichtBy?: EmailAntragJsonldEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    typ?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    meldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    betreff?: string;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragReadEingereichtBy}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    sender?: EmailAntragJsonldEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    empfaenger?: string;
    /**
     *
     * @type {boolean}
     * @memberof EmailAntragJsonldEmailAntragRead
     */
    read?: boolean;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragReadData
 */
export interface EmailAntragJsonldEmailAntragReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    behoerde?: number | null;
    /**
     *
     * @type {EmailAntragJsonldEmailAntragReadEingereichtBy}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    benutzer?: EmailAntragJsonldEmailAntragReadEingereichtBy | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    empfaenger?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    betreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    nachricht?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    updateDeadline?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldEmailAntragRead>}
     * @memberof EmailAntragJsonldEmailAntragReadData
     */
    dokumente?: Array<DokumentJsonldEmailAntragRead> | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragReadEingereichtBy
 */
export interface EmailAntragJsonldEmailAntragReadEingereichtBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    deaktiviertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEingereichtBy
     */
    status?: string;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragReadEinrichtung
 */
export interface EmailAntragJsonldEmailAntragReadEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldEmailAntragReadEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragReadEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldEmailAntragWrite
 */
export interface EmailAntragJsonldEmailAntragWrite {
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragWrite
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldEmailAntragWrite
     */
    typ?: string | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldSimpleformJsonFormEinrichtungRead
 */
export interface EmailAntragJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EmailAntragJsonldSimpleformJsonFormEinrichtungReadData}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    data?: EmailAntragJsonldSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EmailAntragJsonldSimpleformJsonFormEinrichtungReadData
 */
export interface EmailAntragJsonldSimpleformJsonFormEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface EmailAntragSimpleformJsonFormEinrichtungRead
 */
export interface EmailAntragSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {EmailAntragSimpleformJsonFormEinrichtungReadData}
     * @memberof EmailAntragSimpleformJsonFormEinrichtungRead
     */
    data?: EmailAntragSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof EmailAntragSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof EmailAntragSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof EmailAntragSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface EmailAntragSimpleformJsonFormEinrichtungReadData
 */
export interface EmailAntragSimpleformJsonFormEinrichtungReadData {}
/**
 *
 * @export
 * @interface EmailBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface EmailBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof EmailBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmail
 */
export interface EmailEmailListEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof EmailEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {EmailEmailListEmailReadEmailEmpfaenger}
     * @memberof EmailEmailListEmailReadEmail
     */
    empfaenger?: EmailEmailListEmailReadEmailEmpfaenger | null;
    /**
     *
     * @type {EmailEmailListEmailReadEmailEinrichtung}
     * @memberof EmailEmailListEmailReadEmail
     */
    einrichtung?: EmailEmailListEmailReadEmailEinrichtung | null;
    /**
     *
     * @type {EmailEmailListEmailReadEmailMeldungsantrag}
     * @memberof EmailEmailListEmailReadEmail
     */
    meldungsantrag?: EmailEmailListEmailReadEmailMeldungsantrag | null;
    /**
     *
     * @type {EmailEmailListEmailReadEmailFemAntrag}
     * @memberof EmailEmailListEmailReadEmail
     */
    femAntrag?: EmailEmailListEmailReadEmailFemAntrag | null;
    /**
     *
     * @type {EmailEmailListEmailReadEmailRegelpruefungAntrag}
     * @memberof EmailEmailListEmailReadEmail
     */
    regelpruefungAntrag?: EmailEmailListEmailReadEmailRegelpruefungAntrag | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    text?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    gelesen?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailEmailListEmailReadEmail
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailEmailListEmailReadEmail
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {EmailEmailListEmailReadEmailEmpfaenger}
     * @memberof EmailEmailListEmailReadEmail
     */
    createdBy?: EmailEmailListEmailReadEmailEmpfaenger | null;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmailEinrichtung
 */
export interface EmailEmailListEmailReadEmailEinrichtung {
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailEinrichtung
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmailEmpfaenger
 */
export interface EmailEmailListEmailReadEmailEmpfaenger {
    /**
     *
     * @type {number}
     * @memberof EmailEmailListEmailReadEmailEmpfaenger
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailEmpfaenger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailEmpfaenger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailEmpfaenger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailEmpfaenger
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmailFemAntrag
 */
export interface EmailEmailListEmailReadEmailFemAntrag {
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailFemAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmailMeldungsantrag
 */
export interface EmailEmailListEmailReadEmailMeldungsantrag {
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailMeldungsantrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailListEmailReadEmailRegelpruefungAntrag
 */
export interface EmailEmailListEmailReadEmailRegelpruefungAntrag {
    /**
     *
     * @type {string}
     * @memberof EmailEmailListEmailReadEmailRegelpruefungAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmail
 */
export interface EmailEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof EmailEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailEmpfaenger}
     * @memberof EmailEmailReadEmailReadEmail
     */
    empfaenger?: EmailEmailReadEmailReadEmailEmpfaenger | null;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailEinrichtung}
     * @memberof EmailEmailReadEmailReadEmail
     */
    einrichtung?: EmailEmailReadEmailReadEmailEinrichtung | null;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailMeldungsantrag}
     * @memberof EmailEmailReadEmailReadEmail
     */
    meldungsantrag?: EmailEmailReadEmailReadEmailMeldungsantrag | null;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailFemAntrag}
     * @memberof EmailEmailReadEmailReadEmail
     */
    femAntrag?: EmailEmailReadEmailReadEmailFemAntrag | null;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailRegelpruefungAntrag}
     * @memberof EmailEmailReadEmailReadEmail
     */
    regelpruefungAntrag?: EmailEmailReadEmailReadEmailRegelpruefungAntrag | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    text?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    gelesen?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailEmailReadEmailReadEmail
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailEmailReadEmailReadEmail
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {Array<UploadEmailReadEmailReadEmail>}
     * @memberof EmailEmailReadEmailReadEmail
     */
    uploads?: Array<UploadEmailReadEmailReadEmail>;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailEmpfaenger}
     * @memberof EmailEmailReadEmailReadEmail
     */
    createdBy?: EmailEmailReadEmailReadEmailEmpfaenger | null;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmailEinrichtung
 */
export interface EmailEmailReadEmailReadEmailEinrichtung {
    /**
     *
     * @type {number}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEinrichtung
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmailEmpfaenger
 */
export interface EmailEmailReadEmailReadEmailEmpfaenger {
    /**
     *
     * @type {number}
     * @memberof EmailEmailReadEmailReadEmailEmpfaenger
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEmpfaenger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEmpfaenger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEmpfaenger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailEmpfaenger
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmailFemAntrag
 */
export interface EmailEmailReadEmailReadEmailFemAntrag {
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailFemAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmailMeldungsantrag
 */
export interface EmailEmailReadEmailReadEmailMeldungsantrag {
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailMeldungsantrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailEmailReadEmailReadEmailRegelpruefungAntrag
 */
export interface EmailEmailReadEmailReadEmailRegelpruefungAntrag {
    /**
     *
     * @type {string}
     * @memberof EmailEmailReadEmailReadEmailRegelpruefungAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmail
 */
export interface EmailJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {EmailJsonldEmailListEmailReadEmailEmpfaenger}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    empfaenger?: EmailJsonldEmailListEmailReadEmailEmpfaenger | null;
    /**
     *
     * @type {EmailJsonldEmailListEmailReadEmailEinrichtung}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    einrichtung?: EmailJsonldEmailListEmailReadEmailEinrichtung | null;
    /**
     *
     * @type {EmailJsonldEmailListEmailReadEmailMeldungsantrag}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    meldungsantrag?: EmailJsonldEmailListEmailReadEmailMeldungsantrag | null;
    /**
     *
     * @type {EmailJsonldEmailListEmailReadEmailFemAntrag}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    femAntrag?: EmailJsonldEmailListEmailReadEmailFemAntrag | null;
    /**
     *
     * @type {EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    regelpruefungAntrag?: EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    text?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    gelesen?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {EmailJsonldEmailListEmailReadEmailEmpfaenger}
     * @memberof EmailJsonldEmailListEmailReadEmail
     */
    createdBy?: EmailJsonldEmailListEmailReadEmailEmpfaenger | null;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmailEinrichtung
 */
export interface EmailJsonldEmailListEmailReadEmailEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailListEmailReadEmailEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEinrichtung
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmailEmpfaenger
 */
export interface EmailJsonldEmailListEmailReadEmailEmpfaenger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailEmpfaenger
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmailFemAntrag
 */
export interface EmailJsonldEmailListEmailReadEmailFemAntrag {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailListEmailReadEmailFemAntrag
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailFemAntrag
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailFemAntrag
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailFemAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmailMeldungsantrag
 */
export interface EmailJsonldEmailListEmailReadEmailMeldungsantrag {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailListEmailReadEmailMeldungsantrag
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailMeldungsantrag
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailMeldungsantrag
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailMeldungsantrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag
 */
export interface EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailListEmailReadEmailRegelpruefungAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmail
 */
export interface EmailJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailEmpfaenger}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    empfaenger?: EmailJsonldEmailReadEmailReadEmailEmpfaenger | null;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailEinrichtung}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    einrichtung?: EmailJsonldEmailReadEmailReadEmailEinrichtung | null;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailMeldungsantrag}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    meldungsantrag?: EmailJsonldEmailReadEmailReadEmailMeldungsantrag | null;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailFemAntrag}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    femAntrag?: EmailJsonldEmailReadEmailReadEmailFemAntrag | null;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    regelpruefungAntrag?: EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    betreff?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    text?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    gelesen?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    allowedActions?: Array<string> | null;
    /**
     *
     * @type {Array<UploadJsonldEmailReadEmailReadEmail>}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    uploads?: Array<UploadJsonldEmailReadEmailReadEmail>;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailEmpfaenger}
     * @memberof EmailJsonldEmailReadEmailReadEmail
     */
    createdBy?: EmailJsonldEmailReadEmailReadEmailEmpfaenger | null;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmailEinrichtung
 */
export interface EmailJsonldEmailReadEmailReadEmailEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    enabled?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    needs_update?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    update_deadline?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    deleted_notice?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    delete_confirmed?: string | null;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEinrichtung
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmailEmpfaenger
 */
export interface EmailJsonldEmailReadEmailReadEmailEmpfaenger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    rolle: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailEmpfaenger
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmailFemAntrag
 */
export interface EmailJsonldEmailReadEmailReadEmailFemAntrag {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailReadEmailReadEmailFemAntrag
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailFemAntrag
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailFemAntrag
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailFemAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmailMeldungsantrag
 */
export interface EmailJsonldEmailReadEmailReadEmailMeldungsantrag {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailReadEmailReadEmailMeldungsantrag
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailMeldungsantrag
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailMeldungsantrag
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailMeldungsantrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag
 */
export interface EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailJsonldEmailReadEmailReadEmailRegelpruefungAntrag
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface EmailList200Response
 */
export interface EmailList200Response {
    /**
     *
     * @type {Array<EmailJsonldEmailListEmailReadEmail>}
     * @memberof EmailList200Response
     */
    'hydra:member': Array<EmailJsonldEmailListEmailReadEmail>;
    /**
     *
     * @type {number}
     * @memberof EmailList200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof EmailList200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof EmailList200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface EmailtextEmailtextRead
 */
export interface EmailtextEmailtextRead {
    /**
     *
     * @type {number}
     * @memberof EmailtextEmailtextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailtextEmailtextRead
     */
    behoerde?: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextEmailtextRead
     */
    emailtyp: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextEmailtextRead
     */
    inhalt: string;
}
/**
 *
 * @export
 * @interface EmailtextEmailtextWrite
 */
export interface EmailtextEmailtextWrite {
    /**
     *
     * @type {string}
     * @memberof EmailtextEmailtextWrite
     */
    behoerde?: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextEmailtextWrite
     */
    emailtyp: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextEmailtextWrite
     */
    inhalt: string;
}
/**
 *
 * @export
 * @interface EmailtextJsonldEmailtextRead
 */
export interface EmailtextJsonldEmailtextRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof EmailtextJsonldEmailtextRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof EmailtextJsonldEmailtextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextRead
     */
    behoerde?: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextRead
     */
    emailtyp: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextRead
     */
    inhalt: string;
}
/**
 *
 * @export
 * @interface EmailtextJsonldEmailtextWrite
 */
export interface EmailtextJsonldEmailtextWrite {
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextWrite
     */
    behoerde?: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextWrite
     */
    emailtyp: string;
    /**
     *
     * @type {string}
     * @memberof EmailtextJsonldEmailtextWrite
     */
    inhalt: string;
}
/**
 *
 * @export
 * @interface ExportRequest
 */
export interface ExportRequest {
    /**
     *
     * @type {number}
     * @memberof ExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungDetailsOutputMantelbogen}
     * @memberof ExportRequest
     */
    generiertesDokument?: EinrichtungDetailsOutputMantelbogen | null;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    status?: ExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof ExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequest
     */
    resource?: string;
}

export const ExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type ExportRequestStatusEnum = (typeof ExportRequestStatusEnum)[keyof typeof ExportRequestStatusEnum];

/**
 *
 * @export
 * @interface ExportRequestExportRequest
 */
export interface ExportRequestExportRequest {
    /**
     *
     * @type {number}
     * @memberof ExportRequestExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestExportRequestGeneriertesDokument}
     * @memberof ExportRequestExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    status?: ExportRequestExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestExportRequest
     */
    resource?: string;
}

export const ExportRequestExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type ExportRequestExportRequestStatusEnum =
    (typeof ExportRequestExportRequestStatusEnum)[keyof typeof ExportRequestExportRequestStatusEnum];

/**
 *
 * @export
 * @interface ExportRequestJsonld
 */
export interface ExportRequestJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof ExportRequestJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof ExportRequestJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungDetailsOutputJsonldMantelbogen}
     * @memberof ExportRequestJsonld
     */
    generiertesDokument?: EinrichtungDetailsOutputJsonldMantelbogen | null;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    status?: ExportRequestJsonldStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonld
     */
    resource?: string;
}

export const ExportRequestJsonldStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type ExportRequestJsonldStatusEnum =
    (typeof ExportRequestJsonldStatusEnum)[keyof typeof ExportRequestJsonldStatusEnum];

/**
 *
 * @export
 * @interface ExportRequestJsonldExportRequest
 */
export interface ExportRequestJsonldExportRequest {
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    '@type'?: string;
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof ExportRequestJsonldExportRequest
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {number}
     * @memberof ExportRequestJsonldExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestJsonldExportRequestGeneriertesDokument}
     * @memberof ExportRequestJsonldExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestJsonldExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    status?: ExportRequestJsonldExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof ExportRequestJsonldExportRequest
     */
    resource?: string;
}

export const ExportRequestJsonldExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type ExportRequestJsonldExportRequestStatusEnum =
    (typeof ExportRequestJsonldExportRequestStatusEnum)[keyof typeof ExportRequestJsonldExportRequestStatusEnum];

/**
 *
 * @export
 * @interface FemAntrag
 */
export interface FemAntrag {
    /**
     *
     * @type {number}
     * @memberof FemAntrag
     */
    id?: number;
    /**
     *
     * @type {FemAntragData}
     * @memberof FemAntrag
     */
    data?: FemAntragData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof FemAntrag
     */
    jahr?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntrag
     */
    anzahlGerichtlicherGenehmigung?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntrag
     */
    anzahlEinwilligung?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntrag
     */
    quartal?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    aufforderungen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    reminder?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterface>}
     * @memberof FemAntrag
     */
    versionen?: Array<AntragVersionInterface>;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntrag
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntrag
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface FemAntragAntragJsonForm
 */
export interface FemAntragAntragJsonForm {
    /**
     *
     * @type {FemAntragAntragJsonFormData}
     * @memberof FemAntragAntragJsonForm
     */
    data?: FemAntragAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface FemAntragAntragJsonFormData
 */
export interface FemAntragAntragJsonFormData {}
/**
 *
 * @export
 * @interface FemAntragAntragWorkflowActionAntragWorkflow
 */
export interface FemAntragAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof FemAntragAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface FemAntragData
 */
export interface FemAntragData {
    /**
     *
     * @type {string}
     * @memberof FemAntragData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragData
     */
    gerichtlichGesamtanzahl?: number;
    /**
     *
     * @type {number}
     * @memberof FemAntragData
     */
    gerichtlichGesamtanzahlBetroffene?: number;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichSiebenPunktFixierung?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichFuenfPunktFixierung?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichFixierungArme?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichFixierungBeine?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichFixierungBauchgurt?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichZwangsjacke?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichBettgitter?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichPflegedecken?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichTherapietisch?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichGeschlossene?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichEinsperren?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichSchliessmechanismen?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichArretieren?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichFesthalten?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichVerbot?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichGesicherteAufzuege?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichSicherungTransport?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichSedierendeMedikamente?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichSitzhosen?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichWegnehmenDerKleidung?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    gerichtlichSchutzhandschuhe?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragData
     */
    einwilligungGesamtanzahl?: number;
    /**
     *
     * @type {number}
     * @memberof FemAntragData
     */
    einwilligungGesamtanzahlBetroffene?: number;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungSiebenPunktFixierung?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungFuenfPunktFixierung?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungFixierungArme?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungFixierungBeine?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungFixierungBauchgurt?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungZwangsjacke?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungBettgitter?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungPflegedecken?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungTherapietisch?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungGeschlossene?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungEinsperren?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungSchliessmechanismen?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungArretieren?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungFesthalten?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungVerbot?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungGesicherteAufzuege?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungSicherungTransport?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungSedierendeMedikamente?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungSitzhosen?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungWegnehmenDerKleidung?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragData
     */
    einwilligungSchutzhandschuhe?: FemAntragDataGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {Array<CustomMassnahme>}
     * @memberof FemAntragData
     */
    gerichtlichCustomMassnahmen?: Array<CustomMassnahme>;
    /**
     *
     * @type {Array<CustomMassnahme>}
     * @memberof FemAntragData
     */
    einwilligungCustomMassnahmen?: Array<CustomMassnahme>;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof FemAntragData
     */
    dokumente?: Array<Dokument> | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface FemAntragDataGerichtlichSiebenPunktFixierung
 */
export interface FemAntragDataGerichtlichSiebenPunktFixierung {
    /**
     *
     * @type {string}
     * @memberof FemAntragDataGerichtlichSiebenPunktFixierung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataGerichtlichSiebenPunktFixierung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataGerichtlichSiebenPunktFixierung
     */
    displayName?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataGerichtlichSiebenPunktFixierung
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataGerichtlichSiebenPunktFixierung
     */
    anzahlPersonen?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataGerichtlichSiebenPunktFixierung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface FemAntragDataJsonld
 */
export interface FemAntragDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichGesamtanzahl?: number;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichGesamtanzahlBetroffene?: number;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichSiebenPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichFuenfPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichFixierungArme?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichFixierungBeine?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichFixierungBauchgurt?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichZwangsjacke?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichBettgitter?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichPflegedecken?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichTherapietisch?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichGeschlossene?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichEinsperren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichSchliessmechanismen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichArretieren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichFesthalten?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichVerbot?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichGesicherteAufzuege?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichSicherungTransport?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichSedierendeMedikamente?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichSitzhosen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichWegnehmenDerKleidung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichSchutzhandschuhe?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataJsonld
     */
    einwilligungGesamtanzahl?: number;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataJsonld
     */
    einwilligungGesamtanzahlBetroffene?: number;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungSiebenPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungFuenfPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungFixierungArme?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungFixierungBeine?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungFixierungBauchgurt?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungZwangsjacke?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungBettgitter?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungPflegedecken?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungTherapietisch?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungGeschlossene?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungEinsperren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungSchliessmechanismen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungArretieren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungFesthalten?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungVerbot?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungGesicherteAufzuege?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungSicherungTransport?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungSedierendeMedikamente?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungSitzhosen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungWegnehmenDerKleidung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragDataJsonld
     */
    einwilligungSchutzhandschuhe?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {Array<CustomMassnahmeJsonld>}
     * @memberof FemAntragDataJsonld
     */
    gerichtlichCustomMassnahmen?: Array<CustomMassnahmeJsonld>;
    /**
     *
     * @type {Array<CustomMassnahmeJsonld>}
     * @memberof FemAntragDataJsonld
     */
    einwilligungCustomMassnahmen?: Array<CustomMassnahmeJsonld>;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof FemAntragDataJsonld
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragDataJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragDataJsonld
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface FemAntragDataJsonldAntragJsonForm
 */
export interface FemAntragDataJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragDataJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldAntragJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragDataJsonldEinrichtungRead
 */
export interface FemAntragDataJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragDataJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragDataJsonldEinrichtungReadAdresseRead
 */
export interface FemAntragDataJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragDataJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragDataJsonldGerichtlichSiebenPunktFixierung
 */
export interface FemAntragDataJsonldGerichtlichSiebenPunktFixierung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    displayName?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    anzahlPersonen?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldGerichtlichSiebenPunktFixierung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface FemAntragDataJsonldSimpleformJsonFormEinrichtungRead
 */
export interface FemAntragDataJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragEinrichtungRead
 */
export interface FemAntragEinrichtungRead {
    /**
     *
     * @type {FemAntragEinrichtungReadData}
     * @memberof FemAntragEinrichtungRead
     */
    data?: FemAntragEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof FemAntragEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface FemAntragEinrichtungReadAdresseRead
 */
export interface FemAntragEinrichtungReadAdresseRead {
    /**
     *
     * @type {FemAntragEinrichtungReadAdresseReadData}
     * @memberof FemAntragEinrichtungReadAdresseRead
     */
    data?: FemAntragEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof FemAntragEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface FemAntragEinrichtungReadAdresseReadData
 */
export interface FemAntragEinrichtungReadAdresseReadData {}
/**
 *
 * @export
 * @interface FemAntragEinrichtungReadData
 */
export interface FemAntragEinrichtungReadData {}
/**
 *
 * @export
 * @interface FemAntragEmailListEmailReadEmail
 */
export interface FemAntragEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof FemAntragEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FemAntragEmailReadEmailReadEmail
 */
export interface FemAntragEmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof FemAntragEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FemAntragFemAntragJsonAntragJsonForm
 */
export interface FemAntragFemAntragJsonAntragJsonForm {
    /**
     *
     * @type {FemAntragAntragJsonForm}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    antrag?: FemAntragAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragFemAntragJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface FemAntragFemAntragJsonJsonldAntragJsonForm
 */
export interface FemAntragFemAntragJsonJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {FemAntragJsonldAntragJsonForm}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    antrag?: FemAntragJsonldAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragFemAntragJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface FemAntragFemCollectionOutputFemantragList
 */
export interface FemAntragFemCollectionOutputFemantragList {
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    id?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    einrichtungId?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    status?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    jahr?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    quartal?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    startDesQuartals?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    abgabeAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    zuletztAufgefordertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    allowedWorkflowActions?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragFemCollectionOutputFemantragList
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface FemAntragFemCollectionOutputJsonldFemantragList
 */
export interface FemAntragFemCollectionOutputJsonldFemantragList {
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    id?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    einrichtungId?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    status?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    jahr?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    quartal?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    startDesQuartals?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    abgabeAb?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    zuletztAufgefordertAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    allowedWorkflowActions?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragFemCollectionOutputJsonldFemantragList
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
 */
export interface FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    id?: number | null;
    /**
     *
     * @type {FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    einrichtungDaten?: FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    jahr?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    quartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    gerichtlichGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    gerichtlichGesamtanzahlBetroffene?: number | null;
    /**
     *
     * @type {Array<MassnahmeFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    gerichtlichMassnahmen?: Array<MassnahmeFemViewEinrichtungGetAdresseRead> | null;
    /**
     *
     * @type {Array<CustomMassnahmeFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    gerichtlichCustomMassnahmen?: Array<CustomMassnahmeFemViewEinrichtungGetAdresseRead> | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    einwilligungGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    einwilligungGesamtanzahlBetroffene?: number | null;
    /**
     *
     * @type {Array<MassnahmeFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    einwilligungMassnahmen?: Array<MassnahmeFemViewEinrichtungGetAdresseRead> | null;
    /**
     *
     * @type {Array<CustomMassnahmeFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseRead
     */
    einwilligungCustomMassnahmen?: Array<CustomMassnahmeFemViewEinrichtungGetAdresseRead> | null;
}
/**
 *
 * @export
 * @interface FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
 */
export interface FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten {
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    traeger?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    adresse?: EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    deletedBy?: EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    modifiedBy?: EinrichtungDatenOutputFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {boolean}
     * @memberof FemAntragFemDetailsOutputFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    needsUpdate?: boolean | null;
}
/**
 *
 * @export
 * @interface FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
 */
export interface FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    id?: number | null;
    /**
     *
     * @type {FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    einrichtungDaten?: FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    jahr?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    quartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    gerichtlichGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    gerichtlichGesamtanzahlBetroffene?: number | null;
    /**
     *
     * @type {Array<MassnahmeJsonldFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    gerichtlichMassnahmen?: Array<MassnahmeJsonldFemViewEinrichtungGetAdresseRead> | null;
    /**
     *
     * @type {Array<CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    gerichtlichCustomMassnahmen?: Array<CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead> | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    einwilligungGesamtanzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    einwilligungGesamtanzahlBetroffene?: number | null;
    /**
     *
     * @type {Array<MassnahmeJsonldFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    einwilligungMassnahmen?: Array<MassnahmeJsonldFemViewEinrichtungGetAdresseRead> | null;
    /**
     *
     * @type {Array<CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead>}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead
     */
    einwilligungCustomMassnahmen?: Array<CustomMassnahmeJsonldFemViewEinrichtungGetAdresseRead> | null;
}
/**
 *
 * @export
 * @interface FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
 */
export interface FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    landschaftsverband?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    traeger?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    adresse?: EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    datumInbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    community_type?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    community_service_area?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    institutionsKennzeichen?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    shortCareHospital?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    platzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    activated?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    enabled?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    deletedConfirmed?: string | null;
    /**
     *
     * @type {EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    deletedBy?: EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    modifiedBy?: EinrichtungDatenOutputJsonldFemViewEinrichtungGetAdresseReadDeletedBy | null;
    /**
     *
     * @type {boolean}
     * @memberof FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseReadEinrichtungDaten
     */
    needsUpdate?: boolean | null;
}
/**
 *
 * @export
 * @interface FemAntragJsonld
 */
export interface FemAntragJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonld
     */
    id?: number;
    /**
     *
     * @type {FemAntragJsonldData}
     * @memberof FemAntragJsonld
     */
    data?: FemAntragJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonld
     */
    jahr?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonld
     */
    anzahlGerichtlicherGenehmigung?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonld
     */
    anzahlEinwilligung?: number | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonld
     */
    quartal?: number | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    aufforderungen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    reminder?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterfaceJsonld>}
     * @memberof FemAntragJsonld
     */
    versionen?: Array<AntragVersionInterfaceJsonld>;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonld
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface FemAntragJsonldAntragJsonForm
 */
export interface FemAntragJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {FemAntragJsonldAntragJsonFormData}
     * @memberof FemAntragJsonldAntragJsonForm
     */
    data?: FemAntragJsonldAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface FemAntragJsonldAntragJsonFormData
 */
export interface FemAntragJsonldAntragJsonFormData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldAntragJsonFormData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldAntragJsonFormData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldAntragJsonFormData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragJsonldData
 */
export interface FemAntragJsonldData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonldData
     */
    gerichtlichGesamtanzahl?: number;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonldData
     */
    gerichtlichGesamtanzahlBetroffene?: number;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichSiebenPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichFuenfPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichFixierungArme?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichFixierungBeine?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichFixierungBauchgurt?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichZwangsjacke?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichBettgitter?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichPflegedecken?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichTherapietisch?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichGeschlossene?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichEinsperren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichSchliessmechanismen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichArretieren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichFesthalten?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichVerbot?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichGesicherteAufzuege?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichSicherungTransport?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichSedierendeMedikamente?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichSitzhosen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichWegnehmenDerKleidung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    gerichtlichSchutzhandschuhe?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonldData
     */
    einwilligungGesamtanzahl?: number;
    /**
     *
     * @type {number}
     * @memberof FemAntragJsonldData
     */
    einwilligungGesamtanzahlBetroffene?: number;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungSiebenPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungFuenfPunktFixierung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungFixierungArme?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungFixierungBeine?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungFixierungBauchgurt?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungZwangsjacke?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungBettgitter?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungPflegedecken?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungTherapietisch?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungGeschlossene?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungEinsperren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungSchliessmechanismen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungArretieren?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungFesthalten?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungVerbot?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungGesicherteAufzuege?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungSicherungTransport?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungSedierendeMedikamente?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungSitzhosen?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungWegnehmenDerKleidung?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {FemAntragDataJsonldGerichtlichSiebenPunktFixierung}
     * @memberof FemAntragJsonldData
     */
    einwilligungSchutzhandschuhe?: FemAntragDataJsonldGerichtlichSiebenPunktFixierung | null;
    /**
     *
     * @type {Array<CustomMassnahmeJsonld>}
     * @memberof FemAntragJsonldData
     */
    gerichtlichCustomMassnahmen?: Array<CustomMassnahmeJsonld>;
    /**
     *
     * @type {Array<CustomMassnahmeJsonld>}
     * @memberof FemAntragJsonldData
     */
    einwilligungCustomMassnahmen?: Array<CustomMassnahmeJsonld>;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof FemAntragJsonldData
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonldData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof FemAntragJsonldData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface FemAntragJsonldEinrichtungRead
 */
export interface FemAntragJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {FemAntragJsonldEinrichtungReadData}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    data?: FemAntragJsonldEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof FemAntragJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface FemAntragJsonldEinrichtungReadAdresseRead
 */
export interface FemAntragJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {FemAntragJsonldEinrichtungReadAdresseReadData}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    data?: FemAntragJsonldEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof FemAntragJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface FemAntragJsonldEinrichtungReadAdresseReadData
 */
export interface FemAntragJsonldEinrichtungReadAdresseReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldEinrichtungReadAdresseReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadAdresseReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadAdresseReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragJsonldEinrichtungReadData
 */
export interface FemAntragJsonldEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragJsonldEmailListEmailReadEmail
 */
export interface FemAntragJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldEmailListEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FemAntragJsonldEmailReadEmailReadEmail
 */
export interface FemAntragJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface FemAntragJsonldSimpleformJsonFormEinrichtungRead
 */
export interface FemAntragJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {FemAntragJsonldSimpleformJsonFormEinrichtungReadData}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    data?: FemAntragJsonldSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface FemAntragJsonldSimpleformJsonFormEinrichtungReadData
 */
export interface FemAntragJsonldSimpleformJsonFormEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface FemAntragSimpleformJsonFormEinrichtungRead
 */
export interface FemAntragSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {FemAntragSimpleformJsonFormEinrichtungReadData}
     * @memberof FemAntragSimpleformJsonFormEinrichtungRead
     */
    data?: FemAntragSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof FemAntragSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemAntragSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof FemAntragSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface FemAntragSimpleformJsonFormEinrichtungReadData
 */
export interface FemAntragSimpleformJsonFormEinrichtungReadData {}
/**
 *
 * @export
 * @interface FemExportExportRequestExportRequest
 */
export interface FemExportExportRequestExportRequest {
    /**
     *
     * @type {number}
     * @memberof FemExportExportRequestExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestExportRequestGeneriertesDokument}
     * @memberof FemExportExportRequestExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    status?: FemExportExportRequestExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestExportRequest
     */
    resource?: string;
}

export const FemExportExportRequestExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type FemExportExportRequestExportRequestStatusEnum =
    (typeof FemExportExportRequestExportRequestStatusEnum)[keyof typeof FemExportExportRequestExportRequestStatusEnum];

/**
 *
 * @export
 * @interface FemExportExportRequestJsonldExportRequest
 */
export interface FemExportExportRequestJsonldExportRequest {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestJsonldExportRequestGeneriertesDokument}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestJsonldExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    status?: FemExportExportRequestJsonldExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof FemExportExportRequestJsonldExportRequest
     */
    resource?: string;
}

export const FemExportExportRequestJsonldExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type FemExportExportRequestJsonldExportRequestStatusEnum =
    (typeof FemExportExportRequestJsonldExportRequestStatusEnum)[keyof typeof FemExportExportRequestJsonldExportRequestStatusEnum];

/**
 *
 * @export
 * @interface FemExportFemExportJsonForm
 */
export interface FemExportFemExportJsonForm {
    /**
     *
     * @type {FemFilter}
     * @memberof FemExportFemExportJsonForm
     */
    data?: FemFilter;
    /**
     *
     * @type {JsonSchema}
     * @memberof FemExportFemExportJsonForm
     */
    schema?: JsonSchema;
}
/**
 *
 * @export
 * @interface FemExportFemFilterFemexportWrite
 */
export interface FemExportFemFilterFemexportWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterFemexportWrite
     */
    bezirksregierung?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterFemexportWrite
     */
    behoerde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterFemexportWrite
     */
    gemeinde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterFemexportWrite
     */
    arten?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FemExportFemFilterFemexportWrite
     */
    jahr?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemExportFemFilterFemexportWrite
     */
    quartal?: string | null;
}
/**
 *
 * @export
 * @interface FemExportFemFilterJsonldFemexportWrite
 */
export interface FemExportFemFilterJsonldFemexportWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterJsonldFemexportWrite
     */
    bezirksregierung?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterJsonldFemexportWrite
     */
    behoerde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterJsonldFemexportWrite
     */
    gemeinde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemExportFemFilterJsonldFemexportWrite
     */
    arten?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FemExportFemFilterJsonldFemexportWrite
     */
    jahr?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemExportFemFilterJsonldFemexportWrite
     */
    quartal?: string | null;
}
/**
 *
 * @export
 * @interface FemFilter
 */
export interface FemFilter {
    /**
     *
     * @type {Array<string>}
     * @memberof FemFilter
     */
    bezirksregierung?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemFilter
     */
    behoerde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemFilter
     */
    gemeinde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FemFilter
     */
    arten?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FemFilter
     */
    jahr?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemFilter
     */
    quartal?: string | null;
}
/**
 *
 * @export
 * @interface FemmelderReminder
 */
export interface FemmelderReminder {
    /**
     *
     * @type {number}
     * @memberof FemmelderReminder
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    nachricht?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminder
     */
    sentAt?: string | null;
}
/**
 *
 * @export
 * @interface FemmelderReminderJsonld
 */
export interface FemmelderReminderJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FemmelderReminderJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof FemmelderReminderJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    femAntrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    nachricht?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof FemmelderReminderJsonld
     */
    sentAt?: string | null;
}
/**
 *
 * @export
 * @interface FormFieldDiffAntragFormDiff
 */
export interface FormFieldDiffAntragFormDiff {
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffAntragFormDiff
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormFieldDiffAntragFormDiff
     */
    blockPrefixes?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffAntragFormDiff
     */
    label?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FormFieldDiffAntragFormDiff
     */
    choices?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffAntragFormDiff
     */
    v1?: string;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffAntragFormDiff
     */
    v2?: string;
}
/**
 *
 * @export
 * @interface FormFieldDiffJsonldAntragFormDiff
 */
export interface FormFieldDiffJsonldAntragFormDiff {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    blockPrefixes?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    label?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    choices?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    v1?: string;
    /**
     *
     * @type {string}
     * @memberof FormFieldDiffJsonldAntragFormDiff
     */
    v2?: string;
}
/**
 *
 * @export
 * @interface GeloeschtesObjekt
 */
export interface GeloeschtesObjekt {
    /**
     *
     * @type {number}
     * @memberof GeloeschtesObjekt
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjekt
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjekt
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjekt
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjekt
     */
    erstinbetriebnahme?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjekt
     */
    deletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjekt
     */
    deletedBy?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof GeloeschtesObjekt
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof GeloeschtesObjekt
     */
    allowedActions?: Array<string>;
}
/**
 *
 * @export
 * @interface GeloeschtesObjektJsonld
 */
export interface GeloeschtesObjektJsonld {
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeloeschtesObjektJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    erstinbetriebnahme?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    deletedAt?: string;
    /**
     *
     * @type {string}
     * @memberof GeloeschtesObjektJsonld
     */
    deletedBy?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof GeloeschtesObjektJsonld
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof GeloeschtesObjektJsonld
     */
    allowedActions?: Array<string>;
}
/**
 *
 * @export
 * @interface GeneriertesDokument
 */
export interface GeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof GeneriertesDokument
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequest>}
     * @memberof GeneriertesDokument
     */
    exportRequests?: Array<ExportRequest>;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokument
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentEinrichtungRead
 */
export interface GeneriertesDokumentEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentEinrichtungReadAdresseRead
 */
export interface GeneriertesDokumentEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentEinrichtungReadAdresseRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentExportRequest
 */
export interface GeneriertesDokumentExportRequest {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentExportRequest
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentExportRequest
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentExportRequest
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentExportRequest
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentExportRequest
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentExportRequest
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonld
 */
export interface GeneriertesDokumentJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof GeneriertesDokumentJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof GeneriertesDokumentJsonld
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonld
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequestJsonld>}
     * @memberof GeneriertesDokumentJsonld
     */
    exportRequests?: Array<ExportRequestJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentJsonld
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldEinrichtungRead
 */
export interface GeneriertesDokumentJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldEinrichtungReadAdresseRead
 */
export interface GeneriertesDokumentJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldExportRequest
 */
export interface GeneriertesDokumentJsonldExportRequest {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentJsonldExportRequest
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
 */
export interface GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface GeneriertesDokumentSimpleformJsonFormEinrichtungRead
 */
export interface GeneriertesDokumentSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof GeneriertesDokumentSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface GetCollectionMeldung200Response
 */
export interface GetCollectionMeldung200Response {
    /**
     *
     * @type {Array<MeldungMeldungCollectionOutputJsonldMeldungList>}
     * @memberof GetCollectionMeldung200Response
     */
    'hydra:member': Array<MeldungMeldungCollectionOutputJsonldMeldungList>;
    /**
     *
     * @type {number}
     * @memberof GetCollectionMeldung200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof GetCollectionMeldung200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof GetCollectionMeldung200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface GetEinrichtungenForFem200Response
 */
export interface GetEinrichtungenForFem200Response {
    /**
     *
     * @type {Array<EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet>}
     * @memberof GetEinrichtungenForFem200Response
     */
    'hydra:member': Array<EinrichtungEinrichtungenFuerFemCollectionOutputJsonldEinrichtungCollectionGet>;
    /**
     *
     * @type {number}
     * @memberof GetEinrichtungenForFem200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof GetEinrichtungenForFem200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof GetEinrichtungenForFem200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface GetEinrichtungenForMeldung200Response
 */
export interface GetEinrichtungenForMeldung200Response {
    /**
     *
     * @type {Array<EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet>}
     * @memberof GetEinrichtungenForMeldung200Response
     */
    'hydra:member': Array<EinrichtungEinrichtungenFuerMeldungCollectionOutputJsonldEinrichtungCollectionGet>;
    /**
     *
     * @type {number}
     * @memberof GetEinrichtungenForMeldung200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof GetEinrichtungenForMeldung200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof GetEinrichtungenForMeldung200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface GetEinrichtungenForPlatzzahlmelder200Response
 */
export interface GetEinrichtungenForPlatzzahlmelder200Response {
    /**
     *
     * @type {Array<EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof GetEinrichtungenForPlatzzahlmelder200Response
     */
    'hydra:member': Array<EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {number}
     * @memberof GetEinrichtungenForPlatzzahlmelder200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof GetEinrichtungenForPlatzzahlmelder200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof GetEinrichtungenForPlatzzahlmelder200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface GetEinrichtungenForRegelpruefung200Response
 */
export interface GetEinrichtungenForRegelpruefung200Response {
    /**
     *
     * @type {Array<EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet>}
     * @memberof GetEinrichtungenForRegelpruefung200Response
     */
    'hydra:member': Array<EinrichtungRegelpruefungCollectionOutputJsonldEinrichtungCollectionGet>;
    /**
     *
     * @type {number}
     * @memberof GetEinrichtungenForRegelpruefung200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof GetEinrichtungenForRegelpruefung200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof GetEinrichtungenForRegelpruefung200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface GetMeldungAufgabe200Response
 */
export interface GetMeldungAufgabe200Response {
    /**
     *
     * @type {Array<MeldungJsonldMeldungList>}
     * @memberof GetMeldungAufgabe200Response
     */
    'hydra:member': Array<MeldungJsonldMeldungList>;
    /**
     *
     * @type {number}
     * @memberof GetMeldungAufgabe200Response
     */
    'hydra:totalItems'?: number;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraView}
     * @memberof GetMeldungAufgabe200Response
     */
    'hydra:view'?: ApiAdresselookupGetCollection200ResponseHydraView;
    /**
     *
     * @type {ApiAdresselookupGetCollection200ResponseHydraSearch}
     * @memberof GetMeldungAufgabe200Response
     */
    'hydra:search'?: ApiAdresselookupGetCollection200ResponseHydraSearch;
}
/**
 *
 * @export
 * @interface HeimfinderArrayObject
 */
export interface HeimfinderArrayObject {
    /**
     *
     * @type {any}
     * @memberof HeimfinderArrayObject
     */
    arrayCopy?: any;
    /**
     *
     * @type {number}
     * @memberof HeimfinderArrayObject
     */
    flags?: number;
    /**
     *
     * @type {any}
     * @memberof HeimfinderArrayObject
     */
    iterator?: any;
    /**
     *
     * @type {string}
     * @memberof HeimfinderArrayObject
     */
    iteratorClass?: string;
}
/**
 *
 * @export
 * @interface HeimfinderGet
 */
export interface HeimfinderGet {
    /**
     *
     * @type {number}
     * @memberof HeimfinderGet
     */
    id?: number;
    /**
     *
     * @type {EinrichtungItemGet}
     * @memberof HeimfinderGet
     */
    einrichtung?: EinrichtungItemGet;
    /**
     *
     * @type {AdresseItemGet}
     * @memberof HeimfinderGet
     */
    adresse?: AdresseItemGet;
    /**
     *
     * @type {LocationItemGet}
     * @memberof HeimfinderGet
     */
    location?: LocationItemGet;
    /**
     *
     * @type {LocationItemGet}
     * @memberof HeimfinderGet
     */
    geo_location_epsg_4326?: LocationItemGet;
    /**
     *
     * @type {LocationItemGet}
     * @memberof HeimfinderGet
     */
    geo_location_epsg_25832?: LocationItemGet;
    /**
     *
     * @type {PlaetzeItemGet}
     * @memberof HeimfinderGet
     */
    plaetze?: PlaetzeItemGet;
}
/**
 *
 * @export
 * @interface HeimfinderUploadHeimfinderRead
 */
export interface HeimfinderUploadHeimfinderRead {
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof HeimfinderUploadHeimfinderRead
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface HeimfinderUploadJsonldHeimfinderRead
 */
export interface HeimfinderUploadJsonldHeimfinderRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof HeimfinderUploadJsonldHeimfinderRead
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface HeimfinderUploadJsonldUploadRead
 */
export interface HeimfinderUploadJsonldUploadRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadJsonldUploadRead
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface HeimfinderUploadUploadRead
 */
export interface HeimfinderUploadUploadRead {
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadUploadRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadUploadRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadUploadRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadUploadRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderUploadUploadRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadUploadRead
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof HeimfinderUploadUploadRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof HeimfinderUploadUploadRead
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface HeimfinderprofilCollectionOutputHeimfinderList
 */
export interface HeimfinderprofilCollectionOutputHeimfinderList {
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    adresse?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    art?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof HeimfinderprofilCollectionOutputHeimfinderList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface HeimfinderprofilCollectionOutputJsonldHeimfinderList
 */
export interface HeimfinderprofilCollectionOutputJsonldHeimfinderList {
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    adresse?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    art?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof HeimfinderprofilCollectionOutputJsonldHeimfinderList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface HeimfinderprofilHeimfinderRead
 */
export interface HeimfinderprofilHeimfinderRead {
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    homepage?: string;
    /**
     *
     * @type {HeimfinderprofilHeimfinderReadUpload}
     * @memberof HeimfinderprofilHeimfinderRead
     */
    upload?: HeimfinderprofilHeimfinderReadUpload | null;
}
/**
 *
 * @export
 * @interface HeimfinderprofilHeimfinderReadUpload
 */
export interface HeimfinderprofilHeimfinderReadUpload {
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof HeimfinderprofilHeimfinderReadUpload
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface HeimfinderprofilHeimfinderWrite
 */
export interface HeimfinderprofilHeimfinderWrite {
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    homepage?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilHeimfinderWrite
     */
    upload?: string | null;
}
/**
 *
 * @export
 * @interface HeimfinderprofilJsonldHeimfinderRead
 */
export interface HeimfinderprofilJsonldHeimfinderRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    homepage?: string;
    /**
     *
     * @type {HeimfinderprofilJsonldHeimfinderReadUpload}
     * @memberof HeimfinderprofilJsonldHeimfinderRead
     */
    upload?: HeimfinderprofilJsonldHeimfinderReadUpload | null;
}
/**
 *
 * @export
 * @interface HeimfinderprofilJsonldHeimfinderReadUpload
 */
export interface HeimfinderprofilJsonldHeimfinderReadUpload {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof HeimfinderprofilJsonldHeimfinderReadUpload
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface InvestRegistration
 */
export interface InvestRegistration {
    /**
     *
     * @type {number}
     * @memberof InvestRegistration
     */
    id?: number;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistration
     */
    pflegewohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistration
     */
    meldung_p29?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistration
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistration
     */
    firstname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistration
     */
    lastname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistration
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistration
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof InvestRegistration
     */
    transfered?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistration
     */
    meldungP29?: boolean | null;
}
/**
 *
 * @export
 * @interface InvestRegistrationInvestRegistrationRegister
 */
export interface InvestRegistrationInvestRegistrationRegister {
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    pflegewohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    meldung_p29?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    firstname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    lastname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationInvestRegistrationRegister
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface InvestRegistrationJsonld
 */
export interface InvestRegistrationJsonld {
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof InvestRegistrationJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {number}
     * @memberof InvestRegistrationJsonld
     */
    id?: number;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationJsonld
     */
    pflegewohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationJsonld
     */
    meldung_p29?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    firstname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    lastname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonld
     */
    transfered?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationJsonld
     */
    meldungP29?: boolean | null;
}
/**
 *
 * @export
 * @interface InvestRegistrationJsonldInvestRegistrationRegister
 */
export interface InvestRegistrationJsonldInvestRegistrationRegister {
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    pflegewohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    meldung_p29?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    firstname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    lastname?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    mandant?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvestRegistrationJsonldInvestRegistrationRegister
     */
    einrichtung?: string;
}
/**
 *
 * @export
 * @interface JsonCustomSchema
 */
export interface JsonCustomSchema {
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchema
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchema
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchema
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchema
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchema
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchema
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchema
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchema
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchema
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonCustomSchema
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchema
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchema
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchema
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchema
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchema
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchema
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchema
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchema
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchema
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonCustomSchemaAntragJsonForm
 */
export interface JsonCustomSchemaAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaAntragJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonCustomSchemaJsonldAntragJsonForm
 */
export interface JsonCustomSchemaJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldAntragJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonCustomSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonFormStepAntragJsonForm
 */
export interface JsonFormStepAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    headline?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepAntragJsonForm
     */
    state?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonFormStepAntragJsonForm
     */
    actions?: Array<string>;
    /**
     *
     * @type {Array<JsonFormSubStepAntragJsonForm>}
     * @memberof JsonFormStepAntragJsonForm
     */
    children?: Array<JsonFormSubStepAntragJsonForm>;
    /**
     *
     * @type {boolean}
     * @memberof JsonFormStepAntragJsonForm
     */
    hasErrors?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof JsonFormStepAntragJsonForm
     */
    disabled?: boolean;
}
/**
 *
 * @export
 * @interface JsonFormStepJsonldAntragJsonForm
 */
export interface JsonFormStepJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    headline?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    state?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    actions?: Array<string>;
    /**
     *
     * @type {Array<JsonFormSubStepJsonldAntragJsonForm>}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    children?: Array<JsonFormSubStepJsonldAntragJsonForm>;
    /**
     *
     * @type {boolean}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    hasErrors?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof JsonFormStepJsonldAntragJsonForm
     */
    disabled?: boolean;
}
/**
 *
 * @export
 * @interface JsonFormSubStepAntragJsonForm
 */
export interface JsonFormSubStepAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepAntragJsonForm
     */
    state?: string;
}
/**
 *
 * @export
 * @interface JsonFormSubStepJsonldAntragJsonForm
 */
export interface JsonFormSubStepJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof JsonFormSubStepJsonldAntragJsonForm
     */
    state?: string;
}
/**
 *
 * @export
 * @interface JsonSchema
 */
export interface JsonSchema {
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchema
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    additionalItems?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    items?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchema
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchema
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchema
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    additionalProperties?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    definitions?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    properties?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    patternProperties?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    dependencies?: Array<JsonSchema>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchema
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchema
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchema
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    allOf?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    anyOf?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchema
     */
    oneOf?: Array<JsonSchema>;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    not?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    contains?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    propertyNames?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    if?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    then?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchema
     */
    else?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchema
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaCustom}
     * @memberof JsonSchema
     */
    custom?: JsonSchemaCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaAdditionalItems
 */
export interface JsonSchemaAdditionalItems {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    additionalItems?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    items?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    additionalProperties?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    definitions?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    properties?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    patternProperties?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    dependencies?: Array<JsonSchema>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    allOf?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    anyOf?: Array<JsonSchema>;
    /**
     *
     * @type {Array<JsonSchema>}
     * @memberof JsonSchemaAdditionalItems
     */
    oneOf?: Array<JsonSchema>;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    not?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    contains?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    propertyNames?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    if?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    then?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAdditionalItems}
     * @memberof JsonSchemaAdditionalItems
     */
    else?: JsonSchemaAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaCustom}
     * @memberof JsonSchemaAdditionalItems
     */
    custom?: JsonSchemaCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaAntragJsonForm
 */
export interface JsonSchemaAntragJsonForm {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    additionalItems?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    items?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    additionalProperties?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    definitions?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    properties?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    patternProperties?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    dependencies?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    allOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    anyOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonForm
     */
    oneOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    not?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    contains?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    propertyNames?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    if?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    then?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonForm
     */
    else?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormCustom}
     * @memberof JsonSchemaAntragJsonForm
     */
    custom?: JsonSchemaAntragJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaAntragJsonFormAdditionalItems
 */
export interface JsonSchemaAntragJsonFormAdditionalItems {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    additionalItems?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    items?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    additionalProperties?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    definitions?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    properties?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    patternProperties?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    dependencies?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    allOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    anyOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaAntragJsonForm>}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    oneOf?: Array<JsonSchemaAntragJsonForm>;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    not?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    contains?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    propertyNames?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    if?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    then?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    else?: JsonSchemaAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaAntragJsonFormCustom}
     * @memberof JsonSchemaAntragJsonFormAdditionalItems
     */
    custom?: JsonSchemaAntragJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaAntragJsonFormCustom
 */
export interface JsonSchemaAntragJsonFormCustom {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaAntragJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonSchemaCustom
 */
export interface JsonSchemaCustom {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaCustom
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonSchemaCustom
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonSchemaCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaCustom
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonSchemaCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    pattern?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    additionalItems?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    items?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    required?: Array<string>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    additionalProperties?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    definitions?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    properties?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    patternProperties?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dependencies?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    allOf?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    anyOf?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    oneOf?: Array<JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    not?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    contains?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    propertyNames?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    if?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    then?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    else?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    custom?: JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
 */
export interface JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom {
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldAntragJsonForm
 */
export interface JsonSchemaJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    additionalItems?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    items?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    additionalProperties?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    definitions?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    properties?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    patternProperties?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    dependencies?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    allOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    anyOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    oneOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    not?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    contains?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    propertyNames?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    if?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    then?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    else?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormCustom}
     * @memberof JsonSchemaJsonldAntragJsonForm
     */
    custom?: JsonSchemaJsonldAntragJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldAntragJsonFormAdditionalItems
 */
export interface JsonSchemaJsonldAntragJsonFormAdditionalItems {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    additionalItems?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    items?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    additionalProperties?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    definitions?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    properties?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    patternProperties?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    dependencies?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    allOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    anyOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAntragJsonForm>}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    oneOf?: Array<JsonSchemaJsonldAntragJsonForm>;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    not?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    contains?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    propertyNames?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    if?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    then?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormAdditionalItems}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    else?: JsonSchemaJsonldAntragJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonFormCustom}
     * @memberof JsonSchemaJsonldAntragJsonFormAdditionalItems
     */
    custom?: JsonSchemaJsonldAntragJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldAntragJsonFormCustom
 */
export interface JsonSchemaJsonldAntragJsonFormCustom {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldAntragJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
 */
export interface JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
 */
export interface JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    pattern?: string | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    additionalItems?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    items?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    required?: Array<string>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    additionalProperties?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    definitions?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    properties?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    patternProperties?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    dependencies?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    allOf?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    anyOf?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    oneOf?: Array<JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet>;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    not?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    contains?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    propertyNames?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    if?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    then?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    else?: EinrichtungEinrichtungenFuerPlatzzahlCollectionOutputJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetSchema | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGet
     */
    custom?: JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom | null;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
 */
export interface JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    adressLookupRegionalKey?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    columnLabels?: { [key: string]: string } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    hinweise?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof JsonSchemaJsonldEinrichtungCollectionGetAntragJsonFormBelegbarePlaetzeGetCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldSimpleformJsonForm
 */
export interface JsonSchemaJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldSimpleformJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface JsonSchemaJsonldSimpleformJsonFormEinrichtungRead
 */
export interface JsonSchemaJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof JsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof JsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface Konzeptinformation
 */
export interface Konzeptinformation {
    /**
     *
     * @type {string}
     * @memberof Konzeptinformation
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Konzeptinformation
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Konzeptinformation
     */
    customName?: string | null;
    /**
     *
     * @type {number}
     * @memberof Konzeptinformation
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof Konzeptinformation
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface KonzeptinformationJsonld
 */
export interface KonzeptinformationJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof KonzeptinformationJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonld
     */
    customName?: string | null;
    /**
     *
     * @type {number}
     * @memberof KonzeptinformationJsonld
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface KonzeptinformationJsonldMeldungRead
 */
export interface KonzeptinformationJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    customName?: string | null;
    /**
     *
     * @type {number}
     * @memberof KonzeptinformationJsonldMeldungRead
     */
    plaetze?: number | null;
}
/**
 *
 * @export
 * @interface KonzeptinformationMeldungRead
 */
export interface KonzeptinformationMeldungRead {
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationMeldungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof KonzeptinformationMeldungRead
     */
    customName?: string | null;
    /**
     *
     * @type {number}
     * @memberof KonzeptinformationMeldungRead
     */
    plaetze?: number | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandCollectionOutput
 */
export interface LandschaftsverbandCollectionOutput {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandCollectionOutput
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutput
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandCollectionOutput
     */
    benutzer?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutput
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutput
     */
    email?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandCollectionOutput
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandCollectionOutput
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandCollectionOutputJsonld
 */
export interface LandschaftsverbandCollectionOutputJsonld {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    benutzer?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    email?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandCollectionOutputJsonld
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDto
 */
export interface LandschaftsverbandDto {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDto
     */
    kuerzel?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDto
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDto
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDto
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDto
     */
    fax?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof LandschaftsverbandDto
     */
    adressDaten?: AngezeigteBetreuungFormAdresse | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoJsonld
 */
export interface LandschaftsverbandDtoJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandDtoJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandDtoJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    kuerzel?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonld
     */
    fax?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LandschaftsverbandDtoJsonld
     */
    adressDaten?: AngezeigteBetreuungFormJsonldAdresse | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
 */
export interface LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    kuerzel?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    fax?: string | null;
    /**
     *
     * @type {LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung
     */
    adressDaten?: LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
 */
export interface LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
 */
export interface LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    kuerzel?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    fax?: string | null;
    /**
     *
     * @type {LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtung
     */
    adressDaten?: LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
 */
export interface LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
 */
export interface LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    kuerzel?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    fax?: string | null;
    /**
     *
     * @type {LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
     */
    adressDaten?: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
 */
export interface LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandEinrichtungRead
 */
export interface LandschaftsverbandEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof LandschaftsverbandEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandEinrichtungReadAdresseRead
 */
export interface LandschaftsverbandEinrichtungReadAdresseRead {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungReadAdresseRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof LandschaftsverbandEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldEinrichtungRead
 */
export interface LandschaftsverbandJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof LandschaftsverbandJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldEinrichtungReadAdresseRead
 */
export interface LandschaftsverbandJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof LandschaftsverbandJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
 */
export interface LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
 */
export interface LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof LandschaftsverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
 */
export interface LandschaftsverbandLandschaftsverbandReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
     */
    kuerzel: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LandschaftsverbandLandschaftsverbandReadRolleEinrichtung
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface LandschaftsverbandSimpleformJsonFormEinrichtungRead
 */
export interface LandschaftsverbandSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandSimpleformJsonFormEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LandschaftsverbandSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof LandschaftsverbandSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface Leistungsanbieter
 */
export interface Leistungsanbieter {
    /**
     *
     * @type {number}
     * @memberof Leistungsanbieter
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterData}
     * @memberof Leistungsanbieter
     */
    data?: LeistungsanbieterData | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    leistungsangebot?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    responseMessage?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterface>}
     * @memberof Leistungsanbieter
     */
    versionen?: Array<AntragVersionInterface>;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Leistungsanbieter
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungsanbieter
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterAntragJsonForm
 */
export interface LeistungsanbieterAntragJsonForm {
    /**
     *
     * @type {LeistungsanbieterAntragJsonFormData}
     * @memberof LeistungsanbieterAntragJsonForm
     */
    data?: LeistungsanbieterAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterAntragJsonFormData
 */
export interface LeistungsanbieterAntragJsonFormData {}
/**
 *
 * @export
 * @interface LeistungsanbieterAntragWorkflowActionAntragWorkflow
 */
export interface LeistungsanbieterAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterData
 */
export interface LeistungsanbieterData {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof LeistungsanbieterData
     */
    traegerAdresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof LeistungsanbieterData
     */
    traegerTraegerverband?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof LeistungsanbieterData
     */
    dokumente?: Array<Dokument> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonld
 */
export interface LeistungsanbieterDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsanbieterDataJsonld
     */
    traegerTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof LeistungsanbieterDataJsonld
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterDataJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterDataJsonld
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldAntragJsonForm
 */
export interface LeistungsanbieterDataJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldAntragJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldLeistungsanbieterList
 */
export interface LeistungsanbieterDataJsonldLeistungsanbieterList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerAdresse?: LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    traegerTraegerverband?: LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterList
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse
 */
export interface LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband
 */
export interface LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAdresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTraegerverband?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    dokumente?: Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse
 */
export interface LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband
 */
export interface LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataLeistungsanbieterList
 */
export interface LeistungsanbieterDataLeistungsanbieterList {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsanbieterListTraegerAdresse}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerAdresse?: LeistungsanbieterDataLeistungsanbieterListTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsanbieterListTraegerTraegerverband}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    traegerTraegerverband?: LeistungsanbieterDataLeistungsanbieterListTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsanbieterList
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataLeistungsanbieterListTraegerAdresse
 */
export interface LeistungsanbieterDataLeistungsanbieterListTraegerAdresse {}
/**
 *
 * @export
 * @interface LeistungsanbieterDataLeistungsanbieterListTraegerTraegerverband
 */
export interface LeistungsanbieterDataLeistungsanbieterListTraegerTraegerverband {}
/**
 *
 * @export
 * @interface LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAdresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTraegerverband?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    dokumente?: Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse
 */
export interface LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse {}
/**
 *
 * @export
 * @interface LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband
 */
export interface LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband {}
/**
 *
 * @export
 * @interface LeistungsanbieterInfo
 */
export interface LeistungsanbieterInfo {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    verband?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfo
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterInfoJsonld
 */
export interface LeistungsanbieterInfoJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterInfoJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    verband?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterInfoJsonldMeldungRead
 */
export interface LeistungsanbieterInfoJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoJsonldMeldungRead
     */
    verband?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterInfoMeldungRead
 */
export interface LeistungsanbieterInfoMeldungRead {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterInfoMeldungRead
     */
    verband?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonld
 */
export interface LeistungsanbieterJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterJsonld
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterJsonldData}
     * @memberof LeistungsanbieterJsonld
     */
    data?: LeistungsanbieterJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    leistungsangebot?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    responseMessage?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterfaceJsonld>}
     * @memberof LeistungsanbieterJsonld
     */
    versionen?: Array<AntragVersionInterfaceJsonld>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonld
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldAntragJsonForm
 */
export interface LeistungsanbieterJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldAntragJsonFormData}
     * @memberof LeistungsanbieterJsonldAntragJsonForm
     */
    data?: LeistungsanbieterJsonldAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldAntragJsonFormData
 */
export interface LeistungsanbieterJsonldAntragJsonFormData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldAntragJsonFormData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldAntragJsonFormData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldAntragJsonFormData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldData
 */
export interface LeistungsanbieterJsonldData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsanbieterJsonldData
     */
    traegerTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof LeistungsanbieterJsonldData
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsanbieterGetId
 */
export interface LeistungsanbieterJsonldLeistungsanbieterGetId {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterGetId
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterGetId
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterGetId
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterGetId
     */
    id?: number;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsanbieterList
 */
export interface LeistungsanbieterJsonldLeistungsanbieterList {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsanbieterListData}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    data?: LeistungsanbieterJsonldLeistungsanbieterListData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    leistungsangebot?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    responseMessage?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterList
     */
    uuidMap?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsanbieterListData
 */
export interface LeistungsanbieterJsonldLeistungsanbieterListData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerAdresse?: LeistungsanbieterDataJsonldLeistungsanbieterListTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    traegerTraegerverband?: LeistungsanbieterDataJsonldLeistungsanbieterListTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsanbieterListData
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenList
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    data?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtung?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsangebot?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    behoerde?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde | null;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traeger?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    responseMessage?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAdresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTraegerverband?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    dokumente?: Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    data?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    einrichtung?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    eingereichtBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    wohngemeinschaft?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    leistungsanbieter?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
 */
export interface LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsanbieterGetId
 */
export interface LeistungsanbieterLeistungsanbieterGetId {
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsanbieterGetId
     */
    id?: number;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
 */
export interface LeistungsanbieterLeistungsanbieterJsonAntragJsonForm {
    /**
     *
     * @type {LeistungsanbieterAntragJsonForm}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    antrag?: LeistungsanbieterAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsanbieterJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
 */
export interface LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldAntragJsonForm}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    antrag?: LeistungsanbieterJsonldAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsanbieterList
 */
export interface LeistungsanbieterLeistungsanbieterList {
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterLeistungsanbieterListData}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    data?: LeistungsanbieterLeistungsanbieterListData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    leistungsangebot?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    responseMessage?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsanbieterList
     */
    uuidMap?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsanbieterListData
 */
export interface LeistungsanbieterLeistungsanbieterListData {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsanbieterListTraegerAdresse}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerAdresse?: LeistungsanbieterDataLeistungsanbieterListTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsanbieterListTraegerTraegerverband}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    traegerTraegerverband?: LeistungsanbieterDataLeistungsanbieterListTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsanbieterListData
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenList
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenList {
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenList
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenList
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    data?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtung?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsangebot?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    behoerde?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde | null;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traeger?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    responseMessage?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAdresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTraegerverband?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    dokumente?: Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot {
    /**
     *
     * @type {number}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    data?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    einrichtung?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    eingereichtBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    wohngemeinschaft?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    leistungsanbieter?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
 */
export interface LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger {
    /**
     *
     * @type {string}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrieren
 */
export interface LeistungsangebotRegistrieren {
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrieren
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenData}
     * @memberof LeistungsangebotRegistrieren
     */
    data?: LeistungsangebotRegistrierenData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    wohngemeinschaft?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    leistungsanbieter?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterface>}
     * @memberof LeistungsangebotRegistrieren
     */
    versionen?: Array<AntragVersionInterface>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrieren
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    shortClassName?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrieren
     */
    einrichtungVersorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenAntragJsonForm
 */
export interface LeistungsangebotRegistrierenAntragJsonForm {
    /**
     *
     * @type {LeistungsangebotRegistrierenAntragJsonFormData}
     * @memberof LeistungsangebotRegistrierenAntragJsonForm
     */
    data?: LeistungsangebotRegistrierenAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenAntragJsonFormData
 */
export interface LeistungsangebotRegistrierenAntragJsonFormData {}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow
 */
export interface LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenData
 */
export interface LeistungsangebotRegistrierenData {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungAdresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof LeistungsangebotRegistrierenData
     */
    einrichtungTraegerverband?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof LeistungsangebotRegistrierenData
     */
    unternehmensgruppe?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenData
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof LeistungsangebotRegistrierenData
     */
    traeger?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerAdresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof LeistungsangebotRegistrierenData
     */
    traegerTraegerverband?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenData
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenData
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenData
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<Wohngemeinschaft>}
     * @memberof LeistungsangebotRegistrierenData
     */
    wohngemeinschaften?: Array<Wohngemeinschaft> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof LeistungsangebotRegistrierenData
     */
    dokumente?: Array<Dokument> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonld
 */
export interface LeistungsangebotRegistrierenDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    einrichtungTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    unternehmensgruppe?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traeger?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    traegerTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonld>}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenDataJsonld
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldAntragJsonForm
 */
export interface LeistungsangebotRegistrierenDataJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldAntragJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldEinrichtungRead
 */
export interface LeistungsangebotRegistrierenDataJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldEinrichtungReadAdresseRead
 */
export interface LeistungsangebotRegistrierenDataJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
 */
export interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungAdresse?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    einrichtungTraegerverband?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    unternehmensgruppe?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traeger?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerAdresse?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    traegerTraegerverband?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenList>}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenList> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenList
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse
 */
export interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband
 */
export interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungAdresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungTraegerverband?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    unternehmensgruppe?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traeger?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAdresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTraegerverband?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    dokumente?: Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataJsonldSimpleformJsonFormEinrichtungRead
 */
export interface LeistungsangebotRegistrierenDataJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
 */
export interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungAdresse?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    einrichtungTraegerverband?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    unternehmensgruppe?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traeger?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerAdresse?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    traegerTraegerverband?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    wohngemeinschaften?: Array<object> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenList
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse
 */
export interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse {}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband
 */
export interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband {
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungAdresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungTraegerverband?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    unternehmensgruppe?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traeger?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAdresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    traegerTraegerverband?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    dokumente?: Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenEinrichtungRead
 */
export interface LeistungsangebotRegistrierenEinrichtungRead {
    /**
     *
     * @type {LeistungsangebotRegistrierenEinrichtungReadData}
     * @memberof LeistungsangebotRegistrierenEinrichtungRead
     */
    data?: LeistungsangebotRegistrierenEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenEinrichtungReadAdresseRead
 */
export interface LeistungsangebotRegistrierenEinrichtungReadAdresseRead {
    /**
     *
     * @type {LeistungsangebotRegistrierenEinrichtungReadAdresseReadData}
     * @memberof LeistungsangebotRegistrierenEinrichtungReadAdresseRead
     */
    data?: LeistungsangebotRegistrierenEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenEinrichtungReadAdresseReadData
 */
export interface LeistungsangebotRegistrierenEinrichtungReadAdresseReadData {}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenEinrichtungReadData
 */
export interface LeistungsangebotRegistrierenEinrichtungReadData {}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonld
 */
export interface LeistungsangebotRegistrierenJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldData}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    data?: LeistungsangebotRegistrierenJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    wohngemeinschaft?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    leistungsanbieter?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<AntragVersionInterfaceJsonld>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    versionen?: Array<AntragVersionInterfaceJsonld>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldAntragJsonForm
 */
export interface LeistungsangebotRegistrierenJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldAntragJsonFormData}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonForm
     */
    data?: LeistungsangebotRegistrierenJsonldAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldAntragJsonFormData
 */
export interface LeistungsangebotRegistrierenJsonldAntragJsonFormData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonFormData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonFormData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldAntragJsonFormData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldData
 */
export interface LeistungsangebotRegistrierenJsonldData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    einrichtungTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    unternehmensgruppe?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traeger?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    traegerTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonld>}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldEinrichtungRead
 */
export interface LeistungsangebotRegistrierenJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldEinrichtungReadData}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    data?: LeistungsangebotRegistrierenJsonldEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
 */
export interface LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    data?: LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData
 */
export interface LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadAdresseReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldEinrichtungReadData
 */
export interface LeistungsangebotRegistrierenJsonldEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    data?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    status?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    einrichtung?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    wohngemeinschaft?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    leistungsanbieter?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenList
     */
    uuidMap?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungAdresse?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    einrichtungTraegerverband?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    unternehmensgruppe?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traeger?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerAdresse?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    traegerTraegerverband?: LeistungsangebotRegistrierenDataJsonldLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenList>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenList> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListData
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListEinrichtung
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListLeistungsanbieter
     */
    modifiedBy?: BenutzerJsonldLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenListWohngemeinschaft
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    data?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtung?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    wohngemeinschaft?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsanbieter?: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungAdresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungTraegerverband?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    unternehmensgruppe?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traeger?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAdresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTraegerverband?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    dokumente?: Array<DokumentJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    data?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    einrichtung?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    leistungsangebot?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    eingereichtBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    behoerde?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde | null;
    /**
     *
     * @type {LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    traeger?: LeistungsanbieterJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    responseMessage?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    startDate?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    adresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite
 */
export interface LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite
     */
    einrichtung?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
 */
export interface LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    data?: LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData
 */
export interface LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm {
    /**
     *
     * @type {LeistungsangebotRegistrierenAntragJsonForm}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    antrag?: LeistungsangebotRegistrierenAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenJsonldAntragJsonForm}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    antrag?: LeistungsangebotRegistrierenJsonldAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenList {
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    data?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    status?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenListEinrichtung}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    einrichtung?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenListEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenListWohngemeinschaft}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    wohngemeinschaft?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenListWohngemeinschaft | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    leistungsanbieter?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenList
     */
    uuidMap?: Array<string>;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungAdresse?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    einrichtungTraegerverband?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    unternehmensgruppe?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traeger?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerAdresse?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    traegerTraegerverband?: LeistungsangebotRegistrierenDataLeistungsangebotRegistrierenListEinrichtungTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    wohngemeinschaften?: Array<object> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListData
     */
    notiz?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListEinrichtung
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListEinrichtung {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListEinrichtung
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter {
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerLeistungsangebotRegistrierenListModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenListLeistungsanbieter
     */
    modifiedBy?: BenutzerLeistungsangebotRegistrierenListModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListWohngemeinschaft
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenListWohngemeinschaft {}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    id?: number;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    data?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtung?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    eingereichtBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsanbieterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsanbieterName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    wohngemeinschaft?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft | null;
    /**
     *
     * @type {LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    leistungsanbieter?: LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungNrwkey?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungAdresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungKurzzeitpflegeImKrankenhaus?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungPlatzzahlmeldepflicht?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    einrichtungTraegerverband?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    unternehmensgruppe?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    tragerExistiertBereits?: boolean | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traeger?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAdresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachPlz?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerPostfachOrt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    traegerTraegerverband?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerTraegerverband | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    teilnahmePfadInvest?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    pflegeWohngeld?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    angabenUeberInvest?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investVorname?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    investMandant?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    wohngemeinschaften?: Array<WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    mailText?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadData
     */
    dokumente?: Array<DokumentLeistungsangebotRegistrierenReadLeistungsanbieterRead> | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter {
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    id?: number;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    data?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    status?: string;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    einrichtung?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadEinrichtung | null;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    leistungsangebot?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsangebot | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    eingereichtBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    uuid?: string;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    behoerde?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadBehoerde | null;
    /**
     *
     * @type {LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    traeger?: LeistungsanbieterLeistungsangebotRegistrierenReadLeistungsanbieterReadTraeger | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    responseMessage?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadLeistungsanbieter
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    startDate?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenReadLeistungsanbieterReadWohngemeinschaft
     */
    adresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenWrite
 */
export interface LeistungsangebotRegistrierenLeistungsangebotRegistrierenWrite {
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenLeistungsangebotRegistrierenWrite
     */
    einrichtung?: string | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead
 */
export interface LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungReadData}
     * @memberof LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead
     */
    data?: LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungReadData
 */
export interface LeistungsangebotRegistrierenSimpleformJsonFormEinrichtungReadData {}
/**
 *
 * @export
 * @interface Leistungserbringer
 */
export interface Leistungserbringer {
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    name?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof Leistungserbringer
     */
    adresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    mail?: string | null;
    /**
     *
     * @type {string}
     * @memberof Leistungserbringer
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LeistungserbringerJsonld
 */
export interface LeistungserbringerJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungserbringerJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    name?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof LeistungserbringerJsonld
     */
    adresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    mail?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface LeistungserbringerJsonldMeldungRead
 */
export interface LeistungserbringerJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    name?: string | null;
    /**
     *
     * @type {LeistungserbringerJsonldMeldungReadAdresse}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    adresse?: LeistungserbringerJsonldMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungRead
     */
    mail?: string | null;
}
/**
 *
 * @export
 * @interface LeistungserbringerJsonldMeldungReadAdresse
 */
export interface LeistungserbringerJsonldMeldungReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof LeistungserbringerJsonldMeldungReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerJsonldMeldungReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface LeistungserbringerMeldungRead
 */
export interface LeistungserbringerMeldungRead {
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungRead
     */
    name?: string | null;
    /**
     *
     * @type {LeistungserbringerMeldungReadAdresse}
     * @memberof LeistungserbringerMeldungRead
     */
    adresse?: LeistungserbringerMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungRead
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungRead
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungRead
     */
    mail?: string | null;
}
/**
 *
 * @export
 * @interface LeistungserbringerMeldungReadAdresse
 */
export interface LeistungserbringerMeldungReadAdresse {
    /**
     *
     * @type {string}
     * @memberof LeistungserbringerMeldungReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface LocationItemGet
 */
export interface LocationItemGet {
    /**
     *
     * @type {string}
     * @memberof LocationItemGet
     */
    lat?: string;
    /**
     *
     * @type {string}
     * @memberof LocationItemGet
     */
    lon?: string;
}
/**
 *
 * @export
 * @interface LoginCheckPost200Response
 */
export interface LoginCheckPost200Response {
    /**
     *
     * @type {string}
     * @memberof LoginCheckPost200Response
     */
    token: string;
}
/**
 *
 * @export
 * @interface LoginCheckPostRequest
 */
export interface LoginCheckPostRequest {
    /**
     *
     * @type {string}
     * @memberof LoginCheckPostRequest
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof LoginCheckPostRequest
     */
    password: string;
}
/**
 *
 * @export
 * @interface Mantelbogen
 */
export interface Mantelbogen {
    /**
     *
     * @type {number}
     * @memberof Mantelbogen
     */
    id?: number;
    /**
     *
     * @type {EinrichtungDetailsOutputMantelbogen}
     * @memberof Mantelbogen
     */
    generiertesDokument?: EinrichtungDetailsOutputMantelbogen | null;
    /**
     *
     * @type {string}
     * @memberof Mantelbogen
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof Mantelbogen
     */
    einrichtungVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof Mantelbogen
     */
    traegerVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof Mantelbogen
     */
    wohngemeinschaft?: string | null;
}
/**
 *
 * @export
 * @interface MantelbogenEinrichtungRead
 */
export interface MantelbogenEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof MantelbogenEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {MantelbogenEinrichtungReadGeneriertesDokument}
     * @memberof MantelbogenEinrichtungRead
     */
    generiertesDokument?: MantelbogenEinrichtungReadGeneriertesDokument | null;
}
/**
 *
 * @export
 * @interface MantelbogenEinrichtungReadAdresseRead
 */
export interface MantelbogenEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof MantelbogenEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {MantelbogenEinrichtungReadAdresseReadGeneriertesDokument}
     * @memberof MantelbogenEinrichtungReadAdresseRead
     */
    generiertesDokument?: MantelbogenEinrichtungReadAdresseReadGeneriertesDokument | null;
}
/**
 *
 * @export
 * @interface MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
 */
export interface MantelbogenEinrichtungReadAdresseReadGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadAdresseReadGeneriertesDokument
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MantelbogenEinrichtungReadGeneriertesDokument
 */
export interface MantelbogenEinrichtungReadGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof MantelbogenEinrichtungReadGeneriertesDokument
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MantelbogenJsonld
 */
export interface MantelbogenJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonld
     */
    id?: number;
    /**
     *
     * @type {EinrichtungDetailsOutputJsonldMantelbogen}
     * @memberof MantelbogenJsonld
     */
    generiertesDokument?: EinrichtungDetailsOutputJsonldMantelbogen | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonld
     */
    einrichtungVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonld
     */
    traegerVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonld
     */
    wohngemeinschaft?: string | null;
}
/**
 *
 * @export
 * @interface MantelbogenJsonldEinrichtungRead
 */
export interface MantelbogenJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {MantelbogenJsonldEinrichtungReadGeneriertesDokument}
     * @memberof MantelbogenJsonldEinrichtungRead
     */
    generiertesDokument?: MantelbogenJsonldEinrichtungReadGeneriertesDokument | null;
}
/**
 *
 * @export
 * @interface MantelbogenJsonldEinrichtungReadAdresseRead
 */
export interface MantelbogenJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseRead
     */
    generiertesDokument?: MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument | null;
}
/**
 *
 * @export
 * @interface MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
 */
export interface MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadAdresseReadGeneriertesDokument
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MantelbogenJsonldEinrichtungReadGeneriertesDokument
 */
export interface MantelbogenJsonldEinrichtungReadGeneriertesDokument {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldEinrichtungReadGeneriertesDokument
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MantelbogenJsonldSimpleformJsonFormEinrichtungRead
 */
export interface MantelbogenJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungRead
     */
    generiertesDokument?: MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument | null;
}
/**
 *
 * @export
 * @interface MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
 */
export interface MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof MantelbogenJsonldSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MantelbogenSimpleformJsonFormEinrichtungRead
 */
export interface MantelbogenSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungRead
     */
    generiertesDokument?: MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument | null;
}
/**
 *
 * @export
 * @interface MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
 */
export interface MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {boolean}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof MantelbogenSimpleformJsonFormEinrichtungReadGeneriertesDokument
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailEmpfaengerJsonldMassenmailRead
 */
export interface MassenmailEmpfaengerJsonldMassenmailRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerJsonldMassenmailRead}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    benutzer?: BenutzerJsonldMassenmailRead;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    gesendetAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerJsonldMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailEmpfaengerMassenmailRead
 */
export interface MassenmailEmpfaengerMassenmailRead {
    /**
     *
     * @type {number}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {BenutzerMassenmailRead}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    benutzer?: BenutzerMassenmailRead;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    gesendetAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailEmpfaengerMassenmailRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailList
 */
export interface MassenmailJsonldMassenmailList {
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailList
     */
    id?: number;
    /**
     *
     * @type {MassenmailJsonldMassenmailListBehoerde}
     * @memberof MassenmailJsonldMassenmailList
     */
    behoerde?: MassenmailJsonldMassenmailListBehoerde | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    betreff: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailList
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailList
     */
    allowedActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailList
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailList
     */
    createdAt?: string;
    /**
     *
     * @type {MassenmailJsonldMassenmailListCreatedBy}
     * @memberof MassenmailJsonldMassenmailList
     */
    createdBy?: MassenmailJsonldMassenmailListCreatedBy | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailList
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailList
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailListBehoerde
 */
export interface MassenmailJsonldMassenmailListBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassenmailJsonldMassenmailListBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListBehoerde
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailListCreatedBy
 */
export interface MassenmailJsonldMassenmailListCreatedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassenmailJsonldMassenmailListCreatedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListCreatedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListCreatedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListCreatedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListCreatedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailListCreatedBy
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailRead
 */
export interface MassenmailJsonldMassenmailRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassenmailJsonldMassenmailRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {MassenmailJsonldMassenmailReadBehoerde}
     * @memberof MassenmailJsonldMassenmailRead
     */
    behoerde?: MassenmailJsonldMassenmailReadBehoerde | null;
    /**
     *
     * @type {Array<MassenmailEmpfaengerJsonldMassenmailRead>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    empfaenger?: Array<MassenmailEmpfaengerJsonldMassenmailRead>;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<UploadJsonldMassenmailRead>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    uploads?: Array<UploadJsonldMassenmailRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    allowedActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailRead
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {MassenmailJsonldMassenmailReadCreatedBy}
     * @memberof MassenmailJsonldMassenmailRead
     */
    createdBy?: MassenmailJsonldMassenmailReadCreatedBy | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailRead
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailJsonldMassenmailRead
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailReadBehoerde
 */
export interface MassenmailJsonldMassenmailReadBehoerde {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassenmailJsonldMassenmailReadBehoerde
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadBehoerde
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadBehoerde
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadBehoerde
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailReadCreatedBy
 */
export interface MassenmailJsonldMassenmailReadCreatedBy {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailReadCreatedBy
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailJsonldMassenmailWrite
 */
export interface MassenmailJsonldMassenmailWrite {
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailJsonldMassenmailWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailList
 */
export interface MassenmailMassenmailList {
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailList
     */
    id?: number;
    /**
     *
     * @type {MassenmailMassenmailListBehoerde}
     * @memberof MassenmailMassenmailList
     */
    behoerde?: MassenmailMassenmailListBehoerde | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailList
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailList
     */
    betreff: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailList
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailList
     */
    allowedActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailList
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailList
     */
    createdAt?: string;
    /**
     *
     * @type {MassenmailMassenmailListCreatedBy}
     * @memberof MassenmailMassenmailList
     */
    createdBy?: MassenmailMassenmailListCreatedBy | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailList
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailList
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailListBehoerde
 */
export interface MassenmailMassenmailListBehoerde {
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailListBehoerde
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailListCreatedBy
 */
export interface MassenmailMassenmailListCreatedBy {
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailListCreatedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailListCreatedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailListCreatedBy
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailRead
 */
export interface MassenmailMassenmailRead {
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {MassenmailMassenmailReadBehoerde}
     * @memberof MassenmailMassenmailRead
     */
    behoerde?: MassenmailMassenmailReadBehoerde | null;
    /**
     *
     * @type {Array<MassenmailEmpfaengerMassenmailRead>}
     * @memberof MassenmailMassenmailRead
     */
    empfaenger?: Array<MassenmailEmpfaengerMassenmailRead>;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailRead
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<UploadMassenmailRead>}
     * @memberof MassenmailMassenmailRead
     */
    uploads?: Array<UploadMassenmailRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailRead
     */
    allowedActions?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailRead
     */
    defaultActionOnRowClick?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {MassenmailMassenmailReadCreatedBy}
     * @memberof MassenmailMassenmailRead
     */
    createdBy?: MassenmailMassenmailReadCreatedBy | null;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailRead
     */
    countEmpfaenger?: number;
    /**
     *
     * @type {number}
     * @memberof MassenmailMassenmailRead
     */
    countEmpfaengerGesendet?: number;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailReadBehoerde
 */
export interface MassenmailMassenmailReadBehoerde {
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailReadBehoerde
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailReadCreatedBy
 */
export interface MassenmailMassenmailReadCreatedBy {
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailReadCreatedBy
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailReadCreatedBy
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailReadCreatedBy
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailReadCreatedBy
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MassenmailMassenmailWrite
 */
export interface MassenmailMassenmailWrite {
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailWrite
     */
    empfaengerGruppe?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailWrite
     */
    betreff: string;
    /**
     *
     * @type {string}
     * @memberof MassenmailMassenmailWrite
     */
    inhalt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailWrite
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MassenmailMassenmailWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface Massnahme
 */
export interface Massnahme {
    /**
     *
     * @type {string}
     * @memberof Massnahme
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Massnahme
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Massnahme
     */
    displayName?: string | null;
    /**
     *
     * @type {number}
     * @memberof Massnahme
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof Massnahme
     */
    anzahlPersonen?: number | null;
    /**
     *
     * @type {string}
     * @memberof Massnahme
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MassnahmeFemViewEinrichtungGetAdresseRead
 */
export interface MassnahmeFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {string}
     * @memberof MassnahmeFemViewEinrichtungGetAdresseRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeFemViewEinrichtungGetAdresseRead
     */
    displayName?: string | null;
    /**
     *
     * @type {number}
     * @memberof MassnahmeFemViewEinrichtungGetAdresseRead
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof MassnahmeFemViewEinrichtungGetAdresseRead
     */
    anzahlPersonen?: number | null;
}
/**
 *
 * @export
 * @interface MassnahmeJsonld
 */
export interface MassnahmeJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassnahmeJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonld
     */
    displayName?: string | null;
    /**
     *
     * @type {number}
     * @memberof MassnahmeJsonld
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof MassnahmeJsonld
     */
    anzahlPersonen?: number | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MassnahmeJsonldFemViewEinrichtungGetAdresseRead
 */
export interface MassnahmeJsonldFemViewEinrichtungGetAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    displayName?: string | null;
    /**
     *
     * @type {number}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    anzahlQuartal?: number | null;
    /**
     *
     * @type {number}
     * @memberof MassnahmeJsonldFemViewEinrichtungGetAdresseRead
     */
    anzahlPersonen?: number | null;
}
/**
 *
 * @export
 * @interface Meldung
 */
export interface Meldung {
    /**
     *
     * @type {number}
     * @memberof Meldung
     */
    id?: number;
    /**
     *
     * @type {MeldungData}
     * @memberof Meldung
     */
    data?: MeldungData | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    einrichtungVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    traegerVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    versionen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<Leistungserbringer>}
     * @memberof Meldung
     */
    leistungserbringer?: Array<Leistungserbringer>;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Meldung
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    shortClassName?: string;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof Meldung
     */
    einrichtungVersorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface MeldungAntragJsonForm
 */
export interface MeldungAntragJsonForm {
    /**
     *
     * @type {MeldungAntragJsonFormData}
     * @memberof MeldungAntragJsonForm
     */
    data?: MeldungAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface MeldungAntragJsonFormData
 */
export interface MeldungAntragJsonFormData {}
/**
 *
 * @export
 * @interface MeldungAntragWorkflowActionAntragWorkflow
 */
export interface MeldungAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof MeldungAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface MeldungData
 */
export interface MeldungData {
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataLeistungsanbieterInfo}
     * @memberof MeldungData
     */
    leistungsanbieterInfo?: MeldungDataLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof MeldungData
     */
    einrichtungAdresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungData
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof MeldungData
     */
    einrichtungTraegerverband?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof MeldungData
     */
    unternehmensgruppe?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof MeldungData
     */
    traeger?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof MeldungData
     */
    traegerAdresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<Wohngemeinschaft>}
     * @memberof MeldungData
     */
    wohngemeinschaften?: Array<Wohngemeinschaft> | null;
    /**
     *
     * @type {Array<Konzeptinformation>}
     * @memberof MeldungData
     */
    konzeptinformationen?: Array<Konzeptinformation> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepKonzeptinformationenHausordnungen?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Standort>}
     * @memberof MeldungData
     */
    stepStandortStandorte?: Array<Standort> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<Einrichtungsleitung>}
     * @memberof MeldungData
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<Einrichtungsleitung> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraft>}
     * @memberof MeldungData
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraft> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Leistungserbringer>}
     * @memberof MeldungData
     */
    stepLeistungserbringerLeistungserbringer?: Array<Leistungserbringer> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepUnterlagenDokumente?: Array<Dokument> | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    stepVertraegeDokumente?: Array<Dokument> | null;
    /**
     *
     * @type {MeldungDataStepBeschaeftigtePflegePersonal}
     * @memberof MeldungData
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungData
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungData
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungData
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    zurueckweisenDokumente?: Array<Dokument> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungData
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungData
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungData
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field401?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field401a?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field401b?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field401c?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field401d?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field402?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungData
     */
    field402a?: MeldungDataField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof MeldungData
     */
    dokumente?: Array<Dokument> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungDataField401
 */
export interface MeldungDataField401 {
    /**
     *
     * @type {number}
     * @memberof MeldungDataField401
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataField401
     */
    anzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataField401
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataField401
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonld
 */
export interface MeldungDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataJsonldLeistungsanbieterInfo}
     * @memberof MeldungDataJsonld
     */
    leistungsanbieterInfo?: MeldungDataJsonldLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof MeldungDataJsonld
     */
    einrichtungAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonld
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof MeldungDataJsonld
     */
    einrichtungTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof MeldungDataJsonld
     */
    unternehmensgruppe?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof MeldungDataJsonld
     */
    traeger?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof MeldungDataJsonld
     */
    traegerAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonld>}
     * @memberof MeldungDataJsonld
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonld> | null;
    /**
     *
     * @type {Array<KonzeptinformationJsonld>}
     * @memberof MeldungDataJsonld
     */
    konzeptinformationen?: Array<KonzeptinformationJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepKonzeptinformationenHausordnungen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<StandortJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepStandortStandorte?: Array<StandortJsonld> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<EinrichtungsleitungJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<EinrichtungsleitungJsonld> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraftJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraftJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<LeistungserbringerJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepLeistungserbringerLeistungserbringer?: Array<LeistungserbringerJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepUnterlagenDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    stepVertraegeDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {MeldungDataJsonldStepBeschaeftigtePflegePersonal}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataJsonldStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    zurueckweisenDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonld
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonld
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonld
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field401?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field401a?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field401b?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field401c?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field401d?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field402?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonld
     */
    field402a?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungDataJsonld
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonld
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldAntragJsonForm
 */
export interface MeldungDataJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldAntragJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldEinrichtungRead
 */
export interface MeldungDataJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldEinrichtungReadAdresseRead
 */
export interface MeldungDataJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldField401
 */
export interface MeldungDataJsonldField401 {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldField401
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldField401
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldField401
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldField401
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldField401
     */
    anzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldField401
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldField401
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldLeistungsanbieterInfo
 */
export interface MeldungDataJsonldLeistungsanbieterInfo {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    verband?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldLeistungsanbieterInfo
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungList
 */
export interface MeldungDataJsonldMeldungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungList
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungRead
 */
export interface MeldungDataJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadLeistungsanbieterInfo}
     * @memberof MeldungDataJsonldMeldungRead
     */
    leistungsanbieterInfo?: MeldungDataJsonldMeldungReadLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungserbringerJsonldMeldungReadAdresse}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungAdresse?: LeistungserbringerJsonldMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungDataJsonldMeldungRead
     */
    einrichtungTraegerverband?: MeldungDataJsonldMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungDataJsonldMeldungRead
     */
    unternehmensgruppe?: MeldungDataJsonldMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traeger?: MeldungDataJsonldMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungserbringerJsonldMeldungReadAdresse}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerAdresse?: LeistungserbringerJsonldMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<KonzeptinformationJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    konzeptinformationen?: Array<KonzeptinformationJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepKonzeptinformationenHausordnungen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<StandortJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortStandorte?: Array<StandortJsonldMeldungRead> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<EinrichtungsleitungJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<EinrichtungsleitungJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraftJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraftJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<LeistungserbringerJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepLeistungserbringerLeistungserbringer?: Array<LeistungserbringerJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepUnterlagenDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepVertraegeDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    zurueckweisenDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field401?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field401a?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field401b?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field401c?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field401d?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field402?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field402a?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungRead
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungDataJsonldMeldungRead
     */
    dokumente?: Array<DokumentJsonldMeldungRead> | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungReadEinrichtungTraegerverband
 */
export interface MeldungDataJsonldMeldungReadEinrichtungTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungReadEinrichtungTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadEinrichtungTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadEinrichtungTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadEinrichtungTraegerverband
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungReadField401
 */
export interface MeldungDataJsonldMeldungReadField401 {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungReadField401
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadField401
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadField401
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungReadField401
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataJsonldMeldungReadField401
     */
    anzahl?: number | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungReadLeistungsanbieterInfo
 */
export interface MeldungDataJsonldMeldungReadLeistungsanbieterInfo {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadLeistungsanbieterInfo
     */
    verband?: string;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
 */
export interface MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalFachkraft?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalMitAusbildung?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalSozialeBetreuung?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalPalliativ?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
 */
export interface MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalFachkraft?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldSimpleformJsonFormEinrichtungRead
 */
export interface MeldungDataJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldStepBeschaeftigtePflegePersonal
 */
export interface MeldungDataJsonldStepBeschaeftigtePflegePersonal {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    pflegePersonalFachkraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    pflegePersonalMitAusbildung?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    pflegePersonalSozialeBetreuung?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    pflegePersonalPalliativ?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonal
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
 */
export interface MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalFachkraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataLeistungsanbieterInfo
 */
export interface MeldungDataLeistungsanbieterInfo {
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    verband?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataLeistungsanbieterInfo
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataMeldungRead
 */
export interface MeldungDataMeldungRead {
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataMeldungReadLeistungsanbieterInfo}
     * @memberof MeldungDataMeldungRead
     */
    leistungsanbieterInfo?: MeldungDataMeldungReadLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungserbringerMeldungReadAdresse}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungAdresse?: LeistungserbringerMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {MeldungDataMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungDataMeldungRead
     */
    einrichtungTraegerverband?: MeldungDataMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {MeldungDataMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungDataMeldungRead
     */
    unternehmensgruppe?: MeldungDataMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {MeldungDataMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungDataMeldungRead
     */
    traeger?: MeldungDataMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungserbringerMeldungReadAdresse}
     * @memberof MeldungDataMeldungRead
     */
    traegerAdresse?: LeistungserbringerMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof MeldungDataMeldungRead
     */
    wohngemeinschaften?: Array<object> | null;
    /**
     *
     * @type {Array<KonzeptinformationMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    konzeptinformationen?: Array<KonzeptinformationMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepKonzeptinformationenHausordnungen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<StandortMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortStandorte?: Array<StandortMeldungRead> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<EinrichtungsleitungMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<EinrichtungsleitungMeldungRead> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraftMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraftMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<LeistungserbringerMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepLeistungserbringerLeistungserbringer?: Array<LeistungserbringerMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepUnterlagenDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    stepVertraegeDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {MeldungDataMeldungReadStepBeschaeftigtePflegePersonal}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataMeldungReadStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    zurueckweisenDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataMeldungRead
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataMeldungRead
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungDataMeldungRead
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field401?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field401a?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field401b?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field401c?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field401d?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field402?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungRead
     */
    field402a?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungRead
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungDataMeldungRead
     */
    dokumente?: Array<DokumentMeldungRead> | null;
}
/**
 *
 * @export
 * @interface MeldungDataMeldungReadEinrichtungTraegerverband
 */
export interface MeldungDataMeldungReadEinrichtungTraegerverband {
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadEinrichtungTraegerverband
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataMeldungReadField401
 */
export interface MeldungDataMeldungReadField401 {
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungReadField401
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungDataMeldungReadField401
     */
    anzahl?: number | null;
}
/**
 *
 * @export
 * @interface MeldungDataMeldungReadLeistungsanbieterInfo
 */
export interface MeldungDataMeldungReadLeistungsanbieterInfo {
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    bezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    strasse?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    hausnummer?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungDataMeldungReadLeistungsanbieterInfo
     */
    verband?: string;
}
/**
 *
 * @export
 * @interface MeldungDataMeldungReadStepBeschaeftigtePflegePersonal
 */
export interface MeldungDataMeldungReadStepBeschaeftigtePflegePersonal {
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalFachkraft?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalMitAusbildung?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalSozialeBetreuung?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalPalliativ?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonal
     */
    pflegePersonalHilfskraft?: MeldungDataMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
 */
export interface MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung {
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalFachkraft?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalHilfskraft?: MeldungDataMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface MeldungDataStepBeschaeftigtePflegePersonal
 */
export interface MeldungDataStepBeschaeftigtePflegePersonal {
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    pflegePersonalFachkraft?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    pflegePersonalMitAusbildung?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    pflegePersonalSozialeBetreuung?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    pflegePersonalPalliativ?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    pflegePersonalHilfskraft?: MeldungDataField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonal
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung
 */
export interface MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung {
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalFachkraft?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    pflegePersonalHilfskraft?: MeldungDataField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungDataStepBeschaeftigtePflegePersonalSozialeBetreuung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface MeldungEinrichtungRead
 */
export interface MeldungEinrichtungRead {
    /**
     *
     * @type {MeldungEinrichtungReadData}
     * @memberof MeldungEinrichtungRead
     */
    data?: MeldungEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof MeldungEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungEinrichtungReadAdresseRead
 */
export interface MeldungEinrichtungReadAdresseRead {
    /**
     *
     * @type {MeldungEinrichtungReadAdresseReadData}
     * @memberof MeldungEinrichtungReadAdresseRead
     */
    data?: MeldungEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungReadAdresseRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungReadAdresseRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof MeldungEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungEinrichtungReadAdresseReadData
 */
export interface MeldungEinrichtungReadAdresseReadData {}
/**
 *
 * @export
 * @interface MeldungEinrichtungReadData
 */
export interface MeldungEinrichtungReadData {}
/**
 *
 * @export
 * @interface MeldungEmailListEmailReadEmail
 */
export interface MeldungEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof MeldungEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungEmailReadEmailReadEmail
 */
export interface MeldungEmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof MeldungEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungFormDiffAntragFormDiff
 */
export interface MeldungFormDiffAntragFormDiff {
    /**
     *
     * @type {Array<FormFieldDiffAntragFormDiff>}
     * @memberof MeldungFormDiffAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffAntragFormDiff>;
    /**
     *
     * @type {string}
     * @memberof MeldungFormDiffAntragFormDiff
     */
    v1Header?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungFormDiffAntragFormDiff
     */
    v2Header?: string | null;
}
/**
 *
 * @export
 * @interface MeldungFormDiffJsonldAntragFormDiff
 */
export interface MeldungFormDiffJsonldAntragFormDiff {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungFormDiffJsonldAntragFormDiff
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungFormDiffJsonldAntragFormDiff
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungFormDiffJsonldAntragFormDiff
     */
    '@type'?: string;
    /**
     *
     * @type {Array<FormFieldDiffJsonldAntragFormDiff>}
     * @memberof MeldungFormDiffJsonldAntragFormDiff
     */
    fieldDiffs?: Array<FormFieldDiffJsonldAntragFormDiff>;
    /**
     *
     * @type {string}
     * @memberof MeldungFormDiffJsonldAntragFormDiff
     */
    v1Header?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungFormDiffJsonldAntragFormDiff
     */
    v2Header?: string | null;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow
 */
export interface MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
 */
export interface MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList {
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    behoerde?: string;
    /**
     *
     * @type {UploadJsonldMeldungFreigabeDokumenteList}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    upload?: UploadJsonldMeldungFreigabeDokumenteList;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
 */
export interface MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    behoerde?: string;
    /**
     *
     * @type {UploadJsonldMeldungFreigabeDokumenteRead}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    upload?: UploadJsonldMeldungFreigabeDokumenteRead;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite
 */
export interface MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentMeldungFreigabeDokumenteList
 */
export interface MeldungFreigabeDokumentMeldungFreigabeDokumenteList {
    /**
     *
     * @type {number}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteList
     */
    behoerde?: string;
    /**
     *
     * @type {UploadMeldungFreigabeDokumenteList}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteList
     */
    upload?: UploadMeldungFreigabeDokumenteList;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteList
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
 */
export interface MeldungFreigabeDokumentMeldungFreigabeDokumenteRead {
    /**
     *
     * @type {number}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
     */
    behoerde?: string;
    /**
     *
     * @type {UploadMeldungFreigabeDokumenteRead}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
     */
    upload?: UploadMeldungFreigabeDokumenteRead;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungFreigabeDokumentMeldungFreigabeDokumenteWrite
 */
export interface MeldungFreigabeDokumentMeldungFreigabeDokumenteWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteWrite
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungFreigabeDokumentMeldungFreigabeDokumenteWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow
 */
export interface MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentJsonldMeldungHinweistextList
 */
export interface MeldungHinweisdokumentJsonldMeldungHinweistextList {
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    behoerde?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {MeldungHinweisdokumentJsonldMeldungHinweistextListUpload}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    upload?: MeldungHinweisdokumentJsonldMeldungHinweistextListUpload | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
 */
export interface MeldungHinweisdokumentJsonldMeldungHinweistextListUpload {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextListUpload
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentJsonldMeldungHinweistextRead
 */
export interface MeldungHinweisdokumentJsonldMeldungHinweistextRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    behoerde?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    upload?: MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
 */
export interface MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextReadUpload
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentJsonldMeldungHinweistextWrite
 */
export interface MeldungHinweisdokumentJsonldMeldungHinweistextWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextWrite
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentJsonldMeldungHinweistextWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentMeldungHinweistextList
 */
export interface MeldungHinweisdokumentMeldungHinweistextList {
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentMeldungHinweistextList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextList
     */
    behoerde?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextList
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {MeldungHinweisdokumentMeldungHinweistextListUpload}
     * @memberof MeldungHinweisdokumentMeldungHinweistextList
     */
    upload?: MeldungHinweisdokumentMeldungHinweistextListUpload | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentMeldungHinweistextListUpload
 */
export interface MeldungHinweisdokumentMeldungHinweistextListUpload {
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof MeldungHinweisdokumentMeldungHinweistextListUpload
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentMeldungHinweistextRead
 */
export interface MeldungHinweisdokumentMeldungHinweistextRead {
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentMeldungHinweistextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextRead
     */
    behoerde?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextRead
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {MeldungHinweisdokumentMeldungHinweistextReadUpload}
     * @memberof MeldungHinweisdokumentMeldungHinweistextRead
     */
    upload?: MeldungHinweisdokumentMeldungHinweistextReadUpload | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentMeldungHinweistextReadUpload
 */
export interface MeldungHinweisdokumentMeldungHinweistextReadUpload {
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof MeldungHinweisdokumentMeldungHinweistextReadUpload
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface MeldungHinweisdokumentMeldungHinweistextWrite
 */
export interface MeldungHinweisdokumentMeldungHinweistextWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextWrite
     */
    einrichtungsArt?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweisdokumentMeldungHinweistextWrite
     */
    uploads?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungHinweistextAntragWorkflowActionAntragWorkflow
 */
export interface MeldungHinweistextAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface MeldungHinweistextJsonldMeldungHinweistextList
 */
export interface MeldungHinweistextJsonldMeldungHinweistextList {
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    ueberschrift?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    inhalt?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweistextJsonldMeldungHinweistextRead
 */
export interface MeldungHinweistextJsonldMeldungHinweistextRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    ueberschrift?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    inhalt?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweistextJsonldMeldungHinweistextWrite
 */
export interface MeldungHinweistextJsonldMeldungHinweistextWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextWrite
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextWrite
     */
    ueberschrift?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextJsonldMeldungHinweistextWrite
     */
    inhalt?: string;
}
/**
 *
 * @export
 * @interface MeldungHinweistextMeldungHinweistextList
 */
export interface MeldungHinweistextMeldungHinweistextList {
    /**
     *
     * @type {number}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    ueberschrift?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    inhalt?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextList
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweistextMeldungHinweistextRead
 */
export interface MeldungHinweistextMeldungHinweistextRead {
    /**
     *
     * @type {number}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    behoerde?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    ueberschrift?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    inhalt?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungHinweistextMeldungHinweistextWrite
 */
export interface MeldungHinweistextMeldungHinweistextWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungHinweistextMeldungHinweistextWrite
     */
    einrichtungsart?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextWrite
     */
    ueberschrift?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungHinweistextMeldungHinweistextWrite
     */
    inhalt?: string;
}
/**
 *
 * @export
 * @interface MeldungJsonld
 */
export interface MeldungJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonld
     */
    id?: number;
    /**
     *
     * @type {MeldungJsonldData}
     * @memberof MeldungJsonld
     */
    data?: MeldungJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    einrichtungVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    traeger?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    traegerVersion?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    versionen?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<LeistungserbringerJsonld>}
     * @memberof MeldungJsonld
     */
    leistungserbringer?: Array<LeistungserbringerJsonld>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldAntragJsonForm
 */
export interface MeldungJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldAntragJsonFormData}
     * @memberof MeldungJsonldAntragJsonForm
     */
    data?: MeldungJsonldAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldAntragJsonFormData
 */
export interface MeldungJsonldAntragJsonFormData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldAntragJsonFormData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldAntragJsonFormData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldAntragJsonFormData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungJsonldData
 */
export interface MeldungJsonldData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataJsonldLeistungsanbieterInfo}
     * @memberof MeldungJsonldData
     */
    leistungsanbieterInfo?: MeldungDataJsonldLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof MeldungJsonldData
     */
    einrichtungAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldData
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof MeldungJsonldData
     */
    einrichtungTraegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof MeldungJsonldData
     */
    unternehmensgruppe?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof MeldungJsonldData
     */
    traeger?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof MeldungJsonldData
     */
    traegerAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonld>}
     * @memberof MeldungJsonldData
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonld> | null;
    /**
     *
     * @type {Array<KonzeptinformationJsonld>}
     * @memberof MeldungJsonldData
     */
    konzeptinformationen?: Array<KonzeptinformationJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepKonzeptinformationenHausordnungen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<StandortJsonld>}
     * @memberof MeldungJsonldData
     */
    stepStandortStandorte?: Array<StandortJsonld> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<EinrichtungsleitungJsonld>}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<EinrichtungsleitungJsonld> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraftJsonld>}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraftJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<LeistungserbringerJsonld>}
     * @memberof MeldungJsonldData
     */
    stepLeistungserbringerLeistungserbringer?: Array<LeistungserbringerJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepUnterlagenDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    stepVertraegeDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {MeldungDataJsonldStepBeschaeftigtePflegePersonal}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataJsonldStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataJsonldStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    zurueckweisenDokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldData
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldData
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldData
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field401?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field401a?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field401b?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field401c?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field401d?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field402?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof MeldungJsonldData
     */
    field402a?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof MeldungJsonldData
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungJsonldEinrichtungRead
 */
export interface MeldungJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldEinrichtungReadData}
     * @memberof MeldungJsonldEinrichtungRead
     */
    data?: MeldungJsonldEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof MeldungJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldEinrichtungReadAdresseRead
 */
export interface MeldungJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldEinrichtungReadAdresseReadData}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    data?: MeldungJsonldEinrichtungReadAdresseReadData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof MeldungJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldEinrichtungReadAdresseReadData
 */
export interface MeldungJsonldEinrichtungReadAdresseReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldEinrichtungReadAdresseReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadAdresseReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungJsonldEinrichtungReadData
 */
export interface MeldungJsonldEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungJsonldEmailListEmailReadEmail
 */
export interface MeldungJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldEmailListEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungJsonldEmailReadEmailReadEmail
 */
export interface MeldungJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungCreate
 */
export interface MeldungJsonldMeldungCreate {
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungCreate
     */
    einrichtung?: string | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungList
 */
export interface MeldungJsonldMeldungList {
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungList
     */
    id?: number;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListData}
     * @memberof MeldungJsonldMeldungList
     */
    data?: MeldungMeldungCollectionOutputJsonldMeldungListData | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung}
     * @memberof MeldungJsonldMeldungList
     */
    einrichtung?: MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof MeldungJsonldMeldungList
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungRead
 */
export interface MeldungJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungRead
     */
    id?: number;
    /**
     *
     * @type {MeldungJsonldMeldungReadData}
     * @memberof MeldungJsonldMeldungRead
     */
    data?: MeldungJsonldMeldungReadData | null;
    /**
     *
     * @type {MeldungJsonldMeldungReadEinrichtung}
     * @memberof MeldungJsonldMeldungRead
     */
    einrichtung?: MeldungJsonldMeldungReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    status?: string;
    /**
     *
     * @type {MeldungJsonldMeldungReadEinrichtungVersion}
     * @memberof MeldungJsonldMeldungRead
     */
    einrichtungVersion?: MeldungJsonldMeldungReadEinrichtungVersion | null;
    /**
     *
     * @type {MeldungJsonldMeldungReadTraeger}
     * @memberof MeldungJsonldMeldungRead
     */
    traeger?: MeldungJsonldMeldungReadTraeger | null;
    /**
     *
     * @type {MeldungJsonldMeldungReadTraegerVersion}
     * @memberof MeldungJsonldMeldungRead
     */
    traegerVersion?: MeldungJsonldMeldungReadTraegerVersion | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungRead
     */
    eingereichtBy?: BenutzerJsonldMeldungReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldMeldungRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungRead
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldMeldungRead
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldMeldungRead
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungRead
     */
    einrichtungArt?: string | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungReadData
 */
export interface MeldungJsonldMeldungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldMeldungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadLeistungsanbieterInfo}
     * @memberof MeldungJsonldMeldungReadData
     */
    leistungsanbieterInfo?: MeldungDataJsonldMeldungReadLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungserbringerJsonldMeldungReadAdresse}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungAdresse?: LeistungserbringerJsonldMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungJsonldMeldungReadData
     */
    einrichtungTraegerverband?: MeldungDataJsonldMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungJsonldMeldungReadData
     */
    unternehmensgruppe?: MeldungDataJsonldMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungJsonldMeldungReadData
     */
    traeger?: MeldungDataJsonldMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungserbringerJsonldMeldungReadAdresse}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerAdresse?: LeistungserbringerJsonldMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<WohngemeinschaftJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    wohngemeinschaften?: Array<WohngemeinschaftJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<KonzeptinformationJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    konzeptinformationen?: Array<KonzeptinformationJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepKonzeptinformationenHausordnungen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<StandortJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortStandorte?: Array<StandortJsonldMeldungRead> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<EinrichtungsleitungJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<EinrichtungsleitungJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraftJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraftJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<LeistungserbringerJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepLeistungserbringerLeistungserbringer?: Array<LeistungserbringerJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepUnterlagenDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepVertraegeDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataJsonldMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    zurueckweisenDokumente?: Array<DokumentJsonldMeldungRead> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldMeldungReadData
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungJsonldMeldungReadData
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field401?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field401a?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field401b?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field401c?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field401d?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field402?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof MeldungJsonldMeldungReadData
     */
    field402a?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadData
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldMeldungRead>}
     * @memberof MeldungJsonldMeldungReadData
     */
    dokumente?: Array<DokumentJsonldMeldungRead> | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungReadEinrichtung
 */
export interface MeldungJsonldMeldungReadEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldMeldungReadEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungReadEinrichtung
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungReadEinrichtungVersion
 */
export interface MeldungJsonldMeldungReadEinrichtungVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldMeldungReadEinrichtungVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtungVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtungVersion
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtungVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadEinrichtungVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungReadEinrichtungVersion
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungReadTraeger
 */
export interface MeldungJsonldMeldungReadTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldMeldungReadTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungReadTraeger
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldMeldungReadTraegerVersion
 */
export interface MeldungJsonldMeldungReadTraegerVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    createdBy?: BenutzerJsonldMeldungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof MeldungJsonldMeldungReadTraegerVersion
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldSimpleformJsonFormEinrichtungRead
 */
export interface MeldungJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldSimpleformJsonFormEinrichtungReadData}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    data?: MeldungJsonldSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungJsonldSimpleformJsonFormEinrichtungReadData
 */
export interface MeldungJsonldSimpleformJsonFormEinrichtungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungJsonldSimpleformJsonFormEinrichtungReadData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputJsonldMeldungList
 */
export interface MeldungMeldungCollectionOutputJsonldMeldungList {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    nrwSchluessel?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    zustaendig?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    allowedWorkflowActions?: Array<string> | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListData}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    data?: MeldungMeldungCollectionOutputJsonldMeldungListData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    einrichtung?: MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    einrichtungVersion?: MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    status?: string | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListTraeger}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    traeger?: MeldungMeldungCollectionOutputJsonldMeldungListTraeger | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    traegerVersion?: MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    uuidMap?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungList
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputJsonldMeldungListData
 */
export interface MeldungMeldungCollectionOutputJsonldMeldungListData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
 */
export interface MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtung
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
 */
export interface MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListEinrichtungVersion
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputJsonldMeldungListTraeger
 */
export interface MeldungMeldungCollectionOutputJsonldMeldungListTraeger {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraeger
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraeger
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraeger
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraeger
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
 */
export interface MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    createdBy?: BenutzerJsonldMeldungListModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputJsonldMeldungListTraegerVersion
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputMeldungList
 */
export interface MeldungMeldungCollectionOutputMeldungList {
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    nrwSchluessel?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    erstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    letzteMeldung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    zustaendig?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    allowedWorkflowActions?: Array<string> | null;
    /**
     *
     * @type {MeldungMeldungListData}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    data?: MeldungMeldungListData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {MeldungMeldungListEinrichtung}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    einrichtung?: MeldungMeldungListEinrichtung | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputMeldungListEinrichtungVersion}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    einrichtungVersion?: MeldungMeldungCollectionOutputMeldungListEinrichtungVersion | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    status?: string | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputMeldungListTraeger}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    traeger?: MeldungMeldungCollectionOutputMeldungListTraeger | null;
    /**
     *
     * @type {MeldungMeldungCollectionOutputMeldungListTraegerVersion}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    traegerVersion?: MeldungMeldungCollectionOutputMeldungListTraegerVersion | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    notiz?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    uuidMap?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungCollectionOutputMeldungList
     */
    defaultActionOnRowClick?: Array<string> | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputMeldungListEinrichtungVersion
 */
export interface MeldungMeldungCollectionOutputMeldungListEinrichtungVersion {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungListEinrichtungVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungListEinrichtungVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputMeldungListEinrichtungVersion
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputMeldungListTraeger
 */
export interface MeldungMeldungCollectionOutputMeldungListTraeger {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraeger
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCollectionOutputMeldungListTraegerVersion
 */
export interface MeldungMeldungCollectionOutputMeldungListTraegerVersion {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraegerVersion
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraegerVersion
     */
    createdBy?: BenutzerMeldungListModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraegerVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof MeldungMeldungCollectionOutputMeldungListTraegerVersion
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungCreate
 */
export interface MeldungMeldungCreate {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungCreate
     */
    einrichtung?: string | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungJsonAntragJsonForm
 */
export interface MeldungMeldungJsonAntragJsonForm {
    /**
     *
     * @type {MeldungAntragJsonForm}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    antrag?: MeldungAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungMeldungJsonJsonldAntragJsonForm
 */
export interface MeldungMeldungJsonJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungJsonldAntragJsonForm}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    antrag?: MeldungJsonldAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface MeldungMeldungList
 */
export interface MeldungMeldungList {
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungList
     */
    id?: number;
    /**
     *
     * @type {MeldungMeldungListData}
     * @memberof MeldungMeldungList
     */
    data?: MeldungMeldungListData | null;
    /**
     *
     * @type {MeldungMeldungListEinrichtung}
     * @memberof MeldungMeldungList
     */
    einrichtung?: MeldungMeldungListEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof MeldungMeldungList
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungListData
 */
export interface MeldungMeldungListData {}
/**
 *
 * @export
 * @interface MeldungMeldungListEinrichtung
 */
export interface MeldungMeldungListEinrichtung {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungListEinrichtung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungListEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof MeldungMeldungListEinrichtung
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungRead
 */
export interface MeldungMeldungRead {
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungRead
     */
    id?: number;
    /**
     *
     * @type {MeldungMeldungReadData}
     * @memberof MeldungMeldungRead
     */
    data?: MeldungMeldungReadData | null;
    /**
     *
     * @type {MeldungMeldungReadEinrichtung}
     * @memberof MeldungMeldungRead
     */
    einrichtung?: MeldungMeldungReadEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    status?: string;
    /**
     *
     * @type {MeldungMeldungReadEinrichtungVersion}
     * @memberof MeldungMeldungRead
     */
    einrichtungVersion?: MeldungMeldungReadEinrichtungVersion | null;
    /**
     *
     * @type {MeldungMeldungReadTraeger}
     * @memberof MeldungMeldungRead
     */
    traeger?: MeldungMeldungReadTraeger | null;
    /**
     *
     * @type {MeldungMeldungReadTraegerVersion}
     * @memberof MeldungMeldungRead
     */
    traegerVersion?: MeldungMeldungReadTraegerVersion | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungRead
     */
    eingereichtBy?: BenutzerMeldungReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungRead
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungRead
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungRead
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungRead
     */
    einrichtungArt?: string | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungReadData
 */
export interface MeldungMeldungReadData {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    meldungArt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    druckdatum?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    antragsteller?: string;
    /**
     *
     * @type {MeldungDataMeldungReadLeistungsanbieterInfo}
     * @memberof MeldungMeldungReadData
     */
    leistungsanbieterInfo?: MeldungDataMeldungReadLeistungsanbieterInfo | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungWohngemeinschaftArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungWohngemeinschaftLeistungsbereich?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungName?: string | null;
    /**
     *
     * @type {LeistungserbringerMeldungReadAdresse}
     * @memberof MeldungMeldungReadData
     */
    einrichtungAdresse?: LeistungserbringerMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungInstitutionskennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungWeiteresInstitutionenkennzeichen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungHomepage?: string | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    einrichtungPlaetze?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungMeldungReadData
     */
    einrichtungShortCareHospital?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    einrichtungDatumErstinbetriebnahme?: string | null;
    /**
     *
     * @type {MeldungDataMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungMeldungReadData
     */
    einrichtungTraegerverband?: MeldungDataMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {MeldungDataMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungMeldungReadData
     */
    unternehmensgruppe?: MeldungDataMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    unternehmensgruppeName?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    neueUnternehmensgruppe?: string | null;
    /**
     *
     * @type {MeldungDataMeldungReadEinrichtungTraegerverband}
     * @memberof MeldungMeldungReadData
     */
    traeger?: MeldungDataMeldungReadEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerBezeichnung?: string | null;
    /**
     *
     * @type {LeistungserbringerMeldungReadAdresse}
     * @memberof MeldungMeldungReadData
     */
    traegerAdresse?: LeistungserbringerMeldungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerAnschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerPostfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerNrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerAnsprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    traegerTraegerverband?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof MeldungMeldungReadData
     */
    wohngemeinschaften?: Array<object> | null;
    /**
     *
     * @type {Array<KonzeptinformationMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    konzeptinformationen?: Array<KonzeptinformationMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepKonzeptinformationenLeistungsbeschreibungen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepKonzeptinformationenKonzeptinformationen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepKonzeptinformationenHausordnungen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepKonzeptinformationenQualitaetBeschwerden?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepKonzeptinformationenGewaltpraeventionen?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepKonzeptinformationenFreiheitentziehende?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<StandortMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepStandortStandorte?: Array<StandortMeldungRead> | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepStandortGesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepStandortEingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepStandortSeparateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepStandortAnnerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepStandortTeilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepStandortBetriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {Array<EinrichtungsleitungMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigteEinrichtungsleitung?: Array<EinrichtungsleitungMeldungRead> | null;
    /**
     *
     * @type {Array<VerantwortlicheFachkraftMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtePflegedienstleitung?: Array<VerantwortlicheFachkraftMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigteEinrichtungsleitungDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtePflegedienstleitungDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<LeistungserbringerMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepLeistungserbringerLeistungserbringer?: Array<LeistungserbringerMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepUnterlagenDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    stepVertraegeDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {MeldungDataMeldungReadStepBeschaeftigtePflegePersonal}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtePflegePersonal?: MeldungDataMeldungReadStepBeschaeftigtePflegePersonal | null;
    /**
     *
     * @type {MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtePflegePersonalSozialeBetreuung?: MeldungDataMeldungReadStepBeschaeftigtePflegePersonalSozialeBetreuung | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigteIntensivpflegeCheck?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigteIntensivpflegeDatenVZA?: number | null;
    /**
     *
     * @type {number}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigteIntensivpflegeDatenAnzahl?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftCheck?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notiz?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizBasisdaten?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizKonzeptinformation?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizBeschaeftigte?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizUnterlagen?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizLeistungserbringer?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizVertraege?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    notizZusammenfassung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    zurueckweisenBetreff?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    zurueckweisenNotiz?: string | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    zurueckweisenDokumente?: Array<DokumentMeldungRead> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungReadData
     */
    stepHinweiseHinweistexte?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungReadData
     */
    stepHinweiseHinweisdokumente?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungMeldungReadData
     */
    freigabeDokumente?: Array<string> | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field401?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field401a?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field401b?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field401c?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field401d?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field402?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof MeldungMeldungReadData
     */
    field402a?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    field401Label?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadData
     */
    field402Label?: string | null;
    /**
     *
     * @type {Array<DokumentMeldungRead>}
     * @memberof MeldungMeldungReadData
     */
    dokumente?: Array<DokumentMeldungRead> | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungReadEinrichtung
 */
export interface MeldungMeldungReadEinrichtung {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadEinrichtung
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadEinrichtung
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungReadEinrichtung
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungReadEinrichtungVersion
 */
export interface MeldungMeldungReadEinrichtungVersion {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadEinrichtungVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadEinrichtungVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungReadEinrichtungVersion
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungReadTraeger
 */
export interface MeldungMeldungReadTraeger {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadTraeger
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadTraeger
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungReadTraeger
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungMeldungReadTraegerVersion
 */
export interface MeldungMeldungReadTraegerVersion {
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadTraegerVersion
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungReadTraegerVersion
     */
    createdBy?: BenutzerMeldungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof MeldungMeldungReadTraegerVersion
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof MeldungMeldungReadTraegerVersion
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungSimpleformJsonFormEinrichtungRead
 */
export interface MeldungSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {MeldungSimpleformJsonFormEinrichtungReadData}
     * @memberof MeldungSimpleformJsonFormEinrichtungRead
     */
    data?: MeldungSimpleformJsonFormEinrichtungReadData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungSimpleformJsonFormEinrichtungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungSimpleformJsonFormEinrichtungRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof MeldungSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface MeldungSimpleformJsonFormEinrichtungReadData
 */
export interface MeldungSimpleformJsonFormEinrichtungReadData {}
/**
 *
 * @export
 * @interface MeldungVersion
 */
export interface MeldungVersion {
    /**
     *
     * @type {number}
     * @memberof MeldungVersion
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    antragModifiedBy?: string | null;
    /**
     *
     * @type {MeldungData}
     * @memberof MeldungVersion
     */
    data?: MeldungData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    antragModifiedAt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersion
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersion
     */
    dataRaw?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersion
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    createdBy?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungVersion
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    modifiedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersion
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungVersion
     */
    einrichtungVersorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface MeldungVersionJsonld
 */
export interface MeldungVersionJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof MeldungVersionJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof MeldungVersionJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    antrag?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    typ?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    antragModifiedBy?: string | null;
    /**
     *
     * @type {MeldungJsonldData}
     * @memberof MeldungVersionJsonld
     */
    data?: MeldungJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    antragModifiedAt: string;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersionJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersionJsonld
     */
    dataRaw?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersionJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    createdBy?: string | null;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof MeldungVersionJsonld
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    art?: string;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    modifiedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof MeldungVersionJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeldungVersionJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
}
/**
 *
 * @export
 * @interface Nachpruefung
 */
export interface Nachpruefung {
    /**
     *
     * @type {string}
     * @memberof Nachpruefung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Nachpruefung
     */
    datum?: string | null;
    /**
     *
     * @type {string}
     * @memberof Nachpruefung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface NachpruefungJsonld
 */
export interface NachpruefungJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof NachpruefungJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonld
     */
    datum?: string | null;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface NachpruefungJsonldRegelpruefungRead
 */
export interface NachpruefungJsonldRegelpruefungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof NachpruefungJsonldRegelpruefungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonldRegelpruefungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonldRegelpruefungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonldRegelpruefungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof NachpruefungJsonldRegelpruefungRead
     */
    datum?: string | null;
}
/**
 *
 * @export
 * @interface NachpruefungRegelpruefungRead
 */
export interface NachpruefungRegelpruefungRead {
    /**
     *
     * @type {string}
     * @memberof NachpruefungRegelpruefungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof NachpruefungRegelpruefungRead
     */
    datum?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonal
 */
export interface PflegePersonal {
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonal
     */
    pflegePersonalFachkraft?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonal
     */
    pflegePersonalMitAusbildung?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonal
     */
    pflegePersonalSozialeBetreuung?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonal
     */
    pflegePersonalPalliativ?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonal
     */
    pflegePersonalHilfskraft?: MeldungDataField401 | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonal
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonal
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonalDaten
 */
export interface PflegePersonalDaten {
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDaten
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDaten
     */
    anzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonalDatenJsonld
 */
export interface PflegePersonalDatenJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PflegePersonalDatenJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDatenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDatenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDatenJsonld
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDatenJsonld
     */
    anzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDatenJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDatenJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonalDatenJsonldMeldungRead
 */
export interface PflegePersonalDatenJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PflegePersonalDatenJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDatenJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalDatenJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDatenJsonldMeldungRead
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDatenJsonldMeldungRead
     */
    anzahl?: number | null;
}
/**
 *
 * @export
 * @interface PflegePersonalDatenMeldungRead
 */
export interface PflegePersonalDatenMeldungRead {
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDatenMeldungRead
     */
    vza?: number | null;
    /**
     *
     * @type {number}
     * @memberof PflegePersonalDatenMeldungRead
     */
    anzahl?: number | null;
}
/**
 *
 * @export
 * @interface PflegePersonalJsonld
 */
export interface PflegePersonalJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PflegePersonalJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalJsonld
     */
    pflegePersonalFachkraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalJsonld
     */
    pflegePersonalMitAusbildung?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalJsonld
     */
    pflegePersonalSozialeBetreuung?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalJsonld
     */
    pflegePersonalPalliativ?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalJsonld
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonalJsonldMeldungRead
 */
export interface PflegePersonalJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    pflegePersonalFachkraft?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    pflegePersonalMitAusbildung?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    pflegePersonalSozialeBetreuung?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    pflegePersonalPalliativ?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalJsonldMeldungRead
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface PflegePersonalMeldungRead
 */
export interface PflegePersonalMeldungRead {
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalMeldungRead
     */
    pflegePersonalFachkraft?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalMeldungRead
     */
    pflegePersonalMitAusbildung?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalMeldungRead
     */
    pflegePersonalSozialeBetreuung?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalMeldungRead
     */
    pflegePersonalPalliativ?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalMeldungRead
     */
    pflegePersonalHilfskraft?: MeldungDataMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface PflegePersonalSozialeBetreuung
 */
export interface PflegePersonalSozialeBetreuung {
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonalSozialeBetreuung
     */
    pflegePersonalFachkraft?: MeldungDataField401 | null;
    /**
     *
     * @type {MeldungDataField401}
     * @memberof PflegePersonalSozialeBetreuung
     */
    pflegePersonalHilfskraft?: MeldungDataField401 | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonalSozialeBetreuungJsonld
 */
export interface PflegePersonalSozialeBetreuungJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    pflegePersonalFachkraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {MeldungDataJsonldField401}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldField401 | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuungJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface PflegePersonalSozialeBetreuungJsonldMeldungRead
 */
export interface PflegePersonalSozialeBetreuungJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PflegePersonalSozialeBetreuungJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuungJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PflegePersonalSozialeBetreuungJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalSozialeBetreuungJsonldMeldungRead
     */
    pflegePersonalFachkraft?: MeldungDataJsonldMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataJsonldMeldungReadField401}
     * @memberof PflegePersonalSozialeBetreuungJsonldMeldungRead
     */
    pflegePersonalHilfskraft?: MeldungDataJsonldMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface PflegePersonalSozialeBetreuungMeldungRead
 */
export interface PflegePersonalSozialeBetreuungMeldungRead {
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalSozialeBetreuungMeldungRead
     */
    pflegePersonalFachkraft?: MeldungDataMeldungReadField401 | null;
    /**
     *
     * @type {MeldungDataMeldungReadField401}
     * @memberof PflegePersonalSozialeBetreuungMeldungRead
     */
    pflegePersonalHilfskraft?: MeldungDataMeldungReadField401 | null;
}
/**
 *
 * @export
 * @interface PlaetzeItemGet
 */
export interface PlaetzeItemGet {
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    shortTime?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    longTime?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    freitag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlaetzeItemGet
     */
    total?: number;
    /**
     *
     * @type {string}
     * @memberof PlaetzeItemGet
     */
    timestamp?: string;
    /**
     *
     * @type {string}
     * @memberof PlaetzeItemGet
     */
    beschreibung?: string;
}
/**
 *
 * @export
 * @interface PlatzzahlmelderToken
 */
export interface PlatzzahlmelderToken {
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmelderToken
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderToken
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderToken
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderToken
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface PlatzzahlmelderTokenJsonld
 */
export interface PlatzzahlmelderTokenJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonld
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate
 */
export interface PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate {
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate
     */
    benutzer?: string | null;
}
/**
 *
 * @export
 * @interface PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead
 */
export interface PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead
     */
    token?: string;
}
/**
 *
 * @export
 * @interface PlatzzahlmelderTokenPlatzzahlmelderTokenCreate
 */
export interface PlatzzahlmelderTokenPlatzzahlmelderTokenCreate {
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenPlatzzahlmelderTokenCreate
     */
    benutzer?: string | null;
}
/**
 *
 * @export
 * @interface PlatzzahlmelderTokenPlatzzahlmelderTokenRead
 */
export interface PlatzzahlmelderTokenPlatzzahlmelderTokenRead {
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenPlatzzahlmelderTokenRead
     */
    benutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmelderTokenPlatzzahlmelderTokenRead
     */
    token?: string;
}
/**
 *
 * @export
 * @interface PlatzzahlmeldungDTOEinrichtungCollectionGet
 */
export interface PlatzzahlmeldungDTOEinrichtungCollectionGet {
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    letzteAntragId?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    letzterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    belegbarePlaetzeStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    dauerpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    kurzzeitpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    freitag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    samstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    sonntag?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOEinrichtungCollectionGet
     */
    eingereichtAt?: string | null;
}
/**
 *
 * @export
 * @interface PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
 */
export interface PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    letzteAntragId?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    letzterStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    belegbarePlaetzeStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    dauerpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    dauerpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    kurzzeitpflege?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    kurzzeitpflegeBeschreibung?: string | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    montag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    dienstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    mittwoch?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    donnerstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    freitag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    samstag?: number | null;
    /**
     *
     * @type {number}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    sonntag?: number | null;
    /**
     *
     * @type {string}
     * @memberof PlatzzahlmeldungDTOJsonldEinrichtungCollectionGet
     */
    eingereichtAt?: string | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntrag
 */
export interface RegelpruefungAntrag {
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntrag
     */
    id?: number;
    /**
     *
     * @type {RegelpruefungAntragData}
     * @memberof RegelpruefungAntrag
     */
    data?: RegelpruefungAntragData | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    einrichtungStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    letzteNachpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    letzterUploadAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    zuletztGeprueft?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    zuletztGeprueftVon?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<AntragVersionInterface>}
     * @memberof RegelpruefungAntrag
     */
    versionen?: Array<AntragVersionInterface>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntrag
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntrag
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragAntragJsonForm
 */
export interface RegelpruefungAntragAntragJsonForm {
    /**
     *
     * @type {RegelpruefungAntragAntragJsonFormData}
     * @memberof RegelpruefungAntragAntragJsonForm
     */
    data?: RegelpruefungAntragAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragAntragJsonFormData
 */
export interface RegelpruefungAntragAntragJsonFormData {}
/**
 *
 * @export
 * @interface RegelpruefungAntragAntragWorkflowActionAntragWorkflow
 */
export interface RegelpruefungAntragAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragData
 */
export interface RegelpruefungAntragData {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragData
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragData
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragData
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragData
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragData
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<Nachpruefung>}
     * @memberof RegelpruefungAntragData
     */
    nachpruefungenList?: Array<Nachpruefung> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragData
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof RegelpruefungAntragData
     */
    dokumente?: Array<Dokument> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragDataJsonld
 */
export interface RegelpruefungAntragDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragDataJsonld
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragDataJsonld
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<NachpruefungJsonld>}
     * @memberof RegelpruefungAntragDataJsonld
     */
    nachpruefungenList?: Array<NachpruefungJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof RegelpruefungAntragDataJsonld
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonld
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragDataJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragDataJsonld
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragDataJsonldAntragJsonForm
 */
export interface RegelpruefungAntragDataJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragDataJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldAntragJsonForm
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragDataJsonldRegelpruefungRead
 */
export interface RegelpruefungAntragDataJsonldRegelpruefungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<NachpruefungJsonldRegelpruefungRead>}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    nachpruefungenList?: Array<NachpruefungJsonldRegelpruefungRead> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldRegelpruefungRead>}
     * @memberof RegelpruefungAntragDataJsonldRegelpruefungRead
     */
    dokumente?: Array<DokumentJsonldRegelpruefungRead> | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragDataRegelpruefungRead
 */
export interface RegelpruefungAntragDataRegelpruefungRead {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<NachpruefungRegelpruefungRead>}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    nachpruefungenList?: Array<NachpruefungRegelpruefungRead> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<DokumentRegelpruefungRead>}
     * @memberof RegelpruefungAntragDataRegelpruefungRead
     */
    dokumente?: Array<DokumentRegelpruefungRead> | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragEinrichtungRead
 */
export interface RegelpruefungAntragEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof RegelpruefungAntragEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragEinrichtungReadAdresseRead
 */
export interface RegelpruefungAntragEinrichtungReadAdresseRead {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof RegelpruefungAntragEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragEmailListEmailReadEmail
 */
export interface RegelpruefungAntragEmailListEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragEmailReadEmailReadEmail
 */
export interface RegelpruefungAntragEmailReadEmailReadEmail {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonld
 */
export interface RegelpruefungAntragJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragJsonld
     */
    id?: number;
    /**
     *
     * @type {RegelpruefungAntragJsonldData}
     * @memberof RegelpruefungAntragJsonld
     */
    data?: RegelpruefungAntragJsonldData | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    einrichtung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    einrichtungStandort?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    letzteNachpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    letzterUploadAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    zuletztGeprueft?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    zuletztGeprueftVon?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    eingereichtBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    uuid?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    uploads?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    dataRaw?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    hinweiseAusMigration?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    zuletztEingereichtBy?: string | null;
    /**
     *
     * @type {Array<AntragVersionInterfaceJsonld>}
     * @memberof RegelpruefungAntragJsonld
     */
    versionen?: Array<AntragVersionInterfaceJsonld>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    art: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    modifiedBy?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    einrichtungArt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    shortClassName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonld
     */
    labelReplacements?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    einrichtungErstinbetriebnahme?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    einrichtungVersorgungsvertrag?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonld
     */
    einrichtungName?: string | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldAntragJsonForm
 */
export interface RegelpruefungAntragJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {RegelpruefungAntragJsonldAntragJsonFormData}
     * @memberof RegelpruefungAntragJsonldAntragJsonForm
     */
    data?: RegelpruefungAntragJsonldAntragJsonFormData | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldAntragJsonFormData
 */
export interface RegelpruefungAntragJsonldAntragJsonFormData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldAntragJsonFormData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldAntragJsonFormData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldAntragJsonFormData
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldData
 */
export interface RegelpruefungAntragJsonldData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragJsonldData
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragJsonldData
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<NachpruefungJsonld>}
     * @memberof RegelpruefungAntragJsonldData
     */
    nachpruefungenList?: Array<NachpruefungJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof RegelpruefungAntragJsonldData
     */
    dokumente?: Array<DokumentJsonld> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldData
     */
    beschieden?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonldData
     */
    uuidMap?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonldData
     */
    labelReplacements?: Array<string>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldEinrichtungRead
 */
export interface RegelpruefungAntragJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof RegelpruefungAntragJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldEinrichtungReadAdresseRead
 */
export interface RegelpruefungAntragJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof RegelpruefungAntragJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldEmailListEmailReadEmail
 */
export interface RegelpruefungAntragJsonldEmailListEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldEmailListEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEmailListEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEmailListEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEmailListEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldEmailReadEmailReadEmail
 */
export interface RegelpruefungAntragJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldRegelpruefungCreate
 */
export interface RegelpruefungAntragJsonldRegelpruefungCreate {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungCreate
     */
    einrichtung?: string | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldRegelpruefungRead
 */
export interface RegelpruefungAntragJsonldRegelpruefungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    id?: number;
    /**
     *
     * @type {RegelpruefungAntragJsonldRegelpruefungReadData}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    data?: RegelpruefungAntragJsonldRegelpruefungReadData | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    einrichtung?: string | null;
    /**
     *
     * @type {RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    einrichtungStandort?: RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    letzteNachpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    letzterUploadAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    zuletztGeprueft?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungRead
     */
    uuid?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldRegelpruefungReadData
 */
export interface RegelpruefungAntragJsonldRegelpruefungReadData {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<NachpruefungJsonldRegelpruefungRead>}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    nachpruefungenList?: Array<NachpruefungJsonldRegelpruefungRead> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<DokumentJsonldRegelpruefungRead>}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadData
     */
    dokumente?: Array<DokumentJsonldRegelpruefungRead> | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
 */
export interface RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
     */
    id?: number;
    /**
     *
     * @type {EinrichtungStandortJsonldRegelpruefungReadAdresse}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
     */
    adresse?: EinrichtungStandortJsonldRegelpruefungReadAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragJsonldRegelpruefungReadEinrichtungStandort
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
 */
export interface RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof RegelpruefungAntragJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragPruefberichtDTO
 */
export interface RegelpruefungAntragPruefberichtDTO {
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    leistungsangebot?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    behoerde?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    pruefDatum?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    letzteNachpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    zuletztGeprueft?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    link?: string;
    /**
     *
     * @type {Array<Dokument>}
     * @memberof RegelpruefungAntragPruefberichtDTO
     */
    dokumente?: Array<Dokument>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragPruefberichtDTOJsonld
 */
export interface RegelpruefungAntragPruefberichtDTOJsonld {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    leistungsangebot?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    behoerde?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    pruefDatum?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    letzteNachpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    zuletztGeprueft?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    link?: string;
    /**
     *
     * @type {Array<DokumentJsonld>}
     * @memberof RegelpruefungAntragPruefberichtDTOJsonld
     */
    dokumente?: Array<DokumentJsonld>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
 */
export interface RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm {
    /**
     *
     * @type {RegelpruefungAntragAntragJsonForm}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    antrag?: RegelpruefungAntragAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaAntragJsonForm}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    schema?: JsonSchemaAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepAntragJsonForm>}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    steps?: Array<JsonFormStepAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
 */
export interface RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {RegelpruefungAntragJsonldAntragJsonForm}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    antrag?: RegelpruefungAntragJsonldAntragJsonForm;
    /**
     *
     * @type {object}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    data?: object;
    /**
     *
     * @type {JsonSchemaJsonldAntragJsonForm}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    schema?: JsonSchemaJsonldAntragJsonForm;
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    errors?: { [key: string]: Array<string> };
    /**
     *
     * @type {Array<JsonFormStepJsonldAntragJsonForm>}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    steps?: Array<JsonFormStepJsonldAntragJsonForm>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    step?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    id?: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    uuidMap?: { [key: string]: string };
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm
     */
    allowedWorkflowActions?: Array<string>;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungCreate
 */
export interface RegelpruefungAntragRegelpruefungCreate {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungCreate
     */
    einrichtung?: string | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
 */
export interface RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    einrichtung?: string | null;
    /**
     *
     * @type {RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    einrichtungStandort?: RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    maengel?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    nachpruefungen?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungList
     */
    allowedWorkflowActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort
 */
export interface RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungStandortJsonldRegelpruefungListAdresse}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort
     */
    adresse?: EinrichtungStandortJsonldRegelpruefungListAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragRegelpruefungOutputJsonldRegelpruefungListEinrichtungStandort
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungOutputRegelpruefungList
 */
export interface RegelpruefungAntragRegelpruefungOutputRegelpruefungList {
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    einrichtung?: string | null;
    /**
     *
     * @type {RegelpruefungAntragRegelpruefungOutputRegelpruefungListEinrichtungStandort}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    einrichtungStandort?: RegelpruefungAntragRegelpruefungOutputRegelpruefungListEinrichtungStandort | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    maengel?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    nachpruefungen?: Array<string> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungList
     */
    allowedWorkflowActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungOutputRegelpruefungListEinrichtungStandort
 */
export interface RegelpruefungAntragRegelpruefungOutputRegelpruefungListEinrichtungStandort {
    /**
     *
     * @type {EinrichtungStandortRegelpruefungListAdresse}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungListEinrichtungStandort
     */
    adresse?: EinrichtungStandortRegelpruefungListAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragRegelpruefungOutputRegelpruefungListEinrichtungStandort
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungRead
 */
export interface RegelpruefungAntragRegelpruefungRead {
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    id?: number;
    /**
     *
     * @type {RegelpruefungAntragRegelpruefungReadData}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    data?: RegelpruefungAntragRegelpruefungReadData | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    einrichtung?: string | null;
    /**
     *
     * @type {RegelpruefungAntragRegelpruefungReadEinrichtungStandort}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    einrichtungStandort?: RegelpruefungAntragRegelpruefungReadEinrichtungStandort | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    eingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    zuletztEingereichtAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    letzteNachpruefung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    letzterUploadAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    zuletztGeprueft?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    allowedWorkflowActions?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungRead
     */
    uuid?: string;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungReadData
 */
export interface RegelpruefungAntragRegelpruefungReadData {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    uuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    standort?: number | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    ergebnis?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    maengel?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    pruefDatum?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    nachpruefungen?: boolean | null;
    /**
     *
     * @type {Array<NachpruefungRegelpruefungRead>}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    nachpruefungenList?: Array<NachpruefungRegelpruefungRead> | null;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    bemerkung?: string | null;
    /**
     *
     * @type {Array<DokumentRegelpruefungRead>}
     * @memberof RegelpruefungAntragRegelpruefungReadData
     */
    dokumente?: Array<DokumentRegelpruefungRead> | null;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragRegelpruefungReadEinrichtungStandort
 */
export interface RegelpruefungAntragRegelpruefungReadEinrichtungStandort {
    /**
     *
     * @type {number}
     * @memberof RegelpruefungAntragRegelpruefungReadEinrichtungStandort
     */
    id?: number;
    /**
     *
     * @type {EinrichtungStandortRegelpruefungReadAdresse}
     * @memberof RegelpruefungAntragRegelpruefungReadEinrichtungStandort
     */
    adresse?: EinrichtungStandortRegelpruefungReadAdresse | null;
    /**
     *
     * @type {boolean}
     * @memberof RegelpruefungAntragRegelpruefungReadEinrichtungStandort
     */
    hauptstandort?: boolean;
}
/**
 *
 * @export
 * @interface RegelpruefungAntragSimpleformJsonFormEinrichtungRead
 */
export interface RegelpruefungAntragSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof RegelpruefungAntragSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof RegelpruefungAntragSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface Region
 */
export interface Region {
    /**
     *
     * @type {number}
     * @memberof Region
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    regionalschluessel?: string;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof Region
     */
    kreis?: string;
}
/**
 *
 * @export
 * @interface RegionJsonld
 */
export interface RegionJsonld {
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RegionJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    behoerde?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    bezirksregierung?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    regionalschluessel?: string;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    plz?: string;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    ort?: string;
    /**
     *
     * @type {string}
     * @memberof RegionJsonld
     */
    kreis?: string;
}
/**
 *
 * @export
 * @interface Registrierung
 */
export interface Registrierung {
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    anrede: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof Registrierung
     */
    plainPassword: string;
}
/**
 *
 * @export
 * @interface RegistrierungJsonld
 */
export interface RegistrierungJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RegistrierungJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    anrede: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof RegistrierungJsonld
     */
    plainPassword: string;
}
/**
 *
 * @export
 * @interface RelationClassData
 */
export interface RelationClassData {
    /**
     *
     * @type {number}
     * @memberof RelationClassData
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassData
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassData
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassData
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface RelationClassDataAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {number}
     * @memberof RelationClassDataAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataBenutzerFormGetSimpleformJsonForm
 */
export interface RelationClassDataBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {number}
     * @memberof RelationClassDataBenutzerFormGetSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataBenutzerFormGetSimpleformJsonForm
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonld
 */
export interface RelationClassDataJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RelationClassDataJsonld
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm
 */
export interface RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldBenutzerFormGetSimpleformJsonForm
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldLeistungsanbieterList
 */
export interface RelationClassDataJsonldLeistungsanbieterList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldLeistungsanbieterList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsanbieterList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsanbieterList
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldLeistungsangebotRegistrierenList
 */
export interface RelationClassDataJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenList
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface RelationClassDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldMeldungRead
 */
export interface RelationClassDataJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldMeldungRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldSimpleformJsonForm
 */
export interface RelationClassDataJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RelationClassDataJsonldSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldSimpleformJsonForm
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldTraegerCreate
 */
export interface RelationClassDataJsonldTraegerCreate {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldTraegerCreate
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerCreate
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerCreate
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface RelationClassDataJsonldTraegerReadRolleEinrichtung
 */
export interface RelationClassDataJsonldTraegerReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataJsonldTraegerReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataLeistungsangebotRegistrierenList
 */
export interface RelationClassDataLeistungsangebotRegistrierenList {
    /**
     *
     * @type {number}
     * @memberof RelationClassDataLeistungsangebotRegistrierenList
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataLeistungsangebotRegistrierenList
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataMeldungRead
 */
export interface RelationClassDataMeldungRead {
    /**
     *
     * @type {string}
     * @memberof RelationClassDataMeldungRead
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataSimpleformJsonForm
 */
export interface RelationClassDataSimpleformJsonForm {
    /**
     *
     * @type {number}
     * @memberof RelationClassDataSimpleformJsonForm
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataSimpleformJsonForm
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataTraegerReadRolleEinrichtung
 */
export interface RelationClassDataTraegerReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof RelationClassDataTraegerReadRolleEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataTraegerReadRolleEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataTraegerReadRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataTraegerReadRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface RelationClassDataTraegerWriteRolleEinrichtung
 */
export interface RelationClassDataTraegerWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof RelationClassDataTraegerWriteRolleEinrichtung
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataTraegerWriteRolleEinrichtung
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataTraegerWriteRolleEinrichtung
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof RelationClassDataTraegerWriteRolleEinrichtung
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface SignaturJsonldSignaturRead
 */
export interface SignaturJsonldSignaturRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SignaturJsonldSignaturRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SignaturJsonldSignaturRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SignaturJsonldSignaturRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof SignaturJsonldSignaturRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof SignaturJsonldSignaturRead
     */
    benutzer?: string;
    /**
     *
     * @type {string}
     * @memberof SignaturJsonldSignaturRead
     */
    text?: string;
}
/**
 *
 * @export
 * @interface SignaturJsonldSignaturWrite
 */
export interface SignaturJsonldSignaturWrite {
    /**
     *
     * @type {number}
     * @memberof SignaturJsonldSignaturWrite
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof SignaturJsonldSignaturWrite
     */
    benutzer?: string;
    /**
     *
     * @type {string}
     * @memberof SignaturJsonldSignaturWrite
     */
    text?: string;
}
/**
 *
 * @export
 * @interface SignaturSignaturRead
 */
export interface SignaturSignaturRead {
    /**
     *
     * @type {number}
     * @memberof SignaturSignaturRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof SignaturSignaturRead
     */
    benutzer?: string;
    /**
     *
     * @type {string}
     * @memberof SignaturSignaturRead
     */
    text?: string;
}
/**
 *
 * @export
 * @interface SignaturSignaturWrite
 */
export interface SignaturSignaturWrite {
    /**
     *
     * @type {number}
     * @memberof SignaturSignaturWrite
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof SignaturSignaturWrite
     */
    benutzer?: string;
    /**
     *
     * @type {string}
     * @memberof SignaturSignaturWrite
     */
    text?: string;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaBenutzerFormGetSimpleformJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
 */
export interface SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
 */
export interface SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaSimpleformJsonForm
 */
export interface SimpleFormJsonCustomSchemaSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonForm
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
 */
export interface SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonCustomSchemaSimpleformJsonFormEinrichtungRead
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    additionalItems?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    items?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    additionalProperties?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    not?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    contains?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    propertyNames?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    if?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    then?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    else?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    custom?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
 */
export interface SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    items?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    not?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    contains?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    if?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    then?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    else?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    custom?: SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
 */
export interface SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    additionalItems?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    items?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    additionalProperties?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    not?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    contains?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    propertyNames?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    if?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    then?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    else?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonForm
     */
    custom?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
 */
export interface SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    items?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    not?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    contains?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    if?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    then?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    else?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    custom?: SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
 */
export interface SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaBenutzerFormGetSimpleformJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    additionalItems?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    items?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    definitions?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    properties?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    patternProperties?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    dependencies?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    allOf?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    anyOf?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    oneOf?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    not?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    contains?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    propertyNames?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    if?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    then?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    else?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm
     */
    custom?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
 */
export interface SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    items?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    definitions?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    properties?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    patternProperties?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    dependencies?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    allOf?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    anyOf?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    oneOf?: Array<JsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    not?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    contains?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    if?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    then?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    else?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormAdditionalItems
     */
    custom?: SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
 */
export interface SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldAngezeigteBetreuungFormGetSimpleformJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
 */
export interface SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    additionalItems?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    items?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    definitions?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    properties?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    patternProperties?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    dependencies?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    allOf?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    anyOf?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    oneOf?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    not?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    contains?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    propertyNames?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    if?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    then?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    else?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonForm
     */
    custom?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
 */
export interface SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    items?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    definitions?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    properties?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    patternProperties?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    dependencies?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    allOf?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    anyOf?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    oneOf?: Array<JsonSchemaJsonldBenutzerFormGetSimpleformJsonForm>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    not?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    contains?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    if?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    then?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    else?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormAdditionalItems
     */
    custom?: SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
 */
export interface SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldBenutzerFormGetSimpleformJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldSimpleformJsonForm
 */
export interface SimpleFormJsonSchemaJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    additionalItems?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    items?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    definitions?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    properties?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    patternProperties?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    dependencies?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    allOf?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    anyOf?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    oneOf?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    not?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    contains?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    propertyNames?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    if?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    then?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    else?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonForm
     */
    custom?: SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
 */
export interface SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    items?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    definitions?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    properties?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    patternProperties?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    dependencies?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    allOf?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    anyOf?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonForm>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    oneOf?: Array<JsonSchemaJsonldSimpleformJsonForm>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    not?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    contains?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    if?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    then?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    else?: SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormAdditionalItems
     */
    custom?: SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
 */
export interface SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
 */
export interface SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    additionalItems?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    items?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    definitions?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    properties?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    patternProperties?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    dependencies?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    allOf?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    anyOf?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    oneOf?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    not?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    contains?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    propertyNames?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    if?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    then?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    else?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead
     */
    custom?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
 */
export interface SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    items?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    definitions?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    properties?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    patternProperties?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    dependencies?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    allOf?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    anyOf?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    oneOf?: Array<JsonSchemaJsonldSimpleformJsonFormEinrichtungRead>;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    not?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    contains?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    if?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    then?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    else?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    custom?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
 */
export interface SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungReadCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaSimpleformJsonForm
 */
export interface SimpleFormJsonSchemaSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    additionalItems?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    items?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    additionalProperties?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    not?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    contains?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    propertyNames?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    if?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    then?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    else?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaSimpleformJsonForm
     */
    custom?: SimpleFormJsonSchemaSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
 */
export interface SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    items?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    not?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    contains?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    if?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    then?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    else?: SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormCustom}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormAdditionalItems
     */
    custom?: SimpleFormJsonSchemaSimpleformJsonFormCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaSimpleformJsonFormCustom
 */
export interface SimpleFormJsonSchemaSimpleformJsonFormCustom {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
 */
export interface SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    additionalItems?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    items?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    additionalProperties?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    not?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    contains?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    propertyNames?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    if?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    then?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    else?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead
     */
    custom?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
 */
export interface SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    schema?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    description?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    default?: Array<string> | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    multipleOf?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    exclusiveMaximum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    exclusiveMinimum?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minLength?: number | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    pattern?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    additionalItems?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    items?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maxItems?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minItems?: number | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    uniqueItems?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    maxProperties?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    minProperties?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    required?: Array<string>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    additionalProperties?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    definitions?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    properties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    patternProperties?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    dependencies?: Array<object>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    enum?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    enumNames?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    type?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    format?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    ref?: string | null;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    allOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    anyOf?: Array<object>;
    /**
     *
     * @type {Array<object>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    oneOf?: Array<object>;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    not?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    contains?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    propertyNames?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    if?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    then?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    else?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    contentMediaType?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    contentEncoding?: string | null;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadAdditionalItems
     */
    custom?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom | null;
}
/**
 *
 * @export
 * @interface SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
 */
export interface SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom {
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    model_path?: string | null;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    property_path?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    disabled?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    addable?: boolean | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    deleteable?: boolean | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    dependent_fields?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    formula?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    adressLookupNrwOnly?: boolean | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    actions?: { [key: string]: { [key: string]: string } };
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    block_prefixes?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    min?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    max?: number | null;
    /**
     *
     * @type {number}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    scale?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    tenor_fields?: Array<string>;
    /**
     *
     * @type {any}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    previous_value?: any;
    /**
     *
     * @type {boolean}
     * @memberof SimpleFormJsonSchemaSimpleformJsonFormEinrichtungReadCustom
     */
    compare_previous_value?: boolean;
}
/**
 *
 * @export
 * @interface StandardauswertungExportRequestExportRequest
 */
export interface StandardauswertungExportRequestExportRequest {
    /**
     *
     * @type {number}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestExportRequestGeneriertesDokument}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    status?: StandardauswertungExportRequestExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestExportRequest
     */
    resource?: string;
}

export const StandardauswertungExportRequestExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type StandardauswertungExportRequestExportRequestStatusEnum =
    (typeof StandardauswertungExportRequestExportRequestStatusEnum)[keyof typeof StandardauswertungExportRequestExportRequestStatusEnum];

/**
 *
 * @export
 * @interface StandardauswertungExportRequestJsonldExportRequest
 */
export interface StandardauswertungExportRequestJsonldExportRequest {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    benutzer?: string;
    /**
     *
     * @type {EinrichtungExportRequestJsonldExportRequestGeneriertesDokument}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    generiertesDokument?: EinrichtungExportRequestJsonldExportRequestGeneriertesDokument | null;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    requestUri?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    status?: StandardauswertungExportRequestJsonldExportRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    requestDate?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    createdDate?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    updatedDate?: string;
    /**
     * This is an error message for customer, on frontend side.
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    errors?: string;
    /**
     *
     * @type {string}
     * @memberof StandardauswertungExportRequestJsonldExportRequest
     */
    resource?: string;
}

export const StandardauswertungExportRequestJsonldExportRequestStatusEnum = {
    New: 'new',
    Working: 'working',
    Completed: 'completed',
    Failed: 'failed',
} as const;

export type StandardauswertungExportRequestJsonldExportRequestStatusEnum =
    (typeof StandardauswertungExportRequestJsonldExportRequestStatusEnum)[keyof typeof StandardauswertungExportRequestJsonldExportRequestStatusEnum];

/**
 *
 * @export
 * @interface StandardauswertungFilter
 */
export interface StandardauswertungFilter {
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungFilter
     */
    bezirksregierung?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungFilter
     */
    behoerde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungFilter
     */
    gemeinde?: Array<string> | null;
}
/**
 *
 * @export
 * @interface StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite
 */
export interface StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite
     */
    bezirksregierung?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite
     */
    behoerde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite
     */
    gemeinde?: Array<string> | null;
}
/**
 *
 * @export
 * @interface StandardauswertungStandardauswertungFilterStandardauswertungWrite
 */
export interface StandardauswertungStandardauswertungFilterStandardauswertungWrite {
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungStandardauswertungFilterStandardauswertungWrite
     */
    bezirksregierung?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungStandardauswertungFilterStandardauswertungWrite
     */
    behoerde?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof StandardauswertungStandardauswertungFilterStandardauswertungWrite
     */
    gemeinde?: Array<string> | null;
}
/**
 *
 * @export
 * @interface StandardauswertungStandardauswertungJsonForm
 */
export interface StandardauswertungStandardauswertungJsonForm {
    /**
     *
     * @type {StandardauswertungFilter}
     * @memberof StandardauswertungStandardauswertungJsonForm
     */
    data?: StandardauswertungFilter;
    /**
     *
     * @type {JsonSchema}
     * @memberof StandardauswertungStandardauswertungJsonForm
     */
    schema?: JsonSchema;
}
/**
 *
 * @export
 * @interface Standort
 */
export interface Standort {
    /**
     *
     * @type {string}
     * @memberof Standort
     */
    uuid?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Standort
     */
    hauptstandort?: boolean;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof Standort
     */
    standortAdresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    gesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    eingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    einzelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    doppelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    separateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    annerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    teilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof Standort
     */
    betriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof Standort
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof Standort
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface StandortJsonld
 */
export interface StandortJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof StandortJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof StandortJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof StandortJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof StandortJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof StandortJsonld
     */
    hauptstandort?: boolean;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof StandortJsonld
     */
    standortAdresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    gesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    eingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    einzelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    doppelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    separateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    annerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    teilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonld
     */
    betriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof StandortJsonld
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof StandortJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface StandortJsonldMeldungRead
 */
export interface StandortJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof StandortJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof StandortJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof StandortJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof StandortJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof StandortJsonldMeldungRead
     */
    hauptstandort?: boolean;
    /**
     *
     * @type {LeistungserbringerJsonldMeldungReadAdresse}
     * @memberof StandortJsonldMeldungRead
     */
    standortAdresse?: LeistungserbringerJsonldMeldungReadAdresse | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    gesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    eingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    einzelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    doppelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    separateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    annerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    teilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortJsonldMeldungRead
     */
    betriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof StandortJsonldMeldungRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface StandortMeldungRead
 */
export interface StandortMeldungRead {
    /**
     *
     * @type {string}
     * @memberof StandortMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof StandortMeldungRead
     */
    hauptstandort?: boolean;
    /**
     *
     * @type {LeistungserbringerMeldungReadAdresse}
     * @memberof StandortMeldungRead
     */
    standortAdresse?: LeistungserbringerMeldungReadAdresse | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    gesamtPlatzzahl?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    eingestreuteKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    einzelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    doppelzimmerPlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    separateKurzzeitpflegeplaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    annerkanntePlaetze?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    teilzeitbeschaeftigte?: number | null;
    /**
     *
     * @type {number}
     * @memberof StandortMeldungRead
     */
    betriebsintegriertePlatzzahl?: number | null;
    /**
     *
     * @type {string}
     * @memberof StandortMeldungRead
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface TableFilter
 */
export interface TableFilter {
    /**
     *
     * @type {string}
     * @memberof TableFilter
     */
    field?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof TableFilter
     */
    values?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface TableFilterJsonld
 */
export interface TableFilterJsonld {
    /**
     *
     * @type {string}
     * @memberof TableFilterJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TableFilterJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TableFilterJsonld
     */
    field?: string;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof TableFilterJsonld
     */
    values?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface Token
 */
export interface Token {
    /**
     *
     * @type {string}
     * @memberof Token
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof Token
     */
    refresh_token?: string;
}
/**
 *
 * @export
 * @interface TokenRefresh
 */
export interface TokenRefresh {
    /**
     *
     * @type {string}
     * @memberof TokenRefresh
     */
    refresh_token?: string;
}
/**
 *
 * @export
 * @interface TraegerAntragWorkflowActionAntragWorkflow
 */
export interface TraegerAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof TraegerAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof TraegerBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerCollectionOutput
 */
export interface TraegerCollectionOutput {
    /**
     *
     * @type {number}
     * @memberof TraegerCollectionOutput
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutput
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutput
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerCollectionOutput
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutput
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutput
     */
    lastModifiedAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerCollectionOutput
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerCollectionOutput
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface TraegerCollectionOutputJsonld
 */
export interface TraegerCollectionOutputJsonld {
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutputJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutputJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerCollectionOutputJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutputJsonld
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutputJsonld
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerCollectionOutputJsonld
     */
    benutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutputJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerCollectionOutputJsonld
     */
    lastModifiedAt?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerCollectionOutputJsonld
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerCollectionOutputJsonld
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface TraegerDto
 */
export interface TraegerDto {
    /**
     *
     * @type {number}
     * @memberof TraegerDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    typ?: string | null;
    /**
     *
     * @type {BenutzerFormEinrichtung}
     * @memberof TraegerDto
     */
    traegerverband?: BenutzerFormEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDto
     */
    postfachOrt?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof TraegerDto
     */
    adressDaten?: AngezeigteBetreuungFormAdresse | null;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonld
 */
export interface TraegerDtoJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerDtoJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerDtoJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    typ?: string | null;
    /**
     *
     * @type {BenutzerFormJsonldEinrichtung}
     * @memberof TraegerDtoJsonld
     */
    traegerverband?: BenutzerFormJsonldEinrichtung | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonld
     */
    postfachOrt?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof TraegerDtoJsonld
     */
    adressDaten?: AngezeigteBetreuungFormJsonldAdresse | null;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonldTraegerCreate
 */
export interface TraegerDtoJsonldTraegerCreate {
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    typ?: string | null;
    /**
     *
     * @type {TraegerDtoJsonldTraegerCreateTraegerverband}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    traegerverband?: TraegerDtoJsonldTraegerCreateTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerDtoJsonldTraegerCreateAdressDaten}
     * @memberof TraegerDtoJsonldTraegerCreate
     */
    adressDaten?: TraegerDtoJsonldTraegerCreateAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonldTraegerCreateAdressDaten
 */
export interface TraegerDtoJsonldTraegerCreateAdressDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateAdressDaten
     */
    cx?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonldTraegerCreateTraegerverband
 */
export interface TraegerDtoJsonldTraegerCreateTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerDtoJsonldTraegerCreateTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerCreateTraegerverband
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonldTraegerReadRolleEinrichtung
 */
export interface TraegerDtoJsonldTraegerReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    typ?: string | null;
    /**
     *
     * @type {TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    traegerverband?: TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtung
     */
    adressDaten?: TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
 */
export interface TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
 */
export interface TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoJsonldTraegerReadRolleEinrichtungTraegerverband
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerCreate
 */
export interface TraegerDtoTraegerCreate {
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    typ?: string | null;
    /**
     *
     * @type {TraegerDtoTraegerCreateTraegerverband}
     * @memberof TraegerDtoTraegerCreate
     */
    traegerverband?: TraegerDtoTraegerCreateTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreate
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerDtoTraegerCreateAdressDaten}
     * @memberof TraegerDtoTraegerCreate
     */
    adressDaten?: TraegerDtoTraegerCreateAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerCreateAdressDaten
 */
export interface TraegerDtoTraegerCreateAdressDaten {
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerCreateAdressDaten
     */
    cx?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerCreateTraegerverband
 */
export interface TraegerDtoTraegerCreateTraegerverband {}
/**
 *
 * @export
 * @interface TraegerDtoTraegerReadRolleEinrichtung
 */
export interface TraegerDtoTraegerReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    typ?: string | null;
    /**
     *
     * @type {TraegerDtoTraegerReadRolleEinrichtungTraegerverband}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    traegerverband?: TraegerDtoTraegerReadRolleEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerDtoTraegerReadRolleEinrichtungAdressDaten}
     * @memberof TraegerDtoTraegerReadRolleEinrichtung
     */
    adressDaten?: TraegerDtoTraegerReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerReadRolleEinrichtungAdressDaten
 */
export interface TraegerDtoTraegerReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerReadRolleEinrichtungTraegerverband
 */
export interface TraegerDtoTraegerReadRolleEinrichtungTraegerverband {
    /**
     *
     * @type {number}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungTraegerverband
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungTraegerverband
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungTraegerverband
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerReadRolleEinrichtungTraegerverband
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerWriteRolleEinrichtung
 */
export interface TraegerDtoTraegerWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    nrwKey?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    typ?: string | null;
    /**
     *
     * @type {TraegerDtoTraegerWriteRolleEinrichtungTraegerverband}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    traegerverband?: TraegerDtoTraegerWriteRolleEinrichtungTraegerverband | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerDtoTraegerWriteRolleEinrichtungAdressDaten}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtung
     */
    adressDaten?: TraegerDtoTraegerWriteRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
 */
export interface TraegerDtoTraegerWriteRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerDtoTraegerWriteRolleEinrichtungTraegerverband
 */
export interface TraegerDtoTraegerWriteRolleEinrichtungTraegerverband {
    /**
     *
     * @type {number}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungTraegerverband
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungTraegerverband
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungTraegerverband
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerDtoTraegerWriteRolleEinrichtungTraegerverband
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerEinrichtungRead
 */
export interface TraegerEinrichtungRead {
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresse}
     * @memberof TraegerEinrichtungRead
     */
    adresse?: EinrichtungEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof TraegerEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerEinrichtungReadAdresseRead
 */
export interface TraegerEinrichtungReadAdresseRead {
    /**
     *
     * @type {EinrichtungEinrichtungReadAdresseReadAdresse}
     * @memberof TraegerEinrichtungReadAdresseRead
     */
    adresse?: EinrichtungEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungReadAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof TraegerEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerGeneriertesDokument
 */
export interface TraegerGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof TraegerGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof TraegerGeneriertesDokument
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequest>}
     * @memberof TraegerGeneriertesDokument
     */
    exportRequests?: Array<ExportRequest>;
    /**
     *
     * @type {boolean}
     * @memberof TraegerGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokument
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokument
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface TraegerGeneriertesDokumentJsonld
 */
export interface TraegerGeneriertesDokumentJsonld {
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequestJsonld>}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    exportRequests?: Array<ExportRequestJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerGeneriertesDokumentJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerJsonldEinrichtungRead
 */
export interface TraegerJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresse}
     * @memberof TraegerJsonldEinrichtungRead
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof TraegerJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldEinrichtungReadAdresseRead
 */
export interface TraegerJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldEinrichtungReadAdresseReadAdresse}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    adresse?: EinrichtungJsonldEinrichtungReadAdresseReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof TraegerJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof TraegerJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldMeldungList
 */
export interface TraegerJsonldMeldungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldMeldungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof TraegerJsonldMeldungList
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldMeldungRead
 */
export interface TraegerJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof TraegerJsonldMeldungRead
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldSimpleformJsonFormEinrichtungRead
 */
export interface TraegerJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    adresse?: EinrichtungJsonldSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof TraegerJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldTraegerRead
 */
export interface TraegerJsonldTraegerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldTraegerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerRead
     */
    id?: number;
    /**
     *
     * @type {TraegerJsonldTraegerReadAdresse}
     * @memberof TraegerJsonldTraegerRead
     */
    adresse?: TraegerJsonldTraegerReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    type?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    status?: string | null;
    /**
     *
     * @type {TraegerJsonldTraegerReadDeletedByBenutzer}
     * @memberof TraegerJsonldTraegerRead
     */
    deletedByBenutzer: TraegerJsonldTraegerReadDeletedByBenutzer | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerRead
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerRead
     */
    investId?: number | null;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerRead
     */
    webId?: number | null;
    /**
     *
     * @type {TraegerJsonldTraegerReadTraegerverband}
     * @memberof TraegerJsonldTraegerRead
     */
    traegerverband?: TraegerJsonldTraegerReadTraegerverband | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldTraegerReadAdresse
 */
export interface TraegerJsonldTraegerReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface TraegerJsonldTraegerReadDeletedByBenutzer
 */
export interface TraegerJsonldTraegerReadDeletedByBenutzer {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldTraegerReadDeletedByBenutzer
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadDeletedByBenutzer
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadDeletedByBenutzer
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadDeletedByBenutzer
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadDeletedByBenutzer
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadDeletedByBenutzer
     */
    email: string;
}
/**
 *
 * @export
 * @interface TraegerJsonldTraegerReadTraegerverband
 */
export interface TraegerJsonldTraegerReadTraegerverband {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerJsonldTraegerReadTraegerverband
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadTraegerverband
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadTraegerverband
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerJsonldTraegerReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof TraegerJsonldTraegerReadTraegerverband
     */
    investId?: number | null;
}
/**
 *
 * @export
 * @interface TraegerLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface TraegerLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof TraegerLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy}
     * @memberof TraegerLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    modifiedBy?: BehoerdeLeistungsangebotRegistrierenReadLeistungsanbieterReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerMeldungList
 */
export interface TraegerMeldungList {
    /**
     *
     * @type {string}
     * @memberof TraegerMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof TraegerMeldungList
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerMeldungRead
 */
export interface TraegerMeldungRead {
    /**
     *
     * @type {string}
     * @memberof TraegerMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof TraegerMeldungRead
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerPfadInvestTraegerOutputInvestRead
 */
export interface TraegerPfadInvestTraegerOutputInvestRead {
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    postfach?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputInvestRead
     */
    traegerverbandUuid?: string | null;
}
/**
 *
 * @export
 * @interface TraegerPfadInvestTraegerOutputJsonldInvestRead
 */
export interface TraegerPfadInvestTraegerOutputJsonldInvestRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    art?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    postfach?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerPfadInvestTraegerOutputJsonldInvestRead
     */
    traegerverbandUuid?: string | null;
}
/**
 *
 * @export
 * @interface TraegerSimpleformJsonFormEinrichtungRead
 */
export interface TraegerSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungReadAdresse}
     * @memberof TraegerSimpleformJsonFormEinrichtungRead
     */
    adresse?: EinrichtungSimpleformJsonFormEinrichtungReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerSimpleformJsonFormEinrichtungRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof TraegerSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerTraegerRead
 */
export interface TraegerTraegerRead {
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerRead
     */
    id?: number;
    /**
     *
     * @type {TraegerTraegerReadAdresse}
     * @memberof TraegerTraegerRead
     */
    adresse?: TraegerTraegerReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    type?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    gpKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    status?: string | null;
    /**
     *
     * @type {TraegerTraegerReadDeletedByBenutzer}
     * @memberof TraegerTraegerRead
     */
    deletedByBenutzer: TraegerTraegerReadDeletedByBenutzer | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerRead
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerRead
     */
    investId?: number | null;
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerRead
     */
    webId?: number | null;
    /**
     *
     * @type {TraegerTraegerReadTraegerverband}
     * @memberof TraegerTraegerRead
     */
    traegerverband?: TraegerTraegerReadTraegerverband | null;
}
/**
 *
 * @export
 * @interface TraegerTraegerReadAdresse
 */
export interface TraegerTraegerReadAdresse {
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerReadAdresse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface TraegerTraegerReadDeletedByBenutzer
 */
export interface TraegerTraegerReadDeletedByBenutzer {
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadDeletedByBenutzer
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadDeletedByBenutzer
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadDeletedByBenutzer
     */
    email: string;
}
/**
 *
 * @export
 * @interface TraegerTraegerReadTraegerverband
 */
export interface TraegerTraegerReadTraegerverband {
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerReadTraegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerTraegerReadTraegerverband
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof TraegerTraegerReadTraegerverband
     */
    investId?: number | null;
}
/**
 *
 * @export
 * @interface TraegerVersionJsonldMeldungList
 */
export interface TraegerVersionJsonldMeldungList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerVersionJsonldMeldungList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungList
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof TraegerVersionJsonldMeldungList
     */
    createdBy?: BenutzerJsonldMeldungListModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungListModifiedBy}
     * @memberof TraegerVersionJsonldMeldungList
     */
    modifiedBy?: BenutzerJsonldMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerVersionJsonldMeldungRead
 */
export interface TraegerVersionJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    createdBy?: BenutzerJsonldMeldungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerJsonldMeldungReadModifiedBy}
     * @memberof TraegerVersionJsonldMeldungRead
     */
    modifiedBy?: BenutzerJsonldMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerVersionMeldungList
 */
export interface TraegerVersionMeldungList {
    /**
     *
     * @type {string}
     * @memberof TraegerVersionMeldungList
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof TraegerVersionMeldungList
     */
    createdBy?: BenutzerMeldungListModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionMeldungList
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungListModifiedBy}
     * @memberof TraegerVersionMeldungList
     */
    modifiedBy?: BenutzerMeldungListModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerVersionMeldungRead
 */
export interface TraegerVersionMeldungRead {
    /**
     *
     * @type {string}
     * @memberof TraegerVersionMeldungRead
     */
    createdAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof TraegerVersionMeldungRead
     */
    createdBy?: BenutzerMeldungReadModifiedBy | null;
    /**
     *
     * @type {string}
     * @memberof TraegerVersionMeldungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {BenutzerMeldungReadModifiedBy}
     * @memberof TraegerVersionMeldungRead
     */
    modifiedBy?: BenutzerMeldungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface Traegerverband
 */
export interface Traegerverband {
    /**
     *
     * @type {number}
     * @memberof Traegerverband
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {number}
     * @memberof Traegerverband
     */
    investId?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Traegerverband
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Traegerverband
     */
    traegerVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof Traegerverband
     */
    einrichtungVersion?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Traegerverband
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerverbandCollectionOutput
 */
export interface TraegerverbandCollectionOutput {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandCollectionOutput
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutput
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandCollectionOutput
     */
    benutzer?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutput
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutput
     */
    email?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandCollectionOutput
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandCollectionOutput
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface TraegerverbandCollectionOutputJsonld
 */
export interface TraegerverbandCollectionOutputJsonld {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    benutzer?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    ansprechpartner?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    email?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandCollectionOutputJsonld
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDto
 */
export interface TraegerverbandDto {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDto
     */
    postfachOrt?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof TraegerverbandDto
     */
    adressDaten?: AngezeigteBetreuungFormAdresse | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDtoJsonld
 */
export interface TraegerverbandDtoJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandDtoJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandDtoJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonld
     */
    postfachOrt?: string;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof TraegerverbandDtoJsonld
     */
    adressDaten?: AngezeigteBetreuungFormJsonldAdresse | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
 */
export interface TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung
     */
    adressDaten?: TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
 */
export interface TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDtoTraegerverbandReadRolleEinrichtung
 */
export interface TraegerverbandDtoTraegerverbandReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtung
     */
    adressDaten?: TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
 */
export interface TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandReadRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
 */
export interface TraegerverbandDtoTraegerverbandWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten}
     * @memberof TraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    adressDaten?: TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerverbandEinrichtungRead
 */
export interface TraegerverbandEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadModifiedBy}
     * @memberof TraegerverbandEinrichtungRead
     */
    modifiedBy?: AdresseEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerverbandEinrichtungReadAdresseRead
 */
export interface TraegerverbandEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungReadAdresseRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseEinrichtungReadAdresseReadModifiedBy}
     * @memberof TraegerverbandEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonld
 */
export interface TraegerverbandJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonld
     */
    investId?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonld
     */
    benutzer?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonld
     */
    traegerVersion?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonld
     */
    einrichtungVersion?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonld
     */
    modifiedBy?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
 */
export interface TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldEinrichtungRead
 */
export interface TraegerverbandJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadModifiedBy}
     * @memberof TraegerverbandJsonldEinrichtungRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldEinrichtungReadAdresseRead
 */
export interface TraegerverbandJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldEinrichtungReadAdresseReadModifiedBy}
     * @memberof TraegerverbandJsonldEinrichtungReadAdresseRead
     */
    modifiedBy?: AdresseJsonldEinrichtungReadAdresseReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
 */
export interface TraegerverbandJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof TraegerverbandJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseJsonldSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldTraegerRead
 */
export interface TraegerverbandJsonldTraegerRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldTraegerRead
     */
    investId?: number | null;
}
/**
 *
 * @export
 * @interface TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
 */
export interface TraegerverbandJsonldTraegerverbandReadRolleEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    investId?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandJsonldTraegerverbandReadRolleEinrichtung
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface TraegerverbandPfadInvestTraegerverbandOutputInvestRead
 */
export interface TraegerverbandPfadInvestTraegerverbandOutputInvestRead {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    postfach?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputInvestRead
     */
    telefon?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
 */
export interface TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    uuid?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    postfach?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead
     */
    telefon?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandSimpleformJsonFormEinrichtungRead
 */
export interface TraegerverbandSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandSimpleformJsonFormEinrichtungRead
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
    /**
     *
     * @type {AdresseSimpleformJsonFormEinrichtungReadModifiedBy}
     * @memberof TraegerverbandSimpleformJsonFormEinrichtungRead
     */
    modifiedBy?: AdresseSimpleformJsonFormEinrichtungReadModifiedBy | null;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerRead
 */
export interface TraegerverbandTraegerRead {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerRead
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerRead
     */
    investId?: number | null;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
 */
export interface TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
     */
    adressDaten?: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
 */
export interface TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
 */
export interface TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    ansprechpartner?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    telefon?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    fax?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    anschriftTyp?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    postfachBezeichnung?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    postfachPlz?: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    postfachOrt?: string;
    /**
     *
     * @type {TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtung
     */
    adressDaten?: TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten | null;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
 */
export interface TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten {
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    label?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandDtoTraegerverbandWriteRolleEinrichtungAdressDaten
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface TraegerverbandTraegerverbandReadRolleEinrichtung
 */
export interface TraegerverbandTraegerverbandReadRolleEinrichtung {
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    deletedByBenutzer?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    deleted?: string | null;
    /**
     *
     * @type {string}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    deleteConfirmed?: string | null;
    /**
     *
     * @type {number}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    investId?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof TraegerverbandTraegerverbandReadRolleEinrichtung
     */
    benutzer?: Array<string>;
}
/**
 *
 * @export
 * @interface Unternehmensgruppe
 */
export interface Unternehmensgruppe {
    /**
     *
     * @type {number}
     * @memberof Unternehmensgruppe
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Unternehmensgruppe
     */
    name?: string;
    /**
     *
     * @type {boolean}
     * @memberof Unternehmensgruppe
     */
    approved?: boolean;
    /**
     *
     * @type {string}
     * @memberof Unternehmensgruppe
     */
    benutzer?: string | null;
}
/**
 *
 * @export
 * @interface UnternehmensgruppeJsonld
 */
export interface UnternehmensgruppeJsonld {
    /**
     *
     * @type {string}
     * @memberof UnternehmensgruppeJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UnternehmensgruppeJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UnternehmensgruppeJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UnternehmensgruppeJsonld
     */
    name?: string;
    /**
     *
     * @type {boolean}
     * @memberof UnternehmensgruppeJsonld
     */
    approved?: boolean;
    /**
     *
     * @type {string}
     * @memberof UnternehmensgruppeJsonld
     */
    benutzer?: string | null;
}
/**
 *
 * @export
 * @interface UploadEinrichtungRead
 */
export interface UploadEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof UploadEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface UploadEinrichtungReadAdresseRead
 */
export interface UploadEinrichtungReadAdresseRead {
    /**
     *
     * @type {number}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadEinrichtungReadAdresseRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface UploadEmailReadEmailReadEmail
 */
export interface UploadEmailReadEmailReadEmail {
    /**
     *
     * @type {number}
     * @memberof UploadEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadEmailReadEmailReadEmail
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadEmailReadEmailReadEmail
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {EmailEmailReadEmailReadEmailEmpfaenger}
     * @memberof UploadEmailReadEmailReadEmail
     */
    createdBy?: EmailEmailReadEmailReadEmailEmpfaenger | null;
}
/**
 *
 * @export
 * @interface UploadJsonldEinrichtungRead
 */
export interface UploadJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldEinrichtungReadAdresseRead
 */
export interface UploadJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldEmailReadEmailReadEmail
 */
export interface UploadJsonldEmailReadEmailReadEmail {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    createdAt?: string;
    /**
     *
     * @type {EmailJsonldEmailReadEmailReadEmailEmpfaenger}
     * @memberof UploadJsonldEmailReadEmailReadEmail
     */
    createdBy?: EmailJsonldEmailReadEmailReadEmailEmpfaenger | null;
}
/**
 *
 * @export
 * @interface UploadJsonldMassenmailRead
 */
export interface UploadJsonldMassenmailRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldMassenmailRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMassenmailRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldMassenmailRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {MassenmailJsonldMassenmailReadCreatedBy}
     * @memberof UploadJsonldMassenmailRead
     */
    createdBy?: MassenmailJsonldMassenmailReadCreatedBy | null;
}
/**
 *
 * @export
 * @interface UploadJsonldMeldungFreigabeDokumenteList
 */
export interface UploadJsonldMeldungFreigabeDokumenteList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldMeldungFreigabeDokumenteList
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadJsonldMeldungFreigabeDokumenteRead
 */
export interface UploadJsonldMeldungFreigabeDokumenteRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldMeldungFreigabeDokumenteRead
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadJsonldMeldungHinweistextList
 */
export interface UploadJsonldMeldungHinweistextList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldMeldungHinweistextList
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadJsonldMeldungHinweistextRead
 */
export interface UploadJsonldMeldungHinweistextRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldMeldungHinweistextRead
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadJsonldSimpleformJsonFormEinrichtungRead
 */
export interface UploadJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface UploadJsonldUploadRead
 */
export interface UploadJsonldUploadRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof UploadJsonldUploadRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldUploadRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadJsonldUploadRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadJsonldUploadRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadJsonldUploadRead
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface UploadMassenmailRead
 */
export interface UploadMassenmailRead {
    /**
     *
     * @type {number}
     * @memberof UploadMassenmailRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadMassenmailRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadMassenmailRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadMassenmailRead
     */
    createdAt?: string;
    /**
     *
     * @type {MassenmailMassenmailReadCreatedBy}
     * @memberof UploadMassenmailRead
     */
    createdBy?: MassenmailMassenmailReadCreatedBy | null;
}
/**
 *
 * @export
 * @interface UploadMeldungFreigabeDokumenteList
 */
export interface UploadMeldungFreigabeDokumenteList {
    /**
     *
     * @type {number}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadMeldungFreigabeDokumenteList
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadMeldungFreigabeDokumenteRead
 */
export interface UploadMeldungFreigabeDokumenteRead {
    /**
     *
     * @type {number}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadMeldungFreigabeDokumenteRead
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadMeldungHinweistextList
 */
export interface UploadMeldungHinweistextList {
    /**
     *
     * @type {number}
     * @memberof UploadMeldungHinweistextList
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextList
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextList
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextList
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadMeldungHinweistextList
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextList
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextList
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadMeldungHinweistextList
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadMeldungHinweistextRead
 */
export interface UploadMeldungHinweistextRead {
    /**
     *
     * @type {number}
     * @memberof UploadMeldungHinweistextRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadMeldungHinweistextRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadMeldungHinweistextRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadMeldungHinweistextRead
     */
    accessRights?: boolean;
}
/**
 *
 * @export
 * @interface UploadSimpleformJsonFormEinrichtungRead
 */
export interface UploadSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {number}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface UploadUploadRead
 */
export interface UploadUploadRead {
    /**
     *
     * @type {number}
     * @memberof UploadUploadRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    contentUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof UploadUploadRead
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    mimeType?: string;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    token?: string;
    /**
     *
     * @type {boolean}
     * @memberof UploadUploadRead
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof UploadUploadRead
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface VerantwortlicheFachkraft
 */
export interface VerantwortlicheFachkraft {
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    fachkraftName?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    fachkraftTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof VerantwortlicheFachkraft
     */
    fachkraftStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    fachkraftTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    fachkraftFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    fachkraftEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraft
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface VerantwortlicheFachkraftJsonld
 */
export interface VerantwortlicheFachkraftJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    fachkraftName?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    fachkraftTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    fachkraftStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    fachkraftTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    fachkraftFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    fachkraftEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface VerantwortlicheFachkraftJsonldMeldungRead
 */
export interface VerantwortlicheFachkraftJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    fachkraftName?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    fachkraftTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    fachkraftStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    fachkraftTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    fachkraftFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftJsonldMeldungRead
     */
    fachkraftEmail?: string | null;
}
/**
 *
 * @export
 * @interface VerantwortlicheFachkraftMeldungRead
 */
export interface VerantwortlicheFachkraftMeldungRead {
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    fachkraftName?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    fachkraftTaetigSeit?: string | null;
    /**
     *
     * @type {number}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    fachkraftStellenAnteil?: number | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    fachkraftTelefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    fachkraftFax?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerantwortlicheFachkraftMeldungRead
     */
    fachkraftEmail?: string | null;
}
/**
 *
 * @export
 * @interface VerifizierungsMailJsonldVerifizierungsmailWrite
 */
export interface VerifizierungsMailJsonldVerifizierungsmailWrite {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof VerifizierungsMailJsonldVerifizierungsmailWrite
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof VerifizierungsMailJsonldVerifizierungsmailWrite
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof VerifizierungsMailJsonldVerifizierungsmailWrite
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof VerifizierungsMailJsonldVerifizierungsmailWrite
     */
    email?: string;
}
/**
 *
 * @export
 * @interface VerifizierungsMailVerifizierungsmailWrite
 */
export interface VerifizierungsMailVerifizierungsmailWrite {
    /**
     *
     * @type {string}
     * @memberof VerifizierungsMailVerifizierungsmailWrite
     */
    email?: string;
}
/**
 *
 * @export
 * @interface Wohngemeinschaft
 */
export interface Wohngemeinschaft {
    /**
     *
     * @type {string}
     * @memberof Wohngemeinschaft
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Wohngemeinschaft
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof Wohngemeinschaft
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof Wohngemeinschaft
     */
    startDate?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormAdresse}
     * @memberof Wohngemeinschaft
     */
    adresse?: AngezeigteBetreuungFormAdresse | null;
    /**
     *
     * @type {string}
     * @memberof Wohngemeinschaft
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftAntragWorkflowActionAntragWorkflow
 */
export interface WohngemeinschaftAntragWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftAntragWorkflowActionAntragWorkflow
     */
    action: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
 */
export interface WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    registrierungsDatum?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    anschrift?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    anzahlDerBetreutenNutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    ambulanterDienst?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof WohngemeinschaftCollectionOutputJsonldWohngemeinschaftRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftCollectionOutputWohngemeinschaftRead
 */
export interface WohngemeinschaftCollectionOutputWohngemeinschaftRead {
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    registrierungsDatum?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    anschrift?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    anzahlDerBetreutenNutzer?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    einrichtung?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    ambulanterDienst?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    defaultActionOnRowClick?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof WohngemeinschaftCollectionOutputWohngemeinschaftRead
     */
    allowedActions?: Array<string> | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftEinrichtungRead
 */
export interface WohngemeinschaftEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftEinrichtungReadAdresseRead
 */
export interface WohngemeinschaftEinrichtungReadAdresseRead {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftGeneriertesDokument
 */
export interface WohngemeinschaftGeneriertesDokument {
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequest>}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    exportRequests?: Array<ExportRequest>;
    /**
     *
     * @type {boolean}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokument
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftGeneriertesDokumentJsonld
 */
export interface WohngemeinschaftGeneriertesDokumentJsonld {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    contentUrl?: string | null;
    /**
     *
     * @type {any}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    file?: any | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    originalName?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    fileSize?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    mimeType?: string;
    /**
     *
     * @type {Array<ExportRequestJsonld>}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    exportRequests?: Array<ExportRequestJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    accessRights?: boolean;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftGeneriertesDokumentJsonld
     */
    createdBy?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonld
 */
export interface WohngemeinschaftJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonld
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonld
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftJsonld
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonld
     */
    startDate?: string | null;
    /**
     *
     * @type {AngezeigteBetreuungFormJsonldAdresse}
     * @memberof WohngemeinschaftJsonld
     */
    adresse?: AngezeigteBetreuungFormJsonldAdresse | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonld
     */
    beschieden?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldEinrichtungRead
 */
export interface WohngemeinschaftJsonldEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldEinrichtungReadAdresseRead
 */
export interface WohngemeinschaftJsonldEinrichtungReadAdresseRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldEinrichtungReadAdresseRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungReadAdresseRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungReadAdresseRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungReadAdresseRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldEinrichtungReadAdresseRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldLeistungsangebotRegistrierenList
 */
export interface WohngemeinschaftJsonldLeistungsangebotRegistrierenList {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenList
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenList
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenList
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    startDate?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof WohngemeinschaftJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    adresse?: LeistungsanbieterDataJsonldLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldMeldungRead
 */
export interface WohngemeinschaftJsonldMeldungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldMeldungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldMeldungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldMeldungRead
     */
    '@type'?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead
 */
export interface WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldWohngemeinschaftRead
 */
export interface WohngemeinschaftJsonldWohngemeinschaftRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    id?: number;
    /**
     *
     * @type {WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    einrichtung?: WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung | null;
    /**
     *
     * @type {WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    ambulanterDienst?: WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung | null;
    /**
     *
     * @type {WohngemeinschaftJsonldWohngemeinschaftReadAdresse}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    adresse?: WohngemeinschaftJsonldWohngemeinschaftReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    behoerde?: string | null;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    beginn?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    deleted?: string | null;
    /**
     *
     * @type {WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftRead
     */
    deletedByBenutzer?: WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldWohngemeinschaftReadAdresse
 */
export interface WohngemeinschaftJsonldWohngemeinschaftReadAdresse {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadAdresse
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadAdresse
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadAdresse
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
 */
export interface WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadDeletedByBenutzer
     */
    email: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
 */
export interface WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftJsonldWohngemeinschaftReadEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead
 */
export interface WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    uuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    startDate?: string | null;
    /**
     *
     * @type {LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse}
     * @memberof WohngemeinschaftLeistungsangebotRegistrierenReadLeistungsanbieterRead
     */
    adresse?: LeistungsanbieterDataLeistungsangebotRegistrierenReadLeistungsanbieterReadTraegerAdresse | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftSimpleformJsonFormEinrichtungRead
 */
export interface WohngemeinschaftSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftSimpleformJsonFormEinrichtungRead
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftSimpleformJsonFormEinrichtungRead
     */
    modifiedAt?: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftWohngemeinschaftRead
 */
export interface WohngemeinschaftWohngemeinschaftRead {
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    id?: number;
    /**
     *
     * @type {WohngemeinschaftWohngemeinschaftReadEinrichtung}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    einrichtung?: WohngemeinschaftWohngemeinschaftReadEinrichtung | null;
    /**
     *
     * @type {WohngemeinschaftWohngemeinschaftReadEinrichtung}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    ambulanterDienst?: WohngemeinschaftWohngemeinschaftReadEinrichtung | null;
    /**
     *
     * @type {WohngemeinschaftWohngemeinschaftReadAdresse}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    adresse?: WohngemeinschaftWohngemeinschaftReadAdresse | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    behoerde?: string | null;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    beginn?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    deleted?: string | null;
    /**
     *
     * @type {WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer}
     * @memberof WohngemeinschaftWohngemeinschaftRead
     */
    deletedByBenutzer?: WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftWohngemeinschaftReadAdresse
 */
export interface WohngemeinschaftWohngemeinschaftReadAdresse {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadAdresse
     */
    cy?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadAdresse
     */
    label?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer
 */
export interface WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer
     */
    vorname: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer
     */
    nachname: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadDeletedByBenutzer
     */
    email: string;
}
/**
 *
 * @export
 * @interface WohngemeinschaftWohngemeinschaftReadEinrichtung
 */
export interface WohngemeinschaftWohngemeinschaftReadEinrichtung {
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftWohngemeinschaftReadEinrichtung
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadEinrichtung
     */
    nrwKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftReadEinrichtung
     */
    name?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftWohngemeinschaftWriteAdresseWrite
 */
export interface WohngemeinschaftWohngemeinschaftWriteAdresseWrite {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWrite
     */
    einrichtung?: string | null;
    /**
     *
     * @type {WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWrite
     */
    adresse?: WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse | null;
    /**
     *
     * @type {number}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWrite
     */
    plaetze?: number | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWrite
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWrite
     */
    beginn?: string | null;
}
/**
 *
 * @export
 * @interface WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
 */
export interface WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse {
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    strasse?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    hausnummer?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    anschriftTyp?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    postfachBezeichnung?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    plz?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    ort?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    telefon?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    fax?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    homepage?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    cx?: string | null;
    /**
     *
     * @type {string}
     * @memberof WohngemeinschaftWohngemeinschaftWriteAdresseWriteAdresse
     */
    cy?: string | null;
}
/**
 *
 * @export
 * @interface ZugehoerigeEinrichtungenDTO
 */
export interface ZugehoerigeEinrichtungenDTO {
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTO
     */
    nrwSchluessel?: string;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTO
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTO
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTO
     */
    erstinbetriebnahme?: string | null;
}
/**
 *
 * @export
 * @interface ZugehoerigeEinrichtungenDTOJsonld
 */
export interface ZugehoerigeEinrichtungenDTOJsonld {
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTOJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTOJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTOJsonld
     */
    nrwSchluessel?: string;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTOJsonld
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTOJsonld
     */
    adresse?: string | null;
    /**
     *
     * @type {string}
     * @memberof ZugehoerigeEinrichtungenDTOJsonld
     */
    erstinbetriebnahme?: string | null;
}
/**
 *
 * @export
 * @interface Zugriffsrechte
 */
export interface Zugriffsrechte {
    /**
     *
     * @type {number}
     * @memberof Zugriffsrechte
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Zugriffsrechte
     */
    einrichtung?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof Zugriffsrechte
     */
    leistungsanbieterBenutzer?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof Zugriffsrechte
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteJsonld
 */
export interface ZugriffsrechteJsonld {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof ZugriffsrechteJsonld
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonld
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonld
     */
    '@type'?: string;
    /**
     *
     * @type {number}
     * @memberof ZugriffsrechteJsonld
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonld
     */
    einrichtung?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonld
     */
    leistungsanbieterBenutzer?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonld
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteJsonldSimpleformJsonForm
 */
export interface ZugriffsrechteJsonldSimpleformJsonForm {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof ZugriffsrechteJsonldSimpleformJsonForm
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonldSimpleformJsonForm
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonldSimpleformJsonForm
     */
    '@type'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonldSimpleformJsonForm
     */
    einrichtung?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonldSimpleformJsonForm
     */
    leistungsanbieterBenutzer?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonldSimpleformJsonForm
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
 */
export interface ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {EinrichtungJsonldSimpleformJsonFormEinrichtungRead}
     * @memberof ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
     */
    einrichtung?: EinrichtungJsonldSimpleformJsonFormEinrichtungRead;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
     */
    leistungsanbieterBenutzer?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteJsonldZugriffsrechtePost
 */
export interface ZugriffsrechteJsonldZugriffsrechtePost {
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteJsonldZugriffsrechtePost
     */
    einrichtung?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteJsonldZugriffsrechtePost
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteSimpleformJsonForm
 */
export interface ZugriffsrechteSimpleformJsonForm {
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteSimpleformJsonForm
     */
    einrichtung?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteSimpleformJsonForm
     */
    leistungsanbieterBenutzer?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteSimpleformJsonForm
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteSimpleformJsonFormEinrichtungRead
 */
export interface ZugriffsrechteSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {EinrichtungSimpleformJsonFormEinrichtungRead}
     * @memberof ZugriffsrechteSimpleformJsonFormEinrichtungRead
     */
    einrichtung?: EinrichtungSimpleformJsonFormEinrichtungRead;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteSimpleformJsonFormEinrichtungRead
     */
    leistungsanbieterBenutzer?: Array<string> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteSimpleformJsonFormEinrichtungRead
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead
 */
export interface ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {AdressDatenJsonldContext}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead
     */
    '@context'?: AdressDatenJsonldContext;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead
     */
    '@id'?: string;
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead
     */
    '@type'?: string;
    /**
     *
     * @type {ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead
     */
    data?: ZugriffsrechteJsonldSimpleformJsonFormEinrichtungRead;
    /**
     *
     * @type {SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead
     */
    schema?: SimpleFormJsonSchemaJsonldSimpleformJsonFormEinrichtungRead;
}
/**
 *
 * @export
 * @interface ZugriffsrechteZugriffsrechteJsonFormSimpleformJsonFormEinrichtungRead
 */
export interface ZugriffsrechteZugriffsrechteJsonFormSimpleformJsonFormEinrichtungRead {
    /**
     *
     * @type {ZugriffsrechteSimpleformJsonFormEinrichtungRead}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormSimpleformJsonFormEinrichtungRead
     */
    data?: ZugriffsrechteSimpleformJsonFormEinrichtungRead;
    /**
     *
     * @type {SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead}
     * @memberof ZugriffsrechteZugriffsrechteJsonFormSimpleformJsonFormEinrichtungRead
     */
    schema?: SimpleFormJsonSchemaSimpleformJsonFormEinrichtungRead;
}
/**
 *
 * @export
 * @interface ZugriffsrechteZugriffsrechtePost
 */
export interface ZugriffsrechteZugriffsrechtePost {
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteZugriffsrechtePost
     */
    einrichtung?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ZugriffsrechteZugriffsrechtePost
     */
    benutzer?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteZugriffsrechteWorkflowActionAntragWorkflow
 */
export interface ZugriffsrechteZugriffsrechteWorkflowActionAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteZugriffsrechteWorkflowActionAntragWorkflow
     */
    action: string | null;
    /**
     *
     * @type {number}
     * @memberof ZugriffsrechteZugriffsrechteWorkflowActionAntragWorkflow
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof ZugriffsrechteZugriffsrechteWorkflowActionAntragWorkflow
     */
    benutzer?: number | null;
}
/**
 *
 * @export
 * @interface ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow
 */
export interface ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow {
    /**
     *
     * @type {string}
     * @memberof ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow
     */
    action: string | null;
    /**
     *
     * @type {number}
     * @memberof ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow
     */
    einrichtung?: number | null;
    /**
     *
     * @type {number}
     * @memberof ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow
     */
    benutzer?: number | null;
}

/**
 * AdresseApi - axios parameter creator
 * @export
 */
export const AdresseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Adresse resources.
         * @summary Retrieves the collection of Adresse resources.
         * @param {string} [query] Adresseingabe des Users
         * @param {boolean} [nrwOnly] Soll nur in NRW gesucht werden?
         * @param {string} [regionalKey] Regionalschlüssel, es wird nur im selben Gebiet gesucht
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdresselookupGetCollection: async (
            query?: string,
            nrwOnly?: boolean,
            regionalKey?: string,
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/adresse/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (nrwOnly !== undefined) {
                localVarQueryParameter['nrw_only'] = nrwOnly;
            }

            if (regionalKey !== undefined) {
                localVarQueryParameter['regional_key'] = regionalKey;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AdresseApi - functional programming interface
 * @export
 */
export const AdresseApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AdresseApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Adresse resources.
         * @summary Retrieves the collection of Adresse resources.
         * @param {string} [query] Adresseingabe des Users
         * @param {boolean} [nrwOnly] Soll nur in NRW gesucht werden?
         * @param {string} [regionalKey] Regionalschlüssel, es wird nur im selben Gebiet gesucht
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdresselookupGetCollection(
            query?: string,
            nrwOnly?: boolean,
            regionalKey?: string,
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAdresselookupGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdresselookupGetCollection(
                query,
                nrwOnly,
                regionalKey,
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AdresseApi - factory interface
 * @export
 */
export const AdresseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdresseApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Adresse resources.
         * @summary Retrieves the collection of Adresse resources.
         * @param {string} [query] Adresseingabe des Users
         * @param {boolean} [nrwOnly] Soll nur in NRW gesucht werden?
         * @param {string} [regionalKey] Regionalschlüssel, es wird nur im selben Gebiet gesucht
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdresselookupGetCollection(
            query?: string,
            nrwOnly?: boolean,
            regionalKey?: string,
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<ApiAdresselookupGetCollection200Response> {
            return localVarFp
                .apiAdresselookupGetCollection(query, nrwOnly, regionalKey, page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdresseApi - object-oriented interface
 * @export
 * @class AdresseApi
 * @extends {BaseAPI}
 */
export class AdresseApi extends BaseAPI {
    /**
     * Retrieves the collection of Adresse resources.
     * @summary Retrieves the collection of Adresse resources.
     * @param {string} [query] Adresseingabe des Users
     * @param {boolean} [nrwOnly] Soll nur in NRW gesucht werden?
     * @param {string} [regionalKey] Regionalschlüssel, es wird nur im selben Gebiet gesucht
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdresseApi
     */
    public apiAdresselookupGetCollection(
        query?: string,
        nrwOnly?: boolean,
        regionalKey?: string,
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return AdresseApiFp(this.configuration)
            .apiAdresselookupGetCollection(query, nrwOnly, regionalKey, page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * AngezeigteBetreuungFormApi - axios parameter creator
 * @export
 */
export const AngezeigteBetreuungFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a AngezeigteBetreuungForm resource.
         * @summary Retrieves a AngezeigteBetreuungForm resource.
         * @param {string} id AngezeigteBetreuungForm identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAngezeigteBetreuungFormIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAngezeigteBetreuungFormIdGet', 'id', id);
            const localVarPath = `/api/angezeigte-betreuung-form/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a AngezeigteBetreuungForm resource.
         * @summary Creates a AngezeigteBetreuungForm resource.
         * @param {AngezeigteBetreuungFormJsonld} angezeigteBetreuungFormJsonld The new AngezeigteBetreuungForm resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAngezeigteBetreuungFormPost: async (
            angezeigteBetreuungFormJsonld: AngezeigteBetreuungFormJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'angezeigteBetreuungFormJsonld' is not null or undefined
            assertParamExists(
                'apiAngezeigteBetreuungFormPost',
                'angezeigteBetreuungFormJsonld',
                angezeigteBetreuungFormJsonld
            );
            const localVarPath = `/api/angezeigte-betreuung-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                angezeigteBetreuungFormJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AngezeigteBetreuungFormApi - functional programming interface
 * @export
 */
export const AngezeigteBetreuungFormApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AngezeigteBetreuungFormApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a AngezeigteBetreuungForm resource.
         * @summary Retrieves a AngezeigteBetreuungForm resource.
         * @param {string} id AngezeigteBetreuungForm identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAngezeigteBetreuungFormIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAngezeigteBetreuungFormIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a AngezeigteBetreuungForm resource.
         * @summary Creates a AngezeigteBetreuungForm resource.
         * @param {AngezeigteBetreuungFormJsonld} angezeigteBetreuungFormJsonld The new AngezeigteBetreuungForm resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAngezeigteBetreuungFormPost(
            angezeigteBetreuungFormJsonld: AngezeigteBetreuungFormJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAngezeigteBetreuungFormPost(
                angezeigteBetreuungFormJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AngezeigteBetreuungFormApi - factory interface
 * @export
 */
export const AngezeigteBetreuungFormApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = AngezeigteBetreuungFormApiFp(configuration);
    return {
        /**
         * Retrieves a AngezeigteBetreuungForm resource.
         * @summary Retrieves a AngezeigteBetreuungForm resource.
         * @param {string} id AngezeigteBetreuungForm identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAngezeigteBetreuungFormIdGet(
            id: string,
            options?: any
        ): AxiosPromise<AngezeigteBetreuungFormAngezeigteBetreuungJsonFormJsonldAngezeigteBetreuungFormGetSimpleformJsonForm> {
            return localVarFp.apiAngezeigteBetreuungFormIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a AngezeigteBetreuungForm resource.
         * @summary Creates a AngezeigteBetreuungForm resource.
         * @param {AngezeigteBetreuungFormJsonld} angezeigteBetreuungFormJsonld The new AngezeigteBetreuungForm resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAngezeigteBetreuungFormPost(
            angezeigteBetreuungFormJsonld: AngezeigteBetreuungFormJsonld,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .apiAngezeigteBetreuungFormPost(angezeigteBetreuungFormJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AngezeigteBetreuungFormApi - object-oriented interface
 * @export
 * @class AngezeigteBetreuungFormApi
 * @extends {BaseAPI}
 */
export class AngezeigteBetreuungFormApi extends BaseAPI {
    /**
     * Retrieves a AngezeigteBetreuungForm resource.
     * @summary Retrieves a AngezeigteBetreuungForm resource.
     * @param {string} id AngezeigteBetreuungForm identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AngezeigteBetreuungFormApi
     */
    public apiAngezeigteBetreuungFormIdGet(id: string, options?: AxiosRequestConfig) {
        return AngezeigteBetreuungFormApiFp(this.configuration)
            .apiAngezeigteBetreuungFormIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a AngezeigteBetreuungForm resource.
     * @summary Creates a AngezeigteBetreuungForm resource.
     * @param {AngezeigteBetreuungFormJsonld} angezeigteBetreuungFormJsonld The new AngezeigteBetreuungForm resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AngezeigteBetreuungFormApi
     */
    public apiAngezeigteBetreuungFormPost(
        angezeigteBetreuungFormJsonld: AngezeigteBetreuungFormJsonld,
        options?: AxiosRequestConfig
    ) {
        return AngezeigteBetreuungFormApiFp(this.configuration)
            .apiAngezeigteBetreuungFormPost(angezeigteBetreuungFormJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BehoerdeApi - axios parameter creator
 * @export
 */
export const BehoerdeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Behoerde resources.
         * @summary Retrieves the collection of Behoerde resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderVerantwortlicheBehoerde]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderVerantwortlicheBehoerde?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/behoerde`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderVerantwortlicheBehoerde !== undefined) {
                localVarQueryParameter['order[verantwortlicheBehoerde]'] = orderVerantwortlicheBehoerde;
            }

            if (orderAdresseEmail !== undefined) {
                localVarQueryParameter['order[adresse.email]'] = orderAdresseEmail;
            }

            if (orderAdresseName !== undefined) {
                localVarQueryParameter['order[adresse.name]'] = orderAdresseName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Behoerde resource.
         * @summary Retrieves a Behoerde resource.
         * @param {string} id Behoerde identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBehoerdeIdGet', 'id', id);
            const localVarPath = `/api/behoerde/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Behoerde resource.
         * @summary Creates a Behoerde resource.
         * @param {BehoerdeJsonldBehoerdeWrite} behoerdeJsonldBehoerdeWrite The new Behoerde resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdePost: async (
            behoerdeJsonldBehoerdeWrite: BehoerdeJsonldBehoerdeWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'behoerdeJsonldBehoerdeWrite' is not null or undefined
            assertParamExists('apiBehoerdePost', 'behoerdeJsonldBehoerdeWrite', behoerdeJsonldBehoerdeWrite);
            const localVarPath = `/api/behoerde`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                behoerdeJsonldBehoerdeWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BehoerdeApi - functional programming interface
 * @export
 */
export const BehoerdeApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BehoerdeApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Behoerde resources.
         * @summary Retrieves the collection of Behoerde resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderVerantwortlicheBehoerde]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBehoerdeGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderVerantwortlicheBehoerde?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBehoerdeGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBehoerdeGetCollection(
                page,
                itemsPerPage,
                qSearch,
                orderName,
                orderVerantwortlicheBehoerde,
                orderAdresseEmail,
                orderAdresseName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Behoerde resource.
         * @summary Retrieves a Behoerde resource.
         * @param {string} id Behoerde identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBehoerdeIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BehoerdeJsonldBehoerdeReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBehoerdeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Behoerde resource.
         * @summary Creates a Behoerde resource.
         * @param {BehoerdeJsonldBehoerdeWrite} behoerdeJsonldBehoerdeWrite The new Behoerde resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBehoerdePost(
            behoerdeJsonldBehoerdeWrite: BehoerdeJsonldBehoerdeWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BehoerdeJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBehoerdePost(
                behoerdeJsonldBehoerdeWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BehoerdeApi - factory interface
 * @export
 */
export const BehoerdeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BehoerdeApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Behoerde resources.
         * @summary Retrieves the collection of Behoerde resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderVerantwortlicheBehoerde]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderVerantwortlicheBehoerde?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiBehoerdeGetCollection200Response> {
            return localVarFp
                .apiBehoerdeGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    orderName,
                    orderVerantwortlicheBehoerde,
                    orderAdresseEmail,
                    orderAdresseName,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Behoerde resource.
         * @summary Retrieves a Behoerde resource.
         * @param {string} id Behoerde identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeIdGet(id: string, options?: any): AxiosPromise<BehoerdeJsonldBehoerdeReadRolleEinrichtung> {
            return localVarFp.apiBehoerdeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Behoerde resource.
         * @summary Creates a Behoerde resource.
         * @param {BehoerdeJsonldBehoerdeWrite} behoerdeJsonldBehoerdeWrite The new Behoerde resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdePost(
            behoerdeJsonldBehoerdeWrite: BehoerdeJsonldBehoerdeWrite,
            options?: any
        ): AxiosPromise<BehoerdeJsonld> {
            return localVarFp
                .apiBehoerdePost(behoerdeJsonldBehoerdeWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BehoerdeApi - object-oriented interface
 * @export
 * @class BehoerdeApi
 * @extends {BaseAPI}
 */
export class BehoerdeApi extends BaseAPI {
    /**
     * Retrieves the collection of Behoerde resources.
     * @summary Retrieves the collection of Behoerde resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderVerantwortlicheBehoerde]
     * @param {'asc' | 'desc'} [orderAdresseEmail]
     * @param {'asc' | 'desc'} [orderAdresseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehoerdeApi
     */
    public apiBehoerdeGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderVerantwortlicheBehoerde?: 'asc' | 'desc',
        orderAdresseEmail?: 'asc' | 'desc',
        orderAdresseName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BehoerdeApiFp(this.configuration)
            .apiBehoerdeGetCollection(
                page,
                itemsPerPage,
                qSearch,
                orderName,
                orderVerantwortlicheBehoerde,
                orderAdresseEmail,
                orderAdresseName,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Behoerde resource.
     * @summary Retrieves a Behoerde resource.
     * @param {string} id Behoerde identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehoerdeApi
     */
    public apiBehoerdeIdGet(id: string, options?: AxiosRequestConfig) {
        return BehoerdeApiFp(this.configuration)
            .apiBehoerdeIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Behoerde resource.
     * @summary Creates a Behoerde resource.
     * @param {BehoerdeJsonldBehoerdeWrite} behoerdeJsonldBehoerdeWrite The new Behoerde resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehoerdeApi
     */
    public apiBehoerdePost(behoerdeJsonldBehoerdeWrite: BehoerdeJsonldBehoerdeWrite, options?: AxiosRequestConfig) {
        return BehoerdeApiFp(this.configuration)
            .apiBehoerdePost(behoerdeJsonldBehoerdeWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BehoerdeDtoApi - axios parameter creator
 * @export
 */
export const BehoerdeDtoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a BehoerdeDto resource.
         * @summary Retrieves a BehoerdeDto resource.
         * @param {string} id BehoerdeDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeDtoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBehoerdeDtoIdGet', 'id', id);
            const localVarPath = `/api/behoerde-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the BehoerdeDto resource.
         * @summary Updates the BehoerdeDto resource.
         * @param {string} id BehoerdeDto identifier
         * @param {BehoerdeDtoBehoerdeWriteRolleEinrichtung} behoerdeDtoBehoerdeWriteRolleEinrichtung The updated BehoerdeDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeDtoIdPatch: async (
            id: string,
            behoerdeDtoBehoerdeWriteRolleEinrichtung: BehoerdeDtoBehoerdeWriteRolleEinrichtung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBehoerdeDtoIdPatch', 'id', id);
            // verify required parameter 'behoerdeDtoBehoerdeWriteRolleEinrichtung' is not null or undefined
            assertParamExists(
                'apiBehoerdeDtoIdPatch',
                'behoerdeDtoBehoerdeWriteRolleEinrichtung',
                behoerdeDtoBehoerdeWriteRolleEinrichtung
            );
            const localVarPath = `/api/behoerde-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                behoerdeDtoBehoerdeWriteRolleEinrichtung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BehoerdeDtoApi - functional programming interface
 * @export
 */
export const BehoerdeDtoApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BehoerdeDtoApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a BehoerdeDto resource.
         * @summary Retrieves a BehoerdeDto resource.
         * @param {string} id BehoerdeDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBehoerdeDtoIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBehoerdeDtoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the BehoerdeDto resource.
         * @summary Updates the BehoerdeDto resource.
         * @param {string} id BehoerdeDto identifier
         * @param {BehoerdeDtoBehoerdeWriteRolleEinrichtung} behoerdeDtoBehoerdeWriteRolleEinrichtung The updated BehoerdeDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBehoerdeDtoIdPatch(
            id: string,
            behoerdeDtoBehoerdeWriteRolleEinrichtung: BehoerdeDtoBehoerdeWriteRolleEinrichtung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BehoerdeDtoJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBehoerdeDtoIdPatch(
                id,
                behoerdeDtoBehoerdeWriteRolleEinrichtung,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BehoerdeDtoApi - factory interface
 * @export
 */
export const BehoerdeDtoApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BehoerdeDtoApiFp(configuration);
    return {
        /**
         * Retrieves a BehoerdeDto resource.
         * @summary Retrieves a BehoerdeDto resource.
         * @param {string} id BehoerdeDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeDtoIdGet(id: string, options?: any): AxiosPromise<BehoerdeDtoJsonldBehoerdeReadRolleEinrichtung> {
            return localVarFp.apiBehoerdeDtoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the BehoerdeDto resource.
         * @summary Updates the BehoerdeDto resource.
         * @param {string} id BehoerdeDto identifier
         * @param {BehoerdeDtoBehoerdeWriteRolleEinrichtung} behoerdeDtoBehoerdeWriteRolleEinrichtung The updated BehoerdeDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBehoerdeDtoIdPatch(
            id: string,
            behoerdeDtoBehoerdeWriteRolleEinrichtung: BehoerdeDtoBehoerdeWriteRolleEinrichtung,
            options?: any
        ): AxiosPromise<BehoerdeDtoJsonld> {
            return localVarFp
                .apiBehoerdeDtoIdPatch(id, behoerdeDtoBehoerdeWriteRolleEinrichtung, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BehoerdeDtoApi - object-oriented interface
 * @export
 * @class BehoerdeDtoApi
 * @extends {BaseAPI}
 */
export class BehoerdeDtoApi extends BaseAPI {
    /**
     * Retrieves a BehoerdeDto resource.
     * @summary Retrieves a BehoerdeDto resource.
     * @param {string} id BehoerdeDto identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehoerdeDtoApi
     */
    public apiBehoerdeDtoIdGet(id: string, options?: AxiosRequestConfig) {
        return BehoerdeDtoApiFp(this.configuration)
            .apiBehoerdeDtoIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the BehoerdeDto resource.
     * @summary Updates the BehoerdeDto resource.
     * @param {string} id BehoerdeDto identifier
     * @param {BehoerdeDtoBehoerdeWriteRolleEinrichtung} behoerdeDtoBehoerdeWriteRolleEinrichtung The updated BehoerdeDto resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehoerdeDtoApi
     */
    public apiBehoerdeDtoIdPatch(
        id: string,
        behoerdeDtoBehoerdeWriteRolleEinrichtung: BehoerdeDtoBehoerdeWriteRolleEinrichtung,
        options?: AxiosRequestConfig
    ) {
        return BehoerdeDtoApiFp(this.configuration)
            .apiBehoerdeDtoIdPatch(id, behoerdeDtoBehoerdeWriteRolleEinrichtung, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BelegbarePlaetzeApi - axios parameter creator
 * @export
 */
export const BelegbarePlaetzeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a BelegbarePlaetze resource.
         * @summary Retrieves a BelegbarePlaetze resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzmelderDownloadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/platzmelderDownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of BelegbarePlaetze resources.
         * @summary Retrieves the collection of BelegbarePlaetze resources.
         * @param {string} token BelegbarePlaetze identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzmelderTokenGetCollection: async (
            token: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiPlatzmelderTokenGetCollection', 'token', token);
            const localVarPath = `/api/platzmelder/{token}`.replace(`{${'token'}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a BelegbarePlaetze resource.
         * @summary Creates a BelegbarePlaetze resource.
         * @param {string} token BelegbarePlaetze identifier
         * @param {object} body The new BelegbarePlaetze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzmelderTokenPost: async (
            token: string,
            body: object,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiPlatzmelderTokenPost', 'token', token);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiPlatzmelderTokenPost', 'body', body);
            const localVarPath = `/api/platzmelder/{token}`.replace(`{${'token'}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a BelegbarePlaetze resource.
         * @summary Retrieves a BelegbarePlaetze resource.
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBelegbarePlaetze: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBelegbarePlaetze', 'id', id);
            const localVarPath = `/api/belegbare-plaetze/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a BelegbarePlaetze resource.
         * @summary Creates a BelegbarePlaetze resource.
         * @param {object} body The new BelegbarePlaetze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBelegbarePlaetze: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('saveBelegbarePlaetze', 'body', body);
            const localVarPath = `/api/belegbare-plaetze`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BelegbarePlaetzeApi - functional programming interface
 * @export
 */
export const BelegbarePlaetzeApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BelegbarePlaetzeApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a BelegbarePlaetze resource.
         * @summary Retrieves a BelegbarePlaetze resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlatzmelderDownloadGet(
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BelegbarePlaetzeGeneriertesDokumentJsonld>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlatzmelderDownloadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of BelegbarePlaetze resources.
         * @summary Retrieves the collection of BelegbarePlaetze resources.
         * @param {string} token BelegbarePlaetze identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlatzmelderTokenGetCollection(
            token: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPlatzmelderTokenGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlatzmelderTokenGetCollection(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a BelegbarePlaetze resource.
         * @summary Creates a BelegbarePlaetze resource.
         * @param {string} token BelegbarePlaetze identifier
         * @param {object} body The new BelegbarePlaetze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlatzmelderTokenPost(
            token: string,
            body: object,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BelegbarePlaetzeJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlatzmelderTokenPost(token, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a BelegbarePlaetze resource.
         * @summary Retrieves a BelegbarePlaetze resource.
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBelegbarePlaetze(
            id: number,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBelegbarePlaetze(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a BelegbarePlaetze resource.
         * @summary Creates a BelegbarePlaetze resource.
         * @param {object} body The new BelegbarePlaetze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBelegbarePlaetze(
            body: object,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BelegbarePlaetzeJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBelegbarePlaetze(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BelegbarePlaetzeApi - factory interface
 * @export
 */
export const BelegbarePlaetzeApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BelegbarePlaetzeApiFp(configuration);
    return {
        /**
         * Retrieves a BelegbarePlaetze resource.
         * @summary Retrieves a BelegbarePlaetze resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzmelderDownloadGet(options?: any): AxiosPromise<BelegbarePlaetzeGeneriertesDokumentJsonld> {
            return localVarFp.apiPlatzmelderDownloadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of BelegbarePlaetze resources.
         * @summary Retrieves the collection of BelegbarePlaetze resources.
         * @param {string} token BelegbarePlaetze identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzmelderTokenGetCollection(
            token: string,
            options?: any
        ): AxiosPromise<ApiPlatzmelderTokenGetCollection200Response> {
            return localVarFp
                .apiPlatzmelderTokenGetCollection(token, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a BelegbarePlaetze resource.
         * @summary Creates a BelegbarePlaetze resource.
         * @param {string} token BelegbarePlaetze identifier
         * @param {object} body The new BelegbarePlaetze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzmelderTokenPost(token: string, body: object, options?: any): AxiosPromise<BelegbarePlaetzeJsonld> {
            return localVarFp.apiPlatzmelderTokenPost(token, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a BelegbarePlaetze resource.
         * @summary Retrieves a BelegbarePlaetze resource.
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBelegbarePlaetze(
            id: number,
            options?: any
        ): AxiosPromise<BelegbarePlaetzeBelegbarePlaetzeDetailsOutputJsonld> {
            return localVarFp.getBelegbarePlaetze(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a BelegbarePlaetze resource.
         * @summary Creates a BelegbarePlaetze resource.
         * @param {object} body The new BelegbarePlaetze resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBelegbarePlaetze(body: object, options?: any): AxiosPromise<BelegbarePlaetzeJsonld> {
            return localVarFp.saveBelegbarePlaetze(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BelegbarePlaetzeApi - object-oriented interface
 * @export
 * @class BelegbarePlaetzeApi
 * @extends {BaseAPI}
 */
export class BelegbarePlaetzeApi extends BaseAPI {
    /**
     * Retrieves a BelegbarePlaetze resource.
     * @summary Retrieves a BelegbarePlaetze resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelegbarePlaetzeApi
     */
    public apiPlatzmelderDownloadGet(options?: AxiosRequestConfig) {
        return BelegbarePlaetzeApiFp(this.configuration)
            .apiPlatzmelderDownloadGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of BelegbarePlaetze resources.
     * @summary Retrieves the collection of BelegbarePlaetze resources.
     * @param {string} token BelegbarePlaetze identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelegbarePlaetzeApi
     */
    public apiPlatzmelderTokenGetCollection(token: string, options?: AxiosRequestConfig) {
        return BelegbarePlaetzeApiFp(this.configuration)
            .apiPlatzmelderTokenGetCollection(token, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a BelegbarePlaetze resource.
     * @summary Creates a BelegbarePlaetze resource.
     * @param {string} token BelegbarePlaetze identifier
     * @param {object} body The new BelegbarePlaetze resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelegbarePlaetzeApi
     */
    public apiPlatzmelderTokenPost(token: string, body: object, options?: AxiosRequestConfig) {
        return BelegbarePlaetzeApiFp(this.configuration)
            .apiPlatzmelderTokenPost(token, body, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a BelegbarePlaetze resource.
     * @summary Retrieves a BelegbarePlaetze resource.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelegbarePlaetzeApi
     */
    public getBelegbarePlaetze(id: number, options?: AxiosRequestConfig) {
        return BelegbarePlaetzeApiFp(this.configuration)
            .getBelegbarePlaetze(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a BelegbarePlaetze resource.
     * @summary Creates a BelegbarePlaetze resource.
     * @param {object} body The new BelegbarePlaetze resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelegbarePlaetzeApi
     */
    public saveBelegbarePlaetze(body: object, options?: AxiosRequestConfig) {
        return BelegbarePlaetzeApiFp(this.configuration)
            .saveBelegbarePlaetze(body, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BelegbarePlaetzeHistorieApi - axios parameter creator
 * @export
 */
export const BelegbarePlaetzeHistorieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of BelegbarePlaetzeHistorie resources.
         * @summary Retrieves the collection of BelegbarePlaetzeHistorie resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        belegbarePlaetzeHistorie: async (
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            orderCreatedAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/belegbare-plaetze-historie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BelegbarePlaetzeHistorieApi - functional programming interface
 * @export
 */
export const BelegbarePlaetzeHistorieApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BelegbarePlaetzeHistorieApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of BelegbarePlaetzeHistorie resources.
         * @summary Retrieves the collection of BelegbarePlaetzeHistorie resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async belegbarePlaetzeHistorie(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            orderCreatedAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BelegbarePlaetzeHistorie200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.belegbarePlaetzeHistorie(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                orderCreatedAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BelegbarePlaetzeHistorieApi - factory interface
 * @export
 */
export const BelegbarePlaetzeHistorieApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BelegbarePlaetzeHistorieApiFp(configuration);
    return {
        /**
         * Retrieves the collection of BelegbarePlaetzeHistorie resources.
         * @summary Retrieves the collection of BelegbarePlaetzeHistorie resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        belegbarePlaetzeHistorie(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            orderCreatedAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<BelegbarePlaetzeHistorie200Response> {
            return localVarFp
                .belegbarePlaetzeHistorie(page, itemsPerPage, einrichtung, einrichtung2, orderCreatedAt, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BelegbarePlaetzeHistorieApi - object-oriented interface
 * @export
 * @class BelegbarePlaetzeHistorieApi
 * @extends {BaseAPI}
 */
export class BelegbarePlaetzeHistorieApi extends BaseAPI {
    /**
     * Retrieves the collection of BelegbarePlaetzeHistorie resources.
     * @summary Retrieves the collection of BelegbarePlaetzeHistorie resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {'asc' | 'desc'} [orderCreatedAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelegbarePlaetzeHistorieApi
     */
    public belegbarePlaetzeHistorie(
        page?: number,
        itemsPerPage?: number,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        orderCreatedAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BelegbarePlaetzeHistorieApiFp(this.configuration)
            .belegbarePlaetzeHistorie(page, itemsPerPage, einrichtung, einrichtung2, orderCreatedAt, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BenutzerApi - axios parameter creator
 * @export
 */
export const BenutzerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (existsDeaktiviertAt !== undefined) {
                localVarQueryParameter['exists[deaktiviertAt]'] = existsDeaktiviertAt;
            }

            if (existsDeletedAt !== undefined) {
                localVarQueryParameter['exists[deletedAt]'] = existsDeletedAt;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (rolle2) {
                localVarQueryParameter['rolle[]'] = rolle2;
            }

            if (landschaftsverband !== undefined) {
                localVarQueryParameter['landschaftsverband'] = landschaftsverband;
            }

            if (landschaftsverband2) {
                localVarQueryParameter['landschaftsverband[]'] = landschaftsverband2;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            if (bezirksregierung !== undefined) {
                localVarQueryParameter['bezirksregierung'] = bezirksregierung;
            }

            if (bezirksregierung2) {
                localVarQueryParameter['bezirksregierung[]'] = bezirksregierung2;
            }

            if (traeger !== undefined) {
                localVarQueryParameter['traeger'] = traeger;
            }

            if (traeger2) {
                localVarQueryParameter['traeger[]'] = traeger2;
            }

            if (einrichtungBenutzerEinrichtung !== undefined) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung'] = einrichtungBenutzerEinrichtung;
            }

            if (einrichtungBenutzerEinrichtung2) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung[]'] = einrichtungBenutzerEinrichtung2;
            }

            if (traegerverband !== undefined) {
                localVarQueryParameter['traegerverband'] = traegerverband;
            }

            if (traegerverband2) {
                localVarQueryParameter['traegerverband[]'] = traegerverband2;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (email2) {
                localVarQueryParameter['email[]'] = email2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (qImpersonateSearch !== undefined) {
                localVarQueryParameter['qImpersonateSearch'] = qImpersonateSearch;
            }

            if (orderNachname !== undefined) {
                localVarQueryParameter['order[nachname]'] = orderNachname;
            }

            if (orderVorname !== undefined) {
                localVarQueryParameter['order[vorname]'] = orderVorname;
            }

            if (orderEmail !== undefined) {
                localVarQueryParameter['order[email]'] = orderEmail;
            }

            if (orderDeaktiviertAt !== undefined) {
                localVarQueryParameter['order[deaktiviertAt]'] = orderDeaktiviertAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Benutzer resource.
         * @summary Removes the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerIdDelete', 'id', id);
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerIdGet', 'id', id);
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdPatch: async (
            id: string,
            benutzerProfileEdit: BenutzerProfileEdit,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerIdPatch', 'id', id);
            // verify required parameter 'benutzerProfileEdit' is not null or undefined
            assertParamExists('apiBenutzerIdPatch', 'benutzerProfileEdit', benutzerProfileEdit);
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerProfileEdit,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Benutzer resource.
         * @summary Replaces the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdPut: async (
            id: string,
            benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerIdPut', 'id', id);
            // verify required parameter 'benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger' is not null or undefined
            assertParamExists(
                'apiBenutzerIdPut',
                'benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger',
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger
            );
            const localVarPath = `/api/benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerAntragWorkflowActionAntragWorkflow} benutzerAntragWorkflowActionAntragWorkflow The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdworkflowPatch: async (
            id: string,
            benutzerAntragWorkflowActionAntragWorkflow: BenutzerAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerIdworkflowPatch', 'id', id);
            // verify required parameter 'benutzerAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiBenutzerIdworkflowPatch',
                'benutzerAntragWorkflowActionAntragWorkflow',
                benutzerAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/benutzer/{id}/workflow`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerListeGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzerListe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (existsDeaktiviertAt !== undefined) {
                localVarQueryParameter['exists[deaktiviertAt]'] = existsDeaktiviertAt;
            }

            if (existsDeletedAt !== undefined) {
                localVarQueryParameter['exists[deletedAt]'] = existsDeletedAt;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (rolle2) {
                localVarQueryParameter['rolle[]'] = rolle2;
            }

            if (landschaftsverband !== undefined) {
                localVarQueryParameter['landschaftsverband'] = landschaftsverband;
            }

            if (landschaftsverband2) {
                localVarQueryParameter['landschaftsverband[]'] = landschaftsverband2;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            if (bezirksregierung !== undefined) {
                localVarQueryParameter['bezirksregierung'] = bezirksregierung;
            }

            if (bezirksregierung2) {
                localVarQueryParameter['bezirksregierung[]'] = bezirksregierung2;
            }

            if (traeger !== undefined) {
                localVarQueryParameter['traeger'] = traeger;
            }

            if (traeger2) {
                localVarQueryParameter['traeger[]'] = traeger2;
            }

            if (einrichtungBenutzerEinrichtung !== undefined) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung'] = einrichtungBenutzerEinrichtung;
            }

            if (einrichtungBenutzerEinrichtung2) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung[]'] = einrichtungBenutzerEinrichtung2;
            }

            if (traegerverband !== undefined) {
                localVarQueryParameter['traegerverband'] = traegerverband;
            }

            if (traegerverband2) {
                localVarQueryParameter['traegerverband[]'] = traegerverband2;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (email2) {
                localVarQueryParameter['email[]'] = email2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (qImpersonateSearch !== undefined) {
                localVarQueryParameter['qImpersonateSearch'] = qImpersonateSearch;
            }

            if (orderNachname !== undefined) {
                localVarQueryParameter['order[nachname]'] = orderNachname;
            }

            if (orderVorname !== undefined) {
                localVarQueryParameter['order[vorname]'] = orderVorname;
            }

            if (orderEmail !== undefined) {
                localVarQueryParameter['order[email]'] = orderEmail;
            }

            if (orderDeaktiviertAt !== undefined) {
                localVarQueryParameter['order[deaktiviertAt]'] = orderDeaktiviertAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerListeZugriffsrechteGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzerListeZugriffsrechte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (existsDeaktiviertAt !== undefined) {
                localVarQueryParameter['exists[deaktiviertAt]'] = existsDeaktiviertAt;
            }

            if (existsDeletedAt !== undefined) {
                localVarQueryParameter['exists[deletedAt]'] = existsDeletedAt;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (rolle2) {
                localVarQueryParameter['rolle[]'] = rolle2;
            }

            if (landschaftsverband !== undefined) {
                localVarQueryParameter['landschaftsverband'] = landschaftsverband;
            }

            if (landschaftsverband2) {
                localVarQueryParameter['landschaftsverband[]'] = landschaftsverband2;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            if (bezirksregierung !== undefined) {
                localVarQueryParameter['bezirksregierung'] = bezirksregierung;
            }

            if (bezirksregierung2) {
                localVarQueryParameter['bezirksregierung[]'] = bezirksregierung2;
            }

            if (traeger !== undefined) {
                localVarQueryParameter['traeger'] = traeger;
            }

            if (traeger2) {
                localVarQueryParameter['traeger[]'] = traeger2;
            }

            if (einrichtungBenutzerEinrichtung !== undefined) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung'] = einrichtungBenutzerEinrichtung;
            }

            if (einrichtungBenutzerEinrichtung2) {
                localVarQueryParameter['einrichtungBenutzer.einrichtung[]'] = einrichtungBenutzerEinrichtung2;
            }

            if (traegerverband !== undefined) {
                localVarQueryParameter['traegerverband'] = traegerverband;
            }

            if (traegerverband2) {
                localVarQueryParameter['traegerverband[]'] = traegerverband2;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (email2) {
                localVarQueryParameter['email[]'] = email2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (qImpersonateSearch !== undefined) {
                localVarQueryParameter['qImpersonateSearch'] = qImpersonateSearch;
            }

            if (orderNachname !== undefined) {
                localVarQueryParameter['order[nachname]'] = orderNachname;
            }

            if (orderVorname !== undefined) {
                localVarQueryParameter['order[vorname]'] = orderVorname;
            }

            if (orderEmail !== undefined) {
                localVarQueryParameter['order[email]'] = orderEmail;
            }

            if (orderDeaktiviertAt !== undefined) {
                localVarQueryParameter['order[deaktiviertAt]'] = orderDeaktiviertAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Benutzer resource.
         * @summary Creates a Benutzer resource.
         * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerPost: async (
            benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerJsonldBenutzerCreate' is not null or undefined
            assertParamExists('apiBenutzerPost', 'benutzerJsonldBenutzerCreate', benutzerJsonldBenutzerCreate);
            const localVarPath = `/api/benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerJsonldBenutzerCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} email
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzergetByEmailEmailGet: async (
            email: string,
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiBenutzergetByEmailEmailGet', 'email', email);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzergetByEmailEmailGet', 'id', id);
            const localVarPath = `/api/benutzer/get-by-email/{email}`
                .replace(`{${'email'}}`, encodeURIComponent(String(email)))
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} hash BenutzerCredentialChange identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileaccountDeaktivierenHashGet: async (
            hash: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiProfileaccountDeaktivierenHashGet', 'hash', hash);
            const localVarPath = `/api/profile/account-deaktivieren/{hash}`.replace(
                `{${'hash'}}`,
                encodeURIComponent(String(hash))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileconfirmChangePatch: async (
            benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeConfirmInput' is not null or undefined
            assertParamExists(
                'apiProfileconfirmChangePatch',
                'benutzerCredentialChangeConfirmInput',
                benutzerCredentialChangeConfirmInput
            );
            const localVarPath = `/api/profile/confirm-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeConfirmInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileeditEmailPatch: async (
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeRequestInput' is not null or undefined
            assertParamExists(
                'apiProfileeditEmailPatch',
                'benutzerCredentialChangeRequestInput',
                benutzerCredentialChangeRequestInput
            );
            const localVarPath = `/api/profile/edit-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeRequestInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilelvAufgabenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/lv-aufgaben/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilepasswordResetRequestPatch: async (
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerCredentialChangeRequestInput' is not null or undefined
            assertParamExists(
                'apiProfilepasswordResetRequestPatch',
                'benutzerCredentialChangeRequestInput',
                benutzerCredentialChangeRequestInput
            );
            const localVarPath = `/api/profile/password-reset-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeRequestInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} token BenutzerCredentialChange identifier
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilepasswordResetTokenPatch: async (
            token: string,
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiProfilepasswordResetTokenPatch', 'token', token);
            // verify required parameter 'benutzerCredentialChangeRequestInput' is not null or undefined
            assertParamExists(
                'apiProfilepasswordResetTokenPatch',
                'benutzerCredentialChangeRequestInput',
                benutzerCredentialChangeRequestInput
            );
            const localVarPath = `/api/profile/password-reset/{token}`.replace(
                `{${'token'}}`,
                encodeURIComponent(String(token))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerCredentialChangeRequestInput,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BenutzerApi - functional programming interface
 * @export
 */
export const BenutzerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBenutzerGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Benutzer resource.
         * @summary Removes the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerIdPatch(
            id: string,
            benutzerProfileEdit: BenutzerProfileEdit,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerJsonldProfileEdit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerIdPatch(
                id,
                benutzerProfileEdit,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Benutzer resource.
         * @summary Replaces the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerIdPut(
            id: string,
            benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerIdPut(
                id,
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerAntragWorkflowActionAntragWorkflow} benutzerAntragWorkflowActionAntragWorkflow The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerIdworkflowPatch(
            id: string,
            benutzerAntragWorkflowActionAntragWorkflow: BenutzerAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerIdworkflowPatch(
                id,
                benutzerAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerListeGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBenutzerListeGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerListeGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerListeZugriffsrechteGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiBenutzerListeZugriffsrechteGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerListeZugriffsrechteGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Benutzer resource.
         * @summary Creates a Benutzer resource.
         * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerPost(
            benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerJsonldBenutzerCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerPost(
                benutzerJsonldBenutzerCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} email
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzergetByEmailEmailGet(
            email: string,
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzergetByEmailEmailGet(email, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfileGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerJsonldProfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} hash BenutzerCredentialChange identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfileaccountDeaktivierenHashGet(
            hash: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfileaccountDeaktivierenHashGet(
                hash,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfileconfirmChangePatch(
            benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfileconfirmChangePatch(
                benutzerCredentialChangeConfirmInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfileeditEmailPatch(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfileeditEmailPatch(
                benutzerCredentialChangeRequestInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilelvAufgabenGet(
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerAufgabenOutputJsonldBenutzerAufgaben>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilelvAufgabenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilepasswordResetRequestPatch(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilepasswordResetRequestPatch(
                benutzerCredentialChangeRequestInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} token BenutzerCredentialChange identifier
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilepasswordResetTokenPatch(
            token: string,
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilepasswordResetTokenPatch(
                token,
                benutzerCredentialChangeRequestInput,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BenutzerApi - factory interface
 * @export
 */
export const BenutzerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenutzerApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiBenutzerGetCollection200Response> {
            return localVarFp
                .apiBenutzerGetCollection(
                    page,
                    itemsPerPage,
                    existsDeaktiviertAt,
                    existsDeletedAt,
                    id,
                    id2,
                    rolle,
                    rolle2,
                    landschaftsverband,
                    landschaftsverband2,
                    behoerde,
                    behoerde2,
                    bezirksregierung,
                    bezirksregierung2,
                    traeger,
                    traeger2,
                    einrichtungBenutzerEinrichtung,
                    einrichtungBenutzerEinrichtung2,
                    traegerverband,
                    traegerverband2,
                    email,
                    email2,
                    qSearch,
                    qImpersonateSearch,
                    orderNachname,
                    orderVorname,
                    orderEmail,
                    orderDeaktiviertAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the Benutzer resource.
         * @summary Removes the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBenutzerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdGet(
            id: string,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp.apiBenutzerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdPatch(
            id: string,
            benutzerProfileEdit: BenutzerProfileEdit,
            options?: any
        ): AxiosPromise<BenutzerJsonldProfileEdit> {
            return localVarFp
                .apiBenutzerIdPatch(id, benutzerProfileEdit, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Benutzer resource.
         * @summary Replaces the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdPut(
            id: string,
            benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger> {
            return localVarFp
                .apiBenutzerIdPut(
                    id,
                    benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} id Benutzer identifier
         * @param {BenutzerAntragWorkflowActionAntragWorkflow} benutzerAntragWorkflowActionAntragWorkflow The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerIdworkflowPatch(
            id: string,
            benutzerAntragWorkflowActionAntragWorkflow: BenutzerAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .apiBenutzerIdworkflowPatch(id, benutzerAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerListeGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiBenutzerListeGetCollection200Response> {
            return localVarFp
                .apiBenutzerListeGetCollection(
                    page,
                    itemsPerPage,
                    existsDeaktiviertAt,
                    existsDeletedAt,
                    id,
                    id2,
                    rolle,
                    rolle2,
                    landschaftsverband,
                    landschaftsverband2,
                    behoerde,
                    behoerde2,
                    bezirksregierung,
                    bezirksregierung2,
                    traeger,
                    traeger2,
                    einrichtungBenutzerEinrichtung,
                    einrichtungBenutzerEinrichtung2,
                    traegerverband,
                    traegerverband2,
                    email,
                    email2,
                    qSearch,
                    qImpersonateSearch,
                    orderNachname,
                    orderVorname,
                    orderEmail,
                    orderDeaktiviertAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Benutzer resources.
         * @summary Retrieves the collection of Benutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsDeaktiviertAt]
         * @param {boolean} [existsDeletedAt]
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [rolle]
         * @param {Array<string>} [rolle2]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [bezirksregierung]
         * @param {Array<string>} [bezirksregierung2]
         * @param {string} [traeger]
         * @param {Array<string>} [traeger2]
         * @param {string} [einrichtungBenutzerEinrichtung]
         * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
         * @param {string} [traegerverband]
         * @param {Array<string>} [traegerverband2]
         * @param {string} [email]
         * @param {Array<string>} [email2]
         * @param {string} [qSearch]
         * @param {string} [qImpersonateSearch]
         * @param {'asc' | 'desc'} [orderNachname]
         * @param {'asc' | 'desc'} [orderVorname]
         * @param {'asc' | 'desc'} [orderEmail]
         * @param {'asc' | 'desc'} [orderDeaktiviertAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerListeZugriffsrechteGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsDeaktiviertAt?: boolean,
            existsDeletedAt?: boolean,
            id?: number,
            id2?: Array<number>,
            rolle?: string,
            rolle2?: Array<string>,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            bezirksregierung?: string,
            bezirksregierung2?: Array<string>,
            traeger?: string,
            traeger2?: Array<string>,
            einrichtungBenutzerEinrichtung?: string,
            einrichtungBenutzerEinrichtung2?: Array<string>,
            traegerverband?: string,
            traegerverband2?: Array<string>,
            email?: string,
            email2?: Array<string>,
            qSearch?: string,
            qImpersonateSearch?: string,
            orderNachname?: 'asc' | 'desc',
            orderVorname?: 'asc' | 'desc',
            orderEmail?: 'asc' | 'desc',
            orderDeaktiviertAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiBenutzerListeZugriffsrechteGetCollection200Response> {
            return localVarFp
                .apiBenutzerListeZugriffsrechteGetCollection(
                    page,
                    itemsPerPage,
                    existsDeaktiviertAt,
                    existsDeletedAt,
                    id,
                    id2,
                    rolle,
                    rolle2,
                    landschaftsverband,
                    landschaftsverband2,
                    behoerde,
                    behoerde2,
                    bezirksregierung,
                    bezirksregierung2,
                    traeger,
                    traeger2,
                    einrichtungBenutzerEinrichtung,
                    einrichtungBenutzerEinrichtung2,
                    traegerverband,
                    traegerverband2,
                    email,
                    email2,
                    qSearch,
                    qImpersonateSearch,
                    orderNachname,
                    orderVorname,
                    orderEmail,
                    orderDeaktiviertAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Benutzer resource.
         * @summary Creates a Benutzer resource.
         * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerPost(
            benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerCreate> {
            return localVarFp
                .apiBenutzerPost(benutzerJsonldBenutzerCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} email
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzergetByEmailEmailGet(
            email: string,
            id: string,
            options?: any
        ): AxiosPromise<BenutzerBenutzerGetByEmailOutputJsonldBenutzerGetByEmail> {
            return localVarFp
                .apiBenutzergetByEmailEmailGet(email, id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileGet(options?: any): AxiosPromise<BenutzerJsonldProfileRead> {
            return localVarFp.apiProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {string} hash BenutzerCredentialChange identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileaccountDeaktivierenHashGet(
            hash: string,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .apiProfileaccountDeaktivierenHashGet(hash, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileconfirmChangePatch(
            benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .apiProfileconfirmChangePatch(benutzerCredentialChangeConfirmInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfileeditEmailPatch(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .apiProfileeditEmailPatch(benutzerCredentialChangeRequestInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Benutzer resource.
         * @summary Retrieves a Benutzer resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilelvAufgabenGet(options?: any): AxiosPromise<BenutzerAufgabenOutputJsonldBenutzerAufgaben> {
            return localVarFp.apiProfilelvAufgabenGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilepasswordResetRequestPatch(
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .apiProfilepasswordResetRequestPatch(benutzerCredentialChangeRequestInput, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Benutzer resource.
         * @summary Updates the Benutzer resource.
         * @param {string} token BenutzerCredentialChange identifier
         * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilepasswordResetTokenPatch(
            token: string,
            benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
            options?: any
        ): AxiosPromise<BenutzerJsonldBenutzerReadBenutzerReadEmailBenutzerReadRoles> {
            return localVarFp
                .apiProfilepasswordResetTokenPatch(token, benutzerCredentialChangeRequestInput, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerApi - object-oriented interface
 * @export
 * @class BenutzerApi
 * @extends {BaseAPI}
 */
export class BenutzerApi extends BaseAPI {
    /**
     * Retrieves the collection of Benutzer resources.
     * @summary Retrieves the collection of Benutzer resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [existsDeaktiviertAt]
     * @param {boolean} [existsDeletedAt]
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [rolle]
     * @param {Array<string>} [rolle2]
     * @param {string} [landschaftsverband]
     * @param {Array<string>} [landschaftsverband2]
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {string} [bezirksregierung]
     * @param {Array<string>} [bezirksregierung2]
     * @param {string} [traeger]
     * @param {Array<string>} [traeger2]
     * @param {string} [einrichtungBenutzerEinrichtung]
     * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
     * @param {string} [traegerverband]
     * @param {Array<string>} [traegerverband2]
     * @param {string} [email]
     * @param {Array<string>} [email2]
     * @param {string} [qSearch]
     * @param {string} [qImpersonateSearch]
     * @param {'asc' | 'desc'} [orderNachname]
     * @param {'asc' | 'desc'} [orderVorname]
     * @param {'asc' | 'desc'} [orderEmail]
     * @param {'asc' | 'desc'} [orderDeaktiviertAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerGetCollection(
        page?: number,
        itemsPerPage?: number,
        existsDeaktiviertAt?: boolean,
        existsDeletedAt?: boolean,
        id?: number,
        id2?: Array<number>,
        rolle?: string,
        rolle2?: Array<string>,
        landschaftsverband?: string,
        landschaftsverband2?: Array<string>,
        behoerde?: string,
        behoerde2?: Array<string>,
        bezirksregierung?: string,
        bezirksregierung2?: Array<string>,
        traeger?: string,
        traeger2?: Array<string>,
        einrichtungBenutzerEinrichtung?: string,
        einrichtungBenutzerEinrichtung2?: Array<string>,
        traegerverband?: string,
        traegerverband2?: Array<string>,
        email?: string,
        email2?: Array<string>,
        qSearch?: string,
        qImpersonateSearch?: string,
        orderNachname?: 'asc' | 'desc',
        orderVorname?: 'asc' | 'desc',
        orderEmail?: 'asc' | 'desc',
        orderDeaktiviertAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Benutzer resource.
     * @summary Removes the Benutzer resource.
     * @param {string} id Benutzer identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerIdDelete(id: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {string} id Benutzer identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerIdGet(id: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {string} id Benutzer identifier
     * @param {BenutzerProfileEdit} benutzerProfileEdit The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerIdPatch(id: string, benutzerProfileEdit: BenutzerProfileEdit, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerIdPatch(id, benutzerProfileEdit, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Benutzer resource.
     * @summary Replaces the Benutzer resource.
     * @param {string} id Benutzer identifier
     * @param {BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger} benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerIdPut(
        id: string,
        benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger: BenutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerIdPut(
                id,
                benutzerJsonldBenutzerEditBenutzerEditRolleBenutzerEditLandschaftsverbandBenutzerEditTraegerverbandBenutzerEditTraeger,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {string} id Benutzer identifier
     * @param {BenutzerAntragWorkflowActionAntragWorkflow} benutzerAntragWorkflowActionAntragWorkflow The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerIdworkflowPatch(
        id: string,
        benutzerAntragWorkflowActionAntragWorkflow: BenutzerAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerIdworkflowPatch(id, benutzerAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Benutzer resources.
     * @summary Retrieves the collection of Benutzer resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [existsDeaktiviertAt]
     * @param {boolean} [existsDeletedAt]
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [rolle]
     * @param {Array<string>} [rolle2]
     * @param {string} [landschaftsverband]
     * @param {Array<string>} [landschaftsverband2]
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {string} [bezirksregierung]
     * @param {Array<string>} [bezirksregierung2]
     * @param {string} [traeger]
     * @param {Array<string>} [traeger2]
     * @param {string} [einrichtungBenutzerEinrichtung]
     * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
     * @param {string} [traegerverband]
     * @param {Array<string>} [traegerverband2]
     * @param {string} [email]
     * @param {Array<string>} [email2]
     * @param {string} [qSearch]
     * @param {string} [qImpersonateSearch]
     * @param {'asc' | 'desc'} [orderNachname]
     * @param {'asc' | 'desc'} [orderVorname]
     * @param {'asc' | 'desc'} [orderEmail]
     * @param {'asc' | 'desc'} [orderDeaktiviertAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerListeGetCollection(
        page?: number,
        itemsPerPage?: number,
        existsDeaktiviertAt?: boolean,
        existsDeletedAt?: boolean,
        id?: number,
        id2?: Array<number>,
        rolle?: string,
        rolle2?: Array<string>,
        landschaftsverband?: string,
        landschaftsverband2?: Array<string>,
        behoerde?: string,
        behoerde2?: Array<string>,
        bezirksregierung?: string,
        bezirksregierung2?: Array<string>,
        traeger?: string,
        traeger2?: Array<string>,
        einrichtungBenutzerEinrichtung?: string,
        einrichtungBenutzerEinrichtung2?: Array<string>,
        traegerverband?: string,
        traegerverband2?: Array<string>,
        email?: string,
        email2?: Array<string>,
        qSearch?: string,
        qImpersonateSearch?: string,
        orderNachname?: 'asc' | 'desc',
        orderVorname?: 'asc' | 'desc',
        orderEmail?: 'asc' | 'desc',
        orderDeaktiviertAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerListeGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Benutzer resources.
     * @summary Retrieves the collection of Benutzer resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [existsDeaktiviertAt]
     * @param {boolean} [existsDeletedAt]
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [rolle]
     * @param {Array<string>} [rolle2]
     * @param {string} [landschaftsverband]
     * @param {Array<string>} [landschaftsverband2]
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {string} [bezirksregierung]
     * @param {Array<string>} [bezirksregierung2]
     * @param {string} [traeger]
     * @param {Array<string>} [traeger2]
     * @param {string} [einrichtungBenutzerEinrichtung]
     * @param {Array<string>} [einrichtungBenutzerEinrichtung2]
     * @param {string} [traegerverband]
     * @param {Array<string>} [traegerverband2]
     * @param {string} [email]
     * @param {Array<string>} [email2]
     * @param {string} [qSearch]
     * @param {string} [qImpersonateSearch]
     * @param {'asc' | 'desc'} [orderNachname]
     * @param {'asc' | 'desc'} [orderVorname]
     * @param {'asc' | 'desc'} [orderEmail]
     * @param {'asc' | 'desc'} [orderDeaktiviertAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerListeZugriffsrechteGetCollection(
        page?: number,
        itemsPerPage?: number,
        existsDeaktiviertAt?: boolean,
        existsDeletedAt?: boolean,
        id?: number,
        id2?: Array<number>,
        rolle?: string,
        rolle2?: Array<string>,
        landschaftsverband?: string,
        landschaftsverband2?: Array<string>,
        behoerde?: string,
        behoerde2?: Array<string>,
        bezirksregierung?: string,
        bezirksregierung2?: Array<string>,
        traeger?: string,
        traeger2?: Array<string>,
        einrichtungBenutzerEinrichtung?: string,
        einrichtungBenutzerEinrichtung2?: Array<string>,
        traegerverband?: string,
        traegerverband2?: Array<string>,
        email?: string,
        email2?: Array<string>,
        qSearch?: string,
        qImpersonateSearch?: string,
        orderNachname?: 'asc' | 'desc',
        orderVorname?: 'asc' | 'desc',
        orderEmail?: 'asc' | 'desc',
        orderDeaktiviertAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerListeZugriffsrechteGetCollection(
                page,
                itemsPerPage,
                existsDeaktiviertAt,
                existsDeletedAt,
                id,
                id2,
                rolle,
                rolle2,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                bezirksregierung,
                bezirksregierung2,
                traeger,
                traeger2,
                einrichtungBenutzerEinrichtung,
                einrichtungBenutzerEinrichtung2,
                traegerverband,
                traegerverband2,
                email,
                email2,
                qSearch,
                qImpersonateSearch,
                orderNachname,
                orderVorname,
                orderEmail,
                orderDeaktiviertAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Benutzer resource.
     * @summary Creates a Benutzer resource.
     * @param {BenutzerJsonldBenutzerCreate} benutzerJsonldBenutzerCreate The new Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzerPost(benutzerJsonldBenutzerCreate: BenutzerJsonldBenutzerCreate, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzerPost(benutzerJsonldBenutzerCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {string} email
     * @param {string} id Benutzer identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiBenutzergetByEmailEmailGet(email: string, id: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiBenutzergetByEmailEmailGet(email, id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfileGet(options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiProfileGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {string} hash BenutzerCredentialChange identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfileaccountDeaktivierenHashGet(hash: string, options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiProfileaccountDeaktivierenHashGet(hash, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeConfirmInput} benutzerCredentialChangeConfirmInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfileconfirmChangePatch(
        benutzerCredentialChangeConfirmInput: BenutzerCredentialChangeConfirmInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiProfileconfirmChangePatch(benutzerCredentialChangeConfirmInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfileeditEmailPatch(
        benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiProfileeditEmailPatch(benutzerCredentialChangeRequestInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Benutzer resource.
     * @summary Retrieves a Benutzer resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfilelvAufgabenGet(options?: AxiosRequestConfig) {
        return BenutzerApiFp(this.configuration)
            .apiProfilelvAufgabenGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfilepasswordResetRequestPatch(
        benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiProfilepasswordResetRequestPatch(benutzerCredentialChangeRequestInput, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Benutzer resource.
     * @summary Updates the Benutzer resource.
     * @param {string} token BenutzerCredentialChange identifier
     * @param {BenutzerCredentialChangeRequestInput} benutzerCredentialChangeRequestInput The updated Benutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public apiProfilepasswordResetTokenPatch(
        token: string,
        benutzerCredentialChangeRequestInput: BenutzerCredentialChangeRequestInput,
        options?: AxiosRequestConfig
    ) {
        return BenutzerApiFp(this.configuration)
            .apiProfilepasswordResetTokenPatch(token, benutzerCredentialChangeRequestInput, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BenutzerDtoApi - axios parameter creator
 * @export
 */
export const BenutzerDtoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a BenutzerDto resource.
         * @summary Retrieves a BenutzerDto resource.
         * @param {string} id BenutzerDTO identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerDtoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerDtoIdGet', 'id', id);
            const localVarPath = `/api/benutzer-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BenutzerDtoApi - functional programming interface
 * @export
 */
export const BenutzerDtoApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerDtoApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a BenutzerDto resource.
         * @summary Retrieves a BenutzerDto resource.
         * @param {string} id BenutzerDTO identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerDtoIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerDtoJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerDtoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BenutzerDtoApi - factory interface
 * @export
 */
export const BenutzerDtoApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BenutzerDtoApiFp(configuration);
    return {
        /**
         * Retrieves a BenutzerDto resource.
         * @summary Retrieves a BenutzerDto resource.
         * @param {string} id BenutzerDTO identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerDtoIdGet(id: string, options?: any): AxiosPromise<BenutzerDtoJsonld> {
            return localVarFp.apiBenutzerDtoIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerDtoApi - object-oriented interface
 * @export
 * @class BenutzerDtoApi
 * @extends {BaseAPI}
 */
export class BenutzerDtoApi extends BaseAPI {
    /**
     * Retrieves a BenutzerDto resource.
     * @summary Retrieves a BenutzerDto resource.
     * @param {string} id BenutzerDTO identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerDtoApi
     */
    public apiBenutzerDtoIdGet(id: string, options?: AxiosRequestConfig) {
        return BenutzerDtoApiFp(this.configuration)
            .apiBenutzerDtoIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BenutzerFormApi - axios parameter creator
 * @export
 */
export const BenutzerFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces the BenutzerForm resource.
         * @summary Replaces the BenutzerForm resource.
         * @param {BenutzerFormJsonld} benutzerFormJsonld The updated BenutzerForm resource
         * @param {boolean} [persist]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerFormPut: async (
            benutzerFormJsonld: BenutzerFormJsonld,
            persist?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerFormJsonld' is not null or undefined
            assertParamExists('apiBenutzerFormPut', 'benutzerFormJsonld', benutzerFormJsonld);
            const localVarPath = `/api/benutzer-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerFormJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a BenutzerForm resource.
         * @summary Retrieves a BenutzerForm resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benutzerFormGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzer-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a BenutzerForm resource.
         * @summary Retrieves a BenutzerForm resource.
         * @param {string} id BenutzerForm identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benutzerFormGetUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('benutzerFormGetUser', 'id', id);
            const localVarPath = `/api/benutzer_form/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BenutzerFormApi - functional programming interface
 * @export
 */
export const BenutzerFormApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerFormApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces the BenutzerForm resource.
         * @summary Replaces the BenutzerForm resource.
         * @param {BenutzerFormJsonld} benutzerFormJsonld The updated BenutzerForm resource
         * @param {boolean} [persist]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerFormPut(
            benutzerFormJsonld: BenutzerFormJsonld,
            persist?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerFormPut(
                benutzerFormJsonld,
                persist,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a BenutzerForm resource.
         * @summary Retrieves a BenutzerForm resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async benutzerFormGet(
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.benutzerFormGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a BenutzerForm resource.
         * @summary Retrieves a BenutzerForm resource.
         * @param {string} id BenutzerForm identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async benutzerFormGetUser(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.benutzerFormGetUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BenutzerFormApi - factory interface
 * @export
 */
export const BenutzerFormApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BenutzerFormApiFp(configuration);
    return {
        /**
         * Replaces the BenutzerForm resource.
         * @summary Replaces the BenutzerForm resource.
         * @param {BenutzerFormJsonld} benutzerFormJsonld The updated BenutzerForm resource
         * @param {boolean} [persist]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerFormPut(
            benutzerFormJsonld: BenutzerFormJsonld,
            persist?: boolean,
            options?: any
        ): AxiosPromise<BenutzerFormBenutzerJsonFormJsonldSimpleformJsonForm> {
            return localVarFp
                .apiBenutzerFormPut(benutzerFormJsonld, persist, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a BenutzerForm resource.
         * @summary Retrieves a BenutzerForm resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benutzerFormGet(
            options?: any
        ): AxiosPromise<BenutzerFormBenutzerJsonFormJsonldBenutzerFormGetSimpleformJsonForm> {
            return localVarFp.benutzerFormGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a BenutzerForm resource.
         * @summary Retrieves a BenutzerForm resource.
         * @param {string} id BenutzerForm identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benutzerFormGetUser(id: string, options?: any): AxiosPromise<BenutzerForm> {
            return localVarFp.benutzerFormGetUser(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerFormApi - object-oriented interface
 * @export
 * @class BenutzerFormApi
 * @extends {BaseAPI}
 */
export class BenutzerFormApi extends BaseAPI {
    /**
     * Replaces the BenutzerForm resource.
     * @summary Replaces the BenutzerForm resource.
     * @param {BenutzerFormJsonld} benutzerFormJsonld The updated BenutzerForm resource
     * @param {boolean} [persist]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerFormApi
     */
    public apiBenutzerFormPut(benutzerFormJsonld: BenutzerFormJsonld, persist?: boolean, options?: AxiosRequestConfig) {
        return BenutzerFormApiFp(this.configuration)
            .apiBenutzerFormPut(benutzerFormJsonld, persist, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a BenutzerForm resource.
     * @summary Retrieves a BenutzerForm resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerFormApi
     */
    public benutzerFormGet(options?: AxiosRequestConfig) {
        return BenutzerFormApiFp(this.configuration)
            .benutzerFormGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a BenutzerForm resource.
     * @summary Retrieves a BenutzerForm resource.
     * @param {string} id BenutzerForm identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerFormApi
     */
    public benutzerFormGetUser(id: string, options?: AxiosRequestConfig) {
        return BenutzerFormApiFp(this.configuration)
            .benutzerFormGetUser(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BenutzerTablePreferenceApi - axios parameter creator
 * @export
 */
export const BenutzerTablePreferenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of BenutzerTablePreference resources.
         * @summary Retrieves the collection of BenutzerTablePreference resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {Array<string>} [name2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            name?: string,
            name2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/benutzer-table-preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (name2) {
                localVarQueryParameter['name[]'] = name2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the BenutzerTablePreference resource.
         * @summary Removes the BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceIdDelete: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerTablePreferenceIdDelete', 'id', id);
            const localVarPath = `/api/benutzer-table-preference/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a BenutzerTablePreference resource.
         * @summary Retrieves a BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerTablePreferenceIdGet', 'id', id);
            const localVarPath = `/api/benutzer-table-preference/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the BenutzerTablePreference resource.
         * @summary Updates the BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {BenutzerTablePreference} benutzerTablePreference The updated BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceIdPatch: async (
            id: string,
            benutzerTablePreference: BenutzerTablePreference,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBenutzerTablePreferenceIdPatch', 'id', id);
            // verify required parameter 'benutzerTablePreference' is not null or undefined
            assertParamExists('apiBenutzerTablePreferenceIdPatch', 'benutzerTablePreference', benutzerTablePreference);
            const localVarPath = `/api/benutzer-table-preference/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerTablePreference,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a BenutzerTablePreference resource.
         * @summary Creates a BenutzerTablePreference resource.
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferencePost: async (
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerTablePreferenceJsonld' is not null or undefined
            assertParamExists(
                'apiBenutzerTablePreferencePost',
                'benutzerTablePreferenceJsonld',
                benutzerTablePreferenceJsonld
            );
            const localVarPath = `/api/benutzer-table-preference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                benutzerTablePreferenceJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BenutzerTablePreferenceApi - functional programming interface
 * @export
 */
export const BenutzerTablePreferenceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerTablePreferenceApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of BenutzerTablePreference resources.
         * @summary Retrieves the collection of BenutzerTablePreference resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {Array<string>} [name2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerTablePreferenceGetCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            name2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiBenutzerTablePreferenceGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerTablePreferenceGetCollection(
                page,
                itemsPerPage,
                name,
                name2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the BenutzerTablePreference resource.
         * @summary Removes the BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerTablePreferenceIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerTablePreferenceIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a BenutzerTablePreference resource.
         * @summary Retrieves a BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerTablePreferenceIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerTablePreferenceJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerTablePreferenceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the BenutzerTablePreference resource.
         * @summary Updates the BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {BenutzerTablePreference} benutzerTablePreference The updated BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerTablePreferenceIdPatch(
            id: string,
            benutzerTablePreference: BenutzerTablePreference,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerTablePreferenceJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerTablePreferenceIdPatch(
                id,
                benutzerTablePreference,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a BenutzerTablePreference resource.
         * @summary Creates a BenutzerTablePreference resource.
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerTablePreferencePost(
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerTablePreferenceJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerTablePreferencePost(
                benutzerTablePreferenceJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BenutzerTablePreferenceApi - factory interface
 * @export
 */
export const BenutzerTablePreferenceApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BenutzerTablePreferenceApiFp(configuration);
    return {
        /**
         * Retrieves the collection of BenutzerTablePreference resources.
         * @summary Retrieves the collection of BenutzerTablePreference resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {Array<string>} [name2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceGetCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            name2?: Array<string>,
            options?: any
        ): AxiosPromise<ApiBenutzerTablePreferenceGetCollection200Response> {
            return localVarFp
                .apiBenutzerTablePreferenceGetCollection(page, itemsPerPage, name, name2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the BenutzerTablePreference resource.
         * @summary Removes the BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .apiBenutzerTablePreferenceIdDelete(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a BenutzerTablePreference resource.
         * @summary Retrieves a BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceIdGet(id: string, options?: any): AxiosPromise<BenutzerTablePreferenceJsonld> {
            return localVarFp.apiBenutzerTablePreferenceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the BenutzerTablePreference resource.
         * @summary Updates the BenutzerTablePreference resource.
         * @param {string} id BenutzerTablePreference identifier
         * @param {BenutzerTablePreference} benutzerTablePreference The updated BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferenceIdPatch(
            id: string,
            benutzerTablePreference: BenutzerTablePreference,
            options?: any
        ): AxiosPromise<BenutzerTablePreferenceJsonld> {
            return localVarFp
                .apiBenutzerTablePreferenceIdPatch(id, benutzerTablePreference, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a BenutzerTablePreference resource.
         * @summary Creates a BenutzerTablePreference resource.
         * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerTablePreferencePost(
            benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
            options?: any
        ): AxiosPromise<BenutzerTablePreferenceJsonld> {
            return localVarFp
                .apiBenutzerTablePreferencePost(benutzerTablePreferenceJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerTablePreferenceApi - object-oriented interface
 * @export
 * @class BenutzerTablePreferenceApi
 * @extends {BaseAPI}
 */
export class BenutzerTablePreferenceApi extends BaseAPI {
    /**
     * Retrieves the collection of BenutzerTablePreference resources.
     * @summary Retrieves the collection of BenutzerTablePreference resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [name]
     * @param {Array<string>} [name2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public apiBenutzerTablePreferenceGetCollection(
        page?: number,
        itemsPerPage?: number,
        name?: string,
        name2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .apiBenutzerTablePreferenceGetCollection(page, itemsPerPage, name, name2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the BenutzerTablePreference resource.
     * @summary Removes the BenutzerTablePreference resource.
     * @param {string} id BenutzerTablePreference identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public apiBenutzerTablePreferenceIdDelete(id: string, options?: AxiosRequestConfig) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .apiBenutzerTablePreferenceIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a BenutzerTablePreference resource.
     * @summary Retrieves a BenutzerTablePreference resource.
     * @param {string} id BenutzerTablePreference identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public apiBenutzerTablePreferenceIdGet(id: string, options?: AxiosRequestConfig) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .apiBenutzerTablePreferenceIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the BenutzerTablePreference resource.
     * @summary Updates the BenutzerTablePreference resource.
     * @param {string} id BenutzerTablePreference identifier
     * @param {BenutzerTablePreference} benutzerTablePreference The updated BenutzerTablePreference resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public apiBenutzerTablePreferenceIdPatch(
        id: string,
        benutzerTablePreference: BenutzerTablePreference,
        options?: AxiosRequestConfig
    ) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .apiBenutzerTablePreferenceIdPatch(id, benutzerTablePreference, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a BenutzerTablePreference resource.
     * @summary Creates a BenutzerTablePreference resource.
     * @param {BenutzerTablePreferenceJsonld} benutzerTablePreferenceJsonld The new BenutzerTablePreference resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerTablePreferenceApi
     */
    public apiBenutzerTablePreferencePost(
        benutzerTablePreferenceJsonld: BenutzerTablePreferenceJsonld,
        options?: AxiosRequestConfig
    ) {
        return BenutzerTablePreferenceApiFp(this.configuration)
            .apiBenutzerTablePreferencePost(benutzerTablePreferenceJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * BezirksregierungApi - axios parameter creator
 * @export
 */
export const BezirksregierungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Bezirksregierung resources.
         * @summary Retrieves the collection of Bezirksregierung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {'asc' | 'desc'} [orderName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            name?: string,
            orderName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/bezirksregierung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Bezirksregierung resource.
         * @summary Removes the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBezirksregierungIdDelete', 'id', id);
            const localVarPath = `/api/bezirksregierung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Bezirksregierung resource.
         * @summary Retrieves a Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBezirksregierungIdGet', 'id', id);
            const localVarPath = `/api/bezirksregierung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Bezirksregierung resource.
         * @summary Updates the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {BezirksregierungBezirksregierungWrite} bezirksregierungBezirksregierungWrite The updated Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdPatch: async (
            id: string,
            bezirksregierungBezirksregierungWrite: BezirksregierungBezirksregierungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBezirksregierungIdPatch', 'id', id);
            // verify required parameter 'bezirksregierungBezirksregierungWrite' is not null or undefined
            assertParamExists(
                'apiBezirksregierungIdPatch',
                'bezirksregierungBezirksregierungWrite',
                bezirksregierungBezirksregierungWrite
            );
            const localVarPath = `/api/bezirksregierung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                bezirksregierungBezirksregierungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Bezirksregierung resource.
         * @summary Replaces the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The updated Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdPut: async (
            id: string,
            bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBezirksregierungIdPut', 'id', id);
            // verify required parameter 'bezirksregierungJsonldBezirksregierungWrite' is not null or undefined
            assertParamExists(
                'apiBezirksregierungIdPut',
                'bezirksregierungJsonldBezirksregierungWrite',
                bezirksregierungJsonldBezirksregierungWrite
            );
            const localVarPath = `/api/bezirksregierung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                bezirksregierungJsonldBezirksregierungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Bezirksregierung resource.
         * @summary Creates a Bezirksregierung resource.
         * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The new Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungPost: async (
            bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'bezirksregierungJsonldBezirksregierungWrite' is not null or undefined
            assertParamExists(
                'apiBezirksregierungPost',
                'bezirksregierungJsonldBezirksregierungWrite',
                bezirksregierungJsonldBezirksregierungWrite
            );
            const localVarPath = `/api/bezirksregierung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                bezirksregierungJsonldBezirksregierungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BezirksregierungApi - functional programming interface
 * @export
 */
export const BezirksregierungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BezirksregierungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Bezirksregierung resources.
         * @summary Retrieves the collection of Bezirksregierung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {'asc' | 'desc'} [orderName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBezirksregierungGetCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            orderName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBezirksregierungGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBezirksregierungGetCollection(
                page,
                itemsPerPage,
                name,
                orderName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Bezirksregierung resource.
         * @summary Removes the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBezirksregierungIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBezirksregierungIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Bezirksregierung resource.
         * @summary Retrieves a Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBezirksregierungIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BezirksregierungJsonldBezirksregierungRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBezirksregierungIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Bezirksregierung resource.
         * @summary Updates the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {BezirksregierungBezirksregierungWrite} bezirksregierungBezirksregierungWrite The updated Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBezirksregierungIdPatch(
            id: string,
            bezirksregierungBezirksregierungWrite: BezirksregierungBezirksregierungWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BezirksregierungJsonldBezirksregierungRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBezirksregierungIdPatch(
                id,
                bezirksregierungBezirksregierungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Bezirksregierung resource.
         * @summary Replaces the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The updated Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBezirksregierungIdPut(
            id: string,
            bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BezirksregierungJsonldBezirksregierungRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBezirksregierungIdPut(
                id,
                bezirksregierungJsonldBezirksregierungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Bezirksregierung resource.
         * @summary Creates a Bezirksregierung resource.
         * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The new Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBezirksregierungPost(
            bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BezirksregierungJsonldBezirksregierungRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBezirksregierungPost(
                bezirksregierungJsonldBezirksregierungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BezirksregierungApi - factory interface
 * @export
 */
export const BezirksregierungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BezirksregierungApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Bezirksregierung resources.
         * @summary Retrieves the collection of Bezirksregierung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {'asc' | 'desc'} [orderName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungGetCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            orderName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiBezirksregierungGetCollection200Response> {
            return localVarFp
                .apiBezirksregierungGetCollection(page, itemsPerPage, name, orderName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the Bezirksregierung resource.
         * @summary Removes the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBezirksregierungIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Bezirksregierung resource.
         * @summary Retrieves a Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdGet(id: string, options?: any): AxiosPromise<BezirksregierungJsonldBezirksregierungRead> {
            return localVarFp.apiBezirksregierungIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Bezirksregierung resource.
         * @summary Updates the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {BezirksregierungBezirksregierungWrite} bezirksregierungBezirksregierungWrite The updated Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdPatch(
            id: string,
            bezirksregierungBezirksregierungWrite: BezirksregierungBezirksregierungWrite,
            options?: any
        ): AxiosPromise<BezirksregierungJsonldBezirksregierungRead> {
            return localVarFp
                .apiBezirksregierungIdPatch(id, bezirksregierungBezirksregierungWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Bezirksregierung resource.
         * @summary Replaces the Bezirksregierung resource.
         * @param {string} id Bezirksregierung identifier
         * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The updated Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungIdPut(
            id: string,
            bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
            options?: any
        ): AxiosPromise<BezirksregierungJsonldBezirksregierungRead> {
            return localVarFp
                .apiBezirksregierungIdPut(id, bezirksregierungJsonldBezirksregierungWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Bezirksregierung resource.
         * @summary Creates a Bezirksregierung resource.
         * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The new Bezirksregierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBezirksregierungPost(
            bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
            options?: any
        ): AxiosPromise<BezirksregierungJsonldBezirksregierungRead> {
            return localVarFp
                .apiBezirksregierungPost(bezirksregierungJsonldBezirksregierungWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BezirksregierungApi - object-oriented interface
 * @export
 * @class BezirksregierungApi
 * @extends {BaseAPI}
 */
export class BezirksregierungApi extends BaseAPI {
    /**
     * Retrieves the collection of Bezirksregierung resources.
     * @summary Retrieves the collection of Bezirksregierung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [name]
     * @param {'asc' | 'desc'} [orderName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BezirksregierungApi
     */
    public apiBezirksregierungGetCollection(
        page?: number,
        itemsPerPage?: number,
        name?: string,
        orderName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return BezirksregierungApiFp(this.configuration)
            .apiBezirksregierungGetCollection(page, itemsPerPage, name, orderName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Bezirksregierung resource.
     * @summary Removes the Bezirksregierung resource.
     * @param {string} id Bezirksregierung identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BezirksregierungApi
     */
    public apiBezirksregierungIdDelete(id: string, options?: AxiosRequestConfig) {
        return BezirksregierungApiFp(this.configuration)
            .apiBezirksregierungIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Bezirksregierung resource.
     * @summary Retrieves a Bezirksregierung resource.
     * @param {string} id Bezirksregierung identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BezirksregierungApi
     */
    public apiBezirksregierungIdGet(id: string, options?: AxiosRequestConfig) {
        return BezirksregierungApiFp(this.configuration)
            .apiBezirksregierungIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Bezirksregierung resource.
     * @summary Updates the Bezirksregierung resource.
     * @param {string} id Bezirksregierung identifier
     * @param {BezirksregierungBezirksregierungWrite} bezirksregierungBezirksregierungWrite The updated Bezirksregierung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BezirksregierungApi
     */
    public apiBezirksregierungIdPatch(
        id: string,
        bezirksregierungBezirksregierungWrite: BezirksregierungBezirksregierungWrite,
        options?: AxiosRequestConfig
    ) {
        return BezirksregierungApiFp(this.configuration)
            .apiBezirksregierungIdPatch(id, bezirksregierungBezirksregierungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Bezirksregierung resource.
     * @summary Replaces the Bezirksregierung resource.
     * @param {string} id Bezirksregierung identifier
     * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The updated Bezirksregierung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BezirksregierungApi
     */
    public apiBezirksregierungIdPut(
        id: string,
        bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
        options?: AxiosRequestConfig
    ) {
        return BezirksregierungApiFp(this.configuration)
            .apiBezirksregierungIdPut(id, bezirksregierungJsonldBezirksregierungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Bezirksregierung resource.
     * @summary Creates a Bezirksregierung resource.
     * @param {BezirksregierungJsonldBezirksregierungWrite} bezirksregierungJsonldBezirksregierungWrite The new Bezirksregierung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BezirksregierungApi
     */
    public apiBezirksregierungPost(
        bezirksregierungJsonldBezirksregierungWrite: BezirksregierungJsonldBezirksregierungWrite,
        options?: AxiosRequestConfig
    ) {
        return BezirksregierungApiFp(this.configuration)
            .apiBezirksregierungPost(bezirksregierungJsonldBezirksregierungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EinrichtungApi - axios parameter creator
 * @export
 */
export const EinrichtungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuswertung44VWtgGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/auswertung44VWtg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuswertungStandardCsvGetCollection: async (
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/auswertungStandardCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungCollectionexportCsvGetCollection: async (
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtungCollection/exportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungIdGet', 'id', id);
            const localVarPath = `/api/einrichtung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungEinrichtungWrite} einrichtungEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdPatch: async (
            id: string,
            einrichtungEinrichtungWrite: EinrichtungEinrichtungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungIdPatch', 'id', id);
            // verify required parameter 'einrichtungEinrichtungWrite' is not null or undefined
            assertParamExists('apiEinrichtungIdPatch', 'einrichtungEinrichtungWrite', einrichtungEinrichtungWrite);
            const localVarPath = `/api/einrichtung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungEinrichtungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdPut: async (
            id: string,
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungIdPut', 'id', id);
            // verify required parameter 'einrichtungJsonldEinrichtungWrite' is not null or undefined
            assertParamExists(
                'apiEinrichtungIdPut',
                'einrichtungJsonldEinrichtungWrite',
                einrichtungJsonldEinrichtungWrite
            );
            const localVarPath = `/api/einrichtung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungJsonldEinrichtungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungWorkflowActionAntragWorkflow} einrichtungWorkflowActionAntragWorkflow The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdworkflowPatch: async (
            id: string,
            einrichtungWorkflowActionAntragWorkflow: EinrichtungWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungIdworkflowPatch', 'id', id);
            // verify required parameter 'einrichtungWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiEinrichtungIdworkflowPatch',
                'einrichtungWorkflowActionAntragWorkflow',
                einrichtungWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/einrichtung/{id}/workflow`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Einrichtung resource.
         * @summary Creates a Einrichtung resource.
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The new Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungPost: async (
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'einrichtungJsonldEinrichtungWrite' is not null or undefined
            assertParamExists(
                'apiEinrichtungPost',
                'einrichtungJsonldEinrichtungWrite',
                einrichtungJsonldEinrichtungWrite
            );
            const localVarPath = `/api/einrichtung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungJsonldEinrichtungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} uuid Einrichtung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungUuidexportGet: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('apiEinrichtungUuidexportGet', 'uuid', uuid);
            const localVarPath = `/api/einrichtung/{uuid}/export`.replace(
                `{${'uuid'}}`,
                encodeURIComponent(String(uuid))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {'csv' | 'xlsx'} [format] Format des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungCsvGet: async (
            format?: 'csv' | 'xlsx',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/regelpruefungCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} [qSearch] Volltextsuche
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auswertung44VWtgCsv: async (qSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auswertung44VWtgCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForFem: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtungCollection/listForFem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForMeldung: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtungCollection/listForMeldung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForPlatzzahlmelder: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtungCollection/listForPlatzzahlmelder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForRegelpruefung: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtungCollection/listForRegelpruefung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }

            if (art2) {
                localVarQueryParameter['art[]'] = art2;
            }

            if (wohngemeinschaftLeistungsbereich !== undefined) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich'] = wohngemeinschaftLeistungsbereich;
            }

            if (wohngemeinschaftLeistungsbereich2) {
                localVarQueryParameter['wohngemeinschaftLeistungsbereich[]'] = wohngemeinschaftLeistungsbereich2;
            }

            if (wohngemeinschaftArt !== undefined) {
                localVarQueryParameter['wohngemeinschaftArt'] = wohngemeinschaftArt;
            }

            if (wohngemeinschaftArt2) {
                localVarQueryParameter['wohngemeinschaftArt[]'] = wohngemeinschaftArt2;
            }

            if (adresseCx !== undefined) {
                localVarQueryParameter['adresse.cx'] = adresseCx;
            }

            if (adresseCx2) {
                localVarQueryParameter['adresse.cx[]'] = adresseCx2;
            }

            if (adresseCy !== undefined) {
                localVarQueryParameter['adresse.cy'] = adresseCy;
            }

            if (adresseCy2) {
                localVarQueryParameter['adresse.cy[]'] = adresseCy2;
            }

            if (existsEnabled !== undefined) {
                localVarQueryParameter['exists[enabled]'] = existsEnabled;
            }

            if (existsActivated !== undefined) {
                localVarQueryParameter['exists[activated]'] = existsActivated;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (meldungenStatus !== undefined) {
                localVarQueryParameter['meldungenStatus'] = meldungenStatus;
            }

            if (zustaendigerBenutzer) {
                localVarQueryParameter['zustaendigerBenutzer[]'] = zustaendigerBenutzer;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (belegbarePlaetzeStatus !== undefined) {
                localVarQueryParameter['belegbarePlaetzeStatus'] = belegbarePlaetzeStatus;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderBelegbarePlaetzeModifiedAt !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.modifiedAt]'] = orderBelegbarePlaetzeModifiedAt;
            }

            if (orderBelegbarePlaetzeAufgefordert !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.aufgefordert]'] = orderBelegbarePlaetzeAufgefordert;
            }

            if (orderBelegbarePlaetzeDauerpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.dauerpflege]'] = orderBelegbarePlaetzeDauerpflege;
            }

            if (orderBelegbarePlaetzeKurzzeitpflege !== undefined) {
                localVarQueryParameter['order[belegbarePlaetze.kurzzeitpflege]'] = orderBelegbarePlaetzeKurzzeitpflege;
            }

            if (orderFemAntraegeAnzahlGerichtlicherGenehmigung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlGerichtlicherGenehmigung]'] =
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung;
            }

            if (orderFemAntraegeAnzahlEinwilligung !== undefined) {
                localVarQueryParameter['order[femAntraege.anzahlEinwilligung]'] = orderFemAntraegeAnzahlEinwilligung;
            }

            if (orderFemAntraegeZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[femAntraege.zuletztEingereichtAt]'] =
                    orderFemAntraegeZuletztEingereichtAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            if (orderUpdateDeadline !== undefined) {
                localVarQueryParameter['order[update_deadline]'] = orderUpdateDeadline;
            }

            if (orderArt !== undefined) {
                localVarQueryParameter['order[art]'] = orderArt;
            }

            if (orderFemUeberfaellig !== undefined) {
                localVarQueryParameter['order[femUeberfaellig]'] = orderFemUeberfaellig;
            }

            if (orderFemErinnerung !== undefined) {
                localVarQueryParameter['order[femErinnerung]'] = orderFemErinnerung;
            }

            if (orderLetzteMeldung !== undefined) {
                localVarQueryParameter['order[letzteMeldung]'] = orderLetzteMeldung;
            }

            if (orderZustaendig !== undefined) {
                localVarQueryParameter['order[zustaendig]'] = orderZustaendig;
            }

            if (orderRegelpruefungenPruefDatum !== undefined) {
                localVarQueryParameter['order[regelpruefungen.pruefDatum]'] = orderRegelpruefungenPruefDatum;
            }

            if (orderRegelpruefungenLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[regelpruefungen.letzterUploadAt]'] = orderRegelpruefungenLetzterUploadAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EinrichtungApi - functional programming interface
 * @export
 */
export const EinrichtungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EinrichtungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuswertung44VWtgGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAuswertung44VWtgGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuswertung44VWtgGetCollection(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuswertungStandardCsvGetCollection(
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAuswertungStandardCsvGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuswertungStandardCsvGetCollection(
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungCollectionexportCsvGetCollection(
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiEinrichtungCollectionexportCsvGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungCollectionexportCsvGetCollection(
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiEinrichtungGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungGetCollection(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungReadAdresseRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungEinrichtungWrite} einrichtungEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungIdPatch(
            id: string,
            einrichtungEinrichtungWrite: EinrichtungEinrichtungWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungIdPatch(
                id,
                einrichtungEinrichtungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungIdPut(
            id: string,
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungIdPut(
                id,
                einrichtungJsonldEinrichtungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungWorkflowActionAntragWorkflow} einrichtungWorkflowActionAntragWorkflow The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungIdworkflowPatch(
            id: string,
            einrichtungWorkflowActionAntragWorkflow: EinrichtungWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungIdworkflowPatch(
                id,
                einrichtungWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Einrichtung resource.
         * @summary Creates a Einrichtung resource.
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The new Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungPost(
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungJsonldEinrichtungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungPost(
                einrichtungJsonldEinrichtungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} uuid Einrichtung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungUuidexportGet(
            uuid: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungUuidexportGet(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {'csv' | 'xlsx'} [format] Format des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungCsvGet(
            format?: 'csv' | 'xlsx',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungExportRequestJsonldExportRequest>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungCsvGet(format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} [qSearch] Volltextsuche
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auswertung44VWtgCsv(
            qSearch?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungExportRequestJsonldExportRequest>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auswertung44VWtgCsv(qSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungenForFem(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEinrichtungenForFem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungenForFem(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungenForMeldung(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEinrichtungenForMeldung200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungenForMeldung(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungenForPlatzzahlmelder(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEinrichtungenForPlatzzahlmelder200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungenForPlatzzahlmelder(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinrichtungenForRegelpruefung(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEinrichtungenForRegelpruefung200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinrichtungenForRegelpruefung(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EinrichtungApi - factory interface
 * @export
 */
export const EinrichtungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EinrichtungApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuswertung44VWtgGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiAuswertung44VWtgGetCollection200Response> {
            return localVarFp
                .apiAuswertung44VWtgGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuswertungStandardCsvGetCollection(
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiAuswertungStandardCsvGetCollection200Response> {
            return localVarFp
                .apiAuswertungStandardCsvGetCollection(
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungCollectionexportCsvGetCollection(
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiEinrichtungCollectionexportCsvGetCollection200Response> {
            return localVarFp
                .apiEinrichtungCollectionexportCsvGetCollection(
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiEinrichtungGetCollection200Response> {
            return localVarFp
                .apiEinrichtungGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdGet(id: string, options?: any): AxiosPromise<EinrichtungJsonldEinrichtungReadAdresseRead> {
            return localVarFp.apiEinrichtungIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungEinrichtungWrite} einrichtungEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdPatch(
            id: string,
            einrichtungEinrichtungWrite: EinrichtungEinrichtungWrite,
            options?: any
        ): AxiosPromise<EinrichtungJsonldEinrichtungRead> {
            return localVarFp
                .apiEinrichtungIdPatch(id, einrichtungEinrichtungWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Einrichtung resource.
         * @summary Replaces the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdPut(
            id: string,
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options?: any
        ): AxiosPromise<EinrichtungJsonldEinrichtungRead> {
            return localVarFp
                .apiEinrichtungIdPut(id, einrichtungJsonldEinrichtungWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Einrichtung resource.
         * @summary Updates the Einrichtung resource.
         * @param {string} id Einrichtung identifier
         * @param {EinrichtungWorkflowActionAntragWorkflow} einrichtungWorkflowActionAntragWorkflow The updated Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungIdworkflowPatch(
            id: string,
            einrichtungWorkflowActionAntragWorkflow: EinrichtungWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<EinrichtungJsonld> {
            return localVarFp
                .apiEinrichtungIdworkflowPatch(id, einrichtungWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Einrichtung resource.
         * @summary Creates a Einrichtung resource.
         * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The new Einrichtung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungPost(
            einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
            options?: any
        ): AxiosPromise<EinrichtungJsonldEinrichtungRead> {
            return localVarFp
                .apiEinrichtungPost(einrichtungJsonldEinrichtungWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} uuid Einrichtung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungUuidexportGet(
            uuid: string,
            options?: any
        ): AxiosPromise<EinrichtungPfadInvestEinrichtungOutputJsonldInvestRead> {
            return localVarFp.apiEinrichtungUuidexportGet(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {'csv' | 'xlsx'} [format] Format des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungCsvGet(
            format?: 'csv' | 'xlsx',
            options?: any
        ): AxiosPromise<EinrichtungExportRequestJsonldExportRequest> {
            return localVarFp.apiRegelpruefungCsvGet(format, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Einrichtung resource.
         * @summary Retrieves a Einrichtung resource.
         * @param {string} [qSearch] Volltextsuche
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auswertung44VWtgCsv(
            qSearch?: string,
            options?: any
        ): AxiosPromise<EinrichtungExportRequestJsonldExportRequest> {
            return localVarFp.auswertung44VWtgCsv(qSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForFem(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<GetEinrichtungenForFem200Response> {
            return localVarFp
                .getEinrichtungenForFem(
                    page,
                    itemsPerPage,
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForMeldung(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<GetEinrichtungenForMeldung200Response> {
            return localVarFp
                .getEinrichtungenForMeldung(
                    page,
                    itemsPerPage,
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForPlatzzahlmelder(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<GetEinrichtungenForPlatzzahlmelder200Response> {
            return localVarFp
                .getEinrichtungenForPlatzzahlmelder(
                    page,
                    itemsPerPage,
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Einrichtung resources.
         * @summary Retrieves the collection of Einrichtung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [art]
         * @param {Array<string>} [art2]
         * @param {string} [wohngemeinschaftLeistungsbereich]
         * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
         * @param {string} [wohngemeinschaftArt]
         * @param {Array<string>} [wohngemeinschaftArt2]
         * @param {string} [adresseCx]
         * @param {Array<string>} [adresseCx2]
         * @param {string} [adresseCy]
         * @param {Array<string>} [adresseCy2]
         * @param {boolean} [existsEnabled]
         * @param {boolean} [existsActivated]
         * @param {boolean} [existsDeleted]
         * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
         * @param {Array<number>} [zustaendigerBenutzer]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
         * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
         * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
         * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {'asc' | 'desc'} [orderUpdateDeadline]
         * @param {'asc' | 'desc'} [orderArt]
         * @param {'asc' | 'desc'} [orderFemUeberfaellig]
         * @param {'asc' | 'desc'} [orderFemErinnerung]
         * @param {'asc' | 'desc'} [orderLetzteMeldung]
         * @param {'asc' | 'desc'} [orderZustaendig]
         * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
         * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinrichtungenForRegelpruefung(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            status?: string,
            status2?: Array<string>,
            art?: string,
            art2?: Array<string>,
            wohngemeinschaftLeistungsbereich?: string,
            wohngemeinschaftLeistungsbereich2?: Array<string>,
            wohngemeinschaftArt?: string,
            wohngemeinschaftArt2?: Array<string>,
            adresseCx?: string,
            adresseCx2?: Array<string>,
            adresseCy?: string,
            adresseCy2?: Array<string>,
            existsEnabled?: boolean,
            existsActivated?: boolean,
            existsDeleted?: boolean,
            meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
            zustaendigerBenutzer?: Array<number>,
            keinZustaendigerBenutzer?: boolean,
            belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
            orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
            orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
            orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
            orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
            orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
            orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            orderUpdateDeadline?: 'asc' | 'desc',
            orderArt?: 'asc' | 'desc',
            orderFemUeberfaellig?: 'asc' | 'desc',
            orderFemErinnerung?: 'asc' | 'desc',
            orderLetzteMeldung?: 'asc' | 'desc',
            orderZustaendig?: 'asc' | 'desc',
            orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
            orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<GetEinrichtungenForRegelpruefung200Response> {
            return localVarFp
                .getEinrichtungenForRegelpruefung(
                    page,
                    itemsPerPage,
                    qSearch,
                    status,
                    status2,
                    art,
                    art2,
                    wohngemeinschaftLeistungsbereich,
                    wohngemeinschaftLeistungsbereich2,
                    wohngemeinschaftArt,
                    wohngemeinschaftArt2,
                    adresseCx,
                    adresseCx2,
                    adresseCy,
                    adresseCy2,
                    existsEnabled,
                    existsActivated,
                    existsDeleted,
                    meldungenStatus,
                    zustaendigerBenutzer,
                    keinZustaendigerBenutzer,
                    belegbarePlaetzeStatus,
                    orderName,
                    orderNrwKey,
                    orderErstinbetriebnahme,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderBelegbarePlaetzeModifiedAt,
                    orderBelegbarePlaetzeAufgefordert,
                    orderBelegbarePlaetzeDauerpflege,
                    orderBelegbarePlaetzeKurzzeitpflege,
                    orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                    orderFemAntraegeAnzahlEinwilligung,
                    orderFemAntraegeZuletztEingereichtAt,
                    orderDeleted,
                    orderUpdateDeadline,
                    orderArt,
                    orderFemUeberfaellig,
                    orderFemErinnerung,
                    orderLetzteMeldung,
                    orderZustaendig,
                    orderRegelpruefungenPruefDatum,
                    orderRegelpruefungenLetzterUploadAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EinrichtungApi - object-oriented interface
 * @export
 * @class EinrichtungApi
 * @extends {BaseAPI}
 */
export class EinrichtungApi extends BaseAPI {
    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiAuswertung44VWtgGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiAuswertung44VWtgGetCollection(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiAuswertungStandardCsvGetCollection(
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiAuswertungStandardCsvGetCollection(
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungCollectionexportCsvGetCollection(
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungCollectionexportCsvGetCollection(
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungGetCollection(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} id Einrichtung identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungIdGet(id: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Einrichtung resource.
     * @summary Updates the Einrichtung resource.
     * @param {string} id Einrichtung identifier
     * @param {EinrichtungEinrichtungWrite} einrichtungEinrichtungWrite The updated Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungIdPatch(
        id: string,
        einrichtungEinrichtungWrite: EinrichtungEinrichtungWrite,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungIdPatch(id, einrichtungEinrichtungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Einrichtung resource.
     * @summary Replaces the Einrichtung resource.
     * @param {string} id Einrichtung identifier
     * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The updated Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungIdPut(
        id: string,
        einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungIdPut(id, einrichtungJsonldEinrichtungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Einrichtung resource.
     * @summary Updates the Einrichtung resource.
     * @param {string} id Einrichtung identifier
     * @param {EinrichtungWorkflowActionAntragWorkflow} einrichtungWorkflowActionAntragWorkflow The updated Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungIdworkflowPatch(
        id: string,
        einrichtungWorkflowActionAntragWorkflow: EinrichtungWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungIdworkflowPatch(id, einrichtungWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Einrichtung resource.
     * @summary Creates a Einrichtung resource.
     * @param {EinrichtungJsonldEinrichtungWrite} einrichtungJsonldEinrichtungWrite The new Einrichtung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungPost(
        einrichtungJsonldEinrichtungWrite: EinrichtungJsonldEinrichtungWrite,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungPost(einrichtungJsonldEinrichtungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} uuid Einrichtung identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiEinrichtungUuidexportGet(uuid: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .apiEinrichtungUuidexportGet(uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {'csv' | 'xlsx'} [format] Format des Dokuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public apiRegelpruefungCsvGet(format?: 'csv' | 'xlsx', options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .apiRegelpruefungCsvGet(format, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Einrichtung resource.
     * @summary Retrieves a Einrichtung resource.
     * @param {string} [qSearch] Volltextsuche
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public auswertung44VWtgCsv(qSearch?: string, options?: AxiosRequestConfig) {
        return EinrichtungApiFp(this.configuration)
            .auswertung44VWtgCsv(qSearch, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public getEinrichtungenForFem(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .getEinrichtungenForFem(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public getEinrichtungenForMeldung(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .getEinrichtungenForMeldung(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public getEinrichtungenForPlatzzahlmelder(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .getEinrichtungenForPlatzzahlmelder(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Einrichtung resources.
     * @summary Retrieves the collection of Einrichtung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [art]
     * @param {Array<string>} [art2]
     * @param {string} [wohngemeinschaftLeistungsbereich]
     * @param {Array<string>} [wohngemeinschaftLeistungsbereich2]
     * @param {string} [wohngemeinschaftArt]
     * @param {Array<string>} [wohngemeinschaftArt2]
     * @param {string} [adresseCx]
     * @param {Array<string>} [adresseCx2]
     * @param {string} [adresseCy]
     * @param {Array<string>} [adresseCy2]
     * @param {boolean} [existsEnabled]
     * @param {boolean} [existsActivated]
     * @param {boolean} [existsDeleted]
     * @param {'KEINE' | 'AUFGEFORDERT'} [meldungenStatus]
     * @param {Array<number>} [zustaendigerBenutzer]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {'EINGEREICHT' | 'AUSSTEHEND'} [belegbarePlaetzeStatus]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeModifiedAt]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeAufgefordert]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeDauerpflege]
     * @param {'asc' | 'desc'} [orderBelegbarePlaetzeKurzzeitpflege]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlGerichtlicherGenehmigung]
     * @param {'asc' | 'desc'} [orderFemAntraegeAnzahlEinwilligung]
     * @param {'asc' | 'desc'} [orderFemAntraegeZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {'asc' | 'desc'} [orderUpdateDeadline]
     * @param {'asc' | 'desc'} [orderArt]
     * @param {'asc' | 'desc'} [orderFemUeberfaellig]
     * @param {'asc' | 'desc'} [orderFemErinnerung]
     * @param {'asc' | 'desc'} [orderLetzteMeldung]
     * @param {'asc' | 'desc'} [orderZustaendig]
     * @param {'asc' | 'desc'} [orderRegelpruefungenPruefDatum]
     * @param {'asc' | 'desc'} [orderRegelpruefungenLetzterUploadAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungApi
     */
    public getEinrichtungenForRegelpruefung(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        status?: string,
        status2?: Array<string>,
        art?: string,
        art2?: Array<string>,
        wohngemeinschaftLeistungsbereich?: string,
        wohngemeinschaftLeistungsbereich2?: Array<string>,
        wohngemeinschaftArt?: string,
        wohngemeinschaftArt2?: Array<string>,
        adresseCx?: string,
        adresseCx2?: Array<string>,
        adresseCy?: string,
        adresseCy2?: Array<string>,
        existsEnabled?: boolean,
        existsActivated?: boolean,
        existsDeleted?: boolean,
        meldungenStatus?: 'KEINE' | 'AUFGEFORDERT',
        zustaendigerBenutzer?: Array<number>,
        keinZustaendigerBenutzer?: boolean,
        belegbarePlaetzeStatus?: 'EINGEREICHT' | 'AUSSTEHEND',
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderBelegbarePlaetzeModifiedAt?: 'asc' | 'desc',
        orderBelegbarePlaetzeAufgefordert?: 'asc' | 'desc',
        orderBelegbarePlaetzeDauerpflege?: 'asc' | 'desc',
        orderBelegbarePlaetzeKurzzeitpflege?: 'asc' | 'desc',
        orderFemAntraegeAnzahlGerichtlicherGenehmigung?: 'asc' | 'desc',
        orderFemAntraegeAnzahlEinwilligung?: 'asc' | 'desc',
        orderFemAntraegeZuletztEingereichtAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        orderUpdateDeadline?: 'asc' | 'desc',
        orderArt?: 'asc' | 'desc',
        orderFemUeberfaellig?: 'asc' | 'desc',
        orderFemErinnerung?: 'asc' | 'desc',
        orderLetzteMeldung?: 'asc' | 'desc',
        orderZustaendig?: 'asc' | 'desc',
        orderRegelpruefungenPruefDatum?: 'asc' | 'desc',
        orderRegelpruefungenLetzterUploadAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EinrichtungApiFp(this.configuration)
            .getEinrichtungenForRegelpruefung(
                page,
                itemsPerPage,
                qSearch,
                status,
                status2,
                art,
                art2,
                wohngemeinschaftLeistungsbereich,
                wohngemeinschaftLeistungsbereich2,
                wohngemeinschaftArt,
                wohngemeinschaftArt2,
                adresseCx,
                adresseCx2,
                adresseCy,
                adresseCy2,
                existsEnabled,
                existsActivated,
                existsDeleted,
                meldungenStatus,
                zustaendigerBenutzer,
                keinZustaendigerBenutzer,
                belegbarePlaetzeStatus,
                orderName,
                orderNrwKey,
                orderErstinbetriebnahme,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderBelegbarePlaetzeModifiedAt,
                orderBelegbarePlaetzeAufgefordert,
                orderBelegbarePlaetzeDauerpflege,
                orderBelegbarePlaetzeKurzzeitpflege,
                orderFemAntraegeAnzahlGerichtlicherGenehmigung,
                orderFemAntraegeAnzahlEinwilligung,
                orderFemAntraegeZuletztEingereichtAt,
                orderDeleted,
                orderUpdateDeadline,
                orderArt,
                orderFemUeberfaellig,
                orderFemErinnerung,
                orderLetzteMeldung,
                orderZustaendig,
                orderRegelpruefungenPruefDatum,
                orderRegelpruefungenLetzterUploadAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EinrichtungBenutzerApi - axios parameter creator
 * @export
 */
export const EinrichtungBenutzerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of EinrichtungBenutzer resources.
         * @summary Retrieves the collection of EinrichtungBenutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/einrichtung-benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (benutzer !== undefined) {
                localVarQueryParameter['benutzer'] = benutzer;
            }

            if (benutzer2) {
                localVarQueryParameter['benutzer[]'] = benutzer2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the EinrichtungBenutzer resource.
         * @summary Removes the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerIdDelete', 'id', id);
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a EinrichtungBenutzer resource.
         * @summary Retrieves a EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerIdGet', 'id', id);
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the EinrichtungBenutzer resource.
         * @summary Updates the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {EinrichtungBenutzer} einrichtungBenutzer The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdPatch: async (
            id: string,
            einrichtungBenutzer: EinrichtungBenutzer,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerIdPatch', 'id', id);
            // verify required parameter 'einrichtungBenutzer' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerIdPatch', 'einrichtungBenutzer', einrichtungBenutzer);
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungBenutzer,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the EinrichtungBenutzer resource.
         * @summary Replaces the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdPut: async (
            id: string,
            einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerIdPut', 'id', id);
            // verify required parameter 'einrichtungBenutzerJsonld' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerIdPut', 'einrichtungBenutzerJsonld', einrichtungBenutzerJsonld);
            const localVarPath = `/api/einrichtung-benutzer/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungBenutzerJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a EinrichtungBenutzer resource.
         * @summary Creates a EinrichtungBenutzer resource.
         * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The new EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerPost: async (
            einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'einrichtungBenutzerJsonld' is not null or undefined
            assertParamExists('apiEinrichtungBenutzerPost', 'einrichtungBenutzerJsonld', einrichtungBenutzerJsonld);
            const localVarPath = `/api/einrichtung-benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                einrichtungBenutzerJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EinrichtungBenutzerApi - functional programming interface
 * @export
 */
export const EinrichtungBenutzerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EinrichtungBenutzerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of EinrichtungBenutzer resources.
         * @summary Retrieves the collection of EinrichtungBenutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungBenutzerGetCollection(
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiEinrichtungBenutzerGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungBenutzerGetCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                einrichtung,
                einrichtung2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the EinrichtungBenutzer resource.
         * @summary Removes the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungBenutzerIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungBenutzerIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a EinrichtungBenutzer resource.
         * @summary Retrieves a EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungBenutzerIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungBenutzerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the EinrichtungBenutzer resource.
         * @summary Updates the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {EinrichtungBenutzer} einrichtungBenutzer The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungBenutzerIdPatch(
            id: string,
            einrichtungBenutzer: EinrichtungBenutzer,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungBenutzerIdPatch(
                id,
                einrichtungBenutzer,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the EinrichtungBenutzer resource.
         * @summary Replaces the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungBenutzerIdPut(
            id: string,
            einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungBenutzerIdPut(
                id,
                einrichtungBenutzerJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a EinrichtungBenutzer resource.
         * @summary Creates a EinrichtungBenutzer resource.
         * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The new EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEinrichtungBenutzerPost(
            einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEinrichtungBenutzerPost(
                einrichtungBenutzerJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EinrichtungBenutzerApi - factory interface
 * @export
 */
export const EinrichtungBenutzerApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EinrichtungBenutzerApiFp(configuration);
    return {
        /**
         * Retrieves the collection of EinrichtungBenutzer resources.
         * @summary Retrieves the collection of EinrichtungBenutzer resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerGetCollection(
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            options?: any
        ): AxiosPromise<ApiEinrichtungBenutzerGetCollection200Response> {
            return localVarFp
                .apiEinrichtungBenutzerGetCollection(
                    page,
                    itemsPerPage,
                    benutzer,
                    benutzer2,
                    einrichtung,
                    einrichtung2,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the EinrichtungBenutzer resource.
         * @summary Removes the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEinrichtungBenutzerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a EinrichtungBenutzer resource.
         * @summary Retrieves a EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdGet(
            id: string,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead> {
            return localVarFp.apiEinrichtungBenutzerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the EinrichtungBenutzer resource.
         * @summary Updates the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {EinrichtungBenutzer} einrichtungBenutzer The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdPatch(
            id: string,
            einrichtungBenutzer: EinrichtungBenutzer,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead> {
            return localVarFp
                .apiEinrichtungBenutzerIdPatch(id, einrichtungBenutzer, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the EinrichtungBenutzer resource.
         * @summary Replaces the EinrichtungBenutzer resource.
         * @param {string} id EinrichtungBenutzer identifier
         * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The updated EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerIdPut(
            id: string,
            einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead> {
            return localVarFp
                .apiEinrichtungBenutzerIdPut(id, einrichtungBenutzerJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a EinrichtungBenutzer resource.
         * @summary Creates a EinrichtungBenutzer resource.
         * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The new EinrichtungBenutzer resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEinrichtungBenutzerPost(
            einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
            options?: any
        ): AxiosPromise<EinrichtungBenutzerJsonldEinrichtungBenutzerRead> {
            return localVarFp
                .apiEinrichtungBenutzerPost(einrichtungBenutzerJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EinrichtungBenutzerApi - object-oriented interface
 * @export
 * @class EinrichtungBenutzerApi
 * @extends {BaseAPI}
 */
export class EinrichtungBenutzerApi extends BaseAPI {
    /**
     * Retrieves the collection of EinrichtungBenutzer resources.
     * @summary Retrieves the collection of EinrichtungBenutzer resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [benutzer]
     * @param {Array<string>} [benutzer2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public apiEinrichtungBenutzerGetCollection(
        page?: number,
        itemsPerPage?: number,
        benutzer?: string,
        benutzer2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .apiEinrichtungBenutzerGetCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                einrichtung,
                einrichtung2,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the EinrichtungBenutzer resource.
     * @summary Removes the EinrichtungBenutzer resource.
     * @param {string} id EinrichtungBenutzer identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public apiEinrichtungBenutzerIdDelete(id: string, options?: AxiosRequestConfig) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .apiEinrichtungBenutzerIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a EinrichtungBenutzer resource.
     * @summary Retrieves a EinrichtungBenutzer resource.
     * @param {string} id EinrichtungBenutzer identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public apiEinrichtungBenutzerIdGet(id: string, options?: AxiosRequestConfig) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .apiEinrichtungBenutzerIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the EinrichtungBenutzer resource.
     * @summary Updates the EinrichtungBenutzer resource.
     * @param {string} id EinrichtungBenutzer identifier
     * @param {EinrichtungBenutzer} einrichtungBenutzer The updated EinrichtungBenutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public apiEinrichtungBenutzerIdPatch(
        id: string,
        einrichtungBenutzer: EinrichtungBenutzer,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .apiEinrichtungBenutzerIdPatch(id, einrichtungBenutzer, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the EinrichtungBenutzer resource.
     * @summary Replaces the EinrichtungBenutzer resource.
     * @param {string} id EinrichtungBenutzer identifier
     * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The updated EinrichtungBenutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public apiEinrichtungBenutzerIdPut(
        id: string,
        einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .apiEinrichtungBenutzerIdPut(id, einrichtungBenutzerJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a EinrichtungBenutzer resource.
     * @summary Creates a EinrichtungBenutzer resource.
     * @param {EinrichtungBenutzerJsonld} einrichtungBenutzerJsonld The new EinrichtungBenutzer resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EinrichtungBenutzerApi
     */
    public apiEinrichtungBenutzerPost(
        einrichtungBenutzerJsonld: EinrichtungBenutzerJsonld,
        options?: AxiosRequestConfig
    ) {
        return EinrichtungBenutzerApiFp(this.configuration)
            .apiEinrichtungBenutzerPost(einrichtungBenutzerJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Email resource.
         * @summary Retrieves a Email resource.
         * @param {string} id Email identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('emailItem', 'id', id);
            const localVarPath = `/api/email/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Email resources.
         * @summary Retrieves the collection of Email resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [empfaenger]
         * @param {Array<string>} [empfaenger2]
         * @param {string} [createdBy]
         * @param {Array<string>} [createdBy2]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailList: async (
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            empfaenger?: string,
            empfaenger2?: Array<string>,
            createdBy?: string,
            createdBy2?: Array<string>,
            orderCreatedAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (empfaenger !== undefined) {
                localVarQueryParameter['empfaenger'] = empfaenger;
            }

            if (empfaenger2) {
                localVarQueryParameter['empfaenger[]'] = empfaenger2;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (createdBy2) {
                localVarQueryParameter['createdBy[]'] = createdBy2;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Email resource.
         * @summary Retrieves a Email resource.
         * @param {string} id Email identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailItem(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailJsonldEmailReadEmailReadEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Email resources.
         * @summary Retrieves the collection of Email resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [empfaenger]
         * @param {Array<string>} [empfaenger2]
         * @param {string} [createdBy]
         * @param {Array<string>} [createdBy2]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailList(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            empfaenger?: string,
            empfaenger2?: Array<string>,
            createdBy?: string,
            createdBy2?: Array<string>,
            orderCreatedAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailList(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                empfaenger,
                empfaenger2,
                createdBy,
                createdBy2,
                orderCreatedAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration);
    return {
        /**
         * Retrieves a Email resource.
         * @summary Retrieves a Email resource.
         * @param {string} id Email identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailItem(id: string, options?: any): AxiosPromise<EmailJsonldEmailReadEmailReadEmail> {
            return localVarFp.emailItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Email resources.
         * @summary Retrieves the collection of Email resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [empfaenger]
         * @param {Array<string>} [empfaenger2]
         * @param {string} [createdBy]
         * @param {Array<string>} [createdBy2]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailList(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            empfaenger?: string,
            empfaenger2?: Array<string>,
            createdBy?: string,
            createdBy2?: Array<string>,
            orderCreatedAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<EmailList200Response> {
            return localVarFp
                .emailList(
                    page,
                    itemsPerPage,
                    einrichtung,
                    einrichtung2,
                    empfaenger,
                    empfaenger2,
                    createdBy,
                    createdBy2,
                    orderCreatedAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * Retrieves a Email resource.
     * @summary Retrieves a Email resource.
     * @param {string} id Email identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailItem(id: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration)
            .emailItem(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Email resources.
     * @summary Retrieves the collection of Email resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [empfaenger]
     * @param {Array<string>} [empfaenger2]
     * @param {string} [createdBy]
     * @param {Array<string>} [createdBy2]
     * @param {'asc' | 'desc'} [orderCreatedAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailList(
        page?: number,
        itemsPerPage?: number,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        empfaenger?: string,
        empfaenger2?: Array<string>,
        createdBy?: string,
        createdBy2?: Array<string>,
        orderCreatedAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return EmailApiFp(this.configuration)
            .emailList(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                empfaenger,
                empfaenger2,
                createdBy,
                createdBy2,
                orderCreatedAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EmailAntragApi - axios parameter creator
 * @export
 */
export const EmailAntragApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of EmailAntrag resources.
         * @summary Retrieves the collection of EmailAntrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [typ]
         * @param {Array<string>} [typ2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderBetreff]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {'asc' | 'desc'} [orderEingereichtByNachname]
         * @param {'BEHOERDE' | 'ANBIETER'} [rolle]
         * @param {'asc' | 'desc'} [orderTyp]
         * @param {boolean} [isRead] Filter by isRead
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            typ?: string,
            typ2?: Array<string>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderBetreff?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            orderEingereichtByNachname?: 'asc' | 'desc',
            rolle?: 'BEHOERDE' | 'ANBIETER',
            orderTyp?: 'asc' | 'desc',
            isRead?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/email-antrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (eingereichtBy !== undefined) {
                localVarQueryParameter['eingereichtBy'] = eingereichtBy;
            }

            if (eingereichtBy2) {
                localVarQueryParameter['eingereichtBy[]'] = eingereichtBy2;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = modifiedAt;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }

            if (typ2) {
                localVarQueryParameter['typ[]'] = typ2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderBetreff !== undefined) {
                localVarQueryParameter['order[betreff]'] = orderBetreff;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderEinrichtungNrwKey !== undefined) {
                localVarQueryParameter['order[einrichtung.nrwKey]'] = orderEinrichtungNrwKey;
            }

            if (orderEingereichtAt !== undefined) {
                localVarQueryParameter['order[eingereichtAt]'] = orderEingereichtAt;
            }

            if (orderEingereichtByNachname !== undefined) {
                localVarQueryParameter['order[eingereichtBy.nachname]'] = orderEingereichtByNachname;
            }

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (orderTyp !== undefined) {
                localVarQueryParameter['order[typ]'] = orderTyp;
            }

            if (isRead !== undefined) {
                localVarQueryParameter['isRead'] = isRead;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the EmailAntrag resource.
         * @summary Removes the EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailAntragIdDelete', 'id', id);
            const localVarPath = `/api/email-antrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a EmailAntrag resource.
         * @summary Retrieves a EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailAntragIdGet', 'id', id);
            const localVarPath = `/api/email-antrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the EmailAntrag resource.
         * @summary Removes the EmailAntrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdformDelete: async (
            id: number,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailAntragIdformDelete', 'id', id);
            const localVarPath = `/api/email-antrag/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a EmailAntrag resource.
         * @summary Retrieves a EmailAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdformGet: async (
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailAntragIdformGet', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiEmailAntragIdformGet', 'step', step);
            const localVarPath = `/api/email-antrag/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the EmailAntrag resource.
         * @summary Updates the EmailAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated EmailAntrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdformPatch: async (
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailAntragIdformPatch', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiEmailAntragIdformPatch', 'step', step);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiEmailAntragIdformPatch', 'body', body);
            const localVarPath = `/api/email-antrag/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the EmailAntrag resource.
         * @summary Updates the EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {EmailAntragAntragWorkflowActionAntragWorkflow} emailAntragAntragWorkflowActionAntragWorkflow The updated EmailAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdworkflowPatch: async (
            id: string,
            emailAntragAntragWorkflowActionAntragWorkflow: EmailAntragAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailAntragIdworkflowPatch', 'id', id);
            // verify required parameter 'emailAntragAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiEmailAntragIdworkflowPatch',
                'emailAntragAntragWorkflowActionAntragWorkflow',
                emailAntragAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/email-antrag/{id}/workflow`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                emailAntragAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a EmailAntrag resource.
         * @summary Creates a EmailAntrag resource.
         * @param {EmailAntragJsonldEmailAntragWrite} emailAntragJsonldEmailAntragWrite The new EmailAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragPost: async (
            emailAntragJsonldEmailAntragWrite: EmailAntragJsonldEmailAntragWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'emailAntragJsonldEmailAntragWrite' is not null or undefined
            assertParamExists(
                'apiEmailAntragPost',
                'emailAntragJsonldEmailAntragWrite',
                emailAntragJsonldEmailAntragWrite
            );
            const localVarPath = `/api/email-antrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                emailAntragJsonldEmailAntragWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailAntragApi - functional programming interface
 * @export
 */
export const EmailAntragApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailAntragApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of EmailAntrag resources.
         * @summary Retrieves the collection of EmailAntrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [typ]
         * @param {Array<string>} [typ2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderBetreff]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {'asc' | 'desc'} [orderEingereichtByNachname]
         * @param {'BEHOERDE' | 'ANBIETER'} [rolle]
         * @param {'asc' | 'desc'} [orderTyp]
         * @param {boolean} [isRead] Filter by isRead
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragGetCollection(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            typ?: string,
            typ2?: Array<string>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderBetreff?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            orderEingereichtByNachname?: 'asc' | 'desc',
            rolle?: 'BEHOERDE' | 'ANBIETER',
            orderTyp?: 'asc' | 'desc',
            isRead?: boolean,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiEmailAntragGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragGetCollection(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                eingereichtBy,
                eingereichtBy2,
                modifiedAt,
                status,
                status2,
                typ,
                typ2,
                qSearch,
                orderName,
                orderBetreff,
                orderEinrichtungName,
                orderEinrichtungNrwKey,
                orderEingereichtAt,
                orderEingereichtByNachname,
                rolle,
                orderTyp,
                isRead,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the EmailAntrag resource.
         * @summary Removes the EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a EmailAntrag resource.
         * @summary Retrieves a EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAntragJsonldEmailAntragRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the EmailAntrag resource.
         * @summary Removes the EmailAntrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragIdformDelete(
            id: number,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragIdformDelete(id, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a EmailAntrag resource.
         * @summary Retrieves a EmailAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAntragEmailAntragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragIdformGet(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the EmailAntrag resource.
         * @summary Updates the EmailAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated EmailAntrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAntragEmailAntragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragIdformPatch(
                id,
                step,
                body,
                objectType,
                uuid,
                persist,
                fields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the EmailAntrag resource.
         * @summary Updates the EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {EmailAntragAntragWorkflowActionAntragWorkflow} emailAntragAntragWorkflowActionAntragWorkflow The updated EmailAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragIdworkflowPatch(
            id: string,
            emailAntragAntragWorkflowActionAntragWorkflow: EmailAntragAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAntragJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragIdworkflowPatch(
                id,
                emailAntragAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a EmailAntrag resource.
         * @summary Creates a EmailAntrag resource.
         * @param {EmailAntragJsonldEmailAntragWrite} emailAntragJsonldEmailAntragWrite The new EmailAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailAntragPost(
            emailAntragJsonldEmailAntragWrite: EmailAntragJsonldEmailAntragWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAntragJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailAntragPost(
                emailAntragJsonldEmailAntragWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EmailAntragApi - factory interface
 * @export
 */
export const EmailAntragApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = EmailAntragApiFp(configuration);
    return {
        /**
         * Retrieves the collection of EmailAntrag resources.
         * @summary Retrieves the collection of EmailAntrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [typ]
         * @param {Array<string>} [typ2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderBetreff]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {'asc' | 'desc'} [orderEingereichtByNachname]
         * @param {'BEHOERDE' | 'ANBIETER'} [rolle]
         * @param {'asc' | 'desc'} [orderTyp]
         * @param {boolean} [isRead] Filter by isRead
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragGetCollection(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            typ?: string,
            typ2?: Array<string>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderBetreff?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            orderEingereichtByNachname?: 'asc' | 'desc',
            rolle?: 'BEHOERDE' | 'ANBIETER',
            orderTyp?: 'asc' | 'desc',
            isRead?: boolean,
            options?: any
        ): AxiosPromise<ApiEmailAntragGetCollection200Response> {
            return localVarFp
                .apiEmailAntragGetCollection(
                    page,
                    itemsPerPage,
                    einrichtung,
                    einrichtung2,
                    eingereichtBy,
                    eingereichtBy2,
                    modifiedAt,
                    status,
                    status2,
                    typ,
                    typ2,
                    qSearch,
                    orderName,
                    orderBetreff,
                    orderEinrichtungName,
                    orderEinrichtungNrwKey,
                    orderEingereichtAt,
                    orderEingereichtByNachname,
                    rolle,
                    orderTyp,
                    isRead,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the EmailAntrag resource.
         * @summary Removes the EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmailAntragIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a EmailAntrag resource.
         * @summary Retrieves a EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdGet(id: string, options?: any): AxiosPromise<EmailAntragJsonldEmailAntragRead> {
            return localVarFp.apiEmailAntragIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the EmailAntrag resource.
         * @summary Removes the EmailAntrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdformDelete(id: number, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmailAntragIdformDelete(id, uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a EmailAntrag resource.
         * @summary Retrieves a EmailAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<EmailAntragEmailAntragJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiEmailAntragIdformGet(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the EmailAntrag resource.
         * @summary Updates the EmailAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated EmailAntrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: any
        ): AxiosPromise<EmailAntragEmailAntragJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiEmailAntragIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the EmailAntrag resource.
         * @summary Updates the EmailAntrag resource.
         * @param {string} id EmailAntrag identifier
         * @param {EmailAntragAntragWorkflowActionAntragWorkflow} emailAntragAntragWorkflowActionAntragWorkflow The updated EmailAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragIdworkflowPatch(
            id: string,
            emailAntragAntragWorkflowActionAntragWorkflow: EmailAntragAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<EmailAntragJsonld> {
            return localVarFp
                .apiEmailAntragIdworkflowPatch(id, emailAntragAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a EmailAntrag resource.
         * @summary Creates a EmailAntrag resource.
         * @param {EmailAntragJsonldEmailAntragWrite} emailAntragJsonldEmailAntragWrite The new EmailAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailAntragPost(
            emailAntragJsonldEmailAntragWrite: EmailAntragJsonldEmailAntragWrite,
            options?: any
        ): AxiosPromise<EmailAntragJsonld> {
            return localVarFp
                .apiEmailAntragPost(emailAntragJsonldEmailAntragWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailAntragApi - object-oriented interface
 * @export
 * @class EmailAntragApi
 * @extends {BaseAPI}
 */
export class EmailAntragApi extends BaseAPI {
    /**
     * Retrieves the collection of EmailAntrag resources.
     * @summary Retrieves the collection of EmailAntrag resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [eingereichtBy]
     * @param {Array<string>} [eingereichtBy2]
     * @param {string} [modifiedAt]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [typ]
     * @param {Array<string>} [typ2]
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderBetreff]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
     * @param {'asc' | 'desc'} [orderEingereichtAt]
     * @param {'asc' | 'desc'} [orderEingereichtByNachname]
     * @param {'BEHOERDE' | 'ANBIETER'} [rolle]
     * @param {'asc' | 'desc'} [orderTyp]
     * @param {boolean} [isRead] Filter by isRead
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragGetCollection(
        page?: number,
        itemsPerPage?: number,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        eingereichtBy?: string,
        eingereichtBy2?: Array<string>,
        modifiedAt?: string,
        status?: string,
        status2?: Array<string>,
        typ?: string,
        typ2?: Array<string>,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderBetreff?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderEinrichtungNrwKey?: 'asc' | 'desc',
        orderEingereichtAt?: 'asc' | 'desc',
        orderEingereichtByNachname?: 'asc' | 'desc',
        rolle?: 'BEHOERDE' | 'ANBIETER',
        orderTyp?: 'asc' | 'desc',
        isRead?: boolean,
        options?: AxiosRequestConfig
    ) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragGetCollection(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                eingereichtBy,
                eingereichtBy2,
                modifiedAt,
                status,
                status2,
                typ,
                typ2,
                qSearch,
                orderName,
                orderBetreff,
                orderEinrichtungName,
                orderEinrichtungNrwKey,
                orderEingereichtAt,
                orderEingereichtByNachname,
                rolle,
                orderTyp,
                isRead,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the EmailAntrag resource.
     * @summary Removes the EmailAntrag resource.
     * @param {string} id EmailAntrag identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragIdDelete(id: string, options?: AxiosRequestConfig) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a EmailAntrag resource.
     * @summary Retrieves a EmailAntrag resource.
     * @param {string} id EmailAntrag identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragIdGet(id: string, options?: AxiosRequestConfig) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the EmailAntrag resource.
     * @summary Removes the EmailAntrag resource.
     * @param {number} id
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragIdformDelete(id: number, uuid?: string, options?: AxiosRequestConfig) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragIdformDelete(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a EmailAntrag resource.
     * @summary Retrieves a EmailAntrag resource.
     * @param {number} id
     * @param {string} step
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragIdformGet(
        id: number,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragIdformGet(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the EmailAntrag resource.
     * @summary Updates the EmailAntrag resource.
     * @param {number} id
     * @param {string} step
     * @param {object} body The updated EmailAntrag resource
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragIdformPatch(
        id: number,
        step: string,
        body: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the EmailAntrag resource.
     * @summary Updates the EmailAntrag resource.
     * @param {string} id EmailAntrag identifier
     * @param {EmailAntragAntragWorkflowActionAntragWorkflow} emailAntragAntragWorkflowActionAntragWorkflow The updated EmailAntrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragIdworkflowPatch(
        id: string,
        emailAntragAntragWorkflowActionAntragWorkflow: EmailAntragAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragIdworkflowPatch(id, emailAntragAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a EmailAntrag resource.
     * @summary Creates a EmailAntrag resource.
     * @param {EmailAntragJsonldEmailAntragWrite} emailAntragJsonldEmailAntragWrite The new EmailAntrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailAntragApi
     */
    public apiEmailAntragPost(
        emailAntragJsonldEmailAntragWrite: EmailAntragJsonldEmailAntragWrite,
        options?: AxiosRequestConfig
    ) {
        return EmailAntragApiFp(this.configuration)
            .apiEmailAntragPost(emailAntragJsonldEmailAntragWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EmailtextApi - axios parameter creator
 * @export
 */
export const EmailtextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Emailtext resource.
         * @summary Retrieves a Emailtext resource.
         * @param {string} emailtyp
         * @param {string} id Emailtext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailtextIdGet: async (
            emailtyp: string,
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'emailtyp' is not null or undefined
            assertParamExists('apiEmailtextIdGet', 'emailtyp', emailtyp);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailtextIdGet', 'id', id);
            const localVarPath = `/api/emailtext/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (emailtyp !== undefined) {
                localVarQueryParameter['emailtyp'] = emailtyp;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Emailtext resource.
         * @summary Creates a Emailtext resource.
         * @param {EmailtextJsonldEmailtextWrite} emailtextJsonldEmailtextWrite The new Emailtext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailtextPost: async (
            emailtextJsonldEmailtextWrite: EmailtextJsonldEmailtextWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'emailtextJsonldEmailtextWrite' is not null or undefined
            assertParamExists('apiEmailtextPost', 'emailtextJsonldEmailtextWrite', emailtextJsonldEmailtextWrite);
            const localVarPath = `/api/emailtext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                emailtextJsonldEmailtextWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailtextApi - functional programming interface
 * @export
 */
export const EmailtextApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailtextApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Emailtext resource.
         * @summary Retrieves a Emailtext resource.
         * @param {string} emailtyp
         * @param {string} id Emailtext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailtextIdGet(
            emailtyp: string,
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailtextJsonldEmailtextRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailtextIdGet(emailtyp, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Emailtext resource.
         * @summary Creates a Emailtext resource.
         * @param {EmailtextJsonldEmailtextWrite} emailtextJsonldEmailtextWrite The new Emailtext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailtextPost(
            emailtextJsonldEmailtextWrite: EmailtextJsonldEmailtextWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailtextPost(
                emailtextJsonldEmailtextWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EmailtextApi - factory interface
 * @export
 */
export const EmailtextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailtextApiFp(configuration);
    return {
        /**
         * Retrieves a Emailtext resource.
         * @summary Retrieves a Emailtext resource.
         * @param {string} emailtyp
         * @param {string} id Emailtext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailtextIdGet(emailtyp: string, id: string, options?: any): AxiosPromise<EmailtextJsonldEmailtextRead> {
            return localVarFp.apiEmailtextIdGet(emailtyp, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Emailtext resource.
         * @summary Creates a Emailtext resource.
         * @param {EmailtextJsonldEmailtextWrite} emailtextJsonldEmailtextWrite The new Emailtext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailtextPost(
            emailtextJsonldEmailtextWrite: EmailtextJsonldEmailtextWrite,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .apiEmailtextPost(emailtextJsonldEmailtextWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailtextApi - object-oriented interface
 * @export
 * @class EmailtextApi
 * @extends {BaseAPI}
 */
export class EmailtextApi extends BaseAPI {
    /**
     * Retrieves a Emailtext resource.
     * @summary Retrieves a Emailtext resource.
     * @param {string} emailtyp
     * @param {string} id Emailtext identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailtextApi
     */
    public apiEmailtextIdGet(emailtyp: string, id: string, options?: AxiosRequestConfig) {
        return EmailtextApiFp(this.configuration)
            .apiEmailtextIdGet(emailtyp, id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Emailtext resource.
     * @summary Creates a Emailtext resource.
     * @param {EmailtextJsonldEmailtextWrite} emailtextJsonldEmailtextWrite The new Emailtext resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailtextApi
     */
    public apiEmailtextPost(
        emailtextJsonldEmailtextWrite: EmailtextJsonldEmailtextWrite,
        options?: AxiosRequestConfig
    ) {
        return EmailtextApiFp(this.configuration)
            .apiEmailtextPost(emailtextJsonldEmailtextWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ExportRequestApi - axios parameter creator
 * @export
 */
export const ExportRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of ExportRequest resources.
         * @summary Retrieves the collection of ExportRequest resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [reference]
         * @param {Array<string>} [reference2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [resource]
         * @param {Array<string>} [resource2]
         * @param {'asc' | 'desc'} [orderResource]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderCreatedDate]
         * @param {'asc' | 'desc'} [orderUpdatedDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExportRequestGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            reference?: string,
            reference2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            resource?: string,
            resource2?: Array<string>,
            orderResource?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderCreatedDate?: 'asc' | 'desc',
            orderUpdatedDate?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/export-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (benutzer !== undefined) {
                localVarQueryParameter['benutzer'] = benutzer;
            }

            if (benutzer2) {
                localVarQueryParameter['benutzer[]'] = benutzer2;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }

            if (reference2) {
                localVarQueryParameter['reference[]'] = reference2;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (resource !== undefined) {
                localVarQueryParameter['resource'] = resource;
            }

            if (resource2) {
                localVarQueryParameter['resource[]'] = resource2;
            }

            if (orderResource !== undefined) {
                localVarQueryParameter['order[resource]'] = orderResource;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderCreatedDate !== undefined) {
                localVarQueryParameter['order[createdDate]'] = orderCreatedDate;
            }

            if (orderUpdatedDate !== undefined) {
                localVarQueryParameter['order[updatedDate]'] = orderUpdatedDate;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ExportRequest resource.
         * @summary Retrieves a ExportRequest resource.
         * @param {string} id ExportRequest identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExportRequestIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExportRequestIdGet', 'id', id);
            const localVarPath = `/api/export-request/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ExportRequestApi - functional programming interface
 * @export
 */
export const ExportRequestApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportRequestApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of ExportRequest resources.
         * @summary Retrieves the collection of ExportRequest resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [reference]
         * @param {Array<string>} [reference2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [resource]
         * @param {Array<string>} [resource2]
         * @param {'asc' | 'desc'} [orderResource]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderCreatedDate]
         * @param {'asc' | 'desc'} [orderUpdatedDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExportRequestGetCollection(
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            reference?: string,
            reference2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            resource?: string,
            resource2?: Array<string>,
            orderResource?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderCreatedDate?: 'asc' | 'desc',
            orderUpdatedDate?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiExportRequestGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExportRequestGetCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                reference,
                reference2,
                status,
                status2,
                resource,
                resource2,
                orderResource,
                orderStatus,
                orderCreatedDate,
                orderUpdatedDate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a ExportRequest resource.
         * @summary Retrieves a ExportRequest resource.
         * @param {string} id ExportRequest identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExportRequestIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportRequestJsonldExportRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExportRequestIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ExportRequestApi - factory interface
 * @export
 */
export const ExportRequestApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = ExportRequestApiFp(configuration);
    return {
        /**
         * Retrieves the collection of ExportRequest resources.
         * @summary Retrieves the collection of ExportRequest resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [benutzer]
         * @param {Array<string>} [benutzer2]
         * @param {string} [reference]
         * @param {Array<string>} [reference2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [resource]
         * @param {Array<string>} [resource2]
         * @param {'asc' | 'desc'} [orderResource]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderCreatedDate]
         * @param {'asc' | 'desc'} [orderUpdatedDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExportRequestGetCollection(
            page?: number,
            itemsPerPage?: number,
            benutzer?: string,
            benutzer2?: Array<string>,
            reference?: string,
            reference2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            resource?: string,
            resource2?: Array<string>,
            orderResource?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderCreatedDate?: 'asc' | 'desc',
            orderUpdatedDate?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiExportRequestGetCollection200Response> {
            return localVarFp
                .apiExportRequestGetCollection(
                    page,
                    itemsPerPage,
                    benutzer,
                    benutzer2,
                    reference,
                    reference2,
                    status,
                    status2,
                    resource,
                    resource2,
                    orderResource,
                    orderStatus,
                    orderCreatedDate,
                    orderUpdatedDate,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ExportRequest resource.
         * @summary Retrieves a ExportRequest resource.
         * @param {string} id ExportRequest identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExportRequestIdGet(id: string, options?: any): AxiosPromise<ExportRequestJsonldExportRequest> {
            return localVarFp.apiExportRequestIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportRequestApi - object-oriented interface
 * @export
 * @class ExportRequestApi
 * @extends {BaseAPI}
 */
export class ExportRequestApi extends BaseAPI {
    /**
     * Retrieves the collection of ExportRequest resources.
     * @summary Retrieves the collection of ExportRequest resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [benutzer]
     * @param {Array<string>} [benutzer2]
     * @param {string} [reference]
     * @param {Array<string>} [reference2]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [resource]
     * @param {Array<string>} [resource2]
     * @param {'asc' | 'desc'} [orderResource]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderCreatedDate]
     * @param {'asc' | 'desc'} [orderUpdatedDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportRequestApi
     */
    public apiExportRequestGetCollection(
        page?: number,
        itemsPerPage?: number,
        benutzer?: string,
        benutzer2?: Array<string>,
        reference?: string,
        reference2?: Array<string>,
        status?: string,
        status2?: Array<string>,
        resource?: string,
        resource2?: Array<string>,
        orderResource?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderCreatedDate?: 'asc' | 'desc',
        orderUpdatedDate?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return ExportRequestApiFp(this.configuration)
            .apiExportRequestGetCollection(
                page,
                itemsPerPage,
                benutzer,
                benutzer2,
                reference,
                reference2,
                status,
                status2,
                resource,
                resource2,
                orderResource,
                orderStatus,
                orderCreatedDate,
                orderUpdatedDate,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ExportRequest resource.
     * @summary Retrieves a ExportRequest resource.
     * @param {string} id ExportRequest identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportRequestApi
     */
    public apiExportRequestIdGet(id: string, options?: AxiosRequestConfig) {
        return ExportRequestApiFp(this.configuration)
            .apiExportRequestIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FemAntragApi - axios parameter creator
 * @export
 */
export const FemAntragApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FemAntrag resources.
         * @summary Retrieves the collection of FemAntrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [name]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {number} [jahr]
         * @param {Array<number>} [jahr2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            name?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            jahr?: number,
            jahr2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/fem-antrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (eingereichtBy !== undefined) {
                localVarQueryParameter['eingereichtBy'] = eingereichtBy;
            }

            if (eingereichtBy2) {
                localVarQueryParameter['eingereichtBy[]'] = eingereichtBy2;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = modifiedAt;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }

            if (jahr2) {
                localVarQueryParameter['jahr[]'] = jahr2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderEingereichtAt !== undefined) {
                localVarQueryParameter['order[eingereichtAt]'] = orderEingereichtAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FemAntrag resource.
         * @summary Retrieves a FemAntrag resource.
         * @param {string} id FemAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFemAntragIdGet', 'id', id);
            const localVarPath = `/api/fem-antrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the FemAntrag resource.
         * @summary Removes the FemAntrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdformDelete: async (
            id: number,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFemAntragIdformDelete', 'id', id);
            const localVarPath = `/api/femAntrag/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FemAntrag resource.
         * @summary Retrieves a FemAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdformGet: async (
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFemAntragIdformGet', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiFemAntragIdformGet', 'step', step);
            const localVarPath = `/api/femAntrag/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FemAntrag resource.
         * @summary Updates the FemAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated FemAntrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdformPatch: async (
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFemAntragIdformPatch', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiFemAntragIdformPatch', 'step', step);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiFemAntragIdformPatch', 'body', body);
            const localVarPath = `/api/femAntrag/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the FemAntrag resource.
         * @summary Updates the FemAntrag resource.
         * @param {string} id FemAntrag identifier
         * @param {FemAntragAntragWorkflowActionAntragWorkflow} femAntragAntragWorkflowActionAntragWorkflow The updated FemAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdworkflowPatch: async (
            id: string,
            femAntragAntragWorkflowActionAntragWorkflow: FemAntragAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFemAntragIdworkflowPatch', 'id', id);
            // verify required parameter 'femAntragAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiFemAntragIdworkflowPatch',
                'femAntragAntragWorkflowActionAntragWorkflow',
                femAntragAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/femAntrag/{id}/workflow`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                femAntragAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FemAntragApi - functional programming interface
 * @export
 */
export const FemAntragApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FemAntragApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of FemAntrag resources.
         * @summary Retrieves the collection of FemAntrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [name]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {number} [jahr]
         * @param {Array<number>} [jahr2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemAntragGetCollection(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            name?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            jahr?: number,
            jahr2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFemAntragGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemAntragGetCollection(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                eingereichtBy,
                eingereichtBy2,
                name,
                modifiedAt,
                status,
                status2,
                jahr,
                jahr2,
                qSearch,
                orderName,
                orderEingereichtAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FemAntrag resource.
         * @summary Retrieves a FemAntrag resource.
         * @param {string} id FemAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemAntragIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemAntragIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the FemAntrag resource.
         * @summary Removes the FemAntrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemAntragIdformDelete(
            id: number,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemAntragIdformDelete(id, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FemAntrag resource.
         * @summary Retrieves a FemAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemAntragIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FemAntragFemAntragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemAntragIdformGet(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FemAntrag resource.
         * @summary Updates the FemAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated FemAntrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemAntragIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FemAntragFemAntragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemAntragIdformPatch(
                id,
                step,
                body,
                objectType,
                uuid,
                persist,
                fields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the FemAntrag resource.
         * @summary Updates the FemAntrag resource.
         * @param {string} id FemAntrag identifier
         * @param {FemAntragAntragWorkflowActionAntragWorkflow} femAntragAntragWorkflowActionAntragWorkflow The updated FemAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemAntragIdworkflowPatch(
            id: string,
            femAntragAntragWorkflowActionAntragWorkflow: FemAntragAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FemAntragJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemAntragIdworkflowPatch(
                id,
                femAntragAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FemAntragApi - factory interface
 * @export
 */
export const FemAntragApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FemAntragApiFp(configuration);
    return {
        /**
         * Retrieves the collection of FemAntrag resources.
         * @summary Retrieves the collection of FemAntrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [name]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {number} [jahr]
         * @param {Array<number>} [jahr2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragGetCollection(
            page?: number,
            itemsPerPage?: number,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            name?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            jahr?: number,
            jahr2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiFemAntragGetCollection200Response> {
            return localVarFp
                .apiFemAntragGetCollection(
                    page,
                    itemsPerPage,
                    einrichtung,
                    einrichtung2,
                    eingereichtBy,
                    eingereichtBy2,
                    name,
                    modifiedAt,
                    status,
                    status2,
                    jahr,
                    jahr2,
                    qSearch,
                    orderName,
                    orderEingereichtAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FemAntrag resource.
         * @summary Retrieves a FemAntrag resource.
         * @param {string} id FemAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdGet(
            id: string,
            options?: any
        ): AxiosPromise<FemAntragFemDetailsOutputJsonldFemViewEinrichtungGetAdresseRead> {
            return localVarFp.apiFemAntragIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the FemAntrag resource.
         * @summary Removes the FemAntrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdformDelete(id: number, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFemAntragIdformDelete(id, uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FemAntrag resource.
         * @summary Retrieves a FemAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<FemAntragFemAntragJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiFemAntragIdformGet(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FemAntrag resource.
         * @summary Updates the FemAntrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated FemAntrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: any
        ): AxiosPromise<FemAntragFemAntragJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiFemAntragIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the FemAntrag resource.
         * @summary Updates the FemAntrag resource.
         * @param {string} id FemAntrag identifier
         * @param {FemAntragAntragWorkflowActionAntragWorkflow} femAntragAntragWorkflowActionAntragWorkflow The updated FemAntrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemAntragIdworkflowPatch(
            id: string,
            femAntragAntragWorkflowActionAntragWorkflow: FemAntragAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<FemAntragJsonld> {
            return localVarFp
                .apiFemAntragIdworkflowPatch(id, femAntragAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FemAntragApi - object-oriented interface
 * @export
 * @class FemAntragApi
 * @extends {BaseAPI}
 */
export class FemAntragApi extends BaseAPI {
    /**
     * Retrieves the collection of FemAntrag resources.
     * @summary Retrieves the collection of FemAntrag resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [eingereichtBy]
     * @param {Array<string>} [eingereichtBy2]
     * @param {string} [name]
     * @param {string} [modifiedAt]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {number} [jahr]
     * @param {Array<number>} [jahr2]
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderEingereichtAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemAntragApi
     */
    public apiFemAntragGetCollection(
        page?: number,
        itemsPerPage?: number,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        eingereichtBy?: string,
        eingereichtBy2?: Array<string>,
        name?: string,
        modifiedAt?: string,
        status?: string,
        status2?: Array<string>,
        jahr?: number,
        jahr2?: Array<number>,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderEingereichtAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return FemAntragApiFp(this.configuration)
            .apiFemAntragGetCollection(
                page,
                itemsPerPage,
                einrichtung,
                einrichtung2,
                eingereichtBy,
                eingereichtBy2,
                name,
                modifiedAt,
                status,
                status2,
                jahr,
                jahr2,
                qSearch,
                orderName,
                orderEingereichtAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FemAntrag resource.
     * @summary Retrieves a FemAntrag resource.
     * @param {string} id FemAntrag identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemAntragApi
     */
    public apiFemAntragIdGet(id: string, options?: AxiosRequestConfig) {
        return FemAntragApiFp(this.configuration)
            .apiFemAntragIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the FemAntrag resource.
     * @summary Removes the FemAntrag resource.
     * @param {number} id
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemAntragApi
     */
    public apiFemAntragIdformDelete(id: number, uuid?: string, options?: AxiosRequestConfig) {
        return FemAntragApiFp(this.configuration)
            .apiFemAntragIdformDelete(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FemAntrag resource.
     * @summary Retrieves a FemAntrag resource.
     * @param {number} id
     * @param {string} step
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemAntragApi
     */
    public apiFemAntragIdformGet(
        id: number,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return FemAntragApiFp(this.configuration)
            .apiFemAntragIdformGet(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FemAntrag resource.
     * @summary Updates the FemAntrag resource.
     * @param {number} id
     * @param {string} step
     * @param {object} body The updated FemAntrag resource
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemAntragApi
     */
    public apiFemAntragIdformPatch(
        id: number,
        step: string,
        body: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return FemAntragApiFp(this.configuration)
            .apiFemAntragIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the FemAntrag resource.
     * @summary Updates the FemAntrag resource.
     * @param {string} id FemAntrag identifier
     * @param {FemAntragAntragWorkflowActionAntragWorkflow} femAntragAntragWorkflowActionAntragWorkflow The updated FemAntrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemAntragApi
     */
    public apiFemAntragIdworkflowPatch(
        id: string,
        femAntragAntragWorkflowActionAntragWorkflow: FemAntragAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return FemAntragApiFp(this.configuration)
            .apiFemAntragIdworkflowPatch(id, femAntragAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FemExportApi - axios parameter creator
 * @export
 */
export const FemExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a FemExport resource.
         * @summary Creates a FemExport resource.
         * @param {FemExportFemFilterJsonldFemexportWrite} femExportFemFilterJsonldFemexportWrite The new FemExport resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemExportPost: async (
            femExportFemFilterJsonldFemexportWrite: FemExportFemFilterJsonldFemexportWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'femExportFemFilterJsonldFemexportWrite' is not null or undefined
            assertParamExists(
                'apiFemExportPost',
                'femExportFemFilterJsonldFemexportWrite',
                femExportFemFilterJsonldFemexportWrite
            );
            const localVarPath = `/api/fem-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                femExportFemFilterJsonldFemexportWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FemExport resource.
         * @summary Retrieves a FemExport resource.
         * @param {number} jahr Jahr
         * @param {number} quartal Quartal
         * @param {Array<number>} [bezirksregierung] Regierungsbezirke
         * @param {Array<number>} [behoerde] Behörden
         * @param {Array<number>} [gemeinde] Gemeinden
         * @param {Array<string>} [arten] Arten
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemexportCsvGet: async (
            jahr: number,
            quartal: number,
            bezirksregierung?: Array<number>,
            behoerde?: Array<number>,
            gemeinde?: Array<number>,
            arten?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'jahr' is not null or undefined
            assertParamExists('apiFemexportCsvGet', 'jahr', jahr);
            // verify required parameter 'quartal' is not null or undefined
            assertParamExists('apiFemexportCsvGet', 'quartal', quartal);
            const localVarPath = `/api/femexportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (bezirksregierung) {
                localVarQueryParameter['bezirksregierung'] = bezirksregierung.join(COLLECTION_FORMATS.csv);
            }

            if (behoerde) {
                localVarQueryParameter['behoerde'] = behoerde.join(COLLECTION_FORMATS.csv);
            }

            if (gemeinde) {
                localVarQueryParameter['gemeinde'] = gemeinde.join(COLLECTION_FORMATS.csv);
            }

            if (arten) {
                localVarQueryParameter['arten'] = arten.join(COLLECTION_FORMATS.csv);
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }

            if (quartal !== undefined) {
                localVarQueryParameter['quartal'] = quartal;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FemExportApi - functional programming interface
 * @export
 */
export const FemExportApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FemExportApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates a FemExport resource.
         * @summary Creates a FemExport resource.
         * @param {FemExportFemFilterJsonldFemexportWrite} femExportFemFilterJsonldFemexportWrite The new FemExport resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemExportPost(
            femExportFemFilterJsonldFemexportWrite: FemExportFemFilterJsonldFemexportWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FemExportFemExportJsonForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemExportPost(
                femExportFemFilterJsonldFemexportWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FemExport resource.
         * @summary Retrieves a FemExport resource.
         * @param {number} jahr Jahr
         * @param {number} quartal Quartal
         * @param {Array<number>} [bezirksregierung] Regierungsbezirke
         * @param {Array<number>} [behoerde] Behörden
         * @param {Array<number>} [gemeinde] Gemeinden
         * @param {Array<string>} [arten] Arten
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemexportCsvGet(
            jahr: number,
            quartal: number,
            bezirksregierung?: Array<number>,
            behoerde?: Array<number>,
            gemeinde?: Array<number>,
            arten?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FemExportExportRequestJsonldExportRequest>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemexportCsvGet(
                jahr,
                quartal,
                bezirksregierung,
                behoerde,
                gemeinde,
                arten,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FemExportApi - factory interface
 * @export
 */
export const FemExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FemExportApiFp(configuration);
    return {
        /**
         * Creates a FemExport resource.
         * @summary Creates a FemExport resource.
         * @param {FemExportFemFilterJsonldFemexportWrite} femExportFemFilterJsonldFemexportWrite The new FemExport resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemExportPost(
            femExportFemFilterJsonldFemexportWrite: FemExportFemFilterJsonldFemexportWrite,
            options?: any
        ): AxiosPromise<FemExportFemExportJsonForm> {
            return localVarFp
                .apiFemExportPost(femExportFemFilterJsonldFemexportWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FemExport resource.
         * @summary Retrieves a FemExport resource.
         * @param {number} jahr Jahr
         * @param {number} quartal Quartal
         * @param {Array<number>} [bezirksregierung] Regierungsbezirke
         * @param {Array<number>} [behoerde] Behörden
         * @param {Array<number>} [gemeinde] Gemeinden
         * @param {Array<string>} [arten] Arten
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemexportCsvGet(
            jahr: number,
            quartal: number,
            bezirksregierung?: Array<number>,
            behoerde?: Array<number>,
            gemeinde?: Array<number>,
            arten?: Array<string>,
            options?: any
        ): AxiosPromise<FemExportExportRequestJsonldExportRequest> {
            return localVarFp
                .apiFemexportCsvGet(jahr, quartal, bezirksregierung, behoerde, gemeinde, arten, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * FemExportApi - object-oriented interface
 * @export
 * @class FemExportApi
 * @extends {BaseAPI}
 */
export class FemExportApi extends BaseAPI {
    /**
     * Creates a FemExport resource.
     * @summary Creates a FemExport resource.
     * @param {FemExportFemFilterJsonldFemexportWrite} femExportFemFilterJsonldFemexportWrite The new FemExport resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemExportApi
     */
    public apiFemExportPost(
        femExportFemFilterJsonldFemexportWrite: FemExportFemFilterJsonldFemexportWrite,
        options?: AxiosRequestConfig
    ) {
        return FemExportApiFp(this.configuration)
            .apiFemExportPost(femExportFemFilterJsonldFemexportWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FemExport resource.
     * @summary Retrieves a FemExport resource.
     * @param {number} jahr Jahr
     * @param {number} quartal Quartal
     * @param {Array<number>} [bezirksregierung] Regierungsbezirke
     * @param {Array<number>} [behoerde] Behörden
     * @param {Array<number>} [gemeinde] Gemeinden
     * @param {Array<string>} [arten] Arten
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemExportApi
     */
    public apiFemexportCsvGet(
        jahr: number,
        quartal: number,
        bezirksregierung?: Array<number>,
        behoerde?: Array<number>,
        gemeinde?: Array<number>,
        arten?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return FemExportApiFp(this.configuration)
            .apiFemexportCsvGet(jahr, quartal, bezirksregierung, behoerde, gemeinde, arten, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FemmelderReminderApi - axios parameter creator
 * @export
 */
export const FemmelderReminderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FemmelderReminder resource.
         * @summary Retrieves a FemmelderReminder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemmelderReminderGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/femmelder-reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FemmelderReminderApi - functional programming interface
 * @export
 */
export const FemmelderReminderApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FemmelderReminderApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a FemmelderReminder resource.
         * @summary Retrieves a FemmelderReminder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFemmelderReminderGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FemmelderReminderJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFemmelderReminderGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FemmelderReminderApi - factory interface
 * @export
 */
export const FemmelderReminderApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = FemmelderReminderApiFp(configuration);
    return {
        /**
         * Retrieves a FemmelderReminder resource.
         * @summary Retrieves a FemmelderReminder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFemmelderReminderGet(options?: any): AxiosPromise<FemmelderReminderJsonld> {
            return localVarFp.apiFemmelderReminderGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FemmelderReminderApi - object-oriented interface
 * @export
 * @class FemmelderReminderApi
 * @extends {BaseAPI}
 */
export class FemmelderReminderApi extends BaseAPI {
    /**
     * Retrieves a FemmelderReminder resource.
     * @summary Retrieves a FemmelderReminder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FemmelderReminderApi
     */
    public apiFemmelderReminderGet(options?: AxiosRequestConfig) {
        return FemmelderReminderApiFp(this.configuration)
            .apiFemmelderReminderGet(options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * GeloeschtesObjektApi - axios parameter creator
 * @export
 */
export const GeloeschtesObjektApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Liste aller gelöschten Objekte
         * @summary Liste aller gelöschten Objekte
         * @param {number} [page]
         * @param {number} [itemsPerPage]
         * @param {string} [qSearch]
         * @param {boolean} [confirmed]
         * @param {'asc' | 'desc'} [orderByNrwKey]
         * @param {'asc' | 'desc'} [orderByArt]
         * @param {'asc' | 'desc'} [orderByName]
         * @param {'asc' | 'desc'} [orderByErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderByDeletedAt]
         * @param {'asc' | 'desc'} [orderByDeletedBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGeloeschtesObjektGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            confirmed?: boolean,
            orderByNrwKey?: 'asc' | 'desc',
            orderByArt?: 'asc' | 'desc',
            orderByName?: 'asc' | 'desc',
            orderByErstinbetriebnahme?: 'asc' | 'desc',
            orderByDeletedAt?: 'asc' | 'desc',
            orderByDeletedBy?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/geloeschtes-objekt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (confirmed !== undefined) {
                localVarQueryParameter['confirmed'] = confirmed;
            }

            if (orderByNrwKey !== undefined) {
                localVarQueryParameter['orderByNrwKey'] = orderByNrwKey;
            }

            if (orderByArt !== undefined) {
                localVarQueryParameter['orderByArt'] = orderByArt;
            }

            if (orderByName !== undefined) {
                localVarQueryParameter['orderByName'] = orderByName;
            }

            if (orderByErstinbetriebnahme !== undefined) {
                localVarQueryParameter['orderByErstinbetriebnahme'] = orderByErstinbetriebnahme;
            }

            if (orderByDeletedAt !== undefined) {
                localVarQueryParameter['orderByDeletedAt'] = orderByDeletedAt;
            }

            if (orderByDeletedBy !== undefined) {
                localVarQueryParameter['orderByDeletedBy'] = orderByDeletedBy;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * GeloeschtesObjektApi - functional programming interface
 * @export
 */
export const GeloeschtesObjektApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = GeloeschtesObjektApiAxiosParamCreator(configuration);
    return {
        /**
         * Liste aller gelöschten Objekte
         * @summary Liste aller gelöschten Objekte
         * @param {number} [page]
         * @param {number} [itemsPerPage]
         * @param {string} [qSearch]
         * @param {boolean} [confirmed]
         * @param {'asc' | 'desc'} [orderByNrwKey]
         * @param {'asc' | 'desc'} [orderByArt]
         * @param {'asc' | 'desc'} [orderByName]
         * @param {'asc' | 'desc'} [orderByErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderByDeletedAt]
         * @param {'asc' | 'desc'} [orderByDeletedBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGeloeschtesObjektGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            confirmed?: boolean,
            orderByNrwKey?: 'asc' | 'desc',
            orderByArt?: 'asc' | 'desc',
            orderByName?: 'asc' | 'desc',
            orderByErstinbetriebnahme?: 'asc' | 'desc',
            orderByDeletedAt?: 'asc' | 'desc',
            orderByDeletedBy?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGeloeschtesObjektGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGeloeschtesObjektGetCollection(
                page,
                itemsPerPage,
                qSearch,
                confirmed,
                orderByNrwKey,
                orderByArt,
                orderByName,
                orderByErstinbetriebnahme,
                orderByDeletedAt,
                orderByDeletedBy,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * GeloeschtesObjektApi - factory interface
 * @export
 */
export const GeloeschtesObjektApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = GeloeschtesObjektApiFp(configuration);
    return {
        /**
         * Liste aller gelöschten Objekte
         * @summary Liste aller gelöschten Objekte
         * @param {number} [page]
         * @param {number} [itemsPerPage]
         * @param {string} [qSearch]
         * @param {boolean} [confirmed]
         * @param {'asc' | 'desc'} [orderByNrwKey]
         * @param {'asc' | 'desc'} [orderByArt]
         * @param {'asc' | 'desc'} [orderByName]
         * @param {'asc' | 'desc'} [orderByErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderByDeletedAt]
         * @param {'asc' | 'desc'} [orderByDeletedBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGeloeschtesObjektGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            confirmed?: boolean,
            orderByNrwKey?: 'asc' | 'desc',
            orderByArt?: 'asc' | 'desc',
            orderByName?: 'asc' | 'desc',
            orderByErstinbetriebnahme?: 'asc' | 'desc',
            orderByDeletedAt?: 'asc' | 'desc',
            orderByDeletedBy?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiGeloeschtesObjektGetCollection200Response> {
            return localVarFp
                .apiGeloeschtesObjektGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    confirmed,
                    orderByNrwKey,
                    orderByArt,
                    orderByName,
                    orderByErstinbetriebnahme,
                    orderByDeletedAt,
                    orderByDeletedBy,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeloeschtesObjektApi - object-oriented interface
 * @export
 * @class GeloeschtesObjektApi
 * @extends {BaseAPI}
 */
export class GeloeschtesObjektApi extends BaseAPI {
    /**
     * Liste aller gelöschten Objekte
     * @summary Liste aller gelöschten Objekte
     * @param {number} [page]
     * @param {number} [itemsPerPage]
     * @param {string} [qSearch]
     * @param {boolean} [confirmed]
     * @param {'asc' | 'desc'} [orderByNrwKey]
     * @param {'asc' | 'desc'} [orderByArt]
     * @param {'asc' | 'desc'} [orderByName]
     * @param {'asc' | 'desc'} [orderByErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderByDeletedAt]
     * @param {'asc' | 'desc'} [orderByDeletedBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeloeschtesObjektApi
     */
    public apiGeloeschtesObjektGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        confirmed?: boolean,
        orderByNrwKey?: 'asc' | 'desc',
        orderByArt?: 'asc' | 'desc',
        orderByName?: 'asc' | 'desc',
        orderByErstinbetriebnahme?: 'asc' | 'desc',
        orderByDeletedAt?: 'asc' | 'desc',
        orderByDeletedBy?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return GeloeschtesObjektApiFp(this.configuration)
            .apiGeloeschtesObjektGetCollection(
                page,
                itemsPerPage,
                qSearch,
                confirmed,
                orderByNrwKey,
                orderByArt,
                orderByName,
                orderByErstinbetriebnahme,
                orderByDeletedAt,
                orderByDeletedBy,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * HeimfinderApi - axios parameter creator
 * @export
 */
export const HeimfinderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {string} id Heimfinder identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiHeimfindereinrichtungIdGet', 'id', id);
            const localVarPath = `/api/heimfinder/einrichtung/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungendauerpflegeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen/dauerpflege`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungendauerpflegeuebersichtGet: async (
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen/dauerpflege/uebersicht`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenkarteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen/karte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenkurzzeitpflegeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen/kurzzeitpflege`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet: async (
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen/kurzzeitpflege/uebersicht`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenuebersichtGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder/einrichtungen/uebersicht`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * HeimfinderApi - functional programming interface
 * @export
 */
export const HeimfinderApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = HeimfinderApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {string} id Heimfinder identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfindereinrichtungIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungenGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfindereinrichtungenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungendauerpflegeGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfindereinrichtungendauerpflegeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungendauerpflegeuebersichtGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.apiHeimfindereinrichtungendauerpflegeuebersichtGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungenkarteGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfindereinrichtungenkarteGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungenkurzzeitpflegeGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfindereinrichtungenkurzzeitpflegeGet(
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfindereinrichtungenuebersichtGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderArrayObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfindereinrichtungenuebersichtGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * HeimfinderApi - factory interface
 * @export
 */
export const HeimfinderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HeimfinderApiFp(configuration);
    return {
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {string} id Heimfinder identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungIdGet(id: string, options?: any): AxiosPromise<HeimfinderGet> {
            return localVarFp.apiHeimfindereinrichtungIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp.apiHeimfindereinrichtungenGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungendauerpflegeGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp
                .apiHeimfindereinrichtungendauerpflegeGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungendauerpflegeuebersichtGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp
                .apiHeimfindereinrichtungendauerpflegeuebersichtGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenkarteGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp.apiHeimfindereinrichtungenkarteGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenkurzzeitpflegeGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp
                .apiHeimfindereinrichtungenkurzzeitpflegeGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp
                .apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinder resource.
         * @summary Retrieves a Heimfinder resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfindereinrichtungenuebersichtGet(options?: any): AxiosPromise<HeimfinderArrayObject> {
            return localVarFp
                .apiHeimfindereinrichtungenuebersichtGet(options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * HeimfinderApi - object-oriented interface
 * @export
 * @class HeimfinderApi
 * @extends {BaseAPI}
 */
export class HeimfinderApi extends BaseAPI {
    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {string} id Heimfinder identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungIdGet(id: string, options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungenGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungenGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungendauerpflegeGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungendauerpflegeGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungendauerpflegeuebersichtGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungendauerpflegeuebersichtGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungenkarteGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungenkarteGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungenkurzzeitpflegeGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungenkurzzeitpflegeGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungenkurzzeitpflegeuebersichtGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinder resource.
     * @summary Retrieves a Heimfinder resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderApi
     */
    public apiHeimfindereinrichtungenuebersichtGet(options?: AxiosRequestConfig) {
        return HeimfinderApiFp(this.configuration)
            .apiHeimfindereinrichtungenuebersichtGet(options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * HeimfinderUploadApi - axios parameter creator
 * @export
 */
export const HeimfinderUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a HeimfinderUpload resource.
         * @summary Retrieves a HeimfinderUpload resource.
         * @param {string} id HeimfinderUpload identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderUploadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiHeimfinderUploadIdGet', 'id', id);
            const localVarPath = `/api/heimfinder-upload/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a HeimfinderUpload resource.
         * @summary Creates a HeimfinderUpload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderUploadPost: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinder-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * HeimfinderUploadApi - functional programming interface
 * @export
 */
export const HeimfinderUploadApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = HeimfinderUploadApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a HeimfinderUpload resource.
         * @summary Retrieves a HeimfinderUpload resource.
         * @param {string} id HeimfinderUpload identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfinderUploadIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderUploadJsonldUploadRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfinderUploadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a HeimfinderUpload resource.
         * @summary Creates a HeimfinderUpload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfinderUploadPost(
            file?: any,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderUploadJsonldUploadRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfinderUploadPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * HeimfinderUploadApi - factory interface
 * @export
 */
export const HeimfinderUploadApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = HeimfinderUploadApiFp(configuration);
    return {
        /**
         * Retrieves a HeimfinderUpload resource.
         * @summary Retrieves a HeimfinderUpload resource.
         * @param {string} id HeimfinderUpload identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderUploadIdGet(id: string, options?: any): AxiosPromise<HeimfinderUploadJsonldUploadRead> {
            return localVarFp.apiHeimfinderUploadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a HeimfinderUpload resource.
         * @summary Creates a HeimfinderUpload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderUploadPost(file?: any, options?: any): AxiosPromise<HeimfinderUploadJsonldUploadRead> {
            return localVarFp.apiHeimfinderUploadPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HeimfinderUploadApi - object-oriented interface
 * @export
 * @class HeimfinderUploadApi
 * @extends {BaseAPI}
 */
export class HeimfinderUploadApi extends BaseAPI {
    /**
     * Retrieves a HeimfinderUpload resource.
     * @summary Retrieves a HeimfinderUpload resource.
     * @param {string} id HeimfinderUpload identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderUploadApi
     */
    public apiHeimfinderUploadIdGet(id: string, options?: AxiosRequestConfig) {
        return HeimfinderUploadApiFp(this.configuration)
            .apiHeimfinderUploadIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a HeimfinderUpload resource.
     * @summary Creates a HeimfinderUpload resource.
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderUploadApi
     */
    public apiHeimfinderUploadPost(file?: any, options?: AxiosRequestConfig) {
        return HeimfinderUploadApiFp(this.configuration)
            .apiHeimfinderUploadPost(file, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * HeimfinderprofilApi - axios parameter creator
 * @export
 */
export const HeimfinderprofilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Heimfinderprofil resources.
         * @summary Retrieves the collection of Heimfinderprofil resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderprofilGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/heimfinderprofil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Heimfinderprofil resource.
         * @summary Retrieves a Heimfinderprofil resource.
         * @param {string} id Heimfinder identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderprofilIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiHeimfinderprofilIdGet', 'id', id);
            const localVarPath = `/api/heimfinderprofil/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Heimfinderprofil resource.
         * @summary Updates the Heimfinderprofil resource.
         * @param {string} id Heimfinder identifier
         * @param {HeimfinderprofilHeimfinderWrite} heimfinderprofilHeimfinderWrite The updated Heimfinderprofil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderprofilIdPatch: async (
            id: string,
            heimfinderprofilHeimfinderWrite: HeimfinderprofilHeimfinderWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiHeimfinderprofilIdPatch', 'id', id);
            // verify required parameter 'heimfinderprofilHeimfinderWrite' is not null or undefined
            assertParamExists(
                'apiHeimfinderprofilIdPatch',
                'heimfinderprofilHeimfinderWrite',
                heimfinderprofilHeimfinderWrite
            );
            const localVarPath = `/api/heimfinderprofil/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                heimfinderprofilHeimfinderWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * HeimfinderprofilApi - functional programming interface
 * @export
 */
export const HeimfinderprofilApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = HeimfinderprofilApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Heimfinderprofil resources.
         * @summary Retrieves the collection of Heimfinderprofil resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfinderprofilGetCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiHeimfinderprofilGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfinderprofilGetCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Heimfinderprofil resource.
         * @summary Retrieves a Heimfinderprofil resource.
         * @param {string} id Heimfinder identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfinderprofilIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderprofilJsonldHeimfinderRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfinderprofilIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Heimfinderprofil resource.
         * @summary Updates the Heimfinderprofil resource.
         * @param {string} id Heimfinder identifier
         * @param {HeimfinderprofilHeimfinderWrite} heimfinderprofilHeimfinderWrite The updated Heimfinderprofil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHeimfinderprofilIdPatch(
            id: string,
            heimfinderprofilHeimfinderWrite: HeimfinderprofilHeimfinderWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeimfinderprofilJsonldHeimfinderRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHeimfinderprofilIdPatch(
                id,
                heimfinderprofilHeimfinderWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * HeimfinderprofilApi - factory interface
 * @export
 */
export const HeimfinderprofilApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = HeimfinderprofilApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Heimfinderprofil resources.
         * @summary Retrieves the collection of Heimfinderprofil resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderprofilGetCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<ApiHeimfinderprofilGetCollection200Response> {
            return localVarFp
                .apiHeimfinderprofilGetCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Heimfinderprofil resource.
         * @summary Retrieves a Heimfinderprofil resource.
         * @param {string} id Heimfinder identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderprofilIdGet(id: string, options?: any): AxiosPromise<HeimfinderprofilJsonldHeimfinderRead> {
            return localVarFp.apiHeimfinderprofilIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Heimfinderprofil resource.
         * @summary Updates the Heimfinderprofil resource.
         * @param {string} id Heimfinder identifier
         * @param {HeimfinderprofilHeimfinderWrite} heimfinderprofilHeimfinderWrite The updated Heimfinderprofil resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHeimfinderprofilIdPatch(
            id: string,
            heimfinderprofilHeimfinderWrite: HeimfinderprofilHeimfinderWrite,
            options?: any
        ): AxiosPromise<HeimfinderprofilJsonldHeimfinderRead> {
            return localVarFp
                .apiHeimfinderprofilIdPatch(id, heimfinderprofilHeimfinderWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * HeimfinderprofilApi - object-oriented interface
 * @export
 * @class HeimfinderprofilApi
 * @extends {BaseAPI}
 */
export class HeimfinderprofilApi extends BaseAPI {
    /**
     * Retrieves the collection of Heimfinderprofil resources.
     * @summary Retrieves the collection of Heimfinderprofil resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderprofilApi
     */
    public apiHeimfinderprofilGetCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return HeimfinderprofilApiFp(this.configuration)
            .apiHeimfinderprofilGetCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Heimfinderprofil resource.
     * @summary Retrieves a Heimfinderprofil resource.
     * @param {string} id Heimfinder identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderprofilApi
     */
    public apiHeimfinderprofilIdGet(id: string, options?: AxiosRequestConfig) {
        return HeimfinderprofilApiFp(this.configuration)
            .apiHeimfinderprofilIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Heimfinderprofil resource.
     * @summary Updates the Heimfinderprofil resource.
     * @param {string} id Heimfinder identifier
     * @param {HeimfinderprofilHeimfinderWrite} heimfinderprofilHeimfinderWrite The updated Heimfinderprofil resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeimfinderprofilApi
     */
    public apiHeimfinderprofilIdPatch(
        id: string,
        heimfinderprofilHeimfinderWrite: HeimfinderprofilHeimfinderWrite,
        options?: AxiosRequestConfig
    ) {
        return HeimfinderprofilApiFp(this.configuration)
            .apiHeimfinderprofilIdPatch(id, heimfinderprofilHeimfinderWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * InvestRegistrationApi - axios parameter creator
 * @export
 */
export const InvestRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of InvestRegistration resources.
         * @summary Retrieves the collection of InvestRegistration resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsTransfered]
         * @param {boolean} [failed]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            existsTransfered?: boolean,
            failed?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/invest-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (existsTransfered !== undefined) {
                localVarQueryParameter['exists[transfered]'] = existsTransfered;
            }

            if (failed !== undefined) {
                localVarQueryParameter['failed'] = failed;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a InvestRegistration resource.
         * @summary Retrieves a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvestRegistrationIdGet', 'id', id);
            const localVarPath = `/api/invest-registration/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a InvestRegistration resource.
         * @summary Creates a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {InvestRegistrationJsonldInvestRegistrationRegister} investRegistrationJsonldInvestRegistrationRegister The new InvestRegistration resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationIdregisterPost: async (
            id: string,
            investRegistrationJsonldInvestRegistrationRegister: InvestRegistrationJsonldInvestRegistrationRegister,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvestRegistrationIdregisterPost', 'id', id);
            // verify required parameter 'investRegistrationJsonldInvestRegistrationRegister' is not null or undefined
            assertParamExists(
                'apiInvestRegistrationIdregisterPost',
                'investRegistrationJsonldInvestRegistrationRegister',
                investRegistrationJsonldInvestRegistrationRegister
            );
            const localVarPath = `/api/invest-registration/{id}/register`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investRegistrationJsonldInvestRegistrationRegister,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a InvestRegistration resource.
         * @summary Creates a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {InvestRegistrationJsonld} investRegistrationJsonld The new InvestRegistration resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationIdretryPost: async (
            id: string,
            investRegistrationJsonld: InvestRegistrationJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvestRegistrationIdretryPost', 'id', id);
            // verify required parameter 'investRegistrationJsonld' is not null or undefined
            assertParamExists('apiInvestRegistrationIdretryPost', 'investRegistrationJsonld', investRegistrationJsonld);
            const localVarPath = `/api/invest-registration/{id}/retry`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                investRegistrationJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * InvestRegistrationApi - functional programming interface
 * @export
 */
export const InvestRegistrationApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestRegistrationApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of InvestRegistration resources.
         * @summary Retrieves the collection of InvestRegistration resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsTransfered]
         * @param {boolean} [failed]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvestRegistrationGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsTransfered?: boolean,
            failed?: boolean,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiInvestRegistrationGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvestRegistrationGetCollection(
                page,
                itemsPerPage,
                existsTransfered,
                failed,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a InvestRegistration resource.
         * @summary Retrieves a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvestRegistrationIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestRegistrationJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvestRegistrationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a InvestRegistration resource.
         * @summary Creates a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {InvestRegistrationJsonldInvestRegistrationRegister} investRegistrationJsonldInvestRegistrationRegister The new InvestRegistration resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvestRegistrationIdregisterPost(
            id: string,
            investRegistrationJsonldInvestRegistrationRegister: InvestRegistrationJsonldInvestRegistrationRegister,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestRegistrationJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvestRegistrationIdregisterPost(
                id,
                investRegistrationJsonldInvestRegistrationRegister,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a InvestRegistration resource.
         * @summary Creates a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {InvestRegistrationJsonld} investRegistrationJsonld The new InvestRegistration resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvestRegistrationIdretryPost(
            id: string,
            investRegistrationJsonld: InvestRegistrationJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestRegistrationJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvestRegistrationIdretryPost(
                id,
                investRegistrationJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * InvestRegistrationApi - factory interface
 * @export
 */
export const InvestRegistrationApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = InvestRegistrationApiFp(configuration);
    return {
        /**
         * Retrieves the collection of InvestRegistration resources.
         * @summary Retrieves the collection of InvestRegistration resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsTransfered]
         * @param {boolean} [failed]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationGetCollection(
            page?: number,
            itemsPerPage?: number,
            existsTransfered?: boolean,
            failed?: boolean,
            options?: any
        ): AxiosPromise<ApiInvestRegistrationGetCollection200Response> {
            return localVarFp
                .apiInvestRegistrationGetCollection(page, itemsPerPage, existsTransfered, failed, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a InvestRegistration resource.
         * @summary Retrieves a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationIdGet(id: string, options?: any): AxiosPromise<InvestRegistrationJsonld> {
            return localVarFp.apiInvestRegistrationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a InvestRegistration resource.
         * @summary Creates a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {InvestRegistrationJsonldInvestRegistrationRegister} investRegistrationJsonldInvestRegistrationRegister The new InvestRegistration resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationIdregisterPost(
            id: string,
            investRegistrationJsonldInvestRegistrationRegister: InvestRegistrationJsonldInvestRegistrationRegister,
            options?: any
        ): AxiosPromise<InvestRegistrationJsonld> {
            return localVarFp
                .apiInvestRegistrationIdregisterPost(id, investRegistrationJsonldInvestRegistrationRegister, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a InvestRegistration resource.
         * @summary Creates a InvestRegistration resource.
         * @param {string} id InvestRegistration identifier
         * @param {InvestRegistrationJsonld} investRegistrationJsonld The new InvestRegistration resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvestRegistrationIdretryPost(
            id: string,
            investRegistrationJsonld: InvestRegistrationJsonld,
            options?: any
        ): AxiosPromise<InvestRegistrationJsonld> {
            return localVarFp
                .apiInvestRegistrationIdretryPost(id, investRegistrationJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvestRegistrationApi - object-oriented interface
 * @export
 * @class InvestRegistrationApi
 * @extends {BaseAPI}
 */
export class InvestRegistrationApi extends BaseAPI {
    /**
     * Retrieves the collection of InvestRegistration resources.
     * @summary Retrieves the collection of InvestRegistration resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [existsTransfered]
     * @param {boolean} [failed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestRegistrationApi
     */
    public apiInvestRegistrationGetCollection(
        page?: number,
        itemsPerPage?: number,
        existsTransfered?: boolean,
        failed?: boolean,
        options?: AxiosRequestConfig
    ) {
        return InvestRegistrationApiFp(this.configuration)
            .apiInvestRegistrationGetCollection(page, itemsPerPage, existsTransfered, failed, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a InvestRegistration resource.
     * @summary Retrieves a InvestRegistration resource.
     * @param {string} id InvestRegistration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestRegistrationApi
     */
    public apiInvestRegistrationIdGet(id: string, options?: AxiosRequestConfig) {
        return InvestRegistrationApiFp(this.configuration)
            .apiInvestRegistrationIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a InvestRegistration resource.
     * @summary Creates a InvestRegistration resource.
     * @param {string} id InvestRegistration identifier
     * @param {InvestRegistrationJsonldInvestRegistrationRegister} investRegistrationJsonldInvestRegistrationRegister The new InvestRegistration resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestRegistrationApi
     */
    public apiInvestRegistrationIdregisterPost(
        id: string,
        investRegistrationJsonldInvestRegistrationRegister: InvestRegistrationJsonldInvestRegistrationRegister,
        options?: AxiosRequestConfig
    ) {
        return InvestRegistrationApiFp(this.configuration)
            .apiInvestRegistrationIdregisterPost(id, investRegistrationJsonldInvestRegistrationRegister, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a InvestRegistration resource.
     * @summary Creates a InvestRegistration resource.
     * @param {string} id InvestRegistration identifier
     * @param {InvestRegistrationJsonld} investRegistrationJsonld The new InvestRegistration resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestRegistrationApi
     */
    public apiInvestRegistrationIdretryPost(
        id: string,
        investRegistrationJsonld: InvestRegistrationJsonld,
        options?: AxiosRequestConfig
    ) {
        return InvestRegistrationApiFp(this.configuration)
            .apiInvestRegistrationIdretryPost(id, investRegistrationJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LandschaftsverbandApi - axios parameter creator
 * @export
 */
export const LandschaftsverbandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Landschaftsverband resources.
         * @summary Retrieves the collection of Landschaftsverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/landschaftsverband`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderAdresseEmail !== undefined) {
                localVarQueryParameter['order[adresse.email]'] = orderAdresseEmail;
            }

            if (orderAdresseName !== undefined) {
                localVarQueryParameter['order[adresse.name]'] = orderAdresseName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Landschaftsverband resource.
         * @summary Retrieves a Landschaftsverband resource.
         * @param {string} id Landschaftsverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLandschaftsverbandIdGet', 'id', id);
            const localVarPath = `/api/landschaftsverband/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LandschaftsverbandApi - functional programming interface
 * @export
 */
export const LandschaftsverbandApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LandschaftsverbandApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Landschaftsverband resources.
         * @summary Retrieves the collection of Landschaftsverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLandschaftsverbandGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiLandschaftsverbandGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLandschaftsverbandGetCollection(
                page,
                itemsPerPage,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Landschaftsverband resource.
         * @summary Retrieves a Landschaftsverband resource.
         * @param {string} id Landschaftsverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLandschaftsverbandIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLandschaftsverbandIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LandschaftsverbandApi - factory interface
 * @export
 */
export const LandschaftsverbandApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = LandschaftsverbandApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Landschaftsverband resources.
         * @summary Retrieves the collection of Landschaftsverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiLandschaftsverbandGetCollection200Response> {
            return localVarFp
                .apiLandschaftsverbandGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    orderName,
                    orderAdresseEmail,
                    orderAdresseName,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Landschaftsverband resource.
         * @summary Retrieves a Landschaftsverband resource.
         * @param {string} id Landschaftsverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandIdGet(
            id: string,
            options?: any
        ): AxiosPromise<LandschaftsverbandJsonldLandschaftsverbandReadRolleEinrichtung> {
            return localVarFp.apiLandschaftsverbandIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandschaftsverbandApi - object-oriented interface
 * @export
 * @class LandschaftsverbandApi
 * @extends {BaseAPI}
 */
export class LandschaftsverbandApi extends BaseAPI {
    /**
     * Retrieves the collection of Landschaftsverband resources.
     * @summary Retrieves the collection of Landschaftsverband resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderAdresseEmail]
     * @param {'asc' | 'desc'} [orderAdresseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandApi
     */
    public apiLandschaftsverbandGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderAdresseEmail?: 'asc' | 'desc',
        orderAdresseName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return LandschaftsverbandApiFp(this.configuration)
            .apiLandschaftsverbandGetCollection(
                page,
                itemsPerPage,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Landschaftsverband resource.
     * @summary Retrieves a Landschaftsverband resource.
     * @param {string} id Landschaftsverband identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandApi
     */
    public apiLandschaftsverbandIdGet(id: string, options?: AxiosRequestConfig) {
        return LandschaftsverbandApiFp(this.configuration)
            .apiLandschaftsverbandIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LandschaftsverbandDtoApi - axios parameter creator
 * @export
 */
export const LandschaftsverbandDtoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a LandschaftsverbandDto resource.
         * @summary Retrieves a LandschaftsverbandDto resource.
         * @param {string} id LandschaftsverbandDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandDtoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLandschaftsverbandDtoIdGet', 'id', id);
            const localVarPath = `/api/landschaftsverband-dto/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the LandschaftsverbandDto resource.
         * @summary Updates the LandschaftsverbandDto resource.
         * @param {string} id LandschaftsverbandDto identifier
         * @param {LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung} landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung The updated LandschaftsverbandDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandDtoIdPatch: async (
            id: string,
            landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLandschaftsverbandDtoIdPatch', 'id', id);
            // verify required parameter 'landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung' is not null or undefined
            assertParamExists(
                'apiLandschaftsverbandDtoIdPatch',
                'landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung',
                landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung
            );
            const localVarPath = `/api/landschaftsverband-dto/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LandschaftsverbandDtoApi - functional programming interface
 * @export
 */
export const LandschaftsverbandDtoApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LandschaftsverbandDtoApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a LandschaftsverbandDto resource.
         * @summary Retrieves a LandschaftsverbandDto resource.
         * @param {string} id LandschaftsverbandDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLandschaftsverbandDtoIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLandschaftsverbandDtoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the LandschaftsverbandDto resource.
         * @summary Updates the LandschaftsverbandDto resource.
         * @param {string} id LandschaftsverbandDto identifier
         * @param {LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung} landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung The updated LandschaftsverbandDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLandschaftsverbandDtoIdPatch(
            id: string,
            landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandschaftsverbandDtoJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLandschaftsverbandDtoIdPatch(
                id,
                landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LandschaftsverbandDtoApi - factory interface
 * @export
 */
export const LandschaftsverbandDtoApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = LandschaftsverbandDtoApiFp(configuration);
    return {
        /**
         * Retrieves a LandschaftsverbandDto resource.
         * @summary Retrieves a LandschaftsverbandDto resource.
         * @param {string} id LandschaftsverbandDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandDtoIdGet(
            id: string,
            options?: any
        ): AxiosPromise<LandschaftsverbandDtoJsonldLandschaftsverbandReadRolleEinrichtung> {
            return localVarFp.apiLandschaftsverbandDtoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the LandschaftsverbandDto resource.
         * @summary Updates the LandschaftsverbandDto resource.
         * @param {string} id LandschaftsverbandDto identifier
         * @param {LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung} landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung The updated LandschaftsverbandDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLandschaftsverbandDtoIdPatch(
            id: string,
            landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
            options?: any
        ): AxiosPromise<LandschaftsverbandDtoJsonld> {
            return localVarFp
                .apiLandschaftsverbandDtoIdPatch(
                    id,
                    landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandschaftsverbandDtoApi - object-oriented interface
 * @export
 * @class LandschaftsverbandDtoApi
 * @extends {BaseAPI}
 */
export class LandschaftsverbandDtoApi extends BaseAPI {
    /**
     * Retrieves a LandschaftsverbandDto resource.
     * @summary Retrieves a LandschaftsverbandDto resource.
     * @param {string} id LandschaftsverbandDto identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandDtoApi
     */
    public apiLandschaftsverbandDtoIdGet(id: string, options?: AxiosRequestConfig) {
        return LandschaftsverbandDtoApiFp(this.configuration)
            .apiLandschaftsverbandDtoIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the LandschaftsverbandDto resource.
     * @summary Updates the LandschaftsverbandDto resource.
     * @param {string} id LandschaftsverbandDto identifier
     * @param {LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung} landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung The updated LandschaftsverbandDto resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandschaftsverbandDtoApi
     */
    public apiLandschaftsverbandDtoIdPatch(
        id: string,
        landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung: LandschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung,
        options?: AxiosRequestConfig
    ) {
        return LandschaftsverbandDtoApiFp(this.configuration)
            .apiLandschaftsverbandDtoIdPatch(id, landschaftsverbandDtoLandschaftsverbandWriteRolleEinrichtung, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LeistungsanbieterApi - axios parameter creator
 * @export
 */
export const LeistungsanbieterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Leistungsanbieter resource.
         * @summary Removes the Leistungsanbieter resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdformDelete: async (
            id: number,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistunganbieterIdformDelete', 'id', id);
            const localVarPath = `/api/leistunganbieter/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Leistungsanbieter resource.
         * @summary Retrieves a Leistungsanbieter resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdformGet: async (
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistunganbieterIdformGet', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiLeistunganbieterIdformGet', 'step', step);
            const localVarPath = `/api/leistunganbieter/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Leistungsanbieter resource.
         * @summary Updates the Leistungsanbieter resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated Leistungsanbieter resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdformPatch: async (
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistunganbieterIdformPatch', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiLeistunganbieterIdformPatch', 'step', step);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiLeistunganbieterIdformPatch', 'body', body);
            const localVarPath = `/api/leistunganbieter/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Leistungsanbieter resource.
         * @summary Updates the Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {LeistungsanbieterAntragWorkflowActionAntragWorkflow} leistungsanbieterAntragWorkflowActionAntragWorkflow The updated Leistungsanbieter resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdworkflowPatch: async (
            id: string,
            leistungsanbieterAntragWorkflowActionAntragWorkflow: LeistungsanbieterAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistunganbieterIdworkflowPatch', 'id', id);
            // verify required parameter 'leistungsanbieterAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiLeistunganbieterIdworkflowPatch',
                'leistungsanbieterAntragWorkflowActionAntragWorkflow',
                leistungsanbieterAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/leistunganbieter/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                leistungsanbieterAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Leistungsanbieter resources.
         * @summary Retrieves the collection of Leistungsanbieter resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [name]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsanbieterGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            name?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/leistungsanbieter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (eingereichtBy !== undefined) {
                localVarQueryParameter['eingereichtBy'] = eingereichtBy;
            }

            if (eingereichtBy2) {
                localVarQueryParameter['eingereichtBy[]'] = eingereichtBy2;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = modifiedAt;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderEingereichtAt !== undefined) {
                localVarQueryParameter['order[eingereichtAt]'] = orderEingereichtAt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Leistungsanbieter resource.
         * @summary Removes the Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsanbieterIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsanbieterIdDelete', 'id', id);
            const localVarPath = `/api/leistungsanbieter/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Leistungsanbieter resource.
         * @summary Retrieves a Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsanbieterIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsanbieterIdGet', 'id', id);
            const localVarPath = `/api/leistungsanbieter/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Leistungsanbieter resource.
         * @summary Creates a Leistungsanbieter resource.
         * @param {object} body The new Leistungsanbieter resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leistungsanbieterCreate: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('leistungsanbieterCreate', 'body', body);
            const localVarPath = `/api/leistungsanbieter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LeistungsanbieterApi - functional programming interface
 * @export
 */
export const LeistungsanbieterApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LeistungsanbieterApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the Leistungsanbieter resource.
         * @summary Removes the Leistungsanbieter resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistunganbieterIdformDelete(
            id: number,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistunganbieterIdformDelete(
                id,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Leistungsanbieter resource.
         * @summary Retrieves a Leistungsanbieter resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistunganbieterIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistunganbieterIdformGet(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Leistungsanbieter resource.
         * @summary Updates the Leistungsanbieter resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated Leistungsanbieter resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistunganbieterIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistunganbieterIdformPatch(
                id,
                step,
                body,
                objectType,
                uuid,
                persist,
                fields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Leistungsanbieter resource.
         * @summary Updates the Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {LeistungsanbieterAntragWorkflowActionAntragWorkflow} leistungsanbieterAntragWorkflowActionAntragWorkflow The updated Leistungsanbieter resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistunganbieterIdworkflowPatch(
            id: string,
            leistungsanbieterAntragWorkflowActionAntragWorkflow: LeistungsanbieterAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeistungsanbieterJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistunganbieterIdworkflowPatch(
                id,
                leistungsanbieterAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Leistungsanbieter resources.
         * @summary Retrieves the collection of Leistungsanbieter resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [name]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsanbieterGetCollection(
            page?: number,
            itemsPerPage?: number,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            name?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiLeistungsanbieterGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsanbieterGetCollection(
                page,
                itemsPerPage,
                eingereichtBy,
                eingereichtBy2,
                name,
                modifiedAt,
                status,
                status2,
                qSearch,
                orderName,
                orderEingereichtAt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Leistungsanbieter resource.
         * @summary Removes the Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsanbieterIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsanbieterIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Leistungsanbieter resource.
         * @summary Retrieves a Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsanbieterIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeistungsanbieterJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsanbieterIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Leistungsanbieter resource.
         * @summary Creates a Leistungsanbieter resource.
         * @param {object} body The new Leistungsanbieter resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leistungsanbieterCreate(
            body: object,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeistungsanbieterJsonldLeistungsanbieterGetId>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leistungsanbieterCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LeistungsanbieterApi - factory interface
 * @export
 */
export const LeistungsanbieterApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = LeistungsanbieterApiFp(configuration);
    return {
        /**
         * Removes the Leistungsanbieter resource.
         * @summary Removes the Leistungsanbieter resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdformDelete(id: number, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .apiLeistunganbieterIdformDelete(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Leistungsanbieter resource.
         * @summary Retrieves a Leistungsanbieter resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiLeistunganbieterIdformGet(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Leistungsanbieter resource.
         * @summary Updates the Leistungsanbieter resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated Leistungsanbieter resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: any
        ): AxiosPromise<LeistungsanbieterLeistungsanbieterJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiLeistunganbieterIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Leistungsanbieter resource.
         * @summary Updates the Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {LeistungsanbieterAntragWorkflowActionAntragWorkflow} leistungsanbieterAntragWorkflowActionAntragWorkflow The updated Leistungsanbieter resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistunganbieterIdworkflowPatch(
            id: string,
            leistungsanbieterAntragWorkflowActionAntragWorkflow: LeistungsanbieterAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<LeistungsanbieterJsonld> {
            return localVarFp
                .apiLeistunganbieterIdworkflowPatch(id, leistungsanbieterAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Leistungsanbieter resources.
         * @summary Retrieves the collection of Leistungsanbieter resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [name]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEingereichtAt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsanbieterGetCollection(
            page?: number,
            itemsPerPage?: number,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            name?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderEingereichtAt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiLeistungsanbieterGetCollection200Response> {
            return localVarFp
                .apiLeistungsanbieterGetCollection(
                    page,
                    itemsPerPage,
                    eingereichtBy,
                    eingereichtBy2,
                    name,
                    modifiedAt,
                    status,
                    status2,
                    qSearch,
                    orderName,
                    orderEingereichtAt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the Leistungsanbieter resource.
         * @summary Removes the Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsanbieterIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLeistungsanbieterIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Leistungsanbieter resource.
         * @summary Retrieves a Leistungsanbieter resource.
         * @param {string} id Leistungsanbieter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsanbieterIdGet(id: string, options?: any): AxiosPromise<LeistungsanbieterJsonld> {
            return localVarFp.apiLeistungsanbieterIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Leistungsanbieter resource.
         * @summary Creates a Leistungsanbieter resource.
         * @param {object} body The new Leistungsanbieter resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leistungsanbieterCreate(
            body: object,
            options?: any
        ): AxiosPromise<LeistungsanbieterJsonldLeistungsanbieterGetId> {
            return localVarFp.leistungsanbieterCreate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeistungsanbieterApi - object-oriented interface
 * @export
 * @class LeistungsanbieterApi
 * @extends {BaseAPI}
 */
export class LeistungsanbieterApi extends BaseAPI {
    /**
     * Removes the Leistungsanbieter resource.
     * @summary Removes the Leistungsanbieter resource.
     * @param {number} id
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistunganbieterIdformDelete(id: number, uuid?: string, options?: AxiosRequestConfig) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistunganbieterIdformDelete(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Leistungsanbieter resource.
     * @summary Retrieves a Leistungsanbieter resource.
     * @param {number} id
     * @param {string} step
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistunganbieterIdformGet(
        id: number,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistunganbieterIdformGet(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Leistungsanbieter resource.
     * @summary Updates the Leistungsanbieter resource.
     * @param {number} id
     * @param {string} step
     * @param {object} body The updated Leistungsanbieter resource
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistunganbieterIdformPatch(
        id: number,
        step: string,
        body: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistunganbieterIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Leistungsanbieter resource.
     * @summary Updates the Leistungsanbieter resource.
     * @param {string} id Leistungsanbieter identifier
     * @param {LeistungsanbieterAntragWorkflowActionAntragWorkflow} leistungsanbieterAntragWorkflowActionAntragWorkflow The updated Leistungsanbieter resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistunganbieterIdworkflowPatch(
        id: string,
        leistungsanbieterAntragWorkflowActionAntragWorkflow: LeistungsanbieterAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistunganbieterIdworkflowPatch(id, leistungsanbieterAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Leistungsanbieter resources.
     * @summary Retrieves the collection of Leistungsanbieter resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [eingereichtBy]
     * @param {Array<string>} [eingereichtBy2]
     * @param {string} [name]
     * @param {string} [modifiedAt]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderEingereichtAt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistungsanbieterGetCollection(
        page?: number,
        itemsPerPage?: number,
        eingereichtBy?: string,
        eingereichtBy2?: Array<string>,
        name?: string,
        modifiedAt?: string,
        status?: string,
        status2?: Array<string>,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderEingereichtAt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistungsanbieterGetCollection(
                page,
                itemsPerPage,
                eingereichtBy,
                eingereichtBy2,
                name,
                modifiedAt,
                status,
                status2,
                qSearch,
                orderName,
                orderEingereichtAt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Leistungsanbieter resource.
     * @summary Removes the Leistungsanbieter resource.
     * @param {string} id Leistungsanbieter identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistungsanbieterIdDelete(id: string, options?: AxiosRequestConfig) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistungsanbieterIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Leistungsanbieter resource.
     * @summary Retrieves a Leistungsanbieter resource.
     * @param {string} id Leistungsanbieter identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public apiLeistungsanbieterIdGet(id: string, options?: AxiosRequestConfig) {
        return LeistungsanbieterApiFp(this.configuration)
            .apiLeistungsanbieterIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Leistungsanbieter resource.
     * @summary Creates a Leistungsanbieter resource.
     * @param {object} body The new Leistungsanbieter resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsanbieterApi
     */
    public leistungsanbieterCreate(body: object, options?: AxiosRequestConfig) {
        return LeistungsanbieterApiFp(this.configuration)
            .leistungsanbieterCreate(body, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LeistungsangebotRegistrierenApi - axios parameter creator
 * @export
 */
export const LeistungsangebotRegistrierenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of LeistungsangebotRegistrieren resources.
         * @summary Retrieves the collection of LeistungsangebotRegistrieren resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtungName]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderLeistungsanbieterName]
         * @param {'asc' | 'desc'} [orderLeistungsanbieterStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtungName?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            qSearch?: string,
            orderEinrichtungName?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderLeistungsanbieterName?: 'asc' | 'desc',
            orderLeistungsanbieterStatus?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/leistungsangebot-registrieren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (eingereichtBy !== undefined) {
                localVarQueryParameter['eingereichtBy'] = eingereichtBy;
            }

            if (eingereichtBy2) {
                localVarQueryParameter['eingereichtBy[]'] = eingereichtBy2;
            }

            if (einrichtungName !== undefined) {
                localVarQueryParameter['einrichtungName'] = einrichtungName;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = modifiedAt;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtungName]'] = orderEinrichtungName;
            }

            if (orderModifiedAt !== undefined) {
                localVarQueryParameter['order[modifiedAt]'] = orderModifiedAt;
            }

            if (orderZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[zuletztEingereichtAt]'] = orderZuletztEingereichtAt;
            }

            if (orderErstinbetriebnahme !== undefined) {
                localVarQueryParameter['order[erstinbetriebnahme]'] = orderErstinbetriebnahme;
            }

            if (orderOrt !== undefined) {
                localVarQueryParameter['order[ort]'] = orderOrt;
            }

            if (orderLeistungsanbieterName !== undefined) {
                localVarQueryParameter['order[leistungsanbieterName]'] = orderLeistungsanbieterName;
            }

            if (orderLeistungsanbieterStatus !== undefined) {
                localVarQueryParameter['order[leistungsanbieterStatus]'] = orderLeistungsanbieterStatus;
            }

            if (orderEinrichtungArt !== undefined) {
                localVarQueryParameter['order[einrichtungArt]'] = orderEinrichtungArt;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the LeistungsangebotRegistrieren resource.
         * @summary Removes the LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdDelete: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdDelete', 'id', id);
            const localVarPath = `/api/leistungsangebot-registrieren/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a LeistungsangebotRegistrieren resource.
         * @summary Retrieves a LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdGet: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdGet', 'id', id);
            const localVarPath = `/api/leistungsangebot-registrieren/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the LeistungsangebotRegistrieren resource.
         * @summary Removes the LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdformDelete: async (
            id: number,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdformDelete', 'id', id);
            const localVarPath = `/api/leistungsangebot-registrieren/{id}/form`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a LeistungsangebotRegistrieren resource.
         * @summary Retrieves a LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdformGet: async (
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdformGet', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdformGet', 'step', step);
            const localVarPath = `/api/leistungsangebot-registrieren/{id}/form`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the LeistungsangebotRegistrieren resource.
         * @summary Updates the LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated LeistungsangebotRegistrieren resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdformPatch: async (
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdformPatch', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdformPatch', 'step', step);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdformPatch', 'body', body);
            const localVarPath = `/api/leistungsangebot-registrieren/{id}/form`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the LeistungsangebotRegistrieren resource.
         * @summary Updates the LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow} leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow The updated LeistungsangebotRegistrieren resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdworkflowPatch: async (
            id: string,
            leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow: LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeistungsangebotRegistrierenIdworkflowPatch', 'id', id);
            // verify required parameter 'leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiLeistungsangebotRegistrierenIdworkflowPatch',
                'leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow',
                leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/leistungsangebot-registrieren/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a LeistungsangebotRegistrieren resource.
         * @summary Creates a LeistungsangebotRegistrieren resource.
         * @param {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite} leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite The new LeistungsangebotRegistrieren resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenPost: async (
            leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite' is not null or undefined
            assertParamExists(
                'apiLeistungsangebotRegistrierenPost',
                'leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite',
                leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite
            );
            const localVarPath = `/api/leistungsangebot-registrieren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LeistungsangebotRegistrierenApi - functional programming interface
 * @export
 */
export const LeistungsangebotRegistrierenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LeistungsangebotRegistrierenApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of LeistungsangebotRegistrieren resources.
         * @summary Retrieves the collection of LeistungsangebotRegistrieren resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtungName]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderLeistungsanbieterName]
         * @param {'asc' | 'desc'} [orderLeistungsanbieterStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenGetCollection(
            page?: number,
            itemsPerPage?: number,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtungName?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            qSearch?: string,
            orderEinrichtungName?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderLeistungsanbieterName?: 'asc' | 'desc',
            orderLeistungsanbieterStatus?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiLeistungsangebotRegistrierenGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenGetCollection(
                page,
                itemsPerPage,
                eingereichtBy,
                eingereichtBy2,
                einrichtungName,
                modifiedAt,
                status,
                status2,
                qSearch,
                orderEinrichtungName,
                orderModifiedAt,
                orderZuletztEingereichtAt,
                orderErstinbetriebnahme,
                orderOrt,
                orderLeistungsanbieterName,
                orderLeistungsanbieterStatus,
                orderEinrichtungArt,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the LeistungsangebotRegistrieren resource.
         * @summary Removes the LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenIdDelete(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a LeistungsangebotRegistrieren resource.
         * @summary Retrieves a LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the LeistungsangebotRegistrieren resource.
         * @summary Removes the LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenIdformDelete(
            id: number,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenIdformDelete(
                id,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a LeistungsangebotRegistrieren resource.
         * @summary Retrieves a LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenIdformGet(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the LeistungsangebotRegistrieren resource.
         * @summary Updates the LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated LeistungsangebotRegistrieren resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenIdformPatch(
                id,
                step,
                body,
                objectType,
                uuid,
                persist,
                fields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the LeistungsangebotRegistrieren resource.
         * @summary Updates the LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow} leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow The updated LeistungsangebotRegistrieren resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenIdworkflowPatch(
            id: string,
            leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow: LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeistungsangebotRegistrierenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenIdworkflowPatch(
                id,
                leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a LeistungsangebotRegistrieren resource.
         * @summary Creates a LeistungsangebotRegistrieren resource.
         * @param {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite} leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite The new LeistungsangebotRegistrieren resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeistungsangebotRegistrierenPost(
            leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeistungsangebotRegistrierenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeistungsangebotRegistrierenPost(
                leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LeistungsangebotRegistrierenApi - factory interface
 * @export
 */
export const LeistungsangebotRegistrierenApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = LeistungsangebotRegistrierenApiFp(configuration);
    return {
        /**
         * Retrieves the collection of LeistungsangebotRegistrieren resources.
         * @summary Retrieves the collection of LeistungsangebotRegistrieren resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtungName]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
         * @param {'asc' | 'desc'} [orderOrt]
         * @param {'asc' | 'desc'} [orderLeistungsanbieterName]
         * @param {'asc' | 'desc'} [orderLeistungsanbieterStatus]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenGetCollection(
            page?: number,
            itemsPerPage?: number,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtungName?: string,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            qSearch?: string,
            orderEinrichtungName?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderErstinbetriebnahme?: 'asc' | 'desc',
            orderOrt?: 'asc' | 'desc',
            orderLeistungsanbieterName?: 'asc' | 'desc',
            orderLeistungsanbieterStatus?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiLeistungsangebotRegistrierenGetCollection200Response> {
            return localVarFp
                .apiLeistungsangebotRegistrierenGetCollection(
                    page,
                    itemsPerPage,
                    eingereichtBy,
                    eingereichtBy2,
                    einrichtungName,
                    modifiedAt,
                    status,
                    status2,
                    qSearch,
                    orderEinrichtungName,
                    orderModifiedAt,
                    orderZuletztEingereichtAt,
                    orderErstinbetriebnahme,
                    orderOrt,
                    orderLeistungsanbieterName,
                    orderLeistungsanbieterStatus,
                    orderEinrichtungArt,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the LeistungsangebotRegistrieren resource.
         * @summary Removes the LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .apiLeistungsangebotRegistrierenIdDelete(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a LeistungsangebotRegistrieren resource.
         * @summary Retrieves a LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdGet(
            id: string,
            options?: any
        ): AxiosPromise<LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenReadLeistungsanbieterRead> {
            return localVarFp
                .apiLeistungsangebotRegistrierenIdGet(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the LeistungsangebotRegistrieren resource.
         * @summary Removes the LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdformDelete(id: number, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .apiLeistungsangebotRegistrierenIdformDelete(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a LeistungsangebotRegistrieren resource.
         * @summary Retrieves a LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiLeistungsangebotRegistrierenIdformGet(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the LeistungsangebotRegistrieren resource.
         * @summary Updates the LeistungsangebotRegistrieren resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated LeistungsangebotRegistrieren resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: any
        ): AxiosPromise<LeistungsangebotRegistrierenLeistungsangebotRegistrierenJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiLeistungsangebotRegistrierenIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the LeistungsangebotRegistrieren resource.
         * @summary Updates the LeistungsangebotRegistrieren resource.
         * @param {string} id LeistungsangebotRegistrieren identifier
         * @param {LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow} leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow The updated LeistungsangebotRegistrieren resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenIdworkflowPatch(
            id: string,
            leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow: LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<LeistungsangebotRegistrierenJsonld> {
            return localVarFp
                .apiLeistungsangebotRegistrierenIdworkflowPatch(
                    id,
                    leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a LeistungsangebotRegistrieren resource.
         * @summary Creates a LeistungsangebotRegistrieren resource.
         * @param {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite} leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite The new LeistungsangebotRegistrieren resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeistungsangebotRegistrierenPost(
            leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
            options?: any
        ): AxiosPromise<LeistungsangebotRegistrierenJsonld> {
            return localVarFp
                .apiLeistungsangebotRegistrierenPost(
                    leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeistungsangebotRegistrierenApi - object-oriented interface
 * @export
 * @class LeistungsangebotRegistrierenApi
 * @extends {BaseAPI}
 */
export class LeistungsangebotRegistrierenApi extends BaseAPI {
    /**
     * Retrieves the collection of LeistungsangebotRegistrieren resources.
     * @summary Retrieves the collection of LeistungsangebotRegistrieren resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [eingereichtBy]
     * @param {Array<string>} [eingereichtBy2]
     * @param {string} [einrichtungName]
     * @param {string} [modifiedAt]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderModifiedAt]
     * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderErstinbetriebnahme]
     * @param {'asc' | 'desc'} [orderOrt]
     * @param {'asc' | 'desc'} [orderLeistungsanbieterName]
     * @param {'asc' | 'desc'} [orderLeistungsanbieterStatus]
     * @param {'asc' | 'desc'} [orderEinrichtungArt]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenGetCollection(
        page?: number,
        itemsPerPage?: number,
        eingereichtBy?: string,
        eingereichtBy2?: Array<string>,
        einrichtungName?: string,
        modifiedAt?: string,
        status?: string,
        status2?: Array<string>,
        qSearch?: string,
        orderEinrichtungName?: 'asc' | 'desc',
        orderModifiedAt?: 'asc' | 'desc',
        orderZuletztEingereichtAt?: 'asc' | 'desc',
        orderErstinbetriebnahme?: 'asc' | 'desc',
        orderOrt?: 'asc' | 'desc',
        orderLeistungsanbieterName?: 'asc' | 'desc',
        orderLeistungsanbieterStatus?: 'asc' | 'desc',
        orderEinrichtungArt?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenGetCollection(
                page,
                itemsPerPage,
                eingereichtBy,
                eingereichtBy2,
                einrichtungName,
                modifiedAt,
                status,
                status2,
                qSearch,
                orderEinrichtungName,
                orderModifiedAt,
                orderZuletztEingereichtAt,
                orderErstinbetriebnahme,
                orderOrt,
                orderLeistungsanbieterName,
                orderLeistungsanbieterStatus,
                orderEinrichtungArt,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the LeistungsangebotRegistrieren resource.
     * @summary Removes the LeistungsangebotRegistrieren resource.
     * @param {string} id LeistungsangebotRegistrieren identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenIdDelete(id: string, options?: AxiosRequestConfig) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a LeistungsangebotRegistrieren resource.
     * @summary Retrieves a LeistungsangebotRegistrieren resource.
     * @param {string} id LeistungsangebotRegistrieren identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenIdGet(id: string, options?: AxiosRequestConfig) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the LeistungsangebotRegistrieren resource.
     * @summary Removes the LeistungsangebotRegistrieren resource.
     * @param {number} id
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenIdformDelete(id: number, uuid?: string, options?: AxiosRequestConfig) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenIdformDelete(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a LeistungsangebotRegistrieren resource.
     * @summary Retrieves a LeistungsangebotRegistrieren resource.
     * @param {number} id
     * @param {string} step
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenIdformGet(
        id: number,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenIdformGet(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the LeistungsangebotRegistrieren resource.
     * @summary Updates the LeistungsangebotRegistrieren resource.
     * @param {number} id
     * @param {string} step
     * @param {object} body The updated LeistungsangebotRegistrieren resource
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenIdformPatch(
        id: number,
        step: string,
        body: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the LeistungsangebotRegistrieren resource.
     * @summary Updates the LeistungsangebotRegistrieren resource.
     * @param {string} id LeistungsangebotRegistrieren identifier
     * @param {LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow} leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow The updated LeistungsangebotRegistrieren resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenIdworkflowPatch(
        id: string,
        leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow: LeistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenIdworkflowPatch(
                id,
                leistungsangebotRegistrierenAntragWorkflowActionAntragWorkflow,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a LeistungsangebotRegistrieren resource.
     * @summary Creates a LeistungsangebotRegistrieren resource.
     * @param {LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite} leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite The new LeistungsangebotRegistrieren resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeistungsangebotRegistrierenApi
     */
    public apiLeistungsangebotRegistrierenPost(
        leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite: LeistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
        options?: AxiosRequestConfig
    ) {
        return LeistungsangebotRegistrierenApiFp(this.configuration)
            .apiLeistungsangebotRegistrierenPost(
                leistungsangebotRegistrierenJsonldLeistungsangebotRegistrierenWrite,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LoginCheckApi - axios parameter creator
 * @export
 */
export const LoginCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a user token.
         * @summary Creates a user token.
         * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCheckPost: async (
            loginCheckPostRequest: LoginCheckPostRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'loginCheckPostRequest' is not null or undefined
            assertParamExists('loginCheckPost', 'loginCheckPostRequest', loginCheckPostRequest);
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                loginCheckPostRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LoginCheckApi - functional programming interface
 * @export
 */
export const LoginCheckApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginCheckApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates a user token.
         * @summary Creates a user token.
         * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginCheckPost(
            loginCheckPostRequest: LoginCheckPostRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginCheckPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginCheckPost(loginCheckPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LoginCheckApi - factory interface
 * @export
 */
export const LoginCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginCheckApiFp(configuration);
    return {
        /**
         * Creates a user token.
         * @summary Creates a user token.
         * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCheckPost(
            loginCheckPostRequest: LoginCheckPostRequest,
            options?: any
        ): AxiosPromise<LoginCheckPost200Response> {
            return localVarFp
                .loginCheckPost(loginCheckPostRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginCheckApi - object-oriented interface
 * @export
 * @class LoginCheckApi
 * @extends {BaseAPI}
 */
export class LoginCheckApi extends BaseAPI {
    /**
     * Creates a user token.
     * @summary Creates a user token.
     * @param {LoginCheckPostRequest} loginCheckPostRequest The login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginCheckApi
     */
    public loginCheckPost(loginCheckPostRequest: LoginCheckPostRequest, options?: AxiosRequestConfig) {
        return LoginCheckApiFp(this.configuration)
            .loginCheckPost(loginCheckPostRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MassenmailApi - axios parameter creator
 * @export
 */
export const MassenmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Massenmail resources.
         * @summary Retrieves the collection of Massenmail resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMassenmailGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/massenmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Massenmail resource.
         * @summary Retrieves a Massenmail resource.
         * @param {string} id Massenmail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMassenmailIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMassenmailIdGet', 'id', id);
            const localVarPath = `/api/massenmail/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Massenmail resource.
         * @summary Creates a Massenmail resource.
         * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMassenmailPost: async (
            massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'massenmailJsonldMassenmailWrite' is not null or undefined
            assertParamExists('apiMassenmailPost', 'massenmailJsonldMassenmailWrite', massenmailJsonldMassenmailWrite);
            const localVarPath = `/api/massenmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                massenmailJsonldMassenmailWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MassenmailApi - functional programming interface
 * @export
 */
export const MassenmailApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MassenmailApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Massenmail resources.
         * @summary Retrieves the collection of Massenmail resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMassenmailGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMassenmailGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMassenmailGetCollection(
                page,
                itemsPerPage,
                behoerde,
                behoerde2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Massenmail resource.
         * @summary Retrieves a Massenmail resource.
         * @param {string} id Massenmail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMassenmailIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MassenmailJsonldMassenmailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMassenmailIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Massenmail resource.
         * @summary Creates a Massenmail resource.
         * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMassenmailPost(
            massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MassenmailJsonldMassenmailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMassenmailPost(
                massenmailJsonldMassenmailWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MassenmailApi - factory interface
 * @export
 */
export const MassenmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MassenmailApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Massenmail resources.
         * @summary Retrieves the collection of Massenmail resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMassenmailGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: any
        ): AxiosPromise<ApiMassenmailGetCollection200Response> {
            return localVarFp
                .apiMassenmailGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Massenmail resource.
         * @summary Retrieves a Massenmail resource.
         * @param {string} id Massenmail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMassenmailIdGet(id: string, options?: any): AxiosPromise<MassenmailJsonldMassenmailRead> {
            return localVarFp.apiMassenmailIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Massenmail resource.
         * @summary Creates a Massenmail resource.
         * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMassenmailPost(
            massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
            options?: any
        ): AxiosPromise<MassenmailJsonldMassenmailRead> {
            return localVarFp
                .apiMassenmailPost(massenmailJsonldMassenmailWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MassenmailApi - object-oriented interface
 * @export
 * @class MassenmailApi
 * @extends {BaseAPI}
 */
export class MassenmailApi extends BaseAPI {
    /**
     * Retrieves the collection of Massenmail resources.
     * @summary Retrieves the collection of Massenmail resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MassenmailApi
     */
    public apiMassenmailGetCollection(
        page?: number,
        itemsPerPage?: number,
        behoerde?: string,
        behoerde2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return MassenmailApiFp(this.configuration)
            .apiMassenmailGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Massenmail resource.
     * @summary Retrieves a Massenmail resource.
     * @param {string} id Massenmail identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MassenmailApi
     */
    public apiMassenmailIdGet(id: string, options?: AxiosRequestConfig) {
        return MassenmailApiFp(this.configuration)
            .apiMassenmailIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Massenmail resource.
     * @summary Creates a Massenmail resource.
     * @param {MassenmailJsonldMassenmailWrite} massenmailJsonldMassenmailWrite The new Massenmail resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MassenmailApi
     */
    public apiMassenmailPost(
        massenmailJsonldMassenmailWrite: MassenmailJsonldMassenmailWrite,
        options?: AxiosRequestConfig
    ) {
        return MassenmailApiFp(this.configuration)
            .apiMassenmailPost(massenmailJsonldMassenmailWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MeldungApi - axios parameter creator
 * @export
 */
export const MeldungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Meldung resource.
         * @summary Removes the Meldung resource.
         * @param {string} id Meldung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungIdDelete', 'id', id);
            const localVarPath = `/api/meldung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {string} id Meldung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungIdGet', 'id', id);
            const localVarPath = `/api/meldung/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {string} id Meldung identifier
         * @param {number} [v1]
         * @param {number} [v2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIddiffGet: async (
            id: string,
            v1?: number,
            v2?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungIddiffGet', 'id', id);
            const localVarPath = `/api/meldung/{id}/diff`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (v1 !== undefined) {
                localVarQueryParameter['v1'] = v1;
            }

            if (v2 !== undefined) {
                localVarQueryParameter['v2'] = v2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdformGet: async (
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungIdformGet', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiMeldungIdformGet', 'step', step);
            const localVarPath = `/api/meldung/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Meldung resource.
         * @summary Updates the Meldung resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated Meldung resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdformPatch: async (
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungIdformPatch', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiMeldungIdformPatch', 'step', step);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiMeldungIdformPatch', 'body', body);
            const localVarPath = `/api/meldung/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Meldung resource.
         * @summary Updates the Meldung resource.
         * @param {string} id Meldung identifier
         * @param {MeldungAntragWorkflowActionAntragWorkflow} meldungAntragWorkflowActionAntragWorkflow The updated Meldung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdworkflowPatch: async (
            id: string,
            meldungAntragWorkflowActionAntragWorkflow: MeldungAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungIdworkflowPatch', 'id', id);
            // verify required parameter 'meldungAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiMeldungIdworkflowPatch',
                'meldungAntragWorkflowActionAntragWorkflow',
                meldungAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/meldung/{id}/workflow`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Meldung resource.
         * @summary Creates a Meldung resource.
         * @param {MeldungJsonldMeldungCreate} meldungJsonldMeldungCreate The new Meldung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungPost: async (
            meldungJsonldMeldungCreate: MeldungJsonldMeldungCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'meldungJsonldMeldungCreate' is not null or undefined
            assertParamExists('apiMeldungPost', 'meldungJsonldMeldungCreate', meldungJsonldMeldungCreate);
            const localVarPath = `/api/meldung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungJsonldMeldungCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Meldung resources.
         * @summary Retrieves the collection of Meldung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {boolean} [einrichtungNeedsUpdate]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {boolean} [existsEinrichtungDeleted]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionMeldung: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            einrichtungNeedsUpdate?: boolean,
            keinZustaendigerBenutzer?: boolean,
            existsEinrichtungDeleted?: boolean,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderEinrichtungAdresseOrt?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (eingereichtBy !== undefined) {
                localVarQueryParameter['eingereichtBy'] = eingereichtBy;
            }

            if (eingereichtBy2) {
                localVarQueryParameter['eingereichtBy[]'] = eingereichtBy2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = modifiedAt;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (einrichtungNeedsUpdate !== undefined) {
                localVarQueryParameter['einrichtung.needs_update'] = einrichtungNeedsUpdate;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (existsEinrichtungDeleted !== undefined) {
                localVarQueryParameter['exists[einrichtung.deleted]'] = existsEinrichtungDeleted;
            }

            if (orderZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[zuletztEingereichtAt]'] = orderZuletztEingereichtAt;
            }

            if (orderModifiedAt !== undefined) {
                localVarQueryParameter['order[modifiedAt]'] = orderModifiedAt;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderEinrichtungArt !== undefined) {
                localVarQueryParameter['order[einrichtung.art]'] = orderEinrichtungArt;
            }

            if (orderEinrichtungAdresseOrt !== undefined) {
                localVarQueryParameter['order[einrichtung.adresse.ort]'] = orderEinrichtungAdresseOrt;
            }

            if (orderEinrichtungNrwKey !== undefined) {
                localVarQueryParameter['order[einrichtung.nrwKey]'] = orderEinrichtungNrwKey;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Meldung resources.
         * @summary Retrieves the collection of Meldung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {boolean} [einrichtungNeedsUpdate]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {boolean} [existsEinrichtungDeleted]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeldungAufgabe: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            einrichtungNeedsUpdate?: boolean,
            keinZustaendigerBenutzer?: boolean,
            existsEinrichtungDeleted?: boolean,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderEinrichtungAdresseOrt?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung/aufgabe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (eingereichtBy !== undefined) {
                localVarQueryParameter['eingereichtBy'] = eingereichtBy;
            }

            if (eingereichtBy2) {
                localVarQueryParameter['eingereichtBy[]'] = eingereichtBy2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = modifiedAt;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (einrichtungNeedsUpdate !== undefined) {
                localVarQueryParameter['einrichtung.needs_update'] = einrichtungNeedsUpdate;
            }

            if (keinZustaendigerBenutzer !== undefined) {
                localVarQueryParameter['keinZustaendigerBenutzer'] = keinZustaendigerBenutzer;
            }

            if (existsEinrichtungDeleted !== undefined) {
                localVarQueryParameter['exists[einrichtung.deleted]'] = existsEinrichtungDeleted;
            }

            if (orderZuletztEingereichtAt !== undefined) {
                localVarQueryParameter['order[zuletztEingereichtAt]'] = orderZuletztEingereichtAt;
            }

            if (orderModifiedAt !== undefined) {
                localVarQueryParameter['order[modifiedAt]'] = orderModifiedAt;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderEinrichtungArt !== undefined) {
                localVarQueryParameter['order[einrichtung.art]'] = orderEinrichtungArt;
            }

            if (orderEinrichtungAdresseOrt !== undefined) {
                localVarQueryParameter['order[einrichtung.adresse.ort]'] = orderEinrichtungAdresseOrt;
            }

            if (orderEinrichtungNrwKey !== undefined) {
                localVarQueryParameter['order[einrichtung.nrwKey]'] = orderEinrichtungNrwKey;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the Meldung resource.
         * @summary Removes the Meldung resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteMeldung: async (
            id: number,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jsonFormDeleteMeldung', 'id', id);
            const localVarPath = `/api/meldung/{id}/form`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MeldungApi - functional programming interface
 * @export
 */
export const MeldungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MeldungApiAxiosParamCreator(configuration);
    return {
        /**
         * Removes the Meldung resource.
         * @summary Removes the Meldung resource.
         * @param {string} id Meldung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {string} id Meldung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungJsonldMeldungRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {string} id Meldung identifier
         * @param {number} [v1]
         * @param {number} [v2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungIddiffGet(
            id: string,
            v1?: number,
            v2?: number,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungFormDiffJsonldAntragFormDiff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungIddiffGet(id, v1, v2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungMeldungJsonJsonldAntragJsonForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungIdformGet(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Meldung resource.
         * @summary Updates the Meldung resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated Meldung resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungMeldungJsonJsonldAntragJsonForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungIdformPatch(
                id,
                step,
                body,
                objectType,
                uuid,
                persist,
                fields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Meldung resource.
         * @summary Updates the Meldung resource.
         * @param {string} id Meldung identifier
         * @param {MeldungAntragWorkflowActionAntragWorkflow} meldungAntragWorkflowActionAntragWorkflow The updated Meldung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungIdworkflowPatch(
            id: string,
            meldungAntragWorkflowActionAntragWorkflow: MeldungAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungIdworkflowPatch(
                id,
                meldungAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Meldung resource.
         * @summary Creates a Meldung resource.
         * @param {MeldungJsonldMeldungCreate} meldungJsonldMeldungCreate The new Meldung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungPost(
            meldungJsonldMeldungCreate: MeldungJsonldMeldungCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungPost(
                meldungJsonldMeldungCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Meldung resources.
         * @summary Retrieves the collection of Meldung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {boolean} [einrichtungNeedsUpdate]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {boolean} [existsEinrichtungDeleted]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionMeldung(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            einrichtungNeedsUpdate?: boolean,
            keinZustaendigerBenutzer?: boolean,
            existsEinrichtungDeleted?: boolean,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderEinrichtungAdresseOrt?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCollectionMeldung200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionMeldung(
                page,
                itemsPerPage,
                qSearch,
                eingereichtBy,
                eingereichtBy2,
                einrichtung,
                einrichtung2,
                modifiedAt,
                status,
                status2,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                existsEinrichtungDeleted,
                orderZuletztEingereichtAt,
                orderModifiedAt,
                orderEinrichtungName,
                orderEinrichtungArt,
                orderEinrichtungAdresseOrt,
                orderEinrichtungNrwKey,
                orderStatus,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Meldung resources.
         * @summary Retrieves the collection of Meldung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {boolean} [einrichtungNeedsUpdate]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {boolean} [existsEinrichtungDeleted]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeldungAufgabe(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            einrichtungNeedsUpdate?: boolean,
            keinZustaendigerBenutzer?: boolean,
            existsEinrichtungDeleted?: boolean,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderEinrichtungAdresseOrt?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMeldungAufgabe200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeldungAufgabe(
                page,
                itemsPerPage,
                qSearch,
                eingereichtBy,
                eingereichtBy2,
                einrichtung,
                einrichtung2,
                modifiedAt,
                status,
                status2,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                existsEinrichtungDeleted,
                orderZuletztEingereichtAt,
                orderModifiedAt,
                orderEinrichtungName,
                orderEinrichtungArt,
                orderEinrichtungAdresseOrt,
                orderEinrichtungNrwKey,
                orderStatus,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the Meldung resource.
         * @summary Removes the Meldung resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsonFormDeleteMeldung(
            id: number,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsonFormDeleteMeldung(id, uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MeldungApi - factory interface
 * @export
 */
export const MeldungApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeldungApiFp(configuration);
    return {
        /**
         * Removes the Meldung resource.
         * @summary Removes the Meldung resource.
         * @param {string} id Meldung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMeldungIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {string} id Meldung identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdGet(id: string, options?: any): AxiosPromise<MeldungJsonldMeldungRead> {
            return localVarFp.apiMeldungIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {string} id Meldung identifier
         * @param {number} [v1]
         * @param {number} [v2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIddiffGet(
            id: string,
            v1?: number,
            v2?: number,
            options?: any
        ): AxiosPromise<MeldungFormDiffJsonldAntragFormDiff> {
            return localVarFp.apiMeldungIddiffGet(id, v1, v2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Meldung resource.
         * @summary Retrieves a Meldung resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<MeldungMeldungJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiMeldungIdformGet(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Meldung resource.
         * @summary Updates the Meldung resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated Meldung resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: any
        ): AxiosPromise<MeldungMeldungJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiMeldungIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Meldung resource.
         * @summary Updates the Meldung resource.
         * @param {string} id Meldung identifier
         * @param {MeldungAntragWorkflowActionAntragWorkflow} meldungAntragWorkflowActionAntragWorkflow The updated Meldung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungIdworkflowPatch(
            id: string,
            meldungAntragWorkflowActionAntragWorkflow: MeldungAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<MeldungJsonld> {
            return localVarFp
                .apiMeldungIdworkflowPatch(id, meldungAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Meldung resource.
         * @summary Creates a Meldung resource.
         * @param {MeldungJsonldMeldungCreate} meldungJsonldMeldungCreate The new Meldung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungPost(
            meldungJsonldMeldungCreate: MeldungJsonldMeldungCreate,
            options?: any
        ): AxiosPromise<MeldungJsonld> {
            return localVarFp
                .apiMeldungPost(meldungJsonldMeldungCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Meldung resources.
         * @summary Retrieves the collection of Meldung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {boolean} [einrichtungNeedsUpdate]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {boolean} [existsEinrichtungDeleted]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionMeldung(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            einrichtungNeedsUpdate?: boolean,
            keinZustaendigerBenutzer?: boolean,
            existsEinrichtungDeleted?: boolean,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderEinrichtungAdresseOrt?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<GetCollectionMeldung200Response> {
            return localVarFp
                .getCollectionMeldung(
                    page,
                    itemsPerPage,
                    qSearch,
                    eingereichtBy,
                    eingereichtBy2,
                    einrichtung,
                    einrichtung2,
                    modifiedAt,
                    status,
                    status2,
                    einrichtungNeedsUpdate,
                    keinZustaendigerBenutzer,
                    existsEinrichtungDeleted,
                    orderZuletztEingereichtAt,
                    orderModifiedAt,
                    orderEinrichtungName,
                    orderEinrichtungArt,
                    orderEinrichtungAdresseOrt,
                    orderEinrichtungNrwKey,
                    orderStatus,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Meldung resources.
         * @summary Retrieves the collection of Meldung resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtBy]
         * @param {Array<string>} [eingereichtBy2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [modifiedAt]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {boolean} [einrichtungNeedsUpdate]
         * @param {boolean} [keinZustaendigerBenutzer]
         * @param {boolean} [existsEinrichtungDeleted]
         * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungArt]
         * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
         * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeldungAufgabe(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtBy?: string,
            eingereichtBy2?: Array<string>,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            modifiedAt?: string,
            status?: string,
            status2?: Array<string>,
            einrichtungNeedsUpdate?: boolean,
            keinZustaendigerBenutzer?: boolean,
            existsEinrichtungDeleted?: boolean,
            orderZuletztEingereichtAt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungArt?: 'asc' | 'desc',
            orderEinrichtungAdresseOrt?: 'asc' | 'desc',
            orderEinrichtungNrwKey?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<GetMeldungAufgabe200Response> {
            return localVarFp
                .getMeldungAufgabe(
                    page,
                    itemsPerPage,
                    qSearch,
                    eingereichtBy,
                    eingereichtBy2,
                    einrichtung,
                    einrichtung2,
                    modifiedAt,
                    status,
                    status2,
                    einrichtungNeedsUpdate,
                    keinZustaendigerBenutzer,
                    existsEinrichtungDeleted,
                    orderZuletztEingereichtAt,
                    orderModifiedAt,
                    orderEinrichtungName,
                    orderEinrichtungArt,
                    orderEinrichtungAdresseOrt,
                    orderEinrichtungNrwKey,
                    orderStatus,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the Meldung resource.
         * @summary Removes the Meldung resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsonFormDeleteMeldung(id: number, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.jsonFormDeleteMeldung(id, uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeldungApi - object-oriented interface
 * @export
 * @class MeldungApi
 * @extends {BaseAPI}
 */
export class MeldungApi extends BaseAPI {
    /**
     * Removes the Meldung resource.
     * @summary Removes the Meldung resource.
     * @param {string} id Meldung identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungIdDelete(id: string, options?: AxiosRequestConfig) {
        return MeldungApiFp(this.configuration)
            .apiMeldungIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Meldung resource.
     * @summary Retrieves a Meldung resource.
     * @param {string} id Meldung identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungIdGet(id: string, options?: AxiosRequestConfig) {
        return MeldungApiFp(this.configuration)
            .apiMeldungIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Meldung resource.
     * @summary Retrieves a Meldung resource.
     * @param {string} id Meldung identifier
     * @param {number} [v1]
     * @param {number} [v2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungIddiffGet(id: string, v1?: number, v2?: number, options?: AxiosRequestConfig) {
        return MeldungApiFp(this.configuration)
            .apiMeldungIddiffGet(id, v1, v2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Meldung resource.
     * @summary Retrieves a Meldung resource.
     * @param {number} id
     * @param {string} step
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungIdformGet(
        id: number,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return MeldungApiFp(this.configuration)
            .apiMeldungIdformGet(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Meldung resource.
     * @summary Updates the Meldung resource.
     * @param {number} id
     * @param {string} step
     * @param {object} body The updated Meldung resource
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungIdformPatch(
        id: number,
        step: string,
        body: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return MeldungApiFp(this.configuration)
            .apiMeldungIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Meldung resource.
     * @summary Updates the Meldung resource.
     * @param {string} id Meldung identifier
     * @param {MeldungAntragWorkflowActionAntragWorkflow} meldungAntragWorkflowActionAntragWorkflow The updated Meldung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungIdworkflowPatch(
        id: string,
        meldungAntragWorkflowActionAntragWorkflow: MeldungAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return MeldungApiFp(this.configuration)
            .apiMeldungIdworkflowPatch(id, meldungAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Meldung resource.
     * @summary Creates a Meldung resource.
     * @param {MeldungJsonldMeldungCreate} meldungJsonldMeldungCreate The new Meldung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public apiMeldungPost(meldungJsonldMeldungCreate: MeldungJsonldMeldungCreate, options?: AxiosRequestConfig) {
        return MeldungApiFp(this.configuration)
            .apiMeldungPost(meldungJsonldMeldungCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Meldung resources.
     * @summary Retrieves the collection of Meldung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [eingereichtBy]
     * @param {Array<string>} [eingereichtBy2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [modifiedAt]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {boolean} [einrichtungNeedsUpdate]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {boolean} [existsEinrichtungDeleted]
     * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderModifiedAt]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderEinrichtungArt]
     * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
     * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public getCollectionMeldung(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        eingereichtBy?: string,
        eingereichtBy2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        modifiedAt?: string,
        status?: string,
        status2?: Array<string>,
        einrichtungNeedsUpdate?: boolean,
        keinZustaendigerBenutzer?: boolean,
        existsEinrichtungDeleted?: boolean,
        orderZuletztEingereichtAt?: 'asc' | 'desc',
        orderModifiedAt?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderEinrichtungArt?: 'asc' | 'desc',
        orderEinrichtungAdresseOrt?: 'asc' | 'desc',
        orderEinrichtungNrwKey?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return MeldungApiFp(this.configuration)
            .getCollectionMeldung(
                page,
                itemsPerPage,
                qSearch,
                eingereichtBy,
                eingereichtBy2,
                einrichtung,
                einrichtung2,
                modifiedAt,
                status,
                status2,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                existsEinrichtungDeleted,
                orderZuletztEingereichtAt,
                orderModifiedAt,
                orderEinrichtungName,
                orderEinrichtungArt,
                orderEinrichtungAdresseOrt,
                orderEinrichtungNrwKey,
                orderStatus,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Meldung resources.
     * @summary Retrieves the collection of Meldung resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [eingereichtBy]
     * @param {Array<string>} [eingereichtBy2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [modifiedAt]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {boolean} [einrichtungNeedsUpdate]
     * @param {boolean} [keinZustaendigerBenutzer]
     * @param {boolean} [existsEinrichtungDeleted]
     * @param {'asc' | 'desc'} [orderZuletztEingereichtAt]
     * @param {'asc' | 'desc'} [orderModifiedAt]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderEinrichtungArt]
     * @param {'asc' | 'desc'} [orderEinrichtungAdresseOrt]
     * @param {'asc' | 'desc'} [orderEinrichtungNrwKey]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public getMeldungAufgabe(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        eingereichtBy?: string,
        eingereichtBy2?: Array<string>,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        modifiedAt?: string,
        status?: string,
        status2?: Array<string>,
        einrichtungNeedsUpdate?: boolean,
        keinZustaendigerBenutzer?: boolean,
        existsEinrichtungDeleted?: boolean,
        orderZuletztEingereichtAt?: 'asc' | 'desc',
        orderModifiedAt?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderEinrichtungArt?: 'asc' | 'desc',
        orderEinrichtungAdresseOrt?: 'asc' | 'desc',
        orderEinrichtungNrwKey?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return MeldungApiFp(this.configuration)
            .getMeldungAufgabe(
                page,
                itemsPerPage,
                qSearch,
                eingereichtBy,
                eingereichtBy2,
                einrichtung,
                einrichtung2,
                modifiedAt,
                status,
                status2,
                einrichtungNeedsUpdate,
                keinZustaendigerBenutzer,
                existsEinrichtungDeleted,
                orderZuletztEingereichtAt,
                orderModifiedAt,
                orderEinrichtungName,
                orderEinrichtungArt,
                orderEinrichtungAdresseOrt,
                orderEinrichtungNrwKey,
                orderStatus,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the Meldung resource.
     * @summary Removes the Meldung resource.
     * @param {number} id
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungApi
     */
    public jsonFormDeleteMeldung(id: number, uuid?: string, options?: AxiosRequestConfig) {
        return MeldungApiFp(this.configuration)
            .jsonFormDeleteMeldung(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MeldungFreigabeDokumentApi - axios parameter creator
 * @export
 */
export const MeldungFreigabeDokumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of MeldungFreigabeDokument resources.
         * @summary Retrieves the collection of MeldungFreigabeDokument resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung-freigabe-dokument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungFreigabeDokument resource.
         * @summary Retrieves a MeldungFreigabeDokument resource.
         * @param {string} id MeldungFreigabeDokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungFreigabeDokumentIdGet', 'id', id);
            const localVarPath = `/api/meldung-freigabe-dokument/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the MeldungFreigabeDokument resource.
         * @summary Updates the MeldungFreigabeDokument resource.
         * @param {string} id MeldungFreigabeDokument identifier
         * @param {MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow} meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow The updated MeldungFreigabeDokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentIdworkflowPatch: async (
            id: string,
            meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow: MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungFreigabeDokumentIdworkflowPatch', 'id', id);
            // verify required parameter 'meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiMeldungFreigabeDokumentIdworkflowPatch',
                'meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow',
                meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/meldung-freigabe-dokument/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a MeldungFreigabeDokument resource.
         * @summary Creates a MeldungFreigabeDokument resource.
         * @param {MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite} meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite The new MeldungFreigabeDokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentPost: async (
            meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite: MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite' is not null or undefined
            assertParamExists(
                'apiMeldungFreigabeDokumentPost',
                'meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite',
                meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite
            );
            const localVarPath = `/api/meldung-freigabe-dokument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MeldungFreigabeDokumentApi - functional programming interface
 * @export
 */
export const MeldungFreigabeDokumentApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MeldungFreigabeDokumentApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of MeldungFreigabeDokument resources.
         * @summary Retrieves the collection of MeldungFreigabeDokument resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungFreigabeDokumentGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiMeldungFreigabeDokumentGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungFreigabeDokumentGetCollection(
                page,
                itemsPerPage,
                behoerde,
                behoerde2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungFreigabeDokument resource.
         * @summary Retrieves a MeldungFreigabeDokument resource.
         * @param {string} id MeldungFreigabeDokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungFreigabeDokumentIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungFreigabeDokumentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the MeldungFreigabeDokument resource.
         * @summary Updates the MeldungFreigabeDokument resource.
         * @param {string} id MeldungFreigabeDokument identifier
         * @param {MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow} meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow The updated MeldungFreigabeDokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungFreigabeDokumentIdworkflowPatch(
            id: string,
            meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow: MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungFreigabeDokumentIdworkflowPatch(
                id,
                meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a MeldungFreigabeDokument resource.
         * @summary Creates a MeldungFreigabeDokument resource.
         * @param {MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite} meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite The new MeldungFreigabeDokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungFreigabeDokumentPost(
            meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite: MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungFreigabeDokumentPost(
                meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MeldungFreigabeDokumentApi - factory interface
 * @export
 */
export const MeldungFreigabeDokumentApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = MeldungFreigabeDokumentApiFp(configuration);
    return {
        /**
         * Retrieves the collection of MeldungFreigabeDokument resources.
         * @summary Retrieves the collection of MeldungFreigabeDokument resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: any
        ): AxiosPromise<ApiMeldungFreigabeDokumentGetCollection200Response> {
            return localVarFp
                .apiMeldungFreigabeDokumentGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungFreigabeDokument resource.
         * @summary Retrieves a MeldungFreigabeDokument resource.
         * @param {string} id MeldungFreigabeDokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentIdGet(
            id: string,
            options?: any
        ): AxiosPromise<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead> {
            return localVarFp.apiMeldungFreigabeDokumentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the MeldungFreigabeDokument resource.
         * @summary Updates the MeldungFreigabeDokument resource.
         * @param {string} id MeldungFreigabeDokument identifier
         * @param {MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow} meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow The updated MeldungFreigabeDokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentIdworkflowPatch(
            id: string,
            meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow: MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteRead> {
            return localVarFp
                .apiMeldungFreigabeDokumentIdworkflowPatch(
                    id,
                    meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a MeldungFreigabeDokument resource.
         * @summary Creates a MeldungFreigabeDokument resource.
         * @param {MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite} meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite The new MeldungFreigabeDokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungFreigabeDokumentPost(
            meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite: MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .apiMeldungFreigabeDokumentPost(meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeldungFreigabeDokumentApi - object-oriented interface
 * @export
 * @class MeldungFreigabeDokumentApi
 * @extends {BaseAPI}
 */
export class MeldungFreigabeDokumentApi extends BaseAPI {
    /**
     * Retrieves the collection of MeldungFreigabeDokument resources.
     * @summary Retrieves the collection of MeldungFreigabeDokument resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungFreigabeDokumentApi
     */
    public apiMeldungFreigabeDokumentGetCollection(
        page?: number,
        itemsPerPage?: number,
        behoerde?: string,
        behoerde2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return MeldungFreigabeDokumentApiFp(this.configuration)
            .apiMeldungFreigabeDokumentGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungFreigabeDokument resource.
     * @summary Retrieves a MeldungFreigabeDokument resource.
     * @param {string} id MeldungFreigabeDokument identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungFreigabeDokumentApi
     */
    public apiMeldungFreigabeDokumentIdGet(id: string, options?: AxiosRequestConfig) {
        return MeldungFreigabeDokumentApiFp(this.configuration)
            .apiMeldungFreigabeDokumentIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the MeldungFreigabeDokument resource.
     * @summary Updates the MeldungFreigabeDokument resource.
     * @param {string} id MeldungFreigabeDokument identifier
     * @param {MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow} meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow The updated MeldungFreigabeDokument resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungFreigabeDokumentApi
     */
    public apiMeldungFreigabeDokumentIdworkflowPatch(
        id: string,
        meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow: MeldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return MeldungFreigabeDokumentApiFp(this.configuration)
            .apiMeldungFreigabeDokumentIdworkflowPatch(
                id,
                meldungFreigabeDokumentAntragWorkflowActionAntragWorkflow,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a MeldungFreigabeDokument resource.
     * @summary Creates a MeldungFreigabeDokument resource.
     * @param {MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite} meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite The new MeldungFreigabeDokument resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungFreigabeDokumentApi
     */
    public apiMeldungFreigabeDokumentPost(
        meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite: MeldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite,
        options?: AxiosRequestConfig
    ) {
        return MeldungFreigabeDokumentApiFp(this.configuration)
            .apiMeldungFreigabeDokumentPost(meldungFreigabeDokumentJsonldMeldungFreigabeDokumenteWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MeldungHinweisdokumentApi - axios parameter creator
 * @export
 */
export const MeldungHinweisdokumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of MeldungHinweisdokument resources.
         * @summary Retrieves the collection of MeldungHinweisdokument resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung-hinweisdokument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the MeldungHinweisdokument resource.
         * @summary Removes the MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentIdDelete: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweisdokumentIdDelete', 'id', id);
            const localVarPath = `/api/meldung-hinweisdokument/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungHinweisdokument resource.
         * @summary Retrieves a MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweisdokumentIdGet', 'id', id);
            const localVarPath = `/api/meldung-hinweisdokument/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the MeldungHinweisdokument resource.
         * @summary Updates the MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow} meldungHinweisdokumentAntragWorkflowActionAntragWorkflow The updated MeldungHinweisdokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentIdworkflowPatch: async (
            id: string,
            meldungHinweisdokumentAntragWorkflowActionAntragWorkflow: MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweisdokumentIdworkflowPatch', 'id', id);
            // verify required parameter 'meldungHinweisdokumentAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiMeldungHinweisdokumentIdworkflowPatch',
                'meldungHinweisdokumentAntragWorkflowActionAntragWorkflow',
                meldungHinweisdokumentAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/meldung-hinweisdokument/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a MeldungHinweisdokument resource.
         * @summary Creates a MeldungHinweisdokument resource.
         * @param {MeldungHinweisdokumentJsonldMeldungHinweistextWrite} meldungHinweisdokumentJsonldMeldungHinweistextWrite The new MeldungHinweisdokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentPost: async (
            meldungHinweisdokumentJsonldMeldungHinweistextWrite: MeldungHinweisdokumentJsonldMeldungHinweistextWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'meldungHinweisdokumentJsonldMeldungHinweistextWrite' is not null or undefined
            assertParamExists(
                'apiMeldungHinweisdokumentPost',
                'meldungHinweisdokumentJsonldMeldungHinweistextWrite',
                meldungHinweisdokumentJsonldMeldungHinweistextWrite
            );
            const localVarPath = `/api/meldung-hinweisdokument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungHinweisdokumentJsonldMeldungHinweistextWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MeldungHinweisdokumentApi - functional programming interface
 * @export
 */
export const MeldungHinweisdokumentApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MeldungHinweisdokumentApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of MeldungHinweisdokument resources.
         * @summary Retrieves the collection of MeldungHinweisdokument resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweisdokumentGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiMeldungHinweisdokumentGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweisdokumentGetCollection(
                page,
                itemsPerPage,
                behoerde,
                behoerde2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the MeldungHinweisdokument resource.
         * @summary Removes the MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweisdokumentIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweisdokumentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungHinweisdokument resource.
         * @summary Retrieves a MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweisdokumentIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MeldungHinweisdokumentJsonldMeldungHinweistextRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweisdokumentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the MeldungHinweisdokument resource.
         * @summary Updates the MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow} meldungHinweisdokumentAntragWorkflowActionAntragWorkflow The updated MeldungHinweisdokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweisdokumentIdworkflowPatch(
            id: string,
            meldungHinweisdokumentAntragWorkflowActionAntragWorkflow: MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<MeldungHinweisdokumentJsonldMeldungHinweistextRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweisdokumentIdworkflowPatch(
                id,
                meldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a MeldungHinweisdokument resource.
         * @summary Creates a MeldungHinweisdokument resource.
         * @param {MeldungHinweisdokumentJsonldMeldungHinweistextWrite} meldungHinweisdokumentJsonldMeldungHinweistextWrite The new MeldungHinweisdokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweisdokumentPost(
            meldungHinweisdokumentJsonldMeldungHinweistextWrite: MeldungHinweisdokumentJsonldMeldungHinweistextWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweisdokumentPost(
                meldungHinweisdokumentJsonldMeldungHinweistextWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MeldungHinweisdokumentApi - factory interface
 * @export
 */
export const MeldungHinweisdokumentApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = MeldungHinweisdokumentApiFp(configuration);
    return {
        /**
         * Retrieves the collection of MeldungHinweisdokument resources.
         * @summary Retrieves the collection of MeldungHinweisdokument resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: any
        ): AxiosPromise<ApiMeldungHinweisdokumentGetCollection200Response> {
            return localVarFp
                .apiMeldungHinweisdokumentGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the MeldungHinweisdokument resource.
         * @summary Removes the MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .apiMeldungHinweisdokumentIdDelete(id, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungHinweisdokument resource.
         * @summary Retrieves a MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentIdGet(
            id: string,
            options?: any
        ): AxiosPromise<MeldungHinweisdokumentJsonldMeldungHinweistextRead> {
            return localVarFp.apiMeldungHinweisdokumentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the MeldungHinweisdokument resource.
         * @summary Updates the MeldungHinweisdokument resource.
         * @param {string} id MeldungHinweisdokument identifier
         * @param {MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow} meldungHinweisdokumentAntragWorkflowActionAntragWorkflow The updated MeldungHinweisdokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentIdworkflowPatch(
            id: string,
            meldungHinweisdokumentAntragWorkflowActionAntragWorkflow: MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<MeldungHinweisdokumentJsonldMeldungHinweistextRead> {
            return localVarFp
                .apiMeldungHinweisdokumentIdworkflowPatch(
                    id,
                    meldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a MeldungHinweisdokument resource.
         * @summary Creates a MeldungHinweisdokument resource.
         * @param {MeldungHinweisdokumentJsonldMeldungHinweistextWrite} meldungHinweisdokumentJsonldMeldungHinweistextWrite The new MeldungHinweisdokument resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweisdokumentPost(
            meldungHinweisdokumentJsonldMeldungHinweistextWrite: MeldungHinweisdokumentJsonldMeldungHinweistextWrite,
            options?: any
        ): AxiosPromise<any> {
            return localVarFp
                .apiMeldungHinweisdokumentPost(meldungHinweisdokumentJsonldMeldungHinweistextWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeldungHinweisdokumentApi - object-oriented interface
 * @export
 * @class MeldungHinweisdokumentApi
 * @extends {BaseAPI}
 */
export class MeldungHinweisdokumentApi extends BaseAPI {
    /**
     * Retrieves the collection of MeldungHinweisdokument resources.
     * @summary Retrieves the collection of MeldungHinweisdokument resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweisdokumentApi
     */
    public apiMeldungHinweisdokumentGetCollection(
        page?: number,
        itemsPerPage?: number,
        behoerde?: string,
        behoerde2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweisdokumentApiFp(this.configuration)
            .apiMeldungHinweisdokumentGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the MeldungHinweisdokument resource.
     * @summary Removes the MeldungHinweisdokument resource.
     * @param {string} id MeldungHinweisdokument identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweisdokumentApi
     */
    public apiMeldungHinweisdokumentIdDelete(id: string, options?: AxiosRequestConfig) {
        return MeldungHinweisdokumentApiFp(this.configuration)
            .apiMeldungHinweisdokumentIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungHinweisdokument resource.
     * @summary Retrieves a MeldungHinweisdokument resource.
     * @param {string} id MeldungHinweisdokument identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweisdokumentApi
     */
    public apiMeldungHinweisdokumentIdGet(id: string, options?: AxiosRequestConfig) {
        return MeldungHinweisdokumentApiFp(this.configuration)
            .apiMeldungHinweisdokumentIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the MeldungHinweisdokument resource.
     * @summary Updates the MeldungHinweisdokument resource.
     * @param {string} id MeldungHinweisdokument identifier
     * @param {MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow} meldungHinweisdokumentAntragWorkflowActionAntragWorkflow The updated MeldungHinweisdokument resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweisdokumentApi
     */
    public apiMeldungHinweisdokumentIdworkflowPatch(
        id: string,
        meldungHinweisdokumentAntragWorkflowActionAntragWorkflow: MeldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweisdokumentApiFp(this.configuration)
            .apiMeldungHinweisdokumentIdworkflowPatch(
                id,
                meldungHinweisdokumentAntragWorkflowActionAntragWorkflow,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a MeldungHinweisdokument resource.
     * @summary Creates a MeldungHinweisdokument resource.
     * @param {MeldungHinweisdokumentJsonldMeldungHinweistextWrite} meldungHinweisdokumentJsonldMeldungHinweistextWrite The new MeldungHinweisdokument resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweisdokumentApi
     */
    public apiMeldungHinweisdokumentPost(
        meldungHinweisdokumentJsonldMeldungHinweistextWrite: MeldungHinweisdokumentJsonldMeldungHinweistextWrite,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweisdokumentApiFp(this.configuration)
            .apiMeldungHinweisdokumentPost(meldungHinweisdokumentJsonldMeldungHinweistextWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MeldungHinweistextApi - axios parameter creator
 * @export
 */
export const MeldungHinweistextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of MeldungHinweistext resources.
         * @summary Retrieves the collection of MeldungHinweistext resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung-hinweistext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the MeldungHinweistext resource.
         * @summary Removes the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweistextIdDelete', 'id', id);
            const localVarPath = `/api/meldung-hinweistext/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungHinweistext resource.
         * @summary Retrieves a MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweistextIdGet', 'id', id);
            const localVarPath = `/api/meldung-hinweistext/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the MeldungHinweistext resource.
         * @summary Updates the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {MeldungHinweistextMeldungHinweistextWrite} meldungHinweistextMeldungHinweistextWrite The updated MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdPatch: async (
            id: string,
            meldungHinweistextMeldungHinweistextWrite: MeldungHinweistextMeldungHinweistextWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweistextIdPatch', 'id', id);
            // verify required parameter 'meldungHinweistextMeldungHinweistextWrite' is not null or undefined
            assertParamExists(
                'apiMeldungHinweistextIdPatch',
                'meldungHinweistextMeldungHinweistextWrite',
                meldungHinweistextMeldungHinweistextWrite
            );
            const localVarPath = `/api/meldung-hinweistext/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungHinweistextMeldungHinweistextWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the MeldungHinweistext resource.
         * @summary Updates the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {MeldungHinweistextAntragWorkflowActionAntragWorkflow} meldungHinweistextAntragWorkflowActionAntragWorkflow The updated MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdworkflowPatch: async (
            id: string,
            meldungHinweistextAntragWorkflowActionAntragWorkflow: MeldungHinweistextAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungHinweistextIdworkflowPatch', 'id', id);
            // verify required parameter 'meldungHinweistextAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiMeldungHinweistextIdworkflowPatch',
                'meldungHinweistextAntragWorkflowActionAntragWorkflow',
                meldungHinweistextAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/meldung-hinweistext/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungHinweistextAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a MeldungHinweistext resource.
         * @summary Creates a MeldungHinweistext resource.
         * @param {MeldungHinweistextJsonldMeldungHinweistextWrite} meldungHinweistextJsonldMeldungHinweistextWrite The new MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextPost: async (
            meldungHinweistextJsonldMeldungHinweistextWrite: MeldungHinweistextJsonldMeldungHinweistextWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'meldungHinweistextJsonldMeldungHinweistextWrite' is not null or undefined
            assertParamExists(
                'apiMeldungHinweistextPost',
                'meldungHinweistextJsonldMeldungHinweistextWrite',
                meldungHinweistextJsonldMeldungHinweistextWrite
            );
            const localVarPath = `/api/meldung-hinweistext`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungHinweistextJsonldMeldungHinweistextWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MeldungHinweistextApi - functional programming interface
 * @export
 */
export const MeldungHinweistextApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MeldungHinweistextApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of MeldungHinweistext resources.
         * @summary Retrieves the collection of MeldungHinweistext resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweistextGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMeldungHinweistextGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweistextGetCollection(
                page,
                itemsPerPage,
                behoerde,
                behoerde2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the MeldungHinweistext resource.
         * @summary Removes the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweistextIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweistextIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungHinweistext resource.
         * @summary Retrieves a MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweistextIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweistextIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the MeldungHinweistext resource.
         * @summary Updates the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {MeldungHinweistextMeldungHinweistextWrite} meldungHinweistextMeldungHinweistextWrite The updated MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweistextIdPatch(
            id: string,
            meldungHinweistextMeldungHinweistextWrite: MeldungHinweistextMeldungHinweistextWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweistextIdPatch(
                id,
                meldungHinweistextMeldungHinweistextWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the MeldungHinweistext resource.
         * @summary Updates the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {MeldungHinweistextAntragWorkflowActionAntragWorkflow} meldungHinweistextAntragWorkflowActionAntragWorkflow The updated MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweistextIdworkflowPatch(
            id: string,
            meldungHinweistextAntragWorkflowActionAntragWorkflow: MeldungHinweistextAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweistextIdworkflowPatch(
                id,
                meldungHinweistextAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a MeldungHinweistext resource.
         * @summary Creates a MeldungHinweistext resource.
         * @param {MeldungHinweistextJsonldMeldungHinweistextWrite} meldungHinweistextJsonldMeldungHinweistextWrite The new MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungHinweistextPost(
            meldungHinweistextJsonldMeldungHinweistextWrite: MeldungHinweistextJsonldMeldungHinweistextWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungHinweistextPost(
                meldungHinweistextJsonldMeldungHinweistextWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MeldungHinweistextApi - factory interface
 * @export
 */
export const MeldungHinweistextApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = MeldungHinweistextApiFp(configuration);
    return {
        /**
         * Retrieves the collection of MeldungHinweistext resources.
         * @summary Retrieves the collection of MeldungHinweistext resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextGetCollection(
            page?: number,
            itemsPerPage?: number,
            behoerde?: string,
            behoerde2?: Array<string>,
            options?: any
        ): AxiosPromise<ApiMeldungHinweistextGetCollection200Response> {
            return localVarFp
                .apiMeldungHinweistextGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the MeldungHinweistext resource.
         * @summary Removes the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMeldungHinweistextIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungHinweistext resource.
         * @summary Retrieves a MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdGet(
            id: string,
            options?: any
        ): AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead> {
            return localVarFp.apiMeldungHinweistextIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the MeldungHinweistext resource.
         * @summary Updates the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {MeldungHinweistextMeldungHinweistextWrite} meldungHinweistextMeldungHinweistextWrite The updated MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdPatch(
            id: string,
            meldungHinweistextMeldungHinweistextWrite: MeldungHinweistextMeldungHinweistextWrite,
            options?: any
        ): AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead> {
            return localVarFp
                .apiMeldungHinweistextIdPatch(id, meldungHinweistextMeldungHinweistextWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the MeldungHinweistext resource.
         * @summary Updates the MeldungHinweistext resource.
         * @param {string} id MeldungHinweistext identifier
         * @param {MeldungHinweistextAntragWorkflowActionAntragWorkflow} meldungHinweistextAntragWorkflowActionAntragWorkflow The updated MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextIdworkflowPatch(
            id: string,
            meldungHinweistextAntragWorkflowActionAntragWorkflow: MeldungHinweistextAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead> {
            return localVarFp
                .apiMeldungHinweistextIdworkflowPatch(id, meldungHinweistextAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a MeldungHinweistext resource.
         * @summary Creates a MeldungHinweistext resource.
         * @param {MeldungHinweistextJsonldMeldungHinweistextWrite} meldungHinweistextJsonldMeldungHinweistextWrite The new MeldungHinweistext resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungHinweistextPost(
            meldungHinweistextJsonldMeldungHinweistextWrite: MeldungHinweistextJsonldMeldungHinweistextWrite,
            options?: any
        ): AxiosPromise<MeldungHinweistextJsonldMeldungHinweistextRead> {
            return localVarFp
                .apiMeldungHinweistextPost(meldungHinweistextJsonldMeldungHinweistextWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeldungHinweistextApi - object-oriented interface
 * @export
 * @class MeldungHinweistextApi
 * @extends {BaseAPI}
 */
export class MeldungHinweistextApi extends BaseAPI {
    /**
     * Retrieves the collection of MeldungHinweistext resources.
     * @summary Retrieves the collection of MeldungHinweistext resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweistextApi
     */
    public apiMeldungHinweistextGetCollection(
        page?: number,
        itemsPerPage?: number,
        behoerde?: string,
        behoerde2?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweistextApiFp(this.configuration)
            .apiMeldungHinweistextGetCollection(page, itemsPerPage, behoerde, behoerde2, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the MeldungHinweistext resource.
     * @summary Removes the MeldungHinweistext resource.
     * @param {string} id MeldungHinweistext identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweistextApi
     */
    public apiMeldungHinweistextIdDelete(id: string, options?: AxiosRequestConfig) {
        return MeldungHinweistextApiFp(this.configuration)
            .apiMeldungHinweistextIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungHinweistext resource.
     * @summary Retrieves a MeldungHinweistext resource.
     * @param {string} id MeldungHinweistext identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweistextApi
     */
    public apiMeldungHinweistextIdGet(id: string, options?: AxiosRequestConfig) {
        return MeldungHinweistextApiFp(this.configuration)
            .apiMeldungHinweistextIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the MeldungHinweistext resource.
     * @summary Updates the MeldungHinweistext resource.
     * @param {string} id MeldungHinweistext identifier
     * @param {MeldungHinweistextMeldungHinweistextWrite} meldungHinweistextMeldungHinweistextWrite The updated MeldungHinweistext resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweistextApi
     */
    public apiMeldungHinweistextIdPatch(
        id: string,
        meldungHinweistextMeldungHinweistextWrite: MeldungHinweistextMeldungHinweistextWrite,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweistextApiFp(this.configuration)
            .apiMeldungHinweistextIdPatch(id, meldungHinweistextMeldungHinweistextWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the MeldungHinweistext resource.
     * @summary Updates the MeldungHinweistext resource.
     * @param {string} id MeldungHinweistext identifier
     * @param {MeldungHinweistextAntragWorkflowActionAntragWorkflow} meldungHinweistextAntragWorkflowActionAntragWorkflow The updated MeldungHinweistext resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweistextApi
     */
    public apiMeldungHinweistextIdworkflowPatch(
        id: string,
        meldungHinweistextAntragWorkflowActionAntragWorkflow: MeldungHinweistextAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweistextApiFp(this.configuration)
            .apiMeldungHinweistextIdworkflowPatch(id, meldungHinweistextAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a MeldungHinweistext resource.
     * @summary Creates a MeldungHinweistext resource.
     * @param {MeldungHinweistextJsonldMeldungHinweistextWrite} meldungHinweistextJsonldMeldungHinweistextWrite The new MeldungHinweistext resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungHinweistextApi
     */
    public apiMeldungHinweistextPost(
        meldungHinweistextJsonldMeldungHinweistextWrite: MeldungHinweistextJsonldMeldungHinweistextWrite,
        options?: AxiosRequestConfig
    ) {
        return MeldungHinweistextApiFp(this.configuration)
            .apiMeldungHinweistextPost(meldungHinweistextJsonldMeldungHinweistextWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MeldungVersionApi - axios parameter creator
 * @export
 */
export const MeldungVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of MeldungVersion resources.
         * @summary Retrieves the collection of MeldungVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/meldung-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the MeldungVersion resource.
         * @summary Removes the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungVersionIdDelete', 'id', id);
            const localVarPath = `/api/meldung-version/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a MeldungVersion resource.
         * @summary Retrieves a MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungVersionIdGet', 'id', id);
            const localVarPath = `/api/meldung-version/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the MeldungVersion resource.
         * @summary Updates the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {MeldungVersion} meldungVersion The updated MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdPatch: async (
            id: string,
            meldungVersion: MeldungVersion,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungVersionIdPatch', 'id', id);
            // verify required parameter 'meldungVersion' is not null or undefined
            assertParamExists('apiMeldungVersionIdPatch', 'meldungVersion', meldungVersion);
            const localVarPath = `/api/meldung-version/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(meldungVersion, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the MeldungVersion resource.
         * @summary Replaces the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {MeldungVersionJsonld} meldungVersionJsonld The updated MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdPut: async (
            id: string,
            meldungVersionJsonld: MeldungVersionJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMeldungVersionIdPut', 'id', id);
            // verify required parameter 'meldungVersionJsonld' is not null or undefined
            assertParamExists('apiMeldungVersionIdPut', 'meldungVersionJsonld', meldungVersionJsonld);
            const localVarPath = `/api/meldung-version/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungVersionJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a MeldungVersion resource.
         * @summary Creates a MeldungVersion resource.
         * @param {MeldungVersionJsonld} meldungVersionJsonld The new MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionPost: async (
            meldungVersionJsonld: MeldungVersionJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'meldungVersionJsonld' is not null or undefined
            assertParamExists('apiMeldungVersionPost', 'meldungVersionJsonld', meldungVersionJsonld);
            const localVarPath = `/api/meldung-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                meldungVersionJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MeldungVersionApi - functional programming interface
 * @export
 */
export const MeldungVersionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MeldungVersionApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of MeldungVersion resources.
         * @summary Retrieves the collection of MeldungVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungVersionGetCollection(
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMeldungVersionGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungVersionGetCollection(
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the MeldungVersion resource.
         * @summary Removes the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungVersionIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungVersionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a MeldungVersion resource.
         * @summary Retrieves a MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungVersionIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungVersionJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungVersionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the MeldungVersion resource.
         * @summary Updates the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {MeldungVersion} meldungVersion The updated MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungVersionIdPatch(
            id: string,
            meldungVersion: MeldungVersion,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungVersionJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungVersionIdPatch(
                id,
                meldungVersion,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the MeldungVersion resource.
         * @summary Replaces the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {MeldungVersionJsonld} meldungVersionJsonld The updated MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungVersionIdPut(
            id: string,
            meldungVersionJsonld: MeldungVersionJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungVersionJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungVersionIdPut(
                id,
                meldungVersionJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a MeldungVersion resource.
         * @summary Creates a MeldungVersion resource.
         * @param {MeldungVersionJsonld} meldungVersionJsonld The new MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeldungVersionPost(
            meldungVersionJsonld: MeldungVersionJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeldungVersionJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeldungVersionPost(
                meldungVersionJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MeldungVersionApi - factory interface
 * @export
 */
export const MeldungVersionApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = MeldungVersionApiFp(configuration);
    return {
        /**
         * Retrieves the collection of MeldungVersion resources.
         * @summary Retrieves the collection of MeldungVersion resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionGetCollection(
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<ApiMeldungVersionGetCollection200Response> {
            return localVarFp
                .apiMeldungVersionGetCollection(page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the MeldungVersion resource.
         * @summary Removes the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMeldungVersionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a MeldungVersion resource.
         * @summary Retrieves a MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdGet(id: string, options?: any): AxiosPromise<MeldungVersionJsonld> {
            return localVarFp.apiMeldungVersionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the MeldungVersion resource.
         * @summary Updates the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {MeldungVersion} meldungVersion The updated MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdPatch(
            id: string,
            meldungVersion: MeldungVersion,
            options?: any
        ): AxiosPromise<MeldungVersionJsonld> {
            return localVarFp
                .apiMeldungVersionIdPatch(id, meldungVersion, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces the MeldungVersion resource.
         * @summary Replaces the MeldungVersion resource.
         * @param {string} id MeldungVersion identifier
         * @param {MeldungVersionJsonld} meldungVersionJsonld The updated MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionIdPut(
            id: string,
            meldungVersionJsonld: MeldungVersionJsonld,
            options?: any
        ): AxiosPromise<MeldungVersionJsonld> {
            return localVarFp
                .apiMeldungVersionIdPut(id, meldungVersionJsonld, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a MeldungVersion resource.
         * @summary Creates a MeldungVersion resource.
         * @param {MeldungVersionJsonld} meldungVersionJsonld The new MeldungVersion resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeldungVersionPost(
            meldungVersionJsonld: MeldungVersionJsonld,
            options?: any
        ): AxiosPromise<MeldungVersionJsonld> {
            return localVarFp
                .apiMeldungVersionPost(meldungVersionJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeldungVersionApi - object-oriented interface
 * @export
 * @class MeldungVersionApi
 * @extends {BaseAPI}
 */
export class MeldungVersionApi extends BaseAPI {
    /**
     * Retrieves the collection of MeldungVersion resources.
     * @summary Retrieves the collection of MeldungVersion resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungVersionApi
     */
    public apiMeldungVersionGetCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return MeldungVersionApiFp(this.configuration)
            .apiMeldungVersionGetCollection(page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the MeldungVersion resource.
     * @summary Removes the MeldungVersion resource.
     * @param {string} id MeldungVersion identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungVersionApi
     */
    public apiMeldungVersionIdDelete(id: string, options?: AxiosRequestConfig) {
        return MeldungVersionApiFp(this.configuration)
            .apiMeldungVersionIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a MeldungVersion resource.
     * @summary Retrieves a MeldungVersion resource.
     * @param {string} id MeldungVersion identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungVersionApi
     */
    public apiMeldungVersionIdGet(id: string, options?: AxiosRequestConfig) {
        return MeldungVersionApiFp(this.configuration)
            .apiMeldungVersionIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the MeldungVersion resource.
     * @summary Updates the MeldungVersion resource.
     * @param {string} id MeldungVersion identifier
     * @param {MeldungVersion} meldungVersion The updated MeldungVersion resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungVersionApi
     */
    public apiMeldungVersionIdPatch(id: string, meldungVersion: MeldungVersion, options?: AxiosRequestConfig) {
        return MeldungVersionApiFp(this.configuration)
            .apiMeldungVersionIdPatch(id, meldungVersion, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the MeldungVersion resource.
     * @summary Replaces the MeldungVersion resource.
     * @param {string} id MeldungVersion identifier
     * @param {MeldungVersionJsonld} meldungVersionJsonld The updated MeldungVersion resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungVersionApi
     */
    public apiMeldungVersionIdPut(
        id: string,
        meldungVersionJsonld: MeldungVersionJsonld,
        options?: AxiosRequestConfig
    ) {
        return MeldungVersionApiFp(this.configuration)
            .apiMeldungVersionIdPut(id, meldungVersionJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a MeldungVersion resource.
     * @summary Creates a MeldungVersion resource.
     * @param {MeldungVersionJsonld} meldungVersionJsonld The new MeldungVersion resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeldungVersionApi
     */
    public apiMeldungVersionPost(meldungVersionJsonld: MeldungVersionJsonld, options?: AxiosRequestConfig) {
        return MeldungVersionApiFp(this.configuration)
            .apiMeldungVersionPost(meldungVersionJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * PlatzzahlmelderTokenApi - axios parameter creator
 * @export
 */
export const PlatzzahlmelderTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a PlatzzahlmelderToken resource.
         * @summary Creates a PlatzzahlmelderToken resource.
         * @param {PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate} platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate The new PlatzzahlmelderToken resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzzahlmelderTokenPost: async (
            platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate: PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate' is not null or undefined
            assertParamExists(
                'apiPlatzzahlmelderTokenPost',
                'platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate',
                platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate
            );
            const localVarPath = `/api/platzzahlmelder-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a PlatzzahlmelderToken resource.
         * @summary Retrieves a PlatzzahlmelderToken resource.
         * @param {string} token PlatzzahlmelderToken identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzzahlmelderTokenTokenGet: async (
            token: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiPlatzzahlmelderTokenTokenGet', 'token', token);
            const localVarPath = `/api/platzzahlmelder-token/{token}`.replace(
                `{${'token'}}`,
                encodeURIComponent(String(token))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a PlatzzahlmelderToken resource.
         * @summary Retrieves a PlatzzahlmelderToken resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzzahlmelderTokengetByBenutzerIdGet: async (
            id: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlatzzahlmelderTokengetByBenutzerIdGet', 'id', id);
            const localVarPath = `/api/platzzahlmelder-token/getByBenutzer/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PlatzzahlmelderTokenApi - functional programming interface
 * @export
 */
export const PlatzzahlmelderTokenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PlatzzahlmelderTokenApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates a PlatzzahlmelderToken resource.
         * @summary Creates a PlatzzahlmelderToken resource.
         * @param {PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate} platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate The new PlatzzahlmelderToken resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlatzzahlmelderTokenPost(
            platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate: PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlatzzahlmelderTokenPost(
                platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a PlatzzahlmelderToken resource.
         * @summary Retrieves a PlatzzahlmelderToken resource.
         * @param {string} token PlatzzahlmelderToken identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlatzzahlmelderTokenTokenGet(
            token: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatzzahlmelderTokenJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlatzzahlmelderTokenTokenGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a PlatzzahlmelderToken resource.
         * @summary Retrieves a PlatzzahlmelderToken resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlatzzahlmelderTokengetByBenutzerIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlatzzahlmelderTokengetByBenutzerIdGet(
                id,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PlatzzahlmelderTokenApi - factory interface
 * @export
 */
export const PlatzzahlmelderTokenApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = PlatzzahlmelderTokenApiFp(configuration);
    return {
        /**
         * Creates a PlatzzahlmelderToken resource.
         * @summary Creates a PlatzzahlmelderToken resource.
         * @param {PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate} platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate The new PlatzzahlmelderToken resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzzahlmelderTokenPost(
            platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate: PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate,
            options?: any
        ): AxiosPromise<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead> {
            return localVarFp
                .apiPlatzzahlmelderTokenPost(platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a PlatzzahlmelderToken resource.
         * @summary Retrieves a PlatzzahlmelderToken resource.
         * @param {string} token PlatzzahlmelderToken identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzzahlmelderTokenTokenGet(token: string, options?: any): AxiosPromise<PlatzzahlmelderTokenJsonld> {
            return localVarFp
                .apiPlatzzahlmelderTokenTokenGet(token, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a PlatzzahlmelderToken resource.
         * @summary Retrieves a PlatzzahlmelderToken resource.
         * @param {string} id Benutzer identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlatzzahlmelderTokengetByBenutzerIdGet(
            id: string,
            options?: any
        ): AxiosPromise<PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenRead> {
            return localVarFp
                .apiPlatzzahlmelderTokengetByBenutzerIdGet(id, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlatzzahlmelderTokenApi - object-oriented interface
 * @export
 * @class PlatzzahlmelderTokenApi
 * @extends {BaseAPI}
 */
export class PlatzzahlmelderTokenApi extends BaseAPI {
    /**
     * Creates a PlatzzahlmelderToken resource.
     * @summary Creates a PlatzzahlmelderToken resource.
     * @param {PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate} platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate The new PlatzzahlmelderToken resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatzzahlmelderTokenApi
     */
    public apiPlatzzahlmelderTokenPost(
        platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate: PlatzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate,
        options?: AxiosRequestConfig
    ) {
        return PlatzzahlmelderTokenApiFp(this.configuration)
            .apiPlatzzahlmelderTokenPost(platzzahlmelderTokenJsonldPlatzzahlmelderTokenCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a PlatzzahlmelderToken resource.
     * @summary Retrieves a PlatzzahlmelderToken resource.
     * @param {string} token PlatzzahlmelderToken identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatzzahlmelderTokenApi
     */
    public apiPlatzzahlmelderTokenTokenGet(token: string, options?: AxiosRequestConfig) {
        return PlatzzahlmelderTokenApiFp(this.configuration)
            .apiPlatzzahlmelderTokenTokenGet(token, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a PlatzzahlmelderToken resource.
     * @summary Retrieves a PlatzzahlmelderToken resource.
     * @param {string} id Benutzer identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatzzahlmelderTokenApi
     */
    public apiPlatzzahlmelderTokengetByBenutzerIdGet(id: string, options?: AxiosRequestConfig) {
        return PlatzzahlmelderTokenApiFp(this.configuration)
            .apiPlatzzahlmelderTokengetByBenutzerIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * RegelpruefungAntragApi - axios parameter creator
 * @export
 */
export const RegelpruefungAntragApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of regelpruefung-antrag resources.
         * @summary Retrieves the collection of regelpruefung-antrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtAt]
         * @param {string} [eingereichtAt2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [einrichtungBehoerde]
         * @param {Array<string>} [einrichtungBehoerde2]
         * @param {string} [einrichtungStandort]
         * @param {Array<string>} [einrichtungStandort2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
         * @param {'asc' | 'desc'} [orderPruefDatum]
         * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
         * @param {'asc' | 'desc'} [orderLetzterUploadAt]
         * @param {string} [hasPruefbericht]
         * @param {string} [jahr]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPruefberichteGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtAt?: string,
            eingereichtAt2?: string,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            einrichtungBehoerde?: string,
            einrichtungBehoerde2?: Array<string>,
            einrichtungStandort?: string,
            einrichtungStandort2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungBehoerdeName?: 'asc' | 'desc',
            orderPruefDatum?: 'asc' | 'desc',
            orderLetzteNachpruefung?: 'asc' | 'desc',
            orderLetzterUploadAt?: 'asc' | 'desc',
            hasPruefbericht?: string,
            jahr?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/pruefberichte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (eingereichtAt !== undefined) {
                localVarQueryParameter['eingereichtAt'] = eingereichtAt;
            }

            if (eingereichtAt2 !== undefined) {
                localVarQueryParameter['eingereichtAt[]'] = eingereichtAt2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (einrichtungBehoerde !== undefined) {
                localVarQueryParameter['einrichtung.behoerde'] = einrichtungBehoerde;
            }

            if (einrichtungBehoerde2) {
                localVarQueryParameter['einrichtung.behoerde[]'] = einrichtungBehoerde2;
            }

            if (einrichtungStandort !== undefined) {
                localVarQueryParameter['einrichtungStandort'] = einrichtungStandort;
            }

            if (einrichtungStandort2) {
                localVarQueryParameter['einrichtungStandort[]'] = einrichtungStandort2;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderEinrichtungBehoerdeName !== undefined) {
                localVarQueryParameter['order[einrichtung.behoerde.name]'] = orderEinrichtungBehoerdeName;
            }

            if (orderPruefDatum !== undefined) {
                localVarQueryParameter['order[pruefDatum]'] = orderPruefDatum;
            }

            if (orderLetzteNachpruefung !== undefined) {
                localVarQueryParameter['order[letzteNachpruefung]'] = orderLetzteNachpruefung;
            }

            if (orderLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[letzterUploadAt]'] = orderLetzterUploadAt;
            }

            if (hasPruefbericht !== undefined) {
                localVarQueryParameter['hasPruefbericht'] = hasPruefbericht;
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {any} body The updated regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPruefberichteIdpruefenPatch: async (
            id: string,
            body: any,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPruefberichteIdpruefenPatch', 'id', id);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiPruefberichteIdpruefenPatch', 'body', body);
            const localVarPath = `/api/pruefberichte/{id}/pruefen`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of regelpruefung-antrag resources.
         * @summary Retrieves the collection of regelpruefung-antrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtAt]
         * @param {string} [eingereichtAt2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [einrichtungBehoerde]
         * @param {Array<string>} [einrichtungBehoerde2]
         * @param {string} [einrichtungStandort]
         * @param {Array<string>} [einrichtungStandort2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
         * @param {'asc' | 'desc'} [orderPruefDatum]
         * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
         * @param {'asc' | 'desc'} [orderLetzterUploadAt]
         * @param {string} [hasPruefbericht]
         * @param {string} [jahr]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtAt?: string,
            eingereichtAt2?: string,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            einrichtungBehoerde?: string,
            einrichtungBehoerde2?: Array<string>,
            einrichtungStandort?: string,
            einrichtungStandort2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungBehoerdeName?: 'asc' | 'desc',
            orderPruefDatum?: 'asc' | 'desc',
            orderLetzteNachpruefung?: 'asc' | 'desc',
            orderLetzterUploadAt?: 'asc' | 'desc',
            hasPruefbericht?: string,
            jahr?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/regelpruefung-antrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (eingereichtAt !== undefined) {
                localVarQueryParameter['eingereichtAt'] = eingereichtAt;
            }

            if (eingereichtAt2 !== undefined) {
                localVarQueryParameter['eingereichtAt[]'] = eingereichtAt2;
            }

            if (einrichtung !== undefined) {
                localVarQueryParameter['einrichtung'] = einrichtung;
            }

            if (einrichtung2) {
                localVarQueryParameter['einrichtung[]'] = einrichtung2;
            }

            if (einrichtungBehoerde !== undefined) {
                localVarQueryParameter['einrichtung.behoerde'] = einrichtungBehoerde;
            }

            if (einrichtungBehoerde2) {
                localVarQueryParameter['einrichtung.behoerde[]'] = einrichtungBehoerde2;
            }

            if (einrichtungStandort !== undefined) {
                localVarQueryParameter['einrichtungStandort'] = einrichtungStandort;
            }

            if (einrichtungStandort2) {
                localVarQueryParameter['einrichtungStandort[]'] = einrichtungStandort2;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderEinrichtungBehoerdeName !== undefined) {
                localVarQueryParameter['order[einrichtung.behoerde.name]'] = orderEinrichtungBehoerdeName;
            }

            if (orderPruefDatum !== undefined) {
                localVarQueryParameter['order[pruefDatum]'] = orderPruefDatum;
            }

            if (orderLetzteNachpruefung !== undefined) {
                localVarQueryParameter['order[letzteNachpruefung]'] = orderLetzteNachpruefung;
            }

            if (orderLetzterUploadAt !== undefined) {
                localVarQueryParameter['order[letzterUploadAt]'] = orderLetzterUploadAt;
            }

            if (hasPruefbericht !== undefined) {
                localVarQueryParameter['hasPruefbericht'] = hasPruefbericht;
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the regelpruefung-antrag resource.
         * @summary Removes the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdDelete', 'id', id);
            const localVarPath = `/api/regelpruefung-antrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a regelpruefung-antrag resource.
         * @summary Retrieves a regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdGet', 'id', id);
            const localVarPath = `/api/regelpruefung-antrag/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the regelpruefung-antrag resource.
         * @summary Removes the regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdformDelete: async (
            id: number,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdformDelete', 'id', id);
            const localVarPath = `/api/regelpruefung-antrag/{id}/form`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a regelpruefung-antrag resource.
         * @summary Retrieves a regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdformGet: async (
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdformGet', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdformGet', 'step', step);
            const localVarPath = `/api/regelpruefung-antrag/{id}/form`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated regelpruefung-antrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdformPatch: async (
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdformPatch', 'id', id);
            // verify required parameter 'step' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdformPatch', 'step', step);
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdformPatch', 'body', body);
            const localVarPath = `/api/regelpruefung-antrag/{id}/form`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (persist !== undefined) {
                localVarQueryParameter['persist'] = persist;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {RegelpruefungAntragAntragWorkflowActionAntragWorkflow} regelpruefungAntragAntragWorkflowActionAntragWorkflow The updated regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdworkflowPatch: async (
            id: string,
            regelpruefungAntragAntragWorkflowActionAntragWorkflow: RegelpruefungAntragAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegelpruefungAntragIdworkflowPatch', 'id', id);
            // verify required parameter 'regelpruefungAntragAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiRegelpruefungAntragIdworkflowPatch',
                'regelpruefungAntragAntragWorkflowActionAntragWorkflow',
                regelpruefungAntragAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/regelpruefung-antrag/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                regelpruefungAntragAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a regelpruefung-antrag resource.
         * @summary Creates a regelpruefung-antrag resource.
         * @param {RegelpruefungAntragJsonldRegelpruefungCreate} regelpruefungAntragJsonldRegelpruefungCreate The new regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragPost: async (
            regelpruefungAntragJsonldRegelpruefungCreate: RegelpruefungAntragJsonldRegelpruefungCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'regelpruefungAntragJsonldRegelpruefungCreate' is not null or undefined
            assertParamExists(
                'apiRegelpruefungAntragPost',
                'regelpruefungAntragJsonldRegelpruefungCreate',
                regelpruefungAntragJsonldRegelpruefungCreate
            );
            const localVarPath = `/api/regelpruefung-antrag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                regelpruefungAntragJsonldRegelpruefungCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RegelpruefungAntragApi - functional programming interface
 * @export
 */
export const RegelpruefungAntragApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RegelpruefungAntragApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of regelpruefung-antrag resources.
         * @summary Retrieves the collection of regelpruefung-antrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtAt]
         * @param {string} [eingereichtAt2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [einrichtungBehoerde]
         * @param {Array<string>} [einrichtungBehoerde2]
         * @param {string} [einrichtungStandort]
         * @param {Array<string>} [einrichtungStandort2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
         * @param {'asc' | 'desc'} [orderPruefDatum]
         * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
         * @param {'asc' | 'desc'} [orderLetzterUploadAt]
         * @param {string} [hasPruefbericht]
         * @param {string} [jahr]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPruefberichteGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtAt?: string,
            eingereichtAt2?: string,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            einrichtungBehoerde?: string,
            einrichtungBehoerde2?: Array<string>,
            einrichtungStandort?: string,
            einrichtungStandort2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungBehoerdeName?: 'asc' | 'desc',
            orderPruefDatum?: 'asc' | 'desc',
            orderLetzteNachpruefung?: 'asc' | 'desc',
            orderLetzterUploadAt?: 'asc' | 'desc',
            hasPruefbericht?: string,
            jahr?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPruefberichteGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPruefberichteGetCollection(
                page,
                itemsPerPage,
                qSearch,
                eingereichtAt,
                eingereichtAt2,
                einrichtung,
                einrichtung2,
                einrichtungBehoerde,
                einrichtungBehoerde2,
                einrichtungStandort,
                einrichtungStandort2,
                status,
                status2,
                orderEinrichtungName,
                orderEinrichtungBehoerdeName,
                orderPruefDatum,
                orderLetzteNachpruefung,
                orderLetzterUploadAt,
                hasPruefbericht,
                jahr,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {any} body The updated regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPruefberichteIdpruefenPatch(
            id: string,
            body: any,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegelpruefungAntragJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPruefberichteIdpruefenPatch(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of regelpruefung-antrag resources.
         * @summary Retrieves the collection of regelpruefung-antrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtAt]
         * @param {string} [eingereichtAt2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [einrichtungBehoerde]
         * @param {Array<string>} [einrichtungBehoerde2]
         * @param {string} [einrichtungStandort]
         * @param {Array<string>} [einrichtungStandort2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
         * @param {'asc' | 'desc'} [orderPruefDatum]
         * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
         * @param {'asc' | 'desc'} [orderLetzterUploadAt]
         * @param {string} [hasPruefbericht]
         * @param {string} [jahr]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtAt?: string,
            eingereichtAt2?: string,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            einrichtungBehoerde?: string,
            einrichtungBehoerde2?: Array<string>,
            einrichtungStandort?: string,
            einrichtungStandort2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungBehoerdeName?: 'asc' | 'desc',
            orderPruefDatum?: 'asc' | 'desc',
            orderLetzteNachpruefung?: 'asc' | 'desc',
            orderLetzterUploadAt?: 'asc' | 'desc',
            hasPruefbericht?: string,
            jahr?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiRegelpruefungAntragGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragGetCollection(
                page,
                itemsPerPage,
                qSearch,
                eingereichtAt,
                eingereichtAt2,
                einrichtung,
                einrichtung2,
                einrichtungBehoerde,
                einrichtungBehoerde2,
                einrichtungStandort,
                einrichtungStandort2,
                status,
                status2,
                orderEinrichtungName,
                orderEinrichtungBehoerdeName,
                orderPruefDatum,
                orderLetzteNachpruefung,
                orderLetzterUploadAt,
                hasPruefbericht,
                jahr,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the regelpruefung-antrag resource.
         * @summary Removes the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragIdDelete(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a regelpruefung-antrag resource.
         * @summary Retrieves a regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegelpruefungAntragJsonldRegelpruefungRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the regelpruefung-antrag resource.
         * @summary Removes the regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragIdformDelete(
            id: number,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragIdformDelete(
                id,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a regelpruefung-antrag resource.
         * @summary Retrieves a regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragIdformGet(
                id,
                step,
                objectType,
                uuid,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated regelpruefung-antrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragIdformPatch(
                id,
                step,
                body,
                objectType,
                uuid,
                persist,
                fields,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {RegelpruefungAntragAntragWorkflowActionAntragWorkflow} regelpruefungAntragAntragWorkflowActionAntragWorkflow The updated regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragIdworkflowPatch(
            id: string,
            regelpruefungAntragAntragWorkflowActionAntragWorkflow: RegelpruefungAntragAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegelpruefungAntragJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragIdworkflowPatch(
                id,
                regelpruefungAntragAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a regelpruefung-antrag resource.
         * @summary Creates a regelpruefung-antrag resource.
         * @param {RegelpruefungAntragJsonldRegelpruefungCreate} regelpruefungAntragJsonldRegelpruefungCreate The new regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegelpruefungAntragPost(
            regelpruefungAntragJsonldRegelpruefungCreate: RegelpruefungAntragJsonldRegelpruefungCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegelpruefungAntragJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegelpruefungAntragPost(
                regelpruefungAntragJsonldRegelpruefungCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RegelpruefungAntragApi - factory interface
 * @export
 */
export const RegelpruefungAntragApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = RegelpruefungAntragApiFp(configuration);
    return {
        /**
         * Retrieves the collection of regelpruefung-antrag resources.
         * @summary Retrieves the collection of regelpruefung-antrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtAt]
         * @param {string} [eingereichtAt2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [einrichtungBehoerde]
         * @param {Array<string>} [einrichtungBehoerde2]
         * @param {string} [einrichtungStandort]
         * @param {Array<string>} [einrichtungStandort2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
         * @param {'asc' | 'desc'} [orderPruefDatum]
         * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
         * @param {'asc' | 'desc'} [orderLetzterUploadAt]
         * @param {string} [hasPruefbericht]
         * @param {string} [jahr]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPruefberichteGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtAt?: string,
            eingereichtAt2?: string,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            einrichtungBehoerde?: string,
            einrichtungBehoerde2?: Array<string>,
            einrichtungStandort?: string,
            einrichtungStandort2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungBehoerdeName?: 'asc' | 'desc',
            orderPruefDatum?: 'asc' | 'desc',
            orderLetzteNachpruefung?: 'asc' | 'desc',
            orderLetzterUploadAt?: 'asc' | 'desc',
            hasPruefbericht?: string,
            jahr?: string,
            options?: any
        ): AxiosPromise<ApiPruefberichteGetCollection200Response> {
            return localVarFp
                .apiPruefberichteGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    eingereichtAt,
                    eingereichtAt2,
                    einrichtung,
                    einrichtung2,
                    einrichtungBehoerde,
                    einrichtungBehoerde2,
                    einrichtungStandort,
                    einrichtungStandort2,
                    status,
                    status2,
                    orderEinrichtungName,
                    orderEinrichtungBehoerdeName,
                    orderPruefDatum,
                    orderLetzteNachpruefung,
                    orderLetzterUploadAt,
                    hasPruefbericht,
                    jahr,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {any} body The updated regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPruefberichteIdpruefenPatch(id: string, body: any, options?: any): AxiosPromise<RegelpruefungAntragJsonld> {
            return localVarFp
                .apiPruefberichteIdpruefenPatch(id, body, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of regelpruefung-antrag resources.
         * @summary Retrieves the collection of regelpruefung-antrag resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [eingereichtAt]
         * @param {string} [eingereichtAt2]
         * @param {string} [einrichtung]
         * @param {Array<string>} [einrichtung2]
         * @param {string} [einrichtungBehoerde]
         * @param {Array<string>} [einrichtungBehoerde2]
         * @param {string} [einrichtungStandort]
         * @param {Array<string>} [einrichtungStandort2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
         * @param {'asc' | 'desc'} [orderPruefDatum]
         * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
         * @param {'asc' | 'desc'} [orderLetzterUploadAt]
         * @param {string} [hasPruefbericht]
         * @param {string} [jahr]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            eingereichtAt?: string,
            eingereichtAt2?: string,
            einrichtung?: string,
            einrichtung2?: Array<string>,
            einrichtungBehoerde?: string,
            einrichtungBehoerde2?: Array<string>,
            einrichtungStandort?: string,
            einrichtungStandort2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            orderEinrichtungName?: 'asc' | 'desc',
            orderEinrichtungBehoerdeName?: 'asc' | 'desc',
            orderPruefDatum?: 'asc' | 'desc',
            orderLetzteNachpruefung?: 'asc' | 'desc',
            orderLetzterUploadAt?: 'asc' | 'desc',
            hasPruefbericht?: string,
            jahr?: string,
            options?: any
        ): AxiosPromise<ApiRegelpruefungAntragGetCollection200Response> {
            return localVarFp
                .apiRegelpruefungAntragGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    eingereichtAt,
                    eingereichtAt2,
                    einrichtung,
                    einrichtung2,
                    einrichtungBehoerde,
                    einrichtungBehoerde2,
                    einrichtungStandort,
                    einrichtungStandort2,
                    status,
                    status2,
                    orderEinrichtungName,
                    orderEinrichtungBehoerdeName,
                    orderPruefDatum,
                    orderLetzteNachpruefung,
                    orderLetzterUploadAt,
                    hasPruefbericht,
                    jahr,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the regelpruefung-antrag resource.
         * @summary Removes the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRegelpruefungAntragIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a regelpruefung-antrag resource.
         * @summary Retrieves a regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdGet(
            id: string,
            options?: any
        ): AxiosPromise<RegelpruefungAntragJsonldRegelpruefungRead> {
            return localVarFp.apiRegelpruefungAntragIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the regelpruefung-antrag resource.
         * @summary Removes the regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdformDelete(id: number, uuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .apiRegelpruefungAntragIdformDelete(id, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a regelpruefung-antrag resource.
         * @summary Retrieves a regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} step
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdformGet(
            id: number,
            step: string,
            objectType?: string,
            uuid?: string,
            options?: any
        ): AxiosPromise<RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiRegelpruefungAntragIdformGet(id, step, objectType, uuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {number} id
         * @param {string} step
         * @param {object} body The updated regelpruefung-antrag resource
         * @param {string} [objectType]
         * @param {string} [uuid]
         * @param {boolean} [persist]
         * @param {Array<string>} [fields]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdformPatch(
            id: number,
            step: string,
            body: object,
            objectType?: string,
            uuid?: string,
            persist?: boolean,
            fields?: Array<string>,
            options?: any
        ): AxiosPromise<RegelpruefungAntragRegelpruefungAntragJsonJsonldAntragJsonForm> {
            return localVarFp
                .apiRegelpruefungAntragIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the regelpruefung-antrag resource.
         * @summary Updates the regelpruefung-antrag resource.
         * @param {string} id RegelpruefungAntrag identifier
         * @param {RegelpruefungAntragAntragWorkflowActionAntragWorkflow} regelpruefungAntragAntragWorkflowActionAntragWorkflow The updated regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragIdworkflowPatch(
            id: string,
            regelpruefungAntragAntragWorkflowActionAntragWorkflow: RegelpruefungAntragAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<RegelpruefungAntragJsonld> {
            return localVarFp
                .apiRegelpruefungAntragIdworkflowPatch(
                    id,
                    regelpruefungAntragAntragWorkflowActionAntragWorkflow,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a regelpruefung-antrag resource.
         * @summary Creates a regelpruefung-antrag resource.
         * @param {RegelpruefungAntragJsonldRegelpruefungCreate} regelpruefungAntragJsonldRegelpruefungCreate The new regelpruefung-antrag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegelpruefungAntragPost(
            regelpruefungAntragJsonldRegelpruefungCreate: RegelpruefungAntragJsonldRegelpruefungCreate,
            options?: any
        ): AxiosPromise<RegelpruefungAntragJsonld> {
            return localVarFp
                .apiRegelpruefungAntragPost(regelpruefungAntragJsonldRegelpruefungCreate, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegelpruefungAntragApi - object-oriented interface
 * @export
 * @class RegelpruefungAntragApi
 * @extends {BaseAPI}
 */
export class RegelpruefungAntragApi extends BaseAPI {
    /**
     * Retrieves the collection of regelpruefung-antrag resources.
     * @summary Retrieves the collection of regelpruefung-antrag resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [eingereichtAt]
     * @param {string} [eingereichtAt2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [einrichtungBehoerde]
     * @param {Array<string>} [einrichtungBehoerde2]
     * @param {string} [einrichtungStandort]
     * @param {Array<string>} [einrichtungStandort2]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
     * @param {'asc' | 'desc'} [orderPruefDatum]
     * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
     * @param {'asc' | 'desc'} [orderLetzterUploadAt]
     * @param {string} [hasPruefbericht]
     * @param {string} [jahr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiPruefberichteGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        eingereichtAt?: string,
        eingereichtAt2?: string,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        einrichtungBehoerde?: string,
        einrichtungBehoerde2?: Array<string>,
        einrichtungStandort?: string,
        einrichtungStandort2?: Array<string>,
        status?: string,
        status2?: Array<string>,
        orderEinrichtungName?: 'asc' | 'desc',
        orderEinrichtungBehoerdeName?: 'asc' | 'desc',
        orderPruefDatum?: 'asc' | 'desc',
        orderLetzteNachpruefung?: 'asc' | 'desc',
        orderLetzterUploadAt?: 'asc' | 'desc',
        hasPruefbericht?: string,
        jahr?: string,
        options?: AxiosRequestConfig
    ) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiPruefberichteGetCollection(
                page,
                itemsPerPage,
                qSearch,
                eingereichtAt,
                eingereichtAt2,
                einrichtung,
                einrichtung2,
                einrichtungBehoerde,
                einrichtungBehoerde2,
                einrichtungStandort,
                einrichtungStandort2,
                status,
                status2,
                orderEinrichtungName,
                orderEinrichtungBehoerdeName,
                orderPruefDatum,
                orderLetzteNachpruefung,
                orderLetzterUploadAt,
                hasPruefbericht,
                jahr,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the regelpruefung-antrag resource.
     * @summary Updates the regelpruefung-antrag resource.
     * @param {string} id RegelpruefungAntrag identifier
     * @param {any} body The updated regelpruefung-antrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiPruefberichteIdpruefenPatch(id: string, body: any, options?: AxiosRequestConfig) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiPruefberichteIdpruefenPatch(id, body, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of regelpruefung-antrag resources.
     * @summary Retrieves the collection of regelpruefung-antrag resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [eingereichtAt]
     * @param {string} [eingereichtAt2]
     * @param {string} [einrichtung]
     * @param {Array<string>} [einrichtung2]
     * @param {string} [einrichtungBehoerde]
     * @param {Array<string>} [einrichtungBehoerde2]
     * @param {string} [einrichtungStandort]
     * @param {Array<string>} [einrichtungStandort2]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderEinrichtungBehoerdeName]
     * @param {'asc' | 'desc'} [orderPruefDatum]
     * @param {'asc' | 'desc'} [orderLetzteNachpruefung]
     * @param {'asc' | 'desc'} [orderLetzterUploadAt]
     * @param {string} [hasPruefbericht]
     * @param {string} [jahr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        eingereichtAt?: string,
        eingereichtAt2?: string,
        einrichtung?: string,
        einrichtung2?: Array<string>,
        einrichtungBehoerde?: string,
        einrichtungBehoerde2?: Array<string>,
        einrichtungStandort?: string,
        einrichtungStandort2?: Array<string>,
        status?: string,
        status2?: Array<string>,
        orderEinrichtungName?: 'asc' | 'desc',
        orderEinrichtungBehoerdeName?: 'asc' | 'desc',
        orderPruefDatum?: 'asc' | 'desc',
        orderLetzteNachpruefung?: 'asc' | 'desc',
        orderLetzterUploadAt?: 'asc' | 'desc',
        hasPruefbericht?: string,
        jahr?: string,
        options?: AxiosRequestConfig
    ) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragGetCollection(
                page,
                itemsPerPage,
                qSearch,
                eingereichtAt,
                eingereichtAt2,
                einrichtung,
                einrichtung2,
                einrichtungBehoerde,
                einrichtungBehoerde2,
                einrichtungStandort,
                einrichtungStandort2,
                status,
                status2,
                orderEinrichtungName,
                orderEinrichtungBehoerdeName,
                orderPruefDatum,
                orderLetzteNachpruefung,
                orderLetzterUploadAt,
                hasPruefbericht,
                jahr,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the regelpruefung-antrag resource.
     * @summary Removes the regelpruefung-antrag resource.
     * @param {string} id RegelpruefungAntrag identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragIdDelete(id: string, options?: AxiosRequestConfig) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragIdDelete(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a regelpruefung-antrag resource.
     * @summary Retrieves a regelpruefung-antrag resource.
     * @param {string} id RegelpruefungAntrag identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragIdGet(id: string, options?: AxiosRequestConfig) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the regelpruefung-antrag resource.
     * @summary Removes the regelpruefung-antrag resource.
     * @param {number} id
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragIdformDelete(id: number, uuid?: string, options?: AxiosRequestConfig) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragIdformDelete(id, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a regelpruefung-antrag resource.
     * @summary Retrieves a regelpruefung-antrag resource.
     * @param {number} id
     * @param {string} step
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragIdformGet(
        id: number,
        step: string,
        objectType?: string,
        uuid?: string,
        options?: AxiosRequestConfig
    ) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragIdformGet(id, step, objectType, uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the regelpruefung-antrag resource.
     * @summary Updates the regelpruefung-antrag resource.
     * @param {number} id
     * @param {string} step
     * @param {object} body The updated regelpruefung-antrag resource
     * @param {string} [objectType]
     * @param {string} [uuid]
     * @param {boolean} [persist]
     * @param {Array<string>} [fields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragIdformPatch(
        id: number,
        step: string,
        body: object,
        objectType?: string,
        uuid?: string,
        persist?: boolean,
        fields?: Array<string>,
        options?: AxiosRequestConfig
    ) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragIdformPatch(id, step, body, objectType, uuid, persist, fields, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the regelpruefung-antrag resource.
     * @summary Updates the regelpruefung-antrag resource.
     * @param {string} id RegelpruefungAntrag identifier
     * @param {RegelpruefungAntragAntragWorkflowActionAntragWorkflow} regelpruefungAntragAntragWorkflowActionAntragWorkflow The updated regelpruefung-antrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragIdworkflowPatch(
        id: string,
        regelpruefungAntragAntragWorkflowActionAntragWorkflow: RegelpruefungAntragAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragIdworkflowPatch(id, regelpruefungAntragAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a regelpruefung-antrag resource.
     * @summary Creates a regelpruefung-antrag resource.
     * @param {RegelpruefungAntragJsonldRegelpruefungCreate} regelpruefungAntragJsonldRegelpruefungCreate The new regelpruefung-antrag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegelpruefungAntragApi
     */
    public apiRegelpruefungAntragPost(
        regelpruefungAntragJsonldRegelpruefungCreate: RegelpruefungAntragJsonldRegelpruefungCreate,
        options?: AxiosRequestConfig
    ) {
        return RegelpruefungAntragApiFp(this.configuration)
            .apiRegelpruefungAntragPost(regelpruefungAntragJsonldRegelpruefungCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * RegionApi - axios parameter creator
 * @export
 */
export const RegionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Region resources.
         * @summary Retrieves the collection of Region resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/region`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RegionApi - functional programming interface
 * @export
 */
export const RegionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Region resources.
         * @summary Retrieves the collection of Region resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegionGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiRegionGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegionGetCollection(
                page,
                itemsPerPage,
                qSearch,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RegionApi - factory interface
 * @export
 */
export const RegionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Region resources.
         * @summary Retrieves the collection of Region resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            options?: any
        ): AxiosPromise<ApiRegionGetCollection200Response> {
            return localVarFp
                .apiRegionGetCollection(page, itemsPerPage, qSearch, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionApi - object-oriented interface
 * @export
 * @class RegionApi
 * @extends {BaseAPI}
 */
export class RegionApi extends BaseAPI {
    /**
     * Retrieves the collection of Region resources.
     * @summary Retrieves the collection of Region resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public apiRegionGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        options?: AxiosRequestConfig
    ) {
        return RegionApiFp(this.configuration)
            .apiRegionGetCollection(page, itemsPerPage, qSearch, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * RegistrierungApi - axios parameter creator
 * @export
 */
export const RegistrierungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a Registrierung resource.
         * @summary Creates a Registrierung resource.
         * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistrierungPost: async (
            registrierungJsonld: RegistrierungJsonld,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'registrierungJsonld' is not null or undefined
            assertParamExists('apiRegistrierungPost', 'registrierungJsonld', registrierungJsonld);
            const localVarPath = `/api/registrierung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                registrierungJsonld,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * RegistrierungApi - functional programming interface
 * @export
 */
export const RegistrierungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrierungApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates a Registrierung resource.
         * @summary Creates a Registrierung resource.
         * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistrierungPost(
            registrierungJsonld: RegistrierungJsonld,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrierungJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistrierungPost(
                registrierungJsonld,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * RegistrierungApi - factory interface
 * @export
 */
export const RegistrierungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = RegistrierungApiFp(configuration);
    return {
        /**
         * Creates a Registrierung resource.
         * @summary Creates a Registrierung resource.
         * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistrierungPost(
            registrierungJsonld: RegistrierungJsonld,
            options?: any
        ): AxiosPromise<RegistrierungJsonld> {
            return localVarFp
                .apiRegistrierungPost(registrierungJsonld, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrierungApi - object-oriented interface
 * @export
 * @class RegistrierungApi
 * @extends {BaseAPI}
 */
export class RegistrierungApi extends BaseAPI {
    /**
     * Creates a Registrierung resource.
     * @summary Creates a Registrierung resource.
     * @param {RegistrierungJsonld} registrierungJsonld The new Registrierung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrierungApi
     */
    public apiRegistrierungPost(registrierungJsonld: RegistrierungJsonld, options?: AxiosRequestConfig) {
        return RegistrierungApiFp(this.configuration)
            .apiRegistrierungPost(registrierungJsonld, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get JWT token from Session.
         * @summary Get JWT token from Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionGetToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/get_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invalidate Session.
         * @summary Invalidate Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionInvalidate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/invalidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set JWT token in Session.
         * @summary Set JWT token in Session.
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionSetToken: async (token?: Token, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/set_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration);
    return {
        /**
         * Get JWT token from Session.
         * @summary Get JWT token from Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionGetToken(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionGetToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invalidate Session.
         * @summary Invalidate Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionInvalidate(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionInvalidate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set JWT token in Session.
         * @summary Set JWT token in Session.
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionSetToken(
            token?: Token,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionSetToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration);
    return {
        /**
         * Get JWT token from Session.
         * @summary Get JWT token from Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionGetToken(options?: any): AxiosPromise<Token> {
            return localVarFp.sessionGetToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Invalidate Session.
         * @summary Invalidate Session.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionInvalidate(options?: any): AxiosPromise<void> {
            return localVarFp.sessionInvalidate(options).then((request) => request(axios, basePath));
        },
        /**
         * Set JWT token in Session.
         * @summary Set JWT token in Session.
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionSetToken(token?: Token, options?: any): AxiosPromise<void> {
            return localVarFp.sessionSetToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * Get JWT token from Session.
     * @summary Get JWT token from Session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sessionGetToken(options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration)
            .sessionGetToken(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invalidate Session.
     * @summary Invalidate Session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sessionInvalidate(options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration)
            .sessionInvalidate(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set JWT token in Session.
     * @summary Set JWT token in Session.
     * @param {Token} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sessionSetToken(token?: Token, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration)
            .sessionSetToken(token, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * SignaturApi - axios parameter creator
 * @export
 */
export const SignaturApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Signatur resource.
         * @summary Retrieves a Signatur resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignaturGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/signatur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Signatur resource.
         * @summary Updates the Signatur resource.
         * @param {string} id Signatur identifier
         * @param {SignaturSignaturWrite} signaturSignaturWrite The updated Signatur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignaturIdPatch: async (
            id: string,
            signaturSignaturWrite: SignaturSignaturWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSignaturIdPatch', 'id', id);
            // verify required parameter 'signaturSignaturWrite' is not null or undefined
            assertParamExists('apiSignaturIdPatch', 'signaturSignaturWrite', signaturSignaturWrite);
            const localVarPath = `/api/signatur/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                signaturSignaturWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Signatur resource.
         * @summary Creates a Signatur resource.
         * @param {SignaturJsonldSignaturWrite} signaturJsonldSignaturWrite The new Signatur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignaturPost: async (
            signaturJsonldSignaturWrite: SignaturJsonldSignaturWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'signaturJsonldSignaturWrite' is not null or undefined
            assertParamExists('apiSignaturPost', 'signaturJsonldSignaturWrite', signaturJsonldSignaturWrite);
            const localVarPath = `/api/signatur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                signaturJsonldSignaturWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SignaturApi - functional programming interface
 * @export
 */
export const SignaturApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SignaturApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Signatur resource.
         * @summary Retrieves a Signatur resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignaturGet(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignaturJsonldSignaturRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignaturGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Signatur resource.
         * @summary Updates the Signatur resource.
         * @param {string} id Signatur identifier
         * @param {SignaturSignaturWrite} signaturSignaturWrite The updated Signatur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignaturIdPatch(
            id: string,
            signaturSignaturWrite: SignaturSignaturWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignaturJsonldSignaturRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignaturIdPatch(
                id,
                signaturSignaturWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Signatur resource.
         * @summary Creates a Signatur resource.
         * @param {SignaturJsonldSignaturWrite} signaturJsonldSignaturWrite The new Signatur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignaturPost(
            signaturJsonldSignaturWrite: SignaturJsonldSignaturWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignaturJsonldSignaturRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignaturPost(
                signaturJsonldSignaturWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SignaturApi - factory interface
 * @export
 */
export const SignaturApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignaturApiFp(configuration);
    return {
        /**
         * Retrieves a Signatur resource.
         * @summary Retrieves a Signatur resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignaturGet(options?: any): AxiosPromise<SignaturJsonldSignaturRead> {
            return localVarFp.apiSignaturGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Signatur resource.
         * @summary Updates the Signatur resource.
         * @param {string} id Signatur identifier
         * @param {SignaturSignaturWrite} signaturSignaturWrite The updated Signatur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignaturIdPatch(
            id: string,
            signaturSignaturWrite: SignaturSignaturWrite,
            options?: any
        ): AxiosPromise<SignaturJsonldSignaturRead> {
            return localVarFp
                .apiSignaturIdPatch(id, signaturSignaturWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Signatur resource.
         * @summary Creates a Signatur resource.
         * @param {SignaturJsonldSignaturWrite} signaturJsonldSignaturWrite The new Signatur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignaturPost(
            signaturJsonldSignaturWrite: SignaturJsonldSignaturWrite,
            options?: any
        ): AxiosPromise<SignaturJsonldSignaturRead> {
            return localVarFp
                .apiSignaturPost(signaturJsonldSignaturWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignaturApi - object-oriented interface
 * @export
 * @class SignaturApi
 * @extends {BaseAPI}
 */
export class SignaturApi extends BaseAPI {
    /**
     * Retrieves a Signatur resource.
     * @summary Retrieves a Signatur resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignaturApi
     */
    public apiSignaturGet(options?: AxiosRequestConfig) {
        return SignaturApiFp(this.configuration)
            .apiSignaturGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Signatur resource.
     * @summary Updates the Signatur resource.
     * @param {string} id Signatur identifier
     * @param {SignaturSignaturWrite} signaturSignaturWrite The updated Signatur resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignaturApi
     */
    public apiSignaturIdPatch(id: string, signaturSignaturWrite: SignaturSignaturWrite, options?: AxiosRequestConfig) {
        return SignaturApiFp(this.configuration)
            .apiSignaturIdPatch(id, signaturSignaturWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Signatur resource.
     * @summary Creates a Signatur resource.
     * @param {SignaturJsonldSignaturWrite} signaturJsonldSignaturWrite The new Signatur resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignaturApi
     */
    public apiSignaturPost(signaturJsonldSignaturWrite: SignaturJsonldSignaturWrite, options?: AxiosRequestConfig) {
        return SignaturApiFp(this.configuration)
            .apiSignaturPost(signaturJsonldSignaturWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * StandardauswertungApi - axios parameter creator
 * @export
 */
export const StandardauswertungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Standardauswertung resource.
         * @summary Retrieves a Standardauswertung resource.
         * @param {Array<number>} [bezirksregierung] Regierungsbezirke
         * @param {Array<number>} [behoerde] Behörden
         * @param {Array<number>} [gemeinde] Gemeinden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandardauswertungCsvGet: async (
            bezirksregierung?: Array<number>,
            behoerde?: Array<number>,
            gemeinde?: Array<number>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/standardauswertungCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (bezirksregierung) {
                localVarQueryParameter['bezirksregierung'] = bezirksregierung.join(COLLECTION_FORMATS.csv);
            }

            if (behoerde) {
                localVarQueryParameter['behoerde'] = behoerde.join(COLLECTION_FORMATS.csv);
            }

            if (gemeinde) {
                localVarQueryParameter['gemeinde'] = gemeinde.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Standardauswertung resource.
         * @summary Creates a Standardauswertung resource.
         * @param {StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite} standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite The new Standardauswertung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandardauswertungPost: async (
            standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite: StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite' is not null or undefined
            assertParamExists(
                'apiStandardauswertungPost',
                'standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite',
                standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite
            );
            const localVarPath = `/api/standardauswertung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StandardauswertungApi - functional programming interface
 * @export
 */
export const StandardauswertungApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = StandardauswertungApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Standardauswertung resource.
         * @summary Retrieves a Standardauswertung resource.
         * @param {Array<number>} [bezirksregierung] Regierungsbezirke
         * @param {Array<number>} [behoerde] Behörden
         * @param {Array<number>} [gemeinde] Gemeinden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandardauswertungCsvGet(
            bezirksregierung?: Array<number>,
            behoerde?: Array<number>,
            gemeinde?: Array<number>,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<StandardauswertungExportRequestJsonldExportRequest>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandardauswertungCsvGet(
                bezirksregierung,
                behoerde,
                gemeinde,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Standardauswertung resource.
         * @summary Creates a Standardauswertung resource.
         * @param {StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite} standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite The new Standardauswertung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStandardauswertungPost(
            standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite: StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StandardauswertungStandardauswertungJsonForm>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStandardauswertungPost(
                standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * StandardauswertungApi - factory interface
 * @export
 */
export const StandardauswertungApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = StandardauswertungApiFp(configuration);
    return {
        /**
         * Retrieves a Standardauswertung resource.
         * @summary Retrieves a Standardauswertung resource.
         * @param {Array<number>} [bezirksregierung] Regierungsbezirke
         * @param {Array<number>} [behoerde] Behörden
         * @param {Array<number>} [gemeinde] Gemeinden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandardauswertungCsvGet(
            bezirksregierung?: Array<number>,
            behoerde?: Array<number>,
            gemeinde?: Array<number>,
            options?: any
        ): AxiosPromise<StandardauswertungExportRequestJsonldExportRequest> {
            return localVarFp
                .apiStandardauswertungCsvGet(bezirksregierung, behoerde, gemeinde, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Standardauswertung resource.
         * @summary Creates a Standardauswertung resource.
         * @param {StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite} standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite The new Standardauswertung resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStandardauswertungPost(
            standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite: StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
            options?: any
        ): AxiosPromise<StandardauswertungStandardauswertungJsonForm> {
            return localVarFp
                .apiStandardauswertungPost(
                    standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * StandardauswertungApi - object-oriented interface
 * @export
 * @class StandardauswertungApi
 * @extends {BaseAPI}
 */
export class StandardauswertungApi extends BaseAPI {
    /**
     * Retrieves a Standardauswertung resource.
     * @summary Retrieves a Standardauswertung resource.
     * @param {Array<number>} [bezirksregierung] Regierungsbezirke
     * @param {Array<number>} [behoerde] Behörden
     * @param {Array<number>} [gemeinde] Gemeinden
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardauswertungApi
     */
    public apiStandardauswertungCsvGet(
        bezirksregierung?: Array<number>,
        behoerde?: Array<number>,
        gemeinde?: Array<number>,
        options?: AxiosRequestConfig
    ) {
        return StandardauswertungApiFp(this.configuration)
            .apiStandardauswertungCsvGet(bezirksregierung, behoerde, gemeinde, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Standardauswertung resource.
     * @summary Creates a Standardauswertung resource.
     * @param {StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite} standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite The new Standardauswertung resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StandardauswertungApi
     */
    public apiStandardauswertungPost(
        standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite: StandardauswertungStandardauswertungFilterJsonldStandardauswertungWrite,
        options?: AxiosRequestConfig
    ) {
        return StandardauswertungApiFp(this.configuration)
            .apiStandardauswertungPost(standardauswertungStandardauswertungFilterJsonldStandardauswertungWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TableFilterApi - axios parameter creator
 * @export
 */
export const TableFilterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of TableFilter resources.
         * @summary Retrieves the collection of TableFilter resources.
         * @param {string} entityType TableFilter identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFiltersEntityTypeGetCollection: async (
            entityType: string,
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('apiFiltersEntityTypeGetCollection', 'entityType', entityType);
            const localVarPath = `/api/filters/{entityType}`.replace(
                `{${'entityType'}}`,
                encodeURIComponent(String(entityType))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TableFilterApi - functional programming interface
 * @export
 */
export const TableFilterApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TableFilterApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of TableFilter resources.
         * @summary Retrieves the collection of TableFilter resources.
         * @param {string} entityType TableFilter identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFiltersEntityTypeGetCollection(
            entityType: string,
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFiltersEntityTypeGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFiltersEntityTypeGetCollection(
                entityType,
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TableFilterApi - factory interface
 * @export
 */
export const TableFilterApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TableFilterApiFp(configuration);
    return {
        /**
         * Retrieves the collection of TableFilter resources.
         * @summary Retrieves the collection of TableFilter resources.
         * @param {string} entityType TableFilter identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFiltersEntityTypeGetCollection(
            entityType: string,
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<ApiFiltersEntityTypeGetCollection200Response> {
            return localVarFp
                .apiFiltersEntityTypeGetCollection(entityType, page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * TableFilterApi - object-oriented interface
 * @export
 * @class TableFilterApi
 * @extends {BaseAPI}
 */
export class TableFilterApi extends BaseAPI {
    /**
     * Retrieves the collection of TableFilter resources.
     * @summary Retrieves the collection of TableFilter resources.
     * @param {string} entityType TableFilter identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableFilterApi
     */
    public apiFiltersEntityTypeGetCollection(
        entityType: string,
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return TableFilterApiFp(this.configuration)
            .apiFiltersEntityTypeGetCollection(entityType, page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate new JWT Token
         * @summary Get JWT token to login.
         * @param {Credentials} [credentials]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem: async (
            credentials?: Credentials,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration);
    return {
        /**
         * Generate new JWT Token
         * @summary Get JWT token to login.
         * @param {Credentials} [credentials]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCredentialsItem(
            credentials?: Credentials,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCredentialsItem(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration);
    return {
        /**
         * Generate new JWT Token
         * @summary Get JWT token to login.
         * @param {Credentials} [credentials]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem(credentials?: Credentials, options?: any): AxiosPromise<Token> {
            return localVarFp.postCredentialsItem(credentials, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * Generate new JWT Token
     * @summary Get JWT token to login.
     * @param {Credentials} [credentials]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public postCredentialsItem(credentials?: Credentials, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration)
            .postCredentialsItem(credentials, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TokenRefreshApi - axios parameter creator
 * @export
 */
export const TokenRefreshApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate fresh JWT Token based on refresh token
         * @summary Get fresh JWT token to login.
         * @param {TokenRefresh} [tokenRefresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenItem: async (
            tokenRefresh?: TokenRefresh,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/token-refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TokenRefreshApi - functional programming interface
 * @export
 */
export const TokenRefreshApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenRefreshApiAxiosParamCreator(configuration);
    return {
        /**
         * Generate fresh JWT Token based on refresh token
         * @summary Get fresh JWT token to login.
         * @param {TokenRefresh} [tokenRefresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRefreshTokenItem(
            tokenRefresh?: TokenRefresh,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRefreshTokenItem(tokenRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TokenRefreshApi - factory interface
 * @export
 */
export const TokenRefreshApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TokenRefreshApiFp(configuration);
    return {
        /**
         * Generate fresh JWT Token based on refresh token
         * @summary Get fresh JWT token to login.
         * @param {TokenRefresh} [tokenRefresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenItem(tokenRefresh?: TokenRefresh, options?: any): AxiosPromise<Token> {
            return localVarFp.postRefreshTokenItem(tokenRefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenRefreshApi - object-oriented interface
 * @export
 * @class TokenRefreshApi
 * @extends {BaseAPI}
 */
export class TokenRefreshApi extends BaseAPI {
    /**
     * Generate fresh JWT Token based on refresh token
     * @summary Get fresh JWT token to login.
     * @param {TokenRefresh} [tokenRefresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenRefreshApi
     */
    public postRefreshTokenItem(tokenRefresh?: TokenRefresh, options?: AxiosRequestConfig) {
        return TokenRefreshApiFp(this.configuration)
            .postRefreshTokenItem(tokenRefresh, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TraegerApi - axios parameter creator
 * @export
 */
export const TraegerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {string} [qSearch]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [type]
         * @param {Array<string>} [type2]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderType]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerCollectionexportCsvGetCollection: async (
            qSearch?: string,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            type?: string,
            type2?: Array<string>,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderType?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/traegerCollection/exportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (landschaftsverband !== undefined) {
                localVarQueryParameter['landschaftsverband'] = landschaftsverband;
            }

            if (landschaftsverband2) {
                localVarQueryParameter['landschaftsverband[]'] = landschaftsverband2;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['order[type]'] = orderType;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderModifiedAt !== undefined) {
                localVarQueryParameter['order[modifiedAt]'] = orderModifiedAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [type]
         * @param {Array<string>} [type2]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderType]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            type?: string,
            type2?: Array<string>,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderType?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/traeger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (landschaftsverband !== undefined) {
                localVarQueryParameter['landschaftsverband'] = landschaftsverband;
            }

            if (landschaftsverband2) {
                localVarQueryParameter['landschaftsverband[]'] = landschaftsverband2;
            }

            if (behoerde !== undefined) {
                localVarQueryParameter['behoerde'] = behoerde;
            }

            if (behoerde2) {
                localVarQueryParameter['behoerde[]'] = behoerde2;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderNrwKey !== undefined) {
                localVarQueryParameter['order[nrwKey]'] = orderNrwKey;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['order[type]'] = orderType;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['order[status]'] = orderStatus;
            }

            if (orderAdressePlz !== undefined) {
                localVarQueryParameter['order[adresse.plz]'] = orderAdressePlz;
            }

            if (orderAdresseOrt !== undefined) {
                localVarQueryParameter['order[adresse.ort]'] = orderAdresseOrt;
            }

            if (orderModifiedAt !== undefined) {
                localVarQueryParameter['order[modifiedAt]'] = orderModifiedAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Traeger identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerIdGet', 'id', id);
            const localVarPath = `/api/traeger/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Traeger resource.
         * @summary Updates the Traeger resource.
         * @param {string} id Traeger identifier
         * @param {TraegerAntragWorkflowActionAntragWorkflow} traegerAntragWorkflowActionAntragWorkflow The updated Traeger resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerIdworkflowPatch: async (
            id: string,
            traegerAntragWorkflowActionAntragWorkflow: TraegerAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerIdworkflowPatch', 'id', id);
            // verify required parameter 'traegerAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiTraegerIdworkflowPatch',
                'traegerAntragWorkflowActionAntragWorkflow',
                traegerAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/traeger/{id}/workflow`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                traegerAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} uuid Traeger identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerUuidexportGet: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('apiTraegerUuidexportGet', 'uuid', uuid);
            const localVarPath = `/api/traeger/{uuid}/export`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TraegerApi - functional programming interface
 * @export
 */
export const TraegerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TraegerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {string} [qSearch]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [type]
         * @param {Array<string>} [type2]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderType]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerCollectionexportCsvGetCollection(
            qSearch?: string,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            type?: string,
            type2?: Array<string>,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderType?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiTraegerCollectionexportCsvGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerCollectionexportCsvGetCollection(
                qSearch,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                status,
                status2,
                type,
                type2,
                existsDeleted,
                orderName,
                orderNrwKey,
                orderType,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderModifiedAt,
                orderDeleted,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [type]
         * @param {Array<string>} [type2]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderType]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            type?: string,
            type2?: Array<string>,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderType?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTraegerGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerGetCollection(
                page,
                itemsPerPage,
                qSearch,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                status,
                status2,
                type,
                type2,
                existsDeleted,
                orderName,
                orderNrwKey,
                orderType,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderModifiedAt,
                orderDeleted,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Traeger identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerJsonldTraegerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Traeger resource.
         * @summary Updates the Traeger resource.
         * @param {string} id Traeger identifier
         * @param {TraegerAntragWorkflowActionAntragWorkflow} traegerAntragWorkflowActionAntragWorkflow The updated Traeger resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerIdworkflowPatch(
            id: string,
            traegerAntragWorkflowActionAntragWorkflow: TraegerAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerJsonldTraegerRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerIdworkflowPatch(
                id,
                traegerAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} uuid Traeger identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerUuidexportGet(
            uuid: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerPfadInvestTraegerOutputJsonldInvestRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerUuidexportGet(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TraegerApi - factory interface
 * @export
 */
export const TraegerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TraegerApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {string} [qSearch]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [type]
         * @param {Array<string>} [type2]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderType]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerCollectionexportCsvGetCollection(
            qSearch?: string,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            type?: string,
            type2?: Array<string>,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderType?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiTraegerCollectionexportCsvGetCollection200Response> {
            return localVarFp
                .apiTraegerCollectionexportCsvGetCollection(
                    qSearch,
                    landschaftsverband,
                    landschaftsverband2,
                    behoerde,
                    behoerde2,
                    status,
                    status2,
                    type,
                    type2,
                    existsDeleted,
                    orderName,
                    orderNrwKey,
                    orderType,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderModifiedAt,
                    orderDeleted,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Traeger resources.
         * @summary Retrieves the collection of Traeger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {string} [landschaftsverband]
         * @param {Array<string>} [landschaftsverband2]
         * @param {string} [behoerde]
         * @param {Array<string>} [behoerde2]
         * @param {string} [status]
         * @param {Array<string>} [status2]
         * @param {string} [type]
         * @param {Array<string>} [type2]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderNrwKey]
         * @param {'asc' | 'desc'} [orderType]
         * @param {'asc' | 'desc'} [orderStatus]
         * @param {'asc' | 'desc'} [orderAdressePlz]
         * @param {'asc' | 'desc'} [orderAdresseOrt]
         * @param {'asc' | 'desc'} [orderModifiedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            landschaftsverband?: string,
            landschaftsverband2?: Array<string>,
            behoerde?: string,
            behoerde2?: Array<string>,
            status?: string,
            status2?: Array<string>,
            type?: string,
            type2?: Array<string>,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderNrwKey?: 'asc' | 'desc',
            orderType?: 'asc' | 'desc',
            orderStatus?: 'asc' | 'desc',
            orderAdressePlz?: 'asc' | 'desc',
            orderAdresseOrt?: 'asc' | 'desc',
            orderModifiedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiTraegerGetCollection200Response> {
            return localVarFp
                .apiTraegerGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    landschaftsverband,
                    landschaftsverband2,
                    behoerde,
                    behoerde2,
                    status,
                    status2,
                    type,
                    type2,
                    existsDeleted,
                    orderName,
                    orderNrwKey,
                    orderType,
                    orderStatus,
                    orderAdressePlz,
                    orderAdresseOrt,
                    orderModifiedAt,
                    orderDeleted,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} id Traeger identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerIdGet(id: string, options?: any): AxiosPromise<TraegerJsonldTraegerRead> {
            return localVarFp.apiTraegerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Traeger resource.
         * @summary Updates the Traeger resource.
         * @param {string} id Traeger identifier
         * @param {TraegerAntragWorkflowActionAntragWorkflow} traegerAntragWorkflowActionAntragWorkflow The updated Traeger resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerIdworkflowPatch(
            id: string,
            traegerAntragWorkflowActionAntragWorkflow: TraegerAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<TraegerJsonldTraegerRead> {
            return localVarFp
                .apiTraegerIdworkflowPatch(id, traegerAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traeger resource.
         * @summary Retrieves a Traeger resource.
         * @param {string} uuid Traeger identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerUuidexportGet(
            uuid: string,
            options?: any
        ): AxiosPromise<TraegerPfadInvestTraegerOutputJsonldInvestRead> {
            return localVarFp.apiTraegerUuidexportGet(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraegerApi - object-oriented interface
 * @export
 * @class TraegerApi
 * @extends {BaseAPI}
 */
export class TraegerApi extends BaseAPI {
    /**
     * Retrieves the collection of Traeger resources.
     * @summary Retrieves the collection of Traeger resources.
     * @param {string} [qSearch]
     * @param {string} [landschaftsverband]
     * @param {Array<string>} [landschaftsverband2]
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [type]
     * @param {Array<string>} [type2]
     * @param {boolean} [existsDeleted]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderType]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderModifiedAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public apiTraegerCollectionexportCsvGetCollection(
        qSearch?: string,
        landschaftsverband?: string,
        landschaftsverband2?: Array<string>,
        behoerde?: string,
        behoerde2?: Array<string>,
        status?: string,
        status2?: Array<string>,
        type?: string,
        type2?: Array<string>,
        existsDeleted?: boolean,
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderType?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderModifiedAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return TraegerApiFp(this.configuration)
            .apiTraegerCollectionexportCsvGetCollection(
                qSearch,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                status,
                status2,
                type,
                type2,
                existsDeleted,
                orderName,
                orderNrwKey,
                orderType,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderModifiedAt,
                orderDeleted,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Traeger resources.
     * @summary Retrieves the collection of Traeger resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {string} [landschaftsverband]
     * @param {Array<string>} [landschaftsverband2]
     * @param {string} [behoerde]
     * @param {Array<string>} [behoerde2]
     * @param {string} [status]
     * @param {Array<string>} [status2]
     * @param {string} [type]
     * @param {Array<string>} [type2]
     * @param {boolean} [existsDeleted]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderNrwKey]
     * @param {'asc' | 'desc'} [orderType]
     * @param {'asc' | 'desc'} [orderStatus]
     * @param {'asc' | 'desc'} [orderAdressePlz]
     * @param {'asc' | 'desc'} [orderAdresseOrt]
     * @param {'asc' | 'desc'} [orderModifiedAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public apiTraegerGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        landschaftsverband?: string,
        landschaftsverband2?: Array<string>,
        behoerde?: string,
        behoerde2?: Array<string>,
        status?: string,
        status2?: Array<string>,
        type?: string,
        type2?: Array<string>,
        existsDeleted?: boolean,
        orderName?: 'asc' | 'desc',
        orderNrwKey?: 'asc' | 'desc',
        orderType?: 'asc' | 'desc',
        orderStatus?: 'asc' | 'desc',
        orderAdressePlz?: 'asc' | 'desc',
        orderAdresseOrt?: 'asc' | 'desc',
        orderModifiedAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return TraegerApiFp(this.configuration)
            .apiTraegerGetCollection(
                page,
                itemsPerPage,
                qSearch,
                landschaftsverband,
                landschaftsverband2,
                behoerde,
                behoerde2,
                status,
                status2,
                type,
                type2,
                existsDeleted,
                orderName,
                orderNrwKey,
                orderType,
                orderStatus,
                orderAdressePlz,
                orderAdresseOrt,
                orderModifiedAt,
                orderDeleted,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traeger resource.
     * @summary Retrieves a Traeger resource.
     * @param {string} id Traeger identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public apiTraegerIdGet(id: string, options?: AxiosRequestConfig) {
        return TraegerApiFp(this.configuration)
            .apiTraegerIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Traeger resource.
     * @summary Updates the Traeger resource.
     * @param {string} id Traeger identifier
     * @param {TraegerAntragWorkflowActionAntragWorkflow} traegerAntragWorkflowActionAntragWorkflow The updated Traeger resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public apiTraegerIdworkflowPatch(
        id: string,
        traegerAntragWorkflowActionAntragWorkflow: TraegerAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return TraegerApiFp(this.configuration)
            .apiTraegerIdworkflowPatch(id, traegerAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traeger resource.
     * @summary Retrieves a Traeger resource.
     * @param {string} uuid Traeger identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerApi
     */
    public apiTraegerUuidexportGet(uuid: string, options?: AxiosRequestConfig) {
        return TraegerApiFp(this.configuration)
            .apiTraegerUuidexportGet(uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TraegerDtoApi - axios parameter creator
 * @export
 */
export const TraegerDtoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a TraegerDto resource.
         * @summary Retrieves a TraegerDto resource.
         * @param {string} id TraegerDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerDtoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerDtoIdGet', 'id', id);
            const localVarPath = `/api/traeger-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the TraegerDto resource.
         * @summary Updates the TraegerDto resource.
         * @param {string} id TraegerDto identifier
         * @param {TraegerDtoTraegerWriteRolleEinrichtung} traegerDtoTraegerWriteRolleEinrichtung The updated TraegerDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerDtoIdPatch: async (
            id: string,
            traegerDtoTraegerWriteRolleEinrichtung: TraegerDtoTraegerWriteRolleEinrichtung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerDtoIdPatch', 'id', id);
            // verify required parameter 'traegerDtoTraegerWriteRolleEinrichtung' is not null or undefined
            assertParamExists(
                'apiTraegerDtoIdPatch',
                'traegerDtoTraegerWriteRolleEinrichtung',
                traegerDtoTraegerWriteRolleEinrichtung
            );
            const localVarPath = `/api/traeger-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                traegerDtoTraegerWriteRolleEinrichtung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a TraegerDto resource.
         * @summary Creates a TraegerDto resource.
         * @param {TraegerDtoJsonldTraegerCreate} traegerDtoJsonldTraegerCreate The new TraegerDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerDtoPost: async (
            traegerDtoJsonldTraegerCreate: TraegerDtoJsonldTraegerCreate,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'traegerDtoJsonldTraegerCreate' is not null or undefined
            assertParamExists('apiTraegerDtoPost', 'traegerDtoJsonldTraegerCreate', traegerDtoJsonldTraegerCreate);
            const localVarPath = `/api/traeger-dto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                traegerDtoJsonldTraegerCreate,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TraegerDtoApi - functional programming interface
 * @export
 */
export const TraegerDtoApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TraegerDtoApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a TraegerDto resource.
         * @summary Retrieves a TraegerDto resource.
         * @param {string} id TraegerDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerDtoIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerDtoJsonldTraegerReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerDtoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the TraegerDto resource.
         * @summary Updates the TraegerDto resource.
         * @param {string} id TraegerDto identifier
         * @param {TraegerDtoTraegerWriteRolleEinrichtung} traegerDtoTraegerWriteRolleEinrichtung The updated TraegerDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerDtoIdPatch(
            id: string,
            traegerDtoTraegerWriteRolleEinrichtung: TraegerDtoTraegerWriteRolleEinrichtung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerDtoJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerDtoIdPatch(
                id,
                traegerDtoTraegerWriteRolleEinrichtung,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a TraegerDto resource.
         * @summary Creates a TraegerDto resource.
         * @param {TraegerDtoJsonldTraegerCreate} traegerDtoJsonldTraegerCreate The new TraegerDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerDtoPost(
            traegerDtoJsonldTraegerCreate: TraegerDtoJsonldTraegerCreate,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerDtoJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerDtoPost(
                traegerDtoJsonldTraegerCreate,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TraegerDtoApi - factory interface
 * @export
 */
export const TraegerDtoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TraegerDtoApiFp(configuration);
    return {
        /**
         * Retrieves a TraegerDto resource.
         * @summary Retrieves a TraegerDto resource.
         * @param {string} id TraegerDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerDtoIdGet(id: string, options?: any): AxiosPromise<TraegerDtoJsonldTraegerReadRolleEinrichtung> {
            return localVarFp.apiTraegerDtoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the TraegerDto resource.
         * @summary Updates the TraegerDto resource.
         * @param {string} id TraegerDto identifier
         * @param {TraegerDtoTraegerWriteRolleEinrichtung} traegerDtoTraegerWriteRolleEinrichtung The updated TraegerDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerDtoIdPatch(
            id: string,
            traegerDtoTraegerWriteRolleEinrichtung: TraegerDtoTraegerWriteRolleEinrichtung,
            options?: any
        ): AxiosPromise<TraegerDtoJsonld> {
            return localVarFp
                .apiTraegerDtoIdPatch(id, traegerDtoTraegerWriteRolleEinrichtung, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a TraegerDto resource.
         * @summary Creates a TraegerDto resource.
         * @param {TraegerDtoJsonldTraegerCreate} traegerDtoJsonldTraegerCreate The new TraegerDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerDtoPost(
            traegerDtoJsonldTraegerCreate: TraegerDtoJsonldTraegerCreate,
            options?: any
        ): AxiosPromise<TraegerDtoJsonld> {
            return localVarFp
                .apiTraegerDtoPost(traegerDtoJsonldTraegerCreate, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraegerDtoApi - object-oriented interface
 * @export
 * @class TraegerDtoApi
 * @extends {BaseAPI}
 */
export class TraegerDtoApi extends BaseAPI {
    /**
     * Retrieves a TraegerDto resource.
     * @summary Retrieves a TraegerDto resource.
     * @param {string} id TraegerDto identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerDtoApi
     */
    public apiTraegerDtoIdGet(id: string, options?: AxiosRequestConfig) {
        return TraegerDtoApiFp(this.configuration)
            .apiTraegerDtoIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the TraegerDto resource.
     * @summary Updates the TraegerDto resource.
     * @param {string} id TraegerDto identifier
     * @param {TraegerDtoTraegerWriteRolleEinrichtung} traegerDtoTraegerWriteRolleEinrichtung The updated TraegerDto resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerDtoApi
     */
    public apiTraegerDtoIdPatch(
        id: string,
        traegerDtoTraegerWriteRolleEinrichtung: TraegerDtoTraegerWriteRolleEinrichtung,
        options?: AxiosRequestConfig
    ) {
        return TraegerDtoApiFp(this.configuration)
            .apiTraegerDtoIdPatch(id, traegerDtoTraegerWriteRolleEinrichtung, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a TraegerDto resource.
     * @summary Creates a TraegerDto resource.
     * @param {TraegerDtoJsonldTraegerCreate} traegerDtoJsonldTraegerCreate The new TraegerDto resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerDtoApi
     */
    public apiTraegerDtoPost(
        traegerDtoJsonldTraegerCreate: TraegerDtoJsonldTraegerCreate,
        options?: AxiosRequestConfig
    ) {
        return TraegerDtoApiFp(this.configuration)
            .apiTraegerDtoPost(traegerDtoJsonldTraegerCreate, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TraegerverbandApi - axios parameter creator
 * @export
 */
export const TraegerverbandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Liste aller Benutzer eines Trägerverbandes
         * @param {string} benutzerId Benutzer identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerBenutzerIdtraegerverbandGetCollection: async (
            benutzerId: string,
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzerId' is not null or undefined
            assertParamExists('apiBenutzerBenutzerIdtraegerverbandGetCollection', 'benutzerId', benutzerId);
            const localVarPath = `/api/benutzer/{benutzerId}/traegerverband`.replace(
                `{${'benutzerId'}}`,
                encodeURIComponent(String(benutzerId))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderAdresseEmail !== undefined) {
                localVarQueryParameter['order[adresse.email]'] = orderAdresseEmail;
            }

            if (orderAdresseName !== undefined) {
                localVarQueryParameter['order[adresse.name]'] = orderAdresseName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Retrieves the collection of Traegerverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/traegerverband`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (id2) {
                localVarQueryParameter['id[]'] = id2;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderAdresseEmail !== undefined) {
                localVarQueryParameter['order[adresse.email]'] = orderAdresseEmail;
            }

            if (orderAdresseName !== undefined) {
                localVarQueryParameter['order[adresse.name]'] = orderAdresseName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Traegerverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerverbandIdGet', 'id', id);
            const localVarPath = `/api/traegerverband/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Traegerverband resource.
         * @summary Creates a Traegerverband resource.
         * @param {TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung} traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung The new Traegerverband resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandPost: async (
            traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung' is not null or undefined
            assertParamExists(
                'apiTraegerverbandPost',
                'traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung',
                traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung
            );
            const localVarPath = `/api/traegerverband`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} uuid Traegerverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandUuidexportGet: async (
            uuid: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('apiTraegerverbandUuidexportGet', 'uuid', uuid);
            const localVarPath = `/api/traegerverband/{uuid}/export`.replace(
                `{${'uuid'}}`,
                encodeURIComponent(String(uuid))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TraegerverbandApi - functional programming interface
 * @export
 */
export const TraegerverbandApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TraegerverbandApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Liste aller Benutzer eines Trägerverbandes
         * @param {string} benutzerId Benutzer identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBenutzerBenutzerIdtraegerverbandGetCollection(
            benutzerId: string,
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBenutzerBenutzerIdtraegerverbandGetCollection(
                benutzerId,
                page,
                itemsPerPage,
                id,
                id2,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Retrieves the collection of Traegerverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerverbandGetCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTraegerverbandGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerverbandGetCollection(
                page,
                itemsPerPage,
                id,
                id2,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Traegerverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerverbandIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<TraegerverbandJsonldTraegerverbandReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerverbandIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Traegerverband resource.
         * @summary Creates a Traegerverband resource.
         * @param {TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung} traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung The new Traegerverband resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerverbandPost(
            traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerverbandJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerverbandPost(
                traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} uuid Traegerverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerverbandUuidexportGet(
            uuid: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerverbandUuidexportGet(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TraegerverbandApi - factory interface
 * @export
 */
export const TraegerverbandApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TraegerverbandApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Liste aller Benutzer eines Trägerverbandes
         * @param {string} benutzerId Benutzer identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBenutzerBenutzerIdtraegerverbandGetCollection(
            benutzerId: string,
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiBenutzerBenutzerIdtraegerverbandGetCollection200Response> {
            return localVarFp
                .apiBenutzerBenutzerIdtraegerverbandGetCollection(
                    benutzerId,
                    page,
                    itemsPerPage,
                    id,
                    id2,
                    qSearch,
                    orderName,
                    orderAdresseEmail,
                    orderAdresseName,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Traegerverband resources.
         * @summary Retrieves the collection of Traegerverband resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [id]
         * @param {Array<number>} [id2]
         * @param {string} [qSearch]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderAdresseEmail]
         * @param {'asc' | 'desc'} [orderAdresseName]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandGetCollection(
            page?: number,
            itemsPerPage?: number,
            id?: number,
            id2?: Array<number>,
            qSearch?: string,
            orderName?: 'asc' | 'desc',
            orderAdresseEmail?: 'asc' | 'desc',
            orderAdresseName?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiTraegerverbandGetCollection200Response> {
            return localVarFp
                .apiTraegerverbandGetCollection(
                    page,
                    itemsPerPage,
                    id,
                    id2,
                    qSearch,
                    orderName,
                    orderAdresseEmail,
                    orderAdresseName,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} id Traegerverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandIdGet(
            id: string,
            options?: any
        ): AxiosPromise<TraegerverbandJsonldTraegerverbandReadRolleEinrichtung> {
            return localVarFp.apiTraegerverbandIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Traegerverband resource.
         * @summary Creates a Traegerverband resource.
         * @param {TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung} traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung The new Traegerverband resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandPost(
            traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
            options?: any
        ): AxiosPromise<TraegerverbandJsonld> {
            return localVarFp
                .apiTraegerverbandPost(
                    traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Traegerverband resource.
         * @summary Retrieves a Traegerverband resource.
         * @param {string} uuid Traegerverband identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandUuidexportGet(
            uuid: string,
            options?: any
        ): AxiosPromise<TraegerverbandPfadInvestTraegerverbandOutputJsonldInvestRead> {
            return localVarFp.apiTraegerverbandUuidexportGet(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraegerverbandApi - object-oriented interface
 * @export
 * @class TraegerverbandApi
 * @extends {BaseAPI}
 */
export class TraegerverbandApi extends BaseAPI {
    /**
     * Retrieves the collection of Traegerverband resources.
     * @summary Liste aller Benutzer eines Trägerverbandes
     * @param {string} benutzerId Benutzer identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderAdresseEmail]
     * @param {'asc' | 'desc'} [orderAdresseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public apiBenutzerBenutzerIdtraegerverbandGetCollection(
        benutzerId: string,
        page?: number,
        itemsPerPage?: number,
        id?: number,
        id2?: Array<number>,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderAdresseEmail?: 'asc' | 'desc',
        orderAdresseName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return TraegerverbandApiFp(this.configuration)
            .apiBenutzerBenutzerIdtraegerverbandGetCollection(
                benutzerId,
                page,
                itemsPerPage,
                id,
                id2,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Traegerverband resources.
     * @summary Retrieves the collection of Traegerverband resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [id]
     * @param {Array<number>} [id2]
     * @param {string} [qSearch]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderAdresseEmail]
     * @param {'asc' | 'desc'} [orderAdresseName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public apiTraegerverbandGetCollection(
        page?: number,
        itemsPerPage?: number,
        id?: number,
        id2?: Array<number>,
        qSearch?: string,
        orderName?: 'asc' | 'desc',
        orderAdresseEmail?: 'asc' | 'desc',
        orderAdresseName?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return TraegerverbandApiFp(this.configuration)
            .apiTraegerverbandGetCollection(
                page,
                itemsPerPage,
                id,
                id2,
                qSearch,
                orderName,
                orderAdresseEmail,
                orderAdresseName,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traegerverband resource.
     * @summary Retrieves a Traegerverband resource.
     * @param {string} id Traegerverband identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public apiTraegerverbandIdGet(id: string, options?: AxiosRequestConfig) {
        return TraegerverbandApiFp(this.configuration)
            .apiTraegerverbandIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Traegerverband resource.
     * @summary Creates a Traegerverband resource.
     * @param {TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung} traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung The new Traegerverband resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public apiTraegerverbandPost(
        traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung: TraegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung,
        options?: AxiosRequestConfig
    ) {
        return TraegerverbandApiFp(this.configuration)
            .apiTraegerverbandPost(traegerverbandTraegerverbandDtoJsonldTraegerverbandWriteRolleEinrichtung, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Traegerverband resource.
     * @summary Retrieves a Traegerverband resource.
     * @param {string} uuid Traegerverband identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandApi
     */
    public apiTraegerverbandUuidexportGet(uuid: string, options?: AxiosRequestConfig) {
        return TraegerverbandApiFp(this.configuration)
            .apiTraegerverbandUuidexportGet(uuid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * TraegerverbandDtoApi - axios parameter creator
 * @export
 */
export const TraegerverbandDtoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a TraegerverbandDto resource.
         * @summary Retrieves a TraegerverbandDto resource.
         * @param {string} id TraegerverbandDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandDtoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerverbandDtoIdGet', 'id', id);
            const localVarPath = `/api/traegerverband-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the TraegerverbandDto resource.
         * @summary Updates the TraegerverbandDto resource.
         * @param {string} id TraegerverbandDto identifier
         * @param {TraegerverbandDtoTraegerverbandWriteRolleEinrichtung} traegerverbandDtoTraegerverbandWriteRolleEinrichtung The updated TraegerverbandDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandDtoIdPatch: async (
            id: string,
            traegerverbandDtoTraegerverbandWriteRolleEinrichtung: TraegerverbandDtoTraegerverbandWriteRolleEinrichtung,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTraegerverbandDtoIdPatch', 'id', id);
            // verify required parameter 'traegerverbandDtoTraegerverbandWriteRolleEinrichtung' is not null or undefined
            assertParamExists(
                'apiTraegerverbandDtoIdPatch',
                'traegerverbandDtoTraegerverbandWriteRolleEinrichtung',
                traegerverbandDtoTraegerverbandWriteRolleEinrichtung
            );
            const localVarPath = `/api/traegerverband-dto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                traegerverbandDtoTraegerverbandWriteRolleEinrichtung,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TraegerverbandDtoApi - functional programming interface
 * @export
 */
export const TraegerverbandDtoApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TraegerverbandDtoApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a TraegerverbandDto resource.
         * @summary Retrieves a TraegerverbandDto resource.
         * @param {string} id TraegerverbandDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerverbandDtoIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerverbandDtoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the TraegerverbandDto resource.
         * @summary Updates the TraegerverbandDto resource.
         * @param {string} id TraegerverbandDto identifier
         * @param {TraegerverbandDtoTraegerverbandWriteRolleEinrichtung} traegerverbandDtoTraegerverbandWriteRolleEinrichtung The updated TraegerverbandDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTraegerverbandDtoIdPatch(
            id: string,
            traegerverbandDtoTraegerverbandWriteRolleEinrichtung: TraegerverbandDtoTraegerverbandWriteRolleEinrichtung,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraegerverbandDtoJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTraegerverbandDtoIdPatch(
                id,
                traegerverbandDtoTraegerverbandWriteRolleEinrichtung,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TraegerverbandDtoApi - factory interface
 * @export
 */
export const TraegerverbandDtoApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TraegerverbandDtoApiFp(configuration);
    return {
        /**
         * Retrieves a TraegerverbandDto resource.
         * @summary Retrieves a TraegerverbandDto resource.
         * @param {string} id TraegerverbandDto identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandDtoIdGet(
            id: string,
            options?: any
        ): AxiosPromise<TraegerverbandDtoJsonldTraegerverbandReadRolleEinrichtung> {
            return localVarFp.apiTraegerverbandDtoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the TraegerverbandDto resource.
         * @summary Updates the TraegerverbandDto resource.
         * @param {string} id TraegerverbandDto identifier
         * @param {TraegerverbandDtoTraegerverbandWriteRolleEinrichtung} traegerverbandDtoTraegerverbandWriteRolleEinrichtung The updated TraegerverbandDto resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTraegerverbandDtoIdPatch(
            id: string,
            traegerverbandDtoTraegerverbandWriteRolleEinrichtung: TraegerverbandDtoTraegerverbandWriteRolleEinrichtung,
            options?: any
        ): AxiosPromise<TraegerverbandDtoJsonld> {
            return localVarFp
                .apiTraegerverbandDtoIdPatch(id, traegerverbandDtoTraegerverbandWriteRolleEinrichtung, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * TraegerverbandDtoApi - object-oriented interface
 * @export
 * @class TraegerverbandDtoApi
 * @extends {BaseAPI}
 */
export class TraegerverbandDtoApi extends BaseAPI {
    /**
     * Retrieves a TraegerverbandDto resource.
     * @summary Retrieves a TraegerverbandDto resource.
     * @param {string} id TraegerverbandDto identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandDtoApi
     */
    public apiTraegerverbandDtoIdGet(id: string, options?: AxiosRequestConfig) {
        return TraegerverbandDtoApiFp(this.configuration)
            .apiTraegerverbandDtoIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the TraegerverbandDto resource.
     * @summary Updates the TraegerverbandDto resource.
     * @param {string} id TraegerverbandDto identifier
     * @param {TraegerverbandDtoTraegerverbandWriteRolleEinrichtung} traegerverbandDtoTraegerverbandWriteRolleEinrichtung The updated TraegerverbandDto resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TraegerverbandDtoApi
     */
    public apiTraegerverbandDtoIdPatch(
        id: string,
        traegerverbandDtoTraegerverbandWriteRolleEinrichtung: TraegerverbandDtoTraegerverbandWriteRolleEinrichtung,
        options?: AxiosRequestConfig
    ) {
        return TraegerverbandDtoApiFp(this.configuration)
            .apiTraegerverbandDtoIdPatch(id, traegerverbandDtoTraegerverbandWriteRolleEinrichtung, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * UnternehmensgruppeApi - axios parameter creator
 * @export
 */
export const UnternehmensgruppeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Unternehmensgruppe resources.
         * @summary Retrieves the collection of Unternehmensgruppe resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {boolean} [approved]
         * @param {Array<boolean>} [approved2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnternehmensgruppeGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            name?: string,
            approved?: boolean,
            approved2?: Array<boolean>,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/unternehmensgruppe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (approved !== undefined) {
                localVarQueryParameter['approved'] = approved;
            }

            if (approved2) {
                localVarQueryParameter['approved[]'] = approved2;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UnternehmensgruppeApi - functional programming interface
 * @export
 */
export const UnternehmensgruppeApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UnternehmensgruppeApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Unternehmensgruppe resources.
         * @summary Retrieves the collection of Unternehmensgruppe resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {boolean} [approved]
         * @param {Array<boolean>} [approved2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUnternehmensgruppeGetCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            approved?: boolean,
            approved2?: Array<boolean>,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUnternehmensgruppeGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUnternehmensgruppeGetCollection(
                page,
                itemsPerPage,
                name,
                approved,
                approved2,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * UnternehmensgruppeApi - factory interface
 * @export
 */
export const UnternehmensgruppeApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = UnternehmensgruppeApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Unternehmensgruppe resources.
         * @summary Retrieves the collection of Unternehmensgruppe resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [name]
         * @param {boolean} [approved]
         * @param {Array<boolean>} [approved2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUnternehmensgruppeGetCollection(
            page?: number,
            itemsPerPage?: number,
            name?: string,
            approved?: boolean,
            approved2?: Array<boolean>,
            options?: any
        ): AxiosPromise<ApiUnternehmensgruppeGetCollection200Response> {
            return localVarFp
                .apiUnternehmensgruppeGetCollection(page, itemsPerPage, name, approved, approved2, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnternehmensgruppeApi - object-oriented interface
 * @export
 * @class UnternehmensgruppeApi
 * @extends {BaseAPI}
 */
export class UnternehmensgruppeApi extends BaseAPI {
    /**
     * Retrieves the collection of Unternehmensgruppe resources.
     * @summary Retrieves the collection of Unternehmensgruppe resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [name]
     * @param {boolean} [approved]
     * @param {Array<boolean>} [approved2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnternehmensgruppeApi
     */
    public apiUnternehmensgruppeGetCollection(
        page?: number,
        itemsPerPage?: number,
        name?: string,
        approved?: boolean,
        approved2?: Array<boolean>,
        options?: AxiosRequestConfig
    ) {
        return UnternehmensgruppeApiFp(this.configuration)
            .apiUnternehmensgruppeGetCollection(page, itemsPerPage, name, approved, approved2, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Upload resource.
         * @summary Retrieves a Upload resource.
         * @param {string} id Upload identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUploadIdGet', 'id', id);
            const localVarPath = `/api/upload/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Upload resource.
         * @summary Creates a Upload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadPost: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Upload resource.
         * @summary Retrieves a Upload resource.
         * @param {string} id Upload identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUploadIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadJsonldUploadRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUploadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Upload resource.
         * @summary Creates a Upload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUploadPost(
            file?: any,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadJsonldUploadRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUploadPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration);
    return {
        /**
         * Retrieves a Upload resource.
         * @summary Retrieves a Upload resource.
         * @param {string} id Upload identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadIdGet(id: string, options?: any): AxiosPromise<UploadJsonldUploadRead> {
            return localVarFp.apiUploadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Upload resource.
         * @summary Creates a Upload resource.
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadPost(file?: any, options?: any): AxiosPromise<UploadJsonldUploadRead> {
            return localVarFp.apiUploadPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * Retrieves a Upload resource.
     * @summary Retrieves a Upload resource.
     * @param {string} id Upload identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public apiUploadIdGet(id: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration)
            .apiUploadIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Upload resource.
     * @summary Creates a Upload resource.
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public apiUploadPost(file?: any, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration)
            .apiUploadPost(file, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * VerifizierungsMailApi - axios parameter creator
 * @export
 */
export const VerifizierungsMailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a VerifizierungsMail resource.
         * @summary Retrieves a VerifizierungsMail resource.
         * @param {string} hash VerifizierungsMail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailVerifizierenHashGet: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiEmailVerifizierenHashGet', 'hash', hash);
            const localVarPath = `/api/email-verifizieren/{hash}`.replace(
                `{${'hash'}}`,
                encodeURIComponent(String(hash))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a VerifizierungsMail resource.
         * @summary Creates a VerifizierungsMail resource.
         * @param {VerifizierungsMailJsonldVerifizierungsmailWrite} verifizierungsMailJsonldVerifizierungsmailWrite The new VerifizierungsMail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResendVerificationEmailPost: async (
            verifizierungsMailJsonldVerifizierungsmailWrite: VerifizierungsMailJsonldVerifizierungsmailWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'verifizierungsMailJsonldVerifizierungsmailWrite' is not null or undefined
            assertParamExists(
                'apiResendVerificationEmailPost',
                'verifizierungsMailJsonldVerifizierungsmailWrite',
                verifizierungsMailJsonldVerifizierungsmailWrite
            );
            const localVarPath = `/api/resend-verification-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                verifizierungsMailJsonldVerifizierungsmailWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VerifizierungsMailApi - functional programming interface
 * @export
 */
export const VerifizierungsMailApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = VerifizierungsMailApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a VerifizierungsMail resource.
         * @summary Retrieves a VerifizierungsMail resource.
         * @param {string} hash VerifizierungsMail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailVerifizierenHashGet(
            hash: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifizierungsMailJsonldVerifizierungsmailWrite>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailVerifizierenHashGet(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a VerifizierungsMail resource.
         * @summary Creates a VerifizierungsMail resource.
         * @param {VerifizierungsMailJsonldVerifizierungsmailWrite} verifizierungsMailJsonldVerifizierungsmailWrite The new VerifizierungsMail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiResendVerificationEmailPost(
            verifizierungsMailJsonldVerifizierungsmailWrite: VerifizierungsMailJsonldVerifizierungsmailWrite,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifizierungsMailJsonldVerifizierungsmailWrite>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiResendVerificationEmailPost(
                verifizierungsMailJsonldVerifizierungsmailWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * VerifizierungsMailApi - factory interface
 * @export
 */
export const VerifizierungsMailApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = VerifizierungsMailApiFp(configuration);
    return {
        /**
         * Retrieves a VerifizierungsMail resource.
         * @summary Retrieves a VerifizierungsMail resource.
         * @param {string} hash VerifizierungsMail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailVerifizierenHashGet(
            hash: string,
            options?: any
        ): AxiosPromise<VerifizierungsMailJsonldVerifizierungsmailWrite> {
            return localVarFp.apiEmailVerifizierenHashGet(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a VerifizierungsMail resource.
         * @summary Creates a VerifizierungsMail resource.
         * @param {VerifizierungsMailJsonldVerifizierungsmailWrite} verifizierungsMailJsonldVerifizierungsmailWrite The new VerifizierungsMail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResendVerificationEmailPost(
            verifizierungsMailJsonldVerifizierungsmailWrite: VerifizierungsMailJsonldVerifizierungsmailWrite,
            options?: any
        ): AxiosPromise<VerifizierungsMailJsonldVerifizierungsmailWrite> {
            return localVarFp
                .apiResendVerificationEmailPost(verifizierungsMailJsonldVerifizierungsmailWrite, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerifizierungsMailApi - object-oriented interface
 * @export
 * @class VerifizierungsMailApi
 * @extends {BaseAPI}
 */
export class VerifizierungsMailApi extends BaseAPI {
    /**
     * Retrieves a VerifizierungsMail resource.
     * @summary Retrieves a VerifizierungsMail resource.
     * @param {string} hash VerifizierungsMail identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifizierungsMailApi
     */
    public apiEmailVerifizierenHashGet(hash: string, options?: AxiosRequestConfig) {
        return VerifizierungsMailApiFp(this.configuration)
            .apiEmailVerifizierenHashGet(hash, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a VerifizierungsMail resource.
     * @summary Creates a VerifizierungsMail resource.
     * @param {VerifizierungsMailJsonldVerifizierungsmailWrite} verifizierungsMailJsonldVerifizierungsmailWrite The new VerifizierungsMail resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifizierungsMailApi
     */
    public apiResendVerificationEmailPost(
        verifizierungsMailJsonldVerifizierungsmailWrite: VerifizierungsMailJsonldVerifizierungsmailWrite,
        options?: AxiosRequestConfig
    ) {
        return VerifizierungsMailApiFp(this.configuration)
            .apiResendVerificationEmailPost(verifizierungsMailJsonldVerifizierungsmailWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * WohngemeinschaftApi - axios parameter creator
 * @export
 */
export const WohngemeinschaftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of Wohngemeinschaft resources.
         * @summary Retrieves the collection of Wohngemeinschaft resources.
         * @param {string} [qSearch]
         * @param {boolean} [existsEinrichtung]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
         * @param {'asc' | 'desc'} [orderAdresseStrasse]
         * @param {'asc' | 'desc'} [orderPlaetze]
         * @param {'asc' | 'desc'} [orderBeginn]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftCsvGetCollection: async (
            qSearch?: string,
            existsEinrichtung?: boolean,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderAmbulanterDienstName?: 'asc' | 'desc',
            orderAdresseStrasse?: 'asc' | 'desc',
            orderPlaetze?: 'asc' | 'desc',
            orderBeginn?: 'asc' | 'desc',
            orderCreatedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/wohngemeinschaftCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (existsEinrichtung !== undefined) {
                localVarQueryParameter['exists[einrichtung]'] = existsEinrichtung;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderAmbulanterDienstName !== undefined) {
                localVarQueryParameter['order[ambulanterDienst.name]'] = orderAmbulanterDienstName;
            }

            if (orderAdresseStrasse !== undefined) {
                localVarQueryParameter['order[adresse.strasse]'] = orderAdresseStrasse;
            }

            if (orderPlaetze !== undefined) {
                localVarQueryParameter['order[plaetze]'] = orderPlaetze;
            }

            if (orderBeginn !== undefined) {
                localVarQueryParameter['order[beginn]'] = orderBeginn;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Wohngemeinschaft resources.
         * @summary Retrieves the collection of Wohngemeinschaft resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {boolean} [existsEinrichtung]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
         * @param {'asc' | 'desc'} [orderAdresseStrasse]
         * @param {'asc' | 'desc'} [orderPlaetze]
         * @param {'asc' | 'desc'} [orderBeginn]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftGetCollection: async (
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            existsEinrichtung?: boolean,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderAmbulanterDienstName?: 'asc' | 'desc',
            orderAdresseStrasse?: 'asc' | 'desc',
            orderPlaetze?: 'asc' | 'desc',
            orderBeginn?: 'asc' | 'desc',
            orderCreatedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/wohngemeinschaft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (qSearch !== undefined) {
                localVarQueryParameter['qSearch'] = qSearch;
            }

            if (existsEinrichtung !== undefined) {
                localVarQueryParameter['exists[einrichtung]'] = existsEinrichtung;
            }

            if (existsDeleted !== undefined) {
                localVarQueryParameter['exists[deleted]'] = existsDeleted;
            }

            if (orderName !== undefined) {
                localVarQueryParameter['order[name]'] = orderName;
            }

            if (orderEinrichtungName !== undefined) {
                localVarQueryParameter['order[einrichtung.name]'] = orderEinrichtungName;
            }

            if (orderAmbulanterDienstName !== undefined) {
                localVarQueryParameter['order[ambulanterDienst.name]'] = orderAmbulanterDienstName;
            }

            if (orderAdresseStrasse !== undefined) {
                localVarQueryParameter['order[adresse.strasse]'] = orderAdresseStrasse;
            }

            if (orderPlaetze !== undefined) {
                localVarQueryParameter['order[plaetze]'] = orderPlaetze;
            }

            if (orderBeginn !== undefined) {
                localVarQueryParameter['order[beginn]'] = orderBeginn;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            if (orderDeleted !== undefined) {
                localVarQueryParameter['order[deleted]'] = orderDeleted;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Wohngemeinschaft resource.
         * @summary Retrieves a Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWohngemeinschaftIdGet', 'id', id);
            const localVarPath = `/api/wohngemeinschaft/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Wohngemeinschaft resource.
         * @summary Updates the Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {WohngemeinschaftWohngemeinschaftWriteAdresseWrite} wohngemeinschaftWohngemeinschaftWriteAdresseWrite The updated Wohngemeinschaft resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftIdPatch: async (
            id: string,
            wohngemeinschaftWohngemeinschaftWriteAdresseWrite: WohngemeinschaftWohngemeinschaftWriteAdresseWrite,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWohngemeinschaftIdPatch', 'id', id);
            // verify required parameter 'wohngemeinschaftWohngemeinschaftWriteAdresseWrite' is not null or undefined
            assertParamExists(
                'apiWohngemeinschaftIdPatch',
                'wohngemeinschaftWohngemeinschaftWriteAdresseWrite',
                wohngemeinschaftWohngemeinschaftWriteAdresseWrite
            );
            const localVarPath = `/api/wohngemeinschaft/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                wohngemeinschaftWohngemeinschaftWriteAdresseWrite,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Wohngemeinschaft resource.
         * @summary Updates the Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {WohngemeinschaftAntragWorkflowActionAntragWorkflow} wohngemeinschaftAntragWorkflowActionAntragWorkflow The updated Wohngemeinschaft resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftIdworkflowPatch: async (
            id: string,
            wohngemeinschaftAntragWorkflowActionAntragWorkflow: WohngemeinschaftAntragWorkflowActionAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWohngemeinschaftIdworkflowPatch', 'id', id);
            // verify required parameter 'wohngemeinschaftAntragWorkflowActionAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiWohngemeinschaftIdworkflowPatch',
                'wohngemeinschaftAntragWorkflowActionAntragWorkflow',
                wohngemeinschaftAntragWorkflowActionAntragWorkflow
            );
            const localVarPath = `/api/wohngemeinschaft/{id}/workflow`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                wohngemeinschaftAntragWorkflowActionAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * WohngemeinschaftApi - functional programming interface
 * @export
 */
export const WohngemeinschaftApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = WohngemeinschaftApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves the collection of Wohngemeinschaft resources.
         * @summary Retrieves the collection of Wohngemeinschaft resources.
         * @param {string} [qSearch]
         * @param {boolean} [existsEinrichtung]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
         * @param {'asc' | 'desc'} [orderAdresseStrasse]
         * @param {'asc' | 'desc'} [orderPlaetze]
         * @param {'asc' | 'desc'} [orderBeginn]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWohngemeinschaftCsvGetCollection(
            qSearch?: string,
            existsEinrichtung?: boolean,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderAmbulanterDienstName?: 'asc' | 'desc',
            orderAdresseStrasse?: 'asc' | 'desc',
            orderPlaetze?: 'asc' | 'desc',
            orderBeginn?: 'asc' | 'desc',
            orderCreatedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiWohngemeinschaftCsvGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWohngemeinschaftCsvGetCollection(
                qSearch,
                existsEinrichtung,
                existsDeleted,
                orderName,
                orderEinrichtungName,
                orderAmbulanterDienstName,
                orderAdresseStrasse,
                orderPlaetze,
                orderBeginn,
                orderCreatedAt,
                orderDeleted,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Wohngemeinschaft resources.
         * @summary Retrieves the collection of Wohngemeinschaft resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {boolean} [existsEinrichtung]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
         * @param {'asc' | 'desc'} [orderAdresseStrasse]
         * @param {'asc' | 'desc'} [orderPlaetze]
         * @param {'asc' | 'desc'} [orderBeginn]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWohngemeinschaftGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            existsEinrichtung?: boolean,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderAmbulanterDienstName?: 'asc' | 'desc',
            orderAdresseStrasse?: 'asc' | 'desc',
            orderPlaetze?: 'asc' | 'desc',
            orderBeginn?: 'asc' | 'desc',
            orderCreatedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiWohngemeinschaftGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWohngemeinschaftGetCollection(
                page,
                itemsPerPage,
                qSearch,
                existsEinrichtung,
                existsDeleted,
                orderName,
                orderEinrichtungName,
                orderAmbulanterDienstName,
                orderAdresseStrasse,
                orderPlaetze,
                orderBeginn,
                orderCreatedAt,
                orderDeleted,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Wohngemeinschaft resource.
         * @summary Retrieves a Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWohngemeinschaftIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WohngemeinschaftJsonldWohngemeinschaftRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWohngemeinschaftIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Wohngemeinschaft resource.
         * @summary Updates the Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {WohngemeinschaftWohngemeinschaftWriteAdresseWrite} wohngemeinschaftWohngemeinschaftWriteAdresseWrite The updated Wohngemeinschaft resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWohngemeinschaftIdPatch(
            id: string,
            wohngemeinschaftWohngemeinschaftWriteAdresseWrite: WohngemeinschaftWohngemeinschaftWriteAdresseWrite,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WohngemeinschaftJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWohngemeinschaftIdPatch(
                id,
                wohngemeinschaftWohngemeinschaftWriteAdresseWrite,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Wohngemeinschaft resource.
         * @summary Updates the Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {WohngemeinschaftAntragWorkflowActionAntragWorkflow} wohngemeinschaftAntragWorkflowActionAntragWorkflow The updated Wohngemeinschaft resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWohngemeinschaftIdworkflowPatch(
            id: string,
            wohngemeinschaftAntragWorkflowActionAntragWorkflow: WohngemeinschaftAntragWorkflowActionAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WohngemeinschaftJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWohngemeinschaftIdworkflowPatch(
                id,
                wohngemeinschaftAntragWorkflowActionAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * WohngemeinschaftApi - factory interface
 * @export
 */
export const WohngemeinschaftApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = WohngemeinschaftApiFp(configuration);
    return {
        /**
         * Retrieves the collection of Wohngemeinschaft resources.
         * @summary Retrieves the collection of Wohngemeinschaft resources.
         * @param {string} [qSearch]
         * @param {boolean} [existsEinrichtung]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
         * @param {'asc' | 'desc'} [orderAdresseStrasse]
         * @param {'asc' | 'desc'} [orderPlaetze]
         * @param {'asc' | 'desc'} [orderBeginn]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftCsvGetCollection(
            qSearch?: string,
            existsEinrichtung?: boolean,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderAmbulanterDienstName?: 'asc' | 'desc',
            orderAdresseStrasse?: 'asc' | 'desc',
            orderPlaetze?: 'asc' | 'desc',
            orderBeginn?: 'asc' | 'desc',
            orderCreatedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiWohngemeinschaftCsvGetCollection200Response> {
            return localVarFp
                .apiWohngemeinschaftCsvGetCollection(
                    qSearch,
                    existsEinrichtung,
                    existsDeleted,
                    orderName,
                    orderEinrichtungName,
                    orderAmbulanterDienstName,
                    orderAdresseStrasse,
                    orderPlaetze,
                    orderBeginn,
                    orderCreatedAt,
                    orderDeleted,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Wohngemeinschaft resources.
         * @summary Retrieves the collection of Wohngemeinschaft resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [qSearch]
         * @param {boolean} [existsEinrichtung]
         * @param {boolean} [existsDeleted]
         * @param {'asc' | 'desc'} [orderName]
         * @param {'asc' | 'desc'} [orderEinrichtungName]
         * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
         * @param {'asc' | 'desc'} [orderAdresseStrasse]
         * @param {'asc' | 'desc'} [orderPlaetze]
         * @param {'asc' | 'desc'} [orderBeginn]
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftGetCollection(
            page?: number,
            itemsPerPage?: number,
            qSearch?: string,
            existsEinrichtung?: boolean,
            existsDeleted?: boolean,
            orderName?: 'asc' | 'desc',
            orderEinrichtungName?: 'asc' | 'desc',
            orderAmbulanterDienstName?: 'asc' | 'desc',
            orderAdresseStrasse?: 'asc' | 'desc',
            orderPlaetze?: 'asc' | 'desc',
            orderBeginn?: 'asc' | 'desc',
            orderCreatedAt?: 'asc' | 'desc',
            orderDeleted?: 'asc' | 'desc',
            options?: any
        ): AxiosPromise<ApiWohngemeinschaftGetCollection200Response> {
            return localVarFp
                .apiWohngemeinschaftGetCollection(
                    page,
                    itemsPerPage,
                    qSearch,
                    existsEinrichtung,
                    existsDeleted,
                    orderName,
                    orderEinrichtungName,
                    orderAmbulanterDienstName,
                    orderAdresseStrasse,
                    orderPlaetze,
                    orderBeginn,
                    orderCreatedAt,
                    orderDeleted,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Wohngemeinschaft resource.
         * @summary Retrieves a Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftIdGet(id: string, options?: any): AxiosPromise<WohngemeinschaftJsonldWohngemeinschaftRead> {
            return localVarFp.apiWohngemeinschaftIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Wohngemeinschaft resource.
         * @summary Updates the Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {WohngemeinschaftWohngemeinschaftWriteAdresseWrite} wohngemeinschaftWohngemeinschaftWriteAdresseWrite The updated Wohngemeinschaft resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftIdPatch(
            id: string,
            wohngemeinschaftWohngemeinschaftWriteAdresseWrite: WohngemeinschaftWohngemeinschaftWriteAdresseWrite,
            options?: any
        ): AxiosPromise<WohngemeinschaftJsonld> {
            return localVarFp
                .apiWohngemeinschaftIdPatch(id, wohngemeinschaftWohngemeinschaftWriteAdresseWrite, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Updates the Wohngemeinschaft resource.
         * @summary Updates the Wohngemeinschaft resource.
         * @param {string} id Wohngemeinschaft identifier
         * @param {WohngemeinschaftAntragWorkflowActionAntragWorkflow} wohngemeinschaftAntragWorkflowActionAntragWorkflow The updated Wohngemeinschaft resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWohngemeinschaftIdworkflowPatch(
            id: string,
            wohngemeinschaftAntragWorkflowActionAntragWorkflow: WohngemeinschaftAntragWorkflowActionAntragWorkflow,
            options?: any
        ): AxiosPromise<WohngemeinschaftJsonld> {
            return localVarFp
                .apiWohngemeinschaftIdworkflowPatch(id, wohngemeinschaftAntragWorkflowActionAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * WohngemeinschaftApi - object-oriented interface
 * @export
 * @class WohngemeinschaftApi
 * @extends {BaseAPI}
 */
export class WohngemeinschaftApi extends BaseAPI {
    /**
     * Retrieves the collection of Wohngemeinschaft resources.
     * @summary Retrieves the collection of Wohngemeinschaft resources.
     * @param {string} [qSearch]
     * @param {boolean} [existsEinrichtung]
     * @param {boolean} [existsDeleted]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
     * @param {'asc' | 'desc'} [orderAdresseStrasse]
     * @param {'asc' | 'desc'} [orderPlaetze]
     * @param {'asc' | 'desc'} [orderBeginn]
     * @param {'asc' | 'desc'} [orderCreatedAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WohngemeinschaftApi
     */
    public apiWohngemeinschaftCsvGetCollection(
        qSearch?: string,
        existsEinrichtung?: boolean,
        existsDeleted?: boolean,
        orderName?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderAmbulanterDienstName?: 'asc' | 'desc',
        orderAdresseStrasse?: 'asc' | 'desc',
        orderPlaetze?: 'asc' | 'desc',
        orderBeginn?: 'asc' | 'desc',
        orderCreatedAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return WohngemeinschaftApiFp(this.configuration)
            .apiWohngemeinschaftCsvGetCollection(
                qSearch,
                existsEinrichtung,
                existsDeleted,
                orderName,
                orderEinrichtungName,
                orderAmbulanterDienstName,
                orderAdresseStrasse,
                orderPlaetze,
                orderBeginn,
                orderCreatedAt,
                orderDeleted,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Wohngemeinschaft resources.
     * @summary Retrieves the collection of Wohngemeinschaft resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [qSearch]
     * @param {boolean} [existsEinrichtung]
     * @param {boolean} [existsDeleted]
     * @param {'asc' | 'desc'} [orderName]
     * @param {'asc' | 'desc'} [orderEinrichtungName]
     * @param {'asc' | 'desc'} [orderAmbulanterDienstName]
     * @param {'asc' | 'desc'} [orderAdresseStrasse]
     * @param {'asc' | 'desc'} [orderPlaetze]
     * @param {'asc' | 'desc'} [orderBeginn]
     * @param {'asc' | 'desc'} [orderCreatedAt]
     * @param {'asc' | 'desc'} [orderDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WohngemeinschaftApi
     */
    public apiWohngemeinschaftGetCollection(
        page?: number,
        itemsPerPage?: number,
        qSearch?: string,
        existsEinrichtung?: boolean,
        existsDeleted?: boolean,
        orderName?: 'asc' | 'desc',
        orderEinrichtungName?: 'asc' | 'desc',
        orderAmbulanterDienstName?: 'asc' | 'desc',
        orderAdresseStrasse?: 'asc' | 'desc',
        orderPlaetze?: 'asc' | 'desc',
        orderBeginn?: 'asc' | 'desc',
        orderCreatedAt?: 'asc' | 'desc',
        orderDeleted?: 'asc' | 'desc',
        options?: AxiosRequestConfig
    ) {
        return WohngemeinschaftApiFp(this.configuration)
            .apiWohngemeinschaftGetCollection(
                page,
                itemsPerPage,
                qSearch,
                existsEinrichtung,
                existsDeleted,
                orderName,
                orderEinrichtungName,
                orderAmbulanterDienstName,
                orderAdresseStrasse,
                orderPlaetze,
                orderBeginn,
                orderCreatedAt,
                orderDeleted,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Wohngemeinschaft resource.
     * @summary Retrieves a Wohngemeinschaft resource.
     * @param {string} id Wohngemeinschaft identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WohngemeinschaftApi
     */
    public apiWohngemeinschaftIdGet(id: string, options?: AxiosRequestConfig) {
        return WohngemeinschaftApiFp(this.configuration)
            .apiWohngemeinschaftIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Wohngemeinschaft resource.
     * @summary Updates the Wohngemeinschaft resource.
     * @param {string} id Wohngemeinschaft identifier
     * @param {WohngemeinschaftWohngemeinschaftWriteAdresseWrite} wohngemeinschaftWohngemeinschaftWriteAdresseWrite The updated Wohngemeinschaft resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WohngemeinschaftApi
     */
    public apiWohngemeinschaftIdPatch(
        id: string,
        wohngemeinschaftWohngemeinschaftWriteAdresseWrite: WohngemeinschaftWohngemeinschaftWriteAdresseWrite,
        options?: AxiosRequestConfig
    ) {
        return WohngemeinschaftApiFp(this.configuration)
            .apiWohngemeinschaftIdPatch(id, wohngemeinschaftWohngemeinschaftWriteAdresseWrite, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Wohngemeinschaft resource.
     * @summary Updates the Wohngemeinschaft resource.
     * @param {string} id Wohngemeinschaft identifier
     * @param {WohngemeinschaftAntragWorkflowActionAntragWorkflow} wohngemeinschaftAntragWorkflowActionAntragWorkflow The updated Wohngemeinschaft resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WohngemeinschaftApi
     */
    public apiWohngemeinschaftIdworkflowPatch(
        id: string,
        wohngemeinschaftAntragWorkflowActionAntragWorkflow: WohngemeinschaftAntragWorkflowActionAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return WohngemeinschaftApiFp(this.configuration)
            .apiWohngemeinschaftIdworkflowPatch(id, wohngemeinschaftAntragWorkflowActionAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ZugehoerigeEinrichtungenDTOApi - axios parameter creator
 * @export
 */
export const ZugehoerigeEinrichtungenDTOApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Liste der zugehörigen Einrichtungen
         * @summary Liste der zugehörigen Einrichtungen
         * @param {number} benutzer Benutzer-ID
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugehoerigeEinrichtungenDTOGetCollection: async (
            benutzer: number,
            page?: number,
            itemsPerPage?: number,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'benutzer' is not null or undefined
            assertParamExists('apiZugehoerigeEinrichtungenDTOGetCollection', 'benutzer', benutzer);
            const localVarPath = `/api/zugehoerige-einrichtungen-d-t-o`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            if (benutzer !== undefined) {
                localVarQueryParameter['benutzer'] = benutzer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ZugehoerigeEinrichtungenDTOApi - functional programming interface
 * @export
 */
export const ZugehoerigeEinrichtungenDTOApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ZugehoerigeEinrichtungenDTOApiAxiosParamCreator(configuration);
    return {
        /**
         * Liste der zugehörigen Einrichtungen
         * @summary Liste der zugehörigen Einrichtungen
         * @param {number} benutzer Benutzer-ID
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZugehoerigeEinrichtungenDTOGetCollection(
            benutzer: number,
            page?: number,
            itemsPerPage?: number,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ApiZugehoerigeEinrichtungenDTOGetCollection200Response>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZugehoerigeEinrichtungenDTOGetCollection(
                benutzer,
                page,
                itemsPerPage,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ZugehoerigeEinrichtungenDTOApi - factory interface
 * @export
 */
export const ZugehoerigeEinrichtungenDTOApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = ZugehoerigeEinrichtungenDTOApiFp(configuration);
    return {
        /**
         * Liste der zugehörigen Einrichtungen
         * @summary Liste der zugehörigen Einrichtungen
         * @param {number} benutzer Benutzer-ID
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugehoerigeEinrichtungenDTOGetCollection(
            benutzer: number,
            page?: number,
            itemsPerPage?: number,
            options?: any
        ): AxiosPromise<ApiZugehoerigeEinrichtungenDTOGetCollection200Response> {
            return localVarFp
                .apiZugehoerigeEinrichtungenDTOGetCollection(benutzer, page, itemsPerPage, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZugehoerigeEinrichtungenDTOApi - object-oriented interface
 * @export
 * @class ZugehoerigeEinrichtungenDTOApi
 * @extends {BaseAPI}
 */
export class ZugehoerigeEinrichtungenDTOApi extends BaseAPI {
    /**
     * Liste der zugehörigen Einrichtungen
     * @summary Liste der zugehörigen Einrichtungen
     * @param {number} benutzer Benutzer-ID
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZugehoerigeEinrichtungenDTOApi
     */
    public apiZugehoerigeEinrichtungenDTOGetCollection(
        benutzer: number,
        page?: number,
        itemsPerPage?: number,
        options?: AxiosRequestConfig
    ) {
        return ZugehoerigeEinrichtungenDTOApiFp(this.configuration)
            .apiZugehoerigeEinrichtungenDTOGetCollection(benutzer, page, itemsPerPage, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * ZugriffsrechteApi - axios parameter creator
 * @export
 */
export const ZugriffsrechteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Zugriffsrechte resource.
         * @summary Retrieves a Zugriffsrechte resource.
         * @param {string} id Zugriffsrechte identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugriffsrechteIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiZugriffsrechteIdGet', 'id', id);
            const localVarPath = `/api/zugriffsrechte/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Zugriffsrechte resource.
         * @summary Creates a Zugriffsrechte resource.
         * @param {ZugriffsrechteJsonldZugriffsrechtePost} zugriffsrechteJsonldZugriffsrechtePost The new Zugriffsrechte resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugriffsrechtePost: async (
            zugriffsrechteJsonldZugriffsrechtePost: ZugriffsrechteJsonldZugriffsrechtePost,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'zugriffsrechteJsonldZugriffsrechtePost' is not null or undefined
            assertParamExists(
                'apiZugriffsrechtePost',
                'zugriffsrechteJsonldZugriffsrechtePost',
                zugriffsrechteJsonldZugriffsrechtePost
            );
            const localVarPath = `/api/zugriffsrechte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                zugriffsrechteJsonldZugriffsrechtePost,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Zugriffsrechte resource.
         * @summary Creates a Zugriffsrechte resource.
         * @param {ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow} zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow The new Zugriffsrechte resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugriffsrechteWorkflowPost: async (
            zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow: ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow' is not null or undefined
            assertParamExists(
                'apiZugriffsrechteWorkflowPost',
                'zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow',
                zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow
            );
            const localVarPath = `/api/zugriffsrechteWorkflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey required
            await setApiKeyToObject(localVarHeaderParameter, 'X-Authorization', configuration);

            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ZugriffsrechteApi - functional programming interface
 * @export
 */
export const ZugriffsrechteApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ZugriffsrechteApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves a Zugriffsrechte resource.
         * @summary Retrieves a Zugriffsrechte resource.
         * @param {string} id Zugriffsrechte identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZugriffsrechteIdGet(
            id: string,
            options?: AxiosRequestConfig
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string
            ) => AxiosPromise<ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZugriffsrechteIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Zugriffsrechte resource.
         * @summary Creates a Zugriffsrechte resource.
         * @param {ZugriffsrechteJsonldZugriffsrechtePost} zugriffsrechteJsonldZugriffsrechtePost The new Zugriffsrechte resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZugriffsrechtePost(
            zugriffsrechteJsonldZugriffsrechtePost: ZugriffsrechteJsonldZugriffsrechtePost,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZugriffsrechteJsonldSimpleformJsonForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZugriffsrechtePost(
                zugriffsrechteJsonldZugriffsrechtePost,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Zugriffsrechte resource.
         * @summary Creates a Zugriffsrechte resource.
         * @param {ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow} zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow The new Zugriffsrechte resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiZugriffsrechteWorkflowPost(
            zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow: ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZugriffsrechteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiZugriffsrechteWorkflowPost(
                zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ZugriffsrechteApi - factory interface
 * @export
 */
export const ZugriffsrechteApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = ZugriffsrechteApiFp(configuration);
    return {
        /**
         * Retrieves a Zugriffsrechte resource.
         * @summary Retrieves a Zugriffsrechte resource.
         * @param {string} id Zugriffsrechte identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugriffsrechteIdGet(
            id: string,
            options?: any
        ): AxiosPromise<ZugriffsrechteZugriffsrechteJsonFormJsonldSimpleformJsonFormEinrichtungRead> {
            return localVarFp.apiZugriffsrechteIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Zugriffsrechte resource.
         * @summary Creates a Zugriffsrechte resource.
         * @param {ZugriffsrechteJsonldZugriffsrechtePost} zugriffsrechteJsonldZugriffsrechtePost The new Zugriffsrechte resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugriffsrechtePost(
            zugriffsrechteJsonldZugriffsrechtePost: ZugriffsrechteJsonldZugriffsrechtePost,
            options?: any
        ): AxiosPromise<ZugriffsrechteJsonldSimpleformJsonForm> {
            return localVarFp
                .apiZugriffsrechtePost(zugriffsrechteJsonldZugriffsrechtePost, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Creates a Zugriffsrechte resource.
         * @summary Creates a Zugriffsrechte resource.
         * @param {ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow} zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow The new Zugriffsrechte resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiZugriffsrechteWorkflowPost(
            zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow: ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow,
            options?: any
        ): AxiosPromise<ZugriffsrechteJsonld> {
            return localVarFp
                .apiZugriffsrechteWorkflowPost(zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZugriffsrechteApi - object-oriented interface
 * @export
 * @class ZugriffsrechteApi
 * @extends {BaseAPI}
 */
export class ZugriffsrechteApi extends BaseAPI {
    /**
     * Retrieves a Zugriffsrechte resource.
     * @summary Retrieves a Zugriffsrechte resource.
     * @param {string} id Zugriffsrechte identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZugriffsrechteApi
     */
    public apiZugriffsrechteIdGet(id: string, options?: AxiosRequestConfig) {
        return ZugriffsrechteApiFp(this.configuration)
            .apiZugriffsrechteIdGet(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Zugriffsrechte resource.
     * @summary Creates a Zugriffsrechte resource.
     * @param {ZugriffsrechteJsonldZugriffsrechtePost} zugriffsrechteJsonldZugriffsrechtePost The new Zugriffsrechte resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZugriffsrechteApi
     */
    public apiZugriffsrechtePost(
        zugriffsrechteJsonldZugriffsrechtePost: ZugriffsrechteJsonldZugriffsrechtePost,
        options?: AxiosRequestConfig
    ) {
        return ZugriffsrechteApiFp(this.configuration)
            .apiZugriffsrechtePost(zugriffsrechteJsonldZugriffsrechtePost, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Zugriffsrechte resource.
     * @summary Creates a Zugriffsrechte resource.
     * @param {ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow} zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow The new Zugriffsrechte resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZugriffsrechteApi
     */
    public apiZugriffsrechteWorkflowPost(
        zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow: ZugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow,
        options?: AxiosRequestConfig
    ) {
        return ZugriffsrechteApiFp(this.configuration)
            .apiZugriffsrechteWorkflowPost(zugriffsrechteZugriffsrechteWorkflowActionJsonldAntragWorkflow, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
