import { addSeconds } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { shared } from 'use-broadcast-ts';
import { create } from 'zustand';

import { authConfig, authLog, AuthState, JWTToken } from 'api/auth/AuthTypes';
import { Token } from 'api/types';

const createTimeout = () => addSeconds(Date.now(), authConfig.pageTimeout);

/**
 * The application-wide authentication state.
 * This state is synced across browser windows and tabs via a broadcast channel.
 */
export const useAuthState = create(
    shared<AuthState>(
        (set) => ({
            user: null,
            token: null,
            expiresAt: null,
            login: (token?: Token) => {
                authLog('[AuthState.Login]');
                const { exp, ...user }: JWTToken = jwtDecode(token?.token || '');
                set({ token, user, expiresAt: exp });
            },
            logout: (logoutType) => {
                authLog('[AuthState.Logout]');
                set({
                    token: null,
                    user: null,
                    expiresAt: null,
                    impersonator: null,
                    pageTimeout: null,
                    logoutType,
                });
            },

            logoutType: null,
            clearLogoutType: () => {
                authLog('[AuthState.ClearLogoutType]');
                set({ logoutType: null });
            },

            impersonator: null,
            impersonate: (impersonator) => {
                authLog('[AuthState.Impersonate]');
                set({ impersonator });
            },

            pageTimeout: null,
            resetPageTimeout: () => {
                const newTimeout = createTimeout();
                authLog('[AuthState.ResetPageTimeout]', newTimeout);
                set({ pageTimeout: newTimeout.getTime() });
            },
        }),
        {
            name: 'pfadwtg2-authentication-broadcast-channel',
        }
    )
);
