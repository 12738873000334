import React from 'react';
import { Typography } from '@mui/material';

import { ROLE_TYPES } from 'constants/roles';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungHinweisTextForm } from './components/MeldungHinweisTextForm';

const Index = () => {
    return (
        <ContentContainer title="Anforderungstext bearbeiten">
            <StyledContainer>
                <BaseGridContainer>
                    <Typography variant="h1">Anforderungstext bearbeiten</Typography>

                    <MeldungHinweisTextForm
                        submitLabel="Aktualisieren"
                        messages={{
                            success: 'Der Anforderungstext wurde aktualisiert.',
                            error: 'Aktualisieren des Anforderungstext fehlgeschlagen.',
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const MeldungHinweisTextEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT
);
