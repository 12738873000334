import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { emailAntragApi } from 'api/apiDictionaries';
import { backendApiService } from 'api/ApiService';
import { useApiMeldungItem } from 'api/hooks/useApiClient';
import { StepsType } from 'api/types';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ACTIONS } from 'constants/antragActions';
import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { AntragPage } from 'pages/AntragPage';
import { defaultTextValues } from 'utilities/hooks/textConfigs/textTypes';

const Index = () => {
    const { einrichtungId } = useParams();
    const { data, isLoading } = useApiMeldungItem(einrichtungId);

    const headline = useMemo(() => {
        return data?.data?.einrichtungName ?? '';
    }, [data]);

    return (
        <AppLoaderContainer isLoading={isLoading}>
            {data ? (
                <AntragPage
                    antragType={AntragType.EMAIL_ANTRAG}
                    pathBuilder={PathBuilder.home.aufgaben.freigeben.meldungen as PathBuilderProps}
                    tabConfig={[
                        {
                            value: StepsType.ZURUECKWEISEN,
                        },
                    ]}
                    workflowFormItem={backendApiService.workflowEmailAntrag.bind(backendApiService)}
                    api={emailAntragApi}
                    stepUiSchemaDir="meldung"
                    buttonOverrides={{
                        [ACTIONS.EMAIL_SEND]: {
                            label: 'Meldung zurückgeben',
                            color: 'error',
                        },
                    }}
                    headline={`Meldung zurückgeben: ${headline}`}
                    messages={{
                        [ACTIONS.EMAIL_SEND]: {
                            ...defaultTextValues,
                            submitSuccessText: 'Meldung wurde zurückgegeben',
                            errorPublish: 'Server-Fehler: Es ist ein unerwarteter Fehler aufgetreten',
                            hint: {
                                severity: 'error',
                                alertText: 'Meldung jetzt zurückgeben?',
                            },
                        },
                    }}
                    withServerErrors={false}
                />
            ) : null}
        </AppLoaderContainer>
    );
};

export const MeldungZurueckgeben = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_FREIGEBEN
);
