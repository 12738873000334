import { MutableRefObject, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material';

import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

const useSkipLinksRef = (ref: MutableRefObject<HTMLSpanElement | null>, pathname: string) => {
    useDebouncedEffect(
        () => {
            ref.current?.focus();
        },
        [pathname],
        50
    );
};

interface SkipLinksProps {
    isLoggedIn: boolean;
}

export const SkipLinks = ({ isLoggedIn }: SkipLinksProps) => {
    const ref = useRef<HTMLSpanElement>(null);
    const { pathname } = useLocation();

    useSkipLinksRef(ref, pathname);

    return (
        <>
            <span ref={ref} tabIndex={-1} />
            {isLoggedIn ? (
                <>
                    <StyledSkipLinks href="#Mainmenu">Zur Hauptnavigation</StyledSkipLinks>
                    <StyledSkipLinks href="#Metamenu">Zur Metanavigation</StyledSkipLinks>
                    <StyledSkipLinks href="#Inhalt">Zum Inhalt</StyledSkipLinks>
                </>
            ) : (
                <StyledSkipLinks href="#anmeldung">Zur Anmeldung</StyledSkipLinks>
            )}
        </>
    );
};

const StyledSkipLinks = styled('a')(
    ({ theme }) => `
    display: inline-block;
    background-color: ${theme.palette.primary.lighter};
    color: ${theme.palette.primary.darker};
    border-color: ${theme.palette.divider};
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    margin: -1px !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;

    &:focus,
    &:active {
        top: 20px;
        left: 20px;
        border: solid 1px !important;
        padding: 5px 10px !important;
        clip: auto !important;
        -webkit-clip-path: none !important;
        clip-path: none !important;
        height: auto !important;
        overflow: visible !important;
        width: auto !important;
        white-space: normal !important;
        z-index: 400;
    }
`
);
