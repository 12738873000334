import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useBenutzerItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { useBenutzerEinrichtungColumns } from 'components/DataTable/columns/useBenutzerEinrichtungColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useBenutzerEinrichtungenListData } from './useBenutzerEinrichtungenListData';

const Index = () => {
    const { benutzerId } = useParams();

    const { isLoading: loadBenutzer, data: benutzer } = useBenutzerItem(String(benutzerId));

    const getData = useBenutzerEinrichtungenListData(Number(benutzerId));

    const columns = useBenutzerEinrichtungColumns();

    const title = useMemo(() => {
        if (benutzer) {
            return `${benutzer.nachname}, ${benutzer.vorname}: Leistungsangebote`;
        }

        return 'Leistungsangebote';
    }, [benutzer]);

    return (
        <ContentContainer title={title}>
            <StyledContainer>
                <AppLoaderContainer isLoading={loadBenutzer}>
                    <PageHeading title={title} />

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        initialOptions={{ custom: { disableSearch: true } }}
                    />

                    <ButtonGroupGrid backLink={PathBuilder.home.verwaltung.benutzer} />
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const BenutzerEinrichtungenPage = withAuthorization(withSideBar(Index, menuVerwaltung), [
    ROLES.BEHOERDE,
    ROLES.SUPPORT,
]);
