import React from 'react';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { LabelsInterface } from 'constants/labels';

import { CheckboxesFilter } from './CheckboxesFilter';

export const useCheckboxFilterList = (labels: LabelsInterface): MUIDataTableColumnOptions => {
    return {
        customFilterListOptions: {
            render: (value) => value.map((v: string) => `${labels[v]}`),
        },
        filterOptions: {
            names: Object.keys(labels),
            display: (filterList, onChange, index, column, filterData) => {
                return (
                    <CheckboxesFilter
                        index={index}
                        column={column}
                        filterData={filterData[index]}
                        filterValue={filterList[index]}
                        filterLabels={labels}
                        onChange={onChange}
                    />
                );
            },
        },
        filterType: 'custom',
    };
};
