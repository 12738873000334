import { StepsType } from 'api/types';
import { useStepDescription } from 'forms/Meldung/descriptions/useStepDescription';
import { TabConfig } from 'layout/menu/TabNavigation';
import { withComponentProps } from 'layout/menu/withComponentProps';
import { TabComponentProps } from 'navigation/hooks/useTabsConfig';
import { Hinweise } from 'pages/Meldung/Hinweise';

export const useMeldungTabConfig: (step?: string, einrichtungArt?: string) => TabConfig<TabComponentProps>[] = (
    step,
    einrichtungArt
) => {
    const description = useStepDescription(step, einrichtungArt);

    return [
        {
            value: StepsType.HINWEISE,
            component: withComponentProps(Hinweise),
        },
        {
            value: StepsType.BASISDATEN,
            description,
        },
        {
            value: StepsType.KONZEPTINFORMATION,
            description,
        },
        {
            value: StepsType.STANDORTE,
            description,
        },
        {
            value: StepsType.BESCHAEFTIGTE,
            description,
        },
        {
            value: StepsType.LEISTUNGSERBRINGER,
            description,
        },
        {
            value: StepsType.VERTRAEGE,
            description,
        },
        {
            value: StepsType.DOKUMENTE,
            description,
        },
        {
            value: StepsType.ZUSAMMENFASSUNG,
            description,
        },
        {
            value: StepsType.ZURUECKWEISEN,
            description,
        },
    ];
};
