import React from 'react';

import { ROLES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangebotBearbeiten } from 'pages/Leistungsangebote/components/LeistungsangebotBearbeiten';

const Index = () => {
    return <LeistungsangebotBearbeiten pathBuilder={PathBuilder.home.verwaltung.leistungsangebote} />;
};

export const LeistungsangebotRegistrierenPage = withAuthorization(withSideBar(Index, menuVerwaltung), [ROLES.ANBIETER]);
