import React from 'react';
import { Grid } from '@mui/material';

import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';

export const LeistungsanbieterDetail = () => {
    return (
        <ContentContainer title="Leistungsnanbieter: ">
            <StyledContainer>
                <Grid container spacing={5} justifyContent="center">
                    <AppLoaderContainer isLoading={false}>Content goes here</AppLoaderContainer>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};
