import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { FormLabel, Grid, GridSize, styled, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { FormulaPopover } from 'forms/components/FormulaPopover';
import { RequiredFlag } from 'forms/components/RequiredFlag';
import { errorTextTranslations } from 'forms/hooks/useErrors';
import { GridLayoutConfig } from 'forms/types/UiSchemaTypes';
import { theme } from 'theme/theme';
import { getFieldNumber } from 'utilities/ScopeUtils';

interface RowControlProps {
    name: string;
    id?: string;
    label?: string;
    labelHeadline?: {
        variant?: 'h3' | 'h4' | 'h5';
    };
    labelVisuallyHidden?: boolean;
    errorText?: string;
    required?: boolean;
    formula?: string;
    multiline?: boolean;
    fullValueWidth?: boolean;
    controlOnly?: boolean;
    showFieldNumberLabels?: boolean;
    skipLabel?: boolean;
    gridLayout?: GridLayoutConfig;
    children?: ReactNode;
    hasChangedData?: boolean;
    labelForId?: string;
    rowAction?: ReactNode;
}

export const getErrorText = (errorText?: string): string => {
    const errorTextTrimmed = errorText?.trim();
    const tempErrorText = errorTextTrimmed && errorTextTranslations[errorTextTrimmed];
    return (tempErrorText ?? errorTextTrimmed) || '';
};

export const RowControl: React.FC<RowControlProps> = ({
    id,
    children,
    label,
    labelHeadline,
    labelVisuallyHidden = true,
    errorText,
    name,
    required,
    formula,
    multiline = false,
    controlOnly = false,
    fullValueWidth = false,
    showFieldNumberLabels,
    skipLabel,
    gridLayout,
    hasChangedData,
    labelForId,
    rowAction,
}) => {
    const gridCols = 12;
    const defaultGrid: GridLayoutConfig = {
        fieldnumber: 1,
        label: 3,
        input: 12,
    };

    const grid = { ...defaultGrid, ...gridLayout };
    const field = getFieldNumber(name);
    const errorMessage = useMemo(() => getErrorText(errorText), [errorText]);

    const gridWidth: GridSize | undefined = fullValueWidth
        ? ((gridCols - Number(grid.input) - (Number(grid.fieldnumber) ?? 0)) as GridSize)
        : grid.input;

    const actionGridWidth = gridCols - Number(grid.input) - Number(grid.fieldnumber) - Number(grid.label);

    if (controlOnly) {
        return <>{children}</>;
    }

    if (multiline) {
        const gridWidth: GridSize = fullValueWidth
            ? gridCols
            : ((Number(grid.input) + Number(grid.label) + (Number(grid.fieldnumber) ?? 0)) as GridSize);

        return (
            <>
                <Grid item md={gridWidth} data-cy="RowControl--multiline">
                    {children}
                </Grid>

                {errorMessage && (
                    <MultiLineErrorContainer item md={gridWidth} container>
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    </MultiLineErrorContainer>
                )}
            </>
        );
    }

    if (!label || skipLabel) {
        return (
            <Grid container spacing={1} data-cy="RowControl--no-label">
                <Grid item xs={gridCols}>
                    {children}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            id={id}
            container
            spacing={2}
            data-cy="RowControl"
            sx={{ marginBottom: theme.spacing(3) }}
            style={hasChangedData ? { backgroundColor: '#DAFCE7' } : {}}
        >
            {showFieldNumberLabels && (
                <Grid item xs={grid.fieldnumber}>
                    <FormLabel component="legend" data-cy={field || label}>
                        {field}
                        {formula && <FormulaPopover formula={formula} />}
                    </FormLabel>
                </Grid>
            )}

            {!labelVisuallyHidden && labelHeadline ? (
                <Grid item xs={12}>
                    <Typography variant={labelHeadline?.variant || 'h2'}>{label}</Typography>
                    {required && <RequiredFlag />}
                </Grid>
            ) : null}

            {!labelHeadline ? (
                <Grid item xs={grid.label} sx={labelVisuallyHidden ? visuallyHidden : {}}>
                    <Label htmlFor={labelForId} data-cy={label}>
                        <span dangerouslySetInnerHTML={{ __html: label as string }} />
                        {required && <RequiredFlag />}
                    </Label>
                </Grid>
            ) : null}

            <Grid item xs={gridWidth}>
                {children}
            </Grid>

            {Boolean(rowAction) && (
                <Grid item xs={actionGridWidth}>
                    {rowAction}
                </Grid>
            )}
        </Grid>
    );
};

const MultiLineErrorContainer = styled(Grid)(() => ({
    marginTop: 3,
    marginBottom: 10,
}));

const ErrorMessage = styled(Typography)(() => ({
    color: '#cc2222',
    fontSize: 12,
    marginLeft: 5,
    marginRight: 5,
})) as typeof Typography;

const Label = styled(FormLabel)(() => ({
    display: 'block',
    marginTop: '9px',

    '& span': {
        color: '#333',
        lineHeight: 1.4,
    },
}));
