import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder, PathBuilderProps } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { MeldungUebersicht } from './Components/MeldungUebersicht';
import { useMeldungAufgabenListData } from './useMeldungAufgabenListData';

const Index = () => {
    const pathBuilder = PathBuilder.home.aufgaben.freigeben.meldungen;

    const getData = useMeldungAufgabenListData({});

    return (
        <MeldungUebersicht
            title="Meldungen freigeben"
            headline="Meldungen freigeben"
            pathBuilder={pathBuilder}
            getData={getData}
            initialTableOptions={{
                sortOrder: {
                    name: 'letzteMeldung',
                    direction: 'asc',
                },
                custom: {
                    pathBuilder: pathBuilder as PathBuilderProps,
                },
            }}
            labelOverrides={{ [ACTIONS.EDIT]: { label: 'Meldung prüfen' } }}
        />
    );
};

export const MeldungFreigebenOverview = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_FREIGEBEN_OVERVIEW
);
