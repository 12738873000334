import React from 'react';
import { Typography } from '@mui/material';

import { useDeletedObjectsListActions } from 'components/DataTable/actions/useDeletedObjectsListActions';
import { useDeletedObjectsColumns } from 'components/DataTable/columns/useDeletedObjectsColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilderProps } from 'navigation/Paths';
import { useDeletedObjectListData } from 'pages/Verwaltung/DeletedObjects/useDeletedObjectListData';

interface DeletedObjectsTableProps {
    pathBuilder: PathBuilderProps;
    filterConfirmed?: boolean;
}

export const DeletedObjectsTable = ({ pathBuilder, filterConfirmed }: DeletedObjectsTableProps) => {
    const { handleAction } = useDeletedObjectsListActions({ pathBuilder });

    const columns = useDeletedObjectsColumns(handleAction);

    const getData = useDeletedObjectListData(filterConfirmed);

    return (
        <ContentContainer title="Gelöschte Objekte: Übersicht">
            <StyledContainer>
                <Typography variant="h1">Gelöschte Objekte</Typography>

                <DataTableHydraServerside
                    getColumns={columns}
                    getData={getData}
                    persistSearchText
                    initialOptions={{
                        viewColumns: true,
                        custom: {
                            pathBuilder,
                        },
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};
