import { useCallback } from 'react';
import { Typography } from '@mui/material';

import { EinrichtungTypes } from 'constants/einrichtungTypes';

export const useKonzeptinformationDescription = () => {
    return useCallback((einrichtungArt?: string) => {
        switch (einrichtungArt) {
            case EinrichtungTypes.FULLY_INPATIENT_PERMANENT_XI:
            case EinrichtungTypes.FULLY_INPATIENT_INTEGRATION_XII:
            case EinrichtungTypes.FULLY_INPATIENT_SHORT:
            case EinrichtungTypes.SHARED_HOME:
            case EinrichtungTypes.HOSPICE:
                return (
                    <Typography paragraph>
                        Gemäß § 23 WTG DVO müssen Sie der WTG-Behörde im Rahmen Ihrer Anzeigepflicht die allgemeine
                        Leistungsbeschreibung, die Konzeption der Einrichtung, Angaben zum Qualitäts- und
                        Beschwerdeverfahren sowie die Hausordnung zur Verfügung stellen. Bitte geben Sie nachfolgend die
                        konzeptionellen Schwerpunkte und Zielgruppen Ihrer Einrichtung an und laden die entsprechenden
                        Dokumente an den dafür vorgesehenen Stellen hoch. Möchten Sie weitere Dokumente hochladen, die
                        Sie hier nicht zuordnen können, können Sie dies später im Bereich „Weitere Unterlagen“ machen.
                    </Typography>
                );
            case EinrichtungTypes.SEMI_RESIDENTIAL_NIGHT_CARE:
            case EinrichtungTypes.SEMI_RESIDENTIAL_DAYCARE:
            case EinrichtungTypes.SEMI_RESIDENTIAL_INTEGRATION:
                return (
                    <Typography paragraph>
                        Gemäß § 43 WTG DVO müssen Sie der WTG-Behörde im Rahmen Ihrer Anzeigepflicht die allgemeine
                        Leistungsbeschreibung und die Konzeption der Einrichtung zur Verfügung stellen. Bitte geben Sie
                        nachfolgend die konzeptionellen Schwerpunkte und Zielgruppen Ihrer Einrichtung an und laden die
                        entsprechenden Dokumente an den dafür vorgesehenen Stellen hoch. Möchten Sie weitere Dokumente
                        hochladen, die Sie hier nicht zuordnen können, können Sie dies später im Bereich „Weitere
                        Unterlagen“ machen.
                    </Typography>
                );
            case EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT:
            case EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT:
                return (
                    <Typography paragraph>
                        Gemäß § 36 WTG DVO müssen Sie der WTG-Behörde im Rahmen Ihrer Anzeigepflicht die Konzeption
                        Ihres Ambulanten Dienstes zur Verfügung stellen. Bitte geben Sie nachfolgend die konzeptionellen
                        Schwerpunkte und Zielgruppen Ihres Ambulanten Dienstes an und laden die entsprechenden Dokumente
                        an den dafür vorgesehenen Stellen hoch. Möchten Sie weitere Dokumente hochladen, die Sie hier
                        nicht zuordnen können, können Sie dies später im Bereich „Weitere Unterlagen“ machen.
                    </Typography>
                );
            case EinrichtungTypes.WERKSTATT:
                return (
                    <Typography paragraph>
                        Gemäß der §§ 4, 6, 8 und 9 WTG müssen Sie der WTG-Behörde im Rahmen Ihrer Anzeigepflicht die
                        allgemeine Leistungsbeschreibung, die Konzeption der Einrichtung, das Gewaltschutzkonzept sowie
                        Angaben zum Qualitäts- und Beschwerdeverfahren zur Verfügung stellen. Möchten Sie weitere
                        Dokumente hochladen, die Sie hier nicht zuordnen können, können Sie das später im Bereich
                        „Verträge und Unterlagen“ machen.
                    </Typography>
                );
        }
    }, []);
};
