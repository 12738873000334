import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePlatzzahlmelderTokenApi } from 'api/hooks/usePlatzzahlmelderTokenApi';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { usePlatzzahlmelderColumnActions } from 'components/DataTable/columns/platzzahlmelder/usePlatzzahlmelderColumnActions';
import { usePlatzzahlmelderColumns } from 'components/DataTable/columns/platzzahlmelder/usePlatzzahlmelderColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES, ROLES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { usePlatzmelderListData } from './hooks/usePlatzmelderListData';

const Index = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    const { isLoading, currentToken } = usePlatzzahlmelderTokenApi(token);

    const { getColumnActions, handleCustomAction } = usePlatzzahlmelderColumnActions({
        role: ROLES.BEHOERDE,
        onCreateAufforderungAntrag: (antragId) => {
            if (antragId) {
                navigate(PathBuilder.home.verwaltung.platzmelder.aufforderung(token!, antragId));
            }
        },
    });

    const getData = usePlatzmelderListData();

    const columns = usePlatzzahlmelderColumns({
        token: currentToken || '',
        getColumnActions,
        handleCustomAction,
    });

    return (
        <ContentContainer title="Alle Meldungen belegbarer Plätze">
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading && !currentToken}>
                    {currentToken ? (
                        <>
                            <PageHeading title="Alle Meldungen belegbarer Plätze" />

                            <DataTableHydraServerside
                                getColumns={columns}
                                getData={getData}
                                initialOptions={{
                                    filter: true,
                                    sortOrder: {
                                        name: 'name',
                                        direction: 'asc',
                                    },
                                    custom: {
                                        token,
                                        pathBuilder: PathBuilder.home.verwaltung.platzmelder,
                                    },
                                }}
                                persistSearchText
                            />
                        </>
                    ) : null}
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const AuthorizedPlatzmelderBehoerdePage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PLATZMELDER_BEHOERDE
);
