import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { InvestRegistrationCollectionResponse } from 'api/types';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getRowData } from 'components/DataTable/tableUtils';

import { useServerActionsColumn } from './useServerActionsColumn';

export const useInvestRegistrationColumns = (
    handleAction: AntragListAction<any>
): ((data: InvestRegistrationCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });
    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                },
                {
                    name: 'name',
                    label: 'Name des Leistungsangebots',
                },
                {
                    name: 'pflegewohngeld',
                    label: 'Pflegewohngeld beantragt',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);
                            return rowData?.pflegewohngeld ? 'Ja' : 'Nein';
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
