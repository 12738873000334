import { backendApiService } from 'api/ApiService';
import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useTraegerAntragListActions = () => {
    return useListActions(
        PathBuilder.home.verwaltung.traeger,
        backendApiService.workflowTraeger.bind(backendApiService)
    );
};
