import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { MeldungAnsehen } from 'pages/Meldung/Components/MeldungAnsehen';

const Index = () => {
    return (
        <MeldungAnsehen
            title="Freigegebene Meldung"
            headline="Freigegebene Meldung"
            pathBuilder={PathBuilder.home.verwaltung.leistungsangebote.meldung}
        />
    );
};

export const LeistungsangeboteMeldungDetails = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNGEN_DETAIL
);
