import { useCallback, useState } from 'react';
import _, { identity } from 'lodash';

import { backendApiService } from 'api/ApiService';
import { AuthUser } from 'api/auth/AuthTypes';
import { BenutzerProfileEdit } from 'api/client';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';

export interface BenutzerProfilFormDataType extends Pick<BenutzerProfileEdit, 'plainPassword'> {
    anrede?: string | null;
    vorname: string;
    nachname: string;
    telefon?: string | null;
    fax?: string | null;
    email: string;
    password?: string | undefined;
}

const BenutzerProfilFormData: BenutzerProfilFormDataType = {
    anrede: '',
    vorname: '',
    nachname: '',
    telefon: '',
    fax: '',
    email: '',
    password: '',
};

interface UseBenutzerProfilApi {
    isLoading: boolean;
    isSubmitting: boolean;
    showPasswordModal: boolean;
    benutzerProfilFormData: BenutzerProfilFormDataType;
    handleChange(changeData: BenutzerProfilFormDataType): void;
    handleSubmit(): void | Promise<any>;
    loadProfileData(): void;
}

export const useBenutzerProfilApi = (benutzer?: AuthUser): UseBenutzerProfilApi => {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<BenutzerProfilFormDataType>(BenutzerProfilFormData);
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const { isSubmitting, submitStart, submitEnd } = useFormState();

    const { setRequestMessage } = useFormState();

    const loadProfileData = useCallback(() => {
        setLoading(true);
        backendApiService
            .getProfile()
            .then((response) => {
                setData((prevState: BenutzerProfilFormDataType) => {
                    const newData: BenutzerProfilFormDataType = {
                        ...prevState,
                        ...response,
                    };

                    return _.pickBy(newData, identity) as BenutzerProfilFormDataType;
                });
            })
            .catch(() => {
                setRequestMessage(errorMessage('Benutzerdaten konnten nicht geladen werden.'));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setRequestMessage]);

    const handleSubmit = useCallback(
        (updatedData: BenutzerProfilFormDataType | {} = {}) => {
            if (isSubmitting) {
                return;
            }

            submitStart();
            const changedData = {
                ...data,
                ...updatedData,
                ...{
                    password: undefined,
                    plainPassword: data.password,
                },
            } as BenutzerProfilFormDataType;

            return backendApiService
                .patchProfile(String(benutzer?.benutzerId), changedData as BenutzerProfileEdit)
                .then(async () => {
                    setShowPasswordModal(!!changedData.plainPassword?.length);
                    await setData(changedData);
                    return Promise.resolve();
                })
                .catch(() => Promise.reject())
                .finally(() => {
                    submitEnd();
                });
        },
        [isSubmitting, submitStart, data, benutzer?.benutzerId, submitEnd]
    );

    const handleChange = useCallback((changeData: BenutzerProfilFormDataType) => {
        setData(changeData);
    }, []);

    return {
        isLoading,
        isSubmitting,
        showPasswordModal,
        benutzerProfilFormData: data,
        handleChange,
        handleSubmit,
        loadProfileData,
    };
};
