import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, ListItemButton, ListItemIcon, styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { useHasRole } from 'api/auth';
import { IconExpand } from 'elements/IconExpand';
import { MenuInterface } from 'layout/HeaderConfig';
import { transientOptions } from 'theme/utils';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

import { MenuLoader } from './MenuLoader';

interface NavigationMenuItemProps extends MenuInterface {
    autoFocus?: boolean;
    badgePosition?: 'inline' | 'right';
    ariaLabel?: string;
}

export const NavigationMenuItem = React.forwardRef<HTMLDivElement, NavigationMenuItemProps>(
    (
        {
            autoFocus,
            menus,
            path,
            name,
            divide,
            disabled,
            expanded,
            tabs,
            padded,
            aufgabenBadge,
            fullWidth,
            badgePosition,
            ariaLabel,
        }: NavigationMenuItemProps,
        ref
    ) => {
        const currentPath = useLocation().pathname;
        const [open, setOpen] = React.useState(Boolean(expanded));
        const hasRole = useHasRole();
        const { isLoadingAufgaben, getBenutzerAufgabenTotal } = useBenutzerAufgaben();

        const isActive =
            currentPath === path ||
            (path && currentPath.startsWith(path + '/')) ||
            (tabs || []).some((tab) => tab.path === currentPath);

        const isExpandable = menus && menus.length > 0;
        const hasActiveChildren = (menus || []).some((menu) => currentPath.includes(String(menu.path)));

        useEffect(() => {
            setOpen(Boolean(expanded || hasActiveChildren));
        }, [expanded, hasActiveChildren]);

        const handleClick = useCallback(() => {
            setOpen((o) => !o);
        }, []);

        const ListItemRoot = () => {
            const aufgabenCount = getBenutzerAufgabenTotal(aufgabenBadge);

            return isExpandable ? (
                <ListItemButton ref={ref} onClick={handleClick} disabled={disabled} data-testid={'navItem'}>
                    <ItemText $padded={padded}>{name}</ItemText>

                    <ListItemIcon>
                        <Badge
                            aria-label={ariaLabel ? `${ariaLabel} ${aufgabenCount}` : undefined}
                            badgeContent={aufgabenCount}
                            color="primary"
                            showZero={!!aufgabenBadge}
                        />
                    </ListItemIcon>

                    <IconExpand open={open} />
                </ListItemButton>
            ) : (
                <>
                    {path && (
                        <ListItemButton
                            component={Link}
                            to={path}
                            selected={isActive}
                            disabled={disabled}
                            data-testid={'navItem'}
                            autoFocus={autoFocus}
                            aria-current={isActive ? 'page' : undefined}
                        >
                            <ItemText $padded={padded} $fullWidth={fullWidth}>
                                {name}
                            </ItemText>

                            <ListItemIcon>
                                {aufgabenBadge && isLoadingAufgaben ? (
                                    <MenuLoader />
                                ) : (
                                    <StyledBadge
                                        aria-label={ariaLabel ? `${ariaLabel} ${aufgabenCount}` : undefined}
                                        badgeContent={aufgabenCount}
                                        color="primary"
                                        showZero={!!aufgabenBadge}
                                        $badgePosition={badgePosition}
                                    />
                                )}
                            </ListItemIcon>
                        </ListItemButton>
                    )}
                </>
            );
        };

        const ListItemChildren = () =>
            isExpandable ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="nav" disablePadding>
                        {menus
                            ?.filter((item) => hasRole(item.roles))
                            .map((item, index) => {
                                return (
                                    <div key={index}>
                                        <NavigationMenuItem {...item} padded />
                                    </div>
                                );
                            })}
                    </List>
                </Collapse>
            ) : null;

        return (
            <>
                {divide && <Divider aria-hidden />}
                <ListItemRoot />
                <ListItemChildren />
            </>
        );
    }
);

const StyledBadge = styled(
    Badge,
    transientOptions
)<{ $badgePosition?: 'inline' | 'right' }>(({ $badgePosition }) => ({
    marginLeft: $badgePosition === 'right' ? 'auto' : undefined,
    marginRight: $badgePosition === 'right' ? '25%' : undefined,
}));

export const ItemText = styled(
    ListItemText,
    transientOptions
)<{ $padded?: boolean; $fullWidth?: boolean }>(({ theme, $padded, $fullWidth }) => ({
    display: 'flex',
    alginItems: 'center',
    paddingLeft: $padded ? theme.spacing(2) : undefined,
    marginRight: theme.spacing(2),
    flex: $fullWidth ? '1 1 auto' : '0 1 auto',
}));
