import * as React from 'react';
import { SetStateAction, useMemo, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormControl, FormHelperText, InputLabel, OutlinedInput, styled } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ErrorList } from 'forms/controls/components/ErrorList';
import { RowControl } from 'forms/controls/RowControl';
import { ComponentError } from 'forms/hooks/useErrors';
import { GridLayoutConfig, UiSchemaType } from 'forms/types/UiSchemaTypes';

interface PasswordInputProps {
    path: string;
    label: string;
    value: string;
    handleChange: React.Dispatch<SetStateAction<string>>;
    disabled: boolean;
    uischema: UiSchemaType;
    required?: boolean;
    gridLayout?: GridLayoutConfig;
    id?: string;
    hasErrors?: boolean;
    errors?: Array<ComponentError>;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
    path,
    label,
    value,
    handleChange,
    disabled,
    uischema,
    required,
    gridLayout,
    id,
    hasErrors,
    errors = [],
}) => {
    const [show, setShow] = useState(false);

    const handleToggleShow = () => {
        setShow(!show);
    };

    const labelId = useMemo(() => `${path}-${uuidv4()}`, [path]);
    const labelVisuallyHidden = useMemo(() => uischema.options?.labelVisuallyHidden ?? true, [uischema]);

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            gridLayout={gridLayout}
            labelForId={id}
            labelVisuallyHidden={labelVisuallyHidden}
        >
            <FormControl fullWidth variant="outlined" size="small" error={hasErrors}>
                {labelVisuallyHidden && (
                    <InputLabel htmlFor={labelId} disabled={disabled} required={required}>
                        {label}
                    </InputLabel>
                )}

                <InputField
                    id={labelId}
                    autoComplete="new-password"
                    fullWidth
                    value={value}
                    type={!show ? 'password' : 'text'}
                    required={required}
                    disabled={disabled}
                    inputProps={{ 'data-cy': `form_${path}` }}
                    onChange={(event) => handleChange(event.target.value)}
                    endAdornment={
                        !show ? (
                            <VisibilityIcon onClick={handleToggleShow} />
                        ) : (
                            <VisibilityOffIcon onClick={handleToggleShow} />
                        )
                    }
                    margin="dense"
                    placeholder={labelVisuallyHidden ? '' : label}
                    label={labelVisuallyHidden ? label : ''}
                />

                {hasErrors && (
                    <FormHelperText component="div" style={{ marginBottom: 0 }}>
                        <ErrorList errors={errors} />
                    </FormHelperText>
                )}
            </FormControl>
        </RowControl>
    );
};

const InputField = styled(OutlinedInput)(() => ({
    '& input': {
        padding: '8.5px 14px;',
    },
}));
