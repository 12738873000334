import { useCallback, useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number, when: boolean = true): (() => void) => {
    const id = useRef<number | null>(null);

    const handleClearInterval = useCallback(() => {
        if (!id.current) {
            return;
        }

        window.clearInterval(id.current);
    }, []);

    useEffect(() => {
        if ([undefined, false].includes(when)) {
            return;
        }

        id.current = window.setInterval(callback, delay);
        return handleClearInterval;
    }, [callback, delay, when, handleClearInterval]);

    return handleClearInterval;
};
