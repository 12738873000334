import { MUIDataTableColumnOptions } from 'mui-datatables';

export const JahrFilterSelectOptions = (columnLabel: string, filterOptions?: {}): MUIDataTableColumnOptions => {
    const currentYear = new Date().getFullYear();
    return {
        customFilterListOptions: {
            render: (value) => `${columnLabel}: ${value}`,
        },
        filterOptions: {
            names: Object.values(filterOptions || {}).map((entry) => String(entry)),
            fullWidth: true,
        },
        filterType: 'dropdown',
        filterList: [String(currentYear)],
    };
};
