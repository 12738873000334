import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { ApiAuswertung44VWtgGetCollection200Response } from 'api/client';
import { centerAlignedColumn, getRowData, rightAlignedColumn } from 'components/DataTable/tableUtils';
import { EINRICHTUNG_ART_FILTER_LABELS } from 'constants/labels';

export const useAuswertung44vWtgColumns = (): ((
    data: ApiAuswertung44VWtgGetCollection200Response | undefined
) => MUIDataTableColumnDef[]) => {
    return useCallback((data) => {
        if (!data) return [];

        return [
            {
                name: 'name',
                label: 'Name des Leistungsangebots',
            },
            {
                name: 'art',
                label: 'Art des Leistungsangebots',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        const art = getRowData(dataIndex, data)?.art;
                        return art ? EINRICHTUNG_ART_FILTER_LABELS[art as string] : '-';
                    },
                },
            },
            {
                name: 'strasse',
                label: 'Straße und Hausnummer',
                options: {
                    ...centerAlignedColumn,
                },
            },
            {
                name: 'plz',
                label: 'PLZ',
            },
            {
                name: 'ort',
                label: 'Ort',
            },
            {
                name: 'platzzahl',
                label: 'Platzzahl',
                options: {
                    sort: false,
                    ...rightAlignedColumn,
                },
            },
        ];
    }, []);
};
