import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { InvestRegistrationCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';

export const useInvestRegistrationListData = (): GetData<InvestRegistrationCollectionResponse> => {
    return useCallback(({ page, rowsPerPage }) => {
        return backendApiService.getInvestRegistrationCollection({
            page,
            itemsPerPage: rowsPerPage || 9999,
            failed: true,
        });
    }, []);
};
