import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { useSpecificRole } from 'api/auth/useHasRole';
import { EinrichtungCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { EinrichtungArtFilterOptions } from 'components/DataTable/filters/EinrichtungArtFilterOptions';
import { EinrichtungMeldungenStatusFilterOptions } from 'components/DataTable/filters/EinrichtungMeldungenStatusFilterOptions';
import { EinrichtungStatusFilterOptions } from 'components/DataTable/filters/EinrichtungStatusFilterOptions';
import { LeistungsbereichFilterOptions } from 'components/DataTable/filters/LeistungsbereichFilterOptions';
import { WohngemeinschaftArtFilterOptions } from 'components/DataTable/filters/WohngemeinschaftArtFilterOptions';
import {
    BenutzerOptions,
    ZustaendigerBenutzerFilterOptions,
} from 'components/DataTable/filters/ZustaendigerBenutzerFilterOptions';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { OpenViewTab } from 'components/DataTable/hooks/useTableOptions';
import { centerAlignedColumn } from 'components/DataTable/tableUtils';
import { ACTIONS } from 'constants/antragActions';
import {
    ANTRAG_ACTION_LABELS,
    ANTRAG_STATUS_LABELS,
    EINRICHTUNG_ART_FILTER_LABELS,
    EINRICHTUNG_MELDUNG_STATUS_FILTER_LABELS,
} from 'constants/labels';
import { useFormatDate } from 'utilities/hooks/useFormatDate';

export const useFreigegebeneColumns = (
    handleAction: AntragListAction<any>,
    zustaendigerBenutzerOptions?: BenutzerOptions,
    openViewTab?: OpenViewTab,
    enableMeldungenStatusFilter: boolean = true
): ((data: EinrichtungCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const { isBehoerde, isLV, isMags } = useSpecificRole();
    const { transform2Date } = useFormatDate();
    const actions = useServerActionsColumn({
        handleAction,
        openViewTab,
        labelOverrides: {
            [ACTIONS.ZUGRIFFSRECHTE]: {
                label: isBehoerde || isLV || isMags ? ANTRAG_ACTION_LABELS[ACTIONS.ZUGRIFFSRECHTE_ANSEHEN] : undefined,
            },
        },
    });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'nrwKey',
                    label: 'NRW-Schlüssel',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'name',
                    label: 'Name',
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    options: {
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                {
                    name: 'datumInbetriebnahme',
                    label: 'Datum der Inbetriebnahme',
                    options: {
                        ...centerAlignedColumn,
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? transform2Date(value) : '-';
                        },
                    },
                },
                {
                    name: 'einrichtungsArt',
                    label: 'Art des Leistungsangebots',
                    options: {
                        ...EinrichtungArtFilterOptions(EINRICHTUNG_ART_FILTER_LABELS),
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                {
                    name: 'letzteMeldung',
                    label: 'Letzte Meldung',
                    options: {
                        display: false,
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? transform2Date(value, 'dd.MM.yyyy HH:ii') : '-';
                        },
                    },
                },
                {
                    name: 'letzterStatus',
                    label: 'Status',
                    options: {
                        display: false,
                        ...EinrichtungStatusFilterOptions(),
                        customBodyRender: (value) => {
                            return value ? ANTRAG_STATUS_LABELS[value] : '-';
                        },
                    },
                },
                {
                    name: 'wohngemeinschaftArt',
                    label: 'Art der Wohngemeinschaft',
                    options: {
                        display: false,
                        viewColumns: false,
                        sort: false,
                        ...WohngemeinschaftArtFilterOptions,
                    },
                },
                {
                    name: 'wohngemeinschaftLeistungsbereich',
                    label: 'Leistungsbereich der Wohngemeinschaft',
                    options: {
                        display: false,
                        viewColumns: false,
                        sort: false,
                        ...LeistungsbereichFilterOptions,
                    },
                },
                {
                    name: 'letzteRegelpruefung',
                    label: 'Letzte Regelprüfung',
                    options: {
                        display: false,
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                {
                    name: 'meldungenStatus',
                    label: 'Status der Meldung',
                    options: {
                        display: false,
                        viewColumns: false,
                        filter: enableMeldungenStatusFilter === true,
                        ...EinrichtungMeldungenStatusFilterOptions(EINRICHTUNG_MELDUNG_STATUS_FILTER_LABELS),
                    },
                },
                {
                    name: 'letzterPruefbericht',
                    label: 'Letzter Prüfbericht',
                    options: {
                        display: false,
                        filter: false,
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                {
                    name: 'zustaendig',
                    label: 'Zuständig',
                    options: {
                        display: !!zustaendigerBenutzerOptions,
                        viewColumns: !!zustaendigerBenutzerOptions,
                        ...(zustaendigerBenutzerOptions
                            ? ZustaendigerBenutzerFilterOptions(zustaendigerBenutzerOptions, false)
                            : {}),
                        customBodyRender: (value) => {
                            return value ? value : '-';
                        },
                    },
                },
                actions(data),
            ];
        },
        [enableMeldungenStatusFilter, zustaendigerBenutzerOptions, actions, transform2Date]
    );
};
