import React, { useState } from 'react';
import { Download } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography, useTheme } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useHasRole, useSpecificRole } from 'api/auth';
import { GeneratedFile, TraegerExportResponse } from 'api/types';
import { useTraegerAntragListActions } from 'components/DataTable/actions/useTraegerAntragListActions';
import { useTraegerColumns } from 'components/DataTable/columns/useTraegerColumns';
import { DataTableHydraServerside, TableChangeProps } from 'components/DataTable/DataTableHydraServerside';
import { sortBy } from 'components/DataTable/tableUtils';
import { DocumentDownload } from 'components/DocumentDownload';
import { ROLE_TYPES } from 'constants/roles';
import { Link } from 'elements/Link';
import { FormLoadingButton } from 'forms/components/FormButton';
import { useFormState } from 'forms/state/useFormState';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useTraegerListData } from './useTraegerListData';

const Index = () => {
    const theme = useTheme();
    const hasRole = useHasRole();
    const canCreate = hasRole(ROLE_TYPES.ROLLEN_TRAEGER_CREATE);

    const { isBehoerde } = useSpecificRole();

    const { isLoading: isLoadingGeneratedFile, loadingStart, loadingEnd } = useFormState();

    const [exportParams, setExportParams] = useState<TableChangeProps>({});
    const [generatedFile, setGeneratedFile] = useState<TraegerExportResponse | null>(null);

    const { serverDataChanged, handleAction } = useTraegerAntragListActions();

    const getData = useTraegerListData();

    const columns = useTraegerColumns(handleAction);

    const handleTableChange = ({ filters, sortOrder, searchText }: TableChangeProps) => {
        setExportParams({ filters, sortOrder, searchText });
    };

    const handleFileExport = () => {
        loadingStart();

        backendApiService
            .getTraegerCollectionExport({
                qSearch: exportParams.searchText,
                orderNrwKey: sortBy(exportParams.sortOrder, 'nrwKey'),
                orderName: sortBy(exportParams.sortOrder, 'name'),
                orderModifiedAt: sortBy(exportParams.sortOrder, 'lastModifiedAt'),
            })
            .then((res) => setGeneratedFile(res))
            .catch((error) => error)
            .finally(() => loadingEnd());
    };

    return (
        <ContentContainer title="Übersicht der Leistungsanbieter">
            <StyledContainer>
                <Typography variant="h1">Leistungsanbieter</Typography>

                {canCreate ? (
                    <ButtonGroupGrid marginBottom={theme.spacing(4)}>
                        <Button
                            component={Link}
                            to={PathBuilder.home.verwaltung.traeger.create}
                            variant="contained"
                            color="primary"
                            endIcon={<AddIcon />}
                        >
                            Neuen Leistungsanbieter erstellen
                        </Button>
                    </ButtonGroupGrid>
                ) : null}

                <DataTableHydraServerside
                    onTableChange={handleTableChange}
                    getColumns={columns}
                    getData={getData}
                    serverDataChanged={serverDataChanged}
                    persistSearchText
                    initialOptions={{
                        sortOrder: {
                            name: 'name',
                            direction: 'asc',
                        },
                        custom: {
                            pathBuilder: PathBuilder.home.verwaltung.traeger,
                        },
                    }}
                />

                {isBehoerde ? (
                    <ButtonGroupGrid>
                        <DocumentDownload generatedFile={generatedFile} />

                        <FormLoadingButton
                            loading={isLoadingGeneratedFile}
                            loadingPosition="end"
                            endIcon={<Download />}
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-cy="BehoerdeExportButton"
                            onClick={handleFileExport}
                        >
                            Exportieren
                        </FormLoadingButton>
                    </ButtonGroupGrid>
                ) : null}
            </StyledContainer>
        </ContentContainer>
    );
};

export const TraegerUebersicht = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_TRAEGER);
