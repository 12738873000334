import { backendApiService } from 'api/ApiService';
import { useListActions } from 'components/DataTable/hooks/useListActions';
import { PathBuilderProps } from 'navigation/Paths';

interface UseAbwListActionsProps {
    pathBuilder: PathBuilderProps;
}

export const useAbwListActions = ({ pathBuilder }: UseAbwListActionsProps) => {
    return useListActions(pathBuilder, backendApiService.workflowWohngemeinschaftItem.bind(backendApiService));
};
