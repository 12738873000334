import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { FormStateChange } from 'forms/types/UiSchemaTypes';
import { PathBuilderProps } from 'navigation/Paths';

export type Einrichtungen = {
    einrichtungen: {
        id: number;
        name: string;
    };
};

interface UseFormMeldungCreateProps {
    pathBuilder: PathBuilderProps;
    onCreate?: () => void;
}

interface UseFormMeldungCreate {
    isLoading: boolean;
    selectedEinrichtung: Einrichtungen | null;
    setSelectedEinrichtung: React.Dispatch<React.SetStateAction<Einrichtungen | null>>;
    handleMeldungCreate: () => Promise<void> | undefined;
    handleMeldungFormChange: (state: FormStateChange) => void;
}

export const useFormMeldungCreate = ({ onCreate, pathBuilder }: UseFormMeldungCreateProps): UseFormMeldungCreate => {
    const navigate = useNavigate();
    const [selectedEinrichtung, setSelectedEinrichtung] = useState<Einrichtungen | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleMeldungCreate = () => {
        onCreate?.();

        if (!selectedEinrichtung) return;

        const { einrichtungen } = selectedEinrichtung;

        setIsLoading(true);

        return backendApiService
            .createMeldungWithEinrichtung(`${einrichtungen.id}`)
            .then(({ id }) => navigate(String(pathBuilder.edit?.({ id, step: 'hinweise' }))))
            .finally(() => {
                onCreate?.();
                setIsLoading(false);
            });
    };

    const handleMeldungFormChange = useCallback((state: FormStateChange) => {
        setSelectedEinrichtung(state.data);
    }, []);

    return {
        isLoading,
        selectedEinrichtung,
        setSelectedEinrichtung,
        handleMeldungCreate,
        handleMeldungFormChange,
    };
};
