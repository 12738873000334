import { useEffect } from 'react';

import { backendApiContextsService } from 'api/ApiContextsService';
import { backendApiService } from 'api/ApiService';
import { authLog } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';
import { useImpersonation } from 'api/auth/useImpersonation';
import { sessionService } from 'api/SessionService';

/**
 * Hook to handle session updates based on the application-wide auth-state.
 * @param isRecovering If true, session updates are prevented.
 */
export const useSessionHandler = (isRecovering: boolean) => {
    const { impersonator } = useImpersonation();
    const token = useAuthState(({ token }) => token);

    /**
     * Runs every time the token changes.
     * If the token is set (ie the user is authenticated), it is synced to all services.
     * If the token is not set (ie the user was signed out), it is cleared from all services.
     */
    useEffect(() => {
        if (isRecovering) return;
        if (token) {
            authLog('[UseSessionHandler.InitializeSession]');
            backendApiService.setJwtToken(token.token, token.refresh_token);
            backendApiContextsService.setJwtToken(token.token, token.refresh_token);
            sessionService.setToken(token.token, token.refresh_token);
        } else {
            authLog('[UseSessionHandler.ClearSession]');
            localStorage.clear();
            backendApiService.setJwtToken();
            backendApiContextsService.setJwtToken();
            sessionService.invalidate();
        }
    }, [token, isRecovering]);

    /**
     * Runs every time an impersonation is set or cleared.
     */
    useEffect(() => {
        if (impersonator) {
            backendApiService.setImpersonateUser(impersonator.username);
        } else {
            backendApiService.setImpersonateUser(undefined);
        }
    }, [impersonator]);
};
