import { FormState, Schema } from 'forms/types/UiSchemaTypes';
import {
    getElementSchemaFromCollection,
    getFieldSchemaByScope,
    getParentSchemaOfField,
    isCollection,
} from 'forms/utilities/SchemaUtils';
import { getFieldFromScope, getStackedFieldFromScope, getStackedFieldValue } from 'utilities';
import { getScopedValue } from 'utilities/ScopeUtils';

import { getMappedUuidValue, useUuidMapping } from './useUuidValue';

export const useDataFromScopes = (schema: Schema, data: FormState, scopes?: string[]) => {
    const mapUuid = useUuidMapping();

    const getDataFromScope = (scope: string, schema: Schema, rowData: FormState) => {
        const parentSchema = getParentSchemaOfField(scope, schema);
        if (isCollection(parentSchema)) {
            return {
                value: (getScopedValue(scope, data) as string[]).map((v) => getMappedUuidValue(mapUuid, v)).join(', '),
                colSchema: getElementSchemaFromCollection(parentSchema, getFieldFromScope(scope)),
            };
        }

        const stackedValue = getStackedFieldValue(getStackedFieldFromScope(scope), data);

        if (stackedValue) {
            return {
                value: stackedValue ? mapUuid(stackedValue) : null,
                colSchema: schema && getFieldSchemaByScope(scope, schema),
            };
        }

        return {
            value: rowData ? mapUuid(rowData[getFieldFromScope(scope)]) : null,
            colSchema: schema && getFieldSchemaByScope(scope, schema),
        };
    };

    return (
        scopes?.map((scope) => getDataFromScope(scope, schema, data)).find((d) => d.value !== undefined) || {
            colSchema: undefined,
            value: undefined,
        }
    );
};
