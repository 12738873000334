import React, { useCallback, useEffect, useState } from 'react';
import { ErrorObject } from 'ajv';
import { cloneDeep } from 'lodash';

import { backendApiService } from 'api/ApiService';
import {
    ApiPlatzmelderTokenGetCollection200Response,
    BelegbarePlaetzeJsonld,
    GetEinrichtungenForPlatzzahlmelder200Response,
} from 'api/client';
import {
    useApiPlatzzahlmelderEinrichtungenCollection,
    useApiPlatzzahlmelderTokenCollection,
} from 'api/hooks/useApiClient';
import { URL_EINRICHTUNG } from 'api/routes';
import { PlatzzahlmelderEinrichtungItem } from 'api/types';
import { getDateTime } from 'components/DataTable/tableUtils';
import { toIriId } from 'utilities/IriUtils';

import { usePlatzmelderFilterOptions, UsePlatzmelderFilterOptionsResponse } from './usePlatzmelderFilterOptions';

type FormErrors = Omit<ErrorObject & { instancePath: string; message?: string | undefined }, 'dataPath'>[];

interface UsePlatzmelderCollectionDataResult
    extends Omit<UsePlatzmelderFilterOptionsResponse, 'setFilteredEinrichtungen'> {
    isLoadingCollection: boolean;
    collectionData: Array<PlatzzahlmelderEinrichtungItem>;
    handleDataChanged(
        einrichtungId: number | null | undefined,
        data: PlatzzahlmelderEinrichtungItem,
        errors?: FormErrors
    ): void;
    getLetzteErinnerung(einrichtung: PlatzzahlmelderEinrichtungItem): string;
    submitPromise(token?: string | undefined, item?: Record<string, any>): Promise<BelegbarePlaetzeJsonld>;
    setServerDataChanged: React.Dispatch<React.SetStateAction<number>>;
    errors: FormErrors;
}

interface UsePlatzmelderCollection {
    currentToken?: string;
    isLoggedIn?: boolean;
    searchText: string;
    updateSearchText: (searchText: string) => void;
}

export const usePlatzmelderCollectionData = ({
    currentToken,
    isLoggedIn,
    searchText,
    updateSearchText,
}: UsePlatzmelderCollection): UsePlatzmelderCollectionDataResult => {
    const [errors, setErrors] = useState<FormErrors>([]);
    const [serverDataChanged, setServerDataChanged] = useState<number>(0);
    const [collectionData, setCollectionData] = useState<
        | GetEinrichtungenForPlatzzahlmelder200Response['hydra:member']
        | ApiPlatzmelderTokenGetCollection200Response['hydra:member']
        | undefined
    >(undefined);

    const { isLoading: isLoadingEinrichtungCollection, data: einrichtungCollection } =
        useApiPlatzzahlmelderEinrichtungenCollection({}, [serverDataChanged]);

    const { isLoading: isLoadingTokenCollection, data: tokenCollection } = useApiPlatzzahlmelderTokenCollection(
        currentToken,
        [serverDataChanged]
    );

    const { options, handleFilter, filteredEinrichtungen, filters } = usePlatzmelderFilterOptions({
        token: currentToken,
        einrichtungen: collectionData,
        searchText,
        updateSearchText,
    });

    const getLetzteErinnerung = useCallback((einrichtung: PlatzzahlmelderEinrichtungItem): string => {
        if (!einrichtung?.belegbarePlaetze?.aufgefordert) return '-';

        return `${getDateTime(einrichtung?.belegbarePlaetze?.aufgefordert)}, (${einrichtung.behoerdeName} / ${
            einrichtung.zustaendigeBehoerde
        })`;
    }, []);

    const submitPromise = isLoggedIn
        ? backendApiService.savePlatzzahlmelderItem.bind(backendApiService)
        : backendApiService.postPlatzzahlmelderTokenData.bind(backendApiService);

    const handleDataChanged = useCallback(
        (einrichtungId: number | null | undefined, data: PlatzzahlmelderEinrichtungItem, errors?: FormErrors) => {
            const einrichtungIri = toIriId(URL_EINRICHTUNG, einrichtungId);

            setErrors((prevErrors) => {
                const existingErrorPaths = new Set(prevErrors.map((error) => error.data.einrichtung));
                const filteredNewErrors = (errors || []).filter(
                    (error) => !existingErrorPaths.has(error.data.einrichtung)
                );

                const filteredExistingErrors = prevErrors.filter((error) => {
                    const matchesGivenId = error.data.einrichtung === einrichtungIri;
                    const existsInErrors = errors?.some((newError) => newError.message === error.message);

                    return !(matchesGivenId && !existsInErrors);
                });

                return [...filteredExistingErrors, ...filteredNewErrors];
            });

            setCollectionData((prevState) => {
                return (prevState || []).map((einrichtung: PlatzzahlmelderEinrichtungItem) => {
                    if (einrichtung.id === einrichtungId) {
                        return { ...einrichtung, belegbarePlaetze: data };
                    }

                    return einrichtung;
                });
            });
        },
        []
    );

    // Initial: set collection data
    useEffect(() => {
        if (!einrichtungCollection && !tokenCollection) {
            return;
        }

        const clonedCollection = cloneDeep(
            einrichtungCollection?.['hydra:member'] || tokenCollection?.['hydra:member']
        );

        setCollectionData(clonedCollection);
    }, [einrichtungCollection, tokenCollection]);

    return {
        isLoadingCollection: !!(isLoadingTokenCollection || isLoadingEinrichtungCollection),
        collectionData: collectionData || [],
        options,
        handleFilter,
        filters,
        filteredEinrichtungen,
        handleDataChanged,
        getLetzteErinnerung,
        submitPromise,
        setServerDataChanged,
        errors,
    };
};
