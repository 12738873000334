import React from 'react';

import { MeldungData } from 'api/client';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';

interface MeldungDetailsHeaderProps {
    isMeldungAntrag: boolean;
    data: Record<'basisdaten' | 'meldungInfo', MeldungData>;
}

export const MeldungInfoHeader = ({ isMeldungAntrag, data }: MeldungDetailsHeaderProps) => {
    if (!isMeldungAntrag || !data) {
        return <></>;
    }

    return (
        <DetailsLayout.Container>
            <DetailsLayout.Group>
                <DetailsLayout.InfoField value={data.meldungInfo?.meldungArt} label="Art der Meldung" />
                <DetailsLayout.InfoField value={data.meldungInfo?.druckdatum} label="Druckdatum" isDate />
                <DetailsLayout.InfoField value={data.meldungInfo?.antragsteller} label="Antragsteller" />
            </DetailsLayout.Group>

            <DetailsLayout.Group title="Leistunganbieter">
                <DetailsLayout.InfoField
                    value={data.meldungInfo?.leistungsanbieterInfo?.bezeichnung}
                    label="Bezeichnung inkl. Rechtsform"
                />
                <DetailsLayout.InfoField
                    value={data.meldungInfo?.leistungsanbieterInfo?.nrwKey}
                    label="NRW-Schlüssel"
                />
                <DetailsLayout.InfoField value={data.basisdaten?.traeger?.name} label="Träger" />
                <DetailsLayout.InfoField value={data.basisdaten?.traegerAdresse?.label} label="Adresse des Trägers" />
                <DetailsLayout.InfoField
                    value={
                        data.meldungInfo?.leistungsanbieterInfo?.verband?.length
                            ? data.meldungInfo?.leistungsanbieterInfo?.verband
                            : '-'
                    }
                    label="Verbandszugehörigkeit"
                />
            </DetailsLayout.Group>
        </DetailsLayout.Container>
    );
};
