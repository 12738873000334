import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { authLog, AuthState } from 'api/auth/AuthTypes';
import { useAuthState } from 'api/auth/useAuthState';
import { Credentials } from 'api/client';
import { PathBuilder } from 'navigation/Paths';

/**
 * Provides a login function to authenticate the user.
 */
export const useLogin = (): ((credentials: Credentials) => Promise<void>) => {
    const login = useAuthLogin();
    const navigate = useNavigate();
    return useCallback(
        async (credentials) => {
            authLog('[UseLogin.Login]', credentials);
            const token = await backendApiService.getToken(credentials);
            login(token);
            navigate(PathBuilder.home.dashboard.path);
        },
        [login, navigate]
    );
};

export const useAuthLogin = (): AuthState['login'] => {
    return useAuthState(({ login }) => login);
};
