import { Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';

export const schema: Schema = {
    type: 'object',
    required: ['grund'],
    properties: {
        grund: {
            type: 'string',
            // @ts-ignore
            custom: {
                block_prefixes: ['plain_textarea'],
            },
        },
    },
};

export const uiSchema = customVerticalLayout([group([control('grund')])]);
