import { DependencyList, useState } from 'react';
import { MUIDataTableOptions } from 'mui-datatables';

import { TableFilters } from 'components/DataTable/hooks/useTableFilters';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

interface UseTableDataResult<Data = any> {
    isLoading: boolean;
    data: Data | undefined;
    hasErrors: boolean;
}

type GetDataParameters = Pick<MUIDataTableOptions, 'page' | 'rowsPerPage' | 'searchText' | 'sortOrder'> & {
    filters: TableFilters;
};

export type GetData<Data = Array<Record<string, any>>> = (parameters: GetDataParameters) => Promise<Data>;

export const useTableData = <Data = any>(
    options: MUIDataTableOptions,
    filters: TableFilters,
    searchText: string,
    getData: GetData<Data>,
    deps: DependencyList
): UseTableDataResult<Data> => {
    const { setRequestMessage } = useFormState();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<Data | undefined>(undefined);
    const [errors, setErrors] = useState<boolean>(false);

    useDebouncedEffect(
        () => {
            setLoading(true);
            getData(createGetDataParameters(options, filters, searchText))
                .then(setData)
                .catch(() => {
                    setErrors(true);
                    setRequestMessage(createErrorMessage());
                })
                .finally(() => setLoading(false));
        },
        [...deps, getData, setRequestMessage, options, filters],
        300
    );

    return { isLoading, data, hasErrors: errors };
};

const createErrorMessage = () => errorMessage('Daten konnten nicht geladen werden.');

const createGetDataParameters = (
    options: MUIDataTableOptions,
    filters: TableFilters,
    searchText: string
): GetDataParameters => {
    return {
        filters,
        searchText,
        page: (options.page || 0) + 1,
        rowsPerPage: options.rowsPerPage,
        sortOrder: options.sortOrder,
    };
};
