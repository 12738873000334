export const IdColumn = (display: boolean = false) => {
    return {
        name: 'id',
        label: 'Id',
        options: {
            filter: false,
            display,
            viewColumns: process.env.NODE_ENV === 'development',
        },
    };
};
