import React from 'react';
import { ListItem } from '@mui/material';

import { GeneratedFile } from 'api/types';
import { DocumentDownload } from 'components/DocumentDownload';

import { StyledDownloadList } from './DetailsLayoutComponents';

interface DocumentsListProps {
    documents: Array<GeneratedFile>;
    openLinkInWindow?: boolean;
}

export const DocumentsList = ({ documents, openLinkInWindow }: DocumentsListProps) => {
    return (
        <StyledDownloadList $numbered={documents.length > 1} dense>
            {documents.map((document, index) => (
                <ListItem key={document.uploadId || index}>
                    {document?.accessRights ? (
                        <DocumentDownload generatedFile={document} openInWindow={openLinkInWindow} withIcon />
                    ) : (
                        <span>{document.originalName}</span>
                    )}
                </ListItem>
            ))}
        </StyledDownloadList>
    );
};
