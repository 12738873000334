import React from 'react';

import { backendApiService } from 'api/ApiService';
import { FemExportFemFilterJsonldFemexportWrite } from 'api/client';
import { useApiFemExportGet } from 'api/hooks/useApiClient';
import { FemExportCollectionParams } from 'api/types';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { Schema } from 'forms/types/UiSchemaTypes';
import { uiSchema } from 'forms/ui-schemas/statistik/femAuswertung';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAuswertung } from 'navigation/menuConfigs/menuAuswertung';
import { withAuthorization } from 'navigation/withAuthorization';
import { useExportMessage } from 'pages/Auswertung/useExportMessage';

const Index = () => {
    const { isLoading, data, loadData } = useApiFemExportGet({});

    const { createSuccessMessage, createErrorMessage } = useExportMessage();
    const { submitEnd } = useFormState();

    const handleSubmitData = (formData: FemExportCollectionParams) => {
        backendApiService
            .getFemAuswertungExport(formData)
            .then((res) => {
                createSuccessMessage();
            })
            .catch(() => {
                createErrorMessage();
            })
            .finally(() => submitEnd());
    };

    const handleOnChange = (formData: FemExportFemFilterJsonldFemexportWrite) => {
        loadData({ formData });
    };

    return (
        <ContentContainer title="FEM-Auswertung generieren">
            <StyledContainer>
                <PageHeading title="FEM-Auswertung generieren" />

                <BaseGridContainer lg={8}>
                    <SimpleForm
                        onChange={handleOnChange}
                        isLoading={isLoading && !data}
                        data={data?.data}
                        schema={data?.schema as Schema}
                        uiSchema={uiSchema}
                        submitLabel="Export FEM-Auswertung"
                        onSubmit={handleSubmitData}
                        ignoreLeaveWarning
                        buttonStyles={{
                            justifyContent: 'flex-end',
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const StatistikFemPage = withAuthorization(
    withSideBar(Index, menuAuswertung),
    ROLE_TYPES.ROLLEN_AUSWERTUNG_STATISTIK_FEM
);
