import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';

import { RequiredFlag } from 'forms/components/RequiredFlag';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';

import { RowControl } from './RowControl';

interface MultipleAuswahlBase {
    const: string;
    title: string;
}

type MulitpleAuswahlOption = string | number | MultipleAuswahlBase;

type MultipleAuswahlOptions = Array<MulitpleAuswahlOption>;

const MultipleAuswahlControlComponent: React.FC<CustomControlProps<MultipleAuswahlOptions>> = ({
    path,
    label,
    required,
    data,
    handleChange,
    schema,
    hasErrors,
    errors,
    readonly,
    formula,
    showFieldNumberLabels,
    gridLayout,
    uischema,
    id,
}) => {
    const [selectedValues, setSelectedValues] = useState<MultipleAuswahlOptions>([]);

    const getDefaultObject = (value: MulitpleAuswahlOption) =>
        typeof value === 'object' && value?.const ? value.const : value;

    const handleValueChange = useCallback(
        (newValues: MultipleAuswahlOptions) => {
            const preparedValues = newValues.map((value) => {
                if (uischema.parseValueToInt) {
                    return parseInt(String(getDefaultObject(value)), 10);
                }

                return getDefaultObject(value);
            });

            setSelectedValues(newValues);

            handleChange(path, preparedValues);
        },
        [uischema, handleChange, path]
    );

    const options = useMemo(() => {
        return (schema.oneOf || []).map((entry) => ({
            const: entry.const,
            title: entry.title,
        }));
    }, [schema]);

    const isSelectedOption = useCallback((option: any, currentValue: any) => {
        if (!option || !currentValue) {
            return false;
        }

        return option.const === currentValue.const && option.title === currentValue.title;
    }, []);

    useEffect(() => {
        if (data && Array.isArray(data) && schema.oneOf) {
            setSelectedValues(data.map((id) => (schema.oneOf || []).find((entry) => entry.const === String(id)) || id));
        }

        if ((readonly && !data?.length) || !schema.oneOf) {
            handleValueChange([]);
        }
        // eslint-disable-next-line
    }, [data, readonly, schema]);

    return (
        <RowControl
            id={path}
            name={path}
            label={label}
            required={required}
            formula={formula}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            controlOnly={uischema.controlOnly}
            labelForId={id}
            labelVisuallyHidden={true}
        >
            <FormControl fullWidth size="small">
                <Autocomplete
                    noOptionsText="Keine Einträge verfügbar"
                    multiple
                    value={selectedValues}
                    options={options}
                    filterSelectedOptions
                    disabled={readonly}
                    onChange={(event, newValues) => {
                        handleValueChange(newValues);
                    }}
                    getOptionLabel={(option) => (typeof option === 'object' && option?.title) || ''}
                    isOptionEqualToValue={isSelectedOption}
                    defaultValue={[]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                                <>
                                    {label}
                                    {required && <RequiredFlag />}
                                </>
                            }
                        />
                    )}
                />

                {schema?.description && <FormHelperText>{schema.description}</FormHelperText>}
                {hasErrors && (
                    <FormHelperText component="div">
                        <ErrorList errors={errors} />
                    </FormHelperText>
                )}
            </FormControl>
        </RowControl>
    );
};

export const MultipleAuswahlControl = withCustomControlProps(MultipleAuswahlControlComponent);
