import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import { useAuthUser } from 'api/auth';
import { useBenutzerProfilApi } from 'api/hooks/useBenutzerProfilApi';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ROLES } from 'constants/roles';
import { SimpleForm } from 'forms/SimpleForm';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { withAuthorization } from 'navigation/withAuthorization';
import { profilSchema } from 'pages/Profil/Benutzerdaten/schema';

import { PasswordChangedDialog } from './PasswordChangedDialog';
import { uiSchemaBenutzerdaten } from './ui-schema';

const Index = () => {
    const benutzer = useAuthUser();

    const { isLoading, benutzerProfilFormData, showPasswordModal, handleSubmit, handleChange, loadProfileData } =
        useBenutzerProfilApi(benutzer);

    useEffect(() => {
        loadProfileData();
    }, [loadProfileData]);

    return (
        <ContentContainer title="Benutzerdaten">
            <StyledContainer>
                <Typography variant="h1">Benutzerdaten</Typography>

                <BaseGridContainer lg={8}>
                    <AppLoaderContainer isLoading={isLoading}>
                        <SimpleForm
                            data={benutzerProfilFormData}
                            schema={profilSchema}
                            uiSchema={uiSchemaBenutzerdaten}
                            submitLabel="Profildaten speichern"
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                            messages={{
                                success: 'Benutzerdaten aktualisiert',
                                error: 'Aktualisierung der Benutzerdaten fehlgeschlagen.',
                            }}
                        />

                        <PasswordChangedDialog open={showPasswordModal} />
                    </AppLoaderContainer>
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const BenutzerdatenPage = withAuthorization(withSideBar(Index), [ROLES.USER]);
