export const standorte_table = {
    type: 'SummaryTable',
    label: 'Standorte und Plätze',
    columns: 3,
    scope: '#/properties/standorte',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/stepStandortGesamtPlatzzahl',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepStandortAnnerkanntePlaetze',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepStandortBetriebsintegriertePlatzzahl',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepStandortEingestreuteKurzzeitpflegeplaetze',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepStandortSeparateKurzzeitpflegeplaetze',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepStandortTeilzeitbeschaeftigte',
        },

    ],
};
