import { StepsType } from 'api/types';
import { TabConfig } from 'layout/menu/TabNavigation';
import { TabComponentProps } from 'navigation/hooks/useTabsConfig';

export const femAntragTabConfig: TabConfig<TabComponentProps>[] = [
    {
        value: StepsType.GERICHTLICH,
    },
    {
        value: StepsType.EINWILLIGUNG,
    },
];
