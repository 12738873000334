import React, { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useAuthUser } from 'api/auth';
import { EmailtextFormData } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { useServerError } from 'forms/hooks/useServerError';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { errorMessage } from 'forms/utilities/MessageUtils';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { uiSchema } from './config';

const initialFormData: EmailtextFormData = {
    behoerde: undefined,
    emailtyp: undefined,
    inhalt: undefined,
};

const Index = () => {
    const { setRequestMessage } = useFormState();
    const collectionError = useServerError();

    const currentBenutzer = useAuthUser();
    const currentBenutzerId = String(currentBenutzer?.behoerdeId);

    const [formData, setFormData] = useState<EmailtextFormData | undefined>({
        ...initialFormData,
        ...{ behoerde: currentBenutzerId },
    });

    const { schema } = useSchemaFromJsonLdContext('Emailtext', formData);

    const handleOnChange = (updatedFormData: EmailtextFormData) => {
        if (
            formData?.emailtyp !== updatedFormData.emailtyp &&
            updatedFormData.emailtyp &&
            !updatedFormData.inhalt?.length
        ) {
            backendApiService
                .getEmailText(updatedFormData.emailtyp, currentBenutzerId)
                .then((res) => {
                    const { behoerde, emailtyp, inhalt, id } = res;
                    setFormData({ behoerde, emailtyp, inhalt, id });
                })
                .catch((error) =>
                    setRequestMessage(
                        errorMessage(collectionError(error, 'Beim Laden der Formulardaten ist ein Fehler aufgetreten'))
                    )
                );
        }

        setFormData(updatedFormData);
    };

    const handleSubmit = backendApiService.postEmailText.bind(backendApiService, {
        emailtyp: String(formData?.emailtyp),
        inhalt: String(formData?.inhalt),
        behoerde: currentBenutzerId,
    });

    return (
        <ContentContainer title="E-Mail-Texte editieren">
            <StyledContainer>
                <Typography variant="h1">E-Mail-Texte editieren</Typography>

                <SimpleForm
                    isLoading={!formData || !schema}
                    data={formData}
                    schema={schema}
                    uiSchema={uiSchema}
                    messages={{
                        success: `E-Mail-Text wurde aktualisiert`,
                        error: 'Aktualisierung des E-Mail-Text ist fehlgeschlagen',
                    }}
                    onChange={handleOnChange}
                    onSubmit={handleSubmit}
                    submitLabel="Aktualisieren"
                    setResponseFormData
                />

                <BaseGridContainer lg={10}>
                    {formData?.emailtyp ? (
                        <Box>
                            <Typography variant="h2">E-Mailtext Vorlage:</Typography>
                            <StyledParagraph>
                                <strong>[salutation]</strong>,
                            </StyledParagraph>
                            <StyledParagraph>
                                Ihre Registrierung im EDV-Verfahren PfAD.wtg war erfolgreich und wird hiermit bestätigt.
                            </StyledParagraph>
                            <StyledParagraph>
                                Sie können sich nun mit Ihren Zugangsdaten anmelden. Verwenden Sie hierzu bitte die von
                                Ihnen eingetragene E-Mail-Adresse sowie das dazugehörige Passwort. Wir möchten Sie
                                darauf hinweisen, dass die eingegebenen Daten der Registrierung unter Umständen durch
                                uns angepasst wurden.
                            </StyledParagraph>
                            <StyledParagraph>
                                Das Anzeigefahren nach dem WTG ist unterteilt in die von Ihnen erfolgreich durchgeführte
                                (Erst-) Registrierung und die sogenannte Meldung. Erst in der von Ihnen noch zu
                                erstellenden Meldung, werden die nach der Verordnung zur Durchführung des Wohn- und
                                Teilhabegesetzes (WTG DVO) anzuzeigenden Angaben (vgl. §§ 23, 33, 35, 36 und 43 WTG DVO)
                                zum jeweiligen Leistungsangebot abgefragt (Ausnahme: Selbstverantwortete
                                Wohngemeinschaften). Für jedes Leistungsangebot ist daher einmalig eine Meldung
                                anzufertigen.
                            </StyledParagraph>
                            <StyledParagraph>
                                Ihrer Anzeigepflicht nach dem WTG bzw. nach der WTG-DVO kommen Sie erst vollständig
                                nach, wenn Sie für jedes Leistungsangebot nach dem WTG die erforderliche Registrierung
                                und Meldung erfolgreich abgeschlossen haben und Änderungen nach dem WTG bzw. der WTG DVO
                                unverzüglich über das System angezeigt haben.
                            </StyledParagraph>
                            <StyledParagraph>
                                Unter der Adresse <strong>[url:login]</strong> können Sie sich nun mit Ihrer
                                E-Mail-Adresse und Ihrem Passwort anmelden und die Meldung nach § 9 WTG durchführen.
                                Bitte beachten Sie eventuelle individuelle Hinweise der WTG-Behörde zu erforderlichen
                                angebotsbezogenen Angaben oder Unterlagen. Die Hinweise werden Ihnen in der Meldung
                                angezeigt.
                            </StyledParagraph>
                        </Box>
                    ) : null}
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

const StyledParagraph = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

export const EmailTextEdit = withAuthorization(withSideBar(Index, menuVerwaltung), ROLE_TYPES.ROLLEN_EMAIL_TEXT);
