export const konzeptinformation_dokumente_table = {
    type: 'SummaryTable',
    label: 'Dokumente zu Konzeptinformationen',
    columns: 3,
    scope: '#/properties/konzeptinformation',
    entries: [
        {
            type: 'FieldRow',
            scope: '#/properties/stepKonzeptinformationenLeistungsbeschreibungen',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepKonzeptinformationenHausordnungen',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepKonzeptinformationenKonzeptinformationen',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepKonzeptinformationenFreiheitentziehende',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepKonzeptinformationenQualitaetBeschwerden',
        },
        {
            type: 'FieldRow',
            scope: '#/properties/stepKonzeptinformationenGewaltpraeventionen',
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizKonzeptinformation',
        },
    ],
};
