import * as React from 'react';
import { useCallback } from 'react';
import Info from '@mui/icons-material/Info';
import { IconButton, Popover, styled, Typography } from '@mui/material';

import { KEY, useKeyDown } from 'forms/hooks/useKeyDown';

interface FormulaControlProps {
    formula: string;
}

export const FormulaPopover: React.FC<FormulaControlProps> = ({ formula }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const onKeyDown = useKeyDown<HTMLDivElement>(
        useCallback((key, e) => {
            handleClose();
            if ([KEY.SPACE, KEY.ENTER].includes(key)) {
                e.stopPropagation();
                e.preventDefault();
                return false;
            }
        }, []),
        [KEY.SPACE, KEY.ENTER, KEY.TAB, KEY.ESCAPE]
    );

    return (
        <>
            <FormulaIconButton onClick={handleClick} size="small" aria-label="Formel anzeigen">
                <FormulaIcon fontSize="small" />
            </FormulaIconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onKeyDown={onKeyDown}
            >
                <FormulaTypography>
                    <b>
                        Formel:
                        <br />
                    </b>{' '}
                    {formula.split('\n').map((item, key) => (
                        <span key={key}>
                            {item}
                            <br />
                        </span>
                    ))}
                </FormulaTypography>
            </Popover>
        </>
    );
};

const FormulaIconButton = styled(IconButton)(() => ({
    alignItems: 'center',
    display: 'inline-flex',
    minWidth: 0,
    marginLeft: 4,
    marginTop: -2,
}));

const FormulaIcon = styled(Info)(() => ({
    color: '#006ECB',
}));

const FormulaTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(2),
}));
