import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { DokumentPruefungFormData } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { SimpleForm } from 'forms/SimpleForm';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const initialFormData: DokumentPruefungFormData = {
    einrichtungsArt: [],
    uploads: undefined,
};

const uiSchema = customVerticalLayout([control('einrichtungsArt'), control('uploads')]);

const Index = () => {
    const navigate = useNavigate();
    const { schema } = useSchemaFromJsonLdContext('MeldungFreigabeDokument', initialFormData);

    const handleSubmit = backendApiService.createDokumentPruefung.bind(backendApiService);

    const handleAfterSubmit = () => navigate(PathBuilder.home.verwaltung.documentVerification.path);

    return (
        <ContentContainer title="Dokumente nach Prüfung: hinzufügen">
            <StyledContainer>
                <Typography variant="h1">Dokumente nach Prüfung: hinzufügen</Typography>

                <SimpleForm
                    isLoading={!schema}
                    data={initialFormData}
                    schema={schema}
                    uiSchema={uiSchema}
                    submitLabel="Speichern"
                    onSubmit={handleSubmit}
                    onAfterSubmit={handleAfterSubmit}
                    messages={{
                        success: 'Die Dokumente wurden erfolgreich hinzugefügt.',
                        error: 'Die Dokumente konnten nicht hinzugefügt werden.',
                    }}
                    backLink={{
                        path: PathBuilder.home.verwaltung.documentVerification.path,
                        title: 'Zur Übersicht',
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const DokumentPruefungAddPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_DOKUMENTE_PRUEFUNG
);
