import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Paper, styled, Typography } from '@mui/material';

import { useIsLoggedIn } from 'api/auth';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { KONTAKT_BASE_EMAIL, KONTAKT_BASE_TEL } from 'constants/content';
import { Link } from 'elements/Link';
import { LinkEmail } from 'elements/LinkEmail';
import { LinkTel } from 'elements/LinkTel';
import { useMessages } from 'forms/state/useMessages';
import { LoginForm } from 'layout/components/LoginLink/LoginForm';
import { Logo } from 'layout/components/Logo';
import { PasswordResetRequest } from 'layout/components/PasswordReset/PasswordResetRequest';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { PathBuilder } from 'navigation/Paths';
import { theme } from 'theme/theme';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';

const CenteredContainer = styled(Container)(
    () => `
    .ContentContainer--default & {
        position: relative;
        min-width: 100vw;
        width: 100vw;
        min-height: 100%;
        height: 100%;
    }
    .ContentContainer--aside & {
        margin-top: 10%;
    }
`
);

const TextBox = styled(Paper)(
    () => `
    border-radius: 0.25rem;
    padding: 2rem;
    margin: 2rem 0 3rem;
`
);

const Home = () => {
    const navigate = useNavigate();
    const isLoggedIn = useIsLoggedIn();
    const { clearMessages } = useMessages();
    const [openPasswordReset, setOpenPasswordReset] = useState<boolean>(false);
    const { clearBenutzerAufgaben } = useBenutzerAufgaben();

    const handleClose = () => {
        setOpenPasswordReset(false);
    };

    const handlePasswordReset = () => {
        setOpenPasswordReset(true);
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate(PathBuilder.home.dashboard.path);
        } else {
            clearMessages();
            clearBenutzerAufgaben();
        }
    }, [navigate, isLoggedIn, clearBenutzerAufgaben, clearMessages]);

    return (
        <CenteredContainer>
            <ContentContainer title="PfAD.wtg - Anmeldung">
                <StyledContainer>
                    <AppLoaderContainer isLoading={isLoggedIn}>
                        <Typography component="div" align="center">
                            <Logo height={60} />
                        </Typography>

                        <PageHeading title="Herzlich willkommen" align="center" className="no-divider" />

                        <Box sx={{ margin: `${theme.spacing(4)} auto` }} width="400px">
                            <LoginForm />
                            <br />
                            <Grid container justifyContent={'space-between'}>
                                <Link to={PathBuilder.home.registrierung.path}>Registrieren</Link>

                                <Link to="/" onClick={handlePasswordReset}>
                                    Passwort vergessen
                                </Link>
                            </Grid>
                        </Box>

                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={10}>
                                <TextBox elevation={2}>
                                    <Typography paragraph>
                                        Herzlich Willkommen auf der Startseite von PfAD.wtg, dem elektronischen
                                        Anzeigeverfahren für Wohn- und Betreuungsangebote nach dem Wohn- und
                                        Teilhabegesetz Nordrhein-Westfalen (WTG NRW).
                                        <br />
                                        <br />
                                        Alle Leistungsanbieterinnen und Leistungsanbieter von Wohn- und
                                        Betreuungsangeboten in Nordrhein-Westfalen, die in den Geltungsbereich des WTG
                                        NRW fallen, müssen Angebote, die sie betreiben oder betreiben wollen, bei der
                                        zuständigen WTG-Behörde anzeigen. Bitte melden Sie sich hierfür an und folgen
                                        den weiteren Hinweisen.
                                        <br />
                                        Bitte halten Sie für die Erstregistrierung - sofern für Ihr Leistungsangebot
                                        vorhanden - Ihren Versorgungsvertrag nach SGB XI bzw. Ihre
                                        Vergütungsvereinbarung nach SGB XII als Scan bereit.
                                    </Typography>

                                    <Typography paragraph>
                                        Sind Sie bereits für ein Leistungsangebot im System registriert, können Sie sich
                                        hier mit Ihren Zugangsdaten anmelden.
                                    </Typography>

                                    <Typography paragraph>
                                        Für Fragen rund um die Anwendung PfAD.wtg steht Ihnen unsere Hotline zur
                                        Verfügung, die Sie werktags von 9.00 - 17.00 Uhr unter der Rufnummer{' '}
                                        <LinkTel msisdn={KONTAKT_BASE_TEL} /> erreichen können. Sie können Ihre Anfrage
                                        auch per E-Mail an <LinkEmail email={KONTAKT_BASE_EMAIL} /> richten.
                                    </Typography>
                                </TextBox>
                            </Grid>
                        </Grid>

                        <PasswordResetRequest open={openPasswordReset} onClose={handleClose} />
                    </AppLoaderContainer>
                </StyledContainer>
            </ContentContainer>
        </CenteredContainer>
    );
};

export const HomePage = withSideBar(Home, []);
