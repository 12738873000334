import { useMemo } from 'react';

import { GroupListDataType } from 'forms/controls/GroupList/GroupList';
import { Schema } from 'forms/types/UiSchemaTypes';
import { buildPath, determineSchemaByUUID, formatFieldValue, getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { getFieldFromScope } from 'utilities';

import { buildControlGroups } from './useControlsOfElements';

export const useControlGroupsOfElements = (
    data: any,
    schema: any,
    scope: string,
    labelScope: string,
    parentPath: string
): GroupListDataType[] =>
    useMemo(() => {
        const scopeField = getFieldFromScope(scope);
        const groupsData = data[scopeField];
        const scopeSchema = getFieldSchemaByScope(scope, schema);
        if (!Array.isArray(groupsData)) {
            return [];
        }
        return groupsData.map((groupData, dataIndex) => {
            const groupSchema = determineSchemaByUUID(groupData.uuid, scopeSchema as Schema);
            const groupLabelData = groupData[getFieldFromScope(labelScope)];
            const groupLabelSchema = getFieldSchemaByScope(labelScope, groupSchema) as Schema;
            return {
                title: formatFieldValue(groupLabelData, groupLabelSchema),
                controls: buildControlGroups(
                    groupData,
                    groupSchema,
                    scope,
                    buildPath(parentPath, getFieldFromScope(scope), dataIndex)
                ),
            };
        });
    }, [data, schema, scope, labelScope, parentPath]);
