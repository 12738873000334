export enum EXPORT_TYPES {
    Auswertung44VWtg = 'Auswertung44VWtg',
    RegelpruefungCsvOutput = 'RegelpruefungCsvOutput',
    FemExport = 'FemExport',
    Standardauswertung = 'Standardauswertung',
}

export const EXPORT_TYPE_LABELS: Record<string, string> = {
    [EXPORT_TYPES.Auswertung44VWtg]: 'Auswertung 44 V WTG',
    [EXPORT_TYPES.RegelpruefungCsvOutput]: 'Regelprüfungen',
    [EXPORT_TYPES.FemExport]: 'FEM',
    [EXPORT_TYPES.Standardauswertung]: 'Standardauswertung',
};
