import { RuleEffect } from '@jsonforms/core';

import { EinrichtungTypes } from 'constants/einrichtungTypes';
import { Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';

export const defaultSchemaEdit: Schema = {
    required: ['name', 'adresse', 'beginn', 'plaetze', 'ambulanterDienst'],
    properties: {
        name: {
            type: 'string',
            title: 'Name der Wohngemeinschaft',
        },
        adresse: {
            type: 'string',
            title: 'Hausanschrift',
            // @ts-ignore
            custom: {
                block_prefixes: ['form', 'adresse_lookup_autocomplete'],
            },
        },
        beginn: {
            type: 'string',
            title: 'Datum Beginn der betreuenden Tätigkeit',
            format: 'date',
        },
        plaetze: {
            type: 'integer',
            title: 'Anzahl der betreuten Nutzer',
        },
        ambulanterDienst: {
            type: 'string',
            title: 'Ambulanter Dienst',
            // @ts-ignore
            custom: {
                disabled: true,
                block_prefixes: ['form', 'wohngemeinschaft_autocomplete'],
            },
        },
        einrichtung: {
            type: 'string',
            title: 'Name des Leistungsangebots',
            // @ts-ignore
            custom: {
                block_prefixes: ['form', 'wohngemeinschaft_autocomplete'],
            },
        },
    },
};

export const adSchemaEdit: Schema = {
    required: ['name', 'adresse', 'beginn', 'plaetze', 'ambulanterDienst'],
    properties: {
        name: {
            type: 'string',
            title: 'Name der Wohngemeinschaft',
        },
        adresse: {
            type: 'string',
            title: 'Hausanschrift',
            // @ts-ignore
            custom: {
                block_prefixes: ['form', 'adresse_lookup_autocomplete'],
            },
        },
        beginn: {
            type: 'string',
            title: 'Datum Beginn der betreuenden Tätigkeit',
            format: 'date',
        },
        plaetze: {
            type: 'integer',
            title: 'Anzahl der betreuten Nutzer',
        },
        ambulanterDienst: {
            type: 'string',
            title: 'Ambulanter Dienst',
            // @ts-ignore
            custom: {
                block_prefixes: ['form', 'wohngemeinschaft_autocomplete'],
            },
        },
    },
};

const DISABLE_RULE_EINRICHTUNG = {
    rule: {
        effect: RuleEffect.DISABLE,
        condition: {
            scope: '#/properties/einrichtung',
            schema: {
                type: 'object',
                required: ['id', 'name'],
            },
        },
    },
};

const defaultUiSchemaElements = [
    control('name'),
    control('adresse', {
        ...DISABLE_RULE_EINRICHTUNG,
    }),
    inlineRowControl([control('beginn', { ...DISABLE_RULE_EINRICHTUNG }), control('plaetze')]),
];

export const adUiSchemaEdit = customVerticalLayout([
    ...defaultUiSchemaElements,
    control('ambulanterDienst', {
        options: {
            requestFilter: {
                art2: [EinrichtungTypes.AMBULANT_XI_CARE_CONTRACT, EinrichtungTypes.AMBULANT_XII_CARE_CONTRACT],
            },
        },
    }),
]);

export const defaultUiSchemaEdit = customVerticalLayout([
    ...defaultUiSchemaElements,
    control('ambulanterDienst'),
    control('einrichtung'),
]);
