import React, { useCallback } from 'react';
import { Box, styled } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { ExportRequestCollectionResponse } from 'api/types';
import { LoaderApp } from 'components/AppLoaderContainer';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { BadgeColumn } from 'components/DataTable/components/BadgeColumn';
import { getDateTime, getRowData } from 'components/DataTable/tableUtils';
import { DocumentDownload } from 'components/DocumentDownload';
import { EXPORT_STATUS } from 'constants/exportStatus';
import { EXPORT_TYPE_LABELS } from 'constants/exportTypes';
import { EXPORT_STATUS_COLORS, EXPORT_STATUS_LABELS } from 'constants/labels';

export const useAuswertungExportColumns = (
    isPending: (status: EXPORT_STATUS) => boolean
): ((data: ExportRequestCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const getExportArt = (art: string) => {
        if (art.indexOf('\\') === -1) {
            return '';
        }

        return art.split('\\').pop() || '';
    };

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'createdDate',
                    label: 'Erstellt am',
                    options: {
                        customBodyRender: getDateTime,
                    },
                },
                {
                    name: 'resource',
                    label: 'Art des Export',
                    options: {
                        customBodyRender: (value) => {
                            return EXPORT_TYPE_LABELS[getExportArt(value)];
                        },
                    },
                },
                {
                    name: 'status',
                    label: 'Status des Export',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);
                            const status = rowData?.status ?? '';

                            return (
                                <BadgeColumnWrapper>
                                    <BadgeColumn
                                        content={EXPORT_STATUS_LABELS[status]}
                                        size="medium"
                                        textBold
                                        fontSize={14}
                                        color={EXPORT_STATUS_COLORS[status]}
                                    />

                                    {isPending(status as EXPORT_STATUS) ? <LoaderApp /> : null}
                                </BadgeColumnWrapper>
                            );
                        },
                    },
                },
                {
                    name: 'generiertesDokument',
                    label: 'Generiertes Dokument',
                    options: {
                        sort: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);

                            return rowData?.generiertesDokument ? (
                                <DocumentDownload generatedFile={rowData?.generiertesDokument} withIcon />
                            ) : (
                                <>Nicht verfügbar</>
                            );
                        },
                    },
                },
            ];
        },
        [isPending]
    );
};

const BadgeColumnWrapper = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
    '> .MuiCircularProgress-root': {
        width: '25px !important',
        height: '25px !important',
    },
}));
