import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';

export const schema = {
    properties: {
        einrichtungen: {
            type: 'string',
            title: 'Leistungsangebote',
            custom: {
                block_prefixes: ['meldung_einrichtung_autocomplete'],
            },
        },
    },
};

export const uiSchema = customVerticalLayout([control('einrichtungen')]);
