import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { withAuthorization } from 'navigation/withAuthorization';

import { LeistungsangeboteAnbieterOverview } from './anbieter/LeistungsangeboteAnbieterOverview';

const Index = () => {
    return <LeistungsangeboteAnbieterOverview />;
};

export const LeistungsangeboteOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE
);
