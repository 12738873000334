import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { MassenmailCollectionResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { getDateTime, getRowData } from 'components/DataTable/tableUtils';

export const useMassenmailColumns = (
    handleAction: AntragListAction<any>
): ((data: MassenmailCollectionResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'createdAt',
                    label: 'Datum',
                    options: {
                        sort: false,
                        customBodyRender: (value) => getDateTime(value),
                    },
                },
                {
                    name: 'createdBy',
                    label: 'Benutzer',
                    options: {
                        sort: false,
                        customBodyRender: (value) => {
                            return (
                                <>
                                    {value?.nachname || '-'}, {value.vorname || '-'}
                                </>
                            );
                        },
                    },
                },
                {
                    name: 'betreff',
                    label: 'Betreff',
                    options: {
                        sort: false,
                    },
                },
                {
                    name: 'status',
                    label: 'Status',
                    options: {
                        sort: false,
                        customBodyRenderLite: (dataIndex) => {
                            const rowData = getRowData(dataIndex, data);

                            return (
                                <>
                                    {rowData?.countEmpfaengerGesendet} von {rowData?.countEmpfaenger} gesendet
                                </>
                            );
                        },
                    },
                },
                actions(data),
            ];
        },
        [actions]
    );
};
