import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { BenutzerForm } from 'api/client';
import { useBenutzerFormItemById } from 'api/hooks/useApiClient';
import { PageHeading } from 'components/PageHeading';
import { uiSchema } from 'forms/Benutzer/config';
import { SimpleForm } from 'forms/SimpleForm';
import { Schema } from 'forms/types/UiSchemaTypes';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

const Index = () => {
    const navigate = useNavigate();
    const { benutzerId } = useParams();
    const { isLoading, data } = useBenutzerFormItemById(String(benutzerId));

    const [schema, setSchema] = useState<Schema | undefined>(undefined);

    const putBenutzerAntrag = (formData: BenutzerForm, persist: boolean = false) =>
        backendApiService
            .putBenutzerAntrag(formData, persist)
            .then((data) => Promise.resolve(data))
            .catch(() => Promise.reject());

    const handleSubmit = (formData: BenutzerForm) =>
        putBenutzerAntrag(formData, true).then(() => navigate(PathBuilder.home.verwaltung.benutzer.path));

    const handleOnChange = (formData: BenutzerForm) => {
        if (!Object.keys(formData).length) {
            return;
        }

        return putBenutzerAntrag(formData)
            .then((response) => {
                setSchema(response?.schema as Schema);
            })
            .catch(() => Promise.reject());
    };

    useDebouncedEffect(
        () => {
            if (!data?.schema) return;
            setSchema(data.schema as Schema);
        },
        [data],
        100
    );

    return (
        <ContentContainer title="Benutzer: Bearbeiten">
            <StyledContainer>
                <PageHeading title={`Benutzer: ${data?.data?.vorname} ${data?.data?.nachname}`} />

                <SimpleForm
                    isLoading={isLoading}
                    data={data?.data}
                    schema={schema}
                    uiSchema={uiSchema}
                    messages={{
                        success: 'Benutzer wurde aktualisiert.',
                        error: 'Aktualisierung des Benutzers ist fehlgeschlagen.',
                    }}
                    onChange={handleOnChange}
                    onSubmit={handleSubmit}
                    submitLabel="Benutzer aktualisieren"
                    backLink={{
                        path: PathBuilder.home.verwaltung.benutzer.path,
                        title: 'Zur Übersicht',
                    }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const BenutzerEditPage = withAuthorization(withSideBar(Index, menuVerwaltung));
