import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import { AuswertungOverview } from 'pages/Auswertung/AuswertungOverview';
import { P44vWtgPage } from 'pages/Auswertung/P44vWtg/P44vWtgPage';
import { StatistikPage } from 'pages/Auswertung/Statistik/StatistikPage';
import { StatistikFemPage } from 'pages/Auswertung/StatistikFem/StatistikFemPage';

import {
    PATH_AUSWERTUNG,
    PATH_AUSWERTUNG_OVERVIEW,
    PATH_AUSWERTUNG_P44,
    PATH_AUSWERTUNG_STATISTIK,
    PATH_AUSWERTUNG_STATISTIK_FEM,
    PathBuilder,
} from './Paths';

export const RoutingAuswertung = () => {
    const { SentryRoutes } = useSentry();

    return (
        <SentryRoutes>
            <Route path={PATH_AUSWERTUNG}>
                <Route path={PATH_AUSWERTUNG_P44} element={<P44vWtgPage />} />
                <Route path={PATH_AUSWERTUNG_STATISTIK} element={<StatistikPage />} />
                <Route path={PATH_AUSWERTUNG_STATISTIK_FEM} element={<StatistikFemPage />} />
                <Route path={PATH_AUSWERTUNG_OVERVIEW} element={<AuswertungOverview />} />

                <Route index element={<Navigate to={PathBuilder.home.auswertung.overview} />} />
            </Route>
        </SentryRoutes>
    );
};
