import React from 'react';

import { EinrichtungArt } from 'api/types';
import {
    EINRICHTUNG_ART_FILTER_LABELS,
    WOHNGEMEINSCHAFT_ARTEN,
    WOHNGEMEINSCHAFT_LEISTUNGSBEREICH,
} from 'constants/labels';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { MeldungProps } from 'pages/Meldung/Details/MeldungDetailPage';

export const DetailsLeistungsangebot = ({ meldung }: MeldungProps) => {
    return (
        <DetailsLayout.Group title="Leistungsangebot" variant="h3" divider>
            <DetailsLayout.InfoField
                label="Art der Einrichtung"
                value={
                    meldung?.einrichtungArt
                        ? EINRICHTUNG_ART_FILTER_LABELS[meldung.einrichtungArt as keyof EinrichtungArt]
                        : '-'
                }
            />

            {meldung.data?.einrichtungWohngemeinschaftArt ? (
                <DetailsLayout.InfoField
                    label="Art der Wohngemeinschaft"
                    value={WOHNGEMEINSCHAFT_ARTEN[meldung.data.einrichtungWohngemeinschaftArt]}
                />
            ) : null}

            {meldung.data?.einrichtungWohngemeinschaftLeistungsbereich ? (
                <DetailsLayout.InfoField
                    label="Leistungsbereich der Wohngemeinschaft"
                    value={WOHNGEMEINSCHAFT_LEISTUNGSBEREICH[meldung.data.einrichtungWohngemeinschaftLeistungsbereich]}
                />
            ) : null}

            <DetailsLayout.InfoField label="NRW-Schlüssel" value={meldung.data?.einrichtungNrwKey} />
            <DetailsLayout.InfoField
                label={meldung.labelReplacements?.['[einrichtungName]']}
                value={meldung.einrichtungName}
            />
            <DetailsLayout.InfoField label="Adresse der Einrichtung" value={meldung.data?.einrichtungAdresse?.label} />

            <DetailsLayout.InfoField
                label="Institutionskennzeichen"
                value={meldung.data?.einrichtungInstitutionskennzeichen || '-'}
            />

            {meldung.data?.einrichtungPlaetze ? (
                <DetailsLayout.InfoField label="Plätze" value={meldung.data?.einrichtungPlaetze} />
            ) : null}

            <DetailsLayout.InfoField
                label="Datum der Erstinbetriebnahme"
                value={meldung.data?.einrichtungDatumErstinbetriebnahme}
                isDate
            />

            <DetailsLayout.InfoField label="Homepage" value={meldung.data?.einrichtungHomepage} />
        </DetailsLayout.Group>
    );
};
