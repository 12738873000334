import { isVisible, UISchemaElement } from '@jsonforms/core';

import { FormConfig, FormState, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { useErrors } from './useErrors';
import { useJsonFormsState } from './useJsonFormsState';

export const PHONE_NUMBER_REGEX = '^[-0-9 +/()]{6,}$';
export const EMAIL_REGEX = "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$";

export const useFormValidation = (
    schema: Schema | undefined,
    data: FormState | undefined,
    config?: Pick<FormConfig, 'path' | 'errors'> | undefined,
    uischema?: UiSchemaType
): { valid: boolean } => {
    const { ajv } = useJsonFormsState();
    const { hasErrors } = useErrors({ config, immediately: true });

    const getUiSchema = (elements: Array<any>, field: string): UISchemaElement | undefined => {
        for (const element of elements) {
            if (element.elements?.length) {
                const nestedElement = getUiSchema(element.elements, field);
                if (nestedElement) {
                    return nestedElement;
                }
            }

            if (element.scope?.includes(field)) {
                return element;
            }
        }

        return undefined;
    };

    const updatedRequiredSchema = (schema?.required || []).reduce((requiredList: Array<string>, requiredField) => {
        const currentUiFieldSchema = getUiSchema(uischema?.elements || [], requiredField);

        if (currentUiFieldSchema && isVisible(currentUiFieldSchema, data, '', ajv)) {
            return [...requiredList, requiredField];
        }

        return requiredList;
    }, []);

    const filteredSchema = { ...schema, ...{ required: updatedRequiredSchema } };

    if (!filteredSchema || !data || hasErrors) {
        return { valid: false };
    }

    const valid = !isMissingRequiredField(filteredSchema, data);
    return { valid };
};

const isMissingRequiredField = (schema: Schema, data: any): boolean => {
    return (
        (schema?.required || [])
            .filter((r) => r !== 'prototype')
            .some((requiredField) => {
                if (!data) {
                    return true;
                }
                const fieldSchema = (schema.properties ? schema.properties[requiredField] : {}) as Schema;
                if (requiredField === 'i_') {
                    const newElements = data.filter((elem: any) => elem.uuid === undefined);
                    return newElements.some((elemData: any) => isMissingRequiredField(fieldSchema, elemData));
                }
                if (requiredField.startsWith('i_')) {
                    const uuid = requiredField.substring(2);
                    const elemData = data.find((elem: any) => elem.uuid === uuid);
                    return isMissingRequiredField(fieldSchema, elemData);
                }
                const fieldData = data[requiredField];

                let fieldInvalid = fieldData === undefined;

                fieldInvalid = fieldInvalid || (Array.isArray(fieldData) && fieldData.length === 0);
                fieldInvalid =
                    fieldInvalid ||
                    (!!fieldSchema?.custom?.block_prefixes?.includes('checkbox') &&
                        !fieldSchema?.custom?.block_prefixes?.includes('ja_nein') &&
                        !fieldData);
                return fieldInvalid || isMissingRequiredField(fieldSchema, fieldData);
            }) ||
        (Boolean(schema?.properties) &&
            Object.keys(schema.properties || [])
                .filter((field) => field !== 'prototype')
                .some((field) => {
                    // @ts-ignore
                    const fieldSchema = schema.properties[field] as Schema;
                    if (field === 'i_') {
                        const newElements = data?.filter((elem: any) => elem.uuid === undefined);
                        return newElements.some((elemData: any) => isMissingRequiredField(fieldSchema, elemData));
                    }
                    if (field.startsWith('i_')) {
                        const uuid = field.substring(2);
                        const elemData = data?.find((elem: any) => elem.uuid === uuid);

                        return isMissingRequiredField(fieldSchema, elemData);
                    }
                    const fieldData = data ? data[field] : {};

                    if (fieldData && fieldSchema.pattern) {
                        return !RegExp(fieldSchema.pattern).test(fieldData);
                    }

                    return isMissingRequiredField(fieldSchema, fieldData);
                }))
    );
};
