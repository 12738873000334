import { DependencyList, RefObject, useCallback, useRef } from 'react';

import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

export const useScrollInView = <T extends HTMLElement>({
    ref,
    deps = [],
}: {
    ref?: RefObject<T>;
    deps?: DependencyList;
}): { scrollRef: RefObject<T>; scrollIntoView: () => void } => {
    const localRef = useRef<T>(null);

    const getRef = useCallback((): RefObject<T> => {
        return ref || localRef;
    }, [ref]);

    const createObserver = useCallback(
        (rootMargin = '-300px') => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (getRef().current) {
                        observer.unobserve(getRef().current!);
                    }
                    if (entry.isIntersecting) {
                        return;
                    }
                    getRef().current?.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                },
                {
                    rootMargin,
                }
            );
            return observer;
        },
        [getRef]
    );

    const scrollIntoView = useCallback(() => {
        const bottomObserver = createObserver();
        if (getRef().current) {
            bottomObserver.observe(getRef().current!);
        }
    }, [getRef, createObserver]);

    const getRefElementAndOffset = useCallback((): {
        refElement?: HTMLElement;
    } => {
        if (!getRef().current) {
            return {};
        }

        const inputRef =
            getRef().current?.tagName?.toLowerCase() === 'input'
                ? getRef().current
                : getRef().current?.getElementsByTagName?.('input')[0];

        const textareaRef =
            getRef().current?.tagName?.toLowerCase() === 'textarea'
                ? getRef().current
                : getRef().current?.getElementsByTagName?.('textarea')[0];
        return (
            (inputRef && { refElement: inputRef }) ||
            (textareaRef && {
                refElement: textareaRef,
            }) ||
            {}
        );
    }, [getRef]);

    useDebouncedEffect(
        () => {
            const { refElement } = getRefElementAndOffset();
            if (!refElement) {
                return;
            }
            const callback = () => scrollIntoView();

            refElement?.addEventListener('focus', callback);
            return () => refElement?.removeEventListener('focus', callback);
        },
        [getRefElementAndOffset, scrollIntoView, ...deps],
        100
    );

    return { scrollRef: getRef(), scrollIntoView };
};
