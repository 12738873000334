import React, { ReactNode, useCallback, useMemo } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { User } from '@sentry/types';
import { SeverityLevel } from '@sentry/types/types/severity';

import { useAuthUser, useIsLoggedIn } from 'api/auth';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

type SentryRoutesComponent = React.FC<{ children?: React.ReactNode }>;

interface UseSentry {
    initSentry: () => void;
    SentryRoutes: SentryRoutesComponent;
    ErrorBoundary: React.FC<SentryErrorBoundary>;
    useSentrySetUser: () => void;
}

interface SentryErrorBoundary {
    children: ReactNode;
}

export const defaultUserData: User = {
    ip_address: undefined,
    email: undefined,
    geo: undefined,
};

enum ENVIRONMENTS {
    DEVELOPMENT = 'development',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

export const useSentry = (): UseSentry => {
    const environment = useMemo(() => {
        const location = document.location;
        const { hostname } = location;

        if (!hostname || hostname.includes('local') || hostname.includes('test')) {
            return ENVIRONMENTS.DEVELOPMENT;
        }

        if (hostname.includes('staging')) {
            return ENVIRONMENTS.STAGING;
        }

        return ENVIRONMENTS.PRODUCTION;
    }, []);

    const initSentry = useCallback(() => {
        if (![ENVIRONMENTS.STAGING, ENVIRONMENTS.PRODUCTION].includes(environment)) {
            return;
        }

        Sentry.init({
            release: process.env.REACT_APP_VERSION,
            dsn: 'https://c0be668384e8c5bc81095609e85ba345@sentry.live.publicplan.cloud/31',
            environment,
            attachStacktrace: true,
            ignoreErrors: ['CanceledError', 'NotFoundError', 'TooManyRequestsError'],
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            tracesSampleRate: 0.5,
            sampleRate: 0.7,
        });
    }, [environment]);

    const SentryRoutes: SentryRoutesComponent = Sentry.withSentryReactRouterV6Routing(Routes);

    const ErrorBoundary = ({ children }: SentryErrorBoundary) => {
        return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
    };

    const useSentrySetUser = () => {
        const user = useAuthUser();
        const isLoggedIn = useIsLoggedIn();

        useDebouncedEffect(
            () => {
                if (isLoggedIn) {
                    return Sentry.setUser({ ...defaultUserData, id: Number(user.benutzerId) });
                }

                Sentry.setUser({ ...defaultUserData, id: 'unauthorized' });
            },
            [],
            0
        );
    };

    return {
        initSentry,
        SentryRoutes,
        ErrorBoundary,
        useSentrySetUser,
    };
};

export const getErrorLevel = (errorStatus: number): SeverityLevel => {
    switch (errorStatus) {
        case 400:
        case 401:
            return 'fatal';
        case 428:
        case 498:
            return 'warning';
        default:
            return 'error';
    }
};
