import { useMemo, useState } from 'react';

import { GetColumns, OnViewColumnsChanged, TableViewColumns } from 'components/DataTable/types';

type UseTableViewColumnsResult = {
    viewColumns: TableViewColumns;
    onViewColumnsChanged: OnViewColumnsChanged;
};

export const useTableViewColumns = (getColumns: GetColumns): UseTableViewColumnsResult => {
    const initialViewColumns = useMemo(() => determineInitialViewColumns(getColumns), [getColumns]);
    const [viewColumns, setViewColumns] = useState<TableViewColumns>(initialViewColumns);

    const onViewColumnsChanged: OnViewColumnsChanged = (changedColumn, action) => {
        if (!changedColumn) return;

        setViewColumns((viewColumns) => ({ ...viewColumns, [changedColumn]: 'add' === action }));
    };

    return {
        viewColumns,
        onViewColumnsChanged,
    };
};

const determineInitialViewColumns = (getColumns: GetColumns): TableViewColumns => {
    const columns = getColumns({});
    return columns.reduce((acc, column) => {
        if (typeof column === 'string') return { ...acc, [column]: true };
        return { ...acc, [column.name]: false !== column.options?.display && 'excluded' !== column.options?.display };
    }, {});
};
