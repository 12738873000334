import { KeyboardEvent, KeyboardEventHandler } from 'react';

export enum KEY {
    SPACE = 'SPACE',
    ENTER = 'ENTER',
    TAB = 'TAB',
    ESCAPE = 'ESCAPE',
    ARROW_DOWN = 'ARROWDOWN',
}

export const useKeyDown = <T extends Element>(
    callback: (key: KEY, event: KeyboardEvent<T>) => void,
    executeOnKeys: KEY[] = []
): KeyboardEventHandler<T> => {
    return (event) => {
        const key = event.code.toUpperCase() as KEY;

        if (executeOnKeys && !executeOnKeys.includes(key)) return;

        callback(key, event);
    };
};
