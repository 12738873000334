import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { useApiEmailAntragItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { getDate, getDateTime } from 'components/DataTable/tableUtils';
import { PageHeading } from 'components/PageHeading';
import { ROLES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { menuNachrichten } from 'layout/HeaderConfig';
import { withSideBar } from 'layout/hooks/useSideBar';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { useBenutzerAufgaben } from 'utilities/hooks/useBenutzerAufgabenState';
import { useDebouncedEffect } from 'utilities/hooks/useDebouncedEffect';

const Index = () => {
    const { id } = useParams();

    const { isLoading, data: email, error } = useApiEmailAntragItem(id);
    const { loadBenutzerAufgaben } = useBenutzerAufgaben();

    useDebouncedEffect(
        () => {
            if (!error) {
                loadBenutzerAufgaben();
            }
        },
        [error],
        250
    );

    return (
        <ContentContainer title={`Nachricht: ${email?.einrichtung}`}>
            <StyledContainer>
                <AppLoaderContainer isLoading={isLoading || (!email && !error)}>
                    <PageHeading title={`Benachrichtigung vom ${getDate(email?.eingereichtAt)}`} />

                    <BaseGridContainer lg={10}>
                        {!error ? (
                            <DetailsLayout.Container>
                                <DetailsLayout.Group title={`${email?.betreff} - ${getDateTime(email?.eingereichtAt)}`}>
                                    <DetailsLayout.InfoField value={email?.data?.nachricht} />

                                    {(email?.data?.dokumente?.length || 0) > 0 ? (
                                        <DetailsLayout.Group>
                                            <DetailsLayout.DownloadLink value={email?.data?.dokumente} />
                                        </DetailsLayout.Group>
                                    ) : null}
                                </DetailsLayout.Group>
                            </DetailsLayout.Container>
                        ) : (
                            <Alert severity="error">Die Benachrichtigung konnte nicht gefunden/geladen werden</Alert>
                        )}
                    </BaseGridContainer>
                </AppLoaderContainer>

                <ButtonGroupGrid backLink={{ path: PathBuilder.home.nachrichten.path }} />
            </StyledContainer>
        </ContentContainer>
    );
};

export const Nachricht = withAuthorization(withSideBar(Index, menuNachrichten), [ROLES.USER]);
