import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiProfilHeimfinderItem } from 'api/hooks/useApiClient';
import { ProfilHeimfinderFormData, ProfilHeimfinderFormGet } from 'api/types';
import { ROLE_TYPES } from 'constants/roles';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { SimpleForm } from 'forms/SimpleForm';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { uiSchema } from './formConfig';

const initialFormData: ProfilHeimfinderFormGet = {
    name: undefined,
    telefon: undefined,
    fax: undefined,
    email: undefined,
    homepage: undefined,
    upload: undefined,
};

const Index = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState<ProfilHeimfinderFormGet | null>(null);

    const { isLoading, data } = useApiProfilHeimfinderItem(id);

    const { schema } = useSchemaFromJsonLdContext('Heimfinderprofil', formData);

    const handleSubmit = (updatedFormData: ProfilHeimfinderFormData) => {
        return backendApiService.patchProfilHeimfinderItem(String(id), updatedFormData).catch(() => Promise.reject());
    };

    useEffect(() => {
        if (!isLoading && schema) {
            setFormData(data || initialFormData);
        }
    }, [data, isLoading, schema]);

    return (
        <ContentContainer title="Profil Heimfinder: bearbeiten">
            <StyledContainer>
                <Typography variant="h1">Profil Heimfinder: bearbeiten</Typography>

                <SimpleForm
                    isLoading={isLoading || !schema}
                    hideTitle
                    data={formData}
                    schema={schema}
                    uiSchema={uiSchema}
                    submitLabel="Speichern"
                    onSubmit={handleSubmit}
                    backLink={{ path: PathBuilder.home.verwaltung.profilHeimfinder.path }}
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const ProfilHeimfinderEditPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PROFIL_HEIMFINDER
);
