import { CollectionItemAction, SchemaActions } from 'forms/types/UiSchemaTypes';

export const useCollectionItemActions = (actions?: SchemaActions): CollectionItemAction[] => {
    if (!actions || Array.isArray(actions)) {
        return [];
    }

    return Object.keys(actions).map((key) => ({
        label: key,
        key: actions[key],
    }));
};
