import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { DetailPage } from './DetailPage';

const Index = () => {
    return (
        <DetailPage
            pathBuilder={PathBuilder.home.verwaltung.leistungsangebote}
            tablePathBuilder={PathBuilder.home.verwaltung.leistungsangebote.meldung}
        />
    );
};

export const FreigegebeneAnbieterDetailPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN_DETAILS
);
