import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { FemAntragCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useFemAntragListData = (einrichtung?: string): GetData<FemAntragCollectionResponse> => {
    return useCallback(
        ({ page, rowsPerPage, sortOrder, filters }) => {
            return backendApiService.getFemAntragCollection({
                page,
                itemsPerPage: rowsPerPage,
                einrichtung,
                orderName: sortBy(sortOrder, 'name'),
                orderEingereichtAt: sortBy(sortOrder, 'eingereichtAt'),
                jahr2: (filters?.['jahr'] || []).map((entry) => Number(entry)),
            });
        },
        [einrichtung]
    );
};
