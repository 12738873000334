export enum TABLE_ACTIONS {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    ABBRECHEN = 'ABBRECHEN',
    BEARBEITUNG_STARTEN = 'BEARBEITUNG_STARTEN',
    PLATZZAHLMELDER_AUFFORDERUNG = 'PLATZZAHLMELDER_AUFFORDERUNG',
    PLATZZAHLMELDER_ERSTELLEN = 'PLATZZAHLMELDER_ERSTELLEN',
    PLATZZAHLMELDER_EINREICHEN = 'PLATZZAHLMELDER_EINREICHEN',
}

type Action = string;
type ActionGroup = number;
export type AntragActions = Record<Action, ActionGroup>;
export const TABLE_ACTIONS_ORDER: AntragActions = {
    [TABLE_ACTIONS.VIEW]: 0,
    [TABLE_ACTIONS.EDIT]: 0,
    [TABLE_ACTIONS.ABBRECHEN]: 1,
    [TABLE_ACTIONS.BEARBEITUNG_STARTEN]: 1,
};
