import React, { useState } from 'react';

import { ExportRequestCollectionResponse } from 'api/types';
import { useAuswertungExportColumns } from 'components/DataTable/columns/useAuswertungExportColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { EXPORT_STATUS } from 'constants/exportStatus';
import { ROLE_TYPES } from 'constants/roles';
import { FormButton } from 'forms/components/FormButton';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAuswertung } from 'navigation/menuConfigs/menuAuswertung';
import { withAuthorization } from 'navigation/withAuthorization';
import { useInterval } from 'utilities/hooks/useInterval';

import { useAuswertungExportListData } from './useAuswertungExportListData';

const Index = () => {
    const [shouldUpdateTable, setShouldUpdateTable] = useState<boolean>(true);
    const [omitReload, setOmitReload] = useState<boolean>(true);
    const [serverDataChanged, setServerDataChanged] = useState<number>(0);

    const updateServerData = (omit: boolean) => {
        setOmitReload(omit);
        setServerDataChanged((prevState) => prevState + 1);
    };

    const clear = useInterval(() => updateServerData(true), 2000, shouldUpdateTable);

    const isPending = (status: EXPORT_STATUS): boolean => [EXPORT_STATUS.NEW, EXPORT_STATUS.WORKING].includes(status);

    const hasPendingExports = (data?: ExportRequestCollectionResponse) => {
        return (data?.['hydra:member'] || []).some((entry) => isPending(entry.status as EXPORT_STATUS));
    };

    const handleTableChanged = (data?: ExportRequestCollectionResponse) => {
        setShouldUpdateTable(hasPendingExports(data));
    };

    const handleClick = () => {
        updateServerData(false);
        clear();
    };

    const getData = useAuswertungExportListData();

    const columns = useAuswertungExportColumns(isPending);

    return (
        <ContentContainer>
            <StyledContainer>
                <BaseGridContainer>
                    <PageHeading title="Übersicht der Exporte" />

                    <FormButton variant="contained" onClick={handleClick}>
                        Aktualisieren
                    </FormButton>

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        onReady={(data) => handleTableChanged(data)}
                        onTableChange={({ data }) => handleTableChanged(data)}
                        serverDataChanged={serverDataChanged}
                        omitReloadTableContext={omitReload}
                        initialOptions={{
                            sortOrder: {
                                name: 'createdDate',
                                direction: 'desc',
                            },
                            custom: {
                                disableSearch: true,
                            },
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const AuswertungOverview = withAuthorization(withSideBar(Index, menuAuswertung), ROLE_TYPES.ROLLEN_AUSWERTUNG);
