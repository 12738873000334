import { useEffect, useMemo, useState } from 'react';
import { MUIDataTableOptions, MUISortOptions } from 'mui-datatables';

import { CustomHydraSearch } from 'components/DataTable/CustomHydraSearch';
import { OnSearchChanged } from 'components/DataTable/hooks/useTableSearchText';
import { OnViewColumnsChanged } from 'components/DataTable/types';
import { PathBuilderProps } from 'navigation/Paths';
import { useLocalStorage } from 'utilities/hooks/useLocalStorage';

import { OnFiltersChanged } from './useTableFilters';

type UseTableOptionsResult = {
    tableOptions: MUIDataTableOptions;
    sortOrder: MUISortOptions | undefined;
    page: number;
    rowsPerPage: number;
    updateSortOrder: (sortOrder: MUISortOptions | undefined) => void;
    updatePage: (page: number) => void;
    updateRowsPerPage: (rowsPerPage: number) => void;
};

export type OpenViewTab = { view: PathBuilderProps['view'] };

export type CustomTableOptions = {
    custom?: {
        name?: string;
        disableSearch?: boolean;
        segment?: string;
        token?: string;
        step?: string;
        pathBuilder?: PathBuilderProps;
        onCustomNavigation?: (rowData: any) => void;
        openViewTab?: OpenViewTab;
    };
};

export type TableOptions = (MUIDataTableOptions & CustomTableOptions) | undefined;

export const useTableOptions = (
    initialOptions: TableOptions,
    searchText: string,
    onFiltersChanged: OnFiltersChanged,
    onSearchChanged: OnSearchChanged,
    onViewColumnsChanged: OnViewColumnsChanged,
    name?: string
): UseTableOptionsResult => {
    const [page, setPage] = useState(initialOptions?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(initialOptions?.rowsPerPage || 10);
    const [sortOrder, setSortOrder] = useState(initialOptions?.sortOrder || undefined);

    const [sortOrderPersisted, setSortOrderPersisted] = useLocalStorage('datatable_sortOrder', {
        defaultValue: sortOrder,
        shallow: true,
        name,
    });

    const [options] = useState<MUIDataTableOptions>(() => ({
        ...defaultTableOptions,
        ...(initialOptions || {}),
        ...initialOptions?.custom,
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            if (!!initialOptions?.custom?.disableSearch) return <></>;

            return <CustomHydraSearch searchText={searchText} handleSearch={handleSearch} options={options} />;
        },
        onChangePage: (page) => {
            setPage(page);
        },
        onChangeRowsPerPage: (rowsPerPage) => {
            setPage(0);
            setRowsPerPage(rowsPerPage);
        },
        onSearchChange: (searchText) => {
            setPage(0);
            onSearchChanged(searchText);
        },
        onSearchClose: () => {
            setPage(0);
            onSearchChanged('');
        },
        onColumnSortChange: (name, direction) => {
            setPage(0);
            setSortOrderPersisted({ name, direction });
        },
        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            setPage(0);
            onFiltersChanged(changedColumn, filterList, type, changedColumnIndex, displayData);
        },
        onViewColumnsChange: (changedColumn: string, action: string) => {
            onViewColumnsChanged(changedColumn, action);
        },
    }));

    const tableOptions = useMemo(
        () => ({ ...options, page, rowsPerPage, searchText, sortOrder }),
        [options, page, rowsPerPage, searchText, sortOrder]
    );

    useEffect(() => {
        setSortOrder(sortOrderPersisted);
    }, [sortOrderPersisted]);

    return {
        tableOptions,
        sortOrder,
        page,
        rowsPerPage,
        updateSortOrder: setSortOrder,
        updatePage: setPage,
        updateRowsPerPage: setRowsPerPage,
    };
};

const defaultTableOptions: MUIDataTableOptions = {
    serverSide: true,
    page: 0,
    rowsPerPage: 10,
    searchText: '',
    searchAlwaysOpen: true,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
};
