import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { DokumentJsonld } from 'api/client';
import { useApiMeldungHinweisDokumentItem } from 'api/hooks/useApiClient';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { ROLE_TYPES } from 'constants/roles';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer, StyledDivider } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

const Index = () => {
    const { id } = useParams();
    const { data, isLoading, error } = useApiMeldungHinweisDokumentItem(id);

    const uploads = useMemo(() => (data?.upload ? [data.upload as DokumentJsonld] : []), [data]);

    return (
        <ContentContainer title="Anforderungsdokument ansehen">
            <StyledContainer>
                <Typography variant="h1">Anforderungsdokument ansehen</Typography>

                <BaseGridContainer lg={10} justifyContent="center">
                    <AppLoaderContainer isLoading={isLoading}>
                        {!error ? (
                            <>
                                <DetailsLayout.Container divide={false}>
                                    <DetailsLayout.DownloadLink value={uploads} label="Dokument" />
                                    <DetailsLayout.InfoField
                                        value={data?.einrichtungsArt}
                                        label="Art des Leistungsangebots"
                                    />
                                </DetailsLayout.Container>
                            </>
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </AppLoaderContainer>
                </BaseGridContainer>

                <StyledDivider />

                <ButtonGroupGrid backLink={{ path: PathBuilder.home.verwaltung.meldungHinweise.path }} />
            </StyledContainer>
        </ContentContainer>
    );
};

export const MeldungHinweisDokumentAnsehenPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_MELDUNG_HINWEISTEXT
);
