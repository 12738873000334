import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { info } from 'forms/ui-schemas/elements/info';
import { messageVariablesText } from 'forms/ui-schemas/email_antrag/leistungsangebot/aufforderung';

// uiSchema: Meldung Aufforderung
export const uiSchema = customVerticalLayout([
    group([
        info({ options: { withMessageKey: true } }),
        control('einrichtung'),
        control('empfaenger'),
        control('updateDeadline'),
        control('betreff'),
        control('nachricht', { withInfoIcon: true }),
        control('dokumente'),
        info({ message: messageVariablesText }),
    ]),
]);
