import React from 'react';
import { styled } from '@mui/material';

import { theme } from 'theme/theme';

export const Pflichtfeld = ({ display }: { display: boolean }) => {
    if (!display) {
        return <></>;
    }

    return (
        <PflichtfeldInfo>
            Pflichtfeld <span>*</span>
        </PflichtfeldInfo>
    );
};

const PflichtfeldInfo = styled('p')(
    () => `
    font-size: 12px;
    margin-bottom: ${theme.spacing(3)};

    & span {
        color: ${theme.palette.error.main};
    }
`
);
