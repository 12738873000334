export const leistungserbringer_table = {
    type: 'SummaryTable',
    label: 'Leistungserbringer',
    columns: 3,
    scope: '#/properties/leistungserbringer',
    entries: [
        {
            type: 'SubTable',
            level: 0,
            scope: '#/properties/stepLeistungserbringerLeistungserbringer',
            nestedScope: '#/properties/stepLeistungserbringerLeistungserbringer',
            label: '{#/properties/name}',
            entries: [
                {
                    type: 'SubTable',
                    level: 1,
                    scope: '#/properties/',
                    nestedScope: '#/properties/',
                    label: 'Daten',
                    entries: [
                        {
                            type: 'FieldRow',
                            scope: '#/properties/name',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/ansprechpartner',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/telefon',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/fax',
                        },
                        {
                            type: 'FieldRow',
                            scope: '#/properties/mail',
                        },
                    ],
                },
                {
                    type: 'SubTable',
                    level: 1,
                    scope: '#/properties/adresse/properties',
                    nestedScope: '#/properties/adresse',
                    entries: [
                        {
                            type: 'FieldRow',
                            label: 'Adresse',
                            scope: '#/properties/label',
                        },
                    ],
                },
            ],
        },
        {
            type: 'NotesRow',
            scope: '#/properties/notizLeistungserbringer',
        },
    ],
};
