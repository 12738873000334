import { DetailsLayout } from 'layout/components/DetailsLayoutComponents';

import { MeldungProps } from './MeldungDetailPage';

export const WeitereUnterlagen = ({ meldung }: MeldungProps) => {
    return (
        <>
            {meldung.data?.stepUnterlagenDokumente ? (
                <DetailsLayout.Group title="Weitere Unterlagen" variant="h2">
                    <DetailsLayout.DownloadLink value={meldung.data.stepUnterlagenDokumente} />
                </DetailsLayout.Group>
            ) : null}
        </>
    );
};
