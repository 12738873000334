import { useState } from 'react';

interface UseClipboardResponse {
    isCopied: boolean;
    copyTextToClipBoard: (text: string) => Promise<void>;
}

export const useClipboard = (): UseClipboardResponse => {
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const createTextarea = (text: string): HTMLTextAreaElement => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.top = '0';
        textarea.style.left = '0';
        textarea.style.height = '1px';
        textarea.style.width = '1px';
        textarea.style.position = 'fixed';
        textarea.style.transform = 'translateX(99999px)';

        document.documentElement.appendChild(textarea);

        textarea.focus();
        textarea.select();
        textarea.setSelectionRange(0, 99999);

        return textarea;
    };

    const writeTextToClipboard = (text: string) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }

        const textarea = createTextarea(text);

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error('Cannot copy text');
        }

        document.documentElement.removeChild(textarea);
    };

    const copyTextToClipBoard = async (text: string) => {
        await writeTextToClipboard(text);
        setIsCopied(true);

        setTimeout(() => setIsCopied(false), 1500);
    };

    return {
        isCopied,
        copyTextToClipBoard,
    };
};
