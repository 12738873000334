import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JsonForms } from '@jsonforms/react';
import { Alert } from '@mui/material';

import { useLogin } from 'api/auth';
import { ResendVerificationEmail } from 'components/ResendVerificationEmail';
import { FormButton } from 'forms/components/FormButton';
import { Pflichtfeld } from 'forms/components/Pflichtfeld';
import { renderers } from 'forms/renderers';
import { FormStateChange, Schema } from 'forms/types/UiSchemaTypes';
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { PathBuilder } from 'navigation/Paths';

interface LoginFormInterface {
    email: string;
    password: string;
}

export const LoginForm = () => {
    const login = useLogin();
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [formData, setFormData] = useState<LoginFormInterface>({ email: '', password: '' });
    const [error, setError] = useState<string | undefined>();
    const [isUserEmailVerified, setIsUserEmailVerified] = useState(true);

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setSubmitting(true);
            setError(undefined);

            login(formData)
                .then(() => navigate(PathBuilder.home.dashboard.path))
                .catch((error) => {
                    setFormData({ ...formData, password: '' });

                    switch (error.response.data.message) {
                        case 'Benutzer ist nicht verifiziert!':
                            setError('Die E-Mail-Adresse des Benutzers wurde noch nicht verifiziert.');
                            setIsUserEmailVerified(false);
                            break;
                        case 'Benutzer ist deaktiviert.':
                            setError('Der Benutzer wurde deaktiviert. Bitte kontaktieren Sie Ihren Administrator.');
                            break;
                        default:
                            setError('Login fehlgeschlagen.');
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
        // eslint-disable-next-line
        [formData]
    );

    const handleChange = (state: FormStateChange) => {
        setFormData(state.data);
    };

    const schema: Schema = {
        required: ['email', 'password'],
        properties: {
            email: {
                $id: 'loginEmail',
                type: 'string',
                title: 'E-Mail-Adresse',
                // @ts-ignore
                custom: {
                    block_prefixes: ['login_email'],
                },
            },
            password: {
                $id: 'loginPassword',
                type: 'string',
                title: 'Passwort',
                // @ts-ignore
                custom: {
                    block_prefixes: ['login_password'],
                },
            },
        },
    };

    const uiSchema = customVerticalLayout([
        group([
            inlineRowControl([control('email', { focus: true })]),
            inlineRowControl([control('password', { focus: true })]),
        ]),
    ]);

    return (
        <>
            {error && (
                <Alert severity="error">
                    {error}
                    {!isUserEmailVerified ? <ResendVerificationEmail email={formData.email} renderLink /> : null}
                </Alert>
            )}

            <Pflichtfeld display={!!schema?.required?.length} />

            <form id="anmeldung" onSubmit={handleSubmit} data-cy="login_form">
                <JsonForms
                    data={formData}
                    schema={schema}
                    uischema={uiSchema}
                    renderers={renderers}
                    onChange={handleChange}
                />
                <FormButton
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                >
                    Jetzt anmelden
                </FormButton>
            </form>
        </>
    );
};
