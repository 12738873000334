import React from 'react';
import { TableCell } from '@mui/material';

import { TableColumn } from 'forms/types/UiSchemaTypes';

interface FormTableCell {
    column: TableColumn;
    label?: string;
}

export const FormTableHeaderCell: React.FC<FormTableCell> = ({ column, label }) => {
    return <TableCell style={{ verticalAlign: 'bottom', ...column.styles }}>{label ?? column.label}</TableCell>;
};
