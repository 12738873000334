import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add, Download } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useSpecificRole } from 'api/auth';
import { GeneratedFile } from 'api/types';
import { useAbwListActions } from 'components/DataTable/actions/useAbwListActions';
import { useAbwColumns } from 'components/DataTable/columns/abw/useAbwColumns';
import { DataTableHydraServerside, TableChangeProps } from 'components/DataTable/DataTableHydraServerside';
import { sortBy } from 'components/DataTable/tableUtils';
import { DocumentDownload } from 'components/DocumentDownload';
import { ROLE_TYPES } from 'constants/roles';
import { FormButton, FormLoadingButton } from 'forms/components/FormButton';
import { useConfirmationDialogActions } from 'forms/state/useConfirmationDialogState';
import { useFormState } from 'forms/state/useFormState';
import { ButtonGroupGrid } from 'layout/ButtonGroupGrid';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { AbwInfo } from './AbwInfo';
import { useAbwListData } from './useAbwListData';

const Index = () => {
    const navigate = useNavigate();
    const { showConfirmation, closeConfirmation } = useConfirmationDialogActions();

    const pathBuilder = PathBuilder.home.verwaltung.abw;

    const { isBehoerde, isAmbulanterDienst } = useSpecificRole();

    const [showActions, setShowActions] = useState<boolean>(false);
    const [exportParams, setExportParams] = useState<TableChangeProps>({});
    const [generatedFile, setGeneratedFile] = useState<GeneratedFile | null>(null);

    const { isLoading, loadingStart, loadingEnd } = useFormState();
    const { serverDataChanged, handleAction } = useAbwListActions({ pathBuilder });

    const columns = useAbwColumns(handleAction);

    const getData = useAbwListData();

    const handleTableChange = ({ filters, sortOrder, searchText }: TableChangeProps) => {
        setExportParams({ filters, sortOrder, searchText });
    };

    const handleFileExport = () => {
        loadingStart();

        backendApiService
            .getWohngemeinschaftExport({
                qSearch: exportParams.searchText,
                orderName: sortBy(exportParams.sortOrder, 'name'),
                orderEinrichtungName: sortBy(exportParams.sortOrder, 'einrichtung'),
                orderAmbulanterDienstName: sortBy(exportParams.sortOrder, 'ambulanterDienst'),
                orderAdresseStrasse: sortBy(exportParams.sortOrder, 'anschrift'),
                orderPlaetze: sortBy(exportParams.sortOrder, 'plaetze'),
                orderBeginn: sortBy(exportParams.sortOrder, 'beginn'),
                orderCreatedAt: sortBy(exportParams.sortOrder, 'registrierungsDatum'),
            })
            .then((response) => {
                setGeneratedFile(response);
            })
            .finally(() => loadingEnd());
    };

    const handleAbwAdd = () => {
        showConfirmation({
            alertText:
                'Wollen Sie eine Wohngemeinschaft anzeigen, in der Sie Leistungen erbringen? Dann drücken Sie auf „Ok“. Sind Sie selbst Anbieter der Wohngemeinschaft, registrieren Sie diese bitte als eigenes Leistungsangebot, indem Sie im Auswahlmenü unter Leistungsangebot „Leistungsangebot hinzufügen“ wählen.',
            confirmLabel: 'Ok',
            denyLabel: 'Abbrechen',
            confirmAction: () => {
                navigate(pathBuilder.create);
                closeConfirmation();
            },
            denyAction: closeConfirmation,
        });
    };

    return (
        <ContentContainer title="Angezeigte Betreuung in Wohngemeinschaften: Übersicht">
            <StyledContainer>
                <Typography variant="h1">Angezeigte Betreuung in Wohngemeinschaften: Übersicht</Typography>

                <BaseGridContainer>
                    <AbwInfo />

                    <DataTableHydraServerside
                        onTableChange={handleTableChange}
                        onReady={(data) => {
                            if (data) {
                                setShowActions(true);
                            }
                        }}
                        getColumns={columns}
                        getData={getData}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                        initialOptions={{
                            viewColumns: true,
                            sortOrder: {
                                name: 'name',
                                direction: 'asc',
                            },
                            custom: {
                                pathBuilder,
                            },
                        }}
                    />

                    {showActions ? (
                        <ButtonGroupGrid>
                            {isBehoerde ? (
                                <>
                                    <DocumentDownload generatedFile={generatedFile} />

                                    <FormLoadingButton
                                        loading={isLoading}
                                        loadingPosition="end"
                                        endIcon={<Download />}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        data-cy="BehoerdeExportButton"
                                        onClick={handleFileExport}
                                    >
                                        Exportieren
                                    </FormLoadingButton>
                                </>
                            ) : null}

                            {isAmbulanterDienst ? (
                                <>
                                    <FormButton
                                        endIcon={<Add />}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        data-cy="ABW-AddButton"
                                        onClick={handleAbwAdd}
                                    >
                                        Neue anzuzeigende Betreuung registrieren
                                    </FormButton>
                                </>
                            ) : null}
                        </ButtonGroupGrid>
                    ) : null}
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const AbwOverviewPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_BENUTZER_WOHNGEMEINSCHAFTEN
);
