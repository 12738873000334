import { range } from 'lodash';
import { MUIDataTableColumnOptions } from 'mui-datatables';

import { CheckboxesFilter } from 'components/DataTable/filters/CheckboxesFilter';

export const JahrFilterOptions = (columnLabel: string, filterOptions?: {}): MUIDataTableColumnOptions => ({
    customFilterListOptions: {
        render: (value) => value.map((v: string) => `${columnLabel}: ${v}`),
    },
    filterOptions: {
        names: filterOptions
            ? Object.values(filterOptions).map((o) => String(o))
            : range(2015, new Date().getFullYear() + 2).map((jahr) => String(jahr)),
        display: (filterList, onChange, index, column, filterData) => {
            return (
                <CheckboxesFilter
                    index={index}
                    column={column}
                    filterData={filterData[index]}
                    filterValue={filterList[index]}
                    onChange={onChange}
                />
            );
        },
    },
    filterType: 'custom',
});
