import React, { ReactNode } from 'react';
import { TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';

import { RequiredFlag } from './RequiredFlag';

export type DatePickerBlurEvent = React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>;

type DatePickerProps = Omit<DesktopDatePickerProps<Date | string>, 'renderInput' | 'inputFormat'> & {
    error?: boolean;
    onBlur?: (event: DatePickerBlurEvent) => void;
    helperText?: string | ReactNode;
    fullWidth?: boolean;
    size?: TextFieldProps['size'];
    inputProps?: TextFieldProps['inputProps'];
};

const fieldDayPlaceholder = () => 'tt';
const fieldMonthPlaceholder = () => 'mm';
const fieldYearPlaceholder = () => 'jjjj';

export const DatePicker: React.FC<DatePickerProps> = ({
    error,
    helperText,
    onBlur,
    fullWidth,
    size,
    inputProps,
    ...props
}) => {
    return (
        <DesktopDatePicker
            {...props}
            format="dd.MM.yyyy"
            localeText={{
                fieldDayPlaceholder,
                fieldMonthPlaceholder,
                fieldYearPlaceholder,
            }}
            label={
                <>
                    <span>{inputProps?.label}</span>
                    {inputProps?.required && <RequiredFlag />}
                </>
            }
            slotProps={{
                actionBar: { actions: inputProps?.required ? [] : ['clear'] },
                textField: {
                    error,
                    helperText,
                    onBlur: (event) => onBlur?.(event),
                    fullWidth,
                    size,
                    inputProps: {
                        ...inputProps,
                        placeholder: 'tt.mm.jjjj',
                        required: inputProps?.required,
                    },
                },
            }}
        />
    );
};
