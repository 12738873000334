import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';

import { backendApiService } from 'api/ApiService';
import { useApiTraegerDtoItem } from 'api/hooks/useApiClient';
import { TraegerCreateFormData, TraegerFormData } from 'api/types';
import { useSchemaFromJsonLdContext } from 'forms/hooks/useSchemaFromJsonLdContext';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { PathBuilder } from 'navigation/Paths';

import { uiSchema } from './formConfig';

const initialFormData: TraegerFormData = {
    adressDaten: undefined,
    anschriftTyp: undefined,
    ansprechpartner: '',
    email: undefined,
    fax: '',
    name: undefined,
    postfachBezeichnung: undefined,
    postfachOrt: undefined,
    postfachPlz: undefined,
    telefon: undefined,
    traegerverband: undefined,
    typ: undefined,
};

interface TraegerFormProps {
    title: string;
    submitLabel: string;
    messages: {
        success: string;
        error: string;
    };
}

export const TraegerForm = ({ title, submitLabel, messages }: TraegerFormProps) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { submitStart, submitEnd } = useFormState();
    const [formData, setFormData] = useState<TraegerFormData | null>(null);
    const { isLoading, data, error } = useApiTraegerDtoItem(id);
    const { schema } = useSchemaFromJsonLdContext('TraegerDto', formData);

    const handleSubmit = (updatedFormData: TraegerFormData) => {
        submitStart();

        if (id) {
            return backendApiService.patchTraegerDto(String(id), updatedFormData).catch(() => {
                submitEnd();
                return Promise.reject();
            });
        }

        return backendApiService.createTraegerDtoItem(updatedFormData as TraegerCreateFormData).catch(() => {
            submitEnd();
            return Promise.reject();
        });
    };

    useEffect(() => {
        if (!isLoading && schema) {
            setFormData(data || initialFormData);
        }
    }, [data, isLoading, schema]);

    return (
        <>
            <ContentContainer title={title}>
                <StyledContainer>
                    <Typography variant="h1">{title}</Typography>

                    <BaseGridContainer lg={8}>
                        {!error ? (
                            <SimpleForm
                                hideTitle
                                isLoading={isLoading || !schema}
                                data={formData}
                                schema={schema}
                                uiSchema={uiSchema}
                                messages={messages}
                                onSubmit={handleSubmit}
                                submitLabel={submitLabel}
                                onAfterSubmit={() => {
                                    if (!id) {
                                        navigate(PathBuilder.home.verwaltung.traeger.path);
                                    }
                                }}
                                backLink={{
                                    path: PathBuilder.home.verwaltung.traeger.path,
                                    title: 'Zur Übersicht',
                                }}
                            />
                        ) : (
                            <Alert severity="error">Beim Laden der Daten ist ein Fehler aufgetreten.</Alert>
                        )}
                    </BaseGridContainer>
                </StyledContainer>
            </ContentContainer>
        </>
    );
};
