import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';

import { STATUS } from 'constants/antragStatus';
import { UuidMappingFunction } from 'forms/hooks/useUuidValue';
import { FormUuidMap } from 'forms/types/UiSchemaTypes';

import { Message } from './useMessages';

export interface FormStateData {
    isInProgress: boolean;
    isPersisting: boolean;
    isSubmitting: boolean;
    isLoading: boolean;
    formSubmitted: boolean | undefined;
    requestMessage: Message | undefined;
    loadingError: string | undefined;
    einrichtungArt: string | undefined;
    einrichtungId: number | undefined;
    antragId: number | undefined;
    antragToken: string | undefined;
    antragStatus: string | undefined;
    uuidMap: FormUuidMap | undefined;
}

export interface FormStateActions {
    submitStart: (persist?: boolean) => void;
    submitEnd: () => void;

    progressStart: () => void;
    progressEnd: () => void;

    loadingStart: () => void;
    loadingEnd: () => void;

    setRequestMessage: (message: Message | undefined) => string | undefined;
    setLoadingError: (error: string | undefined) => void;

    setFormSubmitted: (submitted?: boolean) => void;
    setEinrichtungArt: (einrichtungArt: string | undefined) => void;
    setEinrichtungId: (einrichtungId: number | undefined) => void;
    setAntragId: (antragId: number | undefined) => void;
    setAntragToken: (antragToken: string | undefined) => void;
    setAntragStatus: (antragStatus: STATUS | undefined) => void;
    setUuidMap: (uuidMap: FormUuidMap | undefined) => void;
    mapUuid: UuidMappingFunction;
}

export const FormStateContext = createContext<{
    formStateData: FormStateData;
    setFormStateData: Dispatch<SetStateAction<FormStateData>>;
}>({
    formStateData: {
        isInProgress: false,
        isPersisting: false,
        isSubmitting: false,
        isLoading: false,
        formSubmitted: false,
        requestMessage: undefined,
        loadingError: undefined,
        einrichtungArt: undefined,
        einrichtungId: undefined,
        antragId: undefined,
        antragToken: undefined,
        antragStatus: undefined,
        uuidMap: undefined,
    },
    setFormStateData: (prev) => prev,
});

export interface FormStateProviderProps {
    initialValue?: Partial<FormStateData>;
    children?: ReactNode;
}

export const FormStateProvider: React.FC<FormStateProviderProps> = ({ children, initialValue = {} }) => {
    const [formStateData, setFormStateData] = useState<FormStateData>({
        isInProgress: false,
        isPersisting: false,
        isSubmitting: false,
        isLoading: false,
        formSubmitted: false,
        loadingError: undefined,
        einrichtungArt: undefined,
        einrichtungId: undefined,
        antragId: undefined,
        antragToken: undefined,
        antragStatus: undefined,
        uuidMap: initialValue.uuidMap,
        requestMessage: undefined,
    });

    const ignoreKeys = useCallback((e: KeyboardEvent) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        return false;
    }, []);

    useEffect(() => {
        if (formStateData.isSubmitting) {
            window.addEventListener('keydown', ignoreKeys);
        } else {
            window.removeEventListener('keydown', ignoreKeys);
        }
        return () => {
            window.removeEventListener('keydown', ignoreKeys);
        };
    }, [formStateData.isSubmitting, ignoreKeys]);

    return (
        <FormStateContext.Provider value={{ formStateData, setFormStateData }}>{children}</FormStateContext.Provider>
    );
};
