import React, { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';

import { backendApiService } from 'api/ApiService';
import { InlineResponseBenutzerJsonldBenutzerRead } from 'api/responses/InlineResponse';
import { BenutzerResponse } from 'api/types';
import { SelectOption, Typeahead } from 'forms/components/Typeahead';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { displayBenutzerName, getBenutzerProfileStatus } from 'utilities';

import { RowControl } from './RowControl';

const ImpersonateAutocompleteControlComponent: React.FC<CustomControlProps<SelectOption | null>> = ({
    data,
    path,
    label,
    required,
    handleChange,
    schema,
    uischema,
    showFieldNumberLabels,
    gridLayout,
    id,
}) => {
    const handleValueChange = useCallback(
        (id: SelectOption | null) => {
            handleChange(path, id, true);
        },
        [handleChange, path]
    );

    const getData = (value: string, options: AxiosRequestConfig): Promise<InlineResponseBenutzerJsonldBenutzerRead> => {
        return backendApiService.getBenutzerCollection({
            page: 1,
            itemsPerPage: 10,
            rolle: schema.custom?.rollen && schema.custom.rollen[0],
            rolle2: schema.custom?.rollen && schema.custom.rollen,
            landschaftsverband: schema.custom?.landschaftsverband,
            qImpersonateSearch: value,
            options: { signal: options.signal },
        });
    };

    const getOption = (benutzer: BenutzerResponse): SelectOption => ({
        id: benutzer.id as number,
        name: benutzer.id + ' - ' + benutzer.email + ' - ' + displayBenutzerName(benutzer),
        status: getBenutzerProfileStatus(benutzer),
    });

    return (
        <RowControl
            name={path}
            label={label}
            required={required}
            showFieldNumberLabels={showFieldNumberLabels}
            gridLayout={gridLayout}
            labelForId={id}
        >
            <Typeahead
                id={id}
                data={data}
                schema={schema}
                uischema={uischema}
                path={path}
                multiple={false}
                getData={getData}
                getOption={getOption}
                onChange={handleValueChange}
                displayBenutzerStatus
            />
        </RowControl>
    );
};

export const ImpersonateAutocompleteControl = withCustomControlProps(ImpersonateAutocompleteControlComponent);
