import { useParams } from 'react-router-dom';

import { AntragType } from 'constants/antragTypes';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { EmailAntragForm } from 'pages/EmailAntragForm';

const Index = () => {
    const { token } = useParams();

    return (
        <EmailAntragForm
            antragType={AntragType.PLATZZAHLMELDER}
            pathBuilder={PathBuilder.home.verwaltung.platzmelder}
            cancelPath={PathBuilder.home.verwaltung.platzmelder.token(token!)}
        />
    );
};

export const PlatzmelderAufforderungPage = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_PLATZMELDER_AUFFORDERUNG
);
