import React, { ElementType, useEffect, useMemo, useRef } from 'react';
import { Close } from '@mui/icons-material';
import { Alert, Box, Button, IconButton, styled, Tooltip, Typography } from '@mui/material';

import { Message } from 'forms/state/useMessages';

export const MessageAlert: React.FC<{
    message: Message;
    closeMessage: (uuid: string) => void;
}> = ({ message, closeMessage }) => {
    const ariaMessageSeverity: string = useMemo(() => {
        switch (message.type) {
            case 'warning':
                return 'Achtung: ';
            case 'error':
                return 'Fehler: ';
            case 'success':
                return 'Aktion erfolgreich: ';
            default:
                return 'Information: ';
        }
    }, [message]);

    const closeButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, []);

    return (
        <Alert severity={message.type}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <Box>
                    <MessageTypography color={message.type === 'error' ? 'error' : 'textPrimary'} component="p">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: `<span class="sr-only">${ariaMessageSeverity}</span> ${message.text}`,
                            }}
                        />
                    </MessageTypography>
                </Box>

                {message.action ? (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => message.action?.actionMethod(message.uuid)}
                        aria-label={message.action.actionTitle}
                    >
                        {message.action.actionTitle}
                    </Button>
                ) : null}

                {message.closable ? (
                    <Tooltip title="Schließen" ref={closeButtonRef}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                closeMessage(message.uuid);
                            }}
                            aria-label="Nachricht verbergen"
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>
        </Alert>
    );
};

const MessageTypography = styled(Typography)<{ component?: ElementType }>(() => ({
    whiteSpace: 'pre-wrap',
}));
