import { RankedTester, rankWith, Tester } from '@jsonforms/core';
import { JsonSchema } from '@jsonforms/core/src/models/jsonSchema';
import { UISchemaElement } from '@jsonforms/core/src/models/uischema';

import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';

export const isJaNein: Tester = (uischema: UISchemaElement | any, schema: JsonSchema | any): boolean => {
    if (uischema?.type !== 'Control') return false;

    return Boolean(getFieldSchemaByScope(uischema.scope, schema)?.custom?.block_prefixes?.includes('ja_nein'));
};

export const JaNeinTester: RankedTester = rankWith(6500, isJaNein);
