import React from 'react';
import { styled, Typography } from '@mui/material';

import { Link } from 'elements/Link';
import { transientOptions } from 'theme/utils';

interface LogoPfadInvestProps {
    height?: number;
}

const defaultSize = 32;

export const LogoLink = styled(Link)(() => ({
    '&:hover': {
        textDecoration: 'none',
    },
}));

const LogoTypography = styled(
    Typography,
    transientOptions
)<{
    $height: number;
    component?: React.ElementType;
}>(({ $height }) => ({
    fontSize: `${$height}px`,
    fontFamily: 'Arial',
    fontWeight: 'bold',
}));

export const Logo = ({ height }: LogoPfadInvestProps) => {
    return (
        <>
            <LogoTypography $height={height || defaultSize} color="secondary" component="span">
                PfAD
            </LogoTypography>
            <LogoTypography $height={(height || defaultSize) / 2.5} color="#233755" component="span">
                .wtg
            </LogoTypography>
        </>
    );
};
