import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { useSentry } from 'components/Sentry/useSentry';
import { Page } from 'layout/Page';
import { BarrierefreiheitPage } from 'pages/Barrierefreiheit';
import { DashboardPage } from 'pages/Dashboard/DashboardPage';
import { DatenschutzPage } from 'pages/Datenschutz';
import { Error404Page } from 'pages/Error404';
import { HilfePage } from 'pages/Hilfe';
import { HomePage } from 'pages/Home';
import { ImpressumPage } from 'pages/Impressum';
import { Nachricht } from 'pages/Nachrichten/Nachricht';
import { NachrichtenOverview } from 'pages/Nachrichten/NachrichtenOverview';
import { AccountDeactivate } from 'pages/Profil/AccountDeactivate';
import { BenutzerdatenPage } from 'pages/Profil/Benutzerdaten';
import { EmailBestaetigenPage } from 'pages/Profil/Benutzerdaten/EmailBestaetigen';
import { PasswortResetPage } from 'pages/Profil/PasswortReset';
import { RegistrierungPage } from 'pages/Registrierung';
import { Success } from 'pages/Registrierung/Success';
import { VerifyEmail } from 'pages/Registrierung/VerifyEmail';

import {
    PATH_BARRIEREFREIHEIT,
    PATH_DASHBOARD,
    PATH_DATENSCHUTZ,
    PATH_HILFE,
    PATH_HOME,
    PATH_IMPRESSUM,
    PATH_NACHRICHTEN,
    PATH_NACHRICHTEN_ANSEHEN,
    PATH_PROFIL,
    PATH_PROFIL_ACCOUNT_DEACTIVATE,
    PATH_PROFIL_BENUTZERDATEN,
    PATH_PROFIL_EMAIL_CONFIRM,
    PATH_PROFIL_PASSWORT_RESET,
    PATH_REGISTRIERUNG,
    PATH_REGISTRIERUNG_EMAIL_VERIFY,
    PATH_REGISTRIERUNG_SUCCESS,
} from './Paths';
import { RoutingAufgaben } from './RoutingAufgaben';
import { RoutingAuswertung } from './RoutingAuswertung';
import { RoutingVerwaltung } from './RoutingVerwaltung';

export const Routing = () => {
    const { SentryRoutes } = useSentry();

    return (
        <SentryRoutes>
            <Route path={PATH_HOME} element={<Page />}>
                <Route index element={<HomePage />} />
                <Route path={PATH_HILFE} element={<HilfePage />} />
                <Route path={PATH_DATENSCHUTZ} element={<DatenschutzPage />} />
                <Route path={PATH_IMPRESSUM} element={<ImpressumPage />} />
                <Route path={PATH_BARRIEREFREIHEIT} element={<BarrierefreiheitPage />} />
                <Route path={PATH_DASHBOARD} element={<DashboardPage />} />

                <Route path={PATH_NACHRICHTEN}>
                    <Route path=":id">
                        <Route path={PATH_NACHRICHTEN_ANSEHEN} element={<Nachricht />} />
                        <Route index element={<Navigate to={PATH_NACHRICHTEN_ANSEHEN} replace />} />
                    </Route>
                    <Route index element={<NachrichtenOverview />} />
                </Route>

                <Route path={PATH_REGISTRIERUNG}>
                    <Route path={PATH_REGISTRIERUNG_SUCCESS} element={<Success />} />
                    <Route path={PATH_REGISTRIERUNG_EMAIL_VERIFY} element={<VerifyEmail />} />
                    <Route index element={<RegistrierungPage />} />
                </Route>

                <Route
                    path="*"
                    element={
                        <>
                            <RoutingAufgaben />
                            <RoutingVerwaltung />
                            <RoutingAuswertung />
                        </>
                    }
                />

                <Route path={PATH_PROFIL}>
                    <Route path={PATH_PROFIL_ACCOUNT_DEACTIVATE} element={<AccountDeactivate />} />
                    <Route path={PATH_PROFIL_PASSWORT_RESET} element={<PasswortResetPage />} />

                    <Route path={PATH_PROFIL_BENUTZERDATEN}>
                        <Route index element={<BenutzerdatenPage />} />
                        <Route path={PATH_PROFIL_EMAIL_CONFIRM} element={<EmailBestaetigenPage />} />
                    </Route>
                </Route>

                <Route path="*" element={<Error404Page />} />
            </Route>
        </SentryRoutes>
    );
};
