import React, { ComponentType, useEffect, useMemo } from 'react';
import { ControlProps } from '@jsonforms/core';
import { isFunction } from 'lodash';

import { ComponentError, useErrors } from 'forms/hooks/useErrors';
import { withControlProps } from 'forms/hooks/withJsonFormProps';
import { useFormState } from 'forms/state/useFormState';
import { FormConfig, FormStateValue, GridLayoutConfig, Schema, UiSchemaType } from 'forms/types/UiSchemaTypes';

import { useJsonFormsState } from './useJsonFormsState';
import { useReadonly } from './useReadonly';
import { useRequiredByRule } from './useRequiredByRule';
import { useUnit } from './useUnit';

export interface CustomControlOptions {
    showErrorsImmediately: boolean;
}

export type CustomControlPropsIn<T = FormStateValue, S extends UiSchemaType = UiSchemaType> = CustomControlProps<
    T,
    S
> & {
    errors: string | ComponentError[];
};

export type CustomControlProps<T = FormStateValue, S extends UiSchemaType = UiSchemaType> = Omit<
    ControlProps,
    'visible' | 'errors' | 'data' | 'handleChange' | 'handleBlur' | 'schema' | 'uischema' | 'config'
> & {
    visible?: boolean;
    hasErrors: boolean;
    errors: ComponentError[];
    data: T;
    unit?: string;
    onTouched: () => void;
    disabled: boolean;
    readonly: boolean;
    formula?: string;
    schema: Schema;
    config: FormConfig;
    uischema: S;
    handleChange: (path: string, data: T | undefined | null, validate?: boolean) => void;
    handleBlur: () => void;
    showFieldNumberLabels?: boolean;
    gridLayout?: GridLayoutConfig;
    labelVisuallyHidden?: boolean;
};

export const withCustomControlPropsHOC = <T extends FormStateValue, S extends UiSchemaType>(
    Component: React.FC<CustomControlPropsIn<T, S>>,
    options?: CustomControlOptions
): React.FC<CustomControlProps<T, S>> => {
    return ({
        visible,
        rootSchema,
        schema,
        uischema,
        config,
        path,
        handleChange,
        errors: jsonFormError,
        required,
        data,
        ...others
    }) => {
        const { missingProperty, setMissingProperty } = useRequiredByRule();

        const { formSubmitted } = useFormState();
        const { isReadonly, formState } = useJsonFormsState();
        const unit = useUnit(schema as Schema);
        const formula = (schema as Schema)?.custom?.formula;
        const readonly = useReadonly(schema as Schema, config);
        const { hasErrors, errors, setTouched } = useErrors({
            config,
            path,
            jsonFormError,
            immediately:
                formSubmitted ||
                options?.showErrorsImmediately ||
                ((!Array.isArray(data) || data.length > 0) && Boolean(data)),
        });

        const isRequired = useMemo(() => {
            if (missingProperty.length && path.length) {
                return missingProperty === path;
            }

            return required;
        }, [missingProperty, path, required]);

        const onChange = (path: string, data: T | undefined | null, validate?: boolean) => {
            if (readonly || isReadonly) return;

            setTouched(true);

            if (validate && isFunction(config.requestValidate)) {
                config.requestValidate((schema as Schema).custom?.model_path);
            }

            handleChange(path, data);
        };

        const validate = () => {
            setTouched(true);
            isFunction(config.validate) && config.validate(schema.custom?.model_path);
        };

        useEffect(() => {
            setMissingProperty(formState, path);
        }, [formState, path, setMissingProperty]);

        useEffect(() => {
            setTouched(!!formSubmitted);
        }, [formSubmitted, rootSchema, setTouched]);

        useEffect(() => {
            setTouched(!!(readonly || isReadonly));
        }, [isReadonly, readonly, setTouched]);

        if (!visible) {
            return <></>;
        }

        return (
            <Component
                {...others}
                rootSchema={rootSchema}
                hasErrors={hasErrors}
                errors={errors}
                unit={unit}
                formula={formula}
                data={data}
                schema={schema}
                uischema={uischema}
                config={config}
                readonly={readonly || !!isReadonly}
                handleChange={onChange}
                handleBlur={validate}
                required={isRequired}
                onTouched={() => {
                    if (readonly || !!isReadonly) return;

                    setTouched(true);
                }}
                path={path}
                showFieldNumberLabels={config.showFieldNumberLabels}
                gridLayout={(uischema as UiSchemaType).gridLayout || config.gridLayout}
            />
        );
    };
};

export const withCustomControlProps = <T extends FormStateValue, S extends UiSchemaType>(
    Component: React.FC<CustomControlProps<T, S>>,
    options?: CustomControlOptions
): ComponentType<CustomControlProps<T, S>> =>
    withControlProps<T, S>(withCustomControlPropsHOC<T, S>(Component, options));
