import { useCallback } from 'react';

import { backendApiService } from 'api/ApiService';
import { DeletecObjectCollectionResponse } from 'api/types';
import { GetData } from 'components/DataTable/hooks/useTableData';
import { sortBy } from 'components/DataTable/tableUtils';

export const useDeletedObjectListData = (confirmed?: boolean): GetData<DeletecObjectCollectionResponse> => {
    return useCallback(
        ({ page, rowsPerPage, searchText, sortOrder }) => {
            return backendApiService.getDeletedObjectCollection({
                page,
                qSearch: searchText,
                itemsPerPage: rowsPerPage || 9999,
                confirmed,
                orderByNrwKey: sortBy(sortOrder, 'nrwKey'),
                orderByArt: sortBy(sortOrder, 'art'),
                orderByName: sortBy(sortOrder, 'name'),
                orderByErstinbetriebnahme: sortBy(sortOrder, 'erstinbetriebnahme'),
                orderByDeletedAt: sortBy(sortOrder, 'deletedAt'),
                orderByDeletedBy: sortBy(sortOrder, 'deletedBy'),
            });
        },
        [confirmed]
    );
};
