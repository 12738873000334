import { useMemo } from 'react';

import { useApiBenutzerCollection } from 'api/hooks/useApiClient';
import { ROLLEN } from 'constants/roles';
import { displayBenutzerName } from 'utilities';

export type BenutzerOptions = Record<string, string>;

interface UseBenutzerOptionsResponse {
    isLoading?: boolean;
    options: BenutzerOptions;
}

export const useBenutzerOptions = (role: ROLLEN, activeOnly?: boolean): UseBenutzerOptionsResponse => {
    const { isLoading, data } = useApiBenutzerCollection({
        page: 1,
        itemsPerPage: 999,
        existsDeaktiviertAt: activeOnly === true ? false : undefined,
        rolle: role,
    });

    const options: BenutzerOptions = useMemo(() => {
        if (!data) return {};
        return data['hydra:member'].reduce(
            (acc, curr) => ({
                ...acc,
                [String(curr.id)]: displayBenutzerName(curr),
            }),
            {}
        );
    }, [data]);

    return { isLoading, options };
};
