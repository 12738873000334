import * as React from 'react';
import { createRef, CSSProperties, MutableRefObject, useEffect, useMemo } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, styled, Tooltip } from '@mui/material';

import { buildPath } from 'forms/utilities/SchemaUtils';
import { getFileExtension, getReadableFileSize } from 'utilities/Utils';

export type UploadedDocumentFile = {
    fileName?: string | null;
    contentUrl?: string | null;
    originalName?: string | null;
    fileSize?: string | number | null;
    accessRights?: boolean | null;
    mimeType?: string | null;
};

interface UploadedDocumentProps {
    fileData: UploadedDocumentFile;
    onRemove?: () => void;
    disabled?: boolean;
    readonly?: boolean;
    style?: CSSProperties;
    autoFocus?: MutableRefObject<boolean>;
}

export const UploadedDocument: React.FC<UploadedDocumentProps> = ({
    fileData,
    onRemove,
    disabled = false,
    readonly = false,
    style,
    autoFocus,
}) => {
    const ref = createRef<HTMLAnchorElement>();

    const hasAccessRights = useMemo(() => fileData?.accessRights !== undefined && fileData.accessRights, [fileData]);

    useEffect(() => {
        const autoFocusRef = autoFocus;

        if (autoFocusRef?.current) {
            ref.current?.focus();
            autoFocusRef.current = false;
        }
    }, [ref, autoFocus]);

    return (
        <ValueContainer style={style}>
            {hasAccessRights ? (
                <LinkText
                    href={buildPath(fileData?.contentUrl)}
                    target="_blank"
                    rel="noreferrer"
                    download={fileData?.originalName}
                    ref={ref}
                    aria-label={`${fileData?.originalName} (${getReadableFileSize(
                        Number(fileData?.fileSize)
                    )}, ${getFileExtension(fileData?.mimeType)})`}
                >
                    {fileData?.originalName}
                </LinkText>
            ) : (
                <span className="no-access-rights">{fileData?.originalName}</span>
            )}

            {!readonly && hasAccessRights ? (
                <Tooltip title="Dokument entfernen">
                    <IconButton
                        size="small"
                        component="a"
                        onClick={onRemove}
                        disabled={disabled}
                        aria-label="Dokument entfernen"
                        aria-disabled={disabled}
                    >
                        <DeleteForeverIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            ) : null}
        </ValueContainer>
    );
};

const ValueContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 40,
    '& *:not(.no-access-rights)': {
        cursor: 'pointer',
    },
}));

const LinkText = styled('a')(() => ({
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));
