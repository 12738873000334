import React, { useMemo } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';

import { RequiredFlag } from 'forms/components/RequiredFlag';
import { FormAddButton } from 'forms/controls/components/AddLink';
import { ErrorList } from 'forms/controls/components/ErrorList';
import { FormDialog } from 'forms/controls/components/FormDialog';
import { FormTable } from 'forms/controls/components/FormTable';
import { useDisabled } from 'forms/hooks/useDisabled';
import { ComponentError } from 'forms/hooks/useErrors';
import { useFormTableData } from 'forms/hooks/useFormTableData';
import { useFormTableSchema } from 'forms/hooks/useFormTableSchema';
import { useJsonFormsState } from 'forms/hooks/useJsonFormsState';
import { CustomControlProps, withCustomControlProps } from 'forms/hooks/withCustomControlProps';
import { FormStateProvider } from 'forms/state/FormStateProvider';
import { useFormState } from 'forms/state/useFormState';
import { ListControlWithModalFormType, Schema } from 'forms/types/UiSchemaTypes';
import { getFieldSchemaByScope } from 'forms/utilities/SchemaUtils';
import { theme } from 'theme/theme';

import { useDependentFields } from './hooks/useDependentFields';
import { useFormAddButtons } from './hooks/useFormAddButtons';
import { useModalFormControlData } from './hooks/useModalFormControlData';

export const ListeMitModalFormularControlComponent: React.FC<
    Omit<CustomControlProps, 'uischema'> & { uischema: ListControlWithModalFormType }
> = ({ uischema, schema, data, required, path }) => {
    const disabled = useDisabled();
    const { config, isReadonly } = useJsonFormsState();
    const { progressStart, progressEnd } = useFormState();
    const tableData = useFormTableData(data, uischema, config.errors);
    const tableSchema = useFormTableSchema(schema, uischema.scopes);
    const dependentFields = useDependentFields(schema, uischema.scopes);

    const componentErrors: Array<ComponentError> = useMemo(() => {
        const errorKeys = Object.keys(config.errors || {});

        if (errorKeys.length && errorKeys.includes(uischema.field ?? '')) {
            return errorKeys
                .flatMap((key) => config.errors?.[key])
                .map(
                    (error, index) =>
                        ({
                            message: error,
                            path: uischema.field,
                        } as ComponentError)
                );
        }

        return [];
    }, [config.errors, uischema.field]);

    const { dialogFormData, prepareModal, clearModal } = useModalFormControlData(config, uischema, schema);

    const listTitle =
        uischema?.options?.noLabel === undefined
            ? getFieldSchemaByScope(uischema?.labelScope, schema as Schema)?.title
            : null;

    const loadData = async () => {
        progressStart();
        config.reloadData && (await config.reloadData(dependentFields));
        progressEnd();
    };

    const onView = (index: number) => {
        onEdit(index, true);
    };

    const onEdit = (index: number, readonly = false) => prepareModal({ ...tableData[index], readonly, action: null });

    const onNew = (scope: string) => {
        prepareModal({ scope, isNew: true, action: null });
    };

    const onSave = async () => {
        await loadData();
        clearModal();
    };

    const onDelete = (index: number) => {
        config.deleteObjectTypeItem && config.deleteObjectTypeItem(tableData[index].uuid).then(() => loadData());
    };

    const onCustomAction = (index: number, action: string) => {
        prepareModal({ ...tableData[index], action });
    };

    const addButtons = useFormAddButtons(uischema.scopes, uischema.addButtonLabels, schema);

    const isRequired = useMemo(
        () => required || schema?.required?.some((field) => dependentFields.includes(field)),
        [dependentFields, required, schema]
    );

    const hasScopesInSchema = useMemo(
        () => uischema.scopes.every((scope) => getFieldSchemaByScope(scope, schema) !== undefined),
        [schema, uischema.scopes]
    );

    return (
        <>
            {hasScopesInSchema ? (
                <>
                    {listTitle ? (
                        <ListHeader variant="h2" gutterBottom>
                            {listTitle}
                            {isRequired && <RequiredFlag />}
                        </ListHeader>
                    ) : null}

                    <Grid container>
                        <Grid item xs={12}>
                            <FormTable
                                readonly={isReadonly}
                                data={tableData}
                                schema={tableSchema}
                                uischema={uischema}
                                onView={onView}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onCustomAction={onCustomAction}
                                deleteAlertText={(index: number) =>
                                    uischema.deleteAlertTexts[uischema.scopes.indexOf(tableData[index].scope)] ||
                                    uischema.deleteAlertTexts[0]
                                }
                                deleteConfirmationLabel={(index: number) =>
                                    uischema.deleteConfirmationLabels[
                                        uischema.scopes.indexOf(tableData[index].scope)
                                    ] || uischema.deleteConfirmationLabels[0]
                                }
                                disabled={disabled}
                            />
                        </Grid>

                        {!isReadonly ? (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    paddingBottom: theme.spacing(1.5),
                                }}
                            >
                                {addButtons.map((addButton, i) => (
                                    <FormAddButton
                                        key={i}
                                        data-cy={'FormAddButton'}
                                        disabled={disabled}
                                        label={addButton.label}
                                        onClick={() => onNew(addButton.scope)}
                                    />
                                ))}

                                <FormStateProvider>
                                    <FormDialog dialogData={dialogFormData} onSave={onSave} onClose={clearModal} />
                                </FormStateProvider>
                            </Grid>
                        ) : null}

                        {componentErrors.length ? (
                            <ErrorListWrapper>
                                <ErrorList errors={componentErrors} />
                            </ErrorListWrapper>
                        ) : null}
                    </Grid>
                </>
            ) : null}
        </>
    );
};

const ListHeader = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
}));

const ErrorListWrapper = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
}));

// @ts-ignore
export const ListeMitModalFormularControl = withCustomControlProps(ListeMitModalFormularControlComponent);
