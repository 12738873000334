import React, { useMemo } from 'react';

import { useSpecificRole } from 'api/auth';
import { STATUS } from 'constants/antragStatus';
import { ROLE_TYPES } from 'constants/roles';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { LeistungsangeboteTable } from 'pages/Leistungsangebote/components/LeistungsangeboteTable';

export const Index = () => {
    const { isBehoerde, isSupport } = useSpecificRole();

    const tableTitle = useMemo(
        () => (isBehoerde || isSupport ? 'Leistungsangebote' : 'Freigegebene Leistungsangebote'),
        [isBehoerde, isSupport]
    );

    return (
        <ContentContainer title={tableTitle}>
            <StyledContainer>
                <LeistungsangeboteTable
                    title={tableTitle}
                    status={STATUS.FREIGEGEBEN}
                    openViewTab={
                        isSupport ? { view: PathBuilder.home.verwaltung.leistungsangebote.freigegeben.view } : undefined
                    }
                />
            </StyledContainer>
        </ContentContainer>
    );
};

export const FreigegebeneOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LEISTUNGSANGEBOTE_FREIGEGEBEN
);
