import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { backendApiService } from 'api/ApiService';
import { BenutzerForm } from 'api/client';
import { AppLoaderContainer } from 'components/AppLoaderContainer';
import { PageHeading } from 'components/PageHeading';
import { ROLES } from 'constants/roles';
import { uiSchema } from 'forms/Benutzer/config';
import { SimpleForm } from 'forms/SimpleForm';
import { useFormState } from 'forms/state/useFormState';
import { Schema } from 'forms/types/UiSchemaTypes';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

export const Index = () => {
    const { loadingStart, loadingEnd, isLoading } = useFormState();

    const navigate = useNavigate();

    const [data, setData] = useState<BenutzerForm | {}>({});
    const [schema, setSchema] = useState<Schema | undefined>(undefined);

    const putBenutzerAntrag = (formData: BenutzerForm, persist: boolean = false) => {
        return backendApiService.putBenutzerAntrag(formData, persist);
    };

    const handleSubmit = (formData: BenutzerForm) => {
        return putBenutzerAntrag(formData, true);
    };

    const handleOnChange = (formData: BenutzerForm) => {
        if (!Object.keys(formData).length) {
            return;
        }

        putBenutzerAntrag(formData).then((response) => {
            setSchema(response?.schema as Schema);
        });
    };

    useEffect(() => {
        loadingStart();

        backendApiService
            .createBenutzerAntragItem()
            .then((response) => {
                setData(response?.data as BenutzerForm);
                setSchema(response?.schema as Schema);
            })
            .finally(() => loadingEnd());
    }, [loadingEnd, loadingStart]);

    return (
        <ContentContainer title="Benutzer erstellen">
            <StyledContainer>
                <PageHeading title="Benutzer erstellen" />

                <AppLoaderContainer isLoading={data && isLoading}>
                    <SimpleForm
                        isLoading={data && isLoading}
                        messages={{
                            success: 'Benutzer wurde erfolgreich angelegt.',
                            error: 'Anlegen des Benutzers ist fehlgeschlagen.',
                        }}
                        data={data}
                        schema={schema}
                        uiSchema={uiSchema}
                        onChange={handleOnChange}
                        onSubmit={handleSubmit}
                        onAfterSubmit={() => navigate(PathBuilder.home.verwaltung.benutzer.path)}
                        submitLabel="Benutzer erstellen"
                        backLink={{
                            path: PathBuilder.home.verwaltung.benutzer.path,
                            title: 'Zur Übersicht',
                        }}
                    />
                </AppLoaderContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const BenutzerCreatePage = withAuthorization(withSideBar(Index, menuVerwaltung), [ROLES.USER]);
