import { RuleEffect } from '@jsonforms/core';

import { modalEinrichtungsleitung, modalVerantwortlicheFachkraft } from 'forms/Meldung/modalElements';
// ToDo Element function verwenden und Typings fixen
import { control } from 'forms/ui-schemas/elements/control';
import { customVerticalLayout } from 'forms/ui-schemas/elements/customVerticalLayout';
import { group } from 'forms/ui-schemas/elements/group';
import { inlineRowControl } from 'forms/ui-schemas/elements/inlineRowControl';
import { listControlWithModalForm } from 'forms/ui-schemas/elements/listControlWithModalForm';
import { nestedGroup } from 'forms/ui-schemas/elements/nestedGroup';
import { tableColumn } from 'forms/ui-schemas/elements/tableColumn';

export const uiSchema = {
    type: 'CustomVerticalLayout',
    elements: [
        group(
            [
                listControlWithModalForm(
                    ['#/properties/stepBeschaeftigteEinrichtungsleitung'],
                    [
                        tableColumn('Name', { scope: '#/properties/personalName' }),
                        tableColumn('Tätig seit', { scope: '#/properties/personalTaetigSeit' }),
                        tableColumn('Stellenanteil (%)', { scope: '#/properties/personalStellenAnteil' }),
                    ],
                    ['Ansprechpartner hinzufügen'],
                    ['Ansprechpartner löschen'],
                    ['Wollen Sie diesen Ansprechpartner mit allen Angaben wirklich löschen?'],
                    [modalEinrichtungsleitung],
                    { types: ['Einrichtungsleitung'], labelScope: '#/properties/stepBeschaeftigteEinrichtungsleitung' }
                ),
            ],
            {
                noSeparator: true,
            }
        ),
        group(
            [control('stepBeschaeftigteEinrichtungsleitungDokumente')],
            {},
            undefined,
            undefined,
            undefined,
            '#/properties/stepBeschaeftigteEinrichtungsleitungDokumente'
        ),
        group(
            [
                listControlWithModalForm(
                    ['#/properties/stepBeschaeftigtePflegedienstleitung'],
                    [
                        tableColumn('Name', { scope: '#/properties/fachkraftName' }),
                        tableColumn('Tätig seit', { scope: '#/properties/fachkraftTaetigSeit' }),
                        tableColumn('Stellenanteil (%)', { scope: '#/properties/fachkraftStellenAnteil' }),
                    ],
                    ['Ansprechpartner hinzufügen'],
                    ['Ansprechpartner löschen'],
                    ['Wollen Sie diesen Ansprechpartner mit allen Angaben wirklich löschen?'],
                    [modalVerantwortlicheFachkraft],
                    {
                        types: ['VerantwortlicheFachkraft'],
                        labelScope: '#/properties/stepBeschaeftigtePflegedienstleitung',
                    }
                ),
            ],
            {
                noSeparator: true,
            }
        ),
        group(
            [control('stepBeschaeftigtePflegedienstleitungDokumente')],
            {},
            undefined,
            undefined,
            undefined,
            '#/properties/stepBeschaeftigtePflegedienstleitungDokumente'
        ),
        group(
            [
                group(
                    [inlineRowControl([control('field401/properties/vza'), control('field401/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field401'
                ),
                group(
                    [inlineRowControl([control('field401b/properties/vza'), control('field401b/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field401b'
                ),
                group(
                    [inlineRowControl([control('field401c/properties/vza'), control('field401c/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field401c'
                ),
                group(
                    [inlineRowControl([control('field401d/properties/vza'), control('field401d/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field401d'
                ),
                group(
                    [inlineRowControl([control('field401a/properties/vza'), control('field401a/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field401a'
                ),
            ],
            undefined,
            undefined,
            undefined,
            undefined,
            '#/properties/field401Label'
        ),
        group(
            [
                group(
                    [inlineRowControl([control('field402/properties/vza'), control('field402/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field402'
                ),
                group(
                    [inlineRowControl([control('field402a/properties/vza'), control('field402a/properties/anzahl')])],
                    {
                        noSeparator: true,
                        unstyled: true,
                        headline: {
                            variant: 'h3',
                            inline: true,
                        },
                    },
                    undefined,
                    undefined,
                    undefined,
                    '#/properties/field402a'
                ),
            ],
            undefined,
            undefined,
            undefined,
            undefined,
            '#/properties/field402Label'
        ),
        nestedGroup(
            'stepBeschaeftigtePflegePersonal',
            [
                'pflegePersonalFachkraft',
                'pflegePersonalMitAusbildung',
                'pflegePersonalPalliativ',
                'pflegePersonalSozialeBetreuung',
                'pflegePersonalHilfskraft',
            ],
            [
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
            ]
        ),
        nestedGroup(
            'stepBeschaeftigtePflegePersonalSozialeBetreuung',
            ['pflegePersonalFachkraft', 'pflegePersonalHilfskraft'],
            [
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
                customVerticalLayout([inlineRowControl([control('vza'), control('anzahl')])]),
            ]
        ),
        group(
            [
                control('stepBeschaeftigteIntensivpflegeCheck', { options: { validateOnChange: true } }),
                group(
                    [
                        inlineRowControl(
                            [
                                control('stepBeschaeftigteIntensivpflegeDatenVZA'),
                                control('stepBeschaeftigteIntensivpflegeDatenAnzahl'),
                            ],
                            {
                                title: 'Intensivpflegefachkräfte',
                                rule: {
                                    effect: RuleEffect.SHOW,
                                    condition: {
                                        scope: '#/properties/stepBeschaeftigteIntensivpflegeCheck',
                                        schema: { const: true },
                                    },
                                },
                            }
                        ),
                    ],
                    { noSeparator: true }
                ),
            ],
            {},
            'Intensivpflege',
            undefined,
            undefined,
            undefined
        ),

        group([control('vza'), control('anzahl')], {}, 'asgasgasgasg', '#/properties/field401', undefined, undefined),
        group(
            [
                control('stepBeschaeftigtepflegePersonalHauswirtschaftCheck'),
                control('stepBeschaeftigtepflegePersonalHauswirtschaftVersorgung', {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/stepBeschaeftigtepflegePersonalHauswirtschaftCheck',
                            schema: { const: false },
                        },
                    },
                }),
            ],
            {},
            'Hauswirtschaft',
            undefined,
            undefined,
            undefined
        ),
        group(
            [
                control('stepBeschaeftigtepflegePersonalPalliativversorgungCheck', {
                    options: { validateOnChange: true },
                }),
                control('stepBeschaeftigtepflegePersonalPalliativversorgungEigenesPersonalCheck', {
                    rule: {
                        effect: RuleEffect.SHOW,
                        condition: {
                            scope: '#/properties/stepBeschaeftigtepflegePersonalPalliativversorgungCheck',
                            schema: { const: true },
                        },
                    },
                }),
            ],
            {},
            'Spezialisierte ambulante Palliativversorgung',
            undefined,
            undefined,
            undefined
        ),
        control('notizBeschaeftigte'),
    ],
};
