import { useCallback } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { BehoerdenListResponse } from 'api/types';
import { useServerActionsColumn } from 'components/DataTable/columns/useServerActionsColumn';
import { BenutzerColumn } from 'components/DataTable/columns/utilities/BenutzerColumn';
import { IdColumn } from 'components/DataTable/columns/utilities/IdColumn';
import { AntragListAction } from 'components/DataTable/hooks/useListActions';
import { PathBuilder } from 'navigation/Paths';

export const useBehoerdenColumns = (
    handleAction: AntragListAction<any>
): ((data: BehoerdenListResponse | undefined) => MUIDataTableColumnDef[]) => {
    const actions = useServerActionsColumn({ handleAction });

    return useCallback(
        (data) => {
            if (!data) return [];

            return [
                IdColumn(),
                {
                    name: 'name',
                    label: 'Name des Kreises / der kreisfreien Stadt',
                },
                BenutzerColumn(data, PathBuilder.home.verwaltung.behoerden),
                {
                    name: 'ansprechpartner',
                    label: 'Ansprechpartner',
                },
                {
                    name: 'email',
                    label: 'E-Mail-Adresse',
                },
                actions(data),
            ];
        },
        [actions]
    );
};
