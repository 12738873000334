import React from 'react';
import { Typography } from '@mui/material';

import { useInvestRegistrationListActions } from 'components/DataTable/actions/useInvestRegistrationListActions';
import { useInvestRegistrationColumns } from 'components/DataTable/columns/useInvestRegistrationColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { ROLE_TYPES } from 'constants/roles';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useInvestRegistrationListData } from './useInvestRegistrationListData';

const Index = () => {
    const { handleAction, serverDataChanged } = useInvestRegistrationListActions({
        pathBuilder: PathBuilder.home.verwaltung.investRegistrations,
    });

    const getData = useInvestRegistrationListData();
    const getColumns = useInvestRegistrationColumns(handleAction);

    return (
        <ContentContainer title="Fehlgeschlagene Invest-Übertragungen">
            <StyledContainer>
                <Typography variant="h1">Fehlgeschlagene Invest-Übertragungen</Typography>

                <BaseGridContainer>
                    <DataTableHydraServerside
                        getColumns={getColumns}
                        getData={getData}
                        serverDataChanged={serverDataChanged}
                        persistSearchText
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const InvestRegistrationOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_INVEST_REGISTRATION_FAILED
);
