import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';

import { useIsLoggedIn } from 'api/auth';
import { PageHeading } from 'components/PageHeading';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { PathBuilder } from 'navigation/Paths';

const Error404 = () => {
    const isLoggedIn = useIsLoggedIn();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1);
    };

    const onNavigateToStart = () => {
        navigate(isLoggedIn ? PathBuilder.home.dashboard.path : PathBuilder.home.path);
    };

    return (
        <ContentContainer title="Fehler 404">
            <StyledContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PageHeading title="Fehler 404 - Seite nicht gefunden" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography paragraph>
                                    Der aufgerufene Inhalt kann nicht angezeigt werden. Entweder existiert die
                                    angeforderte Seite nicht, oder Sie haben keine Berechtigung die Inhalte zu sehen.
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={onBack}>Zurück</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" onClick={onNavigateToStart}>
                                    {isLoggedIn ? 'Weiter zum Dashboard' : 'Weiter zur Startseite'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledContainer>
        </ContentContainer>
    );
};

export const Error404Page = withSideBar(Error404, []);
