import React, { useRef } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

interface HtmlEditorProps {
    value?: string | undefined;
    disabled?: boolean;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    resizeable?: boolean;
    id?: string;
}

export const HtmlEditor: React.FC<HtmlEditorProps> = ({ value, disabled = false, onBlur, onChange, id }) => {
    const ref = useRef<HTMLDivElement>(null);
    const testRref = useRef<HTMLDivElement>(null);

    return (
        <>
            <div id={`test-${id}`} ref={testRref}></div>
            <div id={id} ref={ref}>
                <CKEditor
                    disabled={disabled}
                    editor={ClassicEditor}
                    config={{
                        toolbar: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'undo',
                            'redo',
                        ],
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Absatz', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: 'ck-heading_heading3' },
                            ],
                        },
                    }}
                    data={value}
                    onBlur={(event, editor) => onBlur?.(trim(editor.data.get()))}
                    onChange={(event, editor) => onChange?.(trim(editor.data.get()))}
                    onFocus={() =>
                        ref?.current?.scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                        })
                    }
                />
            </div>
        </>
    );
};

const trim = (value: string) => (value.endsWith('\n') ? value.substr(0, value.length - 1) : value);
