import { useLandschaftsverbandListActions } from 'components/DataTable/actions/useLandschaftsverbandListActions';
import { useLandschaftsverbandColumns } from 'components/DataTable/columns/useLandschaftsverbandColumns';
import { DataTableHydraServerside } from 'components/DataTable/DataTableHydraServerside';
import { PageHeading } from 'components/PageHeading';
import { ROLE_TYPES } from 'constants/roles';
import { BaseGridContainer } from 'layout/components/BaseGridContainer';
import { ContentContainer } from 'layout/container/ContentContainer';
import { StyledContainer } from 'layout/container/StyledContainer';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuVerwaltung } from 'navigation/menuConfigs/menuVerwaltung';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';

import { useLandschaftsverbandListData } from './useLandschaftsverbandListData';

const Index = () => {
    const { handleAction } = useLandschaftsverbandListActions();

    const getData = useLandschaftsverbandListData();

    const columns = useLandschaftsverbandColumns(handleAction);

    return (
        <ContentContainer title="Übersicht der Landschaftsverbände">
            <StyledContainer>
                <BaseGridContainer>
                    <PageHeading title="Landschaftsverbände" />

                    <DataTableHydraServerside
                        getColumns={columns}
                        getData={getData}
                        persistSearchText
                        initialOptions={{
                            sortOrder: {
                                name: 'name',
                                direction: 'asc',
                            },
                            custom: {
                                pathBuilder: PathBuilder.home.verwaltung.landschaftsverband,
                            },
                        }}
                    />
                </BaseGridContainer>
            </StyledContainer>
        </ContentContainer>
    );
};

export const LandschaftsverbandOverview = withAuthorization(
    withSideBar(Index, menuVerwaltung),
    ROLE_TYPES.ROLLEN_LANDSCHAFTSVERBAND
);
