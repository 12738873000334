import React from 'react';

import { ACTIONS } from 'constants/antragActions';
import { ROLE_TYPES } from 'constants/roles';
import { withSideBar } from 'layout/hooks/useSideBar';
import { menuAufgaben } from 'navigation/menuConfigs/menuAufgaben';
import { PathBuilder } from 'navigation/Paths';
import { withAuthorization } from 'navigation/withAuthorization';
import { MeldungUebersicht } from 'pages/Meldung/Components/MeldungUebersicht';
import { useMeldungAufgabenListData } from 'pages/Meldung/useMeldungAufgabenListData';

const Index = () => {
    const pathBuilder = PathBuilder.home.aufgaben.meldungen.ohneZustaendigkeit;

    const getData = useMeldungAufgabenListData({ keinZustaendigerBenutzer: true });

    return (
        <MeldungUebersicht
            title="Meldungen ohne Zuständigkeit"
            headline="Meldungen ohne Zuständigkeit"
            pathBuilder={pathBuilder}
            getData={getData}
            initialTableOptions={{
                custom: {
                    pathBuilder,
                },
            }}
            labelOverrides={{ [ACTIONS.EDIT]: { label: 'Freigeben' } }}
        />
    );
};

export const MeldungOhneZustaendigkeit = withAuthorization(
    withSideBar(Index, menuAufgaben),
    ROLE_TYPES.ROLLEN_MELDUNGEN_OHNE_ZUSTAENDIGKEIT
);
